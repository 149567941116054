import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { submit } from "redux-form";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { isUploading } from "../../reducers";

const styles = (theme) => ({
  fullHeight: {
    height: "100%",
  },
  button: {
    marginLeft: theme.spacing(1),
  },
  normal: {
    height: "auto",
  },
});

class FormDialog extends Component {
  handleSubmit = () => {
    const { dispatch, formName } = this.props;
    dispatch(submit(formName));
  };

  handleDelete = () => {
    const { onDelete, initialValues } = this.props;
    return onDelete && onDelete(initialValues);
  };

  render() {
    const {
      title,
      open,
      cancelButtonText,
      submitButtonText,
      deleteButtonText,
      onCancel,
      onSubmit,
      onDelete,
      maxWidth,
      fullHeight,
      uploading,
      classes,
      formComponent: FormComponent,
      ...rest
    } = this.props;

    const actions = (
      <>
        <Button
          variant="text"
          color="primary"
          onClick={onCancel}
          className={classes.button}
        >
          {cancelButtonText}
        </Button>
        {onDelete && (
          <Button
            variant="text"
            color="primary"
            className={classes.button}
            onClick={this.handleDelete}
          >
            {deleteButtonText}
          </Button>
        )}
        <Button
          variant="text"
          color="primary"
          onClick={this.handleSubmit}
          disabled={uploading}
          className={classes.button}
        >
          {submitButtonText}
        </Button>
      </>
    );

    return (
      <Dialog
        title={title}
        open={open}
        onClose={onCancel}
        fullWidth
        maxWidth={maxWidth}
        classes={{ paper: fullHeight ? classes.fullHeight : classes.normal }}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <FormComponent onSubmit={onSubmit} {...rest} />
        </DialogContent>
        <DialogActions data-cy="dialogActions">{actions}</DialogActions>
      </Dialog>
    );
  }
}

FormDialog.propTypes = {
  title: PropTypes.string.isRequired,
  formComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
    .isRequired,
  formName: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  cancelButtonText: PropTypes.string,
  submitButtonText: PropTypes.string,
  deleteButtonText: PropTypes.string,
  maxWidth: PropTypes.string,
  fullHeight: PropTypes.bool,
  initialValues: PropTypes.object,
  uploading: PropTypes.bool.isRequired,
};

FormDialog.defaultProps = {
  open: false,
  cancelButtonText: "Cancel",
  submitButtonText: "Submit",
  deleteButtonText: "Delete",
  onDelete: null,
  maxWidth: "sm",
  fullHeight: false,
  initialValues: null,
};

const mapStateToProps = (state) => ({
  uploading: isUploading(state),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(FormDialog);
