import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getReferenceDataDescription,
  getWebsiteDifferencePagination,
  getWebsiteDifferences,
  isLoading,
} from "../../reducers";
import { searchWebsiteDifferences } from "../../actions/websiteDifferences";
import api from "../../services/api";
import dates from "../../util/dates";
import GridListing from "../common/GridListing";
import MultilineText from "../common/MultilineText";
import TimeAgo from "../common/TimeAgo";
import Pagination from "../common/Pagination";
import { useAuth0 } from "@auth0/auth0-react";

const WebsiteDifferencesListing = ({
  history,
  websiteDifferences,
  loading,
  filter,
  sortBy,
  updateSort,
  clearFilter,
  getWebsiteDifferenceStatus,
  pagination,
  localSearchWebsiteDifferences,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [mounted, setMounted] = useState(false);

  const refreshData = (offset, accessToken) => {
    const searchParameters = {
      ...filter,
      limit: pagination.pageSize,
      orderByField:
        sortBy.direction === "asc" ? sortBy.field : `-${sortBy.field}`,
      offset,
    };

    return localSearchWebsiteDifferences(searchParameters, accessToken);
  };

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      refreshData(pagination.offset, accessToken).then(() => setMounted(true));
    })();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      if (mounted) {
        refreshData(pagination.offset, accessToken);
      }
    })();
  }, [getAccessTokenSilently, filter, sortBy]);

  const handlePrevious = async () => {
    const accessToken = await getAccessTokenSilently();
    refreshData(pagination.previousOffset, accessToken);
  };

  const handleNext = async () => {
    const accessToken = await getAccessTokenSilently();
    refreshData(pagination.nextOffset, accessToken);
  };

  const downloadFile = async (websiteDifference) => {
    const accessToken = await getAccessTokenSilently();
    const url = await api.websiteDifferences.getChangeUrl(
      websiteDifference.id,
      accessToken
    );
    window.open(url, "_blank");
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <GridListing
        sortedData={websiteDifferences}
        loading={loading && websiteDifferences.length === 0}
        sortBy={sortBy}
        dense={false}
        updateSort={(field, direction) =>
          updateSort({
            field,
            direction,
          })
        }
        clearFilter={clearFilter}
        action={(websiteDifference) => (
          <IconButton
            onClick={() => {
              history.push(`/sentinel/differences/${websiteDifference.id}`, {
                filter,
              });
            }}
          >
            <Icon>edit</Icon>
          </IconButton>
        )}
        columns={[
          {
            label: "Organisation",
            name: "orgFriendlyId",
            size: 1,
            sortable: true,
            render: (websiteDifference) => (
              <span>
                {websiteDifference.organisation
                  ? websiteDifference.organisation.name
                  : "-"}
              </span>
            ),
          },
          {
            label: "Url",
            name: "url",
            size: 3,
            sortable: false,
            render: (websiteDifference) => (
              <MultilineText text={websiteDifference.url} />
            ),
          },
          {
            label: "Created",
            name: "created",
            size: 2,
            sortable: true,
            render: (websiteDifference) =>
              websiteDifference.created ? (
                <TimeAgo
                  value={dates.parseTimestamp(websiteDifference.created)}
                  expandable
                />
              ) : (
                "-"
              ),
          },
          {
            label: "Image Different",
            name: "imageDifferent",
            size: 2,
            sortable: false,
            render: (websiteDifference) =>
              websiteDifference.imageDifferent ? "Yes" : "No",
          },
          {
            label: "Text Different",
            name: "textDifferent",
            size: 2,
            sortable: false,
            render: (websiteDifference) =>
              websiteDifference.textDifferent ? "Yes" : "No",
          },
          {
            label: "Status",
            name: "status",
            size: 1,
            sortable: true,
            render: (websiteDifference) =>
              getWebsiteDifferenceStatus(websiteDifference.status),
          },
          {
            label: "View",
            name: "gcsObjectName",
            size: 1,
            sortable: false,
            render: (websiteDifference) => (
              <IconButton
                onClick={() => downloadFile(websiteDifference)}
                disabled={!websiteDifference.gcsObjectName}
              >
                <Icon>open_in_new</Icon>
              </IconButton>
            ),
          },
        ]}
      />
      <Pagination
        pagination={pagination}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
      />
    </>
  );
};

WebsiteDifferencesListing.propTypes = {
  history: PropTypes.object.isRequired,
  getWebsiteDifferenceStatus: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  sortBy: PropTypes.object.isRequired,
  updateSort: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired,

  // redux
  loading: PropTypes.bool.isRequired,
  websiteDifferences: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  localSearchWebsiteDifferences: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: isLoading(state),
  websiteDifferences: getWebsiteDifferences(state),
  getWebsiteDifferenceStatus: (id) =>
    getReferenceDataDescription(state, "WebsiteDifferenceStatus", id, "-"),
  pagination: getWebsiteDifferencePagination(state),
});

export default connect(mapStateToProps, {
  localSearchWebsiteDifferences: searchWebsiteDifferences,
})(WebsiteDifferencesListing);
