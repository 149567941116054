import { useEffect } from "react";

// Used to load a certain filter if passed in via the location state (ie from RiskDashboard)
// It then removes the location state so that on refresh the filter isn't changed again
function useLocationStateFilter(setFilter, history) {
  useEffect(() => {
    if (history.location.state && history.location.state.filter) {
      setFilter({ ...history.location.state.filter });

      // remove filter from state
      history.replace({
        ...history.location,
        state: { ...history.location.state, filter: undefined },
      });
    }
  }, [history.location.state]);
}

export default useLocationStateFilter;
