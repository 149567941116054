import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import Link from "@material-ui/core/Link";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { required } from "redux-form-validators";
import { scrollToFirstInvalidInput } from "../../../util/formHelpers";
import FormError from "../../common/FormError";
import WrappedFilePicker from "../wrapper/WrappedFilePicker";
import WrappedRadioGroup from "../wrapper/WrappedRadioGroup";
import { getLabels, getReferenceDataType } from "../../../reducers";

const useStyles = makeStyles((theme) => ({
  linkText: {
    cursor: "pointer",
    textDecoration: "none",
    color: theme.palette.swatch.link,
  },
}));

const BulkImportForm = ({
  bulkImportTypes,
  fieldLabels,
  error,
  selectedType,
}) => {
  const classes = useStyles();
  const getTypeHelp = () => {
    if (selectedType) {
      return (
        <span>
          To get started, open{" "}
          <Link
            className={classes.linkText}
            href={bulkImportTypes.values[selectedType].props.template}
            target="_blank"
          >
            {bulkImportTypes.values[selectedType].description} template
          </Link>
        </span>
      );
    }
    return null;
  };

  return (
    <form autoComplete="off" className={classes.root}>
      {error && <FormError errorMessage={error} />}
      <Field
        name="type"
        component={WrappedRadioGroup}
        label={fieldLabels.labels.type}
        validate={required({ msg: `${fieldLabels.labels.type} is required` })}
        options={bulkImportTypes.ids.map((id) => ({
          value: id,
          label: bulkImportTypes.values[id].description,
        }))}
        row
        required
        helperText={getTypeHelp()}
      />
      <Field
        name="workItemFile"
        component={WrappedFilePicker}
        required
        validate={required({
          msg: `${fieldLabels.labels.workItemFile} is required`,
        })}
        label={fieldLabels.labels.workItemFile}
        fullWidth
        uploadNamespace={"workItemFile"}
        multiple={false}
        acceptedFileTypes=".csv"
      />
      {selectedType && selectedType === "NEW_COMPLIANCE_TASKS" && (
        <Field
          name="questionFile"
          component={WrappedFilePicker}
          required
          validate={required({
            msg: `${fieldLabels.labels.questionFile} is required`,
          })}
          label={fieldLabels.labels.questionFile}
          fullWidth
          uploadNamespace={"questionFile"}
          multiple={false}
          acceptedFileTypes=".csv"
        />
      )}
      <Field
        name="persist"
        component={WrappedRadioGroup}
        label={fieldLabels.labels.persist}
        options={[
          {
            label: "Yes please save changes",
            value: true,
          },
          {
            label: "No, only validate the files but don't save changes",
            value: false,
          },
        ]}
        row
        required
        validate={required({
          msg: "We need to know to persist changes or not",
        })}
      />
    </form>
  );
};

BulkImportForm.propTypes = {
  error: PropTypes.string,
  bulkImportTypes: PropTypes.object.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  selectedType: PropTypes.string,
};

BulkImportForm.defaultProps = {
  error: null,
  selectedType: null,
};

export const BULK_IMPORT_FORM_NAME = "bulkImportForm";
const reduxFormSelector = formValueSelector(BULK_IMPORT_FORM_NAME);

const mapStateToProps = (state) => ({
  bulkImportTypes: getReferenceDataType(state, "BulkImportType"),
  fieldLabels: getLabels(state).WorkItemBulkImport,
  selectedType: reduxFormSelector(state, "type"),
});

const form = reduxForm({
  form: BULK_IMPORT_FORM_NAME,
  onSubmitFail: (errors) => scrollToFirstInvalidInput(errors),
});

const reduxData = connect(mapStateToProps);

export default compose(form, reduxData)(BulkImportForm);
