import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { getReferenceDataOptions } from "../../reducers";
import ChoiceChips from "../common/ChoiceChips";
import FiltersBar from "../common/FiltersBar";

const useStyles = makeStyles(() => ({
  label: {
    lineHeight: "1.5rem",
  },
}));

const MentionFilters = ({ filter, updateFilter, mentionOptions }) => {
  const classes = useStyles();

  return (
    <FiltersBar fullWidth={false}>
      <>
        <InputLabel className={classes.label}>Status</InputLabel>
        <ChoiceChips
          value={filter.statuses[0]}
          options={mentionOptions}
          onChange={(value) => updateFilter("statuses", value ? [value] : [])}
        />
      </>
    </FiltersBar>
  );
};

MentionFilters.propTypes = {
  mentionOptions: PropTypes.array.isRequired,
  filter: PropTypes.object.isRequired,
  updateFilter: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  mentionOptions: getReferenceDataOptions(state, "MentionStatus"),
});

export default connect(mapStateToProps)(MentionFilters);
