import { useAuth0 } from "@auth0/auth0-react";
import { authorizer, useTenant } from "@certane/arcadia-web-components";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import AuditReportFilters from "../../components/audit/AuditReportFilters";
import AuditReportListing from "../../components/audit/AuditReportListing";
import ActionButton from "../../components/common/ActionButton";
import ActionHeading from "../../components/common/ActionHeading";
import Container from "../../components/common/Container";
import FloatingActionButton from "../../components/common/FloatingActionButton";
import HeaderBar from "../../components/common/HeaderBar";
import PageHeading from "../../components/common/PageHeading";
import { getLoggedInUser } from "../../reducers";
import { auditIcon, clearIcon } from "../../util/icons";
import usePersistedState from "../../util/persistedState";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  contents: {
    marginBottom: "75px",
  },
}));

const defaultFilter = {
  textSearch: "",
  types: [],
  auditors: [],
  ownerIds: [],
  sargonServiceProviderIds: [],
};

const Index = ({ history, loggedInUser }) => {
  const classes = useStyles();
  const { user } = useAuth0();
  const { tenant } = useTenant();
  const [sortBy, setSortBy] = usePersistedState(
    `audit-listing-sort-${loggedInUser.email}`,
    {
      field: "created",
      direction: "desc",
    }
  );
  const [filter, setFilter] = usePersistedState(
    `audit-listing-filter-${loggedInUser.email}`,
    defaultFilter,
    ["textSearch"]
  );

  const clearFilter = () => setFilter(defaultFilter);

  const updateFilter = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  return (
    <div className={classes.root}>
      <HeaderBar fluid>
        <ActionHeading
          heading={<PageHeading icon={auditIcon()} heading="Audits" />}
          actions={[
            <ActionButton
              key="clearFilter"
              tooltip="Clear filters"
              icon={clearIcon()}
              onClick={clearFilter}
              data-cy="clear-filters-button"
            />,
          ]}
        />
      </HeaderBar>
      {authorizer.check("audit", "editor", user, tenant?.id) && (
        <FloatingActionButton onClick={() => history.push("/audits/new")} />
      )}
      <AuditReportFilters filter={filter} updateFilter={updateFilter} />
      <Container fluid className={classes.contents}>
        <AuditReportListing
          history={history}
          filter={filter}
          clearFilter={clearFilter}
          sortBy={sortBy}
          updateSort={setSortBy}
        />
      </Container>
    </div>
  );
};

Index.propTypes = {
  loggedInUser: PropTypes.object,
  history: PropTypes.object.isRequired,
};

Index.defaultProps = {
  loggedInUser: null,
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
});

export default connect(mapStateToProps)(Index);
