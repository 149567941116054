import { normalize } from "normalizr";
import schemas from "../schemas";
import organisationsApi from "../services/api/organisations";
import { asyncAction } from "./actions";

export const ORGS_CLEAR_ACTIVE = "ORGS_CLEAR_ACTIVE";
export const ORGS_CREATE = "ORGS_CREATE";
export const ORGS_SEARCH = "ORGS_SEARCH";
export const ORGS_GET_BY_ID = "ORGS_GET_BY_ID";
export const ORGS_PATCH = "ORGS_PATCH";

export function clearActiveOrganisations() {
  return {
    type: ORGS_CLEAR_ACTIVE,
  };
}

export const createOrganisation = (organisation, accessToken) =>
  asyncAction(ORGS_CREATE, organisationsApi.create(organisation, accessToken), {
    responseTransformer: (createdOrg) =>
      normalize(createdOrg, schemas.organisation),
  });

export const searchOrganisations = (searchParameters, accessToken) => {
  const abortController = window.AbortController ? new AbortController() : null;
  return asyncAction(
    ORGS_SEARCH,
    organisationsApi.search(searchParameters, abortController, accessToken),
    {
      responseTransformer: (response) => ({
        pagination: {
          offset: response.offset,
          previousOffset: response.previousOffset,
          nextOffset: response.nextOffset,
          pageSize: response.pageSize,
          resultCount: response.resultCount,
        },
        ...normalize(response.results, schemas.arrayOfOrganisations),
      }),
      actionParams: {
        abortController,
        nonBlocking: true,
      },
    }
  );
};

export const getOrganisationById = (orgId, accessToken) =>
  asyncAction(ORGS_GET_BY_ID, organisationsApi.getById(orgId, accessToken), {
    responseTransformer: (organisation) =>
      normalize(organisation, schemas.organisation),
  });

export const patchOrganisation = (orgId, changes, description, accessToken) =>
  asyncAction(
    ORGS_PATCH,
    organisationsApi.patch(orgId, changes, description, accessToken),
    {
      responseTransformer: (organisation) =>
        normalize(organisation, schemas.organisation),
    }
  );
