import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Tooltip from "@material-ui/core/Tooltip";
import * as _ from "lodash";
import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Chip from "@material-ui/core/Chip";
import { getReferenceDataDescription } from "../../../reducers";
import dates from "../../../util/dates";
import ExpandableListItem from "./ExpandableListItem";
import TimeAgo from "../TimeAgo";
import FileIcon from "../../workitem/FileIcon";

const useStyles = makeStyles((theme) => ({
  versionTitle: {
    fontSize: "0.85rem",
    fontWeight: "400",
    color: "#515151",
  },
  versionMeta: {
    fontSize: "0.75rem",
    color: "#999999",
  },
  uploadedFile: {
    fontSize: "0.75rem",
    color: "#999999",
  },
  list: {
    paddingBottom: 0,
  },
  chip: {
    marginRight: theme.spacing(1),
  },
}));

const DELIMITER = "{--}";

const FileList = ({
  documents,
  noFileMessage,
  title,
  additionalContent,
  onFileClick,
  renderMenuItems,
  getDocumentFlagDescription,
}) => {
  const classes = useStyles();

  const [historyExpanded, setHistoryExpanded] = useState({});

  if (!documents || documents.length === 0) {
    return (
      <div className={classes.root}>
        <List dense>
          <ListSubheader disableSticky>{noFileMessage}</ListSubheader>
        </List>
      </div>
    );
  }

  const groupedDocuments = _.groupBy(
    documents,
    (doc) => `${doc.groupKey || ""}${DELIMITER}${doc.fileName}`
  );

  return (
    <div className={classes.root}>
      {title && <h2>{title}</h2>}
      <List className={classes.list}>
        {Object.keys(groupedDocuments).map((fileNameKey) => {
          const fileName = fileNameKey.split(DELIMITER)[1];
          const orderedDocuments = _.orderBy(
            groupedDocuments[fileNameKey],
            "uploaded",
            "desc"
          );
          const latestVersion = orderedDocuments[0];
          const olderVersions = [...orderedDocuments].slice(1);

          return (
            <Fragment key={fileNameKey}>
              <ExpandableListItem
                icon={<FileIcon document={latestVersion} />}
                primary={
                  <span className={classes.versionTitle}>
                    <span>
                      {latestVersion.namespace
                        ? `${latestVersion.namespace} / `
                        : ""}
                      {fileName}
                    </span>
                    <span>{historyExpanded[fileNameKey]}</span>
                    {historyExpanded[fileNameKey] && (
                      <span> (current version)</span>
                    )}
                  </span>
                }
                secondary={
                  <span className={classes.versionMeta}>
                    <Tooltip
                      title={`Uploaded ${dates.parseTimestamp(
                        latestVersion.uploaded
                      )}`}
                      disableFocusListener
                    >
                      <span>
                        <TimeAgo
                          value={dates.parseTimestamp(latestVersion.uploaded)}
                        />
                      </span>
                    </Tooltip>
                    <span>
                      {" "}
                      by <strong>{latestVersion.createdByName}</strong>
                    </span>
                    {additionalContent && additionalContent(latestVersion)}
                    <br />
                    {latestVersion.flags.length > 0 &&
                      latestVersion.flags.map((flag) => (
                        <Chip
                          className={classes.chip}
                          key={flag}
                          label={getDocumentFlagDescription(flag)}
                          size="small"
                          disabled
                        />
                      ))}
                  </span>
                }
                className={classes.uploadedFile}
                onClick={onFileClick ? () => onFileClick(latestVersion) : null}
                renderMenuItems={
                  renderMenuItems
                    ? (onClose) => renderMenuItems(latestVersion, onClose)
                    : null
                }
                expanderLabelClosed="show history"
                expanderLabelOpen="hide history"
                onExpand={() => {
                  setHistoryExpanded({
                    ...historyExpanded,
                    [fileNameKey]: true,
                  });
                }}
                onCollapse={() => {
                  setHistoryExpanded({
                    ...historyExpanded,
                    [fileNameKey]: false,
                  });
                }}
              >
                {olderVersions.length > 0 ? (
                  <List component="div" dense>
                    {olderVersions.map((file, index, theList) => (
                      <ListItem
                        key={file.gcsObjectName}
                        onClick={onFileClick ? () => onFileClick(file) : null}
                      >
                        <ListItemText
                          inset
                          primary={
                            <span className={classes.versionTitle}>
                              <span>Version from </span>
                              <Tooltip
                                title={`Uploaded ${dates.parseTimestamp(
                                  file.uploaded
                                )}`}
                                disableFocusListener
                              >
                                <span>
                                  <TimeAgo
                                    value={dates.parseTimestamp(file.uploaded)}
                                  />
                                </span>
                              </Tooltip>
                              {theList.length === index + 1 && (
                                <span> (oldest version)</span>
                              )}
                            </span>
                          }
                          secondary={
                            <span className={classes.versionMeta}>
                              Uploaded by <strong>{file.createdByName}</strong>
                            </span>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                ) : null}
              </ExpandableListItem>
            </Fragment>
          );
        })}
      </List>
    </div>
  );
};

FileList.propTypes = {
  documents: PropTypes.array.isRequired,
  noFileMessage: PropTypes.string,
  title: PropTypes.string,
  onFileClick: PropTypes.func,
  additionalContent: PropTypes.func, // (doc) => node
  renderMenuItems: PropTypes.func,
  // redux
  getDocumentFlagDescription: PropTypes.func.isRequired,
};

FileList.defaultProps = {
  title: undefined,
  noFileMessage: undefined,
  onFileClick: undefined,
  additionalContent: undefined,
  renderMenuItems: undefined,
};

const mapStateToProps = (state) => ({
  getDocumentFlagDescription: (id) =>
    getReferenceDataDescription(state, "DocumentFlag", id, "-"),
});

export default compose(connect(mapStateToProps))(FileList);
