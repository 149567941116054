import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import withStyles from "@material-ui/core/styles/withStyles";
import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, formValueSelector } from "redux-form";
import { numericality } from "redux-form-validators";
import {
  getReferenceDataOptions,
  getReferenceDataType,
} from "../../../../reducers";
import WrappedRadioGroup from "../../../forms/wrapper/WrappedRadioGroup";
import WrappedSelect from "../../../forms/wrapper/WrappedSelect";
import WrappedTextField from "../../../forms/wrapper/WrappedTextField";
import { getRefDataMenuItems } from "../../../forms/menuItems";
import ContactDetailsFields from "../contacts/ContactDetailsFields";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  dependant: {
    border: `solid 1px ${theme.palette.grey[300]}`,
  },
  dependantForm: {
    marginRight: "20px",
  },
});

const validateBenefitAmount = (value, allValues, props, name) => {
  if (value < 0) {
    return "Benefit amount cannot be a negative number";
  }
  const { dependants } = allValues;
  const index = name.substring(
    name.lastIndexOf("[") + 1,
    name.lastIndexOf("]")
  );
  if (
    dependants &&
    dependants[index] &&
    dependants[index].benefitAmountType === "PERCENTAGE"
  ) {
    const percentageTotal = dependants
      .filter(
        (dependant) => dependant && dependant.benefitAmountType === "PERCENTAGE"
      )
      .map((dependant) =>
        dependant.benefitAmount ? Number(dependant.benefitAmount) : 0
      )
      .reduce((sum, amt) => sum + amt, 0);
    if (percentageTotal > 100) {
      return "Total allocated benefit is greater than 100% please review";
    }
  }

  return undefined;
};

const warnBenefitAmount = (value, allValues, props, name) => {
  const index = name.substring(
    name.lastIndexOf("[") + 1,
    name.lastIndexOf("]")
  );
  const { dependants, memberDetails } = allValues;
  const { fundBalance } = memberDetails || {};
  if (
    dependants &&
    fundBalance &&
    dependants[index] &&
    dependants[index].benefitAmountType === "FIXED"
  ) {
    const fixedTotal = dependants
      .filter(
        (dependant) => dependant && dependant.benefitAmountType === "FIXED"
      )
      .map((dependant) =>
        dependant.benefitAmount ? Number(dependant.benefitAmount) : 0
      )
      .reduce((sum, amt) => sum + amt, 0);
    if (fixedTotal > fundBalance) {
      return "Total allocated benefit exceeds available funds";
    }
  }

  return undefined;
};

const DependantsDetailsFields = ({
  fields,
  fieldLabels,
  classes,
  benefitAmountTypes,
  dependants,
  dependantTypes,
}) => {
  const add = () => {
    fields.push({});
  };

  const remove = (index) => {
    fields.remove(index);
  };

  return (
    <>
      {fields.map((dependant, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <List key={index} className={classes.root}>
          <ListItem className={classes.dependant}>
            <ListItemText className={classes.dependantForm}>
              <ContactDetailsFields
                name={`${dependant}.contact`}
                contactLabels={
                  fieldLabels.nestedTypes.dependants.nestedTypes.contact.labels
                }
              />
              <Field
                name={`${dependant}.type`}
                component={WrappedSelect}
                label={fieldLabels.nestedTypes.dependants.labels.type}
                fullWidth
              >
                {getRefDataMenuItems(dependantTypes)}
              </Field>
              <Field
                name={`${dependant}.relationship`}
                component={WrappedTextField}
                label={fieldLabels.nestedTypes.dependants.labels.relationship}
                fullWidth
                helperText="How is this person related to the member"
              />
              <Field
                name={`${dependant}.benefitAmountType`}
                component={WrappedRadioGroup}
                row
                label={
                  fieldLabels.nestedTypes.dependants.labels.benefitAmountType
                }
                options={benefitAmountTypes}
              />
              <br />
              {dependants[index].benefitAmountType === "FIXED" && (
                <Field
                  component={WrappedTextField}
                  name={`${dependant}.benefitAmount`}
                  label={
                    fieldLabels.nestedTypes.dependants.labels.benefitAmount
                  }
                  validate={[
                    numericality({
                      ">=": 0,
                      allowBlank: true,
                    }),
                    validateBenefitAmount,
                  ]}
                  warn={warnBenefitAmount}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              )}
              {dependants[index].benefitAmountType === "PERCENTAGE" && (
                <Field
                  component={WrappedTextField}
                  name={`${dependant}.benefitAmount`}
                  label={
                    fieldLabels.nestedTypes.dependants.labels.benefitAmount
                  }
                  validate={[
                    numericality({
                      ">=": 0,
                      allowBlank: true,
                    }),
                    validateBenefitAmount,
                  ]}
                  warn={warnBenefitAmount}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              )}
              <Field
                name={`${dependant}.notes`}
                component={WrappedTextField}
                label={fieldLabels.nestedTypes.dependants.labels.notes}
                fullWidth
                multiline
                rowsMax={6}
                rows={1}
                helperText="Any specials conditions relating to the distribution of the benefit to this dependant"
              />
            </ListItemText>
            <ListItemSecondaryAction>
              <IconButton
                aria-label="Remove"
                onClick={() => remove(index)}
                title="Remove"
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      ))}
      <Button size="small" color="primary" aria-label="Add" onClick={add}>
        Add dependant
      </Button>
    </>
  );
};

DependantsDetailsFields.propTypes = {
  classes: PropTypes.object.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  dependantTypes: PropTypes.object.isRequired,
  benefitAmountTypes: PropTypes.array.isRequired,
  dependants: PropTypes.array.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const reduxFormSelector = formValueSelector(ownProps.formName);
  return {
    benefitAmountTypes: getReferenceDataOptions(state, "BenefitAmountType"),
    dependantTypes: getReferenceDataType(state, "DependantType"),
    dependants: reduxFormSelector(state, "dependants") || [],
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(DependantsDetailsFields);
