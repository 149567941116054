import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";
import { required } from "redux-form-validators";
import { useAuth0 } from "@auth0/auth0-react";
import { getLoggedInUser, getReferenceDataType } from "../../../reducers";
import usersApi from "../../../services/api/users";
import { roles } from "@certane/arcadia-web-components";
import HelpPopover from "../../common/HelpPopover";
import { getRefDataMenuItems } from "../menuItems";
import WrappedOwnerPicker from "../wrapper/WrappedOwnerPicker";
import WrappedSelect from "../wrapper/WrappedSelect";
import WrappedSwitch from "../wrapper/WrappedSwitch";
import WrappedTextField from "../wrapper/WrappedTextField";
import ControlDescriptionField from "./ControlDescriptionField";
import ControlObjectiveField from "./ControlObjectiveField";
import OperationalFrequencyField from "./OperationalFrequencyField";

const useStyles = makeStyles((theme) => ({
  helpPopover: {
    padding: theme.spacing(2),
    maxWidth: theme.breakpoints.values.md,
  },
}));

const ControlDetailFields = ({ controlTypes, fieldLabels, loggedInUser }) => {
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();
  const asyncOwnerOptionsFetch = async (
    pickerFilter,
    pagination,
    abortController
  ) => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = {
      ...pickerFilter,
      limit: pagination.pageSize,
      offset: pagination.offset,
      roles: roles.AGENT_ROLES,
      orderByField: "name",
    };
    return usersApi.search(searchParameters, abortController, accessToken);
  };

  return (
    <>
      <Field
        name="title"
        component={WrappedTextField}
        label={fieldLabels.labels.title}
        validate={required({ msg: `${fieldLabels.labels.title} is required` })}
        fullWidth
        required
      />
      <ControlObjectiveField fieldLabels={fieldLabels} />
      <ControlDescriptionField fieldLabels={fieldLabels} />
      <Field
        name="type"
        component={WrappedSelect}
        label={fieldLabels.labels.type}
        validate={required({ msg: `${fieldLabels.labels.type} is required` })}
        fullWidth
        required
        helperText={
          <>
            <span>Get help with </span>
            <HelpPopover linkText={fieldLabels.labels.type.toLowerCase()}>
              <div className={classes.helpPopover}>
                <Typography variant="h5" gutterBottom>
                  {fieldLabels.labels.type}
                </Typography>
                <Typography variant="body1" component="div">
                  A control is an activity, device, documentation that is
                  designed to prevent, detect or reduce the likelihood of risk
                  materializing or to reduce the impact of the risk, if it
                  materializes. There are three types of controls:
                  <ul>
                    <li>
                      Preventative controls are controls that are designed to
                      avert a negative event from occurring. Preventative
                      controls are proactive by design and help prevent loss.
                    </li>
                    <li>
                      Detective controls are designed to identify positive or
                      negative events once its occurred. Detective controls
                      provide evidence that a loss has occurred but do not
                      prevent a loss from occurring.
                    </li>
                    <li>
                      Corrective controls are functional after detective
                      controls have discovered a problem.
                    </li>
                  </ul>
                </Typography>
              </div>
            </HelpPopover>
          </>
        }
      >
        {getRefDataMenuItems(controlTypes)}
      </Field>
      <Field
        name="critical"
        label={fieldLabels.labels.critical}
        component={WrappedSwitch}
        validate={required({
          msg: `${fieldLabels.labels.critical} is required`,
        })}
        required
      />
      <Field
        name="owner"
        component={WrappedOwnerPicker}
        datasource={asyncOwnerOptionsFetch}
        required
        validate={required({ msg: `${fieldLabels.labels.owner} is required` })}
        label={fieldLabels.labels.owner}
        fullWidth
        loggedInUser={loggedInUser}
      />
      <OperationalFrequencyField fieldLabels={fieldLabels} />
    </>
  );
};

ControlDetailFields.propTypes = {
  loggedInUser: PropTypes.object.isRequired,
  fieldLabels: PropTypes.object.isRequired,

  // redux
  controlTypes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
  controlTypes: getReferenceDataType(state, "RiskControlType"),
});

export default connect(mapStateToProps, {})(ControlDetailFields);
