import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import Container from "../../components/common/Container";
import HeaderBar from "../../components/common/HeaderBar";
import PageHeading from "../../components/common/PageHeading";
import DraftWorkItemListing from "../../components/draft/DraftWorkItemListing";
import { getLoggedInUser } from "../../reducers";
import { draftIcon } from "../../util/icons";
import usePersistedState from "../../util/persistedState";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  contents: {
    marginBottom: "75px",
  },
}));

const DraftWorkItemsListingPage = ({ history, loggedInUser }) => {
  const classes = useStyles();

  const [sortBy, setSortBy] = usePersistedState(
    `work-item-drafts-sort-${loggedInUser.email}`,
    {
      field: "created",
      direction: "asc",
    }
  );

  return (
    <div className={classes.root}>
      <HeaderBar>
        <PageHeading
          icon={draftIcon()}
          heading="Draft work items"
          data-cy="draftWorkItems"
        />
      </HeaderBar>
      <Container className={classes.contents}>
        <DraftWorkItemListing
          history={history}
          sortBy={sortBy}
          setSortBy={setSortBy}
        />
      </Container>
    </div>
  );
};

DraftWorkItemsListingPage.propTypes = {
  history: PropTypes.object.isRequired,
  loggedInUser: PropTypes.object,
};

DraftWorkItemsListingPage.defaultProps = {
  loggedInUser: null,
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
});

export default connect(mapStateToProps)(DraftWorkItemsListingPage);
