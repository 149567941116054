import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { createUserGroup, patchUserGroup } from "../../../actions/userGroups";
import ActionButton from "../../../components/common/ActionButton";
import ActionHeading from "../../../components/common/ActionHeading";
import Container from "../../../components/common/Container";
import FloatingActionButton from "../../../components/common/FloatingActionButton";
import HeaderBar from "../../../components/common/HeaderBar";
import PageHeading from "../../../components/common/PageHeading";
import ExpandableForm from "../../../components/forms/ExpandableForm";
import UserGroupForm, {
  USERGROUP_FORM_NAME,
} from "../../../components/forms/userGroup/UserGroupForm";
import UserGroupFilters from "../../../components/userGroup/UserGroupFilters";
import UserGroupListing from "../../../components/userGroup/UserGroupListing";
import { getLoggedInUser } from "../../../reducers";
import { clearIcon, userGroupIcon } from "../../../util/icons";
import usePersistedState from "../../../util/persistedState";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  contents: {
    marginBottom: "75px",
  },
}));

const defaultFilter = {
  textSearch: "",
};

const Index = ({
  history,
  loggedInUser,
  localCreateUserGroup,
  localPatchUserGroup,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();
  const [sortBy, setSortBy] = usePersistedState(
    `user-groups-listing-sort-${loggedInUser.email}`,
    {
      field: "name",
      direction: "asc",
    }
  );
  const [filter, setFilter] = usePersistedState(
    `user-groups-listing-filter-${loggedInUser.email}`,
    defaultFilter,
    ["textSearch"]
  );
  const [formOpen, setFormOpen] = useState(false);
  const [selectedUserGroup, setSelectedUserGroup] = useState(null);
  const formRef = useRef();

  const clearFilter = () => setFilter(defaultFilter);

  const updateFilter = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  const openForm = () => {
    setFormOpen(true);
    window.scrollTo(0, formRef.current.offsetTop);
  };

  useEffect(() => {
    if (selectedUserGroup) {
      if (!formOpen) {
        openForm();
      } else {
        // need to close the redux form fully before changing between edit and create
        setFormOpen(false);
        setTimeout(() => {
          openForm();
        }, 0);
      }
    }
  }, [selectedUserGroup]);

  const onSave = async (userGroup) => {
    const accessToken = await getAccessTokenSilently();
    selectedUserGroup
      ? await localPatchUserGroup(
          selectedUserGroup.id,
          userGroup,
          "Updated user group",
          accessToken
        )
      : await localCreateUserGroup(userGroup, accessToken);

    setFormOpen(false);
    setSelectedUserGroup(null);
  };

  const createNew = () => {
    setSelectedUserGroup(null);
    if (formOpen) {
      // need to close the redux form fully before changing between edit and create
      setFormOpen(false);
      setTimeout(() => {
        openForm();
      }, 0);
    } else {
      openForm();
    }
  };

  const cancelForm = () => {
    setSelectedUserGroup(null);
    setFormOpen(false);
  };

  return (
    <div className={classes.root}>
      <HeaderBar>
        <ActionHeading
          heading={<PageHeading icon={userGroupIcon()} heading="User groups" />}
          actions={[
            <ActionButton
              key="clearFilter"
              tooltip="Clear filters"
              icon={clearIcon()}
              onClick={clearFilter}
              data-cy="clear-filters-button"
            />,
          ]}
        />
      </HeaderBar>
      <Container className={classes.contents}>
        {!formOpen && <FloatingActionButton onClick={createNew} />}
        <ExpandableForm
          title={selectedUserGroup ? "Edit user group" : "Create user group"}
          submitButtonText={selectedUserGroup ? "Save" : "Create"}
          formComponent={UserGroupForm}
          formName={USERGROUP_FORM_NAME}
          open={formOpen}
          formRef={formRef}
          onCancel={cancelForm}
          onSubmit={onSave}
          isAltPaper
          initialValues={selectedUserGroup ? { ...selectedUserGroup } : {}}
          isEditing={!!selectedUserGroup}
        />
        <UserGroupFilters
          filter={filter}
          updateFilter={updateFilter}
          fullWidth={false}
        />
        <UserGroupListing
          history={history}
          filter={filter}
          clearFilter={clearFilter}
          sortBy={sortBy}
          updateSort={setSortBy}
          onSelect={(userGroup) => setSelectedUserGroup(userGroup)}
          selected={selectedUserGroup}
        />
      </Container>
    </div>
  );
};

Index.propTypes = {
  history: PropTypes.object.isRequired,

  // redux
  loggedInUser: PropTypes.object,
  localCreateUserGroup: PropTypes.func.isRequired,
  localPatchUserGroup: PropTypes.func.isRequired,
};

Index.defaultProps = {
  loggedInUser: null,
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
});

export default connect(mapStateToProps, {
  localCreateUserGroup: createUserGroup,
  localPatchUserGroup: patchUserGroup,
})(Index);
