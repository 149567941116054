import { useAuth0 } from "@auth0/auth0-react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { getReferenceDataType } from "../../../reducers";
import workItemSchedulesApi from "../../../services/api/workItemSchedules";
import dates from "../../../util/dates";
import DateAgo from "../../common/DateAgo";
import GridListing from "../../common/GridListing";

const styles = () => ({});

const limit = 12;

const SchedulePreview = ({
  recurrenceTypes,
  recurrenceType,
  recurrenceUnit,
  startBy,
  stopBy,
  creationLeadTime,
  dueDateOffset,
  reminderLeadTime,
  nameOfFirstPeriod,
  noItemsText,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const lastPeriodUpdate = React.useRef(Date.now());
  const [projectedPeriods, setProjectedPeriods] = useState([]);
  const [loading, setLoading] = useState(false);

  const allValid = (values) => !values.some((value) => !value && value !== 0);

  const refreshData = async () => {
    const timestamp = Date.now();

    setProjectedPeriods([]);
    lastPeriodUpdate.current = timestamp;
    setLoading(true);

    if (
      allValid([
        recurrenceType,
        recurrenceUnit,
        startBy,
        creationLeadTime,
        dueDateOffset,
        reminderLeadTime,
      ])
    ) {
      const params = {
        startBy,
        recurrenceType,
        recurrenceUnit,
        creationLeadTime,
        dueDateOffset,
        reminderLeadTime,
        limit,
      };
      if (stopBy) {
        params.stopBy = stopBy;
      }
      if (nameOfFirstPeriod) {
        params.nameOfFirstPeriod = nameOfFirstPeriod;
      }
      const accessToken = await getAccessTokenSilently();
      workItemSchedulesApi
        .projectPeriods(params, accessToken)
        .then((response) => {
          if (lastPeriodUpdate.current === timestamp) {
            setProjectedPeriods(response);
          }
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    refreshData();
  }, [
    recurrenceType,
    recurrenceUnit,
    startBy,
    stopBy,
    creationLeadTime,
    dueDateOffset,
    reminderLeadTime,
    nameOfFirstPeriod,
  ]);

  const recurrenceTypeDef =
    recurrenceType && recurrenceTypes.values[recurrenceType];

  let showMorePeriodsMessage = !stopBy;

  if (stopBy && projectedPeriods.length === limit) {
    const lastPeriod = projectedPeriods[limit - 1];
    const realStopBy = moment(stopBy)
      .add(1, "days")
      .startOf("month")
      .subtract(1, "day")
      .toDate();

    if (!loading && dates.parseDate(lastPeriod.end) < realStopBy) {
      showMorePeriodsMessage = true;
    }
  }

  return (
    <>
      <GridListing
        sortedData={projectedPeriods}
        loading={loading}
        sortBy={{}}
        dense
        updateSort={() => {
          // do nothing.
        }}
        noItemsText={
          noItemsText ||
          "Populate the schedule fields to view preview of schedule dates"
        }
        highlightOnHover={false}
        columns={[
          {
            label: recurrenceTypeDef.description,
            name: "name",
            size: 3,
            sortable: false,
            render: (period) => {
              const indexText = period.indexCode
                ? ` (${period.indexCode})`
                : "";
              return <span>{`${period.name}${indexText}`}</span>;
            },
          },
          {
            label: "Creation date",
            name: "creationDate",
            size: 3,
            sortable: false,
            render: (period) => (
              <DateAgo
                value={dates.parseDate(period.creationDate)}
                expandable
                startFull
              />
            ),
          },
          {
            label: "Reminder date",
            name: "reminderDate",
            size: 3,
            sortable: false,
            render: (period) => (
              <DateAgo
                value={dates.parseDate(period.reminderDate)}
                expandable
                startFull
              />
            ),
          },
          {
            label: "Due date",
            name: "dueDate",
            size: 3,
            sortable: false,
            render: (period) => (
              <DateAgo
                value={dates.parseDate(period.dueDate)}
                expandable
                startFull
              />
            ),
          },
        ]}
      />
      {!loading && showMorePeriodsMessage && (
        <Typography variant="caption">
          * Only showing the first {limit} occurrences
        </Typography>
      )}
    </>
  );
};

SchedulePreview.propTypes = {
  recurrenceTypes: PropTypes.object.isRequired,
  recurrenceType: PropTypes.string,
  recurrenceUnit: PropTypes.number,
  nameOfFirstPeriod: PropTypes.string,
  startBy: PropTypes.string,
  stopBy: PropTypes.string,
  creationLeadTime: PropTypes.number,
  dueDateOffset: PropTypes.number,
  reminderLeadTime: PropTypes.number,
  noItemsText: PropTypes.string,
};

SchedulePreview.defaultProps = {
  recurrenceType: null,
  recurrenceUnit: null,
  nameOfFirstPeriod: null,
  stopBy: null,
  startBy: null,
  creationLeadTime: null,
  dueDateOffset: null,
  reminderLeadTime: null,
  noItemsText: null,
};

const mapStateToProps = (state) => ({
  recurrenceTypes: getReferenceDataType(state, "RecurrenceType"),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(SchedulePreview);
