import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import * as PropTypes from "prop-types";
import React from "react";
import TextField from "@material-ui/core/TextField";

const NULL_VALUE = "--";

function getValue(value) {
  return value === NULL_VALUE ? null : value;
}

function toInputValue(value, displayNullLabelAsPlaceholder) {
  return value || (displayNullLabelAsPlaceholder ? NULL_VALUE : value);
}

const styles = (theme) => ({
  nullOption: {
    color: theme.palette.grey[600],
    fontStyle: "italic",
  },
});

const WrappedSelect = ({
  input,
  label,
  nullLabel,
  displayNullLabelAsPlaceholder,
  helperText,
  children,
  required,
  classes,
  margin,
  meta: { touched, error, invalid },
  ...custom
}) => (
  <TextField
    InputLabelProps={{ name: `position-${input.name}` }}
    select
    label={label}
    error={touched && invalid}
    helperText={(touched && error) || helperText}
    required={required}
    value={toInputValue(input.value, displayNullLabelAsPlaceholder)}
    onChange={(event) => input.onChange(getValue(event.target.value))}
    margin={margin}
    {...custom}
    data-cy={label}
  >
    {!required && (
      <MenuItem value={NULL_VALUE} className={classes.nullOption}>
        {nullLabel}
      </MenuItem>
    )}
    {children}
  </TextField>
);

WrappedSelect.propTypes = {
  input: PropTypes.object.isRequired,
  nullLabel: PropTypes.string,
  displayNullLabelAsPlaceholder: PropTypes.bool,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.any,
  children: PropTypes.any.isRequired,
  classes: PropTypes.object.isRequired,
  required: PropTypes.bool,
  margin: PropTypes.string,
};

WrappedSelect.defaultProps = {
  helperText: "",
  nullLabel: "Select an option",
  displayNullLabelAsPlaceholder: false,
  required: false,
  margin: "dense",
};

export default withStyles(styles)(WrappedSelect);
