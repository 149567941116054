import React from "react";
import PropTypes from "prop-types";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItem from "@material-ui/core/ListItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(() => ({
  checkbox: {
    padding: 0,
  },
}));

const WorkItemFileMenuItems = React.forwardRef(
  ({ onClose, flagOptions, options }) => {
    const classes = useStyles();

    const selectOption = (opts, optionId) => {
      opts.forEach((option) => {
        if (option.id === optionId && option.handleSelect) {
          option.handleSelect();
        }
      });

      onClose();
    };

    if (flagOptions?.length === 0 && options?.length === 0) return null;

    return (
      <>
        {flagOptions?.length > 0 && (
          <ListSubheader disableSticky>Flag file as</ListSubheader>
        )}
        {flagOptions &&
          flagOptions.map((option) => (
            <ListItem
              key={option.id}
              value={option.id}
              onClick={() => selectOption(flagOptions, option.id)}
              button
            >
              <Checkbox
                className={classes.checkbox}
                checked={option.flagged}
                tabIndex={-1}
              />
              <ListItemText primary={option.label} data-cy={option.label} />
            </ListItem>
          ))}

        {options?.length > 0 && flagOptions?.length > 0 && <Divider />}

        {options &&
          options.map((option) => (
            <ListItem
              key={option.id}
              button
              onClick={() => selectOption(options, option.id)}
            >
              <ListItemText primary={option.label} />
            </ListItem>
          ))}
      </>
    );
  }
);

WorkItemFileMenuItems.propTypes = {
  onClose: PropTypes.func.isRequired,
  flagOptions: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
};

WorkItemFileMenuItems.defaultProps = {};

export default WorkItemFileMenuItems;
