import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { connect } from "react-redux";
import { searchMedia } from "../../actions/media";
import {
  getLabels,
  getMedia,
  getMediaLastEdit,
  getMediaPagination,
  isLoading,
} from "../../reducers";
import { publishToastInfo } from "../../services/toasts";
import dates from "../../util/dates";
import { copyToClipboardIcon } from "../../util/icons";
import GridListing from "../common/GridListing";
import MultilineText from "../common/MultilineText";
import TimeAgo from "../common/TimeAgo";
import Pagination from "../common/Pagination";
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles(() => ({
  wrapText: {
    overflow: "hidden",
    overflowWrap: "break-word",
    whiteSpace: "wrap",
  },
}));

const MediaListing = ({
  pagination,
  lastEdit,
  media,
  sortBy,
  filter,
  clearFilter,
  displayEntities,
  loading,
  localSearchMedia,
  updateSort,
  onClick,
  fieldLabels,
}) => {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();

  const getOrderBy = () => {
    const prefix = sortBy.direction === "desc" ? "-" : "";
    return `${prefix}${sortBy.field}`;
  };

  const getSearchParameters = () => ({
    ...filter,
    orderByField: getOrderBy(),
    limit: pagination.pageSize,
    offset: pagination.offset,
  });

  useEffect(() => {
    const fetch = async () => {
      const searchParameters = {
        ...getSearchParameters(),
        offset: 0,
      };
      const accessToken = await getAccessTokenSilently();
      localSearchMedia(searchParameters, accessToken);
    };
    fetch();
  }, [filter, sortBy, lastEdit]);

  const handlePrevious = async () => {
    const searchParameters = {
      ...getSearchParameters(),
      offset: pagination.previousOffset,
    };
    const accessToken = await getAccessTokenSilently();
    localSearchMedia(searchParameters, accessToken);
  };

  const handleNext = async () => {
    const searchParameters = {
      ...getSearchParameters(),
      offset: pagination.nextOffset,
    };
    const accessToken = await getAccessTokenSilently();
    localSearchMedia(searchParameters, accessToken);
  };

  const orderOrganisations = (entityRelationship) => {
    const relationshipOrder = [
      "TRUSTEE",
      "RESPONSIBLE_ENTITY",
      "PROMOTER",
      "SUB_PROMOTER",
    ];
    const productRelationships = _.flatMap(
      entityRelationship.financialProducts,
      (product) => product.relationships
    );

    const organisations = entityRelationship.organisations.map(
      (linkedOrganisation) => {
        const rTypes = {};
        productRelationships
          .filter(
            (rel) => rel.organisation.id === linkedOrganisation.organisation.id
          )
          .forEach((rel) => {
            rTypes[rel.relationshipType] = true;
          });
        const orgRelationships = Object.keys(rTypes);
        const relationshipOrderKey = Math.min(
          ...orgRelationships.map((r) =>
            relationshipOrder.indexOf(r) === -1
              ? 1000
              : relationshipOrder.indexOf(r)
          )
        );
        return {
          ...linkedOrganisation.organisation,
          relationshipOrderKey,
        };
      }
    );

    return _.orderBy(
      organisations,
      ["type", "relationshipOrderKey", "name"],
      ["desc", "asc", "asc"]
    );
  };

  const andXMore = (list, field) => {
    const count = list.length;
    if (count === 0) {
      return "-";
    }
    return (
      <>
        <Typography
          title={list[0][field]}
          className={classes.wrapText}
          data-cy={list[0][field]}
        >
          {list[0][field]}
        </Typography>
        {count > 1 && (
          <Tooltip
            title={
              <MultilineText
                text={list
                  .slice(1)
                  .map((v) => v[field])
                  .join(",\n")}
              />
            }
            disableFocusListener
          >
            <Typography style={{ color: "#778088" }} variant="caption">
              (and {count - 1} more)
            </Typography>
          </Tooltip>
        )}
      </>
    );
  };

  const onCopy = () => {
    publishToastInfo("Copied to clipboard");
  };

  const activeVersion = (m) =>
    m.documentVersions.find((v) => v.status === "ACTIVE");
  const CopyIcon = copyToClipboardIcon();

  return (
    <>
      <GridListing
        sortedData={media}
        loading={loading}
        sortBy={sortBy}
        dense={false}
        updateSort={(field, direction) =>
          updateSort({
            field,
            direction,
          })
        }
        clearFilter={clearFilter}
        onClick={onClick}
        action={(m) => (
          <Tooltip title="Copy serving url" disableFocusListener>
            <CopyToClipboard text={m.publicUrl} onCopy={onCopy}>
              <IconButton size="small" data-cy="copyClipboard">
                <CopyIcon />
              </IconButton>
            </CopyToClipboard>
          </Tooltip>
        )}
        columns={[
          {
            label: fieldLabels.labels.category,
            name: "categoryText",
            size: 2,
            sortable: true,
            render: (m) => (
              <Typography
                className={classes.wrapText}
                data-cy={m.category?.name}
              >
                {m.category?.name}
              </Typography>
            ),
          },
          {
            label: fieldLabels.labels.name,
            name: "name",
            size: 2,
            sortable: true,
            render: (m) => (
              <Typography className={classes.wrapText} data-cy={m.name}>
                {m.name}
              </Typography>
            ),
          },
          {
            label:
              fieldLabels.nestedTypes.entityRelationship.labels.organisations,
            name: "entity",
            size: 2,
            sortable: false,
            hide: !displayEntities,
            render: (m) =>
              andXMore(orderOrganisations(m.entityRelationship), "name"),
          },
          {
            label: fieldLabels.nestedTypes.entityRelationship.labels.funds,
            name: "firstFundName",
            size: 2,
            sortable: true,
            render: (m) =>
              andXMore(_.orderBy(m.entityRelationship.funds, "name"), "name"),
          },
          {
            label:
              fieldLabels.nestedTypes.entityRelationship.labels
                .financialProducts,
            name: "firstProductName",
            size: 2,
            sortable: true,
            render: (m) =>
              andXMore(
                _.orderBy(m.entityRelationship.financialProducts, "name"),
                "name"
              ),
          },
          {
            label: fieldLabels.nestedTypes.documentVersions.labels.activeFrom,
            name: "activeFrom",
            size: 2,
            sortable: true,
            render: (m) => {
              const active = activeVersion(m);
              return active && active.activeFrom ? (
                <TimeAgo
                  value={dates.parseTimestamp(active.activeFrom)}
                  expandable
                  startFull
                />
              ) : (
                "-"
              );
            },
          },
          {
            label:
              fieldLabels.nestedTypes.documentVersions.labels.effectiveFrom,
            name: "effectiveFrom",
            size: 1,
            sortable: true,
            render: (m) => {
              const active = activeVersion(m);
              return active && active.effectiveFrom
                ? dates.formatDate(active.effectiveFrom, "D/M/YYYY")
                : "-";
            },
          },
        ]}
      />
      <Pagination
        pagination={pagination}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
      />
    </>
  );
};

MediaListing.propTypes = {
  filter: PropTypes.object.isRequired,
  sortBy: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired,
  updateSort: PropTypes.func.isRequired,
  displayEntities: PropTypes.bool.isRequired,

  // redux
  media: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  lastEdit: PropTypes.number.isRequired,
  localSearchMedia: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  fieldLabels: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  loading: isLoading(state),
  media: getMedia(state),
  pagination: getMediaPagination(state),
  lastEdit: getMediaLastEdit(state),
  fieldLabels: getLabels(state).Media,
});

export default connect(mapStateToProps, {
  localSearchMedia: searchMedia,
})(MediaListing);
