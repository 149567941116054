import { useEffect, useRef, useState } from "react";
import savedSearchesApi from "../../services/api/savedSearches";
import searchFavouritesApi from "../../services/api/searchFavourites";
import logger from "../../util/logger";

const getViewFromHash = () => {
  const h = window.location.hash;
  return h ? h.substring(1) : null;
};
const setViewOnHash = (viewId) => {
  const currentViewId = getViewFromHash();
  if (currentViewId !== viewId) {
    window.location.hash = `#${viewId}`;
  }
};
const clearViewOnHash = () => {
  history.pushState(
    "",
    document.title,
    window.location.pathname + window.location.search
  );
};

export const useSavedSearches = (loggedInUser, type, getAccessToken) => {
  const [searchFavourites, setSearchFavourites] = useState([]);
  const [savedSearches, setSavedSearches] = useState([]);
  const [selectedSearchId, setSelectedSearchId] = useState(null);
  const [loading, setLoading] = useState(true);
  const savedSearchUrlId = useRef(null);

  const updateSearches = async () => {
    setLoading(true);
    const accessToken = await getAccessToken();
    const searches = await savedSearchesApi.search(type, 0, 100, accessToken);
    setLoading(false);
    setSavedSearches(searches);
    return searches;
  };

  const updateFavourites = async () => {
    const accessToken = await getAccessToken();
    const favourites = await searchFavouritesApi.search(accessToken);
    setSearchFavourites(favourites);
    return favourites;
  };

  useEffect(() => {
    updateSearches().then(() => {
      if (savedSearchUrlId.current) {
        setSelectedSearchId(savedSearchUrlId.current);
      }
    });
    updateFavourites();
  }, []);

  useEffect(() => {
    const viewId = getViewFromHash();
    logger.debug("Got url view param", viewId);
    savedSearchUrlId.current = viewId;
  }, [window.location.hash]);

  useEffect(() => {
    if (selectedSearchId) {
      logger.debug("Setting url view param to ", selectedSearchId);
      setViewOnHash(selectedSearchId);
    } else if (!loading) {
      logger.debug("Removing url view param");
      clearViewOnHash();
    }
  }, [selectedSearchId]);

  const createSavedSearch = async (savedSearch) => {
    const accessToken = await getAccessToken();
    const created = await savedSearchesApi.create(savedSearch, accessToken);
    setSavedSearches([created, ...savedSearches]);
    setSelectedSearchId(created.id);
    await updateSearches();
  };

  const updateSavedSearch = async (id, savedSearch) => {
    const accessToken = await getAccessToken();
    const updated = await savedSearchesApi.patch(
      id,
      savedSearch,
      "Updated",
      accessToken
    );
    setSavedSearches(
      savedSearches.map((s) => {
        if (s.id === id) {
          return updated;
        }
        return s;
      })
    );
    setSelectedSearchId(id);
  };

  const deleteSavedSearch = async (id) => {
    const accessToken = await getAccessToken();
    await savedSearchesApi.remove(id, accessToken);
    if (selectedSearchId === id) {
      setSelectedSearchId(null);
    }
    setSavedSearches(savedSearches.filter((s) => s.id !== id));
  };

  const setSavedSearch = (savedSearch) => {
    setSelectedSearchId(savedSearch ? savedSearch.id : null);
  };

  const addFavourite = async (id) => {
    const accessToken = await getAccessToken();
    const favourites = await searchFavouritesApi.add(id, accessToken);
    setSearchFavourites(favourites);
    return favourites;
  };

  const deleteFavourite = async (id) => {
    const accessToken = await getAccessToken();
    const favourites = await searchFavouritesApi.remove(id, accessToken);
    setSearchFavourites(favourites);
    return favourites;
  };

  return [
    selectedSearchId
      ? savedSearches.find((s) => s.id === selectedSearchId)
      : null,
    setSavedSearch,
    savedSearches,
    createSavedSearch,
    updateSavedSearch,
    deleteSavedSearch,
    searchFavourites,
    addFavourite,
    deleteFavourite,
  ];
};
