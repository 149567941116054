import { Base64 } from "js-base64";
import { requestJSON } from "./http";

const getUploadUrl = (dto, accessToken) =>
  requestJSON({
    path: "/documents/uploadUrl",
    method: "POST",
    data: dto,
    accessToken,
  });

const fileDownloadPageUrl = (fileDownloadMeta) =>
  `/file-download/${Base64.encodeURI(JSON.stringify(fileDownloadMeta))}`;

export default {
  getUploadUrl,
  fileDownloadPageUrl,
};
