import { useAuth0 } from "@auth0/auth0-react";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import {
  deleteDocument,
  saveDocuments,
} from "../../../../../actions/workItems";
import { getLoggedInUser, getReferenceDataType } from "../../../../../reducers";
import api from "../../../../../services/api";
import {
  appliesByRoles,
  appliesByTemplate,
  getResolvedDefinition,
} from "../../../../../util/workItemTypeUtils";
import AlertDialog from "../../../../common/AlertDialog";
import CardSection from "../../../../common/CardSection";
import FlaggedFileUpload from "./FlaggedFileUpload";

const FLAG_THIRD_PARTY_AUTHORITY = "THIRD_PARTY_AUTHORITY";

const onFileClick = (source, document, workItemId, accessToken) => {
  api.workItems
    .documentDownloadUrl(workItemId, source, document.id, false, accessToken)
    .then((location) => window.open(location, "_blank"));
};

const ThirdPartyAuthorityCard = ({
  workItem,
  onChange,
  workItemTypes,
  loggedInUser,
  localSaveDocuments,
  localDeleteDocument,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [deleteFile, setDeleteFile] = useState(null);
  const [uploadNamespace] = useState(
    `${FLAG_THIRD_PARTY_AUTHORITY}-workitem-self`
  );
  const definition = getResolvedDefinition(
    workItemTypes,
    workItem.type,
    workItem.parent?.type
  );

  const { documents } = workItem;

  const executeFileDelete = async () => {
    const accessToken = await getAccessTokenSilently();
    localDeleteDocument(workItem.id, "DEFAULT", deleteFile.id, accessToken);
    setDeleteFile(null);
  };

  const canDeleteFiles = () => {
    const deleteRules = definition.fileDeleteRules;
    return (
      appliesByTemplate(workItem, deleteRules) &&
      appliesByRoles(loggedInUser, deleteRules)
    );
  };

  const saveDocuments = async (docs) => {
    const accessToken = await getAccessTokenSilently();
    localSaveDocuments(workItem.id, docs, accessToken);
  };

  const handleFileClick = async (document) => {
    const accessToken = await getAccessTokenSilently();
    onFileClick("DEFAULT", document, workItem.id, accessToken);
  };

  return (
    <>
      <AlertDialog
        title="Delete file?"
        body={`You are about to permanently delete file ${
          deleteFile && deleteFile.fileName
        }`}
        submitButtonText="Delete"
        open={!!deleteFile}
        onCancel={() => setDeleteFile(null)}
        onSubmit={() => executeFileDelete()}
        data-cy="deleteFile"
      />
      <CardSection title="Third party authority files" gutterTop>
        <FlaggedFileUpload
          flag={FLAG_THIRD_PARTY_AUTHORITY}
          documents={documents}
          addDocuments={saveDocuments}
          deleteDocument={
            canDeleteFiles() && !!onChange ? (doc) => setDeleteFile(doc) : null
          }
          onDocumentClick={handleFileClick}
          uploadNamespace={uploadNamespace}
        />
      </CardSection>
    </>
  );
};

ThirdPartyAuthorityCard.propTypes = {
  className: PropTypes.string,
  fieldLabels: PropTypes.object.isRequired,
  workItem: PropTypes.object.isRequired,
  onChange: PropTypes.func,

  loggedInUser: PropTypes.object,
  workItemTypes: PropTypes.object.isRequired,

  localSaveDocuments: PropTypes.func.isRequired,
  localDeleteDocument: PropTypes.func.isRequired,
};

ThirdPartyAuthorityCard.defaultProps = {
  className: undefined,
  onChange: null,
  loggedInUser: null,
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
  workItemTypes: getReferenceDataType(state, "WorkItemType"),
});

export default connect(mapStateToProps, {
  localSaveDocuments: saveDocuments,
  localDeleteDocument: deleteDocument,
})(ThirdPartyAuthorityCard);
