import PropTypes from "prop-types";
import React from "react";
import { FormattedDate } from "react-intl";
import { connect } from "react-redux";
import formatters from "../../../../util/formatters";
import ComponentScorecard from "../../../common/ComponentScorecard";
import FormCard from "../../../common/FormCard";
import EditWorkItemComponentForm, {
  EDIT_WORK_ITEM_COMPONENT_FORM_NAME,
} from "../EditWorkItemComponentForm";

const ClaimsCard = ({
  className,
  fieldLabels,
  workItem,
  onChange,
  customisations,
}) => {
  const { claimDetails } = workItem;

  return (
    <FormCard
      data-cy={fieldLabels.labels.claimDetails}
      className={className}
      title={fieldLabels.labels.claimDetails}
      formTitle={`Edit ${fieldLabels.labels.claimDetails}`}
      readOnlyView={() => (
        <>
          <ComponentScorecard
            fieldLabels={fieldLabels}
            name="claimDetails.dateReceived"
            customisations={customisations}
            entity={workItem}
            render={(value) => (value ? <FormattedDate value={value} /> : "-")}
          />
          <ComponentScorecard
            fieldLabels={fieldLabels}
            name="claimDetails.dateOfClaim"
            customisations={customisations}
            entity={workItem}
            render={(value) => (value ? <FormattedDate value={value} /> : "-")}
          />
          <ComponentScorecard
            fieldLabels={fieldLabels}
            name="claimDetails.startDateOfClaim"
            customisations={customisations}
            entity={workItem}
            render={(value) => (value ? <FormattedDate value={value} /> : "-")}
          />
          <ComponentScorecard
            fieldLabels={fieldLabels}
            name="claimDetails.endDateOfClaim"
            customisations={customisations}
            entity={workItem}
            render={(value) => (value ? <FormattedDate value={value} /> : "-")}
          />
          <ComponentScorecard
            fieldLabels={fieldLabels}
            name="claimDetails.dateOfUnderwriting"
            customisations={customisations}
            entity={workItem}
            render={(value) => (value ? <FormattedDate value={value} /> : "-")}
          />
          <ComponentScorecard
            fieldLabels={fieldLabels}
            name="claimDetails.benefitAmount"
            customisations={customisations}
            entity={workItem}
            render={(value) => (value ? formatters.currency(value) : "-")}
          />
          <ComponentScorecard
            fieldLabels={fieldLabels}
            name="claimDetails.underwritingDeathCover"
            customisations={customisations}
            entity={workItem}
            render={(value) => formatters.displayBoolean(value)}
            defaultDisplay="N/A"
          />
          <ComponentScorecard
            fieldLabels={fieldLabels}
            name="claimDetails.underwritingTpdCover"
            customisations={customisations}
            entity={workItem}
            render={(value) => formatters.displayBoolean(value)}
            defaultDisplay="N/A"
          />
          <ComponentScorecard
            fieldLabels={fieldLabels}
            name="claimDetails.underwritingIpCover"
            customisations={customisations}
            entity={workItem}
            render={(value) => formatters.displayBoolean(value)}
            defaultDisplay="N/A"
          />
        </>
      )}
      formComponent={EditWorkItemComponentForm}
      formName={EDIT_WORK_ITEM_COMPONENT_FORM_NAME}
      onSave={onChange}
      initialValues={{ claimDetails }}
      formProps={{
        maxWidth: "md",
        workItem,
        componentName: "CLAIMS",
      }}
    />
  );
};

ClaimsCard.propTypes = {
  className: PropTypes.string,
  fieldLabels: PropTypes.object.isRequired,
  workItem: PropTypes.object.isRequired,
  customisations: PropTypes.array,
  onChange: PropTypes.func,
};

ClaimsCard.defaultProps = {
  className: undefined,
  customisations: [],
  onChange: null,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(ClaimsCard);
