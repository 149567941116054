import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import PropTypes from "prop-types";
import React from "react";
import UrlCopyField from "./UrlCopyField";

const SnippetLinksCard = ({ className, title, snippet, fieldLabels }) => (
  <Card className={className} elevation={0}>
    <CardHeader title={title} />
    <CardContent>
      <UrlCopyField
        label={fieldLabels.labels.apiEndpoint}
        url={snippet.apiEndpoint}
      />
      <UrlCopyField
        label={fieldLabels.labels.apiContentEndpoint}
        url={snippet.apiContentEndpoint}
      />
    </CardContent>
  </Card>
);

SnippetLinksCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  snippet: PropTypes.object.isRequired,
  fieldLabels: PropTypes.object.isRequired,
};

SnippetLinksCard.defaultProps = {
  className: null,
};

export default SnippetLinksCard;
