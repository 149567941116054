import { Typography } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { includeTenantParam } from "@certane/arcadia-web-components";
import { getReferenceDataType } from "../../../../reducers";
import { getWorkItemLink } from "../../../../routes/routeUtils";
import WorkItemBlob from "../../WorkItemBlob";

const useStyles = makeStyles((theme) => ({
  box: {
    marginTop: theme.spacing(1),
  },
  text: {
    color: theme.palette.swatch.secondary,
  },
  strikethrough: {
    textDecoration: "line-through",
  },
}));

const useAssociation = (entry, workItem) => {
  const [hasAssociation, setHasAssociation] = useState(false);
  const [association, setAssociation] = useState(null);
  const [otherWorkItem, setOtherWorkItem] = useState(null);

  useEffect(() => {
    if (entry.association) {
      setHasAssociation(true);
      setAssociation(entry.association);
      let otherWorkItemRef;
      if (
        entry.association.workItemA &&
        entry.association.workItemA.id === workItem.id
      ) {
        otherWorkItemRef = entry.association.workItemB;
      }
      if (
        entry.association.workItemB &&
        entry.association.workItemB.id === workItem.id
      ) {
        otherWorkItemRef = entry.association.workItemA;
      }
      setOtherWorkItem(otherWorkItemRef);
    }
  }, [entry]);

  return [hasAssociation, association, otherWorkItem];
};

const Association = ({ workItem, entry, associationTypes }) => {
  const classes = useStyles();
  const [hasAssociation, association, otherWorkItem] = useAssociation(
    entry,
    workItem
  );

  const getAssociationTypeDescription = () => {
    if (association.workItemA && association.workItemA.id === workItem.id) {
      return associationTypes.values[association.associationType].description;
    }
    return associationTypes.values[association.associationType].props
      .inverseDescription;
  };

  return (
    <>
      {hasAssociation && (
        <div className={classes.box}>
          <Typography
            variant="subtitle1"
            className={
              entry.type === "DISASSOCIATE" ? classes.strikethrough : null
            }
          >
            {getAssociationTypeDescription()}
          </Typography>
          <Typography variant="body2" className={classes.text} component="div">
            {otherWorkItem ? (
              <>
                <WorkItemBlob
                  type={otherWorkItem.type}
                  description={otherWorkItem.type_display}
                />{" "}
                {otherWorkItem.accessible ? (
                  <Link
                    to={includeTenantParam(getWorkItemLink(otherWorkItem))}
                    target="_blank"
                  >
                    {otherWorkItem.title}
                  </Link>
                ) : (
                  otherWorkItem.title
                )}
              </>
            ) : (
              <span className={classes.strikethrough}>
                Work item was removed
              </span>
            )}
          </Typography>
        </div>
      )}
    </>
  );
};

Association.propTypes = {
  entry: PropTypes.object.isRequired,
  workItem: PropTypes.object.isRequired,
  associationTypes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  associationTypes: getReferenceDataType(state, "AssociationType"),
});

export default connect(mapStateToProps)(Association);
