import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import dates from "../../../util/dates";
import { workItemsBase } from "../../../routes/routeUtils";
import TimeAgo from "../TimeAgo";

const styles = (theme) => ({
  link: {
    color: theme.palette.common.white,
    cursor: "pointer",
    textDecoration: "none",
    fontWeight: "bolder",
    fontSize: "larger",
  },
  parentLink: {
    marginTop: theme.spacing(2),
  },
});

class DefaultWorkItemTooltip extends Component {
  render() {
    const { classes, dataToRender } = this.props;

    const { workItem, latestNote, linkBase, parent } = dataToRender;

    return (
      <>
        {latestNote && (
          <>
            <Typography variant="body2" gutterBottom>
              {latestNote.text}
            </Typography>
            <Typography variant="caption">{latestNote.user}</Typography>
            <Typography variant="caption" gutterBottom>
              <TimeAgo
                value={dates.parseTimestamp(latestNote.created)}
                expandable
              />
            </Typography>
            <Link className={classes.link} to={`${linkBase}/${workItem.id}`}>
              more
            </Link>
          </>
        )}
        {parent && (
          <>
            <Typography
              className={latestNote ? classes.parentLink : null}
              variant="caption"
            >
              {parent.type_display}{" "}
            </Typography>
            <Link className={classes.link} to={`${linkBase}/${parent.id}`}>
              {parent.title}
            </Link>
          </>
        )}
      </>
    );
  }
}

DefaultWorkItemTooltip.propTypes = {
  classes: PropTypes.object.isRequired,
  dataToRender: PropTypes.object.isRequired,
};

export default withStyles(styles)(DefaultWorkItemTooltip);

export const getDataToRender = (workItem) => {
  const latestNote = _.get(workItem, "internalNotes", [])[0];
  return latestNote || workItem.hasParent
    ? {
        workItem,
        latestNote,
        linkBase: workItemsBase(),
        parent: workItem.parent,
      }
    : null;
};
