import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import useTheme from "@material-ui/core/styles/useTheme";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useState } from "react";
import { User } from "../../../model/User";

const useStyles = makeStyles((theme) => ({
  chipContainer: {
    "& > div": {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
}));

interface Props {
  open: boolean;
  onClose: () => void;
  onBack?: () => void;
  onSubmit: (reason: string) => void;
  users: User[];
}

const WorkItemUserReasonDialog: React.FC<Props> = ({
  open,
  onClose,
  onBack,
  onSubmit,
  users,
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [reason, setReason] = useState("");

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
      onEnter={() => {
        setReason("");
      }}
      aria-labelledby="WorkItemUserReasonDialog"
    >
      <DialogTitle id="WorkItemUserReasonDialog">
        Provide a reason for updating assignee
      </DialogTitle>
      <DialogContent>
        {users && (
          <div>
            <Grid container spacing={2} direction="column">
              <Grid item>
                <Typography display="block" variant="subtitle1">
                  Selected users ({users.length})
                </Typography>
                <div className={classes.chipContainer}>
                  {users.map((user) => (
                    <Chip
                      key={user.id}
                      avatar={
                        <Avatar color={"primary"}>
                          {user.name?.charAt(0)}
                        </Avatar>
                      }
                      label={user.name}
                    />
                  ))}
                </div>
              </Grid>
              <Grid item>
                <Typography display="block" variant="subtitle1">
                  Reason for assignment
                </Typography>
                <TextField
                  value={reason}
                  placeholder="Enter reason here"
                  onChange={(event) => setReason(event.target.value)}
                  fullWidth
                />
              </Grid>
            </Grid>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        {onBack && <Button onClick={onBack}>Back</Button>}
        <Button color="primary" onClick={() => onSubmit(reason)}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WorkItemUserReasonDialog;
