import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import { intersection } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getLoggedInUser, getReferenceDataType } from "../../reducers";
import { getWorkItemLink } from "../../routes/routeUtils";
import OpenCloseWorkItemFilter from "../workitem/OpenCloseWorkItemFilter";
import WorkItemListItem from "../workitem/WorkItemListItem";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  content: {
    overflow: "auto",
  },
  controlTypeChip: {
    backgroundColor: theme.palette.grey[300],
    padding: `${theme.spacing(1) / 4}px ${theme.spacing(1)}px`,
    borderRadius: "16px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: "100%",
    whiteSpace: "nowrap",
    marginRight: theme.spacing(1) / 2,
  },
  nowrap: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  createNewLink: {
    cursor: "pointer",
    color: theme.palette.swatch.link,
  },
  inlineIcon: {
    verticalAlign: "middle",
  },
  registerLabel: {
    fontSize: "smaller",
  },
  listItem: {
    width: "100%",
    overflow: "hidden",
  },
  listIcon: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
}));

const ControlVerificationsCard = ({
  control,
  loading,
  controlVerifications,
  history,
  workItemTypes,
  loggedInUser,
}) => {
  const classes = useStyles();
  const [filteredVerifications, setFilteredVerifications] = useState([]);
  const [addVerificationAction, setAddVerificationAction] = useState(null);

  useEffect(() => {
    const verificationType = workItemTypes.ids
      .map((id) => workItemTypes.values[id])
      .filter((type) => type.props.definition.module === "CONTROLS")
      .filter(
        (type) =>
          loggedInUser &&
          intersection(type.props.definition.creatableBy, loggedInUser.roles)
            .length > 0
      )[0];

    const action = verificationType
      ? () => {
          const entityRelationship = {
            ...control.entityRelationship,
          };

          return history.push(`/work-items/new/${verificationType.id}`, {
            initValues: {
              associatedControl: { id: control.id, title: control.title },
              entityRelationship,
            },
          });
        }
      : null;
    // need to wrap state function in anon function to prevent react from executing it immediately
    setAddVerificationAction(() => action);
  }, [loggedInUser, control]);

  return (
    <Card className={classes.root} elevation={0}>
      <CardHeader
        title="Verification tasks"
        data-cy="verificationTasks"
        action={
          !loading &&
          addVerificationAction && (
            <Tooltip title="Add verification" disableFocusListener>
              <div>
                <IconButton
                  onClick={addVerificationAction}
                  data-cy="addVerifiation"
                >
                  <AddIcon />
                </IconButton>
              </div>
            </Tooltip>
          )
        }
      />
      <OpenCloseWorkItemFilter
        workItems={controlVerifications}
        filteredWorkItemsChange={(filtered) =>
          setFilteredVerifications(filtered)
        }
      />
      {filteredVerifications.length > 0 && !loading && (
        <List dense>
          {filteredVerifications.map((verification) => (
            <WorkItemListItem
              data-cy={verification}
              key={verification.id}
              workItem={verification}
              types={workItemTypes}
              linkTo={getWorkItemLink(verification)}
            />
          ))}
        </List>
      )}

      {loading && (
        <CardContent>
          <span data-cy="loading">Loading...</span>
        </CardContent>
      )}

      {filteredVerifications.length === 0 && !loading && (
        <CardContent>
          <em data-cy="noVerification">
            There are no verifications for this control
          </em>
        </CardContent>
      )}
    </Card>
  );
};

ControlVerificationsCard.propTypes = {
  control: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  controlVerifications: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,

  //redux
  loggedInUser: PropTypes.object,
  workItemTypes: PropTypes.object.isRequired,
};

ControlVerificationsCard.defaultProps = {
  loggedInUser: null,
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
  workItemTypes: getReferenceDataType(state, "WorkItemType"),
});

export default connect(mapStateToProps)(ControlVerificationsCard);
