import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Icon from "@mdi/react";
import { mdiHelp } from "@mdi/js";
import useTheme from "@material-ui/core/styles/useTheme";
import Avatar from "../../../common/Avatar.tsx";
import { checkIcon } from "../../../../util/icons";
import InlineInputEditSaveCancel from "../../../common/InlineInputEditSaveCancel";
import QuestionHandler from "../../../../util/questions";

const styles = (theme) => ({
  summary: {
    display: "flex",
    marginLeft: -5,
    flexWrap: "nowrap",
    justify: "flex-start",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "flex-start",
    overflow: "hidden",
    minWidth: "100%",
    maxWidth: "100%",
  },
  questionText: {
    flexGrow: 10,
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  questionTextAnswered: {
    color: theme.palette.text.secondary,
  },
  questionTextCollapse: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  questionTextExpanded: {
    flexGrow: 10,
    marginRight: theme.spacing(2),
    paddingLeft: theme.spacing(1),
  },
  controls: {
    flexGrow: 0,
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    alignItems: "center",
    alignContent: "center",
    maxWidth: "100%",
  },
  helpIcon: {
    minWidth: 18,
  },
  checkIcon: {
    color: theme.palette.secondary.main,
  },
});

const questionHandler = new QuestionHandler();

const QuestionSummary = ({
  classes,
  question,
  expanded,
  readonly,
  onUpdate,
  questionStatus,
}) => {
  const theme = useTheme();
  const CheckIcon = checkIcon();

  return (
    <div className={classes.summary}>
      {questionStatus.hasResponse && (
        <CheckIcon
          className={classes.checkIcon}
          style={{ marginRight: expanded ? "20px" : "5px" }}
        />
      )}

      {questionStatus.needsResponse && (
        <Icon
          size="18px"
          className={classes.helpIcon}
          color={theme.palette.text.secondary}
          path={mdiHelp}
          style={{ marginRight: expanded ? "20px" : "5px" }}
        />
      )}

      {!expanded && (
        <>
          <Typography
            className={classNames({
              [classes.questionText]: true,
              [classes.questionTextAnswered]: questionStatus.hasResponse,
              [classes.questionTextCollapse]: true,
            })}
            variant="subtitle1"
          >
            {question.text || "-"}
          </Typography>
          <div className={classes.controls}>
            {question.responders.map((user) => (
              <Tooltip
                key={user.id}
                title={questionHandler.getUserTooltip(user, question.responses)}
                disableFocusListener
              >
                <div>
                  <Avatar
                    color={questionHandler.getResponseColor(
                      theme,
                      user,
                      question.responses
                    )}
                    email={user.email}
                    name={user.name}
                    size={24}
                    round
                  />
                </div>
              </Tooltip>
            ))}
          </div>
        </>
      )}
      {expanded && (
        <Typography
          className={classes.questionTextExpanded}
          variant="subtitle1"
        >
          <InlineInputEditSaveCancel
            className={classes.padding}
            value={question.text}
            onChange={(updatedText) => onUpdate({ text: updatedText })}
            fullWidth
            validate={(value) => !!value}
            readonly={readonly}
            multiline
            rowsMax={10}
            rows={1}
          />
        </Typography>
      )}
    </div>
  );
};

QuestionSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  question: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
  readonly: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
  questionStatus: PropTypes.object.isRequired,
};

QuestionSummary.defaultProps = {};

export default withStyles(styles)(QuestionSummary);
