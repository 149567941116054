import { withAuthenticationRequired } from "@auth0/auth0-react";
import { ensureTenantParam } from "@certane/arcadia-web-components";
import React from "react";
import { Route, Switch } from "react-router";
import FileDownloadPage from "../pages/file-download/[fileDownloadMeta]";
import Login from "../pages/login";
import Logout from "../pages/logout";
import UnhandledErrorPage from "../pages/unhandled-error/[errorId]/[status]/[errorMessage]";
import SecuredRoutes from "./SecuredRoutes";
import UnavailablePage from "../pages/unavailable";

/**
 * Define frontend routes.
 */
const Routes: React.FC = () => (
  <Switch>
    <Route exact path="/login">
      <Login />
    </Route>
    <Route exact path="/logout">
      <Logout />
    </Route>
    <Route
      exact
      path="/file-download/:fileDownloadMeta"
      component={FileDownloadPage}
    />
    <Route
      exact
      path="/unhandled-error/:errorId/:status/:errorMessage?"
      component={UnhandledErrorPage}
    />
    <Route exact path="/unavailable" component={UnavailablePage} />
    <Route
      path="*"
      component={withAuthenticationRequired(ensureTenantParam(SecuredRoutes), {
        onRedirecting: () => <div />,
      })}
    />
  </Switch>
);

export default Routes;
