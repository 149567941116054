import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { FieldArray, reduxForm } from "redux-form";
import { getLoggedInUser, getReferenceDataType } from "../../reducers";
import { scrollToFirstInvalidInput } from "../../util/formHelpers";
import { helpIcon } from "../../util/icons";
import {
  appliesByRoles,
  resolveChildTypeDefinition,
} from "../../util/workItemTypeUtils";
import DebugPrefill from "../common/DebugPrefill";
import FormError from "../common/FormError";
import WorkItemChildList from "./children/WorkItemChildList";
import WorkItemComponentFields from "./components/WorkItemComponentFields";
import FormHeader from "./FormHeader";
import ScheduleFields from "./schedule/ScheduleFields";

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
  },
}));

export const CREATE_WORK_ITEM_FORM_NAME = "CREATE_WORK_ITEM_FORM_NAME";

const CreateWorkItemForm = ({
  type,
  loggedInUser,
  error,
  change,
  untouch,
  types,
}) => {
  const classes = useStyles();
  const [template, setTemplate] = useState(false);

  const debugPrefill = () => {
    change("title", "ATO wants to see some information");
    change("priority", "HIGH");
    change("notes", "Lorem ipsum dolor sit amet, consectetur adipiscing elit.");
  };

  const { definition } = types.values[type].props;

  const supportedChildTypeAssignments = definition.childTypes
    .filter((childTypeAssignment) =>
      appliesByRoles(loggedInUser, {
        roles: childTypeAssignment.overrides.creatableBy,
      })
    )
    .map((childTypeAssignment) => ({
      type: childTypeAssignment.type,
      helpText: childTypeAssignment.helpText,
      // mix in the role fields from the parent's 'childTypeAssignment'
      definition: resolveChildTypeDefinition(childTypeAssignment, types),
    }));

  const HelpIcon = helpIcon();

  return (
    <form autoComplete="off">
      <DebugPrefill onClick={debugPrefill} />
      <FormHeader
        workItemDefinition={definition}
        formName={CREATE_WORK_ITEM_FORM_NAME}
      />
      {error && <FormError errorMessage={error} />}
      <Card className={classes.card} elevation={0}>
        <CardContent>
          <WorkItemComponentFields
            workItemDefinition={definition}
            change={change}
            untouch={untouch}
            formName={CREATE_WORK_ITEM_FORM_NAME}
            formType="CREATE"
            template={template}
          />
        </CardContent>
      </Card>
      {supportedChildTypeAssignments.map((childTypeAssignment) => (
        <Card
          key={childTypeAssignment.type}
          className={classes.card}
          elevation={0}
          data-cy={`${childTypeAssignment.definition.name.toLowerCase()}s-card`}
        >
          <CardHeader
            title={`${childTypeAssignment.definition.name}s`}
            action={
              childTypeAssignment.helpText && (
                <Tooltip
                  title={childTypeAssignment.helpText.helpText}
                  disableFocusListener
                >
                  <IconButton aria-label="Help">
                    <HelpIcon />
                  </IconButton>
                </Tooltip>
              )
            }
          />
          <CardContent>
            <FieldArray
              name="children"
              addLabel={`Add ${childTypeAssignment.definition.name.toLowerCase()}`}
              childDefinition={childTypeAssignment.definition}
              component={WorkItemChildList}
              change={change}
              untouch={untouch}
              formName={CREATE_WORK_ITEM_FORM_NAME}
              parentType={type}
              template={template}
            />
          </CardContent>
        </Card>
      ))}
      {definition.schedulable && (
        <Card className={classes.card} elevation={0}>
          <CardHeader title="Repeating" />
          <CardContent>
            <ScheduleFields
              change={change}
              formName={CREATE_WORK_ITEM_FORM_NAME}
              type={type}
              setTemplate={setTemplate}
            />
          </CardContent>
        </Card>
      )}
    </form>
  );
};

CreateWorkItemForm.propTypes = {
  type: PropTypes.string.isRequired,
  loggedInUser: PropTypes.object,
  types: PropTypes.object.isRequired,
  error: PropTypes.string,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
};

CreateWorkItemForm.defaultProps = {
  loggedInUser: null,
  error: null,
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
  types: getReferenceDataType(state, "WorkItemType"),
});

const form = reduxForm({
  form: CREATE_WORK_ITEM_FORM_NAME,
  onSubmitFail: (errors) => scrollToFirstInvalidInput(errors),
});
const reduxData = connect(mapStateToProps);
export default compose(form, reduxData)(CreateWorkItemForm);
