import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import OutcomeChip from "../workitem/OutcomeChip";
import EditTextDialog from "./EditTextDialog";

const styles = (theme) => ({
  root: {
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
    },
  },
  avatarBox: {
    textAlign: "center",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      marginBottom: 0,
    },
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    margin: "0 auto",
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
      marginRight: 16,
      marginBottom: 0,
      marginLeft: 0,
    },
  },
  headingBox: {
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
    },
  },
  titleBox: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      marginBottom: 0,
      display: "flex",
      alignItems: "center",
    },
  },
  heading: {
    color: theme.palette.primary.main,
    fontFamily: theme.typography.h1.fontFamily,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 1,
  },
  subHeading: {
    ...theme.typography.h5,
    letterSpacing: "-0.01",
    color: theme.palette.primary.main,
    [theme.breakpoints.up("md")]: {
      ...theme.typography.h4,
    },
  },
  darkSubheading: {
    color: "#000",
  },
  editButton: {
    padding: "5px",
    marginLeft: "10px",
    width: "30px",
    height: "30px",
  },
  editIcon: {
    fontSize: "20px",
  },
});

const SubHeading = ({
  classes,
  workItem,
  text,
  onTitleChange,
  readonly,
  darkSubheading,
}) => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const updateTitle = (currentText) => {
    if (workItem && workItem.title !== currentText) {
      onTitleChange(currentText);
    }
    setDialogOpen(false);
  };

  return (
    <>
      <EditTextDialog
        open={isDialogOpen}
        onSubmit={updateTitle}
        onClose={() => setDialogOpen(false)}
        initialValue={text}
        title="Edit title"
        data-cy="editTitle"
      />
      <Typography
        className={classNames({
          [classes.subHeading]: true,
          [classes.darkSubheading]: darkSubheading,
        })}
        variant="h6"
        data-cy="header"
      >
        <span data-cy="unified-heading-sub-heading">{text}</span>
        {!readonly && (
          <Tooltip title="Edit" disableFocusListener data-cy="edittooltip">
            <IconButton
              className={classes.editButton}
              onClick={() => setDialogOpen(true)}
            >
              <EditIcon className={classes.editIcon} data-cy="edit" />
            </IconButton>
          </Tooltip>
        )}
      </Typography>
    </>
  );
};

const UnifiedHeading = ({
  classes,
  icon: PageIcon,
  heading,
  subHeading,
  workItem,
  readonly,
  onTitleChange,
  outcome,
  darkSubheading,
}) => (
  <div className={classes.root} data-cy="unified-heading">
    <div className={classes.avatarBox}>
      <Avatar className={classes.avatar}>
        <PageIcon />
      </Avatar>
    </div>
    <div className={classes.headingBox}>
      <div className={classes.titleBox}>
        <Typography className={classes.heading} variant="h4" data-cy={heading}>
          {heading}
        </Typography>
        {outcome && <OutcomeChip outcome={outcome} />}
      </div>
      <div>
        <SubHeading
          {...{ classes, workItem, onTitleChange, readonly, darkSubheading }}
          text={subHeading}
          data-cy="subheading"
        />
      </div>
    </div>
  </div>
);

SubHeading.propTypes = {
  classes: PropTypes.object.isRequired,
  workItem: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  readonly: PropTypes.bool.isRequired,
  onTitleChange: PropTypes.func.isRequired,
  darkSubheading: PropTypes.bool.isRequired,
};

UnifiedHeading.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.any.isRequired,
  heading: PropTypes.node.isRequired,
  subHeading: PropTypes.string.isRequired,
  workItem: PropTypes.object.isRequired,
  readonly: PropTypes.bool.isRequired,
  onTitleChange: PropTypes.func.isRequired,
  outcome: PropTypes.string,
  darkSubheading: PropTypes.bool,
};

UnifiedHeading.defaultProps = {
  outcome: null,
  darkSubheading: false,
};

export default withStyles(styles)(UnifiedHeading);
