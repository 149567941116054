import { combineReducers } from "redux";
import {
  ORGS_CLEAR_ACTIVE,
  ORGS_CREATE,
  ORGS_GET_BY_ID,
  ORGS_PATCH,
  ORGS_SEARCH,
} from "../actions/organisations";
import { publishActionToast } from "../services/toasts";

const successToasts = {
  [ORGS_CREATE]: "Create organisation successfully",
  [ORGS_PATCH]: "Updated organisation successfully",
};

const byId = (state = {}, action) => {
  publishActionToast(action, "ORGS_", successToasts);

  switch (action.type) {
    case `${ORGS_SEARCH}_SUCCESS`:
    case `${ORGS_GET_BY_ID}_SUCCESS`:
    case `${ORGS_CREATE}_SUCCESS`:
    case `${ORGS_PATCH}_SUCCESS`:
      return {
        ...state,
        ...action.response.entities.organisation,
      };

    default:
      break;
  }

  return state;
};

const defaultActive = {
  dashboard: [],
  dashboardLastUpdate: 0,
  activeOrganisation: null,
  lastEdit: 0,
  error: null,
  pagination: {
    offset: 0,
    pageSize: 50,
    resultCount: 0,
  },
};

const active = (state = defaultActive, action) => {
  switch (action.type) {
    case ORGS_CLEAR_ACTIVE:
      return {
        ...defaultActive,
      };

    case `${ORGS_SEARCH}_SUCCESS`:
      return {
        ...state,
        dashboard: [...action.response.result],
        dashboardLastUpdate: new Date().getTime(),
        pagination: {
          ...action.response.pagination,
          pageSize: defaultActive.pagination.pageSize,
        },
      };

    case `${ORGS_GET_BY_ID}_SUCCESS`:
      return {
        ...state,
        activeOrganisation: action.response.result,
      };

    case `${ORGS_CREATE}_SUCCESS`:
    case `${ORGS_PATCH}_SUCCESS`:
      return {
        ...state,
        activeOrganisation: action.response.result,
        lastEdit: new Date().getTime(),
      };

    default:
      break;
  }

  return state;
};

export default combineReducers({
  active,
  byId,
});

export const getActiveOrganisation = (state) =>
  state.active.activeOrganisation
    ? state.byId[state.active.activeOrganisation]
    : null;
export const getAll = (state) =>
  state.active.dashboard.map((id) => state.byId[id]);
export const getPagination = (state) => state.active.pagination;
export const getLastEdit = (state) => state.active.lastEdit;
