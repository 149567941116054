import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import GridListing from "../../common/GridListing";
import InlineInput from "../../common/InlineInput";
import IconButton from "@material-ui/core/IconButton";
import { deleteIcon, editIcon } from "../../../util/icons";
import ColorScoreChip from "../../ColorScoreChip";
import CategoryGroupPicker from "./CategoryGroupPicker";
import { getLabels } from "../../../reducers";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(() => ({
  wrapText: {
    overflow: "hidden",
    overflowWrap: "break-word",
    whiteSpace: "wrap",
  },
}));

const CategoryListing = ({
  categories,
  groups,
  onCreateGroup,
  onUpdateCategory,
  onUpdateGroup,
  onDeleteCategory,
  onDeleteGroup,
  sortBy,
  updateSort,
  loading,
  fieldLabels,
}) => {
  const classes = useStyles();
  const [activeCategoryForGroupEditing, setActiveCategoryForGroupEditing] =
    useState(null);
  const DeleteIcon = deleteIcon();
  const EditIcon = editIcon();

  const getGroups = (category) =>
    groups.filter(
      (g) => g.categories.filter((c) => c.id === category.id).length > 0
    );

  return (
    <>
      <CategoryGroupPicker
        groups={groups}
        activeCategory={activeCategoryForGroupEditing}
        onCreateGroup={onCreateGroup}
        onUpdateGroup={onUpdateGroup}
        onDeleteGroup={onDeleteGroup}
        onClose={() => setActiveCategoryForGroupEditing(null)}
      />
      <GridListing
        sortedData={_.orderBy(categories, [sortBy.field], [sortBy.direction])}
        loading={loading}
        sortBy={sortBy}
        dense={false}
        updateSort={(field, direction) =>
          updateSort({
            field,
            direction,
          })
        }
        action={(c) => (
          <IconButton
            disabled={!onDeleteCategory}
            onClick={() => onDeleteCategory(c)}
          >
            <DeleteIcon />
          </IconButton>
        )}
        columns={[
          {
            label: fieldLabels.labels.name,
            name: "name",
            size: 6,
            sortable: true,
            render: (c) => (
              <Typography
                className={classes.wrapText}
                component="div"
                data-cy={c.name}
              >
                <InlineInput
                  value={c.name}
                  onChange={
                    onUpdateCategory
                      ? (name) => onUpdateCategory(c, { name })
                      : null
                  }
                  validate={(value) => !!value && !!value.trim()}
                />
              </Typography>
            ),
          },
          {
            label: fieldLabels.labels.sortOrder,
            name: "sortOrder",
            size: 2,
            sortable: true,
            render: (c) => (
              <Typography component="div" data-cy={c.sortOrder}>
                <InlineInput
                  value={c.sortOrder}
                  onChange={
                    onUpdateCategory
                      ? (sortOrder) => onUpdateCategory(c, { sortOrder })
                      : null
                  }
                  inputProps={{
                    min: 0,
                  }}
                  validate={(value) =>
                    value !== null &&
                    value !== undefined &&
                    value !== "" &&
                    _.isNumber(Number(value)) &&
                    value >= 0
                  }
                />
              </Typography>
            ),
          },
          {
            label: "Groups",
            name: "groups",
            size: 6,
            sortable: false,
            render: (c) => (
              <Grid container spacing={1}>
                {getGroups(c).map((group) => (
                  <Grid item key={group.id}>
                    <ColorScoreChip
                      label={group.name}
                      onDelete={
                        onUpdateGroup
                          ? () =>
                              onUpdateGroup(group, {
                                categories: group.categories.filter(
                                  (cat) => cat.id !== c.id
                                ),
                              })
                          : null
                      }
                    />
                  </Grid>
                ))}
                {onUpdateGroup && (
                  <Grid item>
                    <IconButton
                      onClick={() => setActiveCategoryForGroupEditing(c)}
                      size="small"
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            ),
          },
        ]}
      />
    </>
  );
};

CategoryListing.propTypes = {
  categories: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
  sortBy: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  updateSort: PropTypes.func.isRequired,
  onCreateGroup: PropTypes.func,
  onUpdateCategory: PropTypes.func,
  onUpdateGroup: PropTypes.func,
  onDeleteCategory: PropTypes.func,
  onDeleteGroup: PropTypes.func,

  // redux
  fieldLabels: PropTypes.object.isRequired,
};

CategoryListing.defaultProps = {
  onCreateGroup: null,
  onUpdateCategory: null,
  onUpdateGroup: null,
  onDeleteCategory: null,
  onDeleteGroup: null,
};

const mapStateToProps = (state) => ({
  fieldLabels: getLabels(state).ContentCategory,
});

export default connect(mapStateToProps)(CategoryListing);
