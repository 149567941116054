import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";

const styles = (theme) => ({
  box: {
    marginTop: theme.spacing(1),
  },
  text: {
    color: theme.palette.swatch.secondary,
  },
});

const isCreateEntry = (entry) => entry.type === "CREATE";
const isLegacyEntry = (entry) =>
  !_.has(entry, "status") && _.has(entry, "description");

const useLegacy = (entry) => {
  const [isLegacy, setIsLegacy] = useState(false);
  const [content, setContent] = useState("");

  useEffect(() => {
    if (isLegacyEntry(entry) && !isCreateEntry(entry)) {
      setIsLegacy(true);
      setContent(entry.description);
    }
  }, [entry]);

  return [isLegacy, content];
};

const Legacy = ({ classes, entry }) => {
  const [isLegacy, content] = useLegacy(entry);

  return (
    <>
      {isLegacy && (
        <Typography variant="body2" className={classes.text}>
          {content.split("\n").map((item) => (
            <span key={Math.random()}>
              {item}
              <br />
            </span>
          ))}
        </Typography>
      )}
    </>
  );
};

Legacy.propTypes = {
  classes: PropTypes.object.isRequired,
  entry: PropTypes.object.isRequired,
};

export default withStyles(styles)(Legacy);
