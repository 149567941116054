import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { subscribeToasts, unsubscribeToasts } from "../services/toasts";

const SnackbarListener = () => {
  const { enqueueSnackbar } = useSnackbar();

  const onToast = (event) => {
    const { message, variant } = event.detail;
    enqueueSnackbar(message, { variant });
  };

  useEffect(() => {
    subscribeToasts(onToast);
    return () => unsubscribeToasts(onToast);
  }, []);

  return null;
};

export default SnackbarListener;
