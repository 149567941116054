import classNames from "classnames";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useDropzone } from "react-dropzone";
import { connect } from "react-redux";
import { compose } from "redux";
import tinycolor from "tinycolor2";
import { isDragging } from "../../reducers";
import { publishToastWarning } from "../../services/toasts";
import logger from "../../util/logger";
import { ACCEPTED_FILES } from "../../util/referenceData";

const styles = (theme) => ({
  dropzone: {
    position: "relative",
    outline: "none",
  },
  dropzoneOverlay: {
    position: "absolute",
    top: 0,
    right: -theme.spacing(1),
    bottom: theme.spacing(1),
    left: -theme.spacing(1),
    zIndex: 1600,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),
    textAlign: "center",
    border: "dashed 2px #ccc",
    borderRadius: 5,
    background: tinycolor(theme.palette.swatch.grey6).setAlpha(0.6),
    "&:hover": {
      cursor: "pointer",
    },
  },
  dropzoneAccept: {
    border: `solid 2px ${theme.palette.primary.main}`,
    background: theme.palette.swatch.grey4,
  },
  dropzoneReject: {
    border: `solid 2px ${theme.palette.warning.main}`,
    background: theme.palette.swatch.grey4,
  },
  uploadText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
  },
  uploadIcon: {
    marginRight: theme.spacing(1),
  },
});

const OverlayDropzone = ({
  classes,
  readonly,
  multiple,
  children,
  dragging,
  onDrop,
  acceptedFileTypes,
}) => {
  const [active, setActive] = useState(false);
  const {
    acceptedFiles,
    rejectedFiles,
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: acceptedFileTypes, multiple });

  useEffect(() => {
    setActive(false);

    if (rejectedFiles && rejectedFiles.length > 0) {
      logger.debug("rejectedFiles", rejectedFiles);
      publishToastWarning(
        `Sorry ${rejectedFiles
          .map((r) => r.name)
          .join(", ")} cannot be uploaded`
      );
    }

    if (acceptedFiles && acceptedFiles.length > 0) {
      onDrop(acceptedFiles);
    }
  }, [acceptedFiles, rejectedFiles]);

  const dropZoneProps = { ...getRootProps() };
  delete dropZoneProps.onClick; // prevent overlay drop zone from opening file picker on click

  return (
    <div className={classes.dropzone} {...dropZoneProps}>
      <input {...getInputProps()} />
      {!readonly && (active || dragging) && (
        <div
          className={classNames({
            [classes.dropzoneOverlay]: true,
            [classes.dropzoneAccept]: isDragAccept,
            [classes.dropzoneReject]: isDragReject,
          })}
        >
          <div className={classes.uploadText}>
            <CloudUploadIcon className={classes.uploadIcon} color="disabled" />
            <Typography variant="subtitle1" display="inline">
              Drag and drop here
            </Typography>
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

OverlayDropzone.propTypes = {
  classes: PropTypes.object.isRequired,
  readonly: PropTypes.bool.isRequired,
  onDrop: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  multiple: PropTypes.bool,
  acceptedFileTypes: PropTypes.string,

  // redux state
  dragging: PropTypes.bool.isRequired,
};

OverlayDropzone.defaultProps = {
  multiple: true,
  acceptedFileTypes: ACCEPTED_FILES,
};

const mapStateToProps = (state) => ({
  dragging: isDragging(state),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(OverlayDropzone);
