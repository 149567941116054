import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import PropTypes from "prop-types";
import InputLabel from "@material-ui/core/InputLabel";
import Icon from "@material-ui/core/Icon";
import ChoiceChips from "../common/ChoiceChips";
import FiltersBar from "../common/FiltersBar";

const useStyles = makeStyles((theme) => ({
  chips: {
    marginTop: theme.spacing(1),
  },
}));

const QuestionFilter = ({ filter, updateFilter }) => {
  const classes = useStyles();

  return (
    <>
      <FiltersBar>
        <>
          <InputLabel>
            <Icon fontSize="inherit">check_circle</Icon> Status
          </InputLabel>
          <div className={classes.chips}>
            <ChoiceChips
              value={filter.audience}
              options={[
                {
                  label: "Unanswered",
                  tooltip: "Show questions I need to answer",
                  value: "UNANSWERED_CURRENT_USER",
                },
                {
                  label: "All",
                  tooltip: "Show all questions",
                  value: "ALL_CURRENT_USER",
                },
              ]}
              onChange={(value) => updateFilter("audience", value)}
              deselectable={false}
            />
          </div>
        </>
      </FiltersBar>
    </>
  );
};

QuestionFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  updateFilter: PropTypes.func.isRequired,
};

export default QuestionFilter;
