import { requestJSON, requestText } from "./http";
import { User } from "../../model/User";

export interface Filters {
  hideDisabled: boolean;
  limit: number;
  offset: number;
  orderByField: string;
}

interface SearchResults {
  results: User[];
}

const decorateSearchParameters = (filter: Filters) =>
  filter ? { __type: "User", ...filter } : { __type: "User" };

// with pagination
const search = (
  filter: Filters,
  abortController: AbortController,
  accessToken: string
): Promise<SearchResults> =>
  requestJSON({
    path: "/users/search",
    method: "POST",
    data: decorateSearchParameters(filter),
    abortController,
    accessToken,
  });

const me = (accessToken: string): Promise<User> =>
  requestJSON({
    path: "/users/me",
    method: "GET",
    accessToken,
  });

const loginCheck = (accessToken: string): Promise<void> =>
  requestText({
    path: "/users/login-check",
    method: "GET",
    accessToken,
  });

const get = (userId: string, accessToken: string): Promise<User> =>
  requestJSON({
    path: `/users/${userId}`,
    accessToken,
  });

const patch = (
  id: string,
  changes: Record<string, unknown>,
  description: string,
  accessToken: string
): Promise<User> =>
  requestJSON({
    path: `/users/${id}`,
    method: "PATCH",
    data: {
      fields: Object.keys(changes),
      changes,
      description,
    },
    accessToken,
  });

export default {
  get,
  search,
  me,
  patch,
  loginCheck,
};
