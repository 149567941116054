import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import ActionButton from "../../../components/common/ActionButton";
import ActionHeading from "../../../components/common/ActionHeading";
import Container from "../../../components/common/Container";
import FloatingActionButton from "../../../components/common/FloatingActionButton";
import HeaderBar from "../../../components/common/HeaderBar";
import PageHeading from "../../../components/common/PageHeading";
import OrganisationFilters from "../../../components/organisation/OrganisationFilters";
import OrganisationListing from "../../../components/organisation/OrganisationListing";
import { getLoggedInUser } from "../../../reducers";
import { clearIcon, organisationIcon } from "../../../util/icons";
import usePersistedState from "../../../util/persistedState";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  contents: {
    marginBottom: "75px",
  },
  title: {
    marginRight: "auto",
  },
}));

const defaultFilter = {
  textSearch: "",
};

const Index = ({ loggedInUser, history }) => {
  const classes = useStyles();
  const [sortBy, setSortBy] = usePersistedState(
    `orgs-listing-sort-${loggedInUser.email}`,
    {
      field: "name",
      direction: "asc",
    }
  );
  const [filter, setFilter] = usePersistedState(
    `orgs-listing-filter-${loggedInUser.email}`,
    defaultFilter,
    ["textSearch"]
  );

  const clearFilter = () => setFilter(defaultFilter);

  const updateFilter = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  const createNew = () => {
    history.push(`/admin/organisations/new`);
  };

  return (
    <div className={classes.root}>
      <HeaderBar>
        <ActionHeading
          heading={
            <PageHeading icon={organisationIcon()} heading="Organisations" />
          }
          actions={[
            <ActionButton
              key="clearFilter"
              tooltip="Clear filters"
              icon={clearIcon()}
              onClick={clearFilter}
              data-cy="clear-filters-button"
            />,
          ]}
        />
      </HeaderBar>
      <Container className={classes.contents}>
        <FloatingActionButton onClick={createNew} />
        <OrganisationFilters
          filter={filter}
          updateFilter={updateFilter}
          fullWidth={false}
        />
        <OrganisationListing
          history={history}
          filter={filter}
          clearFilter={clearFilter}
          sortBy={sortBy}
          updateSort={setSortBy}
          onSelect={(organisation) =>
            history.push(`/admin/organisations/${organisation.id}`)
          }
        />
      </Container>
    </div>
  );
};

Index.propTypes = {
  history: PropTypes.object.isRequired,

  // redux
  loggedInUser: PropTypes.object,
};

Index.defaultProps = {
  loggedInUser: null,
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
});

export default connect(mapStateToProps)(Index);
