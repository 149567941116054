import { normalize } from "normalizr";
import { asyncAction } from "./actions";
import schemas from "../schemas";
import api from "../services/api";

export const WORK_ITEM_ASSOCIATION_PREFIX = "WIA__";

// action types
export const WORK_ITEM_ASSOCIATION_LIST = `${WORK_ITEM_ASSOCIATION_PREFIX}_LIST`;
export const WORK_ITEM_ASSOCIATION_CREATE = `${WORK_ITEM_ASSOCIATION_PREFIX}_CREATE`;
export const WORK_ITEM_ASSOCIATION_DELETE = `${WORK_ITEM_ASSOCIATION_PREFIX}_DELETE`;

export const getAssociationsByWorkItemId = (workItemId, accessToken) =>
  asyncAction(
    WORK_ITEM_ASSOCIATION_LIST,
    api.workItemAssociations.findByWorkItemId(workItemId, accessToken),
    {
      responseTransformer: (associations) =>
        normalize(associations, schemas.arrayOfWorkItemAssociations),
    }
  );

export const createAssociation = (association, accessToken) =>
  asyncAction(
    WORK_ITEM_ASSOCIATION_CREATE,
    api.workItemAssociations.create(association, accessToken),
    {
      responseTransformer: (newAssociation) =>
        normalize(newAssociation, schemas.workItemAssociation),
    }
  );

export const deleteAssociation = (associationId, accessToken) =>
  asyncAction(
    WORK_ITEM_ASSOCIATION_DELETE,
    api.workItemAssociations.remove(associationId, accessToken),
    {
      responseTransformer: () => ({ id: associationId }),
    }
  );
