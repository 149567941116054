import PropTypes from "prop-types";
import React from "react";
import { FormattedDate } from "react-intl";
import { connect } from "react-redux";
import formatters from "../../../../util/formatters";
import ComponentScorecard from "../../../common/ComponentScorecard";
import FormCard from "../../../common/FormCard";
import EditWorkItemComponentForm, {
  EDIT_WORK_ITEM_COMPONENT_FORM_NAME,
} from "../EditWorkItemComponentForm";

const DeathBenefitCard = ({
  className,
  fieldLabels,
  workItem,
  onChange,
  customisations,
}) => {
  const { deathBenefit } = workItem;

  return (
    <FormCard
      className={className}
      title={fieldLabels.labels.deathBenefit}
      formTitle={`Edit ${fieldLabels.labels.deathBenefit}`}
      readOnlyView={() => (
        <>
          <ComponentScorecard
            fieldLabels={fieldLabels}
            name="deathBenefit.claimStakingUndertaken"
            customisations={customisations}
            entity={workItem}
            render={(value) => formatters.displayBoolean(value)}
            data-cy="deathBenefit.claimStakingUndertaken"
          />
          <ComponentScorecard
            fieldLabels={fieldLabels}
            name="deathBenefit.dateOfDeath"
            customisations={customisations}
            entity={workItem}
            render={(value) => <FormattedDate value={value} />}
            data-cy="deathBenefit.dateOfDeath"
          />
          <ComponentScorecard
            fieldLabels={fieldLabels}
            name="deathBenefit.estateSolvent"
            customisations={customisations}
            entity={workItem}
            render={(value) => formatters.displayBoolean(value)}
            defaultDisplay="N/A"
          />
        </>
      )}
      formComponent={EditWorkItemComponentForm}
      formName={EDIT_WORK_ITEM_COMPONENT_FORM_NAME}
      onSave={onChange}
      initialValues={{ deathBenefit }}
      formProps={{
        maxWidth: "md",
        workItem,
        componentName: "DEATH_BENEFIT",
      }}
    />
  );
};

DeathBenefitCard.propTypes = {
  className: PropTypes.string,
  fieldLabels: PropTypes.object.isRequired,
  workItem: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  customisations: PropTypes.array,
};

DeathBenefitCard.defaultProps = {
  className: undefined,
  customisations: [],
  onChange: null,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(DeathBenefitCard);
