import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field } from "redux-form";
import { yesNoOptions } from "../../../../util/radioOptions";
import WrappedDatePicker from "../../../forms/wrapper/WrappedDatePicker";
import WrappedRadioGroup from "../../../forms/wrapper/WrappedRadioGroup";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  dependant: {
    border: `solid 1px ${theme.palette.grey[300]}`,
  },
  label: {
    marginTop: theme.spacing(3),
  },
  checkboxSection: {
    marginTop: theme.spacing(2),
  },
});

class DependantChecklistFields extends Component {
  render() {
    const { fields, fieldLabels, classes } = this.props;

    return (
      <>
        {fields.map((dependant, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <List key={index} className={classes.root}>
            <ListItem className={classes.dependant}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    data-cy={fields.get(index).contact.name}
                  >
                    {fields.get(index).contact.name}
                  </Typography>
                </Grid>
                <Grid container alignItems="center">
                  <Grid className={classes.label} item xs={12} md={3}>
                    <InputLabel>Initial Letter</InputLabel>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Field
                          name={`${dependant}.initialLetterSentDate`}
                          component={WrappedDatePicker}
                          label={
                            fieldLabels.nestedTypes.dependants.labels
                              .initialLetterSentDate
                          }
                          disableFuture
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          name={`${dependant}.initialLetterReceivedDate`}
                          component={WrappedDatePicker}
                          label={
                            fieldLabels.nestedTypes.dependants.labels
                              .initialLetterReceivedDate
                          }
                          disableFuture
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container alignItems="center">
                  <Grid className={classes.label} item xs={12} md={3}>
                    <InputLabel>Form of acceptance</InputLabel>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Field
                          name={`${dependant}.formOfAcceptanceSentDate`}
                          component={WrappedDatePicker}
                          label={
                            fieldLabels.nestedTypes.dependants.labels
                              .formOfAcceptanceSentDate
                          }
                          disableFuture
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          name={`${dependant}.formOfAcceptanceReceivedDate`}
                          component={WrappedDatePicker}
                          label={
                            fieldLabels.nestedTypes.dependants.labels
                              .formOfAcceptanceReceivedDate
                          }
                          disableFuture
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <FormHelperText margin="dense">
                      If the form of acceptance has not been received within 28
                      days of the sent date, the dependant is assumed to have
                      accepted by default and can be marked as Confirmed
                    </FormHelperText>
                  </Grid>
                </Grid>
                <Grid container className={classes.checkboxSection}>
                  <Grid item xs={12} md={6}>
                    <Field
                      name={`${dependant}.dependantIdentification`}
                      component={WrappedRadioGroup}
                      label={
                        fieldLabels.nestedTypes.dependants.labels
                          .dependantIdentification
                      }
                      options={yesNoOptions}
                      row
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      name={`${dependant}.statementOfDependencyClaim`}
                      component={WrappedRadioGroup}
                      label={
                        fieldLabels.nestedTypes.dependants.labels
                          .statementOfDependencyClaim
                      }
                      options={yesNoOptions}
                      row
                    />
                  </Grid>
                </Grid>
              </Grid>
            </ListItem>
          </List>
        ))}
      </>
    );
  }
}

DependantChecklistFields.propTypes = {
  classes: PropTypes.object.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
};

DependantChecklistFields.defaultProps = {};

const mapStateToProps = () => ({});

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(DependantChecklistFields);
