import { requestJSON } from "./http";

const decorateSearchParameters = (filter) =>
  filter ? { __type: "DigitalAsset", ...filter } : { __type: "DigitalAsset" };

const getById = (id, accessToken) =>
  requestJSON({
    path: `/sentinel/digital-asset/${id}`,
    accessToken,
  });

const search = (searchParameters, accessToken) =>
  requestJSON({
    path: "/sentinel/digital-asset/search",
    method: "POST",
    data: decorateSearchParameters(searchParameters),
    accessToken,
  });

const create = (digitalAsset, accessToken) =>
  requestJSON({
    path: "/sentinel/digital-asset",
    method: "POST",
    data: digitalAsset,
    accessToken,
  });

const remove = (id, accessToken) =>
  requestJSON({
    path: `/sentinel/digital-asset/${id}`,
    method: "DELETE",
    accessToken,
  });

const patch = (id, changes, description, accessToken) =>
  requestJSON({
    path: `/sentinel/digital-asset/${id}`,
    method: "PATCH",
    data: {
      fields: Object.keys(changes),
      changes,
      description,
    },
    accessToken,
  });

export default {
  getById,
  create,
  remove,
  search,
  patch,
};
