const pick = (item, ignoreKeys) => {
  const value = {
    ...item,
  };
  if (ignoreKeys) {
    ignoreKeys.forEach((ignoreKey) => delete value[ignoreKey]);
  }
  return value;
};

export default class LocalStorageItem {
  constructor(key, defaults, ignoreKeys) {
    this.key = key;
    this.ignoreKeys = ignoreKeys;

    const cachedItem = localStorage.getItem(key);
    const item = cachedItem ? JSON.parse(cachedItem) : {};

    // Add any missing defaults
    Object.keys(defaults).forEach((k) => {
      if (item[k] === undefined || item[k] === null) {
        item[k] = defaults[k];
      }
    });

    this.update(item);
  }

  get() {
    const cachedItem = localStorage.getItem(this.key);
    return cachedItem ? pick(JSON.parse(cachedItem), this.ignoreKeys) : null;
  }

  update(item) {
    localStorage.setItem(this.key, JSON.stringify(pick(item, this.ignoreKeys)));
  }
}
