import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import EventIcon from "@material-ui/icons/Event";
import { intersection } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import ActionButton from "../../components/common/ActionButton";
import ActionHeading from "../../components/common/ActionHeading";
import Container from "../../components/common/Container";
import FloatingActionButton from "../../components/common/FloatingActionButton";
import HeaderBar from "../../components/common/HeaderBar";
import PageHeading from "../../components/common/PageHeading";
import ScheduleFilters from "../../components/schedule/ScheduleFilters";
import WorkItemScheduleListing from "../../components/schedule/WorkItemScheduleListing";
import { getLoggedInUser, getReferenceDataType } from "../../reducers";
import { clearIcon } from "../../util/icons";
import usePersistedState from "../../util/persistedState";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  contents: {
    marginBottom: "75px",
  },
}));

const defaultFilter = {
  textSearch: "",
  ownership: "createdByMe",
  status: "ACTIVE",
  categories: [],
  organisationIds: [],
  productIds: [],
};

const Index = ({ types, history, loggedInUser }) => {
  const classes = useStyles();
  const [sortBy, setSortBy] = usePersistedState(
    `schedule-listing-sort-${loggedInUser.email}`,
    {
      field: "nextDue",
      direction: "asc",
    }
  );
  const [filter, setFilter] = usePersistedState(
    `schedule-listing-filter-${loggedInUser.email}`,
    defaultFilter,
    ["textSearch"]
  );

  const clearFilter = () => setFilter({ ...defaultFilter });

  const updateFilter = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  const newWorkItemsMenus = types.ids
    .filter((id) => id === "COMPLIANCE_TASK")
    .map((id) => types.values[id])
    .filter(
      (type) =>
        loggedInUser &&
        intersection(type.props.definition.creatableBy, loggedInUser.roles)
          .length > 0
    )
    .map((type) => (
      <MenuItem
        key={type.id}
        onClick={() => history.push(`/work-items/new/${type.id}`)}
      >{`New ${type.description.toLowerCase()}`}</MenuItem>
    ));

  return (
    <div>
      <HeaderBar fluid>
        <ActionHeading
          heading={
            <PageHeading icon={EventIcon} heading="Compliance calendar" />
          }
          actions={[
            <ActionButton
              key="clearFilter"
              tooltip="Clear filters"
              icon={clearIcon()}
              onClick={clearFilter}
              data-cy="clear-filters-button"
            />,
          ]}
        />
      </HeaderBar>
      {newWorkItemsMenus.length > 0 && (
        <FloatingActionButton>{newWorkItemsMenus}</FloatingActionButton>
      )}
      <ScheduleFilters filter={filter} updateFilter={updateFilter} />
      <Container fluid className={classes.contents}>
        <WorkItemScheduleListing
          history={history}
          filter={filter}
          clearFilter={clearFilter}
          sortBy={sortBy}
          updateSort={setSortBy}
        />
      </Container>
    </div>
  );
};

Index.propTypes = {
  history: PropTypes.object.isRequired,
  loggedInUser: PropTypes.object,
  types: PropTypes.object.isRequired,
};

Index.defaultProps = {
  loggedInUser: {},
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
  types: getReferenceDataType(state, "WorkItemType"),
});

export default connect(mapStateToProps)(Index);
