import { normalize } from "normalizr";
import { asyncAction } from "./actions";
import digitalAssetsApi from "../services/api/digitalAssets";
import schemas from "../schemas";

export const DIGITAL_ASSETS_CLEAR_ACTIVE = "DIGITAL_ASSETS_CLEAR_ACTIVE";
export const DIGITAL_ASSETS_GET_BY_ID = "DIGITAL_ASSETS_GET_BY_ID";
export const DIGITAL_ASSETS_CREATE = "DIGITAL_ASSETS_CREATE";
export const DIGITAL_ASSETS_DELETE = "DIGITAL_ASSETS_DELETE";
export const DIGITAL_ASSETS_SEARCH = "DIGITAL_ASSETS_SEARCH";
export const DIGITAL_ASSETS_PATCH = "DIGITAL_ASSETS_PATCH";

export function clearActiveDigitalAssets() {
  return {
    type: DIGITAL_ASSETS_CLEAR_ACTIVE,
  };
}

export const searchDigitalAssets = (searchParameters, accessToken) =>
  asyncAction(
    DIGITAL_ASSETS_SEARCH,
    digitalAssetsApi.search(searchParameters, accessToken),
    {
      responseTransformer: (response) => ({
        pagination: {
          offset: response.offset,
          previousOffset: response.previousOffset,
          nextOffset: response.nextOffset,
          pageSize: response.pageSize,
          resultCount: response.resultCount,
        },
        ...normalize(response.results, schemas.arrayOfDigitalAssets),
      }),
    }
  );

export const getDigitalAssetById = (digitalAssetId, accessToken) =>
  asyncAction(
    DIGITAL_ASSETS_GET_BY_ID,
    digitalAssetsApi.getById(digitalAssetId, accessToken),
    {
      responseTransformer: (digitalAsset) =>
        normalize(digitalAsset, schemas.digitalAsset),
    }
  );

export const createDigitalAsset = (digitalAsset, accessToken) =>
  asyncAction(
    DIGITAL_ASSETS_CREATE,
    digitalAssetsApi.create(digitalAsset, accessToken),
    {
      responseTransformer: (created) =>
        normalize(created, schemas.digitalAsset),
    }
  );

export const deleteDigitalAsset = (digitalAssetId, accessToken) =>
  asyncAction(
    DIGITAL_ASSETS_DELETE,
    digitalAssetsApi.remove(digitalAssetId, accessToken),
    {
      responseTransformer: () => ({ id: digitalAssetId }),
      actionParams: {
        isUpdate: true,
      },
    }
  );

export const patchDigitalAsset = (
  digitalAssetId,
  changes,
  description,
  accessToken
) =>
  asyncAction(
    DIGITAL_ASSETS_PATCH,
    digitalAssetsApi.patch(digitalAssetId, changes, description, accessToken),
    {
      responseTransformer: (digitalAsset) =>
        normalize(digitalAsset, schemas.digitalAsset),
    }
  );
