import React from "react";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "@material-ui/core/Button";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import { CardActions } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Organisation } from "../../../model/Organisation";
import organisationApi from "../../../services/api/organisations";
import { getRefDataMenuItems } from "../menuItems";
import { getReferenceDataType } from "../../../reducers";
import {
  createOrganisation,
  patchOrganisation,
} from "../../../actions/organisations";

interface Props {
  organisation?: Organisation | null;
  organisationTypes: string[];
  regions: string[];
  localCreateOrganisation: (org: Organisation, accessToken: string) => void;
  localPatchOrganisation: (
    id: string,
    organisation: Organisation,
    description: string,
    accessToken: string
  ) => void;
}

interface Values {
  id?: string | null;
  friendlyId: string;
  name: string;
  type: string;
  region: string;
}

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(2),
  },
  actions: {
    padding: theme.spacing(2),
    flexDirection: "row-reverse",
  },
  actionButton: {
    marginLeft: theme.spacing(1),
  },
}));

const organisationSchema = Yup.object().shape({
  friendlyId: Yup.string()
    .min(2, "Friendly id should be between 2 and 4 characters")
    .max(4, "Friendly id should be between 2 and 4 characters")
    .required("Friendly ID is required"),
  name: Yup.string().required("Name is required"),
  type: Yup.string().required("Type is required"),
  region: Yup.string().required("Region is required"),
});

const OrganisationForm: React.FC<Props> = ({
  organisationTypes,
  regions,
  organisation,
  localCreateOrganisation,
  localPatchOrganisation,
}: Props) => {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();

  const goToOrganisationsPage = () => history.push(`/admin/organisations`);

  const initialValues = {
    id: organisation ? organisation.id : undefined,
    name: organisation ? organisation.name : "",
    friendlyId: organisation ? organisation.friendlyId : "",
    type: organisation ? organisation.type : "",
    region: organisation ? organisation.region : "",
  };

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={true}
      validationSchema={organisationSchema}
      validate={async (values: Values) => {
        const errors: Partial<Values> = {};
        const { id, friendlyId } = values;

        if (!values.friendlyId) {
          return;
        }

        try {
          const accessToken = await getAccessTokenSilently();
          const response = await organisationApi.checkFriendlyIdAvailability(
            id,
            friendlyId,
            accessToken
          );

          if (!response.available) {
            errors.friendlyId = `${friendlyId} is not available`;
          }
        } catch (e) {
          errors.friendlyId = e.message;
        }

        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        const accessToken = await getAccessTokenSilently();
        values.id
          ? await localPatchOrganisation(
              values.id,
              values,
              "Updated organisation",
              accessToken
            )
          : await localCreateOrganisation(values, accessToken);

        setSubmitting(false);
        goToOrganisationsPage();
      }}
    >
      {({ isSubmitting, isValid, values }) => (
        <Form>
          <Card className={classes.card} elevation={0}>
            <CardHeader title="Organisation details" />
            <CardContent>
              <Field
                component={TextField}
                name="friendlyId"
                label="Friendly ID"
                margin="dense"
                disabled={values.id}
                fullWidth
              />
              <Field
                component={TextField}
                name="name"
                label="Name"
                margin="dense"
                fullWidth
              />
              <Field
                component={TextField}
                name="type"
                label="Type"
                margin="dense"
                disabled={values.id}
                fullWidth
                select
              >
                {getRefDataMenuItems(organisationTypes)}
              </Field>
              <Field
                component={TextField}
                name="region"
                label="Region"
                margin="dense"
                disabled={values.id}
                fullWidth
                select
              >
                {getRefDataMenuItems(regions)}
              </Field>
            </CardContent>
            <CardActions className={classes.actions}>
              <Button
                className={classes.actionButton}
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting || !isValid}
              >
                Save
              </Button>
              <Button
                className={classes.actionButton}
                variant="text"
                onClick={goToOrganisationsPage}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
            </CardActions>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

interface State {
  organisationTypes: string[];
  regions: string[];
}

const mapStateToProps = (state: State) => ({
  organisationTypes: getReferenceDataType(state, "OrganisationType"),
  regions: getReferenceDataType(state, "Region"),
});

export default connect(mapStateToProps, {
  localCreateOrganisation: createOrganisation,
  localPatchOrganisation: patchOrganisation,
})(OrganisationForm);
