import IconButton from "@material-ui/core/IconButton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import ViewIcon from "@material-ui/icons/OpenInNew";
import React from "react";
import { Link } from "react-router-dom";
import { RiskDto } from "../../../../model/Risk";
import { getRiskLink } from "../../../../routes/routeUtils";
import dates from "../../../../util/dates";
import GridListing from "../../../common/GridListing";
import TimeAgo from "../../../common/TimeAgo";

interface Props {
  risks: RiskDto[];
}

const useStyles = makeStyles(() => ({
  nowrap: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  textWithIcon: {
    display: "flex",
    alignItems: "center",
  },
}));

const RiskBulkUpdateResults: React.FC<Props> = ({ risks }: Props) => {
  const classes = useStyles();

  return (
    <GridListing
      loading={false}
      sortedData={risks}
      action={(risk) => (
        <IconButton component={Link} to={getRiskLink(risk)} target={"_blank"}>
          <ViewIcon />
        </IconButton>
      )}
      columns={[
        {
          label: "Title",
          name: "title",
          size: 3,
          render: (risk: RiskDto) => {
            return (
              <>
                <div className={classes.textWithIcon}>
                  <Typography className={classes.nowrap} title={risk.title}>
                    {risk.title}
                  </Typography>
                </div>
                <Typography
                  className={classes.nowrap}
                  title={risk.friendlyId}
                  variant="caption"
                >
                  # {risk.friendlyId}
                </Typography>
              </>
            );
          },
        },
        {
          label: "Risk rating",
          name: "riskRating_display",
          size: 2,
          sortable: true,
          render: (risk: RiskDto) => {
            return <Typography>{risk.riskRating_display}</Typography>;
          },
        },
        {
          label: "Risk category",
          name: "category_display",
          size: 2,
          sortable: true,
          render: (risk: RiskDto) => {
            return <Typography>{risk.category_display}</Typography>;
          },
        },
        {
          label: "Risk appetite",
          name: "appetite_display",
          size: 2,
          sortable: true,
          render: (risk: RiskDto) => {
            return <Typography>{risk.appetite_display}</Typography>;
          },
        },
        {
          label: "Owner",
          name: "owner",
          size: 2,
          sortable: true,
          render: (risk: RiskDto) => {
            return <Typography>{risk.owner.name}</Typography>;
          },
        },
        {
          label: "Created",
          name: "created",
          size: 2,
          sortable: true,
          render: (risk: RiskDto) => (
            <TimeAgo value={dates.parseTimestamp(risk.created)} expandable />
          ),
        },
        {
          label: "Updated",
          name: "updated",
          size: 2,
          sortable: true,
          render: (risk: RiskDto) => (
            <TimeAgo value={dates.parseTimestamp(risk.updated)} expandable />
          ),
        },
      ]}
    />
  );
};

export default RiskBulkUpdateResults;
