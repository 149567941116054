import { useAuth0 } from "@auth0/auth0-react";
import Card from "@material-ui/core/Card";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import { required } from "redux-form-validators";
import organisationsApi from "../../../services/api/organisations";
import { scrollToFirstInvalidInput } from "../../../util/formHelpers";
import Avatar from "../../common/Avatar.tsx";
import FormError from "../../common/FormError";
import WrappedListPicker from "../wrapper/WrappedListPicker";
import WrappedTextField from "../wrapper/WrappedTextField";

const styles = (theme) => ({
  card: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
});

const DigitalAssetForm = ({ classes, error }) => {
  const { getAccessTokenSilently } = useAuth0();

  const asyncOrganisationOptionsFetch = async (
    pickerFilter,
    pagination,
    abortController
  ) => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = {
      ...pickerFilter,
      limit: pagination.pageSize,
      offset: pagination.offset,
      orderByField: "name",
      excludeGroupFilters: true,
    };
    return organisationsApi.search(
      searchParameters,
      abortController,
      accessToken
    );
  };

  return (
    <form autoComplete="off">
      {error && <FormError errorMessage={error} />}
      <Card className={classes.card} elevation={0}>
        <Field
          name="organisation"
          component={WrappedListPicker}
          datasource={asyncOrganisationOptionsFetch}
          placeholder="Please select an organisation..."
          isMulti={false}
          submitOnChange
          required
          validate={required({ msg: "Organisation is required" })}
          label="Organisation"
          fullWidth
          toOption={(org) => ({
            label: org.name || "Unknown",
            id: org.id,
            org,
          })}
          fromOption={(option) => ({ ...option.org })}
          renderIcon={(user, size) => (
            <Avatar email={user.email} name={user.name} size={size} round />
          )}
        />
        <Field
          name="url"
          component={WrappedTextField}
          label="Url"
          validate={required({ msg: "Url is required" })}
          fullWidth
          required
        />
      </Card>
    </form>
  );
};

DigitalAssetForm.propTypes = {
  classes: PropTypes.object.isRequired,
  error: PropTypes.string,
};

DigitalAssetForm.defaultProps = {
  error: null,
};

export const DIGITAL_ASSET_FORM_NAME = "digitalAssetForm";

const mapStateToProps = () => ({});

const form = reduxForm({
  form: DIGITAL_ASSET_FORM_NAME,
  onSubmitFail: (errors) => scrollToFirstInvalidInput(errors),
});
const reduxData = connect(mapStateToProps, {});

export default compose(form, reduxData)(withStyles(styles)(DigitalAssetForm));
