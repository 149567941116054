import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import { getFileUploadsInProgress, isDragging } from "../../reducers";
import VersionedFileUpload from "../common/VersionedFileUpload";
import FileList from "../common/file/FileList";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  buttonIcon: {
    marginLeft: theme.spacing(1),
  },
  fileUpload: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

const AuditReportFilesCard = ({
  classes,
  readonly,
  documents,
  setDocuments,
  onDocumentClick,
  uploadNamespace,
  dragging,
  filesInProgress,
}) => {
  const [showDropzone, setShowDropzone] = useState(false);

  useEffect(() => {
    if (filesInProgress.length > 0) {
      setShowDropzone(true);
    }
  }, [filesInProgress]);

  return (
    <Card className={classes.root} elevation={0}>
      <CardHeader
        title="Files"
        action={
          readonly ? null : (
            <Tooltip title="Add File" disableFocusListener>
              <Button
                disabled={showDropzone}
                onClick={() => setShowDropzone(true)}
                variant="outlined"
              >
                Upload Files
                <CloudUploadIcon
                  className={classes.buttonIcon}
                  color={showDropzone ? "disabled" : "action"}
                />
              </Button>
            </Tooltip>
          )
        }
      />
      <CardContent>
        <VersionedFileUpload
          existingDocuments={documents}
          className={classes.fileUpload}
          uploadNamespace={uploadNamespace}
          onSubmit={setDocuments}
          onClose={() => setShowDropzone(false)}
          showDropzone={showDropzone || dragging}
        />
        <FileList documents={documents} onFileClick={onDocumentClick} />
      </CardContent>
    </Card>
  );
};

AuditReportFilesCard.propTypes = {
  classes: PropTypes.object.isRequired,
  documents: PropTypes.array.isRequired,
  setDocuments: PropTypes.func.isRequired,
  onDocumentClick: PropTypes.func.isRequired,
  uploadNamespace: PropTypes.string.isRequired,
  readonly: PropTypes.bool.isRequired,
  // redux
  dragging: PropTypes.bool.isRequired,
  filesInProgress: PropTypes.array.isRequired,
};

const mapStateToProps = (state, { uploadNamespace }) => ({
  dragging: isDragging(state),
  filesInProgress: getFileUploadsInProgress(state)[uploadNamespace] || [],
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(withRouter(AuditReportFilesCard));
