import { useAuth0 } from "@auth0/auth0-react";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { formValueSelector } from "redux-form";
import { numericality, required } from "redux-form-validators";
import { getLoggedInUser, getReferenceDataType } from "../../../../reducers";
import usersApi from "../../../../services/api/users";
import logger from "../../../../util/logger";
import { appliesByRegion } from "../../../../util/workItemTypeUtils";
import {
  getCategoryMenuItems,
  getRefDataMenuItems,
} from "../../../forms/menuItems";
import ComponentField from "../../../forms/wrapper/ComponentField";
import WrappedDatePicker from "../../../forms/wrapper/WrappedDatePicker";
import WrappedNonInteractive from "../../../forms/wrapper/WrappedNonInteractive";
import WrappedOwnerPicker from "../../../forms/wrapper/WrappedOwnerPicker";
import WrappedSelect from "../../../forms/wrapper/WrappedSelect";
import WrappedTextField from "../../../forms/wrapper/WrappedTextField";

const styles = (theme) => ({
  categoryWarning: {
    color: theme.palette.warning.main,
    marginTop: theme.spacing(1) / 2,
  },
});

const OverviewFields = ({
  loggedInUser,
  workItemDefinition,
  workItemCategory,
  template,
  hasParent,
  fieldLabels,
  customisations,
  selectedEntityRelationship,
  priorities,
  change,
  untouch,
  formName,
  classes,
  fieldPrefix,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { name, categories, ownableBy } = workItemDefinition;
  const [availableCategories, setAvailableCategories] = useState(categories);

  useEffect(() => {
    if (
      workItemCategory &&
      !availableCategories.some((c) => c.category === workItemCategory)
    ) {
      logger.info("Clearing category as it's no longer an option");
      change(`${fieldPrefix}category`, "");
    }
  }, [workItemCategory, availableCategories]);

  useEffect(() => {
    const filteredCategories = categories.filter((category) =>
      appliesByRegion(
        { entityRelationship: selectedEntityRelationship },
        category
      )
    );
    setAvailableCategories(filteredCategories);
  }, [selectedEntityRelationship]);

  const noCategoryOptionsDueToRegion =
    categories.length > 0 && availableCategories.length === 0;

  const asyncOwnerOptionsFetch = async (
    pickerFilter,
    pagination,
    abortController
  ) => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = {
      ...pickerFilter,
      limit: pagination.pageSize,
      offset: pagination.offset,
      roles: ownableBy,
    };
    return usersApi.search(searchParameters, abortController, accessToken);
  };

  return (
    <>
      {noCategoryOptionsDueToRegion ? (
        <ComponentField
          name="category"
          fieldPrefix={fieldPrefix}
          component={WrappedNonInteractive}
          label={fieldLabels.labels.category}
          fullWidth={false}
          validate={required({
            msg: `${fieldLabels.labels.category} is required`,
          })}
          required
          render={() => (
            <div className={classes.categoryWarning}>
              Please select a product or service provider to obtain category
              options
            </div>
          )}
          customisations={customisations}
          change={change}
          untouch={untouch}
          formName={formName}
          data-cy="category"
        />
      ) : (
        <ComponentField
          name="category"
          fieldPrefix={fieldPrefix}
          component={WrappedSelect}
          required
          validate={required({
            msg: `${fieldLabels.labels.category} is required`,
          })}
          label={fieldLabels.labels.category}
          fullWidth
          customisations={customisations}
          change={change}
          untouch={untouch}
          formName={formName}
          data-cy="category"
        >
          {getCategoryMenuItems(availableCategories)}
        </ComponentField>
      )}
      <ComponentField
        name="title"
        fieldPrefix={fieldPrefix}
        component={WrappedTextField}
        placeholder="Enter a short title"
        label={fieldLabels.labels.title}
        validate={required({ msg: `${fieldLabels.labels.title} is required` })}
        fullWidth
        required
        helperText={`Provide a short title for this ${name.toLowerCase()}`}
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
        data-cy="title"
      />
      {!loggedInUser.externalUser && (
        <ComponentField
          name="owner"
          fieldPrefix={fieldPrefix}
          component={WrappedOwnerPicker}
          datasource={asyncOwnerOptionsFetch}
          isMulti={false}
          submitOnChange
          required
          validate={required({ msg: "Owner is required" })}
          label="Owner"
          fullWidth
          helperText={`The person who will own this ${name.toLowerCase()}`}
          customisations={customisations}
          change={change}
          untouch={untouch}
          formName={formName}
          data-cy="owner"
          loggedInUser={loggedInUser}
        />
      )}
      <ComponentField
        name="priority"
        fieldPrefix={fieldPrefix}
        component={WrappedSelect}
        required
        validate={required({
          msg: `${fieldLabels.labels.priority} is required`,
        })}
        label={fieldLabels.labels.priority}
        fullWidth
        helperText="Use this field to let us know if this is high, medium or low priority"
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
        data-cy="priority"
      >
        {getRefDataMenuItems(priorities).reverse()}
      </ComponentField>
      <ComponentField
        name="priorityReason"
        fieldPrefix={fieldPrefix}
        component={WrappedTextField}
        placeholder="Reason for priority designation"
        label={fieldLabels.labels.priorityReason}
        validateIfRequired={required({
          msg: `${fieldLabels.labels.priorityReason} is required`,
        })}
        fullWidth
        multiline
        rowsMax={10}
        rows={2}
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
        data-cy="notes"
      />
      {template && hasParent && (
        <ComponentField
          name="parentDueDateOffset"
          fieldPrefix={fieldPrefix}
          component={WrappedTextField}
          label={fieldLabels.labels.parentDueDateOffset}
          helperText="Number of days before the primary task is due that this task should be due"
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="end">days</InputAdornment>,
          }}
          required
          validate={numericality({
            int: true,
            ">=": 0,
            allowBlank: false,
          })}
          customisations={customisations}
          change={change}
          untouch={untouch}
          formName={formName}
        />
      )}
      {!workItemDefinition.schedulable && !template && (
        <ComponentField
          component={WrappedDatePicker}
          name="dueDate"
          fieldPrefix={fieldPrefix}
          label={fieldLabels.labels.dueDate}
          fullWidth
          customisations={customisations}
          change={change}
          untouch={untouch}
          formName={formName}
          minDate={Date.now()}
          validateIfRequired={required({
            msg: `${fieldLabels.labels.dueDate} is required`,
          })}
        />
      )}
      {!workItemDefinition.schedulable && (
        <ComponentField
          name="reminderLeadTime"
          fieldPrefix={fieldPrefix}
          component={WrappedTextField}
          label={fieldLabels.labels.reminderLeadTime}
          helperText="Number of days before due date to send reminder"
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="end">days</InputAdornment>,
          }}
          validate={numericality({
            ">": 0,
            int: true,
            allowBlank: true,
          })}
          customisations={customisations}
          change={change}
          untouch={untouch}
          formName={formName}
        />
      )}
      <ComponentField
        name="notes"
        fieldPrefix={fieldPrefix}
        component={WrappedTextField}
        placeholder="Enter notes here"
        label={fieldLabels.labels.notes}
        fullWidth
        multiline
        rowsMax={10}
        rows={2}
        helperText={`Provide a description for this ${name.toLowerCase()}`}
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
        data-cy="notes"
      />
    </>
  );
};

OverviewFields.propTypes = {
  loggedInUser: PropTypes.object,
  workItemDefinition: PropTypes.object.isRequired,
  template: PropTypes.bool.isRequired,
  hasParent: PropTypes.bool.isRequired,
  workItemCategory: PropTypes.string,
  fieldLabels: PropTypes.object.isRequired,
  priorities: PropTypes.object.isRequired,
  customisations: PropTypes.array,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  selectedEntityRelationship: PropTypes.object,
  classes: PropTypes.object.isRequired,
  fieldPrefix: PropTypes.string,
};

OverviewFields.defaultProps = {
  loggedInUser: null,
  workItemCategory: null,
  customisations: [],
  selectedEntityRelationship: {},
  fieldPrefix: "",
};

const mapStateToProps = (state, ownProps) => {
  const reduxFormSelector = formValueSelector(ownProps.formName);
  return {
    loggedInUser: getLoggedInUser(state),
    selectedEntityRelationship: reduxFormSelector(state, "entityRelationship"),
    priorities: getReferenceDataType(state, "Priority"),
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(OverviewFields);
