import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import { omit } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import MultilineText from "./MultilineText";

const styles = (theme) => ({
  button: {
    padding: "5px",
    marginLeft: "10px",
    width: "30px",
    height: "30px",
  },
  gridItem: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "auto",
      "&:last-child": {
        marginLeft: "auto",
      },
    },
  },
});

class InlineInputEditSaveCancel extends Component {
  constructor(props) {
    super(props);

    this.state = { editMode: props.startInEditMode, value: props.value };
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (prevProps.value !== value) {
      if (value !== this.state.value) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ value });
      }
    }
  }

  onCancel = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ editMode: false, value: this.props.value });
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  onSave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.state.value !== this.props.value) {
      this.props
        .onChange(this.state.value)
        .then(() => this.setState({ editMode: false, value: this.props.value }))
        .catch(() =>
          this.setState({ editMode: false, value: this.props.value })
        );
    } else {
      this.setState({ editMode: false, value: this.props.value });
    }
  };

  toEditMode = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ editMode: true });
  };

  render() {
    const rest = omit(
      this.props,
      "classes",
      "value",
      "onChange",
      "startInEditMode",
      "validate",
      "readonly",
      "minWidth"
    );
    const { classes, multiline, validate, readonly, minWidth } = this.props;
    const { editMode, value } = this.state;

    const valid = !validate || validate(value);

    return (
      <div>
        {!editMode && (
          <Grid container alignContent="flex-start" alignItems="center">
            <Grid item className={classes.gridItem}>
              {multiline ? <MultilineText text={value} /> : value}
            </Grid>
            <Grid item className={classes.gridItem}>
              {!readonly && (
                <IconButton
                  classes={{
                    root: classes.button,
                  }}
                  onClick={this.toEditMode}
                  title="Edit"
                >
                  <Icon className={classes.icon}>edit</Icon>
                </IconButton>
              )}
            </Grid>
          </Grid>
        )}
        {editMode && (
          <Input
            style={{ minWidth, font: "inherit" }}
            autoFocus
            value={value}
            onChange={(event) => this.setState({ value: event.target.value })}
            onClick={(event) => event.stopPropagation()}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  classes={{
                    root: classes.button,
                  }}
                  onClick={this.onCancel}
                  title="Cancel"
                >
                  <Icon className={classes.icon}>close</Icon>
                </IconButton>
                <IconButton
                  classes={{
                    root: classes.button,
                  }}
                  onClick={this.onSave}
                  title="Save"
                  disabled={!valid}
                >
                  <Icon className={classes.icon}>done</Icon>
                </IconButton>
              </InputAdornment>
            }
            {...rest}
          />
        )}
      </div>
    );
  }
}

InlineInputEditSaveCancel.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  multiline: PropTypes.bool,
  startInEditMode: PropTypes.bool,
  validate: PropTypes.func,
  readonly: PropTypes.bool,
  minWidth: PropTypes.string,
};

InlineInputEditSaveCancel.defaultProps = {
  value: "",
  multiline: false,
  startInEditMode: false,
  onCancel: null,
  validate: null,
  readonly: false,
  minWidth: undefined,
};

export default withStyles(styles)(InlineInputEditSaveCancel);
