import { useAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import DocumentChips from "../../../common/file/DocumentChips";
import api from "../../../../services/api";

const containsDocuments = (entry) => _.has(entry, "documents");

const useDocuments = (entry) => {
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    if (containsDocuments(entry)) {
      setDocuments(entry.documents);
    }
  }, [entry]);

  return [documents.length > 0, documents];
};

const onFileClick = (source, document, workItemId, accessToken) => {
  api.workItems
    .documentDownloadUrl(workItemId, source, document.id, false, accessToken)
    .then((location) => window.open(location, "_blank"));
};

const Documents = ({ entry, workItemId }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [hasDocuments, documents] = useDocuments(entry);

  const handleFileClick = async (document) => {
    const accessToken = await getAccessTokenSilently();
    onFileClick(entry.documentSource, document, workItemId, accessToken);
  };

  if (!hasDocuments) {
    return null;
  }

  return (
    <DocumentChips documents={documents} onDocumentClick={handleFileClick} />
  );
};

Documents.propTypes = {
  entry: PropTypes.object.isRequired,
  workItemId: PropTypes.string.isRequired,
};

export default Documents;
