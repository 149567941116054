import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { SubmissionError } from "redux-form";
import { loadRiskControl } from "../../actions/riskControls";
import {
  clearActiveWorkItem,
  createWorkItem,
  getWorkItemById,
  loadWorkItems,
  patchWorkItem,
} from "../../actions/workItems";
import {
  getActiveRiskControl,
  getLabels,
  getReferenceDataType,
  getWorkItem,
  getWorkItems,
} from "../../reducers";
import logger from "../../util/logger";
import RiskControlVerificationDetailsCard from "../workitem/components/riskcontrolverification/RiskControlVerificationDetailsCard";
import WorkItemDetailCard from "../workitem/components/overview/WorkItemDetailCard";
import RiskControlVerificationHistoryCard from "./RiskControlVerificationHistoryCard";
import { useAuth0 } from "@auth0/auth0-react";

const RiskControlVerificationCards = ({
  className,
  riskControl,
  types,
  fieldLabels,
  riskControlVerifications,
  currentActiveControlVerification,
  localClearActiveWorkItem,
  localLoadWorkItems,
  localGetWorkItemById,
  localCreateWorkItem,
  localLoadRiskControl,
  localPatchWorkItem,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [openVerificationId, setOpenVerificationId] = useState(null);

  const verificationsForControlSearchParameters = () => ({
    riskControlId: riskControl.id,
    types: ["RISK_CONTROL_VERIFICATION"],
    includeTemplates: false,
    includeChildren: false,
    limit: 100,
    orderByField: "-created",
  });

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      localClearActiveWorkItem();
      localLoadWorkItems(
        verificationsForControlSearchParameters(),
        accessToken
      );
    })();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const currentVerification = riskControlVerifications.find(
      (v) => v.status !== "CLOSED"
    );
    if (currentVerification && currentVerification.id !== openVerificationId) {
      setOpenVerificationId(currentVerification.id);
    } else if (!currentVerification) {
      setOpenVerificationId(null);
    }
  }, [riskControlVerifications]);

  useEffect(() => {
    (async () => {
      if (
        openVerificationId &&
        (!currentActiveControlVerification ||
          openVerificationId != currentActiveControlVerification.id)
      ) {
        const accessToken = await getAccessTokenSilently();
        localGetWorkItemById(openVerificationId, accessToken);
      }
    })();
  }, [openVerificationId, currentActiveControlVerification]);

  const onCreateVerification = async (workItem) => {
    logger.debug(
      "submitting work item of type RiskControlVerification with values",
      workItem
    );

    const accessToken = await getAccessTokenSilently();

    return localCreateWorkItem(workItem, accessToken)
      .then(() => {
        localLoadWorkItems(
          verificationsForControlSearchParameters(),
          accessToken
        );
        localLoadRiskControl(riskControl.id, accessToken);
      })
      .catch((error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  const { definition } = types.values.RISK_CONTROL_VERIFICATION.props;

  const updateWorkItem = async (values) => {
    const accessToken = await getAccessTokenSilently();
    localPatchWorkItem(openVerificationId, values, "Updated", accessToken);
  };

  return (
    <>
      {currentActiveControlVerification && openVerificationId && (
        <Grid container spacing={2}>
          <Grid item sm={12} md={8}>
            <RiskControlVerificationDetailsCard
              workItem={currentActiveControlVerification}
              onChange={updateWorkItem}
              fieldLabels={fieldLabels}
              customisations={definition.fieldCustomisations}
              data-cy={fieldLabels}
            />
          </Grid>
          <Grid item sm={12} md={4}>
            <WorkItemDetailCard
              workItem={currentActiveControlVerification}
              readonly={false}
              template={false}
              fieldLabels={fieldLabels}
              closeable={false}
              data-cy={fieldLabels}
            />
          </Grid>
        </Grid>
      )}
      <RiskControlVerificationHistoryCard
        className={className}
        onCreateVerification={onCreateVerification}
      />
    </>
  );
};

RiskControlVerificationCards.propTypes = {
  className: PropTypes.string,
  riskControl: PropTypes.object,
  riskControlVerifications: PropTypes.array.isRequired,
  currentActiveControlVerification: PropTypes.object,
  types: PropTypes.object.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  localLoadWorkItems: PropTypes.func.isRequired,
  localGetWorkItemById: PropTypes.func.isRequired,
  localClearActiveWorkItem: PropTypes.func.isRequired,
  localCreateWorkItem: PropTypes.func.isRequired,
  localLoadRiskControl: PropTypes.func.isRequired,
  localPatchWorkItem: PropTypes.func.isRequired,
};

RiskControlVerificationCards.defaultProps = {
  className: undefined,
  riskControl: null,
  currentActiveControlVerification: null,
};

const mapStateToProps = (state) => ({
  riskControl: getActiveRiskControl(state),
  riskControlVerifications: getWorkItems(state),
  currentActiveControlVerification: getWorkItem(state),
  types: getReferenceDataType(state, "WorkItemType"),
  fieldLabels: getLabels(state).RISK_CONTROL_VERIFICATION,
});

export default connect(mapStateToProps, {
  localCreateWorkItem: createWorkItem,
  localLoadWorkItems: loadWorkItems,
  localGetWorkItemById: getWorkItemById,
  localClearActiveWorkItem: clearActiveWorkItem,
  localLoadRiskControl: loadRiskControl,
  localPatchWorkItem: patchWorkItem,
})(RiskControlVerificationCards);
