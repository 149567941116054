import { useAuth0 } from "@auth0/auth0-react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  removeTimesheetEntry,
  updateTimesheetEntry,
} from "../../../actions/workItems";
import { getLoggedInUser } from "../../../reducers";
import Avatar from "../../common/Avatar.tsx";
import EditTimesheetDialog from "./EditTimesheetDialog";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  chipRoot: {
    marginRight: theme.spacing(1) / 2,
    marginBottom: theme.spacing(1) / 2,
    marginTop: 0,
  },
  chipIcon: {
    marginLeft: 0,
  },
  label: {
    marginTop: theme.spacing(1) / 2,
  },
});

const TimesheetCard = ({
  classes,
  loggedInUser,
  readonly,
  workItem,
  localUpdateTimesheetEntry,
  localRemoveTimesheetEntry,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleSubmitDialog = async (hours) => {
    const accessToken = await getAccessTokenSilently();
    localUpdateTimesheetEntry(workItem.id, hours, accessToken).then(() =>
      setDialogOpen(false)
    );
  };

  const removeEntry = async () => {
    const accessToken = await getAccessTokenSilently();
    localRemoveTimesheetEntry(workItem.id, accessToken);
  };

  const isEditable = (entry) => {
    return !readonly && loggedInUser && loggedInUser.id === entry.user.id;
  };

  if (!workItem) {
    return <div />;
  }

  const currentUserHours = workItem.timesheetEntries
    .filter((entry) => loggedInUser && entry.user.id === loggedInUser.id)
    .reduce((hours, entry) => entry.hours, 0);

  const totalHours = workItem.timesheetEntries.reduce(
    (total, entry) => total + entry.hours,
    0
  );

  return (
    <>
      <EditTimesheetDialog
        open={dialogOpen}
        onSubmit={handleSubmitDialog}
        onClose={() => setDialogOpen(false)}
        hours={currentUserHours}
      />
      <Card className={classes.root} elevation={0}>
        <CardHeader
          title="Time spent"
          action={
            !readonly && (
              <Tooltip title="Enter time spent" disableFocusListener>
                <IconButton onClick={() => setDialogOpen(true)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )
          }
        />
        <CardContent>
          {workItem.timesheetEntries.length === 0 && <p>No time entered.</p>}
          <Grid container>
            {workItem.timesheetEntries.map((entry) => (
              <Grid item key={entry.user.id}>
                <Tooltip title={entry.user.name} disableFocusListener>
                  <Chip
                    classes={{
                      root: classes.chipRoot,
                      icon: classes.chipIcon,
                    }}
                    icon={
                      <Avatar
                        email={entry.user.email}
                        name={entry.user.name}
                        size={30}
                        round
                      />
                    }
                    label={`${entry.hours} hours`}
                    onClick={
                      isEditable(entry) ? () => setDialogOpen(true) : null
                    }
                    onDelete={isEditable(entry) ? removeEntry : null}
                  />
                </Tooltip>
              </Grid>
            ))}
          </Grid>
        </CardContent>
        {workItem.timesheetEntries.length > 0 && (
          <CardContent>
            <strong>Total {totalHours} hours</strong>
          </CardContent>
        )}
      </Card>
    </>
  );
};

TimesheetCard.propTypes = {
  classes: PropTypes.object.isRequired,
  readonly: PropTypes.bool.isRequired,
  localUpdateTimesheetEntry: PropTypes.func.isRequired,
  localRemoveTimesheetEntry: PropTypes.func.isRequired,
  loggedInUser: PropTypes.object,
  workItem: PropTypes.object,
};

TimesheetCard.defaultProps = {
  workItem: null,
  loggedInUser: null,
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    localUpdateTimesheetEntry: updateTimesheetEntry,
    localRemoveTimesheetEntry: removeTimesheetEntry,
  })
)(TimesheetCard);
