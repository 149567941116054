import { WorkItemSummaryDto } from "../../../model/WorkItem";
import { requestJSON } from "../http";

const decorateSearchParameters = (filter: SearchParams) =>
  filter ? { __type: "WorkItem", ...filter } : { __type: "WorkItem" };

interface SearchParams {
  limit?: number;
  offset?: number;
  orderByField?: string;
  textSearch?: string;
  types?: string[];
  productIds?: string[];
  states?: string[];
  ownerIds?: string[];
}

export interface WorkItemBulkUpdateRequest {
  workItemSearchParameters: SearchParams;
  userId: string;
  dryRun: boolean;
}

interface Results {
  data: WorkItemSummaryDto[];
  meta: unknown;
}

const updateWorkItemUsers = (
  request: WorkItemBulkUpdateRequest,
  accessToken: string,
  abortController?: AbortController
): Promise<Results> => {
  const workItemSearchParameters = decorateSearchParameters(
    request.workItemSearchParameters
  );
  return requestJSON({
    path: "/bulk-updates/work-items/users",
    method: "POST",
    data: { ...request, workItemSearchParameters },
    abortController,
    accessToken,
  });
};

export default {
  updateWorkItemUsers,
};
