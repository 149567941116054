import React from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import Container from "../common/Container";

const Error404 = () => (
  <Container>
    <Grid container style={{ margin: 24 }}>
      <Grid item>
        <h1>Not Found</h1>
        <p>Sorry, we can&#39;t find the page you&#39;re looking for.</p>
        <Button variant="contained" color="primary" component={Link} to="/">
          Home
        </Button>
      </Grid>
    </Grid>
  </Container>
);

export default Error404;
