import { normalize } from "normalizr";
import schemas from "../schemas";
import api from "../services/api";
import workItemsApi from "../services/api/workItems";
import { asyncAction } from "./actions";

export const WORK_ITEM_ADD_CHILD = "WORK_ITEM_ADD_CHILD";
export const WORK_ITEM_ADD_ENDORSEMENT = "WORK_ITEM_ADD_ENDORSEMENT";
export const WORK_ITEM_ADD_CLAIM_ASSESSMENT = "WORK_ITEM_ADD_CLAIM_ASSESSMENT";
export const WORK_ITEM_EDIT_CLAIM_ASSESSMENT =
  "WORK_ITEM_EDIT_CLAIM_ASSESSMENT";
export const WORK_ITEM_LIST_CHILDREN = "WORK_ITEM_LIST_CHILDREN";
export const WORK_ITEM_CLEAR_ACTIVE = "WORK_ITEM_CLEAR_ACTIVE";
export const WORK_ITEM_CLEAR_METRICS = "WORK_ITEM_CLEAR_METRICS";
export const WORK_ITEM_CREATE = "WORK_ITEM_CREATE";
export const WORK_ITEM_DELETE_DOCUMENT = "WORK_ITEM_DELETE_DOCUMENT";
export const WORK_ITEM_GET_BY_ID = "WORK_ITEM_GET_BY_ID";
export const WORK_ITEM_LIST = "WORK_ITEM_LIST";
export const WORK_ITEM_LOAD_METRIC = "WORK_ITEM_LOAD_METRIC";
export const WORK_ITEM_PARENT = "WORK_ITEM_PARENT";
export const WORK_ITEM_PATCH = "WORK_ITEM_PATCH";
export const WORK_ITEM_QUESTION_ADD = "WORK_ITEM_QUESTION_ADD";
export const WORK_ITEM_QUESTION_REMOVE = "WORK_ITEM_QUESTION_REMOVE";
export const WORK_ITEM_QUESTION_PATCH = "WORK_ITEM_QUESTION_PATCH";
export const WORK_ITEM_PERFORM_ACTION = "WORK_ITEM_PERFORM_ACTION";
export const WORK_ITEM_REMOVE_CHILD = "WORK_ITEM_REMOVE_CHILD";
export const WORK_ITEM_REMOVE_USER = "WORK_ITEM_REMOVE_USER";
export const WORK_ITEM_REMOVE_ENDORSEMENT = "WORK_ITEM_REMOVE_ENDORSEMENT";
export const WORK_ITEM_REMOVE_OCCURRENCE = "WORK_ITEM_REMOVE_OCCURRENCE";
export const WORK_ITEM_REMOVE_TIMESHEET_ENTRY =
  "WORK_ITEM_REMOVE_TIMESHEET_ENTRY";
export const WORK_ITEM_SAVE_DOCUMENTS = "WORK_ITEM_SAVE_DOCUMENTS";
export const WORK_ITEM_SCHEDULES_FETCH_OCCURRENCES =
  "WORK_ITEM_SCHEDULES_FETCH_OCCURRENCES";
export const WORK_ITEM_SET_USERS = "WORK_ITEM_SET_USERS";
export const WORK_ITEM_SET_DOCUMENT_FLAGS = "WORK_ITEM_SET_DOCUMENT_FLAGS";
export const WORK_ITEM_SET_SIMPLE_REMINDER = "WORK_ITEM_SET_SIMPLE_REMINDER";
export const WORK_ITEM_SET_STATUS = "WORK_ITEM_SET_STATUS";
export const WORK_ITEM_SET_VISIBLE_TO_OWNER = "WORK_ITEM_SET_VISIBLE_TO_OWNER";
export const WORK_ITEM_SWITCH_USER = "WORK_ITEM_SWITCH_USER";
export const WORK_ITEM_UPDATE_TIMESHEET_ENTRY =
  "WORK_ITEM_UPDATE_TIMESHEET_ENTRY";

export function clearActiveWorkItem() {
  return {
    type: WORK_ITEM_CLEAR_ACTIVE,
  };
}

export const loadMetric = (metric, filter, accessToken) =>
  asyncAction(
    WORK_ITEM_LOAD_METRIC,
    filter
      ? workItemsApi.count(filter, accessToken)
      : workItemsApi.getMetric(metric, accessToken),
    {
      responseTransformer: (results) => ({
        count: results.count,
        metric,
      }),
      actionParams: { nonBlocking: true },
    }
  );

export function clearWorkItemMetrics() {
  return {
    type: WORK_ITEM_CLEAR_METRICS,
  };
}

export const loadWorkItems = (filter, accessToken) => {
  const abortController = window.AbortController ? new AbortController() : null;
  return asyncAction(
    WORK_ITEM_LIST,
    workItemsApi.search(filter, abortController, accessToken),
    {
      responseTransformer: (response) => ({
        pagination: {
          offset: response.offset,
          previousOffset: response.previousOffset,
          nextOffset: response.nextOffset,
          pageSize: response.pageSize,
          resultCount: response.resultCount,
        },
        ...normalize(response.results, schemas.arrayOfWorkItems),
      }),
      actionParams: {
        abortController,
        nonBlocking: true,
      },
    }
  );
};

export const loadWorkItemChildren = (workItemId, accessToken) =>
  asyncAction(
    WORK_ITEM_LIST_CHILDREN,
    workItemsApi.listChildren(workItemId, accessToken),
    {
      responseTransformer: (workItems) =>
        normalize(workItems, schemas.arrayOfWorkItems),
    }
  );

export const loadWorkItemParent = (workItemId, accessToken) =>
  asyncAction(
    WORK_ITEM_PARENT,
    workItemsApi.getParent(workItemId, accessToken),
    {
      responseTransformer: (response) =>
        response ? normalize(response, schemas.workItem) : null, // parent response may be blank
    }
  );

export const createWorkItem = (workItem, accessToken) =>
  asyncAction(WORK_ITEM_CREATE, workItemsApi.create(workItem, accessToken), {
    responseTransformer: (response) => normalize(response, schemas.workItem),
    actionParams: {
      isUpdate: true,
    },
  });

export const addChildWorkItem = (
  parentWorkItemId,
  childWorkItem,
  onSuccess,
  accessToken
) =>
  asyncAction(
    WORK_ITEM_ADD_CHILD,
    workItemsApi.addChild(parentWorkItemId, childWorkItem, accessToken),
    {
      onSuccess: onSuccess || undefined,
      responseTransformer: (response) => normalize(response, schemas.workItem),
      actionParams: {
        isUpdate: true,
      },
    }
  );

export const removeChildWorkItem = (
  parentWorkItemId,
  childWorkItemId,
  onSuccess,
  accessToken
) =>
  asyncAction(
    WORK_ITEM_REMOVE_CHILD,
    workItemsApi.remove(childWorkItemId, accessToken),
    {
      onSuccess: onSuccess || undefined,
      responseTransformer: () => ({ id: childWorkItemId }),
      actionParams: {
        isUpdate: true,
      },
    }
  );

export const patchWorkItem = (workItemId, changes, description, accessToken) =>
  asyncAction(
    WORK_ITEM_PATCH,
    workItemsApi.patch(workItemId, changes, description, accessToken),
    {
      responseTransformer: (response) => normalize(response, schemas.workItem),
      actionParams: {
        isUpdate: true,
      },
    }
  );

export const addQuestion = (workItemId, question, accessToken) =>
  asyncAction(
    WORK_ITEM_QUESTION_ADD,
    workItemsApi.addQuestion(workItemId, question, accessToken),
    {
      responseTransformer: (response) => normalize(response, schemas.workItem),
      actionParams: {
        isUpdate: true,
      },
    }
  );

export const removeQuestion = (workItemId, questionId, accessToken) =>
  asyncAction(
    WORK_ITEM_QUESTION_REMOVE,
    workItemsApi.removeQuestion(workItemId, questionId, accessToken),
    {
      responseTransformer: (response) => normalize(response, schemas.workItem),
      actionParams: {
        isUpdate: true,
      },
    }
  );

export const patchQuestion = (
  workItemId,
  questionId,
  changes,
  description,
  accessToken
) =>
  asyncAction(
    WORK_ITEM_QUESTION_PATCH,
    workItemsApi.patchQuestion(
      workItemId,
      questionId,
      changes,
      description,
      accessToken
    ),
    {
      responseTransformer: (response) => normalize(response, schemas.workItem),
      actionParams: {
        isUpdate: true,
      },
    }
  );

export const performActionOnWorkItem = (workItemId, params, accessToken) =>
  asyncAction(
    WORK_ITEM_PERFORM_ACTION,
    workItemsApi.performAction(workItemId, params, accessToken),
    {
      responseTransformer: (response) => normalize(response, schemas.workItem),
      actionParams: {
        isUpdate: true,
      },
    }
  );

export const fetchWorkItemScheduleOccurrences = (
  workItemScheduleId,
  accessToken
) =>
  asyncAction(
    WORK_ITEM_SCHEDULES_FETCH_OCCURRENCES,
    workItemsApi.fullSearch(
      {
        scheduleId: workItemScheduleId,
        includeTemplates: false,
        includeChildren: false,
        limit: 100,
      },
      null,
      accessToken
    ),
    {
      responseTransformer: (workItems) =>
        normalize(workItems, schemas.arrayOfWorkItems),
    }
  );

export const removeWorkItemOccurrence = (workItemId, accessToken) =>
  asyncAction(
    WORK_ITEM_REMOVE_OCCURRENCE,
    workItemsApi.remove(workItemId, accessToken),
    {
      responseTransformer: () => ({ id: workItemId }),
      actionParams: {
        isUpdate: true,
      },
    }
  );

export const getWorkItemById = (workItemId, accessToken) =>
  asyncAction(WORK_ITEM_GET_BY_ID, workItemsApi.get(workItemId, accessToken), {
    responseTransformer: (response) => normalize(response, schemas.workItem),
  });

export const setWorkItemUsers = (
  { workItemId, userIds, reason },
  accessToken
) =>
  asyncAction(
    WORK_ITEM_SET_USERS,
    workItemsApi.setUsers(workItemId, userIds, reason, accessToken),
    {
      responseTransformer: (response) => normalize(response, schemas.workItem),
      actionParams: {
        isUpdate: true,
      },
    }
  );

export const removeWorkItemUser = (workItemId, type, userId, accessToken) =>
  asyncAction(
    WORK_ITEM_REMOVE_USER,
    workItemsApi.removeUser(workItemId, type, userId, accessToken),
    {
      responseTransformer: (response) => normalize(response, schemas.workItem),
      actionParams: {
        isUpdate: true,
      },
    }
  );

export const setWorkItemStatus = (
  workItemId,
  status,
  outcome,
  completionDate,
  onSuccess,
  accessToken
) =>
  asyncAction(
    WORK_ITEM_SET_STATUS,
    workItemsApi.setStatus(
      workItemId,
      status,
      outcome,
      completionDate,
      accessToken
    ),
    {
      onSuccess: onSuccess || undefined,
      responseTransformer: (response) => normalize(response, schemas.workItem),
      actionParams: {
        isUpdate: true,
      },
    }
  );

export const removeEndorsement = (workItemId, endorsementId, accessToken) =>
  asyncAction(
    WORK_ITEM_REMOVE_ENDORSEMENT,
    workItemsApi.removeEndorsement(workItemId, endorsementId, accessToken),
    {
      responseTransformer: (response) => normalize(response, schemas.workItem),
      actionParams: {
        isUpdate: true,
      },
    }
  );

export const setSimpleReminder = (workItemId, simpleReminder, accessToken) =>
  asyncAction(
    WORK_ITEM_SET_SIMPLE_REMINDER,
    workItemsApi.setSimpleReminder(workItemId, simpleReminder, accessToken),
    {
      responseTransformer: (response) => normalize(response, schemas.workItem),
      actionParams: {
        isUpdate: true,
      },
    }
  );

export const updateTimesheetEntry = (workItemId, hours, accessToken) =>
  asyncAction(
    WORK_ITEM_UPDATE_TIMESHEET_ENTRY,
    workItemsApi.updateTimesheetEntry(workItemId, hours, accessToken),
    {
      responseTransformer: (response) => normalize(response, schemas.workItem),
      actionParams: {
        isUpdate: true,
      },
    }
  );

export const removeTimesheetEntry = (workItemId, accessToken) =>
  asyncAction(
    WORK_ITEM_REMOVE_TIMESHEET_ENTRY,
    workItemsApi.removeTimesheetEntry(workItemId, accessToken),
    {
      responseTransformer: (response) => normalize(response, schemas.workItem),
      actionParams: {
        isUpdate: true,
      },
    }
  );

export const addClaimAssessment = (workItemId, claimAssessment, accessToken) =>
  asyncAction(
    WORK_ITEM_ADD_CLAIM_ASSESSMENT,
    workItemsApi.addClaimAssessment(workItemId, claimAssessment, accessToken),
    {
      responseTransformer: (response) => normalize(response, schemas.workItem),
      actionParams: {
        isUpdate: true,
      },
    }
  );

export const editClaimAssessment = (workItemId, index, changes, accessToken) =>
  asyncAction(
    WORK_ITEM_EDIT_CLAIM_ASSESSMENT,
    workItemsApi.editClaimAssessment(workItemId, index, changes, accessToken),
    {
      responseTransformer: (response) => normalize(response, schemas.workItem),
      actionParams: {
        isUpdate: true,
      },
    }
  );

export const setDocumentFlags = (workItemId, document, flags, accessToken) =>
  asyncAction(
    WORK_ITEM_SET_DOCUMENT_FLAGS,
    workItemsApi.setDocumentFlags(workItemId, document, flags, accessToken),
    {
      responseTransformer: (response) => normalize(response, schemas.workItem),
      actionParams: {
        isUpdate: true,
      },
    }
  );

export const saveDocuments = (workItemId, documents, accessToken) =>
  asyncAction(
    WORK_ITEM_SAVE_DOCUMENTS,
    api.workItems.updateDocuments(workItemId, documents, accessToken),
    {
      responseTransformer: (response) => normalize(response, schemas.workItem),
      actionParams: {
        isUpdate: true,
      },
    }
  );

export const deleteDocument = (workItemId, source, documentId, accessToken) =>
  asyncAction(
    WORK_ITEM_DELETE_DOCUMENT,
    api.workItems.deleteDocument(workItemId, source, documentId, accessToken),
    {
      responseTransformer: (response) => normalize(response, schemas.workItem),
      actionParams: {
        isUpdate: true,
      },
    }
  );
