import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useRef, useState } from "react";
import { publishToastSuccess } from "../../services/toasts";
import logger from "../../util/logger";
import draftsApi from "../../services/api/drafts";

const getDraftFromHash = () => {
  const h = window.location.hash;
  return h ? h.substring(1) : null;
};
const setDraftOnHash = (draftId) => {
  const currentDraftId = getDraftFromHash();
  if (currentDraftId !== draftId) {
    window.location.hash = `#${draftId}`;
  }
};
const clearDraftOnHash = () => {
  history.pushState(
    "",
    document.title,
    window.location.pathname + window.location.search
  );
};

export const useDraftForm = (type) => {
  const { getAccessTokenSilently } = useAuth0();
  const [activeDraft, setActiveDraft] = useState(null);
  const savedDraftUrlId = useRef(getDraftFromHash());

  useEffect(() => {
    const draftId = getDraftFromHash();
    logger.debug("Got url draftId param", draftId);
    savedDraftUrlId.current = draftId;

    if (draftId && (!activeDraft || activeDraft.id !== draftId)) {
      (async () => {
        const accessToken = await getAccessTokenSilently();
        draftsApi.getById(draftId, accessToken).then((d) => setActiveDraft(d));
      })();
    }
  }, [window.location.hash]);

  const saveDraft = async (values) => {
    const accessToken = await getAccessTokenSilently();
    logger.debug(`saving ${type} with values as draft`, values);

    const draftData = JSON.stringify(values);
    const { documents } = values;

    if (!!savedDraftUrlId.current) {
      return draftsApi
        .patch(
          savedDraftUrlId.current,
          { draftData, documents },
          "Updated draft",
          accessToken
        )
        .then((draft) => {
          setActiveDraft(draft);
          publishToastSuccess("Updated draft");
        });
    } else {
      return draftsApi
        .create({ type, draftData, documents }, accessToken)
        .then((draft) => {
          setActiveDraft(draft);
          setDraftOnHash(draft.id);
          savedDraftUrlId.current = draft.id;
          publishToastSuccess("Saved draft");
        });
    }
  };

  const removeDraft = async () => {
    const accessToken = await getAccessTokenSilently();
    return draftsApi
      .remove(savedDraftUrlId.current, accessToken)
      .then(() => clearDraftOnHash());
  };

  // [hasDraft, activeDraft, saveDraft, removeDraft]
  return [!!savedDraftUrlId.current, activeDraft, saveDraft, removeDraft];
};
