import React from "react";
import PropTypes from "prop-types";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Tooltip from "@material-ui/core/Tooltip";
import makeStyles from "@material-ui/core/styles/makeStyles";
import List from "@material-ui/core/List";
import dates from "../../../util/dates";
import TimeAgo from "../TimeAgo";
import FileIcon from "../../workitem/FileIcon";

const useStyles = makeStyles(() => ({
  listItemPrimary: {
    color: "#515151",
  },
}));

const BasicFileList = ({
  className,
  documents,
  icon,
  primaryText,
  secondaryText,
  primaryAction,
  secondaryAction,
}) => {
  const classes = useStyles();

  return (
    <List className={className}>
      {documents.map((doc) => (
        <ListItem
          key={doc.gcsObjectName}
          button={!!primaryAction}
          onClick={primaryAction ? primaryAction(doc) : null}
        >
          <ListItemIcon>{icon(doc)}</ListItemIcon>
          <ListItemText
            classes={{ primary: classes.listItemPrimary }}
            primary={primaryText ? primaryText(doc) : doc.fileName}
            secondary={
              secondaryText ? (
                secondaryText(doc)
              ) : (
                <>
                  <Tooltip
                    title={`Uploaded ${dates.parseTimestamp(doc.uploaded)}`}
                    disableFocusListener
                  >
                    <span>
                      <TimeAgo value={dates.parseTimestamp(doc.uploaded)} />
                    </span>
                  </Tooltip>
                  <span>
                    {" "}
                    by <strong>{doc.createdByName}</strong>
                  </span>
                </>
              )
            }
          />
          {secondaryAction ? secondaryAction(doc) : null}
        </ListItem>
      ))}
    </List>
  );
};

BasicFileList.propTypes = {
  className: PropTypes.string,
  documents: PropTypes.array.isRequired,
  icon: PropTypes.func,
  primaryText: PropTypes.func,
  secondaryText: PropTypes.func,
  primaryAction: PropTypes.func,
  secondaryAction: PropTypes.func,
};

BasicFileList.defaultProps = {
  className: undefined,
  icon: (doc) => <FileIcon document={doc} />,
  primaryText: null,
  secondaryText: null,
  primaryAction: null,
  secondaryAction: null,
};

export default BasicFileList;
