import { Close } from "@material-ui/icons";
import React from "react";
import { IconButton, withStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const styles = (theme) => ({
  headerButton: {
    padding: theme.spacing(1),
  },
  headerIcon: {
    fontSize: 18,
    color: "white",
  },
});

const CloseButton = ({ classes, onClick }) => (
  <IconButton size="small" className={classes.headerButton} onClick={onClick}>
    <Close className={classes.headerIcon} color="inherit" />
  </IconButton>
);

CloseButton.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

CloseButton.defaultProps = {
  onClick: () => {
    // do nothing.
  },
};

export default withStyles(styles)(CloseButton);
