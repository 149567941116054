import { useAuth0 } from "@auth0/auth0-react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import { patchAuditReport } from "../../actions/auditReports";
import {
  getActiveAuditReport,
  getLabels,
  getReferenceDataType,
} from "../../reducers";
import dates from "../../util/dates";
import FormCard from "../common/FormCard";
import MultilineText from "../common/MultilineText";
import Scorecard from "../common/Scorecard";
import SimpleChip from "../common/SimpleChip";
import SimpleChips from "../common/SimpleChips";
import CreateAuditReportForm, {
  AUDIT_REPORT_FORM_NAME,
} from "../forms/audit/CreateAuditReportForm";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
});

const AuditReportDetailsCard = ({ readonly, localPatchAuditReport }) => {
  const { getAccessTokenSilently } = useAuth0();

  const auditReport = useSelector(getActiveAuditReport);
  const labels = useSelector(getLabels);
  const priorities = useSelector((state) =>
    getReferenceDataType(state, "Priority")
  );
  const auditTypes = useSelector((state) =>
    getReferenceDataType(state, "AuditType")
  );

  const handleSubmitEdit = async (values) => {
    const accessToken = await getAccessTokenSilently();
    localPatchAuditReport(auditReport.id, values, "Edited audit", accessToken);
  };

  const initialValues = {
    ...auditReport,
  };

  const fieldLabels = labels.AuditReport;

  return (
    <>
      <FormCard
        title="Audit details"
        formTitle="Edit audit"
        readOnlyView={() => (
          <>
            <Scorecard label={fieldLabels.labels.title}>
              {auditReport.title ? auditReport.title : "-"}
            </Scorecard>
            <Scorecard label={fieldLabels.labels.period}>
              <span>
                {dates.formatDateLong(auditReport.period.from)} to{" "}
                {dates.formatDateLong(auditReport.period.to)}
              </span>
            </Scorecard>
            <Scorecard label={fieldLabels.labels.description}>
              <MultilineText text={auditReport.description} defaultText="-" />
            </Scorecard>
            <Scorecard label={fieldLabels.labels.auditor}>
              {auditReport.auditor ? (
                <SimpleChip
                  key={auditReport.auditor.id}
                  label={auditReport.auditor.text}
                />
              ) : (
                "-"
              )}
            </Scorecard>
            <Scorecard label={fieldLabels.labels.type}>
              {auditReport.type && auditTypes.values[auditReport.type]
                ? auditTypes.values[auditReport.type].description
                : "-"}
            </Scorecard>
            <Scorecard label={fieldLabels.labels.priority}>
              {auditReport.priority && priorities.values[auditReport.priority]
                ? priorities.values[auditReport.priority].description
                : "-"}
            </Scorecard>
            <Scorecard label={fieldLabels.labels.owner}>
              {auditReport.owner ? auditReport.owner.name : "-"}
            </Scorecard>
            <Scorecard label={fieldLabels.labels.sargonServiceProviders}>
              <SimpleChips
                values={auditReport.sargonServiceProviders.map((sp) => sp.name)}
                blankLabel="-"
              />
            </Scorecard>
            <Scorecard label={fieldLabels.labels.barccAction}>
              <MultilineText text={auditReport.barccAction} defaultText="-" />
            </Scorecard>
          </>
        )}
        formComponent={CreateAuditReportForm}
        formName={AUDIT_REPORT_FORM_NAME}
        onSave={!readonly ? (values) => handleSubmitEdit(values) : null}
        initialValues={initialValues}
        formProps={{
          maxWidth: "md",
          onCreate: false,
        }}
      />
    </>
  );
};

AuditReportDetailsCard.propTypes = {
  readonly: PropTypes.bool.isRequired,
  localPatchAuditReport: PropTypes.func.isRequired,
};

export default compose(
  withStyles(styles),
  connect(null, {
    localPatchAuditReport: patchAuditReport,
  })
)(withRouter(AuditReportDetailsCard));
