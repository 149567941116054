import * as PropTypes from "prop-types";
import React, { Component } from "react";
import { logComponentError } from "../util/errors";
import logger from "../util/logger";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps) {
    if (prevProps.children !== this.props.children) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        hasError: false,
      });
    }
  }

  componentDidCatch(error, { componentStack }) {
    logComponentError(error, componentStack);
    logger.error(componentStack, error);

    this.setState({ hasError: true, error });
  }

  render() {
    const { hasError, error, info } = this.state;
    const { children, renderError: Error } = this.props;

    if (hasError) {
      if (Error) {
        return <Error error={error} info={info} />;
      }
      return <div>An error has occurred and been logged.</div>;
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  renderError: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

ErrorBoundary.defaultProps = {
  renderError: undefined,
};

export default ErrorBoundary;
