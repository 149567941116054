import { useAuth0 } from "@auth0/auth0-react";
import Divider from "@material-ui/core/Divider";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import useTheme from "@material-ui/core/styles/useTheme";
import { searchQuestions as SearchQuestions } from "../../actions/questions";
import { performActionOnWorkItem as PerformActionOnWorkItem } from "../../actions/workItems";
import {
  getLoggedInUser,
  getQuestions,
  getQuestionsSearchPagination,
  getReferenceDataType,
  isLoading,
} from "../../reducers";
import { getWorkItemLink } from "../../routes/routeUtils";
import dates from "../../util/dates";
import logger from "../../util/logger";
import QuestionHandler from "../../util/questions";
import { appliesByTemplate } from "../../util/workItemTypeUtils";
import { getFirstWorkItemUserByType } from "../../util/workItemUserUtils";
import DueAgo from "../common/DueAgo";
import GridListing from "../common/GridListing";
import MultilineText from "../common/MultilineText";
import Pagination from "../common/Pagination";
import DateButtonPicker from "../DateButtonPicker";
import FormDialog from "../forms/FormDialog";
import QuestionResponseForm, {
  QUESTION_RESPONSE_FORM_NAME,
} from "../workitem/components/questions/QuestionResponseForm";
import ComplianceDetails from "../workitem/components/compliance/ComplianceDetails";
import WorkItemOwner from "../workitem/components/overview/WorkItemOwner";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  primaryText: {
    display: "block",
    fontSize: "0.875rem",
    fontWeight: "600",
  },
  workItemCell: {
    cursor: "pointer",
  },
  nowrap: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  textWithIcon: {
    display: "flex",
    alignItems: "center",
  },
  questionTextCollapse: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    paddingRight: theme.spacing(2),
    margin: "0 auto",
  },
  iconSm: {
    fontSize: 16,
  },
  due: {
    color: theme.due.ok,
  },
  overdue: {
    color: theme.due.overdue,
  },
  popupTitle: {
    margin: `${theme.spacing(1.5)}px 0`,
  },
  divider: {
    margin: `${theme.spacing(2)}px 0`,
  },
});

const defaultPageSize = 50;

const QuestionsList = ({
  classes,
  loading,
  filter,
  pagination,
  questions,
  loggedInUser,
  clearFilter,
  performActionOnWorkItem,
  searchQuestions,
  history,
  types,
  updateSort,
  sortBy,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const questionHandler = new QuestionHandler(loggedInUser);

  const refreshData = async (offset) => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = {
      ...filter,
      orderByField:
        sortBy.direction === "asc" ? sortBy.field : `-${sortBy.field}`,
      limit: defaultPageSize,
      offset,
    };
    return searchQuestions(searchParameters, accessToken);
  };

  useEffect(() => {
    refreshData(pagination.offset);
  }, [filter, sortBy]);

  // parentWorkItem

  const handlePrevious = () => {
    refreshData(pagination.previousOffset);
  };

  const handleNext = () => {
    refreshData(pagination.nextOffset);
  };

  const createWorkItemLink = (workItem) => getWorkItemLink(workItem);

  const handleOpenDialog = (question) => {
    setSelectedQuestion(question);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setSelectedQuestion(null);
    setOpen(false);
  };

  const onResponse = async (values, cb) => {
    const accessToken = await getAccessTokenSilently();
    if (!selectedQuestion) {
      return;
    }

    const workItem = selectedQuestion.parentWorkItem;

    performActionOnWorkItem(
      workItem.id,
      {
        action: "RESPOND_TO_QUESTION",
        questionId: selectedQuestion.id,
        draft: values.justificationNotes,
        compliant: values.compliant,
        attachments: values.attachments,
      },
      accessToken
    ).then(() => cb());
  };

  const handleSubmitResponse = async (values) => {
    logger.info("saving response", values, "for question", selectedQuestion.id);
    await onResponse(values, () => {
      handleCloseDialog();
      refreshData(pagination.offset);
    });
  };

  const getAdditionalFields = () => {
    if (!selectedQuestion) {
      return null;
    }

    return (
      <>
        <Typography variant="body1">
          {`${selectedQuestion.parentWorkItem.title} details:`}
        </Typography>
        <ComplianceDetails workItem={selectedQuestion.parentWorkItem} />{" "}
        <Divider className={classes.divider} />{" "}
        <Typography variant="body1" className={classes.popupTitle}>
          Question:
        </Typography>
        <MultilineText text={selectedQuestion.text} defaultText="-" />
        <Divider className={classes.divider} />
      </>
    );
  };

  if (loading || !loggedInUser) {
    return <p>Loading...</p>;
  }

  return (
    <>
      {selectedQuestion && (
        <FormDialog
          title="Respond To Question"
          submitButtonText="Submit"
          formComponent={QuestionResponseForm}
          formName={QUESTION_RESPONSE_FORM_NAME}
          open={open && !!selectedQuestion}
          onCancel={handleCloseDialog}
          onSubmit={handleSubmitResponse}
          initialValues={
            selectedQuestion
              ? questionHandler.getMyResponse(selectedQuestion)
              : null
          }
          justificationNotesInput={selectedQuestion?.justificationNotesInput}
          attachmentsInput={selectedQuestion?.attachmentsInput}
          additionalFields={getAdditionalFields()}
        />
      )}
      <GridListing
        sortedData={questions}
        loading={loading}
        sortBy={sortBy}
        updateSort={(field, direction) => updateSort({ field, direction })}
        clearFilter={clearFilter}
        action={(question) => (
          <Tooltip title="Edit response" placement="top" disableFocusListener>
            <IconButton
              aria-label="Edit response"
              onClick={() => handleOpenDialog(question)}
            >
              <Icon>edit</Icon>
            </IconButton>
          </Tooltip>
        )}
        columns={[
          {
            label: "Work Item",
            name: "workItemTitle",
            size: 2,
            sortable: true,
            render: (question) => {
              const workItem = question.parentWorkItem;
              const { category } = workItem;

              return (
                <div
                  onClick={() => history.push(createWorkItemLink(workItem))}
                  className={classes.workItemCell}
                >
                  <div className={classes.textWithIcon}>
                    <Typography
                      className={classes.nowrap}
                      title={workItem.title}
                    >
                      {workItem.title}
                    </Typography>
                  </div>
                  <Typography
                    className={classes.nowrap}
                    title={workItem.friendlyId}
                    variant="caption"
                    style={{ color: "#778088" }}
                  >
                    {category}
                  </Typography>
                </div>
              );
            },
          },
          {
            label: "Owner",
            name: "owner",
            size: 2,
            render: (question) => {
              const workItem = question.parentWorkItem;
              const { ownableBy, keyContactRules } =
                types.values[workItem.type].props.definition;
              const keyContactableBy = appliesByTemplate(
                workItem,
                keyContactRules
              )
                ? keyContactRules.roles
                : [];
              return (
                <>
                  <WorkItemOwner
                    className={classes.owner}
                    owner={
                      getFirstWorkItemUserByType(workItem.users, "OWNER")?.user
                    }
                    userRoles={ownableBy}
                    loggedInUser={loggedInUser}
                    onOwnerChange={null}
                    readonly
                  />
                  {keyContactableBy.length > 0 && (
                    <WorkItemOwner
                      className={classes.keyContact}
                      owner={
                        getFirstWorkItemUserByType(
                          workItem.users,
                          "KEY_CONTACT"
                        )?.user
                      }
                      userRoles={keyContactableBy}
                      loggedInUser={loggedInUser}
                      onOwnerChange={null}
                      readonly
                      title="Select key contact"
                      noValueText="No key contact"
                    />
                  )}
                </>
              );
            },
          },
          {
            label: "Due",
            name: "workItemDueDate",
            size: 2,
            sortable: true,
            render: (question) => {
              const workItem = question.parentWorkItem;
              const dueDate = dates.parseDate(workItem.dueDate);
              const readonly = workItem.status === "CLOSED";
              const overdue = !readonly && dates.dateIsBeforeToday(dueDate);
              return (
                <div>
                  {dates.formatDateLong(dueDate)}
                  <DateButtonPicker
                    labelComponent={
                      <DueAgo value={dueDate} stayFull={readonly} />
                    }
                    dateValue={dueDate}
                    readonly={readonly}
                    className={
                      overdue
                        ? classNames(classes.overdue)
                        : classNames(classes.due)
                    }
                    onDateChange={null}
                  />
                </div>
              );
            },
          },
          {
            label: "Question",
            name: "title",
            size: 4,
            render: (question) => (
              <p className={classes.questionTextCollapse}> {question.text} </p>
            ),
          },
          {
            label: "Response",
            name: "response",
            size: 2,
            sortable: false,
            render: (question) => (
              <span
                style={{
                  color: questionHandler.getResponseColor(
                    theme,
                    loggedInUser,
                    question.responses
                  ),
                }}
              >
                {questionHandler.getUserTooltip(
                  loggedInUser,
                  question.responses,
                  true
                )}
              </span>
            ),
          },
        ]}
      />

      <Pagination
        pagination={pagination}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
      />
    </>
  );
};

QuestionsList.propTypes = {
  classes: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  sortBy: PropTypes.object.isRequired,
  updateSort: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  questions: PropTypes.array.isRequired,
  loggedInUser: PropTypes.object,
  history: PropTypes.object.isRequired,
  searchQuestions: PropTypes.func.isRequired,
  performActionOnWorkItem: PropTypes.func.isRequired,
  types: PropTypes.object.isRequired,
};

QuestionsList.defaultProps = { loggedInUser: {} };

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
  questions: getQuestions(state),
  loading: isLoading(state),
  pagination: getQuestionsSearchPagination(state),
  types: getReferenceDataType(state, "WorkItemType"),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    performActionOnWorkItem: PerformActionOnWorkItem,
    searchQuestions: SearchQuestions,
  })
)(withRouter(QuestionsList));
