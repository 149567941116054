import PropTypes from "prop-types";
import React from "react";
import { required } from "redux-form-validators";
import { yesNoOptions } from "../../../../util/radioOptions";
import ComponentField from "../../../forms/wrapper/ComponentField";
import WrappedRadioGroup from "../../../forms/wrapper/WrappedRadioGroup";
import WrappedTextField from "../../../forms/wrapper/WrappedTextField";

const ThirdPartyAuthorityFields = ({
  name,
  fieldPrefix,
  fieldLabels,
  formName,
  change,
  untouch,
  customisations,
}) => {
  return (
    <>
      <ComponentField
        fieldPrefix={fieldPrefix}
        name={`${name}.name`}
        component={WrappedTextField}
        label={fieldLabels.labels.name}
        fullWidth
        required
        validate={required({
          msg: `${fieldLabels.labels.name} is required`,
        })}
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
        data-cy="name"
      />
      <ComponentField
        fieldPrefix={fieldPrefix}
        name={`${name}.type`}
        component={WrappedTextField}
        label={fieldLabels.labels.type}
        validate={required({
          msg: `${fieldLabels.labels.type} is required`,
        })}
        required
        fullWidth
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      />
      <ComponentField
        fieldPrefix={fieldPrefix}
        name={`${name}.onFile`}
        component={WrappedRadioGroup}
        row
        label={fieldLabels.labels.onFile}
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
        options={yesNoOptions}
      />
      <ComponentField
        fieldPrefix={fieldPrefix}
        name={`${name}.address`}
        component={WrappedTextField}
        label={fieldLabels.labels.address}
        fullWidth
        multiline
        rowsMax={6}
        rows={1}
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      />
      <ComponentField
        fieldPrefix={fieldPrefix}
        name={`${name}.phone`}
        component={WrappedTextField}
        label={fieldLabels.labels.phone}
        fullWidth
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      />
      <ComponentField
        fieldPrefix={fieldPrefix}
        name={`${name}.email`}
        component={WrappedTextField}
        label={fieldLabels.labels.email}
        fullWidth
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      />
    </>
  );
};

ThirdPartyAuthorityFields.propTypes = {
  name: PropTypes.string.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  formName: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  customisations: PropTypes.array,
  fieldPrefix: PropTypes.string,
};

ThirdPartyAuthorityFields.defaultProps = {
  customisations: [],
  fieldPrefix: "",
  activeThirdPartyAuthority: null,
};

export default ThirdPartyAuthorityFields;
