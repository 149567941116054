import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, FieldArray, formValueSelector, reduxForm } from "redux-form";
import { length, required } from "redux-form-validators";
import consequenceRatingImage from "../../../images/consequence-rating.png";
import likelihoodRatingImage from "../../../images/likelihood-rating.png";
import riskRatingImage from "../../../images/risk-rating.png";
import { fetchTags } from "../../../actions/tags";
import {
  getLabels,
  getLoggedInUser,
  getReferenceDataType,
  getTags,
  isLoading,
} from "../../../reducers";
import usersApi from "../../../services/api/users";
import { roles } from "@certane/arcadia-web-components";
import { scrollToFirstInvalidInput } from "../../../util/formHelpers";
import DebugPrefill from "../../common/DebugPrefill";
import FormError from "../../common/FormError";
import HelpPopover from "../../common/HelpPopover";
import { getRefDataMenuItems } from "../menuItems";
import WrappedSelect from "../wrapper/WrappedSelect";
import WrappedTags from "../wrapper/WrappedTags";
import WrappedTextField from "../wrapper/WrappedTextField";
import CapitalImpactFields from "./CapitalImpactFields";
import { calculateRiskRating } from "./riskRating";
import WrappedOwnerPicker from "../wrapper/WrappedOwnerPicker";
import { useAuth0 } from "@auth0/auth0-react";
import EntityRelationshipFields from "../business/EntityRelationshipFields";
import CardSection from "../../common/CardSection";

const styles = () => ({
  riskRatingPopup: {
    width: "699px",
    height: "558px",
  },
  consequenceRatingPopup: {
    width: "100%",
    height: "100%",
  },
  label: {
    position: "relative",
  },
});

const relationshipTypes = [
  "PROMOTER",
  "SUB_PROMOTER",
  "TRUSTEE",
  "RESPONSIBLE_ENTITY",
  "KIWISAVER_MANAGER",
];

const CreateRiskForm = ({
  classes,
  error,
  onCreateForm,
  riskLikelihoods,
  consequenceRatings,
  riskCategories,
  riskAppetites,
  riskRatings,
  likelihood,
  consequence,
  riskRating,
  tags,
  fieldLabels,
  loggedInUser,
  fetchTags,
  change,
  untouch,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      fetchTags(accessToken);
    })();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    calculateRating(likelihood, consequence);
  }, [likelihood, consequence]);

  const calculateRating = (likelihood, consequence) => {
    const likelihoodOridinal = likelihood
      ? riskLikelihoods.values[likelihood].ordinal
      : null;
    const consequenceOrdinal = consequence
      ? consequenceRatings.values[consequence].ordinal
      : null;
    const riskRating = calculateRiskRating(
      likelihoodOridinal,
      consequenceOrdinal
    );
    change("riskRating", riskRating);
  };

  const debugPrefill = () => {
    change("title", "Everything could go wrong");
    change("sourcesOfRisk", [
      {
        text: "Adrian Mawer",
        type: "RISK_SOURCE",
      },
    ]);
    change("consequencesOfRisk", [
      {
        text: "Not sure",
        type: "RISK_CONSEQUENCE",
      },
    ]);
    change("riskRegisters", [
      {
        text: "Resourcing Team",
        type: "RISK_REGISTER",
      },
    ]);
    change("likelihood", "POSSIBLE");
    change("consequence", "MODERATE");
    change("category", "STRATEGIC_AND_TACTICAL");
    change("capitalImpacts", [
      { type: "ORFR" },
      {
        type: "NTA",
        amount: 500.34,
      },
    ]);
    change("owner", { id: loggedInUser.id });
    change("appetite", "ENTREPRENEURIAL");
  };

  const asyncOwnerOptionsFetch = async (
    pickerFilter,
    pagination,
    abortController
  ) => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = {
      ...pickerFilter,
      limit: pagination.pageSize,
      offset: pagination.offset,
      roles: [roles.COMPLIANCE_OWNER, roles.COMPLIANCE_OFFICER],
      orderByField: "name",
    };
    return usersApi.search(searchParameters, abortController, accessToken);
  };

  return (
    <form autoComplete="off">
      {onCreateForm && <DebugPrefill onClick={debugPrefill} />}
      {error && <FormError errorMessage={error} />}
      <CardSection title={fieldLabels.labels.entityRelationship} gutterBottom>
        <EntityRelationshipFields
          formName={RISK_FORM_NAME}
          fieldLabels={fieldLabels.nestedTypes.entityRelationship}
          change={change}
          untouch={untouch}
          customisations={[
            {
              field: "entityRelationship.fundLevel",
              state: "EXCLUDED",
            },
            {
              field: "entityRelationship.organisations",
              state: "REQUIRED",
            },
            {
              field: "entityRelationship.regions",
              state: "REQUIRED",
            },
          ]}
          autoPopulateEntitiesByRelationshipTypes={relationshipTypes}
          filterEntitiesByRelationshipTypes={relationshipTypes}
          defaultVisibilityScope={{
            INTERNAL: "ORGANISATION",
            EXTERNAL: "ORGANISATION",
          }}
        />
      </CardSection>
      <CardSection title="Risk details" gutterBottom>
        <Field
          component={WrappedTextField}
          name="title"
          label={fieldLabels.labels.title}
          validate={required({
            msg: `${fieldLabels.labels.title} is required`,
          })}
          placeholder="Enter a short title"
          fullWidth
          required
          helperText="Provide a short title that describes this risk."
          data-cy="title"
        />

        <Field
          name="sourcesOfRisk"
          component={WrappedTags}
          label={fieldLabels.labels.sourcesOfRisk}
          validate={length({
            min: 1,
            msg: `${fieldLabels.labels.sourcesOfRisk} is required`,
          })}
          tags={tags}
          tagType="RISK_SOURCE"
          placeholder="Enter risk source(s)..."
          fullWidth
          required
          isMulti
          data-cy="sourcesOfRisk"
        />

        <Field
          name="consequencesOfRisk"
          component={WrappedTags}
          label={fieldLabels.labels.consequencesOfRisk}
          validate={length({
            min: 1,
            msg: `${fieldLabels.labels.consequencesOfRisk} is required`,
          })}
          tags={tags}
          tagType="RISK_CONSEQUENCE"
          placeholder="Enter risk consequence(s)..."
          fullWidth
          required
          isMulti
          data-cy="consequencesOfRisk"
        />

        <Field
          name="riskRegisters"
          component={WrappedTags}
          label={fieldLabels.labels.riskRegisters}
          validate={length({
            min: 1,
            msg: `${fieldLabels.labels.riskRegisters} is required`,
          })}
          tags={tags}
          tagType="RISK_REGISTER"
          placeholder="Enter risk register(s)..."
          fullWidth
          required
          isMulti
          data-cy="riskRegisters"
        />

        <Field
          component={WrappedSelect}
          name="likelihood"
          label={fieldLabels.labels.likelihood}
          validate={required({
            msg: `${fieldLabels.labels.likelihood} is required`,
          })}
          required
          fullWidth
          helperText={
            <>
              <span>Get help with </span>
              <HelpPopover linkText="likelihood ratings">
                <img
                  className={classes.consequenceRatingPopup}
                  src={likelihoodRatingImage}
                  alt="Likelihood rating matrix"
                />
              </HelpPopover>
            </>
          }
          data-cy="likelihood"
        >
          {getRefDataMenuItems(riskLikelihoods)}
        </Field>

        <Field
          component={WrappedSelect}
          name="consequence"
          label={fieldLabels.labels.consequence}
          validate={required({
            msg: `${fieldLabels.labels.consequence} is required`,
          })}
          required
          fullWidth
          helperText={
            <>
              <span>Get help with </span>
              <HelpPopover linkText="consequence ratings">
                <img
                  className={classes.consequenceRatingPopup}
                  src={consequenceRatingImage}
                  alt="Consequence rating matrix"
                />
              </HelpPopover>
            </>
          }
          data-cy="consequence"
        >
          {getRefDataMenuItems(consequenceRatings)}
        </Field>

        <FormControl component="fieldset" margin="dense">
          <InputLabel
            shrink
            className={classes.label}
            data-cy={fieldLabels.riskRating}
          >
            {fieldLabels.labels.riskRating}
            <HelpPopover>
              <img
                className={classes.riskRatingPopup}
                src={riskRatingImage}
                alt="Risk rating matrix"
              />
            </HelpPopover>
          </InputLabel>
          {riskRating !== null
            ? riskRatings.values[riskRating].description
            : "N/A"}
        </FormControl>

        <Field
          component={WrappedSelect}
          name="category"
          label={fieldLabels.labels.category}
          validate={required({
            msg: `${fieldLabels.labels.category} is required`,
          })}
          required
          fullWidth
          data-cy="category"
        >
          {getRefDataMenuItems(riskCategories)}
        </Field>

        <Field
          name="owner"
          data-cy="owner"
          component={WrappedOwnerPicker}
          datasource={asyncOwnerOptionsFetch}
          placeholder="Please select an owner..."
          required
          validate={required({
            msg: `${fieldLabels.labels.owner} is required`,
          })}
          label={fieldLabels.labels.owner}
          fullWidth
          loggedInUser={loggedInUser}
        />

        <br />
        <FormLabel data-cy={fieldLabels.capitalImpacts}>
          {fieldLabels.labels.capitalImpacts}
        </FormLabel>
        <br />
        <FieldArray
          name="capitalImpacts"
          component={CapitalImpactFields}
          data-cy={CapitalImpactFields}
        />

        <Field
          component={WrappedSelect}
          name="appetite"
          data-cy="appetite"
          label={fieldLabels.labels.appetite}
          fullWidth
        >
          {getRefDataMenuItems(riskAppetites)}
        </Field>
      </CardSection>
    </form>
  );
};

CreateRiskForm.propTypes = {
  classes: PropTypes.object.isRequired,
  error: PropTypes.string,
  loggedInUser: PropTypes.object.isRequired,
  riskLikelihoods: PropTypes.object.isRequired,
  consequenceRatings: PropTypes.object.isRequired,
  riskCategories: PropTypes.object.isRequired,
  riskAppetites: PropTypes.object.isRequired,
  riskRatings: PropTypes.object.isRequired,
  likelihood: PropTypes.string,
  consequence: PropTypes.string,
  riskRating: PropTypes.string,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  onCreateForm: PropTypes.bool.isRequired,
  tags: PropTypes.array.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  fetchTags: PropTypes.func.isRequired,
};

CreateRiskForm.defaultProps = {
  error: null,
  likelihood: null,
  consequence: null,
  riskRating: null,
};

export const RISK_FORM_NAME = "createRiskForm";

const reduxFormSelector = formValueSelector(RISK_FORM_NAME);

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
  riskLikelihoods: getReferenceDataType(state, "RiskLikelihood"),
  consequenceRatings: getReferenceDataType(state, "RiskConsequenceRating"),
  riskCategories: getReferenceDataType(state, "RiskCategory"),
  riskAppetites: getReferenceDataType(state, "RiskAppetite"),
  riskRatings: getReferenceDataType(state, "RiskRating"),
  loading: isLoading(state),
  likelihood: reduxFormSelector(state, "likelihood"),
  consequence: reduxFormSelector(state, "consequence"),
  riskRating: reduxFormSelector(state, "riskRating"),
  tags: getTags(state),
  fieldLabels: getLabels(state).Risk,
});

const form = reduxForm({
  form: RISK_FORM_NAME,
  onSubmitFail: (errors) => scrollToFirstInvalidInput(errors),
});

const reduxData = connect(mapStateToProps, {
  fetchTags,
});

export default compose(form, reduxData)(withStyles(styles)(CreateRiskForm));
