import _ from "lodash";
import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ListPicker from "../../ListPicker";
import Avatar from "../../common/Avatar.tsx";

const WrappedOwnerPicker = ({
  input: { onChange, onBlur, value, name },
  label,
  datasource,
  isMulti,
  selectAll,
  clearable,
  submitOnChange,
  margin,
  helperText,
  disabled,
  meta: { touched, error, invalid },
  listPickerProps,
  loggedInUser,
  ...custom
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (value === "") {
      // ignore
    } else if (value === null || value === undefined) {
      onBlur(undefined);
    } else {
      onBlur(value);
    }
  }, [value]);

  const onSelect = (values) => {
    setDialogOpen(false);
    onChange(values);
  };

  const getSelection = (values) => {
    if (_.isArray(values)) {
      return values;
    }
    if (values === null || values === undefined || values === "") {
      return null;
    }
    return values;
  };

  const toOption = (user) => ({
    label: user.name || "Unknown",
    id: user.id,
    email: user.email,
  });

  const fromOption = (option) => ({
    name: option.label,
    id: option.id,
    email: option.email,
  });

  const getLabel = (values) => {
    if (_.isArray(values)) {
      return values
        .map((v) => toOption(v))
        .map((option) => option.label)
        .join(", ");
    }
    if (values === null || values === undefined || values === "") {
      return "";
    }
    return toOption(values).label;
  };

  const renderIcon = (user, size) => (
    <Avatar email={user.email} name={user.name} size={size} round />
  );

  return (
    <>
      <TextField
        InputLabelProps={{ name: `position-${name}` }}
        label={label}
        data-cy={label}
        error={touched && invalid}
        placeholder="Please select an owner..."
        helperText={(touched && error) || helperText}
        readOnly
        onClick={disabled ? null : () => setDialogOpen(true)}
        value={getLabel(value)}
        margin={margin}
        disabled={disabled}
        inputProps={{
          onKeyPress: () => setDialogOpen(true),
        }}
        {...custom}
      />
      <ListPicker
        data-cy={label}
        title={label}
        actionText="Select"
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onSubmit={(values) => onSelect(values)}
        datasource={datasource}
        selected={getSelection(value)}
        isMulti={isMulti}
        selectAll={selectAll}
        clearable={clearable}
        renderIcon={renderIcon}
        submitOnChange={submitOnChange}
        toOption={toOption}
        fromOption={fromOption}
        additionalActions={[
          <Button
            key="assignToMe"
            color="primary"
            onClick={() => onSelect(loggedInUser)}
            data-cy="assignToMe"
          >
            Assign to me
          </Button>,
        ]}
        {...listPickerProps}
      />
    </>
  );
};

WrappedOwnerPicker.propTypes = {
  input: PropTypes.object.isRequired,
  datasource: PropTypes.oneOfType([PropTypes.func, PropTypes.array]).isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  isMulti: PropTypes.bool,
  submitOnChange: PropTypes.bool,
  margin: PropTypes.string,
  helperText: PropTypes.any,
  disabled: PropTypes.bool,
  selectAll: PropTypes.bool,
  clearable: PropTypes.bool,
  loggedInUser: PropTypes.object.isRequired,
  listPickerProps: PropTypes.object,
};

WrappedOwnerPicker.defaultProps = {
  submitOnChange: true,
  margin: "dense",
  helperText: "",
  disabled: false,
  isMulti: false,
  selectAll: false,
  clearable: false,
  listPickerProps: {},
};

export default WrappedOwnerPicker;
