import _ from "lodash";
import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Field, formValueSelector } from "redux-form";
import logger from "../../../util/logger";

const ComponentField = ({
  name,
  customisations,
  helperText,
  required,
  validate,
  validateIfRequired,
  change,
  untouch,
  currentValue,
  fieldPrefix,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  formName,
  ...rest
}) => {
  const fieldCustomisations = customisations.filter((c) => c.field === name);
  const excluded = !!fieldCustomisations.find((c) => c.state === "EXCLUDED");
  const hidden = !!fieldCustomisations.find((c) => c.state === "HIDDEN");
  const readOnly = !!fieldCustomisations.find((c) => c.state === "READ_ONLY");
  const isRequired =
    !!fieldCustomisations.find((c) => c.state === "REQUIRED") || required;
  const customHelperText = fieldCustomisations
    .filter((c) => !!c.helpText)
    .map((c) => c.helpText)[0];

  if (excluded) {
    if (
      currentValue !== null &&
      currentValue !== undefined &&
      currentValue !== "" &&
      (!_.isArray(currentValue) || currentValue.length > 0)
    ) {
      logger.debug("Clearing value for field", name);
      const nullValue = _.isArray(currentValue) ? [] : null;
      setTimeout(() => {
        change(`${fieldPrefix}${name}`, nullValue);
        untouch(`${fieldPrefix}${name}`);
      }, 0);
    }
    return null;
  }

  if (hidden) {
    // Don't clear value like we do for excluded fields, just don't render field
    return null;
  }

  return (
    <span data-cy={`${name}-field`}>
      <Field
        name={`${fieldPrefix}${name}`}
        disabled={readOnly}
        helperText={customHelperText || helperText}
        required={isRequired}
        validate={validate || (isRequired ? validateIfRequired : null)}
        {...rest}
      />
    </span>
  );
};

ComponentField.propTypes = {
  name: PropTypes.string.isRequired,
  fieldPrefix: PropTypes.string,
  helperText: PropTypes.any,
  customisations: PropTypes.array.isRequired,
  required: PropTypes.bool,
  validate: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
  validateIfRequired: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
  formName: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,

  // redux
  currentValue: PropTypes.any,
};

ComponentField.defaultProps = {
  fieldPrefix: "",
  currentValue: undefined,
  helperText: "",
  required: false,
  validate: null,
  validateIfRequired: null,
};

const mapStateToProps = (state, { formName, name, fieldPrefix }) => {
  const reduxFormSelector = formValueSelector(formName);
  const fieldName = `${fieldPrefix ? fieldPrefix : ""}${name}`;
  return {
    currentValue: reduxFormSelector(state, fieldName),
  };
};

export default connect(mapStateToProps)(ComponentField);
