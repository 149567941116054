import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import {
  clearActiveWorkItem,
  getWorkItemById,
  patchWorkItem,
} from "../../../actions/workItems";
import BreadcrumbLink from "../../../components/common/BreadcrumbLink";
import HeaderBar from "../../../components/common/HeaderBar";
import PageSubheading from "../../../components/common/PageSubheading";
import UnifiedHeading from "../../../components/common/UnifiedHeading";
import Composer from "../../../components/composer/Composer";
import Error404 from "../../../components/error/Error404";
import Error410 from "../../../components/error/Error410";
import ActionButton from "../../../components/workitem/action/ActionButton";
import WorkItemTabs from "../../../components/workitem/WorkItemTabs";
import WorkItemUpdatedCheck from "../../../components/workitem/WorkItemUpdatedCheck";
import {
  getReferenceDataDescription,
  getReferenceDataType,
  getWorkItem,
  getWorkItemLoadError,
  isLoading,
} from "../../../reducers";
import { workItemIcon } from "../../../util/icons";
import logger from "../../../util/logger";
import { useAuth0 } from "@auth0/auth0-react";
import { getResolvedDefinition } from "../../../util/workItemTypeUtils";

const styles = (theme) => ({
  root: {
    width: "100%",
    paddingBottom: 100,
  },
  outcome: {
    fontWeight: "600",
    color: "#666",
    marginLeft: "10px",
    border: "1.5px solid",
    borderRadius: "5px",
    paddingLeft: theme.spacing(1) / 2,
    paddingRight: theme.spacing(1) / 2,
  },
  toolbarButtons: {
    width: "100%",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  actions: {},
  actionButton: {
    minHeight: "48px",
    minWidth: "134px",
  },
});

const WorkItemId = ({
  classes,
  match: {
    params: { workItemId, riskId, riskControlId },
  },
  loading,
  workItem,
  error,
  getWorkItemTypeDescription,
  getWorkItemOutcomeDescription,
  workItemTypes,
  localClearActiveWorkItem,
  localGetWorkItemById,
  localPatchWorkItem,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      if (!workItem || workItem.id !== workItemId) {
        localClearActiveWorkItem();
        localGetWorkItemById(workItemId, accessToken);
      }
    })();
  }, [getAccessTokenSilently, workItemId]);

  const getBreadCrumb = () => {
    if (riskId) {
      logger.debug(`riskId is ${riskId} and workItem`, workItem);
      const riskRef =
        workItem && workItem.associatedRisks.find((ref) => ref.id === riskId);
      const riskTitle = riskRef ? riskRef.title : "Risk";
      return (
        <BreadcrumbLink
          to={["/risk/risks", `/risk/risks/${riskId}`]}
          label={["Risks", riskTitle]}
          includeArrow
        />
      );
    }
    if (riskControlId) {
      logger.debug(`riskControlId is ${riskControlId} and workItem`, workItem);
      const riskControlRef = workItem && workItem.associatedRiskControl;
      const riskControlTitle = riskControlRef
        ? riskControlRef.name
        : "Risk Control";
      return (
        <BreadcrumbLink
          to={["/risk/controls", `/risk/controls/${riskControlId}`]}
          label={["Risk controls", riskControlTitle]}
          includeArrow
        />
      );
    }
    if (window.location.pathname.indexOf("risks/actions") !== -1) {
      return (
        <BreadcrumbLink
          to="/risk/risks/actions"
          label="Risk actions"
          includeArrow
        />
      );
    }
    if (window.location.pathname.indexOf("risks/reassessments") !== -1) {
      return (
        <BreadcrumbLink
          to="/risk/risks/reassessments"
          label="Risk reassessments"
          includeArrow
        />
      );
    }
    if (window.location.pathname.indexOf("risks/measure-updates") !== -1) {
      return (
        <BreadcrumbLink
          to="/risk/risks/measure-updates"
          label="Risk measure updates"
          includeArrow
        />
      );
    }
    if (window.location.pathname.indexOf("controls") !== -1) {
      return (
        <BreadcrumbLink
          to="/risk/controls/control-verifications"
          label="Risk control verifications"
          includeArrow
        />
      );
    }
    return null;
  };

  const getAssociation = () => {
    if (workItem.associatedRisks.length > 0) {
      const riskLinks = workItem.associatedRisks.map(
        (riskRef, index, array) => {
          const separator = index < array.length - 1 ? " | " : "";
          return riskRef.accessible ? (
            <span key={`/risk/risks/${riskRef.id}`}>
              <Link to={`/risk/risks/${riskRef.id}`} data-cy={riskRef.title}>
                {riskRef.title}
              </Link>
              {separator}
            </span>
          ) : (
            <span key={`/risk/risks/${riskRef.id}`}>
              {riskRef.title}
              {separator}
            </span>
          );
        }
      );

      return (
        <>
          {riskLinks && riskLinks.length > 0 && (
            <span key="association">
              {`associated with the following ${
                riskLinks.length === 1 ? "risk" : "risks"
              }: `}
              {riskLinks}
            </span>
          )}
        </>
      );
    }

    if (workItem.associatedRiskControl) {
      const riskControlLink = workItem.associatedRiskControl.accessible ? (
        <Link
          key={`/risk/controls/${workItem.associatedRiskControl.id}`}
          to={`/risk/controls/${workItem.associatedRiskControl.id}`}
        >
          {workItem.associatedRiskControl.name}
        </Link>
      ) : (
        workItem.associatedRiskControl.name
      );
      return [
        <span key="association">
          associated with the following risk control:{" "}
        </span>,
        riskControlLink,
      ];
    }

    return null;
  };

  if (!workItem && loading) {
    return null;
  }

  if (!workItem && !loading) {
    if (error && error.status === 410) {
      return <Error410 message={error.message} />;
    }
    return <Error404 />;
  }

  const definition = getResolvedDefinition(
    workItemTypes,
    workItem.type,
    workItem.parent?.type
  );
  const outcomeDesc = getWorkItemOutcomeDescription(workItem.outcome);
  const typeDisplay = definition.name;

  const updateTitle = async (title) => {
    const accessToken = await getAccessTokenSilently();
    return localPatchWorkItem(
      workItem.id,
      { title },
      `Edited ${typeDisplay}`,
      accessToken
    );
  };

  return (
    <div className={classes.root}>
      <HeaderBar>
        <div className={classes.headerContainer}>
          <div>
            {getBreadCrumb()}
            <UnifiedHeading
              icon={workItemIcon(workItem.type)}
              heading={`${typeDisplay.toUpperCase()}: ${workItem.friendlyId}`}
              subHeading={workItem.title}
              darkSubheading={false}
              workItem={workItem}
              readonly={workItem.status === "CLOSED"}
              onTitleChange={(title) => updateTitle(title)}
              outcomeDesc={outcomeDesc}
            />
            <PageSubheading
              subheading={
                <>
                  {getWorkItemTypeDescription(workItem.type)} {getAssociation()}
                </>
              }
            />
          </div>
          <div>
            <ActionButton workItem={workItem} data-cy={workItem} />
          </div>
        </div>
      </HeaderBar>
      <WorkItemUpdatedCheck workItem={workItem} />
      <WorkItemTabs workItem={workItem} data-cy={workItem} />
      <Composer workItem={workItem} />
    </div>
  );
};

WorkItemId.propTypes = {
  match: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  workItem: PropTypes.object,
  workItemTypes: PropTypes.object.isRequired,
  getWorkItemTypeDescription: PropTypes.func.isRequired,
  getWorkItemOutcomeDescription: PropTypes.func.isRequired,
  error: PropTypes.object,
  localClearActiveWorkItem: PropTypes.func.isRequired,
  localGetWorkItemById: PropTypes.func.isRequired,
  localPatchWorkItem: PropTypes.func.isRequired,
};

WorkItemId.defaultProps = {
  workItem: null,
  error: null,
};

const mapStateToProps = (state) => ({
  loading: isLoading(state),
  workItem: getWorkItem(state),
  error: getWorkItemLoadError(state),
  workItemTypes: getReferenceDataType(state, "WorkItemType"),
  getWorkItemTypeDescription: (id) =>
    getReferenceDataDescription(state, "WorkItemType", id, "-"),
  getWorkItemOutcomeDescription: (id) =>
    getReferenceDataDescription(state, "WorkItemOutcome", id, null),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    localClearActiveWorkItem: clearActiveWorkItem,
    localGetWorkItemById: getWorkItemById,
    localPatchWorkItem: patchWorkItem,
  })
)(WorkItemId);
