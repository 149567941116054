import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { FieldArray, getFormValues } from "redux-form";
import { getLabels, getLoggedInUser } from "../../../reducers";
import { helpIcon } from "../../../util/icons";
import logger from "../../../util/logger";
import {
  appliesByCategory,
  appliesByCondition,
  appliesByParentType,
  appliesByParties,
  appliesByRegion,
  appliesByRoles,
} from "../../../util/workItemTypeUtils";
import EntityExplorer from "../../business/EntityExplorer";
import CardSection from "../../common/CardSection";
import EntityRelationshipFields from "../../forms/business/EntityRelationshipFields";
import AuditAssociationFields from "./auditassociation/AuditAssociationFields";
import AuditFindingFields from "./auditfinding/AuditFindingFields";
import ChargeabilityFields from "./chargeability/ChargeabilityFields";
import ClaimAssessmentFields from "./claimassessment/ClaimAssessmentFields";
import ClaimsFields from "./claims/ClaimsFields";
import ClientDetailsFields from "./client/ClientDetailsFields";
import ComplaintDetailsFields from "./complaint/ComplaintDetailsFields";
import ComplianceDetailsFields from "./compliance/ComplianceDetailsFields";
import ContactsListField from "./contacts/ContactsListField";
import ContractDetailsFields from "./contract/ContractDetailsFields";
import ControlVerificationFields from "./controlverification/ControlVerificationFields";
import CorrespondenceDetailsFields from "./correspondence/CorrespondenceDetailsFields";
import DeathBenefitDetailsFields from "./deathbenefit/DeathBenefitDetailsFields";
import DependantsDetailsFields from "./dependants/DependantsDetailsFields";
import FormChecklistFields from "./documentchecklist/FormChecklistFields";
import FilesFields from "./files/FilesFields";
import HardshipClaimDetailsFields from "./hardshipclaim/HardshipClaimDetailsFields";
import IncidentOccurrenceDetailsFields from "./incident/IncidentOccurrenceDetailsFields";
import IncidentRecurrenceDetailsFields from "./incident/IncidentRecurrenceDetailsFields";
import IncidentActionFields from "./incidentaction/IncidentActionFields";
import IncidentAssessmentFields from "./incidentassessment/IncidentAssessmentFields";
import IncidentBreachReportFields from "./incidentbreachreport/IncidentBreachReportFields";
import InitialEmailFields from "./initialemail/InitialEmailFields";
import InsuranceFields from "./insurance/InsuranceFields";
import MemberDetailsFields from "./memberdetails/MemberDetailsFields";
import OverviewFields from "./overview/OverviewFields";
import QuestionArrayFields from "./questions/QuestionArrayFields";
import RiskAssessmentFields from "./riskassessment/RiskAssessmentFields";
import RiskControlVerificationFields from "./riskcontrolverification/RiskControlVerificationFields";
import RiskMeasureUpdateDetailsFields from "./riskmeasureupdate/RiskMeasureUpdateDetailsFields";
import TerminalIllnessFields from "./terminalillness/TerminalIllnessFields";
import OriginFields from "./incident/OriginFields";

const relationshipTypes = [
  "PROMOTER",
  "SUB_PROMOTER",
  "TRUSTEE",
  "RESPONSIBLE_ENTITY",
  "KIWISAVER_MANAGER",
];

const WorkItemComponentFields = ({
  formValues,
  existingWorkItem,
  prefix,
  parentType,
  template,
  labels,
  workItemDefinition,
  change,
  untouch,
  formName,
  formType,
  loggedInUser,
  componentName,
  scrollToComponent,
}) => {
  const [productExplorerOpen, setProductExplorerOpen] = useState(false);
  const [availableComponents, setAvailableComponents] = useState([]);
  const [previousComponents, setPreviousComponents] = useState([]);

  const fieldPrefix = prefix ? `${prefix}.` : "";
  if (formType === "UPDATE" && !existingWorkItem) {
    throw new Error("existingWorkItem is not provided!");
  }

  const workItemProxy =
    formType === "CREATE"
      ? {
          hasParent: !!parentType,
          parent: parentType
            ? {
                type: parentType,
              }
            : null,
          users: [],
          ...formValues,
        }
      : existingWorkItem;

  useEffect(() => {
    const components = workItemDefinition.components
      .filter(
        (component) =>
          component.categories.length === 0 ||
          appliesByCategory(workItemProxy, component)
      )
      .filter((component) => appliesByParentType(workItemProxy, component))
      .filter((component) =>
        appliesByParties(workItemProxy, loggedInUser, component)
      )
      .filter((component) => formType !== "CREATE" || component.atCreation)
      .filter(
        (component) =>
          componentName === "ALL" || component.component === componentName
      );

    // Set the default values as provided by the component
    components.forEach((component) =>
      component.fieldCustomisations
        .filter(
          (customisation) =>
            customisation.categories.length === 0 ||
            appliesByCategory(workItemProxy, customisation)
        )
        .filter((customisation) => customisation.defaultValue !== undefined)
        .forEach((customisation) =>
          change(customisation.field, customisation.defaultValue)
        )
    );

    setPreviousComponents(availableComponents);
    setAvailableComponents(components);
  }, [workItemProxy.category]);

  useEffect(() => {
    const removedComponents = previousComponents.filter(
      (pc) => !availableComponents.find((ac) => ac.component === pc.component)
    );
    logger.info(
      "Clearing values for removed components",
      removedComponents.map((rc) => rc.component)
    );
    removedComponents.forEach((removedComponent) => {
      switch (removedComponent.component) {
        case "AUDIT_FINDING":
          change(`${fieldPrefix}auditFinding`, null);
          change(`${fieldPrefix}associatedRisks`, []);
          break;
        case "CHARGEABILITY":
          change(`${fieldPrefix}chargeable`, null);
          break;
        case "CLAIMS":
          change(`${fieldPrefix}claimDetails`, null);
          break;
        case "COMPLAINT":
          change(`${fieldPrefix}complaint`, null);
          break;
        case "COMPLIANCE":
          change(`${fieldPrefix}compliance`, null);
          break;
        case "CONTACTS":
          change(`${fieldPrefix}contacts`, null);
          break;
        case "CONTRACT":
          change(`${fieldPrefix}contract`, null);
          break;
        case "CONTROL_VERIFICATION":
          change(`${fieldPrefix}controlVerification`, null);
          break;
        case "CORRESPONDENCE":
          change(`${fieldPrefix}correspondence`, null);
          break;
        case "DEATH_BENEFIT":
          change(`${fieldPrefix}deathBenefit`, null);
          break;
        case "DEPENDANTS":
          change(`${fieldPrefix}dependants`, null);
          break;
        case "DOCUMENT_CHECKLIST":
          change(`${fieldPrefix}documentChecklist`, null);
          break;
        case "ENTITY_RELATIONSHIP":
          change(`${fieldPrefix}entityRelationship`, null);
          break;
        case "FILES":
          change(`${fieldPrefix}documents`, []);
          break;
        case "CLAIM_ASSESSMENT":
          change(`${fieldPrefix}hardshipAssessments`, []);
          break;
        case "HARDSHIP_CLAIM":
          change(`${fieldPrefix}hardship`, null);
          break;
        case "INCIDENT":
          change(`${fieldPrefix}incident`, null);
          break;
        case "INCIDENT_ACTION":
          change(`${fieldPrefix}incidentAction`, null);
          break;
        case "INCIDENT_ASSESSMENT":
          change(`${fieldPrefix}incidentAssessment`, null);
          break;
        case "INCIDENT_BREACH_REPORT":
          change(`${fieldPrefix}incidentBreachReport`, null);
          break;
        case "INSURANCE":
          change(`${fieldPrefix}insurance`, null);
          break;
        case "MEMBER_DETAILS":
          change(`${fieldPrefix}memberDetails`, null);
          break;
        case "QUESTIONS":
          change(`${fieldPrefix}questions`, []);
          break;
        case "RISK_CONTROL_VERIFICATION":
          change(`${fieldPrefix}riskControlVerificationDetails`, null);
          break;
        case "RISK_MEASURE_UPDATE":
          change(`${fieldPrefix}riskMeasureUpdateDetails`, []);
          break;
        case "TERMINAL_ILLNESS":
          change(`${fieldPrefix}terminalIllness`, null);
          break;
        default:
          logger.error(
            `component ${removedComponent.component} has been removed but have no handler for clearing field values`
          );
          break;
      }
    });
  }, [availableComponents]);

  const fieldLabels = labels[workItemDefinition.id];
  const hideTitles = componentName !== "ALL";
  const HelpIcon = helpIcon();
  // logger.debug("formValues", formValues);

  return availableComponents.map((componentAssignment, index) => {
    const customisations = componentAssignment.fieldCustomisations
      .filter(
        (customisation) =>
          customisation.categories.length === 0 ||
          appliesByCategory(workItemProxy, customisation)
      )
      .filter((customisation) => appliesByRoles(loggedInUser, customisation))
      .filter((customisation) => appliesByRegion(workItemProxy, customisation))
      .filter((customisation) =>
        appliesByCondition(workItemProxy, customisation.condition)
      );
    const gutterTop = index !== 0;

    switch (componentAssignment.component) {
      case "AUDIT_ASSOCIATION":
        return (
          <CardSection
            key={componentAssignment.component}
            title={fieldLabels.labels.auditReport}
            gutterTop={gutterTop}
            hideTitle={hideTitles}
          >
            <AuditAssociationFields
              fieldLabels={fieldLabels}
              formName={formName}
              customisations={customisations}
              change={change}
              untouch={untouch}
              onCreateForm={formType === "CREATE"}
              fieldPrefix={fieldPrefix}
            />
          </CardSection>
        );
      case "AUDIT_FINDING":
        return (
          <CardSection
            key={componentAssignment.component}
            title={fieldLabels.labels.auditFinding}
            gutterTop={gutterTop}
            hideTitle={hideTitles}
          >
            <AuditFindingFields
              fieldLabels={fieldLabels}
              formName={formName}
              customisations={customisations}
              change={change}
              untouch={untouch}
              onCreateForm={formType === "CREATE"}
              fieldPrefix={fieldPrefix}
            />
          </CardSection>
        );
      case "CHARGEABILITY":
        return (
          <ChargeabilityFields
            data-cy={fieldLabels}
            key={componentAssignment.component}
            fieldLabels={fieldLabels}
            formName={formName}
            customisations={customisations}
            change={change}
            untouch={untouch}
            fieldPrefix={fieldPrefix}
          />
        );
      case "CLAIMS":
        return (
          <CardSection
            key={componentAssignment.component}
            title={fieldLabels.labels.claimDetails}
            gutterTop={gutterTop}
            data-cy={fieldLabels.labels.claimDetails}
            hideTitle={hideTitles}
          >
            <ClaimsFields
              fieldLabels={fieldLabels}
              formName={formName}
              formValues={formValues}
              customisations={customisations}
              change={change}
              untouch={untouch}
              data-cy={fieldLabels}
              fieldPrefix={fieldPrefix}
            />
          </CardSection>
        );
      case "CLIENT":
        return (
          <CardSection
            key={componentAssignment.component}
            title={fieldLabels.labels.clientDetails}
            gutterTop={gutterTop}
            data-cy={fieldLabels.labels.clientDetails}
            hideTitle={hideTitles}
          >
            <ClientDetailsFields
              fieldLabels={fieldLabels}
              formName={formName}
              formValues={formValues}
              customisations={customisations}
              change={change}
              untouch={untouch}
              data-cy={fieldLabels}
              fieldPrefix={fieldPrefix}
            />
          </CardSection>
        );
      case "COMPLAINT":
        return (
          <ComplaintDetailsFields
            key={componentAssignment.component}
            gutterTop={gutterTop}
            fieldLabels={fieldLabels}
            formName={formName}
            customisations={customisations}
            change={change}
            untouch={untouch}
            data-cy="complaint"
            fieldPrefix={fieldPrefix}
            entityRelationship={workItemProxy.entityRelationship}
            onCreateForm={formType === "CREATE"}
          />
        );
      case "COMPLIANCE":
        return (
          <CardSection
            key={componentAssignment.component}
            title={fieldLabels.labels.compliance}
            gutterTop={gutterTop}
            hideTitle={hideTitles}
          >
            <ComplianceDetailsFields
              fieldLabels={fieldLabels}
              formName={formName}
              customisations={customisations}
              change={change}
              untouch={untouch}
              data-cy={fieldLabels}
              fieldPrefix={fieldPrefix}
            />
          </CardSection>
        );
      case "CONTACTS":
        return (
          <CardSection
            key={componentAssignment.component}
            title={fieldLabels.labels.contacts}
            gutterTop={gutterTop}
            hideTitle={hideTitles}
          >
            <FieldArray
              name="contacts"
              component={ContactsListField}
              formName={formName}
              fieldLabels={fieldLabels}
              data-cy={fieldLabels}
              fieldPrefix={fieldPrefix}
            />
          </CardSection>
        );
      case "CONTRACT":
        return (
          <CardSection
            key={componentAssignment.component}
            title={fieldLabels.labels.contract}
            gutterTop={gutterTop}
            hideTitle={hideTitles}
          >
            <ContractDetailsFields
              fieldLabels={fieldLabels}
              formName={formName}
              customisations={customisations}
              change={change}
              untouch={untouch}
              data-cy={fieldLabels}
              fieldPrefix={fieldPrefix}
            />
          </CardSection>
        );
      case "CONTROL_VERIFICATION":
        return (
          <CardSection
            key={componentAssignment.component}
            title={fieldLabels.labels.controlVerification}
            gutterTop={gutterTop}
            hideTitle={hideTitles}
          >
            <ControlVerificationFields
              fieldLabels={fieldLabels}
              customisations={customisations}
              formName={formName}
              change={change}
              untouch={untouch}
              data-cy={fieldLabels}
              fieldPrefix={fieldPrefix}
            />
          </CardSection>
        );
      case "CORRESPONDENCE":
        return (
          <CardSection
            key={componentAssignment.component}
            title={fieldLabels.labels.correspondence}
            gutterTop={gutterTop}
            hideTitle={hideTitles}
          >
            <CorrespondenceDetailsFields
              fieldLabels={fieldLabels}
              formName={formName}
              customisations={customisations}
              change={change}
              untouch={untouch}
              data-cy={fieldLabels}
              fieldPrefix={fieldPrefix}
            />
          </CardSection>
        );
      case "DEATH_BENEFIT":
        return (
          <CardSection
            key={componentAssignment.component}
            title={fieldLabels.labels.deathBenefit}
            gutterTop={gutterTop}
            hideTitle={hideTitles}
          >
            <DeathBenefitDetailsFields
              fieldLabels={fieldLabels}
              formName={formName}
              customisations={customisations}
              change={change}
              untouch={untouch}
              data-cy={fieldLabels}
              fieldPrefix={fieldPrefix}
            />
          </CardSection>
        );
      case "DEPENDANTS":
        return (
          <CardSection
            key={componentAssignment.component}
            title={fieldLabels.labels.dependants}
            gutterTop={gutterTop}
            hideTitle={hideTitles}
          >
            <FieldArray
              name="dependants"
              component={DependantsDetailsFields}
              formName={formName}
              fieldLabels={fieldLabels}
              data-cy="dependants"
              fieldPrefix={fieldPrefix}
            />
          </CardSection>
        );
      case "DOCUMENT_CHECKLIST":
        return (
          <CardSection
            key={componentAssignment.component}
            title={fieldLabels.labels.documentChecklist}
            gutterTop={gutterTop}
            hideTitle={hideTitles}
          >
            <FormChecklistFields
              formName={formName}
              workItemDefinition={workItemDefinition}
              workItemCategory={workItemProxy.category}
              change={change}
              customisations={customisations}
              data-cy={formName}
              fieldPrefix={fieldPrefix}
            />
          </CardSection>
        );
      case "ENTITY_RELATIONSHIP":
        return (
          <CardSection
            data-cy="entityRelationship"
            key={componentAssignment.component}
            title={fieldLabels.labels.entityRelationship}
            gutterTop={gutterTop}
            hideTitle={hideTitles}
            action={
              <Tooltip title="Help" disableFocusListener>
                <IconButton
                  aria-label="Help"
                  aria-haspopup="true"
                  onClick={() => setProductExplorerOpen(true)}
                  data-cy="help"
                >
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            }
          >
            <EntityExplorer
              title={`${fieldLabels.labels.entityRelationship} explorer`}
              open={productExplorerOpen}
              onClose={() => setProductExplorerOpen(false)}
              data-cy={`${fieldLabels.labels.entityRelationship} explorer`}
            />
            <EntityRelationshipFields
              fieldLabels={fieldLabels.nestedTypes.entityRelationship}
              formName={formName}
              customisations={customisations}
              change={change}
              untouch={untouch}
              fieldPrefix={fieldPrefix}
              autoPopulateEntitiesByRelationshipTypes={relationshipTypes}
              filterEntitiesByRelationshipTypes={[]}
              defaultVisibilityScope={{
                INTERNAL: "ORGANISATION",
                EXTERNAL: "INDIVIDUAL",
              }}
            />
          </CardSection>
        );
      case "FILES":
        return (
          <FilesFields
            key={componentAssignment.component}
            formName={formName}
            workItemDefinition={workItemDefinition}
            fieldLabels={fieldLabels}
            customisations={customisations}
            change={change}
            untouch={untouch}
            data-cy={formName}
            fieldPrefix={fieldPrefix}
          />
        );
      case "CLAIM_ASSESSMENT":
        return (
          <CardSection
            key={componentAssignment.component}
            title={fieldLabels.labels.hardshipAssessments}
            gutterTop={gutterTop}
          >
            <ClaimAssessmentFields
              workItemDefinition={workItemDefinition}
              fieldLabels={fieldLabels}
              formName={formName}
              customisations={customisations}
              change={change}
              untouch={untouch}
              data-cy={fieldLabels}
              fieldPrefix={fieldPrefix}
            />
          </CardSection>
        );
      case "HARDSHIP_CLAIM":
        return (
          <CardSection
            key={componentAssignment.component}
            title={fieldLabels.labels.hardship}
            gutterTop={gutterTop}
            hideTitle={hideTitles}
          >
            <HardshipClaimDetailsFields
              fieldLabels={fieldLabels}
              formName={formName}
              customisations={customisations}
              change={change}
              untouch={untouch}
              data-cy={fieldLabels}
              fieldPrefix={fieldPrefix}
            />
          </CardSection>
        );
      case "INCIDENT":
        return (
          <Fragment key={componentAssignment.component}>
            <CardSection
              title={fieldLabels.nestedTypes.incident.labels.origin}
              gutterTop={gutterTop}
              gutterBottom
            >
              <OriginFields
                customisations={customisations}
                change={change}
                untouch={untouch}
                formName={formName}
                name="incident.origin"
                fieldLabels={
                  fieldLabels.nestedTypes.incident.nestedTypes.origin
                }
                entityRelationship={workItemProxy.entityRelationship}
              />
            </CardSection>
            <CardSection title="Occurrence details" gutterBottom>
              <IncidentOccurrenceDetailsFields
                fieldLabels={fieldLabels}
                onCreateForm={formType === "CREATE"}
                data-cy={fieldLabels}
                fieldPrefix={fieldPrefix}
                formName={formName}
                customisations={customisations}
                change={change}
                untouch={untouch}
              />
            </CardSection>
            <CardSection title="Recurrence and prevention" gutterBottom>
              <IncidentRecurrenceDetailsFields
                formName={formName}
                fieldLabels={fieldLabels}
                change={change}
                untouch={untouch}
                data-cy={fieldLabels}
                fieldPrefix={fieldPrefix}
              />
            </CardSection>
          </Fragment>
        );
      case "INCIDENT_ACTION":
        return (
          <CardSection
            key={componentAssignment.component}
            title={fieldLabels.labels.incidentAction}
            gutterTop={gutterTop}
            gutterBottom
            hideTitle={hideTitles}
          >
            <IncidentActionFields
              formName={formName}
              fieldLabels={fieldLabels}
              change={change}
              untouch={untouch}
              data-cy={fieldLabels}
              fieldPrefix={fieldPrefix}
            />
          </CardSection>
        );
      case "INCIDENT_ASSESSMENT":
        return (
          <CardSection
            key={componentAssignment.component}
            title={fieldLabels.labels.incidentAssessment}
            gutterTop={gutterTop}
            gutterBottom
            hideTitle={hideTitles}
          >
            <IncidentAssessmentFields
              workItem={existingWorkItem}
              formName={formName}
              customisations={customisations}
              fieldLabels={fieldLabels}
              change={change}
              untouch={untouch}
              data-cy={fieldLabels}
              fieldPrefix={fieldPrefix}
              scrollToComponent={scrollToComponent}
            />
          </CardSection>
        );
      case "INCIDENT_BREACH_REPORT":
        return (
          <CardSection
            key={componentAssignment.component}
            title={fieldLabels.labels.incidentBreachReport}
            gutterTop={gutterTop}
            gutterBottom
            hideTitle={hideTitles}
          >
            <IncidentBreachReportFields
              formName={formName}
              customisations={customisations}
              fieldLabels={fieldLabels}
              change={change}
              untouch={untouch}
              data-cy={fieldLabels}
              fieldPrefix={fieldPrefix}
            />
          </CardSection>
        );
      case "INITIAL_EMAIL":
        return (
          <CardSection
            key={componentAssignment.component}
            title={fieldLabels.labels.initialEmail}
            gutterTop={gutterTop}
            gutterBottom
            data-cy={fieldLabels.labels.initialEmail}
            hideTitle={hideTitles}
          >
            <InitialEmailFields
              defaultSubject={workItemProxy.title}
              fieldLabels={fieldLabels}
              formName={formName}
              customisations={customisations}
              change={change}
              untouch={untouch}
              data-cy={fieldLabels}
              fieldPrefix={fieldPrefix}
            />
          </CardSection>
        );
      case "INSURANCE":
        return (
          <CardSection
            key={componentAssignment.component}
            title={fieldLabels.labels.insurance}
            gutterTop={gutterTop}
            data-cy={fieldLabels.labels.insurance}
            hideTitle={hideTitles}
          >
            <InsuranceFields
              fieldLabels={fieldLabels}
              formName={formName}
              customisations={customisations}
              change={change}
              untouch={untouch}
              data-cy={fieldLabels}
              fieldPrefix={fieldPrefix}
            />
          </CardSection>
        );
      case "MEMBER_DETAILS":
        return (
          <CardSection
            key={componentAssignment.component}
            title={fieldLabels.labels.memberDetails}
            gutterTop={gutterTop}
            data-cy={fieldLabels.labels.memberDetails}
            hideTitle={hideTitles}
          >
            <MemberDetailsFields
              fieldLabels={fieldLabels}
              customisations={customisations}
              formName={formName}
              change={change}
              untouch={untouch}
              data-cy={fieldLabels}
              fieldPrefix={fieldPrefix}
            />
          </CardSection>
        );
      case "OVERVIEW":
        return (
          <CardSection
            key={componentAssignment.component}
            title="Overview"
            gutterTop={gutterTop}
            data-cy="overview"
            hideTitle={hideTitles}
          >
            <OverviewFields
              workItemDefinition={workItemDefinition}
              workItemCategory={workItemProxy.category}
              hasParent={workItemProxy.hasParent}
              template={template}
              fieldLabels={fieldLabels}
              customisations={customisations}
              formName={formName}
              change={change}
              untouch={untouch}
              data-cy="overview"
              fieldPrefix={fieldPrefix}
            />
          </CardSection>
        );
      case "QUESTIONS":
        return (
          <CardSection
            key={componentAssignment.component}
            title={fieldLabels.labels.questions}
            gutterTop={gutterTop}
            data-cy="questions"
            hideTitle={hideTitles}
          >
            <FieldArray
              name="questions"
              component={QuestionArrayFields}
              workItemDefinition={workItemDefinition}
              customisations={customisations}
              formName={formName}
              change={change}
              untouch={untouch}
              data-cy="questions"
              fieldPrefix={fieldPrefix}
            />
          </CardSection>
        );
      case "RISK_ASSESSMENT":
        return (
          <CardSection
            key={componentAssignment.component}
            gutterTop={gutterTop}
            hideTitle={hideTitles}
          >
            <RiskAssessmentFields
              fieldLabels={fieldLabels}
              customisations={customisations}
              formName={formName}
              change={change}
              untouch={untouch}
              data-cy={fieldLabels}
              fieldPrefix={fieldPrefix}
            />
          </CardSection>
        );
      case "RISK_CONTROL_VERIFICATION":
        return (
          <CardSection
            key={componentAssignment.component}
            title={fieldLabels.labels.riskControlVerificationDetails}
            gutterTop={gutterTop}
            hideTitle={hideTitles}
          >
            <RiskControlVerificationFields
              fieldLabels={fieldLabels}
              customisations={customisations}
              formName={formName}
              change={change}
              untouch={untouch}
              data-cy={fieldLabels}
              fieldPrefix={fieldPrefix}
            />
          </CardSection>
        );
      case "RISK_MEASURE_UPDATE":
        return (
          <CardSection
            key={componentAssignment.component}
            title={fieldLabels.labels.riskMeasureUpdateDetails}
            gutterTop={gutterTop}
            hideTitle={hideTitles}
          >
            <FieldArray
              name="riskMeasureUpdateDetails"
              component={RiskMeasureUpdateDetailsFields}
              fieldLabels={fieldLabels}
              customisations={customisations}
              formName={formName}
              change={change}
              untouch={untouch}
              fieldPrefix={fieldPrefix}
              data-cy={RiskMeasureUpdateDetailsFields}
            />
          </CardSection>
        );
      case "TERMINAL_ILLNESS":
        return (
          <CardSection
            key={componentAssignment.component}
            title={fieldLabels.labels.terminalIllness}
            gutterTop={gutterTop}
            hideTitle={hideTitles}
          >
            <TerminalIllnessFields
              fieldLabels={fieldLabels}
              customisations={customisations}
              formName={formName}
              change={change}
              untouch={untouch}
              data-cy={fieldLabels}
              fieldPrefix={fieldPrefix}
            />
          </CardSection>
        );
      default:
        logger.info(
          `No handler for rendering fields for ${componentAssignment.component}.`
        );
        return null;
    }
  });
};

WorkItemComponentFields.propTypes = {
  workItemDefinition: PropTypes.object.isRequired,
  prefix: PropTypes.string,
  parentType: PropTypes.string,
  template: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  formType: PropTypes.oneOf(["CREATE", "UPDATE"]).isRequired,
  componentName: PropTypes.string,
  existingWorkItem: PropTypes.object,
  scrollToComponent: PropTypes.func,

  // redux
  labels: PropTypes.object.isRequired,
  loggedInUser: PropTypes.object,
  formValues: PropTypes.object,
};

WorkItemComponentFields.defaultProps = {
  loggedInUser: null,
  prefix: null,
  parentType: null,
  componentName: "ALL",
  existingWorkItem: null,
  scrollToComponent: null,
  formValues: {},
};

const mapStateToProps = (state, ownProps) => {
  return {
    labels: getLabels(state),
    loggedInUser: getLoggedInUser(state),
    formValues: getFormValues(ownProps.formName)(state),
  };
};

export default connect(mapStateToProps)(WorkItemComponentFields);
