import { useAuth0 } from "@auth0/auth0-react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import Remove from "@material-ui/icons/Remove";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field } from "redux-form";
import BusinessIcon from "@material-ui/icons/Business";
import { required } from "redux-form-validators";
import { getFilteredReferenceDataType } from "../../../reducers";
import { getRefDataMenuItems } from "../menuItems";
import WrappedSelect from "../wrapper/WrappedSelect";
import WrappedChipListPicker from "../wrapper/WrappedChipListPicker";
import Avatar from "../../common/Avatar.tsx";
import organisationsApi from "../../../services/api/organisations";

const styles = () => ({
  root: {
    width: "100%",
  },
  item: {
    paddingLeft: "0",
    paddingTop: "0",
    paddingBottom: "0",
  },
});

const RelatedOrgsFields = ({
  fields,
  classes,
  region,
  relationshipTypes,
  fieldLabels,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const asyncOrganisationOptionsFetch = async (
    pickerFilter,
    pagination,
    abortController
  ) => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = {
      ...pickerFilter,
      regions: [region],
      limit: pagination.pageSize,
      offset: pagination.offset,
      orderByField: "name",
      excludeGroupFilters: true,
    };
    return organisationsApi.search(
      searchParameters,
      abortController,
      accessToken
    );
  };

  const add = () => {
    fields.push({});
  };

  const remove = (index) => {
    fields.remove(index);
  };

  return (
    <>
      {fields.map((relationship, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <List key={index} className={classes.root}>
          <ListItem dense className={classes.item}>
            <ListItemText>
              <Grid container spacing={1} alignItems="flex-end">
                <Grid item xs={8}>
                  <Field
                    name={`${relationship}.organisation`}
                    component={WrappedChipListPicker}
                    datasource={asyncOrganisationOptionsFetch}
                    label={fieldLabels.labels.organisation}
                    fullWidth
                    clearable
                    isMulti={false}
                    submitOnChange
                    required
                    validate={required({
                      msg: `${fieldLabels.labels.organisation} is required`,
                    })}
                    toOption={(org) => ({ label: org.name, id: org.id, org })}
                    fromOption={(option) => option.org}
                    addIcon={BusinessIcon}
                    renderIcon={(org, size) => (
                      <Avatar name={org.name} size={size} round />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    name={`${relationship}.relationshipType`}
                    component={WrappedSelect}
                    required
                    validate={required({
                      msg: `${fieldLabels.labels.relationshipType} is required`,
                    })}
                    label={fieldLabels.labels.relationshipType}
                    fullWidth
                  >
                    {getRefDataMenuItems(relationshipTypes)}
                  </Field>
                </Grid>
              </Grid>
            </ListItemText>
            <ListItemSecondaryAction>
              <IconButton
                color="primary"
                aria-label="Remove"
                onClick={() => remove(index)}
              >
                <Remove />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      ))}
      <Button size="small" color="primary" aria-label="Add" onClick={add}>
        Add related organisation
      </Button>
    </>
  );
};

RelatedOrgsFields.propTypes = {
  classes: PropTypes.object.isRequired,
  region: PropTypes.string.isRequired,
  fields: PropTypes.object.isRequired,
  relationshipTypes: PropTypes.object.isRequired,
  fieldLabels: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  relationshipTypes: getFilteredReferenceDataType(
    state,
    "RelationshipType",
    (type) => type.props.regions.indexOf(ownProps.region) !== -1
  ),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(RelatedOrgsFields);
