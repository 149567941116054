import { useAuth0 } from "@auth0/auth0-react";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import workItemsApi from "../../../../services/api/workItems";
import FormCard from "../../../common/FormCard";
import ProgressBar from "../../../common/ProgressBar";
import Scorecard from "../../../common/Scorecard";
import EditWorkItemComponentForm, {
  EDIT_WORK_ITEM_COMPONENT_FORM_NAME,
} from "../EditWorkItemComponentForm";
import ComplianceDetails from "./ComplianceDetails";
import QuestionHandler from "../../../../util/questions";

const ComplianceCard = ({ className, fieldLabels, workItem, onChange }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [questions, setQuestions] = useState(null);

  const questionHandler = new QuestionHandler();

  if (!workItem) return null;

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      workItemsApi.listQuestions(workItem.id, accessToken).then(setQuestions);
    })();
  }, [workItem]);

  const responses = questionHandler.getResponses(questions);
  const numberYes = questionHandler.getNumYes(questions);
  const numberNo = questionHandler.getNumNo(questions);
  const numResponders = questionHandler.getNumResponders(questions);
  const numPending = questionHandler.getNumPendingResponses(questions);

  return (
    <FormCard
      className={className}
      title={fieldLabels.labels.compliance}
      formTitle={`Edit ${fieldLabels.labels.compliance}`}
      readOnlyView={() => (
        <>
          <ProgressBar
            totalItems={numResponders}
            totalSelected={responses.length}
            title={`Responses received: ${responses.length}/${numResponders}`}
            subtitle={`${numberYes} Yes,  ${numberNo} No , ${numPending} Pending`}
            loading={questions === null}
            withIcon
          />
          <ComplianceDetails workItem={workItem} />
          {workItem.compliance && workItem.compliance.relevantFundsString && (
            <Scorecard
              label={
                fieldLabels.nestedTypes.compliance.labels.relevantFundsString
              }
            >
              {workItem.compliance.relevantFundsString}
            </Scorecard>
          )}
          {workItem.compliance &&
            workItem.compliance.sourcesOfObligationString && (
              <Scorecard
                label={
                  fieldLabels.nestedTypes.compliance.labels
                    .sourcesOfObligationString
                }
              >
                {workItem.compliance.sourcesOfObligationString}
              </Scorecard>
            )}
        </>
      )}
      formComponent={EditWorkItemComponentForm}
      formName={EDIT_WORK_ITEM_COMPONENT_FORM_NAME}
      onSave={onChange}
      initialValues={{ notes: workItem.notes, compliance: workItem.compliance }}
      formProps={{
        maxWidth: "md",
        workItem,
        componentName: "COMPLIANCE",
      }}
    />
  );
};

ComplianceCard.propTypes = {
  workItem: PropTypes.object,
  fieldLabels: PropTypes.object.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

ComplianceCard.defaultProps = {
  className: undefined,
  workItem: undefined,
  onChange: null,
};

const mapStateToProps = null;

export default connect(mapStateToProps)(ComplianceCard);
