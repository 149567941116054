import Grid from "@material-ui/core/Grid";
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  heading: {
    overflow: "hidden",
  },
  noWrapText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
    maxWidth: "100%",
  },
}));

const ActionHeading = ({ heading, actions }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      spacing={1}
      wrap="nowrap"
    >
      <Grid item className={classes.heading}>
        <div className={classes.noWrapText}>{heading}</div>
      </Grid>
      {actions && actions.length > 0 && (
        <Grid item>
          <Grid container wrap="nowrap">
            {actions.map((a, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Grid item key={i}>
                {a}
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

ActionHeading.propTypes = {
  heading: PropTypes.node.isRequired,
  actions: PropTypes.array.isRequired,
};

export default ActionHeading;
