import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React, { Fragment, useState, useEffect } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import uuid from "uuid/v4";
import RichTextMentionsView from "../../../richtext/RichTextMentionsView";
import CollapseExpand from "../../../CollapseExpand";

const styles = (theme) => ({
  box: {
    marginTop: theme.spacing(1),
  },
  text: {
    color: theme.palette.swatch.secondary,
  },
  comment: {
    color: theme.palette.swatch.primary,
    whiteSpace: "pre-wrap",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

const isNotEmpty = (arr) => arr.length > 0;
const startsWithEndorsements = (str) => str.startsWith("endorsements");

const containsEndorsementChange = (entry) => {
  const results = entry.fieldChanges.filter((item) =>
    isNotEmpty(item.path.filter(startsWithEndorsements))
  );

  return isNotEmpty(results);
};

const containsEndorsements = (entry) =>
  _.has(entry, "fieldChanges") && containsEndorsementChange(entry);

const isEndorsementType = (item) =>
  item.type === "threewks.model.workitem.Endorsement" &&
  (item.changeType === "ADDITION" || item.changeType === "REMOVAL");

const getRawItemValue = (arr, key) =>
  _.first(arr.filter((item) => _.first(item.path) === key)).displayValue;

const completeUIFriendlyEndorsement = (base, changes) => ({
  ...base,
  user: {
    name: getRawItemValue(changes, "createdByName"),
    email: getRawItemValue(changes, "createdByEmail"),
  },
  label: getRawItemValue(changes, "type"),
});

const buildUIFriendlyEndorsement = (item) => {
  const base = {
    id: uuid(),
    removed: item.changeType === "REMOVAL",
  };

  switch (base.removed) {
    case true:
      return completeUIFriendlyEndorsement(base, item.before);
    case false:
      return completeUIFriendlyEndorsement(base, item.after);
    default:
      return null;
  }
};

const useEndorsements = (entry) => {
  const [hasEndorsements, setHasEndorsements] = useState(false);
  const [endorsements, setEndorsements] = useState({});

  useEffect(() => {
    if (containsEndorsements(entry)) {
      const rawEndorsements = entry.fieldChanges.filter(isEndorsementType);

      setHasEndorsements(true);
      setEndorsements(rawEndorsements.map(buildUIFriendlyEndorsement));
    }
  }, [entry]);

  return [hasEndorsements, endorsements];
};

const isEndorsementComment = (entry) =>
  _.has(entry, "comment") && _.get(entry.comment, "type") === "ENDORSEMENT";

const useEndorsementComment = (entry) => {
  const [hasEndorsementComment, setHasEndorsementComment] = useState(false);
  const [endorsementComment, setEndorsementComment] = useState(null);

  useEffect(() => {
    if (isEndorsementComment(entry)) {
      setHasEndorsementComment(true);
      setEndorsementComment(entry.comment.draft);
    }
  }, [entry]);

  return [hasEndorsementComment, endorsementComment];
};

const Endorsements = ({ classes, entry }) => {
  const [hasEndorsements, endorsements] = useEndorsements(entry);
  const [hasEndorsementComment, endorsementComment] =
    useEndorsementComment(entry);

  return (
    <>
      {hasEndorsements && (
        <div className={classes.box}>
          {endorsements.map((endorsement) => (
            <Fragment key={endorsement.id}>
              {hasEndorsementComment && endorsementComment && (
                <Typography
                  component="div"
                  variant="body2"
                  className={classes.comment}
                >
                  <CollapseExpand isNewUI>
                    <RichTextMentionsView value={endorsementComment} />
                  </CollapseExpand>
                </Typography>
              )}

              <Typography variant="body2" className={classes.text}>
                {`${endorsement.removed ? "Removed Endorsement" : "Endorsed"}`}{" "}
                <strong>{endorsement.label}</strong>
              </Typography>
            </Fragment>
          ))}
        </div>
      )}
    </>
  );
};

Endorsements.propTypes = {
  classes: PropTypes.object.isRequired,
  entry: PropTypes.object.isRequired,
};

export default withStyles(styles)(Endorsements);
