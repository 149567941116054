import { useAuth0 } from "@auth0/auth0-react";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import usersApi from "../../services/api/users";
import { roles } from "@certane/arcadia-web-components";
import Avatar from "../common/Avatar.tsx";
import ChoiceChips from "../common/ChoiceChips";
import ClearButton from "../common/ClearButton";
import DebouncedTextField from "../common/DebouncedTextField";
import FiltersBar from "../common/FiltersBar";
import ListPicker from "../ListPicker";

const useStyles = makeStyles((theme) => ({
  clearButtonSearchField: {
    padding: theme.spacing(0.5),
    position: "absolute",
    right: 0,
    top: `-${theme.spacing(2)}px`,
  },
  searchField: {
    fontSize: "0.8125rem",
    lineHeight: "1.1875em",
    minWidth: "200px",
  },
  filterWidget: {
    marginTop: theme.spacing(2),
  },
}));

const ProductFilters = ({ filter, updateFilter, ...rest }) => {
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();
  const [rmPickerOpen, setRmPickerOpen] = useState(false);
  const [selectedRms, setSelectedRms] = useState([]);

  useEffect(() => {
    // initially load the users currently selected in the filter
    if (
      filter.relationshipManagerIds &&
      filter.relationshipManagerIds.length > 0
    ) {
      getAccessTokenSilently().then((accessToken) =>
        usersApi
          .search(
            {
              limit: filter.relationshipManagerIds.length,
              offset: 0,
              roles: [...roles.ADMIN_ROLES, ...roles.AGENT_ROLES],
              userIds: filter.relationshipManagerIds,
            },
            null,
            accessToken
          )
          .then((response) => setSelectedRms(response.results))
      );
    }
  }, []);

  const setRms = (users) => {
    setSelectedRms(users);
    updateFilter(
      "relationshipManagerIds",
      users.map((user) => user.id)
    );
    setRmPickerOpen(false);
  };

  const asyncFetch = (pickerFilter, pagination, abortController) => {
    const searchParameters = {
      ...pickerFilter,
      limit: pagination.pageSize,
      offset: pagination.offset,
      roles: [...roles.ADMIN_ROLES, ...roles.AGENT_ROLES],
      orderByField: "name",
    };
    return getAccessTokenSilently().then((accessToken) =>
      usersApi.search(searchParameters, abortController, accessToken)
    );
  };

  return (
    <>
      <ListPicker
        title="Select relationship managers"
        actionText="Select"
        open={rmPickerOpen}
        onClose={() => setRmPickerOpen(false)}
        onSubmit={(u) => setRms(u)}
        datasource={asyncFetch}
        selected={selectedRms}
        isMulti
        clearable
        toOption={(user) => ({
          label: user.name || "Unknown",
          id: user.id,
          email: user.email,
        })}
        fromOption={(option) => ({
          name: option.label,
          id: option.id,
          email: option.email,
        })}
        renderIcon={(user, size) => (
          <Avatar email={user.email} name={user.name} size={size} round />
        )}
      />
      <FiltersBar {...rest}>
        <>
          <InputLabel data-cy="search">Search</InputLabel>
          <div className={classes.filterWidget}>
            {filter.textSearch && (
              <ClearButton
                className={classes.clearButtonSearchField}
                onClear={() => updateFilter("textSearch", "")}
                data-cy="clear"
              />
            )}
            <DebouncedTextField
              value={filter.textSearch || ""}
              onChange={(text) => updateFilter("textSearch", text)}
              placeholder="Type to filter"
              margin="none"
              InputProps={{
                className: classes.searchField,
              }}
            />
          </div>
        </>
        <>
          <InputLabel data-cy="relationshipManager">
            Relationship Manager
          </InputLabel>
          <div className={classes.filterWidget}>
            <ChoiceChips
              value={
                filter.relationshipManagerIds.length > 0
                  ? "relationshipManagerIds"
                  : null
              }
              options={[
                {
                  label: "Managed by",
                  value: "relationshipManagerIds",
                  badgeValue: filter.relationshipManagerIds.length,
                },
              ]}
              onChange={() => setRmPickerOpen(true)}
            />
          </div>
        </>
      </FiltersBar>
    </>
  );
};

ProductFilters.propTypes = {
  filter: PropTypes.object.isRequired,
  updateFilter: PropTypes.func.isRequired,
};

export default ProductFilters;
