import { useAuth0 } from "@auth0/auth0-react";
import { Tooltip } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Star from "@material-ui/icons/Star";
import StarBorder from "@material-ui/icons/StarBorder";
import classNames from "classnames";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, formValueSelector, getFormValues, reduxForm } from "redux-form";
import { required } from "redux-form-validators";
import { uploadFiles } from "../../../actions/files";
import { loadWorkItemChildren } from "../../../actions/workItems";
import {
  getLabels,
  getLoggedInUser,
  getReferenceDataType,
  getWorkItemChildren,
  isUploading,
} from "../../../reducers";
import usersApi from "../../../services/api/users";
import {
  appliesByCategory,
  appliesByCondition,
  appliesByRoles,
  appliesByStatusCondition,
  getResolvedDefinition,
} from "../../../util/workItemTypeUtils";
import Avatar from "../../common/Avatar.tsx";
import OverlayDropzone from "../../common/OverlayDropzone";
import WrappedCheckbox from "../../forms/wrapper/WrappedCheckbox";
import WrappedChipListPicker from "../../forms/wrapper/WrappedChipListPicker";
import WrappedCustomSelect from "../../forms/wrapper/WrappedCustomSelect";
import WrappedDatePicker from "../../forms/wrapper/WrappedDatePicker";
import WrappedErrorDisplay from "../../forms/wrapper/WrappedErrorDisplay";
import WrappedFilePicker from "../../forms/wrapper/WrappedFilePicker";
import WrappedRichTextMentions from "../../forms/wrapper/WrappedRichTextMentions";
import WrappedSelect from "../../forms/wrapper/WrappedSelect";
import ActionFormFlaggedFileList from "./ActionFormFlaggedFileList";
import EmailFields from "./EmailFields";
import NoEndorsementsWarning from "./NoEndorsementsWarning";
import OpenActionsWarning from "./OpenActionsWarning";
import QuestionWarning from "./QuestionWarning";
import RequiredEndorsementsWarning from "./RequiredEndorsementsWarning";

const styles = (theme) => ({
  statusNew: {
    color: theme.palette.statusColors.NEW,
  },
  statusOpen: {
    color: theme.palette.statusColors.OPEN,
  },
  statusExternalReview: {
    color: theme.palette.statusColors.EXTERNAL_REVIEW,
  },
  statusPendingSubmitter: {
    color: theme.palette.statusColors.PENDING_SUBMITTER,
  },
  statusPendingCounterpartySignature: {
    color: theme.palette.statusColors.PENDING_COUNTERPARTY_SIGNATURE,
  },
  statusOnHold: {
    color: theme.palette.statusColors.ON_HOLD,
  },
  statusMonitoring: {
    color: theme.palette.statusColors.MONITORING,
  },
  statusClosed: {
    color: theme.palette.statusColors.CLOSED,
  },
  statusUnknown: {
    color: theme.palette.statusColors.UNKNOWN,
  },
  priorityNone: {
    color: theme.priority.none,
  },
  priorityHigh: {
    color: theme.priority.high,
  },
  priorityMedium: {
    color: theme.priority.medium,
  },
  priorityLow: {
    color: theme.priority.low,
  },
  gridItem: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      "&:first-child": {
        paddingLeft: 0,
      },
      "&:last-child": {
        alignSelf: "flex-end",
        marginLeft: "auto",
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingRight: 0,
      },
    },
  },
  outcome: {
    minWidth: "180px",
  },
  status: {
    minWidth: "200px",
  },
  owner: {
    maxWidth: "280px",
  },
  important: {
    color: theme.palette.swatch.accent1,
  },
  actionButton: {
    padding: theme.spacing(1) / 4,
    margin: 0,
  },
  itemsContainer: {
    marginBottom: 20,
  },
  actionsContainer: {
    position: "sticky",
    bottom: 0,
    paddingBottom: 16,
    width: "100%",
    "&::before": {
      content: '""',
      display: "block",
      position: "absolute",
      bottom: 0,
      width: "100%",
      height: "140%",
      background: "linear-gradient(to top, white 70%, transparent)",
      zIndex: "-1",
    },
  },
  warning: {
    margin: `${theme.spacing(3)}px 0`,
    display: "block",
    color: theme.palette.warning.main,
  },
});

const statusClass = (classes, status) =>
  classNames({
    [classes.statusNew]: status === "NEW",
    [classes.statusOpen]: status === "OPEN",
    [classes.statusExternalReview]: status === "EXTERNAL_REVIEW",
    [classes.statusPendingSubmitter]: status === "PENDING_SUBMITTER",
    [classes.statusPendingCounterpartySignature]:
      status === "PENDING_COUNTERPARTY_SIGNATURE",
    [classes.statusOnHold]: status === "ON_HOLD",
    [classes.statusMonitoring]: status === "MONITORING",
    [classes.statusClosed]: status === "CLOSED",
    [classes.statusUnknown]: status === "UNKNOWN",
  });

function priorityClassNames(classes, priority) {
  return classNames({
    [classes.priorityHigh]: priority === "HIGH",
    [classes.priorityMedium]: priority === "MEDIUM",
    [classes.priorityLow]: priority === "LOW",
    [classes.priorityNone]: priority === "NONE",
  });
}

export const ACTION_FORM = "ACTION_FORM";

const ActionForm = ({
  classes,
  loggedInUser,
  fields,
  workItem,
  children,
  labels,
  metropolisIntegration,
  includeFlaggedFiles,
  warnForUnansweredQuestions,
  warnForNoEndorsements,
  warnForRequiredEndorsements,
  warnForOpenActions,
  actionType,
  handleSubmit,
  submitButtonText,
  documentFlags,
  types,
  outcomes,
  getFieldValue,
  localUploadFiles,
  uploading,
  submitting,
  change,
  untouch,
  loadChildren,
  endorsementTypes,
  priorityTypes,
  formValues,
  addBeforeSubmitAction,
  addAfterSubmitAction,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const fileOpener = useRef(null);
  const [uploadNamespace] = useState("workitem-action");
  const definition = getResolvedDefinition(
    types,
    workItem.type,
    workItem.parent?.type
  );

  useEffect(() => {
    getAccessTokenSilently().then((accessToken) =>
      loadChildren(workItem.id, accessToken)
    );
  }, []);

  useEffect(() => {
    // trigger revalidate if work item changes
    change("__workItemTs", workItem.updated);
    setTimeout(() => change("__workItemTs", null), 10);
  }, [workItem]);

  const getConfig = () => {
    const availableOutcomes = _.uniq(
      definition.outcomes
        .filter((outcomeAssignment) =>
          appliesByRoles(loggedInUser, outcomeAssignment)
        )
        .filter((outcomeAssignment) =>
          appliesByCondition(workItem, outcomeAssignment.condition)
        )
        .filter((outcomeAssignment) =>
          appliesByStatusCondition(workItem, outcomeAssignment)
        )
        .map((outcomeAssignment) => outcomes.values[outcomeAssignment.outcome])
    );
    const flagAssignments = definition.documentFlags.filter(
      (documentFlagAssignment) =>
        appliesByCategory(workItem, documentFlagAssignment)
    );

    const availableFlags = flagAssignments.map(
      (documentFlagAssignment) =>
        documentFlags.values[documentFlagAssignment.documentFlag]
    );
    const requiredFlags = flagAssignments
      .filter(
        (documentFlagAssignment) =>
          documentFlagAssignment.warnIfAbsent ||
          (documentFlagAssignment.warnIfAbsentWhen &&
            appliesByCondition(
              workItem,
              documentFlagAssignment.warnIfAbsentWhen
            ))
      )
      .map(
        (documentFlagAssignment) =>
          documentFlags.values[documentFlagAssignment.documentFlag]
      );

    const openActions = children.filter((child) => child.status !== "CLOSED");

    const {
      ownableBy,
      collaboratableBy,
      submittableBy,
      endorsementTypes: endorsementAssignments,
    } = definition;

    const relatedUserIds = _.uniq(workItem.users.map((u) => u.user.id));

    const availableStatuses = definition.statuses.filter(
      (statusAssignment) =>
        statusAssignment.status === workItem.status ||
        (statusAssignment.selectable && !statusAssignment.endState)
    );

    const endorsementList = endorsementAssignments
      .filter((endorsementAssignment) =>
        appliesByRoles(loggedInUser, endorsementAssignment)
      )
      .map((endorsementAssignment) => endorsementAssignment.endorsementType);

    return {
      availableOutcomes,
      availableFlags,
      requiredFlags,
      ownableBy,
      collaboratableBy,
      submittableBy,
      relatedUserIds,
      submitterContacts: workItem.users
        .filter((workItemUser) => workItemUser.type === "SUBMITTER")
        .map((workItemUser) => workItemUser.user),
      availableStatuses,
      openActions,
      endorsementList,
    };
  };

  const {
    availableOutcomes,
    availableFlags,
    requiredFlags,
    ownableBy,
    collaboratableBy,
    submittableBy,
    relatedUserIds,
    availableStatuses,
    openActions,
    endorsementList,
  } = getConfig();

  const asyncOwnerOptionsFetch = async (
    pickerFilter,
    pagination,
    abortController
  ) => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = {
      ...pickerFilter,
      limit: pagination.pageSize,
      offset: pagination.offset,
      roles: ownableBy,
      orderByField: "name",
    };
    return usersApi.search(searchParameters, abortController, accessToken);
  };

  const asyncInternalMentionsFetch = async (
    filterText,
    pagination,
    abortController
  ) => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = {
      limit: pagination.pageSize,
      offset: pagination.offset,
      textSearch: filterText || undefined,
      roles: _.uniq([...collaboratableBy, ...ownableBy]),
      orderByField: "name",
    };
    return usersApi.search(searchParameters, abortController, accessToken);
  };

  const asyncPublicMentionsFetch = async (
    filterText,
    pagination,
    abortController
  ) => {
    const accessToken = await getAccessTokenSilently();
    const explicitExternalOrgs = _.get(
      workItem,
      "entityRelationship.organisations",
      []
    )
      .filter((linkedOrg) => linkedOrg.organisation.type === "EXTERNAL")
      .map((linkedOrg) => linkedOrg.organisation);
    const financialProducts = _.get(
      workItem,
      "entityRelationship.financialProducts",
      []
    );
    const implicitExternalOrgs = _.flatMap(
      financialProducts,
      (product) => product.relationships
    )
      .map((relationship) => relationship.organisation)
      .filter((organisation) => organisation.type === "EXTERNAL");
    const externalOrgs = [...explicitExternalOrgs, ...implicitExternalOrgs];
    const externalOrganisationCodes =
      externalOrgs.length === 0
        ? ["UNKNOWN"]
        : _.uniq(externalOrgs.map((org) => org.friendlyId));

    const searchParameters = {
      limit: pagination.pageSize,
      offset: pagination.offset,
      textSearch: filterText || undefined,
      roles: _.uniq([...submittableBy, ...collaboratableBy, ...ownableBy]),
      userIds: _.uniq([...relatedUserIds]),
      externalOrganisationCodes,
      orderByField: "name",
    };
    return usersApi.search(searchParameters, abortController, accessToken);
  };

  const asyncPrivateMentionsFetch = async (
    filterText,
    pagination,
    abortController
  ) => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = {
      limit: pagination.pageSize,
      offset: pagination.offset,
      textSearch: filterText || undefined,
      roles: _.uniq([...submittableBy, ...collaboratableBy, ...ownableBy]),
      organisationCodes: loggedInUser?.externalUser
        ? loggedInUser.organisations.map((org) => org.friendlyId)
        : ["UNKNOWN"],
      orderByField: "name",
    };
    return usersApi.search(searchParameters, abortController, accessToken);
  };

  const includeField = (name) => {
    const field = fields[name];

    if (!field) {
      return false;
    }

    const excludeIfNotSetConditionMet =
      !field.excludeIfNotSet || !!getFieldValue(name);
    const statusConditionMet =
      !field.statusCondition || appliesByStatusCondition(workItem, field);

    return statusConditionMet && excludeIfNotSetConditionMet;
  };

  const isRequired = (name) => {
    const field = fields[name];

    if (!field) {
      return false;
    }

    if (field.required) {
      return true;
    }

    if (field.requiredWhen && field.requiredWhen.length > 0) {
      return (
        field.requiredWhen.filter((condition) => {
          const source =
            condition.source === "EXISTING_OBJECT" ? workItem : formValues;
          return appliesByCondition(source, condition);
        }).length > 0
      );
    }
    return false;
  };

  const getStatusDisplayName = (status) => {
    const statusAssignment = availableStatuses.find((s) => s.status === status);
    return statusAssignment ? statusAssignment.name : status;
  };

  const getPriorityDisplayName = (priority) => {
    const priorityDef = priorityTypes.values[priority];
    return priorityDef ? priorityDef.description : priority;
  };

  const getMentionOptions = () => {
    if (actionType === "ENDORSE" || actionType == "SET_PRIORITY") {
      return [];
    }
    switch (getFieldValue("visibility")) {
      case "INTERNAL":
        return asyncInternalMentionsFetch;
      case "ORG_RESTRICTED":
        return asyncPrivateMentionsFetch;
      default:
        return asyncPublicMentionsFetch;
    }
  };

  const getEndorsementDescription = (type) =>
    endorsementTypes.values[type]
      ? endorsementTypes.values[type].description
      : type;

  const handleDrop = async (acceptedFiles) => {
    const accessToken = await getAccessTokenSilently();
    localUploadFiles(acceptedFiles, uploadNamespace, accessToken);
  };

  const reverse = (items) => {
    const reversed = [...items].reverse();
    return reversed;
  };

  // <-- Form starts here -->
  return (
    <OverlayDropzone
      readonly={!includeField("attachments")}
      onDrop={handleDrop}
      multiple
    >
      <form autoComplete="off">
        <Field
          name="form"
          component={WrappedErrorDisplay}
          fullWidth
          renderError={() => <></>}
          displayAlways
        />
        <div className={classes.itemsContainer}>
          {includeFlaggedFiles && availableFlags.length > 0 ? (
            <ActionFormFlaggedFileList
              availableFlags={availableFlags}
              requiredFlags={requiredFlags}
              workItem={workItem}
              metropolisIntegration={metropolisIntegration}
              metropolisLinkedDocuments={
                getFieldValue("metropolisLinkedDocuments") || {}
              }
              changeMetropolisLinkedDocuments={(values) =>
                change("metropolisLinkedDocuments", values)
              }
              addBeforeSubmitAction={addBeforeSubmitAction}
              addAfterSubmitAction={addAfterSubmitAction}
            />
          ) : null}
          {warnForUnansweredQuestions ? (
            <QuestionWarning workItem={workItem} />
          ) : null}

          {!warnForRequiredEndorsements.length && warnForNoEndorsements ? (
            <NoEndorsementsWarning workItem={workItem} />
          ) : null}

          {warnForRequiredEndorsements.length ? (
            <RequiredEndorsementsWarning
              workItem={workItem}
              warnForRequiredEndorsements={warnForRequiredEndorsements}
              getEndorsementDescription={getEndorsementDescription}
            />
          ) : null}

          {warnForOpenActions && openActions.length > 0 ? (
            <OpenActionsWarning
              type={types.values[workItem.type].description}
            />
          ) : null}

          {includeField("draft") && (
            <Field
              component={WrappedRichTextMentions}
              autoFocus
              name="draft"
              label={fields.draft.label}
              validate={
                isRequired("draft")
                  ? required({ msg: `${fields.draft.label} is required` })
                  : null
              }
              required={isRequired("draft")}
              fullWidth
              helperText={fields.draft.helpText}
              datasource={getMentionOptions()}
              placeholder={fields.draft.placeholder}
            >
              {includeField("important") && getFieldValue("important") && (
                <Tooltip
                  title="Marked as important"
                  className={classes.important}
                >
                  <IconButton
                    className={classes.actionButton}
                    color="inherit"
                    onClick={() => change("important", false)}
                  >
                    <Star />
                  </IconButton>
                </Tooltip>
              )}
              {includeField("important") && !getFieldValue("important") && (
                <Tooltip title="Mark as important">
                  <IconButton
                    className={classes.actionButton}
                    color="inherit"
                    onClick={() => change("important", true)}
                  >
                    <StarBorder />
                  </IconButton>
                </Tooltip>
              )}
              {includeField("attachments") && (
                <Tooltip title="Drag files or click to upload">
                  <IconButton
                    className={classes.actionButton}
                    color="inherit"
                    onClick={() => {
                      fileOpener.current();
                    }}
                  >
                    <AttachFileIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Field>
          )}
          {includeField("email") && (
            <EmailFields
              defaultSubject={workItem.title}
              baseName="email"
              fieldLabels={labels.nestedTypes.email}
              formName={ACTION_FORM}
              change={change}
              untouch={untouch}
            >
              {includeField("attachments") && (
                <Tooltip title="Drag files or click to upload">
                  <IconButton
                    className={classes.actionButton}
                    color="inherit"
                    onClick={() => {
                      fileOpener.current();
                    }}
                  >
                    <AttachFileIcon />
                  </IconButton>
                </Tooltip>
              )}
            </EmailFields>
          )}
          {includeField("attachments") && (
            <Field
              name="attachments"
              label={fields.attachments.label}
              component={WrappedFilePicker}
              validate={
                fields.attachments.required
                  ? required({ msg: `${fields.attachments.label} is required` })
                  : null
              }
              required={fields.attachments.required}
              fullWidth
              uploadNamespace={uploadNamespace}
              disableDropzone
              hideUnderline
              setFileOpener={(fOpener) => {
                fileOpener.current = fOpener;
              }}
            />
          )}
          {includeField("completionDate") && (
            <Field
              name="completionDate"
              label={fields.completionDate.label}
              component={WrappedDatePicker}
              validate={
                fields.completionDate.required
                  ? required({
                      msg: `${fields.completionDate.name} is required`,
                    })
                  : null
              }
              required={fields.completionDate.required}
            />
          )}
        </div>
        <Grid
          container
          alignItems="flex-start"
          justifyContent="space-between"
          className={classes.actionsContainer}
        >
          {includeField("returnForReview") && (
            <Grid item className={classes.gridItem}>
              <Field
                name="returnForReview"
                label={fields.returnForReview.label}
                component={WrappedCheckbox}
                validate={
                  fields.returnForReview.required
                    ? required({
                        msg: `${fields.returnForReview.label} is required`,
                      })
                    : null
                }
                required={fields.returnForReview.required}
              />
            </Grid>
          )}
          {includeField("outcome") && (
            <Grid item className={classes.gridItem}>
              <Field
                className={classes.outcome}
                name="outcome"
                label={fields.outcome.label}
                component={WrappedSelect}
                validate={
                  fields.outcome.required
                    ? required({ msg: `${fields.outcome.label} is required` })
                    : null
                }
                required={fields.outcome.required}
                fullWidth
              >
                {availableOutcomes.map((outcome) => (
                  <MenuItem key={outcome.id} value={outcome.id}>
                    {outcome.description}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
          )}
          {includeField("owner") && (
            <Grid item className={classes.gridItem}>
              <Field
                className={classes.owner}
                name="owner"
                label={fields.owner.label}
                component={WrappedChipListPicker}
                helperText={fields.owner.helpText}
                validate={
                  fields.owner.required
                    ? required({ msg: `${fields.owner.label} is required` })
                    : null
                }
                required={fields.owner.required}
                datasource={asyncOwnerOptionsFetch}
                fullWidth
                isMulti={false}
                renderIcon={(user, size) => (
                  <Avatar
                    email={user.email}
                    name={user.name}
                    size={size}
                    round
                  />
                )}
                toOption={(user) => ({
                  label: user.name || "Unknown",
                  id: user.id,
                  email: user.email,
                })}
                fromOption={(option) => ({
                  name: option.label,
                  id: option.id,
                  email: option.email,
                })}
                submitOnChange
                hideUnderline
              />
            </Grid>
          )}
          {includeField("dueDate") && (
            <Grid item className={classes.gridItem}>
              <Field
                data-cy="dueDate"
                name="dueDate"
                label={fields.dueDate.label}
                component={WrappedDatePicker}
                validate={
                  fields.dueDate.required
                    ? required({
                        msg: `${fields.dueDate.label} is required`,
                      })
                    : null
                }
                required={fields.dueDate.required}
              />
            </Grid>
          )}
          {includeField("status") && (
            <Grid item className={classes.gridItem}>
              <Field
                className={classes.status}
                component={WrappedCustomSelect}
                name="status"
                label={fields.status.label}
                validate={
                  fields.status.required
                    ? required({ msg: `${fields.status.label} is required` })
                    : null
                }
                required={fields.status.required}
                fullWidth
                helperText={fields.status.helpText}
                options={availableStatuses.map((s) => s.status)}
                renderValue={(status) => (
                  <Typography
                    className={statusClass(classes, status)}
                    variant="body1"
                  >
                    {getStatusDisplayName(status)}
                  </Typography>
                )}
                renderListItem={(status) => (
                  <Typography
                    className={statusClass(classes, status)}
                    variant="body1"
                  >
                    {getStatusDisplayName(status)}
                  </Typography>
                )}
              />
            </Grid>
          )}
          {includeField("priority") && (
            <Grid item className={classes.gridItem}>
              <Field
                className={classes.status}
                component={WrappedCustomSelect}
                name="priority"
                label={fields.priority.label}
                validate={
                  fields.priority.required
                    ? required({ msg: `${fields.priority.label} is required` })
                    : null
                }
                required={fields.priority.required}
                fullWidth
                helperText={fields.priority.helpText}
                options={reverse(priorityTypes.ids)}
                renderValue={(priority) => (
                  <Typography
                    className={priorityClassNames(classes, priority)}
                    variant="body1"
                  >
                    {getPriorityDisplayName(priority)}
                  </Typography>
                )}
                renderListItem={(priority) => (
                  <Typography
                    className={priorityClassNames(classes, priority)}
                    variant="body1"
                  >
                    {getPriorityDisplayName(priority)}
                  </Typography>
                )}
              />
            </Grid>
          )}

          {includeField("endorsement") && (
            <Grid item className={classes.gridItem}>
              <Field
                data-cy={fields.endorsement.label}
                className={classes.status}
                component={WrappedSelect}
                name="endorsement"
                label={fields.endorsement.label}
                validate={
                  fields.endorsement.required
                    ? required({
                        msg: `${fields.endorsement.label} is required`,
                      })
                    : null
                }
                required={fields.endorsement.required}
                fullWidth
                helperText={fields.endorsement.helpText}
                options={endorsementList}
                renderValue={(endorsement) => (
                  <Typography variant="body1">
                    {getEndorsementDescription(endorsement)}
                  </Typography>
                )}
                renderListItem={(endorsement) => (
                  <Typography variant="body1">
                    {getEndorsementDescription(endorsement)}
                  </Typography>
                )}
                disabled={endorsementList && endorsementList.length === 1}
              >
                {endorsementList.map((item) => (
                  <MenuItem key={item} value={item}>
                    <Typography variant="body1">
                      {getEndorsementDescription(item)}
                    </Typography>
                  </MenuItem>
                ))}
              </Field>
            </Grid>
          )}
          <Grid item className={classes.gridItem}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={uploading || submitting}
            >
              {submitButtonText}
            </Button>
          </Grid>
        </Grid>
      </form>
    </OverlayDropzone>
  );
};

ActionForm.propTypes = {
  classes: PropTypes.object.isRequired,
  loggedInUser: PropTypes.object,
  fields: PropTypes.object.isRequired,
  workItem: PropTypes.object.isRequired,
  metropolisIntegration: PropTypes.bool,
  includeFlaggedFiles: PropTypes.bool,
  warnForUnansweredQuestions: PropTypes.bool,
  warnForNoEndorsements: PropTypes.bool,
  warnForRequiredEndorsements: PropTypes.array,
  warnForOpenActions: PropTypes.bool,
  actionType: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  addBeforeSubmitAction: PropTypes.func,
  addAfterSubmitAction: PropTypes.func,
  children: PropTypes.array.isRequired,

  // redux state injected
  submitting: PropTypes.bool.isRequired,
  endorsementTypes: PropTypes.any.isRequired,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  types: PropTypes.object.isRequired,
  outcomes: PropTypes.object.isRequired,
  documentFlags: PropTypes.object.isRequired,
  priorityTypes: PropTypes.object.isRequired,
  getFieldValue: PropTypes.func.isRequired,
  uploading: PropTypes.bool.isRequired,
  labels: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,

  localUploadFiles: PropTypes.func.isRequired,
  loadChildren: PropTypes.func.isRequired,
};

ActionForm.defaultProps = {
  loggedInUser: null,
  metropolisIntegration: false,
  includeFlaggedFiles: false,
  warnForUnansweredQuestions: false,
  warnForNoEndorsements: false,
  warnForRequiredEndorsements: [],
  warnForOpenActions: false,
  addBeforeSubmitAction: null,
  addAfterSubmitAction: null,
};

const reduxFormSelector = formValueSelector(ACTION_FORM);

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
  types: getReferenceDataType(state, "WorkItemType"),
  outcomes: getReferenceDataType(state, "WorkItemOutcome"),
  documentFlags: getReferenceDataType(state, "DocumentFlag"),
  getFieldValue: (field) => reduxFormSelector(state, field),
  uploading: isUploading(state),
  children: getWorkItemChildren(state),
  endorsementTypes: getReferenceDataType(state, "EndorsementType"),
  priorityTypes: getReferenceDataType(state, "Priority"),
  labels: getLabels(state).WorkItemActionParameters,
  formValues: getFormValues(ACTION_FORM)(state),
});

const form = reduxForm({ form: ACTION_FORM });
const reduxData = connect(mapStateToProps, {
  localUploadFiles: uploadFiles,
  loadChildren: loadWorkItemChildren,
});

export default compose(form, reduxData)(withStyles(styles)(ActionForm));
