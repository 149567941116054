import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { getTolerancesForRiskAndOwner } from "../../../../actions/risks";
import { setWorkItemStatus } from "../../../../actions/workItems";
import {
  getReferenceDataDescription,
  getRiskTolerances,
  isLoading,
} from "../../../../reducers";
import { getFirstWorkItemUserByType } from "../../../../util/workItemUserUtils";
import AlertDialog from "../../../common/AlertDialog";
import Avatar from "../../../common/Avatar.tsx";
import FormCard from "../../../common/FormCard";
import EditWorkItemComponentForm, {
  EDIT_WORK_ITEM_COMPONENT_FORM_NAME,
} from "../EditWorkItemComponentForm";
import { useAuth0 } from "@auth0/auth0-react";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  owner: {
    display: "flex",
  },
  within: {
    color: theme.metrics.green,
  },
  atRisk: {
    color: theme.metrics.amber,
    fontStyle: "italic",
  },
  exceeded: {
    color: theme.metrics.red,
    fontStyle: "bold",
  },
});

function toleranceStatusClassNames(classes, updateDetail) {
  return classNames({
    [classes.within]: updateDetail.status === "WITHIN",
    [classes.atRisk]: updateDetail.status === "AT_RISK",
    [classes.exceeded]: updateDetail.status === "EXCEEDED",
  });
}

const RiskMeasureUpdates = ({
  classes,
  workItem,
  onChange,
  localSetWorkItemStatus,
  localGetTolerancesForRiskAndOwner,
  riskTolerances,
  getOperationalToleranceStatusDescription,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const [closeFailureError, setCloseFailureError] = useState(null);
  const [submitPromise, setSubmitPromise] = useState(null);

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      const owner = getFirstWorkItemUserByType(workItem.users, "OWNER")?.user;
      if (
        owner &&
        workItem.associatedRisks &&
        workItem.associatedRisks.length
      ) {
        localGetTolerancesForRiskAndOwner(
          workItem.associatedRisks[0].id,
          owner.id,
          accessToken
        );
      }
    })();
  }, [getAccessTokenSilently]);

  const confirmSubmission = async () => {
    const accessToken = await getAccessTokenSilently();

    // update the work item, and then close it
    try {
      await onChange(submitPromise.values);
      const response = await localSetWorkItemStatus(
        workItem.id,
        "CLOSED",
        "COMPLETE",
        null,
        undefined,
        accessToken
      );
      submitPromise.resolve(response);
      setSubmitPromise(null);
    } catch (error) {
      submitPromise.resolve(null);
      setSubmitPromise(null);
      setCloseFailureError(error);
    }
  };

  const cancelSubmission = () => {
    if (submitPromise) {
      submitPromise.resolve(null);
      setSubmitPromise(null);
    }
  };

  const submitReassessment = (values) =>
    new Promise((resolve, reject) => {
      setSubmitPromise({
        resolve,
        reject,
        values,
      });
    });

  const renderUpdateDetails = (updateDetails) => {
    return (
      <List dense>
        {updateDetails.map((updateDetail) => (
          <ListItem
            key={updateDetail.toleranceId}
            className={classes.item}
            divider
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  {updateDetail.description}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between" spacing={1}>
                  <Grid item className={classes.owner}>
                    <Avatar
                      name={updateDetail.owner ? updateDetail.owner.name : "?"}
                      email={
                        updateDetail.owner ? updateDetail.owner.email : "?"
                      }
                      size={24}
                      round
                    />
                    <Typography variant="body2" className={classes.ownerName}>
                      {updateDetail.owner
                        ? updateDetail.owner.name
                        : "No Owner"}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      align="right"
                      className={toleranceStatusClassNames(
                        classes,
                        updateDetail
                      )}
                    >
                      {getOperationalToleranceStatusDescription(
                        updateDetail.status
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" align="right">
                  {updateDetail.comment || "-"}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        ))}
      </List>
    );
  };

  const riskMeasureUpdateDetails = riskTolerances.map((tolerance) => {
    const savedUpdate = workItem.riskMeasureUpdateDetails.find(
      (savedDetails) => savedDetails.toleranceId === tolerance.id
    );
    return {
      toleranceId: tolerance.id,
      owner: tolerance.owner,
      description: tolerance.description,
      status: savedUpdate ? savedUpdate.status : tolerance.status,
      comment: savedUpdate ? savedUpdate.comment : null,
    };
  });

  return (
    <>
      <AlertDialog
        title="Submit measure update"
        body="Submitting the measure update will update the risk operational tolerances and close this measure update task."
        submitButtonText="Submit"
        open={!!submitPromise}
        onCancel={() => cancelSubmission()}
        onSubmit={() => confirmSubmission()}
      />
      <AlertDialog
        title="Close error"
        body="A problem occurred closing the risk measure update work item. Please try again."
        submitButtonText="Ok"
        open={!!closeFailureError}
        onSubmit={() => setCloseFailureError(null)}
      />
      <FormCard
        className={classes.root}
        title="Risk measure update details"
        formTitle="Edit risk measure update details"
        readOnlyView={() => renderUpdateDetails(riskMeasureUpdateDetails)}
        formComponent={EditWorkItemComponentForm}
        formName={EDIT_WORK_ITEM_COMPONENT_FORM_NAME}
        onSave={!onChange ? null : submitReassessment}
        initialValues={{ riskMeasureUpdateDetails }}
        formProps={{
          maxWidth: "md",
          workItem,
          componentName: "RISK_MEASURE_UPDATE",
        }}
      />
    </>
  );
};

RiskMeasureUpdates.propTypes = {
  classes: PropTypes.object.isRequired,
  workItem: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  localSetWorkItemStatus: PropTypes.func.isRequired,
  riskTolerances: PropTypes.array.isRequired,
  localGetTolerancesForRiskAndOwner: PropTypes.func.isRequired,
  getOperationalToleranceStatusDescription: PropTypes.func.isRequired,
};

RiskMeasureUpdates.defaultProps = {
  onChange: null,
};

const mapStateToProps = (state) => ({
  loading: isLoading(state),
  riskTolerances: getRiskTolerances(state),
  getOperationalToleranceStatusDescription: (id) =>
    getReferenceDataDescription(state, "OperationalToleranceStatus", id, "-"),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    localSetWorkItemStatus: setWorkItemStatus,
    localGetTolerancesForRiskAndOwner: getTolerancesForRiskAndOwner,
  })
)(RiskMeasureUpdates);
