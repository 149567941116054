import { useAuth0 } from "@auth0/auth0-react";
import { roles } from "@certane/arcadia-web-components";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  clearActiveRisks,
  deleteRisk,
  getRiskById,
  patchRisk,
} from "../../actions/risks";
import { fetchTags } from "../../actions/tags";
import EntityRelationshipCard from "../../components/business/EntityRelationshipCard";
import ActionButton from "../../components/common/ActionButton";
import ActionHeading from "../../components/common/ActionHeading";
import AlertDialog from "../../components/common/AlertDialog";
import BreadcrumbLink from "../../components/common/BreadcrumbLink";
import Container from "../../components/common/Container";
import HeaderBar from "../../components/common/HeaderBar";
import InlineInputEditSaveCancel from "../../components/common/InlineInputEditSaveCancel";
import PageHeading from "../../components/common/PageHeading";
import PageSubheading from "../../components/common/PageSubheading";
import Error404 from "../../components/error/Error404";
import LoadingIndicator from "../../components/LoadingIndicator";
import ActionPlanCard from "../../components/risk/ActionPlanCard";
import MeasureUpdatesCard from "../../components/risk/MeasureUpdatesCard";
import RiskAssessmentHistoryCard from "../../components/risk/RiskAssessmentHistoryCard";
import RiskControlsCard from "../../components/risk/RiskControlsCard";
import RiskDetailsCard from "../../components/risk/RiskDetailsCard";
import RiskMeasuresCard from "../../components/risk/RiskMeasuresCard";
import RiskOverviewCard from "../../components/risk/RiskOverviewCard";
import RiskRatingHistoryCard from "../../components/risk/RiskRatingHistoryCard";
import AuditHistory from "../../components/workitem/AuditHistory";
import {
  getActiveRisk,
  getLabels,
  getLoggedInUser,
  isLoading,
} from "../../reducers";
import { getWorkItemLink } from "../../routes/routeUtils";
import risksApi from "../../services/api/risks";
import { performExport } from "../../util/asyncRequestHelper";
import { deleteIcon, printIcon, riskIcon } from "../../util/icons";

const relationshipTypes = [
  "PROMOTER",
  "SUB_PROMOTER",
  "TRUSTEE",
  "RESPONSIBLE_ENTITY",
  "KIWISAVER_MANAGER",
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: "4rem",
  },
  container: {
    marginTop: theme.spacing(2),
  },
  tabs: {
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
  },
  tabRoot: {
    minWidth: "100px",
    padding: theme.spacing(2),
  },
  workItemList: {
    marginTop: theme.spacing(2),
  },
  workItemType: {
    backgroundColor: theme.palette.grey[300],
    padding: `${theme.spacing(1) / 4}px ${theme.spacing(1)}px`,
    borderRadius: "16px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: "100%",
    whiteSpace: "nowrap",
    marginRight: theme.spacing(1) / 2,
  },
  card: {
    marginBottom: theme.spacing(2),
  },
}));

const tabs = {
  risk: {
    value: "risk",
    label: "Risk",
  },
  controls: {
    value: "controls",
    label: "Controls",
  },
  measures: {
    value: "measures",
    label: "Measures",
  },
  actionPlan: {
    value: "actionPlan",
    label: "Action plan",
  },
  assessments: {
    value: "assessments",
    label: "Assessments",
  },
  ratingHistory: {
    value: "ratingHistory",
    label: "Rating history",
  },
  auditHistory: {
    value: "auditHistory",
    label: "Audit history",
  },
  breadcrumbLeader: {
    display: "flex",
    alignItems: "center",
  },
};

const orderedTabs = [
  tabs.risk,
  tabs.controls,
  tabs.measures,
  tabs.actionPlan,
  tabs.assessments,
  tabs.ratingHistory,
  tabs.auditHistory,
];

const RiskId = ({
  match: {
    params: { riskId },
  },
  loading,
  history,
  risk,
  loggedInUser,
  fieldLabels,
  localClearActiveRisks,
  localGetRiskById,
  localDeleteRisk,
  localPatchRisk,
  localFetchTags,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(tabs.risk.value);
  const [downloadingFormat, setDownloadingFormat] = useState(null);
  const [confirmDeletionDialogOpen, setConfirmDeletionDialogOpen] =
    useState(false);
  const [dependantWorkItems, setDependantWorkItems] = useState([]);
  const [associatedWorkItems, setAssociatedWorkItems] = useState([]);
  const [usagesLoaded, setUsagesLoaded] = useState(false);

  useEffect(() => {
    localClearActiveRisks();
    (async () => {
      const accessToken = await getAccessTokenSilently();
      localFetchTags(accessToken);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      localGetRiskById(riskId, accessToken);
    })();
  }, [riskId]);

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      if (confirmDeletionDialogOpen) {
        setUsagesLoaded(false);
        risksApi.usage(risk.id, accessToken).then((usage) => {
          setDependantWorkItems(
            _.orderBy(
              usage.dependantWorkItems.filter(
                (workItem) => workItem.status !== "CLOSED"
              ),
              ["type", "title"]
            )
          );
          setAssociatedWorkItems(
            _.orderBy(
              usage.associatedWorkItems.filter(
                (workItem) => workItem.status !== "CLOSED"
              ),
              ["type", "title"]
            )
          );
          setUsagesLoaded(true);
        });
      }
    })();
  }, [confirmDeletionDialogOpen]);

  const handleTabChange = (event, value) => {
    setActiveTab(value);
  };

  const isActiveTab = (tab) => activeTab === tab;

  const onDownload = async (format) => {
    const accessToken = await getAccessTokenSilently();
    setDownloadingFormat(format);
    const searchParameters = {
      riskIds: [riskId],
      limit: 1,
      offset: 0,
    };
    performExport(
      "Risk",
      searchParameters,
      format,
      accessToken,
      () => setDownloadingFormat(null),
      "Risk download",
      "Please wait while we export your risk",
      "This window can be closed"
    );
  };

  const onDeleteRisk = async () => {
    const accessToken = await getAccessTokenSilently();
    localDeleteRisk(risk.id, accessToken).then(() => {
      history.push("/risk/risks");
    });
  };

  if (!risk && loading) {
    return <LoadingIndicator size={60} />;
  }

  if (!risk && !loading) {
    return <Error404 />;
  }

  const workItemList = (text, workItems) => (
    <div className={classes.workItemList}>
      <Typography variant="subtitle1" data-cy={text}>
        {text}
      </Typography>
      <List dense>
        {workItems.map((workItem) => (
          <ListItem
            key={workItem.id}
            component={Link}
            to={getWorkItemLink(workItem)}
            button
            divider
            draggable={false}
          >
            <ListItemText data-cy={workItem.title}>
              <span className={classes.workItemType} data-cy={workItem.title}>
                {workItem.type_display}
              </span>
              {workItem.title}
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </div>
  );

  const renderDeleteConfirmation = () => (
    <div>
      {dependantWorkItems.length > 0 &&
        workItemList(
          "The following work items will also be deleted:",
          dependantWorkItems
        )}
      {associatedWorkItems.length > 0 &&
        workItemList(
          "The following work items will be updated to no longer reference this risk:",
          associatedWorkItems
        )}
    </div>
  );

  const readOnly = false;

  const onChange = readOnly
    ? null
    : async (values) => {
        const accessToken = await getAccessTokenSilently();
        return localPatchRisk(risk.id, values, "Updated", accessToken);
      };

  const isRiskAdmin = loggedInUser
    ? _.intersection(
        [...roles.ADMIN_ROLES, roles.RISK_ADMIN],
        loggedInUser.roles
      ).length > 0
    : false;
  const actions = [
    <ActionButton
      key="download"
      tooltip="Download"
      icon={printIcon()}
      onClick={() => onDownload("PDF")}
      loading={downloadingFormat === "PDF"}
    />,
  ];
  if (isRiskAdmin) {
    actions.push(
      <ActionButton
        tooltip="Delete"
        icon={deleteIcon()}
        onClick={() => setConfirmDeletionDialogOpen(true)}
      />
    );
  }

  return (
    <div className={classes.root}>
      <AlertDialog
        title="Are you sure you want to delete this risk?"
        body={renderDeleteConfirmation}
        submitButtonText="Delete"
        open={confirmDeletionDialogOpen}
        submitEnabled={usagesLoaded}
        onCancel={() => setConfirmDeletionDialogOpen(false)}
        onSubmit={() => onDeleteRisk()}
        data-cy="deleteDialog"
      />
      <HeaderBar>
        <BreadcrumbLink to="/risk/risks" label="Risks" includeArrow />
        <ActionHeading
          heading={
            <>
              <PageHeading
                icon={riskIcon()}
                heading={
                  <InlineInputEditSaveCancel
                    value={risk.title}
                    onChange={(title) => onChange({ title })}
                    fullWidth
                    validate={(value) => !!value}
                    readonly={!onChange}
                  />
                }
                gutterBottom
              />
              <PageSubheading inset subheading={`# ${risk.friendlyId}`} />
            </>
          }
          actions={actions}
        />
      </HeaderBar>
      <Paper elevation={0} square>
        <Container>
          <Grid container spacing={2}>
            {/* TAB Headings */}
            <Grid item sm={12} md={8}>
              <Tabs
                value={activeTab}
                textColor="primary"
                onChange={handleTabChange}
                className={classes.tabs}
              >
                {orderedTabs
                  .filter(
                    (tab) =>
                      !tab.roles ||
                      _.intersection(tab.roles, loggedInUser.roles).length > 0
                  )
                  .map((tab) => (
                    <Tab
                      key={tab.value}
                      value={tab.value}
                      label={tab.label}
                      data-cy={tab.label}
                      classes={{
                        root: classes.tabRoot,
                      }}
                    />
                  ))}
              </Tabs>
            </Grid>
            <Grid item sm={12} md={4}></Grid>
          </Grid>
        </Container>
      </Paper>
      <Container className={classes.container}>
        <Grid container spacing={2}>
          {/* TAB CONTENT */}
          <Grid item sm={12} md={8}>
            {isActiveTab(tabs.risk.value) && (
              <>
                <RiskDetailsCard
                  className={classes.card}
                  risk={risk}
                  fieldLabels={fieldLabels}
                  onChange={onChange}
                />
                <EntityRelationshipCard
                  className={classes.card}
                  title={fieldLabels.labels.entityRelationship}
                  baseEntity={risk}
                  fieldLabels={fieldLabels}
                  onChange={onChange}
                  customisations={[
                    {
                      field: "entityRelationship.fundLevel",
                      state: "EXCLUDED",
                    },
                  ]}
                  defaultVisibilityScope={{
                    INTERNAL: "ORGANISATION",
                    EXTERNAL: "ORGANISATION",
                  }}
                  autoPopulateEntitiesByRelationshipTypes={relationshipTypes}
                  filterEntitiesByRelationshipTypes={relationshipTypes}
                />
              </>
            )}
            {isActiveTab(tabs.controls.value) && (
              <RiskControlsCard risk={risk} />
            )}
            {isActiveTab(tabs.measures.value) && (
              <>
                <RiskMeasuresCard />
                <MeasureUpdatesCard />
              </>
            )}
            {isActiveTab(tabs.actionPlan.value) && <ActionPlanCard />}
            {isActiveTab(tabs.assessments.value) && (
              <RiskAssessmentHistoryCard />
            )}
            {isActiveTab(tabs.auditHistory.value) && (
              <AuditHistory
                auditHistory={risk.auditHistory}
                data-cy={risk.auditHistory}
              />
            )}
            {isActiveTab(tabs.ratingHistory.value) && <RiskRatingHistoryCard />}
          </Grid>
          <Grid item sm={12} md={4}>
            <RiskOverviewCard
              loggedInUser={loggedInUser}
              risk={risk}
              onChange={onChange}
              fieldLabels={fieldLabels}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

RiskId.propTypes = {
  loading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  risk: PropTypes.object,
  loggedInUser: PropTypes.object,
  fieldLabels: PropTypes.object.isRequired,
  localClearActiveRisks: PropTypes.func.isRequired,
  localGetRiskById: PropTypes.func.isRequired,
  localDeleteRisk: PropTypes.func.isRequired,
  localPatchRisk: PropTypes.func.isRequired,
  localFetchTags: PropTypes.func.isRequired,
};

RiskId.defaultProps = {
  risk: null,
  loggedInUser: null,
};

const mapStateToProps = (state) => ({
  loading: isLoading(state),
  risk: getActiveRisk(state),
  loggedInUser: getLoggedInUser(state),
  fieldLabels: getLabels(state).Risk,
});

export default connect(mapStateToProps, {
  localGetRiskById: getRiskById,
  localClearActiveRisks: clearActiveRisks,
  localDeleteRisk: deleteRisk,
  localPatchRisk: patchRisk,
  localFetchTags: fetchTags,
})(RiskId);
