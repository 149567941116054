import React from "react";
import classNames from "classnames";
import { Typography, withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import MinMaxButton from "./MinMaxButton";
import CloseButton from "./CloseButton";

const styles = (theme) => ({
  root: {
    zIndex: "1200",
    backgroundColor: "transparent",
    position: "fixed",
    bottom: 0,
    right: 0,
    width: "100%",
    overflowY: "hidden",
    pointerEvents: "none",
    paddingTop: 30,
    transform: "translate3d(0, 100%, 0)",
    transition: "transform 300ms cubic-bezier(0.23, 1, 0.32, 1)",
    [theme.breakpoints.up("sm")]: {
      maxWidth: 800,
      minWidth: 410,
      paddingTop: 50,
      paddingLeft: 50,
      paddingBottom: 0,
      paddingRight: 50,
    },
  },
  shellOpen: {
    transform: "translate3d(0, 0, 0)",
  },
  shellMinimized: {
    transform: "translate3d(0, calc(100% - 88px), 0)",
    [theme.breakpoints.up("sm")]: {
      transform: "translate3d(0, calc(100% - 108px), 0)",
    },
  },
  enabled: {
    pointerEvents: "auto",
  },
  interface: {
    backgroundColor: theme.palette.common.white,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    overflow: "hidden",
    transition: "box-shadow 300ms cubic-bezier(0.23, 1, 0.32, 1)",
    position: "relative",
  },
  interfaceOpen: {
    boxShadow: "0 -8px 20px rgba(0, 0, 0, 0.3)",
    [theme.breakpoints.up("sm")]: {
      boxShadow: "0 8px 50px rgba(0, 0, 0, 0.3)",
    },
  },
  interfaceMinimized: {
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
  },
  header: {
    color: theme.palette.common.white,
    background: theme.palette.secondary.dark,
    padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px`,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  heading: {
    flexShrink: "0",
  },
  headerSpacer: {
    flexGrow: "1",
    flexShrink: "1",
    flexBasis: "100%",
  },
  padder: {
    maxHeight: "55vh",
    overflowY: "scroll",
    backgroundColor: theme.palette.common.white,
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px 0 ${theme.spacing(
      3
    )}px`,
  },
});

const getRootClasses = (classes, open, minimized) =>
  classNames({
    [classes.root]: true,
    [classes.enabled]: open,
    [classes.shellOpen]: !minimized && open,
    [classes.shellMinimized]: open && minimized,
  });

const getInterfaceClasses = (classes, open, minimized) =>
  classNames({
    [classes.interface]: true,
    [classes.interfaceOpen]: !minimized && open,
    [classes.interfaceMinimized]: open && minimized,
  });

const Shell = ({
  classes,
  children,
  title,
  isOpen,
  isMinimized,
  onClose,
  onMinimize,
  notices,
}) => {
  const onHeaderButtonClick = (handler) => (event) => {
    event.stopPropagation();
    handler(event);
  };

  return (
    <div className={getRootClasses(classes, isOpen, isMinimized)}>
      <div className={getInterfaceClasses(classes, isOpen, isMinimized)}>
        <div
          className={classes.header}
          role="button"
          tabIndex="0"
          onKeyPress={onMinimize}
          onClick={onMinimize}
        >
          <Typography
            className={classes.heading}
            variant="h6"
            color="inherit"
            data-cy={title}
          >
            {title}
          </Typography>
          <span className={classes.headerSpacer} />
          <MinMaxButton
            isMinimized={isMinimized}
            onClick={onHeaderButtonClick(onMinimize)}
          />
          <CloseButton onClick={onHeaderButtonClick(onClose)} />
        </div>
        {notices}
        <div className={classes.padder}>{children}</div>
      </div>
    </div>
  );
};

Shell.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isMinimized: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onMinimize: PropTypes.func.isRequired,
  notices: PropTypes.array.isRequired,
};

export default withStyles(styles)(Shell);
