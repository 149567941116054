import PropTypes from "prop-types";
import React, { useState } from "react";
import ComponentField from "../../../forms/wrapper/ComponentField";
import WrappedFilePicker from "../../../forms/wrapper/WrappedFilePicker";

const FilesFields = ({
  workItemDefinition,
  fieldPrefix,
  fieldLabels,
  customisations,
  change,
  untouch,
  formName,
}) => {
  const [uploadNamespace] = useState(`${fieldPrefix}-workitem-self`);
  return (
    <ComponentField
      name="documents"
      fieldPrefix={fieldPrefix}
      component={WrappedFilePicker}
      label={fieldLabels.labels.documents}
      fullWidth
      uploadNamespace={uploadNamespace}
      helperText={`Please upload any documents relevant to your ${workItemDefinition.name.toLowerCase()}.`}
      customisations={customisations}
      change={change}
      untouch={untouch}
      formName={formName}
      data-cy="documents"
    />
  );
};

FilesFields.propTypes = {
  workItemDefinition: PropTypes.object.isRequired,
  fieldPrefix: PropTypes.string,
  fieldLabels: PropTypes.object.isRequired,
  customisations: PropTypes.array,
  formName: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
};

FilesFields.defaultProps = {
  fieldPrefix: "",
  customisations: [],
};

export default FilesFields;
