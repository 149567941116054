import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import SimpleChips from "../../../common/SimpleChips";
import ComponentScorecard from "../../../common/ComponentScorecard";

const OriginFieldsReadOnly = ({
  workItem,
  name,
  customisations,
  fieldLabels,
}) => {
  const type = _.get(workItem, `${name}.type`);

  return (
    <>
      {!type && "Not yet known"}
      {type === "ENTITY" && (
        <ComponentScorecard
          fieldLabels={fieldLabels}
          name={`${name}.relatedEntities`}
          customisations={customisations}
          entity={workItem}
          render={(value) => (
            <SimpleChips values={value.map((org) => org.name)} blankLabel="-" />
          )}
        />
      )}
      {type === "OTHER" && (
        <ComponentScorecard
          fieldLabels={fieldLabels}
          name={`${name}.other`}
          customisations={customisations}
          entity={workItem}
          render={(value) => value}
        />
      )}
    </>
  );
};

OriginFieldsReadOnly.propTypes = {
  customisations: PropTypes.array.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  workItem: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};

export default OriginFieldsReadOnly;
