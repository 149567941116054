import React, { useState, useEffect } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import pluralize from "pluralize";

const createFriendlyDate = (daysElapsed) => {
  // The present day or either side of it.
  switch (daysElapsed) {
    case 0:
      return "Today";
    case 1:
      return "Tomorrow";
    case -1:
      return "Yesterday";
    default:
      break;
  }

  // Appearing in the past or the future.
  const quantifier = pluralize("day", Math.abs(daysElapsed), true);
  switch (daysElapsed <= 0) {
    case true:
      return `${quantifier} ago`;
    case false:
      return `In ${quantifier}`;
    default:
      return "Unknown";
  }
};

const DateAgo = ({ textClass, value, expandable }) => {
  const [shortDate, setShortDate] = useState("...");
  const [longDate, setLongDate] = useState("...");

  useEffect(() => {
    const current = moment(value).startOf("day");
    const daysElapsed = current.diff(moment().startOf("day"), "days");

    setShortDate(
      Math.abs(daysElapsed) <= 30
        ? createFriendlyDate(daysElapsed)
        : current.format("D/M/YYYY")
    );

    setLongDate(current.format("dddd, MMMM Do YYYY"));
  }, [value]);

  const stamp = <span className={textClass}>{shortDate}</span>;

  return expandable ? <Tooltip title={longDate}>{stamp}</Tooltip> : stamp;
};

DateAgo.propTypes = {
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string])
    .isRequired,
  textClass: PropTypes.string,
  expandable: PropTypes.bool,
};

DateAgo.defaultProps = {
  expandable: false,
  textClass: undefined,
};

export default DateAgo;
