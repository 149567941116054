import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { FieldArray, formValueSelector } from "redux-form";
import { email, length, required } from "redux-form-validators";
import ComponentField from "../../forms/wrapper/ComponentField";
import WrappedDateTimePicker from "../../forms/wrapper/WrappedDateTimePicker";
import WrappedHtml from "../../forms/wrapper/WrappedHtml";
import WrappedTextField from "../../forms/wrapper/WrappedTextField";
import EmailToField from "./EmailToField";

const EmailFields = ({
  defaultSubject,
  baseName,
  fieldLabels,
  customisations,
  change,
  untouch,
  formName,
  incoming,
  subject,
  ...rest
}) => {
  const fieldPrefix = baseName ? `${baseName}.` : "";

  useEffect(() => {
    if (subject) {
      change(`${fieldPrefix}subject`, subject);
    } else {
      change(`${fieldPrefix}subject`, defaultSubject);
    }
  }, [defaultSubject, subject]);

  return (
    <>
      {incoming && (
        <>
          <Typography variant="subtitle1">From</Typography>
          <Grid container spacing={2} alignItems="baseline">
            <Grid item xs={12} sm={6}>
              <ComponentField
                name={`${fieldPrefix}from.name`}
                component={WrappedTextField}
                label={fieldLabels.nestedTypes.from.labels.name}
                validate={required({
                  msg: `${fieldLabels.nestedTypes.from.labels.name} is required`,
                })}
                fullWidth
                required
                customisations={customisations}
                change={change}
                untouch={untouch}
                formName={formName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ComponentField
                name={`${fieldPrefix}from.address`}
                component={WrappedTextField}
                label={fieldLabels.nestedTypes.from.labels.address}
                validate={[
                  required({
                    msg: `${fieldLabels.nestedTypes.from.labels.address} is required`,
                  }),
                  email({
                    msg: `${fieldLabels.nestedTypes.from.labels.address} must be an email`,
                  }),
                ]}
                fullWidth
                required
                customisations={customisations}
                change={change}
                untouch={untouch}
                formName={formName}
              />
            </Grid>
          </Grid>
        </>
      )}
      <FieldArray
        name={`${fieldPrefix}to`}
        component={EmailToField}
        label="To"
        fieldLabels={fieldLabels}
        formName={formName}
        customisations={customisations}
        change={change}
        untouch={untouch}
        validate={length({
          min: incoming ? 0 : 1,
          msg: `${fieldLabels.labels.to} is required`,
        })}
      />
      <FieldArray
        name={`${fieldPrefix}cc`}
        component={EmailToField}
        label="Cc"
        fieldLabels={fieldLabels}
        formName={formName}
        customisations={customisations}
        change={change}
        untouch={untouch}
      />
      <ComponentField
        name={`${fieldPrefix}subject`}
        component={WrappedTextField}
        label={fieldLabels.labels.subject}
        validate={required({
          msg: `${fieldLabels.labels.subject} is required`,
        })}
        fullWidth
        required
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      />
      {incoming && (
        <ComponentField
          component={WrappedDateTimePicker}
          name={`${fieldPrefix}sent`}
          label={fieldLabels.labels.sent}
          validate={required({ msg: `${fieldLabels.labels.sent} is required` })}
          fullWidth
          customisations={customisations}
          change={change}
          untouch={untouch}
          formName={formName}
          required
          disableFuture
        />
      )}
      <ComponentField
        component={WrappedHtml}
        name={`${fieldPrefix}htmlBody`}
        label={fieldLabels.labels.htmlBody}
        validate={required({
          msg: `${fieldLabels.labels.htmlBody} is required`,
        })}
        fullWidth
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
        required
        {...rest}
      />
    </>
  );
};

EmailFields.propTypes = {
  baseName: PropTypes.string.isRequired,
  customisations: PropTypes.array,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  defaultSubject: PropTypes.string,

  // redux
  incoming: PropTypes.bool,
  subject: PropTypes.string,
};

EmailFields.defaultProps = {
  customisations: [],
  incoming: true,
  defaultSubject: "",
  subject: "",
};

const mapStateToProps = (state, { formName, baseName }) => {
  const reduxFormSelector = formValueSelector(formName);
  const fieldPrefix = baseName ? `${baseName}.` : "";
  return {
    incoming: reduxFormSelector(state, `${fieldPrefix}incoming`),
    subject: reduxFormSelector(state, `${fieldPrefix}subject`),
  };
};

export default connect(mapStateToProps)(EmailFields);
