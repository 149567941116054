import InputAdornment from "@material-ui/core/InputAdornment";
import PropTypes from "prop-types";
import React from "react";
import { numericality } from "redux-form-validators";
import ComponentField from "../../../forms/wrapper/ComponentField";
import WrappedDatePicker from "../../../forms/wrapper/WrappedDatePicker";
import WrappedTextField from "../../../forms/wrapper/WrappedTextField";
import WrappedRadioGroup from "../../../forms/wrapper/WrappedRadioGroup";
import { yesNoOptions } from "../../../../util/radioOptions";

const ClaimsFields = ({
  fieldLabels,
  customisations,
  change,
  untouch,
  formName,
  formValues,
}) => (
  <>
    <ComponentField
      component={WrappedDatePicker}
      name="claimDetails.dateReceived"
      label={fieldLabels.nestedTypes.claimDetails.labels.dateReceived}
      fullWidth
      data-cy={fieldLabels.nestedTypes.claimDetails.labels.dateReceived}
      helperText="Date complete claim paperwork received."
      disableFuture
      customisations={customisations}
      change={change}
      untouch={untouch}
      formName={formName}
    />
    <ComponentField
      component={WrappedDatePicker}
      name="claimDetails.dateOfClaim"
      label={fieldLabels.nestedTypes.claimDetails.labels.dateOfClaim}
      fullWidth
      data-cy={fieldLabels.nestedTypes.claimDetails.labels.dateOfClaim}
      helperText="Date of Disablement as per Doctor's certificate."
      customisations={customisations}
      change={change}
      untouch={untouch}
      formName={formName}
    />
    <ComponentField
      component={WrappedDatePicker}
      name="claimDetails.startDateOfClaim"
      label={fieldLabels.nestedTypes.claimDetails.labels.startDateOfClaim}
      fullWidth
      data-cy={fieldLabels.nestedTypes.claimDetails.labels.startDateOfClaim}
      maxDate={
        formValues.claimDetails?.endDateOfClaim
          ? formValues.claimDetails?.endDateOfClaim
          : undefined
      }
      customisations={customisations}
      change={change}
      untouch={untouch}
      formName={formName}
    />
    <ComponentField
      component={WrappedDatePicker}
      name="claimDetails.endDateOfClaim"
      label={fieldLabels.nestedTypes.claimDetails.labels.endDateOfClaim}
      fullWidth
      data-cy={fieldLabels.nestedTypes.claimDetails.labels.endDateOfClaim}
      minDate={
        formValues.claimDetails?.startDateOfClaim
          ? formValues.claimDetails.startDateOfClaim
          : undefined
      }
      customisations={customisations}
      change={change}
      untouch={untouch}
      formName={formName}
    />
    <ComponentField
      component={WrappedDatePicker}
      name="claimDetails.dateOfUnderwriting"
      label={fieldLabels.nestedTypes.claimDetails.labels.dateOfUnderwriting}
      fullWidth
      data-cy={fieldLabels.nestedTypes.claimDetails.labels.dateOfUnderwriting}
      customisations={customisations}
      change={change}
      untouch={untouch}
      formName={formName}
    />
    <ComponentField
      component={WrappedTextField}
      name="claimDetails.benefitAmount"
      label={fieldLabels.nestedTypes.claimDetails.labels.benefitAmount}
      fullWidth
      data-cy={fieldLabels.nestedTypes.claimDetails.labels.benefitAmount}
      validate={numericality({
        ">=": 0,
        allowBlank: true,
      })}
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
      customisations={customisations}
      change={change}
      untouch={untouch}
      formName={formName}
    />
    <ComponentField
      component={WrappedRadioGroup}
      name="claimDetails.underwritingDeathCover"
      label={fieldLabels.nestedTypes.claimDetails.labels.underwritingDeathCover}
      fullWidth
      options={yesNoOptions}
      row
      customisations={customisations}
      change={change}
      untouch={untouch}
      formName={formName}
    />
    <ComponentField
      component={WrappedRadioGroup}
      name="claimDetails.underwritingTpdCover"
      label={fieldLabels.nestedTypes.claimDetails.labels.underwritingTpdCover}
      fullWidth
      options={yesNoOptions}
      row
      customisations={customisations}
      change={change}
      untouch={untouch}
      formName={formName}
    />
    <ComponentField
      component={WrappedRadioGroup}
      name="claimDetails.underwritingIpCover"
      label={fieldLabels.nestedTypes.claimDetails.labels.underwritingIpCover}
      fullWidth
      options={yesNoOptions}
      row
      customisations={customisations}
      change={change}
      untouch={untouch}
      formName={formName}
    />
  </>
);

ClaimsFields.propTypes = {
  fieldLabels: PropTypes.object.isRequired,
  customisations: PropTypes.array,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  formValues: PropTypes.object.isRequired,
};

ClaimsFields.defaultProps = {
  customisations: [],
};

export default ClaimsFields;
