import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { getReferenceDataDescription } from "../../../../reducers";
import FormCard from "../../../common/FormCard";
import Scorecard from "../../../common/Scorecard";
import EditWorkItemComponentForm, {
  EDIT_WORK_ITEM_COMPONENT_FORM_NAME,
} from "../EditWorkItemComponentForm";

const IncidentActionCard = ({
  className,
  workItem,
  onChange,
  fieldLabels,
  getIncidentActionTypeDescription,
}) => (
  <FormCard
    className={className}
    title={fieldLabels.labels.incidentAction}
    formTitle={`Edit ${fieldLabels.labels.incidentAction.toLowerCase()}`}
    readOnlyView={() => (
      <Scorecard
        label={fieldLabels.nestedTypes.incidentAction.labels.actionType}
      >
        {getIncidentActionTypeDescription(
          workItem.incidentAction ? workItem.incidentAction.actionType : null
        )}
      </Scorecard>
    )}
    formComponent={EditWorkItemComponentForm}
    formName={EDIT_WORK_ITEM_COMPONENT_FORM_NAME}
    onSave={onChange}
    initialValues={{ incidentAction: workItem.incidentAction }}
    formProps={{
      maxWidth: "md",
      workItem,
      componentName: "INCIDENT_ACTION",
    }}
  />
);

IncidentActionCard.propTypes = {
  className: PropTypes.string,
  workItem: PropTypes.object.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  getIncidentActionTypeDescription: PropTypes.func.isRequired,
  onChange: PropTypes.func,
};

IncidentActionCard.defaultProps = {
  className: undefined,
  onChange: null,
};

const mapStateToProps = (state) => ({
  getIncidentActionTypeDescription: (type) =>
    getReferenceDataDescription(state, "IncidentActionType", type, "-"),
});

export default connect(mapStateToProps)(IncidentActionCard);
