import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { length } from "redux-form-validators";
import { getReferenceDataType } from "../../../../reducers";
import ComponentField from "../../../forms/wrapper/ComponentField";
import WrappedSelect from "../../../forms/wrapper/WrappedSelect";
import WrappedSelectChips from "../../../forms/wrapper/WrappedSelectChips";
import WrappedTextField from "../../../forms/wrapper/WrappedTextField";
import { getRefDataMenuItems } from "../../../forms/menuItems";

const ComplianceDetailsFields = ({
  fieldLabels,
  complianceSourceTypes,
  complianceSources,
  regulators,
  services,
  products,
  selectedSourceTypes,
  selectedComplianceSources,
  customisations,
  change,
  untouch,
  formName,
}) => {
  const [availableSourceIds, setAvailableSourceIds] = useState([]);

  useEffect(() => {
    const sources = complianceSources.ids.filter((id) =>
      _.includes(
        selectedSourceTypes || [],
        complianceSources.values[id].props.sourceType
      )
    );
    setAvailableSourceIds(sources);
    if (selectedComplianceSources) {
      const validSources = selectedComplianceSources.filter((s) =>
        _.includes(sources, s)
      );
      change("compliance.complianceSources", validSources);
    }
  }, [selectedSourceTypes]);

  return (
    <>
      <ComponentField
        name="notes"
        component={WrappedTextField}
        label={fieldLabels.labels.notes}
        fullWidth
        helperText="Provide instructions for this compliance task"
        multiline
        rowsMax={10}
        rows={2}
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      />
      <ComponentField
        name="compliance.sourceTypes"
        component={WrappedSelectChips}
        options={complianceSourceTypes.ids}
        label={fieldLabels.nestedTypes.compliance.labels.sourceTypes}
        validate={length({
          min: 1,
          msg: `${fieldLabels.nestedTypes.compliance.labels.sourceTypes} is required`,
        })}
        required
        fullWidth
        toOption={(id) => ({
          label: complianceSourceTypes.values[id].description,
          value: id,
        })}
        fromOption={(option) => option.value}
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      />
      {availableSourceIds.length > 0 && (
        <ComponentField
          name="compliance.complianceSources"
          component={WrappedSelectChips}
          options={availableSourceIds}
          label={fieldLabels.nestedTypes.compliance.labels.complianceSources}
          required
          validate={length({
            min: 1,
            msg: `${fieldLabels.nestedTypes.compliance.labels.complianceSources} is required`,
          })}
          fullWidth
          toOption={(id) => ({
            label: complianceSources.values[id].description,
            value: id,
          })}
          fromOption={(option) => option.value}
          customisations={customisations}
          change={change}
          untouch={untouch}
          formName={formName}
        />
      )}
      <ComponentField
        name="compliance.reference"
        component={WrappedTextField}
        label={fieldLabels.nestedTypes.compliance.labels.reference}
        fullWidth
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      />
      <ComponentField
        name="compliance.regulator"
        component={WrappedSelect}
        label={fieldLabels.nestedTypes.compliance.labels.regulator}
        fullWidth
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      >
        {getRefDataMenuItems(regulators)}
      </ComponentField>
      <ComponentField
        name="compliance.service"
        component={WrappedSelect}
        label={fieldLabels.nestedTypes.compliance.labels.service}
        fullWidth
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      >
        {getRefDataMenuItems(services)}
      </ComponentField>
      <ComponentField
        name="compliance.product"
        component={WrappedSelect}
        label={fieldLabels.nestedTypes.compliance.labels.product}
        fullWidth
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      >
        {getRefDataMenuItems(products)}
      </ComponentField>
    </>
  );
};

ComplianceDetailsFields.propTypes = {
  customisations: PropTypes.array,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  complianceSourceTypes: PropTypes.object.isRequired,
  complianceSources: PropTypes.object.isRequired,
  regulators: PropTypes.object.isRequired,
  services: PropTypes.object.isRequired,
  products: PropTypes.object.isRequired,
  selectedSourceTypes: PropTypes.array,
  selectedComplianceSources: PropTypes.array,
};

ComplianceDetailsFields.defaultProps = {
  customisations: [],
  selectedSourceTypes: null,
  selectedComplianceSources: null,
};

const mapStateToProps = (state, ownProps) => {
  const reduxFormSelector = formValueSelector(ownProps.formName);
  return {
    complianceSourceTypes: getReferenceDataType(state, "ComplianceSourceType"),
    complianceSources: getReferenceDataType(state, "ComplianceSource"),
    regulators: getReferenceDataType(state, "Regulator"),
    services: getReferenceDataType(state, "Service"),
    products: getReferenceDataType(state, "ComplianceProduct"),
    selectedSourceTypes: reduxFormSelector(state, "compliance.sourceTypes"),
    selectedComplianceSources: reduxFormSelector(
      state,
      "compliance.complianceSources"
    ),
  };
};

export default connect(mapStateToProps)(ComplianceDetailsFields);
