import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import FormCard from "../../../common/FormCard";
import EditWorkItemComponentForm, {
  EDIT_WORK_ITEM_COMPONENT_FORM_NAME,
} from "../EditWorkItemComponentForm";
import InsuranceReadOnly from "./InsuranceReadOnly";

const InsuranceCard = ({
  className,
  fieldLabels,
  workItem,
  onChange,
  customisations,
}) => {
  const { insurance } = workItem;

  return (
    <FormCard
      className={className}
      title={fieldLabels.labels.insurance}
      formTitle={`Edit ${fieldLabels.labels.insurance}`}
      readOnlyView={() => (
        <InsuranceReadOnly
          workItem={workItem}
          fieldLabels={fieldLabels}
          customisations={customisations}
        />
      )}
      formComponent={EditWorkItemComponentForm}
      formName={EDIT_WORK_ITEM_COMPONENT_FORM_NAME}
      onSave={onChange}
      initialValues={{ insurance }}
      formProps={{
        maxWidth: "md",
        workItem,
        componentName: "INSURANCE",
      }}
    />
  );
};

InsuranceCard.propTypes = {
  className: PropTypes.string,
  fieldLabels: PropTypes.object.isRequired,
  workItem: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  customisations: PropTypes.array,
};

InsuranceCard.defaultProps = {
  className: undefined,
  customisations: [],
  onChange: null,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(InsuranceCard);
