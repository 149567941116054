import { withStyles } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import * as PropTypes from "prop-types";
import React from "react";

const styles = () => ({
  label: {
    position: "relative",
  },
  root: {
    maxWidth: "100%",
  },
});

const WrappedNonInteractive = ({
  classes,
  required,
  input: { value, name },
  meta: { touched, error, invalid },
  label,
  margin,
  render,
  helperText,
  fullWidth,
}) => (
  <FormControl
    className={classes.root}
    fullWidth={fullWidth}
    margin={margin}
    name={`position-${name}`}
  >
    {label && (
      <InputLabel shrink className={classes.label} required={required}>
        {label}
      </InputLabel>
    )}
    {render(value)}
    {touched && error && (
      <FormHelperText error={touched && invalid}>{error}</FormHelperText>
    )}
    {!(touched && error) && helperText && (
      <FormHelperText>{helperText}</FormHelperText>
    )}
  </FormControl>
);

WrappedNonInteractive.propTypes = {
  classes: PropTypes.object.isRequired,
  required: PropTypes.bool,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string,
  margin: PropTypes.string,
  render: PropTypes.func.isRequired,
  helperText: PropTypes.any,
  fullWidth: PropTypes.bool,
};

WrappedNonInteractive.defaultProps = {
  required: false,
  margin: "dense",
  label: null,
  helperText: "",
  fullWidth: true,
};

export default withStyles(styles)(WrappedNonInteractive);
