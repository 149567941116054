import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import React from "react";
import QuestionFields from "./QuestionFields";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  question: {
    border: `solid 1px ${theme.palette.grey[300]}`,
  },
  questionForm: {
    marginRight: "20px",
  },
}));

const QuestionArrayFields = ({
  fields,
  workItemDefinition,
  customisations,
  change,
  untouch,
  formName,
}) => {
  const classes = useStyles();

  const add = () => {
    fields.push({});
  };

  const remove = (index) => {
    fields.remove(index);
  };

  return (
    <>
      {fields.map((question, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <List key={index} className={classes.root}>
          <ListItem className={classes.question}>
            <ListItemText className={classes.dependantForm}>
              <QuestionFields
                fieldPrefix={question}
                workItemDefinition={workItemDefinition}
                customisations={customisations}
                change={change}
                untouch={untouch}
                formName={formName}
              />
            </ListItemText>
            <ListItemSecondaryAction>
              <IconButton
                aria-label="Remove"
                onClick={() => remove(index)}
                title="Remove"
                data-cy="removeQuestion"
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      ))}
      <Button
        size="small"
        color="primary"
        aria-label="Add"
        onClick={add}
        data-cy="addQuestion"
      >
        Add question
      </Button>
    </>
  );
};

QuestionArrayFields.propTypes = {
  fields: PropTypes.object.isRequired,
  workItemDefinition: PropTypes.object.isRequired,
  customisations: PropTypes.array,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,

  // redux
  fieldLabels: PropTypes.object.isRequired,
};

QuestionArrayFields.defaultProps = {
  customisations: [],
};

export default QuestionArrayFields;
