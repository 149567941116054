import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Tooltip } from "@material-ui/core";
import PropTypes from "prop-types";
import ReactAvatar from "react-avatar";
import moment from "moment";
import OutcomeChip from "../OutcomeChip";

const styles = (theme) => ({
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: 10,
    marginRight: theme.spacing(1),
  },
  userLine: {
    marginLeft: theme.spacing(-4) - 6,
    display: "flex",
    alignItems: "center",
  },
  userMeta: {
    display: "flex",
    alignItems: "baseline",
    "& > * + *": {
      marginLeft: theme.spacing(1),
    },
  },
  content: {
    marginTop: theme.spacing(1),
  },
  chip: {
    marginLeft: "auto",
  },
});

const UserAction = ({ classes, email, name, time, outcome, children }) => (
  <>
    <div className={classes.userLine}>
      <ReactAvatar
        {...{ name, email }}
        className={classes.avatar}
        round
        size={30}
      />
      <div className={classes.userMeta}>
        <Typography variant="subtitle1" data-cy={name}>
          {name}
        </Typography>
        <Tooltip
          title={moment(time).format("dddd, MMMM Do YYYY, h:mm:ss a")}
          data-cy={moment(time).format("dddd, MMMM Do YYYY, h:mm:ss a")}
        >
          <Typography variant="body2" data-cy={moment(time).format("h:mma")}>
            {moment(time).format("h:mma")}
          </Typography>
        </Tooltip>
      </div>
      {outcome && (
        <div className={classes.chip}>
          <OutcomeChip outcome={outcome} />
        </div>
      )}
    </div>
    <div className={classes.content}>{children}</div>
  </>
);

UserAction.propTypes = {
  classes: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  outcome: PropTypes.string,
  children: PropTypes.node.isRequired,
};

UserAction.defaultProps = {
  outcome: null,
};

export default withStyles(styles)(UserAction);
