import { useAuth0 } from "@auth0/auth0-react";
import { authorizer, useTenant } from "@certane/arcadia-web-components";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import ActionButton from "../../components/common/ActionButton";
import ActionHeading from "../../components/common/ActionHeading";
import Container from "../../components/common/Container";
import FloatingActionButton from "../../components/common/FloatingActionButton";
import HeaderBar from "../../components/common/HeaderBar";
import PageHeading from "../../components/common/PageHeading";
import ContentFilters from "../../components/metropolis/ContentFilters";
import MediaListing from "../../components/metropolis/MediaListing";
import SnippetListing from "../../components/metropolis/SnippetListing";
import { getLoggedInUser } from "../../reducers";
import { performExport } from "../../util/asyncRequestHelper";
import { clearIcon, downloadIcon, getContentIcon } from "../../util/icons";
import usePersistedState from "../../util/persistedState";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  contents: {
    marginBottom: "75px",
  },
}));

const defaultFilter = {
  fundIds: [],
  productIds: [],
  organisationIds: [],
  categoryIds: [],
  type: "MEDIA",
};

const Index = ({ history }) => {
  const { getAccessTokenSilently, user } = useAuth0();
  const { tenant } = useTenant();
  const classes = useStyles();
  const loggedInUser = useSelector(getLoggedInUser);
  const [mediaSortBy, setMediaSortBy] = usePersistedState(
    `media-listing-sort-${loggedInUser.email}`,
    {
      field: "name",
      direction: "asc",
    }
  );
  const [snippetSortBy, setSnippetSortBy] = usePersistedState(
    `snippet-listing-sort-${loggedInUser.email}`,
    {
      field: "title",
      direction: "asc",
    }
  );
  const [filter, setFilter] = usePersistedState(
    `content-listing-filter-${loggedInUser.email}`,
    defaultFilter,
    ["textSearch"]
  );

  const clearFilter = () =>
    setFilter({
      ...defaultFilter,
      type: filter.type,
    });

  const updateFilter = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  const onDownload = async () => {
    const accessToken = await getAccessTokenSilently();
    const getOrderBy = (sortBy) => {
      const prefix = sortBy.direction === "desc" ? "-" : "";
      return `${prefix}${sortBy.field}`;
    };
    const searchParameters = {
      ...filter,
      orderByField: getOrderBy(
        filter.type === "MEDIA" ? mediaSortBy : snippetSortBy
      ),
      offset: 0,
    };
    const searchType = filter.type === "MEDIA" ? "Media" : "Snippet";
    performExport(
      searchType,
      searchParameters,
      "CSV",
      accessToken,
      null,
      `${searchType} download`,
      "Please wait while we export your metropolis content",
      "This window can be closed"
    );
  };

  return (
    <div className={classes.root}>
      <HeaderBar fluid>
        <ActionHeading
          heading={
            <PageHeading
              icon={getContentIcon()}
              heading="Content"
              data-cy="content"
            />
          }
          actions={[
            <ActionButton
              key="clearFilter"
              tooltip="Clear filters"
              icon={clearIcon()}
              onClick={clearFilter}
              data-cy="clear-filters-button"
            />,
            <ActionButton
              key="download"
              tooltip="Download"
              icon={downloadIcon()}
              onClick={onDownload}
              data-cy="download"
            />,
          ]}
        />
      </HeaderBar>
      {authorizer.check("metropolis", "editor", user, tenant?.id) && (
        <FloatingActionButton>
          <MenuItem
            onClick={() => history.push("/metropolis/media/new")}
            data-cy="newMedia"
          >
            New media
          </MenuItem>
          <MenuItem
            onClick={() => history.push("/metropolis/snippet/new")}
            data-cy="newSnippet"
          >
            New snippet
          </MenuItem>
        </FloatingActionButton>
      )}
      <ContentFilters
        filter={filter}
        updateFilter={updateFilter}
        displayEntityFilter={!loggedInUser.externalUser}
        data-cy={filter}
      />
      <Container fluid className={classes.contents}>
        {filter.type === "MEDIA" && (
          <MediaListing
            history={history}
            filter={filter}
            clearFilter={clearFilter}
            sortBy={mediaSortBy}
            updateSort={setMediaSortBy}
            displayEntities={!loggedInUser.externalUser}
            onClick={(m) => history.push(`/metropolis/media/${m.id}`)}
            data-cy={filter}
          />
        )}
        {filter.type === "SNIPPET" && (
          <SnippetListing
            history={history}
            filter={filter}
            clearFilter={clearFilter}
            sortBy={snippetSortBy}
            updateSort={setSnippetSortBy}
            displayEntities={!loggedInUser.externalUser}
            onClick={(m) => history.push(`/metropolis/snippet/${m.id}`)}
          />
        )}
      </Container>
    </div>
  );
};

Index.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Index;
