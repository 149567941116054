/**
 * Function to upload files to gcs directly. It allows multiple file uploads and callback for file statuses.
 *
 * If all callbacks are provided, it will work like this:
 *
 *  1 - callback.onFileUploadStarted(fileInfo). First call for every file. File info is an object as follows:
 *    {
 *      file,  //File who will start the upload.
 *      cancel, //function to cancel the upload.
 *      percentCompleted, // Will be 0 at this point
 *    }
 *
 *  2 - fetchUploadUrl(file). should return a promise with the upload url for the particular file.
 *
 *  3 - callback.onUploadProgress(fileInfo). Will be called when there is progress info. Parameter:
 *    {
 *      file,  //File that is being uploaded
 *      cancel, //function to cancel the upload.
 *      percentCompleted, // Will be 0 at this point
 *    }
 *
 *  3 - callback.onFileUploadCompleted(fileInfo). Called when the file has been uploaded to gcs. file info is an object as follows:
 *    {
 *      file, //The file who was uploaded.
 *      gcsName //The gcs object name that should be used to generate the download url.
 *    }
 *
 *  4 - callback.onAllFilesUploaded(fileInfo[]). Called when all files have been uploaded or cancelled. Contains an array with the fileInfo for all
 *      successful uploads.
 *
 * @param files List of files from the html file input.
 * @param fetchUploadUrl callback to obtain the uploadUrl given a file. Receives a file object and should return a promise resolving to the gcs upload url.
 * @param uploadProgressCallbacks object with callbacks for the different statuses of the file upload.
 */
import * as axios from "axios";

export const uploadToStorage = (url, file, progressCallback) => {
  const config = {
    headers: { "Content-Type": file.type },
    onUploadProgress(progressEvent) {
      const total5percent = (progressEvent.total * 5) / 100;
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / (progressEvent.total + total5percent)
      );
      progressCallback(percentCompleted);
    },
  };

  return axios.put(url, file, config).then((response) => {
    if (response.status >= 200 && response.status < 400) {
      return response.data;
    }

    const error = new Error(response.statusText);
    error.status = response.status;
    error.payload = response.data;
    throw error;
  });
};

export const uploadToGcs = (files, fetchUploadUrl, callbacks = {}) => {
  const uploadPromises = Array.from(files).map((file) => {
    let cancelFunction;

    const cancelUploadPromise = new Promise((resolve) => {
      cancelFunction = resolve;
    });

    const fileInfo = {
      cancel: () => {
        cancelFunction({
          cancelled: true,
        });
      },
      percentCompleted: 0,
      file,
    };

    if (callbacks.onFileUploadStarted) {
      callbacks.onFileUploadStarted(fileInfo);
    }

    const uploadPromise = fetchUploadUrl(file).then((url) =>
      uploadToStorage(url, file, (percentCompleted) => {
        fileInfo.percentCompleted = percentCompleted;

        if (callbacks.onUploadProgress) {
          callbacks.onUploadProgress(fileInfo);
        }
      }).then((response) => ({
        gcsName: response.name,
        file,
      }))
    );

    return Promise.race([uploadPromise, cancelUploadPromise]).then(
      (uploadFileInfo) => {
        if (!uploadFileInfo.cancelled && callbacks.onFileUploadCompleted) {
          callbacks.onFileUploadCompleted(uploadFileInfo);
        }
        return uploadFileInfo;
      }
    );
  });

  Promise.all(uploadPromises).then((allFileInfo) => {
    if (callbacks.onAllFilesUploaded) {
      callbacks.onAllFilesUploaded(
        allFileInfo.filter((info) => !info.cancelled)
      );
    }
  });
};
