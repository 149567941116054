import makeStyles from "@material-ui/core/styles/makeStyles";
import * as PropTypes from "prop-types";
import React from "react";
import { infoIcon } from "../util/icons";

const useStyles = makeStyles((theme) => ({
  panelBorder: {
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
    border: `1px solid ${theme.palette.info.main}`,
    borderRadius: "16px",
    color: theme.palette.info.main,
  },
  panelNoBorder: {
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
    color: theme.palette.info.main,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

const InfoText = ({ text, border }) => {
  const classes = useStyles();
  const InfoIcon = infoIcon();
  return (
    <div className={border ? classes.panelBorder : classes.panelNoBorder}>
      <InfoIcon className={classes.icon} />
      <div>{text}</div>
    </div>
  );
};

InfoText.propTypes = {
  text: PropTypes.any.isRequired,
  border: PropTypes.bool,
};

InfoText.defaultProps = {
  border: false,
};

export default InfoText;
