import { useAuth0 } from "@auth0/auth0-react";
import React, { useState } from "react";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import usersApi from "../../../../services/api/users";
import Avatar from "../../../common/Avatar.tsx";
import LabelStateButton from "../../../common/LabelStateButton";
import ListPicker from "../../../ListPicker";

const styles = (theme) => ({
  button: {
    fontSize: "inherit",
    fontWeight: 600,
    padding: 0,
    minWidth: 0,
  },
  buttonNoValue: {
    fontStyle: "italic",
    color: `${theme.palette.text.hint} !important`,
  },
  disabled: {
    color: "inherit",
  },
  noWrapText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
  },
});

const WorkItemOwner = ({
  classes,
  className,
  owner,
  disabledOptions,
  userRoles,
  readonly,
  onOwnerChange,
  title,
  noValueText,
  includeAvatar,
  loggedInUser,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [dialogOpen, setDialogOpen] = useState(false);

  const asyncFetch = async (pickerFilter, pagination, abortController) => {
    const searchParameters = {
      ...pickerFilter,
      limit: pagination.pageSize,
      offset: pagination.offset,
      roles: userRoles,
      orderByField: "name",
    };
    const accessToken = await getAccessTokenSilently();
    return usersApi.search(searchParameters, abortController, accessToken);
  };

  const onAssignTo = (newOwner) => {
    setDialogOpen(false);

    if (!newOwner) {
      onOwnerChange(null);
    } else if (owner && newOwner.id === owner.id) {
      // ignore
    } else {
      onOwnerChange(newOwner);
    }
  };

  const unassigned = !owner;
  const buttonText = unassigned ? noValueText : owner.name;

  return (
    <div>
      {!onOwnerChange && (
        <Tooltip title={buttonText} placement="bottom-start" enterDelay={300}>
          <div
            className={classNames({
              [classes.noWrapText]: true,
              [classes.button]: true,
              [classes.buttonNoValue]: unassigned,
              [className]: !!className,
            })}
            data-cy={buttonText}
          >
            {buttonText}
          </div>
        </Tooltip>
      )}
      {onOwnerChange && (
        <Tooltip title={buttonText} placement="bottom" enterDelay={300}>
          <span>
            <LabelStateButton
              className={className}
              label={
                <div className={classes.noWrapText} data-cy={buttonText}>
                  {owner && includeAvatar && (
                    <Avatar
                      email={owner.email}
                      name={owner.name}
                      size={24}
                      round
                    />
                  )}
                  {buttonText}
                </div>
              }
              onClick={() => setDialogOpen(true)}
              aria-haspopup="true"
              readonly={readonly}
            />
          </span>
        </Tooltip>
      )}

      {dialogOpen && (
        <ListPicker
          title={title}
          actionText="Select"
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          onSubmit={(user) => onAssignTo(user)}
          datasource={asyncFetch}
          selected={owner}
          disabledOptions={disabledOptions}
          submitOnChange
          toOption={(user) => ({
            label: user.name || "Unknown",
            id: user.id,
            email: user.email,
          })}
          fromOption={(option) => ({
            name: option.label,
            id: option.id,
            email: option.email,
          })}
          renderIcon={(user, size) => (
            <Avatar email={user.email} name={user.name} size={size} round />
          )}
          data-cy={title}
          additionalActions={[
            <Button
              key="assignToMe"
              color="primary"
              onClick={() => onAssignTo(loggedInUser)}
              data-cy="assignToMe"
            >
              Assign to me
            </Button>,
          ]}
        />
      )}
    </div>
  );
};

WorkItemOwner.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  owner: PropTypes.object,
  disabledOptions: PropTypes.array,
  userRoles: PropTypes.array,
  onOwnerChange: PropTypes.func,
  readonly: PropTypes.bool.isRequired,
  loggedInUser: PropTypes.object.isRequired,
  title: PropTypes.string,
  noValueText: PropTypes.string,
  includeAvatar: PropTypes.bool,
};

WorkItemOwner.defaultProps = {
  className: undefined,
  userRoles: [],
  owner: null,
  onOwnerChange: null,
  title: "Select owner",
  noValueText: "No owner",
  includeAvatar: false,
  disabledOptions: [],
};

export default withStyles(styles)(WorkItemOwner);
