import React, { Component } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { Base64 } from "js-base64";
import BlockingLoadingIndicator from "../../components/common/BlockingLoadingIndicator";
import Container from "../../components/common/Container";
import HeaderBar from "../../components/common/HeaderBar";
import PageHeading from "../../components/common/PageHeading";
import StandaloneLayout from "../../components/layout/StandaloneLayout";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginBottom: "4rem",
  },
  container: {
    marginTop: theme.spacing(2),
  },
});

class FileDownloadMeta extends Component {
  render() {
    const {
      classes,
      match: { params },
    } = this.props;

    const { title, text, loading } = JSON.parse(
      Base64.decode(params.fileDownloadMeta)
    );

    return (
      <StandaloneLayout>
        <div className={classes.root}>
          <BlockingLoadingIndicator loading={loading} />
          <HeaderBar>
            <PageHeading heading={title} />
          </HeaderBar>
          <Container className={classes.container}>
            <Typography variant="subtitle1">{text}</Typography>
          </Container>
        </div>
      </StandaloneLayout>
    );
  }
}

FileDownloadMeta.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withStyles(styles)(FileDownloadMeta);
