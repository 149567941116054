import { requestJSON } from "./http";

const search = (type, offset, limit, accessToken) =>
  requestJSON({
    path: `/saved-searches?type=${type}&offset=${offset}&limit=${limit}`,
    accessToken,
  });

const create = (savedSearch, accessToken) =>
  requestJSON({
    path: "/saved-searches/",
    method: "POST",
    data: savedSearch,
    accessToken,
  });

const get = (id, accessToken) =>
  requestJSON({ path: `/saved-searches/${id}`, accessToken });

const patch = (id, changes, description, accessToken) =>
  requestJSON({
    path: `/saved-searches/${id}`,
    method: "PATCH",
    data: {
      fields: Object.keys(changes),
      changes,
      description,
    },
    accessToken,
  });

const remove = (id, accessToken) =>
  requestJSON({ path: `/saved-searches/${id}`, method: "DELETE", accessToken });

const togglePin = (id, accessToken) =>
  requestJSON({
    path: `/saved-searches/${id}/pin`,
    method: "POST",
    accessToken,
  });

const exists = (type, searchParameters, accessToken) =>
  requestJSON({
    path: "/saved-searches/exists",
    method: "POST",
    data: {
      __type: type,
      ...searchParameters,
    },
    accessToken,
  });

export default {
  search,
  create,
  get,
  patch,
  remove,
  togglePin,
  exists,
};
