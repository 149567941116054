import _ from "lodash";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector } from "redux-form";
import { required } from "redux-form-validators";
import { getReferenceDataType } from "../../../reducers";
import snippets from "../../../services/api/snippets";
import { getRefDataMenuItems } from "../menuItems";
import WrappedAceEditor from "../wrapper/WrappedAceEditor";
import WrappedSelect from "../wrapper/WrappedSelect";
import WrappedTextField from "../wrapper/WrappedTextField";

const validateReference = (reference) => {
  if (reference && !/^[A-Z0-9]+(-[A-Z0-9]+)*$/.test(reference)) {
    return "Must only include upper case alphanumeric characters separated by hyphens";
  }
  return null;
};

const validateData = (origData, label, fieldPrefix) => (data, values) => {
  if (!data) {
    return `${label} is required`;
  }

  if (data === origData) {
    return `${label} hasn't changed`;
  }

  const contentType = _.get(values, `${fieldPrefix}contentType`);

  if (contentType === "JSON") {
    try {
      JSON.parse(data);
    } catch (e) {
      return e.message;
    }
  }

  return null;
};

export const referenceAsyncValidator = {
  initiate: (values, field) => {
    const reference = _.get(values, field);

    return reference
      ? snippets.checkReferenceAvailability(reference)
      : Promise.resolve({ available: true });
  },
  validate: (values, response) =>
    response.available ? undefined : "No item found for this friendly id",
};

const SnippetContentFields = ({
  fieldLabels,
  snippetTypes,
  contentType,
  namePrefix,
  originalData,
}) => {
  const fieldPrefix = namePrefix ? `${namePrefix}.` : "";
  const dataRequiredAndChanged = useRef(
    validateData(
      originalData,
      fieldLabels.nestedTypes.contentVersions.labels.data,
      fieldPrefix
    )
  );

  const getEditorMode = () => {
    const editorModes = {
      JSON: "json",
      TEXT_HTML: "html",
    };
    return _.get(editorModes, contentType, "text");
  };

  return (
    <>
      <Field
        name={`${fieldPrefix}reference`}
        component={WrappedTextField}
        placeholder="Please enter the friendly id here"
        label={fieldLabels.nestedTypes.contentVersions.labels.reference}
        validate={validateReference}
        fullWidth
      />
      <Field
        component={WrappedSelect}
        name={`${fieldPrefix}contentType`}
        label={fieldLabels.nestedTypes.contentVersions.labels.contentType}
        validate={required({
          msg: `${fieldLabels.nestedTypes.contentVersions.labels.contentType} is required`,
        })}
        required
        fullWidth
      >
        {getRefDataMenuItems(snippetTypes)}
      </Field>
      <Field
        component={WrappedAceEditor}
        name={`${fieldPrefix}data`}
        label={fieldLabels.nestedTypes.contentVersions.labels.data}
        validate={dataRequiredAndChanged.current}
        mode={getEditorMode()}
      />
    </>
  );
};

SnippetContentFields.propTypes = {
  fieldLabels: PropTypes.object.isRequired,
  namePrefix: PropTypes.string.isRequired,
  originalData: PropTypes.string.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  formName: PropTypes.string.isRequired,

  // redux
  snippetTypes: PropTypes.object.isRequired,
  contentType: PropTypes.string,
};

SnippetContentFields.defaultProps = {
  contentType: null,
};

const mapStateToProps = (state, ownProps) => {
  const reduxFormSelector = formValueSelector(ownProps.formName);
  return {
    contentType: reduxFormSelector(state, "contentType"),
    snippetTypes: getReferenceDataType(state, "SnippetType"),
  };
};

export default connect(mapStateToProps)(SnippetContentFields);
