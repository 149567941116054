import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Fade from "@material-ui/core/Fade";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { SubmissionError } from "redux-form";
import FormWrapper from "../forms/FormWrapper";
import AlertDialog from "./AlertDialog";

const styles = () => ({
  root: {
    overflow: "visible",
  },
});

class FormCard extends Component {
  constructor(props) {
    super(props);
    this.state = { formOpen: false, removeDialogOpen: false };
  }

  componentDidMount() {
    this.unmounted = false;
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  handleSubmitDialog = (changes) => {
    return this.props
      .onSave(changes)
      .then(() => {
        if (!this.unmounted) {
          this.setState({ formOpen: false });
        }
      })
      .catch((error) => {
        if (!this.unmounted) {
          throw new SubmissionError({ _error: error.message });
        }
      });
  };

  handleSubmitDeleteDialog() {
    this.props.onDelete().then(() => {
      if (!this.unmounted) {
        this.setState({ removeDialogOpen: false });
      }
    });
  }

  getAction = () => {
    const { onSave, onDelete, icon, additionalActions } = this.props;
    const { formOpen } = this.state;
    const readOnly = !onSave;
    const deletable = !!onDelete;

    if (!formOpen && !readOnly) {
      return (
        <>
          {additionalActions}
          <IconButton
            onClick={() => this.setState({ formOpen: true })}
            title="Edit"
            data-cy="editIcon"
          >
            {icon}
          </IconButton>
          {deletable && (
            <IconButton
              onClick={() => this.setState({ removeDialogOpen: true })}
              title="Delete"
              data-cy="deleteIcon"
            >
              <Icon>delete</Icon>
            </IconButton>
          )}
        </>
      );
    }
    if (!readOnly) {
      return (
        <>
          {additionalActions}
          <IconButton
            onClick={() => this.setState({ formOpen: false })}
            title="Cancel"
            data-cy="cancelIcon"
          >
            <CloseIcon />
          </IconButton>
        </>
      );
    }
    return null;
  };

  render() {
    const {
      classes,
      className,
      formComponent: FormComponent,
      readOnlyView: ReadOnlyComponent,
      title,
      formTitle,
      deleteTitle,
      deleteBody,
      formName,
      initialValues,
      formProps,
    } = this.props;

    const { formOpen, removeDialogOpen } = this.state;

    const mergedClassName = classNames(classes.root, className);

    return (
      <>
        <Card className={mergedClassName} elevation={0}>
          <CardHeader
            title={formOpen ? formTitle : title}
            action={this.getAction()}
          />
          <AlertDialog
            title={deleteTitle}
            body={deleteBody}
            submitButtonText="Delete"
            open={removeDialogOpen}
            onCancel={() => this.setState({ removeDialogOpen: false })}
            onSubmit={() => this.handleSubmitDeleteDialog()}
          />
          {formOpen && (
            <Fade in timeout={1000}>
              <CardContent>
                <FormWrapper
                  submitButtonText="Save"
                  formComponent={FormComponent}
                  formName={formName}
                  onSubmit={this.handleSubmitDialog}
                  onCancel={() => this.setState({ formOpen: false })}
                  initialValues={initialValues}
                  {...formProps}
                />
              </CardContent>
            </Fade>
          )}
          {!formOpen && (
            <CardContent>
              <ReadOnlyComponent />
            </CardContent>
          )}
        </Card>
      </>
    );
  }
}

FormCard.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  icon: PropTypes.node,
  title: PropTypes.string.isRequired,
  formTitle: PropTypes.string.isRequired,
  deleteTitle: PropTypes.string,
  deleteBody: PropTypes.string,
  readOnlyView: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
    .isRequired,
  formComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
    .isRequired,
  formName: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
  formProps: PropTypes.object,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  additionalActions: PropTypes.object,
};

FormCard.defaultProps = {
  className: undefined,
  icon: <EditIcon />,
  deleteTitle: "",
  deleteBody: "",
  onSave: null,
  onDelete: null,
  initialValues: {},
  formProps: {},
  additionalActions: null,
};

export default withStyles(styles)(FormCard);
