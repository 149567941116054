import analytics from "./analytics";
import auditReports from "./auditReports";
import mentions from "./mentions";
import products from "./products";
import slas from "./slas";
import snippets from "./snippets";
import media from "./media";
import riskControls from "./riskControls";
import risks from "./risks";
import subscriptions from "./subscriptions";
import userGroups from "./userGroups";
import users from "./users";
import websiteDifferences from "./websiteDifferences";
import websiteSnapshots from "./websiteSnapshots";
import workItems from "./workItems";
import workItemAssociations from "./workItemAssociations";
import errors from "./errors";
import file from "./file";
import notifications from "./notifications";
import referenceData from "./referenceData";
import tags from "./tags";
import digitalAssets from "./digitalAssets";
import organisations from "./organisations";
import businessServiceAreas from "./businessServiceAreas";
import searchExports from "./searchExports";
import drafts from "./drafts";
import labels from "./labels";

export default {
  // common
  referenceData,
  labels,
  businessServiceAreas,
  organisations,
  products,
  users,
  userGroups,
  errors,
  file,

  // arcadia
  analytics,
  auditReports,
  workItems,
  workItemAssociations,
  notifications,
  tags,
  risks,
  riskControls,
  slas,
  subscriptions,
  searchExports,
  drafts,

  // metropolis
  media,
  snippets,

  // sentinel,
  digitalAssets,
  websiteSnapshots,
  websiteDifferences,
  mentions,
};
