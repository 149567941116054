import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { SubmissionError } from "redux-form";
import { createSla } from "../../../actions/slas";
import Container from "../../../components/common/Container";
import FloatingActionButton from "../../../components/common/FloatingActionButton";
import HeaderBar from "../../../components/common/HeaderBar";
import PageHeading from "../../../components/common/PageHeading";
import ExpandableForm from "../../../components/forms/ExpandableForm";
import CreateSlaForm, {
  CREATE_SLA_FORM,
} from "../../../components/forms/sla/CreateSlaForm";
import SlaListing from "../../../components/sla/SlaListing";
import { slaIcon } from "../../../util/icons";
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  contents: {
    marginBottom: "75px",
  },
}));

const Index = ({ localCreateSla }) => {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const [dialogOpen, setDialogOpen] = useState(false);
  const formRef = useRef();

  const onCreate = async (sla) => {
    const accessToken = await getAccessTokenSilently();
    localCreateSla(sla, accessToken)
      .then(() => setDialogOpen(false))
      .catch((error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  const openForm = () => {
    setDialogOpen(true);
    window.scrollTo(0, formRef.current.offsetTop);
  };

  return (
    <div className={classes.root}>
      <HeaderBar>
        <PageHeading icon={slaIcon()} heading="Service level agreements" />
      </HeaderBar>
      {!dialogOpen && <FloatingActionButton onClick={openForm} />}
      <Container className={classes.contents}>
        <ExpandableForm
          title="Create SLA"
          submitButtonText="Create"
          formComponent={CreateSlaForm}
          formName={CREATE_SLA_FORM}
          open={dialogOpen}
          formRef={formRef}
          onCancel={() => setDialogOpen(false)}
          onSubmit={onCreate}
          isAltPaper
        />
        <SlaListing />
      </Container>
    </div>
  );
};

Index.propTypes = {
  localCreateSla: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
  localCreateSla: createSla,
})(Index);
