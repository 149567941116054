import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Container from "../components/common/Container";
import HeaderBar from "../components/common/HeaderBar";
import PageHeading from "../components/common/PageHeading";
import StandaloneLayout from "../components/layout/StandaloneLayout";
import Button from "@material-ui/core/Button";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginBottom: "4rem",
  },
  container: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  details: {
    marginTop: theme.spacing(6),
  },
});

const Unavailable = ({ classes }) => (
  <StandaloneLayout>
    <div className={classes.root}>
      <HeaderBar>
        <PageHeading heading="Unavailable" />
      </HeaderBar>
      <Container className={classes.container}>
        <Typography variant="subtitle1" gutterBottom>
          Arcadia is unavailable for you.
        </Typography>
        <div className={classes.button}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              window.location.href = "/logout";
            }}
          >
            Logout
          </Button>
        </div>
      </Container>
    </div>
  </StandaloneLayout>
);

Unavailable.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withStyles(styles)(Unavailable);
