import { Typography, withStyles } from "@material-ui/core";
import React from "react";
import tinycolor from "tinycolor2";
import PropTypes from "prop-types";
import MultilineText from "../common/MultilineText";

const styles = (theme) => ({
  wrapper: {
    backgroundColor: "white",
  },
  general: {
    backgroundColor: tinycolor(theme.palette.swatch.grey1).setAlpha(0.1),
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    color: theme.palette.swatch.secondary,
    display: "flex",
    alignItems: "center",
  },
  warning: {
    backgroundColor: tinycolor(theme.palette.warning.main).setAlpha(0.2),
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    color: theme.palette.swatch.secondary,
    display: "flex",
    alignItems: "center",
  },
  internal: {
    backgroundColor: tinycolor(theme.palette.swatch.accent1).setAlpha(0.1),
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    color: theme.palette.swatch.secondary,
    display: "flex",
    alignItems: "center",
  },
  warningIcon: {
    width: theme.spacing(2),
    marginRight: theme.spacing(1),
    color: theme.palette.warning.main,
  },
  internalIcon: {
    width: theme.spacing(2),
    marginRight: theme.spacing(1),
    color: theme.palette.swatch.accent1,
  },
  generalIcon: {
    width: theme.spacing(2),
    marginRight: theme.spacing(1),
    color: theme.palette.swatch.accent1,
  },
});

const Notice = ({ classes, text, icon: Icon, type }) => (
  <div className={classes.wrapper}>
    <div className={classes[type]}>
      {Icon && <Icon className={classes[`${type}Icon`]} />}
      <Typography variant="body2" color="inherit">
        <MultilineText text={text} />
      </Typography>
    </div>
  </div>
);

Notice.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.object,
  type: PropTypes.oneOf(["general", "internal", "warning"]),
};

Notice.defaultProps = {
  icon: null,
};

export default withStyles(styles)(Notice);
