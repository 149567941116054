import { useAuth0 } from "@auth0/auth0-react";
import { authorizer, useTenant } from "@certane/arcadia-web-components";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { WORK_ITEM_SCHEDULES_FETCH_OCCURRENCES } from "../../actions/workItems";
import {
  getLabels,
  getLoggedInUser,
  getReferenceDataType,
  isLoadingAction,
} from "../../reducers";
import workItemActivitiesApi from "../../services/api/workItemActivities";
import workItemApi from "../../services/api/workItems";
import {
  appliesByParentType,
  appliesByRoles,
  appliesByTemplate,
  getResolvedDefinition,
} from "../../util/workItemTypeUtils";
import Container from "../common/Container";
import ActivityStream from "./activityStream/ActivityStream";
import WorkItemAssociations from "./association/WorkItemAssociations";
import AuditHistory from "./AuditHistory";
import ChildListCard from "./children/ChildListCard";
import WorkItemChargeability from "./components/chargeability/WorkItemChargeability";
import WorkItemFileUpload from "./components/files/WorkItemFileUpload";
import IncidentSummaryFieldsReadOnly from "./components/incident/IncidentSummaryFieldsReadOnly";
import WorkItemDetailCard from "./components/overview/WorkItemDetailCard";
import WorkItemComponentCards from "./components/WorkItemComponentCards";
import ParentCard from "./ParentCard";
import ScheduleCard from "./schedule/ScheduleCard";
import ScheduleOccurrencesCard from "./schedule/ScheduleOccurrencesCard";
import SchedulePreviewCard from "./schedule/SchedulePreviewCard";
import TimesheetCard from "./timesheet/TimesheetCard";
import AssigneeCard from "./userlist/AssigneeCard";
import CollaboratorCard from "./userlist/CollaboratorCard";
import WorkItemNotifications from "./WorkItemNotifications";

const styles = (theme) => ({
  tabContentsContainer: {
    marginTop: theme.spacing(2),
  },
  card: {
    marginBottom: theme.spacing(2),
  },
  tabs: {
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
  },
  tabRoot: {
    minWidth: "100px",
    padding: theme.spacing(2),
  },
  tabCount: {
    paddingLeft: theme.spacing(1) / 2,
    color: theme.palette.grey[400],
  },
});

const TabContent = ({ visible, children }) => (visible ? children : null);

TabContent.propTypes = {
  visible: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
};

// type specific logic to render extra info on the parent card
const parentCardConfig = {
  INCIDENT: (parent, fieldLabels) => (
    <IncidentSummaryFieldsReadOnly
      workItem={parent}
      customisations={[]}
      fieldLabels={fieldLabels}
    />
  ),
};

const WorkItemTabs = ({
  classes,
  loggedInUser,
  workItem,
  workItemTypes,
  labels,
  loadingWorkItemScheduleOccurrences,
}) => {
  const { getAccessTokenSilently, user } = useAuth0();
  const { tenant } = useTenant();
  const [activeTab, setActiveTab] = useState(0);
  const [activities, setActivities] = useState([]);
  const [auditLogs, setAuditLogs] = useState([]);
  const [workItemTabs, setWorkItemTabs] = useState([]);
  const [combinedDocuments, setCombinedDocuments] = useState({});
  const [scrollToActivity, setScrollToActivity] = useState(null);
  const [scrollToQuestion, setScrollToQuestion] = useState(null);
  const [scrollToComponent, setScrollToComponent] = useState(null);
  const definition = getResolvedDefinition(
    workItemTypes,
    workItem.type,
    workItem.parent?.type
  );

  useEffect(() => {
    const { tabs } = definition;
    const tabDefs = tabs
      .filter((tabAssignment) => appliesByTemplate(workItem, tabAssignment))
      .filter((tabAssignment) => appliesByRoles(loggedInUser, tabAssignment))
      .filter((tabAssignment) => appliesByParentType(workItem, tabAssignment))
      .map((tabAssignment) => {
        let label = tabAssignment.name;
        if (tabAssignment.tab === "PARENT") {
          const {
            definition: { name },
          } = workItemTypes.values[workItem.parent.type].props;
          label = name;
        }
        return {
          value: tabAssignment.tab,
          label,
          count: getTabCount(tabAssignment.tab),
        };
      });
    setWorkItemTabs(tabDefs);
  }, [workItem, combinedDocuments, loggedInUser]);

  const openActivity = (activityId) => {
    setActiveTab(_.findIndex(workItemTabs, { value: "ACTIVITY_STREAM" }));
    setScrollToActivity(activityId);
  };

  const openQuestion = (questionId) => {
    setActiveTab(_.findIndex(workItemTabs, { value: "COMPONENTS" }));
    setScrollToQuestion(questionId);
  };

  const openComponent = (componentId) => {
    const componentAssignment = definition.components.find(
      (c) => c.component === componentId
    );

    if (componentAssignment) {
      setActiveTab(
        _.findIndex(workItemTabs, { value: componentAssignment.tab })
      );
      setScrollToComponent(componentId);
    }
  };

  const refreshActivities = async () => {
    const accessToken = await getAccessTokenSilently();
    workItemActivitiesApi
      .listActivities(workItem.id, accessToken)
      .then((response) => setActivities(response));
    workItemActivitiesApi
      .listAuditLogs(workItem.id, accessToken)
      .then((response) => setAuditLogs(response));
  };

  const refreshDocuments = async () => {
    const accessToken = await getAccessTokenSilently();
    workItemApi
      .fetchCombinedDocuments(workItem.id, accessToken)
      .then((response) => setCombinedDocuments(response));
  };

  const getTabCount = (tabId) => {
    switch (tabId) {
      case "CHILDREN":
        return workItem.numChildren;
      case "FILES":
        // 'DEFAULT' documents are grouped by fileName
        const viewableDocs = combinedDocuments[workItem.id];
        return _.size(
          _.groupBy(viewableDocs, (doc) =>
            doc.type === "DEFAULT"
              ? doc.document.fileName
              : doc.document.gcsObjectName
          )
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (window.location.hash.includes("selected-question")) {
      const questionId = window.location.hash.split("=")[1];
      openQuestion(questionId);
    }
  }, []);

  useEffect(() => {
    setActiveTab(0);
  }, [workItem.id]);

  useEffect(() => {
    if (workItem) {
      refreshActivities();
      refreshDocuments();
    }
  }, [workItem]);

  const isActive = (value) =>
    activeTab === _.findIndex(workItemTabs, { value });

  const handleTabChange = (event, value) => {
    setActiveTab(value);
  };

  const fieldLabels = labels[definition.id];

  const readonly =
    workItem.status === "CLOSED" ||
    !authorizer.check("work-item", "editor", user, tenant?.id);
  const isTemplate = workItem.template;
  const supportInternalNotes = !!workItemTabs.find(
    (tab) => tab.value === "ACTIVITY_STREAM"
  );

  return (
    <>
      <Paper elevation={0} square>
        <Container>
          <Tabs
            value={activeTab < workItemTabs.length ? activeTab : 0}
            onChange={handleTabChange}
            textColor="primary"
            variant="scrollable"
            scrollButtons="off"
            className={classes.tabs}
          >
            {workItemTabs.map((tab) => (
              <Tab
                key={tab.value}
                classes={{
                  root: classes.tabRoot,
                }}
                label={
                  <div data-cy={tab.label}>
                    {tab.label}
                    {tab.count > 0 && (
                      <span className={classes.tabCount}>({tab.count})</span>
                    )}
                  </div>
                }
              />
            ))}
          </Tabs>
        </Container>
      </Paper>
      <Container className={classes.tabContentsContainer}>
        <Grid container spacing={2}>
          {/* TAB CONTENT */}
          <Grid item sm={12} md={8}>
            {/* COMPONENTS TAB */}
            <TabContent visible={isActive("COMPONENTS")} data-cy="componentTab">
              <WorkItemComponentCards
                cardClass={classes.card}
                workItem={workItem}
                readonly={readonly}
                tab="COMPONENTS"
                postUpdate={refreshActivities}
                scrollToQuestionId={scrollToQuestion}
                onScrolledToQuestion={() => setScrollToQuestion(null)}
                onScrollToComponent={(comp) => openComponent(comp)}
                onScrolledToComponent={() => setScrollToComponent(null)}
                scrollToComponent={scrollToComponent}
              />
            </TabContent>

            {/* REPORTS TAB */}
            <TabContent visible={isActive("REPORTS")} data-cy="reportsTab">
              <WorkItemComponentCards
                cardClass={classes.card}
                workItem={workItem}
                readonly={readonly}
                tab="REPORTS"
                postUpdate={refreshActivities}
                scrollToQuestionId={scrollToQuestion}
                onScrolledToQuestion={() => setScrollToQuestion(null)}
                onScrollToComponent={(comp) => openComponent(comp)}
                onScrolledToComponent={() => setScrollToComponent(null)}
                scrollToComponent={scrollToComponent}
              />
            </TabContent>

            {/* PARENT TAB */}
            <TabContent visible={isActive("PARENT")}>
              <ParentCard
                className={classes.card}
                workItem={workItem}
                fieldLabels={labels[workItem.parent?.type]}
                getFurtherDetails={parentCardConfig[workItem.parent?.type]}
              />
            </TabContent>

            {/* CHILDREN TAB */}
            <TabContent visible={isActive("CHILDREN")}>
              {definition.childTypes.map((childTypeAssignment) => (
                <ChildListCard
                  key={childTypeAssignment.type}
                  className={classes.card}
                  childType={childTypeAssignment}
                  parent={workItem}
                  readonly={readonly}
                  fieldLabels={fieldLabels}
                  data-cy="childListCard"
                />
              ))}
            </TabContent>

            {/* SCHEDULE TAB */}
            <TabContent visible={isActive("SCHEDULE")}>
              <ScheduleCard
                workItem={workItem}
                fieldLabels={fieldLabels}
                data-cy={fieldLabels}
              />
            </TabContent>

            {/* SCHEDULE_OCCURRENCES TAB */}
            <TabContent visible={isActive("SCHEDULE_OCCURRENCES")}>
              <ScheduleOccurrencesCard
                workItem={workItem}
                fieldLabels={fieldLabels}
                data-cy={fieldLabels}
              />
              {!loadingWorkItemScheduleOccurrences && <SchedulePreviewCard />}
            </TabContent>

            <TabContent visible={isActive("ASSOCIATIONS")}>
              <WorkItemAssociations
                workItem={workItem}
                readonly={readonly || loggedInUser.externalUser}
                postUpdate={refreshActivities}
              />
            </TabContent>

            {/* FILES TAB */}
            <TabContent visible={isActive("FILES")} data-cy="fileTab">
              <WorkItemFileUpload
                workItem={workItem}
                readonly={readonly}
                combinedDocuments={combinedDocuments}
                openActivity={
                  supportInternalNotes
                    ? (activityId) => openActivity(activityId)
                    : null
                }
                openQuestion={(questionId) => openQuestion(questionId)}
                data-cy="fileTab"
              />
            </TabContent>

            {/* AUDIT HISTORY TAB */}
            <TabContent
              visible={isActive("AUDIT_HISTORY")}
              data-cy="auditHistory"
            >
              <AuditHistory auditHistory={auditLogs} data-cy="audit" />
            </TabContent>

            {/* ACTIVITY STREAM TAB */}
            <TabContent
              visible={isActive("ACTIVITY_STREAM")}
              data-cy="activityStreamTab"
            >
              <ActivityStream
                workItem={workItem}
                definition={definition}
                activities={activities}
                loggedInUser={loggedInUser}
                scrollToActivityId={scrollToActivity}
                onScrolledToActivity={() => setScrollToActivity(null)}
                data-cy="activityStream"
              />
            </TabContent>
          </Grid>

          {/* RIGHT HAND PANEL */}
          <Grid item sm={12} md={4} data-cy="rightHandPanel">
            <WorkItemDetailCard
              className={classes.card}
              workItem={workItem}
              readonly={readonly}
              template={isTemplate}
              fieldLabels={fieldLabels}
            />
            <AssigneeCard workItem={workItem} readonly={readonly} />
            <CollaboratorCard workItem={workItem} readonly={readonly} />

            {definition.notificationsPanel &&
              appliesByRoles(loggedInUser, definition.notificationsPanel) && (
                <WorkItemNotifications
                  workItem={workItem}
                  readonly={readonly}
                />
              )}

            {!isTemplate && (
              <>
                {definition.timesheet &&
                  appliesByRoles(loggedInUser, definition.timesheet) && (
                    <TimesheetCard workItem={workItem} readonly={false} />
                  )}
                {definition.chargeability &&
                  appliesByRoles(loggedInUser, definition.chargeability) && (
                    <WorkItemChargeability
                      workItem={workItem}
                      readonly={readonly}
                      fieldLabels={fieldLabels}
                    />
                  )}
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

WorkItemTabs.propTypes = {
  workItem: PropTypes.object.isRequired,

  // derived properties (ie: not passed from parent)
  loggedInUser: PropTypes.object,
  classes: PropTypes.object.isRequired,
  workItemTypes: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  loadingWorkItemScheduleOccurrences: PropTypes.bool.isRequired,
};

WorkItemTabs.defaultProps = {
  loggedInUser: null,
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
  workItemTypes: getReferenceDataType(state, "WorkItemType"),
  labels: getLabels(state),
  loadingWorkItemScheduleOccurrences: isLoadingAction(
    state,
    WORK_ITEM_SCHEDULES_FETCH_OCCURRENCES
  ),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(WorkItemTabs);
