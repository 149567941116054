import { useAuth0 } from "@auth0/auth0-react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { clearActiveWorkItem, getWorkItemById } from "../../actions/workItems";
import FullWidthNotification from "../../components/common/FullWidthNotification";
import Composer from "../../components/composer/Composer";
import Error404 from "../../components/error/Error404";
import Error410 from "../../components/error/Error410";
import LoadingIndicator from "../../components/LoadingIndicator";
import WorkItemHeaderBar from "../../components/workitem/header/WorkItemHeaderBar";
import WorkItemTabs from "../../components/workitem/WorkItemTabs";
import WorkItemUpdatedCheck from "../../components/workitem/WorkItemUpdatedCheck";
import { getWorkItem, getWorkItemLoadError, isLoading } from "../../reducers";

const styles = () => ({
  root: {
    width: "100%",
    paddingBottom: 100,
  },
});

const WorkItemId = ({
  classes,
  match: {
    params: { workItemId },
  },
  loading,
  workItem,
  error,
  localClearActiveWorkItem,
  localGetWorkItemById,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    getAccessTokenSilently().then((accessToken) =>
      localGetWorkItemById(workItemId, accessToken)
    );

    return () => {
      localClearActiveWorkItem();
    };
  }, [workItemId]);

  if (!workItem && loading) {
    return <LoadingIndicator size={60} />;
  }

  if (!workItem && !loading) {
    if (error && error.status === 410) {
      return <Error410 message={error.message} />;
    }
    return <Error404 />;
  }

  const bannerText =
    workItem.template &&
    "Please note: Any modifications made to this template will only take effect for newly created occurrences... existing work items will not be affected.";

  return (
    <div className={classes.root}>
      {bannerText && <FullWidthNotification text={bannerText} />}
      <WorkItemHeaderBar />
      <WorkItemUpdatedCheck workItem={workItem} />
      <WorkItemTabs workItem={workItem} />
      <Composer workItem={workItem} />
    </div>
  );
};

WorkItemId.propTypes = {
  match: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  workItem: PropTypes.object,
  error: PropTypes.object,
  localClearActiveWorkItem: PropTypes.func.isRequired,
  localGetWorkItemById: PropTypes.func.isRequired,
};

WorkItemId.defaultProps = {
  workItem: null,
  error: null,
};

const mapStateToProps = (state) => ({
  loading: isLoading(state),
  workItem: getWorkItem(state),
  error: getWorkItemLoadError(state),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    localClearActiveWorkItem: clearActiveWorkItem,
    localGetWorkItemById: getWorkItemById,
  })
)(WorkItemId);
