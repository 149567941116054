import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import PropTypes from "prop-types";

const styles = (theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  margin: {
    margin: `${theme.spacing(1)}px 0`,
  },
  checkIcon: {
    color: theme.palette.primary.main,
  },
  pendingIcon: {
    color: theme.palette.text.secondary,
  },
  title: {
    marginLeft: theme.spacing(1),
  },
  subtitle: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginLeft: "auto !important",
    },
  },
  // Progress bar override styles
  progressRoot: {
    height: 4,
    backgroundColor: "#ccc",
  },
  progressBar: {
    borderRadius: 20,
    backgroundColor: theme.palette.primary.main,
  },
});

const ProgressBar = ({
  classes,
  totalItems,
  totalSelected,
  title,
  subtitle,
  loading,
  withIcon,
}) => {
  if (loading) {
    return (
      <div className={classes.root}>
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Grid container alignItems="center" justifyContent="space-between">
        {withIcon && (
          <Grid
            item
            container
            alignItems="center"
            justifyContent="flex-start"
            xs={12}
          >
            {totalItems === totalSelected && totalItems ? (
              <CheckCircleIcon fontSize="small" className={classes.checkIcon} />
            ) : (
              <TimelapseIcon fontSize="small" className={classes.pendingIcon} />
            )}
            <Typography
              component="h6"
              variant="subtitle1"
              color="primary"
              className={classes.title}
              data-cy={title || `${totalSelected}/${totalItems}`}
            >
              {title || `${totalSelected}/${totalItems}`}
            </Typography>
            <Typography
              component="h6"
              variant="subtitle2"
              color="textSecondary"
              className={classes.subtitle}
              data-cy={subtitle}
            >
              {subtitle}
            </Typography>
          </Grid>
        )}
      </Grid>
      <LinearProgress
        variant="determinate"
        color="secondary"
        value={(totalSelected / totalItems) * 100}
        className={classes.margin}
        classes={{
          root: classes.progressRoot,
          bar: classes.progressBar,
        }}
        data-cy={(totalSelected / totalItems) * 100}
      />
    </div>
  );
};

ProgressBar.propTypes = {
  classes: PropTypes.object.isRequired,
  totalItems: PropTypes.number.isRequired,
  totalSelected: PropTypes.number.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.any,
  withIcon: PropTypes.bool,
  loading: PropTypes.bool,
};

ProgressBar.defaultProps = {
  withIcon: false,
  loading: false,
  title: null,
  subtitle: null,
};

export default withStyles(styles)(ProgressBar);
