import { normalize } from "normalizr";
import schemas from "../schemas";
import snippetsApi from "../services/api/snippets";
import { asyncAction } from "./actions";

export const SNIPPET_CLEAR_ACTIVE = "SNIPPET_CLEAR_ACTIVE";
export const SNIPPET_SET_ACTIVE_VERSION = "SNIPPET_SET_ACTIVE_VERSION";
export const SNIPPET_CREATE = "SNIPPET_CREATE";
export const SNIPPET_PATCH = "SNIPPET_PATCH";
export const SNIPPET_ADD_CONTENT = "SNIPPET_ADD_CONTENT";
export const SNIPPET_SEARCH = "SNIPPET_SEARCH";
export const SNIPPET_GET_BY_ID = "SNIPPET_GET_BY_ID";
export const SNIPPET_REMOVE = "SNIPPET_REMOVE";

export function clearActiveSnippet() {
  return {
    type: SNIPPET_CLEAR_ACTIVE,
  };
}

export function setActiveVersion(version) {
  return {
    type: SNIPPET_SET_ACTIVE_VERSION,
    payload: version,
  };
}

export const createSnippet = (snippet, accessToken) =>
  asyncAction(SNIPPET_CREATE, snippetsApi.create(snippet, accessToken), {
    responseTransformer: (createdSnippet) =>
      normalize(createdSnippet, schemas.snippet),
  });

export const addContent = (id, version, accessToken) =>
  asyncAction(
    SNIPPET_ADD_CONTENT,
    snippetsApi.addContent(id, version, accessToken),
    {
      responseTransformer: (snippet) => normalize(snippet, schemas.snippet),
    }
  );

export const searchSnippets = (searchParameters, accessToken) => {
  const abortController = window.AbortController ? new AbortController() : null;
  return asyncAction(
    SNIPPET_SEARCH,
    snippetsApi.search(searchParameters, abortController, accessToken),
    {
      responseTransformer: (response) => ({
        pagination: {
          offset: response.offset,
          previousOffset: response.previousOffset,
          nextOffset: response.nextOffset,
          pageSize: response.pageSize,
          resultCount: response.resultCount,
        },
        ...normalize(response.results, schemas.arrayOfSnippets),
      }),
      actionParams: { abortController, nonBlocking: true },
    }
  );
};

export const getSnippetById = (snippetId, accessToken) =>
  asyncAction(SNIPPET_GET_BY_ID, snippetsApi.load(snippetId, accessToken), {
    responseTransformer: (snippet) => normalize(snippet, schemas.snippet),
  });

export const patchSnippet = (snippetId, changes, description, accessToken) =>
  asyncAction(
    SNIPPET_PATCH,
    snippetsApi.patch(snippetId, changes, description, accessToken),
    {
      responseTransformer: (snippet) => normalize(snippet, schemas.snippet),
    }
  );

export const deleteSnippetById = (snippetId, accessToken) =>
  asyncAction(SNIPPET_REMOVE, snippetsApi.remove(snippetId, accessToken), {
    responseTransformer: () => ({ id: snippetId }),
  });
