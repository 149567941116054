/* eslint-disable class-methods-use-this */
import _ from "lodash";

export default class QuestionHandler {
  constructor(loggedInUser) {
    this.loggedInUser = loggedInUser || { id: null };
  }

  /** Returns all questions where logged in user is one of the resonders */
  getMyQuestions = (questions) =>
    this.sortQuestionsByMyResponse(
      questions.filter((question) =>
        question.responders.some((user) => this.loggedInUser.id === user.id)
      )
    );

  /**  Sort questions where loggedInUser has not responded first */
  sortQuestionsByMyResponse = (questions) => [
    ...questions.filter(
      (question) => !this.getResponse(this.loggedInUser, question.responses)
    ),
    ...questions.filter(
      (question) => !!this.getResponse(this.loggedInUser, question.responses)
    ),
  ];

  /** Returns all questions where logged in user is not one of the resonders  */
  getOtherQuestions = (questions) =>
    questions.filter(
      (question) =>
        !question.responders.some((user) => this.loggedInUser.id === user.id)
    );

  /** Returns user response for a given question */
  getMyResponse = (question) =>
    question.responses.filter(
      (response) => response.user.id === this.loggedInUser.id
    )[0] || null;

  /** Checks if user has responded to a question */
  currentUserHasResponded = (question) =>
    question.responses
      .map((response) => response.user)
      .some((user) => this.loggedInUser.id === user.id);

  /**  Returns all responses for a given set of questions */
  getResponses = (questions) =>
    _.flatMap(questions, (question) => [...question.responses]);

  /**  Returns count of all responders for a given set of questions */
  getNumResponders = (questions) =>
    _.flatMap(questions, (question) => question.responders).length;

  /**  Returns count of 'yes' responses for a given set of questions */
  getNumYes = (questions) =>
    this.getResponses(questions).filter((response) => response.compliant)
      .length;

  /**  Returns count of 'no' responses for a given set of questions */
  getNumNo = (questions) =>
    this.getResponses(questions).filter((response) => !response.compliant)
      .length;

  /**  Returns count of 'pending' responses for a given set of questions */
  getNumPendingResponses = (questions) =>
    this.getNumResponders(questions) - this.getResponses(questions).length;

  /**  Returns a tooltip text indicating the state of the responder */
  getUserTooltip = (user, responses, short) => {
    const response = responses.find((r) => r.user.id === user.id);

    if (!response) {
      return short ? "Pending" : `${user.name} has not answered`;
    }

    if (response.compliant) {
      return short ? "Yes" : `${user.name} has answered 'Yes'`;
    }

    return short ? "No" : `${user.name} has answered 'No'`;
  };

  /**  Returns a generated color for response */
  getResponseColor = (theme, user, responses) => {
    const response = responses.find((r) => r.user.id === user.id);

    if (!response) {
      return theme.palette.text.secondary;
    }

    if (response.compliant) {
      return theme.palette.secondary.main;
    }

    return theme.palette.warning.main;
  };

  /**  Returns a selected user response */
  getResponse = (user, responses) =>
    responses.find((r) => r.user.id === user.id);

  /**  Sort responders that have submitted a response first  */
  sortQuestionResponses = (question) => [
    ...question.responders.filter(
      (user) => !!this.getResponse(user, question.responses)
    ),
    ...question.responders.filter(
      (user) => !this.getResponse(user, question.responses)
    ),
  ];
}

export const QUESTION_STATUS = {
  needsResponse: false,
  hasResponse: false,
  notResponder: false,
};
