import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { loadWorkItems } from "../../actions/workItems";
import {
  getLoggedInUser,
  getReferenceDataType,
  getWorkItems,
  getWorkItemSearchPagination,
  isLoading,
} from "../../reducers";
import dates from "../../util/dates";
import { createWorkItemSearchParameters } from "../../util/searchParameterUtils";
import { getResolvedDefinition } from "../../util/workItemTypeUtils";
import { getFirstWorkItemUserByType } from "../../util/workItemUserUtils";
import DueAgo from "../common/DueAgo";
import GridListing from "../common/GridListing";
import Pagination from "../common/Pagination";
import TimeAgo from "../common/TimeAgo";
import WorkItemOwner from "../workitem/components/overview/WorkItemOwner";
import WorkItemStatus from "../workitem/components/overview/WorkItemStatus";
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles((theme) => ({
  row: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.grey[200],
    },
  },
  container: {
    paddingTop: theme.spacing(2),
  },
  label: {
    lineHeight: "1.5rem",
  },
}));

const RiskReassessmentListing = ({
  history,
  pagination,
  reassessments,
  sortBy,
  filter,
  updateSort,
  clearFilter,
  loading,
  types,
  outcomes,
  statuses,
  loggedInUser,
  localLoadWorkItems,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();

  const getOrderBy = () => {
    const prefix = sortBy.direction === "desc" ? "-" : "";
    return `${prefix}${sortBy.field}`;
  };

  const buildSearchParameters = (offset) =>
    createWorkItemSearchParameters(
      {
        ...filter,
        types: ["RISK_REASSESSMENT"],
      },
      statuses,
      types,
      loggedInUser,
      {
        ...pagination,
        offset,
      },
      getOrderBy()
    );

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      localLoadWorkItems(buildSearchParameters(0), accessToken);
    })();
  }, [getAccessTokenSilently, filter, sortBy]);

  const handlePrevious = async () => {
    const accessToken = await getAccessTokenSilently();
    localLoadWorkItems(
      buildSearchParameters(pagination.previousOffset),
      accessToken
    );
  };

  const handleNext = async () => {
    const accessToken = await getAccessTokenSilently();
    localLoadWorkItems(
      buildSearchParameters(pagination.nextOffset),
      accessToken
    );
  };

  return (
    <>
      <GridListing
        sortedData={reassessments}
        loading={loading}
        sortBy={sortBy}
        dense={false}
        updateSort={(field, direction) =>
          updateSort({
            field,
            direction,
          })
        }
        onClick={(action) =>
          history.push(`/risk/risks/reassessments/${action.id}`)
        }
        clearFilter={clearFilter}
        columns={[
          {
            label: "Title",
            name: "title",
            size: 4,
            sortable: true,
            render: (workItem) => <>{workItem.title}</>,
          },
          {
            label: "Owner",
            name: "owner",
            size: 3,
            sortable: true,
            render: (workItem) => (
              <WorkItemOwner
                className={classes.owner}
                workItem={workItem}
                owner={
                  getFirstWorkItemUserByType(workItem.users, "OWNER")?.user
                }
                loggedInUser={loggedInUser}
                data-cy={
                  getFirstWorkItemUserByType(workItem.users, "OWNER")?.user
                }
                readonly
              />
            ),
          },
          {
            label: "Due date",
            name: "dueDate",
            size: 2,
            sortable: true,
            render: (workItem) => (
              <>
                {dates.formatDateLong(workItem.dueDate)}
                <br />
                <DueAgo
                  value={dates.parseDate(workItem.dueDate)}
                  startFull={workItem.status === "CLOSED"}
                />
              </>
            ),
          },
          {
            label: "Status",
            name: "status",
            size: 1,
            sortable: true,
            render: (workItem) => {
              const definition = getResolvedDefinition(
                types,
                workItem.type,
                workItem.parent?.type
              );
              return (
                <>
                  <WorkItemStatus
                    workItem={workItem}
                    readonly
                    definition={definition}
                  />
                  <span>
                    {workItem.outcome
                      ? outcomes.values[workItem.outcome].description
                      : ""}
                  </span>
                </>
              );
            },
          },
          {
            label: "Created",
            name: "created",
            size: 1,
            sortable: true,
            render: (workItem) => (
              <TimeAgo
                value={dates.parseTimestamp(workItem.created)}
                expandable
              />
            ),
          },
          {
            label: "Updated",
            name: "updated",
            size: 1,
            sortable: true,
            render: (workItem) => (
              <TimeAgo
                value={dates.parseTimestamp(workItem.updated)}
                expandable
              />
            ),
          },
        ]}
      />
      <Pagination
        data-cy="pagination"
        pagination={pagination}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
      />
    </>
  );
};

RiskReassessmentListing.propTypes = {
  history: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  sortBy: PropTypes.object.isRequired,
  clearFilter: PropTypes.func.isRequired,
  updateSort: PropTypes.func.isRequired,

  // redux
  loggedInUser: PropTypes.object,
  statuses: PropTypes.object.isRequired,
  types: PropTypes.object.isRequired,
  outcomes: PropTypes.object.isRequired,
  reassessments: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  localLoadWorkItems: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

RiskReassessmentListing.defaultProps = {
  loggedInUser: null,
};

const mapStateToProps = (state) => ({
  loading: isLoading(state),
  loggedInUser: getLoggedInUser(state),
  reassessments: getWorkItems(state),
  pagination: getWorkItemSearchPagination(state),
  statuses: getReferenceDataType(state, "WorkItemStatus"),
  types: getReferenceDataType(state, "WorkItemType"),
  outcomes: getReferenceDataType(state, "WorkItemOutcome"),
});

export default connect(mapStateToProps, {
  localLoadWorkItems: loadWorkItems,
})(RiskReassessmentListing);
