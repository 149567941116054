import { Grid, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getFileUploadsInProgress, isDragging } from "../../../../../reducers";
import { attachmentFile } from "../../../../../util/icons";
import DocumentChips from "../../../../common/file/DocumentChips";
import VersionedFileUpload from "../../../../common/VersionedFileUpload";

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
  },
  buttonIcon: {
    marginLeft: theme.spacing(1),
  },
  fileUpload: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const FlaggedFileUpload = ({
  flag,
  documents,
  addDocuments,
  deleteDocument,
  onDocumentClick,
  uploadNamespace,
  filesInProgress,
  dragging,
}) => {
  const classes = useStyles();
  const [showDropzone, setShowDropzone] = useState(false);
  const flaggedDocuments = documents.filter(
    (doc) => doc.flags.indexOf(flag) !== -1
  );

  useEffect(() => {
    console.log("filesInProgress", filesInProgress);
    if (filesInProgress.length > 0) {
      setShowDropzone(true);
    }
  }, [filesInProgress]);

  const AttachmentIcon = attachmentFile();

  return (
    <>
      <Grid spacing={1} container>
        <Grid item>
          <IconButton
            title="Add File"
            className={classes.button}
            disabled={showDropzone || !addDocuments}
            onClick={() => setShowDropzone(true)}
            size="small"
          >
            <AttachmentIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <DocumentChips
            documents={flaggedDocuments}
            onDocumentClick={onDocumentClick}
            onDeleteDocument={deleteDocument}
          />
        </Grid>
      </Grid>
      <VersionedFileUpload
        className={classes.fileUpload}
        existingDocuments={flaggedDocuments}
        uploadNamespace={uploadNamespace}
        onSubmit={(files) =>
          addDocuments(files.map((f) => ({ ...f, flags: [flag] })))
        }
        onClose={() => setShowDropzone(false)}
        showDropzone={showDropzone || dragging}
      />
    </>
  );
};

FlaggedFileUpload.propTypes = {
  flag: PropTypes.string.isRequired,
  documents: PropTypes.array.isRequired,
  addDocuments: PropTypes.func.isRequired,
  deleteDocument: PropTypes.func,
  onDocumentClick: PropTypes.func.isRequired,
  uploadNamespace: PropTypes.string.isRequired,

  // redux state
  dragging: PropTypes.bool,
  filesInProgress: PropTypes.array,
};

FlaggedFileUpload.defaultProps = {
  filesInProgress: [],
  dragging: false,
};

const mapStateToProps = (state, { uploadNamespace }) => ({
  dragging: isDragging(state),
  filesInProgress: getFileUploadsInProgress(state)[uploadNamespace],
});

export default connect(mapStateToProps)(FlaggedFileUpload);
