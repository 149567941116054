import { useAuth0 } from "@auth0/auth0-react";
import { roles, useTenant } from "@certane/arcadia-web-components";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { getLoggedInUser, getReferenceDataType } from "../../reducers";
import organisationsApi from "../../services/api/organisations";
import productsApi from "../../services/api/products";
import usersApi from "../../services/api/users";
import Avatar from "../common/Avatar.tsx";
import ChoiceChips from "../common/ChoiceChips";
import ClearButton from "../common/ClearButton";
import DebouncedTextField from "../common/DebouncedTextField";
import FiltersBar from "../common/FiltersBar";
import MultiSelectListPicker from "../common/MultiSelectListPicker";
import RefMultiSelect from "../common/RefMultiSelect";
import ListPicker from "../ListPicker";
import getFilteredTypes, { filterableWorkItemTypes } from "./getFilteredTypes";

const useStyles = makeStyles((theme) => ({
  overhead: {
    marginBottom: theme.spacing(1),
  },
  selectWidgets: {
    marginTop: theme.spacing(1),
  },
  chips: {
    marginTop: theme.spacing(2),
  },
  ownerId: {
    marginRight: theme.spacing(1),
  },
  textListPicker: {
    fontSize: "0.8125rem",
    cursor: "pointer",
  },
  clearButton: {
    padding: theme.spacing(0.5),
    position: "absolute",
    right: 0,
    top: `-${theme.spacing(2)}px`,
  },
  badge: {},
}));

const OWNER_IDS = "ownerIds";

const ownershipMap = {
  owner: {
    label: (
      <>
        <Icon fontSize="inherit" data-cy="person">
          person
        </Icon>
      </>
    ),
    tooltip: "Owned by me",
    excludeExternal: true,
  },
  [OWNER_IDS]: {
    label: (
      <>
        <Icon fontSize="inherit" data-cy="person_search">
          person_search
        </Icon>
      </>
    ),
    tooltip: "Owned by...",
    excludeExternal: true,
  },
  unassigned: {
    label: (
      <>
        <Icon fontSize="inherit" data-cy="person_off">
          person_off
        </Icon>
      </>
    ),
    tooltip: "No owner",
  },
};

const userAssignmentMap = {
  createdByMe: {
    label: (
      <>
        <Icon fontSize="inherit" data-cy="person_add">
          person_add
        </Icon>
      </>
    ),
    tooltip: "Submitted by me",
  },
  assignedToMe: {
    label: (
      <>
        <Icon fontSize="inherit" data-cy="assignment_ind">
          assignment_ind
        </Icon>
      </>
    ),
    tooltip: "Assigned to me",
  },
  collaborator: {
    label: (
      <>
        <Icon fontSize="inherit" data-cy="group">
          group
        </Icon>
      </>
    ),
    tooltip: "Collaborating",
  },
};

const userFilterMap = {
  ...ownershipMap,
  ...userAssignmentMap,
};

const organisationMap = {
  sargonServiceProviderIds: {
    label: "Internal",
    tooltip: "Internal entities",
    excludeExternal: true,
  },
  serviceProviderIds: {
    label: "Service providers",
    tooltip: "External service providers",
    excludeExternal: true,
  },
};

const statusMap = {
  OPEN: {
    label: "Open",
    tooltip: "Items in any non-closed state",
  },
  CLOSED: {
    label: "Closed",
    tooltip: "Closed items",
  },
};
const statusOptions = Object.keys(statusMap).map((value) => ({
  ...statusMap[value],
  value,
}));

const dueDateMap = {
  overdue: {
    label: "Overdue",
    tooltip: "Overdue items",
  },
  thisWeek: {
    label: "W",
    tooltip: "Items due this week",
  },
  thisMonth: {
    label: "M",
    tooltip: "Items due this month",
  },
  thisQuarter: {
    label: "Q",
    tooltip: "Items due this quarter",
  },
  thisYear: {
    label: "Y",
    tooltip: "Items due this calendar year",
  },
};
const dueDateOptions = ["thisWeek", "thisMonth", "thisQuarter", "thisYear"].map(
  (value) => ({ ...dueDateMap[value], value })
);
const overdueDateOptions = ["overdue"].map((value) => ({
  ...dueDateMap[value],
  value,
}));

const equalSets = (set1, set2) => _.isEqual(_.orderBy(set1), _.orderBy(set2));

const WorkItemFilters = ({
  filter,
  updateFilter,
  updateFilterMulti,
  types,
  customSearchTypes,
  loggedInUser,
}) => {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const { tenant } = useTenant();

  const [ownerPickerOpen, setOwnerPickerOpen] = useState(false);
  const [sargonServiceProviderPickerOpen, setSargonServiceProviderPickerOpen] =
    useState(false);
  const [serviceProviderPickerOpen, setServiceProviderPickerOpen] =
    useState(false);
  const [selectedOwnedBy, setSelectedOwnedBy] = useState([]);
  const [selectedSargonServiceProviders, setSelectedSargonServiceProviders] =
    useState([]);
  const [selectedServiceProviders, setSelectedServiceProviders] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [filteredTypes] = useState(
    getFilteredTypes(types, loggedInUser, tenant, {
      workItemTypes: filterableWorkItemTypes,
    })
  );

  useEffect(() => {
    // Clear any old subTypes, keyContactIds from legacy user cache.
    const filterDelta = { subTypes: [], keyContactIds: [] };

    if (loggedInUser.externalUser) {
      const nonExternalOwnerships = Object.keys(userFilterMap).filter(
        (ownershipId) => userFilterMap[ownershipId].excludeExternal
      );
      if (_.includes(nonExternalOwnerships, filter.ownership)) {
        filterDelta.ownership = null;
      }

      Object.keys(organisationMap)
        .filter(
          (organisationOptionId) =>
            organisationMap[organisationOptionId].excludeExternal
        )
        .filter(
          (organisationOptionId) => filter[organisationOptionId].length > 0
        )
        .forEach((organisationOptionId) => {
          filterDelta[organisationOptionId] = [];
        });
    }

    updateFilterMulti(filterDelta);
  }, []);

  useEffect(() => {
    if (filter.sargonServiceProviderIds.length === 0) {
      setSelectedSargonServiceProviders([]);
    } else if (
      !equalSets(
        selectedSargonServiceProviders.map((o) => o.id),
        filter.sargonServiceProviderIds
      )
    ) {
      getAccessTokenSilently().then((accessToken) =>
        organisationsApi
          .search(
            {
              limit: filter.sargonServiceProviderIds.length,
              offset: 0,
              organisationIds: filter.sargonServiceProviderIds,
            },
            null,
            accessToken
          )
          .then((response) =>
            setSelectedSargonServiceProviders(response.results)
          )
      );
    }

    if (filter.serviceProviderIds.length === 0) {
      setSelectedServiceProviders([]);
    } else if (
      !equalSets(
        selectedServiceProviders.map((o) => o.id),
        filter.serviceProviderIds
      )
    ) {
      getAccessTokenSilently().then((accessToken) =>
        organisationsApi
          .search(
            {
              limit: filter.serviceProviderIds.length,
              offset: 0,
              organisationIds: filter.serviceProviderIds,
            },
            null,
            accessToken
          )
          .then((response) => setSelectedServiceProviders(response.results))
      );
    }

    if (filter.productIds.length === 0) {
      setSelectedProducts([]);
    } else if (
      !equalSets(
        selectedProducts.map((p) => p.id),
        filter.productIds
      )
    ) {
      getAccessTokenSilently().then((accessToken) =>
        productsApi
          .search(
            {
              limit: filter.productIds.length,
              offset: 0,
              productIds: filter.productIds,
            },
            null,
            accessToken
          )
          .then((response) => setSelectedProducts(response.results))
      );
    }

    if (filter.ownerIds.length === 0) {
      setSelectedOwnedBy([]);
    } else if (
      !equalSets(
        selectedOwnedBy.map((u) => u.id),
        filter.ownerIds
      )
    ) {
      getAccessTokenSilently().then((accessToken) =>
        usersApi
          .search(
            {
              limit: filter.ownerIds.length,
              offset: 0,
              roles: roles.AGENT_ROLES,
              userIds: filter.ownerIds,
            },
            null,
            accessToken
          )
          .then((response) => setSelectedOwnedBy(response.results))
      );
    }
  }, [filter]);

  const asyncAgentFetch = async (pickerFilter, pagination, abortController) => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = {
      ...pickerFilter,
      limit: pagination.pageSize,
      offset: pagination.offset,
      roles: roles.AGENT_ROLES,
      orderByField: "name",
    };
    return usersApi.search(searchParameters, abortController, accessToken);
  };

  const asyncSargonServiceProviderFetch = async (
    pickerFilter,
    pagination,
    abortController
  ) => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = {
      ...pickerFilter,
      limit: pagination.pageSize,
      offset: pagination.offset,
      types: ["INTERNAL"],
      orderByField: "name",
    };
    return organisationsApi.search(
      searchParameters,
      abortController,
      accessToken
    );
  };

  const asyncServiceProviderFetch = async (
    pickerFilter,
    pagination,
    abortController
  ) => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = {
      ...pickerFilter,
      limit: pagination.pageSize,
      offset: pagination.offset,
      types: ["EXTERNAL"],
      orderByField: "name",
    };
    return organisationsApi.search(
      searchParameters,
      abortController,
      accessToken
    );
  };

  const asyncProductFetch = async (
    pickerFilter,
    pagination,
    abortController
  ) => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = {
      ...pickerFilter,
      limit: pagination.pageSize,
      offset: pagination.offset,
      orderByField: "name",
    };
    return productsApi.search(searchParameters, abortController, accessToken);
  };

  const onUserFilterSelection = (value, oldValue) => {
    if (value === OWNER_IDS || oldValue === OWNER_IDS) {
      if (oldValue !== OWNER_IDS) {
        setSelectedOwnedBy([]);
      }
      setOwnerPickerOpen(true);
    } else {
      updateFilterMulti({ ownership: value, [OWNER_IDS]: [] });
    }
  };

  const onSargonServiceProviderSelection = (value, oldValue) => {
    if (!oldValue) {
      setSelectedSargonServiceProviders([]);
    }
    setSargonServiceProviderPickerOpen(true);
  };

  const onServiceProviderSelection = (value, oldValue) => {
    if (!oldValue) {
      setSelectedServiceProviders([]);
    }
    setServiceProviderPickerOpen(true);
  };

  const setOwnedBy = (users) => {
    setSelectedOwnedBy(users);
    updateFilterMulti({
      ownership: null,
      [OWNER_IDS]: users.map((user) => user.id),
    });
    setOwnerPickerOpen(false);
  };

  const setSargonServiceProviders = (organisations) => {
    setSelectedSargonServiceProviders(organisations);
    updateFilter(
      "sargonServiceProviderIds",
      organisations.map((org) => org.id)
    );
    setSargonServiceProviderPickerOpen(false);
  };

  const setServiceProviders = (organisations) => {
    setSelectedServiceProviders(organisations);
    updateFilter(
      "serviceProviderIds",
      organisations.map((org) => org.id)
    );
    setServiceProviderPickerOpen(false);
  };

  const setProducts = (products) => {
    setSelectedProducts(products);
    updateFilter(
      "productIds",
      products.map((product) => product.id)
    );
  };

  const getUserFilterValue = () => {
    if (filter[OWNER_IDS].length > 0) {
      return OWNER_IDS;
    }
    return filter.ownership;
  };

  const getProductsLabel = () => {
    if (selectedProducts.length > 1) {
      return `${selectedProducts.length} selected`;
    }
    return selectedProducts.map((product) => product.name).join(", ");
  };

  const ownershipOptions = useMemo(() => {
    return Object.keys(ownershipMap)
      .filter(
        (value) =>
          !ownershipMap[value].excludeExternal || !loggedInUser.externalUser
      )
      .map((value) => ({
        label: ownershipMap[value].label,
        tooltip: ownershipMap[value].tooltip,
        value,
        badgeValue: value === OWNER_IDS ? filter[OWNER_IDS].length : null,
      }));
  }, [loggedInUser]);

  const userAssignmentOptions = useMemo(() => {
    return Object.keys(userAssignmentMap)
      .filter(
        (key) =>
          !userAssignmentMap[key].excludeExternal || !loggedInUser.externalUser
      )
      .map((value) => ({
        label: userAssignmentMap[value].label,
        tooltip: userAssignmentMap[value].tooltip,
        value,
        badgeValue: value === OWNER_IDS ? filter[OWNER_IDS].length : null,
      }));
  }, [loggedInUser]);

  const { namedQuery } = filter;

  if (!loggedInUser) {
    return <div />;
  }

  const showUnansweredQuestionsFilter =
    !filter.types ||
    filter.types.length === 0 ||
    filter.types.indexOf("COMPLIANCE_TASK") !== -1;

  return (
    <>
      <ListPicker
        title="Select owners"
        actionText="Select"
        open={ownerPickerOpen}
        onClose={() => setOwnerPickerOpen(false)}
        onSubmit={(u) => setOwnedBy(u)}
        datasource={asyncAgentFetch}
        selected={selectedOwnedBy}
        isMulti
        clearable
        toOption={(user) => ({
          label: user.name || "Unknown",
          id: user.id,
          email: user.email,
        })}
        fromOption={(option) => ({
          name: option.label,
          id: option.id,
          email: option.email,
        })}
        renderIcon={(user, size) => (
          <Avatar email={user.email} name={user.name} size={size} round />
        )}
        data-cy="selectowners"
      />
      <ListPicker
        title="Select responsible internal entities"
        data-cy="selectResponsibleSargonEntities"
        actionText="Select"
        open={sargonServiceProviderPickerOpen}
        onClose={() => setSargonServiceProviderPickerOpen(false)}
        onSubmit={(providers) => setSargonServiceProviders(providers)}
        datasource={asyncSargonServiceProviderFetch}
        selected={selectedSargonServiceProviders}
        isMulti
        clearable
        toOption={(org) => ({ label: org.name, id: org.id, org })}
        fromOption={(option) => option.org}
        renderIcon={(org, size) => (
          <Avatar name={(org.name || "Unknown").charAt(0)} size={size} round />
        )}
      />
      <ListPicker
        title="Select service providers"
        data-cy="selectServiceProviders"
        actionText="Select"
        open={serviceProviderPickerOpen}
        onClose={() => setServiceProviderPickerOpen(false)}
        onSubmit={(providers) => setServiceProviders(providers)}
        datasource={asyncServiceProviderFetch}
        selected={selectedServiceProviders}
        isMulti
        clearable
        toOption={(org) => ({ label: org.name, id: org.id, org })}
        fromOption={(option) => option.org}
        renderIcon={(org, size) => (
          <Avatar name={(org.name || "Unknown").charAt(0)} size={size} round />
        )}
      />
      <div>
        <FiltersBar
          overlay={
            namedQuery ? customSearchTypes.values[namedQuery].description : null
          }
          dismissOverlay={() => updateFilter("namedQuery", null)}
          overhead={
            <Grid
              container
              spacing={1}
              alignItems="flex-end"
              className={classes.overhead}
            >
              <Grid item xs={12} md={4}>
                <div>
                  {filter.textSearch && (
                    <ClearButton
                      className={classes.clearButton}
                      onClear={() => updateFilter("textSearch", "")}
                    />
                  )}
                  <div className={classes.selectWidgets}>
                    <DebouncedTextField
                      value={filter.textSearch}
                      onChange={(text) => updateFilter("textSearch", text)}
                      placeholder="Search across reference, title and notes"
                      margin="none"
                      data-cy="Search across reference, title and notes"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Icon>search</Icon>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <InputLabel data-cy="type">Type</InputLabel>
                {filter.types.length > 0 && (
                  <ClearButton
                    className={classes.clearButton}
                    onClear={() => updateFilter("types", [])}
                    data-cy="clear"
                  />
                )}
                <div className={classes.selectWidgets}>
                  <RefMultiSelect
                    title="Select type"
                    actionText="Select"
                    value={filter.types}
                    options={filteredTypes}
                    onChange={(value) => updateFilter("types", value)}
                    data-cy={filter.types}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <InputLabel data-cy="Products">Products</InputLabel>
                {selectedProducts.length > 0 && (
                  <ClearButton
                    className={classes.clearButton}
                    onClear={() => setProducts([])}
                    data-cy="clear"
                  />
                )}
                <div className={classes.selectWidgets}>
                  <MultiSelectListPicker
                    title="Select product"
                    actionText="Select"
                    label={getProductsLabel()}
                    data-cy={getProductsLabel()}
                    onSubmit={(orgs) => setProducts(orgs)}
                    datasource={asyncProductFetch}
                    selected={selectedProducts}
                    isMulti
                    clearable
                    toOption={(product) => ({
                      label: product.name,
                      id: product.id,
                      product,
                    })}
                    fromOption={(option) => option.product}
                    renderIcon={(product, size) => (
                      <Avatar
                        name={(product.name || "Unknown").charAt(0)}
                        size={size}
                        round
                      />
                    )}
                  />
                </div>
              </Grid>
            </Grid>
          }
        >
          {!loggedInUser.externalUser && (
            <>
              <InputLabel>
                <Icon fontSize="inherit" data-cy="person">
                  person
                </Icon>{" "}
                Ownership
              </InputLabel>
              <Grid container className={classes.chips}>
                <ChoiceChips
                  value={getUserFilterValue()}
                  options={ownershipOptions}
                  onChange={onUserFilterSelection}
                  data-cy={ownershipOptions}
                />
              </Grid>
            </>
          )}
          <>
            <InputLabel>
              <Icon fontSize="inherit" data-cy="assignment">
                assignment_ind
              </Icon>{" "}
              Assignment
            </InputLabel>
            <Grid container className={classes.chips}>
              <ChoiceChips
                value={getUserFilterValue()}
                options={userAssignmentOptions}
                onChange={onUserFilterSelection}
              />
            </Grid>
          </>
          <>
            <InputLabel>
              <Icon fontSize="inherit" data-cy="checkCircle">
                check_circle
              </Icon>{" "}
              Status
            </InputLabel>
            <div className={classes.chips}>
              <ChoiceChips
                value={filter.status}
                options={statusOptions}
                onChange={(value) => updateFilter("status", value)}
                data-cy={filter.status}
              />
            </div>
          </>
          <>
            <InputLabel>
              <Icon fontSize="inherit" data-cy="dueDate">
                date_range
              </Icon>{" "}
              Due this
            </InputLabel>
            <div className={classes.chips}>
              <ChoiceChips
                value={filter.dueDate}
                options={dueDateOptions}
                onChange={(value) => updateFilter("dueDate", value)}
                data-cy={filter.dueDate}
              />
            </div>
          </>
          <>
            <InputLabel>
              <Icon fontSize="inherit" data-cy="overDue">
                alarm
              </Icon>{" "}
              Overdue
            </InputLabel>
            <div className={classes.chips}>
              <ChoiceChips
                value={filter.dueDate}
                options={overdueDateOptions}
                onChange={(value) => updateFilter("dueDate", value)}
                data-cy={filter.dueDate}
              />
            </div>
          </>
          {!loggedInUser.externalUser && (
            <>
              <InputLabel data-cy="organisations">
                <Icon fontSize="inherit">business</Icon> Organisations
              </InputLabel>
              <Grid container className={classes.chips}>
                <ChoiceChips
                  data-cy="SargonServiceProviders"
                  value={
                    filter.sargonServiceProviderIds.length > 0
                      ? "sargonServiceProviders"
                      : ""
                  }
                  options={[
                    {
                      label: organisationMap.sargonServiceProviderIds.label,
                      tooltip: organisationMap.sargonServiceProviderIds.tooltip,
                      value: "sargonServiceProviders",
                      badgeValue: filter.sargonServiceProviderIds.length,
                    },
                  ]}
                  onChange={onSargonServiceProviderSelection}
                />
                <ChoiceChips
                  data-cy="serviceProviders"
                  value={
                    filter.serviceProviderIds.length > 0
                      ? "serviceProviders"
                      : ""
                  }
                  options={[
                    {
                      label: organisationMap.serviceProviderIds.label,
                      tooltip: organisationMap.serviceProviderIds.tooltip,
                      value: "serviceProviders",
                      badgeValue: filter.serviceProviderIds.length,
                    },
                  ]}
                  onChange={onServiceProviderSelection}
                />
              </Grid>
            </>
          )}
          {!loggedInUser.externalUser && (
            <>
              <InputLabel data-cy="questions">
                <Icon fontSize="inherit">live_help</Icon> Questions
              </InputLabel>
              <div className={classes.chips}>
                <ChoiceChips
                  value={filter.onlyPendingQuestions}
                  data-cy="unanswered"
                  options={[
                    {
                      label: "Unanswered",
                      tooltip:
                        "Only show compliance tasks with questions I need to answer",
                      value: true,
                    },
                  ]}
                  onChange={(value) =>
                    updateFilter("onlyPendingQuestions", value)
                  }
                  disabled={!showUnansweredQuestionsFilter}
                />
              </div>
            </>
          )}
        </FiltersBar>
      </div>
    </>
  );
};

WorkItemFilters.propTypes = {
  filter: PropTypes.object.isRequired,
  updateFilter: PropTypes.func.isRequired,
  updateFilterMulti: PropTypes.func.isRequired,

  // redux
  loggedInUser: PropTypes.object,
  types: PropTypes.object.isRequired,
  customSearchTypes: PropTypes.object.isRequired,
};

WorkItemFilters.defaultProps = {
  loggedInUser: null,
};

const mapStateToProps = (state) => ({
  types: getReferenceDataType(state, "WorkItemType"),
  customSearchTypes: getReferenceDataType(state, "CustomSearchType"),
  loggedInUser: getLoggedInUser(state),
});

export default connect(mapStateToProps)(WorkItemFilters);
