import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { getLabels } from "../../../../reducers";
import { scrollToFirstInvalidInput } from "../../../../util/formHelpers";
import FormError from "../../../common/FormError";
import OverlayDropzone from "../../../common/OverlayDropzone";
import WrappedRichTextMentions from "../../../forms/wrapper/WrappedRichTextMentions";

export const RATING_NOTES_FORM_NAME = "RATING_NOTES_FORM_NAME";

const RatingNotesForm = ({ fieldLabels, error }) => {
  return (
    <OverlayDropzone onDrop={false} multiple readonly={false}>
      <form autoComplete="off">
        {error && <FormError errorMessage={error} />}
        <Field
          component={WrappedRichTextMentions}
          name="ratingNotes"
          label={fieldLabels.nestedTypes.responses.labels.ratingNotes}
          fullWidth
          datasource={[]}
          small
        />
      </form>
    </OverlayDropzone>
  );
};

RatingNotesForm.propTypes = {
  ratingNotes: PropTypes.string,
  error: PropTypes.string,
  fieldLabels: PropTypes.object.isRequired,
};

RatingNotesForm.defaultProps = {
  error: null,
  additionalFields: null,
  selectedCompliant: false,
};

const reduxFormSelector = formValueSelector(RATING_NOTES_FORM_NAME);

const mapStateToProps = (state) => ({
  ratingNotes: reduxFormSelector(state, "ratingNotes"),
  fieldLabels: getLabels(state).Question,
});

const form = reduxForm({
  form: RATING_NOTES_FORM_NAME,
  onSubmitFail: (errors) => scrollToFirstInvalidInput(errors),
});
const reduxData = connect(mapStateToProps);
export default compose(form, reduxData)(RatingNotesForm);
