import { useAuth0 } from "@auth0/auth0-react";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { getWebsiteSnapshots, isLoading } from "../../reducers";
import api from "../../services/api";
import dates from "../../util/dates";
import GridListing from "../common/GridListing";
import TimeAgo from "../common/TimeAgo";

const WebsiteSnapshotListing = ({ websiteSnapshots, loading }) => {
  const { getAccessTokenSilently } = useAuth0();

  const downloadFile = async (websiteSnapshot) => {
    const accessToken = await getAccessTokenSilently();
    const url = await api.websiteSnapshots.getScreenshotUrl(
      websiteSnapshot.id,
      accessToken
    );
    window.open(url, "_blank");
  };

  return (
    <GridListing
      sortedData={websiteSnapshots}
      loading={loading && websiteSnapshots.length === 0}
      sortBy={{}}
      dense={false}
      updateSort={() => {
        // do nothing.
      }}
      action={(websiteSnapshot) => (
        <IconButton
          onClick={() => downloadFile(websiteSnapshot)}
          disabled={!websiteSnapshot.captureResult}
        >
          <Icon>open_in_new</Icon>
        </IconButton>
      )}
      columns={[
        {
          label: "Organisation",
          name: "orgFriendlyId",
          size: 2,
          sortable: false,
          render: (websiteSnapshot) => (
            <span>
              {websiteSnapshot.organisation
                ? websiteSnapshot.organisation.name
                : "-"}
            </span>
          ),
        },
        {
          label: "Url",
          name: "url",
          size: 6,
          sortable: false,
          render: (websiteSnapshot) => <span>{websiteSnapshot.url}</span>,
        },
        {
          label: "Created",
          name: "created",
          size: 2,
          sortable: false,
          render: (websiteSnapshot) =>
            websiteSnapshot.created ? (
              <TimeAgo
                value={dates.parseTimestamp(websiteSnapshot.created)}
                expandable
              />
            ) : (
              "-"
            ),
        },
      ]}
    />
  );
};

WebsiteSnapshotListing.propTypes = {
  loading: PropTypes.bool.isRequired,
  websiteSnapshots: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  loading: isLoading(state),
  websiteSnapshots: getWebsiteSnapshots(state),
});

export default connect(mapStateToProps)(WebsiteSnapshotListing);
