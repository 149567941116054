import { normalize } from "normalizr";
import schemas from "../schemas";
import workItemSchedulesApi from "../services/api/workItemSchedules";
import { asyncAction } from "./actions";

// action types
export const WORK_ITEM_SCHEDULES_CLEAR_ACTIVE =
  "WORK_ITEM_SCHEDULES_CLEAR_ACTIVE";
export const WORK_ITEM_SCHEDULES_FETCH_ONE = "WORK_ITEM_SCHEDULES_FETCH_ONE";
export const WORK_ITEM_SCHEDULES_PATCH = "WORK_ITEM_SCHEDULES_PATCH";
export const WORK_ITEM_SCHEDULES_LIST = "WORK_ITEM_SCHEDULES_LIST";

export const fetchWorkItemSchedule = (id, accessToken) =>
  asyncAction(
    WORK_ITEM_SCHEDULES_FETCH_ONE,
    workItemSchedulesApi.get(id, accessToken),
    {
      responseTransformer: (schedule) =>
        normalize(schedule, schemas.workItemSchedule),
    }
  );

export function clearActiveWorkItemSchedule() {
  return {
    type: WORK_ITEM_SCHEDULES_CLEAR_ACTIVE,
  };
}

export const fetchWorkItemSchedules = (filter, accessToken) => {
  const abortController = window.AbortController ? new AbortController() : null;
  return asyncAction(
    WORK_ITEM_SCHEDULES_LIST,
    workItemSchedulesApi.search(filter, abortController, accessToken),
    {
      responseTransformer: (response) => ({
        pagination: {
          offset: response.offset,
          previousOffset: response.previousOffset,
          nextOffset: response.nextOffset,
          pageSize: response.pageSize,
          resultCount: response.resultCount,
        },
        ...normalize(response.results, schemas.arrayOfWorkItemSchedules),
      }),
      actionParams: { abortController, nonBlocking: true },
    }
  );
};

export const patchWorkItemSchedule = (id, changes, description, accessToken) =>
  asyncAction(
    WORK_ITEM_SCHEDULES_PATCH,
    workItemSchedulesApi.patch(id, changes, description, accessToken),
    {
      responseTransformer: (response) =>
        normalize(response, schemas.workItemSchedule),
    }
  );
