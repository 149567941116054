import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: theme.spacing(1),
  },
  gutterTop: {
    paddingTop: theme.spacing(4),
  },
  gutterBottom: {
    paddingBottom: theme.spacing(4),
  },
  gridItem: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
      borderLeft: `1px solid ${theme.palette.divider}`,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      "&:first-child": {
        borderLeft: "none",
        paddingLeft: 0,
      },
      "&:last-child": {
        borderLeft: "none",
        marginLeft: "auto",
        paddingRight: 0,
      },
    },
  },
}));

const CardSection = ({
  children,
  title,
  gutterTop,
  gutterBottom,
  action,
  hideTitle,
}) => {
  const classes = useStyles();
  return (
    <div
      className={classNames({
        [classes.gutterTop]: gutterTop,
        [classes.gutterBottom]: gutterBottom,
      })}
    >
      {!hideTitle && (
        <Grid container alignContent="flex-start" alignItems="center">
          <Grid item className={classes.gridItem}>
            <Typography variant="subtitle1" color="primary" data-cy={title}>
              {title}
            </Typography>
          </Grid>
          <Grid item className={classes.gridItem}>
            {action}
          </Grid>
        </Grid>
      )}
      {children}
    </div>
  );
};

CardSection.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  title: PropTypes.string.isRequired,
  gutterTop: PropTypes.bool,
  gutterBottom: PropTypes.bool,
  action: PropTypes.any,
  hideTitle: PropTypes.bool,
};

CardSection.defaultProps = {
  children: null,
  gutterTop: false,
  gutterBottom: false,
  action: null,
  hideTitle: false,
};

export default CardSection;
