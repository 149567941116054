import { useAuth0 } from "@auth0/auth0-react";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { SubmissionError } from "redux-form";
import { createSubscription } from "../../../actions/subscriptions";
import Container from "../../../components/common/Container";
import FloatingActionButton from "../../../components/common/FloatingActionButton";
import HeaderBar from "../../../components/common/HeaderBar";
import PageHeading from "../../../components/common/PageHeading";
import ExpandableForm from "../../../components/forms/ExpandableForm";
import CreateSubscriptionForm, {
  CREATE_SUBSCRIPTION_FORM,
} from "../../../components/forms/subscription/CreateSubscriptionForm";
import SubscriptionListing from "../../../components/subscription/SubscriptionListing";
import { subscriptionIcon } from "../../../util/icons";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  contents: {
    marginBottom: "75px",
  },
  gridItem: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "auto",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      "&:first-child": {
        paddingLeft: 0,
      },
      "&:last-child": {
        marginLeft: "auto",
      },
    },
  },
});

const Index = ({ classes, localCreateSubscription }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  const onCreate = async (subscription) => {
    const accessToken = await getAccessTokenSilently();
    return localCreateSubscription(subscription, accessToken)
      .then(() => setCreateDialogOpen(false))
      .catch((error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  return (
    <div className={classes.root}>
      <HeaderBar>
        <PageHeading icon={subscriptionIcon()} heading="Subscriptions" />
      </HeaderBar>
      {!createDialogOpen && (
        <FloatingActionButton onClick={() => setCreateDialogOpen(true)} />
      )}
      <Container className={classes.contents}>
        <ExpandableForm
          title="Create subscription"
          submitButtonText="Create"
          formComponent={CreateSubscriptionForm}
          formName={CREATE_SUBSCRIPTION_FORM}
          open={createDialogOpen}
          onCancel={() => setCreateDialogOpen(false)}
          onSubmit={(values) => onCreate(values)}
          isAltPaper
        />
        <SubscriptionListing />
      </Container>
    </div>
  );
};

Index.propTypes = {
  classes: PropTypes.object.isRequired,
  localCreateSubscription: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    localCreateSubscription: createSubscription,
  })
)(Index);
