import withStyles from "@material-ui/core/styles/withStyles";
import * as PropTypes from "prop-types";
import React from "react";
import TextField from "@material-ui/core/TextField";

const styles = (theme) => ({
  warning: {
    color: theme.palette.warning.main,
  },
});

const WrappedTextField = ({
  classes,
  input,
  label,
  helperText,
  meta: { touched, error, invalid, warning },
  margin,
  ...custom
}) => (
  <TextField
    label={label}
    data-cy={label}
    error={touched && invalid}
    helperText={
      (touched && error) ||
      (touched && warning && (
        <span className={classes.warning}>{warning}</span>
      )) ||
      helperText
    }
    margin={margin}
    InputLabelProps={{ name: `position-${input.name}` }}
    {...input}
    {...custom}
  />
);

WrappedTextField.propTypes = {
  classes: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.any,
  margin: PropTypes.string,
};

WrappedTextField.defaultProps = {
  helperText: "",
  margin: "dense",
};

export default withStyles(styles)(WrappedTextField);
