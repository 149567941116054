import { requestJSON } from "./http";

const create = (sla, accessToken) =>
  requestJSON({
    path: "/sla/work-item",
    method: "POST",
    data: sla,
    accessToken,
  });

const search = (accessToken) =>
  requestJSON({
    path: "/sla/work-item",
    accessToken,
  });

const getById = (id, accessToken) =>
  requestJSON({
    path: `/sla/work-item/${id}`,
    accessToken,
  });

const patch = (id, changes, description, accessToken) =>
  requestJSON({
    path: `/sla/work-item/${id}`,
    method: "PATCH",
    data: {
      fields: Object.keys(changes),
      changes,
      description,
    },
    accessToken,
  });

const remove = (id, accessToken) =>
  requestJSON({
    path: `/sla/work-item/${id}`,
    method: "DELETE",
    accessToken,
  });

export default {
  create,
  search,
  getById,
  patch,
  remove,
};
