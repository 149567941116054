import { useAuth0 } from "@auth0/auth0-react";
import { Card } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import CheckCircle from "@material-ui/icons/CheckCircle";
import {
  clearActiveRiskControl,
  createRiskControl,
} from "../../../actions/riskControls";
import BreadcrumbLink from "../../../components/common/BreadcrumbLink";
import Container from "../../../components/common/Container";
import HeaderBar from "../../../components/common/HeaderBar";
import PageHeading from "../../../components/common/PageHeading";
import FormWrapper from "../../../components/forms/FormWrapper";
import CreateRiskControlForm, {
  CREATE_RISK_CONTROL_FORM_NAME,
} from "../../../components/forms/risk/CreateRiskControlForm";
import { getActiveRiskControl } from "../../../reducers";

const styles = (theme) => ({
  successPanel: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    width: "600px",
    textAlign: "center",
  },
  refNumber: {
    marginBottom: theme.spacing(4),
  },
  button: {
    marginLeft: "10px",
    "&:first-child": {
      marginLeft: 0,
    },
  },
  successIcon: {
    fontSize: "80px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  container: {
    marginTop: theme.spacing(2),
  },
});

const New = ({
  classes,
  createdRiskControl,
  localClearActiveRiskControl,
  localCreateRiskControl,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    localClearActiveRiskControl();
  }, []);

  const handleSubmission = async (values) => {
    const accessToken = await getAccessTokenSilently();
    return localCreateRiskControl(values, accessToken);
  };

  return (
    <div>
      <HeaderBar narrow>
        <BreadcrumbLink
          to="/risk/controls"
          label="Risk controls"
          includeArrow
        />
        <PageHeading heading="Create new risk control" />
      </HeaderBar>
      <Container narrow className={classes.container}>
        {createdRiskControl && (
          <Grid
            container
            alignItems="center"
            direction="row"
            justifyContent="center"
          >
            <Paper elevation={0} className={classes.successPanel}>
              <Typography variant="h4" data-cy="riskControlCreated">
                The risk control has been created.
              </Typography>
              <CheckCircle className={classes.successIcon} />
              <div>
                <Button
                  className={classes.button}
                  component={Link}
                  to={`/risk/controls/${createdRiskControl.id}`}
                  variant="contained"
                  color="primary"
                  size="large"
                  data-cy="viewRiskControl"
                >
                  View risk control
                </Button>
                <Button
                  className={classes.button}
                  component={Link}
                  to="/risk/controls"
                  variant="contained"
                  color="default"
                  size="large"
                  data-cy="returnToRiskControl"
                >
                  Return to risk controls
                </Button>
              </div>
            </Paper>
          </Grid>
        )}
        {!createdRiskControl && (
          <Card>
            <CardContent>
              <FormWrapper
                submitButtonText="Create"
                formComponent={CreateRiskControlForm}
                formName={CREATE_RISK_CONTROL_FORM_NAME}
                onSubmit={handleSubmission}
                initialValues={{ critical: false }}
                data-cy={CreateRiskControlForm}
              />
            </CardContent>
          </Card>
        )}
      </Container>
    </div>
  );
};

New.propTypes = {
  createdRiskControl: PropTypes.object,
  localCreateRiskControl: PropTypes.func.isRequired,
  localClearActiveRiskControl: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

New.defaultProps = {
  createdRiskControl: null,
};

const mapStateToProps = (state) => ({
  createdRiskControl: getActiveRiskControl(state),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    localCreateRiskControl: createRiskControl,
    localClearActiveRiskControl: clearActiveRiskControl,
  })
)(New);
