import { combineReducers } from "redux";
import {
  WORK_ITEM_ADD_CHILD,
  WORK_ITEM_ADD_CLAIM_ASSESSMENT,
  WORK_ITEM_ADD_ENDORSEMENT,
  WORK_ITEM_CLEAR_ACTIVE,
  WORK_ITEM_CLEAR_METRICS,
  WORK_ITEM_CREATE,
  WORK_ITEM_DELETE_DOCUMENT,
  WORK_ITEM_EDIT_CLAIM_ASSESSMENT,
  WORK_ITEM_GET_BY_ID,
  WORK_ITEM_LIST,
  WORK_ITEM_LIST_CHILDREN,
  WORK_ITEM_LOAD_METRIC,
  WORK_ITEM_PARENT,
  WORK_ITEM_PATCH,
  WORK_ITEM_PERFORM_ACTION,
  WORK_ITEM_QUESTION_ADD,
  WORK_ITEM_QUESTION_PATCH,
  WORK_ITEM_QUESTION_REMOVE,
  WORK_ITEM_REMOVE_CHILD,
  WORK_ITEM_REMOVE_ENDORSEMENT,
  WORK_ITEM_REMOVE_OCCURRENCE,
  WORK_ITEM_REMOVE_TIMESHEET_ENTRY,
  WORK_ITEM_REMOVE_USER,
  WORK_ITEM_SAVE_DOCUMENTS,
  WORK_ITEM_SCHEDULES_FETCH_OCCURRENCES,
  WORK_ITEM_SET_DOCUMENT_FLAGS,
  WORK_ITEM_SET_SIMPLE_REMINDER,
  WORK_ITEM_SET_STATUS,
  WORK_ITEM_SET_USERS,
  WORK_ITEM_SET_VISIBLE_TO_OWNER,
  WORK_ITEM_SWITCH_USER,
  WORK_ITEM_UPDATE_TIMESHEET_ENTRY,
} from "../actions/workItems";
import { publishActionToast } from "../services/toasts";
import logger from "../util/logger";
import { sortWorkItemFields } from "../util/workItemSortUtils";

function sortWorkItems(workItems) {
  if (!workItems) {
    return null;
  }
  const sorted = {};
  Object.keys(workItems).forEach((id) => {
    sorted[id] = sortWorkItemFields(workItems[id]);
  });
  return sorted;
}

const metrics = (state = {}, action) => {
  switch (action.type) {
    case WORK_ITEM_CLEAR_METRICS:
      return {};
    case `${WORK_ITEM_LOAD_METRIC}_SUCCESS`:
      return {
        ...state,
        [action.response.metric]: action.response.count,
      };
    default:
      break;
  }

  return state;
};

const successToasts = {
  [WORK_ITEM_ADD_ENDORSEMENT]: "Endorsement updated successfully",
  [WORK_ITEM_ADD_CLAIM_ASSESSMENT]: "Assessment saved successfully",
  [WORK_ITEM_EDIT_CLAIM_ASSESSMENT]: "Assessment saved successfully",
  [WORK_ITEM_GET_BY_ID]: undefined,
  [WORK_ITEM_PATCH]: "Saved changes successfully",
  [WORK_ITEM_QUESTION_ADD]: "Added question successfully",
  [WORK_ITEM_QUESTION_REMOVE]: "Removed question successfully",
  [WORK_ITEM_QUESTION_PATCH]: "Updated question successfully",
  [WORK_ITEM_PERFORM_ACTION]: "Updated successfully",
  [WORK_ITEM_REMOVE_USER]: "User removed successfully",
  [WORK_ITEM_REMOVE_ENDORSEMENT]: "Endorsement removed successfully",
  [WORK_ITEM_REMOVE_TIMESHEET_ENTRY]: "Timesheet removed successfully",
  [WORK_ITEM_SAVE_DOCUMENTS]: "Saved documents successfully",
  [WORK_ITEM_DELETE_DOCUMENT]: "Document deleted",
  [WORK_ITEM_SET_USERS]: "Users updated successfully",
  [WORK_ITEM_SET_DOCUMENT_FLAGS]: "Document updated successfully",
  [WORK_ITEM_SET_SIMPLE_REMINDER]: "Reminder added successfully",
  [WORK_ITEM_SET_STATUS]: "Status updated successfully",
  [WORK_ITEM_SET_VISIBLE_TO_OWNER]: "Visibility updated successfully",
  [WORK_ITEM_SWITCH_USER]: "User updated successfully",
  [WORK_ITEM_UPDATE_TIMESHEET_ENTRY]: "Timesheet updated successfully",
};

const byId = (state = { lite: {}, full: {} }, action) => {
  publishActionToast(action, "WORK_ITEM", successToasts);

  switch (action.type) {
    case `${WORK_ITEM_SCHEDULES_FETCH_OCCURRENCES}_SUCCESS`:
    case `${WORK_ITEM_LIST}_SUCCESS`:
    case `${WORK_ITEM_LIST_CHILDREN}_SUCCESS`: {
      return {
        ...state,
        lite: {
          ...state.lite,
          ...sortWorkItems(
            action.response ? action.response.entities.workItem : null
          ),
        },
      };
    }

    case `${WORK_ITEM_ADD_ENDORSEMENT}_SUCCESS`:
    case `${WORK_ITEM_ADD_CLAIM_ASSESSMENT}_SUCCESS`:
    case `${WORK_ITEM_EDIT_CLAIM_ASSESSMENT}_SUCCESS`:
    case `${WORK_ITEM_GET_BY_ID}_SUCCESS`:
    case `${WORK_ITEM_PARENT}_SUCCESS`:
    case `${WORK_ITEM_PATCH}_SUCCESS`:
    case `${WORK_ITEM_QUESTION_ADD}_SUCCESS`:
    case `${WORK_ITEM_QUESTION_REMOVE}_SUCCESS`:
    case `${WORK_ITEM_QUESTION_PATCH}_SUCCESS`:
    case `${WORK_ITEM_PERFORM_ACTION}_SUCCESS`:
    case `${WORK_ITEM_REMOVE_USER}_SUCCESS`:
    case `${WORK_ITEM_REMOVE_ENDORSEMENT}_SUCCESS`:
    case `${WORK_ITEM_REMOVE_TIMESHEET_ENTRY}_SUCCESS`:
    case `${WORK_ITEM_SAVE_DOCUMENTS}_SUCCESS`:
    case `${WORK_ITEM_DELETE_DOCUMENT}_SUCCESS`:
    case `${WORK_ITEM_SET_USERS}_SUCCESS`:
    case `${WORK_ITEM_SET_DOCUMENT_FLAGS}_SUCCESS`:
    case `${WORK_ITEM_SET_SIMPLE_REMINDER}_SUCCESS`:
    case `${WORK_ITEM_SET_STATUS}_SUCCESS`:
    case `${WORK_ITEM_SET_VISIBLE_TO_OWNER}_SUCCESS`:
    case `${WORK_ITEM_SWITCH_USER}_SUCCESS`:
    case `${WORK_ITEM_UPDATE_TIMESHEET_ENTRY}_SUCCESS`:
    case `${WORK_ITEM_CREATE}_SUCCESS`:
    case `${WORK_ITEM_ADD_CHILD}_SUCCESS`: {
      return {
        ...state,
        lite: {
          ...state.lite,
          ...sortWorkItems(
            action.response ? action.response.entities.workItem : null
          ),
        },
        full: {
          ...state.full,
          ...sortWorkItems(
            action.response ? action.response.entities.workItem : null
          ),
        },
      };
    }

    // Remove a workitem
    case `${WORK_ITEM_REMOVE_OCCURRENCE}_SUCCESS`:
    case `${WORK_ITEM_REMOVE_CHILD}_SUCCESS`: {
      const idToRemove = action.response.id;
      return {
        ...state,
        lite: {
          ...state.lite,
          [idToRemove]: undefined,
        },
        full: {
          ...state.full,
          [idToRemove]: undefined,
        },
      };
    }

    default:
      break;
  }

  return state;
};

const defaultActive = {
  list: [],
  listLastUpdate: 0,
  workItem: null,
  activeParent: null,
  activeChildren: [],
  activeOccurrences: [],
  error: null,
  searchAbortController: null,
  pagination: {
    offset: 0,
    pageSize: 50,
    resultCount: 0,
  },
};

const active = (state = defaultActive, action) => {
  switch (action.type) {
    case WORK_ITEM_CLEAR_ACTIVE: {
      return {
        ...defaultActive,
      };
    }
    // Dashboard
    case `${WORK_ITEM_LIST}_INPROGRESS`:
      if (state.searchAbortController) {
        logger.info("aborting previous unfinished request");
        state.searchAbortController.abort();
      }
      return {
        ...state,
        searchAbortController: action.abortController,
      };
    case `${WORK_ITEM_LIST}_FAILURE`:
      return {
        ...state,
        searchAbortController: null,
      };
    case `${WORK_ITEM_LIST}_SUCCESS`: {
      if (state.listLastUpdate < action.timestamp) {
        return {
          ...state,
          list: action.response.entities?.workItem
            ? Object.values(action.response.entities.workItem)
            : [],
          listLastUpdate: action.timestamp,
          searchAbortController: null,
          pagination: {
            ...action.response.pagination,
            pageSize: defaultActive.pagination.pageSize,
          },
        };
      }
      logger.info("Ignoring out of date response from server");
      break;
    }

    // failed workItem
    case `${WORK_ITEM_GET_BY_ID}_FAILURE`:
      return {
        ...state,
        workItem: null,
        error: {
          status: action.error.status,
          message: action.errorMessage,
        },
      };

    // workITem
    case `${WORK_ITEM_GET_BY_ID}_SUCCESS`:
    case `${WORK_ITEM_CREATE}_SUCCESS`: {
      return {
        ...state,
        workItem: action.response.result,
      };
    }

    // activeParent - single
    case `${WORK_ITEM_PARENT}_SUCCESS`: {
      return {
        ...state,
        activeParent: action.response ? action.response.result : null, // parent response may be blank
      };
    }

    // activeChildren - list (replace all)
    case `${WORK_ITEM_LIST_CHILDREN}_SUCCESS`: {
      return {
        ...state,
        activeChildren: [...action.response.result],
      };
    }

    // activeChildren - add child
    case `${WORK_ITEM_ADD_CHILD}_SUCCESS`: {
      return {
        ...state,
        activeChildren: [...state.activeChildren, action.response.result],
      };
    }

    // activeChildren - remove child
    case `${WORK_ITEM_REMOVE_CHILD}_SUCCESS`: {
      const idToRemove = action.response.id;
      return {
        ...state,
        activeChildren: [
          ...state.activeChildren.filter((id) => id !== idToRemove),
        ],
      };
    }

    // activeOccurrences - list (replace all)
    case `${WORK_ITEM_SCHEDULES_FETCH_OCCURRENCES}_SUCCESS`: {
      return {
        ...state,
        activeOccurrences: [...action.response.result],
      };
    }

    // activeOccurrences - remove occurrence
    case `${WORK_ITEM_REMOVE_OCCURRENCE}_SUCCESS`: {
      const idToRemove = action.response.id;
      return {
        ...state,
        activeOccurrences: [
          ...state.activeOccurrences.filter((id) => id !== idToRemove),
        ],
      };
    }

    default:
      break;
  }

  // an update to any work item should reset the pagination
  if (action.isUpdate && action.type.endsWith("_SUCCESS")) {
    return {
      ...state,
      pagination: defaultActive.pagination,
    };
  }

  return state;
};

export default combineReducers({
  active,
  byId,
  metrics,
});

// selectors
export const getWorkItems = (state) => state.active.list;
export const getWorkItem = (state) =>
  state.active.workItem ? state.byId.full[state.active.workItem] : null;
export const getParent = (state) =>
  state.active.activeParent ? state.byId.full[state.active.activeParent] : null;
export const getChildren = (state) =>
  state.active.activeChildren.map((id) => state.byId.lite[id]);
export const getOccurrences = (state) =>
  state.active.activeOccurrences.map((id) => state.byId.lite[id]);
export const getError = (state) => state.active.error;
export const getSearchPagination = (state) => state.active.pagination;
export const getMetrics = (state) => state.metrics;
