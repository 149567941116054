import PropTypes from "prop-types";
import React from "react";
import { Field, reduxForm } from "redux-form";
import { required } from "redux-form-validators";
import { scrollToFirstInvalidInput } from "../../../util/formHelpers";
import FormError from "../../common/FormError";
import WrappedFilePicker from "../wrapper/WrappedFilePicker";

const UploadViewsForm = ({ error }) => {
  return (
    <form autoComplete="off">
      {error && <FormError errorMessage={error} />}
      <Field
        name="file"
        component={WrappedFilePicker}
        required
        validate={required({
          msg: "File is required",
        })}
        label="File"
        fullWidth
        uploadNamespace={"viewsFile"}
        multiple={false}
        acceptedFileTypes=".yaml,.yml"
      />
    </form>
  );
};

UploadViewsForm.propTypes = {
  error: PropTypes.string,
};

UploadViewsForm.defaultProps = {
  error: null,
};

export const UPLOAD_VIEWS_FORM_NAME = "UPLOAD_VIEWS_FORM_NAME";

const form = reduxForm({
  form: UPLOAD_VIEWS_FORM_NAME,
  onSubmitFail: (errors) => scrollToFirstInvalidInput(errors),
});

export default form(UploadViewsForm);
