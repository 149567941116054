import { Menu, withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { startWorkItemAction } from "../../../actions/workItemActions";
import {
  getActiveWorkItemAction,
  getLoggedInUser,
  getReferenceDataType,
} from "../../../reducers";
import { helpIcon } from "../../../util/icons";
import {
  actionIsEnabled,
  getActionsForWorkItem,
  getResolvedDefinition,
} from "../../../util/workItemTypeUtils";

const styles = (theme) => ({
  actionButton: {
    minHeight: "48px",
    minWidth: "134px",
  },
  menuItem: {
    minWidth: "255px",
  },
  disabled: {
    color: theme.palette.text.disabled,
    width: "100%",
  },
});

const ActionButton = ({
  classes,
  workItem,
  loggedInUser,
  types,
  activeAction,
  localStateWorkItemAction,
}) => {
  const [actionMenuEl, setActionMenuEl] = useState(null);
  const definition = getResolvedDefinition(
    types,
    workItem.type,
    workItem.parent?.type
  );

  const onAction = (userAction) => {
    setActionMenuEl(null);
    localStateWorkItemAction(userAction);
  };

  const availableActions = getActionsForWorkItem(
    workItem,
    definition,
    loggedInUser
  ).filter((a) => a.visible);

  const actionsAvailable = availableActions.length > 0;
  const HelpIcon = helpIcon();

  return (
    <>
      <Button
        className={classes.actionButton}
        variant="contained"
        color="primary"
        onClick={(event) => setActionMenuEl(event.currentTarget)}
        disabled={!actionsAvailable || !!activeAction}
      >
        Actions
        <Icon className={classes.rightIcon} data-cy="action">
          arrow_drop_down
        </Icon>
      </Button>
      <Menu
        data-cy="actionsMenu"
        id="actions-menu"
        anchorEl={actionMenuEl}
        open={Boolean(actionMenuEl)}
        onClose={() => setActionMenuEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{ horizontal: -16, vertical: "top" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
      >
        {availableActions.map((actionAssignment) => {
          const actionEnabledCheck = actionIsEnabled(
            workItem,
            actionAssignment,
            loggedInUser
          );

          return (
            <MenuItem
              data-cy="actionMenuItem"
              className={classes.menuItem}
              key={`${actionAssignment.action}_${actionAssignment.name}`}
              onClick={
                !actionEnabledCheck.enabled
                  ? null
                  : () => onAction(actionAssignment)
              }
            >
              {actionEnabledCheck.enabled ? (
                actionAssignment.name
              ) : (
                <>
                  <span className={classes.disabled}>
                    {actionAssignment.name}
                  </span>
                  <Tooltip
                    title={actionEnabledCheck.disabledMessage}
                    disableFocusListener
                  >
                    <HelpIcon color="disabled" />
                  </Tooltip>
                </>
              )}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

ActionButton.propTypes = {
  classes: PropTypes.object.isRequired,
  loggedInUser: PropTypes.object,
  workItem: PropTypes.object,
  activeAction: PropTypes.object,
  types: PropTypes.object.isRequired,
  localStateWorkItemAction: PropTypes.func.isRequired,
};

ActionButton.defaultProps = {
  loggedInUser: null,
  activeAction: null,
  workItem: null,
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
  types: getReferenceDataType(state, "WorkItemType"),
  activeAction: getActiveWorkItemAction(state),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    localStateWorkItemAction: startWorkItemAction,
  })
)(ActionButton);
