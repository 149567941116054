import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import _ from "lodash";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { loadMetric } from "../../actions/workItems";
import Container from "../../components/common/Container";
import HeaderBar from "../../components/common/HeaderBar";
import PageHeading from "../../components/common/PageHeading";
import MetricCard from "../../components/MetricCard";
import {
  getLoggedInUser,
  getReferenceDataType,
  getRiskControlMetrics,
  getRiskMetrics,
  getWorkItemMetrics,
} from "../../reducers";
import { loadRiskControlMetric } from "../../actions/riskControls";
import { loadRiskMetric } from "../../actions/risks";
import { riskDashboardIcon } from "../../util/icons";
import logger from "../../util/logger";
import { createWorkItemFilterParameters } from "../../util/searchParameterUtils";
import { useAuth0 } from "@auth0/auth0-react";

const styles = (theme) => ({
  container: {
    paddingTop: theme.spacing(3),
  },
  card: {
    minWidth: 200,
    minHeight: 200,
    maxHeight: 200,
  },
});

const Index = ({
  classes,
  customSearchTypes,
  loggedInUser,
  history,
  workItemStatuses,
  riskMetrics,
  workItemMetrics,
  riskControlMetrics,
  loadMetric,
  loadRiskMetric,
  loadRiskControlMetric,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      getCustomSearchTypes().forEach((type) => {
        const searchParameters = getSearchParameters(type);
        switch (type.props.targetType) {
          case "WorkItem":
            loadMetric(type.id, searchParameters, accessToken);
            break;
          case "Risk":
            loadRiskMetric(type.id, searchParameters, accessToken);
            break;
          case "RiskControl":
            loadRiskControlMetric(type.id, searchParameters, accessToken);
            break;
          default:
            logger.error(
              `Don't know search parameter target type: ${type.props.targetType}`
            );
            break;
        }
      });
    })();
  }, [getAccessTokenSilently]);

  const getCustomSearchTypes = () => {
    return customSearchTypes.ids
      .map((id) => customSearchTypes.values[id])
      .filter((type) => _.startsWith(type.id, "RISK"))
      .filter(
        (type) =>
          loggedInUser &&
          _.intersection(type.props.visibleTo, loggedInUser.roles).length > 0
      );
  };

  const getSearchParameters = (customSearchType) => ({
    ...customSearchType.props.searchParameters,
    ownerIds: [loggedInUser.id],
  });

  const redirectTo = (customSearchType) => {
    const searchParameters = getSearchParameters(customSearchType);
    logger.debug("searchParameters", searchParameters);

    switch (customSearchType.id) {
      case "RISK_OWNED_BY_ME":
        history.push("/risk/risks", { filter: searchParameters });
        break;
      case "RISK_ACTION_OWNED_BY_ME": {
        const filter = createWorkItemFilterParameters(
          searchParameters,
          workItemStatuses
        );
        history.push("/risk/risks/actions", { filter });
        break;
      }
      case "RISK_CONTROL_OWNED_BY_ME": {
        history.push("/risk/controls", { filter: searchParameters });
        break;
      }
      case "RISK_CONTROL_VERIFICATION_OWNED_BY_ME": {
        const filter = createWorkItemFilterParameters(
          searchParameters,
          workItemStatuses
        );
        history.push("/risk/controls/control-verifications", { filter });
        break;
      }
      case "RISK_REASSESSMENT_OWNED_BY_ME": {
        const filter = createWorkItemFilterParameters(
          searchParameters,
          workItemStatuses
        );
        history.push("/risk/risks/reassessments", { filter });
        break;
      }
      case "RISK_MEASURE_UPDATE_OWNED_BY_ME": {
        const filter = createWorkItemFilterParameters(
          searchParameters,
          workItemStatuses
        );
        history.push("/risk/risks/measure-updates", { filter });
        break;
      }
      default:
        break;
    }
  };

  return (
    <div>
      <HeaderBar>
        <PageHeading icon={riskDashboardIcon()} heading="Risks" />
      </HeaderBar>
      <Container className={classes.container}>
        <Grid
          container
          spacing={5}
          alignItems="center"
          justifyContent="flex-start"
        >
          {getCustomSearchTypes().map((type) => {
            let value = 0;
            switch (type.props.targetType) {
              case "WorkItem":
                value = workItemMetrics[type.id];
                break;
              case "Risk":
                value = riskMetrics[type.id];
                break;
              case "RiskControl":
                value = riskControlMetrics[type.id];
                break;
              default:
                logger.error("unknown target type");
                break;
            }
            return (
              <Grid item xs={12} md={6} key={type.id}>
                <MetricCard
                  className={classes.card}
                  title={type.description}
                  value={value}
                  onClick={() => redirectTo(type)}
                  id={type.id}
                />
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </div>
  );
};

Index.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  loggedInUser: PropTypes.object,
  riskMetrics: PropTypes.object.isRequired,
  workItemMetrics: PropTypes.object.isRequired,
  riskControlMetrics: PropTypes.object.isRequired,
  loadRiskMetric: PropTypes.func.isRequired,
  loadMetric: PropTypes.func.isRequired,
  loadRiskControlMetric: PropTypes.func.isRequired,
  customSearchTypes: PropTypes.object.isRequired,
  workItemStatuses: PropTypes.object.isRequired,
};

Index.defaultProps = {
  loggedInUser: {},
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
  riskMetrics: getRiskMetrics(state),
  workItemMetrics: getWorkItemMetrics(state),
  riskControlMetrics: getRiskControlMetrics(state),
  customSearchTypes: getReferenceDataType(state, "CustomSearchType"),
  workItemStatuses: getReferenceDataType(state, "WorkItemStatus"),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    loadRiskMetric,
    loadMetric,
    loadRiskControlMetric,
  })
)(Index);
