import { useAuth0 } from "@auth0/auth0-react";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { fetchWorkItemScheduleOccurrences } from "../../../actions/workItems";
import { patchWorkItemSchedule } from "../../../actions/workItemSchedules";
import {
  getLabels,
  getReferenceDataType,
  getWorkItemSchedule,
} from "../../../reducers";
import dates from "../../../util/dates";
import DateAgo from "../../common/DateAgo";
import FormCard from "../../common/FormCard";
import Scorecard from "../../common/Scorecard";
import EditScheduleForm, { EDIT_SCHEDULE_FORM_NAME } from "./EditScheduleForm";

const styles = () => ({});

const ScheduleCard = ({
  workItem,
  workItemSchedule,
  recurrenceTypes,
  fieldLabels,
  localPatchWorkItemSchedule,
  localFetchWorkItemScheduleOccurrences,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const onSave = async (values) => {
    const accessToken = await getAccessTokenSilently();
    const existingOccurrenceIds = workItemSchedule.workItemIds;

    return localPatchWorkItemSchedule(
      workItemSchedule.id,
      values,
      "Updated work item schedule",
      accessToken
    ).then((schedule) => {
      if (!_.isEqual(existingOccurrenceIds, schedule.workItemIds)) {
        localFetchWorkItemScheduleOccurrences(workItemSchedule.id, accessToken);
      }
      return schedule;
    });
  };

  if (!workItem) return null;

  const recurrenceTypeDef =
    workItemSchedule.recurrenceType &&
    recurrenceTypes.values[workItemSchedule.recurrenceType];

  return (
    <FormCard
      title="Schedule details"
      formTitle="Edit schedule details"
      readOnlyView={() => (
        <>
          <Scorecard label={fieldLabels.labels.enabled}>
            {workItemSchedule.enabled ? "Yes" : "No"}
          </Scorecard>
          <Scorecard label={fieldLabels.labels.recurrenceType}>
            {recurrenceTypeDef.description}
          </Scorecard>
          <Scorecard label={fieldLabels.labels.startBy}>
            <DateAgo
              value={dates.parseDate(workItemSchedule.startBy)}
              startFull
            />
          </Scorecard>
          <Scorecard label={fieldLabels.labels.recurrenceUnit}>
            {workItemSchedule.recurrenceUnit}
            {` ${recurrenceTypeDef.props.units}`}
          </Scorecard>
          {workItemSchedule.nameOfFirstPeriod && (
            <Scorecard label={fieldLabels.labels.nameOfFirstPeriod}>
              {workItemSchedule.nameOfFirstPeriod}
            </Scorecard>
          )}
          <Scorecard label={fieldLabels.labels.stopBy}>
            {workItemSchedule.stopBy ? (
              <DateAgo
                value={dates.parseDate(workItemSchedule.stopBy)}
                startFull
              />
            ) : (
              "-"
            )}
          </Scorecard>
          <Scorecard label={fieldLabels.labels.creationLeadTime}>
            {workItemSchedule.creationLeadTime} days
          </Scorecard>
          <Scorecard label={fieldLabels.labels.dueDateOffset}>
            {workItemSchedule.dueDateOffset} days
          </Scorecard>
        </>
      )}
      formComponent={EditScheduleForm}
      formName={EDIT_SCHEDULE_FORM_NAME}
      onSave={onSave}
      initialValues={workItemSchedule}
      formProps={{
        maxWidth: "md",
        onCreateForm: false,
      }}
    />
  );
};

ScheduleCard.propTypes = {
  workItem: PropTypes.object,
  workItemSchedule: PropTypes.object,
  recurrenceTypes: PropTypes.object.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  localPatchWorkItemSchedule: PropTypes.func.isRequired,
  localFetchWorkItemScheduleOccurrences: PropTypes.func.isRequired,
};

ScheduleCard.defaultProps = {
  workItem: undefined,
  workItemSchedule: undefined,
};

const mapStateToProps = (state) => ({
  workItemSchedule: getWorkItemSchedule(state),
  recurrenceTypes: getReferenceDataType(state, "RecurrenceType"),
  fieldLabels: getLabels(state).WorkItemSchedule,
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    localPatchWorkItemSchedule: patchWorkItemSchedule,
    localFetchWorkItemScheduleOccurrences: fetchWorkItemScheduleOccurrences,
  })
)(ScheduleCard);
