import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
import auditReports, * as fromAuditReports from "./auditReports";
import auth, * as fromAuth from "./auth";
import businessServiceAreas, * as fromBusinessServiceAreas from "./businessServiceAreas";
import controls, * as fromControls from "./controls";
import debugMode from "./debug-mode";
import digitalAssets, * as fromDigitalAssets from "./digitalAssets";
import drag from "./drag";
import drawer, * as fromDrawer from "./drawer";
import files, * as fromFiles from "./files";
import isProd from "./is-prod";
import labels, * as fromLabels from "./labels";
import loading, * as fromLoading from "./loading";
import media, * as fromMedia from "./media";
import mentions, * as fromMentions from "./mentions";
import notifications, * as fromNotifications from "./notifications";
import organisations, * as fromOrganisations from "./organisations";
import products, * as fromProducts from "./products";
import questions, * as fromQuestions from "./questions";
import referenceData, * as fromReferenceData from "./referenceData";
import riskControls, * as fromRiskControls from "./riskControls";
import risks, * as fromRisks from "./risks";
import routerLocations, * as fromRouterLocations from "./routerLocations";
import slas, * as fromSlas from "./slas";
import snippets, * as fromSnippets from "./snippets";
import subscriptions, * as fromSubscriptions from "./subscriptions";
import tags, * as fromTags from "./tags";
import userGroups, * as fromUserGroups from "./userGroups";
import users, * as fromUsers from "./users";
import websiteDifferences, * as fromWebsiteDifferences from "./websiteDifferences";
import websiteSnapshots, * as fromWebsiteSnapshots from "./websiteSnapshots";
import workItemActions from "./workItemActions";
import workItemAssociations, * as fromWorkItemAssociations from "./workItemAssociations";
import workItems, * as fromWorkItems from "./workItems";
import workItemSchedules, * as fromWorkItemSchedules from "./workItemSchedules";

/**
 * Root reducer for the app.
 */
const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    form,
    auth,
    auditReports,
    drag,
    users,
    userGroups,
    drawer,
    debugMode,
    isProd,
    mentions,
    labels,
    notifications,
    referenceData,
    workItems,
    workItemSchedules,
    workItemAssociations,
    workItemActions,
    loading,
    routerLocations,
    files,
    controls,
    risks,
    riskControls,
    media,
    snippets,
    tags,
    digitalAssets,
    websiteSnapshots,
    websiteDifferences,
    slas,
    subscriptions,
    organisations,
    products,
    questions,
    businessServiceAreas,
  });

export const getLoggedInUser = (state) => fromAuth.getUser(state.auth);
export const getIsAuthenticated = (state) =>
  fromAuth.getIsAuthenticated(state.auth);
export const getAuthenticationError = (state) => fromAuth.getError(state.auth);

export const getActiveAuditReport = (state) =>
  fromAuditReports.getActiveAuditReport(state.auditReports);
export const getAuditReports = (state) =>
  fromAuditReports.getAuditReports(state.auditReports);
export const getAuditReportSearchPagination = (state) =>
  fromAuditReports.getSearchPagination(state.auditReports);

export const getUsers = (state) => fromUsers.getAll(state.users);
export const getActiveUser = (state) => fromUsers.getActiveUser(state.users);
export const getUserPagination = (state) =>
  fromUsers.getPagination(state.users);
export const getUserLastEdit = (state) => fromUsers.getLastEdit(state.users);

export const isDragging = (state) => state.drag;

export const getOpenDrawer = (state) => fromDrawer.getOpenDrawer(state.drawer);

export const isLoading = (state) => fromLoading.isLoading(state.loading);
export const isLoadingAction = (state, actionType) =>
  fromLoading.isLoadingAction(state.loading, actionType);
export const isLoadingBlockerEnabled = (state) =>
  fromLoading.isBlockerEnabled(state.loading);

export const getOrganisations = (state) =>
  fromOrganisations.getAll(state.organisations);
export const getActiveOrganisation = (state) =>
  fromOrganisations.getActiveOrganisation(state.organisations);
export const getOrganisationPagination = (state) =>
  fromOrganisations.getPagination(state.organisations);
export const getOrganisationLastEdit = (state) =>
  fromOrganisations.getLastEdit(state.organisations);

export const getProducts = (state) => fromProducts.getAll(state.products);
export const getActiveProduct = (state) =>
  fromProducts.getActiveProduct(state.products);
export const getProductPagination = (state) =>
  fromProducts.getPagination(state.products);
export const getProductLastEdit = (state) =>
  fromProducts.getLastEdit(state.products);

export const getQuestions = (state) => fromQuestions.getAll(state.questions);
export const getQuestionsSearchPagination = (state) =>
  fromQuestions.getPagination(state.questions);
export const getQuestionsMetrics = (state) =>
  fromQuestions.getMetrics(state.questions);

export const getBusinessServiceAreas = (state) =>
  fromBusinessServiceAreas.getAll(state.businessServiceAreas);
export const getActiveBusinessServiceArea = (state) =>
  fromBusinessServiceAreas.getActiveBusinessServiceArea(
    state.businessServiceAreas
  );
export const getBusinessServiceAreaPagination = (state) =>
  fromBusinessServiceAreas.getPagination(state.businessServiceAreas);
export const getBusinessServiceAreaLastEdit = (state) =>
  fromBusinessServiceAreas.getLastEdit(state.businessServiceAreas);

export const getUserGroups = (state) => fromUserGroups.getAll(state.userGroups);
export const getActiveUserGroup = (state) =>
  fromUserGroups.getActiveUserGroup(state.userGroups);
export const getUserGroupPagination = (state) =>
  fromUserGroups.getPagination(state.userGroups);
export const getUserGroupLastEdit = (state) =>
  fromUserGroups.getLastEdit(state.userGroups);

export const getNotifications = (state) =>
  fromNotifications.getAll(state.notifications);

export const getWorkItemSchedules = (state) =>
  fromWorkItemSchedules.getAll(state.workItemSchedules);
export const getWorkItemSchedule = (state) =>
  fromWorkItemSchedules.getOne(state.workItemSchedules);
export const getWorkItemScheduleSearchPagination = (state) =>
  fromWorkItemSchedules.getSearchPagination(state.workItemSchedules);

export const getWorkItems = (state) =>
  fromWorkItems.getWorkItems(state.workItems);
export const getWorkItem = (state) =>
  fromWorkItems.getWorkItem(state.workItems);
export const getWorkItemParent = (state) =>
  fromWorkItems.getParent(state.workItems);
export const getWorkItemChildren = (state) =>
  fromWorkItems.getChildren(state.workItems);
export const getWorkItemScheduleOccurrences = (state) =>
  fromWorkItems.getOccurrences(state.workItems);
export const getWorkItemLoadError = (state) =>
  fromWorkItems.getError(state.workItems);
export const getWorkItemSearchPagination = (state) =>
  fromWorkItems.getSearchPagination(state.workItems);
export const getWorkItemMetrics = (state) =>
  fromWorkItems.getMetrics(state.workItems);

export const getWorkItemAssociations = (state) =>
  fromWorkItemAssociations.getAssociations(state.workItemAssociations);
export const getActiveWorkItemAssociation = (state) =>
  fromWorkItemAssociations.getActiveAssociation(state.workItemAssociations);

export const getActiveWorkItemAction = (state) => state.workItemActions;

export const isLabelsDataLoaded = (state) => fromLabels.isLoaded(state.labels);
export const getLabels = (state) => fromLabels.getLabels(state.labels);

export const isReferenceDataLoaded = (state) =>
  fromReferenceData.isLoaded(state.referenceData);
export const getReferenceDataType = (state, type) =>
  fromReferenceData.getType(state.referenceData, type);
export const getFilteredReferenceDataType = (state, type, filter) =>
  fromReferenceData.getTypeFiltered(state.referenceData, type, filter);
export const getReferenceDataMap = (state, type) =>
  fromReferenceData.getMap(state.referenceData, type);
export const getReferenceDataOptions = (state, type) =>
  fromReferenceData.getOptions(state.referenceData, type);
export const getReferenceDataDescription = (state, type, id, defaultValue) =>
  fromReferenceData.getDescription(state.referenceData, type, id, defaultValue);

export const getRouterLocations = (state) =>
  fromRouterLocations.getLocations(state.routerLocations);

export const getFileUploadsInProgress = (state) =>
  fromFiles.getFilesInProgress(state.files);
export const isUploading = (state) => fromFiles.isUploading(state.files);

export const getActiveControl = (state) =>
  fromControls.getActiveControl(state.controls);
export const getControls = (state) => fromControls.getControls(state.controls);
export const getControlLoadError = (state) =>
  fromControls.getError(state.controls);
export const getControlMetrics = (state) =>
  fromControls.getMetrics(state.controls);
export const getControlPagination = (state) =>
  fromControls.getSearchPagination(state.controls);

export const getActiveRisk = (state) => fromRisks.getActiveRisk(state.risks);
export const lookupRiskById = (state) => fromRisks.lookupRiskById(state.risks);
export const getRisks = (state) => fromRisks.getRisks(state.risks);
export const getRiskSearchPagination = (state) =>
  fromRisks.getSearchPagination(state.risks);
export const getRiskTolerances = (state) =>
  fromRisks.getTolerances(state.risks);
export const getRiskMetrics = (state) => fromRisks.getMetrics(state.risks);

export const getActiveRiskControl = (state) =>
  fromRiskControls.getActiveRiskControl(state.riskControls);
export const getRiskControls = (state) =>
  fromRiskControls.getRiskControls(state.riskControls);
export const getRiskControlLoadError = (state) =>
  fromRiskControls.getError(state.riskControls);
export const getRiskControlMetrics = (state) =>
  fromRiskControls.getMetrics(state.riskControls);
export const getRiskControlAssociatedRisks = (state) =>
  fromRiskControls.getAssociatedRisks(state.riskControls);
export const getRiskControlPagination = (state) =>
  fromRiskControls.getSearchPagination(state.riskControls);

export const getMedia = (state) => fromMedia.getAll(state.media);
export const getActiveMedia = (state) => fromMedia.getActiveMedia(state.media);
export const getMediaPagination = (state) =>
  fromMedia.getPagination(state.media);
export const getMediaLastEdit = (state) => fromMedia.getLastEdit(state.media);

export const getActiveTag = (state) => fromTags.getActiveTag(state.tags);
export const getTagUsages = (state) => fromTags.getTagUsages(state.tags);
export const isTagUsagesLoading = (state) =>
  fromTags.isUsagesLoading(state.tags);
export const getTags = (state) => fromTags.getTags(state.tags);

export const getActiveSnippet = (state) =>
  fromSnippets.getActiveSnippet(state.snippets);
export const getActiveSnippetVersion = (state) =>
  fromSnippets.getActiveVersion(state.snippets);
export const getSnippets = (state) => fromSnippets.getSnippets(state.snippets);
export const getSnippetPagination = (state) =>
  fromSnippets.getPagination(state.snippets);
export const getSnippetLastEdit = (state) =>
  fromSnippets.getLastEdit(state.snippets);

export const getDigitalAssets = (state) =>
  fromDigitalAssets.getDigitalAssets(state.digitalAssets);
export const getDigitalAssetsPagination = (state) =>
  fromDigitalAssets.getPagination(state.digitalAssets);
export const getActiveDigitalAsset = (state) =>
  fromDigitalAssets.getActiveDigitalAsset(state.digitalAssets);

export const getWebsiteSnapshots = (state) =>
  fromWebsiteSnapshots.getSnapshots(state.websiteSnapshots);
export const getActiveWebsiteSnapshot = (state) =>
  fromWebsiteSnapshots.getActiveSnapshot(state.websiteSnapshots);
export const getActivePreviousWebsiteSnapshot = (state) =>
  fromWebsiteSnapshots.getActivePreviousSnapshot(state.websiteSnapshots);

export const getWebsiteDifferences = (state) =>
  fromWebsiteDifferences.getWebsiteDifferences(state.websiteDifferences);
export const getActiveWebsiteDifference = (state) =>
  fromWebsiteDifferences.getActiveWebsiteDifference(state.websiteDifferences);
export const getWebsiteDifferencePagination = (state) =>
  fromWebsiteDifferences.getPagination(state.websiteDifferences);

export const getMentions = (state) => fromMentions.getMentions(state.mentions);

export const getSlas = (state) => fromSlas.getSlas(state.slas);
export const getActiveSla = (state) => fromSlas.getActiveSla(state.slas);

export const getSubscriptions = (state) =>
  fromSubscriptions.getSubscriptions(state.subscriptions);
export const getActiveSubscription = (state) =>
  fromSubscriptions.getActiveSubscription(state.subscriptions);

export default createRootReducer;
