import { makeStyles } from "@material-ui/core/styles";
import { Rating } from "@material-ui/lab";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getReferenceDataType } from "../../../../reducers";
import logger from "../../../../util/logger";
import EffectivenessChip from "../../../controls/EffectivenessChip";

const useStyles = makeStyles(() => ({
  root: {
    width: 200,
    display: "flex",
    alignItems: "center",
  },
}));

const RatingPicker = ({ responseRatings, response, onChange, readonly }) => {
  const classes = useStyles();
  const [liveValue, setLiveValue] = useState(-1);
  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(
      response.responseRating
        ? responseRatings.ids.indexOf(response.responseRating) + 1
        : 0
    );
  }, [response]);

  const handleSetValue = (newValue) => {
    logger.debug(
      "newValue",
      newValue,
      "toRatingValue()",
      toRatingValue(newValue)
    );
    onChange(toRatingValue(newValue));
  };

  const toRatingValue = (index) => {
    let rating = null;
    if (index !== 0) {
      rating = responseRatings.ids[index - 1];
    }
    return rating;
  };

  logger.debug(
    "response.responseRating",
    response.responseRating,
    "value",
    value,
    "liveValue",
    liveValue
  );
  return (
    <div className={classes.root}>
      <Rating
        value={value}
        precision={1}
        max={responseRatings.ids.length}
        onChange={(event, newValue) => {
          logger.debug("newValue", newValue);
          handleSetValue(newValue);
        }}
        onChangeActive={(event, newLive) => {
          setLiveValue(newLive);
        }}
        disabled={readonly}
      />
      {response.responseRating !== null && (
        <div style={{ marginLeft: "10px" }}>
          <EffectivenessChip
            effectiveness={toRatingValue(liveValue !== -1 ? liveValue : value)}
            defaultValue="No rating"
          />
        </div>
      )}
    </div>
  );
};

RatingPicker.propTypes = {
  response: PropTypes.object.isRequired,
  responseRatings: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  readonly: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  responseRatings: getReferenceDataType(state, "ControlEffectiveness"),
});

export default connect(mapStateToProps)(RatingPicker);
