import { useAuth0 } from "@auth0/auth0-react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Chip from "@material-ui/core/Chip";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import classNames from "classnames";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { SubmissionError } from "redux-form";
import { helpIcon, moreVertIcon } from "../../../util/icons";
import {
  addChildWorkItem,
  getWorkItemById,
  loadWorkItemChildren,
  removeChildWorkItem,
  WORK_ITEM_LIST_CHILDREN,
} from "../../../actions/workItems";
import {
  getLabels,
  getLoggedInUser,
  getReferenceDataDescription,
  getReferenceDataType,
  getWorkItemChildren,
  isLoadingAction,
} from "../../../reducers";
import { getWorkItemLink } from "../../../routes/routeUtils";
import dates from "../../../util/dates";
import { sortWorkItems } from "../../../util/workItemSortUtils";
import { resolveChildTypeDefinition } from "../../../util/workItemTypeUtils";
import { getFirstWorkItemUserByType } from "../../../util/workItemUserUtils";
import AlertDialog from "../../common/AlertDialog";
import Avatar from "../../common/Avatar.tsx";
import DueAgo from "../../common/DueAgo";
import GridListing from "../../common/GridListing";
import ExpandableForm from "../../forms/ExpandableForm";
import OpenCloseWorkItemFilter from "../OpenCloseWorkItemFilter";
import CreateChildWorkItemForm, {
  CHILD_WORK_ITEM_FORM_NAME,
} from "./CreateChildWorkItemForm";
import WorkItemStatus from "../components/overview/WorkItemStatus";
import { includeTenantParam } from "@certane/arcadia-web-components";

const useStyles = makeStyles((theme) => ({
  nowrap: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  chip: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1) / 2,
  },
  chipRoot: {
    maxWidth: "100%",
  },
  chipIcon: {
    marginLeft: 0,
  },
  dueDateWarning: {
    color: theme.palette.error.main,
  },
  actions: {
    display: "flex",
  },
}));

function dueDateWarning(child) {
  return (
    child.status !== "CLOSED" &&
    !child.template &&
    moment(child.dueDate)
      .subtract(2, "day")
      .isSameOrBefore(moment().startOf("day"))
  );
}

function dueDateClassNames(classes, workItem) {
  return classNames({
    [classes.dueDateWarning]: dueDateWarning(workItem),
  });
}

const ChildListCard = ({
  childType,
  children,
  className,
  types,
  statuses,
  fieldLabels,
  loading,
  readonly,
  parent,
  loggedInUser,
  getIncidentActionTypeDescription,
  localLoadWorkItemChildren,
  localRemoveChildWorkItem,
  localGetWorkItemById,
  localAddChildWorkItem,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();

  const sort = () =>
    sortWorkItems(children, sortBy, types, {}, statuses, {}, {});

  const [previousParent, setPreviousParent] = useState(parent);
  const [childIdToRemove, setChildIdToRemove] = useState(null);
  const [sortBy, setSortBy] = useState({ field: "dueDate", direction: "asc" });
  const [formOpen, setFormOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuChildId, setMenuChildId] = useState(null);
  const [sortedChildren, setSortedChildren] = useState(sort());
  const [filteredChildren, setFilteredChildren] = useState(sort());

  useEffect(() => {
    getAccessTokenSilently().then((accessToken) =>
      localLoadWorkItemChildren(parent.id, accessToken)
    );
  }, []);

  useEffect(() => {
    if (
      parent.id !== previousParent.id ||
      !_.isEqual(parent.children, previousParent.children) ||
      parent.dueDate !== previousParent.dueDate
    ) {
      getAccessTokenSilently().then((accessToken) =>
        localLoadWorkItemChildren(parent.id, accessToken)
      );
    }
    setPreviousParent(parent);
  }, [parent]);

  useEffect(() => {
    setSortedChildren(sort());
  }, [sortBy, children]);

  const getRelativeDate = (child) => {
    if (child.template && child.hasParent) {
      if (child.parentDueDateOffset === 0) {
        return "Same day";
      }
      if (child.parentDueDateOffset > 1) {
        return `${child.parentDueDateOffset} days prior`;
      }
      if (child.parentDueDateOffset === 1) {
        return `${child.parentDueDateOffset} day prior`;
      }
      if (child.parentDueDateOffset === -1) {
        return `${child.parentDueDateOffset * -1} day after`;
      }
      return `${child.parentDueDateOffset * -1} days after`;
    }
    return null;
  };

  const onOpenMenu = (event, childId) => {
    setAnchorEl(event.currentTarget);
    setMenuChildId(childId);
  };

  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSubmitRemoveDialog = async () => {
    const accessToken = await getAccessTokenSilently();
    // remove new child, then refresh parent
    localRemoveChildWorkItem(
      parent.id,
      childIdToRemove,
      () => localGetWorkItemById(parent.id, accessToken),
      accessToken
    );
    setChildIdToRemove(null);
  };

  const handleSubmitAddDialog = async (values) => {
    const accessToken = await getAccessTokenSilently();
    // add new child, then refresh parent
    localAddChildWorkItem(
      parent.id,
      values,
      () => localGetWorkItemById(parent.id, accessToken),
      accessToken
    )
      .then(() => {
        setFormOpen(false);
      })
      .catch((error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  const HelpIcon = helpIcon();
  const mergedClassName = classNames(classes.root, className);

  const definition = resolveChildTypeDefinition(childType, types);
  const { name, creatableBy, deletableBy } = definition;
  const canCreate =
    loggedInUser && _.intersection(creatableBy, loggedInUser.roles).length > 0;
  const canDelete =
    loggedInUser && _.intersection(deletableBy, loggedInUser.roles).length > 0;
  const { type, helpText } = childType;

  const MoreVertIcon = moreVertIcon();

  return (
    <Card className={mergedClassName} elevation={0}>
      <AlertDialog
        title={`Remove ${name}`}
        body={`Are you sure you want to remove this ${name}?`}
        submitButtonText="Remove"
        open={!!childIdToRemove}
        onCancel={() => setChildIdToRemove(null)}
        onSubmit={handleSubmitRemoveDialog}
      />
      <CardHeader
        title={`${name}s`}
        action={
          <div className={classes.actions}>
            {helpText && (
              <Tooltip title={helpText.helpText} disableFocusListener>
                <IconButton aria-label="Help">
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            )}
            {!readonly && canCreate && (
              <Tooltip title={`Add ${name}`} disableFocusListener>
                <div>
                  <IconButton
                    onClick={() => setFormOpen(true)}
                    disabled={formOpen}
                  >
                    <AddIcon />
                  </IconButton>
                </div>
              </Tooltip>
            )}
          </div>
        }
      />
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onCloseMenu}>
        <MenuItem
          onClick={() => {
            setChildIdToRemove(menuChildId);
            setAnchorEl(null);
          }}
          disabled={readonly}
        >
          Remove
        </MenuItem>
      </Menu>
      <ExpandableForm
        title={`Add ${name}`}
        submitButtonText="Create"
        formComponent={CreateChildWorkItemForm}
        formName={CHILD_WORK_ITEM_FORM_NAME}
        open={formOpen}
        enableFileUploads
        onCancel={() => setFormOpen(false)}
        onSubmit={handleSubmitAddDialog}
        template={parent.template}
        parentType={parent.type}
        childTypeDefinition={definition}
        initialValues={{
          type,
        }}
      />
      <OpenCloseWorkItemFilter
        workItems={sortedChildren}
        filteredWorkItemsChange={setFilteredChildren}
      />
      <GridListing
        sortedData={filteredChildren}
        noItemsText={`No ${name}s available`}
        loading={loading}
        sortBy={sortBy}
        dense={false}
        updateSort={(field, direction) => setSortBy({ field, direction })}
        onClick={(child) =>
          window.open(includeTenantParam(getWorkItemLink(child)), "_blank")
        }
        action={(child) => (
          <>
            {readonly || !canDelete ? null : (
              <IconButton
                aria-owns={anchorEl}
                aria-haspopup="true"
                onClick={(event) => onOpenMenu(event, child.id)}
              >
                <MoreVertIcon />
              </IconButton>
            )}
          </>
        )}
        columns={[
          {
            label: fieldLabels.labels.title,
            name: "title",
            size: 5,
            sortable: true,
            render: (child) => (
              <>
                <Typography className={classes.nowrap} title={child.title}>
                  {child.title}
                </Typography>
                {child.incidentAction && (
                  <Typography
                    className={classes.nowrap}
                    variant="caption"
                    title={child.incidentAction.actionType}
                  >
                    {getIncidentActionTypeDescription(
                      child.incidentAction.actionType
                    )}
                  </Typography>
                )}
              </>
            ),
          },
          {
            label: fieldLabels.labels.owner,
            name: "owner",
            size: 4,
            sortable: true,
            render: (child) => {
              const owner = getFirstWorkItemUserByType(
                child.users,
                "OWNER"
              )?.user;
              return (
                <>
                  {owner && (
                    <Chip
                      className={classes.chip}
                      classes={{
                        root: classes.chipRoot,
                        label: classes.nowrap,
                        icon: classes.chipIcon,
                      }}
                      icon={
                        <Avatar
                          email={owner.email}
                          name={owner.name}
                          size={30}
                          round
                        />
                      }
                      label={owner.name}
                    />
                  )}
                </>
              );
            },
          },
          {
            label: fieldLabels.labels.status,
            name: "status",
            size: 2,
            sortable: true,
            render: (child) => {
              if (child.template) {
                return "-";
              }
              return (
                <WorkItemStatus
                  workItem={child}
                  readonly
                  definition={definition}
                />
              );
            },
          },
          {
            label: "Due date",
            name: "dueDate",
            size: 2,
            sortable: true,
            render: (child) => {
              return (
                <>
                  {!child.template && child.status !== "CLOSED" && (
                    <DueAgo
                      className={dueDateClassNames(classes, child)}
                      value={dates.parseDate(child.dueDate)}
                    />
                  )}
                  {!child.template && child.status === "CLOSED" && (
                    <DueAgo value={dates.parseDate(child.dueDate)} stayFull />
                  )}
                  {child.template && <span>{getRelativeDate(child)}</span>}
                </>
              );
            },
          },
        ]}
      />
    </Card>
  );
};

ChildListCard.propTypes = {
  childType: PropTypes.object.isRequired,
  parent: PropTypes.object.isRequired,
  className: PropTypes.string,
  readonly: PropTypes.bool,

  // redux
  fieldLabels: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  children: PropTypes.array.isRequired,
  types: PropTypes.object.isRequired,
  statuses: PropTypes.object.isRequired,
  loggedInUser: PropTypes.object,
  localGetWorkItemById: PropTypes.func.isRequired,
  getIncidentActionTypeDescription: PropTypes.func.isRequired,
  localLoadWorkItemChildren: PropTypes.func.isRequired,
  localAddChildWorkItem: PropTypes.func.isRequired,
  localRemoveChildWorkItem: PropTypes.func.isRequired,
};

ChildListCard.defaultProps = {
  className: undefined,
  readonly: false,
  loggedInUser: null,
};

const mapStateToProps = (state) => ({
  fieldLabels: getLabels(state).WorkItem,
  loading: isLoadingAction(state, WORK_ITEM_LIST_CHILDREN),
  types: getReferenceDataType(state, "WorkItemType"),
  statuses: getReferenceDataType(state, "WorkItemStatus"),
  children: getWorkItemChildren(state),
  loggedInUser: getLoggedInUser(state),
  getIncidentActionTypeDescription: (id) =>
    getReferenceDataDescription(state, "IncidentActionType", id, ""),
});

export default connect(mapStateToProps, {
  localLoadWorkItemChildren: loadWorkItemChildren,
  localAddChildWorkItem: addChildWorkItem,
  localRemoveChildWorkItem: removeChildWorkItem,
  localGetWorkItemById: getWorkItemById,
})(ChildListCard);
