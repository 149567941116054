import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Fund } from "../../model/fund";
import { toggleOffIcon, toggleOnIcon } from "../../util/icons";
import TimeAgo from "../common/TimeAgo";
import dates from "../../util/dates";
import GridListing from "../common/GridListing";
import Pagination from "../common/Pagination";
import { SearchPagination } from "../../model/SearchPagination";

const useStyles = makeStyles(() => ({
  organisationList: {
    marginBlockStart: 0,
    marginBlockEnd: 0,
  },
}));

interface SortBy {
  field: string;
  direction: string;
}

interface Props {
  funds: Fund[];
  loading: boolean;
  pagination: SearchPagination<Fund>;
  onPrevious: () => void;
  onNext: () => void;
  sortBy: SortBy;
  updateSort: (sortBy: SortBy) => void;
  onChange: (id: string, fund: Partial<Fund>) => Promise<Fund | undefined>;
  onSelect: (fund: Fund) => void;
  selected?: Fund;
  getRelationshipTypeDescription: (key: string) => string;
  getRegionDescription: (key: string) => string;
  fieldLabels: { labels: Record<string, string> };
}

const FundListing: React.FC<Props> = ({
  funds,
  loading,
  selected,
  pagination,
  onPrevious,
  onNext,
  sortBy,
  updateSort,
  onChange,
  onSelect,
  getRelationshipTypeDescription,
  getRegionDescription,
  fieldLabels,
}: Props) => {
  const classes = useStyles();

  const ActiveIcon = toggleOnIcon();
  const InactiveIcon = toggleOffIcon();

  return (
    <>
      <GridListing
        sortedData={funds}
        loading={loading}
        sortBy={sortBy}
        dense={false}
        isHighlight1={(fund) => selected && selected.id === fund.id}
        updateSort={(field, direction) => updateSort({ field, direction })}
        onClick={onSelect}
        // onClick={onSelect}
        // clearFilter={clearFilter}
        columns={[
          {
            label: "Active",
            name: "active",
            size: 1,
            sortable: false,
            render: (fund: Fund) => (
              <Tooltip
                title={fund.active ? "Active" : "Inactive"}
                disableFocusListener
              >
                <IconButton
                  size="small"
                  onClick={(evt) => {
                    evt.stopPropagation();
                    evt.preventDefault();
                    return onChange(fund.id, {
                      active: !fund.active,
                    });
                  }}
                >
                  {fund.active && <ActiveIcon color="primary" />}
                  {!fund.active && <InactiveIcon />}
                </IconButton>
              </Tooltip>
            ),
          },
          {
            label: fieldLabels.labels.friendlyId,
            name: "friendlyId",
            size: 1,
            sortable: true,
            render: (fund: Fund) => <>{fund.friendlyId}</>,
          },
          {
            label: fieldLabels.labels.name,
            name: "name",
            size: 3,
            sortable: true,
            render: (fund: Fund) => <>{fund.name}</>,
          },
          {
            label: fieldLabels.labels.businessNumber,
            name: "businessNumber",
            size: 2,
            sortable: false,
            render: (fund: Fund) => <>{fund.businessNumber}</>,
          },
          {
            label: fieldLabels.labels.region,
            name: "region",
            size: 1,
            sortable: true,
            render: (fund: Fund) => <>{getRegionDescription(fund.region)}</>,
          },
          {
            label: fieldLabels.labels.relationships,
            name: "relationships",
            size: 5,
            render: (fund: Fund) => (
              <ul className={classes.organisationList} data-cy="organisations">
                {fund.relationships.map((relationship) => (
                  <li
                    key={`${relationship.relationshipType}-${relationship.organisation.id}`}
                    data-cy={relationship.organisation.name}
                  >
                    {relationship.organisation.name} -{" "}
                    {getRelationshipTypeDescription(
                      relationship.relationshipType
                    )}
                  </li>
                ))}
              </ul>
            ),
          },
          {
            label: fieldLabels.labels.created,
            name: "created",
            size: 2,
            sortable: true,
            render: (fund: Fund) => (
              <TimeAgo value={dates.parseTimestamp(fund.created)} expandable />
            ),
          },
          {
            label: fieldLabels.labels.updated,
            name: "updated",
            size: 2,
            sortable: true,
            render: (fund: Fund) => (
              <TimeAgo value={dates.parseTimestamp(fund.updated)} expandable />
            ),
          },
        ]}
      />
      <Pagination
        pagination={pagination}
        handlePrevious={onPrevious}
        handleNext={onNext}
      />
    </>
  );
};

export default FundListing;
