import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import formatters from "../../../../util/formatters";
import ComponentScorecard from "../../../common/ComponentScorecard";
import { FormattedDate } from "react-intl";
import {
  getReferenceDataDescription,
  getReferenceDataType,
} from "../../../../reducers";
import SimpleChips from "../../../common/SimpleChips";

const InsuranceReadOnly = ({
  workItem,
  fieldLabels,
  customisations,
  coverType,
  getReferenceDataDescription,
}) => {
  const { insurance } = workItem;

  const excludedFieldsMap = {};
  customisations
    .filter((customisation) => customisation.state === "EXCLUDED")
    .forEach((customisation) => {
      excludedFieldsMap[customisation.field] = true;
    });

  const getDescriptions = (ids, type) =>
    (ids || []).map((id) => getReferenceDataDescription(id, type));

  return (
    <>
      <ComponentScorecard
        fieldLabels={fieldLabels}
        name="insurance.hasInsurance"
        customisations={customisations}
        entity={workItem}
        render={(value) => formatters.displayBoolean(value)}
        defaultDisplay="N/A"
      />
      {insurance && insurance.hasInsurance && (
        <>
          <ComponentScorecard
            fieldLabels={fieldLabels}
            name="insurance.coverAmount"
            customisations={customisations}
            entity={workItem}
            render={(value) => formatters.currency(value)}
            data-cy={fieldLabels}
          />
          <ComponentScorecard
            fieldLabels={fieldLabels}
            name="insurance.claimApproved"
            customisations={customisations}
            entity={workItem}
            render={(value) =>
              formatters.displayBoolean(value, "Approved", "Rejected")
            }
            defaultDisplay="N/A"
            data-cy={fieldLabels}
          />
          <ComponentScorecard
            fieldLabels={fieldLabels}
            name="insurance.claimNotes"
            customisations={customisations}
            entity={workItem}
            data-cy={fieldLabels}
          />
          {!insurance.claimApproved && (
            <>
              <ComponentScorecard
                fieldLabels={fieldLabels}
                name="insurance.dateJoinedFund"
                customisations={customisations}
                entity={workItem}
                render={(value) =>
                  value ? <FormattedDate value={value} /> : "-"
                }
              />
              <ComponentScorecard
                fieldLabels={fieldLabels}
                name="insurance.dateCoverApplied"
                customisations={customisations}
                entity={workItem}
                render={(value) =>
                  value ? <FormattedDate value={value} /> : "-"
                }
              />
              <ComponentScorecard
                fieldLabels={fieldLabels}
                name="insurance.coverTypes"
                customisations={customisations}
                entity={workItem}
                render={(value) => (
                  <SimpleChips
                    values={getDescriptions(value, "CoverType")}
                    blankLabel="-"
                  />
                )}
              />
              {insurance.coverTypes?.toString().includes(coverType.ids[0]) && (
                <>
                  <ComponentScorecard
                    fieldLabels={fieldLabels}
                    name="insurance.optInOrOut"
                    customisations={customisations}
                    entity={workItem}
                    render={(value) =>
                      formatters.displayBoolean(value, "Opt-in", "Opt-out")
                    }
                    data-cy={fieldLabels}
                  />
                </>
              )}
              <ComponentScorecard
                fieldLabels={fieldLabels}
                name="insurance.dateClaimFirstSubmitted"
                customisations={customisations}
                entity={workItem}
                render={(value) =>
                  value ? <FormattedDate value={value} /> : "-"
                }
              />
            </>
          )}
        </>
      )}
    </>
  );
};

InsuranceReadOnly.propTypes = {
  workItem: PropTypes.object.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  customisations: PropTypes.array,
  coverType: PropTypes.object,

  // redux
  getReferenceDataDescription: PropTypes.func.isRequired,
};

InsuranceReadOnly.defaultProps = {
  customisations: [],
};

const mapStateToProps = (state) => ({
  coverType: getReferenceDataType(state, "CoverType"),
  getReferenceDataDescription: (id, type, defaultValue) =>
    getReferenceDataDescription(state, type, id, defaultValue || "-"),
});

export default connect(mapStateToProps, {})(InsuranceReadOnly);
