import {
  JSONAPI,
  Tenant,
  TenantSummary,
} from "@certane/arcadia-web-components";
import { requestJSON } from "./http";

export const rootPath = `${process.env.REACT_APP_TENANT_SRV_HOST}/api`;

const listMine = (accessToken: string): Promise<JSONAPI<TenantSummary[]>> =>
  requestJSON({ path: "/tenants/mine", accessToken, rootPath });

const get = (id: string, accessToken: string): Promise<JSONAPI<Tenant>> =>
  requestJSON({ path: `/tenants/${id}`, accessToken, rootPath });

export default {
  listMine,
  get,
};
