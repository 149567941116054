import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { numericality, required } from "redux-form-validators";
import {
  getFilteredReferenceDataType,
  getReferenceDataOptions,
  getReferenceDataType,
} from "../../../../reducers";
import dates from "../../../../util/dates";
import ComponentField from "../../../forms/wrapper/ComponentField";
import WrappedDatePicker from "../../../forms/wrapper/WrappedDatePicker";
import WrappedSelect from "../../../forms/wrapper/WrappedSelect";
import WrappedSelectChips from "../../../forms/wrapper/WrappedSelectChips";
import WrappedTextField from "../../../forms/wrapper/WrappedTextField";
import WarningText from "../../../WarningText";
import { getRefDataMenuItems } from "../../../forms/menuItems";

const IncidentOccurrenceDetailsFields = ({
  customisations,
  change,
  untouch,
  formName,
  fieldLabels,
  incidentCauseOptions,
  licenseOptions,
  onCreateForm,
  occurrenceStartDate,
  expectedReportableSituation,
  reportableSituations,
}) => {
  const getDate = (d) => (!d ? new Date() : dates.parseDate(d));

  return (
    <>
      <ComponentField
        disableFuture
        maxDateMessage="Must be in the past"
        component={WrappedDatePicker}
        fullWidth
        name="incident.occurrenceStartDate"
        label={fieldLabels.nestedTypes.incident.labels.occurrenceStartDate}
        validate={required({
          msg: `${fieldLabels.nestedTypes.incident.labels.occurrenceStartDate} is required`,
        })}
        required
        data-cy={fieldLabels.nestedTypes.incident.labels.occurrenceStartDate}
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      />
      <ComponentField
        disableFuture
        minDate={getDate(occurrenceStartDate)}
        minDateMessage="Must be after the occurrence start date"
        maxDateMessage="Must be in the past"
        component={WrappedDatePicker}
        fullWidth
        name="incident.occurrenceEndDate"
        label={fieldLabels.nestedTypes.incident.labels.occurrenceEndDate}
        data-cy={fieldLabels.nestedTypes.incident.labels.occurrenceEndDate}
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      />
      <ComponentField
        disableFuture
        minDate={getDate(occurrenceStartDate)}
        minDateMessage="Must be after the occurrence start date"
        maxDateMessage="Must be in the past"
        component={WrappedDatePicker}
        fullWidth
        name="incident.identifiedDate"
        label={fieldLabels.nestedTypes.incident.labels.identifiedDate}
        validate={required({
          msg: `${fieldLabels.nestedTypes.incident.labels.identifiedDate} is required`,
        })}
        required
        data-cy={fieldLabels.nestedTypes.incident.labels.identifiedDate}
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      />
      <ComponentField
        disableFuture
        maxDateMessage="Must be in the past"
        component={WrappedDatePicker}
        fullWidth
        name="incident.reportedDate"
        label={fieldLabels.nestedTypes.incident.labels.reportedDate}
        // helperText="Date incident was reported to your compliance or risk team"
        data-cy={fieldLabels.nestedTypes.incident.labels.reportedDate}
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      />
      <ComponentField
        disableFuture
        maxDateMessage="Must be in the past"
        component={WrappedDatePicker}
        fullWidth
        name="incident.investigationStartDate"
        label={fieldLabels.nestedTypes.incident.labels.investigationStartDate}
        // helperText="Date incident was reported to your compliance or risk team"
        data-cy={fieldLabels.nestedTypes.incident.labels.investigationStartDate}
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
        helperText="Specify the date inquiries and information gathering commenced to determine whether a breach has occurred or will occur."
      />
      <ComponentField
        component={WrappedSelectChips}
        options={licenseOptions.ids}
        name="incident.licenses"
        label={fieldLabels.nestedTypes.incident.labels.licenses}
        fullWidth
        toOption={(id) => ({
          label: licenseOptions.values[id].description,
          value: id,
        })}
        fromOption={(option) => option.value}
        data-cy={fieldLabels.nestedTypes.incident.labels.licenses}
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      />
      <ComponentField
        component={WrappedTextField}
        name="incident.estimatedFinancialImpact"
        label={fieldLabels.nestedTypes.incident.labels.estimatedFinancialImpact}
        validate={numericality({
          int: true,
          ">=": 0,
          allowBlank: true,
        })}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        fullWidth
        data-cy={
          fieldLabels.nestedTypes.incident.labels.estimatedFinancialImpact
        }
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      />
      {!onCreateForm && (
        <ComponentField
          component={WrappedTextField}
          name="incident.actualFinancialImpact"
          label={fieldLabels.nestedTypes.incident.labels.actualFinancialImpact}
          validate={numericality({
            ">=": 0,
            allowBlank: true,
          })}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          fullWidth
          data-cy={
            fieldLabels.nestedTypes.incident.labels.actualFinancialImpact
          }
          customisations={customisations}
          change={change}
          untouch={untouch}
          formName={formName}
        />
      )}
      <ComponentField
        component={WrappedTextField}
        name="incident.affectedClients"
        label={fieldLabels.nestedTypes.incident.labels.affectedClients}
        validate={numericality({
          int: true,
          ">=": 0,
          allowBlank: true,
        })}
        helperText="Estimate number of ultimate clients (member/investor) affected, if not applicable enter 0."
        fullWidth
        data-cy={fieldLabels.nestedTypes.incident.labels.affectedClients}
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      />
      <ComponentField
        component={WrappedSelect}
        name="incident.cause"
        label={fieldLabels.nestedTypes.incident.labels.cause}
        validate={required({
          msg: `${fieldLabels.nestedTypes.incident.labels.cause} is required`,
        })}
        required
        fullWidth
        data-cy={fieldLabels.nestedTypes.incident.labels.cause}
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      >
        {incidentCauseOptions
          .filter((option) => option.value !== "INTERNAL_LIQUIDITY_LIMIT") // To be removed after migration
          .map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
      </ComponentField>
      <ComponentField
        component={WrappedSelect}
        name="incident.expectedReportableSituation"
        label={
          fieldLabels.nestedTypes.incident.labels.expectedReportableSituation
        }
        fullWidth
        nullLabel="Non reportable"
        displayNullLabelAsPlaceholder
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      >
        {getRefDataMenuItems(reportableSituations)}
      </ComponentField>
      {expectedReportableSituation && (
        <WarningText text="A report must be submitted to ASIC if investigations take more than 30 days from the investigation start date." />
      )}
    </>
  );
};

IncidentOccurrenceDetailsFields.propTypes = {
  customisations: PropTypes.array,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  incidentCauseOptions: PropTypes.array.isRequired,
  licenseOptions: PropTypes.object.isRequired,
  reportableSituations: PropTypes.object.isRequired,
  onCreateForm: PropTypes.bool.isRequired,
  // form values
  occurrenceStartDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  expectedReportableSituation: PropTypes.string,
};

IncidentOccurrenceDetailsFields.defaultProps = {
  occurrenceStartDate: null,
  customisations: [],
  expectedReportableSituation: null,
};

const mapStateToProps = (state, ownProps) => {
  const reduxFormSelector = formValueSelector(ownProps.formName);

  return {
    licenseOptions: getFilteredReferenceDataType(
      state,
      "License",
      (license) => license.selectable
    ),
    incidentCauseOptions: getReferenceDataOptions(state, "IncidentCause"),
    reportableSituations: getReferenceDataType(state, "ReportableSituation"),
    occurrenceStartDate: reduxFormSelector(
      state,
      "incident.occurrenceStartDate"
    ),
    expectedReportableSituation: reduxFormSelector(
      state,
      "incident.expectedReportableSituation"
    ),
  };
};

export default connect(mapStateToProps)(IncidentOccurrenceDetailsFields);
