import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { FieldArray, reduxForm } from "redux-form";
import { scrollToFirstInvalidInput } from "../../../../util/formHelpers";
import FormError from "../../../common/FormError";
import DependantChecklistFields from "./DependantChecklistFields";

export const EDIT_DEPENDENT_CHECKLIST_FORM_NAME =
  "EDIT_DEPENDENT_CHECKLIST_FORM_NAME";

const styles = () => ({});

class EditDependentChecklistForm extends Component {
  render() {
    const { error, ...rest } = this.props;

    return (
      <form autoComplete="off">
        {error && <FormError errorMessage={error} />}
        <FieldArray
          name="dependants"
          component={DependantChecklistFields}
          formName={EDIT_DEPENDENT_CHECKLIST_FORM_NAME}
          {...rest}
        />
      </form>
    );
  }
}

EditDependentChecklistForm.propTypes = {
  error: PropTypes.string,
};

EditDependentChecklistForm.defaultProps = {
  error: null,
};

const mapStateToProps = () => ({});

const form = reduxForm({
  form: EDIT_DEPENDENT_CHECKLIST_FORM_NAME,
  onSubmitFail: (errors) => scrollToFirstInvalidInput(errors),
});
const reduxData = connect(mapStateToProps);
export default compose(
  form,
  reduxData
)(withStyles(styles)(EditDependentChecklistForm));
