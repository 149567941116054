import { withStyles } from "@material-ui/core";
import _ from "lodash";
import Chip from "@material-ui/core/Chip";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import AlternateEmail from "@material-ui/icons/AlternateEmail";
import ArrowRight from "@material-ui/icons/ArrowRight";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import logger from "../../../util/logger";
import GhostChip from "../../composer/GhostChip";
import ListPicker from "../../ListPicker";

const styles = (theme) => ({
  label: {
    position: "relative",
  },
  nowrap: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(0.25),
    marginTop: theme.spacing(0.25),
  },
  chipIcon: {
    marginLeft: 0,
  },
  chipSingle: {
    marginBottom: theme.spacing(0.25),
    marginTop: theme.spacing(0.25),
  },
  chipRoot: {
    maxWidth: "100%",
  },
  container: {
    marginTop: theme.spacing(1) - theme.spacing(0.25),
    marginBottom: theme.spacing(1) - theme.spacing(0.25),
    cursor: "pointer",
  },
  borderBottom: {
    borderBottom: `solid 1px ${theme.palette.grey[500]}`,
    paddingBottom: "1px",
    "&:hover": {
      borderBottom: "solid 2px",
      paddingBottom: 0,
    },
  },
  borderBottomError: {
    borderBottom: `solid 2px ${theme.palette.error.main}`,
    paddingBottom: 0,
  },
  addButton: {
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    marginLeft: 0,
    marginBottom: theme.spacing(0.25),
    marginTop: theme.spacing(0.25),
  },
});

const WrappedChipListPicker = ({
  classes,
  className,
  field: { name, onChange, onBlur, value },
  form: { touched, errors },
  label,
  hideLabel,
  datasource,
  clearable,
  margin,
  helperText,
  disabled,
  required,
  renderIcon,
  isMulti,
  renderLabel,
  renderFilter,
  filterInitialValues,
  submitOnChange,
  selectAll,
  toOption,
  fromOption,
  hideUnderline,
  addIcon: AddIcon,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [firstValue] = useState(value);

  const isTouched = _.get(touched, name);
  const errorText = _.get(errors, name);
  logger.debug("isTouched", isTouched, "errorText", errorText);

  useEffect(() => {
    if (value !== firstValue) {
      if (value === "") {
        // ignore
      } else if (value === null || value === undefined) {
        onBlur({ target: { value: undefined, name, id: name } });
      } else {
        onBlur({ target: { value, name, id: name } });
      }
    }
  }, [value]);

  const onSelect = (values) => {
    setDialogOpen(false);
    onChange({ target: { value: values, name, id: name } });
  };

  const getSelection = (values) => {
    if (values === null || values === undefined || values === "") {
      return null;
    }
    return values;
  };

  const onDelete = (option) => {
    if (isMulti) {
      return () => {
        const newValues = value.filter((v) => v !== option);
        onChange({ target: { value: newValues, name, id: name } });
      };
    }
    return () => setDialogOpen(true);
  };

  const valueAsList = value && !isMulti ? [value] : value;

  return (
    <FormControl className={className} fullWidth margin={margin}>
      {!hideLabel && (
        <InputLabel
          className={classes.label}
          shrink
          required={required}
          data-cy={label}
        >
          {label}
        </InputLabel>
      )}
      <div
        className={
          hideUnderline
            ? null
            : isTouched && !!errorText
            ? classes.borderBottomError
            : classes.borderBottom
        }
      >
        <div
          className={classes.container}
          onClick={disabled ? null : () => setDialogOpen(true)}
          role="button"
          onKeyPress={disabled ? null : () => setDialogOpen(true)}
          tabIndex={0}
          data-cy="dialog"
        >
          {isMulti && (
            <IconButton
              className={classes.addButton}
              color="inherit"
              disabled={disabled}
              onClick={disabled ? null : () => setDialogOpen(true)}
              data-cy="iconButton"
            >
              <AddIcon />
            </IconButton>
          )}
          {valueAsList &&
            valueAsList.length > 0 &&
            valueAsList.map((v) => (
              <Chip
                key={toOption(v).id}
                className={
                  valueAsList.length > 1 ? classes.chip : classes.chipSingle
                }
                classes={{
                  root: classes.chipRoot,
                  label: classes.nowrap,
                  icon: classes.chipIcon,
                }}
                icon={renderIcon(v, 30)}
                label={toOption(v).label}
                deleteIcon={isMulti ? null : <ArrowRight />}
                onDelete={disabled ? null : onDelete(v)}
                data-cy={toOption(v).label}
              />
            ))}
          {(!valueAsList || valueAsList.length === 0) && !isMulti && (
            <GhostChip
              className={classes.chip}
              label={`Select ${label}`}
              onClick={disabled ? null : () => setDialogOpen(true)}
            />
          )}
        </div>
        <ListPicker
          data-cy={label}
          title={label}
          actionText="Select"
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          onSubmit={(values) => onSelect(values)}
          datasource={datasource}
          selected={getSelection(value)}
          isMulti={isMulti}
          selectAll={selectAll}
          clearable={clearable}
          renderIcon={renderIcon}
          renderLabel={renderLabel}
          renderFilter={renderFilter}
          filterInitialValues={filterInitialValues}
          submitOnChange={submitOnChange}
          toOption={toOption}
          fromOption={fromOption}
        />
      </div>
      {isTouched && errorText && (
        <FormHelperText error>{errorText}</FormHelperText>
      )}
      {!(isTouched && errorText) && helperText && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

WrappedChipListPicker.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  field: PropTypes.object.isRequired,
  datasource: PropTypes.oneOfType([PropTypes.func, PropTypes.array]).isRequired,
  form: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  hideLabel: PropTypes.bool,
  isMulti: PropTypes.bool,
  renderIcon: PropTypes.func.isRequired,
  renderLabel: PropTypes.func,
  renderFilter: PropTypes.func,
  filterInitialValues: PropTypes.object,
  submitOnChange: PropTypes.bool,
  margin: PropTypes.string,
  helperText: PropTypes.any,
  disabled: PropTypes.bool,
  selectAll: PropTypes.bool,
  clearable: PropTypes.bool,
  toOption: PropTypes.func,
  fromOption: PropTypes.func,
  required: PropTypes.bool,
  addIcon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  hideUnderline: PropTypes.bool,
};

WrappedChipListPicker.defaultProps = {
  className: null,
  isMulti: true,
  renderLabel: undefined,
  renderFilter: undefined,
  filterInitialValues: undefined,
  margin: "dense",
  hideLabel: false,
  helperText: "",
  disabled: false,
  selectAll: false,
  clearable: false,
  toOption: (value) => value,
  fromOption: (option) => option,
  required: false,
  submitOnChange: false,
  addIcon: AlternateEmail,
  hideUnderline: false,
};

export default withStyles(styles)(WrappedChipListPicker);
