import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { SubmissionError } from "redux-form";
import { addAssociatedRisks } from "../../actions/riskControls";
import { loadRisks } from "../../actions/risks";
import { fetchTags } from "../../actions/tags";
import {
  getReferenceDataDescription,
  getTags,
  lookupRiskById,
} from "../../reducers";
import risksApi from "../../services/api/risks";
import Avatar from "../common/Avatar.tsx";
import DebouncedTextField from "../common/DebouncedTextField";
import GridListing from "../common/GridListing";
import MultiSelectDropdown from "../common/MultiSelectDropdown";
import SimpleChip from "../common/SimpleChip";
import { tagsToOptions } from "../forms/menuItems";
import ListPicker from "../ListPicker";
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles((theme) => ({
  riskRatingChip: {
    backgroundColor: theme.palette.grey[300],
    padding: `${theme.spacing(1) / 4}px ${theme.spacing(1)}px`,
    borderRadius: "16px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: "100%",
    whiteSpace: "nowrap",
    marginRight: theme.spacing(1) / 2,
  },
  nowrap: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

const AssociatedRisksCard = ({
  riskControl,
  associatedRiskRefs,
  localLoadRisks,
  lookupRisk,
  tags,
  getRiskRatingDescription,
  localAddAssociatedRisks,
  localFetchTags,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();
  const [riskPickerOpen, setRiskPickerOpen] = useState(false);

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      localFetchTags(accessToken);
    })();
  }, []);

  useEffect(() => {
    // We have the risk refs, but may not have access to the full risks,
    // in which case we'll just try to load them all and those which
    // we can access will be returned
    if (associatedRiskRefs.length > 0) {
      const filter = {
        riskControlIds: [riskControl.id],
        orderByField: "friendlyId",
        limit: associatedRiskRefs.length,
        offset: 0,
      };
      (async () => {
        const accessToken = await getAccessTokenSilently();
        localLoadRisks(filter, accessToken);
      })();
    }
  }, [associatedRiskRefs]);

  const asyncRiskOptionsFetch = async (
    pickerFilter,
    pagination,
    abortController
  ) => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = {
      ...pickerFilter,
      limit: pagination.pageSize,
      offset: pagination.offset,
      orderByField: "title",
    };
    return risksApi.search(searchParameters, abortController, accessToken);
  };

  const associateRisks = async (risks) => {
    const newRisks = risks.filter(
      (r) =>
        !associatedRiskRefs.some((alreadyLinked) => alreadyLinked.id === r.id)
    );

    const accessToken = await getAccessTokenSilently();
    return localAddAssociatedRisks(
      riskControl.id,
      newRisks.map((r) => r.id),
      accessToken
    )
      .then(() => setRiskPickerOpen(false))
      .catch((error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  const riskRegisterOptions = tagsToOptions(
    tags.filter((tag) => tag.type === "RISK_REGISTER")
  );

  return (
    <Card elevation={0}>
      <ListPicker
        title="Select owners"
        actionText="Select"
        open={riskPickerOpen}
        onClose={() => setRiskPickerOpen(false)}
        onSubmit={associateRisks}
        datasource={asyncRiskOptionsFetch}
        selected={associatedRiskRefs}
        disabledOptions={associatedRiskRefs}
        isMulti
        clearable
        toOption={(risk) => ({
          label: risk.title,
          id: risk.id,
          risk,
        })}
        fromOption={(option) => option.risk}
        renderIcon={(risk, size) => (
          <Avatar
            data-cy={(risk.title || "Unknown").charAt(0)}
            name={(risk.title || "Unknown").charAt(0)}
            size={size}
            round
          />
        )}
        renderLabel={(risk) => (
          <ListItemText
            primary={
              <Typography
                className={classes.nowrap}
                title={risk.title}
                data-cy={risk.title}
              >
                {risk.title}
              </Typography>
            }
            secondary={
              <span data-cy={risk.owner && risk.owner.name}>
                <span
                  className={classes.riskRatingChip}
                  data-cy={getRiskRatingDescription(risk.riskRating)}
                >
                  {getRiskRatingDescription(risk.riskRating)}
                </span>
                {risk.owner && risk.owner.name}
              </span>
            }
          />
        )}
        renderFilter={(onChange, filter) => (
          <Grid container spacing={1} alignItems="flex-end">
            <Grid item xs={12} md={6} data-cy="associatedRisk">
              <DebouncedTextField
                value={filter.textSearch}
                onChange={(text) => onChange("textSearch", text)}
                placeholder="Type to filter..."
                margin="none"
                autoFocus
                data-cy={filter.textSearch}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <div>
                <FormLabel data-cy="riskRegister">Risk register</FormLabel>
                <div>
                  <MultiSelectDropdown
                    value={filter.riskRegisters || []}
                    options={riskRegisterOptions}
                    onChange={(value) => onChange("riskRegisters", value)}
                    closeOnChange
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        )}
      />
      <CardHeader
        title="Associated risks"
        action={
          <Tooltip title="Add risk" disableFocusListener>
            <div>
              <IconButton onClick={() => setRiskPickerOpen(true)}>
                <AddIcon data-cy="addRisk" />
              </IconButton>
            </div>
          </Tooltip>
        }
      />
      <CardContent>
        <GridListing
          sortedData={associatedRiskRefs}
          loading={false}
          sortBy={{}}
          dense
          noItemsText="There are no risks associated with this control"
          updateSort={() => {
            // do nothing.
          }}
          columns={[
            {
              label: "Reference",
              name: "friendlyId",
              size: 2,
              sortable: false,
              render: (riskRef) => <span>{riskRef.friendlyId}</span>,
            },
            {
              label: "Risk registers",
              name: "riskRegisters",
              size: 4,
              sortable: false,
              render: (riskRef) => {
                const risk = lookupRisk(riskRef.id);
                return risk
                  ? risk.riskRegisters.map((tag) => (
                      <SimpleChip key={tag.id} label={tag.text} />
                    ))
                  : "-";
              },
            },
            {
              label: "Title",
              name: "title",
              size: 6,
              sortable: false,
              render: (riskRef) =>
                riskRef.accessible ? (
                  <Link
                    to={`/risk/risks/${riskRef.id}`}
                    data-cy={riskRef.title}
                  >
                    {riskRef.title}
                  </Link>
                ) : (
                  <Tooltip title="Sorry you don’t have permission to view this risk">
                    <span>{riskRef.title}</span>
                  </Tooltip>
                ),
            },
          ]}
        />
      </CardContent>
    </Card>
  );
};

AssociatedRisksCard.propTypes = {
  riskControl: PropTypes.object.isRequired,
  associatedRiskRefs: PropTypes.array.isRequired,
  lookupRisk: PropTypes.func.isRequired,
  localLoadRisks: PropTypes.func.isRequired,
  tags: PropTypes.array.isRequired,
  getRiskRatingDescription: PropTypes.func.isRequired,
  localAddAssociatedRisks: PropTypes.func.isRequired,
  localFetchTags: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  lookupRisk: lookupRiskById(state),
  tags: getTags(state),
  getRiskRatingDescription: (rating) =>
    getReferenceDataDescription(state, "RiskRating", rating, "-"),
});

export default connect(mapStateToProps, {
  localLoadRisks: loadRisks,
  localAddAssociatedRisks: addAssociatedRisks,
  localFetchTags: fetchTags,
})(AssociatedRisksCard);
