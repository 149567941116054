import { List } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import ContactDetailsReadOnly from "./ContactDetailsReadOnly";

const styles = () => ({});

class ContactListReadOnly extends Component {
  render() {
    const { contacts } = this.props;

    return (
      <>
        {contacts && contacts.length > 0 ? (
          <List dense>
            {contacts.map((contact, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <ListItem key={index} divider>
                <ContactDetailsReadOnly contact={contact} />
              </ListItem>
            ))}
          </List>
        ) : (
          <ListSubheader disableSticky>No contacts</ListSubheader>
        )}
      </>
    );
  }
}

ContactListReadOnly.propTypes = {
  contacts: PropTypes.array,
};

ContactListReadOnly.defaultProps = {
  contacts: [],
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(
  withStyles(styles)(ContactListReadOnly)
);
