import { useAuth0 } from "@auth0/auth0-react";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link } from "react-router-dom";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { required } from "redux-form-validators";
import { includeTenantParam } from "@certane/arcadia-web-components";
import { fetchTagUsages } from "../../../actions/tags";
import {
  getReferenceDataType,
  getTagUsages,
  isTagUsagesLoading,
} from "../../../reducers";
import FormError from "../../common/FormError";
import GridListing from "../../common/GridListing";
import { getRefDataMenuItems } from "../menuItems";
import WrappedSelect from "../wrapper/WrappedSelect";
import WrappedTextField from "../wrapper/WrappedTextField";

const TagForm = ({
  tagId,
  tagTypes,
  error,
  usagesLoading,
  usages,
  localFetchTagUsages,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      localFetchTagUsages(tagId, accessToken);
    })();
  }, []);

  const getUsageLink = (usage) => {
    const links = {
      Risk: `/risk/risks/${usage.id}`,
      WorkItem: `/work-items/${usage.id}`,
      Media: `/metropolis/media/${usage.id}`,
      Snippet: `/metropolis/snippet/${usage.id}`,
    };
    return _.get(links, usage.type, "/");
  };

  return (
    <form autoComplete="off">
      {error && <FormError errorMessage={error} />}
      <Field
        component={WrappedSelect}
        name="type"
        label="Type"
        validate={required({ msg: "Type is required" })}
        required
        fullWidth
        disabled
      >
        {getRefDataMenuItems(tagTypes)}
      </Field>
      <Field
        component={WrappedTextField}
        name="text"
        label="Text"
        validate={required({ msg: "Text is required" })}
        placeholder="Enter text here..."
        fullWidth
        required
      />
      <Typography variant="subtitle1" gutterBottom>
        Usages
      </Typography>
      <GridListing
        sortedData={usages}
        loading={usagesLoading}
        sortBy={{}}
        dense={false}
        updateSort={() => {
          // do nothing.
        }}
        columns={[
          {
            label: "Type",
            name: "type",
            size: 3,
            sortable: false,
            render: (usage) => <span>{usage.type}</span>,
          },
          {
            label: "Link",
            name: "link",
            size: 9,
            sortable: false,
            render: (usage) => (
              <Link
                to={includeTenantParam(getUsageLink(usage))}
                target="_blank"
              >
                {usage.id}
              </Link>
            ),
          },
        ]}
      />
    </form>
  );
};

TagForm.propTypes = {
  tagTypes: PropTypes.object.isRequired,
  error: PropTypes.string,
  tagId: PropTypes.string,
  localFetchTagUsages: PropTypes.func.isRequired,
  usages: PropTypes.array.isRequired,
  usagesLoading: PropTypes.bool.isRequired,
};

TagForm.defaultProps = {
  tagId: null,
  error: null,
};

export const TAG_FORM_NAME = "tagForm";

const form = reduxForm({
  form: TAG_FORM_NAME,
});
const reduxFormSelector = formValueSelector(TAG_FORM_NAME);

const mapStateToProps = (state) => ({
  tagTypes: getReferenceDataType(state, "TagType"),
  tagId: reduxFormSelector(state, "id"),
  usagesLoading: isTagUsagesLoading(state),
  usages: getTagUsages(state),
});

const reduxData = connect(mapStateToProps, {
  localFetchTagUsages: fetchTagUsages,
});

export default compose(form, reduxData)(TagForm);
