import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  heading: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    marginRight: 10,
  },
});
const PageHeading = ({ classes, icon: PageIcon, heading }) =>
  PageIcon ? (
    <Typography className={classes.heading} variant="h5" data-cy={heading}>
      <Avatar className={classes.avatar}>
        <PageIcon />
      </Avatar>
      {heading}
    </Typography>
  ) : (
    <Typography className={classes.heading} variant="h5" data-cy={heading}>
      {heading}
    </Typography>
  );

PageHeading.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.any,
  heading: PropTypes.any.isRequired,
};

PageHeading.defaultProps = {
  icon: null,
};

export default withStyles(styles)(PageHeading);
