import React from "react";
import PropTypes from "prop-types";
import DebouncedTextField from "../common/DebouncedTextField";
import FiltersBar from "../common/FiltersBar";

const BusinessServiceAreasFilters = ({ filter, updateFilter, ...rest }) => (
  <FiltersBar {...rest}>
    <DebouncedTextField
      value={filter.textSearch || ""}
      onChange={(text) => updateFilter("textSearch", text)}
      placeholder="Type to filter"
    />
  </FiltersBar>
);

BusinessServiceAreasFilters.propTypes = {
  filter: PropTypes.object.isRequired,
  updateFilter: PropTypes.func.isRequired,
};

export default BusinessServiceAreasFilters;
