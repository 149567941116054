import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Card";
import classNames from "classnames";
import PropTypes from "prop-types";
import tinycolor from "tinycolor2";

const styles = (theme) => ({
  paper: {
    borderLeft: "4px solid",
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(8),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(3),
  },
  closedBackground: {
    backgroundColor: tinycolor(theme.palette.statusColors.CLOSED).setAlpha(0.1),
  },
  statusNew: {
    borderColor: theme.palette.statusColors.NEW,
  },
  statusOpen: {
    borderColor: theme.palette.statusColors.OPEN,
  },
  statusExternalReview: {
    borderColor: theme.palette.statusColors.EXTERNAL_REVIEW,
  },
  statusPendingSubmitter: {
    borderColor: theme.palette.statusColors.PENDING_SUBMITTER,
  },
  statusPendingCounterpartySignature: {
    borderColor: theme.palette.statusColors.PENDING_COUNTERPARTY_SIGNATURE,
  },
  statusOnHold: {
    borderColor: theme.palette.statusColors.ON_HOLD,
  },
  statusMonitoring: {
    borderColor: theme.palette.statusColors.MONITORING,
  },
  statusClosed: {
    borderColor: theme.palette.statusColors.CLOSED,
  },
  statusUnknown: {
    borderColor: theme.palette.statusColors.UNKNOWN,
  },
});

const statusClass = (classes, status, isCloseAction) =>
  classNames({
    [classes.paper]: true,
    [classes.closedBackground]: isCloseAction,
    [classes.statusNew]: status === "NEW",
    [classes.statusOpen]: status === "OPEN",
    [classes.statusExternalReview]: status === "EXTERNAL_REVIEW",
    [classes.statusPendingSubmitter]: status === "PENDING_SUBMITTER",
    [classes.statusPendingCounterpartySignature]:
      status === "PENDING_COUNTERPARTY_SIGNATURE",
    [classes.statusOnHold]: status === "ON_HOLD",
    [classes.statusMonitoring]: status === "MONITORING",
    [classes.statusClosed]: status === "CLOSED",
    [classes.statusUnknown]: status === "UNKNOWN",
  });

const Entry = ({ classes, children, status, isCloseAction }) => (
  <Paper
    square
    className={statusClass(classes, status, isCloseAction)}
    elevation={0}
  >
    {children}
  </Paper>
);

Entry.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  status: PropTypes.oneOf([
    "NEW",
    "OPEN",
    "EXTERNAL_REVIEW",
    "PENDING_SUBMITTER",
    "PENDING_COUNTERPARTY_SIGNATURE",
    "ON_HOLD",
    "MONITORING",
    "CLOSED",
    "UNKNOWN",
  ]).isRequired,
  isCloseAction: PropTypes.bool.isRequired,
};

export default withStyles(styles)(Entry);
