import { withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { connect } from "react-redux";
import { compose } from "redux";
import { formValueSelector } from "redux-form";
import { appliesByRegion } from "../../util/workItemTypeUtils";

const styles = (theme) => ({
  intro: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

const FormHeader = ({
  classes,
  workItemDefinition,
  selectedEntityRelationship,
}) => {
  const [availableHelpTexts, setAvailableHelpTexts] = useState([]);

  useEffect(() => {
    const filteredHelpTexts = workItemDefinition.helpTextAssignments.filter(
      (helpTextAssignment) =>
        appliesByRegion(
          { entityRelationship: selectedEntityRelationship },
          helpTextAssignment
        )
    );
    setAvailableHelpTexts(filteredHelpTexts);
  }, [selectedEntityRelationship]);

  return (
    <div className={classes.intro} data-cy="header">
      {availableHelpTexts.map((helpText) => (
        <ReactMarkdown key={helpText.helpText} source={helpText.helpText} />
      ))}
    </div>
  );
};

FormHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  workItemDefinition: PropTypes.object.isRequired,
  selectedEntityRelationship: PropTypes.object,
};

FormHeader.defaultProps = {
  selectedEntityRelationship: null,
};

const mapStateToProps = (state, ownProps) => {
  const reduxFormSelector = formValueSelector(ownProps.formName);
  return {
    selectedEntityRelationship: reduxFormSelector(state, "entityRelationship"),
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(FormHeader);
