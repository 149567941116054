import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import SimpleChips from "../../../common/SimpleChips";
import Scorecard from "../../../common/Scorecard";
import { getLabels, getReferenceDataDescription } from "../../../../reducers";
import MultilineText from "../../../common/MultilineText";

const ComplianceDetails = ({
  fieldLabels,
  workItem,
  getDescription,
  title,
}) => (
  <>
    <Typography variant="body1" style={{ margin: "20px 0" }}>
      {title}
    </Typography>

    <Scorecard label={fieldLabels.labels.notes}>
      <MultilineText text={workItem.notes} defaultText="-" />
    </Scorecard>
    <Grid container>
      <Grid item xs={12} md={4}>
        <Scorecard
          label={fieldLabels.nestedTypes.compliance.labels.sourceTypes}
        >
          <SimpleChips
            blankLabel="-"
            values={
              workItem.compliance &&
              workItem.compliance.sourceTypes.map((type) =>
                getDescription("ComplianceSourceType", type)
              )
            }
          />
        </Scorecard>
      </Grid>
      <Grid item xs={12} md={4}>
        {workItem.compliance &&
          workItem.compliance.complianceSources &&
          workItem.compliance.complianceSources.length > 0 && (
            <Scorecard
              label={
                fieldLabels.nestedTypes.compliance.labels.complianceSources
              }
            >
              <SimpleChips
                blankLabel="-"
                values={
                  workItem.compliance &&
                  workItem.compliance.complianceSources.map((s) =>
                    getDescription("ComplianceSource", s)
                  )
                }
              />
            </Scorecard>
          )}
      </Grid>
      <Grid item xs={12} md={4}>
        <Scorecard label={fieldLabels.nestedTypes.compliance.labels.reference}>
          {(workItem.compliance && workItem.compliance.reference) || "-"}
        </Scorecard>
      </Grid>
    </Grid>
    <Grid container>
      <Grid item xs={12} md={4}>
        <Scorecard label={fieldLabels.nestedTypes.compliance.labels.regulator}>
          {getDescription(
            "Regulator",
            workItem.compliance && workItem.compliance.regulator
          )}
        </Scorecard>
      </Grid>
      <Grid item xs={12} md={4}>
        <Scorecard label={fieldLabels.nestedTypes.compliance.labels.service}>
          {getDescription(
            "Service",
            workItem.compliance && workItem.compliance.service
          )}
        </Scorecard>
      </Grid>
      <Grid item xs={12} md={4}>
        <Scorecard label={fieldLabels.nestedTypes.compliance.labels.product}>
          {getDescription(
            "ComplianceProduct",
            workItem.compliance && workItem.compliance.product
          )}
        </Scorecard>
      </Grid>
    </Grid>
  </>
);

ComplianceDetails.propTypes = {
  fieldLabels: PropTypes.object.isRequired,
  workItem: PropTypes.object.isRequired,
  getDescription: PropTypes.func.isRequired,
  title: PropTypes.string,
};

ComplianceDetails.defaultProps = {
  title: null,
};

const mapStateToProps = (state) => ({
  getDescription: (type, id) =>
    getReferenceDataDescription(state, type, id, "-"),
  fieldLabels: getLabels(state).COMPLIANCE_TASK,
});

export default connect(mapStateToProps)(ComplianceDetails);
