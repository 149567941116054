import { useAuth0 } from "@auth0/auth0-react";
import { useTenant } from "@certane/arcadia-web-components";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import ListItemText from "@material-ui/core/ListItemText";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import { mdiLinkVariant } from "@mdi/js";
import Icon from "@mdi/react";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { required } from "redux-form-validators";
import { fetchTags } from "../../../../actions/tags";
import consequenceRatingImage from "../../../../images/consequence-rating.png";
import {
  getReferenceDataDescription,
  getReferenceDataType,
  getTags,
} from "../../../../reducers";
import risksApi from "../../../../services/api/risks";
import { yesNoOptions } from "../../../../util/radioOptions";
import Avatar from "../../../common/Avatar.tsx";
import DebouncedTextField from "../../../common/DebouncedTextField";
import HelpPopover from "../../../common/HelpPopover";
import MultiSelectDropdown from "../../../common/MultiSelectDropdown";
import { getRefDataMenuItems, tagsToOptions } from "../../../forms/menuItems";
import ComponentField from "../../../forms/wrapper/ComponentField";
import WrappedChipListPicker from "../../../forms/wrapper/WrappedChipListPicker";
import WrappedDatePicker from "../../../forms/wrapper/WrappedDatePicker";
import WrappedRadioGroup from "../../../forms/wrapper/WrappedRadioGroup";
import WrappedSelect from "../../../forms/wrapper/WrappedSelect";
import WrappedSelectChips from "../../../forms/wrapper/WrappedSelectChips";
import WrappedTags from "../../../forms/wrapper/WrappedTags";
import WrappedTextField from "../../../forms/wrapper/WrappedTextField";
import WarningText from "../../../WarningText";

const useStyles = makeStyles((theme) => ({
  consequenceRatingPopup: {
    width: "100%",
    height: "100%",
  },
  riskRatingChip: {
    backgroundColor: theme.palette.grey[300],
    padding: `${theme.spacing(1) / 4}px ${theme.spacing(1)}px`,
    borderRadius: "16px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: "100%",
    whiteSpace: "nowrap",
    marginRight: theme.spacing(1) / 2,
  },
  nowrap: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  linkText: {
    cursor: "pointer",
    textDecoration: "none",
    color: theme.palette.swatch.link,
  },
}));

const linkIcon = () => <Icon size={1} path={mdiLinkVariant} />;

const IncidentAssessmentFields = ({
  workItem,
  fieldLabels,
  scrollToComponent,
  tags,
  localFetchTags,
  customisations,
  complaintOutcomes,
  consequenceRatings,
  reportableSituations,
  reportableSituation,
  reportProvided,
  getRiskRatingDescription,
  change,
  untouch,
  formName,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { tenant } = useTenant();
  const classes = useStyles();

  useEffect(() => {
    getAccessTokenSilently().then((accessToken) => localFetchTags(accessToken));
  }, []);

  useEffect(() => {
    change("incidentAssessment.reportable", !!reportableSituation);
  }, [reportableSituation]);

  const asyncRiskOptionsFetch = async (
    pickerFilter,
    pagination,
    abortController
  ) => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = {
      ...pickerFilter,
      limit: pagination.pageSize,
      offset: pagination.offset,
      orderByField: "title",
    };
    return risksApi.search(searchParameters, abortController, accessToken);
  };

  const riskRegisterOptions = tagsToOptions(
    tags.filter((tag) => tag.type === "RISK_REGISTER")
  );

  return (
    <>
      <ComponentField
        component={WrappedSelect}
        name="incidentAssessment.riskConsequenceRating"
        label={
          fieldLabels.nestedTypes.incidentAssessment.labels
            .riskConsequenceRating
        }
        fullWidth
        nullLabel="Not yet rated"
        helperText={
          <>
            <span>Get help with </span>
            <HelpPopover linkText="consequence ratings">
              <img
                className={classes.consequenceRatingPopup}
                src={consequenceRatingImage}
                alt="Consequence rating matrix"
              />
            </HelpPopover>
          </>
        }
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      >
        {getRefDataMenuItems(consequenceRatings)}
      </ComponentField>
      {tenant.featureFlags.risk && (
        <ComponentField
          name="associatedRisks"
          component={WrappedChipListPicker}
          datasource={asyncRiskOptionsFetch}
          label={fieldLabels.labels.associatedRisks}
          fullWidth
          clearable
          toOption={(risk) => ({ label: risk.title, id: risk.id, risk })}
          fromOption={(option) => option.risk}
          addIcon={linkIcon}
          renderIcon={(risk, size) => (
            <Avatar
              name={(risk.title || "Unknown").charAt(0)}
              size={size}
              round
            />
          )}
          renderLabel={(risk) => (
            <ListItemText
              primary={
                <Typography className={classes.nowrap} title={risk.title}>
                  {risk.title}
                </Typography>
              }
              secondary={
                <span>
                  <span className={classes.riskRatingChip}>
                    {getRiskRatingDescription(risk.riskRating)}
                  </span>
                  {risk.owner && risk.owner.name}
                </span>
              }
            />
          )}
          renderFilter={(onChange, filter) => (
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item xs={12} md={6}>
                <DebouncedTextField
                  value={filter.textSearch}
                  onChange={(text) => onChange("textSearch", text)}
                  placeholder="Type to filter..."
                  margin="none"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <div>
                  <FormLabel>Risk register</FormLabel>
                  <div>
                    <MultiSelectDropdown
                      value={filter.riskRegisters || []}
                      options={riskRegisterOptions}
                      onChange={(value) => onChange("riskRegisters", value)}
                      closeOnChange
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          )}
          customisations={customisations}
          change={change}
          untouch={untouch}
          formName={formName}
        />
      )}
      <ComponentField
        component={WrappedTags}
        name="incidentAssessment.obligationsImpacted"
        label={
          fieldLabels.nestedTypes.incidentAssessment.labels.obligationsImpacted
        }
        tags={tags}
        tagType="REGULATOR"
        fullWidth
        isMulti
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      />
      <ComponentField
        name="incidentAssessment.breach"
        component={WrappedRadioGroup}
        label={fieldLabels.nestedTypes.incidentAssessment.labels.breach}
        options={yesNoOptions}
        row
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
        fullWidth
      />
      <ComponentField
        name="incidentAssessment.systemic"
        component={WrappedRadioGroup}
        label={fieldLabels.nestedTypes.incidentAssessment.labels.systemic}
        options={yesNoOptions}
        row
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
        fullWidth
      />
      <ComponentField
        component={WrappedSelect}
        name="incidentAssessment.reportableSituation"
        label={
          fieldLabels.nestedTypes.incidentAssessment.labels.reportableSituation
        }
        fullWidth
        nullLabel="Non reportable"
        displayNullLabelAsPlaceholder
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      >
        {getRefDataMenuItems(reportableSituations)}
      </ComponentField>
      {!!reportableSituation && (
        <WarningText
          text={
            <Typography gutterBottom variant="caption">
              You must complete a{" "}
              <Link
                className={classes.linkText}
                onClick={() => scrollToComponent("INCIDENT_BREACH_REPORT")}
              >
                breach report
              </Link>{" "}
              for this incident.
            </Typography>
          }
        />
      )}
      <ComponentField
        name="incidentAssessment.reportable"
        component={WrappedRadioGroup}
        label={fieldLabels.nestedTypes.incidentAssessment.labels.reportable}
        options={yesNoOptions}
        row
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
        fullWidth
      />

      {reportableSituation &&
        (workItem.category === "INCIDENT_ADVISER_ADVISER_SERVICE_FEE" ||
          workItem.category === "INCIDENT_OTHER_ACL_ACTIVITY" ||
          workItem.category === "INCIDENT_OTHER_AFS_LICENSEE_ACTIVITY") && (
          <ComponentField
            fullWidth
            component={WrappedRadioGroup}
            name="incidentAssessment.reportProvided"
            label={
              fieldLabels.nestedTypes.incidentAssessment.labels.reportProvided
            }
            options={yesNoOptions}
            row
            validate={required({
              msg: `${fieldLabels.nestedTypes.incidentAssessment.labels.reportProvided} is required`,
            })}
            required
            customisations={customisations}
            change={change}
            untouch={untouch}
            formName={formName}
          />
        )}
      {reportProvided && (
        <>
          <ComponentField
            disableFuture
            component={WrappedDatePicker}
            fullWidth
            name="incidentAssessment.dateReportProvided"
            label={
              fieldLabels.nestedTypes.incidentAssessment.labels
                .dateReportProvided
            }
            validate={required({
              msg: `${fieldLabels.nestedTypes.incidentAssessment.labels.dateReportProvided} is required`,
            })}
            required
            data-cy={
              fieldLabels.nestedTypes.incidentAssessment.labels
                .dateReportProvided
            }
            customisations={customisations}
            change={change}
            untouch={untouch}
            formName={formName}
          />
          <WarningText text="Ensure evidence of report provided is uploaded." />
        </>
      )}

      <ComponentField
        name="incidentAssessment.report"
        component={WrappedTextField}
        label={fieldLabels.nestedTypes.incidentAssessment.labels.report}
        fullWidth
        multiline
        rowsMax={10}
        rows={2}
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      />
      {(reportableSituation === "SIGNIFICANT" ||
        reportableSituation === "LIKELY_SIGNIFICANT") && (
        <ComponentField
          name="incidentAssessment.reasonBreachIsSignificant"
          component={WrappedTextField}
          label={
            fieldLabels.nestedTypes.incidentAssessment.labels
              .reasonBreachIsSignificant
          }
          fullWidth
          customisations={customisations}
          change={change}
          untouch={untouch}
          formName={formName}
        />
      )}
      {reportableSituation === "OTHER" && (
        <ComponentField
          name="incidentAssessment.significanceDetermination"
          component={WrappedTextField}
          label={
            fieldLabels.nestedTypes.incidentAssessment.labels
              .significanceDetermination
          }
          fullWidth
          multiline
          rowsMax={10}
          rows={2}
          customisations={customisations}
          change={change}
          untouch={untouch}
          formName={formName}
        />
      )}
      {reportableSituation === "OVER_30_DAYS" && (
        <ComponentField
          name="incidentAssessment.investigationOutcome"
          component={WrappedTextField}
          label={
            fieldLabels.nestedTypes.incidentAssessment.labels
              .investigationOutcome
          }
          fullWidth
          multiline
          rowsMax={10}
          rows={2}
          customisations={customisations}
          change={change}
          untouch={untouch}
          formName={formName}
          helperText="Detail the outcome of the investigation. If there are no reasonable grounds to believe a reportable situation has arisen, you must create a Regulator Correspondence - Reported breach - request for more information or update task and report the update to ASIC."
        />
      )}
      <ComponentField
        disableFuture
        maxDateMessage="Must be in the past"
        component={WrappedDatePicker}
        fullWidth
        name="incidentAssessment.rectificationDate"
        label={
          fieldLabels.nestedTypes.incidentAssessment.labels.rectificationDate
        }
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      />
      <ComponentField
        name="incidentAssessment.learnings"
        component={WrappedTextField}
        label={fieldLabels.nestedTypes.incidentAssessment.labels.learnings}
        fullWidth
        multiline
        rowsMax={10}
        rows={2}
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      />
      <ComponentField
        name="incidentAssessment.complaintOutcomes"
        component={WrappedSelectChips}
        options={complaintOutcomes.ids}
        label={
          fieldLabels.nestedTypes.incidentAssessment.labels.complaintOutcomes
        }
        fullWidth
        toOption={(id) => ({
          label: complaintOutcomes.values[id].description,
          value: id,
        })}
        fromOption={(option) => option.value}
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      />
    </>
  );
};

IncidentAssessmentFields.propTypes = {
  workItem: PropTypes.object,
  customisations: PropTypes.array,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  scrollToComponent: PropTypes.func.isRequired,

  // redux
  policyLegislation: PropTypes.object.isRequired,
  complaintOutcomes: PropTypes.object.isRequired,
  consequenceRatings: PropTypes.object.isRequired,
  reportableSituations: PropTypes.object.isRequired,
  reportableSituation: PropTypes.string,
  reportProvided: PropTypes.bool,
  getRiskRatingDescription: PropTypes.func.isRequired,
  tags: PropTypes.array.isRequired,
  localFetchTags: PropTypes.func.isRequired,
};

IncidentAssessmentFields.defaultProps = {
  customisations: [],
  reportableSituation: undefined,
};

const mapStateToProps = (state, { formName }) => {
  const reduxFormSelector = formValueSelector(formName);
  return {
    policyLegislation: getReferenceDataType(state, "PolicyLegislation"),
    complaintOutcomes: getReferenceDataType(state, "ComplaintOutcome"),
    consequenceRatings: getReferenceDataType(state, "RiskConsequenceRating"),
    reportableSituations: getReferenceDataType(state, "ReportableSituation"),
    reportableSituation: reduxFormSelector(
      state,
      "incidentAssessment.reportableSituation"
    ),
    reportProvided: reduxFormSelector(
      state,
      "incidentAssessment.reportProvided"
    ),
    tags: getTags(state),
    getRiskRatingDescription: (rating) =>
      getReferenceDataDescription(state, "RiskRating", rating, "-"),
  };
};

export default connect(mapStateToProps, {
  localFetchTags: fetchTags,
})(IncidentAssessmentFields);
