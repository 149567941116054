import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { getLabels } from "../../../reducers";
import { scrollToFirstInvalidInput } from "../../../util/formHelpers";
import FormError from "../../common/FormError";
import RiskControlDetailFields from "./RiskControlDetailFields";

const EditRiskControlForm = ({ error, fieldLabels }) => (
  <form autoComplete="off">
    {error && <FormError errorMessage={error} />}
    <RiskControlDetailFields fieldLabels={fieldLabels} />
  </form>
);

EditRiskControlForm.propTypes = {
  error: PropTypes.string,
  fieldLabels: PropTypes.object.isRequired,
};

EditRiskControlForm.defaultProps = {
  error: null,
};

const mapStateToProps = (state) => ({
  fieldLabels: getLabels(state).RiskControl,
});

export const EDIT_RISK_CONTROL_FORM_NAME = "editRiskControlForm";

const form = reduxForm({
  form: EDIT_RISK_CONTROL_FORM_NAME,
  onSubmitFail: (errors) => scrollToFirstInvalidInput(errors),
});
const reduxData = connect(mapStateToProps, {});

export default compose(form, reduxData)(EditRiskControlForm);
