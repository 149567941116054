import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { loadRisks } from "../../actions/risks";
import {
  getReferenceDataType,
  getRisks,
  getRiskSearchPagination,
  isLoading,
} from "../../reducers";
import GridListing from "../common/GridListing";
import SimpleChip from "../common/SimpleChip";
import Pagination from "../common/Pagination";
import { useAuth0 } from "@auth0/auth0-react";

const styles = () => ({
  primaryText: {
    display: "block",
    fontSize: "0.875rem",
    fontWeight: "600",
  },
  nowrap: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  textWithIcon: {
    display: "flex",
  },
  iconSm: {
    fontSize: 16,
  },
});

function displayEnum(defs, value) {
  return value ? defs.values[value].description : "";
}

const RiskListing = ({
  classes,
  history,
  filter,
  sortBy,
  updateSort,
  clearFilter,
  risks,
  loading,
  riskCategories,
  riskAppetites,
  riskRatings,
  pagination,
  localLoadRisks,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const refreshData = async (offset, pageSize) => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = {
      ...filter,
      orderByField:
        sortBy.direction === "asc" ? sortBy.field : `-${sortBy.field}`,
      limit: pageSize || pagination.pageSize,
      offset,
    };
    return localLoadRisks(searchParameters, accessToken);
  };

  useEffect(() => {
    refreshData(0);
  }, [filter, sortBy]);

  const handlePrevious = () => {
    refreshData(pagination.previousOffset);
  };

  const handleNext = () => {
    refreshData(pagination.nextOffset);
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <GridListing
        data-cy="table"
        sortedData={risks}
        loading={loading}
        sortBy={sortBy}
        updateSort={(field, direction) =>
          updateSort({
            field,
            direction,
          })
        }
        clearFilter={clearFilter}
        onClick={(risk) => history.push(`/risk/risks/${risk.id}`)}
        columns={[
          {
            label: "Title",
            name: "title",
            size: 3,
            sortable: true,
            render: (risk) => (
              <>
                <Typography
                  className={classes.nowrap}
                  title={risk.title}
                  data-cy={risk.title}
                >
                  {risk.title}
                </Typography>
                <Typography title={risk.friendlyId} variant="caption">
                  # {risk.friendlyId}
                </Typography>
              </>
            ),
          },
          {
            label: "Risk register",
            name: "riskRegisters",
            size: 3,
            sortable: true,
            render: (risk) =>
              risk.riskRegisters.map((tag) => (
                <SimpleChip key={tag.id} label={tag.text} />
              )),
          },
          {
            label: "Risk rating",
            name: "riskRating",
            size: 1,
            sortable: true,
            render: (risk) => (
              <span>{displayEnum(riskRatings, risk.riskRating)}</span>
            ),
          },
          {
            label: "Risk category",
            name: "category",
            size: 2,
            sortable: true,
            render: (risk) => (
              <span>{displayEnum(riskCategories, risk.category)}</span>
            ),
          },
          {
            label: "Risk owner",
            name: "owner",
            size: 1,
            sortable: true,
            render: (risk) => <span>{risk.owner ? risk.owner.name : ""}</span>,
          },
          {
            label: "Risk category appetite",
            name: "appetite",
            size: 2,
            sortable: true,
            render: (risk) => (
              <span>{displayEnum(riskAppetites, risk.appetite)}</span>
            ),
          },
        ]}
      />
      <Pagination
        pagination={pagination}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
      />
    </>
  );
};

RiskListing.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  sortBy: PropTypes.object.isRequired,
  updateSort: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired,

  // redux
  loading: PropTypes.bool.isRequired,
  risks: PropTypes.array.isRequired,
  riskCategories: PropTypes.object.isRequired,
  riskAppetites: PropTypes.object.isRequired,
  riskRatings: PropTypes.object.isRequired,
  localLoadRisks: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  loading: isLoading(state),
  risks: getRisks(state),
  riskCategories: getReferenceDataType(state, "RiskCategory"),
  riskAppetites: getReferenceDataType(state, "RiskAppetite"),
  riskRatings: getReferenceDataType(state, "RiskRating"),
  pagination: getRiskSearchPagination(state),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    localLoadRisks: loadRisks,
  })
)(RiskListing);
