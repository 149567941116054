import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router";
import Error404 from "../../components/error/Error404";

const RedirectWithState = () => {
  const { target } = useParams();
  const [redirectTo, setRedirectTo] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const decoded = JSON.parse(Base64.decode(target));
    if (!decoded.pathname) {
      setError({ status: 404 });
    }
    setRedirectTo(decoded);
  }, []);

  if (error && error.status === 404) {
    return <Error404 />;
  }

  return redirectTo ? <Redirect to={redirectTo} /> : <div>Loading...</div>;
};

RedirectWithState.propTypes = {};

export default RedirectWithState;
