import { useAuth0 } from "@auth0/auth0-react";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { numericality } from "redux-form-validators";
import { fetchTags } from "../../../../actions/tags";
import { getReferenceDataOptions, getTags } from "../../../../reducers";
import ComponentField from "../../../forms/wrapper/ComponentField";
import WrappedDatePicker from "../../../forms/wrapper/WrappedDatePicker";
import WrappedSelect from "../../../forms/wrapper/WrappedSelect";
import WrappedTags from "../../../forms/wrapper/WrappedTags";
import WrappedTextField from "../../../forms/wrapper/WrappedTextField";

const ContractDetailsFields = ({
  fieldLabels,
  executionMethodOptions,
  tags,
  localFetchTags,
  customisations,
  change,
  untouch,
  formName,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    getAccessTokenSilently().then((accessToken) => localFetchTags(accessToken));
  }, []);

  return (
    <>
      <ComponentField
        name="contract.dateSigned"
        component={WrappedDatePicker}
        label={fieldLabels.nestedTypes.contract.labels.dateSigned}
        fullWidth
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
        data-cy="dateSigned"
      />
      <ComponentField
        name="contract.executionMethod"
        component={WrappedSelect}
        label={fieldLabels.nestedTypes.contract.labels.executionMethod}
        fullWidth
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
        data-cy="executionMethod"
      >
        {executionMethodOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </ComponentField>
      <ComponentField
        name="contract.counterParties"
        component={WrappedTags}
        label={fieldLabels.nestedTypes.contract.labels.counterParties}
        tags={tags}
        tagType="COUNTERPARTY"
        placeholder="Enter counterparties..."
        fullWidth
        isMulti
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
        data-cy="counterParties"
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <ComponentField
            component={WrappedTextField}
            name="contract.termYear"
            label="Term"
            data-cy="years"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">years</InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: true,
            }}
            min={0}
            validate={numericality({
              ">=": 0,
              int: true,
              allowBlank: true,
            })}
            fullWidth
            customisations={customisations}
            change={change}
            untouch={untouch}
            formName={formName}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ComponentField
            component={WrappedTextField}
            name="contract.termMonth"
            label=" "
            data-cy="month"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">months</InputAdornment>
              ),
            }}
            min={0}
            max={11}
            validate={numericality({
              ">=": 0,
              "<": 12,
              int: true,
              allowBlank: true,
            })}
            fullWidth
            customisations={customisations}
            change={change}
            untouch={untouch}
            formName={formName}
          />
        </Grid>
      </Grid>
      <ComponentField
        name="contract.expiryDate"
        component={WrappedDatePicker}
        label={fieldLabels.nestedTypes.contract.labels.expiryDate}
        fullWidth
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
        data-cy="expiryDate"
      />
      <ComponentField
        label={fieldLabels.nestedTypes.contract.labels.agreementSummary}
        name="contract.agreementSummary"
        component={WrappedTextField}
        placeholder="Short description of the content of the agreement - can be taken from the recitals"
        fullWidth
        rows={2}
        multiline
        rowsMax={10}
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
        data-cy="agreementSummary"
      />
      <ComponentField
        label={fieldLabels.nestedTypes.contract.labels.keyTerms}
        name="contract.keyTerms"
        component={WrappedTextField}
        fullWidth
        rows={2}
        multiline
        rowsMax={10}
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
        data-cy="keyTerms"
      />
      <ComponentField
        name="contract.nextReviewDate"
        component={WrappedDatePicker}
        label={fieldLabels.nestedTypes.contract.labels.nextReviewDate}
        fullWidth
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
        data-cy="nextReviewDate"
      />
      <ComponentField
        name="contract.lastReviewDate"
        component={WrappedDatePicker}
        label={fieldLabels.nestedTypes.contract.labels.lastReviewDate}
        fullWidth
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
        data-cy="lastReviewDate"
      />
    </>
  );
};

ContractDetailsFields.propTypes = {
  customisations: PropTypes.array,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  fieldLabels: PropTypes.object.isRequired,

  // redux
  executionMethodOptions: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  localFetchTags: PropTypes.func.isRequired,
};

ContractDetailsFields.defaultProps = {
  customisations: [],
};

const mapStateToProps = (state) => ({
  executionMethodOptions: getReferenceDataOptions(state, "ExecutionMethod"),
  tags: getTags(state),
});

export default connect(mapStateToProps, {
  localFetchTags: fetchTags,
})(ContractDetailsFields);
