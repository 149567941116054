import { useAuth0 } from "@auth0/auth0-react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import ErrorIcon from "@material-ui/icons/Error";
import WarningIcon from "@material-ui/icons/Warning";
import classNames from "classnames";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactHtmlParser from "react-html-parser";
import snippetsApi from "../../services/api/snippets";
import { publishToastInfo } from "../../services/toasts";
import logger from "../../util/logger";
import UrlCopyField from "./UrlCopyField";

const sourceLabels = {
  TEXT_HTML: "HTML",
  JSON: "JSON",
};

const RENDERED_TAB = "rendered";
const SOURCE_TAB = "source";
const TEMPLATE_TAB = "template";
const INTEGRATION_TAB = "integration";

const useStyles = makeStyles((theme) => ({
  fixedBox: {
    height: "300px",
    flexGrow: 1,
    overflow: "auto",
    padding: "10px",
    backgroundColor: theme.palette.background.default,
  },
  flexBox: {
    maxHeight: "200px",
    flexGrow: 1,
    overflow: "auto",
    padding: "10px",
    backgroundColor: theme.palette.background.default,
  },
  error: {
    color: theme.palette.error.main,
  },
  tabs: {
    marginLeft: "10px",
    marginBottom: "20px",
  },
  container: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  copyButtons: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  copyButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
  },
  warningChip: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    backgroundColor: theme.content.warning,
    color: theme.content.contrastText,
  },
  warningAvatar: {
    backgroundColor: theme.content.warning,
    color: theme.content.contrastText,
  },
  errorChip: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    backgroundColor: theme.content.error,
    color: theme.content.contrastText,
  },
  errorAvatar: {
    backgroundColor: theme.content.error,
    color: theme.content.contrastText,
  },
}));

function strip(html) {
  const tmp = document.createElement("div");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

const SnippetSourceView = ({
  snippet,
  showTemplate,
  product,
  fixedHeight,
  fieldLabels,
  renderVersion,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(
    renderVersion.contentType === "TEXT_HTML" ? RENDERED_TAB : SOURCE_TAB
  );
  const [resolvedData, setResolvedData] = useState(null);
  const [resolveError, setResolvedError] = useState(null);
  const [resolveWarnings, setResolvedWarnings] = useState(null);
  const unmounted = useRef(false);

  const getRenderedData = async () => {
    const accessToken = await getAccessTokenSilently();
    setResolvedData(null);
    setResolvedError(null);

    let productId = product ? product.id : null;

    if (snippet.singleProductNamespaced) {
      const singleProduct =
        snippet.entityRelationship.financialProducts.length > 0
          ? snippet.entityRelationship.financialProducts[0]
          : null;
      productId = singleProduct ? singleProduct.id : productId;
    }

    snippetsApi
      .render(snippet.id, productId, renderVersion.id, accessToken)
      .then((snippetView) => {
        if (!unmounted.current) {
          const rData = _.isString(snippetView.content)
            ? snippetView.content
            : JSON.stringify(snippetView.content, null, 2);

          setResolvedData(rData);
          setResolvedWarnings(snippetView.warnings);
        }
      })
      .catch((error) => !unmounted.current && setResolvedError(error.message));
  };

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  useEffect(() => {
    if (snippet) {
      getRenderedData();
    }
  }, [renderVersion]);

  const onCopy = () => {
    publishToastInfo("Copied to clipboard");
  };

  const handleChange = (event, aTab) => {
    setActiveTab(aTab);
  };

  let content = null;
  let textToCopy = null;

  switch (activeTab) {
    case RENDERED_TAB:
      if (resolveError) {
        content = <pre className={classes.error}>{resolveError}</pre>;
      } else if (resolvedData) {
        content =
          renderVersion.contentType === "TEXT_HTML"
            ? ReactHtmlParser(resolvedData)
            : null;
      } else {
        content = "Loading...";
      }
      textToCopy =
        renderVersion.contentType === "TEXT_HTML"
          ? strip(resolvedData)
          : resolvedData;
      break;
    case SOURCE_TAB:
      if (resolveError) {
        content = <pre className={classes.error}>{resolveError}</pre>;
      } else if (resolvedData) {
        content = <pre>{resolvedData}</pre>;
      } else {
        content = "Loading...";
      }
      textToCopy = resolvedData;
      break;
    case INTEGRATION_TAB:
      content = (
        <>
          <UrlCopyField
            label={fieldLabels.labels.apiEndpoint}
            url={snippet.apiEndpoint}
          />
          {renderVersion.contentType === "TEXT_HTML" && (
            <UrlCopyField
              label={fieldLabels.labels.apiContentEndpoint}
              url={snippet.apiContentEndpoint}
            />
          )}
        </>
      );
      break;
    case TEMPLATE_TAB:
      content = <pre>{renderVersion.data}</pre>;
      textToCopy = renderVersion.data;
      break;
    default:
      logger.warn("Unknown active tab!!");
      break;
  }

  return (
    <>
      <Tabs
        className={classes.tabs}
        value={activeTab}
        onChange={handleChange}
        textColor="primary"
      >
        {renderVersion.contentType === "TEXT_HTML" && (
          <Tab label="Rendered" value={RENDERED_TAB} />
        )}
        <Tab
          label={sourceLabels[renderVersion.contentType]}
          value={SOURCE_TAB}
        />
        {showTemplate && <Tab label="Template" value={TEMPLATE_TAB} />}
      </Tabs>
      <div className={classes.container}>
        <Paper
          elevation={2}
          className={classNames({
            [classes.flexBox]: !fixedHeight,
            [classes.fixedBox]: fixedHeight,
          })}
        >
          {content}
          {textToCopy && (
            <CopyToClipboard
              className={classes.copyButton}
              text={textToCopy}
              onCopy={onCopy}
            >
              <Button size="small" color="primary">
                Copy
              </Button>
            </CopyToClipboard>
          )}
        </Paper>
      </div>
      {resolveError && (
        <Chip
          className={classes.errorChip}
          avatar={
            <Avatar className={classes.errorAvatar}>
              <ErrorIcon />
            </Avatar>
          }
          label={resolveError}
        />
      )}
      {resolveWarnings &&
        resolveWarnings.map((warning, index) => (
          <Chip
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className={classes.warningChip}
            avatar={
              <Avatar className={classes.warningAvatar}>
                <WarningIcon />
              </Avatar>
            }
            label={warning}
          />
        ))}
    </>
  );
};

SnippetSourceView.propTypes = {
  snippet: PropTypes.object.isRequired,
  product: PropTypes.object,
  showTemplate: PropTypes.bool.isRequired,
  fixedHeight: PropTypes.bool,
  fieldLabels: PropTypes.object.isRequired,
  renderVersion: PropTypes.object.isRequired,
};

SnippetSourceView.defaultProps = {
  product: null,
  fixedHeight: false,
};

export default SnippetSourceView;
