import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { scrollToFirstInvalidInput } from "../../../../util/formHelpers";
import FormError from "../../../common/FormError";
import QuestionFields from "./QuestionFields";

export const QUESTION_FORM_NAME = "QUESTION_FORM_NAME";

const styles = () => ({});

const QuestionForm = ({
  error,
  workItemDefinition,
  customisations,
  change,
  untouch,
}) => {
  return (
    <form autoComplete="off">
      {error && <FormError errorMessage={error} />}
      <QuestionFields
        workItemDefinition={workItemDefinition}
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={QUESTION_FORM_NAME}
      />
    </form>
  );
};

QuestionForm.propTypes = {
  error: PropTypes.string,
  workItemDefinition: PropTypes.object.isRequired,
  customisations: PropTypes.array,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
};

QuestionForm.defaultProps = {
  error: null,
  customisations: [],
};

const mapStateToProps = () => ({});

const form = reduxForm({
  form: QUESTION_FORM_NAME,
  onSubmitFail: (errors) => scrollToFirstInvalidInput(errors),
});
const reduxData = connect(mapStateToProps);
export default compose(form, reduxData)(withStyles(styles)(QuestionForm));
