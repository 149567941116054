import Chip from "@material-ui/core/Chip";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import _ from "lodash";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { SubmissionError } from "redux-form";
import { patchTag } from "../../../actions/tags";
import FormDialog from "../FormDialog";
import TagForm, { TAG_FORM_NAME } from "../tag/TagForm";
import { useAuth0 } from "@auth0/auth0-react";

const styles = () => ({});

const compareTags = (a, b) => {
  if (a.text.toUpperCase() > b.text.toUpperCase()) {
    return 1;
  }
  if (a.text.toUpperCase() < b.text.toUpperCase()) {
    return -1;
  }
  return 0;
};

const toOption = (tag) =>
  tag
    ? {
        label: tag.text,
        value: tag.id || `id-${tag.text}`,
        allowEdit: !!tag.id,
      }
    : tag;
const toOptions = (tags) => {
  if (!tags) {
    return [];
  }
  return Array.isArray(tags)
    ? tags.map((tag) => toOption(tag))
    : toOptions([tags]);
};

const toTag = (tags, type, selectedOption) => {
  if (selectedOption) {
    if (_.isString(selectedOption)) {
      return {
        text: selectedOption,
        type,
      };
    }
    const existingTag = tags.find((tag) => tag.id === selectedOption.value);
    if (existingTag) {
      return existingTag;
    }
    return {
      text: selectedOption.label,
      type,
    };
  }
  return selectedOption;
};
const toTags = (tags, type, selectedOptions) =>
  selectedOptions.map((option) => toTag(tags, type, option));

const WrappedTags = ({
  input: { value, onChange, name },
  label,
  tags,
  tagType,
  meta: { touched, error, invalid },
  margin,
  helperText,
  localPatchTag,
  isMulti,
  ...custom
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [activeTag, setActiveTag] = useState(null);
  const [filteredTags, setFilteredTags] = useState([]);

  useEffect(() => {
    setFilteredTags(tags.filter((tag) => tag.type === tagType));
  }, [tags]);

  const handleSubmitEdit = async (tag) => {
    if (tag.id) {
      const accessToken = await getAccessTokenSilently();
      localPatchTag(tag.id, { text: tag.text }, "Updated tag", accessToken)
        .then(() => {
          setActiveTag(null);
        })
        .catch((submissionError) => {
          throw new SubmissionError({ _error: submissionError.message });
        });
    }
  };

  const handleOnChange = (selection) => {
    if (isMulti) {
      const selected = selection || [];
      onChange(toTags(filteredTags, tagType, selected).sort(compareTags));
    } else {
      onChange(toTag(filteredTags, tagType, selection));
    }
  };

  const handleOnInputChange = (selection, reason) => {
    if (reason === "input") {
      handleOnChange(selection);
    }
  };

  const isSelected = (option) => {
    if (isMulti) {
      return value.filter((v) => toOption(v).value === option.value).length > 0;
    }
    return value && toOption(value).value === option.value;
  };

  return (
    <div style={{ marginBottom: "5px" }}>
      <FormDialog
        title="Edit tag"
        submitButtonText="Save"
        formComponent={TagForm}
        formName={TAG_FORM_NAME}
        open={!!activeTag}
        onCancel={() => setActiveTag(null)}
        onSubmit={handleSubmitEdit}
        initialValues={{ ...activeTag }}
      />
      <Autocomplete
        name={`position-${name}`}
        multiple={isMulti}
        margin={margin}
        autoComplete
        autoHighlight
        filterSelectedOptions
        getOptionSelected={isSelected}
        freeSolo
        options={toOptions(filteredTags.sort(compareTags))}
        getOptionLabel={(option) =>
          _.isString(option) ? option : option.label
        }
        value={isMulti ? toOptions(value) : toOption(value)}
        onChange={(event, selection) => handleOnChange(selection)}
        onInputChange={
          isMulti
            ? null
            : (event, selection, reason) =>
                handleOnInputChange(selection, reason)
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            margin={margin}
            label={label}
            error={touched && invalid}
            helperText={(touched && error) || helperText}
            {...custom}
          />
        )}
        renderTags={(selection, getTagProps) =>
          selection.map((item, index) => {
            const tag = toTag(filteredTags, tagType, item);
            return (
              <Chip
                key={tag.text}
                label={tag.text}
                {...getTagProps({ index })}
                onClick={item.allowEdit ? () => setActiveTag(tag) : null}
              />
            );
          })
        }
      />
    </div>
  );
};

WrappedTags.propTypes = {
  input: PropTypes.object.isRequired,
  tags: PropTypes.array.isRequired,
  tagType: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  margin: PropTypes.string,
  helperText: PropTypes.any,
  isMulti: PropTypes.bool,
  localPatchTag: PropTypes.func.isRequired,
};

WrappedTags.defaultProps = {
  helperText: "",
  margin: "dense",
  isMulti: false,
};

const mapStateToProps = () => ({});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    localPatchTag: patchTag,
  })
)(WrappedTags);
