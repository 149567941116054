import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import moment from "moment";
import { Card, CardActions } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {
  subscribeBuildTime,
  unsubscribeBuildTime,
} from "../services/buildInfo";

const styles = (theme) => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    color: theme.palette.common.white,
    background: theme.palette.grey["800"],
    // padding: theme.spacing(3),
  },
});

class NewVersionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      snoozedAt: null,
      buildTime: null,
    };
  }

  componentDidMount() {
    subscribeBuildTime(this.handleNewBuildTime);
  }

  componentWillUnmount() {
    unsubscribeBuildTime(this.handleNewBuildTime);
  }

  /**
   * Store build-time for the first time,
   * and show modal if new build-time from server is newer,
   * unless the user has 'snoozed' the notification
   */
  handleNewBuildTime = (event) => {
    const newBuildTime = event.detail;
    if (!this.state.buildTime) {
      this.setState({ buildTime: newBuildTime });
    } else if (this.shouldShowModal(newBuildTime)) {
      setTimeout(() => {
        this.setState({ open: true });
      }, 3000);
    }
  };

  handleSnooze = () => {
    this.setState({
      open: false,
      snoozedAt: moment().valueOf(),
    });
  };

  /**
   * Dont show the modal if the user snoozed, and its still within the 5 minute snooze period.
   * Otherwise show the modal if the buildTime differs.
   */
  shouldShowModal = (newBuildTime) => {
    if (this.state.snoozedAt) {
      const now = moment().valueOf();
      const snoozeTimeInSecond = (now - this.state.snoozedAt) / 1000;
      if (snoozeTimeInSecond < 60 * 5) {
        // 5 min snooze period has not yet expired
        return false;
      }
    }
    return (
      this.state.buildTime !== newBuildTime && this.state.buildTime !== null
    );
  };

  render() {
    const { classes } = this.props;
    const { open } = this.state;
    return (
      <Modal open={open}>
        <Card elevation={2} className={classes.root}>
          <CardContent>
            <Typography variant="body1">
              A new version of Arcadia is available
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              color="secondary"
              onClick={() => window.location.reload(true)}
            >
              Get new version
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                this.handleSnooze();
              }}
              className={classes.button}
            >
              Snooze (5 mins)
            </Button>
          </CardActions>
        </Card>
      </Modal>
    );
  }
}

NewVersionModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NewVersionModal);
