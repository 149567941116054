import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import MultilineText from "./MultilineText";

const styles = (theme) => ({
  root: {
    "&:not(last-child)": {
      borderBottom: "1px solid #F8F8F8",
    },
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  labelContainer: {
    marginRight: theme.spacing(0.5),
  },
});

const InlineScorecard = ({
  children,
  classes,
  label,
  value,
  labelClass,
  labelVariant,
  labelWidth,
}) => {
  // value is used (and styled) instead of children if supplied.
  let valueEl = children;

  if (value) {
    valueEl = <div>{value}</div>;
  } else if (children && typeof children === "string") {
    valueEl = <MultilineText text={children} />;
  }

  return (
    <div className={classes.root}>
      <Grid container alignItems="center">
        <Grid item xs={labelWidth} data-cy={label}>
          {label && (
            <div className={classes.labelContainer}>
              <Typography
                variant={labelVariant || "subtitle1"}
                className={labelClass}
                data-cy={label}
              >
                {label}
              </Typography>
            </div>
          )}
        </Grid>
        <Grid item xs={12 - labelWidth}>
          {valueEl}
        </Grid>
      </Grid>
    </div>
  );
};

InlineScorecard.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  label: PropTypes.any,
  value: PropTypes.any,
  labelVariant: PropTypes.string,
  labelClass: PropTypes.string,
  labelWidth: PropTypes.number,
};

InlineScorecard.defaultProps = {
  children: null,
  value: null,
  label: undefined,
  labelVariant: null,
  labelClass: null,
  labelWidth: 4,
};

export default withStyles(styles)(InlineScorecard);
