import * as PropTypes from "prop-types";
import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

const WrappedCheckbox = ({
  input,
  label,
  meta: { touched, error, invalid },
  margin,
  ...custom
}) => (
  <>
    <FormControlLabel
      name={`position-${input.name}`}
      control={
        <Checkbox
          color="primary"
          checked={!!input.value}
          onChange={(event, checked) => input.onChange(checked)}
          {...custom}
          data-cy={label}
        />
      }
      margin={margin}
      label={label}
    />
    {touched && error && (
      <FormHelperText error={touched && invalid}>{error}</FormHelperText>
    )}
  </>
);

WrappedCheckbox.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  margin: PropTypes.string,
};

WrappedCheckbox.defaultProps = {
  margin: "dense",
};

export default WrappedCheckbox;
