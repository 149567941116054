import { requestJSON, requestText } from "./http";

const decorateSearchParameters = (filter, type = "Risk") =>
  filter ? { __type: type, ...filter } : { __type: type };

const create = (risk, accessToken) =>
  requestJSON({
    path: "/risk/risks",
    method: "POST",
    data: risk,
    accessToken,
  });

const remove = (riskId, accessToken) =>
  requestJSON({
    path: `/risk/risks/${riskId}`,
    method: "DELETE",
    accessToken,
  });

const search = (filter, abortController, accessToken) =>
  requestJSON({
    path: "/risk/risks/search",
    method: "POST",
    data: decorateSearchParameters(filter),
    abortController,
    accessToken,
  });

const count = (filter, accessToken) =>
  requestJSON({
    path: "/risk/risks/count",
    method: "POST",
    data: decorateSearchParameters(filter),
    accessToken,
  });

const getById = (id, accessToken) =>
  requestJSON({
    path: `/risk/risks/${id}`,
    accessToken,
  });

const usage = (id, accessToken) =>
  requestJSON({
    path: `/risk/risks/${id}/usage`,
    accessToken,
  });

const patch = (id, changes, description, accessToken) =>
  requestJSON({
    path: `/risk/risks/${id}`,
    method: "PATCH",
    data: {
      fields: Object.keys(changes),
      changes,
      description,
    },
    accessToken,
  });

const addMeasure = (id, measure, accessToken) =>
  requestJSON({
    path: `/risk/risks/${id}/measures`,
    method: "POST",
    data: measure,
    accessToken,
  });

const updateMeasure = (id, measureId, measure, accessToken) =>
  requestJSON({
    path: `/risk/risks/${id}/measures/${measureId}`,
    method: "PUT",
    data: measure,
    accessToken,
  });

const removeMeasure = (id, measureId, accessToken) =>
  requestJSON({
    path: `/risk/risks/${id}/measures/${measureId}`,
    method: "DELETE",
    accessToken,
  });

const getTolerances = (id, ownerId, accessToken) =>
  requestJSON({
    path: `/risk/risks/${id}/tolerances?owner=${ownerId}`,
    accessToken,
  });

const createReassessments = (riskIds, dueDate, reminderLeadTime, accessToken) =>
  requestText({
    path: "/risk/risks/reassessments",
    method: "POST",
    data: {
      riskIds,
      dueDate,
      reminderLeadTime,
    },
    accessToken,
  });

const createMeasureUpdates = (
  riskIds,
  dueDate,
  reminderLeadTime,
  accessToken
) =>
  requestText({
    path: "/risk/risks/measure-updates",
    method: "POST",
    data: {
      riskIds,
      dueDate,
      reminderLeadTime,
    },
    accessToken,
  });

const sendRiskActionUpdateRequests = (filter, message, accessToken) =>
  requestText({
    path: "/risk/risks/send-risk-action-update-requests",
    method: "POST",
    data: {
      searchParameters: decorateSearchParameters(filter, "WorkItem"),
      message,
    },
    accessToken,
  });

export default {
  create,
  remove,
  search,
  count,
  usage,
  getById,
  patch,
  addMeasure,
  updateMeasure,
  removeMeasure,
  createReassessments,
  createMeasureUpdates,
  sendRiskActionUpdateRequests,
  getTolerances,
};
