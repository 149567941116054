import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import FormCard from "../../../common/FormCard";
import EditWorkItemComponentForm, {
  EDIT_WORK_ITEM_COMPONENT_FORM_NAME,
} from "../EditWorkItemComponentForm";
import MemberDetailsReadOnly from "./MemberDetailsReadOnly";

const MemberDetailsCard = ({
  className,
  fieldLabels,
  workItem,
  onChange,
  customisations,
}) => {
  const { memberDetails } = workItem;

  return (
    <FormCard
      data-cy={fieldLabels.labels.memberDetails}
      className={className}
      title={fieldLabels.labels.memberDetails}
      formTitle={`Edit ${fieldLabels.labels.memberDetails}`}
      readOnlyView={() => (
        <MemberDetailsReadOnly
          workItem={workItem}
          fieldLabels={fieldLabels}
          customisations={customisations}
          data-cy={fieldLabels}
        />
      )}
      formComponent={EditWorkItemComponentForm}
      formName={EDIT_WORK_ITEM_COMPONENT_FORM_NAME}
      onSave={onChange}
      initialValues={{ memberDetails }}
      formProps={{
        maxWidth: "md",
        workItem,
        componentName: "MEMBER_DETAILS",
      }}
    />
  );
};

MemberDetailsCard.propTypes = {
  className: PropTypes.string,
  fieldLabels: PropTypes.object.isRequired,
  workItem: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  customisations: PropTypes.array,
};

MemberDetailsCard.defaultProps = {
  className: undefined,
  customisations: [],
  onChange: null,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(MemberDetailsCard);
