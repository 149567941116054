import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { getReferenceDataMap } from "../../../../reducers";
import LabelStateSelect from "../../../common/LabelStateSelect";
import { getCategoryMenuItems } from "../../../forms/menuItems";

const styles = () => ({
  button: {
    fontSize: "inherit",
    fontWeight: 600,
    padding: 0,
    minWidth: 0,
  },
  disabled: {
    color: "inherit",
  },
});

const WorkItemCategory = ({
  className,
  category,
  readonly,
  categories,
  allCategories,
  onCategoryChange,
}) => {
  const closer = useRef(null);

  const handleCategoryChange = (newCategory) => {
    closer.current();

    if (newCategory !== category) {
      onCategoryChange(newCategory);
    }
  };

  const categoryAssignment =
    category && categories.find((c) => c.category === category);
  const categoryEnum = category && allCategories[category];
  const buttonText = categoryAssignment
    ? categoryAssignment.name
    : categoryEnum || "";

  if (!onCategoryChange) {
    return <div data-cy={buttonText}>{buttonText}</div>;
  }

  return (
    <LabelStateSelect
      setCloser={(closeFunc) => {
        closer.current = closeFunc;
      }}
      label={buttonText}
      readonly={readonly}
      className={className}
      data-cy={buttonText}
    >
      {getCategoryMenuItems(categories, handleCategoryChange)}
    </LabelStateSelect>
  );
};

WorkItemCategory.propTypes = {
  className: PropTypes.string,
  allCategories: PropTypes.object.isRequired,
  category: PropTypes.string.isRequired,
  onCategoryChange: PropTypes.func,
  readonly: PropTypes.bool,
  categories: PropTypes.array.isRequired,
};

WorkItemCategory.defaultProps = {
  className: undefined,
  readonly: false,
  onCategoryChange: null,
};

const mapStateToProps = (state) => ({
  allCategories: getReferenceDataMap(state, "WorkItemCategory"),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(WorkItemCategory);
