import { requestJSON } from "./http";

const decorateSearchParameters = (filter) =>
  filter ? { __type: "Control", ...filter } : { __type: "Control" };

const search = (filter, abortController, accessToken) =>
  requestJSON({
    path: "/controls/search",
    method: "POST",
    data: decorateSearchParameters(filter),
    abortController,
    accessToken,
  });

const count = (filter, accessToken) =>
  requestJSON({
    path: "/controls/count",
    method: "POST",
    data: decorateSearchParameters(filter),
    accessToken,
  });

const create = (control, accessToken) =>
  requestJSON({
    path: "/controls",
    method: "POST",
    data: control,
    accessToken,
  });

const load = (controlId, accessToken) =>
  requestJSON({
    path: `/controls/${controlId}`,
    accessToken,
  });

const patch = (controlId, changes, description, accessToken) =>
  requestJSON({
    path: `/controls/${controlId}`,
    method: "PATCH",
    data: {
      fields: Object.keys(changes),
      changes,
      description,
    },
    accessToken,
  });

const remove = (controlId, accessToken) =>
  requestJSON({
    path: `/controls/${controlId}`,
    method: "DELETE",
    accessToken,
  });

const associatedRisks = (controlId, accessToken) =>
  requestJSON({
    path: `/controls/${controlId}/associatedRisks`,
    accessToken,
  });

export default {
  search,
  count,
  create,
  load,
  patch,
  remove,
  associatedRisks,
};
