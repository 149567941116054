import React, { useEffect, useState } from "react";
import ReactAvatar from "react-avatar";
import { makeStyles, useTheme } from "@material-ui/core/styles";

interface Props {
  name: string;
  email?: string;
  round?: boolean;
  color?: string;
  size?: string;
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    marginRight: theme.spacing(1),
  },
}));

// Exclude email prop, so ReactAvatar does not try to make requests to gravatar,
// which results in 404. Most users don't have avatar setup there.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Avatar: React.FC<Props> = ({ color, email, ...props }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
    };
  }, []);

  return mounted ? (
    <ReactAvatar
      className={classes.avatar}
      color={color || theme.palette.primary.main}
      {...props}
    />
  ) : null;
};

export default Avatar;
