import "moment/locale/en-au";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { attachErrorLogger } from "./util/errors";

attachErrorLogger();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("arcadia")
);
