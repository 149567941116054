import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { listControls } from "../../actions/controls";
import {
  getControlPagination,
  getControls,
  getLabels,
  getReferenceDataDescription,
  isLoading,
} from "../../reducers";
import dates from "../../util/dates";
import GridListing from "../common/GridListing";
import Pagination from "../common/Pagination";
import { useAuth0 } from "@auth0/auth0-react";
import TimeAgo from "../common/TimeAgo";
import EffectivenessChip from "./EffectivenessChip";

const ControlListing = ({
  controls,
  sortBy,
  filter,
  clearFilter,
  updateSort,
  history,
  fieldLabels,
  loading,
  getControlTypeDescription,
  getLifecycleStatusDescription,
  localListControls,
  pagination,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const refreshData = async (offset, pageSize) => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = {
      ...filter,
      orderByField:
        sortBy.direction === "asc" ? sortBy.field : `-${sortBy.field}`,
      limit: pageSize || pagination.pageSize,
      offset,
    };
    return localListControls(searchParameters, accessToken);
  };

  useEffect(() => {
    refreshData(0);
  }, [filter, sortBy]);

  const handlePrevious = () => {
    refreshData(pagination.previousOffset);
  };

  const handleNext = () => {
    refreshData(pagination.nextOffset);
  };

  if (loading) {
    return <p data-cy="loading">Loading...</p>;
  }

  return (
    <>
      <GridListing
        data-cy="table"
        sortedData={controls}
        loading={loading}
        sortBy={sortBy}
        dense={false}
        updateSort={(field, direction) =>
          updateSort({
            field,
            direction,
          })
        }
        clearFilter={clearFilter}
        onClick={(control) => history.push(`/controls/${control.id}`)}
        columns={[
          {
            label: fieldLabels.labels.title,
            name: "title",
            size: 2,
            sortable: true,
            render: (control) => <span>{control.title}</span>,
          },
          {
            label: fieldLabels.labels.type,
            name: "type",
            size: 2,
            sortable: false,
            render: (control) => (
              <span data-cy="controlType">
                {getControlTypeDescription(control.type)}
              </span>
            ),
          },
          {
            label: fieldLabels.labels.status,
            name: "status",
            size: 2,
            sortable: true,
            render: (control) => (
              <span data-cy="controlStatus">
                {getLifecycleStatusDescription(control.status)}
              </span>
            ),
          },
          {
            label: fieldLabels.labels.owner,
            name: "owner",
            size: 2,
            sortable: true,
            render: (control) => (
              <span data-cy="controlOwner">
                {control.owner ? control.owner.name : ""}
              </span>
            ),
          },
          {
            label: fieldLabels.labels.objective,
            name: "objective",
            size: 4,
            sortable: false,
            render: (control) => (
              <span>{_.truncate(control.objective, { length: 80 })}</span>
            ),
          },
          {
            label: fieldLabels.labels.effectiveness,
            name: "effectiveness",
            size: 2,
            sortable: true,
            render: (control) => (
              <EffectivenessChip effectiveness={control.effectiveness} />
            ),
          },
          {
            label: fieldLabels.labels.created,
            name: "created",
            size: 2,
            sortable: true,
            render: (control) => (
              <TimeAgo
                value={dates.parseTimestamp(control.created)}
                expandable
              />
            ),
          },
          {
            label: fieldLabels.labels.updated,
            name: "updated",
            size: 2,
            sortable: true,
            render: (control) => (
              <TimeAgo
                value={dates.parseTimestamp(control.updated)}
                expandable
              />
            ),
          },
        ]}
      />
      <Pagination
        data-cy="pagination"
        pagination={pagination}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
      />
    </>
  );
};

ControlListing.propTypes = {
  loading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  sortBy: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  updateSort: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired,

  // redux
  fieldLabels: PropTypes.object.isRequired,
  localListControls: PropTypes.func.isRequired,
  getLifecycleStatusDescription: PropTypes.func.isRequired,
  getControlTypeDescription: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  controls: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  loading: isLoading(state),
  controls: getControls(state),
  fieldLabels: getLabels(state).Control,
  getControlTypeDescription: (id) =>
    getReferenceDataDescription(state, "RiskControlType", id, "-"),
  getLifecycleStatusDescription: (id) =>
    getReferenceDataDescription(state, "LifecycleStatus", id, "-"),
  pagination: getControlPagination(state),
});

export default connect(mapStateToProps, {
  localListControls: listControls,
})(ControlListing);
