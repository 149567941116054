import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import PropTypes from "prop-types";
import React, { useState } from "react";
import ListPicker from "../ListPicker";

const styles = () => ({
  textListPicker: {
    fontSize: "0.8125rem",
    cursor: "pointer",
  },
});

const MultiSelectListPicker = ({
  classes,
  title,
  actionText,
  onSubmit,
  label,
  ...rest
}) => {
  const [pickerOpen, setPickerOpen] = useState(false);

  const setSelection = (selection) => {
    onSubmit(selection);
    setPickerOpen(false);
  };

  return (
    <>
      <ListPicker
        title={title}
        actionText={actionText}
        open={pickerOpen}
        onClose={() => setPickerOpen(false)}
        onSubmit={setSelection}
        {...rest}
        data-cy={title}
      />
      <Input
        classes={{
          input: classes.textListPicker,
        }}
        className={classes.textListPicker}
        value={label || ""}
        onClick={() => setPickerOpen(true)}
        fullWidth
        readOnly
        endAdornment={
          <InputAdornment position="end">
            <ArrowDropDownIcon color="action" />
          </InputAdornment>
        }
      />
    </>
  );
};

MultiSelectListPicker.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  actionText: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  label: PropTypes.string,
};

MultiSelectListPicker.defaultProps = {
  label: "",
  actionText: "Select",
};

export default withStyles(styles)(MultiSelectListPicker);
