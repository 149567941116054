import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { listRiskControls } from "../../actions/riskControls";
import {
  getReferenceDataType,
  getRiskControlPagination,
  getRiskControls,
  isLoading,
} from "../../reducers";
import dates from "../../util/dates";
import DateAgo from "../common/DateAgo";
import GridListing from "../common/GridListing";
import Pagination from "../common/Pagination";
import { useAuth0 } from "@auth0/auth0-react";

function displayEnum(defs, value) {
  return value ? defs.values[value].description : "";
}

const RiskControlListing = ({
  riskControls,
  sortBy,
  filter,
  clearFilter,
  updateSort,
  history,
  loading,
  riskControlTypes,
  localListRiskControls,
  pagination,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const refreshData = async (offset, pageSize) => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = {
      ...filter,
      orderByField:
        sortBy.direction === "asc" ? sortBy.field : `-${sortBy.field}`,
      limit: pageSize || pagination.pageSize,
      offset,
    };
    return localListRiskControls(searchParameters, accessToken);
  };

  useEffect(() => {
    refreshData(0);
  }, [filter, sortBy]);

  const handlePrevious = () => {
    refreshData(pagination.previousOffset);
  };

  const handleNext = () => {
    refreshData(pagination.nextOffset);
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <GridListing
        data-cy="table"
        sortedData={riskControls}
        loading={loading}
        sortBy={sortBy}
        dense={false}
        updateSort={(field, direction) =>
          updateSort({
            field,
            direction,
          })
        }
        clearFilter={clearFilter}
        onClick={(control) => history.push(`/risk/controls/${control.id}`)}
        columns={[
          {
            label: "Type",
            name: "type",
            size: 2,
            sortable: true,
            render: (riskControl) => (
              <span>{displayEnum(riskControlTypes, riskControl.type)}</span>
            ),
          },
          {
            label: "Owner",
            name: "owner",
            size: 2,
            sortable: true,
            render: (riskControl) => (
              <span>{riskControl.owner ? riskControl.owner.name : ""}</span>
            ),
          },
          {
            label: "Name",
            name: "name",
            size: 2,
            sortable: true,
            render: (riskControl) => <span>{riskControl.name}</span>,
          },
          {
            label: "Description",
            name: "description",
            size: 4,
            sortable: false,
            render: (riskControl) => (
              <span>{_.truncate(riskControl.description, { length: 80 })}</span>
            ),
          },
          {
            label: "Last verified",
            name: "lastVerified",
            size: 2,
            sortable: true,
            render: (riskControl) => {
              const lastVerified = dates.parseDate(riskControl.lastVerified);
              return (
                lastVerified && (
                  <>
                    {dates.formatDateLong(lastVerified)} <br />
                    <DateAgo value={lastVerified} expandable={false} />
                  </>
                )
              );
            },
          },
        ]}
      />
      <Pagination
        data-cy="pagination"
        pagination={pagination}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
      />
    </>
  );
};

RiskControlListing.propTypes = {
  loading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  sortBy: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  updateSort: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired,

  // redux
  localListRiskControls: PropTypes.func.isRequired,
  riskControlTypes: PropTypes.object.isRequired,
  pagination: PropTypes.object.isRequired,
  riskControls: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  loading: isLoading(state),
  riskControls: getRiskControls(state),
  riskControlTypes: getReferenceDataType(state, "RiskControlType"),
  pagination: getRiskControlPagination(state),
});

export default connect(mapStateToProps, {
  localListRiskControls: listRiskControls,
})(RiskControlListing);
