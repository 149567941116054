import { useAuth0 } from "@auth0/auth0-react";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import { getLabels, getReferenceDataOptions } from "../../../../reducers";
import usersApi from "../../../../services/api/users";
import Avatar from "../../../common/Avatar.tsx";
import ComponentField from "../../../forms/wrapper/ComponentField";
import WrappedListPicker from "../../../forms/wrapper/WrappedListPicker";
import WrappedRadioGroup from "../../../forms/wrapper/WrappedRadioGroup";
import WrappedTextField from "../../../forms/wrapper/WrappedTextField";

const QuestionFields = ({
  fieldPrefix,
  workItemDefinition,
  customisations,
  change,
  untouch,
  formName,
  fieldLabels,
  attachmentsInputOptions,
  justificationNotesInputOptions,
}) => {
  const fieldStart = fieldPrefix ? fieldPrefix + "." : "";
  const { getAccessTokenSilently } = useAuth0();
  const { submittableBy } = workItemDefinition;

  const asyncSubmitterOptionsFetch = async (
    pickerFilter,
    pagination,
    abortController
  ) => {
    const searchParameters = {
      ...pickerFilter,
      limit: pagination.pageSize,
      offset: pagination.offset,
      roles: submittableBy,
    };
    const accessToken = await getAccessTokenSilently();
    return usersApi.search(searchParameters, abortController, accessToken);
  };

  const getUserLabel = (user) => {
    const organisation =
      user.externalUser && user.organisations.length > 0
        ? user.organisations[0]
        : null;
    const username = user.name || "Unknown";

    return organisation ? `${username} (${organisation.name})` : username;
  };

  return (
    <>
      <ComponentField
        name={`${fieldStart}text`}
        component={WrappedTextField}
        label={fieldLabels.labels.text}
        validate={required({ msg: `${fieldLabels.labels.text} is required` })}
        fullWidth
        multiline
        rowsMax={6}
        rows={1}
        required
        helperText='Questions should be worded in a way which allows them to be answered with a "yes" or "no" response.'
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      />
      <ComponentField
        name={`${fieldStart}justificationNotesInput`}
        component={WrappedRadioGroup}
        fullWidth
        label={fieldLabels.labels.justificationNotesInput}
        validate={required({
          msg: `${fieldLabels.labels.justificationNotesInput} is required`,
        })}
        options={justificationNotesInputOptions}
        row={false}
        required
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      />
      <ComponentField
        name={`${fieldStart}attachmentsInput`}
        component={WrappedRadioGroup}
        fullWidth
        label={fieldLabels.labels.attachmentsInput}
        validate={required({
          msg: `${fieldLabels.labels.attachmentsInput} is required`,
        })}
        options={attachmentsInputOptions}
        row={false}
        required
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      />
      <ComponentField
        name={`${fieldStart}responders`}
        component={WrappedListPicker}
        datasource={asyncSubmitterOptionsFetch}
        isMulti
        required
        validate={required({
          msg: `${fieldLabels.labels.responders} is required`,
        })}
        label={fieldLabels.labels.responders}
        fullWidth
        toOption={(user) => ({
          label: getUserLabel(user),
          id: user.id,
          email: user.email,
          user,
        })}
        fromOption={(option) => option.user}
        renderIcon={(user, size) => (
          <Avatar email={user.email} name={user.name} size={size} round />
        )}
        helperText="The person/people who will respond to this question"
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      />
    </>
  );
};

QuestionFields.propTypes = {
  fieldPrefix: PropTypes.string,
  workItemDefinition: PropTypes.object.isRequired,
  customisations: PropTypes.array,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,

  // redux
  fieldLabels: PropTypes.object.isRequired,
  justificationNotesInputOptions: PropTypes.array.isRequired,
  attachmentsInputOptions: PropTypes.array.isRequired,
};

QuestionFields.defaultProps = {
  fieldPrefix: "",
  customisations: [],
};

const mapStateToProps = (state) => ({
  fieldLabels: getLabels(state).Question,
  justificationNotesInputOptions: getReferenceDataOptions(
    state,
    "JustificationNotesInput"
  ),
  attachmentsInputOptions: getReferenceDataOptions(state, "AttachmentsInput"),
});

export default connect(mapStateToProps)(QuestionFields);
