import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector } from "redux-form";
import { numericality, required } from "redux-form-validators";
import { getLabels, getReferenceDataType } from "../../../reducers";
import dates from "../../../util/dates";
import { monthlyPeriodNames } from "../../common/periodUtils";
import SchedulePreview from "./SchedulePreview";
import WrappedDatePicker from "../../forms/wrapper/WrappedDatePicker";
import WrappedMonthPicker from "../../forms/wrapper/WrappedMonthPicker";
import WrappedSelect from "../../forms/wrapper/WrappedSelect";
import WrappedTextField from "../../forms/wrapper/WrappedTextField";
import WrappedWeekPicker from "../../forms/wrapper/WrappedWeekPicker";
import { getRefDataMenuItems } from "../../forms/menuItems";

const ScheduleFields = ({
  change,
  type,
  setTemplate,
  labels,
  recurrenceTypes,
  recurrenceType,
  recurrenceUnit,
  startBy,
  stopBy,
  schedule,
  nameOfFirstPeriod,
  creationLeadTime,
  reminderLeadTime,
  dueDateOffset,
}) => {
  const [showSchedulePreview, setShowSchedulePreview] = useState(false);

  const workItemLabels = labels[type];
  const scheduleLabels = labels.WorkItemSchedule;

  useEffect(() => {
    setTemplate(!!recurrenceType);

    if (recurrenceType) {
      change("dueDate", null);
      change("schedule.nameOfFirstPeriod", null);

      switch (recurrenceType) {
        case "WEEK":
          change(
            "schedule.startBy",
            dates.serializeDate(moment().startOf("week"))
          );
          break;
        case "MONTH":
          change(
            "schedule.startBy",
            dates.serializeDate(moment().startOf("month"))
          );
          break;
        case "YEAR":
          change(
            "schedule.startBy",
            dates.serializeDate(moment().startOf("year"))
          );
          break;
        default:
          break;
      }
    } else if (schedule) {
      change("schedule", null);
    }
  }, [recurrenceType]);

  useEffect(() => {
    if (schedule && !recurrenceType) {
      change("schedule", null);
    }
  }, [schedule]);

  useEffect(() => {
    if (recurrenceType) {
      change("schedule.nameOfFirstPeriod", null);
    }
  }, [recurrenceUnit]);

  const recurrenceTypeDef =
    recurrenceType && recurrenceTypes.values[recurrenceType];

  return (
    <>
      <Field
        name="schedule.recurrenceType"
        component={WrappedSelect}
        label={scheduleLabels.labels.recurrenceType}
        fullWidth
        nullLabel="Once off"
        displayNullLabelAsPlaceholder
      >
        {getRefDataMenuItems(recurrenceTypes)}
      </Field>
      {!recurrenceType && (
        <>
          <Field
            name="dueDate"
            component={WrappedDatePicker}
            label={workItemLabels.labels.dueDate}
            validate={required({
              msg: `${workItemLabels.labels.dueDate} is required`,
            })}
            required
            fullWidth
            data-cy="dueDate"
          />
          <Field
            name="reminderLeadTime"
            component={WrappedTextField}
            label={workItemLabels.labels.reminderLeadTime}
            helperText="Number of days before due date to send reminder"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">days</InputAdornment>
              ),
            }}
            required
            validate={numericality({
              ">": 0,
              int: true,
              allowBlank: false,
            })}
            data-cy="remaiderLeadTime"
          />
        </>
      )}
      {recurrenceType && (
        <>
          {recurrenceType !== "WEEK" && (
            <Field
              name="schedule.startBy"
              component={WrappedMonthPicker}
              label={scheduleLabels.labels.startBy}
              validate={required({
                msg: `${scheduleLabels.labels.startBy} is required`,
              })}
              required
              fullWidth
            />
          )}
          {recurrenceType === "WEEK" && (
            <Field
              name="schedule.startBy"
              component={WrappedWeekPicker}
              label={scheduleLabels.labels.startBy}
              validate={required({
                msg: `${scheduleLabels.labels.startBy} is required`,
              })}
              required
              fullWidth
              data-cy="firstPeriodStarting"
            />
          )}
          <Field
            name="schedule.recurrenceUnit"
            component={WrappedTextField}
            label={scheduleLabels.labels.recurrenceUnit}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {recurrenceTypeDef.props.units}
                </InputAdornment>
              ),
            }}
            required
            validate={numericality({
              ">": 0,
              int: true,
              allowBlank: false,
            })}
          />
          {recurrenceType === "MONTH" && !!monthlyPeriodNames[recurrenceUnit] && (
            <Field
              name="schedule.nameOfFirstPeriod"
              component={WrappedSelect}
              label={scheduleLabels.labels.nameOfFirstPeriod}
              helperText="Optionally select a name for the first period"
              fullWidth
              data-cy="Name of first period"
            >
              {monthlyPeriodNames[recurrenceUnit].map((name) => (
                <MenuItem key={name} value={name} data-cy={name}>
                  {name}
                </MenuItem>
              ))}
            </Field>
          )}
          <Field
            name="schedule.dueDateOffset"
            component={WrappedTextField}
            label={scheduleLabels.labels.dueDateOffset}
            helperText="Number of days after end of period the task is due. Use negative value for before end of period."
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">days</InputAdornment>
              ),
            }}
            required
            validate={numericality({
              int: true,
              allowBlank: false,
            })}
            data-cy="dueDateOffset"
          />
          <Field
            name="schedule.creationLeadTime"
            component={WrappedTextField}
            label={scheduleLabels.labels.creationLeadTime}
            helperText="Number of days before end of period to create task. Use negative value for after end of period."
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">days</InputAdornment>
              ),
            }}
            required
            validate={numericality({
              int: true,
              allowBlank: false,
            })}
            data-cy="creationLeadTime"
          />
          <Field
            name="reminderLeadTime"
            component={WrappedTextField}
            label={workItemLabels.labels.reminderLeadTime}
            helperText="Number of days before due date to send reminder"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">days</InputAdornment>
              ),
            }}
            required
            validate={numericality({
              ">": 0,
              int: true,
              allowBlank: false,
            })}
            data-cy="remainderLeadTime"
          />
          <Field
            name="schedule.stopBy"
            component={WrappedDatePicker}
            label={scheduleLabels.labels.stopBy}
            fullWidth
            minDate={moment().endOf("month").add(1, "day").toDate()}
            data-cy="stopBy"
          />
          <Button
            data-cy="previewDates"
            color="primary"
            onClick={() => setShowSchedulePreview(true)}
            disabled={[
              recurrenceType,
              recurrenceUnit,
              startBy,
              _.isNumber(creationLeadTime),
              _.isNumber(dueDateOffset),
              _.isNumber(reminderLeadTime),
            ].some((value) => !value)}
          >
            Preview dates
          </Button>
          <Dialog
            open={showSchedulePreview}
            onClose={() => setShowSchedulePreview(false)}
            maxWidth="md"
            fullWidth
          >
            <DialogTitle data-cy="previewDates">Preview dates</DialogTitle>
            <DialogContent data-cy="scheduledPreview">
              <SchedulePreview
                recurrenceType={recurrenceType}
                recurrenceUnit={recurrenceUnit}
                nameOfFirstPeriod={nameOfFirstPeriod}
                startBy={startBy}
                stopBy={stopBy}
                creationLeadTime={creationLeadTime}
                dueDateOffset={dueDateOffset}
                reminderLeadTime={reminderLeadTime}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setShowSchedulePreview(false)}
                color="primary"
                autoFocus
                data-cy="ok"
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
};

ScheduleFields.propTypes = {
  change: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  formName: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  setTemplate: PropTypes.func.isRequired,

  // redux stuff
  labels: PropTypes.object.isRequired,
  recurrenceTypes: PropTypes.object.isRequired,
  schedule: PropTypes.object,
  recurrenceType: PropTypes.string,
  recurrenceUnit: PropTypes.number,
  nameOfFirstPeriod: PropTypes.string,
  startBy: PropTypes.string,
  stopBy: PropTypes.string,
  creationLeadTime: PropTypes.number,
  dueDateOffset: PropTypes.number,
  reminderLeadTime: PropTypes.number,
};

ScheduleFields.defaultProps = {
  schedule: null,
  recurrenceType: null,
  recurrenceUnit: null,
  nameOfFirstPeriod: null,
  stopBy: null,
  startBy: null,
  creationLeadTime: null,
  dueDateOffset: null,
  reminderLeadTime: null,
};

const toInt = (value) => {
  if (value === null || value === undefined || value === "") {
    return null;
  }
  return _.toNumber(value);
};

const mapStateToProps = (state, { formName }) => {
  const reduxFormSelector = formValueSelector(formName);
  return {
    labels: getLabels(state),
    recurrenceTypes: getReferenceDataType(state, "RecurrenceType"),
    schedule: reduxFormSelector(state, "schedule"),
    recurrenceType: reduxFormSelector(state, "schedule.recurrenceType"),
    recurrenceUnit: toInt(reduxFormSelector(state, "schedule.recurrenceUnit")),
    nameOfFirstPeriod: reduxFormSelector(state, "schedule.nameOfFirstPeriod"),
    startBy: reduxFormSelector(state, "schedule.startBy"),
    stopBy: reduxFormSelector(state, "schedule.stopBy"),
    creationLeadTime: toInt(
      reduxFormSelector(state, "schedule.creationLeadTime")
    ),
    dueDateOffset: toInt(reduxFormSelector(state, "schedule.dueDateOffset")),
    reminderLeadTime: toInt(reduxFormSelector(state, "reminderLeadTime")),
  };
};

export default connect(mapStateToProps)(ScheduleFields);
