import { requestJSON, requestText } from "./http";

const decorateSearchParameters = (filter) =>
  filter ? { __type: "AuditReport", ...filter } : { __type: "AuditReport" };

const create = (auditReport, accessToken) =>
  requestJSON({
    path: "/audit-reports",
    method: "POST",
    data: auditReport,
    accessToken,
  });

const search = (filter, abortController, accessToken) =>
  requestJSON({
    path: "/audit-reports/search",
    method: "POST",
    data: decorateSearchParameters(filter),
    abortController,
    accessToken,
  });

const getById = (id, accessToken) =>
  requestJSON({ path: `/audit-reports/${id}`, accessToken });

const patch = (id, changes, description, accessToken) =>
  requestJSON({
    path: `/audit-reports/${id}`,
    method: "PATCH",
    data: {
      fields: Object.keys(changes),
      changes,
      description,
    },
    accessToken,
  });

const updateDocuments = (id, documents, accessToken) =>
  requestJSON({
    path: `/audit-reports/${id}/documents`,
    method: "PUT",
    data: documents,
    accessToken,
  });

const documentDownloadUrl = (id, document, asAttachment, accessToken) =>
  requestText({
    path: `/audit-reports/${id}/documents/${document.id}${
      asAttachment ? "?asAttachment=true" : ""
    }`,
    accessToken,
  });

export default {
  create,
  search,
  getById,
  patch,
  documentDownloadUrl,
  updateDocuments,
};
