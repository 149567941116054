import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { patchUser } from "../../../actions/users";
import ActionButton from "../../../components/common/ActionButton";
import Container from "../../../components/common/Container";
import HeaderBar from "../../../components/common/HeaderBar";
import ActionHeading from "../../../components/common/ActionHeading";
import PageHeading from "../../../components/common/PageHeading";
import ExpandableForm from "../../../components/forms/ExpandableForm";
import UserForm, {
  USER_FORM_NAME,
} from "../../../components/forms/user/UserForm";
import UserFilters from "../../../components/user/UserFilters";
import UserListing from "../../../components/user/UserListing";
import { getLoggedInUser } from "../../../reducers";
import { clearIcon, userIcon } from "../../../util/icons";
import usePersistedState from "../../../util/persistedState";
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  contents: {
    marginBottom: "75px",
  },
}));

const defaultFilter = {
  textSearch: "",
};

const Index = ({ loggedInUser, history, localPatchUser }) => {
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();
  const [sortBy, setSortBy] = usePersistedState(
    `user-listing-sort-${loggedInUser.email}`,
    {
      field: "name",
      direction: "asc",
    }
  );
  const [filter, setFilter] = usePersistedState(
    `user-listing-filter-${loggedInUser.email}`,
    defaultFilter,
    ["textSearch"]
  );
  const [formOpen, setFormOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const formRef = useRef();

  const clearFilter = () => setFilter(defaultFilter);

  const updateFilter = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  const openForm = () => {
    setFormOpen(true);
    window.scrollTo(0, formRef.current.offsetTop);
  };

  useEffect(() => {
    if (selectedUser) {
      if (!formOpen) {
        openForm();
      } else {
        // need to close the redux form fully before changing between edit and create
        setFormOpen(false);
        setTimeout(() => {
          openForm();
        }, 0);
      }
    }
  }, [selectedUser]);

  const onSave = async (user) => {
    const accessToken = await getAccessTokenSilently();
    localPatchUser(selectedUser.id, user, "Updated user", accessToken).then(
      () => {
        setFormOpen(false);
        setSelectedUser(null);
      }
    );
  };

  const cancelForm = () => {
    setSelectedUser(null);
    setFormOpen(false);
  };

  return (
    <div className={classes.root}>
      <HeaderBar>
        <ActionHeading
          heading={<PageHeading icon={userIcon()} heading="Users" />}
          actions={[
            <ActionButton
              key="clearFilter"
              tooltip="Clear filters"
              icon={clearIcon()}
              onClick={clearFilter}
              data-cy="clear-filters-button"
            />,
          ]}
        />
      </HeaderBar>
      <Container className={classes.contents}>
        <ExpandableForm
          title="Edit user"
          submitButtonText="Save"
          formComponent={UserForm}
          formName={USER_FORM_NAME}
          open={formOpen}
          formRef={formRef}
          onCancel={cancelForm}
          onSubmit={onSave}
          initialValues={selectedUser}
          isAltPaper
        />
        <UserFilters
          filter={filter}
          updateFilter={updateFilter}
          fullWidth={false}
        />
        <UserListing
          history={history}
          filter={filter}
          clearFilter={clearFilter}
          sortBy={sortBy}
          updateSort={setSortBy}
          onSelect={(user) => setSelectedUser(user)}
          selected={selectedUser}
        />
      </Container>
    </div>
  );
};

Index.propTypes = {
  history: PropTypes.object.isRequired,

  // redux
  loggedInUser: PropTypes.object,
  localPatchUser: PropTypes.func.isRequired,
};

Index.defaultProps = {
  loggedInUser: null,
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
});

export default connect(mapStateToProps, {
  localPatchUser: patchUser,
})(Index);
