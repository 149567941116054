import _ from "lodash";
import moment from "moment";
import dates from "./dates";

export function sortSchedules(schedules, orderBy, frequencyTypes) {
  let valueFunc;
  switch (orderBy.field) {
    case "template.title":
      valueFunc = (schedule) => schedule.template.title;
      break;
    case "template.relevantFunds":
      valueFunc = (schedule) => schedule.template.relevantFunds.join(" ");
      break;
    case "template.sourcesOfObligation":
      valueFunc = (schedule) => schedule.template.sourcesOfObligation.join(" ");
      break;
    case "frequencyType":
      valueFunc = (schedule) =>
        frequencyTypes.values[schedule[orderBy.field]].ordinal;
      break;
    case "updated":
    case "created":
      valueFunc = (schedule) =>
        schedule[orderBy.field]
          ? dates.parseTimestamp(schedule[orderBy.field])
          : null;
      break;
    case "nextDue":
      valueFunc = (schedule) =>
        schedule[orderBy.field]
          ? dates.parseDate(schedule[orderBy.field])
          : null;
      break;
    default:
      valueFunc = (schedule) =>
        schedule[orderBy.field] && schedule[orderBy.field].toLowerCase
          ? schedule[orderBy.field].toLowerCase()
          : schedule[orderBy.field];
      break;
  }

  return _.orderBy(schedules, valueFunc, orderBy.direction);
}

export function groupByMonthDue(schedules, showProjected) {
  const projectedSchedules = showProjected
    ? _.flatMap(schedules, (schedule) =>
        schedule.periods
          .filter(
            (item) =>
              item.dueDate &&
              !dates.dateIsBeforeToday(item.dueDate) &&
              !dates.dateIsAfterEndOfNextYear(item.dueDate) &&
              item.dueDate !== schedule.nextDue
          )
          .map((period) => {
            const clonedSchedule = _.cloneDeep(schedule);
            clonedSchedule.key = `${clonedSchedule.id}_${dates.serializeDate(
              period.start
            )}`;
            clonedSchedule.nextDue = period.dueDate;
            clonedSchedule.nextPeriod = period;
            clonedSchedule.isProjected = true;
            return clonedSchedule;
          })
      )
    : [];

  const combinedSchedules = _.concat(schedules, projectedSchedules);

  const groupedByMonth = _.groupBy(combinedSchedules, (item) => {
    if (dates.dateIsBeforeThisMonth(item.nextDue)) {
      return "0"; // to sort at top
    }
    if (dates.dateIsAfterEndOfNextYear(item.nextDue)) {
      return "999999"; // to sort at bottom
    }
    return dates.formatDate(item.nextDue, "YYYYMM");
  });

  function monthGroupLabel(monthGroup) {
    if (monthGroup === "0") {
      const currMonth = dates.formatDate(moment(), "MMM YYYY");
      return `Before ${currMonth}`;
    }
    if (monthGroup === "999999") {
      const nextYear = dates.formatDate(moment().add(2, "y"), "YYYY");
      return `${nextYear} onwards...`;
    }
    const year = monthGroup.substring(0, 4);
    const month = dates.monthNumToShortName(monthGroup.substring(4));
    return `${month} ${year}`;
  }

  return Object.keys(groupedByMonth)
    .sort()
    .map((monthGroup) => ({
      id: monthGroup,
      label: monthGroupLabel(monthGroup),
      items: groupedByMonth[monthGroup],
    }));
}
