import { normalize } from "normalizr";
import schemas from "../schemas";
import api from "../services/api";
import { asyncAction } from "./actions";

// action types
export const TAG_LIST = "TAG_LIST";
export const TAG_DELETE = "TAG_DELETE";
export const TAG_GET_BY_ID = "TAG_GET_BY_ID";
export const TAG_PATCH = "TAG_PATCH";
export const TAG_GET_USAGES = "TAG_GET_USAGES";

export const fetchTags = (accessToken) =>
  asyncAction(TAG_LIST, api.tags.list(accessToken), {
    responseTransformer: (tags) => normalize(tags, schemas.arrayOfTags),
  });

export const getTagById = (tagId, accessToken) =>
  asyncAction(TAG_GET_BY_ID, api.tags.load(tagId, accessToken), {
    responseTransformer: (tag) => normalize(tag, schemas.tag),
  });

export const patchTag = (id, changes, description, accessToken) =>
  asyncAction(
    TAG_PATCH,
    api.tags.patch(id, changes, description, accessToken),
    {
      responseTransformer: (tag) => normalize(tag, schemas.tag),
    }
  );

export const fetchTagUsages = (tagId, accessToken) =>
  asyncAction(TAG_GET_USAGES, api.tags.usages(tagId, accessToken));

export const deleteTag = (tagId, accessToken) =>
  asyncAction(TAG_DELETE, api.tags.deleteTag(tagId, accessToken), {
    responseTransformer: () => ({ id: tagId }),
  });
