export const WORK_ITEM_ACTION_PREFIX = "WIACTION__";

// action types
export const WORK_ITEM_ACTION_START = `${WORK_ITEM_ACTION_PREFIX}_START`;
export const WORK_ITEM_ACTION_STOP = `${WORK_ITEM_ACTION_PREFIX}_STOP`;

export function startWorkItemAction(action) {
  return {
    type: WORK_ITEM_ACTION_START,
    activeAction: action,
  };
}

export function stopWorkItemAction() {
  return {
    type: WORK_ITEM_ACTION_STOP,
  };
}
