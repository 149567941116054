import { useAuth0 } from "@auth0/auth0-react";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { stopWorkItemAction } from "../../actions/workItemActions";
import { performActionOnWorkItem } from "../../actions/workItems";
import { getActiveWorkItemAction, getReferenceDataType } from "../../reducers";
import dates from "../../util/dates";
import { noteIcon, warningIcon } from "../../util/icons";
import {
  appliesByCondition,
  appliesByRegion,
} from "../../util/workItemTypeUtils";
import FormWrapper from "../forms/FormWrapper";
import ActionForm, { ACTION_FORM } from "../workitem/action/ActionForm";
import Notice from "./Notice";
import Shell from "./Shell";

const styles = () => ({});

const Composer = ({
  workItem,
  activeAction,
  workItemDefinition,
  performActionOnWorkItem: performAction,
  stopWorkItemAction: stopAction,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [isOpen, setIsOpen] = useState(false);
  const [composerType, setComposerType] = useState(null);
  const [isMinimized, setMinimized] = useState(false);
  const [requiresWidget, setRequiresWidget] = useState(false);
  const [formData, setFormData] = useState({});
  const [beforeSubmitActions, setBeforeSubmitActions] = useState([]);
  const [afterSubmitActions, setAfterSubmitActions] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);

  const addBeforeSubmitAction = (func) => {
    setBeforeSubmitActions([...beforeSubmitActions, func]);
  };

  const addAfterSubmitAction = (func) => {
    setAfterSubmitActions([...afterSubmitActions, func]);
  };

  const onMinimize = () => setMinimized(!isMinimized);

  const onClose = () => {
    setMinimized(false);
    setIsOpen(false);
    stopAction();
  };

  const onSubmit = async (formValues) => {
    const accessToken = await getAccessTokenSilently();
    beforeSubmitActions.forEach((action) => action(formValues));
    return performAction(
      workItem.id,
      {
        action: composerType,
        ...formValues,
      },
      accessToken
    )
      .then(() => afterSubmitActions.forEach((action) => action(formValues)))
      .then(onClose);
  };

  const getHelpText = (helpTexts) =>
    helpTexts
      .filter((helpTextAssignment) =>
        appliesByCondition(workItem, helpTextAssignment.condition)
      )
      .filter((helpTextAssignment) =>
        appliesByRegion(workItem, helpTextAssignment)
      )
      .map((helpTextAssignment) => helpTextAssignment.helpText)[0];

  // cancel action on mount and unmount
  useEffect(() => {
    onClose();
    return onClose;
  }, []);

  // if navigating between workitems on the same page - cancel action
  useEffect(onClose, [workItem.id]);

  useEffect(() => {
    if (activeAction !== null) {
      const { input, action } = activeAction;

      setIsOpen(true);
      setComposerType(action);
      setRequiresWidget(!!input);

      if (!input) {
        onClose();
        (async () => {
          const accessToken = await getAccessTokenSilently();
          performAction(workItem.id, { action }, accessToken);
        })();
      } else {
        const initialValues = {
          ...input.initialValues,
        };

        // set initial values to current work item values if they aren't explicitly set with initial values
        Object.keys(input.fields)
          .filter(
            (fieldName) => !!workItem[fieldName] && !initialValues[fieldName]
          )
          .forEach((fieldName) => {
            initialValues[fieldName] = workItem[fieldName];
          });

        setFormData({
          ...input,
          initialValues,
          helpText: getHelpText(input.helpTexts || []),
          dynamicValidations: input.dynamicValidations,
        });
      }
    } else {
      setIsOpen(false);
      setComposerType(null);
    }
  }, [getAccessTokenSilently, activeAction, workItem]);

  // --------------------------------------------------
  // HIDES COMPOSE WIDGET WHEN NO WIDGET ACTIONS ACTIVE
  // ...
  if (!isOpen || !requiresWidget) {
    return <></>;
  }

  const validateForm = (values) => {
    const errorMessages = [];
    const dynamicValidations = formData.dynamicValidations;
    dynamicValidations.forEach((dynamicValidation) => {
      let performValidations = true;
      if (dynamicValidation.whenAll && dynamicValidation.whenAll.length > 0) {
        performValidations =
          dynamicValidation.whenAll.filter((c) =>
            appliesByCondition(
              c.source === "EXISTING_OBJECT" ? workItem : values,
              c
            )
          ).length === dynamicValidation.whenAll.length;
      } else if (
        dynamicValidation.whenAny &&
        dynamicValidation.whenAny.length > 0
      ) {
        performValidations =
          dynamicValidation.whenAny.filter((c) =>
            appliesByCondition(
              c.source === "EXISTING_OBJECT" ? workItem : values,
              c
            )
          ).length > 0;
      }

      if (performValidations) {
        // Field validations
        dynamicValidation.validations.forEach((validation) => {
          const source =
            validation.source === "EXISTING_OBJECT" ? workItem : values;
          const valid = appliesByCondition(source, validation);
          if (!valid) {
            errorMessages.push(validation.message);
          }
        });
        // Component completeness errors
        dynamicValidation.requiresComplete
          .map((comp) =>
            workItem.componentProgress.find((cp) => cp.dataComponent === comp)
          )
          .filter(
            (compProgress) => !!compProgress && !!compProgress.completeErrors
          )
          .forEach((compProgress) => {
            errorMessages.push(...compProgress.completeErrors);
          });
      }
    });
    setValidationErrors(errorMessages);
    return errorMessages.length > 0 ? { form: errorMessages.join("\n") } : {};
  };

  const { name: typeDisplay, statuses } = workItemDefinition;

  const statusAssignment = statuses.find(
    (statusAss) => statusAss.status === workItem.status
  );
  const isClosed = statusAssignment && statusAssignment.endState;
  const isOverdue =
    !isClosed && dates.dateIsBeforeToday(dates.parseDate(workItem.dueDate));

  const closedNotice = isClosed
    ? `This ${typeDisplay.toLowerCase()} is closed`
    : null;

  const overdueNotice = isOverdue ? formData.overdueWarning : null;

  const getInitialValues = () => {
    const initialValues = {};
    Object.keys(formData.initialValues).forEach((key) =>
      _.set(initialValues, key, formData.initialValues[key])
    );
    return initialValues;
  };

  const notices = [];
  if (validationErrors.length > 0) {
    notices.push(
      <Notice
        key="validation"
        text={`Please correct the following errors:\n- ${validationErrors.join(
          "\n- "
        )}`}
        type="warning"
        icon={warningIcon()}
      />
    );
  } else {
    if (closedNotice) {
      notices.push(
        <Notice
          key="closed"
          text={closedNotice}
          type="warning"
          icon={warningIcon()}
        />
      );
    } else if (overdueNotice) {
      notices.push(
        <Notice
          key="overdue"
          text={overdueNotice}
          type="warning"
          icon={warningIcon()}
        />
      );
    }
    if (!!formData.helpText) {
      if (activeAction.action === "ADD_INTERNAL_NOTE") {
        notices.push(
          <Notice
            key="helpText1"
            text={formData.helpText}
            type="internal"
            icon={noteIcon()}
          />
        );
      } else {
        notices.push(
          <Notice key="helpText2" text={formData.helpText} type="general" />
        );
      }
    }
  }

  // ---------------------------
  // HANDLES ACTION FORMS
  // ...
  return (
    <Shell
      {...{
        isOpen,
        isMinimized,
        onClose,
        onMinimize,
      }}
      title={activeAction.name}
      notices={notices}
    >
      <FormWrapper
        submitButtonText={formData.submitText}
        cancelButtonText={formData.cancelText}
        renderActions={false}
        formComponent={ActionForm}
        formName={ACTION_FORM}
        onCancel={onClose}
        onSubmit={onSubmit}
        addBeforeSubmitAction={addBeforeSubmitAction}
        addAfterSubmitAction={addAfterSubmitAction}
        workItem={workItem}
        fields={formData.fields}
        actionType={activeAction.action}
        initialValues={getInitialValues()}
        metropolisIntegration={formData.metropolisIntegration}
        includeFlaggedFiles={formData.includeFlaggedFiles}
        warnForUnansweredQuestions={formData.warnForUnansweredQuestions}
        warnForNoEndorsements={formData.warnForNoEndorsements}
        warnForOpenActions={formData.warnForOpenActions}
        warnForRequiredEndorsements={formData.warnForRequiredEndorsements}
        validate={validateForm}
        enableReinitialize
      />
    </Shell>
  );
};

Composer.propTypes = {
  workItem: PropTypes.object.isRequired,
  activeAction: PropTypes.object,
  workItemDefinition: PropTypes.object.isRequired,
  performActionOnWorkItem: PropTypes.func.isRequired,
  stopWorkItemAction: PropTypes.func.isRequired,
};

Composer.defaultProps = {
  activeAction: null,
};

const mapStateToProps = (state, ownProps) => ({
  activeAction: getActiveWorkItemAction(state),
  workItemDefinition: getReferenceDataType(state, "WorkItemType").values[
    ownProps.workItem.type
  ].props.definition,
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    performActionOnWorkItem,
    stopWorkItemAction,
  })
)(Composer);
