import { Theme } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import ActionButton from "../../../components/common/ActionButton";
import ActionHeading from "../../../components/common/ActionHeading";
import BreadcrumbLink from "../../../components/common/BreadcrumbLink";
import Container from "../../../components/common/Container";
import HeaderBar from "../../../components/common/HeaderBar";
import InlineInputEditSaveCancel from "../../../components/common/InlineInputEditSaveCancel";
import PageHeading from "../../../components/common/PageHeading";
import PageSubheading from "../../../components/common/PageSubheading";
import Scorecard from "../../../components/common/Scorecard";
import FormCard from "../../../components/forms/formik/FormCard";
import { FieldLabels } from "../../../model/FieldLabels";
import { fundIcon, toggleOffIcon, toggleOnIcon } from "../../../util/icons";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingIndicator from "../../../components/LoadingIndicator";
import fundApi from "../../../services/api/funds";
import Error404 from "../../../components/error/Error404";
import { Fund } from "../../../model/fund";
import FundForm from "../../../components/forms/fund/FundForm";
import { connect } from "react-redux";
import { getLabels, getReferenceDataDescription } from "../../../reducers";
import logger from "../../../util/logger";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
  },
  card: {
    marginTop: theme.spacing(2),
  },
  organisationList: {
    marginBlockStart: 0,
    marginBlockEnd: 0,
  },
}));

interface Props {
  fieldLabels: FieldLabels;
  getRelationshipTypeDescription(relationshipType: string): string;
}

interface Params {
  fundId: string;
}

const FundPage: React.FC<Props> = ({
  fieldLabels,
  getRelationshipTypeDescription,
}: Props) => {
  const classes = useStyles();
  const { fundId } = useParams<Params>();
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [fund, setFund] = useState<Fund | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        setLoading(true);
        const fund = await fundApi.getById(fundId, accessToken);
        setFund(fund);
      } finally {
        setLoading(false);
      }
    })();
  }, [getAccessTokenSilently, fundId]);

  const updateFund = async (values: Partial<Fund>) => {
    const accessToken = await getAccessTokenSilently();
    try {
      const f = await fundApi.patch(
        fund?.id,
        values,
        "Updated fund",
        accessToken
      );
      setFund(f);
    } catch (e) {
      logger.error(e);
    }
  };

  if (!fund && loading) {
    return <LoadingIndicator size={60} />;
  }

  if (!fund && !loading) {
    return <Error404 />;
  }

  return (
    <div className={classes.root}>
      <HeaderBar>
        <BreadcrumbLink to="/admin/funds" label="Funds" includeArrow />
        <ActionHeading
          heading={
            <>
              <PageHeading
                icon={fundIcon()}
                heading={
                  <InlineInputEditSaveCancel
                    value={fund?.name}
                    onChange={(name) => updateFund({ name })}
                    validate={(value) => !!value}
                    minWidth="800px"
                  />
                }
              />
              <PageSubheading inset subheading={fund?.friendlyId} />
            </>
          }
          actions={[
            <ActionButton
              key="toggleActive"
              tooltip={fund?.active ? "Active" : "Inactive"}
              icon={fund?.active ? toggleOnIcon() : toggleOffIcon()}
              iconProps={fund?.active ? { color: "primary" } : {}}
              onClick={() => updateFund({ active: !fund?.active })}
            />,
          ]}
        />
      </HeaderBar>
      <Container>
        <FormCard
          className={classes.card}
          formTitle="Edit fund"
          title="Fund details"
          formComponent={FundForm}
          initialValues={{ ...fund }}
          readOnlyView={() => (
            <>
              <Scorecard label={fieldLabels.labels.description}>
                {fund?.description ? fund.description : "-"}
              </Scorecard>
              <Scorecard label={fieldLabels.labels.businessNumber}>
                {fund?.businessNumber ? fund.businessNumber : "-"}
              </Scorecard>
              <Scorecard label={fieldLabels.labels.region}>
                {fund?.region ? fund.region : "-"}
              </Scorecard>
              <Scorecard label={fieldLabels.labels.relationships}>
                <ul
                  className={classes.organisationList}
                  data-cy="organisationList"
                >
                  {fund?.relationships.map((relationship) => (
                    <li
                      key={`${relationship.organisation.id}-${relationship.relationshipType}`}
                      data-cy={relationship.organisation.name}
                    >
                      {relationship.organisation.name} -{" "}
                      {getRelationshipTypeDescription(
                        relationship.relationshipType
                      )}
                    </li>
                  ))}
                </ul>
              </Scorecard>
            </>
          )}
          onSave={updateFund}
          formProps={{
            fieldLabels,
          }}
        />
      </Container>
    </div>
  );
};

const mapStateToProps = (state: Map<string, unknown>) => ({
  fieldLabels: getLabels(state).Fund,
  getRelationshipTypeDescription: (relationshipType: string) =>
    getReferenceDataDescription(
      state,
      "RelationshipType",
      relationshipType,
      "-"
    ),
});

export default connect(mapStateToProps)(FundPage);
