import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { getReferenceDataType } from "../../../reducers";
import { scrollToFirstInvalidInput } from "../../../util/formHelpers";
import { getResolvedDefinition } from "../../../util/workItemTypeUtils";
import FormError from "../../common/FormError";
import WorkItemComponentFields from "./WorkItemComponentFields";

export const EDIT_WORK_ITEM_COMPONENT_FORM_NAME =
  "EDIT_WORK_ITEM_COMPONENT_FORM_NAME";

const EditWorkItemComponentForm = ({
  error,
  workItem,
  componentName,
  change,
  untouch,
  types,
  scrollToComponent,
}) => {
  const definition = getResolvedDefinition(
    types,
    workItem.type,
    workItem.parent?.type
  );

  return (
    <form autoComplete="off">
      {error && <FormError errorMessage={error} />}
      <WorkItemComponentFields
        workItemDefinition={definition}
        change={change}
        untouch={untouch}
        formName={EDIT_WORK_ITEM_COMPONENT_FORM_NAME}
        formType="UPDATE"
        componentName={componentName}
        existingWorkItem={workItem}
        template={workItem.template}
        hasParent={workItem.hasParent}
        scrollToComponent={scrollToComponent}
      />
    </form>
  );
};

EditWorkItemComponentForm.propTypes = {
  error: PropTypes.string,
  workItem: PropTypes.object.isRequired,
  componentName: PropTypes.string.isRequired,
  types: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  scrollToComponent: PropTypes.func,
};

EditWorkItemComponentForm.defaultProps = {
  error: null,
  scrollToComponent: null,
};

const mapStateToProps = (state) => ({
  types: getReferenceDataType(state, "WorkItemType"),
});

const form = reduxForm({
  form: EDIT_WORK_ITEM_COMPONENT_FORM_NAME,
  onSubmitFail: (errors) => scrollToFirstInvalidInput(errors),
});
const reduxData = connect(mapStateToProps);
export default compose(form, reduxData)(EditWorkItemComponentForm);
