import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { getReferenceDataType } from "../../../../reducers";
import WrappedTextField from "../../../forms/wrapper/WrappedTextField";
import { getRefDataMenuItems } from "../../../forms/menuItems";
import ComponentField from "../../../forms/wrapper/ComponentField";
import WrappedSelect from "../../../forms/wrapper/WrappedSelect";

const RiskControlVerificationFields = ({
  frequencyTypes,
  fieldLabels,
  customisations,
  change,
  untouch,
  formName,
}) => (
  <>
    <ComponentField
      component={WrappedTextField}
      name="riskControlVerificationDetails.testPlan"
      label={
        fieldLabels.nestedTypes.riskControlVerificationDetails.labels.testPlan
      }
      fullWidth
      multiline
      rowsMax={20}
      rows={8}
      customisations={customisations}
      change={change}
      untouch={untouch}
      formName={formName}
    />
    <ComponentField
      component={WrappedSelect}
      name="riskControlVerificationDetails.verificationFrequency"
      label={
        fieldLabels.nestedTypes.riskControlVerificationDetails.labels
          .verificationFrequency
      }
      fullWidth
      customisations={customisations}
      change={change}
      untouch={untouch}
      formName={formName}
    >
      {getRefDataMenuItems(frequencyTypes)}
    </ComponentField>
  </>
);

RiskControlVerificationFields.propTypes = {
  fieldLabels: PropTypes.object.isRequired,
  customisations: PropTypes.array,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,

  // redux
  frequencyTypes: PropTypes.object.isRequired,
};

RiskControlVerificationFields.defaultProps = {
  customisations: [],
};

const mapStateToProps = (state) => ({
  frequencyTypes: getReferenceDataType(state, "FrequencyType"),
});

export default connect(mapStateToProps, {})(RiskControlVerificationFields);
