import { SET_DRAGGING } from "../actions/drag";

export default (state = false, action) => {
  switch (action.type) {
    case SET_DRAGGING:
      return action.dragging;
    default:
      break;
  }
  return state;
};
