import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import MultilineText from "./MultilineText";

const styles = (theme) => ({
  root: {
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

const Scorecard = ({
  children,
  classes,
  label,
  value,
  labelClass,
  labelVariant,
}) => {
  // value is used (and styled) instead of children if supplied.
  let valueEl = children;

  if (value) {
    valueEl = <div>{value}</div>;
  } else if (children && typeof children === "string") {
    valueEl = <MultilineText text={children} />;
  }

  return (
    <div className={classes.root}>
      {label && (
        <Typography
          variant={labelVariant || "subtitle1"}
          className={labelClass}
          data-cy={label}
        >
          {label}
        </Typography>
      )}
      {valueEl}
    </div>
  );
};

Scorecard.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  value: PropTypes.any,
  labelVariant: PropTypes.string,
  labelClass: PropTypes.string,
};

Scorecard.defaultProps = {
  children: null,
  value: null,
  label: undefined,
  labelVariant: null,
  labelClass: null,
};

export default withStyles(styles)(Scorecard);
