import { withStyles } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import * as PropTypes from "prop-types";
import React, { useState } from "react";
import HtmlDraftJsField from "../../richtext/HtmlDraftJsField";

const styles = (theme) => ({
  label: {
    position: "relative",
  },
  container: {
    marginTop: theme.spacing(1) - theme.spacing(1) / 4,
    marginBottom: theme.spacing(1) - theme.spacing(1) / 4,
  },
  borderBottom: {
    borderBottom: `solid 1px ${theme.palette.grey[500]}`,
    paddingBottom: "1px",
    "&:hover": {
      borderBottom: "solid 2px",
      paddingBottom: 0,
    },
  },
});

const WrappedHtml = ({
  classes,
  input,
  label,
  margin,
  helperText,
  disabled,
  required,
  hideUnderline,
  meta: { touched, error, invalid },
  ...rest
}) => {
  const [focused, setFocused] = useState(false);

  return (
    <FormControl
      name={`position-${input.name}`}
      fullWidth
      margin={margin}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
    >
      <InputLabel
        className={classes.label}
        shrink
        focused={focused}
        required={required}
      >
        {label}
      </InputLabel>
      <div className={hideUnderline ? null : classes.borderBottom}>
        <div className={classes.container}>
          <HtmlDraftJsField
            value={input.value === "" ? null : input.value}
            onChange={disabled ? null : input.onChange}
            stylingSupported
            {...rest}
          />
        </div>
      </div>
      {touched && error && (
        <FormHelperText error={touched && invalid}>{error}</FormHelperText>
      )}
      {!(touched && error) && helperText && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

WrappedHtml.propTypes = {
  classes: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  margin: PropTypes.string,
  helperText: PropTypes.any,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  hideUnderline: PropTypes.bool,
};

WrappedHtml.defaultProps = {
  margin: "dense",
  helperText: "",
  disabled: false,
  required: false,
  hideUnderline: false,
};

export default withStyles(styles)(WrappedHtml);
