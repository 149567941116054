import { schema } from "normalizr";

const user = new schema.Entity("users");
const arrayOfUsers = new schema.Array(user);

const client = new schema.Entity("clients");
const arrayOfClients = new schema.Array(client);

const notification = new schema.Entity("notifications");
const arrayOfNotifications = new schema.Array(notification);

const refDataSchemas = (types) =>
  types.reduce((obj, type) => {
    // eslint-disable-next-line no-param-reassign
    obj[type] = new schema.Array(new schema.Entity(type));
    return obj;
  }, {});

const referenceData = new schema.Values(
  refDataSchemas([
    "AuditFindingRating",
    "AuditType",
    "AssessmentOutcome",
    "AssociationType",
    "AttachmentsInput",
    "BenefitAmountType",
    "BulkImportType",
    "CapitalImpactType",
    "ClientAge",
    "ClientGender",
    "ClientType",
    "CollaboratorType",
    "CommunicationFormat",
    "ComplaintIssue",
    "ComplaintOutcome",
    "ComplaintProductService",
    "ComplianceSourceType",
    "ComplianceProduct",
    "ComplianceSource",
    "ContentVersionStatus",
    "ControlEffectiveness",
    "CoverType",
    "CustomSearchType",
    "DependantType",
    "DocumentFlag",
    "EndorsementType",
    "ExecutionMethod",
    "ExemptionType",
    "FormStatus",
    "FrequencyType",
    "Fund",
    "GroupCompany",
    "IncidentActionType",
    "IncidentCause",
    "IncidentOriginType",
    "IncidentIdentifier",
    "JustificationNotesInput",
    "License",
    "LifecycleStatus",
    "MentionStatus",
    "OperationalFrequencyType",
    "OperationalToleranceStatus",
    "OrganisationType",
    "RelationshipType",
    "PolicyLegislation",
    "Priority",
    "RecurrenceType",
    "Region",
    "ReportableSituation",
    "Regulator",
    "RemediationType",
    "RiskAppetite",
    "RiskCategory",
    "RiskConsequenceRating",
    "RiskControlType",
    "RiskLikelihood",
    "RiskRating",
    "Role",
    "Service",
    "ServiceProvider",
    "SnippetType",
    "SubscriptionEventType",
    "TagType",
    "WebsiteDifferenceStatus",
    "WorkItemCategory",
    "WorkItemOutcome",
    "WorkItemSlaDayType",
    "WorkItemStatus",
    "WorkItemTab",
    "WorkItemType",
  ]),
  (input, parent, key) => key
);

const finalDocument = new schema.Entity("finalDocument");
const arrayOfFinalDocuments = new schema.Array(finalDocument);

const workItemSchedule = new schema.Entity("workItemSchedules");
const arrayOfWorkItemSchedules = new schema.Array(workItemSchedule);

const workItem = new schema.Entity("workItem");
const arrayOfWorkItems = new schema.Array(workItem);

const workItemAssociation = new schema.Entity("workItemAssociation");
const arrayOfWorkItemAssociations = new schema.Array(workItemAssociation);

const sla = new schema.Entity("sla");
const arrayOfSlas = new schema.Array(sla);

const subscription = new schema.Entity("subscription");
const arrayOfSubscriptions = new schema.Array(subscription);

const auditReport = new schema.Entity("auditReport");
const arrayOfAuditReports = new schema.Array(auditReport);

const control = new schema.Entity("control");
const arrayOfControls = new schema.Array(control);

const risk = new schema.Entity("risk");
const arrayOfRisks = new schema.Array(risk);

const riskControl = new schema.Entity("riskControl");
const arrayOfRiskControls = new schema.Array(riskControl);

const organisation = new schema.Entity("organisation");
const arrayOfOrganisations = new schema.Array(organisation);

const product = new schema.Entity("product");
const arrayOfProducts = new schema.Array(product);

const question = new schema.Entity("question");
const arrayOfQuestions = new schema.Array(question);

const businessServiceArea = new schema.Entity("businessServiceArea");
const arrayOfBusinessServiceAreas = new schema.Array(businessServiceArea);

const media = new schema.Entity("media");
const arrayOfMedia = new schema.Array(media);

const snippet = new schema.Entity("snippet");
const arrayOfSnippets = new schema.Array(snippet);

const tag = new schema.Entity("tag");
const arrayOfTags = new schema.Array(tag);

const digitalAsset = new schema.Entity("digitalAsset");
const arrayOfDigitalAssets = new schema.Array(digitalAsset);

const websiteSnapshot = new schema.Entity("websiteSnapshot");
const arrayOfWebsiteSnapshots = new schema.Array(websiteSnapshot);

const websiteDifference = new schema.Entity("websiteDifference");
const arrayOfWebsiteDifferences = new schema.Array(websiteDifference);

const mention = new schema.Entity("mention");
const arrayOfMentions = new schema.Array(mention);

const userGroup = new schema.Entity("userGroup");
const arrayOfUserGroups = new schema.Array(userGroup);

const savedSearch = new schema.Entity("savedSearch");
const arrayOfSavedSearches = new schema.Array(savedSearch);

const draft = new schema.Entity("draft");
const arrayOfDrafts = new schema.Array(draft);

export default {
  user,
  arrayOfUsers,
  client,
  arrayOfClients,
  notification,
  arrayOfNotifications,
  referenceData,
  finalDocument,
  arrayOfFinalDocuments,
  workItemSchedule,
  arrayOfWorkItemSchedules,
  workItem,
  arrayOfWorkItems,
  workItemAssociation,
  arrayOfWorkItemAssociations,
  sla,
  arrayOfSlas,
  subscription,
  arrayOfSubscriptions,
  auditReport,
  arrayOfAuditReports,
  control,
  arrayOfControls,
  risk,
  arrayOfRisks,
  riskControl,
  arrayOfRiskControls,
  organisation,
  arrayOfOrganisations,
  product,
  arrayOfProducts,
  question,
  arrayOfQuestions,
  businessServiceArea,
  arrayOfBusinessServiceAreas,
  media,
  arrayOfMedia,
  snippet,
  arrayOfSnippets,
  tag,
  arrayOfTags,
  digitalAsset,
  arrayOfDigitalAssets,
  websiteSnapshot,
  arrayOfWebsiteSnapshots,
  websiteDifference,
  arrayOfWebsiteDifferences,
  mention,
  arrayOfMentions,
  userGroup,
  arrayOfUserGroups,
  savedSearch,
  arrayOfSavedSearches,
  draft,
  arrayOfDrafts,
};
