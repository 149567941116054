import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { getLabels, getReferenceDataDescription } from "../../reducers";
import dates from "../../util/dates";
import DateAgo from "../common/DateAgo";
import Scorecard from "../common/Scorecard";

const RiskControlDetailsFieldsReadOnly = ({
  riskControl,
  fieldLabels,
  getOperationalFrequencyTypeDescription,
  getRiskControlTypeDescription,
}) => {
  const lastVerified = riskControl && dates.parseDate(riskControl.lastVerified);

  return (
    <>
      <Scorecard
        label={fieldLabels.labels.type}
        data-cy={fieldLabels.labels.type}
      >
        {getRiskControlTypeDescription(riskControl.type)}
      </Scorecard>
      <Scorecard
        label={fieldLabels.labels.critical}
        data-cy={fieldLabels.labels.critical}
      >
        {riskControl.critical ? "Yes" : "No"}
      </Scorecard>
      <Scorecard
        label={fieldLabels.labels.owner}
        data-cy={fieldLabels.labels.owner}
      >
        {riskControl.owner ? riskControl.owner.name : "-"}
      </Scorecard>
      <Scorecard
        label={fieldLabels.labels.name}
        data-cy={fieldLabels.labels.name}
      >
        {riskControl.name}
      </Scorecard>
      <Scorecard
        label={fieldLabels.labels.description}
        data-cy={fieldLabels.labels.description}
      >
        {riskControl.description}
      </Scorecard>
      <Scorecard
        label={fieldLabels.labels.operationalFrequency}
        data-cy={fieldLabels.labels.operationalFrequency}
      >
        {getOperationalFrequencyTypeDescription(
          riskControl.operationalFrequency
        )}
      </Scorecard>
      <Scorecard
        label={fieldLabels.labels.lastVerified}
        data-cy={fieldLabels.labels.lastVerified}
      >
        {lastVerified ? (
          <>
            {dates.formatDateLong(lastVerified)} <br />
            <DateAgo value={lastVerified} expandable={false} />
          </>
        ) : (
          "-"
        )}
      </Scorecard>
    </>
  );
};

RiskControlDetailsFieldsReadOnly.propTypes = {
  fieldLabels: PropTypes.object.isRequired,
  getRiskControlTypeDescription: PropTypes.func.isRequired,
  getOperationalFrequencyTypeDescription: PropTypes.func.isRequired,
  riskControl: PropTypes.object,
};

RiskControlDetailsFieldsReadOnly.defaultProps = {
  riskControl: null,
};

const mapStateToProps = (state) => ({
  fieldLabels: getLabels(state).RiskControl,
  getRiskControlTypeDescription: (id) =>
    getReferenceDataDescription(state, "RiskControlType", id, "-"),
  getOperationalFrequencyTypeDescription: (id) =>
    getReferenceDataDescription(state, "OperationalFrequencyType", id, "-"),
});

export default connect(mapStateToProps)(RiskControlDetailsFieldsReadOnly);
