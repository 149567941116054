import { useAuth0 } from "@auth0/auth0-react";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { length, required } from "redux-form-validators";
import { uploadFiles } from "../../../../actions/files";
import { getLabels } from "../../../../reducers";
import { scrollToFirstInvalidInput } from "../../../../util/formHelpers";
import logger from "../../../../util/logger";
import { yesNoOptions } from "../../../../util/radioOptions";
import FormError from "../../../common/FormError";
import OverlayDropzone from "../../../common/OverlayDropzone";
import WrappedFilePicker from "../../../forms/wrapper/WrappedFilePicker";
import WrappedRadioGroup from "../../../forms/wrapper/WrappedRadioGroup";
import WrappedRichTextMentions from "../../../forms/wrapper/WrappedRichTextMentions";

export const QUESTION_RESPONSE_FORM_NAME = "QUESTION_RESPONSE_FORM_NAME";

const QuestionResponseForm = ({
  justificationNotesInput,
  attachmentsInput,
  fieldLabels,
  selectedCompliant,
  error,
  localUploadFiles,
  additionalFields,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [notesRequired, setNotesRequired] = useState(false);
  const [attachmentsRequired, setAttachmentsRequired] = useState(false);
  const [uploadNamespace] = useState("workitem-question-response");

  const handleDrop = async (acceptedFiles) => {
    const accessToken = await getAccessTokenSilently();
    localUploadFiles(acceptedFiles, uploadNamespace, accessToken);
  };

  useEffect(() => {
    switch (justificationNotesInput) {
      case "MANDATORY_ALWAYS":
        setNotesRequired(true);
        break;
      case "MANDATORY_WHEN_NO":
        setNotesRequired(!selectedCompliant);
        break;
      default:
        logger.error(
          "Don't know JustificationNotesInput",
          justificationNotesInput
        );
        break;
    }

    switch (attachmentsInput) {
      case "MANDATORY":
        setAttachmentsRequired(true);
        break;
      case "NON_MANDATORY":
        setAttachmentsRequired(false);
        break;
      default:
        logger.error("Don't know AttachmentsInput", attachmentsInput);
        break;
    }
  }, [attachmentsInput, justificationNotesInput, selectedCompliant]);

  return (
    <OverlayDropzone onDrop={handleDrop} multiple readonly={false}>
      <form autoComplete="off">
        {error && <FormError errorMessage={error} />}
        {additionalFields && additionalFields}
        <Field
          name="compliant"
          component={WrappedRadioGroup}
          label={fieldLabels.nestedTypes.responses.labels.compliant}
          required
          validate={required({
            msg: `${fieldLabels.nestedTypes.responses.labels.compliant} is required`,
          })}
          options={yesNoOptions}
          row
        />
        <Field
          component={WrappedRichTextMentions}
          name="justificationNotes"
          label={fieldLabels.nestedTypes.responses.labels.justificationNotes}
          required={notesRequired}
          validate={
            notesRequired
              ? required({
                  msg: `${fieldLabels.nestedTypes.responses.labels.justificationNotes} is required`,
                })
              : null
          }
          fullWidth
          datasource={[]}
          small
        />
        <Field
          name="attachments"
          label={fieldLabels.nestedTypes.responses.labels.attachments}
          component={WrappedFilePicker}
          fullWidth
          required={attachmentsRequired}
          validate={
            attachmentsRequired
              ? length({
                  min: 1,
                  message: `${fieldLabels.nestedTypes.responses.labels.attachments} is required`,
                })
              : null
          }
          uploadNamespace={uploadNamespace}
        />
      </form>
    </OverlayDropzone>
  );
};

QuestionResponseForm.propTypes = {
  error: PropTypes.string,
  justificationNotesInput: PropTypes.string.isRequired,
  attachmentsInput: PropTypes.string.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  localUploadFiles: PropTypes.func.isRequired,
  selectedCompliant: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  additionalFields: PropTypes.object,
};

QuestionResponseForm.defaultProps = {
  error: null,
  additionalFields: null,
  selectedCompliant: false,
};

const reduxFormSelector = formValueSelector(QUESTION_RESPONSE_FORM_NAME);

const mapStateToProps = (state) => ({
  selectedCompliant: reduxFormSelector(state, "compliant"),
  fieldLabels: getLabels(state).Question,
});

const form = reduxForm({
  form: QUESTION_RESPONSE_FORM_NAME,
  onSubmitFail: (errors) => scrollToFirstInvalidInput(errors),
});
const reduxData = connect(mapStateToProps, {
  localUploadFiles: uploadFiles,
});
export default compose(form, reduxData)(QuestionResponseForm);
