/**
 * Get the description of a reference data item.
 *
 * @param id the id of the reference data item.
 * @param referenceData the reference data object.
 * @param defaultValue the value to return if no matching item is found (defaults to empty string).
 * @return {string} the reference data item description.
 */
export const getDescription = (id, referenceData, defaultValue = "") => {
  const value = referenceData.values[id];
  return value ? value.description : defaultValue;
};

const SUPPORTED_FILE_TYPES = [
  "application/pdf",
  "image/*",
  "audio/*",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.ms-powerpoint",
  "application/vnd.ms-outlook",
  ".msg",
  ".doc",
  ".docx",
  ".ppt",
  ".pptx",
  ".xls",
  ".xlsx",
];

export const ACCEPTED_FILES = SUPPORTED_FILE_TYPES.join(",");
