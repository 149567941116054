import { useAuth0 } from "@auth0/auth0-react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { email, required } from "redux-form-validators";
import {
  getFilteredReferenceDataType,
  getLabels,
  getLoggedInUser,
} from "../../../reducers";
import usersApi from "../../../services/api/users";
import { roles } from "@certane/arcadia-web-components";
import { scrollToFirstInvalidInput } from "../../../util/formHelpers";
import Avatar from "../../common/Avatar.tsx";
import FormError from "../../common/FormError";
import { getCategoryMenuItems, getRefDataMenuItems } from "../menuItems";
import WrappedListPicker from "../wrapper/WrappedListPicker";
import WrappedSelect from "../wrapper/WrappedSelect";
import WrappedTextField from "../wrapper/WrappedTextField";

const styles = {};

const CreateSlaForm = ({
  error,
  change,
  workItemTypes,
  eventTypes,
  selectedWorkItemType,
  loggedInUser,
  labels,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [useEmailAddressOnly, setUseEmailAddressOnly] = useState(false);
  const [isAdmin] = useState(
    loggedInUser
      ? _.intersection(roles.ADMIN_ROLES, loggedInUser.roles).length > 0
      : false
  );
  const [excludedWorkItemTypes] = useState(
    workItemTypes.ids.filter(
      (id) =>
        !loggedInUser ||
        _.intersection(
          workItemTypes.values[id].props.definition.visibleTo,
          loggedInUser.roles
        ).length === 0
    )
  );

  const asyncUserOptionsFetch = async (
    pickerFilter,
    pagination,
    abortController
  ) => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = {
      ...pickerFilter,
      limit: pagination.pageSize,
      offset: pagination.offset,
      roles: roles.AGENT_ROLES,
      orderByField: "name",
    };
    return usersApi.search(searchParameters, abortController, accessToken);
  };

  useEffect(() => {
    change("category", null);
  }, [selectedWorkItemType]);

  const getCategoryOptions = () => {
    if (selectedWorkItemType) {
      const { categories } =
        workItemTypes.values[selectedWorkItemType].props.definition;
      return getCategoryMenuItems(categories);
    }
    return [];
  };

  const fieldLabels = labels.WorkItemSubscription;

  return (
    <form autoComplete="off">
      {error && <FormError errorMessage={error} />}
      <Field
        component={WrappedSelect}
        name="workItemType"
        label={fieldLabels.labels.workItemType}
        validate={required({
          msg: `${fieldLabels.labels.workItemType} is required`,
        })}
        required
        fullWidth
      >
        {getRefDataMenuItems(workItemTypes, excludedWorkItemTypes)}
      </Field>
      <Field
        component={WrappedSelect}
        disabled={!selectedWorkItemType}
        name="category"
        label={fieldLabels.labels.category}
        fullWidth
      >
        {getCategoryOptions()}
      </Field>
      <Field
        component={WrappedSelect}
        name="eventType"
        label={fieldLabels.labels.eventType}
        validate={required({
          msg: `${fieldLabels.labels.eventType} is required`,
        })}
        required
        fullWidth
      >
        {getRefDataMenuItems(eventTypes)}
      </Field>
      {isAdmin && (
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={useEmailAddressOnly}
              onChange={(event, checked) => setUseEmailAddressOnly(checked)}
            />
          }
          label="Use email address instead of Arcadia user"
        />
      )}
      {!useEmailAddressOnly && isAdmin && (
        <Field
          name="subscriber"
          component={WrappedListPicker}
          datasource={asyncUserOptionsFetch}
          isMulti={false}
          submitOnChange
          required
          validate={required({
            msg: `${fieldLabels.labels.subscriber} is required`,
          })}
          label={fieldLabels.labels.subscriber}
          fullWidth
          toOption={(user) => ({
            label: user.name || "Unknown",
            id: user.id,
            email: user.email,
          })}
          fromOption={(option) => ({
            name: option.label,
            id: option.id,
            email: option.email,
          })}
          renderIcon={(user, size) => (
            <Avatar email={user.email} name={user.name} size={size} round />
          )}
        />
      )}
      {useEmailAddressOnly && isAdmin && (
        <Field
          name="subscriptionEmail"
          component={WrappedTextField}
          label="Subscription email"
          validate={[
            required({ msg: "Subscription email is required" }),
            email({ msg: "Subscription email is invalid" }),
          ]}
          fullWidth
          required
          helperText="Use this option if the notifications are to go to an email address rather than an Arcadia user"
        />
      )}
    </form>
  );
};

CreateSlaForm.propTypes = {
  error: PropTypes.string,
  change: PropTypes.func.isRequired,
  workItemTypes: PropTypes.object.isRequired,
  eventTypes: PropTypes.object.isRequired,
  selectedWorkItemType: PropTypes.string,
  loggedInUser: PropTypes.object,
  labels: PropTypes.object.isRequired,
};

CreateSlaForm.defaultProps = {
  error: null,
  selectedWorkItemType: null,
  loggedInUser: null,
};

export const CREATE_SUBSCRIPTION_FORM = "CREATE_SUBSCRIPTION_FORM";
const reduxFormSelector = formValueSelector(CREATE_SUBSCRIPTION_FORM);

const mapStateToProps = (state) => ({
  workItemTypes: getFilteredReferenceDataType(
    state,
    "WorkItemType",
    (type) => type.props.definition.module !== "UNUSED"
  ),
  eventTypes: getFilteredReferenceDataType(
    state,
    "SubscriptionEventType",
    (type) => ["CREATION", "CLOSURE"].includes(type.id)
  ),
  selectedWorkItemType: reduxFormSelector(state, "workItemType"),
  loggedInUser: getLoggedInUser(state),
  labels: getLabels(state),
});

const form = reduxForm({
  form: CREATE_SUBSCRIPTION_FORM,
  onSubmitFail: (errors) => scrollToFirstInvalidInput(errors),
});
const reduxData = connect(mapStateToProps);
export default compose(form, reduxData)(withStyles(styles)(CreateSlaForm));
