import { combineReducers } from "redux";
import {
  SUBSCRIPTION_CREATE,
  SUBSCRIPTION_LIST,
  SUBSCRIPTION_REMOVE,
} from "../actions/subscriptions";
import { publishActionToast } from "../services/toasts";

const successToasts = {
  [SUBSCRIPTION_CREATE]: "Created subscription successfully",
};

const byId = (state = {}, action) => {
  publishActionToast(action, "SUBSCRIPTION_", successToasts);

  switch (action.type) {
    case `${SUBSCRIPTION_LIST}_SUCCESS`:
    case `${SUBSCRIPTION_CREATE}_SUCCESS`:
      return {
        ...state,
        ...action.response.entities.subscription,
      };

    // Remove a subscription
    case `${SUBSCRIPTION_REMOVE}_SUCCESS`:
      const idToRemove = action.response.id;
      return {
        ...state,
        [idToRemove]: undefined,
      };

    default:
      break;
  }

  return state;
};

const defaultActive = {
  activeList: [],
  activeSubscription: null,
  error: null,
};

const active = (state = defaultActive, action) => {
  switch (action.type) {
    case `${SUBSCRIPTION_LIST}_SUCCESS`:
      return {
        ...state,
        activeList: [...action.response.result],
      };

    case `${SUBSCRIPTION_CREATE}_SUCCESS`:
      return {
        ...state,
        activeList:
          state.activeList.indexOf(action.response.result) === -1
            ? [...state.activeList, action.response.result]
            : state.activeList,
        activeSubscription: action.response.result,
      };

    case `${SUBSCRIPTION_REMOVE}_SUCCESS`:
      const idToRemove = action.response.id;
      return {
        ...state,
        activeList: [...state.activeList.filter((id) => id !== idToRemove)],
        activeSubscription:
          state.activeSubscription === idToRemove
            ? null
            : state.activeSubscription,
      };

    default:
      break;
  }

  return state;
};

export default combineReducers({
  active,
  byId,
});

export const getActiveSubscription = (state) =>
  state.active.activeSubscription
    ? state.byId[state.active.activeSubscription]
    : null;
export const getSubscriptions = (state) =>
  state.active.activeList.map((id) => state.byId[id]);
