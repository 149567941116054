import { useAuth0 } from "@auth0/auth0-react";
import { roles } from "@certane/arcadia-web-components";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { SubmissionError } from "redux-form";
import { sendRiskActionUpdateRequests } from "../../../actions/risks";
import ActionButton from "../../../components/common/ActionButton";
import ActionHeading from "../../../components/common/ActionHeading";
import Container from "../../../components/common/Container";
import HeaderBar from "../../../components/common/HeaderBar";
import PageHeading from "../../../components/common/PageHeading";
import FormDialog from "../../../components/forms/FormDialog";
import RiskActionSendUpdateRequestConfirmationForm, {
  RISK_ACTION_UPDATE_REQUEST_CONFIRMATION_FORM_NAME,
} from "../../../components/forms/risk/RiskActionSendUpdateRequestConfirmationForm";
import RiskActionFilters from "../../../components/risk/RiskActionFilters";
import RiskActionListing from "../../../components/risk/RiskActionListing";
import {
  getLoggedInUser,
  getReferenceDataType,
  getWorkItems,
  getWorkItemSearchPagination,
} from "../../../reducers";
import {
  clearIcon,
  riskActionRequestUpdate,
  workItemIcon,
} from "../../../util/icons";
import useLocationStateFilter from "../../../util/locationStateFilter";
import usePersistedState from "../../../util/persistedState";
import { createWorkItemSearchParameters } from "../../../util/searchParameterUtils";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  contents: {
    marginBottom: "75px",
  },
}));

const defaultFilter = {
  ownerIds: [],
  riskRegisters: [],
  subTypes: [],
};

const Actions = ({
  history,
  loggedInUser,
  localSendRiskActionUpdateRequests,
  pagination,
  statuses,
  types,
  active,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();
  const [
    sendUpdateRequestConfirmationDialogOpen,
    setSendUpdateRequestConfirmationDialogOpen,
  ] = useState(false);
  const [sortBy, setSortBy] = usePersistedState(
    `risk-action-listing-sort-${loggedInUser.email}`,
    {
      field: "created",
      direction: "desc",
    }
  );
  const [filter, setFilter] = usePersistedState(
    `risk-action-listing-filter-${loggedInUser.email}`,
    defaultFilter,
    ["textSearch"]
  );
  useLocationStateFilter(setFilter, history);

  const clearFilter = () => setFilter({ ...defaultFilter });

  const updateFilter = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  const getOrderBy = () => {
    const prefix = sortBy.direction === "desc" ? "-" : "";
    return `${prefix}${sortBy.field}`;
  };

  const buildSearchParameters = () =>
    createWorkItemSearchParameters(
      {
        ...filter,
        types: ["RISK_ACTION"],
      },
      statuses,
      types,
      loggedInUser,
      pagination,
      getOrderBy()
    );

  const sendUpdateRequests = async (values) => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = buildSearchParameters();
    localSendRiskActionUpdateRequests(
      searchParameters,
      values.message || "",
      accessToken
    )
      .then(() => setSendUpdateRequestConfirmationDialogOpen(false))
      .catch((error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  const isComplianceOwner = loggedInUser
    ? _.intersection(
        [roles.COMPLIANCE_OWNER, ...roles.ADMIN_ROLES],
        loggedInUser.roles
      ).length > 0
    : false;
  const usingClosedFilter = filter.status === "CLOSED";
  const hasSelectedActions = active && active.length > 0;
  const canSendNotifications =
    hasSelectedActions && isComplianceOwner && !usingClosedFilter;

  const actions = [
    <ActionButton
      key="clearFilter"
      tooltip="Clear filters"
      icon={clearIcon()}
      onClick={clearFilter}
      data-cy="clear-filters-button"
    />,
  ];
  if (canSendNotifications) {
    actions.push(
      <ActionButton
        tooltip="Request update"
        icon={riskActionRequestUpdate()}
        onClick={() => setSendUpdateRequestConfirmationDialogOpen(true)}
      />
    );
  }

  return (
    <div className={classes.root}>
      <FormDialog
        title={`Send ${pagination.resultCount} update requests?`}
        submitButtonText="Confirm"
        formComponent={RiskActionSendUpdateRequestConfirmationForm}
        formName={RISK_ACTION_UPDATE_REQUEST_CONFIRMATION_FORM_NAME}
        open={sendUpdateRequestConfirmationDialogOpen}
        onCancel={() => setSendUpdateRequestConfirmationDialogOpen(false)}
        onSubmit={sendUpdateRequests}
      />
      <HeaderBar fluid>
        <ActionHeading
          heading={
            <PageHeading
              icon={workItemIcon("RISK_ACTION")}
              heading="Risk actions"
            />
          }
          actions={actions}
        />
      </HeaderBar>
      <RiskActionFilters filter={filter} updateFilter={updateFilter} />
      <Container fluid className={classes.contents}>
        <RiskActionListing
          history={history}
          filter={filter}
          clearFilter={clearFilter}
          sortBy={sortBy}
          updateSort={setSortBy}
        />
      </Container>
    </div>
  );
};

Actions.propTypes = {
  history: PropTypes.object.isRequired,
  pagination: PropTypes.object.isRequired,
  active: PropTypes.array.isRequired,
  loggedInUser: PropTypes.object,
  localSendRiskActionUpdateRequests: PropTypes.func.isRequired,
  statuses: PropTypes.object.isRequired,
  types: PropTypes.object.isRequired,
};

Actions.defaultProps = {
  loggedInUser: null,
};

const mapStateToProps = (state) => ({
  active: getWorkItems(state),
  loggedInUser: getLoggedInUser(state),
  pagination: getWorkItemSearchPagination(state),
  statuses: getReferenceDataType(state, "WorkItemStatus"),
  types: getReferenceDataType(state, "WorkItemType"),
});

export default connect(mapStateToProps, {
  localSendRiskActionUpdateRequests: sendRiskActionUpdateRequests,
})(Actions);
