import React, { Fragment, useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Chip, Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import moment from "moment";
import _ from "lodash";
import pluralize from "pluralize";
import classNames from "classnames";
import { isClosed } from "../components/overview/WorkItemStatus";
import Entry from "./Entry";
import Date from "./Date";
import UserAction from "./UserAction";
import Association from "./widgets/Association";
import Email from "./widgets/Email";
import {
  InitialComment,
  StatusChange,
  InternalNote,
  Correspondence,
  Documents,
  Diff,
  Legacy,
  Create,
  UserGroup,
  Endorsements,
  SingleUserChange,
} from "./widgets";
import logger from "../../../util/logger";
import MetropolisLinks from "./widgets/MetropolisLinks";
import PrivateNote from "./widgets/PrivateNote";
import QuestionResponse from "./widgets/QuestionResponse";
import Users from "./widgets/Users";
import GenericComment from "./widgets/GenericComment";

const styles = (theme) => ({
  paper: {
    overflow: "hidden",
  },
  header: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.swatch.grey4}`,
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
    },
  },
  filters: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.swatch.secondary,
    marginTop: theme.spacing(3),
    [theme.breakpoints.up("lg")]: {
      marginTop: 0,
    },
  },
  filterLabel: {
    flexShrink: "0",
    flexGrow: "0",
    flexBasis: "auto",
    marginRight: theme.spacing(2),
  },
  chips: {
    "& > * + *": {
      marginLeft: theme.spacing(1),
    },
  },
  chip: {
    backgroundColor: theme.palette.swatch.grey5,
  },
  activeChip: {
    color: "white",
    backgroundColor: theme.palette.primary.main,
    "": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  activity: {
    "& > * + *": {
      marginTop: "1px",
    },
  },
});

const getStatus = (entry) => entry.status || "UNKNOWN";
const getGroupDate = (entry) => moment(entry.created).startOf("day").unix();
const getOrderDate = (entry) => moment(entry.created).unix();
const orderByDate = (entries) => _.orderBy(entries, "orderTimestamp", "desc");

const isCloseAction = (entry) => {
  const pathsWithStatus = entry.fieldChanges.filter((change) =>
    _.includes(change.path, "status")
  );

  if (pathsWithStatus.length > 0) {
    const first = _.head(pathsWithStatus);

    return _.head(first.after).value === "CLOSED";
  }
  return false;
};

const getNewOutcome = (entry) => {
  const pathsWithOutcome = entry.fieldChanges.filter((change) =>
    _.includes(change.path, "outcome")
  );

  if (pathsWithOutcome.length > 0) {
    const first = _.head(pathsWithOutcome);
    const afterValue = _.head(first.after);
    if (afterValue) {
      return afterValue.value;
    }
  }
  return null;
};

const injectTimestamps = (entry) => ({
  ...entry,
  groupTimestamp: getGroupDate(entry),
  orderTimestamp: getOrderDate(entry),
});

const isNotNotification = (entry) => _.get(entry, "type") !== "NOTIFICATION";

const getLeadWithDateComponent = (entry, lastGroupTimestamp) => {
  if (entry.groupTimestamp !== lastGroupTimestamp) {
    const component = (
      <Entry status={getStatus(entry)} isCloseAction={false}>
        <Date time={entry.created} />
      </Entry>
    );
    return [component, entry.groupTimestamp];
  }
  return [<></>, lastGroupTimestamp];
};

const chipClasses = (classes, chipFilter, selectedFilter) =>
  classNames({
    [classes.chip]: true,
    [classes.activeChip]: chipFilter === selectedFilter,
  });

const filterTypes = [
  {
    value: "CORRESPONDENCE",
    match: (entry) =>
      _.has(entry, "comment") && entry.comment.type === "CORRESPONDENCE",
    label: "Correspondence",
  },
  {
    value: "EMAIL",
    match: (entry) => _.has(entry, "email"),
    label: "Email",
  },
  {
    value: "INTERNAL_NOTES",
    match: (entry) =>
      _.has(entry, "comment") && entry.comment.type === "INTERNAL",
    label: "Internal notes",
  },
  {
    value: "IMPORTANT_NOTES",
    match: (entry) =>
      _.has(entry, "comment") &&
      _.includes(["INTERNAL", "PRIVATE"], entry.comment.type) &&
      entry.comment.important,
    label: "Important notes",
  },
  {
    value: "PRIVATE_NOTES",
    match: (entry) =>
      _.has(entry, "comment") && entry.comment.type === "PRIVATE",
    label: "Private notes",
  },
  {
    value: "ENDORSEMENT",
    match: (entry) =>
      _.has(entry, "actionPerformed") && entry.actionPerformed === "ENDORSE",
    label: "Endorsements",
  },
  {
    value: "QUESTIONS",
    match: (entry) =>
      _.has(entry, "actionPerformed") &&
      entry.actionPerformed === "RESPOND_TO_QUESTION",
    label: "Questions",
  },
];

const ActivityStream = ({
  classes,
  workItem,
  definition,
  loggedInUser,
  activities,
  scrollToActivityId,
  onScrolledToActivity,
}) => {
  // Used to manage date breaks between streams of activity.
  let lastGroupTimestamp = 0;
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState(null);
  const [filters, setFilters] = useState([]);
  const [closed, setClosed] = useState(isClosed(workItem, definition));

  useEffect(() => {
    if (scrollToActivityId) {
      // eslint-disable-next-line no-undef
      const element = document.getElementById(
        `activity-stream-${scrollToActivityId}`
      );
      if (element) {
        logger.info("found element for activity", scrollToActivityId);

        setTimeout(
          () =>
            element.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "center",
            }),
          200
        );

        if (onScrolledToActivity) {
          onScrolledToActivity();
        }
      } else {
        logger.warn("No activity found with id", scrollToActivityId);
      }
    }
  }, [data, scrollToActivityId]);

  useEffect(() => {
    logger.warn(
      `[ACTIVITY-STREAM] Workitem mutated, current workitem set as ${workItem.id}`
    );
  }, [workItem]);

  useEffect(() => {
    const rawEntries = activities;

    logger.info(
      `[ACTIVITY-STREAM] Found ${pluralize(
        "entry",
        rawEntries.length,
        true
      )} for the workitem ${workItem.id}`
    );

    const entries = rawEntries.filter(isNotNotification).map(injectTimestamps);

    logger.info(
      `[ACTIVITY-STREAM] Preparing to render ${pluralize(
        "entry",
        entries.length,
        true
      )} after removing ${pluralize(
        "notification",
        rawEntries.length - entries.length,
        true
      )}`
    );

    const activeFilters = [];
    let activeEntries = entries;

    filterTypes.forEach((filterType) => {
      const filteredEntries = entries.filter(filterType.match);
      if (filteredEntries.length > 0) {
        activeFilters.push({ ...filterType });
      }

      if (filter === filterType.value) {
        activeEntries = filteredEntries;
      }
    });

    setFilters(activeFilters);

    setData(orderByDate(activeEntries));
    lastGroupTimestamp = 0;
  }, [activities, filter]);

  useEffect(() => {
    setClosed(isClosed(workItem, definition));
  }, [workItem]);

  const toggleOrSelectFilter = (selected) => () =>
    filter === null || filter !== selected
      ? setFilter(selected)
      : setFilter(null);

  const buildChip = (label, filterType, currentFilter) => (
    <Grid item key={filterType}>
      <Chip
        label={label}
        className={chipClasses(classes, filterType, currentFilter)}
        onClick={toggleOrSelectFilter(filterType)}
        data-cy={label}
      />
    </Grid>
  );

  return (
    <Paper className={classes.paper} elevation={0}>
      <div className={classes.header} data-cy="activityStream">
        <Typography variant="h6" color="primary" data-cy="activity">
          Activity
        </Typography>
        {filters.length > 0 && (
          <div className={classes.filters} data-cy="activity">
            <Typography
              className={classes.filterLabel}
              variant="body2"
              color="inherit"
              data-cy="filterBy"
            >
              Filter by
            </Typography>
            <Grid className={classes.chips} container>
              {filters.map((filterDef) =>
                buildChip(filterDef.label, filterDef.value, filter)
              )}
            </Grid>
          </div>
        )}
      </div>
      <div className={classes.activity} data-cy="activityStreamentry">
        {data.map((entry) => {
          const [leadWithDate, newTimestamp] = getLeadWithDateComponent(
            entry,
            lastGroupTimestamp
          );
          lastGroupTimestamp = newTimestamp;

          return (
            <Fragment key={entry.id}>
              {leadWithDate}
              <Entry
                status={getStatus(entry)}
                isCloseAction={isCloseAction(entry)}
              >
                <UserAction
                  name={entry.user.name}
                  email={entry.user.email}
                  time={entry.created}
                  outcome={getNewOutcome(entry)}
                  data-cy={entry.user.name}
                >
                  <div id={`activity-stream-${entry.id}`} />
                  <InternalNote entry={entry} data-cy={entry} />
                  <PrivateNote entry={entry} data-cy={entry} />
                  <Correspondence entry={entry} />
                  <GenericComment entry={entry} />
                  <Email
                    entry={entry}
                    workItem={workItem}
                    definition={definition}
                    loggedInUser={loggedInUser}
                    closed={closed}
                  />
                  <Create entry={entry} workItem={workItem} data-cy={entry} />
                  <StatusChange entry={entry} data-cy={entry} />
                  <Users entry={entry} data-cy={entry} />
                  <SingleUserChange
                    entry={entry}
                    pathKey="owner"
                    label="owner"
                    data-cy="owner"
                  />
                  <SingleUserChange
                    entry={entry}
                    pathKey="keyContact"
                    label="key contact"
                    data-cy="keyContact"
                  />
                  <InitialComment entry={entry} data-cy="initialComment" />
                  <QuestionResponse entry={entry} data-cy="response" />
                  <Documents
                    entry={entry}
                    workItemId={workItem.id}
                    data-cy="documents"
                  />
                  <UserGroup
                    pathKey="collaborators"
                    label="Collaborator"
                    entry={entry}
                    data-cy="collaborator"
                  />
                  <UserGroup
                    pathKey="submitterContacts"
                    label="Submitter"
                    entry={entry}
                    data-cy="submitter"
                  />
                  <Endorsements entry={entry} data-cy="endorsements" />
                  <Association
                    entry={entry}
                    workItem={workItem}
                    data-cy="association"
                  />
                  <MetropolisLinks
                    entry={entry}
                    workItem={workItem}
                    data-cy="metropolisLinks"
                  />
                  <Legacy entry={entry} data-cy="legacy" />
                  <Diff entry={entry} data-cy="diff" />
                </UserAction>
              </Entry>
            </Fragment>
          );
        })}
      </div>
    </Paper>
  );
};

ActivityStream.propTypes = {
  classes: PropTypes.object.isRequired,
  loggedInUser: PropTypes.object,
  workItem: PropTypes.object.isRequired,
  definition: PropTypes.object.isRequired,
  activities: PropTypes.array.isRequired,
  scrollToActivityId: PropTypes.string,
  onScrolledToActivity: PropTypes.func,
};

ActivityStream.defaultProps = {
  scrollToActivityId: undefined,
  onScrolledToActivity: undefined,
  loggedInUser: null,
};

export default withStyles(styles)(ActivityStream);
