import * as PropTypes from "prop-types";
import React from "react";
import ArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { DatePicker } from "@material-ui/pickers";
import dates from "../../../util/dates";

const WrappedMonthPicker = ({
  input,
  storeDatesAsString,
  helperText,
  margin,
  meta: { touched, error, invalid },
  ...custom
}) => {
  const rest = { ...custom };

  if (touched && error) {
    rest.error = true;
    rest.helperText = error;
  } else if ((!touched || !invalid) && helperText) {
    rest.helperText = helperText;
  }

  return (
    <DatePicker
      name={`position-${input.name}`}
      value={
        storeDatesAsString ? dates.parseDate(input.value) || null : input.value
      }
      format="MMM YYYY"
      leftArrowIcon={<ArrowLeftIcon />}
      rightArrowIcon={<ArrowRightIcon />}
      autoOk
      clearable
      onChange={(date) =>
        input.onChange(storeDatesAsString ? dates.serializeDate(date) : date)
      }
      onFocus={input.onFocus}
      margin={margin}
      views={["year", "month"]}
      {...rest}
    />
  );
};

WrappedMonthPicker.propTypes = {
  input: PropTypes.object.isRequired,
  helperText: PropTypes.any,
  storeDatesAsString: PropTypes.bool,
  meta: PropTypes.object.isRequired,
  margin: PropTypes.string,
};

WrappedMonthPicker.defaultProps = {
  helperText: "",
  storeDatesAsString: true,
  margin: "dense",
};

export default WrappedMonthPicker;
