import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { fetchTags } from "../../actions/tags";
import {
  getReferenceDataDescription,
  getTags,
  isLoading,
} from "../../reducers";
import dates from "../../util/dates";
import GridListing from "../common/GridListing";
import TimeAgo from "../common/TimeAgo";
import { useAuth0 } from "@auth0/auth0-react";

const styles = () => ({
  primaryText: {
    display: "block",
    fontSize: "0.875rem",
    fontWeight: "600",
  },
  nowrap: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  textWithIcon: {
    display: "flex",
  },
  iconSm: {
    fontSize: 16,
  },
});

const TagsListing = ({
  classes,
  history,
  loading,
  tags,
  filter,
  clearFilter,
  localFetchTags,
  getTagTypeDescription,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      localFetchTags(accessToken);
    })();
  }, [getAccessTokenSilently]);
  console.log(
    "tags:\n",
    tags
      .filter((t) => t.type == "RISK_REGISTER")
      .map((t) => t.id)
      .join("\n")
  );
  const getFilteredTags = () => {
    if (_.isEmpty(filter.tagTypes)) {
      return _.orderBy(tags, (tag) => tag.text.toLowerCase());
    }
    return _.orderBy(
      _.filter(tags, (tag) => _.includes(filter.tagTypes, tag.type)),
      (tag) => tag.text.toLowerCase()
    );
  };
  return (
    <GridListing
      sortedData={getFilteredTags()}
      loading={loading && tags.length === 0}
      dense={false}
      sortBy={{}}
      updateSort={() => {
        // do nothing.
      }}
      clearFilter={clearFilter}
      onClick={(tag) => history.push(`/admin/tags/${tag.id}`)}
      columns={[
        {
          label: "Tag",
          name: "text",
          size: 5,
          sortable: false,
          render: (tag) => <span className={classes.nowrap}>{tag.text}</span>,
        },
        {
          label: "Category",
          name: "type",
          size: 3,
          sortable: false,
          render: (tag) => (
            <span className={classes.primaryText}>
              {getTagTypeDescription(tag.type)}
            </span>
          ),
        },
        {
          label: "Created",
          name: "created",
          size: 2,
          sortable: false,
          render: (tag) => (
            <TimeAgo value={dates.parseTimestamp(tag.created)} expandable />
          ),
        },
        {
          label: "Updated",
          name: "updated",
          size: 2,
          sortable: false,
          render: (tag) => (
            <TimeAgo value={dates.parseTimestamp(tag.updated)} expandable />
          ),
        },
      ]}
    />
  );
};

TagsListing.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  tags: PropTypes.array.isRequired,
  filter: PropTypes.object.isRequired,
  clearFilter: PropTypes.func.isRequired,
  localFetchTags: PropTypes.func.isRequired,
  getTagTypeDescription: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  loading: isLoading(state),
  tags: getTags(state),
  getTagTypeDescription: (id) =>
    getReferenceDataDescription(state, "TagType", id),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    localFetchTags: fetchTags,
  })
)(TagsListing);
