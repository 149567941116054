import { requestJSON } from "./http";

const decorateSearchParameters = (filter) =>
  filter ? { __type: "Media", ...filter } : { __type: "Media" };

// global media API endpoints

const load = (mediaId, accessToken) =>
  requestJSON({
    path: `/metropolis/media/${mediaId}`,
    accessToken,
  });

const create = (media, accessToken) =>
  requestJSON({
    path: "/metropolis/media",
    method: "POST",
    data: media,
    accessToken,
  });

const patch = (id, changes, description, accessToken) =>
  requestJSON({
    path: `/metropolis/media/${id}`,
    method: "PATCH",
    data: {
      fields: Object.keys(changes),
      changes,
      description,
    },
    accessToken,
  });

const addDocument = (id, version, accessToken) =>
  requestJSON({
    path: `/metropolis/media/${id}/documents`,
    method: "POST",
    data: version,
    accessToken,
  });

const addDocumentLinkFromArcadia = (id, version, accessToken) =>
  requestJSON({
    path: `/metropolis/media/${id}/documents/link-from-arcadia`,
    method: "POST",
    data: version,
    accessToken,
  });

const patchDocument = (id, versionId, changes, description, accessToken) =>
  requestJSON({
    path: `/metropolis/media/${id}/documents/${versionId}`,
    method: "PATCH",
    data: {
      fields: Object.keys(changes),
      changes,
      description,
    },
    accessToken,
  });

const deleteDocument = (id, versionId, accessToken) =>
  requestJSON({
    path: `/metropolis/media/${id}/documents/${versionId}`,
    method: "DELETE",
    accessToken,
  });

const deleteMedia = (mediaId, accessToken) =>
  requestJSON({
    path: `/metropolis/media/${mediaId}`,
    method: "DELETE",
    accessToken,
  });

const search = (searchParams, abortController, accessToken) =>
  requestJSON({
    path: "/metropolis/media/search",
    method: "POST",
    data: decorateSearchParameters(searchParams),
    abortController,
    accessToken,
  });

// check for available slug (note: productId is optional)
const checkSlugAvailability = (productId, slug, id, accessToken) => {
  const baseUrl = "/metropolis/media/slug-available";
  const query = id ? `?id=${id}` : "";
  const url = productId
    ? `${baseUrl}/${productId}/${slug}${query}`
    : `${baseUrl}/${slug}${query}`;
  return requestJSON({ path: url, accessToken });
};

const checkReferenceAvailability = (reference, accessToken) =>
  requestJSON({
    path: `/metropolis/media/reference-available/${encodeURIComponent(
      reference
    )}`,
    accessToken,
  });

export default {
  create,
  deleteMedia,
  search,
  load,
  patch,
  addDocument,
  addDocumentLinkFromArcadia,
  patchDocument,
  deleteDocument,
  checkSlugAvailability,
  checkReferenceAvailability,
};
