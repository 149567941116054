export const toCamelCaseFromDash = (str: string): string =>
  str.replace(/(-[a-z])/g, ($1) => $1.toUpperCase().replace("-", ""));

export const toDashCaseFromCamel = (str: string): string =>
  str.replace(/([A-Z])/g, ($1) => `-${$1.toLowerCase()}`);

export const toUnderscoreCaseFromCamel = (str: string): string =>
  str.replace(/([A-Z])/g, ($1) => `_${$1.toLowerCase()}`);

export const toUpperUnderscoreCase = (str: string): string =>
  toUnderscoreCaseFromCamel(str).toUpperCase();

export const toCamelCaseFromUpperUnderscore = (str: string): string =>
  str
    .toLowerCase()
    .replace(/(_[a-z])/g, ($1) => $1.toUpperCase().replace("_", ""));

export const toTitleCaseFromUpperUnderscore = (str: string): string =>
  toCamelCaseFromUpperUnderscore(str).replace(/(^[a-z])/g, ($1) =>
    $1.toUpperCase()
  );

export const toFirstLetterUpper = (str: string): string =>
  str.toLowerCase().replace(/(^[a-z])/g, ($1) => $1.toUpperCase());

export const toLowerCaseFromUnderscoreCase = (str: string): string =>
  str
    .split("_")
    .map(($1) => $1.toLowerCase())
    .join(" ");

export const toDashCaseFromSentence = (str: string): string =>
  str
    .trim()
    .replace(/[^A-Za-z0-9\s]/g, "")
    .trim()
    .replace(/\s+/g, "-")
    .toLowerCase();
