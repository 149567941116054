import { withStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Code from "@material-ui/icons/Code";
import FormatBold from "@material-ui/icons/FormatBold";
import FormatItalic from "@material-ui/icons/FormatItalic";
import FormatUnderlined from "@material-ui/icons/FormatUnderlined";
import { convertFromHTML, convertToHTML } from "draft-convert";
import { Editor, EditorState, getDefaultKeyBinding, RichUtils } from "draft-js";
import _ from "lodash";
import * as PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

const styles = (theme) => ({
  actions: {
    position: "absolute",
    right: theme.spacing(1) / 4,
    top: 0,
  },
  actionButton: {
    padding: theme.spacing(1) / 4,
    margin: 0,
  },
  editorLarge: {
    height: "200px",
    overflowY: "auto",
    outline: "none",
  },
  editorSmall: {
    height: "60px",
    overflowY: "auto",
    outline: "none",
  },
});

const HtmlDraftJsField = ({
  classes,
  value,
  onChange,
  children,
  small,
  autoFocus,
  placeholder,
  stylingSupported,
}) => {
  const editor = useRef(null);
  const [editorState, setEditorState] = useState(
    value
      ? EditorState.createWithContent(convertFromHTML(value))
      : EditorState.createEmpty()
  );

  const focus = () => editor.current && editor.current.focus();

  useEffect(() => {
    if (autoFocus) {
      focus();
    }
  }, [autoFocus]);

  useEffect(() => {
    const currentContent = editorState.getCurrentContent();
    let htmlNewState = convertToHTML(currentContent);
    if (!htmlNewState) {
      htmlNewState = null;
    }
    const stateChanged = !_.isEqual(value, htmlNewState);

    if (stateChanged) {
      onChange(htmlNewState);
    }
  }, [editorState]);

  const toggleStyle = (style) => {
    const selection = editorState.getSelection();
    const newState = RichUtils.toggleInlineStyle(editorState, style);

    setTimeout(() => {
      focus();
      setEditorState(EditorState.forceSelection(newState, selection));
    }, 100);
  };

  const handleKeyCommand = (command, eState) => {
    if (stylingSupported) {
      const newState = RichUtils.handleKeyCommand(eState, command);
      if (newState) {
        setEditorState(newState);
        return "handled";
      }
    }
    return "not-handled";
  };

  return (
    <div>
      <div className={classes.actions} data-cy="actionsIcon">
        {stylingSupported && (
          <>
            <IconButton
              className={classes.actionButton}
              color="inherit"
              onClick={() => toggleStyle("BOLD")}
            >
              <FormatBold />
            </IconButton>
            <IconButton
              className={classes.actionButton}
              color="inherit"
              onClick={() => toggleStyle("ITALIC")}
            >
              <FormatItalic />
            </IconButton>
            <IconButton
              className={classes.actionButton}
              color="inherit"
              onClick={() => toggleStyle("UNDERLINE")}
            >
              <FormatUnderlined />
            </IconButton>
            <IconButton
              className={classes.actionButton}
              color="inherit"
              onClick={() => toggleStyle("CODE")}
            >
              <Code />
            </IconButton>
          </>
        )}
        {children}
      </div>
      <div
        className={small ? classes.editorSmall : classes.editorLarge}
        onClick={() => focus()}
        role="button"
        onKeyPress={() => focus()}
        tabIndex={0}
        data-cy="editor"
      >
        <Editor
          editorState={editorState}
          onChange={setEditorState}
          spellCheck
          stripPastedStyles={!stylingSupported}
          handleKeyCommand={handleKeyCommand}
          keyBindingFn={getDefaultKeyBinding}
          placeholder={placeholder}
          ref={editor}
        />
      </div>
    </div>
  );
};

HtmlDraftJsField.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  small: PropTypes.bool,
  autoFocus: PropTypes.bool,
  placeholder: PropTypes.string,
  stylingSupported: PropTypes.bool,
};

HtmlDraftJsField.defaultProps = {
  value: null,
  children: null,
  small: false,
  autoFocus: false,
  placeholder: null,
  stylingSupported: false,
};

export default withStyles(styles)(HtmlDraftJsField);
