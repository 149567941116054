import { requestJSON } from "./http";
import { Category } from "../../model/Category";

const list = (accessToken: string): Promise<Category[]> =>
  requestJSON({
    path: "/metropolis/categories",
    accessToken,
  });

const create = (group: Category, accessToken: string): Promise<Category> =>
  requestJSON({
    method: "POST",
    path: "/metropolis/categories",
    accessToken,
    data: group,
  });

const update = (
  id: string,
  changes: Category,
  description: string,
  accessToken: string
): Promise<Category> =>
  requestJSON({
    method: "PATCH",
    path: `/metropolis/categories/${id}`,
    accessToken,
    data: {
      fields: Object.keys(changes),
      changes,
      description,
    },
  });

const remove = (id: string, accessToken: string): Promise<void> =>
  requestJSON({
    method: "DELETE",
    path: `/metropolis/categories/${id}`,
    accessToken,
  });

export default {
  list,
  create,
  update,
  remove,
};
