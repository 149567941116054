import { combineReducers } from "redux";
import {
  WORK_ITEM_ASSOCIATION_CREATE,
  WORK_ITEM_ASSOCIATION_DELETE,
  WORK_ITEM_ASSOCIATION_LIST,
  WORK_ITEM_ASSOCIATION_PREFIX,
} from "../actions/workItemAssociations";
import { publishActionToast } from "../services/toasts";

const successToasts = {
  [WORK_ITEM_ASSOCIATION_CREATE]: "Create association successfully",
  [WORK_ITEM_ASSOCIATION_DELETE]: "Removed association successfully",
};

const byId = (state = {}, action) => {
  publishActionToast(action, WORK_ITEM_ASSOCIATION_PREFIX, successToasts);

  switch (action.type) {
    case `${WORK_ITEM_ASSOCIATION_LIST}_SUCCESS`:
    case `${WORK_ITEM_ASSOCIATION_CREATE}_SUCCESS`:
      return {
        ...state,
        ...action.response.entities.workItemAssociation,
      };

    case `${WORK_ITEM_ASSOCIATION_DELETE}_SUCCESS`: {
      const idToRemove = action.response.id;
      return {
        ...state,
        [idToRemove]: undefined,
      };
    }

    default:
      break;
  }

  return state;
};

const defaultActive = {
  workItemAssociations: [],
  workItemAssociation: null,
  error: null,
};

const active = (state = defaultActive, action) => {
  switch (action.type) {
    case `${WORK_ITEM_ASSOCIATION_LIST}_SUCCESS`:
      return {
        ...state,
        workItemAssociations: [...action.response.result],
      };
    case `${WORK_ITEM_ASSOCIATION_CREATE}_SUCCESS`:
      const newId = action.response.result;
      return {
        ...state,
        workItemAssociations:
          state.workItemAssociations.indexOf(newId) === -1
            ? [...state.workItemAssociations, newId]
            : state.workItemAssociations,
        workItemAssociation: newId,
      };

    case `${WORK_ITEM_ASSOCIATION_DELETE}_SUCCESS`: {
      const idToRemove = action.response.id;
      return {
        ...state,
        workItemAssociations: [
          ...state.workItemAssociations.filter((id) => id !== idToRemove),
        ],
        workItemAssociation:
          state.workItemAssociation === idToRemove
            ? undefined
            : state.workItemAssociation,
      };
    }

    default:
      break;
  }

  return state;
};

export default combineReducers({
  active,
  byId,
});

export const getActiveAssociation = (state) =>
  state.active.workItemAssociation
    ? state.byId[state.active.workItemAssociation]
    : null;
export const getAssociations = (state) =>
  state.active.workItemAssociations.map((id) => state.byId[id]);
