import { useAuth0 } from "@auth0/auth0-react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Switch from "@material-ui/core/Switch";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";
import { required } from "redux-form-validators";
import tinycolor from "tinycolor2";
import { getReferenceDataDescription } from "../../../reducers";
import organisationsApi from "../../../services/api/organisations";
import {
  addIcon,
  deleteIcon,
  invisibleIcon,
  visibleIcon,
  warningIcon,
} from "../../../util/icons";
import Avatar from "../../common/Avatar.tsx";
import DebouncedTextField from "../../common/DebouncedTextField";
import ListPicker from "../../ListPicker";
import WrappedNonInteractive from "../wrapper/WrappedNonInteractive";

const useStyles = makeStyles((theme) => ({
  label: {
    position: "relative",
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
  },
  nowrap: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(0.25),
    marginTop: theme.spacing(0.25),
  },
  chipIcon: {
    marginLeft: 0,
  },
  chipSingle: {
    maxWidth: "100%",
    marginBottom: theme.spacing(0.25),
    marginTop: theme.spacing(0.25),
  },
  chipRoot: {
    maxWidth: "100%",
  },
  relationship: {
    backgroundColor: tinycolor(theme.palette.primary.main).setAlpha(0.1),
    marginLeft: theme.spacing(1),
  },
  warning: {
    backgroundColor: tinycolor(theme.palette.warning.main).setAlpha(0.1),
    marginLeft: theme.spacing(1),
  },
  filter: {
    marginBottom: theme.spacing(1),
  },
  borderBottom: {
    borderBottom: `solid 1px ${theme.palette.grey[500]}`,
    paddingBottom: theme.spacing(1),
    "&:hover": {
      borderBottom: "solid 2px",
      paddingBottom: `${theme.spacing(1) - 1}px`,
    },
  },
  toggleVisibilityButton: {
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    marginLeft: 0,
    marginBottom: theme.spacing(0.25),
    marginTop: theme.spacing(0.25),
  },
}));

const EntityRelationshipOrganisationFields = ({
  meta: { error, submitFailed },
  products,
  funds,
  organisations,
  regions,
  fieldLabels,
  label,
  isRequired,
  className,
  margin,
  fields,
  getRelationshipTypeDescription,
  filterEntitiesByRelationshipTypes,
  defaultVisibilityScope,
  change,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();
  const [listPickerOpen, setListPickerOpen] = useState(false);

  const asyncOrganisationOptionsFetch = async (
    pickerFilter,
    pagination,
    abortController
  ) => {
    const accessToken = await getAccessTokenSilently();
    const { textSearch, sargonOnly, byProducts, byFunds } = pickerFilter;
    const searchParameters = {
      textSearch,
      regions: byProducts ? regions : undefined,
      limit: pagination.pageSize,
      offset: pagination.offset,
      orderByField: "name",
      excludeGroupFilters: false,
      productIds: byProducts
        ? [...(products || []).map((product) => product.id)]
        : [],
      fundIds: byFunds ? [...(funds || []).map((fund) => fund.id)] : [],
      relationshipTypes: filterEntitiesByRelationshipTypes,
      types: sargonOnly ? ["INTERNAL"] : [],
    };
    return organisationsApi.search(
      searchParameters,
      abortController,
      accessToken
    );
  };

  const setOrganisations = (selected) => {
    const newOrganisations = selected.map((organisation) => {
      const existingLinkedOrg = organisations.find(
        (linked) => linked.organisation.id === organisation.id
      );
      if (existingLinkedOrg) {
        return existingLinkedOrg;
      }
      return {
        organisation,
        visibilityScope: defaultVisibilityScope[organisation.type],
      };
    });
    setListPickerOpen(false);
    setTimeout(() => change(fields.name, newOrganisations), 10);
  };

  const remove = (index) => {
    fields.remove(index);
  };

  const toggleVisibility = (fieldName, currentValue) => {
    change(
      `${fieldName}.visibilityScope`,
      currentValue === "ORGANISATION" ? "INDIVIDUAL" : "ORGANISATION"
    );
  };

  const getVisibilityTooltip = (currentValue) => {
    if (currentValue === "ORGANISATION") {
      return "Visible to all users of organisation";
    }
    return "Not visible to all users from organisation";
  };

  const getRelationshipTypes = (organisation) => {
    const rTypes = {};
    _.flatMap(products, (product) => product.relationships)
      .filter((rel) => rel.organisation.id === organisation.id)
      .forEach((rel) => {
        rTypes[rel.relationshipType] = true;
      });
    _.flatMap(funds, (fund) => fund.relationships)
      .filter((rel) => rel.organisation.id === organisation.id)
      .forEach((rel) => {
        rTypes[rel.relationshipType] = true;
      });
    return _.orderBy(Object.keys(rTypes));
  };

  const DeleteIcon = deleteIcon();
  const AddIcon = addIcon();
  const WarningIcon = warningIcon();
  const VisibleIcon = visibleIcon();
  const InvisibleIcon = invisibleIcon();
  const existingOrganisations = organisations.map(
    (linkedOrg) => linkedOrg.organisation
  );

  return (
    <FormControl
      className={className}
      fullWidth
      margin={margin}
      name={`position-${fields.name}._error`}
    >
      <InputLabel
        className={classes.label}
        shrink
        required={isRequired}
        data-cy={label}
      >
        {label}
      </InputLabel>
      <List className={classes.borderBottom}>
        {fields.map((fieldName, index) => {
          const organisation = organisations[index];
          const relationshipTypes = !organisation
            ? []
            : getRelationshipTypes(organisations[index].organisation);
          const visibilityScope = !organisation
            ? null
            : organisations[index].visibilityScope;
          const showWarning =
            relationshipTypes.length === 0 && products.length !== 0;
          return (
            // eslint-disable-next-line react/no-array-index-key
            <ListItem key={index} className={classes.listItem}>
              <IconButton
                title={getVisibilityTooltip(visibilityScope)}
                className={classes.toggleVisibilityButton}
                aria-label="Visibility"
                onClick={() => toggleVisibility(fieldName, visibilityScope)}
                data-cy="remove"
              >
                {visibilityScope === "ORGANISATION" ? (
                  <VisibleIcon />
                ) : (
                  <InvisibleIcon />
                )}
              </IconButton>
              <Grid container alignItems="center">
                <Grid item>
                  <Field
                    name={`${fieldName}.organisation`}
                    data-cy={`${fieldName}.organisation`}
                    component={WrappedNonInteractive}
                    validate={required(
                      `${fieldLabels.nestedTypes.organisations.labels.organisation} is required`
                    )}
                    required
                    margin="none"
                    fullWidth={false}
                    render={(value) => (
                      <Chip
                        className={classes.chipSingle}
                        classes={{
                          root: classes.chipRoot,
                          label: classes.nowrap,
                          icon: classes.chipIcon,
                        }}
                        icon={
                          <Avatar
                            name={(value.name || "Unknown").charAt(0)}
                            size={30}
                            round
                          />
                        }
                        label={value.name}
                        data-cy={value.name}
                      />
                    )}
                  />
                </Grid>
                {relationshipTypes.map((type) => (
                  <Grid key={type} item>
                    <Chip
                      className={classes.relationship}
                      label={getRelationshipTypeDescription(type)}
                      size="small"
                      variant="outlined"
                    />
                  </Grid>
                ))}
                {showWarning && (
                  <Grid item>
                    <Chip
                      className={classes.warning}
                      icon={<WarningIcon />}
                      label="Not related"
                      size="small"
                      variant="outlined"
                    />
                  </Grid>
                )}
              </Grid>
              <ListItemSecondaryAction>
                <IconButton
                  aria-label="Remove"
                  onClick={() => remove(index)}
                  data-cy="remove"
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Button
            size="small"
            color="primary"
            aria-label="Add"
            onClick={() => setListPickerOpen(true)}
            data-cy="Add"
          >
            <AddIcon /> Add
          </Button>
        </Grid>
      </Grid>
      <ListPicker
        data-cy="selectEntities"
        title="Select related entities"
        actionText="Select"
        open={listPickerOpen}
        onClose={() => setListPickerOpen(false)}
        onSubmit={setOrganisations}
        datasource={asyncOrganisationOptionsFetch}
        selected={existingOrganisations}
        isMulti
        clearable
        toOption={(org) => ({ label: org.name, id: org.id, org })}
        fromOption={(option) => option.org}
        renderIcon={(org, size) => (
          <Avatar name={(org.name || "Unknown").charAt(0)} size={size} round />
        )}
        filterInitialValues={{
          byProducts: true,
          sargonOnly: true,
          byFunds: true,
        }}
        renderFilter={(onChange, filter) => (
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
            className={classes.filter}
          >
            <Grid
              item
              style={{ flex: 1, marginRight: "3px", minWidth: "200px" }}
            >
              <DebouncedTextField
                value={filter.textSearch}
                onChange={(text) => onChange("textSearch", text)}
                placeholder="Type to filter..."
                margin="none"
                autoFocus
                fullWidth
                data-cy="typeToFilter"
              />
            </Grid>
            <Grid item>
              <Grid
                container
                alignItems="flex-start"
                justifyContent="space-between"
                direction="column"
              >
                <Grid item>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!!filter.sargonOnly}
                        onChange={(evt) =>
                          onChange("sargonOnly", evt.target.checked)
                        }
                        name="sargonOnly"
                      />
                    }
                    label="Internal only"
                    data-cy="certaneOnly"
                  />
                </Grid>
                {products.length > 0 && (
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={!!filter.byProducts}
                          onChange={(evt) =>
                            onChange("byProducts", evt.target.checked)
                          }
                          name="byProducts"
                        />
                      }
                      label="Filter by selected products"
                      data-cy="FilterBySelectedProducts"
                    />
                  </Grid>
                )}
                {funds.length > 0 && (
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={!!filter.byFunds}
                          onChange={(evt) =>
                            onChange("byFunds", evt.target.checked)
                          }
                          name="byFunds"
                        />
                      }
                      label="Filter by selected funds"
                      data-cy="FilterBySelectedFunds"
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      />
      {error && submitFailed && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
};

EntityRelationshipOrganisationFields.propTypes = {
  meta: PropTypes.object.isRequired,
  products: PropTypes.array,
  funds: PropTypes.array,
  organisations: PropTypes.array,
  regions: PropTypes.array,
  fields: PropTypes.object.isRequired,
  filterEntitiesByRelationshipTypes: PropTypes.array.isRequired,
  defaultVisibilityScope: PropTypes.object.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  className: PropTypes.string,
  margin: PropTypes.string,
  change: PropTypes.func.isRequired,

  // redux
  getRelationshipTypeDescription: PropTypes.func.isRequired,
};

EntityRelationshipOrganisationFields.defaultProps = {
  isRequired: false,
  className: null,
  margin: "dense",
  funds: [],
  organisations: [],
  regions: [],
  products: [],
};

const mapStateToProps = (state) => ({
  getRelationshipTypeDescription: (relationshipType) =>
    getReferenceDataDescription(state, "RelationshipType", relationshipType),
});

export default connect(mapStateToProps)(EntityRelationshipOrganisationFields);
