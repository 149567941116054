import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";
import React, { Component } from "react";
import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import dates from "../../util/dates";
import AttachmentFile from "./AttachmentFile";
import Avatar from "./Avatar.tsx";
import TimeAgo from "./TimeAgo";

const styles = (theme) => ({
  container: {
    display: "block",
    "&:last-of-type": {
      borderBottom: "none",
    },
  },
  entry: {
    margin: "0.375rem 0 1.25rem",
    display: "block",
    width: "100%",
  },
  user: {
    fontWeight: 600,
  },
  mentions: {
    display: "flex",
  },
  files: {
    marginTop: theme.spacing(1) / 2,
    display: "flex",
  },
  icon: {
    width: 24,
    marginTop: theme.spacing(1) / 2,
    marginRight: theme.spacing(1),
  },
  chipRoot: {
    marginRight: theme.spacing(1) / 2,
    marginBottom: theme.spacing(1) / 2,
    marginTop: 0,
  },
  chipIcon: {
    marginLeft: 0,
  },
});

class UserEntries extends Component {
  render() {
    const { classes, entries, noEntriesText } = this.props;

    // TODO deal with links to internal notes (ref)
    return (
      <div>
        {entries.length === 0 && <p>{noEntriesText}</p>}
        <List dense>
          {entries.map((entry, index) => (
            <ListItem
              key={`${entry.user}_${entry.date}_${index}`}
              divider
              className={classes.container}
              disableGutters
            >
              <ListItemText
                primary={
                  <>
                    <Typography
                      className={classes.user}
                      variant="caption"
                      color="primary"
                      display="inline"
                      data-cy="userEntry"
                    >
                      {entry.user}
                    </Typography>
                    <span> </span>
                    <Typography variant="caption" display="inline">
                      <TimeAgo
                        value={dates.parseTimestamp(entry.date)}
                        expandable
                      />
                    </Typography>
                    {entry.otherHeading}
                  </>
                }
              />
              {entry.setRef && <div ref={entry.setRef} />}
              <div className={classes.entry}>{entry.content}</div>
              {entry.mentions && entry.mentions.length > 0 && (
                <div className={classes.mentions}>
                  <Icon className={classes.icon} title="Mentions">
                    alternate_email
                  </Icon>
                  <Grid container>
                    {entry.mentions.map((mention) => (
                      <Grid item key={mention.id}>
                        <Chip
                          classes={{
                            root: classes.chipRoot,
                            icon: classes.chipIcon,
                          }}
                          icon={
                            <Avatar
                              email={mention.email}
                              name={mention.name}
                              size={30}
                              round
                            />
                          }
                          label={mention.name}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </div>
              )}
              {entry.documents && entry.documents.length > 0 && (
                <div className={classes.files}>
                  <Icon
                    className={classes.icon}
                    title="Files"
                    data-cy="attachFiles"
                  >
                    attach_file
                  </Icon>
                  <Grid container>
                    {entry.documents.map((document) => (
                      <AttachmentFile
                        key={document.gcsObjectName}
                        document={document}
                        onFileClick={entry.onFileClick}
                      />
                    ))}
                  </Grid>
                </div>
              )}
            </ListItem>
          ))}
        </List>
      </div>
    );
  }
}

UserEntries.propTypes = {
  classes: PropTypes.object.isRequired,
  entries: PropTypes.array.isRequired,
  noEntriesText: PropTypes.string,
};

UserEntries.defaultProps = {
  noEntriesText: "No entries",
};

export default compose(withStyles(styles))(UserEntries);
