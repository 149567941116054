import { normalize } from "normalizr";
import schemas from "../schemas";
import businessServiceAreasApi from "../services/api/businessServiceAreas";
import { asyncAction } from "./actions";

export const BUSINESS_SERVICE_AREAS_CLEAR_ACTIVE =
  "BUSINESS_SERVICE_AREAS_CLEAR_ACTIVE";
export const BUSINESS_SERVICE_AREAS_CREATE = "BUSINESS_SERVICE_AREAS_CREATE";
export const BUSINESS_SERVICE_AREAS_SEARCH = "BUSINESS_SERVICE_AREAS_SEARCH";
export const BUSINESS_SERVICE_AREAS_GET_BY_ID =
  "BUSINESS_SERVICE_AREAS_GET_BY_ID";
export const BUSINESS_SERVICE_AREAS_PATCH = "BUSINESS_SERVICE_AREAS_PATCH";

export function clearActiveBusinessServiceAreas() {
  return {
    type: BUSINESS_SERVICE_AREAS_CLEAR_ACTIVE,
  };
}

export const createBusinessServiceArea = (businessServiceArea, accessToken) =>
  asyncAction(
    BUSINESS_SERVICE_AREAS_CREATE,
    businessServiceAreasApi.create(businessServiceArea, accessToken),
    {
      responseTransformer: (createdBusinessServiceArea) =>
        normalize(createdBusinessServiceArea, schemas.businessServiceArea),
    }
  );

export const searchBusinessServiceAreas = (searchParameters, accessToken) => {
  const abortController = window.AbortController ? new AbortController() : null;
  return asyncAction(
    BUSINESS_SERVICE_AREAS_SEARCH,
    businessServiceAreasApi.search(
      searchParameters,
      abortController,
      accessToken
    ),
    {
      responseTransformer: (response) => ({
        pagination: {
          offset: response.offset,
          previousOffset: response.previousOffset,
          nextOffset: response.nextOffset,
          pageSize: response.pageSize,
          resultCount: response.resultCount,
        },
        ...normalize(response.results, schemas.arrayOfBusinessServiceAreas),
      }),
      actionParams: {
        abortController,
        nonBlocking: true,
      },
    }
  );
};

export const getBusinessServiceAreaById = (orgId, accessToken) =>
  asyncAction(
    BUSINESS_SERVICE_AREAS_GET_BY_ID,
    businessServiceAreasApi.getById(orgId, accessToken),
    {
      responseTransformer: (businessServiceArea) =>
        normalize(businessServiceArea, schemas.businessServiceArea),
    }
  );

export const patchBusinessServiceArea = (
  orgId,
  changes,
  description,
  accessToken
) =>
  asyncAction(
    BUSINESS_SERVICE_AREAS_PATCH,
    businessServiceAreasApi.patch(orgId, changes, description, accessToken),
    {
      responseTransformer: (businessServiceArea) =>
        normalize(businessServiceArea, schemas.businessServiceArea),
    }
  );
