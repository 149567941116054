import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import FormCard from "../../../common/FormCard";
import EditWorkItemComponentForm, {
  EDIT_WORK_ITEM_COMPONENT_FORM_NAME,
} from "../EditWorkItemComponentForm";
import ContactListReadOnly from "./ContactListReadOnly";

class ContactsCard extends Component {
  render() {
    const { className, fieldLabels, workItem, onChange } = this.props;

    return (
      <FormCard
        className={className}
        title={fieldLabels.labels.contacts}
        formTitle={`Edit ${fieldLabels.labels.contacts}`}
        readOnlyView={() => (
          <ContactListReadOnly contacts={workItem.contacts} />
        )}
        formComponent={EditWorkItemComponentForm}
        formName={EDIT_WORK_ITEM_COMPONENT_FORM_NAME}
        onSave={onChange}
        initialValues={{ contacts: workItem.contacts }}
        formProps={{
          maxWidth: "md",
          workItem,
          componentName: "CONTACTS",
        }}
      />
    );
  }
}

ContactsCard.propTypes = {
  className: PropTypes.string,
  fieldLabels: PropTypes.object.isRequired,
  workItem: PropTypes.object.isRequired,
  onChange: PropTypes.func,
};

ContactsCard.defaultProps = {
  className: undefined,
  onChange: null,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(ContactsCard);
