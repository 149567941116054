import React from "react";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

// A simple replacement for the Chip component which doesn't
// overflow the container it's in and includes a tooltip.

const styles = (theme) => ({
  chip: {
    backgroundColor: theme.palette.grey[300],
    padding: "6px 12px",
    borderRadius: "16px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: "100%",
    whiteSpace: "nowrap",
    marginRight: "5px",
    display: "inline-block",
  },
});

const SimpleChip = ({ classes, label }) => (
  <Tooltip title={label} enterDelay={500}>
    <div className={classes.chip} data-cy={label}>
      {label}
    </div>
  </Tooltip>
);

SimpleChip.propTypes = {
  label: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleChip);
