import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { fetchTags } from "../../actions/tags";
import { getReferenceDataType, getTags } from "../../reducers";
import usersApi from "../../services/api/users";
import Avatar from "../common/Avatar.tsx";
import ChoiceChips from "../common/ChoiceChips";
import DebouncedTextField from "../common/DebouncedTextField";
import FiltersBar from "../common/FiltersBar";
import RefMultiSelect from "../common/RefMultiSelect";
import { tagsToOptions } from "../forms/menuItems";
import ListPicker from "../ListPicker";
import { useAuth0 } from "@auth0/auth0-react";

const statusMap = {
  OPEN: "Open",
  CLOSED: "Closed",
};
const statusOptions = ["OPEN", "CLOSED"].map((value) => ({
  label: statusMap[value],
  value,
}));

const dueDateMap = {
  overdue: "Overdue",
  thisWeek: "Week",
  thisMonth: "Month",
  thisQuarter: "Quarter",
  thisYear: "Calendar year",
};
const dueDateOptions = ["thisWeek", "thisMonth", "thisQuarter", "thisYear"].map(
  (value) => ({
    label: dueDateMap[value],
    value,
  })
);
const overdueDateOptions = ["overdue"].map((value) => ({
  label: dueDateMap[value],
  value,
}));

const useStyles = makeStyles((theme) => ({
  filterWidget: {
    marginTop: theme.spacing(2),
  },
  searchField: {
    fontSize: "0.8125rem",
    lineHeight: "1.1875em",
    minWidth: "400px",
  },
}));

const RiskReassessmentFilters = ({
  filter,
  updateFilter,
  ownerRoles,
  tags,
  localFetchTags,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();
  const [ownerPickerOpen, setOwnerPickerOpen] = useState(false);
  const [selectedOwnedBy, setSelectedOwnedBy] = useState([]);

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      localFetchTags(accessToken);

      // initially load the users currently selected in the filter
      if (filter.ownerIds && filter.ownerIds.length > 0) {
        usersApi
          .search(
            {
              limit: filter.ownerIds.length,
              offset: 0,
              roles: ownerRoles,
              userIds: filter.ownerIds,
            },
            null,
            accessToken
          )
          .then((response) => setSelectedOwnedBy(response.results));
      }
    })();
  }, [getAccessTokenSilently]);

  const setOwnedBy = (users) => {
    setSelectedOwnedBy(users);
    updateFilter(
      "ownerIds",
      users.map((user) => user.id)
    );
    setOwnerPickerOpen(false);
  };

  const asyncFetch = async (pickerFilter, pagination, abortController) => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = {
      ...pickerFilter,
      limit: pagination.pageSize,
      offset: pagination.offset,
      roles: ownerRoles,
      orderByField: "name",
    };
    return usersApi.search(searchParameters, abortController, accessToken);
  };

  const riskRegisterOptions = tagsToOptions(
    tags.filter((tag) => tag.type === "RISK_REGISTER")
  );

  return (
    <>
      <ListPicker
        title="Select owners"
        actionText="Select"
        open={ownerPickerOpen}
        onClose={() => setOwnerPickerOpen(false)}
        onSubmit={(u) => setOwnedBy(u)}
        datasource={asyncFetch}
        selected={selectedOwnedBy}
        isMulti
        clearable
        toOption={(user) => ({
          label: user.name || "Unknown",
          id: user.id,
          email: user.email,
        })}
        fromOption={(option) => ({
          name: option.label,
          id: option.id,
          email: option.email,
        })}
        renderIcon={(user, size) => (
          <Avatar email={user.email} name={user.name} size={size} round />
        )}
      />
      <FiltersBar>
        <>
          <InputLabel data-cy="search">Search</InputLabel>
          <div className={classes.filterWidget}>
            <DebouncedTextField
              value={filter.textSearch || ""}
              onChange={(text) => updateFilter("textSearch", text)}
              placeholder="Type to filter"
              margin="none"
              InputProps={{
                className: classes.searchField,
              }}
              data-cy={filter.textSearch || ""}
            />
          </div>
        </>
        <>
          <InputLabel data-cy="ownership">Ownership</InputLabel>
          <div className={classes.filterWidget}>
            <ChoiceChips
              value={filter.ownerIds.length > 0 ? "ownerIds" : null}
              options={[
                {
                  label: "Owned by",
                  value: "ownerIds",
                  badgeValue: filter.ownerIds.length,
                },
              ]}
              onChange={() => setOwnerPickerOpen(true)}
              data-cy={filter.ownerIds.length > 0 ? "ownerIds" : null}
            />
          </div>
        </>
        <>
          <InputLabel className={classes.label} data-cy="status">
            Status
          </InputLabel>
          <div className={classes.filterWidget}>
            <ChoiceChips
              value={filter.status}
              options={statusOptions}
              onChange={(value) => updateFilter("status", value)}
              data-cy={filter.status}
            />
          </div>
        </>
        <>
          <InputLabel className={classes.label} data-cy="dueThis">
            Due this
          </InputLabel>
          <div className={classes.filterWidget}>
            <ChoiceChips
              value={filter.dueDate}
              options={dueDateOptions}
              onChange={(value) => updateFilter("dueDate", value)}
              data-cy={dueDateOptions}
            />
          </div>
        </>
        <>
          <InputLabel className={classes.label} data-cy="overDue">
            Overdue
          </InputLabel>
          <div className={classes.filterWidget}>
            <ChoiceChips
              value={filter.dueDate}
              options={overdueDateOptions}
              onChange={(value) => updateFilter("dueDate", value)}
              data-cy={overdueDateOptions}
            />
          </div>
        </>
        <>
          <InputLabel className={classes.label} data-cy="riskRegister">
            Risk register
          </InputLabel>
          <div className={classes.filterWidget}>
            <RefMultiSelect
              title="Select risk registers"
              value={filter.riskRegisters}
              options={riskRegisterOptions}
              onChange={(value) => updateFilter("riskRegisters", value)}
              data-cy={filter.riskRegisters}
            />
          </div>
        </>
      </FiltersBar>
    </>
  );
};

RiskReassessmentFilters.propTypes = {
  filter: PropTypes.object.isRequired,
  updateFilter: PropTypes.func.isRequired,

  // redux
  ownerRoles: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  localFetchTags: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  ownerRoles: getReferenceDataType(state, "WorkItemType").values
    .RISK_REASSESSMENT.props.definition.ownableBy,
  tags: getTags(state),
});

export default connect(mapStateToProps, {
  localFetchTags: fetchTags,
})(RiskReassessmentFilters);
