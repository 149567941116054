import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { getLabels } from "../../../reducers";
import GridListing from "../../common/GridListing";
import InlineInput from "../../common/InlineInput";
import IconButton from "@material-ui/core/IconButton";
import { addIcon, deleteIcon } from "../../../util/icons";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  wrapText: {
    overflow: "hidden",
    overflowWrap: "break-word",
    whiteSpace: "wrap",
  },
  addButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));

const CategoryGroupPicker = ({
  groups,
  onCreateGroup,
  onUpdateGroup,
  onDeleteGroup,
  onClose,
  activeCategory,
  fieldLabels,
}) => {
  const classes = useStyles();
  const [sortBy, setSortBy] = useState({
    field: "name",
    direction: "asc",
  });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const DeleteIcon = deleteIcon();
  const AddIcon = addIcon();

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
      open={!!activeCategory}
      onClose={onClose}
      aria-labelledby="Category Group Dialog"
    >
      <DialogTitle>
        Select category group
        <Tooltip
          className={classes.addButton}
          title="New category group"
          enterDelay={100}
          disableFocusListener
        >
          <IconButton
            disabled={!onCreateGroup}
            onClick={onCreateGroup}
            color="primary"
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent>
        <GridListing
          sortedData={_.orderBy(groups, [sortBy.field], [sortBy.direction])}
          loading={false}
          sortBy={sortBy}
          dense
          updateSort={(field, direction) =>
            setSortBy({
              field,
              direction,
            })
          }
          action={(g) => (
            <IconButton
              disabled={!onDeleteGroup}
              onClick={() => onDeleteGroup(g)}
              size="small"
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          )}
          columns={[
            {
              label: "",
              name: "selected",
              size: 1,
              sortable: false,
              render: (g) => (
                <Checkbox
                  color="primary"
                  checked={
                    !!g.categories.find((c) => c.id === activeCategory?.id)
                  }
                  onChange={(event, checked) => {
                    if (checked) {
                      return onUpdateGroup(g, {
                        categories: [activeCategory, ...g.categories],
                      });
                    }
                    return onUpdateGroup(g, {
                      categories: g.categories.filter(
                        (c) => c.id !== activeCategory.id
                      ),
                    });
                  }}
                />
              ),
            },
            {
              label: fieldLabels.labels.name,
              name: "name",
              size: 6,
              sortable: true,
              render: (g) => (
                <Typography
                  className={classes.wrapText}
                  component="div"
                  data-cy={g.name}
                >
                  <InlineInput
                    value={g.name}
                    onChange={
                      onUpdateGroup
                        ? (name) => onUpdateGroup(g, { name })
                        : null
                    }
                    validate={(value) => !!value && !!value.trim()}
                  />
                </Typography>
              ),
            },
          ]}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Done</Button>
      </DialogActions>
    </Dialog>
  );
};

CategoryGroupPicker.propTypes = {
  groups: PropTypes.array.isRequired,
  activeCategory: PropTypes.object,
  onCreateGroup: PropTypes.func,
  onUpdateGroup: PropTypes.func,
  onDeleteGroup: PropTypes.func,
  onClose: PropTypes.func.isRequired,

  // redux
  fieldLabels: PropTypes.object.isRequired,
};

CategoryGroupPicker.defaultProps = {
  activeCategory: null,
  onCreateGroup: null,
  onUpdateGroup: null,
  onDeleteGroup: null,
};

const mapStateToProps = (state) => ({
  fieldLabels: getLabels(state).ContentCategoryGroup,
});

export default connect(mapStateToProps)(CategoryGroupPicker);
