import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { required } from "redux-form-validators";
import consequenceRatingImage from "../../../../images/consequence-rating.png";
import likelihoodRatingImage from "../../../../images/likelihood-rating.png";
import riskRatingImage from "../../../../images/risk-rating.png";
import { getReferenceDataType } from "../../../../reducers";
import HelpPopover from "../../../common/HelpPopover";
import { calculateRiskRating } from "../../../forms/risk/riskRating";
import ComponentField from "../../../forms/wrapper/ComponentField";
import WrappedSelect from "../../../forms/wrapper/WrappedSelect";
import WrappedTextField from "../../../forms/wrapper/WrappedTextField";
import { getRefDataMenuItems } from "../../../forms/menuItems";

const useStyles = makeStyles((theme) => ({
  riskRatingPopup: {
    width: "699px",
    height: "558px",
  },
  consequenceRatingPopup: {
    width: "100%",
    height: "100%",
  },
  riskRating: {
    marginBottom: theme.spacing(1),
  },
  label: {
    position: "relative",
  },
}));

const RiskAssessmentFields = ({
  fieldLabels,
  customisations,
  change,
  untouch,
  formName,
  riskLikelihoods,
  consequenceRatings,
  riskRatings,
  riskRating,
  likelihood,
  consequence,
}) => {
  const classes = useStyles();

  useEffect(() => {
    const likelihoodOrdinal = likelihood
      ? riskLikelihoods.values[likelihood].ordinal
      : null;
    const consequenceOrdinal = consequence
      ? consequenceRatings.values[consequence].ordinal
      : null;
    const newRiskRating = calculateRiskRating(
      likelihoodOrdinal,
      consequenceOrdinal
    );
    change("riskAssessmentDetails.riskRating", newRiskRating);
  }, [likelihood, consequence]);

  return (
    <>
      <ComponentField
        component={WrappedSelect}
        name="riskAssessmentDetails.likelihood"
        label={fieldLabels.nestedTypes.riskAssessmentDetails.labels.likelihood}
        validate={required({
          msg: `${fieldLabels.nestedTypes.riskAssessmentDetails.labels.likelihood} is required`,
        })}
        required
        fullWidth
        helperText={
          <>
            <span>Get help with </span>
            <HelpPopover linkText="likelihood ratings">
              <img
                className={classes.consequenceRatingPopup}
                src={likelihoodRatingImage}
                alt="Likelihood rating matrix"
              />
            </HelpPopover>
          </>
        }
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      >
        {getRefDataMenuItems(riskLikelihoods)}
      </ComponentField>

      <ComponentField
        data-cy="[tabContent]"
        component={WrappedSelect}
        name="riskAssessmentDetails.consequence"
        label={fieldLabels.nestedTypes.riskAssessmentDetails.labels.consequence}
        validate={required({
          msg: `${fieldLabels.nestedTypes.riskAssessmentDetails.labels.consequence} is required`,
        })}
        required
        fullWidth
        helperText={
          <>
            <span>Get help with </span>
            <HelpPopover linkText="consequence ratings">
              <img
                className={classes.consequenceRatingPopup}
                src={consequenceRatingImage}
                alt="Consequence rating matrix"
              />
            </HelpPopover>
          </>
        }
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      >
        {getRefDataMenuItems(consequenceRatings)}
      </ComponentField>

      <FormControl component="fieldset" margin="dense">
        <InputLabel shrink className={classes.label}>
          {fieldLabels.nestedTypes.riskAssessmentDetails.labels.riskRating}
          <HelpPopover>
            <img
              className={classes.riskRatingPopup}
              src={riskRatingImage}
              alt="Risk rating matrix"
            />
          </HelpPopover>
        </InputLabel>
        {riskRating !== null
          ? riskRatings.values[riskRating].description
          : "N/A"}
      </FormControl>

      <ComponentField
        name="riskAssessmentDetails.comment"
        component={WrappedTextField}
        label={fieldLabels.nestedTypes.riskAssessmentDetails.labels.comment}
        fullWidth
        multiline
        validate={required({
          msg: `${fieldLabels.nestedTypes.riskAssessmentDetails.labels.comment} is required`,
        })}
        required
        rowsMax={10}
        rows={2}
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
        data-cy={fieldLabels.nestedTypes.riskAssessmentDetails.labels.comment}
      />
    </>
  );
};

RiskAssessmentFields.propTypes = {
  customisations: PropTypes.array,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  fieldLabels: PropTypes.object.isRequired,

  // redux
  riskLikelihoods: PropTypes.object.isRequired,
  consequenceRatings: PropTypes.object.isRequired,
  riskRatings: PropTypes.object.isRequired,
  likelihood: PropTypes.string,
  consequence: PropTypes.string,
  riskRating: PropTypes.string,
};

RiskAssessmentFields.defaultProps = {
  customisations: [],
  likelihood: null,
  consequence: null,
  riskRating: null,
};

const mapStateToProps = (state, { formName }) => {
  const reduxFormSelector = formValueSelector(formName);

  return {
    riskLikelihoods: getReferenceDataType(state, "RiskLikelihood"),
    consequenceRatings: getReferenceDataType(state, "RiskConsequenceRating"),
    riskRatings: getReferenceDataType(state, "RiskRating"),
    likelihood: reduxFormSelector(state, "riskAssessmentDetails.likelihood"),
    consequence: reduxFormSelector(state, "riskAssessmentDetails.consequence"),
    riskRating: reduxFormSelector(state, "riskAssessmentDetails.riskRating"),
  };
};

export default connect(mapStateToProps)(RiskAssessmentFields);
