import PropTypes from "prop-types";
import React from "react";
import { FormattedDate } from "react-intl";
import { connect } from "react-redux";
import { getLabels, getReferenceDataMap } from "../../../../reducers";
import FormCard from "../../../common/FormCard";
import SimpleChips from "../../../common/SimpleChips";
import Scorecard from "../../../common/Scorecard";
import EditWorkItemComponentForm, {
  EDIT_WORK_ITEM_COMPONENT_FORM_NAME,
} from "../EditWorkItemComponentForm";

const ContractCard = ({
  workItem,
  executionMethodMap,
  fieldLabels,
  className,
  onChange,
}) => {
  const yearLabel = workItem.termYear === 1 ? "year" : "years";
  const monthLabel = workItem.termMonth === 1 ? "month" : "months";

  const contract = workItem.contract || { counterParties: [] };

  return (
    <FormCard
      data-cy={fieldLabels.labels.contract}
      className={className}
      title={fieldLabels.labels.contract}
      formTitle={`Edit ${fieldLabels.labels.contract}`}
      readOnlyView={() => (
        <>
          <Scorecard label={fieldLabels.nestedTypes.contract.labels.dateSigned}>
            {contract.dateSigned ? (
              <FormattedDate value={contract.dateSigned} />
            ) : (
              "-"
            )}
          </Scorecard>
          <Scorecard
            label={fieldLabels.nestedTypes.contract.labels.executionMethod}
          >
            {contract.executionMethod
              ? executionMethodMap[contract.executionMethod]
              : "-"}
          </Scorecard>
          <Scorecard
            label={fieldLabels.nestedTypes.contract.labels.counterParties}
          >
            <SimpleChips
              values={contract.counterParties.map((tag) => tag.text)}
              blankLabel="-"
            />
          </Scorecard>
          <Scorecard label="Term">
            {!contract.termMonth && !contract.termYear && "-"}
            {contract.termMonth
              ? `${contract.termMonth} ${monthLabel}`
              : ""}{" "}
            {contract.termYear ? `${contract.termYear} ${yearLabel}` : ""}
          </Scorecard>
          <Scorecard label={fieldLabels.nestedTypes.contract.labels.expiryDate}>
            {contract.expiryDate ? (
              <FormattedDate value={contract.expiryDate} />
            ) : (
              "-"
            )}
          </Scorecard>
          <Scorecard
            label={fieldLabels.nestedTypes.contract.labels.agreementSummary}
          >
            {contract.agreementSummary ? contract.agreementSummary : "-"}
          </Scorecard>
          <Scorecard label={fieldLabels.nestedTypes.contract.labels.keyTerms}>
            {contract.keyTerms ? contract.keyTerms : "-"}
          </Scorecard>
          <Scorecard
            label={fieldLabels.nestedTypes.contract.labels.nextReviewDate}
          >
            {contract.nextReviewDate ? (
              <FormattedDate value={contract.nextReviewDate} />
            ) : (
              "-"
            )}
          </Scorecard>
          <Scorecard
            label={fieldLabels.nestedTypes.contract.labels.lastReviewDate}
          >
            {contract.lastReviewDate ? (
              <FormattedDate value={contract.lastReviewDate} />
            ) : (
              "-"
            )}
          </Scorecard>
        </>
      )}
      formComponent={EditWorkItemComponentForm}
      formName={EDIT_WORK_ITEM_COMPONENT_FORM_NAME}
      onSave={onChange}
      initialValues={{
        contract: workItem.contract,
      }}
      formProps={{
        maxWidth: "md",
        workItem,
        componentName: "CONTRACT",
      }}
    />
  );
};

ContractCard.propTypes = {
  workItem: PropTypes.object.isRequired,
  executionMethodMap: PropTypes.object.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

ContractCard.defaultProps = {
  onChange: null,
  className: false,
};

const mapStateToProps = (state) => ({
  executionMethodMap: getReferenceDataMap(state, "ExecutionMethod"),
  fieldLabels: getLabels(state).CONTRACT_APPROVAL,
});

export default connect(mapStateToProps)(ContractCard);
