import { LABELS_FETCH } from "../actions/labels";

const list = (state = { labels: {}, initialised: false }, action) => {
  switch (action.type) {
    case `${LABELS_FETCH}_SUCCESS`:
      return {
        initialised: true,
        labels: action.response,
      };
    default:
      return state;
  }
};

export default list;

// selectors

export const isLoaded = (state) => state.initialised;
export const getLabels = (state) => state.labels;
