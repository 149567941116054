import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import WrappedTextField from "../wrapper/WrappedTextField";

const RiskActionSendUpdateRequestConfirmation = () => (
  <form autoComplete="off">
    <p>
      An update request email will be sent to the owner of every action matching
      your current search criteria
    </p>

    <Field
      name="message"
      component={WrappedTextField}
      label="Message"
      fullWidth
      multiline
      rowsMax={10}
      rows={2}
      helperText="Optional message to all recipients of this update request"
    />
  </form>
);

RiskActionSendUpdateRequestConfirmation.propTypes = {};

RiskActionSendUpdateRequestConfirmation.defaultProps = {};

const mapStateToProps = () => ({});

export const RISK_ACTION_UPDATE_REQUEST_CONFIRMATION_FORM_NAME =
  "RiskActionSendUpdateRequestConfirmation";

const form = reduxForm({
  form: RISK_ACTION_UPDATE_REQUEST_CONFIRMATION_FORM_NAME,
});

const reduxData = connect(mapStateToProps);

export default compose(
  form,
  reduxData
)(RiskActionSendUpdateRequestConfirmation);
