import { requestJSON } from "./http";

const decorateSearchParameters = (filter) =>
  filter ? { __type: "Question", ...filter } : { __type: "Question" };
// with pagination
const search = (searchParams, abortController, accessToken) =>
  requestJSON({
    path: "/questions/search",
    method: "POST",
    data: decorateSearchParameters(searchParams),
    abortController,
    accessToken,
  });

const count = (searchParams, abortController, accessToken) =>
  requestJSON({
    path: "/questions/count",
    method: "POST",
    data: decorateSearchParameters(searchParams),
    abortController,
    accessToken,
  });

export default {
  search,
  count,
};
