import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import PropTypes from "prop-types";
import Popover from "@material-ui/core/Popover";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import { closeIcon, helpIcon } from "../../util/icons";

const useStyles = makeStyles((theme) => ({
  icon: {},
  popoverRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  linkText: {
    cursor: "pointer",
    textDecoration: "none",
    color: theme.palette.swatch.link,
  },
  closeBtn: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  parentDiv: {
    width: "100vw",
    height: "100vh",
    display: "none",
  },
}));

const HelpPopover = ({ children, linkText, smallIcon }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const HelpIcon = helpIcon();
  const CloseIcon = closeIcon();

  return (
    <>
      {linkText && (
        <Link className={classes.linkText} onClick={handleClick}>
          {linkText}
        </Link>
      )}
      {!linkText && smallIcon && (
        <IconButton onClick={handleClick} size="small">
          <HelpIcon fontSize="small" />
        </IconButton>
      )}
      {!linkText && !smallIcon && (
        <IconButton onClick={handleClick}>
          <HelpIcon />
        </IconButton>
      )}
      <Popover
        className={classes.popoverRoot}
        open={Boolean(anchorEl)}
        anchorReference="none"
        onClose={() => setAnchorEl(null)}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <IconButton
          className={classes.closeBtn}
          onClick={() => setAnchorEl(null)}
        >
          <CloseIcon data-cy="close" />
        </IconButton>
        {children}
      </Popover>
    </>
  );
};

HelpPopover.propTypes = {
  children: PropTypes.node.isRequired,
  linkText: PropTypes.string,
  smallIcon: PropTypes.bool,
};

HelpPopover.defaultProps = {
  linkText: undefined,
  smallIcon: false,
};

export default HelpPopover;
