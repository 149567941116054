import Card from "@material-ui/core/Card";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Tab from "@material-ui/core/Tab";
import UpdateIcon from "@material-ui/icons/Update";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import RiskBulkUpdate from "../../../components/admin/bulk-updates/risks/RiskBulkUpdate";
import WorkItemBulkUpdate from "../../../components/admin/bulk-updates/work-items/WorkItemBulkUpdate";
import Container from "../../../components/common/Container";
import HeaderBar from "../../../components/common/HeaderBar";
import PageHeading from "../../../components/common/PageHeading";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  card: {
    marginTop: theme.spacing(2),
  },
}));

const tabs: Record<string, string> = {
  "work-items": "Work items",
  risks: "Risks",
};

const BulkUpdatePage: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<string>("work-items");

  useEffect(() => {
    console.log("useEffect", location.hash);
    const tabValue = location.hash ? location.hash?.substr(1) : "work-items";
    setActiveTab(tabValue);
  }, [location.hash]);

  const navigate = (value: string) => {
    console.log("navigate", value);
    setActiveTab(value);
    window.location.hash = value;
  };

  return (
    <div className={classes.root}>
      <HeaderBar>
        <PageHeading icon={UpdateIcon} heading="Bulk update" />
      </HeaderBar>
      <Container>
        <Card className={classes.card}>
          <TabContext value={activeTab}>
            <TabList
              value={activeTab}
              onChange={(event, newValue) => navigate(newValue)}
              textColor="primary"
              variant="scrollable"
              scrollButtons="off"
            >
              {Object.keys(tabs).map((key) => (
                <Tab key={key} label={tabs[key]} value={key} />
              ))}
            </TabList>
            <TabPanel value={"work-items"}>
              <WorkItemBulkUpdate />
            </TabPanel>
            <TabPanel value={"risks"}>
              <RiskBulkUpdate />
            </TabPanel>
          </TabContext>
        </Card>
      </Container>
    </div>
  );
};

export default BulkUpdatePage;
