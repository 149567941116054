import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    ...theme.typography.button,
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(3),
    color: theme.palette.swatch.secondary,
    "& a": {
      color: "inherit",
      textDecoration: "none",
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
    "& .material-icons": {
      fontSize: 20,
      marginLeft: 4,
      marginRight: 4,
    },
  },
});

const BreadCrumb = ({ classes, children }) => (
  <Typography className={classes.root}>{children}</Typography>
);

BreadCrumb.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(BreadCrumb);
