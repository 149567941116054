import { requestJSON, requestText } from "./http";

const startExport = (type, searchParameters, format, accessToken) =>
  format
    ? requestJSON({
        path: `/search-exports?format=${format}`,
        method: "POST",
        data: {
          __type: type,
          ...searchParameters,
        },
        accessToken,
      })
    : requestJSON({
        path: "/search-exports",
        method: "POST",
        data: {
          __type: type,
          ...searchParameters,
        },
        accessToken,
      });

const getExport = (searchExportId, accessToken) =>
  requestJSON({ path: `/search-exports/${searchExportId}`, accessToken });

const getDownloadUrl = (searchExportId, asAttachment, accessToken) =>
  requestText({
    path: `/search-exports/${searchExportId}/download${
      asAttachment ? "?asAttachment=true" : ""
    }`,
    accessToken,
  });

export default {
  startExport,
  getExport,
  getDownloadUrl,
};
