import { useAuth0 } from "@auth0/auth0-react";
import { authorizer, useTenant } from "@certane/arcadia-web-components";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import ActionButton from "../../components/common/ActionButton";
import ActionHeading from "../../components/common/ActionHeading";
import Container from "../../components/common/Container";
import HeaderBar from "../../components/common/HeaderBar";
import InlineInputEditSaveCancel from "../../components/common/InlineInputEditSaveCancel";
import PageHeading from "../../components/common/PageHeading";
import PageSubheading from "../../components/common/PageSubheading";
import FormDialog from "../../components/forms/FormDialog";
import CreateSavedSearchForm, {
  CREATE_SAVED_SEARCH_FORM,
} from "../../components/savedsearch/CreateSavedSearchForm";
import SavedSearchDrawer from "../../components/savedsearch/SavedSearchDrawer";
import { useSavedSearches } from "../../components/savedsearch/useSavedSearches";
import getFilteredTypes, {
  filterableWorkItemTypes,
} from "../../components/workitem/getFilteredTypes";
import WorkItemFilters from "../../components/workitem/WorkItemFilters";
import WorkItemFloatingActionButton from "../../components/workitem/WorkItemFloatingActionButton";
import WorkItemList from "../../components/workitem/WorkItemList";
import {
  getLoggedInUser,
  getReferenceDataType,
  getWorkItemSearchPagination,
} from "../../reducers";
import { roles } from "@certane/arcadia-web-components";
import { performExport } from "../../util/asyncRequestHelper";
import {
  clearIcon,
  downloadIcon,
  saveIcon,
  viewsIcon,
  workItemIcon,
} from "../../util/icons";
import useLocationStateFilter from "../../util/locationStateFilter";
import logger from "../../util/logger";
import usePersistedState from "../../util/persistedState";
import {
  createWorkItemFilterParameters,
  createWorkItemSearchParameters,
} from "../../util/searchParameterUtils";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  contents: {
    marginBottom: "75px",
  },
}));

const defaultFilter = {
  textSearch: "",
  namedQuery: "",
  ownership: "",
  status: "",
  dueDate: "",
  types: [],
  sargonServiceProviderIds: [],
  serviceProviderIds: [],
  productIds: [],
  ownerIds: [],
};

const Index = () => {
  const { getAccessTokenSilently, user } = useAuth0();
  const classes = useStyles();
  const history = useHistory();
  const { tenant } = useTenant();

  const loggedInUser = useSelector(getLoggedInUser);
  const workItemTypes = useSelector((state) =>
    getReferenceDataType(state, "WorkItemType")
  );
  const workItemStatuses = useSelector((state) =>
    getReferenceDataType(state, "WorkItemStatus")
  );
  const pagination = useSelector(getWorkItemSearchPagination);

  const filteredTypes = useMemo(
    () =>
      getFilteredTypes(workItemTypes, loggedInUser, tenant, {
        workItemTypes: filterableWorkItemTypes,
      }),
    [workItemTypes, loggedInUser, tenant]
  );

  const [isAdmin] = useState(
    loggedInUser
      ? _.intersection(roles.ADMIN_ROLES, loggedInUser.roles).length > 0
      : false
  );
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [sortBy, setSortBy] = usePersistedState(
    `work-item-listing-sort-${loggedInUser.email}`,
    {
      field: "dueDate",
      direction: "asc",
    }
  );
  const [filter, setFilter] = usePersistedState(
    `work-item-listing-filter-${loggedInUser.email}`,
    defaultFilter,
    ["textSearch"]
  );
  const [previousFilter, setPreviousFilter] = useState(filter);
  const [
    savedSearch,
    setSavedSearch,
    savedSearches,
    createSavedSearch,
    updateSavedSearch,
    deleteSavedSearch,
    searchFavourites,
    addFavourite,
    deleteFavourite,
  ] = useSavedSearches(loggedInUser, "WorkItem", getAccessTokenSilently);

  // Copy filter to a ref so we can refer to it in closures without reading stale values
  const refFilter = useRef(filter);
  useEffect(() => {
    refFilter.current = filter;
  }, [filter]);

  const mergeFilter = (f) => {
    if (f.namedQuery || f.namedQuery === null) {
      setFilter({
        ...filter,
        ...f,
      });
    } else {
      setFilter({ ...f });
    }
  };

  useLocationStateFilter(mergeFilter, history);

  const clearFilter = useCallback(() => setFilter({ ...defaultFilter }), []);

  const updateFilter = useCallback(
    (key, value) => {
      setFilter({
        ...filter,
        [key]: value,
      });
    },
    [filter]
  );

  const updateFilterMulti = useCallback(
    (delta) => {
      setFilter({
        ...filter,
        ...delta,
      });
    },
    [filter]
  );

  const clearFilters = useCallback(() => {
    clearFilter();
    setSavedSearch(null);
    history.push("/work-items");
  }, [history]);

  const getSearchParameters = () => {
    const orderByField =
      sortBy.direction === "asc" ? sortBy.field : `-${sortBy.field}`;
    return createWorkItemSearchParameters(
      filter,
      workItemStatuses,
      filteredTypes,
      loggedInUser,
      pagination,
      orderByField
    );
  };

  // make the filter / sort based on any current saved search
  useEffect(() => {
    if (savedSearch) {
      const { searchParameters } = savedSearch;
      const { orderByField } = searchParameters;
      const newFilter = createWorkItemFilterParameters(
        searchParameters,
        workItemStatuses
      );
      const [field, direction] =
        orderByField[0] === "-"
          ? [orderByField.substring(1), "desc"]
          : [orderByField, "asc"];

      if (!_.isEqual(filter, newFilter)) {
        setFilter({ ...newFilter });
      }
      if (
        !_.isEqual(sortBy, {
          field,
          direction,
        })
      ) {
        setSortBy({
          field,
          direction,
        });
      }
    }
  }, [savedSearch]);

  const savedSearchMatchesFilter = (savedSearchParameters) => {
    const savedSearchFilter = createWorkItemFilterParameters(
      savedSearchParameters,
      workItemStatuses
    );
    return _.isEqual(savedSearchFilter, filter);
  };

  // if the user edits the filter check if we have a view matching the filter
  // if a view is active and doesn't match, clear the selected view
  useEffect(() => {
    if (!_.isEqual(previousFilter, filter)) {
      if (
        savedSearch &&
        savedSearchMatchesFilter(savedSearch.searchParameters)
      ) {
        // ignore
      } else {
        if (savedSearch) {
          logger.info(
            "filter changed and doesn't match saved search",
            savedSearch
          );
          setSavedSearch(null);
        }
      }

      setPreviousFilter(filter);
    }
  }, [filter, savedSearch]);

  const onSelectSavedSearch = (selectedSavedSearch) => {
    setSavedSearch(selectedSavedSearch);
    setDrawerOpen(false);
  };

  const onSave = (formResults) => {
    const { saveAsNew, name, global, existingView } = formResults;
    const searchParameters = {
      __type: "WorkItem",
      ...getSearchParameters(),
    };

    if (saveAsNew) {
      createSavedSearch({
        name,
        global,
        searchParameters,
      }).then(() => setSaveDialogOpen(false));
    } else {
      updateSavedSearch(existingView, { global, searchParameters }).then(() =>
        setSaveDialogOpen(false)
      );
    }
  };

  const onDownload = async () => {
    const accessToken = await getAccessTokenSilently();
    performExport(
      "WorkItem",
      getSearchParameters(),
      "CSV",
      accessToken,
      null,
      "Work items download",
      "Please wait while we export your work items",
      "This window can be closed"
    );
  };

  const onUpdateSavedSearch = (name) => {
    return updateSavedSearch(savedSearch.id, { name });
  };

  const searchIsReadonly = (search) => {
    if (search.global) {
      return !isAdmin;
    }
    return search.owner.id !== loggedInUser.id;
  };

  const actions = [
    <ActionButton
      key="clear-filters"
      tooltip="Clear filters"
      icon={clearIcon()}
      onClick={clearFilters}
      data-cy="clear-filters-button"
    />,
    <ActionButton
      key={"saveas"}
      tooltip="Save as"
      icon={saveIcon()}
      onClick={() => setSaveDialogOpen(true)}
      disabled={!!savedSearch}
      data-cy="save-as-button"
    />,
    <ActionButton
      key="views"
      tooltip="Views"
      icon={viewsIcon()}
      onClick={() => setDrawerOpen(true)}
      data-cy="views-button"
    />,
    <ActionButton
      key={"download"}
      tooltip="Download"
      icon={downloadIcon()}
      onClick={onDownload}
      data-cy="download-button"
    />,
  ];

  return (
    <div>
      <HeaderBar fluid>
        <ActionHeading
          heading={
            <>
              <PageHeading
                icon={workItemIcon()}
                heading="Work items"
                data-cy="workItems"
              />
              {savedSearch && (
                <PageSubheading
                  inset
                  subheading={
                    <InlineInputEditSaveCancel
                      minWidth="500px"
                      value={savedSearch.name}
                      readonly={searchIsReadonly(savedSearch)}
                      onChange={onUpdateSavedSearch}
                      validate={(value) => !!value}
                    />
                  }
                />
              )}
            </>
          }
          actions={actions}
        />
      </HeaderBar>
      <FormDialog
        title="Save view as"
        submitButtonText="Save"
        formComponent={CreateSavedSearchForm}
        formName={CREATE_SAVED_SEARCH_FORM}
        open={saveDialogOpen}
        onCancel={() => setSaveDialogOpen(false)}
        onSubmit={onSave}
        savedSearches={savedSearches}
        isAdmin={isAdmin}
        initialValues={{ saveAsNew: true, global: false }}
      />
      <SavedSearchDrawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onSelect={onSelectSavedSearch}
        onDelete={deleteSavedSearch}
        savedSearches={savedSearches}
        activeSearch={savedSearch}
        loggedInUser={loggedInUser}
        searchFavourites={searchFavourites}
        addFavourite={addFavourite}
        deleteFavourite={deleteFavourite}
      />
      {authorizer.check("work-item", "editor", user, tenant?.id) && (
        <WorkItemFloatingActionButton />
      )}
      <WorkItemFilters
        filter={filter}
        updateFilter={updateFilter}
        updateFilterMulti={updateFilterMulti}
      />
      <Container fluid className={classes.contents}>
        <WorkItemList
          filter={filter}
          clearFilter={clearFilters}
          sortBy={sortBy}
          updateSort={setSortBy}
          data-cy="list"
        />
      </Container>
    </div>
  );
};

export default Index;
