import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { SubmissionError } from "redux-form";
import { includeTenantParam } from "@certane/arcadia-web-components";
import {
  deleteTag,
  fetchTagUsages,
  getTagById,
  patchTag,
} from "../../../actions/tags";
import AlertDialog from "../../../components/common/AlertDialog";
import BreadcrumbLink from "../../../components/common/BreadcrumbLink";
import Container from "../../../components/common/Container";
import GridListing from "../../../components/common/GridListing";
import HeaderBar from "../../../components/common/HeaderBar";
import InlineInputEditSaveCancel from "../../../components/common/InlineInputEditSaveCancel";
import PageHeading from "../../../components/common/PageHeading";
import Scorecard from "../../../components/common/Scorecard";
import LoadingIndicator from "../../../components/LoadingIndicator";
import {
  getActiveTag,
  getReferenceDataDescription,
  getTagUsages,
  isLoading,
  isTagUsagesLoading,
} from "../../../reducers";
import { tagIcon } from "../../../util/icons";
import Error404 from "../../../components/error/Error404";
import { useAuth0 } from "@auth0/auth0-react";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    margin: 10,
  },
  card: {
    marginTop: theme.spacing(2),
  },
  loading: {
    marginTop: theme.spacing(2),
  },
  usages: {
    paddingTop: theme.spacing(2),
  },
});

const TagId = ({
  match: {
    params: { tagId },
  },
  history,
  classes,
  tag,
  loading,
  getTagTypeDescription,
  usages,
  usagesLoading,
  localGetTagById,
  localFetchTagUsages,
  localDeleteTag,
  localPatchTag,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      localGetTagById(tagId, accessToken);
      localFetchTagUsages(tagId, accessToken);
    })();
  }, [getAccessTokenSilently, tagId]);

  const getUsageLink = (usage) => {
    const links = {
      Risk: `/risk/risks/${usage.id}`,
      WorkItem: `/admin/work-items/${usage.id}`,
      Media: `/metropolis/media/${usage.id}`,
      Snippet: `/metropolis/snippet/${usage.id}`,
    };
    return _.get(links, usage.type, "/");
  };

  const hasUsages = () => usages.length > 0;

  const handleDelete = async () => {
    const accessToken = await getAccessTokenSilently();
    localDeleteTag(tagId, accessToken)
      .then(() => {
        history.push("/admin/tags");
      })
      .catch((error) => {
        throw new SubmissionError({ _error: error.message });
      })
      .finally(() => {
        setDialogOpen(false);
      });
  };

  if (!tag && loading) {
    return <LoadingIndicator size={60} />;
  }

  if (!tag && !loading) {
    return <Error404 />;
  }

  const updateTag = async (text) => {
    const accessToken = await getAccessTokenSilently();
    return localPatchTag(
      tag.id,
      {
        ...tag,
        text,
      },
      "Updated text",
      accessToken
    );
  };

  return (
    <div className={classes.root}>
      {/* CONFIRM DELETE DIALOG */}
      <AlertDialog
        title="Delete tag?"
        body="Are you sure you want to delete this tag?"
        submitButtonText="Delete"
        open={dialogOpen}
        onCancel={() => setDialogOpen(false)}
        onSubmit={() => handleDelete()}
      />

      <HeaderBar>
        <BreadcrumbLink to="/admin/tags" label="Tags" includeArrow />
        <PageHeading icon={tagIcon()} heading={tag.text} />
      </HeaderBar>
      <Container>
        <Card className={classes.card} elevation={0}>
          <CardHeader
            title="Tag details"
            action={
              <Tooltip title="Delete" disableFocusListener>
                <div>
                  <IconButton
                    aria-label="Delete"
                    aria-haspopup="true"
                    disabled={usagesLoading || hasUsages()}
                    onClick={() => setDialogOpen(true)}
                  >
                    <Icon>delete</Icon>
                  </IconButton>
                </div>
              </Tooltip>
            }
          />
          <CardContent>
            <Scorecard label="Text">
              <InlineInputEditSaveCancel
                value={tag.text}
                onChange={updateTag}
                fullWidth
              />
            </Scorecard>
            <Scorecard label="Type">
              {getTagTypeDescription(tag.type)}
            </Scorecard>
            <Scorecard label="Usages" className={classes.usages}>
              <GridListing
                sortedData={usages}
                loading={usagesLoading}
                sortBy={{}}
                dense={false}
                updateSort={() => {
                  // do nothing.
                }}
                columns={[
                  {
                    label: "Type",
                    name: "type",
                    size: 3,
                    sortable: false,
                    render: (usage) => <span>{usage.type}</span>,
                  },
                  {
                    label: "Link",
                    name: "link",
                    size: 9,
                    sortable: false,
                    render: (usage) => (
                      <Link
                        to={includeTenantParam(getUsageLink(usage))}
                        target="_blank"
                      >
                        {usage.id}
                      </Link>
                    ),
                  },
                ]}
              />
            </Scorecard>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
};

TagId.propTypes = {
  classes: PropTypes.object.isRequired,
  getTagTypeDescription: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  tag: PropTypes.object,
  usages: PropTypes.array.isRequired,
  usagesLoading: PropTypes.bool.isRequired,
  localDeleteTag: PropTypes.func.isRequired,
  localGetTagById: PropTypes.func.isRequired,
  localPatchTag: PropTypes.func.isRequired,
  localFetchTagUsages: PropTypes.func.isRequired,
};

TagId.defaultProps = {
  tag: null,
};

const mapStateToProps = (state) => ({
  getTagTypeDescription: (id) =>
    getReferenceDataDescription(state, "TagType", id),
  tag: getActiveTag(state),
  usages: getTagUsages(state),
  usagesLoading: isTagUsagesLoading(state),
  loading: isLoading(state),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    localDeleteTag: deleteTag,
    localGetTagById: getTagById,
    localPatchTag: patchTag,
    localFetchTagUsages: fetchTagUsages,
  })
)(TagId);
