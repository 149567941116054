import { useAuth0 } from "@auth0/auth0-react";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import _ from "lodash";
import PropTypes from "prop-types";
import ReplyIcon from "@material-ui/icons/Reply";
import { connect } from "react-redux";
import { compose } from "redux";
import api from "../../../../services/api";
import dates from "../../../../util/dates";
import {
  attachmentFile,
  downloadIcon,
  warningIcon,
} from "../../../../util/icons";
import logger from "../../../../util/logger";
import { getActionsForWorkItem } from "../../../../util/workItemTypeUtils";
import CollapseExpand from "../../../CollapseExpand";
import ErrorBoundary from "../../../ErrorBoundary";
import HtmlView from "../../../richtext/HtmlView";
import FileIcon from "../../FileIcon";
import { startWorkItemAction } from "../../../../actions/workItemActions";

const styles = (theme) => ({
  box: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  heading: {
    color: theme.palette.swatch.secondary,
  },
  replyIndicator: {
    color: theme.palette.success.main,
    fontSize: theme.typography.body1.fontSize,
  },
  mentions: {
    color: theme.palette.swatch.secondary,
  },
  text: {
    marginTop: theme.spacing(1),
    color: theme.palette.swatch.primary,
    whiteSpace: "pre-wrap",
  },
  toChip: {
    marginRight: theme.spacing(1),
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.swatch.grey5,
  },
  removed: {
    "& span": {
      textDecoration: "line-through",
    },
  },
  parseError: {
    borderRadius: "16px",
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.warning.main}`,
    float: "left",
  },
});

const isEmail = (entry) => _.has(entry, "email");

const useEmail = (entry) => {
  const [hasEmail, setHasEmail] = useState(false);
  const [email, setEmail] = useState(null);

  useEffect(() => {
    if (isEmail(entry)) {
      setHasEmail(true);
      setEmail(entry.email);
    }
  }, [entry]);

  return [hasEmail, email];
};

const onFileClick = (source, document, workItemId, accessToken) => {
  api.workItems
    .documentDownloadUrl(workItemId, source, document.id, false, accessToken)
    .then((location) => window.open(location, "_blank"));
};

const getSendEmailAction = (workItem, definition, loggedInUser) => {
  return getActionsForWorkItem(workItem, definition, loggedInUser).find(
    (actionAssignment) =>
      actionAssignment.action === "ADD_EMAIL" &&
      !actionAssignment.input.initialValues["email.incoming"]
  );
};

const getChipClasses = (classes, removed) =>
  classNames({
    [classes.chip]: true,
    [classes.removed]: removed,
  });

const Email = ({
  classes,
  entry,
  workItem,
  definition,
  loggedInUser,
  closed,
  localStateWorkItemAction,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [hasEmail, email] = useEmail(entry);
  const [sendEmailAction] = useState(
    getSendEmailAction(workItem, definition, loggedInUser)
  );

  const getEmailAddressAsString = (emailAddress) => {
    if (emailAddress?.name) {
      return `${emailAddress?.name} <${emailAddress.address}>`;
    }
    return emailAddress.address;
  };

  const getTitle = (doc) => {
    if (doc.removed) {
      return `Uploaded by ${doc.createdByName} on ${dates.formatTimestamp(
        doc.uploaded
      )}\nRemoved by ${doc.deletedByName} on ${dates.formatTimestamp(
        doc.deleted
      )}`;
    }
    return `Uploaded by ${doc.createdByName} on ${dates.formatTimestamp(
      doc.uploaded
    )}`;
  };

  const handleFileClick = async (source, document) => {
    const accessToken = await getAccessTokenSilently();
    onFileClick(source, document, workItem.id, accessToken);
  };

  const replyEmail = () => {
    const replyHtmlBody = `
        <br/><br/>${email.from?.name} &lt;${email.from.address}&gt; wrote:<br/>
        <blockquote>${email.htmlBody}</blockquote>
    `;

    const replyToAction = {
      ...sendEmailAction,
      name: "Reply to email",
      input: {
        ...sendEmailAction.input,
        helpTexts: [
          {
            helpText: `Reply to ${email.from?.name} <${email.from.address}>`,
            regions: [],
          },
        ],
        initialValues: {
          ...sendEmailAction.input.initialValues,
          email: {
            incoming: false,
            to: [
              {
                name: email.from?.name,
                address: email.from.address,
              },
            ],
            subject: `RE: ${email.subject}`,
            htmlBody: replyHtmlBody,
            replyToEmailId: email.id,
          },
        },
      },
    };

    localStateWorkItemAction(replyToAction);
  };

  const AttachmentsIcon = attachmentFile();
  const DownloadIcon = downloadIcon();
  const WarningIcon = warningIcon();

  return (
    <>
      {hasEmail && (
        <div className={classes.box} data-cy="email">
          <Typography
            variant="body2"
            component="div"
            className={classes.heading}
          >
            Email {email.incoming ? "received" : "sent"}
            &nbsp;
            {email.replied && <Chip label="Replied" size="small" disabled />}
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="nowrap"
            alignItems="center"
          >
            <Box flexGrow={1}>
              <Typography variant="h6" data-cy="subject">
                {email.subject}
              </Typography>
            </Box>
            {email.incoming && !closed && !!sendEmailAction && (
              <Box flexGrow={0} flexShrink={0} flexBasis="auto">
                <IconButton
                  aria-label="reply"
                  title="Reply"
                  onClick={() => replyEmail()}
                  data-cy="reply"
                >
                  <ReplyIcon fontSize="small" />
                </IconButton>
              </Box>
            )}
            {email.originalEmail && (
              <Box flexGrow={0} flexShrink={0} flexBasis="auto" pl={1}>
                <IconButton
                  data-cy="download"
                  aria-label="download"
                  title="Download email file"
                  onClick={() =>
                    handleFileClick("EMAIL_ORIGINAL", email.originalEmail)
                  }
                >
                  <DownloadIcon fontSize="small" />
                </IconButton>
              </Box>
            )}
          </Box>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={1} data-cy="from">
              From
            </Grid>
            <Grid item xs={11}>
              <Chip
                label={getEmailAddressAsString(email.from)}
                size="small"
                variant="outlined"
              />
            </Grid>

            <Grid item xs={1} data-cy="to">
              To
            </Grid>
            <Grid item xs={11}>
              {email.to.map((to) => (
                <Chip
                  key={to.address}
                  className={classes.toChip}
                  label={getEmailAddressAsString(to)}
                  size="small"
                  variant="outlined"
                />
              ))}
            </Grid>

            {email.cc?.length > 0 && (
              <>
                <Grid item xs={1} data-cy="Cc">
                  Cc
                </Grid>
                <Grid item xs={11}>
                  {email.cc.map((cc) => (
                    <Chip
                      key={cc.address}
                      className={classes.toChip}
                      label={getEmailAddressAsString(cc)}
                      size="small"
                      variant="outlined"
                    />
                  ))}
                </Grid>
              </>
            )}

            <Grid item xs={1} data-cy="date">
              Date
            </Grid>
            <Grid item xs={11}>
              {dates.formatTimestamp(email.sent)}
            </Grid>
            {email.attachments && email.attachments.length > 0 && (
              <>
                <Grid item xs={1}>
                  <AttachmentsIcon />
                </Grid>
                <Grid item xs={11}>
                  {email.attachments.map((attachment) => (
                    <Chip
                      key={attachment.id}
                      className={getChipClasses(classes, attachment.removed)}
                      clickable={!attachment.removed}
                      title={getTitle(attachment)}
                      onClick={
                        attachment.removed
                          ? () => {
                              // do nothing.
                            }
                          : () => handleFileClick("EMAIL", attachment)
                      }
                      avatar={
                        <Avatar className={classes.avatar}>
                          <FileIcon document={attachment} />
                        </Avatar>
                      }
                      label={attachment.fileName}
                    />
                  ))}
                </Grid>
              </>
            )}
          </Grid>
          <Typography
            component="div"
            variant="body2"
            className={classes.text}
            data-cy="text"
          >
            <CollapseExpand isNewUI>
              <ErrorBoundary
                renderError={({ e }) => {
                  logger.error("Couldn't parse html", e);
                  return (
                    <div className={classes.parseError}>
                      <Grid container>
                        <Grid item>
                          <WarningIcon />
                        </Grid>
                        <Grid item>
                          Unable to preview email, please download to view
                          original
                        </Grid>
                      </Grid>
                    </div>
                  );
                }}
              >
                <HtmlView value={email.htmlBody} />
              </ErrorBoundary>
            </CollapseExpand>
          </Typography>
        </div>
      )}
    </>
  );
};

Email.propTypes = {
  classes: PropTypes.object.isRequired,
  entry: PropTypes.object.isRequired,
  closed: PropTypes.bool.isRequired,
  workItem: PropTypes.object.isRequired,
  loggedInUser: PropTypes.object,
  definition: PropTypes.object.isRequired,
  localStateWorkItemAction: PropTypes.func.isRequired,
};

Email.defaultProps = {
  loggedInUser: null,
};

export default compose(
  withStyles(styles),
  connect(null, {
    localStateWorkItemAction: startWorkItemAction,
  })
)(Email);
