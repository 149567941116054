import { requestJSON } from "./http";

const search = (accessToken) =>
  requestJSON({
    path: "/saved-searches/favourites",
    accessToken,
  });

const add = (savedSearchId, accessToken) =>
  requestJSON({
    path: `/saved-searches/favourites/${savedSearchId}`,
    method: "PUT",
    accessToken,
  });

const remove = (savedSearchId, accessToken) =>
  requestJSON({
    path: `/saved-searches/favourites/${savedSearchId}`,
    method: "DELETE",
    accessToken,
  });

export default {
  search,
  add,
  remove,
};
