import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import React from "react";
import FileIcon from "../workitem/FileIcon";
import dates from "../../util/dates";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(1) / 4,
    textTransform: "none",
    margin: theme.spacing(1) / 2,
  },
  listItem: {
    paddingLeft: 4,
  },
});

function AttachmentFile(props) {
  const { classes, document, onFileClick } = props;

  const tooltip = `${document.createdByName} - ${dates.formatTimestamp(
    dates.parseTimestamp(document.uploaded)
  )}`;

  return (
    <Tooltip title={tooltip} placement="top" disableFocusListener>
      <Button
        className={classes.root}
        onClick={() => onFileClick(document)}
        data-cy="fileButton"
      >
        <FileIcon document={document} data-cy="fileIcon" />
        <ListItemText
          primary={document.fileName}
          className={classes.listItem}
        />
      </Button>
    </Tooltip>
  );
}

AttachmentFile.propTypes = {
  classes: PropTypes.object.isRequired,
  document: PropTypes.object.isRequired,
  onFileClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(AttachmentFile);
