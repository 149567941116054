import { useAuth0 } from "@auth0/auth0-react";
import { useTenant } from "@certane/arcadia-web-components";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { patchWorkItem } from "../../../actions/workItems";
import {
  getLabels,
  getLoggedInUser,
  getReferenceDataType,
} from "../../../reducers";
import logger from "../../../util/logger";
import {
  appliesByCategory,
  appliesByParentType,
  appliesByParties,
  appliesByRegion,
  appliesByRoles,
  getResolvedDefinition,
} from "../../../util/workItemTypeUtils";
import EntityRelationshipCard from "../../business/EntityRelationshipCard";
import QuestionsCard from "../../questions/QuestionCard";
import AuditFindingCard from "./auditfinding/AuditFindingCard";
import ClaimAssessmentCard from "./claimassessment/ClaimAssessmentCard";
import ClaimsCard from "./claims/ClaimsCard";
import ClientCard from "./client/ClientCard";
import ComplaintCard from "./complaint/ComplaintCard";
import ThirdPartyAuthorityCard from "./complaint/thirdpartyauthority/ThirdPartyAuthorityCard";
import ComplianceCard from "./compliance/ComplianceCard";
import ContactsCard from "./contacts/ContactsCard";
import ContractCard from "./contract/ContractCard";
import ControlVerificationComponentCard from "./controlverification/ControlVerificationComponentCard";
import CorrespondenceCard from "./correspondence/CorrespondenceCard";
import DeathBenefitCard from "./deathbenefit/DeathBenefitCard";
import DependantsCard from "./dependants/DependantsCard";
import DependantsChecklistCard from "./dependants/DependantsChecklistCard";
import DocumentFormChecklistCard from "./documentchecklist/DocumentFormChecklistCard";
import HardshipCard from "./hardshipclaim/HardshipCard";
import IncidentCard from "./incident/IncidentCard";
import IncidentActionCard from "./incidentaction/IncidentActionCard";
import AssessmentCard from "./incidentassessment/AssessmentCard";
import IncidentBreachReportCard from "./incidentbreachreport/IncidentBreachReportCard";
import InsuranceCard from "./insurance/InsuranceCard";
import LinkedComplaintsCard from "./linkedcomplaints/LinkedComplaintsCard";
import LinkedIncidentsCard from "./linkedincidents/LinkedIncidentsCard";
import LinkedRegulatorCorrespondenceCard from "./linkedregulatorcorrespondence/LinkedRegulatorCorrespondenceCard";
import MemberDetailsCard from "./memberdetails/MemberDetailsCard";
import RiskAssessment from "./riskassessment/RiskAssessment";
import RiskControlSummaryCard from "./riskcontrolsummary/RiskControlSummaryCard";
import RiskControlVerificationDetailsCard from "./riskcontrolverification/RiskControlVerificationDetailsCard";
import RiskMeasureUpdates from "./riskmeasureupdate/RiskMeasureUpdates";
import RiskSummaryCard from "./risksummary/RiskSummaryCard";
import TerminalIllnessCard from "./terminalillness/TerminalIllnessCard";

const relationshipTypes = [
  "PROMOTER",
  "SUB_PROMOTER",
  "TRUSTEE",
  "RESPONSIBLE_ENTITY",
  "KIWISAVER_MANAGER",
];

const WorkItemComponentCards = ({
  cardClass,
  workItem,
  readonly,
  tab,
  postUpdate,
  loggedInUser,
  workItemTypes,
  labels,
  scrollToQuestionId,
  onScrolledToQuestion,
  onScrollToComponent,
  onScrolledToComponent,
  scrollToComponent,
  localPatchWorkItem,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { tenant } = useTenant();
  const definition = getResolvedDefinition(
    workItemTypes,
    workItem.type,
    workItem.parent?.type
  );
  const fieldLabels = labels[definition.id];

  useEffect(() => {
    if (scrollToComponent) {
      const element = document.getElementById(`comp-${scrollToComponent}`);
      if (element) {
        logger.info("found element for component", scrollToComponent);

        setTimeout(() => {
          element.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
          onScrolledToComponent();
        }, 200);
      }
    }
  }, [scrollToComponent]);

  const availableComponents = definition.components
    .filter((component) => appliesByCategory(workItem, component))
    .filter((component) => appliesByParentType(workItem, component))
    .filter((component) => appliesByParties(workItem, loggedInUser, component))
    .filter((component) => !tab || tab === component.tab);

  const renderComponents = () =>
    availableComponents.map((componentAssignment) => {
      const customisations = componentAssignment.fieldCustomisations
        .filter((customisation) => appliesByCategory(workItem, customisation))
        .filter((customisation) => appliesByRoles(loggedInUser, customisation))
        .filter((customisation) => appliesByRegion(workItem, customisation));

      const componentReadOnly =
        readonly ||
        (loggedInUser.externalUser && !componentAssignment.externalEdit);

      const onChange = componentReadOnly
        ? null
        : async (values) => {
            const accessToken = await getAccessTokenSilently();
            return localPatchWorkItem(
              workItem.id,
              values,
              "Updated",
              accessToken
            );
          };

      switch (componentAssignment.component) {
        case "AUDIT_FINDING":
          return (
            <Fragment key={componentAssignment.component}>
              <span id={`comp-${componentAssignment.component}`} />
              <AuditFindingCard
                className={cardClass}
                workItem={workItem}
                fieldLabels={fieldLabels}
                customisations={customisations}
                onChange={onChange}
              />
            </Fragment>
          );
        case "CLAIMS":
          return (
            <Fragment key={componentAssignment.component}>
              <span id={`comp-${componentAssignment.component}`} />
              <ClaimsCard
                className={cardClass}
                workItem={workItem}
                fieldLabels={fieldLabels}
                customisations={customisations}
                onChange={onChange}
              />
            </Fragment>
          );
        case "CLIENT":
          return (
            <Fragment key={componentAssignment.component}>
              <span id={`comp-${componentAssignment.component}`} />
              <ClientCard
                className={cardClass}
                workItem={workItem}
                fieldLabels={fieldLabels}
                customisations={customisations}
                onChange={onChange}
              />
            </Fragment>
          );
        case "COMPLAINT":
          return (
            <Fragment key={componentAssignment.component}>
              <span id={`comp-${componentAssignment.component}`} />
              <ComplaintCard
                className={cardClass}
                workItem={workItem}
                fieldLabels={fieldLabels}
                customisations={customisations}
                onChange={onChange}
              />
            </Fragment>
          );
        case "COMPLIANCE":
          return (
            <Fragment key={componentAssignment.component}>
              <span id={`comp-${componentAssignment.component}`} />
              <ComplianceCard
                className={cardClass}
                workItem={workItem}
                fieldLabels={fieldLabels}
                onChange={onChange}
              />
            </Fragment>
          );
        case "CONTACTS":
          return (
            <Fragment key={componentAssignment.component}>
              <span id={`comp-${componentAssignment.component}`} />
              <ContactsCard
                className={cardClass}
                workItem={workItem}
                fieldLabels={fieldLabels}
                onChange={onChange}
              />
            </Fragment>
          );
        case "CONTRACT":
          return (
            <Fragment key={componentAssignment.component}>
              <span id={`comp-${componentAssignment.component}`} />
              <ContractCard
                className={cardClass}
                workItem={workItem}
                fieldLabels={fieldLabels}
                onChange={onChange}
              />
            </Fragment>
          );
        case "CONTROL_VERIFICATION":
          return (
            <Fragment key={componentAssignment.component}>
              <span id={`comp-${componentAssignment.component}`} />
              <ControlVerificationComponentCard
                workItem={workItem}
                fieldLabels={fieldLabels}
                customisations={customisations}
                onChange={onChange}
              />
            </Fragment>
          );
        case "CORRESPONDENCE":
          return (
            <Fragment key={componentAssignment.component}>
              <span id={`comp-${componentAssignment.component}`} />
              <CorrespondenceCard
                className={cardClass}
                workItem={workItem}
                fieldLabels={fieldLabels}
                customisations={customisations}
                onChange={onChange}
              />
            </Fragment>
          );
        case "DEATH_BENEFIT":
          return (
            <Fragment key={componentAssignment.component}>
              <span id={`comp-${componentAssignment.component}`} />
              <DeathBenefitCard
                className={cardClass}
                workItem={workItem}
                fieldLabels={fieldLabels}
                customisations={customisations}
                onChange={onChange}
              />
            </Fragment>
          );
        case "DEPENDANTS":
          return (
            <Fragment key={componentAssignment.component}>
              <span id={`comp-${componentAssignment.component}`} />
              <DependantsCard
                className={cardClass}
                workItem={workItem}
                fieldLabels={fieldLabels}
                onChange={onChange}
              />
              {_.get(workItem, "dependants.length", 0) > 0 && (
                <DependantsChecklistCard
                  className={cardClass}
                  workItem={workItem}
                  fieldLabels={fieldLabels}
                  onChange={onChange}
                />
              )}
            </Fragment>
          );
        case "DOCUMENT_CHECKLIST":
          return (
            <Fragment key={componentAssignment.component}>
              <span id={`comp-${componentAssignment.component}`} />
              <DocumentFormChecklistCard
                className={cardClass}
                workItem={workItem}
                fieldLabels={fieldLabels}
                customisations={customisations}
                onChange={onChange}
              />
            </Fragment>
          );
        case "ENTITY_RELATIONSHIP":
          return (
            <Fragment key={componentAssignment.component}>
              <span id={`comp-${componentAssignment.component}`} />
              <EntityRelationshipCard
                className={cardClass}
                title={fieldLabels.labels.entityRelationship}
                baseEntity={workItem}
                fieldLabels={fieldLabels}
                customisations={customisations}
                onChange={onChange}
                defaultVisibilityScope={{
                  INTERNAL: "ORGANISATION",
                  EXTERNAL: "INDIVIDUAL",
                }}
                autoPopulateEntitiesByRelationshipTypes={relationshipTypes}
                filterEntitiesByRelationshipTypes={[]}
              />
            </Fragment>
          );
        case "HARDSHIP_CLAIM":
          return (
            <Fragment key={componentAssignment.component}>
              <span id={`comp-${componentAssignment.component}`} />
              <HardshipCard
                className={cardClass}
                workItem={workItem}
                fieldLabels={fieldLabels}
                customisations={customisations}
                onChange={onChange}
              />
            </Fragment>
          );
        case "CLAIM_ASSESSMENT":
          return (
            <Fragment key={componentAssignment.component}>
              <span id={`comp-${componentAssignment.component}`} />
              <ClaimAssessmentCard
                className={cardClass}
                workItem={workItem}
                readonly={componentReadOnly}
                fieldLabels={fieldLabels}
              />
            </Fragment>
          );
        case "INCIDENT":
          return (
            <Fragment key={componentAssignment.component}>
              <span id={`comp-${componentAssignment.component}`} />
              <IncidentCard
                className={cardClass}
                workItem={workItem}
                fieldLabels={fieldLabels}
                onChange={onChange}
                customisations={customisations}
              />
            </Fragment>
          );
        case "INCIDENT_ACTION":
          return (
            <Fragment key={componentAssignment.component}>
              <span id={`comp-${componentAssignment.component}`} />
              <IncidentActionCard
                className={cardClass}
                workItem={workItem}
                fieldLabels={fieldLabels}
                onChange={onChange}
              />
            </Fragment>
          );
        case "INCIDENT_ASSESSMENT":
          return (
            <Fragment key={componentAssignment.component}>
              <span id={`comp-${componentAssignment.component}`} />
              <AssessmentCard
                className={cardClass}
                workItem={workItem}
                fieldLabels={fieldLabels}
                onChange={onChange}
                customisations={customisations}
                scrollToComponent={onScrollToComponent}
              />
            </Fragment>
          );
        case "INCIDENT_BREACH_REPORT":
          return (
            <Fragment key={componentAssignment.component}>
              <span id={`comp-${componentAssignment.component}`} />
              <IncidentBreachReportCard
                className={cardClass}
                workItem={workItem}
                fieldLabels={fieldLabels}
                onChange={onChange}
                customisations={customisations}
                scrollToComponent={onScrollToComponent}
              />
            </Fragment>
          );
        case "INSURANCE":
          return (
            <Fragment key={componentAssignment.component}>
              <span id={`comp-${componentAssignment.component}`} />
              <InsuranceCard
                className={cardClass}
                workItem={workItem}
                fieldLabels={fieldLabels}
                customisations={customisations}
                onChange={onChange}
              />
            </Fragment>
          );
        case "LINKED_COMPLAINTS":
          return (
            <Fragment key={componentAssignment.component}>
              <span id={`comp-${componentAssignment.component}`} />
              <LinkedComplaintsCard
                className={cardClass}
                workItem={workItem}
                postUpdate={postUpdate}
              />
            </Fragment>
          );
        case "LINKED_INCIDENTS":
          return (
            <Fragment key={componentAssignment.component}>
              <span id={`comp-${componentAssignment.component}`} />
              <LinkedIncidentsCard
                className={cardClass}
                workItem={workItem}
                postUpdate={postUpdate}
                readonly={readonly}
              />
            </Fragment>
          );
        case "LINKED_REGULATOR_CORRESPONDENCE":
          return tenant.featureFlags["regulator-correspondence"] ? (
            <Fragment key={componentAssignment.component}>
              <span id={`comp-${componentAssignment.component}`} />
              <LinkedRegulatorCorrespondenceCard
                className={cardClass}
                workItem={workItem}
                postUpdate={postUpdate}
              />
            </Fragment>
          ) : null;
        case "MEMBER_DETAILS":
          return (
            <Fragment key={componentAssignment.component}>
              <span id={`comp-${componentAssignment.component}`} />
              <MemberDetailsCard
                className={cardClass}
                workItem={workItem}
                fieldLabels={fieldLabels}
                customisations={customisations}
                onChange={onChange}
              />
            </Fragment>
          );
        case "QUESTIONS":
          return (
            <Fragment key={componentAssignment.component}>
              <span id={`comp-${componentAssignment.component}`} />
              <QuestionsCard
                className={cardClass}
                workItem={workItem}
                readonly={componentReadOnly}
                fieldLabels={fieldLabels}
                customisations={customisations}
                scrollToQuestionId={scrollToQuestionId}
                onScrolledToQuestion={onScrolledToQuestion}
              />
            </Fragment>
          );
        case "RISK_SUMMARY":
          return (
            <Fragment key={componentAssignment.component}>
              <span id={`comp-${componentAssignment.component}`} />
              <RiskSummaryCard workItem={workItem} />
            </Fragment>
          );
        case "RISK_ASSESSMENT":
          return (
            <Fragment key={componentAssignment.component}>
              <span id={`comp-${componentAssignment.component}`} />
              <RiskAssessment workItem={workItem} onChange={onChange} />
            </Fragment>
          );
        case "RISK_CONTROL_VERIFICATION":
          return (
            <Fragment key={componentAssignment.component}>
              <span id={`comp-${componentAssignment.component}`} />
              <RiskControlVerificationDetailsCard
                workItem={workItem}
                fieldLabels={fieldLabels}
                customisations={customisations}
                onChange={onChange}
              />
            </Fragment>
          );
        case "RISK_MEASURE_UPDATE":
          return (
            <Fragment key={componentAssignment.component}>
              <span id={`comp-${componentAssignment.component}`} />
              <RiskMeasureUpdates workItem={workItem} onChange={onChange} />
            </Fragment>
          );
        case "RISK_CONTROL_SUMMARY":
          return (
            <Fragment key={componentAssignment.component}>
              <span id={`comp-${componentAssignment.component}`} />
              <RiskControlSummaryCard workItem={workItem} />
            </Fragment>
          );
        case "TERMINAL_ILLNESS":
          return (
            <Fragment key={componentAssignment.component}>
              <span id={`comp-${componentAssignment.component}`} />
              <TerminalIllnessCard
                className={cardClass}
                workItem={workItem}
                fieldLabels={fieldLabels}
                onChange={onChange}
              />
            </Fragment>
          );
        case "THIRD_PARTY_AUTHORITY":
          return (
            <Fragment key={componentAssignment.component}>
              <span id={`comp-${componentAssignment.component}`} />
              <ThirdPartyAuthorityCard
                className={cardClass}
                workItem={workItem}
                fieldLabels={fieldLabels}
                onChange={onChange}
              />
            </Fragment>
          );
        default:
          return null;
      }
    });

  return renderComponents();
};

WorkItemComponentCards.propTypes = {
  cardClass: PropTypes.string,
  workItem: PropTypes.object.isRequired,
  readonly: PropTypes.bool.isRequired,
  tab: PropTypes.string,
  postUpdate: PropTypes.func,
  scrollToQuestionId: PropTypes.string,
  onScrolledToQuestion: PropTypes.func.isRequired,
  onScrollToComponent: PropTypes.func.isRequired,
  onScrolledToComponent: PropTypes.func.isRequired,
  scrollToComponent: PropTypes.string,

  // redux
  loggedInUser: PropTypes.object,
  workItemTypes: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  localPatchWorkItem: PropTypes.func.isRequired,
};

WorkItemComponentCards.defaultProps = {
  cardClass: undefined,
  loggedInUser: null,
  tab: null,
  scrollToQuestionId: undefined,
  scrollToComponent: undefined,
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
  workItemTypes: getReferenceDataType(state, "WorkItemType"),
  labels: getLabels(state),
});

export default connect(mapStateToProps, {
  localPatchWorkItem: patchWorkItem,
})(WorkItemComponentCards);
