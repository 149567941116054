import makeStyles from "@material-ui/core/styles/makeStyles";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { getReferenceDataDescription } from "../../../../reducers";
import formatters from "../../../../util/formatters";
import CardSection from "../../../common/CardSection";
import SimpleChips from "../../../common/SimpleChips";
import WarningText from "../../../WarningText";
import ComponentScorecard from "../../../common/ComponentScorecard";
import OriginFieldsReadOnly from "./OriginFieldsReadOnly";

const useStyles = makeStyles((theme) => ({
  warning: {
    paddingTop: theme.spacing(1),
  },
}));

const IncidentSummaryFieldsReadOnly = ({
  workItem,
  customisations,
  fieldLabels,
  getRefDataDescription,
}) => {
  const classes = useStyles();
  const getLicenseDescriptions = (licenses) =>
    licenses.map((license) => getRefDataDescription(license, "License"));

  const { incident } = workItem;

  return (
    <>
      {incident && (
        <>
          <CardSection title={fieldLabels.nestedTypes.incident.labels.origin}>
            <OriginFieldsReadOnly
              fieldLabels={fieldLabels}
              name="incident.origin"
              customisations={customisations}
              workItem={workItem}
            />
          </CardSection>
          <CardSection title="Occurrence details">
            {incident && incident.businessArea && (
              <ComponentScorecard
                fieldLabels={fieldLabels}
                name="incident.businessArea"
                customisations={customisations}
                entity={workItem}
                render={(value) => value}
              />
            )}
            <ComponentScorecard
              fieldLabels={fieldLabels}
              name="incident.occurrenceStartDate"
              customisations={customisations}
              entity={workItem}
              render={(value) => formatters.date(value)}
            />
            <ComponentScorecard
              fieldLabels={fieldLabels}
              name="incident.occurrenceEndDate"
              customisations={customisations}
              entity={workItem}
              render={(value) => formatters.date(value)}
            />
            <ComponentScorecard
              fieldLabels={fieldLabels}
              name="incident.identifiedDate"
              customisations={customisations}
              entity={workItem}
              render={(value) => formatters.date(value)}
            />
            <ComponentScorecard
              fieldLabels={fieldLabels}
              name="incident.reportedDate"
              customisations={customisations}
              entity={workItem}
              render={(value) => formatters.date(value)}
            />
            <ComponentScorecard
              fieldLabels={fieldLabels}
              name="incident.investigationStartDate"
              customisations={customisations}
              entity={workItem}
              render={(value) => formatters.date(value)}
            />
            <ComponentScorecard
              fieldLabels={fieldLabels}
              name="incident.licenses"
              customisations={customisations}
              entity={workItem}
              render={(value) => (
                <SimpleChips
                  values={getLicenseDescriptions(value)}
                  blankLabel="-"
                />
              )}
            />
            <ComponentScorecard
              fieldLabels={fieldLabels}
              name="incident.estimatedFinancialImpact"
              customisations={customisations}
              entity={workItem}
              render={(value) => formatters.currency(value)}
            />
            <ComponentScorecard
              fieldLabels={fieldLabels}
              name="incident.actualFinancialImpact"
              customisations={customisations}
              entity={workItem}
              render={(value) => formatters.currency(value)}
            />
            <ComponentScorecard
              fieldLabels={fieldLabels}
              name="incident.affectedClients"
              customisations={customisations}
              entity={workItem}
              render={(value) => value}
            />
            <ComponentScorecard
              fieldLabels={fieldLabels}
              name="incident.cause"
              customisations={customisations}
              entity={workItem}
              render={(value) => getRefDataDescription(value, "IncidentCause")}
            />
            <ComponentScorecard
              fieldLabels={fieldLabels}
              name="incident.expectedReportableSituation"
              customisations={customisations}
              entity={workItem}
              defaultDisplay="Non reportable"
              render={(value) => (
                <>
                  {getRefDataDescription(value, "ReportableSituation")}

                  <div className={classes.warning}>
                    <WarningText text="A report must be submitted to ASIC if investigations take more than 30 days from the investigation start date." />
                  </div>
                </>
              )}
            />
          </CardSection>
          <CardSection title="Recurrence and prevention">
            <ComponentScorecard
              fieldLabels={fieldLabels}
              name="incident.preventable"
              customisations={customisations}
              entity={workItem}
              render={(value) => formatters.displayBoolean(value)}
            />
            <ComponentScorecard
              fieldLabels={fieldLabels}
              name="incident.reasonForIneffectiveness"
              customisations={customisations}
              entity={workItem}
              render={(value) => value}
            />
            <ComponentScorecard
              fieldLabels={fieldLabels}
              name="incident.identifier"
              customisations={customisations}
              entity={workItem}
              render={(value) =>
                getRefDataDescription(value, "IncidentIdentifier")
              }
            />
            <ComponentScorecard
              fieldLabels={fieldLabels}
              name="incident.recurrence"
              customisations={customisations}
              entity={workItem}
              render={(value) => formatters.displayBoolean(value)}
            />
            {incident && incident.recurrence && (
              <>
                <ComponentScorecard
                  fieldLabels={fieldLabels}
                  name="incident.previousIncidentReference"
                  customisations={customisations}
                  entity={workItem}
                  render={(value) => value}
                />
                <ComponentScorecard
                  fieldLabels={fieldLabels}
                  name="incident.previousIncidentDate"
                  customisations={customisations}
                  entity={workItem}
                  render={(value) => formatters.date(value)}
                />
              </>
            )}
          </CardSection>
        </>
      )}
    </>
  );
};

IncidentSummaryFieldsReadOnly.propTypes = {
  customisations: PropTypes.array.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  workItem: PropTypes.object.isRequired,

  // redux
  getRefDataDescription: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  getRefDataDescription: (id, type) =>
    getReferenceDataDescription(state, type, id),
});

export default connect(mapStateToProps)(IncidentSummaryFieldsReadOnly);
