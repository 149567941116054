import { combineReducers } from "redux";
import { REQUEST_LOGGED_IN_USER, SET_USER } from "../actions/auth";

const user = (state = null, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...action.user };
    default:
      return state;
  }
};

const isAuthenticated = (state = false, action) => {
  switch (action.type) {
    case `${REQUEST_LOGGED_IN_USER}_SUCCESS`:
      return true;
    case `${REQUEST_LOGGED_IN_USER}_FAILURE`:
      return false;
    default:
      return state;
  }
};

const error = (state = null, action) => {
  switch (action.type) {
    case `${REQUEST_LOGGED_IN_USER}_SUCCESS`:
      return null;
    case `${REQUEST_LOGGED_IN_USER}_FAILURE`:
      return { ...action.error, message: action.error.message };
    default:
      return state;
  }
};

export const getUser = (state) => state.user;

export const getIsAuthenticated = (state) => state.isAuthenticated;

export const getError = (state) => state.error;

export default combineReducers({
  user,
  isAuthenticated,
  error,
});
