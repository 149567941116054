import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import _, { debounce } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector } from "redux-form";
import { required } from "redux-form-validators";
import contentCategoriesApi from "../../../services/api/contentCategories";
import contentCategoryGroupsApi from "../../../services/api/contentCategoryGroups";
import { toDashCaseFromSentence } from "../../../util/strings";
import WrappedSelect from "../wrapper/WrappedSelect";
import WrappedTextField from "../wrapper/WrappedTextField";
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles((theme) => ({
  category: {
    marginBottom: theme.spacing(2),
  },
  categoryGroups: {
    marginLeft: theme.spacing(1),
  },
}));

const validateSlug = (slug) => {
  if (!slug) {
    return "Slug is required";
  }

  if (/^[a-z0-9]+(-[a-z0-9]+)*$/.test(slug)) {
    return null;
  }

  return "Must only include lower case alphanumeric characters separated by hyphens";
};

const MediaDetailFields = ({
  fieldLabels,
  editMode,
  selectedSlug,
  selectedName,
  selectedCategory,
  change,
  touch,
}) => {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const [autoSlug, setAutoSlug] = useState(selectedSlug);
  const delayedSetAutoSlug = useCallback(debounce(setAutoSlug, 500), []);
  const [categories, setCategories] = useState([]);
  const [categoryGroups, setCategoryGroups] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const accessToken = await getAccessTokenSilently();
      contentCategoriesApi.list(accessToken).then((results) => {
        const orderedCategories = _.orderBy(results, "name").map((cat) => {
          // return selected on instead of provided on so that the menu knows it's a match
          if (selectedCategory && selectedCategory.id === cat.id) {
            return selectedCategory;
          }
          return cat;
        });
        setCategories(orderedCategories);
      });
      contentCategoryGroupsApi.list(accessToken).then((results) => {
        setCategoryGroups(_.orderBy(results, "name"));
      });
    };
    fetch();
  }, []);

  useEffect(() => {
    if (!editMode) {
      const generatedSlug = toDashCaseFromSentence(selectedName || "");
      if (
        !selectedSlug ||
        selectedSlug === autoSlug ||
        selectedSlug === generatedSlug
      ) {
        // Only auto-change the slug if it's empty OR set to what we auto-changed it to last time
        // ie. Don't auto-change slug if it's been edited by the user
        delayedSetAutoSlug(generatedSlug);
      }
    }
  }, [selectedName]);

  useEffect(() => {
    change("slug", autoSlug);
    if (autoSlug) {
      touch("slug");
    }
  }, [autoSlug]);

  return (
    <>
      {!editMode && (
        <Field
          name="name"
          component={WrappedTextField}
          placeholder="Enter a name"
          label={fieldLabels.labels.name}
          validate={required({ msg: `${fieldLabels.labels.name} is required` })}
          fullWidth
          required
        />
      )}

      {!editMode && (
        <Field
          name="slug"
          component={WrappedTextField}
          placeholder="Enter a slug"
          label={fieldLabels.labels.slug}
          validate={validateSlug}
          fullWidth
          required
        />
      )}
      {categories.length > 0 && (
        <Field
          component={WrappedSelect}
          name="category"
          label={fieldLabels.labels.category}
          required
          validate={required({
            msg: `${fieldLabels.labels.category} is required`,
          })}
          fullWidth
          nullLabel="Select category"
        >
          {categories.map((c) => {
            const groups = categoryGroups.filter(
              (g) => g.categories.map((gc) => gc.id).indexOf(c.id) !== -1
            );
            return (
              <MenuItem key={c.id} value={c}>
                {c.name}{" "}
                {groups.map((g) => (
                  <Chip
                    key={g.id}
                    className={classes.categoryGroups}
                    label={g.name}
                    size="small"
                    variant="outlined"
                  />
                ))}
              </MenuItem>
            );
          })}
        </Field>
      )}
    </>
  );
};

MediaDetailFields.propTypes = {
  fieldLabels: PropTypes.object.isRequired,
  editMode: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  formName: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  touch: PropTypes.func.isRequired,

  // redux
  selectedName: PropTypes.string,
  selectedSlug: PropTypes.string,
  selectedCategory: PropTypes.object,
};

MediaDetailFields.defaultProps = {
  selectedName: null,
  selectedSlug: null,
  selectedCategory: null,
};

const mapStateToProps = (state, ownProps) => {
  const reduxFormSelector = formValueSelector(ownProps.formName);
  return {
    selectedName: reduxFormSelector(state, "name"),
    selectedSlug: reduxFormSelector(state, "slug"),
    selectedCategory: reduxFormSelector(state, "category"),
  };
};

export default connect(mapStateToProps)(MediaDetailFields);
