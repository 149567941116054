import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchTags } from "../../actions/tags";
import { getTags } from "../../reducers";
import usersApi from "../../services/api/users";
import { roles } from "@certane/arcadia-web-components";
import Avatar from "../common/Avatar.tsx";
import ChoiceChips from "../common/ChoiceChips";
import DebouncedTextField from "../common/DebouncedTextField";
import FiltersBar from "../common/FiltersBar";
import RefMultiSelect from "../common/RefMultiSelect";
import { tagsToOptions } from "../forms/menuItems";
import ListPicker from "../ListPicker";
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles((theme) => ({
  filterWidget: {
    marginTop: theme.spacing(2),
  },
  searchField: {
    fontSize: "0.8125rem",
    lineHeight: "1.1875em",
    minWidth: "400px",
  },
}));

const RiskControlFilters = ({ tags, filter, updateFilter, localFetchTags }) => {
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();
  const [ownerPickerOpen, setOwnerPickerOpen] = useState(false);
  const [selectedOwnedBy, setSelectedOwnedBy] = useState([]);

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      localFetchTags(accessToken);

      // initially load the users currently selected in the filter
      if (filter.ownerIds && filter.ownerIds.length > 0) {
        usersApi
          .search(
            {
              limit: filter.ownerIds.length,
              offset: 0,
              roles: roles.AGENT_ROLES,
              userIds: filter.ownerIds,
            },
            null,
            accessToken
          )
          .then((response) => setSelectedOwnedBy(response.results));
      }
    })();
  }, []);

  const setOwnedBy = (users) => {
    setSelectedOwnedBy(users);
    updateFilter(
      "ownerIds",
      users.map((user) => user.id)
    );
    setOwnerPickerOpen(false);
  };

  const asyncFetch = async (pickerFilter, pagination, abortController) => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = {
      ...pickerFilter,
      limit: pagination.pageSize,
      offset: pagination.offset,
      roles: roles.AGENT_ROLES,
      orderByField: "name",
    };
    return usersApi.search(searchParameters, abortController, accessToken);
  };

  const riskRegisterOptions = tagsToOptions(
    tags.filter((tag) => tag.type === "RISK_REGISTER")
  );

  return (
    <>
      <ListPicker
        data-cy="select"
        title="Select owners"
        actionText="Select"
        open={ownerPickerOpen}
        onClose={() => setOwnerPickerOpen(false)}
        onSubmit={(u) => setOwnedBy(u)}
        datasource={asyncFetch}
        selected={selectedOwnedBy}
        isMulti
        clearable
        toOption={(user) => ({
          label: user.name || "Unknown",
          id: user.id,
          email: user.email,
        })}
        fromOption={(option) => ({
          name: option.label,
          id: option.id,
          email: option.email,
        })}
        renderIcon={(user, size) => (
          <Avatar
            data-cy={user.name}
            email={user.email}
            name={user.name}
            size={size}
            round
          />
        )}
      />
      <FiltersBar>
        <>
          <InputLabel data-cy="search">Search</InputLabel>
          <div className={classes.filterWidget}>
            <DebouncedTextField
              data-cy={filter.textSearch || ""}
              value={filter.textSearch || ""}
              onChange={(text) => updateFilter("textSearch", text)}
              placeholder="Type to filter"
              margin="none"
              InputProps={{
                className: classes.searchField,
              }}
            />
          </div>
        </>
        <>
          <InputLabel data-cy="ownership">Ownership</InputLabel>
          <div className={classes.filterWidget}>
            <ChoiceChips
              value={filter.ownerIds.length > 0 ? "ownerIds" : null}
              options={[
                {
                  label: "Owned by",
                  value: "ownerIds",
                  badgeValue: filter.ownerIds.length,
                },
              ]}
              onChange={() => setOwnerPickerOpen(true)}
            />
          </div>
        </>
        <>
          <InputLabel data-cy="critical">Critical</InputLabel>
          <div className={classes.filterWidget}>
            <ChoiceChips
              data-cy={filter.critical}
              value={filter.critical}
              options={[
                {
                  label: "Critical",
                  value: true,
                },
                {
                  label: "Non-critical",
                  value: false,
                },
              ]}
              onChange={(value) => updateFilter("critical", value)}
            />
          </div>
        </>
        <>
          <InputLabel data-cy="riskRegister">Risk register</InputLabel>
          <div className={classes.filterWidget}>
            <RefMultiSelect
              data-cy={filter.riskRegisters}
              title="Select risk registers"
              value={filter.riskRegisters}
              options={riskRegisterOptions}
              onChange={(value) => updateFilter("riskRegisters", value)}
            />
          </div>
        </>
      </FiltersBar>
    </>
  );
};

RiskControlFilters.propTypes = {
  filter: PropTypes.object.isRequired,
  updateFilter: PropTypes.func.isRequired,

  // redux
  tags: PropTypes.array.isRequired,
  localFetchTags: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  tags: getTags(state),
});

export default connect(mapStateToProps, {
  localFetchTags: fetchTags,
})(RiskControlFilters);
