import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { getLabels, getReferenceDataDescription } from "../../../../reducers";
import SimpleChips from "../../../common/SimpleChips";
import InfoText from "../../../InfoText";
import LinkedWorkItemsCard from "./LinkedWorkItemsCard";

const LinkedRegulatorCorrespondenceCard = ({
  workItem,
  postUpdate,
  fieldLabels,
  getRegulatorName,
}) => {
  return (
    <LinkedWorkItemsCard
      workItemType="REGULATOR_CORRESPONDENCE"
      workItem={workItem}
      postUpdate={postUpdate}
      cardTitle="Regulator correspondence"
      promptText={
        <Box mb={2}>
          <InfoText
            text={
              <Typography gutterBottom variant="caption">
                Since this is a reportable situation, remember to link or create
                a regulator correspondence
              </Typography>
            }
          />
        </Box>
      }
      noItemsText="No regulator correspondence sent or received"
      columns={[
        {
          label: fieldLabels.labels.friendlyId,
          name: "friendlyId",
          size: 3,
          sortable: false,
          render: ([, associatedWorkItem]) => (
            <span>{associatedWorkItem.friendlyId}</span>
          ),
        },
        {
          label: fieldLabels.labels.title,
          name: "title",
          size: 3,
          sortable: false,
          render: ([, associatedWorkItem]) => (
            <span>{associatedWorkItem.title}</span>
          ),
        },
        {
          label: fieldLabels.nestedTypes.correspondence.labels.regulators,
          name: "correspondence.regulators",
          size: 3,
          sortable: false,
          render: ([, associatedWorkItem]) => (
            <SimpleChips
              values={associatedWorkItem.correspondence?.regulators?.map(
                getRegulatorName
              )}
              blankLabel="-"
            />
          ),
        },
      ]}
    />
  );
};

LinkedRegulatorCorrespondenceCard.propTypes = {
  workItem: PropTypes.object,
  postUpdate: PropTypes.func,
  fieldLabels: PropTypes.object.isRequired,
  getRegulatorName: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  fieldLabels: getLabels(state).WorkItem,
  getRegulatorName: (regulator) =>
    getReferenceDataDescription(state, "Regulator", regulator, "-"),
});

export default connect(mapStateToProps)(LinkedRegulatorCorrespondenceCard);
