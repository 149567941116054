const getFlagOptions = (document, onFlagDocument) => {
  const flagOptions = [];

  if (document.availableFlags && onFlagDocument) {
    document.availableFlags.forEach((flag) => {
      const flagged = document.flags.indexOf(flag.id) !== -1;

      flagOptions.push({
        id: flag.id,
        label: `${flag.description}`,
        flagged,
        handleSelect: () => onFlagDocument(document, [flag.id]),
      });
    });
  }

  return flagOptions;
};

export default getFlagOptions;
