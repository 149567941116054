const getOptions = (document, onFileDelete) => {
  const { fileName } = document;

  const options = [];

  if (document.additionalActions) {
    document.additionalActions.forEach((action) => {
      options.push(action);
    });
  }

  if (document.canDelete && onFileDelete) {
    options.push({
      id: `delete-${fileName}`,
      label: "Delete file",
      handleSelect: () => onFileDelete(document),
    });
  }

  return options;
};

export default getOptions;
