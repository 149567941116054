import { isFunction } from "lodash";
import logger from "../util/logger";

export const asyncAction =
  (prefix, promiseOrPromiseCreator, options = {}) =>
  (dispatch, getState) => {
    const {
      responseTransformer = (res) => res,
      onSuccess = () => undefined,
      onFailure = () => undefined,
      actionParams = {},
    } = options;

    actionParams.timestamp = Date.now();

    logger.debug(`${prefix}_INPROGRESS`);
    dispatch({
      type: `${prefix}_INPROGRESS`,
      ...actionParams,
    });

    let promise;
    if (isFunction(promiseOrPromiseCreator)) {
      promise = promiseOrPromiseCreator(getState);
    } else {
      promise = promiseOrPromiseCreator;
    }

    return promise
      .then(
        (res) => {
          logger.debug(`${prefix}_SUCCESS`);
          dispatch({
            type: `${prefix}_SUCCESS`,
            response: responseTransformer(res),
            ...actionParams,
          });
          onSuccess(res, dispatch, getState);
          return res;
        },
        (error) => {
          let type = `${prefix}_FAILURE`;
          let handled = false;

          switch (error.name) {
            case "AbortError":
              type = `${prefix}_CANCELLED`;
              handled = true;
              break;
            case "NetworkError":
              handled = true;
              break;
            default:
              logger.warn("Async request error:", error);
              break;
          }

          dispatch({
            type,
            errorMessage: error.message || "Something went wrong.",
            error,
            ...actionParams,
          });

          onFailure(error, dispatch, getState);

          if (!handled) {
            throw error;
          }
        }
      )
      .catch((error) => {
        logger.warn("Unhandled error", error);
        throw error;
      });
  };

export default {
  asyncAction,
};
