import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import dates from "../../../../util/dates";
import formatters from "../../../../util/formatters";
import ComponentScorecard from "../../../common/ComponentScorecard";
import DateAgo from "../../../common/DateAgo";

class MemberDetailsReadOnly extends Component {
  render() {
    const { workItem, customisations, fieldLabels } = this.props;

    return (
      <>
        <ComponentScorecard
          fieldLabels={fieldLabels}
          name="memberDetails.memberName"
          customisations={customisations}
          entity={workItem}
          data-cy="memberName"
        />
        <ComponentScorecard
          fieldLabels={fieldLabels}
          name="memberDetails.memberNumber"
          customisations={customisations}
          entity={workItem}
          data-cy="memberNumber"
        />
        <ComponentScorecard
          fieldLabels={fieldLabels}
          name="memberDetails.memberSince"
          customisations={customisations}
          entity={workItem}
          render={(value) => (
            <DateAgo value={dates.parseDate(value)} startFull expandable />
          )}
          data-cy="memberSince"
        />
        <ComponentScorecard
          fieldLabels={fieldLabels}
          name="memberDetails.fundBalance"
          customisations={customisations}
          entity={workItem}
          render={(value) => formatters.currency(value)}
          data-cy="fundBalance"
        />
      </>
    );
  }
}

MemberDetailsReadOnly.propTypes = {
  workItem: PropTypes.object.isRequired,
  customisations: PropTypes.array,
  fieldLabels: PropTypes.object.isRequired,
};

MemberDetailsReadOnly.defaultProps = {
  customisations: [],
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(MemberDetailsReadOnly);
