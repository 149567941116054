import InputAdornment from "@material-ui/core/InputAdornment";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { required, numericality } from "redux-form-validators";
import ComponentField from "../../../forms/wrapper/ComponentField";
import WrappedDatePicker from "../../../forms/wrapper/WrappedDatePicker";
import WrappedSelectChips from "../../../forms/wrapper/WrappedSelectChips";
import WrappedTextField from "../../../forms/wrapper/WrappedTextField";
import WrappedRadioGroup from "../../../forms/wrapper/WrappedRadioGroup";
import { yesNoOptions } from "../../../../util/radioOptions";

const HardshipClaimDetailsFields = ({
  fieldLabels,
  hasPreviouslyBeenPaid,
  customisations,
  change,
  untouch,
  formName,
}) => {
  useEffect(() => {
    if (!hasPreviouslyBeenPaid) {
      change("hardship.dateOfLastClaim", null);
      change("hardship.previousClaimAmount", null);
    }
  }, [hasPreviouslyBeenPaid]);

  const reasonSuggestions =
    customisations
      .filter((c) => c.field === "hardship.reason")
      .map((c) => c.suggestions)
      .find((s) => s) || [];

  return (
    <>
      <ComponentField
        component={WrappedTextField}
        name="hardship.fundsRequested"
        label={fieldLabels.nestedTypes.hardship.labels.fundsRequested}
        validate={numericality({
          ">=": 0,
          allowBlank: true,
        })}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        fullWidth
        helperText="Amount being claimed"
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      />
      {reasonSuggestions.length === 0 ? (
        <ComponentField
          name="hardship.reason"
          component={WrappedTextField}
          label={fieldLabels.nestedTypes.hardship.labels.reason}
          fullWidth
          multiline
          rowsMax={10}
          rows={2}
          helperText="Describe the nature of the claim"
          customisations={customisations}
          change={change}
          untouch={untouch}
          formName={formName}
        />
      ) : (
        <ComponentField
          name="hardship.reason"
          component={WrappedSelectChips}
          options={reasonSuggestions}
          label={fieldLabels.nestedTypes.hardship.labels.reason}
          fullWidth
          toOption={(suggestion) => ({ label: suggestion, value: suggestion })}
          fromOption={(option) => option.value}
          isMulti={false}
          freeTextConverter={(customOption) => customOption}
          multiline
          rowsMax={10}
          rows={2}
          helperText="Select from the options provided or describe the claim reason in your own words"
          customisations={customisations}
          change={change}
          untouch={untouch}
          formName={formName}
        />
      )}
      <ComponentField
        name="hardship.hasPreviouslyBeenPaid"
        component={WrappedRadioGroup}
        row
        label={fieldLabels.nestedTypes.hardship.labels.hasPreviouslyBeenPaid}
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
        options={yesNoOptions}
        validate={required({
          msg: `${fieldLabels.nestedTypes.hardship.labels.hasPreviouslyBeenPaid} is required`,
        })}
        required
      />

      {hasPreviouslyBeenPaid && (
        <ComponentField
          name="hardship.dateOfLastClaim"
          component={WrappedDatePicker}
          label={fieldLabels.nestedTypes.hardship.labels.dateOfLastClaim}
          fullWidth
          disableFuture
          customisations={customisations}
          change={change}
          untouch={untouch}
          formName={formName}
        />
      )}

      {hasPreviouslyBeenPaid && (
        <ComponentField
          component={WrappedTextField}
          name="hardship.previousClaimAmount"
          label={fieldLabels.nestedTypes.hardship.labels.previousClaimAmount}
          validate={numericality({
            ">=": 0,
            allowBlank: true,
          })}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          fullWidth
          helperText="Please enter the amount of the most recent previous claim"
          customisations={customisations}
          change={change}
          untouch={untouch}
          formName={formName}
        />
      )}

      <ComponentField
        component={WrappedTextField}
        name="hardship.recommendedAmount"
        label={fieldLabels.nestedTypes.hardship.labels.recommendedAmount}
        validate={numericality({
          ">=": 0,
          allowBlank: true,
        })}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        fullWidth
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      />
    </>
  );
};

HardshipClaimDetailsFields.propTypes = {
  fieldLabels: PropTypes.object.isRequired,
  hasPreviouslyBeenPaid: PropTypes.bool,
  customisations: PropTypes.array,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
};

HardshipClaimDetailsFields.defaultProps = {
  hasPreviouslyBeenPaid: false,
  customisations: [],
};

const mapStateToProps = (state, ownProps) => {
  const reduxFormSelector = formValueSelector(ownProps.formName);
  return {
    hasPreviouslyBeenPaid: reduxFormSelector(
      state,
      "hardship.hasPreviouslyBeenPaid"
    ),
  };
};

export default connect(mapStateToProps)(HardshipClaimDetailsFields);
