import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import {
  getActiveRisk,
  getReferenceDataDescription,
  getReferenceDataType,
} from "../../reducers";
import Chart from "../chart/Chart";
import GridListing from "../common/GridListing";

const RiskRatingHistoryCard = ({
  risk,
  riskRatings,
  getRiskLikelihoodDescription,
  getRiskConsequenceDescription,
  getRiskRatingDescription,
}) => {
  const formatRating = (rating) =>
    Object.values(riskRatings.values)[rating].description;

  const getRiskRatingChartConfig = () => {
    const chartData = risk.riskRatingHistory
      .filter(
        (historyItem) =>
          !_.isNil(historyItem.riskRating) && !_.isNil(historyItem.dateTime)
      )
      .map((historyItem) => ({
        x: moment(historyItem.dateTime).format("DD/MM/YYYY"),
        y: riskRatings.values[historyItem.riskRating].ordinal,
      }));

    const options = {
      chart: {
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: chartData.length === 1,
        formatter: (rating) => formatRating(Math.round(rating)),
      },
      stroke: {
        curve: "straight",
      },
      xaxis: {
        categories: chartData.map((dataEntry) => dataEntry.x),
      },
      yaxis: {
        forceNiceScale: true,
        min: 0,
        max: 3,
        labels: {
          formatter: (rating) => formatRating(Math.round(rating)),
        },
      },
    };

    const series = [
      {
        name: "Risk rating",
        data: chartData.map((dataEntry) => dataEntry.y),
      },
    ];

    return {
      options,
      series,
      type: "area",
      height: 400,
    };
  };

  const history = risk.riskRatingHistory;

  return (
    <>
      <Chart
        title="Risk rating history"
        chartConfig={getRiskRatingChartConfig()}
        data-cy="riskRatingHistory"
      />

      <GridListing
        data-cy="riskHistoryTable"
        sortedData={history}
        loading={history.length === 0}
        sortBy={{}}
        updateSort={() => {
          // do nothing.
        }}
        dense={false}
        columns={[
          {
            label: "Date",
            name: "dateTime",
            size: 2,
            sortable: false,
            render: (historyItem) =>
              historyItem.dateTime
                ? moment(historyItem.dateTime).format("DD/MM/YYYY")
                : "-",
          },
          {
            label: "Likelihood / Consequence",
            name: "likelihood-consequence",
            size: 2,
            sortable: false,
            render: (historyItem) => (
              <>
                {historyItem.likelihood
                  ? getRiskLikelihoodDescription(historyItem.likelihood)
                  : "-"}
                <span> / </span>
                {historyItem.consequenceRating
                  ? getRiskConsequenceDescription(historyItem.consequenceRating)
                  : "-"}
              </>
            ),
          },
          {
            label: "Risk Rating",
            name: "riskRating",
            size: 2,
            sortable: false,
            render: (historyItem) =>
              historyItem.riskRating
                ? getRiskRatingDescription(historyItem.riskRating)
                : "-",
          },
          {
            label: "Comment",
            name: "comment",
            size: 4,
            sortable: false,
            render: (historyItem) => historyItem.comment,
          },
          {
            label: "Updated by",
            name: "user",
            size: 2,
            sortable: false,
            render: (historyItem) =>
              historyItem.user ? historyItem.user.name : "-",
          },
        ]}
      />
    </>
  );
};

RiskRatingHistoryCard.propTypes = {
  risk: PropTypes.object.isRequired,
  riskRatings: PropTypes.object.isRequired,
  getRiskLikelihoodDescription: PropTypes.func.isRequired,
  getRiskConsequenceDescription: PropTypes.func.isRequired,
  getRiskRatingDescription: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  risk: getActiveRisk(state),
  riskRatings: getReferenceDataType(state, "RiskRating"),
  getRiskLikelihoodDescription: (id) =>
    getReferenceDataDescription(state, "RiskLikelihood", id, "-"),
  getRiskConsequenceDescription: (id) =>
    getReferenceDataDescription(state, "RiskConsequenceRating", id, "-"),
  getRiskRatingDescription: (id) =>
    getReferenceDataDescription(state, "RiskRating", id, "-"),
});

export default connect(mapStateToProps)(RiskRatingHistoryCard);
