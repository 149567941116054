import Button from "@material-ui/core/Button";
import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { Base64 } from "js-base64";
import Container from "../../../../components/common/Container";
import HeaderBar from "../../../../components/common/HeaderBar";
import PageHeading from "../../../../components/common/PageHeading";
import StandaloneLayout from "../../../../components/layout/StandaloneLayout";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginBottom: "4rem",
  },
  container: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  details: {
    marginTop: theme.spacing(6),
  },
});

const ErrorMessage = ({
  classes,
  match: {
    params: { errorId, status, errorMessage },
  },
}) => (
  <StandaloneLayout>
    <div className={classes.root}>
      <HeaderBar>
        <PageHeading heading="Something went wrong" />
      </HeaderBar>
      <Container className={classes.container}>
        <div className={classes.button}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              window.location.href = "/";
            }}
          >
            Try again
          </Button>
        </div>
        <div>
          <Typography variant="subtitle1" gutterBottom>
            The error has been logged and will be investigated. Please quote the
            error reference <strong>{errorId}</strong>.
          </Typography>
        </div>
        <div className={classes.details}>
          <Typography variant="subtitle1" gutterBottom>
            Details:
          </Typography>
          <Typography variant="body2" gutterBottom>
            Status: {status}
          </Typography>
          {errorMessage && (
            <Typography variant="body2">
              {Base64.decode(errorMessage)}
            </Typography>
          )}
        </div>
      </Container>
    </div>
  </StandaloneLayout>
);

ErrorMessage.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withStyles(styles)(ErrorMessage);
