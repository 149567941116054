import PropTypes from "prop-types";
import React from "react";
import { Field } from "redux-form";
import WrappedTextField from "../wrapper/WrappedTextField";

const ControlObjectiveField = ({ fieldLabels }) => {
  return (
    <Field
      name="objective"
      component={WrappedTextField}
      label={fieldLabels.labels.objective}
      multiline
      rowsMax={8}
      rows={4}
      fullWidth
    />
  );
};

ControlObjectiveField.propTypes = {
  fieldLabels: PropTypes.object.isRequired,
};

export default ControlObjectiveField;
