import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { getLabels, getReferenceDataType } from "../../../../reducers";
import LinkedWorkItemsCard from "../linkedregulatorcorrespondence/LinkedWorkItemsCard";
import WorkItemStatus from "../overview/WorkItemStatus";

const LinkedIncidentsCard = ({
  workItem,
  postUpdate,
  readonly,
  fieldLabels,
  workItemTypes,
}) => {
  const definition = workItemTypes.values.INCIDENT.props.definition;
  return (
    <LinkedWorkItemsCard
      workItemType="INCIDENT"
      workItem={workItem}
      postUpdate={postUpdate}
      readonly={readonly}
      noItemsText="No incidents recorded"
      columns={[
        {
          label: fieldLabels.labels.friendlyId,
          name: "friendlyId",
          size: 2,
          sortable: false,
          render: ([, associatedWorkItem]) => (
            <span>{associatedWorkItem.friendlyId}</span>
          ),
        },
        {
          label: fieldLabels.labels.title,
          name: "title",
          size: 4,
          sortable: false,
          render: ([, associatedWorkItem]) => (
            <span>{associatedWorkItem.title}</span>
          ),
        },
        {
          label: fieldLabels.labels.status,
          name: "status",
          size: 2,
          sortable: false,
          render: ([, associatedWorkItem]) => (
            <WorkItemStatus
              workItem={associatedWorkItem}
              readonly
              definition={definition}
            />
          ),
        },
      ]}
    />
  );
};

LinkedIncidentsCard.propTypes = {
  workItem: PropTypes.object,
  postUpdate: PropTypes.func,
  readonly: PropTypes.bool,

  // redux
  fieldLabels: PropTypes.object.isRequired,
  workItemTypes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  fieldLabels: getLabels(state).WorkItem,
  workItemTypes: getReferenceDataType(state, "WorkItemType"),
});

export default connect(mapStateToProps)(LinkedIncidentsCard);
