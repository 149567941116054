import { combineReducers } from "redux";
import {
  SLA_CREATE,
  SLA_GET_BY_ID,
  SLA_LIST,
  SLA_PATCH,
  SLA_REMOVE,
} from "../actions/slas";
import { publishActionToast } from "../services/toasts";

const successToasts = {
  [SLA_CREATE]: "Created SLA successfully",
  [SLA_PATCH]: "Updated SLA successfully",
};

const byId = (state = {}, action) => {
  publishActionToast(action, "SLA_", successToasts);

  switch (action.type) {
    case `${SLA_LIST}_SUCCESS`:
    case `${SLA_GET_BY_ID}_SUCCESS`:
    case `${SLA_CREATE}_SUCCESS`:
    case `${SLA_PATCH}_SUCCESS`:
      return {
        ...state,
        ...action.response.entities.sla,
      };

    // Remove a sla
    case `${SLA_REMOVE}_SUCCESS`:
      const idToRemove = action.response.id;
      return {
        ...state,
        [idToRemove]: undefined,
      };

    default:
      break;
  }

  return state;
};

const defaultActive = {
  activeList: [],
  activeSla: null,
  error: null,
};

const active = (state = defaultActive, action) => {
  switch (action.type) {
    case `${SLA_LIST}_SUCCESS`:
      return {
        ...state,
        activeList: [...action.response.result],
      };

    case `${SLA_GET_BY_ID}_SUCCESS`:
    case `${SLA_CREATE}_SUCCESS`:
      return {
        ...state,
        activeList:
          state.activeList.indexOf(action.response.result) === -1
            ? [...state.activeList, action.response.result]
            : state.activeList,
        activeSla: action.response.result,
      };

    case `${SLA_REMOVE}_SUCCESS`:
      const idToRemove = action.response.id;
      return {
        ...state,
        activeList: [...state.activeList.filter((id) => id !== idToRemove)],
        activeSla: state.activeSla === idToRemove ? null : state.activeSla,
      };

    default:
      break;
  }

  return state;
};

export default combineReducers({
  active,
  byId,
});

export const getActiveSla = (state) =>
  state.active.activeSla ? state.byId[state.active.activeSla] : null;
export const getSlas = (state) =>
  state.active.activeList.map((id) => state.byId[id]);
