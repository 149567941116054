import { normalize } from "normalizr";
import schemas from "../schemas";
import productsApi from "../services/api/products";
import { asyncAction } from "./actions";

export const PRODUCT_CLEAR_ACTIVE = "PRODUCT_CLEAR_ACTIVE";
export const PRODUCT_CREATE = "PRODUCT_CREATE";
export const PRODUCT_SEARCH = "PRODUCT_SEARCH";
export const PRODUCT_GET_BY_ID = "PRODUCT_GET_BY_ID";
export const PRODUCT_PATCH = "PRODUCT_PATCH";

export const createProduct = (product, accessToken) =>
  asyncAction(PRODUCT_CREATE, productsApi.create(product, accessToken), {
    responseTransformer: (created) => normalize(created, schemas.product),
  });

export const searchProducts = (searchParameters, accessToken) => {
  const abortController = window.AbortController ? new AbortController() : null;
  return asyncAction(
    PRODUCT_SEARCH,
    productsApi.search(searchParameters, abortController, accessToken),
    {
      responseTransformer: (response) => ({
        pagination: {
          offset: response.offset,
          previousOffset: response.previousOffset,
          nextOffset: response.nextOffset,
          pageSize: response.pageSize,
          resultCount: response.resultCount,
        },
        ...normalize(response.results, schemas.arrayOfProducts),
      }),
      actionParams: { abortController, nonBlocking: true },
    }
  );
};

export const getProductById = (orgId, accessToken) =>
  asyncAction(PRODUCT_GET_BY_ID, productsApi.getById(orgId, accessToken), {
    responseTransformer: (product) => normalize(product, schemas.product),
  });

export const patchProduct = (orgId, changes, description, accessToken) =>
  asyncAction(
    PRODUCT_PATCH,
    productsApi.patch(orgId, changes, description, accessToken),
    {
      responseTransformer: (product) => normalize(product, schemas.product),
    }
  );
