import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import classNames from "classnames";

const styles = (theme) => ({
  button: {
    ...theme.typography.body2,
    fontWeight: 500,
    padding: 0,
    minWidth: 0,
    maxWidth: "100%",
    textAlign: "left",
    justifyContent: "space-between",
    "& span": {
      textTransform: "none !important",
      maxWidth: "100%",
    },
  },
  disabled: {
    color: "inherit",
  },
});

class LabelStateButton extends Component {
  render() {
    const { classes, className, readonly, label, ...rest } = this.props;

    return (
      <Button
        classes={{
          root: classNames({
            [classes.button]: true,
            [className]: !!className,
          }),
          disabled: classes.disabled,
        }}
        color="primary"
        onClick={this.props.onClick}
        disabled={readonly}
        fullWidth
        {...rest}
        data-cy={label}
      >
        {label}
        {!readonly && <ArrowDropDown style={{ float: "right" }} />}
      </Button>
    );
  }
}

LabelStateButton.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  label: PropTypes.any.isRequired,
  onClick: PropTypes.func,
  readonly: PropTypes.bool,
};

LabelStateButton.defaultProps = {
  className: undefined,
  readonly: false,
  onClick: null,
};

export default withStyles(styles)(LabelStateButton);
