import PropTypes from "prop-types";
import React from "react";
import { FormattedDate } from "react-intl";
import { connect } from "react-redux";
import { getLabels } from "../../../../reducers";
import LinkedWorkItemsCard from "../linkedregulatorcorrespondence/LinkedWorkItemsCard";

const LinkedComplaintsCard = ({ workItem, postUpdate, fieldLabels }) => {
  return (
    <LinkedWorkItemsCard
      workItemType="COMPLAINT"
      workItem={workItem}
      postUpdate={postUpdate}
      noItemsText="No complaints sent/received"
      columns={[
        {
          label: fieldLabels.labels.friendlyId,
          name: "friendlyId",
          size: 3,
          sortable: false,
          render: ([, associatedWorkItem]) => (
            <span>{associatedWorkItem.friendlyId}</span>
          ),
        },
        {
          label: fieldLabels.labels.title,
          name: "title",
          size: 3,
          sortable: false,
          render: ([, associatedWorkItem]) => (
            <span>{associatedWorkItem.title}</span>
          ),
        },
        {
          label: fieldLabels.nestedTypes.clientDetails.labels.name,
          name: "clientDetails.name",
          size: 3,
          sortable: false,
          render: ([, associatedWorkItem]) => (
            <span>{associatedWorkItem.clientDetails.name}</span>
          ),
        },
        {
          label: fieldLabels.nestedTypes.complaint.labels.dateReceived,
          name: "complaint.dateReceived",
          size: 3,
          sortable: false,
          render: ([, associatedWorkItem]) => (
            <span>
              {associatedWorkItem.complaint.dateReceived ? (
                <FormattedDate
                  value={associatedWorkItem.complaint.dateReceived}
                />
              ) : (
                "-"
              )}
            </span>
          ),
        },
      ]}
    />
  );
};

LinkedComplaintsCard.propTypes = {
  workItem: PropTypes.object,
  postUpdate: PropTypes.func,

  // redux
  fieldLabels: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  fieldLabels: getLabels(state).WorkItem,
});

export default connect(mapStateToProps)(LinkedComplaintsCard);
