import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ReactMarkdown from "react-markdown";
import PropTypes from "prop-types";
import React from "react";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, formValueSelector } from "redux-form";
import { getReferenceDataDescription } from "../../../../reducers";
import { appliesByCategory } from "../../../../util/workItemTypeUtils";
import WrappedTriStateCheckbox from "../../../forms/wrapper/WrappedTriStateCheckbox";

const styles = (theme) => ({
  notApplicable: {
    textDecoration: "line-through",
  },
  notApplicableLabel: {
    color: theme.palette.grey[500],
  },
  progressIndicator: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  helpText: {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: 12,
    fontWeight: 400,
  },
});

const normalise = (max, value) => (value * 100) / max;

const FormChecklistFields = ({
  classes,
  workItemDefinition,
  workItemCategory: category,
  documentChecklist,
  getFormStatusDescription,
  customisations,
  change,
}) => {
  const availableTypes = workItemDefinition.documentFlags
    .filter((flagAssignment) => flagAssignment.inDocumentChecklist)
    .filter((flagAssignment) =>
      appliesByCategory({ category }, flagAssignment)
    );

  const checklistValues = documentChecklist || {};
  const numSelected = Object.values(checklistValues).filter(
    (v) => v !== undefined && v !== null
  ).length;
  const checklistScore = normalise(availableTypes.length, numSelected);

  // Clear any selected values which are no longer relevant
  const availableDocumentFormTypes = availableTypes.map(
    (formAssignment) => formAssignment.documentFlag
  );
  const keysToRemove = Object.keys(checklistValues).filter(
    (selectedFormType) =>
      !availableDocumentFormTypes.find(
        (formType) => formType === selectedFormType
      )
  );
  if (keysToRemove.length > 0) {
    keysToRemove.forEach((keyToRemove) => {
      delete checklistValues[keyToRemove];
    });
    setTimeout(() => change("documentChecklist", checklistValues), 0);
  }

  const getHelpText = () => {
    if (!customisations.length || !category) {
      return "";
    }

    const customisationMatch = customisations.find(({ categories }) =>
      categories.includes(category)
    );
    return customisationMatch && customisationMatch.helpText
      ? customisationMatch.helpText
      : "";
  };

  return (
    <>
      <div className={classes.progressIndicator}>
        <LinearProgress
          variant="determinate"
          color="secondary"
          value={checklistScore}
          data-cy="checklistScore"
        />
        <Typography variant="caption" data-cy="complete">
          {numSelected}/{availableTypes.length} complete
        </Typography>
      </div>
      {availableTypes.map((flagAssignment) => {
        const currentValue = checklistValues[flagAssignment.documentFlag];
        const label =
          currentValue === "NOT_APPLICABLE" ? (
            <Typography variant="body2">
              <span
                className={classes.notApplicable}
                data-cy={flagAssignment.documentFlagLabel}
              >
                {flagAssignment.documentFlagLabel}
              </span>
              &nbsp;
              <span className={classes.notApplicableLabel}>
                (Not Applicable)
              </span>
            </Typography>
          ) : (
            <Typography
              variant="body2"
              data-cy={flagAssignment.documentFlagLabel}
            >
              {flagAssignment.documentFlagLabel}
            </Typography>
          );
        return (
          <div key={flagAssignment.documentFlag}>
            <Field
              title={getFormStatusDescription(currentValue)}
              name={`documentChecklist.${flagAssignment.documentFlag}`}
              component={WrappedTriStateCheckbox}
              label={label}
              icon={<NotInterestedIcon />}
              indeterminateIcon={<CheckBoxOutlineBlankIcon />}
              checkedValue="INCLUDED"
              uncheckedValue="NOT_APPLICABLE"
              data-cy={label}
            />
          </div>
        );
      })}
      <Typography
        variant="subtitle2"
        className={classes.helpText}
        data-cy="subTitle2"
      >
        <ReactMarkdown source={getHelpText()} />
      </Typography>
    </>
  );
};

FormChecklistFields.propTypes = {
  classes: PropTypes.object.isRequired,
  workItemDefinition: PropTypes.object.isRequired,
  customisations: PropTypes.array,
  workItemCategory: PropTypes.string,
  documentChecklist: PropTypes.object,
  getFormStatusDescription: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
};

FormChecklistFields.defaultProps = {
  workItemCategory: null,
  documentChecklist: {},
  customisations: [],
};

const mapStateToProps = (state, ownProps) => {
  const reduxFormSelector = formValueSelector(ownProps.formName);
  return {
    getFormStatusDescription: (id) =>
      getReferenceDataDescription(state, "FormStatus", id, "Please select"),
    documentChecklist: reduxFormSelector(state, "documentChecklist"),
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(FormChecklistFields);
