import { useAuth0 } from "@auth0/auth0-react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Icon from "@material-ui/core/Icon";
import List from "@material-ui/core/List";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { SubmissionError } from "redux-form";
import {
  fetchWorkItemScheduleOccurrences,
  removeWorkItemOccurrence,
} from "../../../actions/workItems";
import { fetchWorkItemSchedule } from "../../../actions/workItemSchedules";
import {
  getLoggedInUser,
  getReferenceDataType,
  getWorkItemSchedule,
  getWorkItemScheduleOccurrences,
} from "../../../reducers";
import { roles } from "@certane/arcadia-web-components";
import dates from "../../../util/dates";
import AlertDialog from "../../common/AlertDialog";
import WorkItemListItem from "../WorkItemListItem";

const styles = (theme) => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  stopAfter: {
    fontSize: "1.125rem",
  },
  showMore: {
    marginBottom: "8px",
    marginLeft: "8px",
  },
});

const ScheduleOccurrencesCard = ({
  classes,
  loggedInUser,
  workItem,
  workItemSchedule,
  workItemScheduleOccurrences,
  types,
  limit,
  localFetchWorkItemScheduleOccurrences,
  localRemoveWorkItemOccurrence,
  localFetchWorkItemSchedule,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [occurrenceIdToRemove, setOccurrenceIdToRemove] = useState(null);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (workItemSchedule) {
      getAccessTokenSilently().then((accessToken) =>
        localFetchWorkItemScheduleOccurrences(workItemSchedule.id, accessToken)
      );
      setExpanded(false);
    }
  }, [workItemSchedule]);

  const handleSubmitRemoveDialog = async () => {
    const accessToken = await getAccessTokenSilently();
    localRemoveWorkItemOccurrence(occurrenceIdToRemove, accessToken)
      .then(() => {
        setOccurrenceIdToRemove(null);
        setExpanded(false);
        localFetchWorkItemSchedule(workItemSchedule.id, accessToken);
      })
      .catch((error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  if (!workItem) return null;

  const allowDeletion =
    loggedInUser && loggedInUser.roles.indexOf(roles.COMPLIANCE_OWNER) !== -1;

  const occurrences = workItemScheduleOccurrences
    ? _.orderBy(workItemScheduleOccurrences, (o) => dates.parseDate(o.dueDate))
    : null;

  const limited = occurrences && occurrences.length > limit;
  const limitedOccurrences =
    occurrences && limited && !expanded
      ? occurrences.slice(0, limit)
      : occurrences;

  return (
    <>
      <AlertDialog
        title="Remove Occurrence"
        body="Are you sure you want to remove this task occurrence?"
        submitButtonText="Remove"
        open={!!occurrenceIdToRemove}
        onCancel={() => setOccurrenceIdToRemove(null)}
        onSubmit={(values) => handleSubmitRemoveDialog(values)}
      />

      <Card className={classes.card} elevation={0}>
        <CardHeader title="Compliance tasks created" />
        <List>
          {limitedOccurrences &&
            limitedOccurrences.map((occurrence) => (
              <WorkItemListItem
                key={occurrence.id}
                workItem={occurrence}
                types={types}
              >
                <MenuItem
                  onClick={() => setOccurrenceIdToRemove(occurrence.id)}
                  disabled={!allowDeletion}
                >
                  <Icon>delete</Icon> Remove
                </MenuItem>
              </WorkItemListItem>
            ))}
        </List>

        {limited && (
          <Button
            className={classes.showMore}
            color="primary"
            size="small"
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? "Show less..." : "Show more..."}
          </Button>
        )}
      </Card>
    </>
  );
};

ScheduleOccurrencesCard.propTypes = {
  loggedInUser: PropTypes.object,
  classes: PropTypes.object.isRequired,
  workItem: PropTypes.object,
  workItemSchedule: PropTypes.object,
  workItemScheduleOccurrences: PropTypes.array,
  localRemoveWorkItemOccurrence: PropTypes.func.isRequired,
  localFetchWorkItemSchedule: PropTypes.func.isRequired,
  localFetchWorkItemScheduleOccurrences: PropTypes.func.isRequired,
  types: PropTypes.object.isRequired,
  limit: PropTypes.number,
};

ScheduleOccurrencesCard.defaultProps = {
  loggedInUser: null,
  workItem: undefined,
  workItemSchedule: undefined,
  workItemScheduleOccurrences: [],
  limit: 10,
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
  types: getReferenceDataType(state, "WorkItemType"),
  workItemSchedule: getWorkItemSchedule(state),
  workItemScheduleOccurrences: getWorkItemScheduleOccurrences(state),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    localFetchWorkItemScheduleOccurrences: fetchWorkItemScheduleOccurrences,
    localFetchWorkItemSchedule: fetchWorkItemSchedule,
    localRemoveWorkItemOccurrence: removeWorkItemOccurrence,
  })
)(ScheduleOccurrencesCard);
