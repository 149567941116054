import { normalize } from "normalizr";
import notificationsApi from "../services/api/notifications";
import { asyncAction } from "./actions";
import schemas from "../schemas";

// action types
export const NOTIFICATIONS_FETCH = "NOTIFICATIONS_FETCH";
export const NOTIFICATIONS_MARK_AS_READ = "NOTIFICATIONS_MARK_AS_READ";
export const NOTIFICATIONS_DISMISS = "NOTIFICATIONS_DISMISS";
export const NOTIFICATIONS_DISMISS_ALL = "NOTIFICATIONS_DISMISS_ALL";

export const fetchNotifications = (accessToken) =>
  asyncAction(NOTIFICATIONS_FETCH, notificationsApi.list(accessToken), {
    responseTransformer: (notifications) =>
      normalize(notifications, schemas.arrayOfNotifications),
    actionParams: { nonBlocking: true, loadingIgnore: true },
  });

export const markAsRead = (id, accessToken) =>
  asyncAction(
    NOTIFICATIONS_MARK_AS_READ,
    notificationsApi.markAsRead(id, accessToken),
    {
      responseTransformer: (notification) =>
        normalize(notification, schemas.notification),
    }
  );

export const dismiss = (id, accessToken) =>
  asyncAction(
    NOTIFICATIONS_DISMISS,
    notificationsApi.dismiss(id, accessToken),
    {
      responseTransformer: () => ({ id }),
    }
  );

export const dismissAll = (accessToken) =>
  asyncAction(
    NOTIFICATIONS_DISMISS_ALL,
    notificationsApi.dismissAll(accessToken)
  );
