import * as PropTypes from "prop-types";
import React from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import dates from "../../../util/dates";

const WrappedDatePicker = ({
  input,
  storeDatesAsString,
  helperText,
  margin,
  meta: { touched, error, invalid, active },
  ...custom
}) => {
  const rest = { ...custom };

  if (touched && error) {
    rest.error = true;
    rest.helperText = error;
  } else if ((!touched || !invalid) && helperText) {
    rest.helperText = helperText;
  }

  return (
    <KeyboardDatePicker
      name={`position-${input.name}`}
      value={
        storeDatesAsString ? dates.parseDate(input.value) || null : input.value
      }
      format="DD/MM/YYYY"
      placeholder={active ? "dd/mm/yyyy" : null}
      autoOk
      clearable
      onChange={(date) =>
        input.onChange(storeDatesAsString ? dates.serializeDate(date) : date)
      }
      onFocus={input.onFocus}
      margin={margin}
      {...rest}
    />
  );
};

WrappedDatePicker.propTypes = {
  input: PropTypes.object.isRequired,
  helperText: PropTypes.any,
  storeDatesAsString: PropTypes.bool,
  meta: PropTypes.object.isRequired,
  margin: PropTypes.string,
};

WrappedDatePicker.defaultProps = {
  helperText: "",
  storeDatesAsString: true,
  margin: "dense",
};

export default WrappedDatePicker;
