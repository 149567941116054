import { useAuth0 } from "@auth0/auth0-react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import { required } from "redux-form-validators";
import { fetchTags } from "../../../actions/tags";
import {
  getLabels,
  getReferenceDataType,
  getTags,
  isLoading,
} from "../../../reducers";
import organisationsApi from "../../../services/api/organisations";
import usersApi from "../../../services/api/users";
import { roles } from "@certane/arcadia-web-components";
import { scrollToFirstInvalidInput } from "../../../util/formHelpers";
import { organisationIcon } from "../../../util/icons";
import Avatar from "../../common/Avatar.tsx";
import DebugPrefill from "../../common/DebugPrefill";
import FormError from "../../common/FormError";
import { getRefDataMenuItems } from "../menuItems";
import WrappedChipListPicker from "../wrapper/WrappedChipListPicker";
import WrappedDatePicker from "../wrapper/WrappedDatePicker";
import WrappedFilePicker from "../wrapper/WrappedFilePicker";
import WrappedListPicker from "../wrapper/WrappedListPicker";
import WrappedSelect from "../wrapper/WrappedSelect";
import WrappedTags from "../wrapper/WrappedTags";
import WrappedTextField from "../wrapper/WrappedTextField";

const styles = () => ({});

const CreateAuditReportForm = ({
  onCreate,
  error,
  change,
  localFetchTags,
  priorities,
  auditTypes,
  labels,
  tags,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [uploadNamespace] = useState("audit");

  useEffect(() => {
    getAccessTokenSilently().then((accessToken) => localFetchTags(accessToken));
  }, []);

  const debugPrefill = () => {
    change("title", "Just check everthing");
    change("period.from", "2019-07-01");
    change("period.to", "2020-06-30");
    change("priority", "HIGH");
    change("auditor", { text: "KPMG", type: "AUDITOR" });
    change("type", "INTERNAL");
    change("description", "Like last time");
  };

  const asyncOwnerOptionsFetch = async (
    pickerFilter,
    pagination,
    abortController
  ) => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = {
      ...pickerFilter,
      limit: pagination.pageSize,
      offset: pagination.offset,
      roles: [roles.COMPLIANCE_OWNER, roles.COMPLIANCE_OFFICER],
      orderByField: "name",
    };
    return usersApi.search(searchParameters, abortController, accessToken);
  };

  const asyncSargonOrganisationOptionsFetch = async (
    pickerFilter,
    pagination,
    abortController
  ) => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = {
      ...pickerFilter,
      limit: pagination.pageSize,
      offset: pagination.offset,
      types: ["INTERNAL"],
      orderByField: "name",
    };
    return organisationsApi.search(
      searchParameters,
      abortController,
      accessToken
    );
  };

  const fieldLabels = labels.AuditReport;

  return (
    <form autoComplete="off">
      <DebugPrefill onClick={debugPrefill} />
      {error && <FormError errorMessage={error} />}
      <Field
        component={WrappedTextField}
        name="title"
        label={fieldLabels.labels.title}
        validate={required({ msg: `${fieldLabels.labels.title} is required` })}
        placeholder="Enter a short title"
        fullWidth
        required
      />

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Field
            name="period.from"
            component={WrappedDatePicker}
            label={fieldLabels.nestedTypes.period.labels.from}
            fullWidth
            validate={required({
              msg: `${fieldLabels.nestedTypes.period.labels.from} is required`,
            })}
            required
            helperText="Period covered by the audit."
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            name="period.to"
            component={WrappedDatePicker}
            label={fieldLabels.nestedTypes.period.labels.to}
            fullWidth
            validate={required({
              msg: `${fieldLabels.nestedTypes.period.labels.to} is required`,
            })}
            required
          />
        </Grid>
      </Grid>

      <Field
        name="description"
        component={WrappedTextField}
        label={fieldLabels.labels.description}
        multiline
        validate={required({ msg: "Description is required" })}
        rowsMax={4}
        rows={2}
        fullWidth
        required
      />

      <Field
        name="auditor"
        component={WrappedTags}
        label={fieldLabels.labels.auditor}
        validate={required(`${fieldLabels.labels.auditor} is required`)}
        tags={tags}
        tagType="AUDITOR"
        placeholder="Enter auditor..."
        fullWidth
        required
        isMulti={false}
      />

      <Field
        component={WrappedSelect}
        name="type"
        label={fieldLabels.labels.type}
        validate={required({ msg: `${fieldLabels.labels.type} is required` })}
        required
        fullWidth
      >
        {getRefDataMenuItems(auditTypes)}
      </Field>

      <Field
        component={WrappedSelect}
        name="priority"
        label={fieldLabels.labels.priority}
        validate={required({
          msg: `${fieldLabels.labels.priority} is required`,
        })}
        required
        fullWidth
      >
        {getRefDataMenuItems(priorities)}
      </Field>

      <Field
        name="owner"
        component={WrappedListPicker}
        datasource={asyncOwnerOptionsFetch}
        placeholder="Please select an owner..."
        isMulti={false}
        submitOnChange
        required
        validate={required({ msg: `${fieldLabels.labels.owner} is required` })}
        label={fieldLabels.labels.owner}
        fullWidth
        toOption={(user) => ({
          label: user.name || "Unknown",
          id: user.id,
          email: user.email,
        })}
        fromOption={(option) => ({
          name: option.label,
          id: option.id,
          email: option.email,
        })}
        renderIcon={(user, size) => (
          <Avatar email={user.email} name={user.name} size={size} round />
        )}
      />

      <Field
        name="sargonServiceProviders"
        component={WrappedChipListPicker}
        datasource={asyncSargonOrganisationOptionsFetch}
        placeholder="Please select responsible internal entities(s)..."
        isMulti
        required
        validate={required({
          msg: `${fieldLabels.labels.sargonServiceProviders} is required`,
        })}
        label={fieldLabels.labels.sargonServiceProviders}
        fullWidth
        toOption={(org) => ({ label: org.name, id: org.id, org })}
        fromOption={(option) => option.org}
        addIcon={organisationIcon()}
        renderIcon={(org, size) => (
          <Avatar name={(org.name || "Unknown").charAt(0)} size={size} round />
        )}
      />

      <Field
        name="barccAction"
        component={WrappedTextField}
        label={fieldLabels.labels.barccAction}
        multiline
        rowsMax={4}
        rows={2}
        fullWidth
      />

      {onCreate && (
        <Field
          name="documents"
          component={WrappedFilePicker}
          label={fieldLabels.labels.documents}
          fullWidth
          uploadNamespace={uploadNamespace}
        />
      )}
    </form>
  );
};

CreateAuditReportForm.propTypes = {
  onCreate: PropTypes.bool.isRequired,
  error: PropTypes.string,
  change: PropTypes.func.isRequired,
  priorities: PropTypes.object.isRequired,
  auditTypes: PropTypes.object.isRequired,
  tags: PropTypes.array.isRequired,
  labels: PropTypes.object.isRequired,
  localFetchTags: PropTypes.func.isRequired,
};

CreateAuditReportForm.defaultProps = {
  error: null,
};

export const AUDIT_REPORT_FORM_NAME = "AUDIT_REPORT_FORM_NAME";

const mapStateToProps = (state) => ({
  priorities: getReferenceDataType(state, "Priority"),
  auditTypes: getReferenceDataType(state, "AuditType"),
  loading: isLoading(state),
  tags: getTags(state),
  labels: getLabels(state),
});

const form = reduxForm({
  form: AUDIT_REPORT_FORM_NAME,
  onSubmitFail: (errors) => scrollToFirstInvalidInput(errors),
});

const reduxData = connect(mapStateToProps, {
  localFetchTags: fetchTags,
});

export default compose(
  form,
  reduxData
)(withStyles(styles)(CreateAuditReportForm));
