import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Container from "./Container";

const styles = (theme) => ({
  root: {
    background: theme.palette.common.white,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    "& h1": {
      display: "flex",
      alignItems: "center",
      "& .material-icons": {
        marginRight: theme.spacing(1),
      },
    },
  },
});

function HeaderBar(props) {
  const { children, classes, fluid, narrow } = props;
  return (
    <div className={classes.root}>
      <Container fluid={fluid} narrow={narrow}>
        {children}
      </Container>
    </div>
  );
}

HeaderBar.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  fluid: PropTypes.bool,
  narrow: PropTypes.bool,
};

HeaderBar.defaultProps = {
  fluid: false,
  narrow: false,
};

export default withStyles(styles)(HeaderBar);
