import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import pluralize from "pluralize";

const styles = (theme) => ({
  box: {
    marginTop: theme.spacing(1),
  },
  text: {
    color: theme.palette.swatch.secondary,
  },
  textSingle: {
    color: theme.palette.swatch.secondary,
    marginRight: theme.spacing(1),
  },
  chipBox: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.swatch.grey5,
  },
  chipSingle: {
    marginRight: 0,
    marginBottom: 0,
  },
  removed: {
    "& span": {
      textDecoration: "line-through",
    },
  },
});

const isNotEmpty = (arr) => arr.length > 0;
const startsWith = (pathKey) => (str) => str.startsWith(pathKey);
const containsUserChanges = (pathKey, entry) => {
  const results = entry.fieldChanges.filter(
    (item) =>
      isNotEmpty(item.path.filter(startsWith(pathKey))) &&
      item.type === "threewks.model.entitystub.EntityStub"
  );

  return isNotEmpty(results);
};

const entryHasUserChanges = (pathKey, entry) =>
  _.has(entry, "fieldChanges") && containsUserChanges(pathKey, entry);

const getUser = (data) => {
  switch (data.changeType) {
    case "ADDITION":
      return {
        ...data.after[0].properties,
      };
    case "REMOVAL":
      return {
        ...data.before[0].properties,
      };
    default:
      return {};
  }
};

const useUserGroup = (pathKey, entry) => {
  const [hasSubmitters, setHasSubmitters] = useState(false);
  const [submitters, setSubmitters] = useState([]);

  useEffect(() => {
    if (entryHasUserChanges(pathKey, entry)) {
      const data = entry.fieldChanges
        .filter(
          (field) => field.type === "threewks.model.entitystub.EntityStub"
        )
        .map((field) => ({
          removed: field.changeType === "REMOVAL",
          ...getUser(field),
        }));

      setHasSubmitters(true);
      setSubmitters(_.groupBy(data, "removed"));
    }
  }, [entry]);

  return [hasSubmitters, submitters];
};

const SingleItemChange = ({ classes, user, prefix, label }) => (
  <Typography variant="body2" className={classes.textSingle}>
    {prefix} {pluralize(label, 1)} <strong>{user.name}</strong>
  </Typography>
);

const MultiItemChange = ({ classes, group, prefix, label }) => (
  <Typography variant="body2" className={classes.text}>
    {prefix} {pluralize(label, group.length)}{" "}
    <strong>{group.map((user) => user.name).join(", ")}</strong>
  </Typography>
);

const Renderer = ({ classes, group, prefix, label }) => {
  switch (group.length) {
    case 0:
      return <></>;
    case 1:
      return (
        <SingleItemChange {...{ classes, prefix, label }} user={group[0]} />
      );
    default:
      return <MultiItemChange {...{ classes, group, prefix, label }} />;
  }
};

const UserGroup = ({ classes, entry, pathKey, label }) => {
  const [hasChanges, { true: removals = [], false: additions = [] }] =
    useUserGroup(pathKey, entry);

  return (
    <>
      {hasChanges && (
        <>
          <Renderer {...{ classes, label }} prefix="Added" group={additions} />
          <Renderer {...{ classes, label }} prefix="Removed" group={removals} />
        </>
      )}
    </>
  );
};

Renderer.propTypes = {
  classes: PropTypes.object.isRequired,
  group: PropTypes.array.isRequired,
  prefix: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

SingleItemChange.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  prefix: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

MultiItemChange.propTypes = {
  classes: PropTypes.object.isRequired,
  group: PropTypes.array.isRequired,
  prefix: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

UserGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  entry: PropTypes.object.isRequired,
  pathKey: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default withStyles(styles)(UserGroup);
