import { normalize } from "normalizr";
import schemas from "../schemas";
import questionsApi from "../services/api/questions";
import { asyncAction } from "./actions";

export const QUESTION_CLEAR_METRICS = "QUESTION_CLEAR_METRICS";
export const QUESTION_CLEAR_ACTIVE = "QUESTION_CLEAR_ACTIVE";
export const QUESTION_SEARCH = "QUESTION_SEARCH";
export const QUESTION_LOAD_METRIC = "QUESTION_LOAD_METRIC";

export function clearActiveQuestions() {
  return {
    type: QUESTION_CLEAR_ACTIVE,
  };
}

export function clearQuestionMetrics() {
  return {
    type: QUESTION_CLEAR_METRICS,
  };
}

export const loadMetric = (metric, filter, accessToken) =>
  asyncAction(
    QUESTION_LOAD_METRIC,
    questionsApi.count(filter, null, accessToken),
    {
      responseTransformer: (results) => ({ count: results.count, metric }),
      actionParams: { nonBlocking: true },
    }
  );

export const searchQuestions = (searchParameters, accessToken) => {
  const abortController = window.AbortController ? new AbortController() : null;
  return asyncAction(
    QUESTION_SEARCH,
    questionsApi.search(searchParameters, abortController, accessToken),
    {
      responseTransformer: (response) => ({
        pagination: {
          offset: response.offset,
          previousOffset: response.previousOffset,
          nextOffset: response.nextOffset,
          pageSize: response.pageSize,
          resultCount: response.resultCount,
        },
        ...normalize(response.results, schemas.arrayOfQuestions),
      }),
      actionParams: { abortController, nonBlocking: true },
    }
  );
};
