import { useAuth0 } from "@auth0/auth0-react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Switch from "@material-ui/core/Switch";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { length } from "redux-form-validators";
import { getLoggedInUser } from "../../../reducers";
import businessServiceAreasApi from "../../../services/api/businessServiceAreas";
import productsApi from "../../../services/api/products";
import { businessServiceAreaIcon, productIcon } from "../../../util/icons";
import logger from "../../../util/logger";
import Avatar from "../../common/Avatar.tsx";
import DebouncedTextField from "../../common/DebouncedTextField";
import SimpleChips from "../../common/SimpleChips";
import ComponentField from "../wrapper/ComponentField";
import ComponentFieldArray from "../wrapper/ComponentFieldArray";
import WrappedChipListPicker from "../wrapper/WrappedChipListPicker";
import WrappedNonInteractive from "../wrapper/WrappedNonInteractive";
import WrappedSwitch from "../wrapper/WrappedSwitch";
import EntityRelationshipFundFields from "./EntityRelationshipFundFields";
import EntityRelationshipOrganisationFields from "./EntityRelationshipOrganisationFields";

const isEmpty = (array) => !array || array.length === 0;

const validateHasCertaneEntities = (message) => (organisations) => {
  if (!organisations || organisations.length === 0) {
    return message;
  }

  const hasCertaneOrganisation =
    organisations.filter(
      (linkedOrg) => linkedOrg.organisation.type === "INTERNAL"
    ).length > 0;

  if (hasCertaneOrganisation) {
    return null;
  }

  return message;
};

export const getEntityRelationshipInitialValues = (
  loggedInUser,
  autoPopulateEntitiesByRelationshipTypes,
  accessToken
) =>
  new Promise((resolve) => {
    const userRegions = loggedInUser ? loggedInUser.regions : [];

    const entityRelationship = {
      financialProducts: [],
      funds: [],
      organisations: [],
      regions: [],
      businessServiceAreas: [],
    };

    if (userRegions.length === 1) {
      entityRelationship.regions = [...userRegions];
    }

    if (loggedInUser.externalUser) {
      // Default external organisation
      if (loggedInUser.organisations?.length <= 0) {
        logger.warn(
          "User {} is not a staff user but is missing the organisation",
          loggedInUser.email
        );
      } else if (loggedInUser.organisations?.length === 1) {
        // Only default organisation for external user with only 1 org
        const externalOrg = loggedInUser.organisations[0];
        logger.debug("Defaulting service providers to", externalOrg);
        entityRelationship.organisations = [
          ...entityRelationship.organisations,
          {
            organisation: externalOrg,
            visibilityScope: "ORGANISATION",
          },
        ];
      }

      // Default products
      const searchParameters = {
        limit: 100,
        offset: 0,
        orderByField: "name",
        excludeInactive: true,
      };

      productsApi
        .search(searchParameters, null, accessToken)
        .then((response) => {
          if (response.resultCount === 1) {
            const product = response.results[0];
            entityRelationship.financialProducts = [product];
            const sargonEntities = product.relationships
              .filter(
                (relationship) =>
                  autoPopulateEntitiesByRelationshipTypes.indexOf(
                    relationship.relationshipType
                  ) !== -1
              )
              .map((relationship) => relationship.organisation)
              .filter((org) => org.type === "INTERNAL");
            const linkedOrganisations = sargonEntities.map((organisation) => ({
              organisation,
              visibilityScope: "ORGANISATION",
            }));
            entityRelationship.organisations = [
              ...entityRelationship.organisations,
              ...linkedOrganisations,
            ];
            if (product.fund) {
              entityRelationship.funds = [product.fund];
            }
          }
          resolve(entityRelationship);
        });
    } else {
      resolve(entityRelationship);
    }
  });

const equalSets = (set1, set2) => _.isEqual(_.orderBy(set1), _.orderBy(set2));

const useStyles = makeStyles((theme) => ({
  warning: {
    color: theme.palette.warning.main,
  },
}));

const EntityRelationshipFields = ({
  fieldLabels,
  fieldPrefix,
  selectedFinancialProducts,
  selectedFunds,
  selectedOrganisations,
  selectedRegions,
  selectedFundLevel,
  autoPopulateEntitiesByRelationshipTypes,
  filterEntitiesByRelationshipTypes,
  defaultVisibilityScope,
  change,
  untouch,
  formName,
  loggedInUser,
  customisations,
}) => {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const [userRegions] = useState(loggedInUser ? loggedInUser.regions : []);
  const [defaultProduct, setDefaultProduct] = useState(false);
  const [previousProducts, setPreviousProducts] = useState(
    selectedFinancialProducts
  );
  const [previousFunds, setPreviousFunds] = useState(selectedFunds);
  const [previousOrganisations, setPreviousOrganisations] = useState(
    selectedOrganisations
  );
  const [productDrivenFundChange, setProductDrivenFundChange] = useState(false);
  const [fundLevelWarning, setFundLevelWarning] = useState("");
  const fundLevelWarningTimestamp = useRef(Date.now());

  const hasCertaneEntities = _.memoize(
    validateHasCertaneEntities(
      `${fieldLabels.labels.organisations} at least one required to be internal entity`
    )
  );

  const getProductsForFunds = async (funds) => {
    const accessToken = await getAccessTokenSilently();
    const fundIds = funds.map((fund) => fund.id);
    const searchParameters = {
      limit: 100,
      offset: 0,
      fundIds,
      excludeInactive: true,
    };
    const response = await productsApi.search(
      searchParameters,
      null,
      accessToken
    );
    return response.results;
  };

  const autoPopulateRelatedOrganisations = (relationshipHolders) => {
    const userOrgIds = loggedInUser.organisations.map((o) => o.id);
    // Auto populate organisations which:
    // - Belong to the logged in user, or
    // - Are related to the products / funds via 'autoPopulateEntitiesByRelationshipTypes'
    const relatedOrganisations = _.uniqBy(
      _.flatMap(relationshipHolders, (relationshipHolder) =>
        relationshipHolder.relationships
          .filter(
            (relationship) =>
              autoPopulateEntitiesByRelationshipTypes.indexOf(
                relationship.relationshipType
              ) !== -1 ||
              userOrgIds.indexOf(relationship.organisation.id) !== -1
          )
          .map((relationship) => relationship.organisation)
      ),
      "id"
    );

    const newOrganisations = [...selectedOrganisations];
    // Add all related orgs with visibility:
    // - set to 'ORGANISATION' for orgs belonging to the logged in user
    // - otherwise, as configured in 'defaultVisibilityScope'
    relatedOrganisations.forEach((organisation) => {
      newOrganisations.push({
        organisation,
        visibilityScope:
          userOrgIds.indexOf(organisation.id) !== -1
            ? "ORGANISATION"
            : defaultVisibilityScope[organisation.type],
      });
    });

    const newOrganisationsSet = _.uniqBy(newOrganisations, "organisation.id");

    logger.debug(
      "Auto populating organisations (due to change in funds or products)",
      newOrganisationsSet
    );

    // Auto populate internal entities
    change("entityRelationship.organisations", newOrganisationsSet);
  };

  const autoPopulateRelatedFunds = () => {
    const newLinkedFunds = [...selectedFunds];
    _.uniqBy(
      _.flatMap(selectedFinancialProducts, (product) => product.fund),
      "id"
    )
      .filter((fund) => !!fund)
      .forEach((fund) => newLinkedFunds.push(fund));
    const newFundsSet = _.uniqBy(newLinkedFunds, "id");
    logger.debug(
      "Auto populating funds (due to change in products)",
      newFundsSet
    );
    change("entityRelationship.funds", newFundsSet);
  };

  const autoPopulateRelatedProducts = async (newFunds) => {
    const fundsEmpty = isEmpty(newFunds);
    if (!fundsEmpty) {
      const products = await getProductsForFunds(newFunds);
      const newProductSet = _.uniqBy(
        [...products, ...selectedFinancialProducts],
        "id"
      );
      logger.debug(
        "Auto populating products (due to change in funds)",
        newProductSet
      );
      change("entityRelationship.financialProducts", newProductSet);
    }
  };

  // Auto populate default product if user only has one product
  useEffect(() => {
    if (loggedInUser.externalUser) {
      // Default products
      const searchParameters = {
        limit: 100,
        offset: 0,
        orderByField: "name",
        excludeInactive: true,
      };
      getAccessTokenSilently().then((accessToken) =>
        productsApi
          .search(searchParameters, null, accessToken)
          .then((response) => {
            if (response.resultCount === 1) {
              setDefaultProduct(true);
            }
          })
      );
    }
  }, []);

  // Auto populate internal & external entities (if not populated) on products set
  useEffect(() => {
    const currentEmpty = isEmpty(selectedFinancialProducts);
    const previousEmpty = isEmpty(previousProducts);
    const isDifferent =
      !currentEmpty &&
      !previousEmpty &&
      !equalSets(
        previousProducts.map((p) => p.id),
        selectedFinancialProducts.map((p) => p.id)
      );

    if (!currentEmpty && (previousEmpty || isDifferent)) {
      setProductDrivenFundChange(true);
      autoPopulateRelatedOrganisations(selectedFinancialProducts);
      autoPopulateRelatedFunds();
    }
    setPreviousProducts(selectedFinancialProducts);
  }, [selectedFinancialProducts]);

  useEffect(() => {
    const currentEmpty = isEmpty(selectedFunds);
    const previousEmpty = isEmpty(previousFunds);
    const isDifferent =
      !currentEmpty &&
      !previousEmpty &&
      !equalSets(
        previousFunds.map((fund) => fund.id),
        selectedFunds.map((fund) => fund.id)
      );

    if (!currentEmpty && (previousEmpty || isDifferent)) {
      if (!productDrivenFundChange) {
        let newFunds;
        if (previousEmpty) {
          newFunds = selectedFunds;
        } else {
          newFunds = selectedFunds.filter(
            (fund) => !previousFunds.find((prev) => prev.id === fund.id)
          );
        }
        // Auto populate organisations related to new funds
        autoPopulateRelatedProducts(newFunds);
      }

      // Auto populate organisations related to new funds
      autoPopulateRelatedOrganisations(selectedFunds);
    }
    setProductDrivenFundChange(false);
    setPreviousFunds(selectedFunds);
  }, [selectedFunds]);

  useEffect(() => {
    if (isEmpty(selectedOrganisations) && !isEmpty(previousOrganisations)) {
      change("entityRelationship.businessServiceAreas", []);
    }
    setPreviousOrganisations(selectedOrganisations);
  }, [selectedOrganisations]);

  useEffect(() => {
    if (userRegions.length !== 1) {
      if (!isEmpty(selectedFinancialProducts)) {
        logger.debug("Auto populating regions from selectedFinancialProducts");
        change(
          "entityRelationship.regions",
          _.uniq(selectedFinancialProducts.map((product) => product.region))
        );
      } else if (!isEmpty(selectedOrganisations)) {
        logger.debug("Auto populating regions from selectedOrganisations");
        change(
          "entityRelationship.regions",
          _.uniq(
            selectedOrganisations
              .filter((linkedOrg) => !!linkedOrg.organisation)
              .map((linkedOrg) => linkedOrg.organisation.region)
          )
        );
      } else if (selectedRegions && selectedRegions.length > 0) {
        logger.debug("Clearing regions");
        change("entityRelationship.regions", []);
      }
    }
  }, [selectedFinancialProducts, selectedOrganisations]);

  useEffect(() => {
    const timestamp = Date.now();
    if (selectedFundLevel) {
      (async () => {
        const allFundProducts = await getProductsForFunds(selectedFunds);
        const selectedProductIds = selectedFinancialProducts.map((p) => p.id);
        const allProductsAdded =
          allFundProducts.filter((p) => selectedProductIds.indexOf(p.id) === -1)
            .length === 0;
        if (timestamp > fundLevelWarningTimestamp.current) {
          fundLevelWarningTimestamp.current = timestamp;
          if (allProductsAdded) {
            setFundLevelWarning("");
          } else {
            setFundLevelWarning(
              "Not all products have been added. You are unable to set this as Fund level."
            );
          }
        }
      })();
    } else {
      fundLevelWarningTimestamp.current = timestamp;
      setFundLevelWarning("");
    }
  }, [selectedFundLevel, selectedFinancialProducts]);

  const asyncProductOptionsFetch = async (
    pickerFilter,
    pagination,
    abortController
  ) => {
    const accessToken = await getAccessTokenSilently();
    const { textSearch, byOrganisations, byFunds } = pickerFilter;
    let organisationIds = [];
    if (!isEmpty(selectedOrganisations) && byOrganisations) {
      organisationIds = selectedOrganisations.map(
        (linkedOrg) => linkedOrg.organisation.id
      );
    }
    let fundIds = [];
    if (!isEmpty(selectedFunds) && byFunds) {
      fundIds = selectedFunds.map((fund) => fund.id);
    }
    const searchParameters = {
      textSearch,
      limit: pagination.pageSize,
      offset: pagination.offset,
      organisationIds,
      fundIds,
      orderByField: "name",
      excludeInactive: true,
    };
    return productsApi.search(searchParameters, abortController, accessToken);
  };

  const asyncBusinessServiceAreaOptionsFetch = async (
    pickerFilter,
    pagination,
    abortController
  ) => {
    const accessToken = await getAccessTokenSilently();
    let organisationIds = [];
    if (!isEmpty(selectedOrganisations)) {
      organisationIds = selectedOrganisations.map(
        (linkedOrg) => linkedOrg.organisation.id
      );
    }
    const searchParameters = {
      ...pickerFilter,
      limit: pagination.pageSize,
      offset: pagination.offset,
      organisationIds,
      orderByField: "name",
    };
    return businessServiceAreasApi.search(
      searchParameters,
      abortController,
      accessToken
    );
  };

  return (
    <>
      <ComponentField
        name="entityRelationship.financialProducts"
        fieldPrefix={fieldPrefix}
        component={WrappedChipListPicker}
        datasource={asyncProductOptionsFetch}
        label={fieldLabels.labels.financialProducts}
        fullWidth
        clearable
        selectAll
        toOption={(product) => ({
          label: product.name,
          id: product.id,
          product,
        })}
        fromOption={(option) => option.product}
        addIcon={productIcon()}
        disabled={defaultProduct}
        validateIfRequired={length({
          min: 1,
          msg: `${fieldLabels.labels.financialProducts} is required`,
        })}
        renderIcon={(product, size) => (
          <Avatar
            name={(product.name || "Unknown").charAt(0)}
            size={size}
            round
          />
        )}
        filterInitialValues={{ byOrganisations: true, byFunds: true }}
        renderFilter={(onChange, filter) => (
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item style={{ flex: 1, marginRight: "3px" }}>
              <DebouncedTextField
                value={filter.textSearch}
                onChange={(text) => onChange("textSearch", text)}
                placeholder="Type to filter..."
                margin="none"
                autoFocus
                fullWidth
                data-cy="typeToFilter"
              />
            </Grid>
            <Grid item>
              <Grid
                container
                alignItems="flex-start"
                justifyContent="space-between"
                direction="column"
              >
                {!isEmpty(selectedFunds) && !loggedInUser.externalUser && (
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={!!filter.byFunds}
                          onChange={(evt) =>
                            onChange("byFunds", evt.target.checked)
                          }
                          name="byFunds"
                          data-cy="byFunds"
                        />
                      }
                      label="Filter by selected funds"
                    />
                  </Grid>
                )}
                {!isEmpty(selectedOrganisations) && !loggedInUser.externalUser && (
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={!!filter.byOrganisations}
                          onChange={(evt) =>
                            onChange("byOrganisations", evt.target.checked)
                          }
                          name="byOrganisations"
                          data-cy="byOrganisations"
                        />
                      }
                      label="Filter by selected entities"
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      />
      {!loggedInUser.externalUser && (
        <ComponentFieldArray
          name="entityRelationship.funds"
          fieldPrefix={fieldPrefix}
          label={fieldLabels.labels.funds}
          validateIfRequired={length({
            min: 1,
            msg: `${fieldLabels.labels.funds} is required`,
          })}
          fieldLabels={fieldLabels}
          component={EntityRelationshipFundFields}
          regions={selectedRegions}
          products={selectedFinancialProducts}
          funds={selectedFunds}
          customisations={customisations}
          change={change}
          untouch={untouch}
          formName={formName}
          data-cy="funds"
        />
      )}
      {!loggedInUser.externalUser && (
        <ComponentField
          margin="none"
          name="entityRelationship.fundLevel"
          fieldPrefix={fieldPrefix}
          label={fieldLabels.labels.fundLevel}
          component={WrappedSwitch}
          customisations={customisations}
          change={change}
          untouch={untouch}
          formName={formName}
          disabled={selectedFunds.length === 0}
          helperText={
            <span className={classes.warning}>{fundLevelWarning}</span>
          }
        />
      )}
      {!loggedInUser.externalUser && (
        <ComponentFieldArray
          name="entityRelationship.organisations"
          fieldPrefix={fieldPrefix}
          label={fieldLabels.labels.organisations}
          validateIfRequired={[
            length({
              min: 1,
              msg: `${fieldLabels.labels.organisations} is required`,
            }),
            hasCertaneEntities,
          ]}
          fieldLabels={fieldLabels}
          component={EntityRelationshipOrganisationFields}
          regions={selectedRegions}
          products={selectedFinancialProducts}
          funds={selectedFunds}
          organisations={selectedOrganisations}
          filterEntitiesByRelationshipTypes={filterEntitiesByRelationshipTypes}
          defaultVisibilityScope={defaultVisibilityScope}
          customisations={customisations}
          change={change}
          untouch={untouch}
          formName={formName}
          data-cy="organisations"
        />
      )}
      {!loggedInUser.externalUser &&
        !isEmpty(selectedOrganisations) &&
        selectedOrganisations.some(
          (linkedOrg) =>
            linkedOrg.organisation && linkedOrg.organisation.type === "INTERNAL"
        ) && (
          <ComponentField
            name="entityRelationship.businessServiceAreas"
            fieldPrefix={fieldPrefix}
            component={WrappedChipListPicker}
            datasource={asyncBusinessServiceAreaOptionsFetch}
            label={fieldLabels.labels.businessServiceAreas}
            fullWidth
            clearable
            selectAll
            validateIfRequired={length({
              min: 1,
              msg: `${fieldLabels.labels.businessServiceAreas} is required`,
            })}
            toOption={(serviceArea) => ({
              label: serviceArea.name,
              id: serviceArea.id,
              serviceArea,
            })}
            fromOption={(option) => option.serviceArea}
            addIcon={businessServiceAreaIcon()}
            renderIcon={(serviceArea, size) => (
              <Avatar
                name={(serviceArea.name || "Unknown").charAt(0)}
                size={size}
                round
              />
            )}
            data-cy="businessServiceAreas"
            customisations={customisations}
            change={change}
            untouch={untouch}
            formName={formName}
          />
        )}
      {!loggedInUser.externalUser && (
        <ComponentField
          name="entityRelationship.regions"
          fieldPrefix={fieldPrefix}
          component={WrappedNonInteractive}
          label={fieldLabels.labels.regions}
          validateIfRequired={length({
            min: 1,
            msg: `${fieldLabels.labels.regions} is required`,
          })}
          render={(value) => (
            <SimpleChips values={value || []} blankLabel="-" />
          )}
          customisations={customisations}
          change={change}
          untouch={untouch}
          formName={formName}
        />
      )}
    </>
  );
};

EntityRelationshipFields.propTypes = {
  fieldLabels: PropTypes.object.isRequired,
  fieldPrefix: PropTypes.string,
  formName: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  autoPopulateEntitiesByRelationshipTypes: PropTypes.array.isRequired,
  filterEntitiesByRelationshipTypes: PropTypes.array.isRequired,
  defaultVisibilityScope: PropTypes.object.isRequired,
  customisations: PropTypes.array,

  // redux
  loggedInUser: PropTypes.object,
  selectedFinancialProducts: PropTypes.array,
  selectedFunds: PropTypes.array,
  selectedOrganisations: PropTypes.array,
  selectedRegions: PropTypes.array,
  selectedFundLevel: PropTypes.bool,
};

EntityRelationshipFields.defaultProps = {
  selectedFinancialProducts: [],
  selectedFunds: [],
  selectedOrganisations: [],
  selectedRegions: [],
  selectedFundLevel: false,
  loggedInUser: null,
  customisations: [],
  fieldPrefix: "",
};

const mapStateToProps = (state, ownProps) => {
  const reduxFormSelector = formValueSelector(ownProps.formName);
  return {
    loggedInUser: getLoggedInUser(state),
    selectedFinancialProducts: reduxFormSelector(
      state,
      "entityRelationship.financialProducts"
    ),
    selectedFunds: reduxFormSelector(state, "entityRelationship.funds"),
    selectedOrganisations: reduxFormSelector(
      state,
      "entityRelationship.organisations"
    ),
    selectedRegions: reduxFormSelector(state, "entityRelationship.regions"),
    selectedFundLevel: reduxFormSelector(state, "entityRelationship.fundLevel"),
  };
};

export default connect(mapStateToProps)(EntityRelationshipFields);
