import Typography from "@material-ui/core/Typography";
import React from "react";
import { compose } from "redux";
import { withStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const styles = (theme) => ({
  warning: {
    margin: `${theme.spacing(3)}px 0`,
    display: "block",
    color: theme.palette.warning.main,
  },
});

const OpenActionsWarning = ({ classes, type }) => (
  <Typography className={classes.warning} variant="body2" display="block">
    Warning: This {type} has open actions. It should not be closed until all
    actions have been completed.
  </Typography>
);

OpenActionsWarning.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default compose(withStyles(styles))(OpenActionsWarning);
