import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { SubmissionError } from "redux-form";
import { getRiskById } from "../../actions/risks";
import {
  clearActiveWorkItem,
  createWorkItem,
  loadWorkItems,
  patchWorkItem,
} from "../../actions/workItems";
import {
  getActiveRisk,
  getReferenceDataType,
  getWorkItems,
  isLoading,
} from "../../reducers";
import workItemsApi from "../../services/api/workItems";
import logger from "../../util/logger";
import Avatar from "../common/Avatar.tsx";
import ExpandableForm from "../forms/ExpandableForm";
import ActionPlanItemForm, {
  ACTION_PLAN_ITEM_FORM_NAME,
} from "../forms/risk/ActionPlanItemForm";
import ListPicker from "../ListPicker";
import WorkItemListItem from "../workitem/WorkItemListItem";
import OpenCloseWorkItemFilter from "../workitem/OpenCloseWorkItemFilter";
import { getStatusName } from "../workitem/components/overview/WorkItemStatus";
import { useAuth0 } from "@auth0/auth0-react";
import MenuItem from "@material-ui/core/MenuItem";
import { associationRemoveIcon } from "../../util/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  overdue: {
    color: theme.due.overdue,
  },
  actionStatusChip: {
    backgroundColor: theme.palette.grey[300],
    padding: `${theme.spacing(1) / 4}px ${theme.spacing(1)}px`,
    borderRadius: "16px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: "100%",
    whiteSpace: "nowrap",
    marginRight: theme.spacing(1) / 2,
  },
  nowrap: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  createNewLink: {
    cursor: "pointer",
    color: theme.palette.swatch.link,
  },
}));

const ActionPlanCard = ({
  className,
  loading,
  actions,
  workItemTypes,
  risk,
  localClearActiveWorkItem,
  localLoadWorkItems,
  localPatchWorkItem,
  localCreateWorkItem,
  localGetRiskById,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showLinkActionDialog, setShowLinkActionDialog] = useState(false);
  const [filteredActions, setFilteredActions] = useState([]);

  const actionsForRiskSearchParameters = () => ({
    riskId: risk.id,
    types: ["RISK_ACTION"],
    includeTemplates: false,
    includeChildren: false,
    limit: 100,
    orderByField: "-created",
  });

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      localClearActiveWorkItem();
      localLoadWorkItems(actionsForRiskSearchParameters(), accessToken);
    })();
  }, [getAccessTokenSilently]);

  const handleSubmission = async (workItem) => {
    logger.debug(
      "submitting work item of type RiskAction with values",
      workItem
    );

    const accessToken = await getAccessTokenSilently();
    localCreateWorkItem(workItem, accessToken)
      .then(() => {
        setShowCreateForm(false);
        localLoadWorkItems(actionsForRiskSearchParameters(), accessToken);
        localGetRiskById(risk.id, accessToken);
      })
      .catch((error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  const removeAction = async (action) => {
    const accessToken = await getAccessTokenSilently();
    localPatchWorkItem(
      action.id,
      {
        associatedRisks: action.associatedRisks.filter((r) => r.id !== risk.id),
      },
      "Unlinking action from risk",
      accessToken
    ).then(() => {
      localLoadWorkItems(actionsForRiskSearchParameters(), accessToken);
    });
  };

  const asyncActionOptionsFetch = async (
    pickerFilter,
    pagination,
    abortController
  ) => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = {
      ...pickerFilter,
      types: ["RISK_ACTION"],
      status: "OPEN",
      limit: pagination.pageSize,
      offset: pagination.offset,
      orderByField: "title",
    };
    return workItemsApi.search(searchParameters, abortController, accessToken);
  };

  const linkActions = async (allActions) => {
    const accessToken = await getAccessTokenSilently();
    const newActionsToLink = allActions.filter(
      (a) => !a.associatedRisks.some((r) => r.id === risk.id)
    );

    Promise.all(
      newActionsToLink.map((a) =>
        localPatchWorkItem(
          a.id,
          { associatedRisks: [...a.associatedRisks, { id: risk.id }] },
          "Linking action to risk",
          accessToken
        )
      )
    ).then(() => {
      localLoadWorkItems(actionsForRiskSearchParameters(), accessToken);
      setShowLinkActionDialog(false);
    });
  };

  const mergedClassName = classNames(classes.root, className);

  const initialValues = {
    type: "RISK_ACTION",
    associatedRisks: [{ id: risk.id }],
    category: "RISK_ACTION_GENERAL",
  };

  const DeleteIcon = associationRemoveIcon();

  return (
    <Card className={mergedClassName} elevation={0}>
      <CardHeader
        title="Action plan"
        action={
          <Grid container>
            <Grid item>
              <Tooltip title="Add action" disableFocusListener>
                <div>
                  <IconButton
                    onClick={() => setShowLinkActionDialog(true)}
                    disabled={showCreateForm}
                    data-cy="addAction"
                  >
                    <AddIcon />
                  </IconButton>
                </div>
              </Tooltip>
            </Grid>
          </Grid>
        }
      />
      <ListPicker
        data-cy="select"
        title="Find a risk action to link"
        actionText="Select"
        open={showLinkActionDialog}
        onClose={() => setShowLinkActionDialog(false)}
        onSubmit={linkActions}
        datasource={asyncActionOptionsFetch}
        selected={actions}
        disabledOptions={actions}
        isMulti
        toOption={(action) => ({
          label: action.title,
          id: action.id,
          action,
        })}
        fromOption={(option) => option.action}
        renderIcon={(action, size) => (
          <Avatar
            name={(action.title || "Unknown").charAt(0)}
            size={size}
            round
          />
        )}
        renderLabel={(action) => (
          <ListItemText
            primary={
              <Typography
                className={classes.nowrap}
                title={action.title}
                data-cy={action.title}
              >
                {action.title}
              </Typography>
            }
            secondary={
              <span data-cy={action.owner && action.owner.name}>
                <span
                  className={classes.actionStatusChip}
                  data-cy={action.owner && action.owner.name}
                >
                  {getStatusName(
                    action,
                    workItemTypes.values.RISK_ACTION.props.definition
                  )}
                </span>
                {action.owner && action.owner.name}
              </span>
            }
          />
        )}
        additionalActions={[
          <Link
            key="create-new"
            className={classes.createNewLink}
            onClick={() => {
              setShowLinkActionDialog(false);
              setShowCreateForm(true);
            }}
            data-cy="createNewActionPlan"
          >
            Can&apos;t find appropriate action? (Create a new one here)
          </Link>,
        ]}
      />
      <ExpandableForm
        title="New action"
        submitButtonText="Save"
        maxWidth="md"
        formComponent={ActionPlanItemForm}
        formName={ACTION_PLAN_ITEM_FORM_NAME}
        open={showCreateForm}
        onCancel={() => setShowCreateForm(false)}
        onSubmit={(values) => handleSubmission(values)}
        initialValues={initialValues}
        data-cy={ActionPlanItemForm}
      />
      <OpenCloseWorkItemFilter
        workItems={actions}
        filteredWorkItemsChange={(filtered) => setFilteredActions(filtered)}
      />
      {filteredActions.length > 0 && (
        <List dense>
          {filteredActions.map((action) => (
            <WorkItemListItem
              key={action.id}
              workItem={action}
              types={workItemTypes}
              linkTo={`/risk/risks/${risk.id}/actions/${action.id}`}
              data-cy="actionList"
            >
              <MenuItem onClick={() => removeAction(action)}>
                <DeleteIcon /> Remove
              </MenuItem>
            </WorkItemListItem>
          ))}
        </List>
      )}
      {filteredActions.length === 0 && !loading && (
        <CardContent>
          <em data-cy="noActions">
            There are no actions associated with this risk
          </em>
        </CardContent>
      )}
    </Card>
  );
};

ActionPlanCard.propTypes = {
  loading: PropTypes.bool.isRequired,
  risk: PropTypes.object.isRequired,
  actions: PropTypes.array.isRequired,
  workItemTypes: PropTypes.object.isRequired,
  localLoadWorkItems: PropTypes.func.isRequired,
  localClearActiveWorkItem: PropTypes.func.isRequired,
  localGetRiskById: PropTypes.func.isRequired,
  localCreateWorkItem: PropTypes.func.isRequired,
  className: PropTypes.string,
  localPatchWorkItem: PropTypes.func.isRequired,
};

ActionPlanCard.defaultProps = {
  className: undefined,
};

const mapStateToProps = (state) => ({
  loading: isLoading(state),
  risk: getActiveRisk(state),
  actions: getWorkItems(state),
  workItemTypes: getReferenceDataType(state, "WorkItemType"),
});

export default connect(mapStateToProps, {
  localLoadWorkItems: loadWorkItems,
  localClearActiveWorkItem: clearActiveWorkItem,
  localGetRiskById: getRiskById,
  localCreateWorkItem: createWorkItem,
  localPatchWorkItem: patchWorkItem,
})(ActionPlanCard);
