import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListSubheader from "@material-ui/core/ListSubheader";
import { useTheme } from "@material-ui/core/styles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import classNames from "classnames";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles((theme) => ({
  grid: {
    width: "100%",
  },
  listItemSecondaryAction: {
    visibility: "hidden",
  },
  tableRowContainer: {
    "&:hover $listItemSecondaryAction": {
      visibility: "inherit",
    },
  },
  tableRowNoHover: {
    background: "#fff",
    color: "#727272",
    boxShadow: "0 2px 6px rgba(160, 160, 160, 0.1)",
    transition: "all 0.2s ease",
  },
  tableRowWithHover: {
    background: "#fff",
    color: "#727272",
    boxShadow: "0 2px 6px rgba(160, 160, 160, 0.1)",
    transition: "all 0.2s ease",

    "&:hover": {
      background: "#f9f9f9",
      boxShadow: "0 2px 6px rgba(160, 160, 160, 0.4)",
      color: "#515151",
    },
  },
  clearFiltersCta: {
    textDecoration: "underline",
    display: "inline",
    cursor: "pointer",
    "&:hover": {
      color: "#515151",
    },
  },
  tableRowHighlight1: {
    borderLeft: "2px solid #333",
  },
  tableRowHighlight2: {
    borderLeft: "2px solid #999",
  },
  tableRowHighlight3: {
    borderLeft: "2px solid #ffcd38",
  },
  tableRowSuppressed: {
    background: theme.palette.divider,
  },
  tableHeader: {
    lineHeight: "1.5rem",
    marginBottom: theme.spacing(1) / 2,
  },
  groupHeader: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
  item: {
    paddingRight: `${theme.spacing(1.5)}px !important`,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const GridListing = ({
  className,
  loading,
  sortedData,
  groupedData,
  sortBy,
  updateSort,
  onClick,
  dense,
  action,
  isHighlight1,
  isHighlight2,
  isHighlight3,
  isSuppressed,
  columns,
  selection,
  handleSelect,
  noItemsText,
  highlightOnHover,
  clearFilter,
}) => {
  const classes = useStyles();
  const hasSelection =
    selection !== null && selection !== undefined && !!handleSelect;

  const onSortClick = (field) => {
    if (!sortBy || !updateSort) return;

    const sortByNew = { ...sortBy };
    if (sortByNew.field === field) {
      sortByNew.direction = sortByNew.direction === "asc" ? "desc" : "asc";
    } else {
      sortByNew.field = field;
      sortByNew.direction = "asc";
    }

    updateSort(sortByNew.field, sortByNew.direction);
  };

  const columnHeader = (title, field) => (
    <Tooltip
      title="Sort"
      placement="bottom"
      enterDelay={300}
      disableFocusListener
    >
      <TableSortLabel
        active={sortBy?.field === field}
        direction={sortBy?.direction}
        onClick={() => onSortClick(field)}
        data-cy={title}
      >
        {title}
      </TableSortLabel>
    </Tooltip>
  );

  const visibleColumns = columns.filter((c) => !c.hide);

  const smallScreen = useMediaQuery(useTheme().breakpoints.down("sm"));
  const totalGridSize = _.sum(visibleColumns.map((field) => field.size));
  const calculateWidth = (col) =>
    smallScreen ? 100 : (col / totalGridSize) * 100;

  const renderGroupHeader = (group) => (
    <ListSubheader className={classes.groupHeader}>
      <Typography variant="h6" data-cy={group.label}>
        {group.label}
      </Typography>
    </ListSubheader>
  );

  const clearFiltersCta = clearFilter ? (
    <Typography variant="body2" style={{ display: "inline" }}>
      {" "}
      <span
        className={classes.clearFiltersCta}
        role="button"
        data-cy="clearFilters"
        tabIndex={0}
        onClick={clearFilter}
        onKeyDown={clearFilter}
      >
        Clear filters.
      </span>
    </Typography>
  ) : null;

  const renderListItem = (item, index) => (
    <ListItem
      key={item.key || item.id || index}
      className={classNames({
        "work-item-list": true,
        [classes.tableRowNoHover]: !highlightOnHover,
        [classes.tableRowWithHover]: highlightOnHover,
        [classes.tableRowHighlight1]: isHighlight1 && isHighlight1(item),
        [classes.tableRowHighlight2]: isHighlight2 && isHighlight2(item),
        [classes.tableRowHighlight3]: isHighlight3 && isHighlight3(item),
        [classes.tableRowSuppressed]: isSuppressed && isSuppressed(item),
      })}
      classes={{
        container: classes.tableRowContainer,
      }}
      divider
      button={!!onClick}
      onClick={() => onClick && onClick(item)}
    >
      {hasSelection && (
        <Checkbox
          checked={selection.indexOf(item.id || index) !== -1}
          onClick={() => handleSelect(item.id || index)}
          tabIndex={-1}
          disableRipple
        />
      )}
      <Grid container spacing={1} alignItems="center" data-cy="row">
        {visibleColumns.map((field) => (
          <Grid
            item
            key={`${item.id || index}-${field.name}`}
            style={{ width: `${calculateWidth(field.size)}%` }}
            classes={{ item: classes.item }}
            data-cy="columns"
          >
            {field.render(item)}
          </Grid>
        ))}
      </Grid>
      {action && (
        <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
          {action(item)}
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );

  const hasItemsToDisplay = sortedData.length > 0 || groupedData.length > 0;

  return (
    <List
      component="nav"
      dense={dense}
      classes={{ root: classes.grid }}
      className={className}
      data-cy="workItemsList"
    >
      {loading && <ListSubheader>Loading...</ListSubheader>}
      {!loading && !hasItemsToDisplay && (
        <ListSubheader data-cy={noItemsText}>
          {noItemsText} {clearFiltersCta}
        </ListSubheader>
      )}
      {!loading && hasItemsToDisplay && (
        <Hidden smDown>
          <ListItem className={classes.tableHeader}>
            {hasSelection && (
              <Checkbox
                style={{ visibility: "hidden" }}
                checked={false}
                tabIndex={-1}
                disableRipple
              />
            )}
            <Grid container spacing={1} alignItems="center">
              {visibleColumns.map((field) => (
                <Grid
                  item
                  key={`header-${field.name}`}
                  style={{ width: `${calculateWidth(field.size)}%` }}
                >
                  {field.sortable
                    ? columnHeader(field.label, field.name)
                    : field.label}
                </Grid>
              ))}
            </Grid>
            {action && (
              <ListItemSecondaryAction>
                <div />
              </ListItemSecondaryAction>
            )}
          </ListItem>
        </Hidden>
      )}
      {!loading &&
        sortedData.length > 0 &&
        sortedData.map((item, index) => renderListItem(item, index))}
      {!loading &&
        groupedData.length > 0 &&
        groupedData.map((group, groupIndex) => (
          <div key={group.id || groupIndex}>
            {renderGroupHeader(group)}
            {group.items.map((item, index) => renderListItem(item, index))}
          </div>
        ))}
    </List>
  );
};

GridListing.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  sortedData: PropTypes.array.isRequired,
  groupedData: PropTypes.array,
  sortBy: PropTypes.object,
  updateSort: PropTypes.func,
  onClick: PropTypes.func,
  dense: PropTypes.bool,
  action: PropTypes.func,
  isHighlight1: PropTypes.func,
  isHighlight2: PropTypes.func,
  isHighlight3: PropTypes.func,
  isSuppressed: PropTypes.func,
  clearFilter: PropTypes.func,
  columns: PropTypes.array.isRequired,
  selection: PropTypes.array,
  handleSelect: PropTypes.func,
  noItemsText: PropTypes.string,
  highlightOnHover: PropTypes.bool,
};

GridListing.defaultProps = {
  action: null,
  isHighlight1: null,
  isHighlight2: null,
  isHighlight3: null,
  isSuppressed: null,
  clearFilter: null,
  dense: true,
  onClick: null,
  selection: null,
  handleSelect: null,
  noItemsText:
    "No items were found. Please try another search term and check your filters.",
  groupedData: [],
  highlightOnHover: true,
};

export default GridListing;
