import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import { fetchTags } from "../../../actions/tags";
import {
  getLabels,
  getReferenceDataDescription,
  getTags,
} from "../../../reducers";
import risksApi from "../../../services/api/risks";
import { scrollToFirstInvalidInput } from "../../../util/formHelpers";
import { associationsIcon } from "../../../util/icons";
import Avatar from "../../common/Avatar.tsx";
import CardSection from "../../common/CardSection";
import DebouncedTextField from "../../common/DebouncedTextField";
import DebugPrefill from "../../common/DebugPrefill";
import FormError from "../../common/FormError";
import MultiSelectDropdown from "../../common/MultiSelectDropdown";
import { tagsToOptions } from "../menuItems";
import WrappedChipListPicker from "../wrapper/WrappedChipListPicker";
import RiskControlDetailFields from "./RiskControlDetailFields";
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles((theme) => ({
  riskRatingChip: {
    backgroundColor: theme.palette.grey[300],
    padding: `${theme.spacing(1) / 4}px ${theme.spacing(1)}px`,
    borderRadius: "16px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: "100%",
    whiteSpace: "nowrap",
    marginRight: theme.spacing(1) / 2,
  },
  nowrap: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

const CreateRiskControlForm = ({
  error,
  change,
  fieldLabels,
  getRiskRatingDescription,
  tags,
  localFetchTags,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();
  const debugPrefill = () => {
    change("type", "DETECTIVE");
    change("name", "Test control");
    change(
      "description",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
    );
  };

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      localFetchTags(accessToken);
    })();
  }, []);

  const asyncRiskOptionsFetch = async (
    pickerFilter,
    pagination,
    abortController
  ) => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = {
      ...pickerFilter,
      limit: pagination.pageSize,
      offset: pagination.offset,
      orderByField: "title",
    };
    return risksApi.search(searchParameters, abortController, accessToken);
  };

  const riskRegisterOptions = tagsToOptions(
    tags.filter((tag) => tag.type === "RISK_REGISTER")
  );

  return (
    <form autoComplete="off">
      <DebugPrefill onClick={debugPrefill} />
      {error && <FormError errorMessage={error} />}
      <CardSection title="Risk control details" gutterBottom>
        <RiskControlDetailFields
          fieldLabels={fieldLabels}
          data-cy={fieldLabels}
        />
      </CardSection>
      <CardSection
        title="Associated risks"
        gutterBottom
        data-cy="associatedRisks"
      >
        <Field
          name="associatedRisks"
          component={WrappedChipListPicker}
          datasource={asyncRiskOptionsFetch}
          label={fieldLabels.labels.associatedRisks}
          data-cy={fieldLabels.labels.associatedRisks}
          fullWidth
          clearable
          toOption={(risk) => ({
            label: risk.title,
            id: risk.id,
            risk,
          })}
          fromOption={(option) => option.risk}
          addIcon={associationsIcon()}
          renderIcon={(risk, size) => (
            <Avatar
              name={(risk.title || "Unknown").charAt(0)}
              size={size}
              round
            />
          )}
          renderLabel={(risk) => (
            <ListItemText
              primary={
                <Typography
                  className={classes.nowrap}
                  title={risk.title}
                  data-cy={risk.title}
                >
                  {risk.title}
                </Typography>
              }
              secondary={
                <span>
                  <span
                    className={classes.riskRatingChip}
                    data-cy={getRiskRatingDescription(risk.riskRating)}
                  >
                    {getRiskRatingDescription(risk.riskRating)}
                  </span>
                  {risk.owner && risk.owner.name}
                </span>
              }
            />
          )}
          renderFilter={(onChange, filter) => (
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item xs={12} md={6}>
                <DebouncedTextField
                  value={filter.textSearch}
                  onChange={(text) => onChange("textSearch", text)}
                  placeholder="Type to filter..."
                  margin="none"
                  autoFocus
                  data-cy={filter.textSearch}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <div>
                  <FormLabel data-cy="riskRegister">Risk register</FormLabel>
                  <div>
                    <MultiSelectDropdown
                      value={filter.riskRegisters || []}
                      options={riskRegisterOptions}
                      onChange={(value) => onChange("riskRegisters", value)}
                      closeOnChange
                      data-cy={filter.riskRegisters || []}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          )}
        />
      </CardSection>
    </form>
  );
};

CreateRiskControlForm.propTypes = {
  error: PropTypes.string,
  change: PropTypes.func.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  tags: PropTypes.array.isRequired,
  getRiskRatingDescription: PropTypes.func.isRequired,
  localFetchTags: PropTypes.func.isRequired,
};

CreateRiskControlForm.defaultProps = {
  error: null,
};

const mapStateToProps = (state) => ({
  fieldLabels: getLabels(state).RiskControl,
  tags: getTags(state),
  getRiskRatingDescription: (rating) =>
    getReferenceDataDescription(state, "RiskRating", rating, "-"),
});

export const CREATE_RISK_CONTROL_FORM_NAME = "createRiskControlForm";

const form = reduxForm({
  form: CREATE_RISK_CONTROL_FORM_NAME,
  onSubmitFail: (errors) => scrollToFirstInvalidInput(errors),
});
const reduxData = connect(mapStateToProps, {
  localFetchTags: fetchTags,
});

export default compose(form, reduxData)(CreateRiskControlForm);
