import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { numericality, required } from "redux-form-validators";
import {
  getLabels,
  getReferenceDataType,
  getWorkItemSchedule,
  isLoading,
} from "../../../reducers";
import dates from "../../../util/dates";
import { scrollToFirstInvalidInput } from "../../../util/formHelpers";
import DateAgo from "../../common/DateAgo";
import FormError from "../../common/FormError";
import { monthlyPeriodNames } from "../../common/periodUtils";
import SchedulePreview from "./SchedulePreview";
import WrappedDatePicker from "../../forms/wrapper/WrappedDatePicker";
import WrappedMonthPicker from "../../forms/wrapper/WrappedMonthPicker";
import WrappedRadioGroup from "../../forms/wrapper/WrappedRadioGroup";
import WrappedSelect from "../../forms/wrapper/WrappedSelect";
import WrappedTextField from "../../forms/wrapper/WrappedTextField";
import WrappedWeekPicker from "../../forms/wrapper/WrappedWeekPicker";
import { getRefDataMenuItems } from "../../forms/menuItems";

export const EDIT_SCHEDULE_FORM_NAME = "EDIT_SCHEDULE_FORM_NAME";

const toInt = (value) => {
  if (value === null || value === undefined || value === "") {
    return null;
  }
  return _.toNumber(value);
};

const styles = (theme) => ({
  intro: {
    marginBottom: theme.spacing(1),
    fontWeight: 600,
  },
  label: {
    position: "relative",
  },
});

const EditScheduleForm = ({
  error,
  classes,
  fieldLabels,
  recurrenceTypes,
  recurrenceType,
  recurrenceUnit,
  nameOfFirstPeriod,
  startBy,
  stopBy,
  creationLeadTime,
  dueDateOffset,
  workItemIds,
  workItemSchedule,
  change,
}) => {
  const [hasExistingWorkItems] = useState(
    workItemIds && workItemIds.length > 0
  );
  const [showSchedulePreview, setShowSchedulePreview] = useState(false);

  useEffect(() => {
    if (!hasExistingWorkItems) {
      change("nameOfFirstPeriod", null);

      switch (recurrenceType) {
        case "WEEK":
          change("startBy", dates.serializeDate(moment().startOf("week")));
          break;
        case "MONTH":
          change("startBy", dates.serializeDate(moment().startOf("month")));
          break;
        case "YEAR":
          change("startBy", dates.serializeDate(moment().startOf("year")));
          break;
        default:
          break;
      }
    }
  }, [recurrenceType]);

  const recurrenceTypeDef =
    recurrenceType && recurrenceTypes.values[recurrenceType];

  const { reminderLeadTime } = workItemSchedule.template;

  return (
    <form autoComplete="off">
      {hasExistingWorkItems && (
        <div className={classes.intro}>
          Please note that the schedule recurrence details and start dates are
          not editable once work items have been created for this schedule.
        </div>
      )}
      {error && <FormError errorMessage={error} />}
      <Field
        name="enabled"
        component={WrappedRadioGroup}
        label={fieldLabels.labels.enabled}
        validate={required({
          msg: `${fieldLabels.labels.enabled} is required`,
        })}
        options={[
          { label: "Yes", value: true },
          { label: "No", value: false },
        ]}
        row
        required
      />
      {hasExistingWorkItems && (
        <>
          <FormControl fullWidth margin="dense">
            <InputLabel shrink className={classes.label}>
              {fieldLabels.labels.recurrenceType}
            </InputLabel>
            <Typography>{recurrenceTypeDef.description}</Typography>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel shrink className={classes.label}>
              {fieldLabels.labels.startBy}
            </InputLabel>
            <Typography>
              <DateAgo value={dates.parseDate(startBy)} startFull />
            </Typography>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel shrink className={classes.label}>
              {fieldLabels.labels.recurrenceUnit}
            </InputLabel>
            <Typography>
              {recurrenceUnit}
              {` ${recurrenceTypeDef.props.units}`}
            </Typography>
          </FormControl>
          {recurrenceType === "MONTH" && !!monthlyPeriodNames[recurrenceUnit] && (
            <FormControl fullWidth margin="dense">
              <InputLabel shrink className={classes.label}>
                {fieldLabels.labels.nameOfFirstPeriod}
              </InputLabel>
              <Typography>{nameOfFirstPeriod}</Typography>
            </FormControl>
          )}
        </>
      )}
      {!hasExistingWorkItems && (
        <Field
          name="recurrenceType"
          component={WrappedSelect}
          label={fieldLabels.labels.recurrenceType}
          fullWidth
          required
          validate={required({
            msg: `${fieldLabels.labels.recurrenceType} is required`,
          })}
        >
          {getRefDataMenuItems(recurrenceTypes)}
        </Field>
      )}
      {!hasExistingWorkItems && recurrenceType && (
        <>
          {recurrenceType !== "WEEK" && (
            <Field
              name="startBy"
              component={WrappedMonthPicker}
              label={fieldLabels.labels.startBy}
              validate={required({
                msg: `${fieldLabels.labels.startBy} is required`,
              })}
              required
              fullWidth
            />
          )}
          {recurrenceType === "WEEK" && (
            <Field
              name="startBy"
              component={WrappedWeekPicker}
              label={fieldLabels.labels.startBy}
              validate={required({
                msg: `${fieldLabels.labels.startBy} is required`,
              })}
              required
              fullWidth
            />
          )}
          <Field
            name="recurrenceUnit"
            component={WrappedTextField}
            label={fieldLabels.labels.recurrenceUnit}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {recurrenceTypeDef.props.units}
                </InputAdornment>
              ),
            }}
            required
            validate={numericality({
              ">": 0,
              int: true,
              allowBlank: false,
            })}
          />
          {recurrenceType === "MONTH" && !!monthlyPeriodNames[recurrenceUnit] && (
            <Field
              name="nameOfFirstPeriod"
              component={WrappedSelect}
              label={fieldLabels.labels.nameOfFirstPeriod}
              helperText="Optionally select a name for the first period"
              fullWidth
            >
              {monthlyPeriodNames[recurrenceUnit].map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Field>
          )}
        </>
      )}
      {recurrenceType && (
        <>
          <Field
            name="dueDateOffset"
            component={WrappedTextField}
            label={fieldLabels.labels.dueDateOffset}
            helperText="Number of days after end of period the task is due. Use negative value for before end of period."
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">days</InputAdornment>
              ),
            }}
            required
            validate={numericality({
              int: true,
              allowBlank: false,
            })}
          />
          <Field
            name="creationLeadTime"
            component={WrappedTextField}
            label={fieldLabels.labels.creationLeadTime}
            helperText="Number of days before end of period to create task."
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">days</InputAdornment>
              ),
            }}
            required
            validate={numericality({
              int: true,
              allowBlank: false,
            })}
          />
          <Field
            name="stopBy"
            component={WrappedDatePicker}
            label={fieldLabels.labels.stopBy}
            fullWidth
          />
          <Button
            color="primary"
            onClick={() => setShowSchedulePreview(true)}
            disabled={[
              recurrenceType,
              recurrenceUnit,
              startBy,
              _.isNumber(creationLeadTime),
              _.isNumber(dueDateOffset),
              _.isNumber(reminderLeadTime),
            ].some((value) => !value)}
          >
            Preview dates
          </Button>

          <Dialog
            open={showSchedulePreview}
            onClose={() => setShowSchedulePreview(false)}
            maxWidth="md"
            fullWidth
          >
            <DialogTitle>Preview dates</DialogTitle>
            <DialogContent>
              <SchedulePreview
                recurrenceType={recurrenceType}
                recurrenceUnit={recurrenceUnit}
                nameOfFirstPeriod={nameOfFirstPeriod}
                startBy={startBy}
                stopBy={stopBy}
                creationLeadTime={creationLeadTime}
                dueDateOffset={dueDateOffset}
                reminderLeadTime={reminderLeadTime}
                noItemsText="No further work items will be created for the current schedule settings."
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setShowSchedulePreview(false)}
                color="primary"
                autoFocus
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </form>
  );
};

EditScheduleForm.propTypes = {
  classes: PropTypes.object.isRequired,
  error: PropTypes.string,

  // redux
  recurrenceTypes: PropTypes.object.isRequired,
  workItemIds: PropTypes.array,
  change: PropTypes.func.isRequired,
  workItemSchedule: PropTypes.object,
  fieldLabels: PropTypes.object.isRequired,

  // redux form
  recurrenceType: PropTypes.string,
  recurrenceUnit: PropTypes.number,
  nameOfFirstPeriod: PropTypes.string,
  startBy: PropTypes.string,
  stopBy: PropTypes.string,
  creationLeadTime: PropTypes.number,
  dueDateOffset: PropTypes.number,
};

EditScheduleForm.defaultProps = {
  error: null,
  recurrenceType: null,
  recurrenceUnit: null,
  nameOfFirstPeriod: null,
  startBy: null,
  stopBy: null,
  creationLeadTime: null,
  dueDateOffset: null,
  workItemIds: [],
  workItemSchedule: undefined,
};

const reduxFormSelector = formValueSelector(EDIT_SCHEDULE_FORM_NAME);
const mapStateToProps = (state) => ({
  recurrenceTypes: getReferenceDataType(state, "RecurrenceType"),
  loading: isLoading(state),
  recurrenceType: reduxFormSelector(state, "recurrenceType"),
  recurrenceUnit: toInt(reduxFormSelector(state, "recurrenceUnit")),
  nameOfFirstPeriod: reduxFormSelector(state, "nameOfFirstPeriod"),
  startBy: reduxFormSelector(state, "startBy"),
  stopBy: reduxFormSelector(state, "stopBy"),
  creationLeadTime: toInt(reduxFormSelector(state, "creationLeadTime")),
  dueDateOffset: toInt(reduxFormSelector(state, "dueDateOffset")),
  workItemIds: reduxFormSelector(state, "workItemIds"),
  workItemSchedule: getWorkItemSchedule(state),
  fieldLabels: getLabels(state).WorkItemSchedule,
});

const form = reduxForm({
  form: EDIT_SCHEDULE_FORM_NAME,
  onSubmitFail: (errors) => scrollToFirstInvalidInput(errors),
});

const reduxData = connect(mapStateToProps);
export default compose(form, reduxData)(withStyles(styles)(EditScheduleForm));
