import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { getLabels } from "../../../reducers";
import {
  getAsyncValidate,
  scrollToFirstInvalidInput,
} from "../../../util/formHelpers";
import CardSection from "../../common/CardSection";
import FormError from "../../common/FormError";
import EntityRelationshipFields from "../business/EntityRelationshipFields";
import { referenceAsyncValidator } from "./MediaDocumentFields";
import SnippetContentFields from "./SnippetContentFields";
import SnippetDetailFields, { slugAsyncValidator } from "./SnippetDetailFields";

const useStyles = makeStyles((theme) => ({
  documentInstructions: {
    color: theme.palette.text.hint,
  },
  category: {
    marginBottom: theme.spacing(2),
  },
  categoryGroups: {
    marginLeft: theme.spacing(1),
  },
}));

const relationshipTypes = [
  "PROMOTER",
  "SUB_PROMOTER",
  "TRUSTEE",
  "RESPONSIBLE_ENTITY",
  "KIWISAVER_MANAGER",
];

export const SNIPPET_FORM_NAME = "snippetForm";

const asyncValidators = {
  slug: slugAsyncValidator,
  "contentVersions[].reference": {
    allFields: (values) =>
      (values.contentVersions || []).map(
        (v, i) => `contentVersions[${i}].reference`
      ),
    ...referenceAsyncValidator,
  },
};

const SnippetForm = ({ error, fieldLabels, change, touch, untouch }) => {
  const classes = useStyles();

  return (
    <form autoComplete="off" className={classes.root}>
      {error && <FormError errorMessage={error} />}
      <Card elevation={0}>
        <CardContent>
          <CardSection title={fieldLabels.labels.entityRelationship}>
            <EntityRelationshipFields
              formName={SNIPPET_FORM_NAME}
              fieldLabels={fieldLabels.nestedTypes.entityRelationship}
              change={change}
              untouch={untouch}
              customisations={[
                {
                  field: "entityRelationship.businessServiceAreas",
                  state: "HIDDEN",
                },
                {
                  field: "entityRelationship.regions",
                  state: "HIDDEN",
                },
              ]}
              autoPopulateEntitiesByRelationshipTypes={relationshipTypes}
              filterEntitiesByRelationshipTypes={relationshipTypes}
              defaultVisibilityScope={{
                INTERNAL: "ORGANISATION",
                EXTERNAL: "ORGANISATION",
              }}
            />
          </CardSection>
          <CardSection title="Snippet details" gutterBottom>
            <SnippetDetailFields
              fieldLabels={fieldLabels}
              editMode={false}
              change={change}
              touch={touch}
              formName={SNIPPET_FORM_NAME}
            />
          </CardSection>
          <CardSection title="Content">
            <SnippetContentFields
              fieldLabels={fieldLabels}
              namePrefix="contentVersions[0]"
              originalData=""
              formName={SNIPPET_FORM_NAME}
            />
          </CardSection>
        </CardContent>
      </Card>
    </form>
  );
};

SnippetForm.propTypes = {
  // redux
  change: PropTypes.func.isRequired,
  touch: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  error: PropTypes.string,
  fieldLabels: PropTypes.object.isRequired,
};

SnippetForm.defaultProps = {
  error: null,
};

const mapStateToProps = (state) => ({
  fieldLabels: getLabels(state).Snippet,
});

const form = reduxForm({
  form: SNIPPET_FORM_NAME,
  asyncValidate: getAsyncValidate(asyncValidators),
  asyncBlurFields: ["slug", "contentVersions[].reference"],
  onSubmitFail: (errors) => scrollToFirstInvalidInput(errors),
});

const reduxData = connect(mapStateToProps);

export default compose(form, reduxData)(SnippetForm);
