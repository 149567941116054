import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { getReferenceDataType } from "../../../../reducers";
import WrappedTextField from "../../../forms/wrapper/WrappedTextField";
import { getRefDataMenuItems } from "../../../forms/menuItems";
import ComponentField from "../../../forms/wrapper/ComponentField";
import WrappedSelect from "../../../forms/wrapper/WrappedSelect";

const ControlVerificationFields = ({
  frequencyTypes,
  fieldLabels,
  customisations,
  change,
  untouch,
  formName,
}) => (
  <>
    <ComponentField
      component={WrappedTextField}
      name="controlVerification.testPlan"
      label={fieldLabels.nestedTypes.controlVerification.labels.testPlan}
      fullWidth
      multiline
      rowsMax={20}
      rows={8}
      customisations={customisations}
      change={change}
      untouch={untouch}
      formName={formName}
    />
    <ComponentField
      component={WrappedSelect}
      name="controlVerification.verificationFrequency"
      label={
        fieldLabels.nestedTypes.controlVerification.labels.verificationFrequency
      }
      fullWidth
      customisations={customisations}
      change={change}
      untouch={untouch}
      formName={formName}
    >
      {getRefDataMenuItems(frequencyTypes)}
    </ComponentField>
  </>
);

ControlVerificationFields.propTypes = {
  fieldLabels: PropTypes.object.isRequired,
  customisations: PropTypes.array,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,

  // redux
  frequencyTypes: PropTypes.object.isRequired,
};

ControlVerificationFields.defaultProps = {
  customisations: [],
};

const mapStateToProps = (state) => ({
  frequencyTypes: getReferenceDataType(state, "FrequencyType"),
});

export default connect(mapStateToProps)(ControlVerificationFields);
