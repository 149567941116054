import React from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Container from "../common/Container";

const Error410 = ({ message }) => (
  <Container>
    <Grid container style={{ margin: 24 }}>
      <Grid item>
        <h1>Not Available</h1>
        <p>{message}</p>
        <Button variant="contained" color="primary" component={Link} to="/">
          Home
        </Button>
      </Grid>
    </Grid>
  </Container>
);

Error410.propTypes = {
  message: PropTypes.string,
};

Error410.defaultProps = {
  message: "Item is not available",
};

export default Error410;
