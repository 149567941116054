import { useAuth0 } from "@auth0/auth0-react";
import { Typography } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { SubmissionError } from "redux-form";
import { Link } from "react-router-dom";
import { includeTenantParam } from "@certane/arcadia-web-components";
import { getReferenceDataDescription } from "../../reducers";
import dates from "../../util/dates";
import {
  addIcon,
  deleteIcon,
  helpIcon,
  moreVertIcon,
  openInNewIcon,
} from "../../util/icons";
import GridListing from "../common/GridListing";
import InlineDatePicker from "../common/InlineDatePicker";
import TimeAgo from "../common/TimeAgo";
import ExpandableForm from "../forms/ExpandableForm";
import AddDocumentForm, {
  ADD_DOCUMENT_FORM_NAME,
} from "../forms/metropolis/AddDocumentForm";

const useStyles = makeStyles((theme) => ({
  menuText: {
    marginLeft: theme.spacing(1),
  },
  disabled: {
    color: theme.palette.text.disabled,
    width: "100%",
  },
}));

const MediaDocumentVersionsCard = ({
  className,
  media,
  fieldLabels,
  getStatusDescription,
  onAddDocument,
  onPatchDocument,
  onDeleteDocument,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();
  const [sortBy, setSortBy] = useState({
    field: "activeFrom",
    direction: "desc",
  });
  const [formOpen, setFormOpen] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [menuVersion, setMenuVersion] = useState(null);
  const OpenInNewIcon = openInNewIcon();
  const AddIcon = addIcon();
  const MoreVertIcon = moreVertIcon();
  const DeleteIcon = deleteIcon();
  const disableDelete = Object.is(media.documentVersions.length, undefined)
    ? false
    : media.documentVersions.length === 1;

  const handleAddDocument = (newDocument) =>
    onAddDocument(newDocument)
      .then(() => {
        setFormOpen(false);
      })
      .catch((error) => {
        throw new SubmissionError({ _error: error.message });
      });

  const inUseFutureDates = media.documentVersions
    .filter((v) => v.status === "FUTURE")
    .map((v) => v.scheduledActivationDate);

  const openMenu = (version) => (evt) => {
    setMenuAnchor(evt.currentTarget);
    setMenuVersion(version);
  };

  const closeMenu = () => {
    setMenuAnchor(null);
    setMenuVersion(null);
  };

  const getActions = (version) => {
    if (version.status === "FUTURE" && onDeleteDocument) {
      return (
        <IconButton onClick={openMenu(version)}>
          <MoreVertIcon />
        </IconButton>
      );
    }
    return (
      <IconButton onClick={() => window.open(version.versionedServingUrl)}>
        <OpenInNewIcon />
      </IconButton>
    );
  };

  const HelpIcon = helpIcon();

  return (
    <Card className={className} elevation={0}>
      <CardHeader
        data-cy={fieldLabels.labels.documentVersions}
        title={fieldLabels.labels.documentVersions}
        action={
          !!onAddDocument && (
            <Tooltip title="Add document" disableFocusListener>
              <div>
                <IconButton
                  onClick={() => setFormOpen(true)}
                  disabled={formOpen}
                  data-cy="formOpen"
                >
                  <AddIcon />
                </IconButton>
              </div>
            </Tooltip>
          )
        }
      />
      <ExpandableForm
        title="New document"
        submitButtonText="Submit"
        maxWidth="md"
        formComponent={AddDocumentForm}
        formName={ADD_DOCUMENT_FORM_NAME}
        open={formOpen}
        onCancel={() => setFormOpen(false)}
        onSubmit={handleAddDocument}
        fieldLabels={fieldLabels}
        disabledFutureDates={inUseFutureDates}
        data-cy={fieldLabels}
        getAccessToken={getAccessTokenSilently}
      />
      <Menu
        id="version-menu"
        anchorEl={menuAnchor}
        keepMounted
        open={!!menuAnchor}
        onClose={closeMenu}
        data-cy="versionMenu"
      >
        <MenuItem
          onClick={() => {
            window.open(menuVersion.versionedServingUrl);
            closeMenu();
          }}
        >
          <OpenInNewIcon color="action" />
          <ListItemText className={classes.menuText} data-cy="open">
            Open
          </ListItemText>
        </MenuItem>
        <MenuItem
          onClick={
            disableDelete
              ? null
              : () => {
                  onDeleteDocument(menuVersion.id);
                  closeMenu();
                }
          }
        >
          <DeleteIcon color={!disableDelete ? "action" : "disabled"} />
          {!disableDelete ? (
            <>
              <ListItemText className={classes.menuText} data-cy="delete">
                Delete
              </ListItemText>
            </>
          ) : (
            <>
              <span className={classes.disabled}>Delete</span>
              <Tooltip
                title="Delete is not allowed for the only version"
                disableFocusListener
                style={{ marginLeft: ".75rem" }}
              >
                <HelpIcon color="disabled" />
              </Tooltip>
            </>
          )}
        </MenuItem>
      </Menu>

      <CardContent>
        <GridListing
          sortedData={_.orderBy(
            media.documentVersions,
            sortBy.field,
            sortBy.direction
          )}
          dense={false}
          sortBy={sortBy}
          loading={false}
          updateSort={(field, direction) =>
            setSortBy({
              field,
              direction,
            })
          }
          action={(v) => getActions(v)}
          columns={[
            {
              label: fieldLabels.nestedTypes.documentVersions.labels.status,
              name: "status",
              size: 1,
              sortable: true,
              render: (v) => getStatusDescription(v.status),
            },
            {
              label: fieldLabels.nestedTypes.documentVersions.labels.createdBy,
              name: "createdBy",
              size: 3,
              sortable: false,
              render: (v) => (
                <>
                  <Typography variant="subtitle1">
                    {v.createdBy ? v.createdBy.name : v.document.createdByName}
                  </Typography>
                  <Typography variant="caption">
                    <TimeAgo
                      value={dates.parseTimestamp(v.document.uploaded)}
                      expandable
                      startFull
                    />
                  </Typography>
                </>
              ),
            },
            {
              label: fieldLabels.nestedTypes.documentVersions.labels.reference,
              name: "reference",
              size: 2,
              sortable: true,
              render: (v) =>
                v.reference ? (
                  <Link
                    to={includeTenantParam(`/s/${v.reference}`)}
                    target="_blank"
                  >
                    {v.reference}
                  </Link>
                ) : (
                  ""
                ),
            },
            {
              label: fieldLabels.nestedTypes.documentVersions.labels.activeFrom,
              name: "activeFrom",
              size: 2,
              sortable: true,
              render: (v) => {
                if (v.status === "FUTURE") {
                  return (
                    <InlineDatePicker
                      value={v.scheduledActivationDate}
                      storeDatesAsString
                      onChange={(newDate) =>
                        onPatchDocument(v.id, {
                          scheduledActivationDate: newDate,
                        })
                      }
                      readonly={!onPatchDocument}
                      readOnlyView={
                        <span>
                          ETA:{" "}
                          {dates.formatDate(
                            v.scheduledActivationDate,
                            "D/M/YYYY"
                          )}
                        </span>
                      }
                      shouldDisableDate={(date) => {
                        if (
                          moment(date).isSame(moment(v.scheduledActivationDate))
                        ) {
                          return false;
                        }
                        return inUseFutureDates.some((d) =>
                          moment(d).isSame(moment(date), "day")
                        );
                      }}
                      minDate={moment().add(1, "day").startOf("day")}
                      validate={(date) => !!date}
                    />
                  );
                }
                if (v.activeFrom) {
                  return (
                    <TimeAgo
                      value={dates.parseTimestamp(v.activeFrom)}
                      expandable
                      startFull
                    />
                  );
                }
                return "-";
              },
            },
            {
              label:
                fieldLabels.nestedTypes.documentVersions.labels.effectiveFrom,
              name: "effectiveFrom",
              size: 2,
              sortable: true,
              render: (v) => (
                <InlineDatePicker
                  value={v.effectiveFrom}
                  storeDatesAsString
                  onChange={(newDate) =>
                    onPatchDocument(v.id, { effectiveFrom: newDate })
                  }
                  readonly={!onPatchDocument}
                  readOnlyView={
                    v.effectiveFrom
                      ? dates.formatDate(v.effectiveFrom, "D/M/YYYY")
                      : "-"
                  }
                  data-cy="date"
                />
              ),
            },
            {
              label: fieldLabels.nestedTypes.documentVersions.labels.reviewBy,
              name: "reviewBy",
              size: 2,
              sortable: true,
              render: (v) => {
                const canEditReviewBy =
                  onPatchDocument && v.status !== "SUPERSEDED";
                const baseDate =
                  v.status === "FUTURE"
                    ? moment(v.scheduledActivationDate)
                    : moment();
                return (
                  <InlineDatePicker
                    value={v.reviewBy}
                    storeDatesAsString
                    onChange={(newDate) =>
                      onPatchDocument(v.id, { reviewBy: newDate })
                    }
                    readonly={!canEditReviewBy}
                    readOnlyView={
                      v.reviewBy
                        ? dates.formatDate(v.reviewBy, "D/M/YYYY")
                        : "-"
                    }
                    minDate={baseDate.add(1, "day").startOf("day")}
                    data-cy="date"
                  />
                );
              },
            },
          ]}
        />
      </CardContent>
    </Card>
  );
};

MediaDocumentVersionsCard.propTypes = {
  className: PropTypes.string,
  media: PropTypes.object.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  onAddDocument: PropTypes.func,
  onPatchDocument: PropTypes.func,
  onDeleteDocument: PropTypes.func,
  getStatusDescription: PropTypes.func.isRequired,
};

MediaDocumentVersionsCard.defaultProps = {
  className: null,
  onAddDocument: null,
  onPatchDocument: null,
  onDeleteDocument: null,
};

const mapStateToProps = (state) => ({
  getStatusDescription: (status) =>
    getReferenceDataDescription(state, "ContentVersionStatus", status),
});

export default connect(mapStateToProps)(MediaDocumentVersionsCard);
