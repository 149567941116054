import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import React from "react";
import PropTypes from "prop-types";
import ApexChart from "react-apexcharts";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  title: {
    marginTop: 10,
    marginBottom: 20,
  },
});

const Chart = ({ classes, chartConfig, title }) => {
  if (!chartConfig) {
    return null;
  }

  return (
    <Card className={classes.root} elevation={0}>
      <CardHeader title={title} className={classes.title} />
      <ApexChart {...chartConfig} />
    </Card>
  );
};

Chart.propTypes = {
  classes: PropTypes.object.isRequired,
  chartConfig: PropTypes.object.isRequired,
  title: PropTypes.string,
};

Chart.defaultProps = {
  title: null,
};

export default withStyles(styles)(Chart);
