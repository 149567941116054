import { useEffect, useState } from "react";
import LocalStorageItem from "./LocalStorageItem";

function usePersistedState(key, defaultValue, ignoreKeys) {
  const [mounted, setMounted] = useState(false);
  const [persistedState, setPersistedState] = useState(
    new LocalStorageItem(`tp-portal-${key}`, defaultValue, ignoreKeys)
  );
  const [value, setValue] = useState(persistedState.get());

  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
    };
  }, []);

  // react to changes in the key
  useEffect(() => {
    if (mounted) {
      const storage = new LocalStorageItem(
        `tp-portal-${key}`,
        defaultValue,
        []
      );
      setPersistedState(storage);
      setValue({ ...storage.get() });
    }
  }, [key]);

  // react to changes in the value
  useEffect(() => {
    if (mounted) {
      persistedState.update(value);
    }
  }, [value]);

  return [value, setValue];
}

export default usePersistedState;
