import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  layout: {
    display: "flex",
    flexDirection: "column",
  },
  main: {
    display: "flex",
    flex: "1 1",
  },
  appBar: {
    backgroundColor: theme.palette.common.black,
    boxShadow: "none",
  },
  toolbar: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  appLogo: {
    maxHeight: 42,
    marginLeft: 4,
  },
  title: {
    flex: 1,
  },
});

const StandaloneLayout = ({ classes, children }) => (
  <div className={classes.layout}>
    <AppBar position="sticky" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <img
          className={classes.appLogo}
          src="https://cdn.diversa.com.au/assets/logos/logo-white.png"
          alt=""
          data-cy="certane"
        />
        <Typography
          variant="h6"
          color="inherit"
          className={classes.title}
          data-cy="layout"
        />
      </Toolbar>
    </AppBar>

    <div className={classes.main}>{children}</div>
  </div>
);

StandaloneLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default withStyles(styles)(StandaloneLayout);
