import { useAuth0 } from "@auth0/auth0-react";
import { roles } from "@certane/arcadia-web-components";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field } from "redux-form";
import { required } from "redux-form-validators";
import { getLoggedInUser, getReferenceDataType } from "../../../reducers";
import usersApi from "../../../services/api/users";
import { getRefDataMenuItems } from "../menuItems";
import WrappedOwnerPicker from "../wrapper/WrappedOwnerPicker";
import WrappedSelect from "../wrapper/WrappedSelect";
import WrappedTextField from "../wrapper/WrappedTextField";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  tolerance: {
    border: `solid 1px ${theme.palette.grey[300]}`,
  },
  toleranceForm: {
    marginRight: "20px",
  },
});

const fieldLabels = {
  description: "Description",
  status: "Status",
  owner: "Owner",
  comment: "Comment",
};

const OperationalToleranceFields = ({
  fields,
  classes,
  operationalToleranceStatuses,
  loggedInUser,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const add = () => {
    fields.push({});
  };

  const remove = (index) => {
    fields.remove(index);
  };

  const asyncOwnerOptionsFetch = async (
    pickerFilter,
    pagination,
    abortController
  ) => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = {
      ...pickerFilter,
      limit: pagination.pageSize,
      offset: pagination.offset,
      roles: [roles.COMPLIANCE_OWNER, roles.COMPLIANCE_OFFICER],
    };
    return usersApi.search(searchParameters, abortController, accessToken);
  };

  return (
    <>
      {fields.map((operationalTolerance, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <List key={index} className={classes.root}>
          <ListItem className={classes.tolerance}>
            <ListItemText className={classes.toleranceForm}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Field
                    component={WrappedTextField}
                    name={`${operationalTolerance}.description`}
                    label={fieldLabels.description}
                    validate={required({
                      msg: `${fieldLabels.description} is required`,
                    })}
                    fullWidth
                    required
                    data-cy={fieldLabels.description}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name={`${operationalTolerance}.status`}
                    component={WrappedSelect}
                    label={fieldLabels.status}
                    fullWidth
                    data-cy={fieldLabels.status}
                  >
                    {getRefDataMenuItems(operationalToleranceStatuses)}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name={`${operationalTolerance}.owner`}
                    component={WrappedOwnerPicker}
                    datasource={asyncOwnerOptionsFetch}
                    required
                    validate={required({
                      msg: `${fieldLabels.owner} is required`,
                    })}
                    label={fieldLabels.owner}
                    data-cy={fieldLabels.owner}
                    fullWidth
                    loggedInUser={loggedInUser}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name={`${operationalTolerance}.comment`}
                    component={WrappedTextField}
                    label={fieldLabels.comment}
                    fullWidth
                    multiline
                    rowsMax={10}
                    rows={2}
                    data-cy={fieldLabels.comment}
                  />
                </Grid>
              </Grid>
            </ListItemText>
            <ListItemSecondaryAction>
              <IconButton
                aria-label="Remove"
                onClick={() => remove(index)}
                title="Remove"
                data-cy="delete"
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      ))}
      <Button
        size="small"
        color="primary"
        aria-label="Add"
        onClick={add}
        data-cy="addOperationalTolerance"
      >
        Add operational tolerance
      </Button>
    </>
  );
};

OperationalToleranceFields.propTypes = {
  fields: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  loggedInUser: PropTypes.object.isRequired,
  operationalToleranceStatuses: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
  operationalToleranceStatuses: getReferenceDataType(
    state,
    "OperationalToleranceStatus"
  ),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(OperationalToleranceFields);
