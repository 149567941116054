import { requestJSON, requestText } from "./http";

const list = (digitalAssetId, limit, accessToken) => {
  const query = limit ? `?limit=${limit}` : "";
  return digitalAssetId
    ? requestJSON({
        path: `/sentinel/digital-asset/${digitalAssetId}/snapshot${query}`,
        accessToken,
      })
    : requestJSON({
        path: `/sentinel/snapshot${query}`,
        accessToken,
      });
};

const createSnapshot = (digitalAssetId, accessToken) =>
  requestJSON({
    path: `/sentinel/digital-asset/${digitalAssetId}/snapshot`,
    method: "POST",
    accessToken,
  });

const getById = (websiteSnapshotId, accessToken) =>
  requestJSON({
    path: `/sentinel/snapshot/${websiteSnapshotId}`,
    accessToken,
  });

const getScreenshotUrl = (websiteSnapshotId, accessToken) =>
  requestText({
    path: `/sentinel/snapshot/${websiteSnapshotId}/screenshot`,
    accessToken,
  });

const getText = (websiteSnapshotId, accessToken) =>
  requestText({
    path: `/sentinel/snapshot/${websiteSnapshotId}/text`,
    accessToken,
  });

const getContent = (websiteSnapshotId, accessToken) =>
  requestText({
    path: `/sentinel/snapshot/${websiteSnapshotId}/content`,
    accessToken,
  });

export default {
  createSnapshot,
  list,
  getById,
  getScreenshotUrl,
  getText,
  getContent,
};
