import { useAuth0 } from "@auth0/auth0-react";
import { useTenant } from "@certane/arcadia-web-components";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import MailIcon from "@material-ui/icons/Mail";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import tenantsApi from "../../../services/api/tenants";
import { patchWorkItem } from "../../../actions/workItems";
import {
  getLoggedInUser,
  getReferenceDataType,
  getWorkItem,
  isLoading,
} from "../../../reducers";
import {
  auditReportPath,
  controlPath,
  workItemSchedulePath,
} from "../../../routes/routeUtils";
import { publishToastInfo } from "../../../services/toasts";
import {
  auditIcon,
  riskControlIcon,
  workItemIcon,
  workItemScheduleIcon,
} from "../../../util/icons";
import { getResolvedDefinition } from "../../../util/workItemTypeUtils";
import HeaderBar from "../../common/HeaderBar";
import PageSubheading from "../../common/PageSubheading";
import UnifiedHeading from "../../common/UnifiedHeading";
import ActionButton from "../action/ActionButton";
import WorkItemBreadcrumb from "./WorkItemBreadcrumb";

const styles = (theme) => ({
  outcome: {
    color: "#666",
  },
  iconTextContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 4,
  },
  iconPreText: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
    fontSize: 20,
  },
  breadcrumbLeader: {
    display: "flex",
    alignItems: "center",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "flex-end",
    },
  },
  headerActions: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  actions: {},
  actionButton: {
    minHeight: "48px",
    minWidth: "134px",
  },
  copyButton: {
    width: 24,
    height: 24,
    padding: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  copyIcon: {
    fontSize: 14,
  },
});

const WorkItemHeaderBar = ({
  classes,
  loggedInUser,
  workItem,
  types,
  localPatchWorkItem,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { getActiveTenantId } = useTenant();
  const [emailAddress, setEmailAddress] = useState();
  const definition = getResolvedDefinition(
    types,
    workItem.type,
    workItem.parent?.type
  );

  if (!workItem) return <div />;

  useEffect(() => {
    const tenantId = getActiveTenantId();
    (async () => {
      const accessToken = await getAccessTokenSilently();
      const response = await tenantsApi.get(tenantId, accessToken);
      const workItemEmail = `${workItem.friendlyId}@${response.data.emailDomain}`;
      setEmailAddress(workItemEmail);
    })();
  }, [getActiveTenantId]);

  const { name: typeDisplay } = definition;
  const isTemplate = workItem.template;

  const AuditReportIcon = auditIcon();
  const ControlIcon = riskControlIcon();

  const onCopy = () => {
    publishToastInfo("Copied to clipboard");
  };

  const onTitleChange = async (title) => {
    const accessToken = await getAccessTokenSilently();
    localPatchWorkItem(
      workItem.id,
      { title },
      `Edited ${typeDisplay}`,
      accessToken
    );
  };

  return (
    <HeaderBar>
      <div className={classes.headerContainer}>
        <div>
          <WorkItemBreadcrumb workItem={workItem} isTemplate={isTemplate} />
          {isTemplate && (
            <UnifiedHeading
              icon={workItemScheduleIcon()}
              heading={
                <span data-cy={workItem.friendlyId}>
                  {typeDisplay} template
                </span>
              }
              subHeading={workItem.title}
              workItem={workItem}
              readonly={
                loggedInUser.externalUser || workItem.status === "CLOSED"
              }
              onTitleChange={onTitleChange}
              outcome={workItem.outcome}
            />
          )}
          {!isTemplate && (
            <>
              <UnifiedHeading
                icon={workItemIcon(workItem.type)}
                heading={
                  <span data-cy={workItem.friendlyId}>
                    {typeDisplay.toUpperCase()}: {workItem.friendlyId}
                    <Tooltip title="Copy work item email" disableFocusListener>
                      <CopyToClipboard text={emailAddress} onCopy={onCopy}>
                        <IconButton
                          className={classes.copyButton}
                          data-cy="icon"
                        >
                          <MailIcon
                            className={classes.copyIcon}
                            data-cy="mailIcon"
                          />
                        </IconButton>
                      </CopyToClipboard>
                    </Tooltip>
                  </span>
                }
                subHeading={workItem.title}
                workItem={workItem}
                readonly={
                  loggedInUser.externalUser || workItem.status === "CLOSED"
                }
                onTitleChange={onTitleChange}
                outcome={workItem.outcome}
              />
              {workItem.workItemSchedule && (
                <div className={classes.iconTextContainer}>
                  <Icon className={classes.iconPreText}>repeat</Icon>
                  <PageSubheading
                    subheading={
                      <>
                        This task recurs on a{" "}
                        <span>
                          {workItem.workItemSchedule.accessible ? (
                            <Link
                              to={workItemSchedulePath(
                                workItem.workItemSchedule.id
                              )}
                            >
                              {workItem.workItemSchedule.type_display} schedule
                            </Link>
                          ) : (
                            <span>
                              {workItem.workItemSchedule.type_display} schedule
                            </span>
                          )}
                        </span>
                      </>
                    }
                  />
                </div>
              )}
              {workItem.auditReport && !loggedInUser.externalUser && (
                <div className={classes.iconTextContainer}>
                  <AuditReportIcon className={classes.iconPreText} />
                  <PageSubheading
                    subheading={
                      <>
                        This is a finding of Audit:{" "}
                        <span>
                          {workItem.auditReport.accessible ? (
                            <Link to={auditReportPath(workItem.auditReport.id)}>
                              {workItem.auditReport.title}
                            </Link>
                          ) : (
                            workItem.auditReport.title
                          )}
                        </span>
                      </>
                    }
                  />
                </div>
              )}
            </>
          )}
          {workItem.associatedControl && (
            <div className={classes.iconTextContainer}>
              <ControlIcon className={classes.iconPreText} />
              <PageSubheading
                subheading={
                  <>
                    This is a verification of Control:{" "}
                    <span>
                      {workItem.associatedControl.accessible ? (
                        <Link to={controlPath(workItem.associatedControl.id)}>
                          {workItem.associatedControl.title}
                        </Link>
                      ) : (
                        workItem.associatedControl.title
                      )}
                    </span>
                  </>
                }
              />
            </div>
          )}
        </div>
        <div className={classes.headerActions}>
          {!isTemplate && <ActionButton workItem={workItem} />}
        </div>
      </div>
    </HeaderBar>
  );
};

WorkItemHeaderBar.propTypes = {
  classes: PropTypes.object.isRequired,
  loggedInUser: PropTypes.object,
  workItem: PropTypes.object,
  types: PropTypes.object.isRequired,
  localPatchWorkItem: PropTypes.func.isRequired,
};

WorkItemHeaderBar.defaultProps = {
  workItem: undefined,
  loggedInUser: null,
};

const mapStateToProps = (state) => ({
  loading: isLoading(state),
  loggedInUser: getLoggedInUser(state),
  workItem: getWorkItem(state),
  types: getReferenceDataType(state, "WorkItemType"),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    localPatchWorkItem: patchWorkItem,
  })
)(WorkItemHeaderBar);
