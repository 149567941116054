import { useAuth0 } from "@auth0/auth0-react";
import { Card } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { clearActiveSnippet, createSnippet } from "../../../actions/snippets";
import BreadcrumbLink from "../../../components/common/BreadcrumbLink";
import Container from "../../../components/common/Container";
import HeaderBar from "../../../components/common/HeaderBar";
import PageHeading from "../../../components/common/PageHeading";
import { getEntityRelationshipInitialValues } from "../../../components/forms/business/EntityRelationshipFields";
import FormWrapper from "../../../components/forms/FormWrapper";
import SnippetForm, {
  SNIPPET_FORM_NAME,
} from "../../../components/forms/metropolis/SnippetForm";
import CreateSnippetSuccess from "../../../components/metropolis/CreateSnippetSuccess";
import { getActiveSnippet, getLoggedInUser } from "../../../reducers";
import logger from "../../../util/logger";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  contents: {
    marginTop: theme.spacing(2),
    marginBottom: "75px",
  },
}));

const New = ({
  loggedInUser,
  localCreateSnippet,
  localClearActiveSnippet,
  createdSnippet,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    localClearActiveSnippet();

    getEntityRelationshipInitialValues(loggedInUser).then(
      (entityRelationship) => {
        logger.debug(
          "async setting initial values to (entityRelationship) ",
          entityRelationship
        );
        setInitialValues({
          entityRelationship,
        });
      }
    );
  }, []);

  const handleSubmission = async (values) => {
    logger.debug("submitting snippet with values", values);
    const accessToken = await getAccessTokenSilently();
    return localCreateSnippet(values, accessToken);
  };

  return (
    <div className={classes.root}>
      <HeaderBar narrow>
        <BreadcrumbLink to="/metropolis" label="Content" includeArrow />
        <PageHeading heading="Create new snippet" />
      </HeaderBar>
      <Container narrow className={classes.contents}>
        {createdSnippet && (
          <CreateSnippetSuccess
            originLink="/metropolis"
            originLinkLabel="Return to content"
            itemLink={`/metropolis/snippet/${createdSnippet.id}`}
            servingUrl={createdSnippet.apiContentEndpoint}
          />
        )}
        {!createdSnippet && (
          <Card>
            <CardContent>
              {initialValues && (
                <FormWrapper
                  submitButtonText="Create"
                  formComponent={SnippetForm}
                  formName={SNIPPET_FORM_NAME}
                  onSubmit={handleSubmission}
                  initialValues={initialValues}
                  getAccessToken={getAccessTokenSilently}
                />
              )}
            </CardContent>
          </Card>
        )}
      </Container>
    </div>
  );
};

New.propTypes = {
  loggedInUser: PropTypes.object,
  createdSnippet: PropTypes.object,
  localCreateSnippet: PropTypes.func.isRequired,
  localClearActiveSnippet: PropTypes.func.isRequired,
};

New.defaultProps = {
  loggedInUser: null,
  createdSnippet: null,
};

const mapStateToProps = (state) => ({
  createdSnippet: getActiveSnippet(state),
  loggedInUser: getLoggedInUser(state),
});

export default connect(mapStateToProps, {
  localCreateSnippet: createSnippet,
  localClearActiveSnippet: clearActiveSnippet,
})(New);
