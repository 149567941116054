import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector } from "redux-form";
import { required } from "redux-form-validators";
import { getReferenceDataOptions } from "../../../../reducers";
import { yesNoOptions } from "../../../../util/radioOptions";
import WrappedDatePicker from "../../../forms/wrapper/WrappedDatePicker";
import WrappedRadioGroup from "../../../forms/wrapper/WrappedRadioGroup";
import WrappedSelect from "../../../forms/wrapper/WrappedSelect";
import WrappedTextField from "../../../forms/wrapper/WrappedTextField";

class IncidentRecurrenceDetailsFields extends Component {
  componentDidUpdate(prevProps) {
    const { preventable, recurringIncident } = this.props;

    if (preventable !== prevProps.preventable) {
      this.props.change("incident.reasonForIneffectiveness", null);
      this.props.untouch("incident.reasonForIneffectiveness", null);
    }

    if (recurringIncident !== prevProps.recurringIncident) {
      this.props.change("incident.previousIncidentReference", null);
      this.props.change("incident.previousIncidentDate", null);
      this.props.untouch(
        "incident.previousIncidentReference",
        "incident.previousIncidentDate"
      );
    }
  }

  render() {
    const {
      fieldLabels,
      incidentIdentifierOptions,
      recurringIncident,
      preventable,
    } = this.props;

    return (
      <>
        <Field
          name="incident.preventable"
          component={WrappedRadioGroup}
          row
          label={fieldLabels.nestedTypes.incident.labels.preventable}
          helperText="Were there controls in place that could have prevented this incident?"
          options={yesNoOptions}
          nullLabel={"Unknown"}
          data-cy={fieldLabels.nestedTypes.incident.labels.preventable}
        />
        {preventable && (
          <Field
            component={WrappedTextField}
            name="incident.reasonForIneffectiveness"
            label={
              fieldLabels.nestedTypes.incident.labels.reasonForIneffectiveness
            }
            helperText="What were they and why were they ineffective?"
            fullWidth
            multiline
            rowsMax={10}
            rows={2}
            data-cy={
              fieldLabels.nestedTypes.incident.labels.reasonForIneffectiveness
            }
          />
        )}

        <Field
          component={WrappedSelect}
          name="incident.identifier"
          label={fieldLabels.nestedTypes.incident.labels.identifier}
          validate={required({
            msg: `${fieldLabels.nestedTypes.incident.labels.identifier} is required`,
          })}
          helperText="How was the incident identified?"
          required
          fullWidth
          data-cy={fieldLabels.nestedTypes.incident.labels.identifier}
        >
          {incidentIdentifierOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Field>
        <Field
          name="incident.recurrence"
          component={WrappedRadioGroup}
          row
          label={fieldLabels.nestedTypes.incident.labels.recurrence}
          helperText="Is this a recurrence of a previous incident"
          options={yesNoOptions}
          nullLabel={"Unknown"}
          data-cy={fieldLabels.nestedTypes.incident.labels.recurrence}
        />

        {recurringIncident && (
          <>
            <Field
              name="incident.previousIncidentReference"
              component={WrappedTextField}
              label={
                fieldLabels.nestedTypes.incident.labels
                  .previousIncidentReference
              }
              validate={required({
                msg: `${fieldLabels.nestedTypes.incident.labels.previousIncidentReference} is required`,
              })}
              required
              fullWidth
              data-cy={
                fieldLabels.nestedTypes.incident.labels
                  .previousIncidentReference
              }
            />
            <Field
              component={WrappedDatePicker}
              name="incident.previousIncidentDate"
              label={
                fieldLabels.nestedTypes.incident.labels.previousIncidentDate
              }
              validate={required({
                msg: `${fieldLabels.nestedTypes.incident.labels.previousIncidentDate} is required`,
              })}
              disableFuture
              required
              fullWidth
              data-cy={
                fieldLabels.nestedTypes.incident.labels.previousIncidentDate
              }
            />
          </>
        )}
      </>
    );
  }
}

IncidentRecurrenceDetailsFields.propTypes = {
  fieldLabels: PropTypes.object.isRequired,
  incidentIdentifierOptions: PropTypes.array.isRequired,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  formName: PropTypes.string.isRequired,
  // form values
  recurringIncident: PropTypes.bool,
  preventable: PropTypes.bool,
};

IncidentRecurrenceDetailsFields.defaultProps = {
  recurringIncident: false,
  preventable: false,
};

const mapStateToProps = (state, ownProps) => {
  const reduxFormSelector = formValueSelector(ownProps.formName);
  return {
    preventable: reduxFormSelector(state, "incident.preventable"),
    recurringIncident: reduxFormSelector(state, "incident.recurrence"),
    incidentIdentifierOptions: getReferenceDataOptions(
      state,
      "IncidentIdentifier"
    ),
  };
};

export default connect(mapStateToProps)(IncidentRecurrenceDetailsFields);
