import { combineReducers } from "redux";
import { publishActionToast } from "../services/toasts";
import {
  DIGITAL_ASSETS_CREATE,
  DIGITAL_ASSETS_GET_BY_ID,
  DIGITAL_ASSETS_CLEAR_ACTIVE,
  DIGITAL_ASSETS_SEARCH,
  DIGITAL_ASSETS_DELETE,
  DIGITAL_ASSETS_PATCH,
} from "../actions/digitalAssets";

const successToasts = {};

const byId = (state = {}, action) => {
  publishActionToast(action, "DIGITAL_ASSET_", successToasts);

  switch (action.type) {
    case `${DIGITAL_ASSETS_SEARCH}_SUCCESS`:
    case `${DIGITAL_ASSETS_CREATE}_SUCCESS`:
    case `${DIGITAL_ASSETS_PATCH}_SUCCESS`:
    case `${DIGITAL_ASSETS_GET_BY_ID}_SUCCESS`:
      return {
        ...state,
        ...action.response.entities.digitalAsset,
      };

    case `${DIGITAL_ASSETS_DELETE}_SUCCESS`: {
      const idToRemove = action.response.id;
      return {
        ...state,
        [idToRemove]: undefined,
      };
    }

    default:
      break;
  }

  return state;
};

const defaultActive = {
  activeList: [],
  activeDigitalAsset: null,
  pagination: {
    offset: 0,
    pageSize: 50,
    resultCount: 0,
  },
};

const active = (state = defaultActive, action) => {
  switch (action.type) {
    case DIGITAL_ASSETS_CLEAR_ACTIVE:
      return {
        ...defaultActive,
      };

    case `${DIGITAL_ASSETS_SEARCH}_SUCCESS`:
      return {
        ...state,
        activeList: [...action.response.result],
        pagination: {
          ...action.response.pagination,
          pageSize: defaultActive.pagination.pageSize,
        },
      };

    case `${DIGITAL_ASSETS_CREATE}_SUCCESS`:
    case `${DIGITAL_ASSETS_GET_BY_ID}_SUCCESS`:
      return {
        ...state,
        activeList:
          state.activeList.indexOf(action.response.result) === -1
            ? [...state.activeList, action.response.result]
            : state.activeList,
        activeDigitalAsset: action.response.result,
      };

    // delete (remove active and dashboard)
    case `${DIGITAL_ASSETS_DELETE}_SUCCESS`: {
      const idToRemove = action.response.id;
      return {
        ...state,
        activeDigitalAsset:
          state.activeDigitalAsset === idToRemove
            ? null
            : state.activeDigitalAsset,
        activeList: [...state.activeList.filter((id) => id !== idToRemove)],
      };
    }

    default:
      break;
  }

  return state;
};

export default combineReducers({
  active,
  byId,
});

export const getDigitalAssets = (state) =>
  state.active.activeList.map((id) => state.byId[id]);
export const getActiveDigitalAsset = (state) =>
  state.active.activeDigitalAsset
    ? state.byId[state.active.activeDigitalAsset]
    : null;
export const getPagination = (state) => state.active.pagination;
