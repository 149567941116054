import { useAuth0 } from "@auth0/auth0-react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import { loadWorkItems, WORK_ITEM_LIST } from "../../actions/workItems";
import {
  getActiveAuditReport,
  getReferenceDataType,
  getWorkItems,
  isLoadingAction,
} from "../../reducers";
import { getWorkItemLink } from "../../routes/routeUtils";
import WorkItemListItem from "../workitem/WorkItemListItem";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  buttonIcon: {
    marginLeft: theme.spacing(1),
  },
  fileUpload: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

const AuditReportFindingsCard = ({
  classes,
  loading,
  auditReport,
  workItems,
  workItemTypes,
  localLoadWorkItems,
  addAuditReportFindingAction,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    getAccessTokenSilently().then((accessToken) =>
      localLoadWorkItems(
        {
          auditId: auditReport.id,
          types: ["AUDIT_FINDING"],
          includeTemplates: false,
          includeChildren: false,
          limit: 100,
          offset: 0,
          orderByField: "-created",
        },
        accessToken
      )
    );
  }, [getAccessTokenSilently, auditReport?.id]);

  return (
    <Card className={classes.root} elevation={0}>
      <CardHeader
        title="Audit findings"
        action={
          addAuditReportFindingAction ? (
            <Tooltip title="Add audit finding" disableFocusListener>
              <div>
                <IconButton onClick={() => addAuditReportFindingAction()}>
                  <AddIcon />
                </IconButton>
              </div>
            </Tooltip>
          ) : null
        }
      />
      <CardContent>
        {workItems.length > 0 && !loading && (
          <List dense>
            {workItems.map((finding) => (
              <WorkItemListItem
                key={finding.id}
                workItem={finding}
                types={workItemTypes}
                linkTo={getWorkItemLink(finding)}
              />
            ))}
          </List>
        )}
        {workItems.length === 0 && !loading && (
          <CardContent>
            <em>No findings</em>
          </CardContent>
        )}
        {loading && (
          <CardContent>
            <em>Loading</em>
          </CardContent>
        )}
      </CardContent>
    </Card>
  );
};

AuditReportFindingsCard.propTypes = {
  classes: PropTypes.object.isRequired,
  addAuditReportFindingAction: PropTypes.func,
  // redux
  loading: PropTypes.bool.isRequired,
  auditReport: PropTypes.object.isRequired,
  workItems: PropTypes.array.isRequired,
  workItemTypes: PropTypes.object.isRequired,
  localLoadWorkItems: PropTypes.func.isRequired,
};

AuditReportFindingsCard.defaultProps = {
  addAuditReportFindingAction: null,
};

const mapStateToProps = (state) => ({
  loading: isLoadingAction(state, WORK_ITEM_LIST),
  auditReport: getActiveAuditReport(state),
  workItems: getWorkItems(state),
  workItemTypes: getReferenceDataType(state, "WorkItemType"),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    localLoadWorkItems: loadWorkItems,
  })
)(withRouter(AuditReportFindingsCard));
