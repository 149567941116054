import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    borderWidth: 0,
    borderTopWidth: 8,
    borderStyle: "solid",
  },
  cardUnassigned: {
    color: theme.palette.text.secondary,
    borderColor: theme.palette.secondary.main,
  },
  cardAssigned: {
    color: theme.palette.text.secondary,
    borderColor: theme.palette.secondary.dark,
  },
  cardNeedAction: {
    color: theme.palette.text.secondary,
    borderColor: theme.palette.warning.main,
  },
  cardOverdue: {
    color: theme.palette.text.secondary,
    borderColor: theme.palette.error.main,
  },
  // Added to retain same styles as older version of MUI
  "@media (min-width: 600px)": {
    cardContent: {
      paddingLeft: 24,
      paddingRight: 24,
    },
  },
  icon: {
    fontSize: "100px",
  },
}));

const metricConfig = {
  // WORK ITEM METRICS
  WORK_ITEM_CREATED_BY_ME_UNASSIGNED: {
    cardStyle: "cardUnassigned",
    icon: "inbox",
  },
  WORK_ITEM_CREATED_BY_ME_OPEN: {
    cardStyle: "cardAssigned",
    icon: "folder_open",
  },
  WORK_ITEM_CREATED_BY_ME_OVERDUE: {
    cardStyle: "cardOverdue",
    icon: "access_alarm",
  },
  WORK_ITEM_CREATED_BY_ME_REQUIRES_CLARIFICATION: {
    cardStyle: "cardNeedAction",
    icon: "question_answer",
  },
  WORK_ITEM_OWNED_BY_ME_OPEN: {
    cardStyle: "cardAssigned",
    icon: "folder_open",
  },
  WORK_ITEM_OWNED_BY_ME_OVERDUE: {
    cardStyle: "cardOverdue",
    icon: "access_alarm",
  },
  WORK_ITEM_GLOBAL_UNASSIGNED: {
    cardStyle: "cardUnassigned",
    icon: "inbox",
  },

  // CLIENT DASH METRICS
  CLIENT_DASH_DISCLOSURE_APPROVALS_UNASSIGNED: {
    cardStyle: "cardUnassigned",
    icon: "inbox",
  },
  CLIENT_DASH_CHANGE_APPROVALS_UNASSIGNED: {
    cardStyle: "cardUnassigned",
    icon: "inbox",
  },
  CLIENT_DASH_DISCLOSURE_APPROVALS_ALL_OPEN: {
    cardStyle: "cardAssigned",
    icon: "folder_open",
  },
  CLIENT_DASH_CHANGE_APPROVALS_ALL_OPEN: {
    cardStyle: "cardAssigned",
    icon: "folder_open",
  },
  CLIENT_DASH_DISCLOSURE_APPROVALS_OVERDUE: {
    cardStyle: "cardOverdue",
    icon: "access_alarm",
  },
  CLIENT_DASH_CHANGE_APPROVALS_OVERDUE: {
    cardStyle: "cardOverdue",
    icon: "access_alarm",
  },

  // RISK METRICS
  RISK_OWNED_BY_ME: {
    cardStyle: "cardAssigned",
    icon: "warning",
  },
  RISK_ACTION_OWNED_BY_ME: {
    cardStyle: "cardAssigned",
    icon: "assignment",
  },
  RISK_CONTROL_OWNED_BY_ME: {
    cardStyle: "cardAssigned",
    icon: "control_camera",
  },
  RISK_CONTROL_VERIFICATION_OWNED_BY_ME: {
    cardStyle: "cardAssigned",
    icon: "done_all",
  },
  RISK_REASSESSMENT_OWNED_BY_ME: {
    cardStyle: "cardAssigned",
    icon: "assessment",
  },
  RISK_MEASURE_UPDATE_OWNED_BY_ME: {
    cardStyle: "cardAssigned",
    icon: "straighten",
  },
};

const MetricCard = (props) => {
  const { className, title, onClick, value, id } = props;

  const classes = useStyles();

  if (!metricConfig[id]) {
    return null;
  }

  const { cardStyle, icon } = metricConfig[id];

  const mergedClassName = classNames({
    [classes.root]: true,
    [className]: true,
    [classes[cardStyle]]: true,
  });

  const valueElement =
    value === null || value === undefined ? (
      <CircularProgress />
    ) : (
      <Typography variant="h2">{value}</Typography>
    );

  return (
    <Card
      className={mergedClassName}
      elevation={0}
      onClick={() => onClick()}
      square
    >
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item md={4}>
            <Icon className={classes.icon} color="inherit" data-cy="icon">
              {icon}
            </Icon>
          </Grid>
          <Grid item md={8}>
            <Typography variant="h6" data-cy={title}>
              {title}
            </Typography>
            {valueElement}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

MetricCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  value: PropTypes.number,
  id: PropTypes.string.isRequired,
};

MetricCard.defaultProps = {
  className: undefined,
  value: null,
};

export default MetricCard;
