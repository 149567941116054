import React, { Component } from "react";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import { notesIcon } from "../../util/icons";
import CardIconTitle from "../common/CardIconTitle";
import MultilineText from "../common/MultilineText";
import EditTextDialog from "../common/EditTextDialog";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  description: {
    display: "flex",
    alignItems: "flex-start",
  },
  button: {
    padding: "5px",
    marginLeft: "10px",
    width: "30px",
    height: "30px",
  },
  text: {
    ...theme.typography.body1,
  },
  cardHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  cardContent: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
});

class WorkItemNotes extends Component {
  constructor(props) {
    super(props);

    this.state = { dialogOpen: false };
  }

  onUpdateNotes = (text) => {
    if (this.props.workItem && this.props.workItem.notes !== text) {
      this.props.onNotesChange(text);
    }
    this.setState({ dialogOpen: false });
  };

  onOpenMenu = () => {
    this.setState({ dialogOpen: true });
  };

  onCloseMenu = () => {
    this.setState({ dialogOpen: false });
  };

  render() {
    const { classes, readonly, workItem, contentName, fieldLabels } =
      this.props;

    const { dialogOpen } = this.state;

    return (
      <>
        <Card className={classes.root} elevation={0}>
          <CardHeader
            className={classes.cardHeader}
            title={
              <CardIconTitle
                title={fieldLabels.labels.notes}
                icon={notesIcon()}
                data-cy={fieldLabels.labels.notes}
              />
            }
            action={
              !readonly && (
                <Tooltip title="Edit" disableFocusListener data-cy="edit">
                  <IconButton onClick={this.onOpenMenu}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              )
            }
          />
          <CardContent className={classes.cardContent}>
            <EditTextDialog
              open={dialogOpen}
              onSubmit={this.onUpdateNotes}
              onClose={this.onCloseMenu}
              initialValue={workItem.notes}
              title={`Edit ${contentName}`}
              multiline
              data-cy={`Edit ${contentName}`}
            />

            <div className={classes.description}>
              <div className={classes.text}>
                <MultilineText
                  text={workItem.notes}
                  defaultText={`No ${contentName}`}
                />
              </div>
            </div>
          </CardContent>
        </Card>
      </>
    );
  }
}

WorkItemNotes.propTypes = {
  classes: PropTypes.object.isRequired,
  workItem: PropTypes.object.isRequired,
  readonly: PropTypes.bool.isRequired,
  onNotesChange: PropTypes.func.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  contentName: PropTypes.string,
};

WorkItemNotes.defaultProps = {
  contentName: "notes",
};

export default withStyles(styles)(WorkItemNotes);
