import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import ActionButton from "../../../components/common/ActionButton";
import ActionHeading from "../../../components/common/ActionHeading";
import Container from "../../../components/common/Container";
import HeaderBar from "../../../components/common/HeaderBar";
import PageHeading from "../../../components/common/PageHeading";
import RiskReassessmentFilters from "../../../components/risk/RiskReassessmentFilters";
import RiskReassessmentListing from "../../../components/risk/RiskReassessmentListing";
import { getLoggedInUser } from "../../../reducers";
import { clearIcon, workItemIcon } from "../../../util/icons";
import useLocationStateFilter from "../../../util/locationStateFilter";
import usePersistedState from "../../../util/persistedState";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  contents: {
    marginBottom: "75px",
  },
}));

const defaultFilter = {
  ownerIds: [],
  riskRegisters: [],
  subTypes: [],
};

const Reassessments = ({ history, loggedInUser }) => {
  const classes = useStyles();
  const [sortBy, setSortBy] = usePersistedState(
    `risk-reassessment-listing-sort-${loggedInUser.email}`,
    {
      field: "created",
      direction: "desc",
    }
  );
  const [filter, setFilter] = usePersistedState(
    `risk-reassessment-listing-filter-${loggedInUser.email}`,
    defaultFilter,
    ["textSearch"]
  );
  useLocationStateFilter(setFilter, history);

  const clearFilter = () => setFilter({ ...defaultFilter });

  const updateFilter = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  return (
    <div className={classes.root}>
      <HeaderBar fluid>
        <ActionHeading
          heading={
            <PageHeading
              icon={workItemIcon("RISK_REASSESSMENT")}
              heading="Risk reassessments"
              data-cy="riskReassessment"
            />
          }
          actions={[
            <ActionButton
              key="clearFilter"
              tooltip="Clear filters"
              icon={clearIcon()}
              onClick={clearFilter}
              data-cy="clear-filters-button"
            />,
          ]}
        />
      </HeaderBar>
      <RiskReassessmentFilters filter={filter} updateFilter={updateFilter} />
      <Container fluid className={classes.contents}>
        <RiskReassessmentListing
          history={history}
          filter={filter}
          clearFilter={clearFilter}
          sortBy={sortBy}
          updateSort={setSortBy}
          data-cy={history}
        />
      </Container>
    </div>
  );
};

Reassessments.propTypes = {
  history: PropTypes.object.isRequired,
  loggedInUser: PropTypes.object,
};

Reassessments.defaultProps = {
  loggedInUser: null,
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
});

export default connect(mapStateToProps)(Reassessments);
