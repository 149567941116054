import { useAuth0 } from "@auth0/auth0-react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { SubmissionError } from "redux-form";
import { Link } from "react-router-dom";
import { includeTenantParam } from "@certane/arcadia-web-components";
import { getReferenceDataDescription } from "../../reducers";
import dates from "../../util/dates";
import { addIcon } from "../../util/icons";
import CardSection from "../common/CardSection";
import GridListing from "../common/GridListing";
import TimeAgo from "../common/TimeAgo";
import ExpandableForm from "../forms/ExpandableForm";
import AddSnippetContentForm, {
  ADD_SNIPPET_CONTENT_FORM_NAME,
} from "../forms/metropolis/AddSnippetContentForm";
import SnippetSourceView from "./SnippetSourceView";

const SnippetVersionsCard = ({
  className,
  snippet,
  fieldLabels,
  onAddContent,
  getStatusDescription,
  getSnippetTypeDescription,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [activeVersion, setActiveVersion] = useState(
    snippet.contentVersions.find((v) => v.status === "ACTIVE")
  );
  const [sortBy, setSortBy] = useState({
    field: "activeFrom",
    direction: "desc",
  });
  const [selectedVersion, setSelectedVersion] = useState(activeVersion);
  const [formOpen, setFormOpen] = useState(false);

  useEffect(() => {
    const aVersion = snippet.contentVersions.find((v) => v.status === "ACTIVE");
    if (aVersion.id !== activeVersion.id) {
      setActiveVersion(aVersion);
      setSelectedVersion(aVersion);
    }
  }, [snippet]);

  const AddIcon = addIcon();

  const handleAddContent = (newContent) =>
    onAddContent(newContent)
      .then(() => {
        setFormOpen(false);
      })
      .catch((error) => {
        throw new SubmissionError({ _error: error.message });
      });

  return (
    <Card className={className} elevation={0}>
      <CardHeader
        title={fieldLabels.labels.contentVersions}
        action={
          !!onAddContent && (
            <Tooltip title="Add content" disableFocusListener>
              <div>
                <IconButton
                  onClick={() => setFormOpen(true)}
                  disabled={formOpen}
                >
                  <AddIcon />
                </IconButton>
              </div>
            </Tooltip>
          )
        }
      />
      <ExpandableForm
        title="New content"
        submitButtonText="Submit"
        maxWidth="md"
        formComponent={AddSnippetContentForm}
        formName={ADD_SNIPPET_CONTENT_FORM_NAME}
        open={formOpen}
        onCancel={() => setFormOpen(false)}
        onSubmit={handleAddContent}
        fieldLabels={fieldLabels}
        originalData={activeVersion.data}
        initialValues={{
          contentType: activeVersion.contentType,
          data: activeVersion.data,
        }}
        getAccessToken={getAccessTokenSilently}
      />
      <CardContent>
        <CardSection title="Preview" gutterBottom>
          <SnippetSourceView
            showTemplate
            renderVersion={selectedVersion}
            snippet={snippet}
            fixedHeight
            fieldLabels={fieldLabels}
          />
        </CardSection>
        <CardSection title="Versions" gutterBottom>
          <GridListing
            sortedData={_.orderBy(
              snippet.contentVersions,
              sortBy.field,
              sortBy.direction
            )}
            dense={false}
            sortBy={sortBy}
            loading={false}
            updateSort={(field, direction) => setSortBy({ field, direction })}
            selection={[selectedVersion.id]}
            handleSelect={(id) =>
              setSelectedVersion(
                snippet.contentVersions.find((v) => v.id === id)
              )
            }
            columns={[
              {
                label: fieldLabels.nestedTypes.contentVersions.labels.status,
                name: "status",
                size: 1,
                sortable: true,
                render: (v) => getStatusDescription(v.status),
              },
              {
                label: fieldLabels.nestedTypes.contentVersions.labels.createdBy,
                name: "createdBy",
                size: 3,
                sortable: false,
                render: (v) =>
                  v.createdBy ? v.createdBy.name : v.document.createdByName,
              },
              {
                label:
                  fieldLabels.nestedTypes.contentVersions.labels.contentType,
                name: "contentType",
                size: 2,
                sortable: true,
                render: (v) => getSnippetTypeDescription(v.contentType),
              },
              {
                label: fieldLabels.nestedTypes.contentVersions.labels.reference,
                name: "reference",
                size: 2,
                sortable: true,
                render: (v) =>
                  v.reference ? (
                    <Link
                      to={includeTenantParam(`/s/${v.reference}`)}
                      target="_blank"
                    >
                      {v.reference}
                    </Link>
                  ) : (
                    ""
                  ),
              },
              {
                label:
                  fieldLabels.nestedTypes.contentVersions.labels.activeFrom,
                name: "activeFrom",
                size: 2,
                sortable: true,
                render: (v) => (
                  <TimeAgo
                    value={dates.parseTimestamp(v.activeFrom)}
                    expandable
                    startFull
                  />
                ),
              },
            ]}
          />
        </CardSection>
      </CardContent>
    </Card>
  );
};

SnippetVersionsCard.propTypes = {
  className: PropTypes.string,
  snippet: PropTypes.object.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  onAddContent: PropTypes.func,
  getStatusDescription: PropTypes.func.isRequired,
  getSnippetTypeDescription: PropTypes.func.isRequired,
};

SnippetVersionsCard.defaultProps = {
  className: null,
  onAddContent: null,
};

const mapStateToProps = (state) => ({
  getSnippetTypeDescription: (type) =>
    getReferenceDataDescription(state, "SnippetType", type),
  getStatusDescription: (status) =>
    getReferenceDataDescription(state, "ContentVersionStatus", status),
});

export default connect(mapStateToProps)(SnippetVersionsCard);
