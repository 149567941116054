import { useAuth0 } from "@auth0/auth0-react";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { fetchWorkItemSchedules } from "../../actions/workItemSchedules";
import {
  getReferenceDataType,
  getWorkItemSchedules,
  getWorkItemScheduleSearchPagination,
  isLoading,
} from "../../reducers";
import { workItemsBase, workItemSchedulePath } from "../../routes/routeUtils";
import dates from "../../util/dates";
import { groupByMonthDue } from "../../util/scheduleSortUtils";
import { createWorkItemScheduleSearchParameters } from "../../util/searchParameterUtils";
import DueAgo from "../common/DueAgo";
import GridListing from "../common/GridListing";
import Pagination from "../common/Pagination";

const useStyles = makeStyles((theme) => ({
  primaryText: {
    display: "block",
    fontSize: "0.875rem",
    fontWeight: "600",
    margin: `${theme.spacing(1)}px 0`,
  },
  nowrap: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  textWithIcon: {
    display: "flex",
  },
  iconSm: {
    fontSize: 16,
  },
  overdue: {
    color: theme.due.overdue,
  },
}));

function displayEnum(defs, value) {
  return value ? defs.values[value].description : "";
}

const WorkItemScheduleListing = ({
  workItemSchedules,
  sortBy,
  history,
  loading,
  filter,
  clearFilter,
  updateSort,
  recurrenceTypes,
  workItemCategories,
  pagination,
  localFetchWorkItemSchedules,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();

  const getOrderBy = () => {
    const prefix = sortBy.direction === "desc" ? "-" : "";
    return `${prefix}${sortBy.field}`;
  };

  const createSearchParameters = () =>
    createWorkItemScheduleSearchParameters(filter, pagination, getOrderBy());

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      localFetchWorkItemSchedules(createSearchParameters(), accessToken);
    })();
  }, [filter, sortBy]);

  const handlePrevious = async () => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = createSearchParameters();
    searchParameters.offset = pagination.previousOffset;
    localFetchWorkItemSchedules(searchParameters, accessToken);
  };

  const handleNext = async () => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = createSearchParameters(filter);
    searchParameters.offset = pagination.nextOffset;
    localFetchWorkItemSchedules(searchParameters, accessToken);
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  const linkBase = workItemsBase();

  const groupedSchedules = groupByMonthDue(
    workItemSchedules,
    filter.views === "projected"
  );

  const getScheduleRecurrence = (schedule) => {
    const recurrenceTypeDef = recurrenceTypes.values[schedule.recurrenceType];
    return `${schedule.recurrenceUnit} ${recurrenceTypeDef.props.altDescription}`;
  };

  return (
    <>
      <GridListing
        sortedData={[]}
        groupedData={groupedSchedules}
        loading={loading}
        sortBy={sortBy}
        dense={false}
        updateSort={(field, direction) => updateSort({ field, direction })}
        clearFilter={clearFilter}
        action={(workItemSchedule) =>
          workItemSchedule &&
          workItemSchedule.nextPeriod &&
          workItemSchedule.nextPeriod.workItemId && (
            <Tooltip title="Open current task" disableFocusListener>
              <IconButton
                component={Link}
                to={`${linkBase}/${workItemSchedule.nextPeriod.workItemId}`}
              >
                <Icon>launch</Icon>
              </IconButton>
            </Tooltip>
          )
        }
        isSuppressed={(item) => item.isProjected}
        onClick={(schedule) => history.push(workItemSchedulePath(schedule.id))}
        columns={[
          {
            label: "Title",
            name: "template.title",
            size: 4,
            sortable: false,
            render: (schedule) => (
              <span className={classes.primaryText}>
                {schedule.template.title}
              </span>
            ),
          },
          {
            label: "Category",
            name: "category",
            size: 2,
            sortable: false,
            render: (schedule) => (
              <span>
                {displayEnum(workItemCategories, schedule.template.category)}
              </span>
            ),
          },
          {
            label: "Period",
            name: "period",
            size: 2,
            sortable: false,
            render: (schedule) => (
              <span>
                {schedule.nextPeriod.name}
                {schedule.nextPeriod.indexCode
                  ? ` (${schedule.nextPeriod.indexCode})`
                  : ""}
              </span>
            ),
          },
          {
            label: "Recurrence",
            name: "recurrenceType",
            size: 2,
            sortable: false,
            render: (schedule) => getScheduleRecurrence(schedule),
          },
          {
            label: "Next Due",
            name: "nextDue",
            size: 2,
            sortable: false,
            render: (schedule) => {
              const overdue = schedule.nextDue
                ? dates.dateIsBeforeToday(dates.parseDate(schedule.nextDue))
                : false;
              return (
                <span className={overdue ? classes.overdue : null}>
                  {schedule.nextDue ? (
                    <DueAgo
                      value={dates.parseDate(schedule.nextDue)}
                      expandable
                      displayAsFullAfter={30}
                    />
                  ) : (
                    <div />
                  )}
                </span>
              );
            },
          },
        ]}
      />
      <Pagination
        pagination={pagination}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
      />
    </>
  );
};

WorkItemScheduleListing.propTypes = {
  sortBy: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  clearFilter: PropTypes.func.isRequired,
  updateSort: PropTypes.func.isRequired,

  // redux
  loading: PropTypes.bool.isRequired,
  recurrenceTypes: PropTypes.object.isRequired,
  workItemCategories: PropTypes.object.isRequired,
  workItemSchedules: PropTypes.array,
  pagination: PropTypes.object.isRequired,
  localFetchWorkItemSchedules: PropTypes.func.isRequired,
};

WorkItemScheduleListing.defaultProps = {
  workItemSchedules: [],
};

const mapStateToProps = (state) => ({
  loading: isLoading(state),
  workItemSchedules: getWorkItemSchedules(state),
  recurrenceTypes: getReferenceDataType(state, "RecurrenceType"),
  workItemCategories: getReferenceDataType(state, "WorkItemCategory"),
  pagination: getWorkItemScheduleSearchPagination(state),
});

export default connect(mapStateToProps, {
  localFetchWorkItemSchedules: fetchWorkItemSchedules,
})(WorkItemScheduleListing);
