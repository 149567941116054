import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { email } from "redux-form-validators";
import Chip from "@material-ui/core/Chip";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { addIcon } from "../../../util/icons";
import ComponentField from "../../forms/wrapper/ComponentField";
import WrappedNonInteractive from "../../forms/wrapper/WrappedNonInteractive";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: 0,
    padding: 0,
  },
  emailListContainer: {
    display: "flex",
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  label: {
    flex: "0 0 auto",
    paddingRight: theme.spacing(1),
  },
  chipField: {
    width: "auto",
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1) / 4,
    marginTop: theme.spacing(1) / 4,
  },
  chipRoot: {
    maxWidth: "100%",
  },
  chipLabel: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    marginRight: theme.spacing(2),
  },
  addForm: {
    display: "flex",
    width: "100%",
  },
  addButton: {
    flex: 1,
  },
}));

const EmailToField = ({
  meta: { error, submitFailed },
  fields,
  label,
  fieldLabels,
  customisations,
  change,
  untouch,
  formName,
}) => {
  const classes = useStyles();
  const [currentName, setCurrentName] = useState("");
  const [currentAddress, setCurrentAddress] = useState("");
  const [addressError, setAddressError] = useState("");
  const [addButtonEnabled, setAddButtonEnabled] = useState(false);

  useEffect(() => {
    if (currentAddress) {
      setAddressError(
        email({
          msg: `${fieldLabels.nestedTypes.to.labels.address} must be an email`,
        })(currentAddress)
      );
    }
  }, [currentAddress]);

  useEffect(() => {
    setAddButtonEnabled(!!currentAddress && !addressError);
  }, [currentAddress, addressError]);

  const add = () => {
    fields.push({
      name: currentName,
      address: currentAddress,
    });
    setCurrentName("");
    setCurrentAddress("");
    setAddressError("");
  };

  const remove = (index) => {
    fields.remove(index);
  };

  const AddIcon = addIcon();

  return (
    <>
      <div className={classes.emailListContainer}>
        <Typography className={classes.label} variant="subtitle1">
          {label}
        </Typography>
        <div>
          {fields.map((to, index) => (
            <ComponentField
              key={to}
              name={to}
              component={WrappedNonInteractive}
              customisations={customisations}
              change={change}
              untouch={untouch}
              formName={formName}
              className={classes.chipField}
              fullWidth={false}
              margin="none"
              render={(value) => (
                <Chip
                  key={`${value.name}-${value.address}`}
                  className={classes.chip}
                  classes={{
                    root: classes.chipRoot,
                    label: classes.chipLabel,
                  }}
                  variant="outlined"
                  size="small"
                  label={
                    value.name
                      ? `${value.name} <${value.address}>`
                      : value.address
                  }
                  onDelete={() => remove(index)}
                />
              )}
            />
          ))}
        </div>
      </div>
      <div className={classes.addForm}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <TextField
              label={fieldLabels.nestedTypes.to.labels.name}
              margin="none"
              value={currentName}
              fullWidth
              onChange={(evt) => setCurrentName(evt.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label={fieldLabels.nestedTypes.to.labels.address}
              margin="none"
              value={currentAddress}
              fullWidth
              type="email"
              required
              error={!!addressError}
              helperText={addressError}
              onChange={(evt) => setCurrentAddress(evt.target.value)}
            />
          </Grid>
        </Grid>
        <div className={classes.addButton}>
          <IconButton
            aria-label="Add email address"
            onClick={add}
            title="Add"
            disabled={!addButtonEnabled}
          >
            <AddIcon />
          </IconButton>
        </div>
      </div>
      {addButtonEnabled && (
        <FormHelperText>Click &rsquo;+&rsquo; button to add.</FormHelperText>
      )}
      {error && submitFailed && <FormHelperText error>{error}</FormHelperText>}
    </>
  );
};

EmailToField.propTypes = {
  meta: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  customisations: PropTypes.array,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  fieldLabels: PropTypes.object.isRequired,
};

EmailToField.defaultProps = {
  customisations: [],
};

export default EmailToField;
