import { useAuth0 } from "@auth0/auth0-react";
import { roles } from "@certane/arcadia-web-components";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import InputLabel from "@material-ui/core/InputLabel";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { fetchTags } from "../../actions/tags";
import { getReferenceDataType, getTags } from "../../reducers";
import organisationsApi from "../../services/api/organisations";
import usersApi from "../../services/api/users";
import Avatar from "../common/Avatar.tsx";
import ChoiceChips from "../common/ChoiceChips";
import ClearButton from "../common/ClearButton";
import DebouncedTextField from "../common/DebouncedTextField";
import FiltersBar from "../common/FiltersBar";
import RefMultiSelect from "../common/RefMultiSelect";
import { tagsToOptions } from "../forms/menuItems";
import ListPicker from "../ListPicker";

const styles = (theme) => ({
  clearButtonSearchField: {
    padding: theme.spacing(0.5),
    position: "absolute",
    right: 0,
    top: `-${theme.spacing(2)}px`,
  },
  searchField: {
    fontSize: "0.8125rem",
    lineHeight: "1.1875em",
    minWidth: "200px",
  },
  filterWidget: {
    marginTop: theme.spacing(2),
  },
});

const organisationMap = {
  sargonServiceProviderIds: {
    label: "Internal",
    tooltip: "Internal entities",
    excludeExternal: true,
  },
};

const equalSets = (set1, set2) => _.isEqual(_.orderBy(set1), _.orderBy(set2));

const AuditReportFilters = ({
  classes,
  filter,
  auditTypes,
  tags,
  localFetchTags,
  updateFilter,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [ownerPickerOpen, setOwnerPickerOpen] = useState(false);
  const [selectedOwnedBy, setSelectedOwnedBy] = useState([]);
  const [sargonServiceProviderPickerOpen, setSargonServiceProviderPickerOpen] =
    useState(false);
  const [selectedSargonServiceProviders, setSelectedSargonServiceProviders] =
    useState([]);

  useEffect(() => {
    getAccessTokenSilently().then((accessToken) => localFetchTags(accessToken));
  }, []);

  useEffect(() => {
    // initially load the users currently selected in the filter
    getAccessTokenSilently().then((accessToken) => {
      if (filter.ownerIds.length === 0) {
        setSelectedOwnedBy([]);
      } else if (
        !equalSets(
          selectedOwnedBy.map((u) => u.id),
          filter.ownerIds
        )
      ) {
        usersApi
          .search(
            {
              limit: filter.ownerIds.length,
              offset: 0,
              roles: roles.AGENT_ROLES,
              userIds: filter.ownerIds,
            },
            null,
            accessToken
          )
          .then((response) => setSelectedOwnedBy(response.results));
      }

      if (filter.sargonServiceProviderIds.length === 0) {
        setSelectedSargonServiceProviders([]);
      } else if (
        !equalSets(
          selectedSargonServiceProviders.map((o) => o.id),
          filter.sargonServiceProviderIds
        )
      ) {
        organisationsApi
          .search(
            {
              limit: filter.sargonServiceProviderIds.length,
              offset: 0,
              organisationIds: filter.sargonServiceProviderIds,
            },
            null,
            accessToken
          )
          .then((response) =>
            setSelectedSargonServiceProviders(response.results)
          );
      }
    });
  }, [filter]);

  const setOwnedBy = (users) => {
    setSelectedOwnedBy(users);
    updateFilter(
      "ownerIds",
      users.map((user) => user.id)
    );
    setOwnerPickerOpen(false);
  };

  const setSargonServiceProviders = (organisations) => {
    setSelectedSargonServiceProviders(organisations);
    updateFilter(
      "sargonServiceProviderIds",
      organisations.map((org) => org.id)
    );
    setSargonServiceProviderPickerOpen(false);
  };

  const asyncAgentsFetch = async (
    pickerFilter,
    pagination,
    abortController
  ) => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = {
      ...pickerFilter,
      limit: pagination.pageSize,
      offset: pagination.offset,
      roles: [roles.COMPLIANCE_OWNER, roles.COMPLIANCE_OFFICER],
      orderByField: "name",
    };
    return usersApi.search(searchParameters, abortController, accessToken);
  };

  const asyncSargonServiceProviderFetch = async (
    pickerFilter,
    pagination,
    abortController
  ) => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = {
      ...pickerFilter,
      limit: pagination.pageSize,
      offset: pagination.offset,
      types: ["INTERNAL"],
      orderByField: "name",
    };
    return organisationsApi.search(
      searchParameters,
      abortController,
      accessToken
    );
  };

  const onSargonServiceProviderSelection = (value, oldValue) => {
    if (!oldValue) {
      setSelectedSargonServiceProviders([]);
    }
    setSargonServiceProviderPickerOpen(true);
  };

  const auditorOptions = tagsToOptions(
    tags.filter((tag) => tag.type === "AUDITOR")
  );

  return (
    <>
      <ListPicker
        title="Select owners"
        actionText="Select"
        open={ownerPickerOpen}
        onClose={() => setOwnerPickerOpen(false)}
        onSubmit={(u) => setOwnedBy(u)}
        datasource={asyncAgentsFetch}
        selected={selectedOwnedBy}
        isMulti
        clearable
        toOption={(user) => ({
          label: user.name || "Unknown",
          id: user.id,
          email: user.email,
        })}
        fromOption={(option) => ({
          name: option.label,
          id: option.id,
          email: option.email,
        })}
        renderIcon={(user, size) => (
          <Avatar email={user.email} name={user.name} size={size} round />
        )}
      />
      <ListPicker
        title="Select responsible internal entities"
        actionText="Select"
        open={sargonServiceProviderPickerOpen}
        onClose={() => setSargonServiceProviderPickerOpen(false)}
        onSubmit={(providers) => setSargonServiceProviders(providers)}
        datasource={asyncSargonServiceProviderFetch}
        selected={selectedSargonServiceProviders}
        isMulti
        clearable
        toOption={(org) => ({ label: org.name, id: org.id, org })}
        fromOption={(option) => option.org}
        renderIcon={(org, size) => (
          <Avatar name={(org.name || "Unknown").charAt(0)} size={size} round />
        )}
      />
      <FiltersBar>
        <>
          <InputLabel>Search</InputLabel>
          <div className={classes.filterWidget}>
            {filter.textSearch && (
              <ClearButton
                className={classes.clearButtonSearchField}
                onClear={() => updateFilter("textSearch", "")}
              />
            )}
            <DebouncedTextField
              value={filter.textSearch}
              onChange={(text) => updateFilter("textSearch", text)}
              placeholder="Search across audit titles"
              margin="none"
              InputProps={{
                className: classes.searchField,
              }}
            />
          </div>
        </>
        <>
          <InputLabel>Ownership</InputLabel>
          <div className={classes.filterWidget}>
            <ChoiceChips
              value={filter.ownerIds.length > 0 ? "ownerIds" : null}
              options={[
                {
                  label: "Owned by",
                  value: "ownerIds",
                  badgeValue: filter.ownerIds.length,
                },
              ]}
              onChange={() => setOwnerPickerOpen(true)}
            />
          </div>
        </>
        <>
          <InputLabel>Types</InputLabel>
          <div className={classes.filterWidget}>
            <RefMultiSelect
              title="Select types"
              value={filter.types}
              options={auditTypes}
              onChange={(value) => updateFilter("types", value)}
            />
          </div>
        </>
        <>
          <InputLabel>Auditors</InputLabel>
          <div className={classes.filterWidget}>
            <RefMultiSelect
              title="Select auditors"
              value={filter.auditors}
              options={auditorOptions}
              onChange={(value) => updateFilter("auditors", value)}
            />
          </div>
        </>
        <>
          <InputLabel>
            <Icon fontSize="inherit">business</Icon> Organisations
          </InputLabel>
          <Grid container className={classes.filterWidget}>
            <ChoiceChips
              value={
                filter.sargonServiceProviderIds.length > 0
                  ? "sargonServiceProviders"
                  : ""
              }
              options={[
                {
                  label: organisationMap.sargonServiceProviderIds.label,
                  tooltip: organisationMap.sargonServiceProviderIds.tooltip,
                  value: "sargonServiceProviders",
                  badgeValue: filter.sargonServiceProviderIds.length,
                },
              ]}
              onChange={onSargonServiceProviderSelection}
            />
          </Grid>
        </>
      </FiltersBar>
    </>
  );
};

AuditReportFilters.propTypes = {
  classes: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  auditTypes: PropTypes.object.isRequired,
  updateFilter: PropTypes.func.isRequired,
  tags: PropTypes.array.isRequired,
  localFetchTags: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auditTypes: getReferenceDataType(state, "AuditType"),
  tags: getTags(state),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    localFetchTags: fetchTags,
  })
)(AuditReportFilters);
