import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import tinycolor from "tinycolor2";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
  },
  noWrapText: {
    width: "100%",
    display: "inline-block",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
  },
  score0: {
    backgroundColor: tinycolor(theme.metrics.default).setAlpha(0.1),
  },
  score1: {
    backgroundColor: tinycolor(theme.metrics.red).setAlpha(0.1),
  },
  score2: {
    backgroundColor: tinycolor(theme.metrics.amber).setAlpha(0.1),
  },
  score3: {
    backgroundColor: tinycolor(theme.metrics.green).setAlpha(0.1),
  },
}));

const ColorScoreChip = ({ label, defaultValue, score, ...rest }) => {
  const classes = useStyles();

  if (!label) {
    return defaultValue;
  }

  return (
    <Chip
      className={classes[`score${score || 0}`]}
      classes={{ root: classes.root, label: classes.noWrapText }}
      label={label}
      size="small"
      variant="outlined"
      {...rest}
    />
  );
};

ColorScoreChip.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  score: PropTypes.number,
};

ColorScoreChip.defaultProps = {
  score: 0,
  label: null,
  defaultValue: "-",
};

export default ColorScoreChip;
