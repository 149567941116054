import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { required } from "redux-form-validators";
import { scrollToFirstInvalidInput } from "../../util/formHelpers";
import FormError from "../common/FormError";
import WrappedRadioGroup from "../forms/wrapper/WrappedRadioGroup";
import WrappedSelect from "../forms/wrapper/WrappedSelect";
import WrappedSwitch from "../forms/wrapper/WrappedSwitch";
import WrappedTextField from "../forms/wrapper/WrappedTextField";

const styles = () => ({});

const CreateSavedSearchForm = ({
  isAdmin,
  error,
  savedSearches,
  saveAsNew,
}) => {
  const sortedSearches = _.orderBy(savedSearches, "name");
  const updateMode = saveAsNew === false && isAdmin;

  return (
    <form autoComplete="off">
      {error && <FormError errorMessage={error} />}
      {isAdmin && (
        <Field
          name="saveAsNew"
          component={WrappedRadioGroup}
          required
          validate={required({ msg: "Save as is required" })}
          options={[
            {
              label: "A new view",
              value: true,
            },
            {
              label: "Update to an existing view",
              value: false,
            },
          ]}
          row
        />
      )}
      {!updateMode && (
        <>
          <Field
            margin="normal"
            name="name"
            component={WrappedTextField}
            placeholder="Enter a name"
            label="Name"
            validate={required({ msg: "Name is required" })}
            fullWidth
            required
          />
          {isAdmin && (
            <Field
              margin="normal"
              name="global"
              label="Create as a system view"
              component={WrappedSwitch}
            />
          )}
        </>
      )}
      {updateMode && (
        <Field
          margin="normal"
          component={WrappedSelect}
          name="existingView"
          label="Existing view"
          fullWidth
          required
          validate={required({ msg: "View is required" })}
        >
          {sortedSearches.map((savedSearch) => (
            <MenuItem
              key={savedSearch.id}
              value={savedSearch.id}
              data-cy={savedSearch.id}
            >
              {savedSearch.name}
            </MenuItem>
          ))}
        </Field>
      )}
    </form>
  );
};

CreateSavedSearchForm.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  error: PropTypes.string,
  savedSearches: PropTypes.array.isRequired,
  saveAsNew: PropTypes.bool,
};

CreateSavedSearchForm.defaultProps = {
  error: null,
  saveAsNew: null,
};

export const CREATE_SAVED_SEARCH_FORM = "CREATE_SAVED_SEARCH_FORM";
const reduxFormSelector = formValueSelector(CREATE_SAVED_SEARCH_FORM);

const mapStateToProps = (state) => ({
  saveAsNew: reduxFormSelector(state, "saveAsNew"),
});

const form = reduxForm({
  form: CREATE_SAVED_SEARCH_FORM,
  onSubmitFail: (errors) => scrollToFirstInvalidInput(errors, {}),
});
const reduxData = connect(mapStateToProps);

export default compose(
  form,
  reduxData
)(withStyles(styles)(CreateSavedSearchForm));
