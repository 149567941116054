import { useAuth0 } from "@auth0/auth0-react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { patchWorkItem } from "../../actions/workItems";
import { getReferenceDataType } from "../../reducers";
import { notificationsIcon } from "../../util/icons";
import CardIconTitle from "../common/CardIconTitle";
import InlineInputEditSaveCancel from "../common/InlineInputEditSaveCancel";
import Scorecard from "../common/Scorecard";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  cardHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  cardContent: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  chipRoot: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1) / 2,
  },
});

const WorkItemNotifications = ({
  classes,
  workItem,
  types,
  readonly,
  localPatchWorkItem,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  if (!workItem) return null;

  const typeDisplay = types.values[workItem.type].description;

  const updateReminderLeadTime = async (reminderLeadTime) => {
    const accessToken = await getAccessTokenSilently();
    localPatchWorkItem(
      workItem.id,
      { reminderLeadTime },
      `Edited ${typeDisplay}`,
      accessToken
    );
  };

  return (
    <Card className={classes.root} elevation={0}>
      <CardHeader
        className={classes.cardHeader}
        title={
          <CardIconTitle title="Notifications" icon={notificationsIcon()} />
        }
      />
      <CardContent className={classes.cardContent}>
        <Scorecard label="Reminder lead time (days)">
          <InlineInputEditSaveCancel
            value={workItem.reminderLeadTime}
            onChange={updateReminderLeadTime}
            fullWidth
            inputProps={{
              min: 0,
            }}
            validate={(value) =>
              value && _.isNumber(Number(value)) && value >= 0
            }
            readonly={readonly}
          />
        </Scorecard>
      </CardContent>
    </Card>
  );
};

WorkItemNotifications.propTypes = {
  classes: PropTypes.object.isRequired,
  workItem: PropTypes.object,
  localPatchWorkItem: PropTypes.func.isRequired,
  types: PropTypes.object.isRequired,
  readonly: PropTypes.bool,
};

WorkItemNotifications.defaultProps = {
  workItem: null,
  readonly: false,
};

const mapStateToProps = (state) => ({
  types: getReferenceDataType(state, "WorkItemType"),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    localPatchWorkItem: patchWorkItem,
  })
)(WorkItemNotifications);
