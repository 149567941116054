import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import * as React from "react";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import BugReport from "@material-ui/icons/BugReport";

const styles = () => ({
  button: {
    position: "fixed",
    top: "70px",
    right: "5px",
  },
});

const DebugPrefill = ({ debugMode, onClick, classes }) => (
  <div>
    {debugMode && (
      <IconButton
        className={classes.button}
        aria-label="Prefill form"
        onClick={onClick}
      >
        <BugReport />
      </IconButton>
    )}
  </div>
);

DebugPrefill.propTypes = {
  onClick: PropTypes.func.isRequired,
  debugMode: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  debugMode: state.debugMode,
});

export default connect(mapStateToProps)(withStyles(styles)(DebugPrefill));
