import { Maximize, Minimize } from "@material-ui/icons";
import React from "react";
import { IconButton, withStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const styles = (theme) => ({
  headerButton: {
    padding: theme.spacing(1),
  },
  headerIcon: {
    fontSize: 18,
    color: "white",
  },
});

const MinMaxButton = ({ classes, isMinimized, onClick }) => (
  <IconButton size="small" className={classes.headerButton} onClick={onClick}>
    {isMinimized ? (
      <Maximize className={classes.headerIcon} color="inherit" />
    ) : (
      <Minimize className={classes.headerIcon} color="inherit" />
    )}
  </IconButton>
);

MinMaxButton.propTypes = {
  classes: PropTypes.object.isRequired,
  isMinimized: PropTypes.bool,
  onClick: PropTypes.func,
};

MinMaxButton.defaultProps = {
  isMinimized: false,
  onClick: () => {
    // do nothing.
  },
};

export default withStyles(styles)(MinMaxButton);
