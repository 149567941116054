import { normalize } from "normalizr";
import schemas from "../schemas";
import risksApi from "../services/api/risks";
import { asyncAction } from "./actions";

export const RISK_CLEAR_ACTIVE = "RISK_CLEAR_ACTIVE";
export const RISK_CREATE_REASSESSMENTS = "RISK_CREATE_REASSESSMENTS";
export const RISK_CREATE_MEASURE_UPDATES = "RISK_CREATE_MEASURE_UPDATES";
export const RISK_SEND_ACTION_UPDATE_REQUESTS =
  "RISK_SEND_ACTION_UPDATE_REQUESTS";
export const RISK_CREATE = "RISK_CREATE";
export const RISK_DELETE = "RISK_DELETE";
export const RISK_LOAD = "RISK_LOAD";
export const RISK_GET_BY_ID = "RISK_GET_BY_ID";
export const RISK_PATCH = "RISK_PATCH";
export const RISK_MEASURE_ADD = "RISK_MEASURE_ADD";
export const RISK_MEASURE_UPDATE = "RISK_MEASURE_UPDATE";
export const RISK_GET_TOLERANCES = "RISK_GET_TOLERANCES";
export const RISK_MEASURE_REMOVE = "RISK_MEASURE_REMOVE";
export const RISK_LOAD_METRIC = "RISK_LOAD_METRIC";

export function clearActiveRisks() {
  return {
    type: RISK_CLEAR_ACTIVE,
  };
}

export const createReassessmentsForRisks = (
  riskIds,
  dueDate,
  reminderLeadTime,
  accessToken
) =>
  asyncAction(
    RISK_CREATE_REASSESSMENTS,
    risksApi.createReassessments(
      riskIds,
      dueDate,
      reminderLeadTime,
      accessToken
    )
  );

export const createMeasureUpdatesForRisks = (
  riskIds,
  dueDate,
  reminderLeadTime,
  accessToken
) =>
  asyncAction(
    RISK_CREATE_MEASURE_UPDATES,
    risksApi.createMeasureUpdates(
      riskIds,
      dueDate,
      reminderLeadTime,
      accessToken
    )
  );

export const sendRiskActionUpdateRequests = (filter, message, accessToken) =>
  asyncAction(
    RISK_SEND_ACTION_UPDATE_REQUESTS,
    risksApi.sendRiskActionUpdateRequests(filter, message, accessToken)
  );

export const createRisk = (risk, accessToken) =>
  asyncAction(RISK_CREATE, risksApi.create(risk, accessToken), {
    responseTransformer: (createdRisk) => normalize(createdRisk, schemas.risk),
  });

export const deleteRisk = (riskId, accessToken) =>
  asyncAction(RISK_DELETE, risksApi.remove(riskId, accessToken), {
    responseTransformer: () => ({ id: riskId }),
    actionParams: {
      isUpdate: true,
    },
  });

export const loadRisks = (filter, accessToken) => {
  const abortController = window.AbortController ? new AbortController() : null;
  return asyncAction(
    RISK_LOAD,
    risksApi.search(filter, abortController, accessToken),
    {
      responseTransformer: (response) => ({
        pagination: {
          offset: response.offset,
          previousOffset: response.previousOffset,
          nextOffset: response.nextOffset,
          pageSize: response.pageSize,
          resultCount: response.resultCount,
        },
        ...normalize(response.results, schemas.arrayOfRisks),
      }),
      actionParams: {
        abortController,
        nonBlocking: true,
      },
    }
  );
};

export const getRiskById = (riskId, accessToken) =>
  asyncAction(RISK_GET_BY_ID, risksApi.getById(riskId, accessToken), {
    responseTransformer: (risk) => normalize(risk, schemas.risk),
  });

export const addRiskMeasure = (riskId, riskMeasure, accessToken) =>
  asyncAction(
    RISK_MEASURE_ADD,
    risksApi.addMeasure(riskId, riskMeasure, accessToken),
    {
      responseTransformer: (risk) => normalize(risk, schemas.risk),
    }
  );

export const updateRiskMeasure = (
  riskId,
  riskMeasureId,
  riskMeasure,
  accessToken
) =>
  asyncAction(
    RISK_MEASURE_UPDATE,
    risksApi.updateMeasure(riskId, riskMeasureId, riskMeasure, accessToken),
    {
      responseTransformer: (risk) => normalize(risk, schemas.risk),
    }
  );

export const getTolerancesForRiskAndOwner = (riskId, ownerId, accessToken) =>
  asyncAction(
    RISK_GET_TOLERANCES,
    risksApi.getTolerances(riskId, ownerId, accessToken)
  );

export const removeRiskMeasure = (riskId, riskMeasureId, accessToken) =>
  asyncAction(
    RISK_MEASURE_REMOVE,
    risksApi.removeMeasure(riskId, riskMeasureId, accessToken),
    {
      responseTransformer: (risk) => normalize(risk, schemas.risk),
    }
  );

export const patchRisk = (riskId, changes, description, accessToken) =>
  asyncAction(
    RISK_PATCH,
    risksApi.patch(riskId, changes, description, accessToken),
    {
      responseTransformer: (risk) => normalize(risk, schemas.risk),
    }
  );

export const loadRiskMetric = (metric, filter, accessToken) =>
  asyncAction(RISK_LOAD_METRIC, risksApi.count(filter, accessToken), {
    responseTransformer: (results) => ({
      count: results.count,
      metric,
    }),
  });
