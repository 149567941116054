import _ from "lodash";
import * as PropTypes from "prop-types";
import React from "react";
import Scorecard from "./Scorecard";

const ComponentScorecard = ({
  fieldLabels,
  name,
  customisations,
  entity,
  render,
  defaultDisplay,
  labelClass,
}) => {
  const fieldCustomisations = customisations.filter((c) => c.field === name);
  const excluded = !!fieldCustomisations.find((c) => c.state === "EXCLUDED");
  const hidden = !!fieldCustomisations.find((c) => c.state === "HIDDEN");

  if (excluded || hidden) {
    return null;
  }

  const getLabel = (nameParts, labels, index) => {
    if (index === nameParts.length - 1) {
      return labels.labels[nameParts[index]];
    }
    return getLabel(nameParts, labels.nestedTypes[nameParts[index]], index + 1);
  };

  const label = getLabel(name.split("."), fieldLabels, 0);
  const value = _.get(entity, name);

  const getDisplayValue = () => {
    if (
      value !== null &&
      value !== undefined &&
      (!_.isArray(value) || value.length > 0)
    ) {
      if (render) {
        return render(value);
      }
      return value;
    }
    return defaultDisplay;
  };

  return (
    <Scorecard label={label} labelClass={labelClass} data-cy={label}>
      {getDisplayValue()}
    </Scorecard>
  );
};

ComponentScorecard.propTypes = {
  name: PropTypes.string.isRequired,
  entity: PropTypes.object.isRequired,
  customisations: PropTypes.array,
  fieldLabels: PropTypes.object.isRequired,
  render: PropTypes.func,
  defaultDisplay: PropTypes.string,
  labelClass: PropTypes.string,
};

ComponentScorecard.defaultProps = {
  customisations: {},
  defaultDisplay: "-",
  render: null,
};

export default ComponentScorecard;
