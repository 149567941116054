import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { getReferenceDataDescription } from "../../../../reducers";
import FormCard from "../../../common/FormCard";
import EditWorkItemComponentForm, {
  EDIT_WORK_ITEM_COMPONENT_FORM_NAME,
} from "../EditWorkItemComponentForm";
import SimpleChips from "../../../common/SimpleChips";
import ComponentScorecard from "../../../common/ComponentScorecard";

const CorrespondenceCard = ({
  workItem,
  onChange,
  getRegulatorName,
  fieldLabels,
  className,
  customisations,
}) => {
  return (
    <FormCard
      data-cy={fieldLabels.labels.correspondence}
      className={className}
      title={fieldLabels.labels.correspondence}
      formTitle={`Edit ${fieldLabels.labels.correspondence}`}
      readOnlyView={() => (
        <ComponentScorecard
          fieldLabels={fieldLabels}
          name="correspondence.regulators"
          customisations={customisations}
          entity={workItem}
          render={(regulators) => (
            <SimpleChips
              values={regulators.map(getRegulatorName)}
              blankLabel="-"
            />
          )}
        />
      )}
      formComponent={EditWorkItemComponentForm}
      formName={EDIT_WORK_ITEM_COMPONENT_FORM_NAME}
      onSave={onChange}
      initialValues={{
        correspondence: workItem.correspondence,
      }}
      formProps={{
        maxWidth: "md",
        workItem,
        componentName: "CORRESPONDENCE",
      }}
    />
  );
};

CorrespondenceCard.propTypes = {
  workItem: PropTypes.object.isRequired,
  getRegulatorName: PropTypes.func.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func,
  customisations: PropTypes.array.isRequired,
};

CorrespondenceCard.defaultProps = {
  onChange: null,
  className: false,
};

const mapStateToProps = (state) => ({
  getRegulatorName: (regulator) =>
    getReferenceDataDescription(state, "Regulator", regulator, "-"),
});

export default connect(mapStateToProps)(CorrespondenceCard);
