import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { Theme } from "@material-ui/core/styles";
import BusinessIcon from "@material-ui/icons/Business";
import Remove from "@material-ui/icons/Remove";
import React from "react";
import { connect } from "react-redux";
import { Field, FieldArray, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { required } from "redux-form-validators";
import * as Yup from "yup";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "@material-ui/core/Button";
import { Box, List } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { FieldLabels } from "../../../model/FieldLabels";
import { Organisation } from "../../../model/Organisation";
import { ReferenceData } from "../../../model/ReferenceData";
import { Relationship } from "../../../model/Relationship";
import fundApi from "../../../services/api/funds";
import organisationsApi from "../../../services/api/organisations";
import logger from "../../../util/logger";
import Avatar from "../../common/Avatar";
import { getRefDataMenuItems } from "../menuItems";
import { getReferenceDataType } from "../../../reducers";
import { Fund } from "../../../model/fund";
import FormLabel from "@material-ui/core/FormLabel";
import WrappedChipListPicker from "../formik/WrappedChipListPicker";

interface Props {
  initialValues?: Values | null;
  onSubmit(values: Values): Promise<Fund>;
  onCancel(): void;
  regions: ReferenceData;
  fieldLabels: FieldLabels;
  relationshipTypes: ReferenceData;
}

interface Values {
  id?: string | null;
  friendlyId?: string;
  name?: string;
  description?: string;
  businessNumber?: string;
  region?: string;
  relationships: Relationship[];
}

const useStyles = makeStyles((theme: Theme) => ({
  actions: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
  },
  actionButton: {
    marginLeft: theme.spacing(1),
  },
  relationshipFields: {
    marginRight: theme.spacing(2),
  },
}));

const fundSchema = Yup.object().shape({
  friendlyId: Yup.string()
    .min(2, "Friendly id should be between 2 and 4 characters")
    .max(4, "Friendly id should be between 2 and 4 characters")
    .required("Friendly ID is required"),
  name: Yup.string().required("Name is required"),
  businessNumber: Yup.string().required("Business number is required"),
  region: Yup.string().required("Region is required"),
  relationships: Yup.array().of(
    Yup.object()
      .shape({
        organisation: Yup.object()
          .shape({
            id: Yup.string().required("Organisation is required"),
          })
          .nullable()
          .required("Organisation is required"),
        relationshipType: Yup.string().required(
          "Relationship type is required"
        ),
      })
      .required("Relationships are required")
  ),
});

const FundForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
  onCancel,
  regions,
  fieldLabels,
  relationshipTypes,
}: Props) => {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();

  const existingId = initialValues?.id;
  const isUpdate = !!existingId;

  const iValues = {
    friendlyId: initialValues?.friendlyId || "",
    name: initialValues?.name || "",
    description: initialValues?.description || "",
    businessNumber: initialValues?.businessNumber || "",
    region: initialValues?.region || "",
    relationships: initialValues?.relationships || [],
  };

  return (
    <Formik
      initialValues={iValues}
      validateOnChange={true}
      validateOnBlur={true}
      validationSchema={fundSchema}
      validate={async (values: Values) => {
        const errors: Partial<Values> = {};
        const { friendlyId } = values;

        if (!values.friendlyId) {
          return;
        }

        try {
          const accessToken = await getAccessTokenSilently();
          const response = await fundApi.checkFriendlyIdAvailability(
            existingId,
            friendlyId,
            accessToken
          );

          if (!response.available) {
            errors.friendlyId = `${friendlyId} is not available`;
          }
        } catch (e) {
          errors.friendlyId = e.message;
        }

        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSubmit(values);
        } catch (e) {
          logger.error(e);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, isValid, values, errors }) => {
        const asyncOrganisationOptionsFetch = async (
          pickerFilter: Map<string, unknown>,
          pagination: { pageSize: number; offset: number },
          abortController?: AbortController
        ) => {
          const accessToken = await getAccessTokenSilently();
          const searchParameters = {
            ...pickerFilter,
            regions: values.region ? [values.region] : [],
            limit: pagination.pageSize,
            offset: pagination.offset,
            orderByField: "name",
            excludeGroupFilters: true,
          };
          return organisationsApi.search(
            searchParameters,
            abortController,
            accessToken
          );
        };

        if (!isValid) {
          logger.warn("Form errors: ", errors);
        }

        return (
          <Form>
            {!isUpdate && (
              <Field
                component={TextField}
                name="friendlyId"
                label={fieldLabels.labels.friendlyId}
                margin="dense"
                disabled={!!values.id}
                fullWidth
              />
            )}
            {!isUpdate && (
              <Field
                component={TextField}
                name="name"
                label={fieldLabels.labels.name}
                margin="dense"
                fullWidth
              />
            )}
            <Field
              component={TextField}
              name="description"
              label={fieldLabels.labels.description}
              margin="dense"
              fullWidth
            />
            <Field
              component={TextField}
              name="businessNumber"
              label={fieldLabels.labels.businessNumber}
              margin="dense"
              fullWidth
            />
            <Field
              component={TextField}
              name="region"
              label={fieldLabels.labels.region}
              margin="dense"
              disabled={!!values.id}
              fullWidth
              select
            >
              {getRefDataMenuItems(regions)}
            </Field>
            <Box mt={2}>
              <FormLabel>{fieldLabels.labels.relationships}</FormLabel>
              <br />
              <FieldArray
                name="relationships"
                render={(arrayHelpers) => (
                  <List style={{ width: "100%" }}>
                    {values.relationships.map((relationship, index) => (
                      <ListItem key={index} dense divider>
                        <ListItemText className={classes.relationshipFields}>
                          <Grid container spacing={1} alignItems="flex-end">
                            <Grid item xs={8}>
                              <Field
                                name={`relationships.${index}.organisation`}
                                component={WrappedChipListPicker}
                                datasource={asyncOrganisationOptionsFetch}
                                label={
                                  fieldLabels.nestedTypes.relationships.labels
                                    .organisation
                                }
                                fullWidth
                                clearable
                                isMulti={false}
                                submitOnChange
                                required
                                validate={required({
                                  msg: `${fieldLabels.nestedTypes.relationships.labels.organisation} is required`,
                                })}
                                toOption={(org: Organisation) => ({
                                  label: org.name,
                                  id: org.id,
                                  org,
                                })}
                                fromOption={(option: {
                                  label: string;
                                  id: string;
                                  org: Organisation;
                                }) => option.org}
                                addIcon={BusinessIcon}
                                renderIcon={(
                                  org: Organisation,
                                  size: string
                                ) => (
                                  <Avatar name={org.name} size={size} round />
                                )}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <Field
                                component={TextField}
                                name={`relationships.${index}.relationshipType`}
                                label={
                                  fieldLabels.nestedTypes.relationships.labels
                                    .relationshipType
                                }
                                margin="dense"
                                fullWidth
                                select
                              >
                                {getRefDataMenuItems(relationshipTypes)}
                              </Field>
                            </Grid>
                          </Grid>
                        </ListItemText>
                        <ListItemSecondaryAction>
                          <IconButton
                            color="primary"
                            aria-label="Remove"
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            <Remove />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                    <Button
                      size="small"
                      color="primary"
                      aria-label="Add"
                      onClick={() =>
                        arrayHelpers.insert(values.relationships.length, {
                          organisation: null,
                          relationshipType: "",
                        })
                      }
                    >
                      Add related organisation
                    </Button>
                  </List>
                )}
              />
            </Box>
            <div className={classes.actions}>
              <Button
                className={classes.actionButton}
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting || !isValid}
              >
                Save
              </Button>
              <Button
                className={classes.actionButton}
                variant="text"
                onClick={onCancel}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: Map<string, unknown>) => ({
  regions: getReferenceDataType(state, "Region"),
  relationshipTypes: getReferenceDataType(state, "RelationshipType"),
});

export default connect(mapStateToProps)(FundForm);
