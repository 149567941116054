import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import Avatar from "./Avatar.tsx";
import MultiSelectListPicker from "./MultiSelectListPicker";

const styles = () => ({});

const RefMultiSelect = ({ title, actionText, onChange, options, value }) => {
  const getLabel = () => {
    if (value.length > 1) {
      return `${value.length} selected`;
    }
    return value
      .map((typeId) =>
        options.values[typeId] ? options.values[typeId].description : "Unknown"
      )
      .join(", ");
  };

  return (
    <MultiSelectListPicker
      title={title}
      actionText={actionText}
      label={getLabel()}
      onSubmit={onChange}
      datasource={options.ids}
      selected={value}
      isMulti
      clearable
      toOption={(id) => ({
        label: options.values[id] ? options.values[id].description : "",
        id,
      })}
      fromOption={(option) => option.id}
      renderIcon={(type, size) => (
        <Avatar
          name={options.values[type] ? options.values[type].description : ""}
          size={size}
          round
        />
      )}
    />
  );
};

RefMultiSelect.propTypes = {
  title: PropTypes.string.isRequired,
  actionText: PropTypes.string,
  value: PropTypes.array.isRequired,
  options: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

RefMultiSelect.defaultProps = {
  actionText: "Select",
};

export default withStyles(styles)(RefMultiSelect);
