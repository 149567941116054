import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import formatters from "../../../../util/formatters";
import FormCard from "../../../common/FormCard";
import Scorecard from "../../../common/Scorecard";
import EditWorkItemComponentForm, {
  EDIT_WORK_ITEM_COMPONENT_FORM_NAME,
} from "../EditWorkItemComponentForm";

const TerminalIllnessCard = ({
  className,
  fieldLabels,
  workItem,
  onChange,
}) => {
  const { terminalIllness } = workItem;

  return (
    <FormCard
      className={className}
      title={fieldLabels.labels.terminalIllness}
      formTitle={`Edit ${fieldLabels.labels.terminalIllness}`}
      readOnlyView={() => (
        <>
          <Scorecard
            label={
              fieldLabels.nestedTypes.terminalIllness.labels
                .memberReportsStateUnlikelyToSurvive24Months
            }
          >
            {formatters.displayBoolean(
              terminalIllness &&
                terminalIllness.memberReportsStateUnlikelyToSurvive24Months
            )}
          </Scorecard>
          <Scorecard
            label={
              fieldLabels.nestedTypes.terminalIllness.labels
                .declarationBySpecialistInFieldOfIllness
            }
          >
            {formatters.displayBoolean(
              terminalIllness &&
                terminalIllness.declarationBySpecialistInFieldOfIllness
            )}
          </Scorecard>
        </>
      )}
      formComponent={EditWorkItemComponentForm}
      formName={EDIT_WORK_ITEM_COMPONENT_FORM_NAME}
      onSave={onChange}
      initialValues={{ terminalIllness }}
      formProps={{
        maxWidth: "md",
        workItem,
        componentName: "TERMINAL_ILLNESS",
      }}
    />
  );
};

TerminalIllnessCard.propTypes = {
  className: PropTypes.string,
  fieldLabels: PropTypes.object.isRequired,
  workItem: PropTypes.object.isRequired,
  onChange: PropTypes.func,
};

TerminalIllnessCard.defaultProps = {
  className: undefined,
  onChange: null,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(TerminalIllnessCard);
