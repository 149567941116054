import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import React from "react";
import PropTypes from "prop-types";
import { auditReportPath } from "../../../../routes/routeUtils";
import { auditIcon } from "../../../../util/icons";
import ComponentField from "../../../forms/wrapper/ComponentField";
import WrappedNonInteractive from "../../../forms/wrapper/WrappedNonInteractive";

const useStyles = makeStyles((theme) => ({
  iconTextContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 4,
  },
  iconPreText: {
    marginRight: theme.spacing(1),
  },
}));

const AuditAssociationFields = ({
  customisations,
  change,
  untouch,
  formName,
}) => {
  const classes = useStyles();
  const AuditReportIcon = auditIcon();

  return (
    <ComponentField
      name="auditReport"
      label=""
      component={WrappedNonInteractive}
      customisations={customisations}
      change={change}
      untouch={untouch}
      formName={formName}
      fullWidth={false}
      margin="none"
      render={(value) => (
        <div className={classes.iconTextContainer}>
          <AuditReportIcon className={classes.iconPreText} />
          <Typography variant="subtitle1">
            Linked to Audit:{" "}
            <span>
              <Link to={auditReportPath(value.id)}>{value.title}</Link>
            </span>
          </Typography>
        </div>
      )}
    />
  );
};

AuditAssociationFields.propTypes = {
  customisations: PropTypes.array,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
};

AuditAssociationFields.defaultProps = {
  loggedInUser: null,
  customisations: [],
  disableRiskSelection: false,
};

export default AuditAssociationFields;
