import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import tinycolor from "tinycolor2";
import { getLoggedInUser, getReferenceDataDescription } from "../../reducers";
import {
  helpIcon,
  invisibleIcon,
  visibleIcon,
  warningIcon,
} from "../../util/icons";
import ComponentScorecard from "../common/ComponentScorecard";
import FormCard from "../common/FormCard";
import EditEntityRelationshipForm, {
  EDIT_ENTITY_RELATIONSHIP_FORM_NAME,
} from "../forms/business/EditEntityRelationshipForm";
import EntityExplorer from "./EntityExplorer";

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: 0,
  },
  chipRoot: {
    marginRight: theme.spacing(1) / 2,
    marginBottom: theme.spacing(1) / 2,
  },
  relationship: {
    backgroundColor: tinycolor(theme.palette.primary.main).setAlpha(0.1),
  },
  warning: {
    backgroundColor: tinycolor(theme.palette.warning.main).setAlpha(0.1),
  },
  visibilityIcon: {
    marginRight: theme.spacing(1),
  },
}));

const EntityRelationshipCard = ({
  className,
  loggedInUser,
  baseEntity,
  title,
  fieldLabels,
  onChange,
  customisations,
  defaultVisibilityScope,
  getRelationshipTypeDescription,
  autoPopulateEntitiesByRelationshipTypes,
  filterEntitiesByRelationshipTypes,
}) => {
  const classes = useStyles();
  const [productExplorerOpen, setProductExplorerOpen] = useState(false);

  const notEmpty = (array) => array && array.length > 0;

  const shouldRender =
    baseEntity.entityRelationship &&
    (notEmpty(baseEntity.entityRelationship.financialProducts) ||
      notEmpty(baseEntity.entityRelationship.organisations) ||
      notEmpty(baseEntity.entityRelationship.businessServiceAreas) ||
      notEmpty(baseEntity.entityRelationship.regions));

  const HelpIcon = helpIcon();
  const WarningIcon = warningIcon();

  const getRelationshipTypes = (organisation) => {
    const rTypes = {};
    _.flatMap(
      baseEntity.entityRelationship.financialProducts,
      (product) => product.relationships
    )
      .filter((rel) => rel.organisation.id === organisation.id)
      .forEach((rel) => {
        rTypes[rel.relationshipType] = true;
      });
    _.flatMap(baseEntity.entityRelationship.funds, (fund) => fund.relationships)
      .filter((rel) => rel.organisation.id === organisation.id)
      .forEach((rel) => {
        rTypes[rel.relationshipType] = true;
      });
    return _.orderBy(Object.keys(rTypes));
  };

  const filterProductsForExternalUser = (products) => {
    const userOrgIds = loggedInUser.organisations.map((o) => o.id);
    return products.filter(
      (p) =>
        !loggedInUser.externalUser ||
        p.relationships.some((r) => userOrgIds.indexOf(r.organisation.id) >= 0)
    );
  };

  const getVisibilityTooltip = (currentValue) => {
    if (currentValue === "ORGANISATION") {
      return "Visible to all users of organisation";
    }
    return "Not visible to all users from organisation";
  };

  const getProducts = (fund) =>
    baseEntity.entityRelationship.financialProducts.filter(
      (p) => p.fund && p.fund.id === fund.id
    );

  const VisibleIcon = visibleIcon();
  const InvisibleIcon = invisibleIcon();

  return (
    <FormCard
      className={className}
      title={title}
      data-cy={title}
      formTitle={`Edit ${title}`}
      additionalActions={
        <Tooltip title="Help" disableFocusListener data-cy="help">
          <IconButton
            aria-label="Help"
            aria-haspopup="true"
            onClick={() => setProductExplorerOpen(true)}
            data-cy="iconButton"
          >
            <HelpIcon />
          </IconButton>
        </Tooltip>
      }
      readOnlyView={() => (
        <>
          <EntityExplorer
            title={`${title} explorer`}
            open={productExplorerOpen}
            onClose={() => setProductExplorerOpen(false)}
            data-cy={`${title} explorer`}
          />
          {shouldRender ? (
            <>
              <ComponentScorecard
                fieldLabels={fieldLabels}
                name="entityRelationship.financialProducts"
                customisations={customisations}
                entity={baseEntity}
                render={(products) => {
                  const fProducts = loggedInUser.externalUser
                    ? filterProductsForExternalUser(products)
                    : products;
                  const numFilteredOut = products.length - fProducts.length;
                  return (
                    <>
                      {fProducts.map((product) => (
                        <Chip
                          classes={{
                            root: classes.chipRoot,
                          }}
                          key={product.id}
                          label={product.name}
                        />
                      ))}
                      {numFilteredOut === 1 && (
                        <Chip
                          classes={{
                            root: classes.chipRoot,
                          }}
                          variant="outlined"
                          label={`...and ${numFilteredOut} other`}
                        />
                      )}
                      {numFilteredOut > 1 && (
                        <Chip
                          classes={{
                            root: classes.chipRoot,
                          }}
                          variant="outlined"
                          label={`...and ${numFilteredOut} others`}
                        />
                      )}
                    </>
                  );
                }}
              />

              <ComponentScorecard
                fieldLabels={fieldLabels}
                name="entityRelationship.funds"
                customisations={customisations}
                entity={baseEntity}
                render={(funds) => (
                  <List>
                    {funds.map((fund) => {
                      const relatedProducts = getProducts(fund);
                      const showWarning =
                        relatedProducts.length === 0 &&
                        baseEntity.entityRelationship.financialProducts
                          .length !== 0;
                      return (
                        <ListItem key={fund.id} className={classes.listItem}>
                          <Grid
                            container
                            alignItems="center"
                            spacing={1}
                            style={{ margin: 0 }}
                          >
                            <Grid item style={{ paddingLeft: 0 }}>
                              <Chip key={fund.id} label={fund.name} />
                            </Grid>
                            {showWarning && (
                              <Grid item>
                                <Chip
                                  className={classes.warning}
                                  icon={<WarningIcon />}
                                  label="Not related"
                                  size="small"
                                  variant="outlined"
                                />
                              </Grid>
                            )}
                          </Grid>
                        </ListItem>
                      );
                    })}
                  </List>
                )}
              />

              <ComponentScorecard
                fieldLabels={fieldLabels}
                name="entityRelationship.fundLevel"
                customisations={customisations}
                entity={baseEntity}
                render={(fundWide) => (
                  <Switch
                    checked={fundWide}
                    name={
                      fieldLabels.nestedTypes.entityRelationship.labels
                        .fundLevel
                    }
                    disabled
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                )}
              />

              <ComponentScorecard
                fieldLabels={fieldLabels}
                name="entityRelationship.organisations"
                customisations={customisations}
                entity={baseEntity}
                render={(organisations) => (
                  <List>
                    {organisations.map((linkedOrganisation) => {
                      const relationshipTypes = getRelationshipTypes(
                        linkedOrganisation.organisation
                      );
                      const showWarning =
                        relationshipTypes.length === 0 &&
                        baseEntity.entityRelationship.financialProducts
                          .length !== 0;
                      return (
                        <ListItem
                          key={linkedOrganisation.organisation.id}
                          className={classes.listItem}
                        >
                          {!loggedInUser.externalUser && (
                            <Tooltip
                              title={getVisibilityTooltip(
                                linkedOrganisation.visibilityScope
                              )}
                            >
                              {linkedOrganisation.visibilityScope ===
                              "ORGANISATION" ? (
                                <VisibleIcon
                                  className={classes.visibilityIcon}
                                />
                              ) : (
                                <InvisibleIcon
                                  className={classes.visibilityIcon}
                                />
                              )}
                            </Tooltip>
                          )}
                          <Grid
                            container
                            alignItems="center"
                            spacing={1}
                            style={{ margin: 0 }}
                          >
                            <Grid item style={{ paddingLeft: 0 }}>
                              <Chip
                                key={linkedOrganisation.organisation.id}
                                label={linkedOrganisation.organisation.name}
                              />
                            </Grid>
                            {relationshipTypes.map((type) => (
                              <Grid key={type} item>
                                <Chip
                                  className={classes.relationship}
                                  label={getRelationshipTypeDescription(type)}
                                  size="small"
                                  variant="outlined"
                                />
                              </Grid>
                            ))}
                            {showWarning && (
                              <Grid item>
                                <Chip
                                  className={classes.warning}
                                  icon={<WarningIcon />}
                                  label="Not related"
                                  size="small"
                                  variant="outlined"
                                />
                              </Grid>
                            )}
                          </Grid>
                        </ListItem>
                      );
                    })}
                  </List>
                )}
              />

              <ComponentScorecard
                fieldLabels={fieldLabels}
                name="entityRelationship.businessServiceAreas"
                customisations={customisations}
                entity={baseEntity}
                render={(businessServiceAreas) =>
                  businessServiceAreas.map((serviceArea) => (
                    <Chip
                      classes={{
                        root: classes.chipRoot,
                      }}
                      key={serviceArea.id}
                      label={serviceArea.name}
                      data-cy="businessServiceArea"
                    />
                  ))
                }
              />

              <ComponentScorecard
                fieldLabels={fieldLabels}
                name="entityRelationship.regions"
                customisations={customisations}
                entity={baseEntity}
                render={(regions) =>
                  regions.map((region) => (
                    <Chip
                      classes={{
                        root: classes.chipRoot,
                      }}
                      key={region}
                      label={region}
                      data-cy="regions"
                    />
                  ))
                }
              />
            </>
          ) : (
            <p>No {title.toLowerCase()} defined</p>
          )}
        </>
      )}
      formComponent={EditEntityRelationshipForm}
      formName={EDIT_ENTITY_RELATIONSHIP_FORM_NAME}
      onSave={onChange}
      initialValues={{ entityRelationship: baseEntity.entityRelationship }}
      formProps={{
        fieldLabels,
        customisations,
        defaultVisibilityScope,
        autoPopulateEntitiesByRelationshipTypes,
        filterEntitiesByRelationshipTypes,
      }}
    />
  );
};

EntityRelationshipCard.propTypes = {
  baseEntity: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  customisations: PropTypes.array,
  defaultVisibilityScope: PropTypes.object.isRequired,
  autoPopulateEntitiesByRelationshipTypes: PropTypes.array.isRequired,
  filterEntitiesByRelationshipTypes: PropTypes.array.isRequired,

  // redux
  getRelationshipTypeDescription: PropTypes.func.isRequired,
  loggedInUser: PropTypes.object,
};

EntityRelationshipCard.defaultProps = {
  className: undefined,
  onChange: null,
  loggedInUser: null,
  customisations: [],
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
  getRelationshipTypeDescription: (relationshipType) =>
    getReferenceDataDescription(state, "RelationshipType", relationshipType),
});

export default connect(mapStateToProps)(EntityRelationshipCard);
