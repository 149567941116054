import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Fragment } from "react";

const styles = () => ({
  root: {
    // These are technically the same, but use both
    overflowWrap: "break-word",
    wordWrap: "break-word",

    "-ms-word-break": "break-all",
    // Instead use this non-standard one:
    wordBreak: "break-word",

    // Adds a hyphen where the word breaks, if supported (No Blink)
    "-ms-hyphens": "auto",
    "-moz-hyphens": "auto",
    "-webkit-hyphens": "auto",
    hyphens: "auto",
  },
});

const parseLinks = (text, hyperlinks) => {
  let textInProgress = text;
  const links = [];

  hyperlinks.forEach((hyperlink) => {
    const linkName = hyperlink.fileName;
    const indexOfFile = textInProgress.indexOf(linkName);

    if (indexOfFile !== -1) {
      const linkIndex = links.length;
      const linkPlaceholder = `__LINK__${linkIndex}`;
      links[linkIndex] = (
        <a
          key={`link_${linkIndex}`}
          href={hyperlink.url}
          target="_blank"
          rel="noreferrer"
        >
          {linkName}
        </a>
      );

      // remove single quotes if they exist too
      const indexWithQuotes = textInProgress.indexOf(`'${linkName}'`);
      if (indexWithQuotes !== -1 && indexWithQuotes === indexOfFile - 1) {
        textInProgress = textInProgress.replace(
          `'${linkName}'`,
          linkPlaceholder
        );
      } else {
        textInProgress = textInProgress.replace(linkName, linkPlaceholder);
      }
    }
  });

  return {
    text: textInProgress,
    links,
  };
};

const getLine = (className, lineIndex, line, links) => {
  const regex = new RegExp("__LINK__\\d+", "g");
  const lineParts = line.split(regex);
  const fullLine = [];

  lineParts.forEach((text, i) => {
    fullLine.push(
      // eslint-disable-next-line react/no-array-index-key
      <span key={`text_${lineIndex}_${i}`} className={className}>
        {lineParts[i]}
      </span>
    );

    const match = regex.exec(line);
    if (match) {
      const linkIndex = Number(match[0].replace("__LINK__", ""));
      fullLine.push(links[linkIndex]);
    }
  });

  return fullLine;
};

const MultilineText = ({ classes, text, defaultText, hyperlinks }) => {
  const textToDisplay = text || defaultText;
  const parsedText = parseLinks(textToDisplay, hyperlinks || []);

  const lines = parsedText.text.split("\n");
  return (
    <>
      {lines.map((l, index) => (
        // eslint-disable-next-line react/no-array-index-key,
        <Fragment key={index}>
          {getLine(classes.root, index, l, parsedText.links)}
          {index !== lines.length - 1 && <br />}
        </Fragment>
      ))}
    </>
  );
};

MultilineText.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string,
  defaultText: PropTypes.string,
  hyperlinks: PropTypes.array,
};

MultilineText.defaultProps = {
  text: undefined,
  defaultText: "",
  hyperlinks: [],
};

export default withStyles(styles)(MultilineText);
