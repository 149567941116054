import { useAuth0 } from "@auth0/auth0-react";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { SubmissionError } from "redux-form";
import {
  deleteSubscription,
  listSubscriptions,
} from "../../actions/subscriptions";
import {
  getLabels,
  getReferenceDataDescription,
  getSubscriptions,
  isLoading,
} from "../../reducers";
import { roles } from "@certane/arcadia-web-components";
import dates from "../../util/dates";
import AlertDialog from "../common/AlertDialog";
import GridListing from "../common/GridListing";
import TimeAgo from "../common/TimeAgo";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  primaryText: {
    display: "block",
    fontSize: "0.875rem",
    fontWeight: "600",
  },
  nowrap: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  textWithIcon: {
    display: "flex",
  },
  iconSm: {
    fontSize: 16,
  },
});

const SubscriptionListing = ({
  classes,
  loading,
  getWorkItemTypeDescription,
  getCategoryDescription,
  getEventTypeDescription,
  subscriptions,
  localListSubscriptions,
  localDeleteSubscription,
  labels,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [idToRemove, setIdToRemove] = useState(null);

  useEffect(() => {
    getAccessTokenSilently().then((accessToken) =>
      localListSubscriptions(accessToken)
    );
  }, []);

  const onSubmitRemoveDialog = async () => {
    const accessToken = await getAccessTokenSilently();
    return localDeleteSubscription(idToRemove, accessToken)
      .then(() => {
        setIdToRemove(null);
      })
      .catch((error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  const sortSubscriptions = () =>
    _.orderBy(subscriptions, (subscription) => {
      const type = getWorkItemTypeDescription(subscription.workItemType);
      const user = subscription.subscriber ? subscription.subscriber.name : "";
      return `${type}${user}`;
    });

  const fieldLabels = labels.WorkItemSubscription;

  return (
    <div className={classes.root}>
      <AlertDialog
        title="Remove?"
        body="Are you sure you want to remove this subscription?"
        submitButtonText="Remove"
        open={!!idToRemove}
        onCancel={() => setIdToRemove(null)}
        onSubmit={(values) => onSubmitRemoveDialog(values)}
      />
      <GridListing
        sortedData={sortSubscriptions()}
        loading={loading && subscriptions.length === 0}
        sortBy={{}}
        updateSort={() => {
          // do nothing.
        }}
        dense={false}
        action={(subscription) => (
          <IconButton
            aria-label="Delete"
            onClick={() => setIdToRemove(subscription.id)}
          >
            <Icon>delete</Icon>
          </IconButton>
        )}
        columns={[
          {
            label: fieldLabels.labels.workItemType,
            name: "workItemType",
            size: 3,
            sortable: false,
            render: (subscription) => (
              <span className={classes.primaryText}>
                {getWorkItemTypeDescription(subscription.workItemType)}
              </span>
            ),
          },
          {
            label: fieldLabels.labels.category,
            name: "category",
            size: 3,
            sortable: false,
            render: (subscription) => (
              <span className={classes.primaryText}>
                {getCategoryDescription(subscription.category)}
              </span>
            ),
          },
          {
            label: fieldLabels.labels.eventType,
            name: "eventType",
            size: 2,
            sortable: false,
            render: (subscription) => (
              <span className={classes.primaryText}>
                {getEventTypeDescription(subscription.eventType)}
              </span>
            ),
          },
          {
            label: fieldLabels.labels.subscriber,
            name: "subscriber",
            size: 5,
            sortable: false,
            render: (subscription) => {
              if (subscription.subscriber) {
                const serviceUser =
                  subscription.subscriber.roles.indexOf(
                    roles.SUBSCRIBER_SERVICE
                  ) !== -1;
                return serviceUser
                  ? subscription.subscriber.email
                  : subscription.subscriber.name;
              }
              return "-";
            },
          },
          {
            label: fieldLabels.labels.created,
            name: "created",
            size: 2,
            sortable: false,
            render: (subscription) => (
              <TimeAgo
                value={dates.parseTimestamp(subscription.created)}
                expandable
              />
            ),
          },
        ]}
      />
    </div>
  );
};

SubscriptionListing.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  subscriptions: PropTypes.array.isRequired,
  getWorkItemTypeDescription: PropTypes.func.isRequired,
  getCategoryDescription: PropTypes.func.isRequired,
  getEventTypeDescription: PropTypes.func.isRequired,
  labels: PropTypes.object.isRequired,
  localListSubscriptions: PropTypes.func.isRequired,
  localDeleteSubscription: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: isLoading(state),
  subscriptions: getSubscriptions(state),
  getWorkItemTypeDescription: (id) =>
    getReferenceDataDescription(state, "WorkItemType", id, "-"),
  getCategoryDescription: (id) =>
    getReferenceDataDescription(state, "WorkItemCategory", id, "*"),
  getEventTypeDescription: (id) =>
    getReferenceDataDescription(state, "SubscriptionEventType", id, "-"),
  labels: getLabels(state),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    localListSubscriptions: listSubscriptions,
    localDeleteSubscription: deleteSubscription,
  })
)(SubscriptionListing);
