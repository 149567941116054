import Typography from "@material-ui/core/Typography";
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
  inset: {
    marginLeft: "50px",
  },
});

const PageSubheading = ({ classes, inset, subheading }) =>
  inset ? (
    <Typography className={classes.inset} variant="subtitle1">
      {subheading}
    </Typography>
  ) : (
    <Typography variant="subtitle1">{subheading}</Typography>
  );

PageSubheading.propTypes = {
  classes: PropTypes.object.isRequired,
  inset: PropTypes.bool,
  subheading: PropTypes.any.isRequired,
};

PageSubheading.defaultProps = {
  inset: false,
};

export default withStyles(styles)(PageSubheading);
