import PropTypes from "prop-types";
import React from "react";
import { yesNoOptions } from "../../../../util/radioOptions";
import ComponentField from "../../../forms/wrapper/ComponentField";
import WrappedDatePicker from "../../../forms/wrapper/WrappedDatePicker";
import WrappedRadioGroup from "../../../forms/wrapper/WrappedRadioGroup";

const DeathBenefitDetailsFields = ({
  fieldLabels,
  customisations,
  change,
  untouch,
  formName,
}) => (
  <>
    <ComponentField
      name="deathBenefit.claimStakingUndertaken"
      component={WrappedRadioGroup}
      label={fieldLabels.nestedTypes.deathBenefit.labels.claimStakingUndertaken}
      options={yesNoOptions}
      row
      customisations={customisations}
      change={change}
      untouch={untouch}
      formName={formName}
    />
    <ComponentField
      component={WrappedDatePicker}
      name="deathBenefit.dateOfDeath"
      disableFuture
      label={fieldLabels.nestedTypes.deathBenefit.labels.dateOfDeath}
      fullWidth
      customisations={customisations}
      change={change}
      untouch={untouch}
      formName={formName}
    />
    <ComponentField
      component={WrappedRadioGroup}
      name="deathBenefit.estateSolvent"
      label={fieldLabels.nestedTypes.deathBenefit.labels.estateSolvent}
      options={yesNoOptions}
      row
      customisations={customisations}
      change={change}
      untouch={untouch}
      formName={formName}
    />
  </>
);

DeathBenefitDetailsFields.propTypes = {
  customisations: PropTypes.array.isRequired,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  fieldLabels: PropTypes.object.isRequired,
};

export default DeathBenefitDetailsFields;
