import { useAuth0 } from "@auth0/auth0-react";
import { useTenant } from "@certane/arcadia-web-components";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import classNames from "classnames";
import _ from "lodash";
import PropTypes from "prop-types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  loadWorkItems,
  patchWorkItem,
  setWorkItemStatus,
  setWorkItemUsers,
  WORK_ITEM_LIST,
} from "../../actions/workItems";
import {
  getLoggedInUser,
  getReferenceDataDescription,
  getReferenceDataType,
  getWorkItems,
  getWorkItemSearchPagination,
  isLoadingAction,
} from "../../reducers";
import { getWorkItemLink } from "../../routes/routeUtils";
import { roles } from "@certane/arcadia-web-components";
import dates from "../../util/dates";
import {
  editIcon,
  infoIcon,
  recurringIcon,
  typeParentIcon,
} from "../../util/icons";
import logger from "../../util/logger";
import { createWorkItemSearchParameters } from "../../util/searchParameterUtils";
import {
  appliesByTemplate,
  getResolvedDefinition,
} from "../../util/workItemTypeUtils";
import { getFirstWorkItemUserByType } from "../../util/workItemUserUtils";
import DueAgo from "../common/DueAgo";
import GridListing from "../common/GridListing";
import Pagination from "../common/Pagination";
import RiskConsequenceRating from "../common/RiskConsequenceRating";
import TimeAgo from "../common/TimeAgo";
import DefaultWorkItemTooltip, {
  getDataToRender,
} from "../common/workitemTooltips/DefaultWorkItemTooltip";
import DateButtonPicker from "../DateButtonPicker";
import WorkItemOwner from "./components/overview/WorkItemOwner";
import WorkItemPriority from "./components/overview/WorkItemPriority";
import WorkItemStatus from "./components/overview/WorkItemStatus";
import getFilteredTypes, { filterableWorkItemTypes } from "./getFilteredTypes";
import OutcomeChip from "./OutcomeChip";
import WorkItemBlob from "./WorkItemBlob";
import WorkItemSlaPopover from "./WorkItemSlaPopover";
import WorkItemSubmitter from "./WorkItemSubmitter";

const useStyles = makeStyles((theme) => ({
  sub: {
    fontSize: "0.7rem",
    color: "#888",
  },
  due: {
    color: theme.due.ok,
  },
  overdue: {
    color: theme.due.overdue,
  },
  nowrap: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  keyContact: {
    fontWeight: "300 !important",
    color: theme.palette.text.hint,
  },
  textWithIcon: {
    display: "flex",
    alignItems: "center",
  },
  iconSm: {
    fontSize: 16,
    marginLeft: theme.spacing(1),
    cursor: "pointer",
  },
  scheduleIcon: {
    fontSize: "inherit",
    marginRight: "5px",
    marginTop: "-3px",
    verticalAlign: "middle",
  },
  clientLink: {
    textDecoration: "none",
  },
  lightTooltip: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const WorkItemList = ({ filter, sortBy, clearFilter, updateSort }) => {
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();
  const { tenant } = useTenant();

  const loggedInUser = useSelector(getLoggedInUser);
  const loading = useSelector((state) =>
    isLoadingAction(state, WORK_ITEM_LIST)
  );
  const workItems = useSelector(getWorkItems);
  const types = useSelector((state) =>
    getReferenceDataType(state, "WorkItemType")
  );
  const workItemTypes = useSelector((state) =>
    getReferenceDataType(state, "WorkItemType")
  );
  const statuses = useSelector((state) =>
    getReferenceDataType(state, "WorkItemStatus")
  );
  const priorities = useSelector((state) =>
    getReferenceDataType(state, "Priority")
  );
  const pagination = useSelector(getWorkItemSearchPagination);

  const dispatch = useDispatch();

  const visibleTypes = useMemo(
    () =>
      getFilteredTypes(workItemTypes, loggedInUser, tenant, {
        workItemTypes: filterableWorkItemTypes,
      }),
    [workItemTypes, loggedInUser, tenant]
  );

  const [lastSearchParameters, setLastSearchParameters] = useState(null);
  const arrowRef = useRef();

  const getOrderBy = () => {
    const prefix = sortBy.direction === "desc" ? "-" : "";
    return `${prefix}${sortBy.field}`;
  };

  const createSearchParameters = (offset) =>
    createWorkItemSearchParameters(
      filter,
      statuses,
      visibleTypes,
      loggedInUser,
      {
        ...pagination,
        offset,
      },
      getOrderBy()
    );

  const fetchWorkItems = async (searchParameters, forceReload) => {
    if (forceReload || !_.isEqual(searchParameters, lastSearchParameters)) {
      const accessToken = await getAccessTokenSilently();
      dispatch(loadWorkItems(searchParameters, accessToken));
      setLastSearchParameters(searchParameters);
    } else {
      logger.info(
        "Ignoring call to refresh work items because search parameters haven't changed"
      );
    }
  };

  const refreshDash = async () => {
    const searchParameters = createSearchParameters(pagination.offset);
    console.log("***** refreshDash", searchParameters);
    await fetchWorkItems(searchParameters, true);
  };

  useEffect(() => {
    (async () => {
      await fetchWorkItems(createSearchParameters(0), false);
    })();
  }, [filter, sortBy]);

  const setNewDueDate = async (workItem, dueDate) => {
    const accessToken = await getAccessTokenSilently();
    const typeDisplay = types.values[workItem.type].description;
    dispatch(
      patchWorkItem(
        workItem.id,
        { dueDate },
        `Edited ${typeDisplay}`,
        accessToken
      )
    ).then(refreshDash);
  };

  const setNewPriority = async (workItem, priority) => {
    const accessToken = await getAccessTokenSilently();
    const typeDisplay = types.values[workItem.type].description;
    dispatch(
      patchWorkItem(
        workItem.id,
        { priority },
        `Edited ${typeDisplay}`,
        accessToken
      )
    ).then(refreshDash);
  };

  const setUsers = async (workItem, users, type) => {
    const accessToken = await getAccessTokenSilently();
    dispatch(
      setWorkItemUsers(
        {
          workItemId: workItem.id,
          userIds: {
            [type]: users.map((user) => user.id),
          },
        },
        accessToken
      )
    ).then(refreshDash);
  };

  const setNewStatus = async (workItemId, newStatus) => {
    const accessToken = await getAccessTokenSilently();
    dispatch(
      setWorkItemStatus(
        workItemId,
        newStatus,
        undefined,
        undefined,
        refreshDash,
        accessToken
      )
    );
  };

  const handlePrevious = async () => {
    await fetchWorkItems(createSearchParameters(pagination.previousOffset));
  };

  const handleNext = async () => {
    await fetchWorkItems(createSearchParameters(pagination.nextOffset));
  };

  const isAgent = loggedInUser
    ? _.intersection(roles.AGENT_ROLES, loggedInUser.roles).length > 0
    : false;
  const editingDisabled =
    !loggedInUser ||
    _.intersection([...roles.EXTERNAL_ROLES, roles.VIEWER], loggedInUser.roles)
      .length > 0;

  const getTooltip = (workItem) => {
    if (isAgent) {
      const dataToRender = getDataToRender(workItem);
      return dataToRender ? (
        <DefaultWorkItemTooltip dataToRender={dataToRender} />
      ) : null;
    }
    return null;
  };

  const InfoIcon = infoIcon();
  const EditIcon = editIcon();
  const TypeParentIcon = typeParentIcon();
  const RecurringIcon = recurringIcon();

  const handleUpdateSort = useCallback(
    (field, direction) => updateSort({ field, direction }),
    [updateSort]
  );
  const handleHighlight1 = useCallback((workItem) => workItem.hasChildren, []);
  const handleHighlight2 = useCallback((workItem) => workItem.hasParent, []);
  const handleAction = useCallback(
    (workItem) => (
      <IconButton component={Link} to={getWorkItemLink(workItem)}>
        <EditIcon data-cy="edit" />
      </IconButton>
    ),
    []
  );

  const columns = useMemo(
    () => [
      {
        label: "Type",
        name: "type",
        size: 4,
        sortable: true,
        render: (workItem) => {
          const definition = getResolvedDefinition(
            types,
            workItem.type,
            workItem.parent?.type
          );
          const { name } = definition;
          const category = workItem.category_display;
          const riskRatingId = workItem.incidentAssessment
            ? workItem.incidentAssessment.riskConsequenceRating
            : null;
          const riskRatingText =
            workItem?.incidentAssessment?.riskConsequenceRating_display ||
            "Not yet rated";
          return (
            <>
              <WorkItemBlob type={workItem.type} description={name} />
              <Typography
                className={classes.nowrap}
                title={category}
                variant="caption"
                display="block"
                data-cy={category}
              >
                {category}
                {workItem.hasParent && (
                  <div className={classes.sub}>
                    <TypeParentIcon
                      title="Parent-item"
                      className={classes.scheduleIcon}
                    />
                    {types.values[workItem.parent.type].props.definition.name}
                  </div>
                )}
                {workItem.workItemSchedule && (
                  <div className={classes.sub}>
                    <RecurringIcon
                      title="Recurring task"
                      className={classes.scheduleIcon}
                    />
                    Recurring
                  </div>
                )}
              </Typography>
              {riskRatingId && !workItem.hasParent && (
                <RiskConsequenceRating
                  riskConsequenceRatingId={riskRatingId}
                  riskConsequenceRatingText={riskRatingText}
                />
              )}
            </>
          );
        },
      },
      {
        label: "Submitter",
        name: "createdBy",
        size: 3,
        sortable: true,
        render: (workItem) => <WorkItemSubmitter workItem={workItem} />,
      },
      {
        label: "Title",
        name: "title",
        size: 4,
        render: (workItem) => {
          const tooltip = getTooltip(workItem);
          return (
            <>
              <div className={classes.textWithIcon}>
                <Typography
                  className={classes.nowrap}
                  title={workItem.title}
                  data-cy={workItem.title}
                >
                  {workItem.title}
                </Typography>
                {tooltip && (
                  <Tooltip
                    classes={{
                      tooltip: classes.lightTooltip,
                    }}
                    title={tooltip}
                    enterDelay={100}
                    leaveDelay={100}
                    interactive
                    placement="top"
                    TransitionComponent={Zoom}
                    PopperProps={{
                      popperOptions: {
                        modifiers: {
                          arrow: {
                            enabled: Boolean(arrowRef.current),
                            element: arrowRef.current,
                          },
                        },
                      },
                    }}
                  >
                    <InfoIcon className={classes.iconSm} />
                  </Tooltip>
                )}
              </div>
              <Typography
                className={classes.nowrap}
                title={workItem.friendlyId}
                variant="caption"
                style={{ color: "#778088" }}
                data-cy={workItem.friendlyId}
              >
                {workItem.friendlyId}
              </Typography>
            </>
          );
        },
      },
      {
        label: "Priority",
        name: "priority",
        size: 2,
        sortable: true,
        render: (workItem) => {
          const readonly = workItem.status === "CLOSED";
          const definition = getResolvedDefinition(
            types,
            workItem.type,
            workItem.parent?.type
          );
          return (
            workItem.priority && (
              <WorkItemPriority
                workItem={workItem}
                priority={workItem.priority}
                onPriorityChange={
                  editingDisabled
                    ? null
                    : (priority) => setNewPriority(workItem, priority)
                }
                readonly={readonly}
                loggedInUser={loggedInUser}
                definition={definition}
                priorities={priorities}
              />
            )
          );
        },
      },
      {
        label: "Due",
        name: "dueDate",
        size: 3,
        sortable: true,
        render: (workItem) => {
          const dueDate = dates.parseDate(workItem.dueDate);
          const readonly = workItem.status === "CLOSED";
          const overdue = !readonly && dates.dateIsBeforeToday(dueDate);
          const definition = getResolvedDefinition(
            types,
            workItem.type,
            workItem.parent?.type
          );
          const { name, dueDateRules } = definition;
          const category = getReferenceDataDescription(
            "WorkItemCategory",
            workItem.category
          );
          const dueDateReadonly = dueDateRules.length
            ? dueDateRules.some(
                (dueDateRule) => dueDateRule.disableEditing === true
              )
            : false;

          return (
            <div>
              {dates.formatDateLong(dueDate)}
              {isAgent && false && (
                <WorkItemSlaPopover
                  workItem={workItem}
                  title={
                    <WorkItemBlob type={workItem.type} description={name} />
                  }
                  subtitle={category}
                />
              )}
              <DateButtonPicker
                labelComponent={<DueAgo value={dueDate} stayFull={readonly} />}
                dateValue={dueDate}
                readonly={readonly || dueDateReadonly}
                className={
                  overdue
                    ? classNames(classes.overdue)
                    : classNames(classes.due)
                }
                onDateChange={
                  editingDisabled
                    ? null
                    : (date) =>
                        setNewDueDate(workItem, dates.serializeDate(date))
                }
              />
            </div>
          );
        },
      },
      {
        label: "Status",
        name: "status",
        size: 2,
        sortable: true,
        render: (workItem) => {
          const definition = getResolvedDefinition(
            types,
            workItem.type,
            workItem.parent?.type
          );
          return (
            <>
              <WorkItemStatus
                workItem={workItem}
                onStatusChange={
                  editingDisabled
                    ? null
                    : (status) => setNewStatus(workItem.id, status)
                }
                closable={false}
                readonly={false}
                loggedInUser={loggedInUser}
                definition={definition}
              />
              <OutcomeChip outcome={workItem.outcome} defaultValue="" />
            </>
          );
        },
      },
      {
        label: "Owner",
        name: "owner",
        size: 2,
        sortable: true,
        render: (workItem) => {
          const readonly = workItem.status === "CLOSED";
          const { ownableBy, keyContactRules } =
            types.values[workItem.type].props.definition;
          const keyContactableBy = appliesByTemplate(workItem, keyContactRules)
            ? keyContactRules.roles
            : [];
          return (
            <>
              <WorkItemOwner
                owner={
                  getFirstWorkItemUserByType(workItem.users, "OWNER")?.user
                }
                userRoles={ownableBy}
                loggedInUser={loggedInUser}
                onOwnerChange={
                  editingDisabled
                    ? null
                    : (owner) =>
                        setUsers(workItem, owner ? [owner] : [], "OWNER", true)
                }
                readonly={readonly}
                data-cy={
                  getFirstWorkItemUserByType(workItem.users, "OWNER")?.user
                }
              />
              {keyContactableBy.length > 0 && (
                <WorkItemOwner
                  className={classes.keyContact}
                  owner={
                    getFirstWorkItemUserByType(workItem.users, "KEY_CONTACT")
                      ?.user
                  }
                  userRoles={keyContactableBy}
                  loggedInUser={loggedInUser}
                  onOwnerChange={
                    editingDisabled
                      ? null
                      : (keyContact) =>
                          setUsers(
                            workItem,
                            keyContact ? [keyContact] : [],
                            "KEY_CONTACT",
                            true
                          )
                  }
                  readonly={readonly}
                  title="Select key contact"
                  noValueText="No key contact"
                />
              )}
            </>
          );
        },
      },
      {
        label: "Created",
        name: "created",
        size: 2,
        sortable: true,
        render: (workItem) => (
          <TimeAgo value={dates.parseTimestamp(workItem.created)} expandable />
        ),
      },
      {
        label: "Updated",
        name: "updated",
        size: 2,
        sortable: true,
        render: (workItem) => (
          <TimeAgo value={dates.parseTimestamp(workItem.updated)} expandable />
        ),
      },
    ],
    [filter, sortBy]
  );

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <GridListing
        sortedData={workItems}
        loading={loading}
        sortBy={sortBy}
        updateSort={handleUpdateSort}
        clearFilter={clearFilter}
        action={handleAction}
        isHighlight1={handleHighlight1}
        isHighlight2={handleHighlight2}
        columns={columns}
      />
      <Pagination
        pagination={pagination}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
      />
    </>
  );
};

WorkItemList.propTypes = {
  filter: PropTypes.object.isRequired,
  sortBy: PropTypes.object.isRequired,
  clearFilter: PropTypes.func.isRequired,
  updateSort: PropTypes.func.isRequired,
};

export default WorkItemList;
