import "custom-event-polyfill";

const BUILD_TIME_EVENT = "BUILD_TIME";

const BUILD_TIME_HEADER = "build-time";

export const publishBuildTime = (buildTime) => {
  window.dispatchEvent(
    new CustomEvent(BUILD_TIME_EVENT, {
      detail: buildTime,
    })
  );
};

export const subscribeBuildTime = (listener) => {
  window.addEventListener(BUILD_TIME_EVENT, listener, false);
};

export const unsubscribeBuildTime = (listener) => {
  window.removeEventListener(BUILD_TIME_EVENT, listener, false);
};

export const checkBuildTimeHeader = (response) => {
  if (response.headers.has(BUILD_TIME_HEADER)) {
    publishBuildTime(response.headers.get(BUILD_TIME_HEADER));
  }

  return response;
};
