import React, { Component } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import { workItemIcon } from "../../util/icons";

const styles = (theme) => ({
  blob: {
    display: "inline-block",
    width: "auto",
    borderRadius: "5px",
    background: "#888",
    color: "white",
    padding: "5px 5px 3px 5px",
    fontSize: "12px",
    lineHeight: 1,
    textTransform: "uppercase",
    fontFamily: theme.typography.h1.fontFamily,
    verticalAlign: "bottom,",
  },
  icon: {
    lineHeight: "inherit",
    marginRight: "5px",
  },
});

export const workItemColor = (type) => {
  switch (type) {
    case "COMPLIANCE_TASK":
      return "#206522";
    case "CHANGE_APPROVAL":
      return "#5E932F";
    case "DISCLOSURE_APPROVAL":
      return "#1DB790";
    case "COMPLAINT":
      return "#B91D44";
    case "INCIDENT":
      return "#CF4C20";
    case "REQUEST_FOR_INFORMATION":
      return "#1D44B9";
    case "CONTRACT_APPROVAL":
      return "#8119A3";
    case "CLAIMS":
      return "#A38119";
    default:
      return "#000000";
  }
};

class WorkItemBlob extends Component {
  render() {
    const { classes, type, description } = this.props;

    const WorkItemTypeIcon = workItemIcon(type);
    const shortDescription = description.replace(" Approval", ""); // Unnecessary

    return (
      <div
        className={classes.blob}
        style={{ backgroundColor: workItemColor(type) }}
      >
        <Grid container spacing={0}>
          <Grid item>
            <WorkItemTypeIcon fontSize="inherit" className={classes.icon} />
          </Grid>
          <Grid item xs>
            {shortDescription}
          </Grid>
        </Grid>
      </div>
    );
  }
}

WorkItemBlob.propTypes = {
  classes: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.string,
};

WorkItemBlob.defaultProps = {
  type: undefined,
};

export default withStyles(styles)(WorkItemBlob);
