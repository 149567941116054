export const dashboardPath = () => "/dashboard";
export const workItemsBase = () => "/work-items";
export const workItemSchedulesPath = () => "/work-item-schedules";
export const workItemSchedulePath = (id) => `/work-item-schedules/${id}`;
export const auditReportPath = (id) => `/audits/${id}`;
export const controlPath = (id) => `/controls/${id}`;

export const getRiskLink = (risk) => `/risk/risks/${risk.id}`;

export const getWorkItemLink = (workItem) => {
  switch (workItem.type) {
    case "RISK_ACTION":
      return `/risk/risks/actions/${workItem.id}`;
    case "RISK_REASSESSMENT":
      return `/risk/risks/reassessments/${workItem.id}`;
    case "RISK_MEASURE_UPDATE":
      return `/risk/risks/measure-updates/${workItem.id}`;
    case "RISK_CONTROL_VERIFICATION":
      return `/risk/controls/control-verifications/${workItem.id}`;
    default:
      return `/work-items/${workItem.id}`;
  }
};
