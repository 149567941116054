import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { searchOrganisations } from "../../actions/organisations";
import {
  getLoggedInUser,
  getOrganisationLastEdit,
  getOrganisationPagination,
  getOrganisations,
  getReferenceDataDescription,
  isLoading,
} from "../../reducers";
import dates from "../../util/dates";
import GridListing from "../common/GridListing";
import TimeAgo from "../common/TimeAgo";
import Pagination from "../common/Pagination";
import { useAuth0 } from "@auth0/auth0-react";

const styles = (theme) => ({
  row: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.grey[200],
    },
  },
  container: {
    paddingTop: theme.spacing(2),
  },
  label: {
    lineHeight: "1.5rem",
  },
});

const OrganisationListing = ({
  onSelect,
  selected,
  pagination,
  lastEdit,
  organisations,
  sortBy,
  filter,
  loading,
  localSearchOrganisations,
  updateSort,
  clearFilter,
  localGetReferenceDataDescription,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const getOrderBy = () => {
    const prefix = sortBy.direction === "desc" ? "-" : "";
    return `${prefix}${sortBy.field}`;
  };

  const getSearchParameters = () => ({
    textSearch: filter.textSearch,
    orderByField: getOrderBy(),
    limit: pagination.pageSize,
    offset: pagination.offset,
    excludeGroupFilters: true,
  });

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      localSearchOrganisations(getSearchParameters(), accessToken);
    })();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    (async () => {
      const searchParameters = {
        ...getSearchParameters(),
        offset: 0,
      };
      const accessToken = await getAccessTokenSilently();
      localSearchOrganisations(searchParameters, accessToken);
    })();
  }, [getAccessTokenSilently, filter, sortBy, lastEdit]);

  const handlePrevious = async () => {
    const searchParameters = {
      ...getSearchParameters(),
      offset: pagination.previousOffset,
    };
    const accessToken = await getAccessTokenSilently();
    localSearchOrganisations(searchParameters, accessToken);
  };

  const handleNext = async () => {
    const searchParameters = {
      ...getSearchParameters(),
      offset: pagination.nextOffset,
    };
    const accessToken = await getAccessTokenSilently();
    localSearchOrganisations(searchParameters, accessToken);
  };

  return (
    <>
      <GridListing
        sortedData={organisations}
        loading={loading}
        sortBy={sortBy}
        dense={false}
        isHighlight1={(organisation) =>
          selected && selected.id === organisation.id
        }
        updateSort={(field, direction) =>
          updateSort({
            field,
            direction,
          })
        }
        clearFilter={clearFilter}
        onClick={(organisation) => onSelect(organisation)}
        columns={[
          {
            label: "Name",
            name: "name",
            size: 3,
            sortable: true,
            render: (organisation) => <>{organisation.name}</>,
          },
          {
            label: "Type",
            name: "type",
            size: 2,
            sortable: true,
            render: (organisation) => (
              <>
                {localGetReferenceDataDescription(
                  "OrganisationType",
                  organisation.type
                )}
              </>
            ),
          },
          {
            label: "Friendly id",
            name: "friendlyId",
            size: 2,
            sortable: true,
            render: (organisation) => <>{organisation.friendlyId ?? "–"}</>,
          },
          {
            label: "Region",
            name: "region",
            size: 1,
            sortable: true,
            render: (org) => (
              <>{localGetReferenceDataDescription("Region", org.region)}</>
            ),
          },
          {
            label: "Created",
            name: "created",
            size: 2,
            sortable: true,
            render: (organisation) => (
              <TimeAgo
                value={dates.parseTimestamp(organisation.created)}
                expandable
              />
            ),
          },
          {
            label: "Updated",
            name: "updated",
            size: 2,
            sortable: true,
            render: (organisation) => (
              <TimeAgo
                value={dates.parseTimestamp(organisation.updated)}
                expandable
              />
            ),
          },
        ]}
      />
      <Pagination
        pagination={pagination}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
      />
    </>
  );
};

OrganisationListing.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.object,
  filter: PropTypes.object.isRequired,
  sortBy: PropTypes.object.isRequired,
  updateSort: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired,

  // redux
  organisations: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  lastEdit: PropTypes.number.isRequired,
  localSearchOrganisations: PropTypes.func.isRequired,
  localGetReferenceDataDescription: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

OrganisationListing.defaultProps = {
  selected: null,
};

const mapStateToProps = (state) => ({
  loading: isLoading(state),
  loggedInUser: getLoggedInUser(state),
  organisations: getOrganisations(state),
  pagination: getOrganisationPagination(state),
  lastEdit: getOrganisationLastEdit(state),
  localGetReferenceDataDescription: (type, value) =>
    getReferenceDataDescription(state, type, value, "-"),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    localSearchOrganisations: searchOrganisations,
  })
)(OrganisationListing);
