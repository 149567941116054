import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import { warningIcon } from "../util/icons";
import * as PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  panelBorder: {
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
    border: `1px solid ${theme.palette.warning.dark}`,
    borderRadius: "16px",
    color: theme.palette.warning.dark,
  },
  panelNoBorder: {
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
    color: theme.palette.warning.dark,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

const WarningText = ({ text, border }) => {
  const classes = useStyles();
  const WarningIcon = warningIcon();
  return (
    <div className={border ? classes.panelBorder : classes.panelNoBorder}>
      <WarningIcon className={classes.icon} />
      <div>{text}</div>
    </div>
  );
};

WarningText.propTypes = {
  text: PropTypes.any.isRequired,
  border: PropTypes.bool,
};

WarningText.defaultProps = {
  border: false,
};

export default WarningText;
