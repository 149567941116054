import { Field, FieldArray } from "redux-form";
import WrappedTags from "../../wrapper/WrappedTags";
import { length } from "redux-form-validators";
import React from "react";
import { connect } from "react-redux";
import FormLabel from "@material-ui/core/FormLabel";
import CapitalImpactFields from "../CapitalImpactFields";
import WrappedSelect from "../../wrapper/WrappedSelect";
import { getRefDataMenuItems } from "../../menuItems";
import PropTypes from "prop-types";
import { getReferenceDataType, getTags } from "../../../../reducers";

const RiskDetailFields = ({ fieldLabels, tags, riskAppetites }) => {
  return (
    <>
      <Field
        name="sourcesOfRisk"
        component={WrappedTags}
        label={fieldLabels.labels.sourcesOfRisk}
        validate={length({
          min: 1,
          msg: `${fieldLabels.labels.sourcesOfRisk} is required`,
        })}
        tags={tags}
        tagType="RISK_SOURCE"
        placeholder="Enter risk source(s)..."
        fullWidth
        required
        isMulti
        data-cy="sourcesOfRisk"
      />

      <Field
        name="consequencesOfRisk"
        component={WrappedTags}
        label={fieldLabels.labels.consequencesOfRisk}
        validate={length({
          min: 1,
          msg: `${fieldLabels.labels.consequencesOfRisk} is required`,
        })}
        tags={tags}
        tagType="RISK_CONSEQUENCE"
        placeholder="Enter risk consequence(s)..."
        fullWidth
        required
        isMulti
        data-cy="consequencesOfRisk"
      />

      <Field
        name="riskRegisters"
        component={WrappedTags}
        label={fieldLabels.labels.riskRegisters}
        validate={length({
          min: 1,
          msg: `${fieldLabels.labels.riskRegisters} is required`,
        })}
        tags={tags}
        tagType="RISK_REGISTER"
        placeholder="Enter risk register(s)..."
        fullWidth
        required
        isMulti
        data-cy="riskRegisters"
      />
      <br />
      <FormLabel data-cy={fieldLabels.labels.capitalImpacts}>
        {fieldLabels.labels.capitalImpacts}
      </FormLabel>
      <br />
      <FieldArray
        name="capitalImpacts"
        component={CapitalImpactFields}
        data-cy={CapitalImpactFields}
      />

      <Field
        component={WrappedSelect}
        name="appetite"
        data-cy="appetite"
        label={fieldLabels.labels.appetite}
        fullWidth
      >
        {getRefDataMenuItems(riskAppetites)}
      </Field>
    </>
  );
};

RiskDetailFields.propTypes = {
  fieldLabels: PropTypes.object.isRequired,

  // redux
  riskAppetites: PropTypes.object.isRequired,
  tags: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  riskAppetites: getReferenceDataType(state, "RiskAppetite"),
  tags: getTags(state),
});

export default connect(mapStateToProps)(RiskDetailFields);
