import { useAuth0 } from "@auth0/auth0-react";
import { Card } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import CheckCircle from "@material-ui/icons/CheckCircle";
import { clearActiveControl, createControl } from "../../actions/controls";
import BreadcrumbLink from "../../components/common/BreadcrumbLink";
import Container from "../../components/common/Container";
import HeaderBar from "../../components/common/HeaderBar";
import PageHeading from "../../components/common/PageHeading";
import CreateControlForm, {
  CREATE_CONTROL_FORM_NAME,
} from "../../components/forms/controls/CreateControlForm";
import FormWrapper from "../../components/forms/FormWrapper";
import { getActiveControl } from "../../reducers";

const styles = (theme) => ({
  successPanel: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    width: "600px",
    textAlign: "center",
  },
  refNumber: {
    marginBottom: theme.spacing(4),
  },
  button: {
    marginLeft: "10px",
    "&:first-child": {
      marginLeft: 0,
    },
  },
  successIcon: {
    fontSize: "80px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  container: {
    marginTop: theme.spacing(2),
  },
});

const New = ({
  classes,
  createdControl,
  localClearActiveControl,
  localCreateControl,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    localClearActiveControl();
  }, []);

  const handleSubmission = async (values) => {
    const accessToken = await getAccessTokenSilently();
    return localCreateControl(values, accessToken);
  };

  return (
    <div>
      <HeaderBar narrow>
        <BreadcrumbLink to="/controls" label="Controls" includeArrow />
        <PageHeading heading="Create new control" />
      </HeaderBar>
      <Container narrow className={classes.container}>
        {createdControl && (
          <Grid
            container
            alignItems="center"
            direction="row"
            justifyContent="center"
          >
            <Paper elevation={0} className={classes.successPanel}>
              <Typography variant="h4" data-cy="controlCreated">
                The control has been created.
              </Typography>
              <CheckCircle className={classes.successIcon} />
              <div>
                <Button
                  className={classes.button}
                  component={Link}
                  to={`/controls/${createdControl.id}`}
                  variant="contained"
                  color="primary"
                  size="large"
                  data-cy="viewControl"
                >
                  View control
                </Button>
                <Button
                  className={classes.button}
                  component={Link}
                  to="/controls"
                  variant="contained"
                  color="default"
                  size="large"
                  data-cy="returnToControls"
                >
                  Return to controls
                </Button>
              </div>
            </Paper>
          </Grid>
        )}
        {!createdControl && (
          <Card>
            <CardContent>
              <FormWrapper
                submitButtonText="Create"
                formComponent={CreateControlForm}
                formName={CREATE_CONTROL_FORM_NAME}
                onSubmit={handleSubmission}
                initialValues={{ critical: false }}
                data-cy={CREATE_CONTROL_FORM_NAME}
              />
            </CardContent>
          </Card>
        )}
      </Container>
    </div>
  );
};

New.propTypes = {
  createdControl: PropTypes.object,
  localCreateControl: PropTypes.func.isRequired,
  localClearActiveControl: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

New.defaultProps = {
  createdControl: null,
};

const mapStateToProps = (state) => ({
  createdControl: getActiveControl(state),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    localCreateControl: createControl,
    localClearActiveControl: clearActiveControl,
  })
)(New);
