import PropTypes from "prop-types";
import React from "react";
import ComponentField from "../../../forms/wrapper/ComponentField";
import WrappedRadioGroup from "../../../forms/wrapper/WrappedRadioGroup";

const yesNoOptions = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

const TerminalIllnessFields = ({
  fieldLabels,
  customisations,
  change,
  untouch,
  formName,
}) => (
  <>
    <ComponentField
      component={WrappedRadioGroup}
      name="terminalIllness.memberReportsStateUnlikelyToSurvive24Months"
      label={
        fieldLabels.nestedTypes.terminalIllness.labels
          .memberReportsStateUnlikelyToSurvive24Months
      }
      options={yesNoOptions}
      row
      customisations={customisations}
      change={change}
      untouch={untouch}
      formName={formName}
      data-cy="terminalIllnessmemberReportsStateUnlikelyToSurvive24Months"
    />
    <ComponentField
      component={WrappedRadioGroup}
      name="terminalIllness.declarationBySpecialistInFieldOfIllness"
      label={
        fieldLabels.nestedTypes.terminalIllness.labels
          .declarationBySpecialistInFieldOfIllness
      }
      options={yesNoOptions}
      row
      customisations={customisations}
      change={change}
      untouch={untouch}
      formName={formName}
      data-cy="terminalIllnessdeclarationBySpecialistInFieldOfIllness"
    />
  </>
);

TerminalIllnessFields.propTypes = {
  fieldLabels: PropTypes.object.isRequired,
  customisations: PropTypes.array,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
};

TerminalIllnessFields.defaultProps = {
  customisations: [],
};

export default TerminalIllnessFields;
