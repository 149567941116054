import { requestJSON } from "./http";

const listActivities = (workItemId, accessToken) =>
  requestJSON({ path: `/work-items/${workItemId}/activities`, accessToken });

const listAuditLogs = (workItemId, accessToken) =>
  requestJSON({ path: `/work-items/${workItemId}/audit-logs`, accessToken });

export default {
  listActivities,
  listAuditLogs,
};
