import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { publishToastInfo } from "../../services/toasts";

const UrlCopyField = ({ url, label, margin }) => {
  const onCopy = () => {
    publishToastInfo("Copied to clipboard");
  };

  return (
    <TextField
      data-cy={label}
      label={label}
      value={url}
      readOnly
      fullWidth
      margin={margin}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <CopyToClipboard text={url} onCopy={onCopy}>
              <Button size="small" color="primary" data-cy="copy">
                Copy
              </Button>
            </CopyToClipboard>
          </InputAdornment>
        ),
      }}
    />
  );
};

UrlCopyField.propTypes = {
  label: PropTypes.string,
  url: PropTypes.string.isRequired,
  margin: PropTypes.string,
};

UrlCopyField.defaultProps = {
  label: "",
  margin: "dense",
};

export default UrlCopyField;
