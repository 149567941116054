import {
  getActiveTenantParam,
  includeTenantParam,
} from "@certane/arcadia-web-components";
import "custom-event-polyfill";
import logger from "../../util/logger";
import { checkBuildTimeHeader } from "../buildInfo";
import { checkNotificationLastUpdatedHeader } from "../notificationAdapter";
import NetworkError from "./NetworkError";

export const basePath = process.env.REACT_APP_ARCADIA_API_HOST || "";
export const baseApiPath = `${basePath}/api`;

const commonHeaders = () => ({
  pragma: "no-cache",
  "cache-control": "no-cache", // required so IE11 does not automatically cache all GET requests
  "x-requested-with": "XMLHttpRequest", // Suppress the gray basic auth dialog in the browser on 401
});

export const formEncode = (obj) =>
  Object.keys(obj)
    .map((k) => `${k}=${encodeURIComponent(obj[k])}`)
    .join("&");

const request = ({
  path,
  method = "GET",
  data = null,
  headers = {},
  abortController,
  rootPath,
}) => {
  const url = `${rootPath || baseApiPath}${path}`;

  logger.debug(`${method} ${url}`);

  const config = {
    method,
    headers: { ...commonHeaders(), ...headers },
    // credentials: "include",
    signal: abortController ? abortController.signal : undefined,
  };

  // Edge browsers will fail silently if you give a body, even a null one, to a GET request
  if (data) {
    config.body = data;
  }

  return fetch(url, config).then(
    (response) => {
      if (response.ok) {
        return response;
      }

      if (response.status === 401) {
        // session has expired
        // window.location.href = `${basePath}/login?next=${window.location.href}`;
        throw new Error("Session Timeout");
      }

      if (response.status === 403) {
        // forbidden (include tenant)
        window.location.href = includeTenantParam("/forbidden");
        throw new Error("Forbidden");
      }

      if (response.status === 451) {
        // forbidden (include tenant)
        window.location.href = includeTenantParam("/unavailable");
        throw new Error("Unavailable");
      }

      return response.text().then((text) => {
        let error;

        try {
          // Attempt to parse body as JSON, fallback to plain text if parsing fails
          const data = JSON.parse(text);
          error = new Error(data.message);
          error.type = data.type || data.error;
        } catch (e) {
          // Fallback to plain text
          error = new Error(response.statusText);
        }

        error.status = response.status;
        error.payload = text;

        throw error;
      });
    },
    (error) => {
      if (error.name === "TypeError") {
        logger.warn(
          `Network error has occurred named ${error.name} with message ${error.message}!`
        );
        throw new NetworkError(
          "You're offline! Please check your network connection."
        );
      }
      throw error;
    }
  );
};

const hasHeader = (headers = {}, headerName) =>
  Object.keys(headers).some(
    (key) => key.toLowerCase() === headerName.toLowerCase()
  );

const handleData = (req) => {
  const { data, headers } = req;
  const headerContentType = "Content-Type";
  // Don't modify for FormData or request with existing content-type header set
  if (
    !data ||
    data instanceof FormData ||
    hasHeader(headers, headerContentType)
  ) {
    return req;
  }
  return {
    ...req,
    data: JSON.stringify(data),
    headers: { [headerContentType]: "application/json", ...headers },
  };
};

const handleAccessToken = (req) => {
  const headerAuthorization = "Authorization";
  const { accessToken, headers } = req;
  if (!accessToken || hasHeader(headers, headerAuthorization)) {
    return req;
  }
  return {
    ...req,
    headers: { [headerAuthorization]: `Bearer ${accessToken}`, ...headers },
  };
};

const handleTenantHeader = (req) => {
  return {
    ...req,
    headers: { "x-tenant": getActiveTenantParam(), ...req.headers },
  };
};

/**
 * Request is an object containing:
 *  accessToken?
 *  path
 *  method?
 *  data?
 *  headers?
 *  abortController?
 * @param req
 */
export const requestJSON = (req) =>
  request(handleAccessToken(handleTenantHeader(handleData(req))))
    .then(checkBuildTimeHeader)
    .then(checkNotificationLastUpdatedHeader)
    .then((response) => response.text())
    .then((responseText) => responseText && JSON.parse(responseText));

/**
 * Request is an object containing:
 *  accessToken?
 *  path
 *  method?
 *  data?
 *  headers?
 *  abortController?
 * @param req
 */
export const requestText = (req) =>
  request(handleAccessToken(handleTenantHeader(handleData(req))))
    .then(checkBuildTimeHeader)
    .then(checkNotificationLastUpdatedHeader)
    .then((response) => response.text());
