import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Menu from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/core/styles";
import LabelStateButton from "./LabelStateButton";

const styles = () => ({
  menu: {
    fontSize: "inherit",
  },
});

const LabelStateSelect = ({
  classes,
  className,
  readonly,
  label,
  children,
  setCloser,
  onClick,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setCloser(() => onCloseMenu());
  }, []);

  const onOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <LabelStateButton
        className={className}
        label={label}
        onClick={onClick || onOpenMenu}
        aria-owns={anchorEl ? "simple-menu" : null}
        aria-haspopup="true"
        readonly={readonly}
        data-cy={label}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onCloseMenu}
        className={classes.menu}
        data-cy="menu"
      >
        {children}
      </Menu>
    </div>
  );
};

LabelStateSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  label: PropTypes.any.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  readonly: PropTypes.bool,
  setCloser: PropTypes.func.isRequired,
  onClick: PropTypes.func,
};

LabelStateSelect.defaultProps = {
  className: undefined,
  readonly: false,
  onClick: null,
};

export default withStyles(styles)(LabelStateSelect);
