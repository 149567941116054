import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Slide from "@material-ui/core/Slide";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { compose } from "redux";
import dates from "../../../../util/dates";
import TimeAgo from "../../../common/TimeAgo";

const styles = (theme) => ({
  dismissAllButton: {
    position: "absolute",
    top: theme.spacing(1) / 2,
    right: theme.spacing(1) / 2,
  },
  listItemTextRead: {
    color: theme.palette.text.disabled,
  },
});

class NotificationCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNotifications: true,
    };
  }

  render() {
    const { classes, notifications, onNotificationClick, onDismissAllClick } =
      this.props;
    return (
      <List
        dense
        subheader={
          <ListSubheader component="div">
            <span>Notifications</span>
            {notifications.length > 0 && (
              <IconButton
                className={classes.dismissAllButton}
                title="Dismiss all"
                onClick={() => {
                  this.setState({ showNotifications: false });
                  onDismissAllClick();
                }}
              >
                <Icon>clear_all</Icon>
              </IconButton>
            )}
          </ListSubheader>
        }
      >
        {notifications.length === 0 && (
          <ListItem>
            <ListItemText primary="No unread notifications" />
          </ListItem>
        )}
        {notifications.length > 0 &&
          notifications.map((notification, index) => (
            <Slide
              key={notification.id}
              direction="left"
              in={this.state.showNotifications}
              mountOnEnter
              unmountOnExit
              timeout={{ enter: 0, exit: index * 150 }}
            >
              <ListItem
                key={notification.id}
                button
                onClick={() => onNotificationClick(notification)}
              >
                <ListItemIcon>
                  <Icon>{notification.icon || "info"}</Icon>
                </ListItemIcon>
                <ListItemText
                  classes={{
                    primary: notification.read && classes.listItemTextRead,
                    secondary: notification.read && classes.listItemTextRead,
                  }}
                  secondary={notification.body}
                >
                  <TimeAgo
                    value={dates.parseTimestamp(notification.timestamp)}
                    expandable
                  />
                  <div>{notification.title}</div>
                </ListItemText>
                <ListItemSecondaryAction>
                  <IconButton
                    title="Dismiss"
                    onClick={() => this.props.onDismissClick(notification)}
                  >
                    <Icon>delete_forever</Icon>
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </Slide>
          ))}
      </List>
    );
  }
}

NotificationCard.propTypes = {
  classes: PropTypes.object.isRequired,
  onNotificationClick: PropTypes.func.isRequired,
  onDismissAllClick: PropTypes.func.isRequired,
  onDismissClick: PropTypes.func.isRequired,
  notifications: PropTypes.array,
};

NotificationCard.defaultProps = {
  notifications: [],
};

export default compose(withRouter, withStyles(styles))(NotificationCard);
