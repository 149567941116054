import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import tinycolor from "tinycolor2";

const styles = (theme) => ({
  box: {},
  path: {
    color: "inherit",
    display: "inline-block",
    fontWeight: 600,
  },
  added: {
    color: "inherit",
    backgroundColor: tinycolor(theme.palette.success.main).setAlpha(0.1),
    display: "inline-block",
  },
  removed: {
    color: "inherit",
    backgroundColor: tinycolor(theme.palette.error.main).setAlpha(0.1),
    textDecoration: "line-through",
    display: "inline-block",
  },
  text: {
    marginLeft: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  toggleText: {
    color: theme.palette.text.secondary,
  },
  title: {
    color: theme.palette.text.secondary,
  },
  button: {
    color: theme.palette.secondary.dark,
    border: 0,
    padding: 0,
    margin: 0,
    cursor: "pointer",
  },
});

const containsDiffs = (entry) => _.has(entry, "fieldChanges");

const ignore = [
  "threewks.model.workitem.WorkItemStatus",
  "threewks.model.Document",
  "threewks.model.workitem.Endorsement",
  "threewks.model.workitem.EndorsementType",
];

const ignorePath = ["endorsements", "metropolisLinkedDocuments"];

const ignorePathEntityStubs = [
  "collaborators",
  "submitterContacts",
  "owner",
  "keyContact",
  "users",
];

const formatDisplayPath = (arr) => `${_.join(arr, " > ")}`;
const dropRightIfApplicable = (arr) =>
  arr.length > 1 ? _.dropRight(arr) : arr;
const formatMutationTitleDisplayPath = (arr) =>
  `${_.join(dropRightIfApplicable(arr), " > ")}`;
const formatMutationDisplayPath = (arr) => `${_.last(arr)}`;

const useDiffs = (entry) => {
  const [diffs, setDiffs] = useState([]);

  useEffect(() => {
    if (containsDiffs(entry)) {
      setDiffs(
        entry.fieldChanges
          .filter((diff) => !_.includes(ignore, diff.type))
          .filter(
            (diff) =>
              !_.some(ignorePath, (path) => _.first(diff.path).startsWith(path))
          )
          .filter(
            (diff) =>
              !_.some(ignorePathEntityStubs, (path) =>
                _.first(diff.path).startsWith(path)
              )
          )
          .map((diff) => ({
            ...diff,
            changelog: _.zip(diff.before, diff.after),
          }))
      );
    }
  }, [entry]);

  return [diffs.length > 0, diffs];
};

const Add = ({ classes, item }) => (
  <Typography variant="body2" className={classes.text}>
    <em className={classes.path}>{formatDisplayPath(item.displayPath)}</em> was
    set as <span className={classes.added}>{item.displayValue}</span>
  </Typography>
);

const Change = ({ classes, path, item }) => {
  const [before, after] = item;

  return (
    <Typography variant="body2" className={classes.text}>
      <em className={classes.path}>{path}</em> changed from{" "}
      <span className={classes.removed}>{before.displayValue}</span> to{" "}
      <span className={classes.added}>{after.displayValue}</span>
    </Typography>
  );
};

const Delete = ({ classes, item }) => (
  <Typography variant="body2" className={classes.text}>
    <em className={classes.path}>{formatDisplayPath(item.displayPath)}</em> was
    deleted, previously set as{" "}
    <span className={classes.removed}>{item.displayValue}</span>
  </Typography>
);

const ChangeComponent = ({ classes, diff }) => {
  switch (diff.changeType) {
    case "ADDITION":
      return diff.after.map((item) => (
        <Add
          key={item.path ? item.path.join(".") : item.value}
          classes={classes}
          item={item}
        />
      ));
    case "MUTATION":
      return diff.changelog.map((item) => (
        <Change
          key={JSON.stringify(item)}
          classes={classes}
          item={item}
          path={formatMutationDisplayPath(diff.displayPath)}
        />
      ));
    case "REMOVAL":
      return diff.before.map((item) => (
        <Delete
          key={item.path ? item.path.join(".") : item.value}
          classes={classes}
          item={item}
        />
      ));
    default:
      return <></>;
  }
};

const Toggle = ({ classes, children }) => {
  const [isVisible, setVisible] = useState(false);

  return (
    <div>
      {isVisible && <>{children}</>}
      {!isVisible && (
        <Typography variant="body2" className={classes.toggleText}>
          Made changes to this item...{" "}
          <button
            className={classes.button}
            type="button"
            onClick={() => setVisible(true)}
          >
            view details
          </button>
        </Typography>
      )}
    </div>
  );
};

const Diff = ({ classes, entry }) => {
  const [hasDiffs, diffs] = useDiffs(entry);

  return (
    <>
      {hasDiffs && (
        <Toggle classes={classes}>
          {diffs.map((diff) => (
            <div className={classes.box} key={diff.path.join(".")}>
              <Typography variant="body2" className={classes.title}>
                {diff.changeType === "MUTATION" && (
                  <span className={classes.path}>
                    {formatMutationTitleDisplayPath(diff.displayPath)}
                  </span>
                )}
                {diff.changeType !== "MUTATION" && (
                  <span className={classes.path}>
                    {formatDisplayPath(diff.displayPath)}
                  </span>
                )}
              </Typography>
              <ChangeComponent classes={classes} diff={diff} />
            </div>
          ))}
        </Toggle>
      )}
    </>
  );
};

Diff.propTypes = {
  classes: PropTypes.object.isRequired,
  entry: PropTypes.object.isRequired,
};

Toggle.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

ChangeComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  diff: PropTypes.object.isRequired,
};

Delete.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
};

Change.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.array.isRequired,
  path: PropTypes.string.isRequired,
};

Add.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
};

export default withStyles(styles)(Diff);
