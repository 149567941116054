import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { useAuth0 } from "@auth0/auth0-react";
import { searchUserGroups } from "../../actions/userGroups";
import Pagination from "../common/Pagination";
import {
  getLoggedInUser,
  getUserGroupLastEdit,
  getUserGroupPagination,
  getUserGroups,
  isLoading,
} from "../../reducers";
import dates from "../../util/dates";
import GridListing from "../common/GridListing";
import TimeAgo from "../common/TimeAgo";

const styles = () => ({
  organisationList: {
    marginBlockStart: 0,
    marginBlockEnd: 0,
  },
});

const UserGroupListing = ({
  classes,
  onSelect,
  selected,
  pagination,
  lastEdit,
  userGroups,
  sortBy,
  filter,
  loading,
  localSearchUserGroups,
  updateSort,
  clearFilter,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const getOrderBy = () => {
    const prefix = sortBy.direction === "desc" ? "-" : "";
    return `${prefix}${sortBy.field}`;
  };

  const getSearchParameters = () => ({
    textSearch: filter.textSearch,
    orderByField: getOrderBy(),
    limit: pagination.pageSize,
    offset: pagination.offset,
  });

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      const searchParameters = {
        ...getSearchParameters(),
        offset: 0,
      };
      localSearchUserGroups(searchParameters, accessToken);
    })();
  }, [getAccessTokenSilently, filter, sortBy, lastEdit]);

  const handlePrevious = () => {
    const searchParameters = {
      ...getSearchParameters(),
      offset: pagination.previousOffset,
    };
    localSearchUserGroups(searchParameters);
  };

  const handleNext = () => {
    const searchParameters = {
      ...getSearchParameters(),
      offset: pagination.nextOffset,
    };
    localSearchUserGroups(searchParameters);
  };

  return (
    <>
      <GridListing
        sortedData={userGroups}
        loading={loading}
        sortBy={sortBy}
        dense={false}
        isHighlight1={(userGroup) => selected && selected.id === userGroup.id}
        updateSort={(field, direction) =>
          updateSort({
            field,
            direction,
          })
        }
        clearFilter={clearFilter}
        onClick={(userGroup) => onSelect(userGroup)}
        columns={[
          {
            label: "Friendly Id",
            name: "friendlyId",
            size: 1,
            sortable: true,
            render: (userGroup) => <>{userGroup.friendlyId}</>,
          },
          {
            label: "Name",
            name: "name",
            size: 3,
            sortable: true,
            render: (userGroup) => <>{userGroup.name}</>,
          },
          {
            label: "Organisations",
            name: "organisations",
            size: 4,
            render: (userGroup) => (
              <ul className={classes.organisationList}>
                {userGroup.organisations.map((org) => (
                  <li key={org.id}>{org.name}</li>
                ))}
              </ul>
            ),
          },
          {
            label: "Created",
            name: "created",
            size: 2,
            sortable: true,
            render: (userGroup) => (
              <TimeAgo
                value={dates.parseTimestamp(userGroup.created)}
                expandable
              />
            ),
          },
          {
            label: "Updated",
            name: "updated",
            size: 2,
            sortable: true,
            render: (userGroup) => (
              <TimeAgo
                value={dates.parseTimestamp(userGroup.updated)}
                expandable
              />
            ),
          },
        ]}
      />
      <Pagination
        pagination={pagination}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
      />
    </>
  );
};

UserGroupListing.propTypes = {
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.object,
  filter: PropTypes.object.isRequired,
  sortBy: PropTypes.object.isRequired,
  updateSort: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired,

  // redux
  userGroups: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  lastEdit: PropTypes.number.isRequired,
  localSearchUserGroups: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

UserGroupListing.defaultProps = {
  selected: null,
};

const mapStateToProps = (state) => ({
  loading: isLoading(state),
  loggedInUser: getLoggedInUser(state),
  userGroups: getUserGroups(state),
  pagination: getUserGroupPagination(state),
  lastEdit: getUserGroupLastEdit(state),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    localSearchUserGroups: searchUserGroups,
  })
)(UserGroupListing);
