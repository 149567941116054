import IconButton from "@material-ui/core/IconButton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import ViewIcon from "@material-ui/icons/OpenInNew";
import React from "react";
import { Link } from "react-router-dom";
import { WorkItemSummaryDto } from "../../../../model/WorkItem";
import { getWorkItemLink } from "../../../../routes/routeUtils";
import dates from "../../../../util/dates";
import { getFirstWorkItemUserByType } from "../../../../util/workItemUserUtils";
import GridListing from "../../../common/GridListing";
import TimeAgo from "../../../common/TimeAgo";
import WorkItemBlob from "../../../workitem/WorkItemBlob";
import WorkItemSubmitter from "../../../workitem/WorkItemSubmitter";

interface Props {
  workItems: WorkItemSummaryDto[];
}

const useStyles = makeStyles(() => ({
  nowrap: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  textWithIcon: {
    display: "flex",
    alignItems: "center",
  },
}));

const WorkItemBulkUpdateResults: React.FC<Props> = ({ workItems }: Props) => {
  const classes = useStyles();

  return (
    <GridListing
      loading={false}
      sortedData={workItems}
      action={(workItem) => (
        <IconButton
          component={Link}
          to={getWorkItemLink(workItem)}
          target={"_blank"}
        >
          <ViewIcon />
        </IconButton>
      )}
      columns={[
        {
          label: "Type",
          name: "type",
          size: 4,
          sortable: true,
          render: (workItem: WorkItemSummaryDto) => {
            const category = workItem.category_display;
            return (
              <>
                <WorkItemBlob
                  type={workItem.type}
                  description={workItem.type}
                />
                <Typography
                  className={classes.nowrap}
                  title={category}
                  variant="caption"
                  display="block"
                >
                  {category}
                </Typography>
              </>
            );
          },
        },
        {
          label: "Submitter",
          name: "createdBy",
          size: 3,
          sortable: true,
          render: (workItem: WorkItemSummaryDto) => (
            <WorkItemSubmitter workItem={workItem} />
          ),
        },
        {
          label: "Title",
          name: "title",
          size: 4,
          render: (workItem: WorkItemSummaryDto) => {
            return (
              <>
                <div className={classes.textWithIcon}>
                  <Typography className={classes.nowrap} title={workItem.title}>
                    {workItem.title}
                  </Typography>
                </div>
                <Typography
                  className={classes.nowrap}
                  title={workItem.friendlyId}
                  variant="caption"
                >
                  {workItem.friendlyId}
                </Typography>
              </>
            );
          },
        },
        {
          label: "Priority",
          name: "priority",
          size: 2,
          sortable: true,
          render: (workItem: WorkItemSummaryDto) => {
            return <Typography>{workItem.priority_display}</Typography>;
          },
        },
        {
          label: "Due",
          name: "dueDate",
          size: 3,
          sortable: true,
          render: (workItem: WorkItemSummaryDto) => {
            const dueDate = dates.parseDate(workItem.dueDate);
            return <Typography>{dates.formatDateLong(dueDate)}</Typography>;
          },
        },
        {
          label: "Status",
          name: "status",
          size: 2,
          sortable: true,
          render: (workItem: WorkItemSummaryDto) => {
            return <Typography>{workItem.status_display}</Typography>;
          },
        },
        {
          label: "Owner",
          name: "owner",
          size: 2,
          sortable: true,
          render: (workItem: WorkItemSummaryDto) => {
            return (
              <Typography>
                {getFirstWorkItemUserByType(workItem.users, "OWNER")?.user.name}
              </Typography>
            );
          },
        },
        {
          label: "Created",
          name: "created",
          size: 2,
          sortable: true,
          render: (workItem: WorkItemSummaryDto) => (
            <TimeAgo
              value={dates.parseTimestamp(workItem.created)}
              expandable
            />
          ),
        },
        {
          label: "Updated",
          name: "updated",
          size: 2,
          sortable: true,
          render: (workItem: WorkItemSummaryDto) => (
            <TimeAgo
              value={dates.parseTimestamp(workItem.updated)}
              expandable
            />
          ),
        },
      ]}
    />
  );
};

export default WorkItemBulkUpdateResults;
