import { withStyles } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import * as PropTypes from "prop-types";
import React, { Component } from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/ext-searchbox";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/theme-xcode";
import FormHelperText from "@material-ui/core/FormHelperText";

const styles = (theme) => ({
  label: {
    position: "relative",
  },
  ace: {
    border: "solid 1px",
    borderColor: theme.palette.grey[300],
    zIndex: 0,
  },
});

class WrappedAceEditor extends Component {
  // see here for details: https://github.com/securingsincity/react-ace/issues/340
  aceOnBlur = (onBlur) => (event, editor) => {
    const value = editor.getValue();
    onBlur(value);
  };

  render() {
    const {
      classes,
      input,
      label,
      meta: { touched, error, invalid },
      required,
      margin,
      ...custom
    } = this.props;

    return (
      <FormControl fullWidth margin={margin} name={`position-${input.name}`}>
        {label && (
          <InputLabel
            className={classes.label}
            shrink
            required={required}
            error={touched && invalid}
          >
            {label}
          </InputLabel>
        )}
        <AceEditor
          className={classes.ace}
          theme="xcode"
          width="100%"
          height="300px"
          highlightActiveLine
          showPrintMargin={false}
          setOptions={{
            useWorker: false,
          }}
          editorProps={{
            $blockScrolling: true,
          }}
          enableBasicAutocompletion
          onBlur={this.aceOnBlur(input.onBlur)}
          onChange={input.onChange}
          onFocus={input.onFocus}
          name={input.name}
          value={input.value}
          {...custom}
        />
        {touched && error && (
          <FormHelperText error={touched && invalid}>{error}</FormHelperText>
        )}
      </FormControl>
    );
  }
}

WrappedAceEditor.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  helperText: PropTypes.any,
  margin: PropTypes.string,
};

WrappedAceEditor.defaultProps = {
  helperText: "",
  label: null,
  required: false,
  margin: "dense",
};

export default withStyles(styles)(WrappedAceEditor);
