import { useAuth0 } from "@auth0/auth0-react";
import { authorizer, useTenant } from "@certane/arcadia-web-components";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import _, { intersection } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { getAuditReportById, saveDocuments } from "../../actions/auditReports";
import AuditReportDetailsCard from "../../components/audit/AuditReportDetailsCard";
import AuditReportFilesCard from "../../components/audit/AuditReportFilesCard";
import AuditReportFindingsCard from "../../components/audit/AuditReportFindingsCard";
import BreadcrumbLink from "../../components/common/BreadcrumbLink";
import Container from "../../components/common/Container";
import HeaderBar from "../../components/common/HeaderBar";
import PageHeading from "../../components/common/PageHeading";
import Error404 from "../../components/error/Error404";
import LoadingIndicator from "../../components/LoadingIndicator";
import {
  getActiveAuditReport,
  getLoggedInUser,
  getReferenceDataType,
  isLoading,
} from "../../reducers";
import api from "../../services/api";
import { auditIcon } from "../../util/icons";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  container: {
    marginTop: theme.spacing(2),
  },
  tabs: {
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
  },
  tabRoot: {
    minWidth: "100px",
    padding: theme.spacing(2),
  },
});

const tabs = {
  details: {
    value: "details",
    label: "Details",
  },
  findings: {
    value: "findings",
    label: "Findings",
  },
  files: {
    value: "files",
    label: "Files",
  },
};

const orderedTabs = [tabs.details, tabs.findings, tabs.files];

const AuditReportId = ({
  classes,
  loading,
  history,
  match: {
    params: { auditReportId },
  },
  loggedInUser,
  auditReport,
  workItemTypes,
  localGetAuditReportById,
  localSaveDocuments,
}) => {
  const { getAccessTokenSilently, user } = useAuth0();
  const { tenant } = useTenant();
  const [activeTab, setActiveTab] = useState(tabs.details.value);
  const [uploadNamespace] = useState("audit");

  const readonly = useMemo(
    () => !authorizer.check("audit", "editor", user, tenant?.id),
    [user, tenant]
  );

  useEffect(() => {
    getAccessTokenSilently().then((accessToken) =>
      localGetAuditReportById(auditReportId, accessToken)
    );
  }, []);

  const addDocuments = async (documents) => {
    const accessToken = await getAccessTokenSilently();
    localSaveDocuments(auditReport.id, documents, accessToken);
  };

  if (!auditReport && loading) {
    return <LoadingIndicator size={60} />;
  }

  if (!auditReport && !loading) {
    return <Error404 />;
  }

  const generateAddAuditFindingAction = () => {
    const auditType = workItemTypes.ids
      .map((id) => workItemTypes.values[id])
      .filter((type) => type.props.definition.module === "AUDIT")
      .filter(
        (type) =>
          loggedInUser &&
          intersection(type.props.definition.creatableBy, loggedInUser.roles)
            .length > 0
      )[0];

    return auditType
      ? () => {
          const certaneEntities = auditReport.sargonServiceProviders || [];
          const regions = _.uniq(certaneEntities.map((org) => org.region));
          const entityRelationship = {
            organisations: certaneEntities.map((organisation) => ({
              organisation,
              visibilityScope: "ORGANISATION",
            })),
            regions,
          };

          return history.push(`/work-items/new/${auditType.id}`, {
            initValues: {
              auditReport: { id: auditReport.id, title: auditReport.title },
              entityRelationship,
            },
          });
        }
      : null;
  };

  const downloadFile = async (document) => {
    const accessToken = await getAccessTokenSilently();
    const url = await api.auditReports.documentDownloadUrl(
      auditReport.id,
      document,
      false,
      accessToken
    );
    window.open(url, "_blank");
  };

  return (
    <div className={classes.root}>
      <HeaderBar>
        <BreadcrumbLink to="/audits" label="Audits" includeArrow />
        <PageHeading icon={auditIcon()} heading={auditReport.title} />
      </HeaderBar>

      <Paper elevation={0} square>
        <Container>
          <Tabs
            value={activeTab}
            textColor="primary"
            onChange={(event, value) => setActiveTab(value)}
            className={classes.tabs}
          >
            {orderedTabs.map((tab) => (
              <Tab
                key={tab.value}
                value={tab.value}
                label={tab.label}
                classes={{
                  root: classes.tabRoot,
                }}
              />
            ))}
          </Tabs>
        </Container>
      </Paper>
      <Container className={classes.container}>
        {activeTab === tabs.details.value && (
          <AuditReportDetailsCard readonly={readonly} />
        )}
        {activeTab === tabs.findings.value && (
          <AuditReportFindingsCard
            addAuditReportFindingAction={generateAddAuditFindingAction()}
          />
        )}
        {activeTab === tabs.files.value && (
          <AuditReportFilesCard
            documents={auditReport.documents}
            readonly={readonly}
            setDocuments={addDocuments}
            onDocumentClick={downloadFile}
            uploadNamespace={uploadNamespace}
          />
        )}
      </Container>
    </div>
  );
};

AuditReportId.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  loggedInUser: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  auditReport: PropTypes.object,
  workItemTypes: PropTypes.object.isRequired,
  localGetAuditReportById: PropTypes.func.isRequired,
  localSaveDocuments: PropTypes.func.isRequired,
};

AuditReportId.defaultProps = {
  loggedInUser: null,
  auditReport: null,
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
  loading: isLoading(state),
  auditReport: getActiveAuditReport(state),
  workItemTypes: getReferenceDataType(state, "WorkItemType"),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    localGetAuditReportById: getAuditReportById,
    localSaveDocuments: saveDocuments,
  })
)(AuditReportId);
