import { roles } from "@certane/arcadia-web-components";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { connect } from "react-redux";
import { getReferenceDataType } from "../../reducers";
import { overviewIcon } from "../../util/icons";
import CardIconTitle from "../common/CardIconTitle";
import InlineScorecard from "../common/InlineScorecard";
import LabelStateSelect from "../common/LabelStateSelect";
import WorkItemOwner from "../workitem/components/overview/WorkItemOwner";
import EffectivenessChip from "./EffectivenessChip";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  cardContent: {
    paddingTop: "0 !important",
  },
  label: {
    ...theme.typography.body2,
    color: theme.palette.swatch.secondary,
  },
  switchLabel: {
    marginLeft: `-${theme.spacing(1)}px`,
  },
  selectable: {
    ...theme.typography.body2,
    fontWeight: 400,
    color: theme.palette.swatch.primary,
  },
}));

const ControlSummaryCard = ({
  loggedInUser,
  control,
  onChange,
  fieldLabels,
  riskControlTypes,
  controlEffectiveness,
}) => {
  const classes = useStyles();
  const typeCloser = useRef(null);
  const effectivenessCloser = useRef(null);

  const handleTypeChange = (type) => {
    onChange({ type }).then(() => typeCloser.current());
  };

  const handleEffectivenessChange = (effectiveness) => {
    onChange({ effectiveness }).then(() => effectivenessCloser.current());
  };

  const handleOwnerChange = (owner) => {
    onChange({ owner });
  };

  const handleCriticalChange = (critical) => {
    onChange({ critical });
  };

  return (
    <Card className={classes.root} elevation={0}>
      <CardHeader
        title={<CardIconTitle title="Overview" icon={overviewIcon()} />}
        data-cy="overview"
      />
      <CardContent className={classes.cardContent}>
        <InlineScorecard
          label={fieldLabels.labels.type}
          labelVariant="body2"
          labelClass={classes.label}
        >
          <LabelStateSelect
            setCloser={(closer) => {
              typeCloser.current = closer;
            }}
            label={riskControlTypes.values[control.type].description}
            readonly={!onChange}
            className={classes.selectable}
            data-cy="type"
          >
            {riskControlTypes.ids
              .map((id) => riskControlTypes.values[id])
              .map((type) => (
                <MenuItem
                  key={type.id}
                  value={type.id}
                  disabled={control.type === type.id}
                  onClick={() => handleTypeChange(type.id)}
                  data-cy={type.description}
                >
                  {type.description}
                </MenuItem>
              ))}
          </LabelStateSelect>
        </InlineScorecard>
        <InlineScorecard
          label={fieldLabels.labels.owner}
          labelVariant="body2"
          labelClass={classes.label}
        >
          <WorkItemOwner
            className={classes.selectable}
            owner={control.owner}
            userRoles={[roles.ADMIN, roles.RISK_ADMIN]}
            loggedInUser={loggedInUser}
            onOwnerChange={loggedInUser.externalUser ? null : handleOwnerChange}
            readonly={!onChange}
            includeAvatar
            data-cy="owner"
          />
        </InlineScorecard>
        <InlineScorecard
          label={fieldLabels.labels.critical}
          labelVariant="body2"
          labelClass={classes.label}
        >
          <Switch
            className={classes.switchLabel}
            checked={control.critical}
            disabled={!onChange}
            onChange={(evt) => handleCriticalChange(evt.target.checked)}
            data-cy="critical"
          />
        </InlineScorecard>
        <InlineScorecard
          label={fieldLabels.labels.effectiveness}
          labelVariant="body2"
          labelClass={classes.label}
        >
          <LabelStateSelect
            setCloser={(closer) => {
              effectivenessCloser.current = closer;
            }}
            label={<EffectivenessChip effectiveness={control.effectiveness} />}
            readonly={!onChange}
            className={classes.selectable}
            data-cy="effectiveness"
          >
            <MenuItem
              value=""
              disabled={!control.effectiveness}
              onClick={() => handleEffectivenessChange(null)}
            >
              <em>Clear</em>
            </MenuItem>
            {controlEffectiveness.ids.map((effectiveness) => (
              <MenuItem
                key={effectiveness}
                value={effectiveness}
                disabled={control.effectiveness === effectiveness}
                onClick={() => handleEffectivenessChange(effectiveness)}
                data-cy={effectiveness}
              >
                <EffectivenessChip effectiveness={effectiveness} />
              </MenuItem>
            ))}
          </LabelStateSelect>
        </InlineScorecard>
      </CardContent>
    </Card>
  );
};

ControlSummaryCard.propTypes = {
  loggedInUser: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  fieldLabels: PropTypes.object.isRequired,
  riskControlTypes: PropTypes.object.isRequired,
  controlEffectiveness: PropTypes.object.isRequired,
};

ControlSummaryCard.defaultProps = {
  onChange: null,
};

const mapStateToProps = (state) => ({
  riskControlTypes: getReferenceDataType(state, "RiskControlType"),
  controlEffectiveness: getReferenceDataType(state, "ControlEffectiveness"),
});

export default connect(mapStateToProps)(ControlSummaryCard);
