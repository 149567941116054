import { useAuth0 } from "@auth0/auth0-react";
import { Card, Menu, MenuItem } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  createAssociation,
  deleteAssociation,
  getAssociationsByWorkItemId,
} from "../../../../actions/workItemAssociations";
import {
  getLabels,
  getReferenceDataDescription,
  getReferenceDataType,
  getWorkItemAssociations,
} from "../../../../reducers";
import { getWorkItemLink } from "../../../../routes/routeUtils";
import logger from "../../../../util/logger";
import GridListing from "../../../common/GridListing";
import WorkItemAssociationSearchDialog from "../../association/WorkItemAssociationSearchDialog";
import { includeTenantParam } from "@certane/arcadia-web-components";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
}));

const LinkedWorkItemsCard = ({
  workItem,
  workItemTypes,
  workItemAssociations,
  workItemType,
  cardTitle,
  promptText,
  noItemsText,
  postUpdate,
  readonly,
  columns,
  localGetAssociationsByWorkItemId,
  localCreateAssociation,
  localDeleteAssociation,
}) => {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    (async () => {
      if (workItem) {
        const accessToken = await getAccessTokenSilently();
        localGetAssociationsByWorkItemId(workItem.id, accessToken);
      }
    })();
  }, [getAccessTokenSilently, workItem]);

  const addAssociation = async (otherWorkItem) => {
    const accessToken = await getAccessTokenSilently();
    await localCreateAssociation(
      {
        workItemA: {
          id: workItem.id,
        },
        workItemB: {
          id: otherWorkItem.id,
        },
        associationType: "RELATES_TO",
      },
      accessToken
    );
    setDialogOpen(false);
    postUpdate();
  };

  const removeAssociation = async (associationId) => {
    logger.debug("removeAssociation", associationId);
    const accessToken = await getAccessTokenSilently();
    await localDeleteAssociation(associationId, accessToken);
    postUpdate && postUpdate();
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCreateNew = () => {
    const target = {
      pathname: `/work-items/new/${workItemType}`,
      state: {
        initValues: {
          workItemAssociations: [
            {
              workItemId: workItem.id,
              associationType: "RELATES_TO",
            },
          ],
        },
      },
    };
    window.open(
      includeTenantParam(`/r/${Base64.encode(JSON.stringify(target))}`),
      "_blank"
    );
    setAnchorEl(null);
  };

  const handleOpenDialog = () => {
    setAnchorEl(null);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const openWorkItem = (otherWorkItemRef) => {
    window.open(
      includeTenantParam(getWorkItemLink(otherWorkItemRef)),
      "_blank"
    );
  };

  // Array of [association, associatedWorkItem]
  const associationWorkItemList = workItemAssociations
    .map((association) => {
      const associatedWorkItem =
        association.workItemA.id === workItem.id
          ? association.workItemB
          : association.workItemA;
      return [association, associatedWorkItem];
    })
    .filter(([, associatedWorkItem]) => {
      return associatedWorkItem.type === workItemType;
    });

  const linkToTypeLabel = workItemTypes.values[workItemType].description;
  const linkFromTypeLabel = workItemTypes.values[workItem.type].description;

  return (
    <Card className={classes.root} elevation={0}>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleCreateNew}>
          Create new {linkToTypeLabel}
        </MenuItem>
        <MenuItem onClick={handleOpenDialog}>
          Select from existing {linkToTypeLabel}s
        </MenuItem>
      </Menu>
      <WorkItemAssociationSearchDialog
        workItem={workItem}
        workItemAssociations={workItemAssociations}
        open={dialogOpen}
        onClose={handleCloseDialog}
        onAdd={addAssociation}
        filteredType={[workItemType]}
      />
      <CardHeader
        title={cardTitle || `${linkToTypeLabel}s received`}
        action={
          !readonly ? (
            <IconButton
              title={`Add ${linkToTypeLabel.toLowerCase()}`}
              aria-label={`Add ${linkToTypeLabel.toLowerCase()}`}
              aria-haspopup="true"
              onClick={handleOpenMenu}
            >
              <AddIcon />
            </IconButton>
          ) : null
        }
      />
      <CardContent>
        {!readonly && (
          <Typography variant={"body2"}>
            {promptText ||
              `Has a ${linkToTypeLabel.toLowerCase()} been received regarding this ${linkFromTypeLabel.toLowerCase()}?`}{" "}
            You can{" "}
            <Link component="button" onClick={handleOpenDialog}>
              link an existing
            </Link>{" "}
            {linkToTypeLabel.toLowerCase()} work item here or{" "}
            <Link component="button" onClick={handleCreateNew}>
              create a new one
            </Link>
          </Typography>
        )}
        <GridListing
          sortedData={associationWorkItemList}
          loading={false}
          sortBy={{}}
          updateSort={() => {
            // do nothing.
          }}
          dense={false}
          onClick={([, associatedWorkItem]) => openWorkItem(associatedWorkItem)}
          action={([association]) => {
            return !readonly ? (
              <IconButton
                aria-label="Remove"
                onClick={() => removeAssociation(association.id)}
                title="Remove"
                size={"small"}
                disabled={!association.deletableByUser}
              >
                <DeleteIcon />
              </IconButton>
            ) : null;
          }}
          noItemsText={
            noItemsText || `No ${linkToTypeLabel.toLowerCase()} sent/received`
          }
          columns={columns}
        />
      </CardContent>
    </Card>
  );
};

LinkedWorkItemsCard.propTypes = {
  workItem: PropTypes.object,
  workItemType: PropTypes.string.isRequired,
  cardTitle: PropTypes.string,
  promptText: PropTypes.string,
  noItemsText: PropTypes.string,
  columns: PropTypes.array.isRequired,
  postUpdate: PropTypes.func,
  readonly: PropTypes.bool,

  // redux
  fieldLabels: PropTypes.object.isRequired,
  workItemTypes: PropTypes.object.isRequired,
  workItemAssociations: PropTypes.array.isRequired,
  localGetAssociationsByWorkItemId: PropTypes.func.isRequired,
  localCreateAssociation: PropTypes.func.isRequired,
  localDeleteAssociation: PropTypes.func.isRequired,
};

LinkedWorkItemsCard.defaultProps = {
  cardTitle: null,
  promptText: null,
  noItemsText: null,
  postUpdate: null,
  readonly: false,
};

const mapStateToProps = (state) => ({
  fieldLabels: getLabels(state).WorkItem,
  workItemAssociations: getWorkItemAssociations(state),
  workItemTypes: getReferenceDataType(state, "WorkItemType"),
  localGetReferenceDataDescription: (type, id, defaultValue) =>
    getReferenceDataDescription(
      state,
      type,
      id,
      defaultValue === undefined ? "-" : defaultValue
    ),
});

export default connect(mapStateToProps, {
  localGetAssociationsByWorkItemId: getAssociationsByWorkItemId,
  localCreateAssociation: createAssociation,
  localDeleteAssociation: deleteAssociation,
})(LinkedWorkItemsCard);
