import IconButton from "@material-ui/core/IconButton";
import { Theme } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import React from "react";
import { SearchPagination } from "../../model/SearchPagination";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  pageDetails: {
    lineHeight: "48px",
    margin: `0px ${theme.spacing(2)}px`,
    color: theme.palette.grey[600],
  },
  countDetails: {
    lineHeight: "48px",
    marginRight: "auto",
    color: theme.palette.grey[600],
  },
}));

interface Props {
  pagination: SearchPagination<unknown>;
  handleNext: () => void;
  handlePrevious: () => void;
}

const Pagination: React.FC<Props> = ({
  pagination: { resultCount, offset, pageSize, nextOffset },
  handleNext,
  handlePrevious,
}: Props) => {
  const classes = useStyles();
  const hasResults = resultCount > 0;

  if (hasResults) {
    const currentPage = offset / pageSize + 1;
    const numPages = Math.ceil(resultCount / pageSize);
    const isFirstPage = offset === 0;
    const isLastPage = !nextOffset;
    const numReturned = Math.min(resultCount - offset, pageSize);
    const first = offset + 1;
    const last = offset + numReturned;

    return (
      <div className={classes.root}>
        <div className={classes.countDetails}>
          {first} to {last} of {resultCount}
        </div>
        <Tooltip title="Previous page">
          <div>
            <IconButton
              onClick={handlePrevious}
              aria-label="Previous page"
              disabled={isFirstPage}
            >
              <KeyboardArrowLeft />
            </IconButton>
          </div>
        </Tooltip>
        <div className={classes.pageDetails}>
          Page {currentPage} of {numPages}
        </div>
        <Tooltip title="Next page">
          <div>
            <IconButton
              onClick={handleNext}
              aria-label="Next page"
              disabled={isLastPage}
            >
              <KeyboardArrowRight />
            </IconButton>
          </div>
        </Tooltip>
      </div>
    );
  }

  return null;
};

export default Pagination;
