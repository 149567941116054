import { combineReducers } from "redux";
import {
  RISK_CLEAR_ACTIVE,
  RISK_CREATE,
  RISK_CREATE_MEASURE_UPDATES,
  RISK_CREATE_REASSESSMENTS,
  RISK_DELETE,
  RISK_GET_BY_ID,
  RISK_GET_TOLERANCES,
  RISK_LOAD,
  RISK_LOAD_METRIC,
  RISK_MEASURE_ADD,
  RISK_MEASURE_REMOVE,
  RISK_MEASURE_UPDATE,
  RISK_PATCH,
  RISK_SEND_ACTION_UPDATE_REQUESTS,
} from "../actions/risks";
import { publishActionToast } from "../services/toasts";
import logger from "../util/logger";

const successToasts = {
  [RISK_CREATE]: "Create risk successfully",
  [RISK_MEASURE_ADD]: "Added risk measure successfully",
  [RISK_MEASURE_UPDATE]: "Updated risk measure successfully",
  [RISK_MEASURE_REMOVE]: "Removed risk measure successfully",
  [RISK_CREATE_REASSESSMENTS]:
    "Initiated creation of reassessments successfully",
  [RISK_CREATE_MEASURE_UPDATES]:
    "Initiated creation of measure updates successfully",
  [RISK_SEND_ACTION_UPDATE_REQUESTS]: "Your update requests are on their way",
};

const byId = (state = {}, action) => {
  publishActionToast(action, "RISK_", successToasts);

  switch (action.type) {
    case `${RISK_LOAD}_SUCCESS`:
    case `${RISK_GET_BY_ID}_SUCCESS`:
    case `${RISK_CREATE}_SUCCESS`:
    case `${RISK_PATCH}_SUCCESS`:
    case `${RISK_MEASURE_ADD}_SUCCESS`:
    case `${RISK_MEASURE_UPDATE}_SUCCESS`:
    case `${RISK_MEASURE_REMOVE}_SUCCESS`:
      return {
        ...state,
        ...action.response.entities.risk,
      };

    case `${RISK_DELETE}_SUCCESS`: {
      const idToRemove = action.response.id;
      return {
        ...state,
        [idToRemove]: undefined,
      };
    }

    default:
      break;
  }

  return state;
};

const defaultActive = {
  dashboard: [],
  dashboardLastUpdate: 0,
  activeRisk: null,
  exportLink: null,
  activeTolerances: [],
  error: null,
  searchAbortController: null,
  pagination: {
    offset: 0,
    pageSize: 50,
    resultCount: 0,
  },
};

const active = (state = defaultActive, action) => {
  switch (action.type) {
    case RISK_CLEAR_ACTIVE:
      return {
        ...defaultActive,
      };

    case `${RISK_LOAD}_INPROGRESS`:
      if (state.searchAbortController) {
        logger.info("aborting previous unfinished request");
        state.searchAbortController.abort();
      }
      return {
        ...state,
        searchAbortController: action.abortController,
      };

    case `${RISK_LOAD}_FAILURE`:
      return {
        ...state,
        searchAbortController: null,
      };

    case `${RISK_LOAD}_SUCCESS`:
      return {
        ...state,
        dashboard: [...action.response.result],
        dashboardLastUpdate: new Date().getTime(),
        searchAbortController: null,
        pagination: {
          ...action.response.pagination,
          pageSize: defaultActive.pagination.pageSize,
        },
      };

    case `${RISK_GET_BY_ID}_SUCCESS`:
    case `${RISK_CREATE}_SUCCESS`:
      return {
        ...state,
        activeRisk: action.response.result,
      };

    // delete (remove active and dashboard)
    case `${RISK_DELETE}_SUCCESS`: {
      const idToRemove = action.response.id;
      return {
        ...state,
        activeRisk: state.activeRisk === idToRemove ? null : state.activeRisk,
        dashboard: [...state.dashboard.filter((id) => id !== idToRemove)],
      };
    }

    case `${RISK_GET_TOLERANCES}_INPROGRESS`:
      return {
        ...state,
        activeTolerances: [],
      };

    case `${RISK_GET_TOLERANCES}_SUCCESS`:
      return {
        ...state,
        activeTolerances: action.response,
      };

    default:
      break;
  }

  return state;
};

const metrics = (state = {}, action) => {
  switch (action.type) {
    case `${RISK_LOAD_METRIC}_SUCCESS`:
      return {
        ...state,
        [action.response.metric]: action.response.count,
      };
    default:
      break;
  }

  return state;
};

export default combineReducers({
  active,
  byId,
  metrics,
});

export const getActiveRisk = (state) =>
  state.active.activeRisk ? state.byId[state.active.activeRisk] : null;
export const lookupRiskById = (state) => (id) => state.byId[id];
export const getRisks = (state) =>
  state.active.dashboard.map((id) => state.byId[id]);
export const getSearchPagination = (state) => state.active.pagination;
export const getTolerances = (state) => state.active.activeTolerances;
export const getMetrics = (state) => state.metrics;
