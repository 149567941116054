import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import _ from "lodash";
import { normalize, schema } from "normalizr";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import fundsApi from "../../services/api/funds";
import productsApi from "../../services/api/products";
import organisationsApi from "../../services/api/organisations";
import contentCategoriesApi from "../../services/api/contentCategories";
import contentCategoryGroupsApi from "../../services/api/contentCategoryGroups";
import Avatar from "../common/Avatar.tsx";
import ChoiceChips from "../common/ChoiceChips";
import ClearButton from "../common/ClearButton";
import DebouncedTextField from "../common/DebouncedTextField";
import FiltersBar from "../common/FiltersBar";
import MultiSelectListPicker from "../common/MultiSelectListPicker";
import RefMultiSelect from "../common/RefMultiSelect";
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles((theme) => ({
  searchField: {
    fontSize: "0.8125rem",
    lineHeight: "1.1875em",
    minWidth: "200px",
  },
  topFilterWidget: {
    marginTop: theme.spacing(1),
  },
  filterWidget: {
    marginTop: theme.spacing(2),
  },
  clearButton: {
    padding: theme.spacing(0.5),
    position: "absolute",
    right: 0,
    top: `-${theme.spacing(2)}px`,
  },
  overhead: {
    marginBottom: theme.spacing(2),
  },
}));

const equalSets = (set1, set2) => _.isEqual(_.orderBy(set1), _.orderBy(set2));

const ContentFilters = ({
  filter,
  updateFilter,
  displayEntityFilter,
  ...rest
}) => {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const [selectedFunds, setSelectedFunds] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedOrganisations, setSelectedOrganisations] = useState([]);
  const [categories, setCategories] = useState({
    values: {},
    ids: [],
  });
  const [categoryGroups, setCategoryGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      contentCategoriesApi.list(accessToken).then((results) => {
        // Make categories look like Reference Data
        const cats = normalize(
          _.orderBy(results, "name").map((cat) => ({
            id: cat.id,
            description: cat.name,
          })),
          new schema.Array(new schema.Entity("categories"))
        );
        setCategories({
          values: cats.entities.categories,
          ids: cats.result,
        });
      });
      contentCategoryGroupsApi.list(accessToken).then((results) => {
        setCategoryGroups(_.orderBy(results, "name"));

        const selected = results.find((group) =>
          equalSets(
            group.categories.map((c) => c.id),
            filter.categoryIds
          )
        );
        setSelectedGroup(selected);
      });
    })();
  }, [getAccessTokenSilently]);

  // handle changes to organisationIds
  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      if (filter.organisationIds.length === 0) {
        setSelectedOrganisations([]);
      } else if (
        !equalSets(
          selectedOrganisations.map((o) => o.id),
          filter.organisationIds
        )
      ) {
        organisationsApi
          .search(
            {
              limit: filter.organisationIds.length,
              offset: 0,
              organisationIds: filter.organisationIds,
            },
            null,
            accessToken
          )
          .then((response) => setSelectedOrganisations(response.results));
      }
    })();
  }, [getAccessTokenSilently, filter.organisationIds]);

  // handle changes to productIds
  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      if (filter.productIds.length === 0) {
        setSelectedProducts([]);
      } else if (
        !equalSets(
          selectedProducts.map((p) => p.id),
          filter.productIds
        )
      ) {
        productsApi
          .search(
            {
              limit: filter.productIds.length,
              offset: 0,
              productIds: filter.productIds,
            },
            null,
            accessToken
          )
          .then((response) => setSelectedProducts(response.results));
      }
    })();
  }, [getAccessTokenSilently, filter.productIds]);

  // handle changes to fundIds
  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      if (filter.fundIds.length === 0) {
        setSelectedFunds([]);
      } else if (
        !equalSets(
          selectedFunds.map((p) => p.id),
          filter.fundIds
        )
      ) {
        fundsApi
          .search(
            {
              limit: filter.fundIds.length,
              offset: 0,
              fundIds: filter.fundIds,
            },
            null,
            accessToken
          )
          .then((response) => setSelectedFunds(response.results));
      }
    })();
  }, [getAccessTokenSilently, filter.fundIds]);

  // check if category group should be deselected
  useEffect(() => {
    if (
      selectedGroup &&
      !equalSets(
        selectedGroup.categories.map((c) => c.id),
        filter.categoryIds
      )
    ) {
      setSelectedGroup(null);
    }
  }, [filter.categoryIds]);

  const asyncFetch =
    (api, orderByField) =>
    async (pickerFilter, pagination, abortController) => {
      const searchParameters = {
        ...pickerFilter,
        limit: pagination.pageSize,
        offset: pagination.offset,
        orderByField,
      };
      const accessToken = await getAccessTokenSilently();
      return api.search(searchParameters, abortController, accessToken);
    };

  const setFunds = (funds) => {
    setSelectedFunds(funds);
    updateFilter(
      "fundIds",
      funds.map((fund) => fund.id)
    );
  };

  const setProducts = (products) => {
    setSelectedProducts(products);
    updateFilter(
      "productIds",
      products.map((product) => product.id)
    );
  };

  const setOrganisations = (organisations) => {
    setSelectedOrganisations(organisations);
    updateFilter(
      "organisationIds",
      organisations.map((org) => org.id)
    );
  };

  const getMultiSelectLabel = (selected, field) => {
    if (selected.length > 1) {
      return `${selected.length} selected`;
    }
    return selected.map((s) => s[field]).join(", ");
  };

  const setCategoryGroup = (selected) => {
    if (selected) {
      updateFilter(
        "categoryIds",
        selected.categories.map((c) => c.id)
      );
    } else {
      updateFilter("categoryIds", []);
    }
    setSelectedGroup(selected);
  };

  const topRowSize = displayEntityFilter ? 2 : 3;

  return (
    <>
      <FiltersBar
        overhead={
          <Grid
            container
            spacing={1}
            alignItems="flex-end"
            className={classes.overhead}
          >
            <Grid item xs={12} md={3}>
              <div>
                {filter.textSearch && (
                  <ClearButton
                    className={classes.clearButton}
                    onClear={() => updateFilter("textSearch", "")}
                    data-cy="clearButton"
                  />
                )}
                <div className={classes.topFilterWidget}>
                  <DebouncedTextField
                    data-cy={filter.textSearch}
                    value={filter.textSearch}
                    onChange={(text) => updateFilter("textSearch", text)}
                    placeholder="Search across name, slug and reference"
                    margin="none"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon data-cy="search">search</Icon>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={topRowSize}>
              <InputLabel data-cy="categories">Categories</InputLabel>
              <div className={classes.topFilterWidget}>
                {filter.categoryIds.length > 0 && (
                  <ClearButton
                    className={classes.clearButton}
                    onClear={() => updateFilter("categoryIds", [])}
                  />
                )}
                <RefMultiSelect
                  title="Select category"
                  actionText="Select"
                  value={filter.categoryIds}
                  options={categories}
                  onChange={(value) => updateFilter("categoryIds", value)}
                  data-cy={categories}
                />
              </div>
            </Grid>
            {displayEntityFilter && (
              <Grid item xs={12} md={topRowSize}>
                <InputLabel data-cy="relatedEntities">
                  Related entities
                </InputLabel>
                <div className={classes.topFilterWidget}>
                  {selectedOrganisations.length > 0 && (
                    <ClearButton
                      className={classes.clearButton}
                      onClear={() => setOrganisations([])}
                      data-cy="clear"
                    />
                  )}
                  <MultiSelectListPicker
                    title="Select entity"
                    actionText="Select"
                    label={getMultiSelectLabel(selectedOrganisations, "name")}
                    onSubmit={(orgs) => setOrganisations(orgs)}
                    datasource={asyncFetch(organisationsApi, "name")}
                    selected={selectedOrganisations}
                    isMulti
                    clearable
                    toOption={(org) => ({
                      label: org.name,
                      id: org.id,
                      org,
                    })}
                    fromOption={(option) => option.org}
                    renderIcon={(org, size) => (
                      <Avatar
                        name={(org.name || "Unknown").charAt(0)}
                        size={size}
                        round
                      />
                    )}
                    filterInitialValues={{ types: ["INTERNAL"] }}
                    renderFilter={(onChange, searchFilter) => (
                      <Grid
                        container
                        spacing={1}
                        alignItems="center"
                        justifyContent="space-between"
                        className={classes.filter}
                      >
                        <Grid
                          item
                          style={{
                            flex: 1,
                            marginRight: "3px",
                          }}
                        >
                          <DebouncedTextField
                            value={searchFilter.textSearch}
                            onChange={(text) => onChange("textSearch", text)}
                            placeholder="Type to filter..."
                            margin="none"
                            autoFocus
                            fullWidth
                            data-cy="typeToFilter"
                          />
                        </Grid>
                        <Grid item>
                          <FormControlLabel
                            data-cy="certaneOnly"
                            control={
                              <Switch
                                checked={
                                  !!(
                                    searchFilter.types &&
                                    searchFilter.types.length &&
                                    searchFilter.types[0] === "INTERNAL"
                                  )
                                }
                                onChange={(evt) =>
                                  onChange(
                                    "types",
                                    evt.target.checked ? ["INTERNAL"] : []
                                  )
                                }
                                name="sargonOnly"
                              />
                            }
                            label="Internal only"
                          />
                        </Grid>
                      </Grid>
                    )}
                  />
                </div>
              </Grid>
            )}
            <Grid item xs={12} md={topRowSize}>
              <InputLabel>Funds</InputLabel>
              <div className={classes.topFilterWidget}>
                {selectedFunds.length > 0 && (
                  <ClearButton
                    className={classes.clearButton}
                    onClear={() => setFunds([])}
                    data-cy="clear"
                  />
                )}
                <MultiSelectListPicker
                  title="Select fund"
                  actionText="Select"
                  label={getMultiSelectLabel(selectedFunds, "name")}
                  onSubmit={(funds) => setFunds(funds)}
                  datasource={asyncFetch(fundsApi, "name")}
                  selected={selectedFunds}
                  isMulti
                  clearable
                  toOption={(fund) => ({
                    label: fund.name,
                    id: fund.id,
                    fund: fund,
                  })}
                  fromOption={(option) => option.fund}
                  renderIcon={(fund, size) => (
                    <Avatar
                      name={(fund.name || "Unknown").charAt(0)}
                      size={size}
                      round
                    />
                  )}
                  data-cy={getMultiSelectLabel(selectedFunds, "name")}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={topRowSize}>
              <InputLabel>Products</InputLabel>
              <div className={classes.topFilterWidget}>
                {selectedProducts.length > 0 && (
                  <ClearButton
                    className={classes.clearButton}
                    onClear={() => setProducts([])}
                    data-cy="clear"
                  />
                )}
                <MultiSelectListPicker
                  title="Select product"
                  actionText="Select"
                  label={getMultiSelectLabel(selectedProducts, "name")}
                  onSubmit={(orgs) => setProducts(orgs)}
                  datasource={asyncFetch(productsApi, "name")}
                  selected={selectedProducts}
                  isMulti
                  clearable
                  toOption={(product) => ({
                    label: product.name,
                    id: product.id,
                    product,
                  })}
                  fromOption={(option) => option.product}
                  renderIcon={(product, size) => (
                    <Avatar
                      name={(product.name || "Unknown").charAt(0)}
                      size={size}
                      round
                    />
                  )}
                  data-cy={getMultiSelectLabel(selectedProducts, "name")}
                />
              </div>
            </Grid>
          </Grid>
        }
        {...rest}
      >
        <>
          <InputLabel data-cy="categoryGroups">Category groups</InputLabel>
          <div className={classes.filterWidget}>
            <ChoiceChips
              value={selectedGroup}
              options={categoryGroups
                .filter((group) => group.categories.length > 0)
                .map((group) => ({
                  label: group.name,
                  value: group,
                  badgeValue:
                    selectedGroup && selectedGroup.id === group.id
                      ? group.categories.length
                      : null,
                }))}
              onChange={setCategoryGroup}
            />
          </div>
        </>
        <>
          <InputLabel data-cy="contentType">Content type</InputLabel>
          <div className={classes.filterWidget}>
            <ChoiceChips
              value={filter.type}
              options={[
                {
                  label: "Media",
                  value: "MEDIA",
                },
                {
                  label: "Snippets",
                  value: "SNIPPET",
                },
              ]}
              onChange={(type) => updateFilter("type", type)}
            />
          </div>
        </>
      </FiltersBar>
    </>
  );
};

ContentFilters.propTypes = {
  filter: PropTypes.object.isRequired,
  updateFilter: PropTypes.func.isRequired,
  displayEntityFilter: PropTypes.bool.isRequired,
};

export default ContentFilters;
