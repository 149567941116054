import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  createBusinessServiceArea,
  patchBusinessServiceArea,
} from "../../../actions/businessServiceAreas";
import ActionButton from "../../../components/common/ActionButton";
import ActionHeading from "../../../components/common/ActionHeading";
import Container from "../../../components/common/Container";
import FloatingActionButton from "../../../components/common/FloatingActionButton";
import HeaderBar from "../../../components/common/HeaderBar";
import PageHeading from "../../../components/common/PageHeading";
import ExpandableForm from "../../../components/forms/ExpandableForm";
import BusinessServiceAreasForm, {
  BUSINESS_SERVICE_AREAS_FORM_NAME,
} from "../../../components/forms/business/BusinessServiceAreasForm";
import BusinessServiceAreasFilters from "../../../components/business/BusinessServiceAreasFilters";
import BusinessServiceAreasListing from "../../../components/business/BusinessServiceAreasListing";
import { getLoggedInUser } from "../../../reducers";
import { businessServiceAreaIcon, clearIcon } from "../../../util/icons";
import usePersistedState from "../../../util/persistedState";
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  contents: {
    marginBottom: "75px",
  },
}));

const defaultFilter = {
  textSearch: "",
};

const Index = ({
  loggedInUser,
  history,
  localCreateServiceArea,
  localPatchServiceArea,
}) => {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const [sortBy, setSortBy] = usePersistedState(
    `business-service-areas-sort-${loggedInUser.email}`,
    {
      field: "name",
      direction: "asc",
    }
  );
  const [filter, setFilter] = usePersistedState(
    `business-service-areas-filter-${loggedInUser.email}`,
    defaultFilter,
    ["textSearch"]
  );
  const [formOpen, setFormOpen] = useState(false);
  const [selectedServiceArea, setSelectedServiceArea] = useState(null);
  const formRef = useRef();

  const clearFilter = () => setFilter(defaultFilter);

  const updateFilter = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  const openForm = () => {
    setFormOpen(true);
    window.scrollTo(0, formRef.current.offsetTop);
  };

  useEffect(() => {
    if (selectedServiceArea) {
      if (!formOpen) {
        openForm();
      } else {
        // need to close the redux form fully before changing between edit and create
        setFormOpen(false);
        setTimeout(() => {
          openForm();
        }, 0);
      }
    }
  }, [selectedServiceArea]);

  const onSave = async (product) => {
    const accessToken = await getAccessTokenSilently();
    selectedServiceArea
      ? await localPatchServiceArea(
          selectedServiceArea.id,
          product,
          "Updated business service area",
          accessToken
        )
      : await localCreateServiceArea(product, accessToken);

    setFormOpen(false);
    setSelectedServiceArea(null);
  };

  const createNew = () => {
    setSelectedServiceArea(null);
    if (formOpen) {
      // need to close the redux form fully before changing between edit and create
      setFormOpen(false);
      setTimeout(() => {
        openForm();
      }, 0);
    } else {
      openForm();
    }
  };

  const cancelForm = () => {
    setSelectedServiceArea(null);
    setFormOpen(false);
  };

  return (
    <div className={classes.root}>
      <HeaderBar>
        <ActionHeading
          heading={
            <PageHeading
              icon={businessServiceAreaIcon()}
              heading="Business Service Areas"
            />
          }
          actions={[
            <ActionButton
              key="clearFilter"
              tooltip="Clear filters"
              icon={clearIcon()}
              onClick={clearFilter}
              data-cy="clear-filters-button"
            />,
          ]}
        />
      </HeaderBar>
      <Container className={classes.contents}>
        {!formOpen && <FloatingActionButton onClick={createNew} />}
        <ExpandableForm
          title={
            selectedServiceArea
              ? "Edit business service area"
              : "Create business service area"
          }
          submitButtonText={selectedServiceArea ? "Save" : "Create"}
          formComponent={BusinessServiceAreasForm}
          formName={BUSINESS_SERVICE_AREAS_FORM_NAME}
          open={formOpen}
          formRef={formRef}
          onCancel={cancelForm}
          onSubmit={onSave}
          isAltPaper
          initialValues={selectedServiceArea ? { ...selectedServiceArea } : {}}
        />
        <BusinessServiceAreasFilters
          filter={filter}
          updateFilter={updateFilter}
          fullWidth={false}
        />
        <BusinessServiceAreasListing
          history={history}
          filter={filter}
          clearFilter={clearFilter}
          sortBy={sortBy}
          updateSort={setSortBy}
          onSelect={(serviceArea) => setSelectedServiceArea(serviceArea)}
          selected={selectedServiceArea}
        />
      </Container>
    </div>
  );
};

Index.propTypes = {
  history: PropTypes.object.isRequired,

  // redux
  loggedInUser: PropTypes.object,
  localCreateServiceArea: PropTypes.func.isRequired,
  localPatchServiceArea: PropTypes.func.isRequired,
};

Index.defaultProps = {
  loggedInUser: null,
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
});

export default connect(mapStateToProps, {
  localCreateServiceArea: createBusinessServiceArea,
  localPatchServiceArea: patchBusinessServiceArea,
})(Index);
