import { normalize } from "normalizr";
import schemas from "../schemas";
import websiteSnapshotsApi from "../services/api/websiteSnapshots";
import { asyncAction } from "./actions";

export const WEBSITE_SNAPSHOT_CLEAR_ACTIVE = "WEBSITE_SNAPSHOT_CLEAR_ACTIVE";
export const WEBSITE_SNAPSHOT_CREATE = "WEBSITE_SNAPSHOT_CREATE";
export const WEBSITE_SNAPSHOT_GET_BY_ID = "WEBSITE_SNAPSHOT_GET_BY_ID";
export const WEBSITE_SNAPSHOT_GET_PREVIOUS_BY_ID =
  "WEBSITE_SNAPSHOT_GET_PREVIOUS_BY_ID";
export const WEBSITE_SNAPSHOT_LIST = "WEBSITE_SNAPSHOT_LIST";

export function clearActiveWebsiteSnapshot() {
  return {
    type: WEBSITE_SNAPSHOT_CLEAR_ACTIVE,
  };
}

export const listWebsiteSnapshots = (digitalAssetId, limit, accessToken) =>
  asyncAction(
    WEBSITE_SNAPSHOT_LIST,
    websiteSnapshotsApi.list(digitalAssetId, limit, accessToken),
    {
      responseTransformer: (websiteSnapshots) =>
        normalize(websiteSnapshots, schemas.arrayOfWebsiteSnapshots),
    }
  );

export const getWebsiteSnapshotById = (websiteSnapshotId, accessToken) =>
  asyncAction(
    WEBSITE_SNAPSHOT_GET_BY_ID,
    websiteSnapshotsApi.getById(websiteSnapshotId, accessToken),
    {
      responseTransformer: (websiteSnapshot) =>
        normalize(websiteSnapshot, schemas.websiteSnapshot),
    }
  );

export const getPreviousWebsiteSnapshotById = (
  websiteSnapshotId,
  accessToken
) =>
  asyncAction(
    WEBSITE_SNAPSHOT_GET_PREVIOUS_BY_ID,
    websiteSnapshotsApi.getById(websiteSnapshotId, accessToken),
    {
      responseTransformer: (websiteSnapshot) =>
        normalize(websiteSnapshot, schemas.websiteSnapshot),
    }
  );

export const createWebsiteSnapshot = (digitalAssetId, accessToken) =>
  asyncAction(
    WEBSITE_SNAPSHOT_CREATE,
    websiteSnapshotsApi.createSnapshot(digitalAssetId, accessToken),
    {
      responseTransformer: (websiteSnapshot) =>
        normalize(websiteSnapshot, schemas.websiteSnapshot),
    }
  );
