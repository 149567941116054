import { normalize } from "normalizr";
import schemas from "../schemas";
import mentionsApi from "../services/api/mentions.ts";
import { asyncAction } from "./actions";

export const MENTION_LIST = "MENTION_LIST";
export const MENTION_PATCH = "MENTION_PATCH";

export const listMentions = (filter, accessToken) =>
  asyncAction(MENTION_LIST, mentionsApi.search(filter, accessToken), {
    responseTransformer: (mentions) =>
      normalize(mentions, schemas.arrayOfMentions),
  });

export const patchMention = (mentionId, changes, description, accessToken) =>
  asyncAction(
    MENTION_PATCH,
    mentionsApi.patch(mentionId, changes, description, accessToken),
    {
      responseTransformer: (mention) => normalize(mention, schemas.mention),
    }
  );
