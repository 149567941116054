import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import _ from "lodash";

export const getCategoryMenuItems = (categories, onClick) =>
  _.orderBy(categories, "name").map((cat) => (
    <MenuItem
      key={cat.category}
      value={cat.category}
      onClick={onClick ? () => onClick(cat.category) : undefined}
      data-cy={cat.name}
    >
      {cat.name}
    </MenuItem>
  ));

export const getRefDataMenuItems = (list, excludedValues = []) => {
  return list.ids
    .filter((enumValue) => !_.includes(excludedValues, enumValue))
    .filter((enumValue) => list.values[enumValue].selectable !== false)
    .map((enumValue) => (
      <MenuItem
        key={enumValue}
        value={enumValue}
        data-cy={list.values[enumValue].description}
      >
        {list.values[enumValue].description}
      </MenuItem>
    ));
};

export const tagsToOptions = (tags) => {
  const options = { ids: [], values: {} };
  options.ids = _.sortBy(tags, "text").map((tag) => tag.id);
  tags.forEach((tag) => {
    options.values[tag.id] = { id: tag.id, description: tag.text };
  });
  return options;
};

export const usersToOptions = (users) => {
  const options = { ids: [], values: {} };
  options.ids = users.map((u) => u.id);
  users.forEach((u) => {
    options.values[u.id] = { id: u.id, description: u.name };
  });
  return options;
};
