import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { required } from "redux-form-validators";
import MediaIcon from "@material-ui/icons/Cloud";
import InputLabel from "@material-ui/core/InputLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import { Grid } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import FormControl from "@material-ui/core/FormControl";
import makeStyles from "@material-ui/core/styles/makeStyles";
import moment from "moment";
import Switch from "@material-ui/core/Switch";
import { compose } from "redux";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Collapse from "@material-ui/core/Collapse";
import mediaApi from "../../../services/api/media";
import WrappedListPicker from "../../forms/wrapper/WrappedListPicker";
import WrappedDatePicker from "../../forms/wrapper/WrappedDatePicker";
import { infoIcon } from "../../../util/icons";
import { scrollToFirstInvalidInput } from "../../../util/formHelpers";
import { useAuth0 } from "@auth0/auth0-react";

export const LINK_TO_METROPOLIS_FORM_NAME = "LINK_TO_METROPOLIS_FORM_NAME";

const InfoIcon = infoIcon();

const fieldLabels = {
  media: "Media",
  effectiveFrom: "Effective date of document",
  scheduledActivationDate: "Go live on",
  reviewBy: "Review by",
};

const timingOptions = {
  NOW: {
    id: "NOW",
    label: "Now (Current version)",
    tooltip: "This document will go live as soon as submitted",
  },
  // HISTORICAL: {
  //   id: 'HISTORICAL',
  //   label: 'Historical (Past version)',
  //   tooltip: 'This document won\'t go live but will be stored as a past version',
  // },
  FUTURE: {
    id: "FUTURE",
    label: "Future date (Future version)",
    tooltip: "This document will go live midnight of go live date",
  },
};

const useStyles = makeStyles((theme) => ({
  stepHeading: {
    boxSizing: "border-box",
    listStyle: "none",
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.subtitle1.fontFamily,
    fontWeight: theme.typography.subtitle1.fontWeight,
    fontSize: theme.typography.pxToRem(14),
    marginTop: theme.spacing(2),
  },
  label: {
    position: "relative",
  },
  groupVertical: {
    marginTop: "-4px",
    marginBottom: "-4px",
  },
  optionVertical: {
    marginTop: "-4px",
    "&:first-child": {
      marginTop: 0,
    },
  },
}));

const LinkFileToMetropolisForm = ({
  change,
  getFieldValue,
  productIds,
  selectedDocument,
  selectedScheduledActivationDate,
  selectedReviewDate,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();
  const [timing, setTiming] = useState(timingOptions.NOW.id);
  const [includeReviewDate, setIncludeReviewDate] = useState(
    !!selectedReviewDate
  );

  const searchMedia = async (pickerFilter, pagination, abortController) => {
    const accessToken = await getAccessTokenSilently();
    return mediaApi.search(
      {
        ...pickerFilter,
        productIds,
        organisationIds: [],
        limit: pagination.pageSize,
        offset: pagination.offset,
        orderByField: "name",
      },
      abortController,
      accessToken
    );
  };

  useEffect(() => {
    change("selectedDocument", selectedDocument);
  }, [selectedDocument]);

  return (
    <form autoComplete="off">
      <Typography className={classes.stepHeading}>Step 1</Typography>
      <Field
        name="media"
        component={WrappedListPicker}
        datasource={searchMedia}
        placeholder="Please select a media..."
        isMulti={false}
        submitOnChange
        required
        validate={required({ msg: `${fieldLabels.media} is required` })}
        label={fieldLabels.media}
        fullWidth
        toOption={(media) => ({
          label: media.name || "Unknown",
          id: media.id,
        })}
        fromOption={(media) => ({
          name: media.label,
          id: media.id,
        })}
        renderIcon={() => <MediaIcon />}
      />

      <Collapse in={!!getFieldValue("media")} timeout="auto" unmountOnExit>
        <Typography className={classes.stepHeading}>Step 2</Typography>
        <Field
          name="effectiveFrom"
          component={WrappedDatePicker}
          label={fieldLabels.effectiveFrom}
          fullWidth
        />

        <FormControl component="fieldset" margin="dense" fullWidth>
          <InputLabel shrink className={classes.label}>
            Publish
          </InputLabel>
          <RadioGroup
            className={classes.groupVertical}
            aria-label="timing"
            name="timing"
            value={timing}
            onChange={(event) => setTiming(event.target.value)}
          >
            {Object.values(timingOptions).map((timingOption) => (
              <FormControlLabel
                className={classes.optionVertical}
                key={timingOption.id}
                value={timingOption.id}
                control={<Radio />}
                label={
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>{timingOption.label}</Grid>
                    <Grid item>
                      <Tooltip title={timingOption.tooltip}>
                        <InfoIcon fontSize="small" color="action" />
                      </Tooltip>
                    </Grid>
                  </Grid>
                }
              />
            ))}
          </RadioGroup>
        </FormControl>
        {timing === timingOptions.FUTURE.id && (
          <Field
            name="scheduledActivationDate"
            component={WrappedDatePicker}
            label={fieldLabels.scheduledActivationDate}
            validate={required({
              msg: `${fieldLabels.scheduledActivationDate} is required`,
            })}
            required
            fullWidth
            minDate={moment().add(1, "day").startOf("day")}
          />
        )}

        <FormControl margin="dense" fullWidth>
          <FormControlLabel
            control={
              <Switch
                checked={includeReviewDate}
                onChange={() => setIncludeReviewDate(!includeReviewDate)}
                name="includeReviewDate"
              />
            }
            label="Requires regular review?"
          />
        </FormControl>
        {includeReviewDate && (
          <Field
            name="reviewBy"
            component={WrappedDatePicker}
            label={fieldLabels.reviewBy}
            fullWidth
            validate={required({ msg: `${fieldLabels.reviewBy} is required` })}
            required
            disabled={
              timing === timingOptions.FUTURE.id &&
              !selectedScheduledActivationDate
            }
            minDate={moment(selectedScheduledActivationDate || new Date())
              .add(1, "day")
              .startOf("day")}
          />
        )}
      </Collapse>
    </form>
  );
};

LinkFileToMetropolisForm.propTypes = {
  change: PropTypes.func.isRequired, // redux form
  getFieldValue: PropTypes.func.isRequired,
  productIds: PropTypes.array, // productIds to filter
  selectedDocument: PropTypes.object, // selected document to link
  selectedScheduledActivationDate: PropTypes.string,
  selectedReviewDate: PropTypes.string,
};

LinkFileToMetropolisForm.defaultProps = {
  productIds: [],
  selectedDocument: null,
  selectedScheduledActivationDate: null,
  selectedReviewDate: null,
};

const mapStateToProps = (state, { namePrefix }) => {
  const fieldPrefix = namePrefix ? `${namePrefix}.` : "";
  const reduxFormSelector = formValueSelector(LINK_TO_METROPOLIS_FORM_NAME);
  return {
    selectedScheduledActivationDate: reduxFormSelector(
      state,
      `${fieldPrefix}scheduledActivationDate`
    ),
    selectedReviewDate: reduxFormSelector(state, `${fieldPrefix}reviewBy`),
    getFieldValue: (field) => reduxFormSelector(state, field),
  };
};

const form = reduxForm({
  form: LINK_TO_METROPOLIS_FORM_NAME,
  onSubmitFail: (errors) => scrollToFirstInvalidInput(errors),
});
const reduxData = connect(mapStateToProps);
export default compose(form, reduxData)(LinkFileToMetropolisForm);
