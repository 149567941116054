import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import PropTypes from "prop-types";
import classNames from "classnames";
import dates from "../../../util/dates";
import FileIcon from "../../workitem/FileIcon";

const useStyles = makeStyles((theme) => ({
  box: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.swatch.grey5,
  },
  removed: {
    "& span": {
      textDecoration: "line-through",
    },
  },
  avatar: {
    backgroundColor: "transparent",
  },
}));

const getChipClasses = (classes, removed) =>
  classNames({
    [classes.chip]: true,
    [classes.removed]: removed,
  });

const DocumentChips = ({ documents, onDocumentClick, onDeleteDocument }) => {
  const classes = useStyles();

  const getTitle = (doc) => {
    if (doc.removed) {
      return `Uploaded by ${doc.createdByName} on ${dates.formatTimestamp(
        doc.uploaded
      )}\nRemoved by ${doc.deletedByName} on ${dates.formatTimestamp(
        doc.deleted
      )}`;
    }
    return `Uploaded by ${doc.createdByName} on ${dates.formatTimestamp(
      doc.uploaded
    )}`;
  };

  return (
    <div className={classes.box}>
      {documents.map((document) => (
        <Chip
          key={document.id}
          className={getChipClasses(classes, document.removed)}
          clickable={!document.removed}
          title={getTitle(document)}
          onClick={
            document.removed
              ? () => {
                  // do nothing.
                }
              : () => onDocumentClick(document)
          }
          avatar={
            <Avatar className={classes.avatar}>
              <FileIcon document={document} />
            </Avatar>
          }
          label={document.fileName}
          onDelete={onDeleteDocument ? () => onDeleteDocument(document) : null}
        />
      ))}
    </div>
  );
};

DocumentChips.propTypes = {
  documents: PropTypes.array.isRequired,
  onDocumentClick: PropTypes.func.isRequired,
  onDeleteDocument: PropTypes.func,
};

DocumentChips.defaultProps = {
  onDeleteDocument: null,
};

export default DocumentChips;
