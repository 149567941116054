import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import useTheme from "@material-ui/core/styles/useTheme";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import * as PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import {
  getLoggedInUser,
  getReferenceDataDescription,
  getReferenceDataType,
} from "../../../reducers";
import { typeParentIcon } from "../../../util/icons";
import { getResolvedDefinition } from "../../../util/workItemTypeUtils";
import { getFirstWorkItemUserByType } from "../../../util/workItemUserUtils";
import GridListing from "../../common/GridListing";
import WorkItemOwner from "../components/overview/WorkItemOwner";
import WorkItemStatus from "../components/overview/WorkItemStatus";
import WorkItemBlob from "../WorkItemBlob";
import WorkItemSubmitter from "../WorkItemSubmitter";

const useStyles = makeStyles(() => ({
  resultsAria: {
    minHeight: "350px",
    maxHeight: "350px",
    position: "relative",
    overflowY: "scroll",
  },
  dialogContentRoot: {
    paddingBottom: 0,
  },
  childParentIcon: {
    fontSize: "inherit",
    marginRight: "5px",
    marginTop: "-3px",
    verticalAlign: "middle",
  },
}));

const WorkItemAssociationReasonDialog = ({
  workItem,
  open,
  onCancel,
  onBack,
  onSubmit,
  loggedInUser,
  workItemTypes,
  localGetReferenceDataDescription,
}) => {
  const [reason, setReason] = useState("");
  const classes = useStyles();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const TypeParentIcon = typeParentIcon();

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onCancel}
      onEnter={() => {
        setReason("");
      }}
      aria-labelledby="WorkItemAssociationReasonDialog"
    >
      <DialogTitle id="WorkItemAssociationReasonDialog">
        Provide a reason for this association
      </DialogTitle>
      <DialogContent
        classes={{
          root: classes.dialogContentRoot,
        }}
      >
        {workItem && (
          <div>
            <Grid container spacing={2} direction="column">
              <Grid item>
                <Typography display="block" variant="subtitle1">
                  Selected work item
                </Typography>
                <GridListing
                  sortedData={[workItem]}
                  loading={false}
                  sortBy={{}}
                  updateSort={() => {
                    // do nothing.
                  }}
                  columns={[
                    {
                      label: "Type",
                      name: "type",
                      size: 2,
                      sortable: true,
                      render: (item) => {
                        const { definition } =
                          workItemTypes.values[item.type].props;
                        const { name } = definition;
                        const category = localGetReferenceDataDescription(
                          "WorkItemCategory",
                          item.category
                        );
                        return (
                          <>
                            <WorkItemBlob type={item.type} description={name} />
                            <Typography
                              className={classes.nowrap}
                              title={category}
                              variant="caption"
                              display="block"
                              data-cy={category}
                            >
                              {category}
                              {item.hasParent && (
                                <div className={classes.sub}>
                                  <TypeParentIcon
                                    title="Parent-item"
                                    className={classes.childParentIcon}
                                  />
                                  {
                                    workItemTypes.values[item.parent.type].props
                                      .definition.name
                                  }
                                </div>
                              )}
                            </Typography>
                          </>
                        );
                      },
                    },
                    {
                      label: "Submitter",
                      name: "createdBy",
                      size: 2,
                      sortable: true,
                      render: (item) => <WorkItemSubmitter workItem={item} />,
                    },
                    {
                      label: "Title",
                      name: "title",
                      size: 4,
                      render: (item) => (
                        <>
                          <div>
                            <Typography
                              className={classes.nowrap}
                              title={item.title}
                            >
                              {item.title}
                            </Typography>
                          </div>
                          <Typography
                            className={classes.nowrap}
                            title={item.friendlyId}
                            variant="caption"
                          >
                            {item.friendlyId}
                          </Typography>
                        </>
                      ),
                    },
                    {
                      label: "Status",
                      name: "status",
                      size: 2,
                      sortable: true,
                      render: (item) => {
                        const definition = getResolvedDefinition(
                          workItemTypes,
                          item.type,
                          item.parent?.type
                        );
                        return (
                          <>
                            <WorkItemStatus
                              workItem={item}
                              readonly
                              definition={definition}
                            />
                            <span>
                              {localGetReferenceDataDescription(
                                "WorkItemOutcome",
                                item.outcome,
                                ""
                              )}
                            </span>
                          </>
                        );
                      },
                    },
                    {
                      label: "Owner",
                      name: "owner",
                      size: 2,
                      sortable: true,
                      render: (item) => (
                        <>
                          <WorkItemOwner
                            className={classes.owner}
                            owner={
                              getFirstWorkItemUserByType(item.users, "OWNER")
                                ?.user
                            }
                            loggedInUser={loggedInUser}
                            readonly
                          />
                        </>
                      ),
                    },
                  ]}
                />
              </Grid>
              <Grid item>
                <Typography display="block" variant="subtitle1">
                  Reason for association
                </Typography>
                <TextField
                  value={reason}
                  placeholder="Enter reason here"
                  onChange={(event) => setReason(event.target.value)}
                  fullWidth
                />
              </Grid>
            </Grid>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onBack}>Back</Button>
        <Button color="primary" onClick={() => onSubmit(reason)}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

WorkItemAssociationReasonDialog.propTypes = {
  workItem: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,

  // redux state
  loggedInUser: PropTypes.object,
  workItemTypes: PropTypes.object.isRequired,
  localGetReferenceDataDescription: PropTypes.func.isRequired,
};

WorkItemAssociationReasonDialog.defaultProps = {
  loggedInUser: null,
  workItem: null,
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
  workItemTypes: getReferenceDataType(state, "WorkItemType"),
  localGetReferenceDataDescription: (type, id, defaultValue) =>
    getReferenceDataDescription(
      state,
      type,
      id,
      defaultValue === undefined ? "-" : defaultValue
    ),
});

export default connect(mapStateToProps)(WorkItemAssociationReasonDialog);
