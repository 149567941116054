import _ from "lodash";

const list = (state = { types: {}, initialised: false }, action) => {
  switch (action.type) {
    case "FETCH_REF_DATA_SUCCESS":
      const types = {};

      Object.keys(action.response.result).forEach((type) => {
        types[type] = {
          ids: action.response.result[type].id,
          values: action.response.entities[type],
        };
      });

      return {
        initialised: true,
        types,
      };
    default:
      return state;
  }
};

export default list;

// selectors
export const isLoaded = (state) => state.initialised;

export const getType = (state, type) => state.types[type];

export const getTypeFiltered = (state, type, filter) => {
  const refData = state.types[type];
  const ids = refData.ids.filter((id) => filter(refData.values[id]));
  const values = {};
  ids.forEach((id) => {
    values[id] = refData.values[id];
  });

  return {
    ids,
    values,
  };
};

/**
 * Returns a hash map of enum id mapping to label.
 */
export const getMap = (state, type) =>
  Object.values(state.types[type].values).reduce((map, obj) => {
    map[obj.id] = obj.description; // eslint-disable-line no-param-reassign
    return map;
  }, {});

/**
 * Returns the description for a reference data item given the type and id.
 */
export const getDescription = (state, type, id, defaultValue = "") =>
  _.get(state, `types.${type}.values.${id}.description`, defaultValue);

/**
 * Returns array of label/value options, suitable for select menu items
 */
export const getOptions = (state, type) => {
  const refData = state.types[type];
  return refData.ids
    .map((id) => refData.values[id])
    .filter((item) => item.selectable)
    .map((item) => ({ label: item.description, value: item.id }));
};
