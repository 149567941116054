import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { fetchTags } from "../../../actions/tags";
import {
  getLabels,
  getReferenceDataDescription,
  getTags,
} from "../../../reducers";
import { scrollToFirstInvalidInput } from "../../../util/formHelpers";
import CardSection from "../../common/CardSection";
import FormError from "../../common/FormError";
import EntityRelationshipFields from "../business/EntityRelationshipFields";
import ControlDetailFields from "./ControlDetailFields";

export const CREATE_CONTROL_FORM_NAME = "createControlForm";

const relationshipTypes = [
  "PROMOTER",
  "SUB_PROMOTER",
  "TRUSTEE",
  "RESPONSIBLE_ENTITY",
  "KIWISAVER_MANAGER",
];

const CreateControlForm = ({ error, fieldLabels, change, untouch }) => {
  return (
    <form autoComplete="off">
      {error && <FormError errorMessage={error} />}
      <CardSection title={fieldLabels.labels.entityRelationship} gutterBottom>
        <EntityRelationshipFields
          formName={CREATE_CONTROL_FORM_NAME}
          fieldLabels={fieldLabels.nestedTypes.entityRelationship}
          change={change}
          untouch={untouch}
          customisations={[
            {
              field: "entityRelationship.fundLevel",
              state: "EXCLUDED",
            },
          ]}
          autoPopulateEntitiesByRelationshipTypes={relationshipTypes}
          filterEntitiesByRelationshipTypes={relationshipTypes}
          defaultVisibilityScope={{
            INTERNAL: "ORGANISATION",
            EXTERNAL: "ORGANISATION",
          }}
        />
      </CardSection>
      <CardSection title="Control details" gutterBottom>
        <ControlDetailFields fieldLabels={fieldLabels} data-cy={fieldLabels} />
      </CardSection>
    </form>
  );
};

CreateControlForm.propTypes = {
  error: PropTypes.string,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  tags: PropTypes.array.isRequired,
  getRiskRatingDescription: PropTypes.func.isRequired,
  localFetchTags: PropTypes.func.isRequired,
};

CreateControlForm.defaultProps = {
  error: null,
};

const mapStateToProps = (state) => ({
  fieldLabels: getLabels(state).Control,
  tags: getTags(state),
  getRiskRatingDescription: (rating) =>
    getReferenceDataDescription(state, "RiskRating", rating, "-"),
});

const form = reduxForm({
  form: CREATE_CONTROL_FORM_NAME,
  onSubmitFail: (errors) => scrollToFirstInvalidInput(errors),
});
const reduxData = connect(mapStateToProps, {
  localFetchTags: fetchTags,
});

export default compose(form, reduxData)(CreateControlForm);
