import { useAuth0 } from "@auth0/auth0-react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {
  deleteDocument,
  loadWorkItemChildren,
  saveDocuments,
  setDocumentFlags,
} from "../../../../actions/workItems";
import workItemsApi from "../../../../services/api/workItems";
import documentZipApi from "../../../../services/api/documentZip";
import {
  getFileUploadsInProgress,
  getLoggedInUser,
  getReferenceDataType,
  getWorkItemChildren,
  isDragging,
} from "../../../../reducers";
import { getWorkItemLink } from "../../../../routes/routeUtils";
import api from "../../../../services/api";
import { asyncFilePoll } from "../../../../util/asyncRequestHelper";
import dates from "../../../../util/dates";
import {
  appliesByCategory,
  appliesByParties,
  appliesByRoles,
  appliesByTemplate,
  getResolvedDefinition,
} from "../../../../util/workItemTypeUtils";
import AlertDialog from "../../../common/AlertDialog";
import ChoiceChips from "../../../common/ChoiceChips";
import VersionedFileUpload from "../../../common/VersionedFileUpload";
import FileList from "../../../common/file/FileList";
import MetropolisLinkedFileList from "../../../common/file/MetropolisLinkedFileList";
import FlaggedFileList from "../../../common/file/FlaggedFileList";
import metropolisLogo from "../../../../images/metropolis-logo-sml.png";
import getFlagOptions from "../../../common/file/menu/getFlagOptions";
import getOptions from "../../../common/file/menu/getOptions";
import WorkItemFileMenuItems from "../../../common/file/menu/WorkItemFileMenuItems";
import FileViewerDialog from "../../../common/file/viewer/FileViewerDialog";

const PUBLISH_VERSION = "PUBLISH_VERSION";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  filter: {
    display: "flex",
    padding: theme.spacing(2),
  },
  buttonIcon: {
    marginLeft: theme.spacing(1),
  },
  fileUpload: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  downloadAsZipLink: {
    cursor: "pointer",
    color: theme.palette.swatch.link,
  },
});

const uploadNamespace = "workitem-self";

const WorkItemFileUpload = ({
  classes,
  history,
  loggedInUser,
  workItem,
  combinedDocuments,
  readonly,
  allowActions,
  workItemTypes,
  documentFlags,
  children,
  dragging,
  openActivity,
  openQuestion,
  localSetDocumentFlags,
  localDeleteDocument,
  localLoadWorkItemChildren,
  localSaveDocuments,
  filesInProgress,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [deleteFile, setDeleteFile] = useState(null);
  const [filter, setFilter] = useState({});
  const [showDropzone, setShowDropzone] = useState(false);
  const [fileViewerState, setFileViewerState] = useState({
    open: false,
    fileSrc: undefined,
    onSubmit: undefined,
  });
  const definition = getResolvedDefinition(
    workItemTypes,
    workItem.type,
    workItem.parent?.type
  );

  const compareDates = (docA, docB) =>
    dates.parseTimestamp(docB.uploaded).getTime() -
    dates.parseTimestamp(docA.uploaded).getTime();

  useEffect(() => {
    getAccessTokenSilently().then((accessToken) =>
      localLoadWorkItemChildren(workItem.id, accessToken)
    );
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (filesInProgress.length > 0) {
      setShowDropzone(true);
    }
  }, [filesInProgress]);

  const closeFileViewerDialog = () => {
    setFileViewerState({
      open: false,
      fileSrc: undefined,
      onSubmit: undefined,
    });
  };

  const openFileViewerDialog =
    (workItemId, document, onDialogSubmit) => async () => {
      const accessToken = await getAccessTokenSilently();
      const fileSrc = await api.workItems.documentDownloadUrl(
        workItemId,
        document.source,
        document.id,
        false,
        accessToken
      );
      setFileViewerState({
        open: true,
        fileSrc,
        onSubmit: () => {
          onDialogSubmit();
          closeFileViewerDialog();
        },
      });
    };

  const handleFlagDocument = async (document, selectedFlags) => {
    const accessToken = await getAccessTokenSilently();
    const newFlags = _.xor(document.flags, selectedFlags);
    localSetDocumentFlags(
      document.workItemId || workItem.id,
      document,
      newFlags,
      accessToken
    );
  };

  const handleFileDelete = (document) => {
    setDeleteFile(document);
  };

  const cancelFileDelete = () => {
    setDeleteFile(null);
  };

  const executeFileDelete = async () => {
    const accessToken = await getAccessTokenSilently();
    localDeleteDocument(
      deleteFile.workItemId || workItem.id,
      deleteFile.source,
      deleteFile.id,
      accessToken
    );
    setDeleteFile(null);
  };

  const canDeleteFiles = (typeDefinition) => {
    const deleteRules = typeDefinition.fileDeleteRules;
    return (
      appliesByTemplate(workItem, deleteRules) &&
      appliesByRoles(loggedInUser, deleteRules)
    );
  };

  const getAvailableFlags = (typeDefinition, givenWorkItem = workItem) =>
    typeDefinition.documentFlags
      .filter((documentFlagAssignment) =>
        appliesByCategory(givenWorkItem, documentFlagAssignment)
      )
      .filter((documentFlagAssignment) =>
        appliesByParties(givenWorkItem, loggedInUser, documentFlagAssignment)
      )
      .map(
        (documentFlagAssignment) =>
          documentFlags.values[documentFlagAssignment.documentFlag]
      );

  const openWorkItem = (child) => {
    history.push(getWorkItemLink(child));
  };

  const onFileClick = async (document) => {
    const accessToken = await getAccessTokenSilently();
    const workItemId = document.workItemId || workItem.id;
    api.workItems
      .documentDownloadUrl(
        workItemId,
        document.source,
        document.id,
        false,
        accessToken
      )
      .then((location) => window.open(location, "_blank"));
  };

  const getDocumentsForWorkItem = (workItemId) =>
    combinedDocuments[workItemId] || [];

  const saveDocuments = async (docs) => {
    const accessToken = await getAccessTokenSilently();
    localSaveDocuments(workItem.id, docs, accessToken);
  };

  const onDownloadAll = async (workItemId) => {
    const accessToken = await getAccessTokenSilently();
    asyncFilePoll(
      null,
      "Documents download",
      "Please wait while we prepare the zip file",
      "This window can be closed",
      workItemsApi.downloadAllDocuments(workItemId, accessToken),
      (response) => documentZipApi.getDocumentZip(response.id, accessToken),
      (response) =>
        documentZipApi.getDownloadUrl(response.id, false, accessToken)
    );
  };

  const availableFlags = getAvailableFlags(definition);
  const canDeleteExisting = canDeleteFiles(definition);
  const orderedChildren = _.orderBy(children, (c) => c.title.toUpperCase());
  const childTypeDefinitions = definition.childTypes.map(
    (childType) => workItemTypes.values[childType.type].props.definition
  );

  const documents = getDocumentsForWorkItem(workItem.id)
    .filter((doc) => doc.source === "DEFAULT")
    .map((doc) => ({
      ...doc.document,
      source: doc.source,
      canDelete: canDeleteExisting,
      availableFlags,
    }))
    .sort(compareDates);

  const filterTypes = [
    {
      value: "INTERNAL_NOTE_ATTACHMENTS",
      label: "Internal notes",
      match: getDocumentsForWorkItem(workItem.id)
        .filter((doc) => doc.source === "INTERNAL")
        .map((doc) => ({
          ...doc.document,
          source: doc.source,
          namespace: "Internal note",
          groupKey: doc.activityId,
          canDelete: canDeleteFiles(definition, workItem),
          additionalActions: [
            {
              id: `open${doc.activityId}`,
              label: "Open note",
              handleSelect: () => openActivity(doc.activityId),
            },
          ],
        }))
        .sort(compareDates),
    },
    {
      value: "PRIVATE_NOTE_ATTACHMENTS",
      label: "Private notes",
      match: getDocumentsForWorkItem(workItem.id)
        .filter((doc) => doc.source === "PRIVATE")
        .map((doc) => ({
          ...doc.document,
          source: doc.source,
          namespace: "Private note",
          groupKey: doc.activityId,
          canDelete: false,
          additionalActions: [
            {
              id: `open${doc.activityId}`,
              label: "Open note",
              handleSelect: () => openActivity(doc.activityId),
            },
          ],
        }))
        .sort(compareDates),
    },
    {
      value: "EMAIL_ATTACHMENTS",
      label: "Email",
      match: getDocumentsForWorkItem(workItem.id)
        .filter((doc) => doc.source === "EMAIL")
        .map((doc) => ({
          ...doc.document,
          source: doc.source,
          namespace: "Email",
          groupKey: doc.activityId,
          canDelete: false,
          additionalActions: [
            {
              id: `open${doc.activityId}`,
              label: "View email",
              handleSelect: () => openActivity(doc.activityId),
            },
          ],
        }))
        .sort(compareDates),
    },
    {
      value: "QUESTION_RESPONSE_ATTACHMENTS",
      label: "Question responses",
      match: getDocumentsForWorkItem(workItem.id)
        .filter((doc) => doc.source === "QUESTION_RESPONSE")
        .map((doc) => ({
          ...doc.document,
          source: doc.source,
          namespace: "Question response",
          groupKey: doc.questionId,
          canDelete: false,
          additionalActions: [
            {
              id: `open${doc.questionId}`,
              label: "Open question",
              handleSelect: () => openQuestion(doc.questionId),
            },
          ],
        }))
        .sort(compareDates),
    },
  ];

  // Add filters for all the children work items
  childTypeDefinitions.forEach((childTypeDefinition) => {
    const childName = childTypeDefinition.name;
    const childTypeChildren = orderedChildren.filter(
      (child) => child.type === childTypeDefinition.id
    );
    filterTypes.push({
      value: "CHILDREN",
      label: `Related ${childName.toLowerCase()}s`,
      match: _.flatMap(childTypeChildren, (child) =>
        _.flatMap(
          getDocumentsForWorkItem(child.id)
            .filter((doc) => doc.source === "DEFAULT")
            .map((doc) => ({
              ...doc.document,
              source: doc.source,
              namespace: child.title,
              groupKey: child.id,
              workItemId: child.id,
              canDelete: canDeleteFiles(childTypeDefinition, child),
              availableFlags: getAvailableFlags(childTypeDefinition, child),
              additionalActions: [
                {
                  id: `open${child.id}`,
                  label: `Open ${childName.toLowerCase()}`,
                  handleSelect: () => openWorkItem(child),
                },
              ],
            }))
        )
      ),
    });

    filterTypes.push({
      value: "CHILD_INTERNAL_NOTE_ATTACHMENTS",
      label: `Related ${childName.toLowerCase()}s (internal notes)`,
      match: _.flatMap(childTypeChildren, (child) =>
        _.flatMap(
          getDocumentsForWorkItem(child.id)
            .filter((doc) => doc.source === "INTERNAL")
            .map((doc) => ({
              ...doc.document,
              source: doc.source,
              namespace: `${child.title} / Internal note`,
              groupKey: `${child.title} - ${doc.activityId}`,
              workItemId: child.id,
              canDelete: canDeleteFiles(childTypeDefinition, child),
              additionalActions: [
                {
                  id: `open${child.id}`,
                  label: `Open ${childName.toLowerCase()}`,
                  handleSelect: () => openWorkItem(child),
                },
              ],
            }))
        )
      ),
    });

    filterTypes.push({
      value: "CHILD_PRIVATE_NOTE_ATTACHMENTS",
      label: `Related ${childName.toLowerCase()}s (private notes)`,
      match: _.flatMap(childTypeChildren, (child) =>
        _.flatMap(
          getDocumentsForWorkItem(child.id)
            .filter((doc) => doc.source === "PRIVATE")
            .map((doc) => ({
              ...doc.document,
              source: doc.source,
              namespace: `${child.title} / Private note`,
              groupKey: `${child.title} - ${doc.activityId}`,
              workItemId: child.id,
              canDelete: false,
              additionalActions: [
                {
                  id: `open${child.id}`,
                  label: `Open ${childName.toLowerCase()}`,
                  handleSelect: () => openWorkItem(child),
                },
              ],
            }))
            .sort(compareDates)
        )
      ),
    });

    filterTypes.push({
      value: "CHILD_EMAIL_ATTACHMENTS",
      label: `Related ${childName.toLowerCase()}s (email)`,
      match: _.flatMap(childTypeChildren, (child) =>
        _.flatMap(
          getDocumentsForWorkItem(child.id)
            .filter((doc) => doc.source === "EMAIL")
            .map((doc) => ({
              ...doc.document,
              source: doc.source,
              namespace: `${child.title} / Email`,
              groupKey: `${child.title} - ${doc.activityId}`,
              workItemId: child.id,
              canDelete: false,
              additionalActions: [
                {
                  id: `open${child.id}`,
                  label: `Open ${childName.toLowerCase()}`,
                  handleSelect: () => openWorkItem(child),
                },
              ],
            }))
            .sort(compareDates)
        )
      ),
    });

    filterTypes.push({
      value: "CHILD_QUESTION_RESPONSE_ATTACHMENTS",
      label: `Related ${childName.toLowerCase()}s (question responses)`,
      match: _.flatMap(childTypeChildren, (child) =>
        _.flatMap(
          getDocumentsForWorkItem(child.id)
            .filter((doc) => doc.source === "QUESTION_RESPONSE")
            .map((doc) => ({
              ...doc.document,
              source: doc.source,
              namespace: `${child.title} / Question response`,
              groupKey: `${child.title} - ${doc.questionId}`,
              workItemId: child.id,
              canDelete: false,
              additionalActions: [
                {
                  id: `open${child.id}`,
                  label: `Open ${childName.toLowerCase()}`,
                  handleSelect: () => openWorkItem(child),
                },
              ],
            }))
            .sort(compareDates)
        )
      ),
    });
  });

  // add filtered-in documents to the overall list
  filterTypes
    .filter((filterType) => filter[filterType.value])
    .forEach((filterType) =>
      filterType.match.forEach((d) => documents.push(d))
    );

  return (
    <Card className={classes.root} elevation={0}>
      <AlertDialog
        title="Delete file?"
        body={`You are about to permanently delete file ${
          deleteFile && deleteFile.fileName
        }`}
        submitButtonText="Delete"
        open={!!deleteFile}
        onCancel={() => cancelFileDelete()}
        onSubmit={() => executeFileDelete()}
        data-cy="deleteFile"
      />
      <CardHeader
        title="Files"
        action={
          readonly ? null : (
            <Tooltip title="Add File" disableFocusListener data-cy="tooltip">
              <Button
                disabled={showDropzone}
                onClick={() => setShowDropzone(true)}
                variant="outlined"
                data-cy="uploadFiles"
              >
                Upload Files
                <CloudUploadIcon
                  className={classes.buttonIcon}
                  color={showDropzone ? "disabled" : "action"}
                  data-cy="uploadFile"
                />
              </Button>
            </Tooltip>
          )
        }
      />
      <CardContent>
        {filterTypes.some((filterType) => filterType.match.length > 0) && (
          <div className={classes.filter}>
            {filterTypes
              .filter((filterType) => filterType.match.length > 0)
              .map((filterType) => (
                <ChoiceChips
                  key={filterType.value}
                  value={filter[filterType.value]}
                  options={[
                    {
                      label: filterType.label,
                      value: true,
                    },
                  ]}
                  onChange={(value) =>
                    setFilter({
                      ...filter,
                      [filterType.value]: value,
                    })
                  }
                  data-cy={filter[filterType.value]}
                />
              ))}
          </div>
        )}
        <VersionedFileUpload
          existingDocuments={workItem.documents}
          className={classes.fileUpload}
          uploadNamespace={uploadNamespace}
          onSubmit={saveDocuments}
          onClose={() => setShowDropzone(false)}
          showDropzone={showDropzone || dragging}
          data-cy="versionFileUpload"
        />
        <FileList
          data-cy={documents}
          documents={documents}
          additionalContent={(doc) =>
            workItem.metropolisLinkedDocuments[doc.id] ? (
              <Box ml={1} component="span">
                <Tooltip title="Published to Metropolis">
                  <img src={metropolisLogo} alt="Metropolis" height={12} />
                </Tooltip>
              </Box>
            ) : undefined
          }
          noFileMessage="No files."
          onFileClick={onFileClick}
          renderMenuItems={(document, onClose) => {
            if (readonly) return null;
            const flagOptions = getFlagOptions(
              document,
              allowActions ? handleFlagDocument : null
            );
            const options = getOptions(
              document,
              allowActions ? handleFileDelete : null
            );
            if (flagOptions?.length > 0 || options?.length > 0) {
              const modifiedFlagOptions = flagOptions.map((option) => {
                // Add FileViewerDialog as an intermediate step before flagging file as PUBLISH_VERSION
                if (
                  option.id === PUBLISH_VERSION &&
                  !document.flags.find((flag) => flag === PUBLISH_VERSION)
                ) {
                  return {
                    ...option,
                    handleSelect: openFileViewerDialog(
                      workItem.id,
                      document,
                      option.handleSelect
                    ),
                  };
                }
                return option;
              });

              return (
                <WorkItemFileMenuItems
                  onClose={onClose}
                  flagOptions={modifiedFlagOptions}
                  options={options}
                  data-cy={options}
                />
              );
            }
            return null;
          }}
        />
        <FlaggedFileList
          documents={documents}
          onFileClick={onFileClick}
          data-cy={documents}
        />
        <MetropolisLinkedFileList
          workItem={workItem}
          onFileClick={onFileClick}
          data-cy={workItem}
        />

        <FileViewerDialog
          data-cy="flagASPublishVersion"
          open={fileViewerState.open}
          fileSrc={fileViewerState.fileSrc}
          content={
            <Typography data-cy="reviewText">
              Please review the document to confirm. If unable to preview file,
              it will be downloaded, or click{" "}
              <a
                href={fileViewerState.fileSrc}
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              .
            </Typography>
          }
          submitButtonText="Flag as publish version"
          onSubmit={fileViewerState.onSubmit}
          onCancel={() =>
            setFileViewerState({
              open: false,
              onSubmit: undefined,
            })
          }
        />
        {documents && documents.length ? (
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link
                key="create-new"
                className={classes.downloadAsZipLink}
                onClick={() => {
                  return onDownloadAll(workItem.id);
                }}
              >
                Download all as zip
              </Link>
            </Grid>
          </Grid>
        ) : null}
      </CardContent>
    </Card>
  );
};

WorkItemFileUpload.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  loggedInUser: PropTypes.object,
  workItem: PropTypes.object.isRequired,
  readonly: PropTypes.bool.isRequired,
  allowActions: PropTypes.bool,
  openActivity: PropTypes.func,
  openQuestion: PropTypes.func,
  combinedDocuments: PropTypes.object.isRequired,

  // redux
  children: PropTypes.array.isRequired,
  documentFlags: PropTypes.object.isRequired,
  workItemTypes: PropTypes.object.isRequired,
  dragging: PropTypes.bool.isRequired,
  filesInProgress: PropTypes.array.isRequired,
  localSaveDocuments: PropTypes.func.isRequired,
  localDeleteDocument: PropTypes.func.isRequired,
  localSetDocumentFlags: PropTypes.func.isRequired,
  localLoadWorkItemChildren: PropTypes.func.isRequired,
};

WorkItemFileUpload.defaultProps = {
  loggedInUser: null,
  allowActions: true,
  openActivity: null,
  openQuestion: null,
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
  documentFlags: getReferenceDataType(state, "DocumentFlag"),
  workItemTypes: getReferenceDataType(state, "WorkItemType"),
  children: getWorkItemChildren(state),
  dragging: isDragging(state),
  filesInProgress: getFileUploadsInProgress(state)[uploadNamespace] || [],
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    localSaveDocuments: saveDocuments,
    localSetDocumentFlags: setDocumentFlags,
    localDeleteDocument: deleteDocument,
    localLoadWorkItemChildren: loadWorkItemChildren,
  })
)(withRouter(WorkItemFileUpload));
