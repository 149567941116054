import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { includeTenantParam } from "@certane/arcadia-web-components";
import { listMentions, patchMention } from "../../actions/mentions";
import {
  getMentions,
  getReferenceDataOptions,
  isLoading,
} from "../../reducers";
import dates from "../../util/dates";
import GridListing from "../common/GridListing";
import TimeAgo from "../common/TimeAgo";
import { useAuth0 } from "@auth0/auth0-react";

const MARK_AS_MENU = "MARK_AS_MENU";

const styles = () => ({
  primaryText: {
    display: "block",
    fontSize: "0.875rem",
    fontWeight: "600",
  },
});

const MentionListing = ({
  classes,
  loading,
  mentions,
  mentionOptions,
  filter,
  clearFilter,
  localListMentions,
  localPatchMention,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [anchorMarkAs, setAnchorMarkAs] = useState(null);
  const [selectedMention, setSelectedMention] = useState(null);

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      localListMentions(filter, accessToken);
    })();
  }, [filter]);

  const openMention = async (mention) => {
    window.open(mention.clickableUrl);

    if (mention.status === "UNREAD") {
      const accessToken = await getAccessTokenSilently();
      localPatchMention(
        mention.id,
        { status: "READ" },
        "Updated status",
        accessToken
      );
    }
  };

  const handleSetStatus = async (status, mention) => {
    const accessToken = await getAccessTokenSilently();
    localPatchMention(mention.id, { status }, "Updated status", accessToken);
    setAnchorMarkAs(null);
    setSelectedMention(null);
  };

  const markAsOptions = mentionOptions.map((option) => ({
    ...option,
    label: `Mark as ${option.label}`,
  }));

  return (
    <>
      <Menu
        id={MARK_AS_MENU}
        anchorEl={anchorMarkAs}
        open={Boolean(anchorMarkAs)}
        onClose={() => {
          setAnchorMarkAs(null);
          setSelectedMention(null);
        }}
      >
        {markAsOptions.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            disabled={
              !selectedMention || selectedMention.status === option.value
            }
            onClick={() => handleSetStatus(option.value, selectedMention)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>

      <GridListing
        sortedData={mentions}
        loading={loading && mentions.length === 0}
        sortBy={{}}
        dense={false}
        updateSort={() => {
          // do nothing.
        }}
        clearFilter={clearFilter}
        isHighlight1={(mention) => mention.status === "UNREAD"}
        isHighlight3={(mention) => mention.status === "FOLLOWUP"}
        action={(mention) => (
          <Tooltip title="More" disableFocusListener>
            <IconButton
              aria-owns={anchorMarkAs ? { MARK_AS_MENU } : null}
              aria-haspopup="true"
              onClick={(event) => {
                setAnchorMarkAs(event.currentTarget);
                setSelectedMention(mention);
              }}
            >
              <Icon>more_vert</Icon>
            </IconButton>
          </Tooltip>
        )}
        columns={[
          {
            label: "Alert",
            name: "alertName",
            size: 2,
            sortable: false,
            render: (mention) => (
              <span>{mention.alertName ? mention.alertName : "-"}</span>
            ),
          },
          {
            label: "Title",
            name: "title",
            size: 4,
            sortable: false,
            render: (mention) => (
              <span>{mention.title ? mention.title : "-"}</span>
            ),
          },
          {
            label: "Source",
            name: "sourceName",
            size: 3,
            sortable: false,
            render: (mention) => (
              <>
                <span className={classes.primaryText}>
                  {mention.sourceType}
                </span>
                {mention.sourceUrl && (
                  <a
                    href={includeTenantParam(mention.sourceUrl)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {mention.sourceName || mention.sourceType}
                  </a>
                )}
              </>
            ),
          },
          {
            label: "Published",
            name: "publishedAt",
            size: 2,
            sortable: false,
            render: (mention) =>
              mention.publishedAt ? (
                <TimeAgo
                  value={dates.parseTimestamp(mention.publishedAt)}
                  expandable
                />
              ) : (
                "-"
              ),
          },
          {
            label: "View",
            name: "clickableUrl",
            size: 1,
            sortable: false,
            render: (mention) => (
              <IconButton
                onClick={() => openMention(mention)}
                disabled={!mention.clickableUrl}
              >
                <Icon>open_in_new</Icon>
              </IconButton>
            ),
          },
        ]}
      />
    </>
  );
};

MentionListing.propTypes = {
  classes: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  clearFilter: PropTypes.func.isRequired,

  // redux
  loading: PropTypes.bool.isRequired,
  mentions: PropTypes.array.isRequired,
  mentionOptions: PropTypes.array.isRequired,
  localListMentions: PropTypes.func.isRequired,
  localPatchMention: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: isLoading(state),
  mentionOptions: getReferenceDataOptions(state, "MentionStatus"),
  mentions: getMentions(state),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    localListMentions: listMentions,
    localPatchMention: patchMention,
  })
)(MentionListing);
