import { withStyles } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { clearFiles, removeFile } from "../../../actions/files";
import { getFileUploadsInProgress, isDragging } from "../../../reducers";
import FileUpload from "../../common/FileUpload";
import FileIcon from "../../workitem/FileIcon";

const styles = (theme) => ({
  label: {
    position: "relative",
  },
  dropZone: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  dropZoneThin: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1) / 2,
  },
  field: {
    display: "flex",
    flexWrap: "wrap",
    alignContent: "center",
    alignItems: "flex-start",
  },
  nowrap: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1) / 4,
    marginTop: theme.spacing(1) / 4,
  },
  chipRoot: {
    maxWidth: "100%",
  },
  container: {
    marginTop: theme.spacing(1) - theme.spacing(1) / 4,
    marginBottom: theme.spacing(1) - theme.spacing(1) / 4,
  },
  borderBottom: {
    borderBottom: `solid 1px ${theme.palette.grey[500]}`,
    paddingBottom: "1px",
    "&:hover": {
      borderBottom: "solid 2px",
      paddingBottom: 0,
    },
  },
  addButton: {
    padding: theme.spacing(1) / 2,
    marginRight: theme.spacing(1),
    marginLeft: 0,
    marginBottom: theme.spacing(1) / 4,
    marginTop: theme.spacing(1) / 4,
  },
  avatar: {
    backgroundColor: "transparent",
  },
  chipContent: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const WrappedFilePicker = ({
  classes,
  className,
  label,
  fullWidth,
  input,
  meta: { touched, error, invalid },
  margin,
  helperText,
  required,
  uploadNamespace,
  filesInProgress,
  removeUploadedFile,
  dragging,
  hideUnderline,
  disableDropzone,
  multiple,
  localClearFiles,
  ...rest
}) => {
  const initExistingFiles = () => {
    if (input.value) {
      return multiple ? input.value : [input.value];
    }
    return [];
  };

  const [showDropzone, setShowDropzone] = useState(false);
  const [existingFiles, setExistingFiles] = useState(initExistingFiles());

  const setFiles = (files) => {
    if (multiple) {
      if (files && files.length > 0) {
        input.onChange([...existingFiles, ...files]);
      } else if (existingFiles && existingFiles.length > 0) {
        input.onChange([...existingFiles]);
      } else if (input.value && input.value.length > 0) {
        input.onChange([]);
      }
    } else if (files) {
      input.onChange(files);
    } else if (input.value) {
      input.onChange(null);
    }
  };

  // clear file list on unmount.
  useEffect(() => () => localClearFiles(uploadNamespace), []);

  return (
    <FormControl
      className={className}
      fullWidth={fullWidth}
      margin={margin}
      name={`position-${input.name}`}
    >
      {!disableDropzone && (
        <InputLabel
          className={classes.label}
          shrink
          required={required}
          data-cy={label}
        >
          {label}
        </InputLabel>
      )}
      <div className={hideUnderline ? null : classes.borderBottom}>
        <div className={disableDropzone ? null : classes.container}>
          <FileUpload
            className={
              filesInProgress.length > 0
                ? classes.dropZoneThin
                : classes.dropZone
            }
            onChange={setFiles}
            onClose={() => setShowDropzone(false)}
            uploadNamespace={uploadNamespace}
            showFiles={false}
            showDropzone={!disableDropzone && (showDropzone || dragging)}
            multiple={multiple}
            {...rest}
            data-cy="fileUpload"
          />
          {(filesInProgress.length > 0 ||
            !showDropzone ||
            existingFiles.length > 0) && (
            <div className={classes.field}>
              {!disableDropzone && !showDropzone && (
                <IconButton
                  className={classes.addButton}
                  color="inherit"
                  onClick={() => setShowDropzone(true)}
                  data-cy="uploadFile"
                >
                  <AttachFileIcon />
                </IconButton>
              )}
              {filesInProgress.map((document) => (
                <Chip
                  key={`${document.fileName}_${document.percentCompleted}`}
                  className={classes.chip}
                  classes={{
                    root: classes.chipRoot,
                    label: classes.nowrap,
                  }}
                  avatar={
                    <Avatar className={classes.avatar}>
                      <FileIcon document={document} data-cy="fileIcon" />
                    </Avatar>
                  }
                  label={document.fileName}
                  deleteIcon={
                    document.gcsObjectName ? undefined : (
                      <CircularProgress
                        variant="determinate"
                        value={
                          document.percentCompleted === 0
                            ? 10
                            : document.percentCompleted
                        }
                        size={20}
                        thickness={10}
                      />
                    )
                  }
                  onDelete={() => {
                    if (document.gcsObjectName) {
                      removeUploadedFile(uploadNamespace, document.fileName);
                    }
                  }}
                />
              ))}
              {existingFiles.map((document) => (
                <Chip
                  key={document.gcsObjectName}
                  className={classes.chip}
                  classes={{
                    root: classes.chipRoot,
                    label: classes.nowrap,
                  }}
                  avatar={
                    <Avatar className={classes.avatar}>
                      <FileIcon document={document} data-cy="fileIcon" />
                    </Avatar>
                  }
                  label={document.fileName}
                  onDelete={() => {
                    setExistingFiles(
                      existingFiles.filter(
                        (doc) => doc.gcsObjectName !== document.gcsObjectName
                      )
                    );
                    input.onChange(
                      (input.value || []).filter(
                        (doc) => doc.gcsObjectName !== document.gcsObjectName
                      )
                    );
                  }}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      {touched && error && (
        <FormHelperText error={touched && invalid}>{error}</FormHelperText>
      )}
      {!(touched && error) && helperText && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

WrappedFilePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  margin: PropTypes.string,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  uploadNamespace: PropTypes.string.isRequired,
  hideUnderline: PropTypes.bool,
  disableDropzone: PropTypes.bool,
  multiple: PropTypes.bool,

  // redux actions
  removeUploadedFile: PropTypes.func.isRequired,
  localClearFiles: PropTypes.func.isRequired,

  // redux state
  filesInProgress: PropTypes.array.isRequired,
  dragging: PropTypes.bool.isRequired,
};

WrappedFilePicker.defaultProps = {
  className: null,
  fullWidth: false,
  margin: "dense",
  required: false,
  helperText: null,
  hideUnderline: false,
  disableDropzone: false,
  multiple: true,
};

const mapStateToProps = (state, { uploadNamespace }) => ({
  filesInProgress: getFileUploadsInProgress(state)[uploadNamespace] || [],
  dragging: isDragging(state),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    removeUploadedFile: removeFile,
    localClearFiles: clearFiles,
  })
)(WrappedFilePicker);
