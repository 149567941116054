import useTheme from "@material-ui/core/styles/useTheme";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import _ from "lodash";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { withStyles } from "@material-ui/core/styles";
import logger from "../util/logger";
import DebouncedTextField from "./common/DebouncedTextField";
import Pagination from "./common/Pagination";

const styles = (theme) => ({
  dialogContentRoot: {
    paddingBottom: 0,
  },
  content: {
    position: "relative",
    maxHeight: "340px",
    minHeight: "340px",
    overflow: "hidden",
  },
  list: {
    position: "relative",
    maxHeight: "280px",
    minHeight: "280px",
    overflow: "auto",
  },
  listIcon: {
    marginRight: 0,
  },
  loading: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  chipLabel: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    marginRight: theme.spacing(2),
  },
  chipLabelDisabled: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1) / 4,
    marginTop: theme.spacing(1) / 4,
  },
  chipRoot: {
    maxWidth: "100%",
  },
  chipIcon: {
    marginLeft: 0,
  },
  selectAllText: {
    textAlign: "right",
    paddingRight: theme.spacing(1),
  },
  nowrap: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

const getNewSelection = (isMulti, existingValue, itemValue) => {
  if (isMulti) {
    if (!existingValue) {
      return [itemValue];
    }
    if (existingValue.some((v) => v.id === itemValue.id)) {
      return [...existingValue.filter((v) => v.id !== itemValue.id)];
    }
    return [...existingValue, itemValue];
  }
  return existingValue && existingValue.some((v) => v.id === itemValue.id)
    ? []
    : [itemValue];
};

const isChecked = (existingValue, itemValue) =>
  existingValue ? existingValue.some((v) => v.id === itemValue.id) : false;

const defaultPagination = {
  offset: 0,
  pageSize: 20,
  resultCount: 0,
};

const ListPicker = ({
  open,
  onSubmit,
  onClose,
  title,
  actionText,
  classes,
  maxWidth,
  isMulti,
  submitOnChange,
  clearable,
  selectAll,
  selected,
  disabledOptions,
  datasource,
  renderIcon,
  renderLabel,
  renderFilter,
  filterInitialValues,
  fromOption,
  toOption,
  additionalActions,
}) => {
  const isAsyncFetch = !_.isArray(datasource);

  const toOptions = (value) => {
    if (isMulti) {
      return value ? value.map(toOption) : [];
    }
    return value ? [toOption(value)] : [];
  };

  const fromOptions = (value) => {
    if (isMulti) {
      return value.map(fromOption);
    }
    if (value && value.length > 0) {
      return fromOption(value[0]);
    }
    return null;
  };

  const [mounted, setMounted] = useState(false);
  const [filter, setFilter] = useState(filterInitialValues);
  const [localSelected, setLocalSelected] = useState(toOptions(selected));
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [pagination, setPagination] = useState(defaultPagination);
  const [searching, setSearching] = useState(false);
  const [abortController, setAbortController] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const loadResults = (offset) => {
    if (isAsyncFetch) {
      if (abortController) {
        abortController.abort();
      }

      const newAbortController = window.AbortController
        ? new AbortController()
        : null;
      setSearching(true);
      setAbortController(newAbortController);

      const page = {
        ...pagination,
        offset,
      };

      datasource(filter, page, newAbortController)
        .then((response) => {
          if (mounted) {
            setFilteredOptions(response.results.map(toOption));
            setPagination({
              offset: response.offset,
              previousOffset: response.previousOffset,
              nextOffset: response.nextOffset,
              pageSize: response.pageSize,
              resultCount: response.resultCount,
            });
          }
        })
        .catch((error) => {
          switch (error.name) {
            case "AbortError":
              logger.info("Request was aborted");
              break;
            default:
              logger.warn("Unhandled error", error);
              throw error;
          }
        })
        .finally(() => {
          if (mounted) {
            setSearching(false);
            setAbortController(null);
          }
        });
    } else {
      const options = datasource.map(toOption);
      const filtered = !filter.textSearch
        ? options
        : options.filter(
            (option) =>
              option.label
                .toLowerCase()
                .indexOf(filter.textSearch.toLowerCase()) !== -1
          );
      const pagedResults = _.slice(
        filtered,
        offset,
        offset + pagination.pageSize
      );
      setFilteredOptions(pagedResults);
      setPagination({
        offset,
        previousOffset:
          offset === 0 ? null : Math.min(0, offset - pagination.pageSize),
        nextOffset:
          filtered.length - offset > pagination.pageSize
            ? offset + pagination.pageSize
            : null,
        pageSize: pagination.pageSize,
        resultCount: filtered.length,
      });
    }
  };

  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
    };
  }, []);

  useEffect(() => {
    loadResults(0);
  }, [filter]);

  const onChange = (value) => {
    setLocalSelected(value);

    if (submitOnChange) {
      onSubmit(fromOptions(value));
    }
  };

  const initialise = () => {
    setFilter({ ...filterInitialValues });
    setLocalSelected(toOptions(selected));
    setFilteredOptions([]);
    setPagination(defaultPagination);
  };

  const handlePreviousPage = () => {
    loadResults(pagination.previousOffset);
  };

  const handleNextPage = () => {
    loadResults(pagination.nextOffset);
  };

  const selectAllFromPage = () => {
    const newSelection = _.uniqBy([...localSelected, ...filteredOptions], "id");
    onChange(newSelection);
  };

  const deselectAllFromPage = () => {
    const newSelection = localSelected.filter(
      (sel) => !filteredOptions.find((option) => option.id === sel.id)
    );
    onChange(newSelection);
  };

  const allSelected =
    filteredOptions.filter((option) =>
      localSelected.find((sel) => sel.id === option.id)
    ).length === filteredOptions.length;

  const isDisabled = (option) =>
    !!disabledOptions.map(toOption).find((d) => d.id === option.id);

  const updateFilter = (field, value) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      onEnter={initialise}
      fullWidth
      maxWidth={fullScreen ? undefined : maxWidth}
      aria-labelledby="ListPicker"
      data-cy="listPicker"
    >
      <DialogTitle id="ListPicker" data-cy={title}>
        {title}
      </DialogTitle>
      <DialogContent
        classes={{
          root: submitOnChange ? null : classes.dialogContentRoot,
        }}
      >
        {renderFilter(updateFilter, filter)}
        <div className={classes.content}>
          <List className={classes.list}>
            {searching && (
              <div className={classes.loading}>
                <CircularProgress size={24} />
              </div>
            )}
            {!searching && selectAll && isMulti && filteredOptions.length > 0 && (
              <ListItem dense>
                <ListItemText
                  className={classes.selectAllText}
                  primary={
                    allSelected && filteredOptions.length > 0
                      ? "Deselect all"
                      : "Select all"
                  }
                  data-cy="selectAll"
                />
                <ListItemSecondaryAction>
                  <Checkbox
                    checked={allSelected}
                    tabIndex={-1}
                    onClick={
                      allSelected ? deselectAllFromPage : selectAllFromPage
                    }
                    data-cy="checkBox"
                  />
                </ListItemSecondaryAction>
              </ListItem>
            )}
            {!searching && filteredOptions.length === 0 && (
              <ListItem dense>
                <ListItemText primary="No results" data-cy="noResults" />
              </ListItem>
            )}
            {!searching &&
              filteredOptions.map((option) => (
                <ListItem
                  key={option.id}
                  role={undefined}
                  dense
                  button
                  disabled={isDisabled(option)}
                  onClick={() =>
                    onChange(getNewSelection(isMulti, localSelected, option))
                  }
                >
                  <ListItemIcon className={classes.listIcon} data-cy="key">
                    {renderIcon(fromOption(option), 24)}
                  </ListItemIcon>
                  {renderLabel && renderLabel(fromOption(option))}
                  {!renderLabel && (
                    <ListItemText
                      primary={
                        <Typography
                          className={classes.nowrap}
                          title={option.label}
                        >
                          {option.label}
                        </Typography>
                      }
                      data-cy={option.label}
                    />
                  )}
                  <ListItemSecondaryAction>
                    <Checkbox
                      data-cy="checkBox"
                      checked={isChecked(localSelected, option)}
                      tabIndex={-1}
                      disabled={isDisabled(option)}
                      onClick={() =>
                        onChange(
                          getNewSelection(isMulti, localSelected, option)
                        )
                      }
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>

          <Pagination
            pagination={pagination}
            handlePrevious={handlePreviousPage}
            handleNext={handleNextPage}
            data-cy="page"
          />
        </div>

        <div>
          {localSelected.map((option) => (
            <Chip
              key={option.id}
              className={classes.chip}
              classes={{
                root: classes.chipRoot,
                label: isDisabled(option)
                  ? classes.chipLabelDisabled
                  : classes.chipLabel,
                icon: classes.chipIcon,
              }}
              icon={renderIcon(fromOption(option), 32)}
              label={option.label}
              onDelete={
                !isDisabled(option)
                  ? () =>
                      onChange(getNewSelection(isMulti, localSelected, option))
                  : null
              }
              data-cy="selection"
            />
          ))}
        </div>
      </DialogContent>
      {(!submitOnChange || additionalActions) && (
        <DialogActions>
          {additionalActions}
          {!submitOnChange && (
            <>
              <Button color="primary" onClick={onClose} data-cy="cancel">
                Cancel
              </Button>
              {clearable && (
                <Button
                  color="primary"
                  onClick={() => onChange([])}
                  data-cy="clear"
                >
                  Clear
                </Button>
              )}
              <Button
                color="primary"
                onClick={() => onSubmit(fromOptions(localSelected))}
                data-cy="submit"
              >
                {actionText}
              </Button>
            </>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

ListPicker.propTypes = {
  classes: PropTypes.object.isRequired,
  selected: PropTypes.any,
  disabledOptions: PropTypes.array,
  title: PropTypes.string.isRequired,
  actionText: PropTypes.string.isRequired,
  maxWidth: PropTypes.string,
  // function(filter, page, newAbortController) or array of options
  datasource: PropTypes.oneOfType([PropTypes.func, PropTypes.array]).isRequired,
  isMulti: PropTypes.bool,
  renderLabel: PropTypes.func,
  renderFilter: PropTypes.func,
  filterInitialValues: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitOnChange: PropTypes.bool,
  renderIcon: PropTypes.func.isRequired,
  clearable: PropTypes.bool,
  selectAll: PropTypes.bool,
  toOption: PropTypes.func.isRequired,
  fromOption: PropTypes.func.isRequired,
  additionalActions: PropTypes.array,
};

ListPicker.defaultProps = {
  selected: null,
  isMulti: false,
  maxWidth: "sm",
  renderLabel: null,
  renderFilter: (onChange, filter) => (
    <DebouncedTextField
      value={filter.textSearch || ""}
      onChange={(text) => onChange("textSearch", text)}
      placeholder="Type to filter..."
      margin="normal"
      autoFocus
      data-cy="typeToFilter"
    />
  ),
  filterInitialValues: {},
  submitOnChange: false,
  clearable: false,
  selectAll: false,
  disabledOptions: [],
  additionalActions: [],
};

export default withStyles(styles)(ListPicker);
