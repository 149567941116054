import { useAuth0 } from "@auth0/auth0-react";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  removeWorkItemUser,
  setWorkItemUsers,
} from "../../../actions/workItems";
import { User, WorkItemUser } from "../../../model/User";
import { WorkItem } from "../../../model/WorkItem";
import { getReferenceDataType } from "../../../reducers";
import { authorizer, roles, useTenant } from "@certane/arcadia-web-components";
import { getResolvedDefinition } from "../../../util/workItemTypeUtils";
import { getWorkItemUsersByType } from "../../../util/workItemUserUtils";
import externalUserCanRemoveUser from "./externalUserCanRemoveUser";
import WorkItemUsers from "./WorkItemUsers";

interface Props {
  workItem: WorkItem;
  readonly: boolean;
  workItemTypes: User;
  localSetWorkItemUsers: (
    param: {
      workItemId: string;
      userIds: Record<string, string[]>;
      reason: string | undefined;
    },
    accessToken: string
  ) => Promise<unknown>;
  localRemoveWorkItemUser: (
    workItemId: string,
    type: string,
    userId: string,
    accessToken: string
  ) => Promise<unknown>;
}

const CollaboratorCard = ({
  workItem,
  readonly,
  workItemTypes,
  localSetWorkItemUsers,
  localRemoveWorkItemUser,
}: Props) => {
  const { getAccessTokenSilently, user } = useAuth0();
  const { tenant } = useTenant();
  const [workItemAgents, setWorkItemAgents] = useState<WorkItemUser[]>([]);
  const [workItemSubmitters, setWorkItemSubmitters] = useState<WorkItemUser[]>(
    []
  );

  const definition = getResolvedDefinition(
    workItemTypes,
    workItem.type,
    workItem.parent?.type
  );
  const { submittableBy, collaboratableBy } = definition;

  useEffect(() => {
    (async () => {
      const agents = getWorkItemUsersByType(workItem.users, "AGENT");
      const submitters = getWorkItemUsersByType(workItem.users, "SUBMITTER");
      setWorkItemAgents(agents);
      setWorkItemSubmitters(submitters);
    })();
  }, [workItem.users]);

  const canAddUsers = () => true;
  const canRemoveUser = (
    loggedInUser: User,
    selectedWorkItemUser: WorkItemUser
  ) => {
    if (!authorizer.check("work-item", "editor", user, tenant?.id))
      return false;
    if (loggedInUser.externalUser) {
      return externalUserCanRemoveUser(loggedInUser, selectedWorkItemUser.user);
    } else {
      return true;
    }
  };

  const handleAddUsers = async (users: User[]) => {
    // By default add the internal users as 'AGENT' and the external users as 'SUBMITTER'
    const updatedAgents: User[] = [];
    const updatedSubmitters: User[] = [];

    users.forEach((user) => {
      if (workItemAgents.some((a) => a.user.id === user.id)) {
        // if already an AGENT leave them as an AGENT
        updatedAgents.push(user);
      } else if (workItemSubmitters.some((s) => s.user.id === user.id)) {
        // if already a SUBMITTER leave them as a SUBMITTER
        updatedSubmitters.push(user);
      } else if (_.intersection(roles.EXTERNAL_ROLES, user.roles).length > 0) {
        // Add external users as SUBMITTER
        updatedSubmitters.push(user);
      } else if (_.intersection(collaboratableBy, user.roles).length > 0) {
        // Add collaboratable users as AGENT
        updatedAgents.push(user);
      } else if (_.intersection(submittableBy, user.roles).length > 0) {
        // Add submittable users as SUBMITTER
        updatedSubmitters.push(user);
      } else {
        // Default add as an AGENT
        updatedAgents.push(user);
      }
    });

    const userIds: Record<string, string[]> = {};
    userIds.SUBMITTER = updatedSubmitters.map((user) => user.id);
    userIds.AGENT = updatedAgents.map((user) => user.id);

    const accessToken = await getAccessTokenSilently();

    await localSetWorkItemUsers(
      { workItemId: workItem.id, userIds, reason: undefined },
      accessToken
    );
  };

  const handleRemoveUser = async (workItemUser: WorkItemUser) => {
    const accessToken = await getAccessTokenSilently();
    return await localRemoveWorkItemUser(
      workItem.id,
      workItemUser.type,
      workItemUser.user.id,
      accessToken
    );
  };

  return (
    <WorkItemUsers
      workItem={workItem}
      userTypes={["AGENT", "SUBMITTER"]}
      title={"Collaborators"}
      readonly={readonly}
      canAddUsers={canAddUsers}
      canRemoveUser={canRemoveUser}
      onAddUsers={handleAddUsers}
      onRemoveUser={handleRemoveUser}
    />
  );
};

const mapStateToProps = (state: Record<string, unknown>) => ({
  workItemTypes: getReferenceDataType(state, "WorkItemType"),
});

export default connect(mapStateToProps, {
  localSetWorkItemUsers: setWorkItemUsers,
  localRemoveWorkItemUser: removeWorkItemUser,
})(CollaboratorCard);
