import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { getReferenceDataDescription } from "../../../../reducers";
import ComponentScorecard from "../../../common/ComponentScorecard";
import FormCard from "../../../common/FormCard";
import MultilineText from "../../../common/MultilineText";
import EditWorkItemComponentForm, {
  EDIT_WORK_ITEM_COMPONENT_FORM_NAME,
} from "../EditWorkItemComponentForm";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
}));

const RiskControlVerificationDetailsCard = ({
  workItem,
  onChange,
  fieldLabels,
  customisations,
  getFrequencyDescription,
}) => {
  const classes = useStyles();
  const { riskControlVerificationDetails } = workItem;
  return (
    <>
      <FormCard
        className={classes.root}
        title={fieldLabels.labels.riskControlVerificationDetails}
        formTitle={`Edit ${fieldLabels.labels.riskControlVerificationDetails.toLowerCase()}`}
        readOnlyView={() => (
          <>
            <ComponentScorecard
              fieldLabels={fieldLabels}
              name="riskControlVerificationDetails.testPlan"
              customisations={customisations}
              entity={workItem}
              render={(value) => <MultilineText text={value} defaultText="-" />}
            />
            <ComponentScorecard
              fieldLabels={fieldLabels}
              name="riskControlVerificationDetails.verificationFrequency"
              customisations={customisations}
              entity={workItem}
              render={(value) => getFrequencyDescription(value)}
            />
          </>
        )}
        formComponent={EditWorkItemComponentForm}
        formName={EDIT_WORK_ITEM_COMPONENT_FORM_NAME}
        onSave={onChange}
        initialValues={{ riskControlVerificationDetails }}
        formProps={{
          maxWidth: "md",
          workItem,
          componentName: "RISK_CONTROL_VERIFICATION",
        }}
      />
    </>
  );
};

RiskControlVerificationDetailsCard.propTypes = {
  workItem: PropTypes.object.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  customisations: PropTypes.array,
  // redux
  getFrequencyDescription: PropTypes.func.isRequired,
  onChange: PropTypes.func,
};

RiskControlVerificationDetailsCard.defaultProps = {
  customisations: [],
  onChange: null,
};

const mapStateToProps = (state) => ({
  getFrequencyDescription: (frequency) =>
    getReferenceDataDescription(state, "FrequencyType", frequency, "-"),
});

export default connect(mapStateToProps)(RiskControlVerificationDetailsCard);
