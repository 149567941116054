import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CheckCircle from "@material-ui/icons/CheckCircle";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import UrlCopyField from "./UrlCopyField";

const useStyles = makeStyles((theme) => ({
  successPanel: {
    width: "600px",
    textAlign: "center",
  },
  refNumber: {
    marginBottom: theme.spacing(4),
  },
  button: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    "&:first-child": {
      marginLeft: 0,
    },
  },
  successIcon: {
    fontSize: "80px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
}));

const CreateSnippetSuccess = ({
  originLink,
  originLinkLabel,
  itemLink,
  servingUrl,
}) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center" direction="row" justifyContent="center">
      <Paper style={{ padding: 24 }} elevation={0}>
        <div className={classes.successPanel}>
          <Typography variant="h4">The snippet has been created.</Typography>
          <CheckCircle className={classes.successIcon} />
          <UrlCopyField url={servingUrl} />
          <div>
            <Button
              className={classes.button}
              component={Link}
              to={itemLink}
              variant="contained"
              color="primary"
              size="large"
            >
              View Snippet
            </Button>
            <Button
              className={classes.button}
              component={Link}
              to={originLink}
              variant="contained"
              color="default"
              size="large"
            >
              {originLinkLabel}
            </Button>
          </div>
        </div>
      </Paper>
    </Grid>
  );
};

CreateSnippetSuccess.propTypes = {
  originLink: PropTypes.string.isRequired,
  originLinkLabel: PropTypes.string.isRequired,
  itemLink: PropTypes.string.isRequired,
  servingUrl: PropTypes.string.isRequired,
};

export default CreateSnippetSuccess;
