import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { applyMiddleware, createStore, PreloadedState, Store } from "redux";
import reduxCatch from "redux-catch";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import createRootReducer from "./reducers";
import { logReduxError } from "./util/errors";
import createPreserveQueryHistory from "./util/createPreserveQueryHistory";

export const history = createPreserveQueryHistory(createBrowserHistory, [
  "t",
])();

const configureStore = (
  preloadedState: PreloadedState<Record<string, unknown>>
): Store => {
  return createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    composeWithDevTools(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        thunk,
        reduxCatch(logReduxError)
      )
    )
  );
};

export default configureStore;
