import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import tinycolor from "tinycolor2";

const styles = (theme) => ({
  content: {
    position: "relative",
    maxHeight: "10rem" /* exactly 8 lines */,
    overflow: "hidden",
  },
  contentNewUI: {
    maxHeight: "10rem !important",
  },
  contentTruncated: {
    position: "relative",
    minHeight: "2.5rem" /* exactly 2 lines */,
    maxHeight: "10rem" /* exactly 8 lines */,
    overflow: "hidden",
    "&:after": {
      content: '""',
      textAlign: "right",
      position: "absolute",
      bottom: 0,
      right: 0,
      width: "100%",
      height: "1.25rem" /* exactly 1 lines */,
      background:
        "linear-gradient(to right, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1) 100%)",
    },
  },
  contentTruncatedNewUI: {
    maxHeight: "10.5rem !important",
  },
  internal: {
    "&:after": {
      background: `linear-gradient(
        to right,
        ${tinycolor
          .mix("white", theme.palette.swatch.accent1, 10)
          .setAlpha(0.5)},
        ${tinycolor
          .mix("white", theme.palette.swatch.accent1, 10)
          .setAlpha(1)} 100%
      )`,
    },
  },
  button: {
    marginTop: theme.spacing(1),
  },
});

const bodyClassNames = (classes, overflowing, expanded, isInternal, isNewUI) =>
  classNames({
    [classes.contentTruncated]: overflowing && !expanded,
    [classes.contentTruncated]: overflowing && !expanded && isNewUI,
    [classes.content]: !expanded,
    [classes.contentNewUI]: !expanded && isNewUI,
    [classes.internal]: overflowing && isInternal,
  });

const CollapseExpand = ({ classes, children, isInternal, isNewUI }) => {
  const [overflowing, setOverflowing] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (elementRef.current) {
        const overflowingNow =
          elementRef.current.offsetHeight < elementRef.current.scrollHeight;

        if (overflowing !== overflowingNow) {
          setOverflowing(overflowingNow);
        }
      }
    }, 0);
  }, [children]);

  return (
    <>
      <div
        className={bodyClassNames(
          classes,
          overflowing,
          expanded,
          isInternal,
          isNewUI
        )}
        ref={elementRef}
      >
        {children}
      </div>
      {(overflowing || expanded) && (
        <Fade in timeout={1000}>
          <Button
            color="primary"
            size="small"
            onClick={() => setExpanded(!expanded)}
            className={classes.button}
            data-cy="expand"
          >
            {expanded ? "Show less..." : "Show more..."}
          </Button>
        </Fade>
      )}
    </>
  );
};

CollapseExpand.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  isInternal: PropTypes.bool,
  isNewUI: PropTypes.bool,
};

CollapseExpand.defaultProps = {
  isInternal: false,
  isNewUI: false,
};

export default withStyles(styles)(CollapseExpand);
