import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { Field } from "redux-form";
import { required } from "redux-form-validators";
import HelpPopover from "../../common/HelpPopover";
import WrappedTextField from "../wrapper/WrappedTextField";

const useStyles = makeStyles((theme) => ({
  helpPopover: {
    padding: theme.spacing(2),
    maxWidth: theme.breakpoints.values.md,
  },
}));

const ControlDescriptionField = ({ fieldLabels }) => {
  const classes = useStyles();
  return (
    <Field
      name="description"
      component={WrappedTextField}
      label={fieldLabels.labels.description}
      multiline
      validate={required({
        msg: `${fieldLabels.labels.description} is required`,
      })}
      rowsMax={8}
      rows={4}
      fullWidth
      required
      helperText={
        <>
          <span>Get help with </span>
          <HelpPopover linkText={fieldLabels.labels.description.toLowerCase()}>
            <div className={classes.helpPopover}>
              <Typography variant="h5" gutterBottom>
                {fieldLabels.labels.description}
              </Typography>
              <Typography variant="body1" gutterBottom>
                This is a detailed statement what the control is and how it
                works.
              </Typography>
              <Typography variant="body1" component="div">
                Things to consider:
                <ul>
                  <li>How and how often the control operates?</li>
                  <li>How does the control mitigate its risks associated?</li>
                  <li>
                    Will the control effectively address sources or consequences
                    of risks associated?
                  </li>
                </ul>
              </Typography>
            </div>
          </HelpPopover>
        </>
      }
    />
  );
};

ControlDescriptionField.propTypes = {
  fieldLabels: PropTypes.object.isRequired,
};

export default ControlDescriptionField;
