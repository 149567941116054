export const calculateRiskRating = (likelihood, consequence) => {
  const matrix = [
    ["LOW", "LOW", "LOW", "LOW", "MEDIUM"],
    ["LOW", "LOW", "MEDIUM", "MEDIUM", "HIGH"],
    ["LOW", "MEDIUM", "HIGH", "HIGH", "HIGH"],
    ["MEDIUM", "MEDIUM", "HIGH", "EXTREME", "EXTREME"],
    ["MEDIUM", "HIGH", "EXTREME", "EXTREME", "EXTREME"],
  ];

  if (
    likelihood === null ||
    likelihood === undefined ||
    consequence === null ||
    consequence === undefined
  ) {
    return null;
  }

  return matrix[consequence][likelihood];
};
