import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import moment from "moment/moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getWorkItemLink } from "../../routes/routeUtils";
import dates from "../../util/dates";
import { getFirstWorkItemUserByType } from "../../util/workItemUserUtils";
import Avatar from "../common/Avatar.tsx";
import DueAgo from "../common/DueAgo";
import { getStatusName } from "./components/overview/WorkItemStatus";
import OutcomeChip from "./OutcomeChip";
import { moreVertIcon } from "../../util/icons";

const useStyles = makeStyles((theme) => ({
  listItemRoot: {},
  icon: {
    marginRight: theme.spacing(1),
  },
  owner: {
    display: "flex",
  },
  ownerName: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    marginRight: theme.spacing(1) / 2,
  },
  dueDate: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  dueDateWarning: {
    color: theme.palette.error.main,
  },
}));

function dueDateWarning(child) {
  return (
    child.status !== "CLOSED" &&
    !child.template &&
    moment(child.dueDate)
      .subtract(2, "day")
      .isSameOrBefore(moment().startOf("day"))
  );
}

function dueDateClassNames(classes, workItem) {
  return classNames({
    [classes.dueDate]: true,
    [classes.dueDateWarning]: dueDateWarning(workItem),
  });
}

const WorkItemListItem = ({ types, workItem, linkTo, children, divider }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuId] = useState(`WorkItemListItem-${workItem.id}`);

  const onOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (clickHandler) => () => {
    setAnchorEl(null);
    clickHandler();
  };

  const user = getFirstWorkItemUserByType(workItem.users, "OWNER")?.user;
  const { template } = workItem;
  const displayStatus = template
    ? ""
    : getStatusName(workItem, types.values[workItem.type].props.definition);
  const showOwner = true;
  const completed = workItem.status === "CLOSED";

  let relativeDate = null;
  if (workItem.template && workItem.hasParent) {
    if (workItem.parentDueDateOffset === 0) {
      relativeDate = "Same day";
    } else if (workItem.parentDueDateOffset > 1) {
      relativeDate = `${workItem.parentDueDateOffset} days prior`;
    } else if (workItem.parentDueDateOffset === 1) {
      relativeDate = `${workItem.parentDueDateOffset} day prior`;
    } else if (workItem.parentDueDateOffset === -1) {
      relativeDate = `${workItem.parentDueDateOffset * -1} day after`;
    } else {
      relativeDate = `${workItem.parentDueDateOffset * -1} days after`;
    }
  }

  const MoreIcon = moreVertIcon();

  return (
    <>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onCloseMenu}
      >
        {children &&
          React.Children.map(children, (child) =>
            React.cloneElement(child, {
              onClick: handleMenuItemClick(child.props.onClick),
            })
          )}
      </Menu>
      <ListItem
        key={workItem.id}
        classes={{
          root: classes.listItemRoot,
        }}
        component={Link}
        to={typeof linkTo === "string" ? linkTo : linkTo(workItem)}
        button
        divider={divider}
        draggable={false}
      >
        <ListItemText>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="subtitle1" data-cy={workItem.title}>
                {workItem.title}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Grid
                container
                spacing={1}
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item>
                  <Typography
                    variant="body1"
                    align="right"
                    data-cy={displayStatus}
                    component="div"
                  >
                    {displayStatus}
                  </Typography>
                </Grid>
                <Grid item>
                  <OutcomeChip outcome={workItem.outcome} defaultValue="" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} className={classes.owner}>
              {showOwner && (
                <>
                  <Avatar
                    name={user ? user.name : "?"}
                    email={user ? user.email : "?"}
                    size={24}
                    round
                  />
                  <Typography
                    variant="body2"
                    className={classes.ownerName}
                    data-cy={user ? user.name : "No Owner"}
                  >
                    {user ? user.name : "No Owner"}
                  </Typography>
                </>
              )}
            </Grid>
            {!completed && (
              <Grid
                item
                xs={6}
                className={dueDateClassNames(classes, workItem)}
              >
                <Icon className={classes.icon} data-cy="alarm">
                  alarm
                </Icon>
                {relativeDate || (
                  <DueAgo
                    value={dates.parseDate(workItem.dueDate)}
                    stayFull={template}
                  />
                )}
              </Grid>
            )}
            {completed && (
              <Grid item xs={6} className={classes.dueDate}>
                <span data-cy="wasDue">Was due:&nbsp;</span>
                <DueAgo value={dates.parseDate(workItem.dueDate)} stayFull />
              </Grid>
            )}
          </Grid>
        </ListItemText>
        <ListItemSecondaryAction>
          {children && (
            <IconButton
              aria-owns={anchorEl ? { menuId } : null}
              aria-haspopup="true"
              onClick={onOpenMenu}
              size="small"
            >
              <MoreIcon />
            </IconButton>
          )}
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
};

WorkItemListItem.propTypes = {
  workItem: PropTypes.object.isRequired,
  types: PropTypes.object.isRequired,
  linkTo: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  children: PropTypes.node,
  divider: PropTypes.bool,
};

WorkItemListItem.defaultProps = {
  linkTo: (workItem) => getWorkItemLink(workItem),
  children: undefined,
  divider: true,
};

export default WorkItemListItem;
