import { useAuth0 } from "@auth0/auth0-react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { loadWorkItemParent } from "../../actions/workItems";
import {
  getReferenceDataType,
  getWorkItemParent,
  isLoading,
} from "../../reducers";
import { getWorkItemLink, workItemSchedulePath } from "../../routes/routeUtils";
import dates from "../../util/dates";
import DueAgo from "../common/DueAgo";
import Scorecard from "../common/Scorecard";
import { getStatusName } from "./components/overview/WorkItemStatus";
import { includeTenantParam } from "@certane/arcadia-web-components";

const styles = () => ({
  root: {},
});

const ParentCard = ({
  parent,
  classes,
  className,
  workItem,
  fieldLabels,
  loading,
  types,
  collaboratorTypes,
  getFurtherDetails,
  loadParent,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  if (!workItem.parent) {
    return null;
  }

  useEffect(() => {
    getAccessTokenSilently().then((accessToken) =>
      loadParent(workItem.id, accessToken)
    );
  }, [workItem]);

  const getLinkTo = () =>
    workItem.template
      ? workItemSchedulePath(workItem.workItemSchedule.id)
      : getWorkItemLink(workItem.parent);

  const mergedClassName = classNames(classes.root, className);

  const typeDef = parent ? types.values[parent.type] : null;
  const definition = typeDef && typeDef.props.definition;
  const typeName = definition ? definition.name : "";

  return (
    <Card className={mergedClassName} elevation={0}>
      <CardHeader
        title={`${typeName} summary`}
        action={
          <Tooltip title={`Open ${typeName}`} disableFocusListener>
            <IconButton
              onClick={() =>
                window.open(includeTenantParam(getLinkTo()), "_blank")
              }
            >
              <Icon>open_in_browser</Icon>
            </IconButton>
          </Tooltip>
        }
      />
      <CardContent>
        {parent && (
          <>
            <Scorecard label={fieldLabels.labels.title}>
              {parent ? parent.title : "-"}
            </Scorecard>
            <Scorecard label={fieldLabels.labels.dueDate}>
              {parent ? (
                <DueAgo value={dates.parseDate(parent.dueDate)} expandable />
              ) : (
                "-"
              )}
            </Scorecard>
            <Scorecard label={fieldLabels.labels.users}>
              <ul>
                {parent &&
                  parent.users.map((workItemUser) => (
                    <li
                      key={`${workItemUser.user.id}-${workItemUser.type}`}
                      data-cy={
                        collaboratorTypes.values[workItemUser.type].description
                      }
                    >
                      {workItemUser.user.name} (
                      {collaboratorTypes.values[workItemUser.type].description})
                    </li>
                  ))}
              </ul>
            </Scorecard>
            <Scorecard label={fieldLabels.labels.status}>
              {parent ? getStatusName(parent, definition) : "-"}
            </Scorecard>
            {getFurtherDetails &&
              parent &&
              getFurtherDetails(parent, fieldLabels)}
          </>
        )}
        {!parent && !loading && <em>No access to primary task</em>}
      </CardContent>
    </Card>
  );
};

ParentCard.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  workItem: PropTypes.object.isRequired,
  loadParent: PropTypes.func.isRequired,
  types: PropTypes.object.isRequired,
  collaboratorTypes: PropTypes.object.isRequired,
  className: PropTypes.string,
  parent: PropTypes.object,
  fieldLabels: PropTypes.object.isRequired,
  getFurtherDetails: PropTypes.func,
};

ParentCard.defaultProps = {
  className: undefined,
  parent: null,
  getFurtherDetails: null,
};

const mapStateToProps = (state) => ({
  loading: isLoading(state),
  types: getReferenceDataType(state, "WorkItemType"),
  collaboratorTypes: getReferenceDataType(state, "CollaboratorType"),
  parent: getWorkItemParent(state),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    loadParent: loadWorkItemParent,
  })
)(ParentCard);
