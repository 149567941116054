import { useAuth0 } from "@auth0/auth0-react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { searchUsers } from "../../actions/users";
import {
  getLoggedInUser,
  getReferenceDataDescription,
  getUserLastEdit,
  getUserPagination,
  getUsers,
  isLoading,
} from "../../reducers";
import GridListing from "../common/GridListing";
import Pagination from "../common/Pagination";
import SimpleChips from "../common/SimpleChips";

const styles = () => ({});

const UserListing = ({
  onSelect,
  selected,
  pagination,
  lastEdit,
  users,
  sortBy,
  filter,
  loading,
  localSearchUsers,
  updateSort,
  clearFilter,
  getRoleDescription,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const getOrderBy = () => {
    const prefix = sortBy.direction === "desc" ? "-" : "";
    return `${prefix}${sortBy.field}`;
  };

  const getSearchParameters = () => ({
    textSearch: filter.textSearch,
    orderByField: getOrderBy(),
    limit: pagination.pageSize,
    offset: pagination.offset,
    hideDisabled: false,
  });

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      const searchParameters = {
        ...getSearchParameters(),
        offset: 0,
      };
      localSearchUsers(searchParameters, accessToken);
    })();
  }, [getAccessTokenSilently, filter, sortBy, lastEdit]);

  const handlePrevious = async () => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = {
      ...getSearchParameters(),
      offset: pagination.previousOffset,
    };
    localSearchUsers(searchParameters, accessToken);
  };

  const handleNext = async () => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = {
      ...getSearchParameters(),
      offset: pagination.nextOffset,
    };
    localSearchUsers(searchParameters, accessToken);
  };

  return (
    <>
      <GridListing
        sortedData={users}
        loading={loading}
        sortBy={sortBy}
        dense={false}
        isHighlight1={(user) => selected && selected.id === user.id}
        updateSort={(field, direction) =>
          updateSort({
            field,
            direction,
          })
        }
        clearFilter={clearFilter}
        onClick={(user) => onSelect(user)}
        columns={[
          {
            label: "Name / Email",
            name: "name",
            size: 4,
            sortable: true,
            render: (user) => (
              <>
                <Typography variant="subtitle1">{user.name}</Typography>
                {user.email}
              </>
            ),
          },
          {
            label: "Roles",
            name: "roles",
            size: 4,
            sortable: false,
            render: (user) => (
              <SimpleChips
                values={user.roles.map((role) => getRoleDescription(role))}
                blankLabel="-"
              />
            ),
          },
          {
            label: "Organisations",
            name: "organisations",
            size: 2,
            sortable: false,
            render: (user) => {
              return (
                <>
                  {user.organisations && (
                    <SimpleChips
                      values={user.organisations.map((org) => org.name)}
                      blankLabel="-"
                    />
                  )}
                </>
              );
            },
          },
          {
            label: "Status",
            name: "status",
            size: 2,
            sortable: false,
            render: (user) => (user.enabled ? "Enabled" : "Disabled"),
          },
        ]}
      />
      <Pagination
        pagination={pagination}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
      />
    </>
  );
};

UserListing.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.object,
  filter: PropTypes.object.isRequired,
  sortBy: PropTypes.object.isRequired,
  updateSort: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired,

  // redux
  users: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  lastEdit: PropTypes.number.isRequired,
  localSearchUsers: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  getRoleDescription: PropTypes.func.isRequired,
};

UserListing.defaultProps = {
  selected: null,
};

const mapStateToProps = (state) => ({
  loading: isLoading(state),
  loggedInUser: getLoggedInUser(state),
  users: getUsers(state),
  pagination: getUserPagination(state),
  lastEdit: getUserLastEdit(state),
  getRoleDescription: (role) =>
    getReferenceDataDescription(state, "Role", role),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    localSearchUsers: searchUsers,
  })
)(UserListing);
