import { intersection } from "lodash";
import {
  toCamelCaseFromUpperUnderscore,
  toUpperUnderscoreCase,
} from "./strings.ts";

export const createWorkItemSearchParameters = (
  filter,
  statuses,
  types,
  loggedInUser,
  pagination,
  orderByField
) => {
  const stateMappings = {
    OPEN: statuses.ids.filter((id) => !statuses.values[id].props.endState),
    CLOSED: statuses.ids.filter((id) => statuses.values[id].props.endState),
  };

  const filterTypes = filter.types ? [...filter.types] : [];
  if (filterTypes.length === 0) {
    filterTypes.push(
      ...types.ids.filter(
        (id) =>
          loggedInUser &&
          intersection(
            types.values[id].props.definition.visibleTo,
            loggedInUser.roles
          ).length > 0
      )
    );
  }

  return filter.namedQuery
    ? {
        customSearch: filter.namedQuery,
        limit: pagination.pageSize,
        offset: pagination.offset,
        orderByField,
      }
    : {
        textSearch: filter.textSearch,
        types: filterTypes,
        sargonServiceProviderIds: filter.sargonServiceProviderIds,
        serviceProviderIds: filter.serviceProviderIds,
        productIds: filter.productIds,
        riskRegisters: filter.riskRegisters,
        states: stateMappings[filter.status],
        outcomes: filter.outcomes,
        ownerIds: filter.ownerIds,
        ownership: filter.ownership
          ? toUpperUnderscoreCase(filter.ownership)
          : null,
        dueDate: filter.dueDate ? toUpperUnderscoreCase(filter.dueDate) : null,
        onlyPendingQuestions: filter.onlyPendingQuestions,
        limit: pagination.pageSize,
        offset: pagination.offset,
        orderByField,
      };
};

export const createWorkItemScheduleSearchParameters = (
  filter,
  pagination,
  orderByField
) => ({
  textSearch: filter.textSearch,
  ownership: filter.ownership ? toUpperUnderscoreCase(filter.ownership) : null,
  dueDate: filter.dueDate ? toUpperUnderscoreCase(filter.dueDate) : null,
  status: filter.status ? filter.status : null,
  categories: filter.categories,
  organisationIds: filter.organisationIds,
  productIds: filter.productIds,
  limit: pagination.pageSize,
  offset: pagination.offset,
  orderByField,
});

export const createWorkItemFilterParameters = (searchParameters, statuses) => {
  const filter = {
    namedQuery: searchParameters.customSearch,
    textSearch: searchParameters.textSearch,
    types: searchParameters.types,
    subTypes: searchParameters.subTypes,
    sargonServiceProviderIds: searchParameters.sargonServiceProviderIds,
    serviceProviderIds: searchParameters.serviceProviderIds,
    productIds: searchParameters.productIds,
    outcomes: searchParameters.outcomes,
    riskRegisters: searchParameters.riskRegisters,
    status: searchParameters.states
      .map((state) =>
        statuses.values[state].props.endState ? "CLOSED" : "OPEN"
      )
      .find(() => true),
    ownerIds: searchParameters.ownerIds,
    ownership: searchParameters.ownership
      ? toCamelCaseFromUpperUnderscore(searchParameters.ownership)
      : null,
    dueDate: searchParameters.dueDate
      ? toCamelCaseFromUpperUnderscore(searchParameters.dueDate)
      : null,
    onlyPendingQuestions: searchParameters.onlyPendingQuestions,
  };

  return filter;
};
