import { normalize } from "normalizr";
import schemas from "../schemas";
import controlsApi from "../services/api/controls";
import { asyncAction } from "./actions";

export const CONTROL_LOAD = "CONTROL-LOAD";
export const CONTROL_REMOVE = "CONTROL-REMOVE";
export const CONTROL_LIST = "CONTROL-LIST";
export const CONTROL_CREATE = "CONTROL-CREATE";
export const CONTROL_CLEAR_ACTIVE = "CONTROL-CLEAR-ACTIVE";
export const CONTROL_PATCH = "CONTROL-PATCH";
export const CONTROL_LOAD_METRIC = "CONTROL-LOAD-METRIC";

export const listControls = (filter, accessToken) => {
  const abortController = window.AbortController ? new AbortController() : null;
  return asyncAction(
    CONTROL_LIST,
    controlsApi.search(filter, abortController, accessToken),
    {
      responseTransformer: (response) => ({
        pagination: {
          offset: response.offset,
          previousOffset: response.previousOffset,
          nextOffset: response.nextOffset,
          pageSize: response.pageSize,
          resultCount: response.resultCount,
        },
        ...normalize(response.results, schemas.arrayOfControls),
      }),
      actionParams: {
        abortController,
        nonBlocking: true,
      },
    }
  );
};

export const createControl = (control, accessToken) =>
  asyncAction(CONTROL_CREATE, controlsApi.create(control, accessToken), {
    responseTransformer: (response) => normalize(response, schemas.control),
  });

export const loadControl = (controlId, accessToken) =>
  asyncAction(CONTROL_LOAD, controlsApi.load(controlId, accessToken), {
    responseTransformer: (response) => normalize(response, schemas.control),
  });

export function clearActiveControl() {
  return {
    type: CONTROL_CLEAR_ACTIVE,
  };
}

export const deleteControl = (controlId, accessToken) =>
  asyncAction(CONTROL_REMOVE, controlsApi.remove(controlId, accessToken), {
    responseTransformer: () => ({ id: controlId }),
  });

export const patchControl = (controlId, changes, description, accessToken) =>
  asyncAction(
    CONTROL_PATCH,
    controlsApi.patch(controlId, changes, description, accessToken),
    {
      responseTransformer: (response) => normalize(response, schemas.control),
    }
  );

export const loadControlMetric = (metric, filter, accessToken) =>
  asyncAction(CONTROL_LOAD_METRIC, controlsApi.count(filter, accessToken), {
    responseTransformer: (results) => ({
      count: results.count,
      metric,
    }),
  });
