import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { SubmissionError } from "redux-form";
import { useAuth0 } from "@auth0/auth0-react";
import { createSla, deleteSla, listSlas, patchSla } from "../../actions/slas";
import {
  getLabels,
  getReferenceDataType,
  getSlas,
  isLoading,
} from "../../reducers";
import dates from "../../util/dates";
import AlertDialog from "../common/AlertDialog";
import GridListing from "../common/GridListing";
import InlineInput from "../common/InlineInput";
import TimeAgo from "../common/TimeAgo";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  primaryText: {
    display: "block",
    fontSize: "0.875rem",
    fontWeight: "600",
  },
  nowrap: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  textWithIcon: {
    display: "flex",
  },
  iconSm: {
    fontSize: 16,
  },
});

function displayEnum(defs, value) {
  return value ? defs.values[value].description : "";
}

const SlaListing = ({
  classes,
  loading,
  workItemTypes,
  workItemCategories,
  dayCalculationTypes,
  slas,
  listSlas,
  patchSla,
  deleteSla,
  fieldLabels,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const [state, setState] = useState({
    removeDialogOpen: false,
    idToRemove: null,
  });

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      listSlas(accessToken);
    })();
  }, []);

  const onSubmitRemoveDialog = async () => {
    const { idToRemove } = state;
    const accessToken = await getAccessTokenSilently();
    return deleteSla(idToRemove, accessToken)
      .then(() =>
        setState({
          removeDialogOpen: false,
          idToRemove: null,
        })
      )
      .catch((error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  const sortSlas = () => {
    return _.orderBy(slas, (sla) => {
      const type = sla.workItemType
        ? displayEnum(workItemTypes, sla.workItemType)
        : "";
      const category = sla.workItemCategory
        ? displayEnum(workItemCategories, sla.workItemCategory)
        : "";
      return `${type}${category}`;
    });
  };

  const { removeDialogOpen } = state;

  const patch = async (sla, changes) => {
    const accessToken = await getAccessTokenSilently();
    return patchSla(sla.id, changes, "Updated", accessToken);
  };

  return (
    <div className={classes.root}>
      <AlertDialog
        title="Remove?"
        body="Are you sure you want to remove this service level agreement?"
        submitButtonText="Remove"
        open={removeDialogOpen}
        onCancel={() =>
          setState({
            removeDialogOpen: false,
            idToRemove: null,
          })
        }
        onSubmit={(values) => onSubmitRemoveDialog(values)}
      />
      <GridListing
        sortedData={sortSlas()}
        loading={loading && slas.length === 0}
        sortBy={{}}
        updateSort={() => {
          // do nothing.
        }}
        action={(sla) => (
          <IconButton
            aria-label="Delete"
            onClick={() =>
              setState({
                removeDialogOpen: true,
                idToRemove: sla.id,
              })
            }
            disabled={!sla.deletable}
          >
            <Icon>delete</Icon>
          </IconButton>
        )}
        columns={[
          {
            label: fieldLabels.labels.workItemType,
            name: "workItemType",
            size: 2,
            sortable: false,
            render: (sla) => (
              <span className={classes.primaryText}>
                {sla.workItemType
                  ? displayEnum(workItemTypes, sla.workItemType)
                  : "*"}
              </span>
            ),
          },
          {
            label: fieldLabels.labels.workItemCategory,
            name: "workItemCategory",
            size: 2,
            sortable: false,
            render: (sla) => (
              <span className={classes.primaryText}>
                {sla.workItemCategory
                  ? displayEnum(workItemCategories, sla.workItemCategory)
                  : "*"}
              </span>
            ),
          },
          {
            label: fieldLabels.labels.maxDaysToProcess,
            name: "maxDaysToProcess",
            size: 2,
            sortable: false,
            render: (sla) => (
              <InlineInput
                value={sla.maxDaysToProcess}
                onChange={(maxDaysToProcess) =>
                  patch(sla, { maxDaysToProcess })
                }
                endAdornment={
                  <InputAdornment position="end">days</InputAdornment>
                }
                inputProps={{
                  min: 1,
                }}
                validate={(value) =>
                  value && _.isNumber(Number(value)) && value > 0
                }
              />
            ),
          },
          {
            label: fieldLabels.labels.reminderLeadTime,
            name: "reminderLeadTime",
            size: 2,
            sortable: false,
            render: (sla) => (
              <InlineInput
                value={sla.reminderLeadTime}
                onChange={(reminderLeadTime) =>
                  patch(sla, { reminderLeadTime })
                }
                endAdornment={
                  <InputAdornment position="end">days</InputAdornment>
                }
                inputProps={{
                  min: 1,
                }}
                validate={(value) =>
                  value && _.isNumber(Number(value)) && value > 0
                }
              />
            ),
          },
          {
            label: fieldLabels.labels.customReferenceDateField,
            name: "customReferenceDateField",
            size: 3,
            sortable: false,
            render: (sla) => (
              <InlineInput
                value={sla.customReferenceDateField}
                onChange={(customReferenceDateField) =>
                  patch(sla, { customReferenceDateField })
                }
              />
            ),
          },
          {
            label: fieldLabels.labels.dayCalculationType,
            name: "dayCalculationType",
            size: 2,
            sortable: false,
            render: (sla) => (
              <span className={classes.primaryText}>
                {sla.dayCalculationType
                  ? displayEnum(dayCalculationTypes, sla.dayCalculationType)
                  : "-"}
              </span>
            ),
          },
          {
            label: fieldLabels.labels.created,
            name: "created",
            size: 2,
            sortable: false,
            render: (sla) => (
              <TimeAgo value={dates.parseTimestamp(sla.created)} expandable />
            ),
          },
          {
            label: fieldLabels.labels.updated,
            name: "updated",
            size: 2,
            sortable: false,
            render: (sla) => (
              <TimeAgo value={dates.parseTimestamp(sla.updated)} expandable />
            ),
          },
        ]}
      />
    </div>
  );
};

SlaListing.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  slas: PropTypes.array.isRequired,
  workItemTypes: PropTypes.object.isRequired,
  workItemCategories: PropTypes.object.isRequired,
  dayCalculationTypes: PropTypes.object.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  listSlas: PropTypes.func.isRequired,
  deleteSla: PropTypes.func.isRequired,
  patchSla: PropTypes.func.isRequired,
};

SlaListing.defaultProps = {};

const mapStateToProps = (state) => ({
  loading: isLoading(state),
  slas: getSlas(state),
  workItemTypes: getReferenceDataType(state, "WorkItemType"),
  workItemCategories: getReferenceDataType(state, "WorkItemCategory"),
  dayCalculationTypes: getReferenceDataType(state, "WorkItemSlaDayType"),
  fieldLabels: getLabels(state).WorkItemSlaSetting,
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    listSlas,
    deleteSla,
    createSla,
    patchSla,
  })
)(SlaListing);
