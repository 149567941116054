import queryString from "query-string";

// Used to ensure that the tenant header "t" is retained during route changes.
// NOTE that links which open in new tabs have to call includeTenantParam() to keep the tenant parameter in the link
// Lifted from https://stackoverflow.com/questions/43613140/how-to-preserve-query-parameters-in-react-router-v4

function preserveQueryParameters(history, preserve, location) {
  const currentQuery = queryString.parse(history.location.search);
  if (currentQuery) {
    const preservedQuery = {};
    for (let p of preserve) {
      const v = currentQuery[p];
      if (v) {
        preservedQuery[p] = v;
      }
    }
    if (location.search) {
      Object.assign(preservedQuery, queryString.parse(location.search));
    }
    location.search = queryString.stringify(preservedQuery);
  }
  return location;
}

function createLocationDescriptorObject(location, state) {
  return typeof location === "string"
    ? { pathname: location, state }
    : location;
}

function createPreserveQueryHistory(createHistory, queryParameters) {
  return (options) => {
    const history = createHistory(options);
    const oldPush = history.push,
      oldReplace = history.replace;
    history.push = (path, state) =>
      oldPush.apply(history, [
        preserveQueryParameters(
          history,
          queryParameters,
          createLocationDescriptorObject(path, state)
        ),
      ]);
    history.replace = (path, state) =>
      oldReplace.apply(history, [
        preserveQueryParameters(
          history,
          queryParameters,
          createLocationDescriptorObject(path, state)
        ),
      ]);
    return history;
  };
}

export default createPreserveQueryHistory;
