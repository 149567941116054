import classNames from "classnames";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedDate } from "react-intl";

class DueAgo extends Component {
  constructor(props) {
    super(props);

    this.state = { showFull: props.startFull };
  }

  toggleShowFull = (e) => {
    this.setState({ showFull: !this.state.showFull });
    e.stopPropagation();
  };

  render() {
    const { value, expandable, className, stayFull, displayAsFullAfter } =
      this.props;
    const { showFull } = this.state;
    const displayFull = showFull || stayFull;

    const refDate = moment().startOf("day");
    const valueDate = moment(value).startOf("day");
    const displayFullOverride = _.isNumber(displayAsFullAfter);

    const displayValue = displayFull ? (
      <FormattedDate value={value} />
    ) : (
      valueDate.calendar(refDate, {
        sameDay: "[Today]",
        nextDay: "[In 1 day]",
        nextWeek() {
          const daysLeft = this.diff(refDate, "days");
          return `[In ${daysLeft} ${daysLeft === 1 ? "day" : "days"}]`;
        },
        lastDay: "[Overdue (1 day)]",
        lastWeek() {
          const daysOverdue = refDate.diff(this, "days");
          return `[Overdue (${daysOverdue} ${
            daysOverdue === 1 ? "day" : "days"
          })]`;
        },
        sameElse() {
          if (this.isBefore(refDate)) {
            const daysOverdue = refDate.diff(this, "days");
            if (displayFullOverride && daysOverdue >= displayAsFullAfter) {
              return `[Overdue (${this.format("DD/MM/YYYY")})]`;
            }
            return `[Overdue (${daysOverdue} ${
              daysOverdue === 1 ? "day" : "days"
            })]`;
          }
          const daysLeft = this.diff(refDate, "days");
          if (displayFullOverride && daysLeft >= displayAsFullAfter) {
            return `[${this.format("DD/MM/YYYY")}]`;
          }
          return `[In ${daysLeft} days]`;
        },
      })
    );

    return (
      <span>
        {expandable && (
          <span
            role="button"
            onKeyPress={this.toggleShowFull}
            tabIndex={0}
            onClick={this.toggleShowFull}
            style={{ cursor: "pointer" }}
            className={classNames({
              [className]: !!className,
            })}
          >
            {displayValue}
          </span>
        )}
        {!expandable && (
          <span
            className={classNames({
              [className]: !!className,
            })}
          >
            {displayValue}
          </span>
        )}
      </span>
    );
  }
}

DueAgo.propTypes = {
  value: PropTypes.instanceOf(Date).isRequired,
  expandable: PropTypes.bool,
  startFull: PropTypes.bool,
  stayFull: PropTypes.bool,
  displayAsFullAfter: PropTypes.number,
  className: PropTypes.string,
};

DueAgo.defaultProps = {
  expandable: false,
  startFull: false,
  stayFull: false,
  className: undefined,
  displayAsFullAfter: null,
};

export default DueAgo;
