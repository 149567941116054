import { createTheme } from "@material-ui/core/styles";
import Color from "color";

// Certane colours
const colors = {
  teal: {
    1: "#186170",
    2: "#0C4551",
    3: "#07262D",
  },
  turquoise: {
    1: "#7FE5E9",
    2: "#05C0C7",
    3: "#05A3A9",
    4: "#00777B",
  },
  charcoal: {
    1: "#1D1F25",
  },
  grey: {
    1: "#eff0f3",
    2: "#c2c7d1",
    3: "#656a74",
  },
  orange: {
    1: "#fa613b",
    2: "#d24524",
    3: "#a32f1e",
  },
  green: {
    1: "#1db790",
    2: "#3eb982",
    3: "#51bb79",
    4: "#6abf6a",
    5: "#89c657",
    6: "#add140",
  },

  complimentary: {
    amber: "#FFAD28",
    orange: "#FD5E00",
    red: "#b71d31",
    purple: "#B532C3",
    blue: "#2A68B8",
  },

  swatch: {
    primary: "#2A323C",
    secondary: "#778088",
    grey1: "#92A1B3",
    grey2: "#ADB9C6",
    grey3: "#C9D0D9",
    grey4: "#E3E7EC",
    grey5: "#F1F3F5",
    grey6: "#F9FAFB",
    accent1: "#FFAD28",
    accent2: "#B71D31",
    accent3: "#9B37D9",
    accent4: "#2BA2E5",
    link: "#fa613b",
  },
};

export const statusColors = {
  // These have been deliberately CAP_CASED to match the internal status enum,
  // hence serving below as a utility hashmap between those values and their colors.
  NEW: colors.swatch.accent3,
  OPEN: colors.green[4],
  EXTERNAL_REVIEW: colors.swatch.accent1,
  PENDING_SUBMITTER: colors.swatch.accent1,
  PENDING_COUNTERPARTY_SIGNATURE: colors.swatch.accent1,
  ON_HOLD: colors.green[4],
  MONITORING: colors.green[4],
  CLOSED: colors.swatch.grey2,
  UNKNOWN: "white",
};

export default createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1600,
    },
  },

  palette: {
    type: "light",
    primary: {
      // light: colors.teal[1],
      main: colors.teal[1],
      dark: colors.teal[2],
      contrastText: "#fff",
    },
    secondary: {
      light: colors.turquoise[2],
      main: colors.turquoise[3],
      dark: colors.turquoise[4],
      contrastText: "#fff",
    },
    error: {
      main: colors.orange[3],
      contrastText: "#fff",
    },
    warning: {
      main: colors.orange[1],
      contrastText: "#fff",
    },
    // info: {},
    // success: {},
    // grey: {},
    text: {
      primary: colors.charcoal[1],
      secondary: colors.grey[3],
    },
    background: {
      default: "#eff0f3",
    },
    swatch: colors.swatch,
    statusColors,
  },

  typography: {
    h1: {
      fontFamily: "Poppins, sans-serif",
      fontWeight: 300,
      fontSize: 93,
    },
    h2: {
      fontFamily: "Poppins, sans-serif",
      fontWeight: 300,
      fontSize: 58,
    },
    h3: {
      fontFamily: "Poppins, sans-serif",
      fontWeight: 400,
      fontSize: 46,
      lineHeight: "56px",
    },
    h4: {
      fontFamily: "Poppins, sans-serif",
      fontWeight: 400,
      fontSize: 33,
      lineHeight: "40px",
    },
    h5: {
      fontFamily: "Poppins, sans-serif",
      fontWeight: 400,
      fontSize: 23,
      lineHeight: "32px",
    },
    h6: {
      fontFamily: "Poppins, sans-serif",
      fontWeight: 500,
      fontSize: 19,
      letterSpacing: 0.15,
    },
    subtitle1: {
      fontFamily: "Poppins, sans-serif",
      fontWeight: "normal",
      fontSize: 15,
      lineHeight: "24px",
    },
    subtitle2: {
      fontFamily: "Poppins, sans-serif",
      fontWeight: "bold",
      fontSize: 13,
      lineHeight: "24px",
    },
    button: {
      fontFamily: "Poppins, sans-serif",
      fontWeight: 500,
      fontSize: 15,
      letterSpacing: 1.25,
      lineHeight: "24px",
      textTransform: "uppercase",
    },
    body1: {
      fontFamily: "Work Sans, sans-serif",
      fontWeight: 400,
      fontSize: 16,
      letterSpacing: 0.5,
      lineHeight: "24px",
    },
    body2: {
      fontFamily: "Work Sans, sans-serif",
      fontWeight: 400,
      fontSize: 14,
      letterSpacing: 0.25,
      lineHeight: "20px",
    },
    caption: {
      fontFamily: "Work Sans, sans-serif",
      fontWeight: 400,
      fontSize: 13,
      letterSpacing: 0.4,
    },
    overline: {
      fontFamily: "Work Sans, sans-serif",
      fontWeight: 400,
      fontSize: 11,
      textTransform: "uppercase",
      letterSpacing: 1.5,
    },
  },

  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          // WebkitFontSmoothing: 'auto',
        },
        a: {
          color: colors.swatch.link,
        },
      },
    },
    MuiCardHeader: {
      title: {
        color: colors.teal[1],
        // match h6
        fontWeight: 500,
        fontSize: 19,
        letterSpacing: 0.15,
      },
    },
    MuiChip: {
      root: {
        background: "#eeeeee",
      },
    },
    MuiInputLabel: {
      root: {
        // color: "inherit",
      },
      shrink: {
        fontSize: 18,
      },
    },
    MuiTab: {
      root: {
        letterSpacing: 0.5,
      },
    },
  },

  labels: {
    category: Color("#515151").hex(),
  },

  due: {
    ok: colors.green[3],
    atRisk: colors.complimentary.amber,
    overdue: colors.complimentary.red,
  },

  priority: {
    high: colors.complimentary.red,
    medium: colors.complimentary.amber,
    low: colors.green[3],
    none: colors.grey[3],
  },

  scheduleStatus: {
    enabled: colors.green[3],
    disabled: colors.complimentary.red,
  },

  status: {
    new: statusColors.NEW,
    open: statusColors.OPEN,
    externalReview: statusColors.EXTERNAL_REVIEW,
    pending: statusColors.PENDING_SUBMITTER,
    onHold: statusColors.ON_HOLD,
    closed: statusColors.CLOSED,
  },

  documents: {
    normal: colors.grey[3],
    green: colors.green[3],
    orange: colors.complimentary.amber,
    purple: colors.complimentary.purple,
    blue: colors.complimentary.blue,
  },

  metrics: {
    amber: colors.complimentary.amber,
    green: colors.green[3],
    red: colors.complimentary.red,
    default: colors.grey[3],
  },

  content: {
    warning: colors.complimentary.amber,
    error: colors.complimentary.red,
    contrastText: "white",
  },
});
