import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import { required } from "redux-form-validators";
import BusinessIcon from "@material-ui/icons/Business";
import { scrollToFirstInvalidInput } from "../../../util/formHelpers";
import WrappedTextField from "../wrapper/WrappedTextField";
import WrappedChipListPicker from "../wrapper/WrappedChipListPicker";
import Avatar from "../../common/Avatar.tsx";
import organisationsApi from "../../../services/api/organisations";

const BusinessServiceAreasForm = () => {
  const { getAccessTokenSilently } = useAuth0();

  const asyncOrganisationOptionsFetch = async (
    pickerFilter,
    pagination,
    abortController
  ) => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = {
      ...pickerFilter,
      limit: pagination.pageSize,
      offset: pagination.offset,
      orderByField: "name",
      excludeGroupFilters: true,
    };
    return organisationsApi.search(
      searchParameters,
      abortController,
      accessToken
    );
  };

  return (
    <form autoComplete="off">
      <Field
        name="name"
        component={WrappedTextField}
        label="Name"
        validate={required({ msg: "Name is required" })}
        fullWidth
        required
      />
      <br />
      <br />
      <Field
        name="organisations"
        component={WrappedChipListPicker}
        datasource={asyncOrganisationOptionsFetch}
        label="Organisations"
        fullWidth
        clearable
        isMulti
        required
        validate={required({ msg: "Organisation is required" })}
        toOption={(org) => ({ label: org.name, id: org.id, org })}
        fromOption={(option) => option.org}
        addIcon={BusinessIcon}
        renderIcon={(org, size) => <Avatar name={org.name} size={size} round />}
      />
    </form>
  );
};

BusinessServiceAreasForm.defaultProps = {
  selectedType: null,
};

export const BUSINESS_SERVICE_AREAS_FORM_NAME = "businessServiceAreasForm";

const form = reduxForm({
  form: BUSINESS_SERVICE_AREAS_FORM_NAME,
  onSubmitFail: (errors) => scrollToFirstInvalidInput(errors),
});

export default compose(form)(BusinessServiceAreasForm);
