import _ from "lodash";
import logger from "./logger";

export const appliesByRoles = (loggedInUser, config) =>
  config &&
  loggedInUser &&
  (config.roles.length === 0 ||
    _.intersection(config.roles, loggedInUser.roles).length > 0);

export const appliesByTemplate = (workItem, config) =>
  config &&
  ((workItem.template && config.forTemplate) ||
    (!workItem.template && config.forNonTemplate));

export const appliesByParentType = (workItem, config) =>
  config &&
  (config.parentTypes.length === 0 ||
    (workItem.hasParent &&
      config.parentTypes.indexOf(workItem.parent.type) !== -1));

export const appliesByStatusCondition = (workItem, config) =>
  config &&
  (!config.statusCondition ||
    (config.statusCondition &&
      ((config.statusCondition.condition === "INCLUDES" &&
        config.statusCondition.statuses.some((s) => s === workItem.status)) ||
        (config.statusCondition.condition === "EXCLUDES" &&
          !config.statusCondition.statuses.some(
            (s) => s === workItem.status
          )))));

export const appliesByCategory = (workItem, config) =>
  config &&
  (config.categories.length === 0 ||
    (workItem.category && _.includes(config.categories, workItem.category)));

export const appliesByCondition = (inspectionObj, condition) => {
  if (!condition) {
    return true;
  }
  const { field, operation, value } = condition;
  const inspectionValue = getObjectValue(inspectionObj, field);
  // logger.debug(
  //   "field",
  //   field,
  //   "operation",
  //   operation,
  //   "value",
  //   value,
  //   "inspectionValue",
  //   inspectionValue
  // );
  switch (operation) {
    case "EQ":
      return inspectionValue === value;
    case "NOT_EQ":
      return inspectionValue !== value;
    case "IS_NULL":
      return !inspectionValue;
    case "IS_NOT_NULL":
      return !!inspectionValue;
    case "INCLUDES":
      return (
        _.isArray(inspectionValue) && inspectionValue.indexOf(value) !== -1
      );
    case "EXCLUDES":
      return (
        !_.isArray(inspectionValue) || inspectionValue.indexOf(value) === -1
      );
    default:
      logger.warn("Don't know condition operation", operation);
      break;
  }
  return false;
};

export const appliesByRegion = (workItem, config) =>
  config &&
  (config.regions.length === 0 ||
    (workItem.entityRelationship &&
      workItem.entityRelationship.regions &&
      _.intersection(config.regions, workItem.entityRelationship.regions)
        .length > 0));

const getUserParties = (workItem, loggedInUser) => {
  const userParties = [];

  if (loggedInUser && workItem) {
    workItem.users
      .filter((u) => u.user.id === loggedInUser.id)
      .map((u) => u.type)
      .forEach((type) => userParties.push(type));

    // implicit party types
    if (loggedInUser.externalUser) {
      userParties.push("SUBMITTER");
    }
    if (!loggedInUser.externalUser) {
      userParties.push("AGENT");
    }
  }
  return userParties;
};

export const getWorkItemParties = (workItem) =>
  workItem ? workItem.users.map((u) => u.type) : [];

export const appliesByParties = (workItem, loggedInUser, config) => {
  const userParties = getUserParties(workItem, loggedInUser);
  return (
    config &&
    (config.parties.length === 0 ||
      _.intersection(config.parties, userParties).length > 0)
  );
};

export const enabledFor = (workItem, loggedInUser, config) => {
  const userParties = getUserParties(workItem, loggedInUser);
  return (
    config &&
    config.enabledFor &&
    _.intersection(config.enabledFor.parties, userParties).length > 0
  );
};

export const enabledWhen = (workItem, config) => {
  const workItemParties = getWorkItemParties(workItem);
  return (
    config &&
    config.enabledWhen &&
    _.intersection(config.enabledWhen.parties, workItemParties).length > 0
  );
};

/**
 * If the work item has a parent type, some role fields actually come form the parent definition
 * instead of the child type definition.
 * @param types - all work item type definitions
 * @param type - work item type
 * @param parentType - [OPTIONAL] parent type
 * @returns WorkItemTypeDefinition (child with some fields from parent mixed in)
 */
export const getResolvedDefinition = (types, type, parentType) => {
  if (parentType) {
    const { definition: parentTypeDef } = types.values[parentType].props;
    const childTypeAssignment = parentTypeDef.childTypes.find(
      (ct) => ct.type === type
    );
    if (!childTypeAssignment) {
      throw new Error(
        `Cannot find child type assignment ${type} on parent type ${parentType}`
      );
    }
    return resolveChildTypeDefinition(childTypeAssignment, types);
  }
  return types.values[type].props.definition;
};

export const resolveChildTypeDefinition = (
  childTypeAssignment,
  workItemTypes
) => {
  const { type, overrides } = childTypeAssignment;

  return {
    ...workItemTypes.values[type].props.definition,
    ...overrides,
  };
};

/**
 * Supports getting a field from an object even with nested arrays in the path
 * which are denoted in the path as '[]'.
 * @param obj
 * @param path
 * @returns {undefined|*}
 */
const getObjectValue = (obj, path) => {
  if (path.indexOf("[") !== -1) {
    const arrayPath = path.substring(0, path.indexOf("["));
    const nextPath = path.substring(path.indexOf("]") + 1);
    const arrayValue = _.get(obj, arrayPath);

    if (nextPath.length === 0) {
      return arrayValue;
    }
    return arrayValue?.map((value) =>
      getObjectValue(value, nextPath.substring(1))
    );
  }
  return _.get(obj, path);
};

export const getActionsForWorkItem = (workItem, definition, loggedInUser) => {
  return definition.actions
    .filter((actionAssignment) => appliesByTemplate(workItem, actionAssignment))
    .filter((actionAssignment) =>
      appliesByRoles(loggedInUser, actionAssignment)
    )
    .filter(
      (actionAssignment) =>
        !actionAssignment.statusCondition ||
        appliesByStatusCondition(workItem, actionAssignment)
    )
    .filter((actionAssignment) =>
      appliesByParties(workItem, loggedInUser, actionAssignment)
    );
};

export const actionIsEnabled = (workItem, actionAssignment, loggedInUser) => {
  const enabledForUserParty =
    !actionAssignment.enabledFor ||
    enabledFor(workItem, loggedInUser, actionAssignment);
  const enabledForWorkItem =
    !actionAssignment.enabledWhen || enabledWhen(workItem, actionAssignment);

  const enabled = enabledForUserParty && enabledForWorkItem;
  let disabledMessage = "";

  if (
    !enabledForUserParty &&
    actionAssignment.enabledFor &&
    actionAssignment.enabledFor.disabledTooltip
  ) {
    disabledMessage += `${actionAssignment.enabledFor.disabledTooltip} `;
  }
  if (
    !enabledForWorkItem &&
    actionAssignment.enabledWhen &&
    actionAssignment.enabledWhen.disabledTooltip
  ) {
    disabledMessage += `${actionAssignment.enabledWhen.disabledTooltip} `;
  }

  return {
    enabled,
    disabledMessage,
  };
};
