import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import dates from "../../util/dates";
import { openInNewIcon } from "../../util/icons";
import GridListing from "../common/GridListing";
import TimeAgo from "../common/TimeAgo";
import { includeTenantParam } from "@certane/arcadia-web-components";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  content: {
    overflow: "auto",
  },
  controlTypeChip: {
    backgroundColor: theme.palette.grey[300],
    padding: `${theme.spacing(1) / 4}px ${theme.spacing(1)}px`,
    borderRadius: "16px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: "100%",
    whiteSpace: "nowrap",
    marginRight: theme.spacing(1) / 2,
  },
  nowrap: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  createNewLink: {
    cursor: "pointer",
    color: theme.palette.swatch.link,
  },
  inlineIcon: {
    verticalAlign: "middle",
  },
  registerLabel: {
    fontSize: "smaller",
  },
  listItem: {
    width: "100%",
    overflow: "hidden",
  },
  listIcon: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
}));

const ControlObligationLinksCard = ({ control, fieldLabels }) => {
  const classes = useStyles();

  const OpenInNewIcon = openInNewIcon();

  return (
    <Card className={classes.root} elevation={0}>
      <CardHeader title={fieldLabels.labels.obligationLinks} />
      <CardContent className={classes.content}>
        <GridListing
          data-cy="table"
          sortedData={_.orderBy(control.obligationLinks, "created")}
          dense={false}
          sortBy={{
            field: "created",
            direction: "asc",
          }}
          loading={false}
          updateSort={() => {
            // do nothing.
          }}
          action={(link) => (
            <IconButton
              onClick={() =>
                window.open(includeTenantParam(link.displayLink), "_blank")
              }
            >
              <OpenInNewIcon />
            </IconButton>
          )}
          noItemsText="There are no obligation links associated with this control"
          columns={[
            {
              label:
                fieldLabels.nestedTypes.obligationLinks.labels.regSourceTitle,
              name: "regSourceTitle",
              size: 4,
              sortable: false,
              render: (link) => <span>{link.regSourceTitle}</span>,
            },
            {
              label:
                fieldLabels.nestedTypes.obligationLinks.labels
                  .obligationReference,
              name: "obligationReference",
              size: 4,
              sortable: false,
              render: (link) => <span>{link.obligationReference}</span>,
            },
            {
              label: fieldLabels.nestedTypes.obligationLinks.labels.created,
              name: "created",
              size: 2,
              sortable: false,
              render: (link) => (
                <TimeAgo
                  value={dates.parseTimestamp(link.created)}
                  expandable
                />
              ),
            },
            {
              label: fieldLabels.nestedTypes.obligationLinks.labels.updated,
              name: "updated",
              size: 2,
              sortable: false,
              render: (link) => (
                <TimeAgo
                  value={dates.parseTimestamp(link.updated)}
                  expandable
                />
              ),
            },
          ]}
        />
      </CardContent>
    </Card>
  );
};

ControlObligationLinksCard.propTypes = {
  control: PropTypes.object.isRequired,
  fieldLabels: PropTypes.object.isRequired,
};

export default ControlObligationLinksCard;
