import { normalize } from "normalizr";
import schemas from "../schemas";
import websiteDifferencesApi from "../services/api/websiteDifferences";
import { asyncAction } from "./actions";

export const WEBSITE_DIFFERENCE_CLEAR_ACTIVE =
  "WEBSITE_DIFFERENCE_CLEAR_ACTIVE";
export const WEBSITE_DIFFERENCE_GET_BY_ID = "WEBSITE_DIFFERENCE_GET_BY_ID";
export const WEBSITE_DIFFERENCE_CREATE = "WEBSITE_DIFFERENCE_CREATE";
export const WEBSITE_DIFFERENCE_PATCH = "WEBSITE_DIFFERENCE_PATCH";
export const WEBSITE_DIFFERENCE_SEARCH = "WEBSITE_DIFFERENCE_SEARCH";

export function clearActiveWebsiteDifference() {
  return {
    type: WEBSITE_DIFFERENCE_CLEAR_ACTIVE,
  };
}

export const searchWebsiteDifferences = (searchParameters, accessToken) =>
  asyncAction(
    WEBSITE_DIFFERENCE_SEARCH,
    websiteDifferencesApi.search(searchParameters, accessToken),
    {
      responseTransformer: (response) => ({
        pagination: {
          offset: response.offset,
          previousOffset: response.previousOffset,
          nextOffset: response.nextOffset,
          pageSize: response.pageSize,
          resultCount: response.resultCount,
        },
        ...normalize(response.results, schemas.arrayOfWebsiteDifferences),
      }),
    }
  );

export const getWebsiteDifferenceById = (websiteDifferenceId, accessToken) =>
  asyncAction(
    WEBSITE_DIFFERENCE_GET_BY_ID,
    websiteDifferencesApi.getById(websiteDifferenceId, accessToken),
    {
      responseTransformer: (websiteDifference) =>
        normalize(websiteDifference, schemas.websiteDifference),
    }
  );

export const patchWebsiteDifference = (
  websiteDifferenceId,
  changes,
  description,
  accessToken
) =>
  asyncAction(
    WEBSITE_DIFFERENCE_PATCH,
    websiteDifferencesApi.patch(
      websiteDifferenceId,
      changes,
      description,
      accessToken
    ),
    {
      responseTransformer: (websiteDifference) =>
        normalize(websiteDifference, schemas.websiteDifference),
    }
  );
