import AddIcon from "@material-ui/icons/Add";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import BeenhereIcon from "@material-ui/icons/Beenhere";
import BusinessIcon from "@material-ui/icons/Business";
import CategoryIcon from "@material-ui/icons/Category";
import ChangeHistoryIcon from "@material-ui/icons/ChangeHistory";
import CheckIcon from "@material-ui/icons/Check";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import ClearIcon from "@material-ui/icons/Clear";
import CloseIcon from "@material-ui/icons/Close";
import CloudIcon from "@material-ui/icons/Cloud";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CodeIcon from "@material-ui/icons/Code";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import ControlCameraIcon from "@material-ui/icons/ControlCamera";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DeleteIcon from "@material-ui/icons/Delete";
import DescriptionIcon from "@material-ui/icons/Description";
import DoneIcon from "@material-ui/icons/Done";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import EditIcon from "@material-ui/icons/Edit";
import ErrorIcon from "@material-ui/icons/Error";
import EventIcon from "@material-ui/icons/Event";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteOptionIcon from "@material-ui/icons/FavoriteBorder";
import FeedbackIcon from "@material-ui/icons/Feedback";
import FilterListIcon from "@material-ui/icons/FilterList";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import GroupIcon from "@material-ui/icons/Group";
import HelpIcon from "@material-ui/icons/Help";
import InfoIcon from "@material-ui/icons/Info";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import LanguageIcon from "@material-ui/icons/Language";
import LinkIcon from "@material-ui/icons/Link";
import LinkOffIcon from "@material-ui/icons/LinkOff";
import ListAltIcon from "@material-ui/icons/ListAlt";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import LockIcon from "@material-ui/icons/Lock";
import MailIcon from "@material-ui/icons/Mail";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import MoneyIcon from "@material-ui/icons/Money";
import MoreIcon from "@material-ui/icons/More";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import NearMeIcon from "@material-ui/icons/NearMe";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import NoteIcon from "@material-ui/icons/Note";
import NotesIcon from "@material-ui/icons/Notes";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import PeopleIcon from "@material-ui/icons/People";
import PersonIcon from "@material-ui/icons/Person";
import PrintIcon from "@material-ui/icons/Print";
import RepeatIcon from "@material-ui/icons/Repeat";
import SaveIcon from "@material-ui/icons/Save";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import StarRateIcon from "@material-ui/icons/StarRate";
import StraightenIcon from "@material-ui/icons/Straighten";
import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight";
import SubdirectoryArrowLeftIcon from "@material-ui/icons/SubdirectoryArrowLeft";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import Timer from "@material-ui/icons/Timer";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import UpdateIcon from "@material-ui/icons/Update";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import WarningIcon from "@material-ui/icons/Warning";
import WebIcon from "@material-ui/icons/Web";
import WorkIcon from "@material-ui/icons/Work";

// Common
export const addIcon = () => AddIcon;
export const attachmentFile = () => AttachFileIcon;
export const breadcrumbArrowLeft = () => KeyboardArrowLeftIcon;
export const dropDownIcon = () => ArrowDropDownIcon;
export const editIcon = () => EditIcon;
export const helpIcon = () => HelpIcon;
export const clearIcon = () => ClearIcon;
export const checkIcon = () => CheckIcon;
export const checkBoxCheckedIcon = () => CheckBoxIcon;
export const checkBoxUncheckedIcon = () => CheckBoxOutlineBlankIcon;
export const closeIcon = () => CloseIcon;
export const collapseIcon = () => ExpandLessIcon;
export const copyToClipboardIcon = () => FileCopyIcon;
export const deleteIcon = () => DeleteIcon;
export const doneIcon = () => DoneIcon;
export const downloadIcon = () => CloudDownloadIcon;
export const expandIcon = () => ExpandMoreIcon;
export const favoriteIcon = () => FavoriteIcon;
export const favoriteOptionIcon = () => FavoriteOptionIcon;
export const infoIcon = () => InfoIcon;
export const moreIcon = () => MoreIcon;
export const moreVertIcon = () => MoreVertIcon;
export const newFeature = () => NewReleasesIcon;
export const privateIcon = () => LockIcon;
export const printIcon = () => PrintIcon;
export const uploadIcon = () => ImportExportIcon;
export const saveIcon = () => SaveIcon;
export const toggleOnIcon = () => ToggleOnIcon;
export const toggleOffIcon = () => ToggleOffIcon;
export const visibleIcon = () => VisibilityIcon;
export const viewsIcon = () => FilterListIcon;
export const invisibleIcon = () => VisibilityOffIcon;
export const warningIcon = () => WarningIcon;
export const openInNewIcon = () => OpenInNewIcon;

// Arcadia
export const associationsIcon = () => LinkIcon;
export const associationRemoveIcon = () => LinkOffIcon;
export const auditIcon = () => ListAltIcon;
export const businessServiceAreaIcon = () => NearMeIcon;
export const collaboratorsIcon = () => GroupIcon;
export const userGroupIcon = () => GroupIcon;
export const draftIcon = () => EditIcon;
export const endorsementsIcon = () => BeenhereIcon;
export const finalDocumentsIcon = () => InsertDriveFileIcon;
export const noteIcon = () => NoteIcon;
export const notesIcon = () => NotesIcon;
export const notificationsIcon = () => NotificationsActiveIcon;
export const organisationIcon = () => BusinessIcon;
export const overviewIcon = () => AssignmentIcon;
export const fundIcon = () => AccountBalanceIcon;
export const productIcon = () => MoneyIcon;
export const riskIcon = () => WarningIcon;
export const riskControlIcon = () => ControlCameraIcon;
export const riskDashboardIcon = () => DashboardIcon;
export const riskActionRequestUpdate = () => UpdateIcon;
export const slaIcon = () => Timer;
export const submittersIcon = () => PersonIcon;
export const subscriptionIcon = () => MailIcon;
export const recurringIcon = () => RepeatIcon;
export const tagIcon = () => LocalOfferIcon;
export const tenantIcon = () => GroupWorkIcon;
export const typeChildIcon = () => SubdirectoryArrowRightIcon;
export const typeParentIcon = () => SubdirectoryArrowLeftIcon;
export const userIcon = () => PeopleIcon;
export const workItemScheduleIcon = () => EventIcon;
export const workItemDashboardIcon = () => DashboardIcon;
export const workItemClientDashboardIcon = () => DashboardIcon;
export const workItemIcon = (type) => {
  switch (type) {
    case "CHANGE_APPROVAL":
      return ChangeHistoryIcon;
    case "CLAIMS":
      return HelpIcon;
    case "COMPLAINT":
      return FeedbackIcon;
    case "COMPLIANCE_TASK":
      return DoneIcon;
    case "CONTRACT_APPROVAL":
      return NoteIcon;
    case "DISCLOSURE_APPROVAL":
      return AssignmentTurnedInIcon;
    case "INCIDENT":
      return ErrorIcon;
    case "REQUEST_FOR_INFORMATION":
      return InfoIcon;
    case "RISK_ACTION":
      return AssignmentIcon;
    case "RISK_CONTROL_VERIFICATION":
      return DoneAllIcon;
    case "RISK_MEASURE_UPDATE":
      return StraightenIcon;
    case "RISK_REASSESSMENT":
      return AssessmentIcon;
    default:
      return WorkIcon;
  }
};

// Reporting
export const reportingIcon = () => ShowChartIcon;

// Metropolis
export const clientIcon = () => BusinessIcon;
export const categoryIcon = () => CategoryIcon;
export const getContentIcon = () => CloudIcon;
export const globalIcon = () => LanguageIcon;
export const mediaIcon = () => DescriptionIcon;
export const snippetIcon = () => CodeIcon;

// Reggae
export const regulatoryObligationIcon = () => MenuBookIcon;

// Sentinel
export const digitalAssetIcon = () => WebIcon;
export const mentionIcon = () => StarRateIcon;
export const websiteDifferenceIcon = () => CompareArrowsIcon;
