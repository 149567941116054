import { useAuth0 } from "@auth0/auth0-react";
import { Card } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  clearActiveAuditReports,
  createAuditReport,
} from "../../actions/auditReports";
import CreateAuditReportSuccess from "../../components/audit/CreateAuditReportSuccess";
import BreadcrumbLink from "../../components/common/BreadcrumbLink";
import Container from "../../components/common/Container";
import HeaderBar from "../../components/common/HeaderBar";
import PageHeading from "../../components/common/PageHeading";
import CreateAuditReportForm, {
  AUDIT_REPORT_FORM_NAME,
} from "../../components/forms/audit/CreateAuditReportForm";
import FormWrapper from "../../components/forms/FormWrapper";
import { getActiveAuditReport } from "../../reducers";
import logger from "../../util/logger";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginBottom: "4rem",
  },
  container: {
    marginTop: theme.spacing(2),
  },
});

const New = ({
  classes,
  localClearActiveAuditReports,
  localCreateAuditReport,
  auditReport,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    localClearActiveAuditReports();
  }, []);

  const handleSubmission = async (values) => {
    const accessToken = await getAccessTokenSilently();
    logger.debug("submitting", values);
    return localCreateAuditReport(values, accessToken);
  };

  return (
    <div className={classes.root}>
      <HeaderBar narrow>
        <BreadcrumbLink to="/audits" label="Audits" includeArrow />
        <PageHeading heading="Create new audit" />
      </HeaderBar>
      <Container narrow className={classes.container}>
        {auditReport && (
          <CreateAuditReportSuccess
            originLink="/audits"
            originLinkLabel="Return to audits"
            itemLink={`/audits/${auditReport.id}`}
          />
        )}
        {!auditReport && (
          <Card>
            <CardContent>
              <FormWrapper
                submitButtonText="Create"
                formComponent={CreateAuditReportForm}
                formName={AUDIT_REPORT_FORM_NAME}
                onSubmit={handleSubmission}
                onCreate
              />
            </CardContent>
          </Card>
        )}
      </Container>
    </div>
  );
};

New.propTypes = {
  classes: PropTypes.object.isRequired,
  auditReport: PropTypes.object,
  localCreateAuditReport: PropTypes.func.isRequired,
  localClearActiveAuditReports: PropTypes.func.isRequired,
};

New.defaultProps = {
  auditReport: null,
};

const mapStateToProps = (state) => ({
  auditReport: getActiveAuditReport(state),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    localCreateAuditReport: createAuditReport,
    localClearActiveAuditReports: clearActiveAuditReports,
  })
)(New);
