import InputLabel from "@material-ui/core/InputLabel";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { getReferenceDataType } from "../../reducers";
import FiltersBar from "../common/FiltersBar";
import RefMultiSelect from "../common/RefMultiSelect";

const TagFilters = ({ filter, updateFilter, tagTypes }) => (
  <FiltersBar fullWidth={false}>
    <>
      <InputLabel>Type</InputLabel>
      <div>
        <RefMultiSelect
          title="Select types"
          value={filter.tagTypes}
          options={tagTypes}
          onChange={(value) => updateFilter("tagTypes", value)}
        />
      </div>
    </>
  </FiltersBar>
);

TagFilters.propTypes = {
  filter: PropTypes.object.isRequired,
  tagTypes: PropTypes.object.isRequired,
  updateFilter: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  tagTypes: getReferenceDataType(state, "TagType"),
});

export default connect(mapStateToProps)(TagFilters);
