import { normalize } from "normalizr";
import schemas from "../schemas";
import auditReportsApi from "../services/api/auditReports";
import { asyncAction } from "./actions";

export const AUDIT_REPORT_CLEAR_ACTIVE = "AUDIT_REPORT_CLEAR_ACTIVE";
export const AUDIT_REPORT_CREATE = "AUDIT_REPORT_CREATE";
export const AUDIT_REPORT_SEARCH = "AUDIT_REPORT_SEARCH";
export const AUDIT_REPORT_GET_BY_ID = "AUDIT_REPORT_GET_BY_ID";
export const AUDIT_REPORT_PATCH = "AUDIT_REPORT_PATCH";
export const AUDIT_SAVE_DOCUMENTS = "AUDIT_SAVE_DOCUMENTS";

export function clearActiveAuditReports() {
  return {
    type: AUDIT_REPORT_CLEAR_ACTIVE,
  };
}

export const createAuditReport = (auditReport, accessToken) =>
  asyncAction(
    AUDIT_REPORT_CREATE,
    auditReportsApi.create(auditReport, accessToken),
    {
      responseTransformer: (createdAuditReport) =>
        normalize(createdAuditReport, schemas.auditReport),
    }
  );

export const searchAuditReports = (filter, accessToken) => {
  const abortController = window.AbortController ? new AbortController() : null;
  return asyncAction(
    AUDIT_REPORT_SEARCH,
    auditReportsApi.search(filter, abortController, accessToken),
    {
      responseTransformer: (response) => ({
        pagination: {
          offset: response.offset,
          previousOffset: response.previousOffset,
          nextOffset: response.nextOffset,
          pageSize: response.pageSize,
          resultCount: response.resultCount,
        },
        ...normalize(response.results, schemas.arrayOfAuditReports),
      }),
      actionParams: {
        abortController,
        nonBlocking: true,
      },
    }
  );
};

export const getAuditReportById = (auditReportId, accessToken) =>
  asyncAction(
    AUDIT_REPORT_GET_BY_ID,
    auditReportsApi.getById(auditReportId, accessToken),
    {
      responseTransformer: (auditReport) =>
        normalize(auditReport, schemas.auditReport),
    }
  );

export const patchAuditReport = (
  auditReportId,
  changes,
  description,
  accessToken
) =>
  asyncAction(
    AUDIT_REPORT_PATCH,
    auditReportsApi.patch(auditReportId, changes, description, accessToken),
    {
      responseTransformer: (auditReport) =>
        normalize(auditReport, schemas.auditReport),
    }
  );

export const saveDocuments = (auditReportId, documents, accessToken) =>
  asyncAction(
    AUDIT_SAVE_DOCUMENTS,
    auditReportsApi.updateDocuments(auditReportId, documents, accessToken),
    {
      responseTransformer: (response) =>
        normalize(response, schemas.auditReport),
      actionParams: {
        isUpdate: true,
      },
    }
  );
