import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { getReferenceDataDescription } from "../../reducers";
import FormCard from "../common/FormCard";
import Scorecard from "../common/Scorecard";
import EditControlDetailsForm, {
  EDIT_CONTROL_DETAILS_FORM_NAME,
} from "../forms/controls/EditControlDetailsForm";

const ControlDetailsCard = ({
  className,
  control,
  onChange,
  fieldLabels,
  getOperationalFrequencyTypeDescription,
}) => {
  return (
    <FormCard
      className={className}
      title="Details"
      formTitle={`Edit details`}
      readOnlyView={() => (
        <>
          <Scorecard label={fieldLabels.labels.objective}>
            {control.objective || "-"}
          </Scorecard>
          <Scorecard
            label={fieldLabels.labels.description}
            data-cy="description"
          >
            {control.description}
          </Scorecard>
          <Scorecard
            label={fieldLabels.labels.operationalFrequency}
            data-cy="frequecncy"
          >
            {getOperationalFrequencyTypeDescription(
              control.operationalFrequency
            )}
          </Scorecard>
        </>
      )}
      formComponent={EditControlDetailsForm}
      formName={EDIT_CONTROL_DETAILS_FORM_NAME}
      onSave={onChange}
      initialValues={{
        objective: control.objective,
        description: control.description,
        operationalFrequency: control.operationalFrequency,
      }}
      formProps={{
        fieldLabels,
      }}
    />
  );
};

ControlDetailsCard.propTypes = {
  className: PropTypes.string,
  control: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  fieldLabels: PropTypes.object.isRequired,
  getOperationalFrequencyTypeDescription: PropTypes.func.isRequired,
};

ControlDetailsCard.defaultProps = {
  className: undefined,
  onChange: null,
};

const mapStateToProps = (state) => ({
  getOperationalFrequencyTypeDescription: (id) =>
    getReferenceDataDescription(state, "OperationalFrequencyType", id, "-"),
});

export default connect(mapStateToProps)(ControlDetailsCard);
