import { useAuth0 } from "@auth0/auth0-react";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import { format, length, required } from "redux-form-validators";
import organisationsApi from "../../../services/api/organisations";
import { scrollToFirstInvalidInput } from "../../../util/formHelpers";
import { organisationIcon } from "../../../util/icons";
import Avatar from "../../common/Avatar.tsx";
import WrappedChipListPicker from "../wrapper/WrappedChipListPicker";
import WrappedTextField from "../wrapper/WrappedTextField";

const UserGroupForm = ({ isEditing }) => {
  const { getAccessTokenSilently } = useAuth0();

  const asyncSargonOrganisationOptionsFetch = async (
    pickerFilter,
    pagination,
    abortController
  ) => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = {
      ...pickerFilter,
      limit: pagination.pageSize,
      offset: pagination.offset,
      types: ["INTERNAL"],
      orderByField: "name",
      excludeGroupFilters: true,
    };
    return organisationsApi.search(
      searchParameters,
      abortController,
      accessToken
    );
  };

  return (
    <form autoComplete="off">
      <Field
        name="friendlyId"
        component={WrappedTextField}
        label="Friendly id"
        fullWidth
        disabled={isEditing}
        required
        validate={[
          required({ message: "Friendly id is required" }),
          length({
            min: 2,
            max: 2,
            message: "Friendly should be 2 characters",
          }),
          format({
            with: /^[A-Z]+$/,
            message: "Friendly id should consist of only uppercase letters",
          }),
        ]}
      />
      <Field
        name="name"
        component={WrappedTextField}
        label="Name"
        validate={required({ msg: "Name is required" })}
        fullWidth
        required
      />
      <Field
        name="organisations"
        component={WrappedChipListPicker}
        datasource={asyncSargonOrganisationOptionsFetch}
        label="Organisations"
        fullWidth
        clearable
        selectAll
        toOption={(org) => ({ label: org.name, id: org.id, org })}
        fromOption={(option) => option.org}
        addIcon={organisationIcon()}
        renderIcon={(org, size) => (
          <Avatar name={(org.name || "Unknown").charAt(0)} size={size} round />
        )}
      />
    </form>
  );
};

UserGroupForm.propTypes = {
  isEditing: PropTypes.bool,
};

UserGroupForm.defaultProps = {
  isEditing: false,
};

export const USERGROUP_FORM_NAME = "userGroupForm";

const mapStateToProps = () => ({});

const form = reduxForm({
  form: USERGROUP_FORM_NAME,
  onSubmitFail: (errors) => scrollToFirstInvalidInput(errors),
});

const reduxData = connect(mapStateToProps);
export default compose(form, reduxData)(UserGroupForm);
