import { normalize } from "normalizr";
import schemas from "../schemas";
import userGroupsApi from "../services/api/userGroups";
import { asyncAction } from "./actions";

export const USERGROUP_CLEAR_ACTIVE = "USERGROUP_CLEAR_ACTIVE";
export const USERGROUP_CREATE = "USERGROUP_CREATE";
export const USERGROUP_SEARCH = "USERGROUP_SEARCH";
export const USERGROUP_GET_BY_ID = "USERGROUP_GET_BY_ID";
export const USERGROUP_PATCH = "USERGROUP_PATCH";

export function clearActiveUserGroups() {
  return {
    type: USERGROUP_CLEAR_ACTIVE,
  };
}

export const createUserGroup = (userGroup, accessToken) =>
  asyncAction(USERGROUP_CREATE, userGroupsApi.create(userGroup, accessToken), {
    responseTransformer: (created) => normalize(created, schemas.userGroup),
  });

export const searchUserGroups = (searchParameters, accessToken) => {
  const abortController = window.AbortController ? new AbortController() : null;
  return asyncAction(
    USERGROUP_SEARCH,
    userGroupsApi.search(searchParameters, abortController, accessToken),
    {
      responseTransformer: (response) => ({
        pagination: {
          offset: response.offset,
          previousOffset: response.previousOffset,
          nextOffset: response.nextOffset,
          pageSize: response.pageSize,
          resultCount: response.resultCount,
        },
        ...normalize(response.results, schemas.arrayOfUserGroups),
      }),
      actionParams: {
        abortController,
        nonBlocking: true,
      },
    }
  );
};

export const getUserGroupById = (orgId, accessToken) =>
  asyncAction(USERGROUP_GET_BY_ID, userGroupsApi.getById(orgId, accessToken), {
    responseTransformer: (userGroup) => normalize(userGroup, schemas.userGroup),
  });

export const patchUserGroup = (orgId, changes, description, accessToken) =>
  asyncAction(
    USERGROUP_PATCH,
    userGroupsApi.patch(orgId, changes, description, accessToken),
    {
      responseTransformer: (userGroup) =>
        normalize(userGroup, schemas.userGroup),
    }
  );
