import _ from "lodash";
import { CollaboratorType } from "../model/CollaboratorType";
import { WorkItemUser } from "../model/User";

export const getFirstWorkItemUserByType = (
  users: WorkItemUser[],
  type: CollaboratorType
): WorkItemUser | undefined => {
  return _.find(users, (u) => u.type === type);
};

export const getWorkItemUsersByType = (
  users: WorkItemUser[],
  type: CollaboratorType
): WorkItemUser[] => {
  if (!users) return [];
  return users.filter((u) => u.type === type);
};
