import { useAuth0 } from "@auth0/auth0-react";
import { withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { getLoggedInUser } from "../reducers";
import zendeskService from "../services/api/zendesk";
import logger from "../util/logger";

const styles = () => ({});

const createScript = (accessToken) => {
  try {
    zendeskService.getData(accessToken).then((data) => {
      if (!data) {
        return;
      }

      const { url, id, token } = data;
      const zendeskScriptTag = document.createElement("script");
      zendeskScriptTag.src = url;
      zendeskScriptTag.id = id;
      zendeskScriptTag.async = true;
      document.getElementsByTagName("head")[0].appendChild(zendeskScriptTag);

      // Main zendesk script tag
      window.zESettings = {
        // eslint-disable-next-line max-len
        authenticate: { jwt: token },
        webWidget: {
          helpCenter: {
            filter: {
              category: "360000121095",
            },
          },
          color: { theme: "#92a1b3", launcherText: "#FFF" },
          position: { horizontal: "left" },
          zIndex: 1,
          talk: {
            suppress: true,
          },
          answerBot: {
            suppress: true,
          },
        },
      };

      // Additional settings with time buffer - previous settings need to initialize
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        if (window.zE) {
          // eslint-disable-next-line no-undef
          window.zE("webWidget", "helpCenter:setSuggestions", {
            search: "arcadia",
          });
        }
      }, 2000);
    });
  } catch (e) {
    logger.error("Failed to load support widget");
  }
};

const SupportWidget = ({ loggedInUser }) => {
  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    if (loggedInUser) {
      getAccessTokenSilently().then((accessToken) => createScript(accessToken));
    }
  }, [loggedInUser]);

  return null;
};

SupportWidget.propTypes = {
  loggedInUser: PropTypes.object,
};

SupportWidget.defaultProps = {
  loggedInUser: null,
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(SupportWidget);
