import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import {
  getAsyncValidate,
  scrollToFirstInvalidInput,
} from "../../../util/formHelpers";
import FormError from "../../common/FormError";
import SnippetContentFields, {
  referenceAsyncValidator,
} from "./SnippetContentFields";

export const ADD_SNIPPET_CONTENT_FORM_NAME = "ADD_SNIPPET_CONTENT_FORM_NAME";

const asyncValidators = {
  reference: {
    ...referenceAsyncValidator,
  },
};

const AddSnippetContentForm = ({ error, fieldLabels, originalData }) => (
  <form autoComplete="off">
    {error && <FormError errorMessage={error} />}
    <SnippetContentFields
      fieldLabels={fieldLabels}
      formName={ADD_SNIPPET_CONTENT_FORM_NAME}
      namePrefix=""
      originalData={originalData}
    />
  </form>
);

AddSnippetContentForm.propTypes = {
  error: PropTypes.string,
  fieldLabels: PropTypes.object.isRequired,
  originalData: PropTypes.string.isRequired,
};

AddSnippetContentForm.defaultProps = {
  error: null,
};

const mapStateToProps = () => ({});

const form = reduxForm({
  form: ADD_SNIPPET_CONTENT_FORM_NAME,
  asyncValidate: getAsyncValidate(asyncValidators),
  asyncBlurFields: ["reference"],
  onSubmitFail: (errors) => scrollToFirstInvalidInput(errors),
});
const reduxData = connect(mapStateToProps);
export default compose(form, reduxData)(AddSnippetContentForm);
