import moment from "moment";
import { isProd } from "./env";

const levels = {
  error: {
    value: 0,
    name: "ERROR",
    log: console.error,
  },
  warn: {
    value: 1,
    name: "WARN",
    log: console.warn,
  },
  info: {
    value: 2,
    name: "INFO",
    log: console.info,
  },
  debug: {
    value: 3,
    name: "DEBUG",
    log: console.log,
  },
};

const logLevel = isProd ? levels.error : levels.debug;

const print = (level) =>
  `${level} - ${moment().format("YYYY-MM-DD HH:mm:ss.SSS")}`;

const log =
  (levelConfig) =>
  (...args) =>
    logLevel.value >= levelConfig.value &&
    levelConfig.log(print(levelConfig.name), ...args);

export default {
  debug: log(levels.debug),
  info: log(levels.info),
  warn: log(levels.warn),
  error: log(levels.error),
};
