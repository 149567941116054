import { withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  getReferenceDataDescription,
  getReferenceDataType,
} from "../../reducers";
import Scorecard from "../common/Scorecard";

const styles = (theme) => ({
  icon: {
    marginRight: theme.spacing(1),
  },
});

const RiskOverviewFieldsReadOnly = ({
  fieldLabels,
  risk,
  riskLikelihoods,
  consequenceRatings,
  riskCategories,
  getRiskRatingDescription,
}) => {
  return (
    <>
      <Scorecard
        label={fieldLabels.labels.title}
        data-cy={fieldLabels.labels.title}
      >
        {risk.title}
      </Scorecard>
      <Scorecard
        label={fieldLabels.labels.category}
        data-cy={fieldLabels.labels.category}
      >
        {riskCategories.values[risk.category].description}
      </Scorecard>
      <Scorecard
        label={fieldLabels.labels.likelihood}
        data-cy={fieldLabels.labels.likelihood}
      >
        {riskLikelihoods.values[risk.likelihood].description}
      </Scorecard>
      <Scorecard
        label={fieldLabels.labels.consequence}
        data-cy={fieldLabels.labels.consequence}
      >
        {consequenceRatings.values[risk.consequence].description}
      </Scorecard>
      <Scorecard
        label={fieldLabels.labels.riskRating}
        data-cy={fieldLabels.labels.riskRating}
      >
        {getRiskRatingDescription(risk.riskRating)}
      </Scorecard>
    </>
  );
};

RiskOverviewFieldsReadOnly.propTypes = {
  fieldLabels: PropTypes.object.isRequired,
  risk: PropTypes.object.isRequired,
  riskLikelihoods: PropTypes.object.isRequired,
  consequenceRatings: PropTypes.object.isRequired,
  riskCategories: PropTypes.object.isRequired,
  getRiskRatingDescription: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  riskLikelihoods: getReferenceDataType(state, "RiskLikelihood"),
  consequenceRatings: getReferenceDataType(state, "RiskConsequenceRating"),
  riskCategories: getReferenceDataType(state, "RiskCategory"),
  getRiskRatingDescription: (id) =>
    getReferenceDataDescription(state, "RiskRating", id, "-"),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(RiskOverviewFieldsReadOnly);
