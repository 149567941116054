import { useAuth0 } from "@auth0/auth0-react";
import {
  AccountButton,
  AppBarLogo,
  MenuButton,
  NavDrawer,
  Tenant,
  TenantSummary,
  TenantSwitcher,
  useTenant,
} from "@certane/arcadia-web-components";
import { Theme, ThemeProvider } from "@material-ui/core/styles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { SnackbarProvider } from "notistack";
import React, { ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDragging } from "../../actions/drag";
import {
  closeDrawer as closeDrawerAction,
  openDrawer as openDrawerAction,
} from "../../actions/drawer";
import {
  getOpenDrawer,
  isLoading,
  isLoadingBlockerEnabled,
} from "../../reducers";
import tenantsApi from "../../services/api/tenants";
import BlockingLoadingIndicator from "../common/BlockingLoadingIndicator";
import NewVersionModal from "../NewVersionModal";
import SnackbarListener from "../SnackbarListener";
import SupportWidget from "../SupportWidget";
import ArcadiaAppBar from "./app-bar/ArcadiaAppBar";
import NotificationButton from "./app-bar/notification/NotificationButton";

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: theme.palette.success.main,
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
  info: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

interface Props {
  children: ReactNode | ReactNode[];
}

const Layout: React.FC<Props> = ({ children }: Props) => {
  const classes = useStyles();

  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const loading = useSelector(isLoading);
  const blockerEnabled = useSelector(isLoadingBlockerEnabled);
  const drawerOpened = useSelector((state) => getOpenDrawer(state) === "main");

  const { getActiveTenantId, tenant } = useTenant();
  const [availableTenants, setAvailableTenants] = useState<TenantSummary[]>([]);
  const [activeTenant, setActiveTenant] = useState<Tenant | null>(null);

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      const response = await tenantsApi.listMine(accessToken);
      setAvailableTenants(response.data || []);
    })();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    (async () => {
      const activeTenantId = getActiveTenantId();
      const matched = availableTenants.filter(
        (tenant) => activeTenantId === tenant.id
      );

      if (matched?.length > 0) {
        setActiveTenant(tenant);
      }
    })();
  }, [availableTenants]);

  const openDrawer = () => dispatch(openDrawerAction("main"));
  const closeDrawer = () => dispatch(closeDrawerAction());
  const toggleDragging = (dragging: boolean) => dispatch(setDragging(dragging));

  const arcadiaBaseUrl = process.env.REACT_APP_ARCADIA_HOST;
  const reggaeBaseUrl = process.env.REACT_APP_REGGAE_HOST;

  // Use default if activeTenant is loaded but logo is not set.
  // The condition is like this, so there is no flashing Certane logo.
  const tenantUrl =
    activeTenant && !activeTenant.logo
      ? "https://cdn.diversa.com.au/assets/logos/logo-white.png"
      : activeTenant?.logo;
  const tenantName =
    activeTenant && !activeTenant.logo ? "Certane" : activeTenant?.name;

  const logo = (
    <AppBarLogo logoUrl={tenantUrl} title={tenantName} link={arcadiaBaseUrl} />
  );

  return (
    <div
      onDragEnter={() => toggleDragging(true)}
      onMouseLeave={() => toggleDragging(false)}
      onDrop={() => toggleDragging(false)}
    >
      <SnackbarProvider
        classes={{
          variantSuccess: classes.success,
          variantError: classes.error,
          variantWarning: classes.warning,
          variantInfo: classes.info,
        }}
        maxSnack={3}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <ThemeProvider
          theme={(outerTheme) => ({
            ...outerTheme,
            palette: {
              ...(outerTheme as Theme).palette,
              type: "dark",
            },
          })}
        >
          <ArcadiaAppBar
            leadingIcon={<MenuButton onClick={openDrawer} />}
            headline={logo}
            trailingIcon={
              <>
                <TenantSwitcher tenants={availableTenants} />
                <NotificationButton />
                <AccountButton />
              </>
            }
          />
        </ThemeProvider>

        <NavDrawer
          open={drawerOpened}
          onClose={closeDrawer}
          headline={logo}
          options={{
            arcadiaBaseUrl,
            reggaeBaseUrl,
          }}
        />

        <BlockingLoadingIndicator loading={loading} block={blockerEnabled} />
        <div className="main" data-cy="main">
          {children}
        </div>

        <NewVersionModal />
        <SupportWidget />
        <SnackbarListener />
      </SnackbarProvider>
    </div>
  );
};

export default Layout;
