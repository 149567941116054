import { combineReducers } from "redux";
import {
  RISK_CONTROL_ADD_ASSOCIATED_RISKS,
  RISK_CONTROL_CLEAR_ACTIVE,
  RISK_CONTROL_CREATE,
  RISK_CONTROL_LIST,
  RISK_CONTROL_LOAD,
  RISK_CONTROL_LOAD_ASSOCIATED_RISKS,
  RISK_CONTROL_LOAD_METRIC,
  RISK_CONTROL_PATCH,
  RISK_CONTROL_REMOVE,
} from "../actions/riskControls";
import { publishActionToast } from "../services/toasts";
import logger from "../util/logger";

const successToasts = {
  [RISK_CONTROL_CREATE]: "Created risk control successfully",
  [RISK_CONTROL_REMOVE]: "Removed risk control successfully",
  [RISK_CONTROL_PATCH]: "Updated risk control successfully",
};

const byId = (state = {}, action) => {
  publishActionToast(action, "RISK-CONTROL", successToasts);

  switch (action.type) {
    case `${RISK_CONTROL_CREATE}_SUCCESS`:
    case `${RISK_CONTROL_LIST}_SUCCESS`:
    case `${RISK_CONTROL_LOAD}_SUCCESS`:
    case `${RISK_CONTROL_PATCH}_SUCCESS`:
      return {
        ...state,
        ...action.response.entities.riskControl,
      };

    // Remove a risk control
    case `${RISK_CONTROL_REMOVE}_SUCCESS`:
      const idToRemove = action.response.id;
      return {
        ...state,
        [idToRemove]: undefined,
      };
    default:
      break;
  }

  return state;
};

const defaultActive = {
  dashboard: [],
  dashboardLastUpdate: 0,
  activeRiskControl: null,
  exportLink: null,
  error: null,
  searchAbortController: null,
  pagination: {
    offset: 0,
    pageSize: 50,
    resultCount: 0,
  },
};

const active = (state = defaultActive, action) => {
  switch (action.type) {
    case RISK_CONTROL_CLEAR_ACTIVE:
      return {
        ...defaultActive,
      };

    case `${RISK_CONTROL_LIST}_INPROGRESS`:
      if (state.searchAbortController) {
        logger.info("aborting previous unfinished request");
        state.searchAbortController.abort();
      }
      return {
        ...state,
        searchAbortController: action.abortController,
      };

    case `${RISK_CONTROL_LIST}_FAILURE`:
      return {
        ...state,
        searchAbortController: null,
      };

    case `${RISK_CONTROL_LIST}_SUCCESS`:
      return {
        ...state,
        dashboard: [...action.response.result],
        dashboardLastUpdate: new Date().getTime(),
        pagination: {
          ...action.response.pagination,
          pageSize: defaultActive.pagination.pageSize,
        },
      };

    case `${RISK_CONTROL_CREATE}_SUCCESS`:
    case `${RISK_CONTROL_LOAD}_SUCCESS`:
      return {
        ...state,
        activeRiskControl: action.response.result,
      };

    // failed risk control
    case `${RISK_CONTROL_LOAD}_FAILURE`:
      return {
        ...state,
        activeRiskControl: null,
        error: {
          status: action.error.status,
          message: action.errorMessage,
        },
      };

    // Remove a risk control
    case `${RISK_CONTROL_REMOVE}_SUCCESS`:
      const idToRemove = action.response.id;
      return {
        ...state,
        dashboard: [...state.dashboard.filter((id) => id !== idToRemove)],
        activeRiskControl:
          state.activeRiskControl === idToRemove
            ? null
            : state.activeRiskControl,
      };

    default:
      break;
  }

  return state;
};

const metrics = (state = {}, action) => {
  switch (action.type) {
    case `${RISK_CONTROL_LOAD_METRIC}_SUCCESS`:
      return {
        ...state,
        [action.response.metric]: action.response.count,
      };
    default:
      break;
  }

  return state;
};

const associatedRisks = (state = [], action) => {
  switch (action.type) {
    case `${RISK_CONTROL_LOAD_ASSOCIATED_RISKS}_LOADING`:
      return [];
    case `${RISK_CONTROL_ADD_ASSOCIATED_RISKS}_SUCCESS`:
    case `${RISK_CONTROL_LOAD_ASSOCIATED_RISKS}_SUCCESS`:
      return [...action.response];

    default:
      break;
  }

  return state;
};

export default combineReducers({
  active,
  byId,
  metrics,
  associatedRisks,
});

export const getActiveRiskControl = (state) =>
  state.active.activeRiskControl
    ? state.byId[state.active.activeRiskControl]
    : null;
export const getRiskControls = (state) =>
  state.active.dashboard.map((id) => state.byId[id]);
export const getSearchPagination = (state) => state.active.pagination;
export const getError = (state) => state.active.error;
export const getMetrics = (state) => state.metrics;
export const getAssociatedRisks = (state) => state.associatedRisks;
