import { requestJSON } from "./http";

const list = (accessToken) =>
  requestJSON({
    path: "/notifications",
    method: "GET",
    accessToken,
  });

const markAsRead = (id, accessToken) =>
  requestJSON({
    path: `/notifications/${id}`,
    method: "PATCH",
    data: {
      fields: ["read"],
      changes: { read: true },
      description: "Marked as read",
    },
    accessToken,
  });

const dismiss = (id, accessToken) =>
  requestJSON({ path: `/notifications/${id}`, method: "DELETE", accessToken });

const dismissAll = (accessToken) =>
  requestJSON({ path: "/notifications", method: "DELETE", accessToken });

export default {
  list,
  markAsRead,
  dismiss,
  dismissAll,
};
