import PropTypes from "prop-types";
import React from "react";
import dates from "../../util/dates";
import DateAgo from "../common/DateAgo";
import FormCard from "../common/FormCard";
import Scorecard from "../common/Scorecard";
import TimeAgo from "../common/TimeAgo";
import EditMediaForm, {
  EDIT_MEDIA_FORM_NAME,
} from "../forms/metropolis/EditMediaForm";

const MediaCard = ({ className, title, media, onChange, fieldLabels }) => {
  const inUseFutureDates = media.documentVersions
    .filter((v) => v.status === "FUTURE")
    .map((v) => v.scheduledActivationDate);

  return (
    <FormCard
      data-cy={title}
      className={className}
      title={title}
      formTitle={`Edit ${title}`}
      readOnlyView={() => (
        <>
          <Scorecard
            label={fieldLabels.labels.category}
            data-cy={fieldLabels.labels.category}
          >
            {media.category ? media.category.name : "-"}
          </Scorecard>
          <Scorecard
            label={fieldLabels.labels.nextReviewDate}
            data-cy={fieldLabels.labels.nextReviewDate}
          >
            {media.nextReviewDate ? (
              <DateAgo
                value={dates.parseDate(media.nextReviewDate)}
                expandable
              />
            ) : (
              "-"
            )}
          </Scorecard>
          <Scorecard
            label={fieldLabels.labels.updated}
            data-cy={fieldLabels.labels.updated}
          >
            <TimeAgo value={dates.parseTimestamp(media.updated)} expandable />
          </Scorecard>
        </>
      )}
      formComponent={EditMediaForm}
      formName={EDIT_MEDIA_FORM_NAME}
      onSave={onChange}
      disabledFutureDates={inUseFutureDates}
      initialValues={media}
      formProps={{
        fieldLabels,
      }}
    />
  );
};

MediaCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  media: PropTypes.object.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  onChange: PropTypes.func,
};

MediaCard.defaultProps = {
  className: null,
  onChange: null,
};

export default MediaCard;
