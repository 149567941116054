import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";

const AlertDialog = ({
  title,
  body,
  submitButtonText,
  submitEnabled,
  open,
  onCancel,
  onSubmit,
}) => (
  <Dialog
    fullWidth
    maxWidth="sm"
    open={open}
    onClose={onCancel}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    data-cy="alert"
  >
    <DialogTitle id="alert-dialog-title" data-cy={title}>
      {title}
    </DialogTitle>
    <DialogContent>
      {_.isString(body) ? (
        <DialogContentText id="alert-dialog-description" data-cy={body}>
          {body}
        </DialogContentText>
      ) : (
        body()
      )}
    </DialogContent>
    <DialogActions>
      {!!onCancel && (
        <Button onClick={onCancel} color="primary" data-cy="cancel">
          Cancel
        </Button>
      )}
      <Button
        onClick={onSubmit}
        color="primary"
        autoFocus
        disabled={!submitEnabled}
        data-cy="submit"
      >
        {submitButtonText}
      </Button>
    </DialogActions>
  </Dialog>
);

AlertDialog.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  submitButtonText: PropTypes.string.isRequired,
  submitEnabled: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
};

AlertDialog.defaultProps = {
  onCancel: null,
  submitEnabled: true,
};

export default AlertDialog;
