import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import useTheme from "@material-ui/core/styles/useTheme";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PropTypes from "prop-types";
import React, { useState } from "react";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  paperWidthSm: {
    width: Math.max(theme.breakpoints.values.sm, 600),
  },
  notesArea: {
    width: "100%",
  },
});

const EditTextDialog = ({
  classes,
  open,
  title,
  actionText,
  multiline,
  initialValue,
  onClose,
  onSubmit,
}) => {
  const [value, setValue] = useState(initialValue);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      classes={{
        paperWidthSm: classes.paperWidthSm,
      }}
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="Edit text"
      data-cy="dialog"
    >
      <DialogTitle id="Edit text" data-cy={title}>
        {title}
      </DialogTitle>
      <DialogContent>
        <TextField
          className={classes.notesArea}
          value={value}
          multiline={multiline}
          onChange={(event) => setValue(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose} data-cy="cancel">
          Cancel
        </Button>
        <Button color="primary" onClick={() => onSubmit(value)}>
          {actionText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EditTextDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  initialValue: PropTypes.string,
  title: PropTypes.string,
  actionText: PropTypes.string,
  multiline: PropTypes.bool,
};

EditTextDialog.defaultProps = {
  title: "",
  multiline: false,
  actionText: "Save",
  initialValue: "",
};

export default withStyles(styles)(EditTextDialog);
