import _ from "lodash";
import { withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { getFormValues, isDirty, isSubmitting, submit } from "redux-form";
import { isUploading } from "../../reducers";
import logger from "../../util/logger";

const styles = (theme) => ({
  actions: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  form: {
    zIndex: 1,
    overflow: "visible",
  },
});

const AUTO_SAVE_INTERVAL = 30000;

const removeEmptyFields = (obj) => {
  let result = _.omitBy(obj, _.isUndefined);
  result = _.omitBy(result, _.isNull);
  result = _.omitBy(result, (v) => _.isArray(v) && v.length === 0);

  return result;
};

const FormWrapper = ({
  classes,
  submitButtonText,
  cancelButtonText,
  deleteButtonText,
  saveDraftButtonText,
  onSubmit,
  onCancel,
  onDelete,
  onSaveDraft,
  uploading,
  style,
  renderActions,
  formComponent: FormComponent,
  initialValues,
  handleSubmit,
  formState,
  editingStarted,
  autoSaveDraft,
  submitting,
  ...rest
}) => {
  const [triggerAutoSave, setTriggerAutoSave] = useState(false);
  const [savedFormState, setSavedFormState] = useState(initialValues);
  const [formChanged, setFormChanged] = useState(false);

  const handleDelete = () => onDelete && onDelete(initialValues);
  const handleSaveDraft = () => {
    if (onSaveDraft) {
      onSaveDraft(formState);
      setSavedFormState(formState);
    }
  };

  useEffect(() => {
    let intervalId = null;
    if (autoSaveDraft) {
      intervalId = window.setInterval(
        () => setTriggerAutoSave(true),
        AUTO_SAVE_INTERVAL
      );
    }
    return () => {
      logger.debug("Clearing interval", intervalId);
      if (intervalId) {
        window.clearInterval(intervalId);
      }
    };
  }, []);

  useEffect(() => {
    const changed =
      editingStarted &&
      formState &&
      !_.isEqual(
        removeEmptyFields(formState),
        removeEmptyFields(savedFormState)
      );
    // logger.debug('Checking if form changed: isDirty:', editingStarted, 'current form state:', formState, 'last saved state:', savedFormState, 'result is:', changed);
    setFormChanged(changed);
  }, [formState, savedFormState, editingStarted]);

  useEffect(() => {
    if (triggerAutoSave) {
      if (formChanged && !uploading) {
        logger.debug("Saving draft", formState, savedFormState);
        handleSaveDraft();
      } else if (uploading) {
        logger.debug("Changed - but waiting for file uploading to stop");
      } else {
        logger.debug("No change - no need to save draft");
      }
      setTriggerAutoSave(false);
    }
  }, [triggerAutoSave]);

  const actions = (
    <>
      {onCancel && (
        <Button
          variant="text"
          color="default"
          onClick={onCancel}
          className={classes.button}
          data-cy="cancel"
        >
          {cancelButtonText}
        </Button>
      )}
      {onDelete && (
        <Button
          variant="text"
          color="secondary"
          onClick={handleDelete}
          className={classes.button}
          disabled={submitting}
          data-cy="delete"
        >
          {deleteButtonText}
        </Button>
      )}
      {onSaveDraft && (
        <Button
          variant="text"
          color="secondary"
          onClick={handleSaveDraft}
          className={classes.button}
          disabled={uploading || submitting || !formChanged}
          data-cy="saveAs"
        >
          {saveDraftButtonText}
        </Button>
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={uploading || submitting}
        data-cy="create"
      >
        {submitButtonText}
      </Button>
    </>
  );

  return (
    <div style={style}>
      <div className={classes.form}>
        <FormComponent
          onSubmit={onSubmit}
          onCancel={onCancel}
          handleSubmit={handleSubmit}
          submitButtonText={submitButtonText}
          initialValues={initialValues}
          {...rest}
        />
      </div>
      {renderActions && <div className={classes.actions}>{actions}</div>}
    </div>
  );
};

FormWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  formComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
    .isRequired,
  formName: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onSaveDraft: PropTypes.func,
  submitButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  deleteButtonText: PropTypes.string,
  saveDraftButtonText: PropTypes.string,
  initialValues: PropTypes.object,
  submitting: PropTypes.bool.isRequired,
  uploading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  style: PropTypes.any,
  renderActions: PropTypes.bool,
  formState: PropTypes.object,
  editingStarted: PropTypes.bool,
  autoSaveDraft: PropTypes.bool,
};

FormWrapper.defaultProps = {
  submitButtonText: "Submit",
  cancelButtonText: "Cancel",
  deleteButtonText: "Delete",
  saveDraftButtonText: "Save as draft",
  initialValues: null,
  onCancel: null,
  onDelete: null,
  onSaveDraft: null,
  style: null,
  renderActions: true,
  formState: {},
  editingStarted: false,
  autoSaveDraft: false,
};

const mapStateToProps = (state, ownProps) => {
  const formValues = getFormValues(ownProps.formName);
  return {
    uploading: isUploading(state),
    formState: formValues(state),
    editingStarted: isDirty(ownProps.formName)(state),
    submitting: isSubmitting(ownProps.formName)(state),
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => ({
  handleSubmit: () => dispatch(submit(ownProps.formName)),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(FormWrapper);
