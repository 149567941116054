import { Card } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { clearActiveRisks, createRisk } from "../../actions/risks";
import BreadcrumbLink from "../../components/common/BreadcrumbLink";
import Container from "../../components/common/Container";
import HeaderBar from "../../components/common/HeaderBar";
import PageHeading from "../../components/common/PageHeading";
import FormWrapper from "../../components/forms/FormWrapper";
import CreateRiskForm, {
  RISK_FORM_NAME,
} from "../../components/forms/risk/CreateRiskForm";
import CreateRiskSuccess from "../../components/risk/CreateRiskSuccess";
import { getActiveRisk } from "../../reducers";
import logger from "../../util/logger";
import { useAuth0 } from "@auth0/auth0-react";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginBottom: "4rem",
  },
  container: {
    marginTop: theme.spacing(2),
  },
});

const New = ({ classes, createdRisk, clearActiveRisks, createRisk }) => {
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    clearActiveRisks();
  }, []);

  const handleSubmission = async (values) => {
    logger.debug("submitting risk with values", values);

    const payload = {
      ...values,
    };

    logger.debug("submitting", payload);
    const accessToken = await getAccessTokenSilently();
    return createRisk(payload, accessToken);
  };

  return (
    <div className={classes.root}>
      <HeaderBar narrow>
        <BreadcrumbLink to="/risk/risks" label="Risks" includeArrow />
        <PageHeading heading="Create new risk" />
      </HeaderBar>
      <Container narrow className={classes.container}>
        {createdRisk && (
          <CreateRiskSuccess
            originLink="/risk/risks"
            originLinkLabel="Return to risks"
            itemLink={`/risk/risks/${createdRisk.id}`}
            friendlyId={createdRisk.friendlyId}
          />
        )}
        {!createdRisk && (
          <Card>
            <CardContent>
              <FormWrapper
                submitButtonText="Create"
                formComponent={CreateRiskForm}
                formName={RISK_FORM_NAME}
                onSubmit={handleSubmission}
                onCreateForm
                data-cy={CreateRiskForm}
              />
            </CardContent>
          </Card>
        )}
      </Container>
    </div>
  );
};

New.propTypes = {
  classes: PropTypes.object.isRequired,
  createdRisk: PropTypes.object,
  createRisk: PropTypes.func.isRequired,
  clearActiveRisks: PropTypes.func.isRequired,
};

New.defaultProps = {
  createdRisk: null,
};

const mapStateToProps = (state) => ({
  createdRisk: getActiveRisk(state),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    createRisk,
    clearActiveRisks,
  })
)(New);
