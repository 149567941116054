import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

const Logout: React.FC = () => {
  const { isLoading, logout } = useAuth0();
  if (!isLoading) {
    logout({ returnTo: window.location.origin });
  }
  return null;
};

export default Logout;
