import "custom-event-polyfill";
import _ from "lodash";

const TOAST_EVENT = "TOAST_EVENT";

export const publishToastSuccess = (message) => {
  window.dispatchEvent(
    new CustomEvent(TOAST_EVENT, {
      detail: {
        message,
        variant: "success",
      },
    })
  );
};

export const publishToastInfo = (message) => {
  window.dispatchEvent(
    new CustomEvent(TOAST_EVENT, {
      detail: {
        message,
        variant: "info",
      },
    })
  );
};

export const publishToastError = (message) => {
  window.dispatchEvent(
    new CustomEvent(TOAST_EVENT, {
      detail: {
        message,
        variant: "error",
      },
    })
  );
};

export const publishToastWarning = (message) => {
  window.dispatchEvent(
    new CustomEvent(TOAST_EVENT, {
      detail: {
        message,
        variant: "warning",
      },
    })
  );
};

export const publishActionToast = (
  action,
  prefix,
  successToasts = {},
  failureToasts = {}
) => {
  if (action.type && _.isString(action.type)) {
    if (action.type.startsWith(prefix) && action.type.endsWith("_FAILURE")) {
      const message =
        failureToasts[action.type.replace("_FAILURE", "")] ||
        action.errorMessage;
      if (message) {
        publishToastError(message);
      }
    } else if (
      action.type.startsWith(prefix) &&
      action.type.endsWith("_SUCCESS")
    ) {
      const message = successToasts[action.type.replace("_SUCCESS", "")];

      if (message) {
        publishToastSuccess(message);
      }
    }
  }
};

export const subscribeToasts = (listener) => {
  window.addEventListener(TOAST_EVENT, listener, false);
};

export const unsubscribeToasts = (listener) => {
  window.removeEventListener(TOAST_EVENT, listener, false);
};
