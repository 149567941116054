import { useAuth0 } from "@auth0/auth0-react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  clearActiveWorkItem,
  getWorkItemById,
  patchWorkItem,
} from "../../actions/workItems";
import { fetchWorkItemSchedule } from "../../actions/workItemSchedules";
import BreadcrumbLink from "../../components/common/BreadcrumbLink";
import FullWidthNotification from "../../components/common/FullWidthNotification";
import HeaderBar from "../../components/common/HeaderBar";
import UnifiedHeading from "../../components/common/UnifiedHeading";
import { getWorkItem, getWorkItemSchedule, isLoading } from "../../reducers";

import { workItemSchedulesPath } from "../../routes/routeUtils";
import { workItemScheduleIcon } from "../../util/icons";
import WorkItemTabs from "../../components/workitem/WorkItemTabs";

const styles = (theme) => ({
  container: {
    paddingTop: theme.spacing(3),
    marginBottom: "75px",
  },
});

const WorkItemScheduleId = ({
  loading,
  workItemSchedule,
  workItem,
  localFetchWorkItemSchedule,
  localClearActiveWorkItem,
  localGetWorkItemById,
  localPatchWorkItem,
  match: {
    params: { workItemScheduleId },
  },
}) => {
  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      localFetchWorkItemSchedule(workItemScheduleId, accessToken);
      localClearActiveWorkItem();
    })();
  }, [workItemScheduleId]);

  useEffect(() => {
    if (
      workItemSchedule &&
      !loading &&
      (!workItem || workItem.id !== workItemSchedule.template.id)
    ) {
      (async () => {
        const accessToken = await getAccessTokenSilently();
        localGetWorkItemById(workItemSchedule.template.id, accessToken);
      })();
    }
  }, [workItemSchedule, workItem]);

  const handleTitleUpdate = async (title) => {
    const accessToken = await getAccessTokenSilently();
    localPatchWorkItem(
      workItemSchedule.template.id,
      { title },
      "Edited compliance task template",
      accessToken
    );
  };

  const bannerText =
    "Please note: Any modifications made to this template will only take effect for newly created occurrences... existing work items will not be affected.";

  return (
    <div>
      <FullWidthNotification text={bannerText} />
      {workItemSchedule && (
        <>
          <HeaderBar>
            <BreadcrumbLink
              to={workItemSchedulesPath()}
              label="Compliance calendar"
              includeArrow
            />
            <UnifiedHeading
              icon={workItemScheduleIcon()}
              heading="Compliance task template"
              subHeading={workItemSchedule.template.title}
              workItem={workItemSchedule.template}
              readonly={workItemSchedule.template.status === "CLOSED"}
              onTitleChange={handleTitleUpdate}
            />
          </HeaderBar>
          {workItem && <WorkItemTabs workItem={workItem} />}
        </>
      )}
    </div>
  );
};

WorkItemScheduleId.propTypes = {
  match: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  workItemSchedule: PropTypes.object,
  workItem: PropTypes.object,
  localFetchWorkItemSchedule: PropTypes.func.isRequired,
  localClearActiveWorkItem: PropTypes.func.isRequired,
  localGetWorkItemById: PropTypes.func.isRequired,
  localPatchWorkItem: PropTypes.func.isRequired,
};

WorkItemScheduleId.defaultProps = {
  workItemSchedule: undefined,
  workItem: undefined,
};

const mapStateToProps = (state) => ({
  loading: isLoading(state),
  workItemSchedule: getWorkItemSchedule(state),
  workItem: getWorkItem(state),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    localFetchWorkItemSchedule: fetchWorkItemSchedule,
    localClearActiveWorkItem: clearActiveWorkItem,
    localGetWorkItemById: getWorkItemById,
    localPatchWorkItem: patchWorkItem,
  })
)(WorkItemScheduleId);
