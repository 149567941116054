import { List } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import dates from "../../../../util/dates";
import formatters from "../../../../util/formatters";
import DateAgo from "../../../common/DateAgo";
import FormCard from "../../../common/FormCard";
import EditDependentChecklistForm, {
  EDIT_DEPENDENT_CHECKLIST_FORM_NAME,
} from "./EditDependentChecklistForm";

class DependantsChecklistCard extends Component {
  render() {
    const { className, fieldLabels, workItem, onChange } = this.props;

    const { dependants } = workItem;
    const disableEdit = !onChange || dependants.length === 0;

    return (
      <FormCard
        className={className}
        title={`${fieldLabels.labels.dependants} checklist`}
        formTitle={`Edit ${fieldLabels.labels.dependants} checklist`}
        readOnlyView={() => (
          <>
            <List dense>
              {dependants.map((dependant, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <ListItem key={index} divider>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1">
                        {dependant.contact.name}
                      </Typography>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="caption">
                          Initial letter
                        </Typography>
                        <Typography variant="body1">
                          sent:{" "}
                          {dependant.initialLetterSentDate ? (
                            <DateAgo
                              value={dates.parseDate(
                                dependant.initialLetterSentDate
                              )}
                              startFull
                            />
                          ) : (
                            "-"
                          )}
                        </Typography>
                        <Typography variant="body1">
                          recv:{" "}
                          {dependant.initialLetterReceivedDate ? (
                            <DateAgo
                              value={dates.parseDate(
                                dependant.initialLetterReceivedDate
                              )}
                              startFull
                            />
                          ) : (
                            "-"
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="caption">
                          Form of acceptance
                        </Typography>
                        <Typography variant="body1">
                          sent:{" "}
                          {dependant.formOfAcceptanceSentDate ? (
                            <DateAgo
                              value={dates.parseDate(
                                dependant.formOfAcceptanceSentDate
                              )}
                              startFull
                            />
                          ) : (
                            "-"
                          )}
                        </Typography>
                        <Typography variant="body1">
                          recv:{" "}
                          {dependant.formOfAcceptanceReceivedDate ? (
                            <DateAgo
                              value={dates.parseDate(
                                dependant.formOfAcceptanceReceivedDate
                              )}
                              startFull
                            />
                          ) : (
                            "-"
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="caption">
                          {
                            fieldLabels.nestedTypes.dependants.labels
                              .dependantIdentification
                          }
                        </Typography>
                        <Typography variant="body1">
                          {formatters.displayBoolean(
                            dependant.dependantIdentification
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="caption">
                          {
                            fieldLabels.nestedTypes.dependants.labels
                              .statementOfDependencyClaim
                          }
                        </Typography>
                        <Typography variant="body1">
                          {formatters.displayBoolean(
                            dependant.statementOfDependencyClaim
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </ListItem>
              ))}
            </List>
          </>
        )}
        formComponent={EditDependentChecklistForm}
        formName={EDIT_DEPENDENT_CHECKLIST_FORM_NAME}
        onSave={disableEdit ? null : onChange}
        initialValues={{ dependants }}
        formProps={{
          maxWidth: "md",
          fieldLabels,
        }}
      />
    );
  }
}

DependantsChecklistCard.propTypes = {
  className: PropTypes.string,
  fieldLabels: PropTypes.object.isRequired,
  workItem: PropTypes.object.isRequired,
  onChange: PropTypes.func,
};

DependantsChecklistCard.defaultProps = {
  className: undefined,
  onChange: null,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(DependantsChecklistCard);
