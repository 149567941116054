import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { breadcrumbArrowLeft } from "../../util/icons";
import Breadcrumb from "./Breadcrumb";

const useStyles = makeStyles(() => ({
  breadcrumbLeader: {
    display: "flex",
    alignItems: "center",
  },
}));

const BreadcrumbLink = ({ to, label, includeArrow }) => {
  const classes = useStyles();
  const BreadcrumbArrowLeft = breadcrumbArrowLeft();
  const toAddresses = _.isArray(to) ? to : [to];
  const labels = _.isArray(label) ? label : [label];

  return (
    <Breadcrumb>
      {toAddresses.map((toAddr, index) => (
        <Link key={toAddr} className={classes.breadcrumbLeader} to={toAddr}>
          {includeArrow && <BreadcrumbArrowLeft />} {labels[index]}
        </Link>
      ))}
    </Breadcrumb>
  );
};

BreadcrumbLink.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  includeArrow: PropTypes.bool,
};

BreadcrumbLink.defaultProps = {
  includeArrow: false,
};

export default BreadcrumbLink;
