import fileApi from "../services/api/file";
import searchExportsApi from "../services/api/searchExports";
import logger from "./logger";

export const performExport = (
  type,
  searchParameters,
  format,
  accessToken,
  onDone,
  title,
  loadingText,
  successText
) => {
  asyncFilePoll(
    onDone,
    title,
    loadingText,
    successText,
    searchExportsApi.startExport(type, searchParameters, format, accessToken),
    (searchExport) => searchExportsApi.getExport(searchExport.id, accessToken),
    (searchExport) =>
      searchExportsApi.getDownloadUrl(searchExport.id, false, accessToken)
  );
};

export const asyncFilePoll = (
  onDone,
  title,
  loadingText,
  successText,
  requestPromise,
  poll,
  getDownloadUrl
) => {
  const done =
    onDone ||
    (() => {
      // do nothing.
    });
  const childWindow = window.open(
    fileApi.fileDownloadPageUrl({
      title,
      text: loadingText,
      loading: true,
    })
  );

  requestPromise
    .then((requestResponse) => {
      const pollId = window.setInterval(() => {
        poll(requestResponse)
          .then(async (pollResponse) => {
            if (pollResponse.available) {
              logger.info("file is available");
              const downloadUrl = await getDownloadUrl(
                requestResponse,
                pollResponse
              );
              childWindow.location = fileApi.fileDownloadPageUrl({
                title,
                text: successText,
                loading: false,
              });
              window.setTimeout(() => {
                childWindow.location = downloadUrl;
              }, 500);
              window.clearInterval(pollId);
              done();
            } else if (pollResponse.error) {
              logger.info("error occurred", pollResponse.error);
              childWindow.location = fileApi.fileDownloadPageUrl({
                title,
                text: `Error: ${pollResponse.error}`,
                loading: false,
              });
              window.clearInterval(pollId);
              done();
            }
          })
          .catch((e) => {
            logger.error(e);
            window.clearInterval(pollId);
            done();
          });
      }, 10000);
    })
    .catch(() => {
      childWindow.close();
      done();
    });
};

export const asyncPoll = (
  onDone,
  requestPromise,
  poll,
  stopPolling,
  interval
) => {
  const done =
    onDone ||
    (() => {
      // do nothing.
    });

  requestPromise
    .then((requestResponse) => {
      const pollId = window.setInterval(() => {
        poll(requestResponse)
          .then(async (pollResponse) => {
            if (stopPolling(pollResponse)) {
              logger.info("Stopping polling");
              window.clearInterval(pollId);
              done();
            }
          })
          .catch((e) => {
            logger.error(e);
            window.clearInterval(pollId);
            done();
          });
      }, interval);
    })
    .catch(() => {
      done();
    });
};
