import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import React from "react";

interface Props {
  tooltip: string;
  icon: React.FC;
  onClick: () => void;
  loading?: boolean;
  disabled?: boolean;
  iconProps?: Record<string, unknown>;
}

const ActionButton: React.FC<Props> = ({
  tooltip,
  icon: Icon,
  onClick,
  loading = false,
  disabled = false,
  iconProps = {},
  ...rest
}: Props) => (
  <Tooltip title={tooltip} disableFocusListener>
    <div>
      <IconButton
        size="small"
        onClick={onClick}
        disabled={disabled || loading}
        {...rest}
      >
        {loading && <CircularProgress className="spinner" size={24} />}
        {!loading && <Icon {...iconProps} />}
      </IconButton>
    </div>
  </Tooltip>
);

export default ActionButton;
