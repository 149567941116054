import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  addRiskMeasure,
  removeRiskMeasure,
  updateRiskMeasure,
} from "../../actions/risks";
import {
  getActiveRisk,
  getReferenceDataDescription,
  isLoading,
} from "../../reducers";
import AlertDialog from "../common/AlertDialog";
import Avatar from "../common/Avatar.tsx";
import SimpleChips from "../common/SimpleChips";
import ExpandableForm from "../forms/ExpandableForm";
import RiskMeasureForm, {
  RISK_MEASURE_FORM_NAME,
} from "../forms/risk/RiskMeasureForm";
import { useAuth0 } from "@auth0/auth0-react";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  owner: {
    display: "flex",
  },
  within: {
    color: theme.metrics.green,
  },
  atRisk: {
    color: theme.metrics.amber,
    fontStyle: "italic",
  },
  exceeded: {
    color: theme.metrics.red,
    fontStyle: "bold",
  },
  operationalToleranceList: {
    marginRight: "30px",
  },
});

function toleranceStatusClassNames(classes, tolerance) {
  return classNames({
    [classes.within]: tolerance.status === "WITHIN",
    [classes.atRisk]: tolerance.status === "AT_RISK",
    [classes.exceeded]: tolerance.status === "EXCEEDED",
  });
}

const RiskMeasuresCard = ({
  classes,
  className,
  loading,
  risk,
  localRemoveRiskMeasure,
  localUpdateRiskMeasure,
  localAddRiskMeasure,
  getOperationalToleranceStatusDescription,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [measureIdToRemove, setMeasureIdToRemove] = useState(null);
  const [measureToEdit, setMeasureToEdit] = useState(null);
  const [formExpanded, setFormExpanded] = useState(false);

  const handleSubmitRemoveDialog = async () => {
    const accessToken = await getAccessTokenSilently();
    // remove new child, then refresh parent
    localRemoveRiskMeasure(risk.id, measureIdToRemove, accessToken);
    setMeasureIdToRemove(null);
  };

  const handleEdit = async (values) => {
    const accessToken = await getAccessTokenSilently();
    localUpdateRiskMeasure(risk.id, measureToEdit.id, values, accessToken).then(
      () => setMeasureToEdit(null)
    );
  };

  const handleAdd = async (values) => {
    const accessToken = await getAccessTokenSilently();
    localAddRiskMeasure(risk.id, values, accessToken).then(() =>
      setFormExpanded(false)
    );
  };

  const mergedClassName = classNames(classes.root, className);

  const measures = risk ? risk.measures : [];
  const formOpen = formExpanded || !!measureToEdit;

  const readOnlyMeasureView = (measure) => (
    <ListItem
      key={measure.id}
      divider
      button={!formOpen}
      onClick={!formOpen ? () => setMeasureToEdit(measure) : null}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SimpleChips
            values={measure.items.map((tag) => tag.text)}
            blankLabel="-"
          />
        </Grid>
        <Grid item xs={12}>
          <List dense className={classes.operationalToleranceList}>
            {measure.operationalTolerances.map((tolerance) => (
              <ListItem key={tolerance.id} divider>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      data-cy={tolerance.description}
                    >
                      {tolerance.description}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justifyContent="space-between" spacing={1}>
                      <Grid item className={classes.owner}>
                        <Avatar
                          name={tolerance.owner ? tolerance.owner.name : "?"}
                          email={tolerance.owner ? tolerance.owner.email : "?"}
                          size={24}
                          round
                        />
                        <Typography
                          variant="body2"
                          className={classes.ownerName}
                          data-cy={
                            tolerance.owner ? tolerance.owner.name : "No Owner"
                          }
                        >
                          {tolerance.owner ? tolerance.owner.name : "No Owner"}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          align="right"
                          className={toleranceStatusClassNames(
                            classes,
                            tolerance
                          )}
                          data-cy={getOperationalToleranceStatusDescription(
                            tolerance.status
                          )}
                        >
                          {getOperationalToleranceStatusDescription(
                            tolerance.status
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="caption"
                      align="right"
                      data-cy={tolerance.comment || "-"}
                    >
                      {tolerance.comment || "-"}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
      <ListItemSecondaryAction>
        <Tooltip title="Remove measure" disableFocusListener>
          <IconButton
            aria-label="Delete"
            onClick={() => setMeasureIdToRemove(measure.id)}
            data-cy="delete"
          >
            <Icon data-cy="delete">delete</Icon>
          </IconButton>
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
  );

  const editMeasureView = () => (
    <ListItem key={measureToEdit.id} divider>
      <ExpandableForm
        title="Edit measure"
        submitButtonText="Save"
        maxWidth="md"
        formComponent={RiskMeasureForm}
        formName={RISK_MEASURE_FORM_NAME}
        open
        onCancel={() => setMeasureToEdit(null)}
        onSubmit={(values) => handleEdit(values)}
        initialValues={measureToEdit}
        data-cy={RiskMeasureForm}
      />
    </ListItem>
  );

  return (
    <Card className={mergedClassName} elevation={0}>
      <AlertDialog
        title="Remove Measure"
        body="Are you sure you want to remove this measure?"
        submitButtonText="Remove"
        open={!!measureIdToRemove}
        onCancel={() => setMeasureIdToRemove(null)}
        onSubmit={(values) => handleSubmitRemoveDialog(values)}
        data-cy="remove"
      />
      <CardHeader
        title="Measures"
        data-cy="measures"
        action={
          <Tooltip title="Add measure" disableFocusListener>
            <div>
              <IconButton
                onClick={() => setFormExpanded(true)}
                disabled={formOpen}
              >
                <AddIcon data-cy="add" />
              </IconButton>
            </div>
          </Tooltip>
        }
      />
      <ExpandableForm
        title="Add measure"
        submitButtonText="Create"
        maxWidth="md"
        formComponent={RiskMeasureForm}
        formName={RISK_MEASURE_FORM_NAME}
        open={formExpanded}
        onCancel={() => setFormExpanded(false)}
        onSubmit={(values) => handleAdd(values)}
        data-cy={RiskMeasureForm}
      />
      {measures && measures.length > 0 && (
        <List component="nav" dense>
          {measures.map((measure) => {
            if (measureToEdit && measureToEdit.id === measure.id) {
              return editMeasureView();
            }
            return readOnlyMeasureView(measure);
          })}
        </List>
      )}
      {measures && measures.length === 0 && !loading && (
        <CardContent>
          <em data-cy="noMeasures">No measures yet</em>
        </CardContent>
      )}
    </Card>
  );
};

RiskMeasuresCard.propTypes = {
  loading: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  risk: PropTypes.object.isRequired,
  getOperationalToleranceStatusDescription: PropTypes.func.isRequired,
  localAddRiskMeasure: PropTypes.func.isRequired,
  localUpdateRiskMeasure: PropTypes.func.isRequired,
  localRemoveRiskMeasure: PropTypes.func.isRequired,
  className: PropTypes.string,
};

RiskMeasuresCard.defaultProps = {
  className: undefined,
};

const mapStateToProps = (state) => ({
  loading: isLoading(state),
  risk: getActiveRisk(state),
  getOperationalToleranceStatusDescription: (id) =>
    getReferenceDataDescription(state, "OperationalToleranceStatus", id, "-"),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    localAddRiskMeasure: addRiskMeasure,
    localUpdateRiskMeasure: updateRiskMeasure,
    localRemoveRiskMeasure: removeRiskMeasure,
  })
)(RiskMeasuresCard);
