import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { organisationIcon, productIcon } from "../../util/icons";

const OrganisationIcon = organisationIcon();
const ProductIcon = productIcon();

const styles = () => ({
  noWrapText: {
    width: "100%",
    display: "inline-block",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
  },
});

class WorkItemSubmitter extends Component {
  render() {
    const { classes, workItem } = this.props;

    const { createdBy, entityRelationship } = workItem;
    const createdByOrg =
      createdBy && createdBy.organisation ? createdBy.organisation.name : null;
    const submitterText = createdBy ? createdBy.name : "-";
    const products = entityRelationship
      ? entityRelationship.financialProducts
      : [];
    const sargonEntities = entityRelationship
      ? entityRelationship.organisations
          .map((linkedOrg) => linkedOrg.organisation)
          .filter((o) => o.type === "INTERNAL")
      : [];
    const showProducts = products && products.length > 0;
    const productsExtra =
      showProducts && products.length > 1
        ? ` (and ${products.length - 1} more)`
        : "";
    const showSargonEntities =
      !showProducts && sargonEntities && sargonEntities.length > 0;
    const sargonEntitiesExtra =
      showSargonEntities && sargonEntities.length > 1
        ? ` (and ${sargonEntities.length - 1} more)`
        : "";

    return (
      <>
        <span className={classes.noWrapText}>{submitterText}</span>
        {createdByOrg && (
          <Typography
            display="block"
            title={createdByOrg}
            variant="caption"
            style={{ color: "#778088" }}
          >
            <OrganisationIcon fontSize="inherit" /> {createdByOrg}
          </Typography>
        )}
        {showProducts && (
          <Typography
            display="block"
            variant="caption"
            style={{ color: "#778088" }}
          >
            <ProductIcon fontSize="inherit" /> {products[0].name}
            {productsExtra}
          </Typography>
        )}
        {showSargonEntities && (
          <Typography
            display="block"
            variant="caption"
            style={{ color: "#778088" }}
          >
            <OrganisationIcon fontSize="inherit" /> {sargonEntities[0].name}
            {sargonEntitiesExtra}
          </Typography>
        )}
      </>
    );
  }
}

WorkItemSubmitter.propTypes = {
  classes: PropTypes.object.isRequired,
  workItem: PropTypes.object.isRequired,
};

export default withStyles(styles)(WorkItemSubmitter);
