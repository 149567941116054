import users from "../services/api/users";

export const SET_USER = "SET_USER";
export const REQUEST_LOGGED_IN_USER = "REQUEST_LOGGED_IN_USER";

export const setUser = (user) => ({
  type: SET_USER,
  user,
});

export const fetchUser = (accessToken) => (dispatch) => {
  dispatch({ type: `${REQUEST_LOGGED_IN_USER}_INPROGRESS` });

  return users
    .me(accessToken)
    .then((user) => {
      dispatch({ type: `${REQUEST_LOGGED_IN_USER}_SUCCESS` });
      dispatch(setUser(user));
      window.Appcues.identify(user.id, {
        name: user.name,
        organisation: user?.organisation?.friendlyId || null,
      });
      return user;
    })
    .catch((error) => {
      dispatch({
        type: `${REQUEST_LOGGED_IN_USER}_FAILURE`,
        error,
      });
    });
};
