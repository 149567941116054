import { requestJSON } from "./http";

const decorateSearchParameters = (filter) =>
  filter ? { __type: "Organisation", ...filter } : { __type: "Organisation" };

const create = (org, accessToken) =>
  requestJSON({
    path: "/organisations",
    method: "POST",
    data: org,
    accessToken,
  });

// with pagination
const search = (searchParams, abortController, accessToken) =>
  requestJSON({
    path: "/organisations/search",
    method: "POST",
    data: decorateSearchParameters(searchParams),
    abortController,
    accessToken,
  });

const getById = (id, accessToken) =>
  requestJSON({
    path: `/organisations/${id}`,
    accessToken,
  });

const checkFriendlyIdAvailability = (id, friendlyId, accessToken) =>
  id
    ? requestJSON({
        path: `/organisations/${id}/friendly-available/${friendlyId}`,
        accessToken,
      })
    : requestJSON({
        path: `/organisations/friendly-available/${friendlyId}`,
        accessToken,
      });

const patch = (id, changes, description, accessToken) =>
  requestJSON({
    path: `/organisations/${id}`,
    method: "PATCH",
    data: {
      fields: Object.keys(changes),
      changes,
      description,
    },
    accessToken,
  });

export default {
  create,
  search,
  getById,
  patch,
  checkFriendlyIdAvailability,
};
