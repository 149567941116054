import { useAuth0 } from "@auth0/auth0-react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getLabels } from "../../reducers";
import controlsApi from "../../services/api/controls";
import { openInNewIcon } from "../../util/icons";
import GridListing from "../common/GridListing";
import SimpleChip from "../common/SimpleChip";
import { includeTenantParam } from "@certane/arcadia-web-components";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  content: {
    overflow: "auto",
  },
  controlTypeChip: {
    backgroundColor: theme.palette.grey[300],
    padding: `${theme.spacing(1) / 4}px ${theme.spacing(1)}px`,
    borderRadius: "16px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: "100%",
    whiteSpace: "nowrap",
    marginRight: theme.spacing(1) / 2,
  },
  nowrap: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  createNewLink: {
    cursor: "pointer",
    color: theme.palette.swatch.link,
  },
  inlineIcon: {
    verticalAlign: "middle",
  },
  registerLabel: {
    fontSize: "smaller",
  },
  listItem: {
    width: "100%",
    overflow: "hidden",
  },
  listIcon: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
}));

const ControlRiskLinksCard = ({ control, riskFieldLabels }) => {
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();
  const [associatedRiskRefs, setAssociatedRiskRefs] = useState([]);

  const OpenInNewIcon = openInNewIcon();

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      const riskRefs = await controlsApi.associatedRisks(
        control.id,
        accessToken
      );
      setAssociatedRiskRefs(riskRefs);
    })();
  }, []);

  return (
    <Card className={classes.root} elevation={0}>
      <CardHeader title="Risks" />
      <CardContent className={classes.content}>
        <GridListing
          data-cy="table"
          sortedData={associatedRiskRefs}
          dense={false}
          sortBy={{
            field: "created",
            direction: "asc",
          }}
          loading={false}
          updateSort={() => {
            // do nothing.
          }}
          action={(riskRef) => (
            <IconButton
              disabled={!riskRef.accessible}
              onClick={() =>
                window.open(
                  includeTenantParam(`/risk/risks/${riskRef.id}`),
                  "_blank"
                )
              }
            >
              <OpenInNewIcon />
            </IconButton>
          )}
          noItemsText="There are no risks associated with this control"
          columns={[
            {
              label: riskFieldLabels.labels.title,
              name: "title",
              size: 4,
              sortable: false,
              render: (riskRef) => <span>{riskRef.title}</span>,
            },
            {
              label: riskFieldLabels.labels.friendlyId,
              name: "friendlyId",
              size: 2,
              sortable: false,
              render: (riskRef) => <span>{riskRef.friendlyId}</span>,
            },
            {
              label: riskFieldLabels.labels.riskRegisters,
              name: "riskRegisters",
              size: 4,
              sortable: false,
              render: (riskRef) =>
                riskRef.riskRegisters.map((tag) => (
                  <SimpleChip key={tag.id} label={tag.text} />
                )),
            },
          ]}
        />
      </CardContent>
    </Card>
  );
};

ControlRiskLinksCard.propTypes = {
  control: PropTypes.object.isRequired,
  riskFieldLabels: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  riskFieldLabels: getLabels(state).Risk,
});

export default connect(mapStateToProps)(ControlRiskLinksCard);
