import { useAuth0 } from "@auth0/auth0-react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Theme } from "@material-ui/core/styles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import CheckCircle from "@material-ui/icons/CheckCircle";
import { History } from "history";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ActionHeading from "../../../components/common/ActionHeading";
import BreadcrumbLink from "../../../components/common/BreadcrumbLink";
import Container from "../../../components/common/Container";
import HeaderBar from "../../../components/common/HeaderBar";
import PageHeading from "../../../components/common/PageHeading";
import FundForm from "../../../components/forms/fund/FundForm";
import { FieldLabels } from "../../../model/FieldLabels";
import { Fund } from "../../../model/fund";
import { getLabels } from "../../../reducers";
import fundApi from "../../../services/api/funds";
import { fundIcon } from "../../../util/icons";

const useStyles = makeStyles((theme: Theme) => ({
  successPanel: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    width: "600px",
    textAlign: "center",
  },
  refNumber: {
    marginBottom: theme.spacing(4),
  },
  button: {
    marginLeft: "10px",
    "&:first-child": {
      marginLeft: 0,
    },
  },
  successIcon: {
    fontSize: "80px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  container: {
    marginTop: theme.spacing(2),
  },
}));

interface Props {
  history: History;
  fieldLabels: FieldLabels;
}

const NewFundPage: React.FC<Props> = ({ history, fieldLabels }: Props) => {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const [fund, setFund] = useState<Fund | null>(null);

  const createFund = async (values: Fund) => {
    const accessToken = await getAccessTokenSilently();
    const f = await fundApi.create(values, accessToken);
    setTimeout(() => setFund(f), 100);
    return f;
  };

  return (
    <div>
      <HeaderBar narrow>
        <BreadcrumbLink to="/admin/funds" label="Funds" includeArrow />
        <ActionHeading
          heading={<PageHeading icon={fundIcon()} heading="Create new fund" />}
          actions={[]}
        />
      </HeaderBar>
      <Container narrow className={classes.container}>
        {fund && (
          <Grid
            container
            alignItems="center"
            direction="row"
            justifyContent="center"
          >
            <Paper elevation={0} className={classes.successPanel}>
              <Typography variant="h4" data-cy="controlCreated">
                The fund has been created.
              </Typography>
              <CheckCircle className={classes.successIcon} />
              <div>
                <Button
                  className={classes.button}
                  component={Link}
                  to={`/admin/funds/${fund.id}`}
                  variant="contained"
                  color="primary"
                  size="large"
                  data-cy="viewControl"
                >
                  View fund
                </Button>
                <Button
                  className={classes.button}
                  component={Link}
                  to="/admin/funds"
                  variant="contained"
                  color="default"
                  size="large"
                  data-cy="returnToControls"
                >
                  Return to funds
                </Button>
              </div>
            </Paper>
          </Grid>
        )}
        {!fund && (
          <Card elevation={0}>
            <CardHeader title="Details" />
            <CardContent>
              <FundForm
                onSubmit={createFund}
                onCancel={() => history.push("/admin/funds")}
                fieldLabels={fieldLabels}
              />
            </CardContent>
          </Card>
        )}
      </Container>
    </div>
  );
};

const mapStateToProps = (state: Map<string, unknown>) => ({
  fieldLabels: getLabels(state).Fund,
});

export default connect(mapStateToProps)(NewFundPage);
