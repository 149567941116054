import InputAdornment from "@material-ui/core/InputAdornment";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { numericality, required } from "redux-form-validators";
import {
  getFilteredReferenceDataType,
  getLabels,
  getReferenceDataType,
} from "../../../reducers";
import { scrollToFirstInvalidInput } from "../../../util/formHelpers";
import FormError from "../../common/FormError";
import { getCategoryMenuItems, getRefDataMenuItems } from "../menuItems";
import WrappedSelect from "../wrapper/WrappedSelect";
import WrappedTextField from "../wrapper/WrappedTextField";

const styles = {};

const CreateSlaForm = ({
  error,
  workItemTypes,
  selectedWorkItemType,
  workItemSlaDayTypes,
  change,
  fieldLabels,
}) => {
  useEffect(() => {
    change("workItemCategory", null);
  }, [selectedWorkItemType]);

  const getCategoryOptions = () => {
    if (selectedWorkItemType) {
      const { categories } =
        workItemTypes.values[selectedWorkItemType].props.definition;
      return getCategoryMenuItems(categories);
    }
    return [];
  };

  return (
    <form autoComplete="off">
      {error && <FormError errorMessage={error} />}
      <Field
        component={WrappedSelect}
        name="workItemType"
        label={fieldLabels.labels.workItemType}
        validate={required({
          msg: `${fieldLabels.labels.workItemType} is required`,
        })}
        required
        fullWidth
      >
        {getRefDataMenuItems(workItemTypes)}
      </Field>
      <Field
        component={WrappedSelect}
        disabled={!selectedWorkItemType}
        name="workItemCategory"
        label={fieldLabels.labels.workItemCategory}
        fullWidth
      >
        {getCategoryOptions()}
      </Field>
      <Field
        name="maxDaysToProcess"
        component={WrappedTextField}
        label={fieldLabels.labels.maxDaysToProcess}
        fullWidth
        InputProps={{
          endAdornment: <InputAdornment position="end">days</InputAdornment>,
        }}
        validate={numericality({
          ">": 0,
          int: true,
          allowBlank: false,
        })}
      />
      <Field
        name="reminderLeadTime"
        component={WrappedTextField}
        label={fieldLabels.labels.reminderLeadTime}
        fullWidth
        InputProps={{
          endAdornment: <InputAdornment position="end">days</InputAdornment>,
        }}
        validate={numericality({
          ">": 0,
          int: true,
          allowBlank: false,
        })}
      />
      <Field
        component={WrappedSelect}
        name="dayCalculationType"
        label={fieldLabels.labels.dayCalculationType}
        fullWidth
      >
        {getRefDataMenuItems(workItemSlaDayTypes)}
      </Field>
      <Field
        name="customReferenceDateField"
        component={WrappedTextField}
        label={fieldLabels.labels.customReferenceDateField}
        fullWidth
      />
    </form>
  );
};

CreateSlaForm.propTypes = {
  error: PropTypes.string,
  change: PropTypes.func.isRequired,
  workItemTypes: PropTypes.object.isRequired,
  workItemSlaDayTypes: PropTypes.object.isRequired,
  selectedWorkItemType: PropTypes.string,
  fieldLabels: PropTypes.object.isRequired,
};

CreateSlaForm.defaultProps = {
  error: null,
  selectedWorkItemType: null,
};

export const CREATE_SLA_FORM = "CREATE_SLA_FORM";
const reduxFormSelector = formValueSelector(CREATE_SLA_FORM);

const mapStateToProps = (state) => ({
  workItemTypes: getFilteredReferenceDataType(
    state,
    "WorkItemType",
    (type) => type.props.definition.module !== "UNUSED"
  ),
  selectedWorkItemType: reduxFormSelector(state, "workItemType"),
  workItemSlaDayTypes: getReferenceDataType(state, "WorkItemSlaDayType"),
  fieldLabels: getLabels(state).WorkItemSlaSetting,
});

const form = reduxForm({
  form: CREATE_SLA_FORM,
  onSubmitFail: (errors) => scrollToFirstInvalidInput(errors),
});
const reduxData = connect(mapStateToProps);
export default compose(form, reduxData)(withStyles(styles)(CreateSlaForm));
