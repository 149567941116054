import { combineReducers } from "redux";
import {
  TAG_DELETE,
  TAG_GET_BY_ID,
  TAG_GET_USAGES,
  TAG_LIST,
  TAG_PATCH,
} from "../actions/tags";
import { publishActionToast } from "../services/toasts";

const successToasts = {
  [TAG_DELETE]: "Tag deleted successfully",
  [TAG_PATCH]: "Tag updated successfully",
};

const byId = (state = {}, action) => {
  publishActionToast(action, "TAG_", successToasts);

  switch (action.type) {
    case `${TAG_GET_BY_ID}_SUCCESS`:
    case `${TAG_LIST}_SUCCESS`:
    case `${TAG_PATCH}_SUCCESS`:
      return {
        ...state,
        ...action.response.entities.tag,
      };

    // Remove a tag
    case `${TAG_DELETE}_SUCCESS`:
      const idToRemove = action.response.id;
      return {
        ...state,
        [idToRemove]: undefined,
      };

    default:
      break;
  }

  return state;
};

const defaultActive = {
  activeIds: [],
  activeTag: null,
  usagesLoading: false,
  activeUsages: [],
};

const active = (state = defaultActive, action) => {
  switch (action.type) {
    case `${TAG_LIST}_INPROGRESS`:
      return {
        ...defaultActive,
      };

    case `${TAG_LIST}_SUCCESS`:
      return {
        ...state,
        activeIds: action.response.result,
      };

    case `${TAG_GET_BY_ID}_INPROGRESS`:
      return {
        ...state,
        activeTag: null,
      };

    case `${TAG_GET_BY_ID}_SUCCESS`:
      return {
        ...state,
        activeTag: action.response.result,
        activeIds:
          state.activeIds.indexOf(action.response.result) !== -1
            ? state.activeIds
            : [...state.activeIds, action.response.result],
      };

    case `${TAG_DELETE}_SUCCESS`:
      const idToRemove = action.response.id;
      return {
        ...state,
        activeTag: state.activeTag === idToRemove ? null : state.activeTag,
        activeIds: [...state.activeIds.filter((id) => id !== idToRemove)],
        activeUsages: state.activeTag === idToRemove ? [] : state.activeUsages,
      };

    case `${TAG_GET_USAGES}_INPROGRESS`:
      return {
        ...state,
        activeUsages: [],
        usagesLoading: true,
      };

    case `${TAG_GET_USAGES}_SUCCESS`:
      return {
        ...state,
        activeUsages: action.response,
        usagesLoading: false,
      };

    case `${TAG_GET_USAGES}_FAILURE`:
      return {
        ...state,
        activeUsages: [],
        usagesLoading: false,
      };

    default:
      return state;
  }
};

export default combineReducers({
  byId,
  active,
});

export const getActiveTag = (state) =>
  state.active.activeTag ? state.byId[state.active.activeTag] : null;
export const getTags = (state) =>
  state.active.activeIds.map((id) => state.byId[id]);
export const getTagUsages = (state) => state.active.activeUsages;
export const isUsagesLoading = (state) => state.active.usagesLoading;
