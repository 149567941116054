import { makeStyles } from "@material-ui/core/styles";
import StarRateIcon from "@material-ui/icons/StarRate";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import ActionButton from "../../../components/common/ActionButton";
import ActionHeading from "../../../components/common/ActionHeading";
import Container from "../../../components/common/Container";
import HeaderBar from "../../../components/common/HeaderBar";
import PageHeading from "../../../components/common/PageHeading";
import MentionFilters from "../../../components/sentinel/MentionFilters";
import MentionListing from "../../../components/sentinel/MentionListing";
import { getLoggedInUser } from "../../../reducers";
import { clearIcon } from "../../../util/icons";
import usePersistedState from "../../../util/persistedState";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  contents: {
    marginBottom: "75px",
  },
}));

const defaultFilter = {
  statuses: [],
};

const Index = ({ history, loggedInUser }) => {
  const classes = useStyles();
  const [sortBy, setSortBy] = usePersistedState(
    `mentions-listing-sort-${loggedInUser.email}`,
    {
      field: "publishedAt",
      direction: "desc",
    }
  );
  const [filter, setFilter] = usePersistedState(
    `mentions-filter-${loggedInUser.email}`,
    defaultFilter,
    ["textSearch"]
  );

  const clearFilter = () => setFilter(defaultFilter);

  const updateFilter = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  return (
    <div className={classes.root}>
      <HeaderBar>
        <ActionHeading
          heading={<PageHeading icon={StarRateIcon} heading="Mentions" />}
          actions={[
            <ActionButton
              key="clearFilter"
              tooltip="Clear filters"
              icon={clearIcon()}
              onClick={clearFilter}
              data-cy="clear-filters-button"
            />,
          ]}
        />
      </HeaderBar>
      <Container className={classes.contents}>
        <MentionFilters filter={filter} updateFilter={updateFilter} />
        <MentionListing
          filter={filter}
          clearFilter={clearFilter}
          sortBy={sortBy}
          updateSort={setSortBy}
          history={history}
        />
      </Container>
    </div>
  );
};

Index.propTypes = {
  history: PropTypes.object.isRequired,
  loggedInUser: PropTypes.object,
};

Index.defaultProps = {
  loggedInUser: null,
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
});

export default connect(mapStateToProps)(Index);
