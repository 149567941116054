import { withStyles } from "@material-ui/core/styles";
import { KeyboardDatePicker } from "@material-ui/pickers";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import DateAgo from "./common/DateAgo";
import LabelStateButton from "./common/LabelStateButton";

const styles = (theme) => ({
  button: {
    fontSize: "inherit",
    padding: 0,
    minWidth: 0,
  },
  buttonNoValue: {
    fontStyle: "italic",
    color: theme.palette.text.hint,
  },
  disabled: {
    color: "inherit",
  },
});

const DateButtonPicker = ({
  classes,
  className,
  dateValue,
  readonly,
  labelComponent,
  onDateChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleDateChange = (date) => {
    if (date !== dateValue) {
      onDateChange(date);
    }
  };

  let label = labelComponent;

  if (!label && dateValue) {
    label = <DateAgo value={dateValue} stayFull={readonly || !onDateChange} />;
  }

  const button = onDateChange ? (
    <LabelStateButton
      className={className}
      label={label}
      data-cy={label}
      onClick={() => setIsOpen(true)}
      aria-haspopup="true"
      readonly={readonly}
    />
  ) : (
    <div
      className={classNames({
        [classes.button]: true,
        [classes.buttonNoValue]: !dateValue,
        [className]: !!className,
      })}
      data-cy={label}
    >
      {label}
    </div>
  );

  return (
    <div>
      <KeyboardDatePicker
        format="DD/MM/YYYY"
        value={dateValue || new Date()}
        onChange={handleDateChange}
        fullWidth
        autoOk
        margin="normal"
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        TextFieldComponent={() => button}
        data-cy="datePicker"
      />
    </div>
  );
};

DateButtonPicker.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  dateValue: PropTypes.instanceOf(Date),
  onDateChange: PropTypes.func,
  readonly: PropTypes.bool,
  labelComponent: PropTypes.element,
};

DateButtonPicker.defaultProps = {
  className: undefined,
  readonly: false,
  dateValue: null,
  labelComponent: null,
  onDateChange: null,
};

export default withStyles(styles)(DateButtonPicker);
