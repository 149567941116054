import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Divider from "@material-ui/core/Divider";
import PropTypes from "prop-types";
import { compose } from "redux";
import { Field, FieldArray, formValueSelector, reduxForm } from "redux-form";
import WrappedRadioGroup from "../forms/wrapper/WrappedRadioGroup";

const styles = (theme) => ({
  info: {
    marginBottom: 0,
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
});

class ConfirmFileNamesForm extends Component {
  getFileNameOption = (index) => this.props.fileNameOptions[index];

  getRadioOptions = (filename) => [
    {
      label: `Yes, this is a new version of '${filename}'`,
      value: true,
    },
    {
      label: "No, this is a new file",
      value: false,
    },
  ];

  renderMapping = ({ fields, classes }) =>
    fields.map((fieldName, index) => {
      const { fileName, rootFileName } = this.getFileNameOption(index);

      return (
        <div key={`${fieldName}`}>
          <p className={classes.info}>
            {`'${fileName}' looks like it could be a new version of '${rootFileName}'`}
          </p>
          <Field
            name={`${fieldName}.useRootName`}
            component={WrappedRadioGroup}
            options={this.getRadioOptions(rootFileName)}
            required
          />
          {index + 1 < fields.length && <Divider className={classes.divider} />}
        </div>
      );
    });

  render() {
    const { classes } = this.props;

    return (
      <form autoComplete="off">
        <FieldArray
          name="fileNameOptions"
          component={this.renderMapping}
          props={{ classes }}
        />
      </form>
    );
  }
}

ConfirmFileNamesForm.propTypes = {
  fileNameOptions: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export const CONFIRM_FILE_NAMES_FORM_NAME = "CONFIRM_FILE_NAMES_FORM_NAME";
const reduxFormSelector = formValueSelector(CONFIRM_FILE_NAMES_FORM_NAME);

const mapStateToProps = (state) => ({
  fileNameOptions: reduxFormSelector(state, "fileNameOptions"),
});

const form = reduxForm({ form: CONFIRM_FILE_NAMES_FORM_NAME });
const reduxData = connect(mapStateToProps, {});

export default compose(
  form,
  reduxData
)(withStyles(styles)(ConfirmFileNamesForm));
