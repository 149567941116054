import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  deleteDigitalAsset,
  getDigitalAssetById,
  patchDigitalAsset,
} from "../../../actions/digitalAssets";
import { searchWebsiteDifferences } from "../../../actions/websiteDifferences";
import {
  createWebsiteSnapshot,
  listWebsiteSnapshots,
} from "../../../actions/websiteSnapshots";
import ActionButton from "../../../components/common/ActionButton";
import ActionHeading from "../../../components/common/ActionHeading";
import AlertDialog from "../../../components/common/AlertDialog";
import BreadcrumbLink from "../../../components/common/BreadcrumbLink";
import ChoiceChips from "../../../components/common/ChoiceChips";
import Container from "../../../components/common/Container";
import HeaderBar from "../../../components/common/HeaderBar";
import PageHeading from "../../../components/common/PageHeading";
import WebsiteDifferencesListing from "../../../components/sentinel/WebsiteDifferencesListing";
import WebsiteSnapshotListing from "../../../components/sentinel/WebsiteSnapshotListing";
import { getActiveDigitalAsset } from "../../../reducers";
import {
  deleteIcon,
  digitalAssetIcon,
  toggleOffIcon,
  toggleOnIcon,
} from "../../../util/icons";
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  card: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  container: {
    marginTop: theme.spacing(2),
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  gridItem: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "auto",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      "&:first-child": {
        paddingLeft: 0,
      },
      "&:last-child": {
        marginLeft: "auto",
      },
    },
  },
}));

const RECENT_SNAPSHOT_LIMIT = 10;
const RECENT_DIFF_LIMIT = 10;

const DigitalAssetId = ({
  match: {
    params: { digitalAssetId },
  },
  history,
  digitalAsset,
  localSearchWebsiteDifferences,
  localGetDigitalAssetById,
  localListWebsiteSnapshots,
  localCreateWebsiteSnapshot,
  localDeleteDigitalAsset,
  localPatchDigitalAsset,
}) => {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const [showOnlyDifferent, setShowOnlyDifferent] = useState(null);
  const [deleteIntention, setDeleteIntention] = useState(false);
  const [filter, setFilter] = useState({
    digitalAssetIds: [digitalAssetId],
    different: showOnlyDifferent ? true : null,
    limit: RECENT_DIFF_LIMIT,
    orderByField: "-created",
  });

  const refreshData = (accessToken) => {
    localGetDigitalAssetById(digitalAssetId, accessToken);
    localSearchWebsiteDifferences(filter, accessToken);
    localListWebsiteSnapshots(
      digitalAssetId,
      RECENT_SNAPSHOT_LIMIT,
      accessToken
    );
  };

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      refreshData(accessToken);
    })();
  }, [getAccessTokenSilently, digitalAssetId, filter]);

  useEffect(() => {
    setFilter({
      ...filter,
      different: showOnlyDifferent ? true : null,
    });
  }, [showOnlyDifferent]);

  const handleDeleteAsset = async () => {
    const accessToken = await getAccessTokenSilently();
    localDeleteDigitalAsset(digitalAssetId, accessToken).then(() =>
      history.push("/sentinel/digital-assets")
    );
  };

  const handleUpdate = async (changes) => {
    const accessToken = await getAccessTokenSilently();
    localPatchDigitalAsset(digitalAssetId, changes, "Updated", accessToken);
  };

  const differenceOptions = [
    {
      label: "Show only detected differences",
      value: true,
    },
  ];

  const addTrigger = async () => {
    const accessToken = await getAccessTokenSilently();
    return localCreateWebsiteSnapshot(digitalAsset.id, accessToken);
  };

  return (
    <div className={classes.root}>
      <AlertDialog
        title="Delete digital asset?"
        body="Are you sure you want to delete this digital asset?"
        submitButtonText="Delete"
        open={deleteIntention}
        onCancel={() => setDeleteIntention(false)}
        onSubmit={handleDeleteAsset}
      />
      <HeaderBar>
        <BreadcrumbLink to="/sentinel" label="Digital Assets" includeArrow />
        <ActionHeading
          heading={
            <PageHeading
              icon={digitalAssetIcon()}
              heading={digitalAsset ? digitalAsset.url : ""}
            />
          }
          actions={[
            <ActionButton
              key="toggleActive"
              tooltip={digitalAsset?.active ? "Active" : "Inactive"}
              icon={digitalAsset?.active ? toggleOnIcon() : toggleOffIcon()}
              iconProps={digitalAsset?.active ? { color: "primary" } : {}}
              onClick={() => handleUpdate({ active: !digitalAsset.active })}
            />,
            <ActionButton
              key="deleteAsset"
              tooltip="Delete"
              icon={deleteIcon()}
              onClick={() => setDeleteIntention(true)}
            />,
          ]}
        />
      </HeaderBar>
      <Container className={classes.container}>
        <Card className={classes.card} elevation={0}>
          <CardHeader
            avatar={
              <Avatar className={classes.avatar}>
                <Icon>compare_arrows</Icon>
              </Avatar>
            }
            title="Recent Differences"
          />
          <CardContent>
            <ChoiceChips
              value={showOnlyDifferent}
              options={differenceOptions}
              onChange={setShowOnlyDifferent}
            />
            <WebsiteDifferencesListing
              filter={filter}
              clearFilter={() => setShowOnlyDifferent(null)}
              sortBy={{
                field: "created",
                direction: "desc",
              }}
              updateSort={() => {
                // do nothing.
              }}
              history={history}
            />
          </CardContent>
        </Card>
        <Card className={classes.card} elevation={0}>
          <CardHeader
            avatar={
              <Avatar className={classes.avatar}>
                <Icon>camera_alt</Icon>
              </Avatar>
            }
            title="Recent Snapshots"
            action={
              <Tooltip title="Trigger" disableFocusListener>
                <IconButton onClick={addTrigger}>
                  <Icon>add</Icon>
                </IconButton>
              </Tooltip>
            }
          />
          <CardContent>
            <WebsiteSnapshotListing />
          </CardContent>
        </Card>
      </Container>
    </div>
  );
};

DigitalAssetId.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  digitalAsset: PropTypes.object,
  localSearchWebsiteDifferences: PropTypes.func.isRequired,
  localListWebsiteSnapshots: PropTypes.func.isRequired,
  localGetDigitalAssetById: PropTypes.func.isRequired,
  localCreateWebsiteSnapshot: PropTypes.func.isRequired,
  localDeleteDigitalAsset: PropTypes.func.isRequired,
  localPatchDigitalAsset: PropTypes.func.isRequired,
};

DigitalAssetId.defaultProps = {
  digitalAsset: null,
};

const mapStateToProps = (state) => ({
  digitalAsset: getActiveDigitalAsset(state),
});

export default connect(mapStateToProps, {
  localSearchWebsiteDifferences: searchWebsiteDifferences,
  localListWebsiteSnapshots: listWebsiteSnapshots,
  localGetDigitalAssetById: getDigitalAssetById,
  localCreateWebsiteSnapshot: createWebsiteSnapshot,
  localDeleteDigitalAsset: deleteDigitalAsset,
  localPatchDigitalAsset: patchDigitalAsset,
})(DigitalAssetId);
