import { Auth0Provider } from "@auth0/auth0-react";
import { TenantProvider } from "@certane/arcadia-web-components";
import MomentUtils from "@date-io/moment";
import Reboot from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ConnectedRouter } from "connected-react-router";
import React from "react";
import { initialize, pageview } from "react-ga";
import { hot } from "react-hot-loader";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";
import Routes from "./routes/Routes";
import configureStore, { history } from "./store";
import theme from "./theme";
import { isDev, isLocal, isProd, isUat } from "./util/env";

if (!isLocal) {
  initialize(isProd ? "UA-125411172-3" : "UA-125411172-2", {
    debug: !isProd,
  });
  pageview(window.location.pathname + window.location.search);
}

window.Appcues.page();

history.listen((location) => {
  window.Appcues.page();

  if (!isLocal) {
    pageview(location.pathname + location.search);
  }
});

let domain;
let clientId;
let redirectUri;
if (isDev) {
  domain = "temp-diversa.au.auth0.com";
  clientId = "4q3RDCag8G2K56zCHgiygHSVA8vf2QBr";
  redirectUri = "https://dev.arcadia.diversa.com.au";
} else if (isUat) {
  domain = "temp-diversa.au.auth0.com";
  clientId = "4q3RDCag8G2K56zCHgiygHSVA8vf2QBr";
  redirectUri = "https://uat.arcadia.diversa.com.au";
} else if (isProd) {
  domain = "temp-diversa.au.auth0.com";
  clientId = "4q3RDCag8G2K56zCHgiygHSVA8vf2QBr";
  redirectUri = "https://arcadia.diversa.com.au";
} else {
  domain = "temp-diversa.au.auth0.com";
  clientId = "4q3RDCag8G2K56zCHgiygHSVA8vf2QBr";
  redirectUri = "http://localhost:3000";
}

const store = configureStore();

const tenantApiBasePath = process.env.REACT_APP_TENANT_SRV_HOST;

const App = () => {
  const onRedirectCallback = async (appState) => {
    window.location.replace(appState?.returnTo || window.location.pathname);
  };

  return (
    <ThemeProvider theme={theme}>
      <Reboot />
      <Provider store={store}>
        <Auth0Provider
          domain={domain}
          clientId={clientId}
          redirectUri={redirectUri}
          audience="https://arcadia.diversa.com.au/api"
          scope="openid profile email"
          cacheLocation="localstorage"
          useRefreshTokens={true}
          onRedirectCallback={onRedirectCallback}
        >
          <TenantProvider tenantApiBasePath={tenantApiBasePath}>
            <ConnectedRouter history={history}>
              <IntlProvider locale="en-AU">
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <Routes />
                </MuiPickersUtilsProvider>
              </IntlProvider>
            </ConnectedRouter>
          </TenantProvider>
        </Auth0Provider>
      </Provider>
    </ThemeProvider>
  );
};

export default hot(module)(App);
