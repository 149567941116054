import { combineReducers } from "redux";
import {
  WEBSITE_SNAPSHOT_CLEAR_ACTIVE,
  WEBSITE_SNAPSHOT_CREATE,
  WEBSITE_SNAPSHOT_GET_BY_ID,
  WEBSITE_SNAPSHOT_GET_PREVIOUS_BY_ID,
  WEBSITE_SNAPSHOT_LIST,
} from "../actions/websiteSnapshots";
import { publishActionToast } from "../services/toasts";

const successToasts = {
  [WEBSITE_SNAPSHOT_CREATE]: "Successfully initiated snapshot",
};

const byId = (state = {}, action) => {
  publishActionToast(action, "WEBSITE_SNAPSHOT_", successToasts);

  switch (action.type) {
    case `${WEBSITE_SNAPSHOT_GET_PREVIOUS_BY_ID}_SUCCESS`:
    case `${WEBSITE_SNAPSHOT_GET_BY_ID}_SUCCESS`:
    case `${WEBSITE_SNAPSHOT_LIST}_SUCCESS`:
    case `${WEBSITE_SNAPSHOT_CREATE}_SUCCESS`:
      return {
        ...state,
        ...action.response.entities.websiteSnapshot,
      };

    default:
      break;
  }

  return state;
};

const defaultActive = {
  activeList: [],
  activeSnapshot: null,
  activePreviousSnapshot: null,
};

const active = (state = defaultActive, action) => {
  switch (action.type) {
    case WEBSITE_SNAPSHOT_CLEAR_ACTIVE:
      return {
        ...defaultActive,
      };

    case `${WEBSITE_SNAPSHOT_LIST}_SUCCESS`:
      return {
        ...state,
        activeList: [...action.response.result],
      };

    case `${WEBSITE_SNAPSHOT_GET_BY_ID}_SUCCESS`:
    case `${WEBSITE_SNAPSHOT_CREATE}_SUCCESS`:
      return {
        ...state,
        activeList:
          state.activeList.indexOf(action.response.result) === -1
            ? [...state.activeList, action.response.result]
            : state.activeList,
        activeSnapshot: action.response.result,
      };

    case `${WEBSITE_SNAPSHOT_GET_PREVIOUS_BY_ID}_SUCCESS`:
      return {
        ...state,
        activeList:
          state.activeList.indexOf(action.response.result) === -1
            ? [...state.activeList, action.response.result]
            : state.activeList,
        activePreviousSnapshot: action.response.result,
      };

    default:
      break;
  }

  return state;
};

export default combineReducers({
  active,
  byId,
});

export const getSnapshots = (state) =>
  state.active.activeList.map((id) => state.byId[id]);
export const getActiveSnapshot = (state) =>
  state.active.activeSnapshot ? state.byId[state.active.activeSnapshot] : null;
export const getActivePreviousSnapshot = (state) =>
  state.active.activePreviousSnapshot
    ? state.byId[state.active.activePreviousSnapshot]
    : null;
