import { useAuth0 } from "@auth0/auth0-react";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { removeEndorsement } from "../../actions/workItems";
import { getReferenceDataType } from "../../reducers";
import dates from "../../util/dates";
import Avatar from "../common/Avatar.tsx";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  cardHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  cardContent: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  endorsement: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  disabled: {
    color: "rgba(0, 0, 0, 0.26)",
  },
  chipLabel: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    marginRight: theme.spacing(2),
  },
  chipRoot: {
    maxWidth: "100%",
  },
  chipIcon: {
    marginLeft: 0,
  },
});

const EndorsementsSummary = ({
  classes,
  workItem,
  loggedInUser,
  readonly,
  removeEndorsementAction,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const handleDelete = async (endorsement) => {
    const accessToken = await getAccessTokenSilently();
    removeEndorsementAction(workItem.id, endorsement.id, accessToken);
  };

  if (!workItem.endorsements.length) {
    return <div className={classes.disabled}>-</div>;
  }

  return workItem.endorsements.map((endorsement) => (
    <Tooltip
      key={endorsement.createdDate}
      title={`${endorsement.createdBy.name} - ${dates.formatTimestamp(
        dates.parseTimestamp(endorsement.createdDate)
      )}`}
    >
      <Chip
        className={classes.endorsement}
        classes={{
          root: classes.chipRoot,
          label: classes.chipLabel,
          icon: classes.chipIcon,
        }}
        icon={
          <Avatar
            email={endorsement.createdBy.email}
            name={endorsement.createdBy.name}
            size={30}
            round
          />
        }
        label={endorsement.description}
        onDelete={
          !readonly &&
          loggedInUser &&
          loggedInUser.id === endorsement.createdBy.id
            ? () => handleDelete(endorsement)
            : null
        }
      />
    </Tooltip>
  ));
};

EndorsementsSummary.propTypes = {
  workItem: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  endorsementTypes: PropTypes.any.isRequired,
  loggedInUser: PropTypes.object,
  readonly: PropTypes.bool.isRequired,
  removeEndorsementAction: PropTypes.func.isRequired,
};

EndorsementsSummary.defaultProps = {
  loggedInUser: {},
};

const mapStateToProps = (state) => ({
  endorsementTypes: getReferenceDataType(state, "EndorsementType"),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    removeEndorsementAction: removeEndorsement,
  })
)(EndorsementsSummary);
