import React, { Component } from "react";
import Icon from "@mdi/react";
import {
  mdiFileDocument,
  mdiFileExcel,
  mdiFileWord,
  mdiFilePowerpoint,
  mdiFilePdf,
  mdiFileImage,
  mdiEmail,
} from "@mdi/js";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import classNames from "classnames";
import * as _ from "lodash";
import PropTypes from "prop-types";

const styles = (theme) => ({
  icon: {
    fill: theme.documents.normal,
  },
  orangeIcon: {
    fill: theme.documents.orange,
  },
  greenIcon: {
    fill: theme.documents.green,
  },
});

// content type prefixes => icon name
const icons = {
  "image/": mdiFileImage,
  "application/pdf": mdiFilePdf,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    mdiFileWord,
  "application/msword": mdiFileWord,
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    mdiFileExcel,
  "application/vnd.ms-excel": mdiFileExcel,
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    mdiFilePowerpoint,
  "application/vnd.ms-powerpoint": mdiFilePowerpoint,
  "application/vnd.ms-outlook": mdiEmail,
};

const getFinalRegisterFlags = (document) =>
  document.availableFlags
    .filter((flag) => flag.props.includeInFinalDocumentRegister)
    .map((flag) => flag.id);

const isIncludedInFinalRegister = (document) =>
  _.some(document.flags, (flag) =>
    _.includes(getFinalRegisterFlags(document), flag)
  );

class FileIcon extends Component {
  getIcon = (contentType) => {
    const fileTypeIcon = _.find(icons, (icon, pattern) =>
      _.startsWith(contentType, pattern)
    );
    return fileTypeIcon || mdiFileDocument;
  };

  documentClassNames = (document) => {
    const { classes } = this.props;
    return classNames({
      [classes.icon]: true,
      [classes.orangeIcon]: document.flags && document.flags.length > 0,
      [classes.greenIcon]:
        document.flags &&
        document.availableFlags &&
        isIncludedInFinalRegister(document),
    });
  };

  render() {
    const { document, tooltip } = this.props;

    return (
      <Tooltip title={tooltip}>
        <Icon
          size={1}
          path={this.getIcon(document.contentType)}
          className={this.documentClassNames(document)}
          data-cy="fileIcon"
        />
      </Tooltip>
    );
  }
}

FileIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  document: PropTypes.object.isRequired,
  tooltip: PropTypes.string,
};

FileIcon.defaultProps = {
  tooltip: "",
};

export default withStyles(styles)(FileIcon);
