import React from "react";
import { connect } from "react-redux";
import { FieldArray, formValueSelector } from "redux-form";
import { numericality, required } from "redux-form-validators";
import { FieldLabels } from "../../../../model/FieldLabels";
import { getReferenceDataType } from "../../../../reducers";
import { yesNoOptions } from "../../../../util/radioOptions";
import CardSection from "../../../common/CardSection";
import { getRefDataMenuItems } from "../../../forms/menuItems";
import WrappedDatePicker from "../../../forms/wrapper/WrappedDatePicker";
import WrappedRadioGroup from "../../../forms/wrapper/WrappedRadioGroup";
import WrappedTextField from "../../../forms/wrapper/WrappedTextField";
import IncidentAssessmentReportTos from "../incidentassessment/IncidentAssessmentReportTos";
import ComponentField from "../../../forms/wrapper/ComponentField";
import WrappedSelect from "../../../forms/wrapper/WrappedSelect";
import InputAdornment from "@material-ui/core/InputAdornment";

interface Props {
  fieldPrefix?: string;
  fieldLabels: FieldLabels;
  customisations: [];
  formName: string;
  change: (field: string) => void;
  untouch: (field: string) => void;

  // redux
  natureOfBreachOptions: unknown[];
  natureOfBreach: string;
  representativeInvolved: boolean;
}

const IncidentBreachReportFields = ({
  fieldPrefix,
  fieldLabels,
  customisations,
  formName,
  change,
  untouch,
  natureOfBreachOptions,
  natureOfBreach,
  representativeInvolved,
}: Props) => {
  const breachReportLabels = fieldLabels.nestedTypes.incidentBreachReport;
  const commonProps = {
    fieldPrefix,
    customisations,
    change,
    untouch,
    formName,
  };
  return (
    <>
      <ComponentField
        component={WrappedDatePicker}
        name="incidentBreachReport.reportableDate"
        label={breachReportLabels.labels.reportableDate}
        data-cy={breachReportLabels.labels.reportableDate}
        validate={required({
          msg: `${breachReportLabels.labels.reportableDate} is required`,
        })}
        required
        fullWidth
        {...commonProps}
      />
      <ComponentField
        component={WrappedSelect}
        name="incidentBreachReport.natureOfBreach"
        label={breachReportLabels.labels.natureOfBreach}
        data-cy={breachReportLabels.labels.natureOfBreach}
        validate={required({
          msg: `${breachReportLabels.labels.natureOfBreach} is required`,
        })}
        required
        fullWidth
        {...commonProps}
      >
        {getRefDataMenuItems(natureOfBreachOptions)}
      </ComponentField>
      {(natureOfBreach === "SIGNIFICANT" ||
        natureOfBreach === "LIKELY_SIGNIFICANT") && (
        <ComponentField
          component={WrappedTextField}
          name="incidentBreachReport.reasonBreachIsSignificant"
          label={breachReportLabels.labels.reasonBreachIsSignificant}
          data-cy={breachReportLabels.labels.reasonBreachIsSignificant}
          validate={required({
            msg: `${breachReportLabels.labels.reasonBreachIsSignificant} is required`,
          })}
          required
          fullWidth
          helperText={
            "Provide details if breach relates to a deemed significant breach or the factors in s912D(5) of the Corporations Act or s50A(5) of the National Credit Act that you consider apply in determining whether the breach (or likely breach) is significant and required to be reported to ASIC."
          }
          {...commonProps}
        />
      )}
      <ComponentField
        component={WrappedTextField}
        name="incidentBreachReport.description"
        label={breachReportLabels.labels.description}
        data-cy={breachReportLabels.labels.description}
        fullWidth
        helperText={
          "Including the section of the relevant law that sets out the relevant obligation, including any relevant financial services law or credit legislation and any relevant licence condition."
        }
        {...commonProps}
      />
      <ComponentField
        component={WrappedTextField}
        name="incidentBreachReport.numberOfInstances"
        label={breachReportLabels.labels.numberOfInstances}
        data-cy={breachReportLabels.labels.numberOfInstances}
        validate={numericality({
          int: true,
          ">=": 0,
          allowBlank: true,
        })}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">instances</InputAdornment>
          ),
        }}
        fullWidth
        helperText={
          "Provide the number of reportable situations relating to the breach or likely breach you are reporting i.e., arising from the same single specific root cause  e.g., system error causes fee error across a number of products."
        }
        {...commonProps}
      />
      <ComponentField
        component={WrappedTextField}
        name="incidentBreachReport.howBreachWasIdentified"
        label={breachReportLabels.labels.howBreachWasIdentified}
        data-cy={breachReportLabels.labels.howBreachWasIdentified}
        validate={required({
          msg: `${breachReportLabels.labels.howBreachWasIdentified} is required`,
        })}
        required
        fullWidth
        helperText={
          "Details of how you found out about the reportable situation e.g. identified through compliance, an audit or review, or as a result of a complaint."
        }
        {...commonProps}
      />
      <ComponentField
        component={WrappedTextField}
        name="incidentBreachReport.duration"
        label={breachReportLabels.labels.duration}
        data-cy={breachReportLabels.labels.duration}
        validate={numericality({
          int: true,
          ">=": 0,
          allowBlank: true,
        })}
        InputProps={{
          endAdornment: <InputAdornment position="end">days</InputAdornment>,
        }}
        fullWidth
        {...commonProps}
      />

      <CardSection title="Information about representatives " gutterTop>
        <ComponentField
          component={WrappedRadioGroup}
          name="incidentBreachReport.representativeInvolved"
          label={breachReportLabels.labels.representativeInvolved}
          data-cy={breachReportLabels.labels.representativeInvolved}
          validate={required({
            msg: `${breachReportLabels.labels.representativeInvolved} is required`,
          })}
          required
          options={yesNoOptions}
          row
          fullWidth
          {...commonProps}
        />
        {representativeInvolved && (
          <>
            <ComponentField
              component={WrappedTextField}
              name="incidentBreachReport.representativeName"
              label={breachReportLabels.labels.representativeName}
              data-cy={breachReportLabels.labels.representativeName}
              fullWidth
              validate={required({
                msg: `${breachReportLabels.labels.representativeName} is required`,
              })}
              required
              {...commonProps}
            />
            <ComponentField
              component={WrappedTextField}
              name="incidentBreachReport.representativeNumber"
              label={breachReportLabels.labels.representativeNumber}
              data-cy={breachReportLabels.labels.representativeNumber}
              fullWidth
              validate={required({
                msg: `${breachReportLabels.labels.representativeNumber} is required`,
              })}
              required
              {...commonProps}
            />
            <ComponentField
              component={WrappedRadioGroup}
              name="incidentBreachReport.representativeAuthorisationRevokedOrSuspended"
              label={
                breachReportLabels.labels
                  .representativeAuthorisationRevokedOrSuspended
              }
              data-cy={
                breachReportLabels.labels
                  .representativeAuthorisationRevokedOrSuspended
              }
              fullWidth
              options={yesNoOptions}
              row
              {...commonProps}
            />
            <ComponentField
              component={WrappedRadioGroup}
              name="incidentBreachReport.representativeMonitoredOrSupervised"
              label={
                breachReportLabels.labels.representativeMonitoredOrSupervised
              }
              data-cy={
                breachReportLabels.labels.representativeMonitoredOrSupervised
              }
              fullWidth
              options={yesNoOptions}
              row
              {...commonProps}
            />
          </>
        )}
      </CardSection>
      <CardSection title="Reportable to">
        <FieldArray
          component={IncidentAssessmentReportTos}
          name="incidentBreachReport.reportTos"
        />
      </CardSection>
    </>
  );
};

const mapStateToProps = (
  state: Record<string, unknown>,
  ownProps: Record<string, string>
) => {
  const reduxFormSelector = formValueSelector(ownProps.formName);
  return {
    natureOfBreachOptions: getReferenceDataType(state, "ReportableSituation"),
    natureOfBreach: reduxFormSelector(
      state,
      "incidentBreachReport.natureOfBreach"
    ),
    representativeInvolved: reduxFormSelector(
      state,
      "incidentBreachReport.representativeInvolved"
    ),
  };
};

export default connect(mapStateToProps)(IncidentBreachReportFields);
