import { useAuth0 } from "@auth0/auth0-react";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getReferenceDataType } from "../../reducers";
import dates from "../../util/dates";
import { deleteIcon } from "../../util/icons";
import AlertDialog from "../common/AlertDialog";
import GridListing from "../common/GridListing";
import TimeAgo from "../common/TimeAgo";
import Pagination from "../common/Pagination";
import WorkItemBlob from "../workitem/WorkItemBlob";
import draftsApi from "../../services/api/drafts";

const useStyles = makeStyles(() => ({
  nowrap: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

const DraftWorkItemListing = ({
  history,
  workItemTypes,
  sortBy,
  setSortBy,
}) => {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const [pagination, setPagination] = useState({ pageSize: 50, offset: 0 });
  const [drafts, setDrafts] = useState([]);
  const [toBeDeleted, setToBeDeleted] = useState(null);

  const getOrderBy = () => {
    const prefix = sortBy.direction === "desc" ? "-" : "";
    return `${prefix}${sortBy.field}`;
  };

  const getSearchParameters = () => ({
    orderByField: getOrderBy(),
    limit: pagination.pageSize,
    offset: pagination.offset,
    type: "WorkItem",
  });

  const loadDrafts = async (searchParameters) => {
    const accessToken = await getAccessTokenSilently();
    const response = await draftsApi.search(searchParameters, accessToken);
    setDrafts(response.results);
    setPagination({
      ...pagination,
      offset: response.offset,
      previousOffset: response.previousOffset,
      nextOffset: response.nextOffset,
      resultCount: response.resultCount,
    });
  };

  useEffect(() => {
    loadDrafts(getSearchParameters());
  }, [sortBy]);

  const handlePrevious = () => {
    const searchParameters = {
      ...getSearchParameters(),
      offset: pagination.previousOffset,
    };
    return loadDrafts(searchParameters);
  };

  const handleNext = () => {
    const searchParameters = {
      ...getSearchParameters(),
      offset: pagination.nextOffset,
    };
    return loadDrafts(searchParameters);
  };

  const openDraft = (draft) => {
    const { type } = JSON.parse(draft.draftData);
    history.push(`/work-items/new/${type}#${draft.id}`);
  };

  const handleDeleteDraft = async () => {
    const accessToken = await getAccessTokenSilently();
    draftsApi.remove(toBeDeleted.id, accessToken).then(() => {
      setDrafts(drafts.filter((d) => d.id !== toBeDeleted.id));
      setToBeDeleted(null);
    });
  };

  const DeleteIcon = deleteIcon();

  return (
    <>
      <AlertDialog
        title="Delete draft?"
        body="Are you sure you want to delete this draft?"
        submitButtonText="Delete"
        open={!!toBeDeleted}
        onCancel={() => setToBeDeleted(null)}
        onSubmit={handleDeleteDraft}
        data-cy="deleteDraft"
      />
      <GridListing
        sortedData={drafts}
        loading={false}
        sortBy={sortBy}
        dense={false}
        updateSort={(field, direction) => setSortBy({ field, direction })}
        onClick={openDraft}
        action={(draft) => (
          <Tooltip title="Delete" disableFocusListener>
            <IconButton
              onClick={() => setToBeDeleted(draft)}
              data-cy="deleteIcon"
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
        columns={[
          {
            label: "Type",
            name: "type",
            size: 3,
            sortable: false,
            render: (draftWorkItem) => {
              const { type } = JSON.parse(draftWorkItem.draftData);
              const {
                definition: { name },
              } = workItemTypes.values[type].props;
              return (
                <WorkItemBlob type={type} description={name} data-cy={type} />
              );
            },
          },
          {
            label: "Title",
            name: "title",
            size: 5,
            sortable: false,
            render: (draftWorkItem) => {
              const { title } = JSON.parse(draftWorkItem.draftData);
              return (
                <Typography
                  className={classes.nowrap}
                  title={title}
                  data-cy={title}
                >
                  {title}
                </Typography>
              );
            },
          },
          {
            label: "Created",
            name: "created",
            size: 2,
            sortable: true,
            render: (draftWorkItem) => (
              <TimeAgo
                value={dates.parseTimestamp(draftWorkItem.created)}
                expandable
              />
            ),
          },
          {
            label: "Updated",
            name: "updated",
            size: 2,
            sortable: true,
            render: (draftWorkItem) => (
              <TimeAgo
                value={dates.parseTimestamp(draftWorkItem.updated)}
                expandable
              />
            ),
          },
        ]}
      />
      <Pagination
        pagination={pagination}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
        data-cy="pagination"
      />
    </>
  );
};

DraftWorkItemListing.propTypes = {
  history: PropTypes.object.isRequired,
  sortBy: PropTypes.object.isRequired,
  setSortBy: PropTypes.func.isRequired,

  // redux
  workItemTypes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  workItemTypes: getReferenceDataType(state, "WorkItemType"),
});

export default connect(mapStateToProps)(DraftWorkItemListing);
