import { CardContent, CardHeader } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  getWorkItem,
  getWorkItemSchedule,
  getWorkItemScheduleOccurrences,
} from "../../../reducers";
import dates from "../../../util/dates";
import SchedulePreview from "./SchedulePreview";

const styles = () => ({});

const SchedulePreviewCard = ({
  workItemSchedule,
  template,
  workItemScheduleOccurrences,
}) => {
  let previewStart = workItemSchedule.nextPeriod?.start;

  if (previewStart && workItemScheduleOccurrences.length) {
    const lastOccurrence = _.orderBy(
      workItemScheduleOccurrences,
      [(o) => dates.parseDate(o.dueDate)],
      ["desc"]
    )[0];
    switch (workItemSchedule.recurrenceType) {
      case "WEEK":
        previewStart = dates.serializeDate(
          moment(dates.parseDate(lastOccurrence.occurrencePeriod)).add(
            workItemSchedule.recurrenceUnit,
            "weeks"
          )
        );
        break;
      case "MONTH":
        previewStart = dates.serializeDate(
          moment(dates.parseDate(lastOccurrence.occurrencePeriod)).add(
            workItemSchedule.recurrenceUnit,
            "months"
          )
        );
        break;
      case "YEAR":
        previewStart = dates.serializeDate(
          moment(dates.parseDate(lastOccurrence.occurrencePeriod)).add(
            workItemSchedule.recurrenceUnit,
            "years"
          )
        );
        break;
      default:
        throw new Error(
          `Unknown recurrence type: ${workItemSchedule.recurrenceType}`
        );
    }
  }

  return (
    <Card elevation={0}>
      <CardHeader title="Future work items" />
      <CardContent>
        {!previewStart &&
          "No further work items will be created for the current schedule settings."}
        {previewStart && (
          <SchedulePreview
            recurrenceType={workItemSchedule.recurrenceType}
            recurrenceUnit={workItemSchedule.recurrenceUnit}
            nameOfFirstPeriod={workItemSchedule.nameOfFirstPeriod}
            startBy={previewStart}
            stopBy={workItemSchedule.stopBy}
            creationLeadTime={workItemSchedule.creationLeadTime}
            dueDateOffset={workItemSchedule.dueDateOffset}
            reminderLeadTime={template.reminderLeadTime}
            noItemsText="No further work items will be created for the current schedule settings."
          />
        )}
      </CardContent>
    </Card>
  );
};

SchedulePreviewCard.propTypes = {
  workItemSchedule: PropTypes.object,
  template: PropTypes.object,
  workItemScheduleOccurrences: PropTypes.array,
};

SchedulePreviewCard.defaultProps = {
  workItemSchedule: undefined,
  template: undefined,
  workItemScheduleOccurrences: [],
};

const mapStateToProps = (state) => ({
  workItemSchedule: getWorkItemSchedule(state),
  template: getWorkItem(state),
  workItemScheduleOccurrences: getWorkItemScheduleOccurrences(state),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(SchedulePreviewCard);
