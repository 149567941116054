export default class LoadingGroup {
  constructor(
    fetchesInProgress = 0,
    blockingFetchesInProgress = 0,
    isLoadingActionMap = {}
  ) {
    this.fetchesInProgress = fetchesInProgress < 0 ? 0 : fetchesInProgress;
    this.blockingFetchesInProgress =
      blockingFetchesInProgress < 0 ? 0 : blockingFetchesInProgress;
    this.isLoading = fetchesInProgress > 0;
    this.isLoadingActionMap = isLoadingActionMap;
    this.isBlocking = blockingFetchesInProgress > 0;
  }

  startFetch(nonBlocking, loadingAction) {
    const loadingActionMap = {
      ...this.isLoadingActionMap,
    };

    if (loadingAction) {
      loadingActionMap[loadingAction] = loadingActionMap[loadingAction]
        ? loadingActionMap[loadingAction] + 1
        : 1;
    }

    return new LoadingGroup(
      this.fetchesInProgress + 1,
      nonBlocking
        ? this.blockingFetchesInProgress
        : this.blockingFetchesInProgress + 1,
      loadingActionMap
    );
  }

  completeFetch(nonBlocking, loadingAction) {
    const loadingActionMap = {
      ...this.isLoadingActionMap,
    };

    if (loadingAction) {
      loadingActionMap[loadingAction] = loadingActionMap[loadingAction]
        ? loadingActionMap[loadingAction] - 1
        : 0;
    }

    return new LoadingGroup(
      this.fetchesInProgress - 1,
      nonBlocking
        ? this.blockingFetchesInProgress
        : this.blockingFetchesInProgress - 1,
      loadingActionMap
    );
  }

  isLoadingAction(actionType) {
    return !!(
      this.isLoadingActionMap[actionType] &&
      this.isLoadingActionMap[actionType] > 0
    );
  }
}
