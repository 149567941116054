import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { getActionsForWorkItem } from "../../../../util/workItemTypeUtils";
import LabelStateSelect from "../../../common/LabelStateSelect";

const styles = (theme) => ({
  priorityNone: {
    color: theme.priority.none,
  },
  priorityHigh: {
    color: theme.priority.high,
  },
  priorityMedium: {
    color: theme.priority.medium,
  },
  priorityLow: {
    color: theme.priority.low,
  },
});

function priorityClassNames(classes, className, priority) {
  return classNames({
    [classes.priorityHigh]: priority === "HIGH",
    [classes.priorityMedium]: priority === "MEDIUM",
    [classes.priorityLow]: priority === "LOW",
    [classes.priorityNone]: priority === "NONE",
    [className]: !!className,
  });
}

const WorkItemPriority = ({
  classes,
  priorities,
  className,
  readonly,
  workItem,
  onPriorityChange,
  loggedInUser,
  definition,
  startWorkItemAction,
}) => {
  const closer = useRef(null);
  const [prioritiesList] = useState(
    priorities.ids.map((priority) => priorities.values[priority]).reverse()
  );

  const setPriorityAction = getActionsForWorkItem(
    workItem,
    definition,
    loggedInUser
  )
    .filter((actionAssignment) => actionAssignment.visible)
    .find((actionAssignment) => actionAssignment.action === "SET_PRIORITY");

  const handlePriorityChange = (p) => {
    closer.current();

    if (p !== workItem.priority) {
      onPriorityChange(p);
    }
  };

  let buttonText = "";
  prioritiesList.forEach((state) => {
    if (state.id === workItem.priority) {
      buttonText = state.description;
    }
  });

  const combinedClassName = priorityClassNames(
    classes,
    className,
    workItem.priority
  );

  if (!onPriorityChange) {
    return (
      <div className={combinedClassName} data-cy={buttonText}>
        {buttonText}
      </div>
    );
  }

  return (
    <LabelStateSelect
      setCloser={(closeFunc) => {
        closer.current = closeFunc;
      }}
      label={buttonText}
      readonly={readonly || (setPriorityAction && !startWorkItemAction)}
      className={combinedClassName}
      onClick={
        setPriorityAction ? () => startWorkItemAction(setPriorityAction) : null
      }
      data-cy="priority"
    >
      {prioritiesList.map((state) => (
        <MenuItem
          key={state.id}
          value={state.id}
          disabled={workItem.priority === state.id}
          onClick={() => handlePriorityChange(state.id)}
          data-cy={state.description}
        >
          {state.description}
        </MenuItem>
      ))}
    </LabelStateSelect>
  );
};

WorkItemPriority.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  loggedInUser: PropTypes.object,
  workItem: PropTypes.object.isRequired,
  onPriorityChange: PropTypes.func,
  readonly: PropTypes.bool,
  priorities: PropTypes.object.isRequired,
  definition: PropTypes.object.isRequired,
  startWorkItemAction: PropTypes.func,
};

WorkItemPriority.defaultProps = {
  className: undefined,
  readonly: false,
  onPriorityChange: null,
  loggedInUser: null,
  startWorkItemAction: null,
};

export default withStyles(styles)(WorkItemPriority);
