import { useAuth0 } from "@auth0/auth0-react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getLoggedInUser, getReferenceDataType } from "../../reducers";
import organisationsApi from "../../services/api/organisations";
import productsApi from "../../services/api/products";
import Avatar from "../common/Avatar";
import ChoiceChips from "../common/ChoiceChips";
import DebouncedTextField from "../common/DebouncedTextField";
import FiltersBar from "../common/FiltersBar";
import RefMultiSelect from "../common/RefMultiSelect";
import ListPicker from "../ListPicker";

const useStyles = makeStyles((theme) => ({
  overhead: {
    marginBottom: theme.spacing(1),
  },
  selectWidget: {
    marginTop: theme.spacing(1),
  },
  chips: {
    marginTop: theme.spacing(1),
  },
  filter: {
    marginBottom: theme.spacing(1),
  },
}));

const toOptions = (map) =>
  Object.keys(map).map((value) => ({
    label: map[value].label,
    tooltip: map[value].tooltip,
    value,
  }));

const statusOptions = toOptions({
  ACTIVE: {
    label: "Active",
    tooltip: "Show active schedules only",
  },
  INACTIVE: {
    label: "Inactive",
    tooltip: "Show inactive schedules only",
  },
});

const viewsOptions = toOptions({
  projected: {
    label: "Future tasks",
    tooltip: "Show future tasks which have not yet been created",
  },
});

const ownershipOptions = toOptions({
  createdByMe: {
    label: "Created by me",
    tooltip: "Show schedules created by me",
  },
});

const equalSets = (set1, set2) => _.isEqual(_.orderBy(set1), _.orderBy(set2));

const ScheduleFilters = ({
  filter,
  updateFilter,
  loggedInUser,
  categories,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();
  const [organisationPickerOpen, setOrganisationPickerOpen] = useState(false);
  const [selectedOrganisations, setSelectedOrganisations] = useState([]);
  const [productPickerOpen, setProductPickerOpen] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);

  useEffect(() => {
    if (filter.organisationIds.length === 0) {
      setSelectedOrganisations([]);
    } else if (
      !equalSets(
        selectedOrganisations.map((o) => o.id),
        filter.organisationIds
      )
    ) {
      getAccessTokenSilently().then((accessToken) =>
        organisationsApi
          .search(
            {
              limit: filter.organisationIds.length,
              offset: 0,
              organisationIds: filter.organisationIds,
            },
            null,
            accessToken
          )
          .then((response) => setSelectedOrganisations(response.results))
      );
    }

    if (filter.productIds.length === 0) {
      setSelectedProducts([]);
    } else if (
      !equalSets(
        selectedProducts.map((p) => p.id),
        filter.productIds
      )
    ) {
      getAccessTokenSilently().then((accessToken) =>
        productsApi
          .search(
            {
              limit: filter.productIds.length,
              offset: 0,
              productIds: filter.productIds,
            },
            null,
            accessToken
          )
          .then((response) => setSelectedProducts(response.results))
      );
    }
  }, [filter]);

  const asyncOrganisationFetch = async (
    pickerFilter,
    pagination,
    abortController
  ) => {
    const accessToken = await getAccessTokenSilently();
    const { textSearch, sargonOnly } = pickerFilter;
    const searchParameters = {
      textSearch,
      types: sargonOnly ? ["INTERNAL"] : [],
      limit: pagination.pageSize,
      offset: pagination.offset,
      orderByField: "name",
    };
    return organisationsApi.search(
      searchParameters,
      abortController,
      accessToken
    );
  };

  const asyncProductFetch = async (
    pickerFilter,
    pagination,
    abortController
  ) => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = {
      ...pickerFilter,
      limit: pagination.pageSize,
      offset: pagination.offset,
      orderByField: "name",
    };
    return productsApi.search(searchParameters, abortController, accessToken);
  };

  const onOwnershipSelection = (value) => {
    updateFilter("ownership", value);
  };

  const getComplianceCategoryOptions = () => {
    const options = { ids: [], values: {} };
    categories.forEach((categoryAssignment) => {
      options.ids.push(categoryAssignment.category);
      options.values[categoryAssignment.category] = {
        description: categoryAssignment.name,
      };
    });
    return options;
  };

  const onOrganisationSelection = (value, oldValue) => {
    if (!oldValue) {
      setSelectedOrganisations([]);
    }
    setOrganisationPickerOpen(true);
  };

  const onOrganisationsSubmit = (organisations) => {
    setSelectedOrganisations(organisations);
    updateFilter(
      "organisationIds",
      organisations.map((org) => org.id)
    );
    setOrganisationPickerOpen(false);
  };

  const onProductSelection = (value, oldValue) => {
    if (!oldValue) {
      setSelectedProducts([]);
    }
    setProductPickerOpen(true);
  };

  const onProductsSubmit = (products) => {
    setSelectedProducts(products);
    updateFilter(
      "productIds",
      products.map((p) => p.id)
    );
    setProductPickerOpen(false);
  };

  if (!loggedInUser) {
    return <div />;
  }

  return (
    <>
      <FiltersBar
        overhead={
          <Grid
            container
            spacing={1}
            className={classes.overhead}
            alignItems="flex-end"
          >
            <Grid item xs={12} md={8}>
              <div className={classes.selectWidget}>
                <DebouncedTextField
                  value={filter.textSearch}
                  onChange={(text) => updateFilter("textSearch", text)}
                  placeholder="Search across title and notes"
                  margin="none"
                  data-cy="Search across reference, title and notes"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon>search</Icon>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <InputLabel data-cy="category">Category</InputLabel>
              <div className={classes.selectWidget}>
                <RefMultiSelect
                  title="Select categories"
                  value={filter.categories}
                  options={getComplianceCategoryOptions()}
                  fullWidth
                  onChange={(value) => updateFilter("categories", value)}
                />
              </div>
            </Grid>
          </Grid>
        }
      >
        <div>
          <InputLabel data-cy="ownership">Ownership</InputLabel>
          <div className={classes.chips}>
            <ChoiceChips
              value={filter.ownership}
              options={ownershipOptions}
              onChange={onOwnershipSelection}
            />
          </div>
        </div>
        <div>
          <InputLabel data-cy="status">Status</InputLabel>
          <div className={classes.chips}>
            <ChoiceChips
              value={filter.status}
              options={statusOptions}
              onChange={(value) => updateFilter("status", value)}
            />
          </div>
        </div>
        <div>
          <InputLabel data-cy="view">View</InputLabel>
          <div className={classes.chips}>
            <ChoiceChips
              value={filter.views}
              options={viewsOptions}
              onChange={(value) => updateFilter("views", value)}
            />
          </div>
        </div>
        <div>
          <InputLabel data-cy="entity">Entity relationship</InputLabel>
          <Grid container className={classes.chips}>
            <ChoiceChips
              value={filter.organisationIds.length > 0 ? "organisationIds" : ""}
              options={[
                {
                  label: "Related entities",
                  value: "organisationIds",
                  badgeValue: filter.organisationIds.length,
                },
              ]}
              onChange={onOrganisationSelection}
            />
            <ChoiceChips
              value={filter.productIds.length > 0 ? "productIds" : ""}
              options={[
                {
                  label: "Products",
                  value: "productIds",
                  badgeValue: filter.productIds.length,
                },
              ]}
              onChange={onProductSelection}
            />
          </Grid>
        </div>
      </FiltersBar>
      <ListPicker
        title="Select related entities"
        actionText="Select"
        open={organisationPickerOpen}
        onClose={() => setOrganisationPickerOpen(false)}
        onSubmit={onOrganisationsSubmit}
        datasource={asyncOrganisationFetch}
        selected={selectedOrganisations}
        isMulti
        clearable
        toOption={(org) => ({ label: org.name, id: org.id, org })}
        fromOption={(option) => option.org}
        renderIcon={(org, size) => (
          <Avatar name={(org.name || "Unknown").charAt(0)} size={size} round />
        )}
        renderFilter={(onChange, filter) => (
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
            className={classes.filter}
          >
            <Grid
              item
              style={{ flex: 1, marginRight: "3px", minWidth: "200px" }}
            >
              <DebouncedTextField
                value={filter.textSearch}
                onChange={(text) => onChange("textSearch", text)}
                placeholder="Type to filter..."
                margin="none"
                autoFocus
                fullWidth
                data-cy="typeToFilter"
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={!!filter.sargonOnly}
                    onChange={(evt) =>
                      onChange("sargonOnly", evt.target.checked)
                    }
                    name="sargonOnly"
                  />
                }
                label="Internal only"
                data-cy="certaneOnly"
              />
            </Grid>
          </Grid>
        )}
      />
      <ListPicker
        title="Select products"
        actionText="Select"
        open={productPickerOpen}
        onClose={() => setProductPickerOpen(false)}
        onSubmit={onProductsSubmit}
        datasource={asyncProductFetch}
        selected={selectedProducts}
        isMulti
        clearable
        toOption={(product) => ({
          label: product.name,
          id: product.id,
          product,
        })}
        fromOption={(option) => option.product}
        renderIcon={(product, size) => (
          <Avatar
            name={(product.name || "Unknown").charAt(0)}
            size={size}
            round
          />
        )}
      />
    </>
  );
};

ScheduleFilters.propTypes = {
  filter: PropTypes.object.isRequired,
  updateFilter: PropTypes.func.isRequired,

  // redux
  loggedInUser: PropTypes.object,
  categories: PropTypes.array.isRequired,
};

ScheduleFilters.defaultProps = {
  loggedInUser: null,
};

const mapStateToProps = (state) => ({
  categories: getReferenceDataType(state, "WorkItemType").values.COMPLIANCE_TASK
    .props.definition.categories,
  loggedInUser: getLoggedInUser(state),
});

export default connect(mapStateToProps)(ScheduleFilters);
