import { withStyles } from "@material-ui/core";
import HtmlToReact, { Parser } from "html-to-react";
import * as PropTypes from "prop-types";
import React from "react";

const styles = (theme) => ({
  actions: {
    position: "absolute",
    right: theme.spacing(1) / 4,
    top: 0,
  },
});

const allowedTags = [
  "address",
  "article",
  "aside",
  "footer",
  "header",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "hgroup",
  "main",
  "nav",
  "section",
  "blockquote",
  "dd",
  "div",
  "dl",
  "dt",
  "figcaption",
  "figure",
  "head",
  "hr",
  "html",
  "img",
  "li",
  "main",
  "meta",
  "ol",
  "p",
  "pre",
  "ul",
  "a",
  "abbr",
  "b",
  "bdi",
  "bdo",
  "body",
  "br",
  "cite",
  "code",
  "data",
  "dfn",
  "em",
  "i",
  "kbd",
  "mark",
  "q",
  "rb",
  "rp",
  "rt",
  "rtc",
  "ruby",
  "s",
  "samp",
  "small",
  "span",
  "strong",
  "sub",
  "sup",
  "time",
  "u",
  "var",
  "wbr",
  "caption",
  "col",
  "colgroup",
  "table",
  "tbody",
  "td",
  "tfoot",
  "th",
  "thead",
  "tr",
];

const HtmlView = ({ classes, value, children }) => {
  const htmlToReactParser = new Parser();
  // Order matters. Instructions are processed in the order they're defined
  const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);

  const isValidNode = (node) => {
    if (node.type === "script") {
      return false;
    }
    if (node.type === "directive") {
      return false;
    }
    if (node.type === "tag") {
      if (allowedTags.indexOf(node.name) === -1) {
        return false;
      }
    }
    // logger.debug("Good tag", node);
    return true;
  };

  const processingInstructions = [
    {
      shouldProcessNode: function () {
        return true;
      },
      processNode: processNodeDefinitions.processDefaultNode,
    },
  ];

  const getContent = () => {
    return htmlToReactParser.parseWithInstructions(
      value,
      isValidNode,
      processingInstructions
    );
  };

  return (
    <div>
      <div className={classes.actions}>{children}</div>
      {getContent()}
    </div>
  );
};

HtmlView.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

HtmlView.defaultProps = {
  value: null,
  children: null,
};

export default withStyles(styles)(HtmlView);
