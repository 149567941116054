import { combineReducers } from "redux";
import {
  QUESTION_SEARCH,
  QUESTION_CLEAR_ACTIVE,
  QUESTION_CLEAR_METRICS,
  QUESTION_LOAD_METRIC,
} from "../actions/questions";
import { publishActionToast } from "../services/toasts";

const byId = (state = {}, action) => {
  publishActionToast(action, "QUESTION_");

  switch (action.type) {
    case `${QUESTION_SEARCH}_SUCCESS`:
      return {
        ...state,
        ...action.response.entities.question,
      };

    default:
      break;
  }

  return state;
};

const defaultActive = {
  dashboard: [],
  pagination: {
    offset: 0,
    pageSize: 50,
    resultCount: 0,
  },
};

const active = (state = defaultActive, action) => {
  switch (action.type) {
    case QUESTION_CLEAR_ACTIVE:
      return {
        ...defaultActive,
      };

    case `${QUESTION_SEARCH}_SUCCESS`:
      return {
        ...state,
        dashboard: [...action.response.result],
        pagination: {
          ...action.response.pagination,
          pageSize: defaultActive.pagination.pageSize,
        },
      };

    default:
      break;
  }

  return state;
};

const metrics = (state = {}, action) => {
  switch (action.type) {
    case QUESTION_CLEAR_METRICS:
      return {};
    case `${QUESTION_LOAD_METRIC}_SUCCESS`:
      return {
        ...state,
        [action.response.metric]: action.response.count,
      };
    default:
      break;
  }

  return state;
};

export default combineReducers({
  byId,
  active,
  metrics,
});

export const getAll = (state) =>
  state.active.dashboard.map((id) => state.byId[id]);
export const getPagination = (state) => state.active.pagination;
export const getMetrics = (state) => state.metrics;
