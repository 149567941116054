import VerticalAlignCenterIcon from "@material-ui/icons/VerticalAlignCenter";
import Tooltip from "@material-ui/core/Tooltip";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Diff, parseDiff } from "react-diff-view";
import * as JsDiff from "diff";
import _ from "lodash";
import { withStyles } from "@material-ui/core/styles";
import "react-diff-view/style/index.css";
import "./TextDiff.less";

const styles = (theme) => ({
  message: {
    fontStyle: "italic",
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  actions: {
    color: theme.palette.grey[500],
  },
  action: {
    margin: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    "&:hover": {
      cursor: "pointer",
    },
  },
  activeAction: {
    color: theme.palette.grey[900],
  },
});

class TextDiff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      diff: null,
      oldText: "",
      newText: "",
      condensed: false,
    };
  }

  componentDidMount() {
    this.calculateDiff();
  }

  componentDidUpdate() {
    this.calculateDiff();
  }

  getDiff = (oldText, newText, condensed) => {
    // condensed view only shows 3 lines of context
    const context = condensed ? 3 : 99999999;
    const diff = JsDiff.createPatch("filename", oldText, newText, "a", "b", {
      context,
    });

    // return an empty string if there are no diffs
    const patch = JsDiff.parsePatch(diff);
    const hunks = _.get(patch, "[0].hunks", []);
    if (_.isEmpty(hunks)) {
      return "";
    }

    // remove header from diff string because react-diff-view doesn't recognise it
    return _.replace(
      diff,
      "Index: filename\n===================================================================\n",
      ""
    );
  };

  calculateDiff = () => {
    const { oldText, newText } = this.props;
    const { condensed } = this.state;

    if (oldText !== this.state.oldText || newText !== this.state.newText) {
      const diff = this.getDiff(oldText, newText, condensed);
      this.setState({
        diff,
        oldText,
        newText,
        condensed,
      });
    }
  };

  toggleCondensed = () => {
    const { oldText, newText } = this.props;
    const condensed = !this.state.condensed;

    const diff = this.getDiff(oldText, newText, condensed);
    this.setState({
      diff,
      oldText,
      newText,
      condensed,
    });
  };

  render() {
    const { classes } = this.props;
    const { oldText, diff, condensed } = this.state;

    if (diff === null) {
      return <div className={classes.message}>Calculating diff...</div>;
    }

    if (diff === "") {
      const lines = _.split(oldText, /\r?\n|\r/);
      return (
        <>
          <div className={classes.message}>Contents are identical.</div>
          <div className="text-diff">
            {/* Re-use the styles from react-diff-view */}
            <table className="diff">
              <colgroup>
                <col className="diff-gutter-col" />
                <col />
                <col className="diff-gutter-col" />
                <col />
              </colgroup>
              <tbody className="diff-hunk">
                {lines.map((line, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <tr className="diff-line diff-line-normal" key={index}>
                    <td
                      className="diff-gutter diff-gutter-normal"
                      data-line-number={index + 1}
                    />
                    <td className="diff-code diff-code-normal">{line}</td>
                    <td
                      className="diff-gutter diff-gutter-normal"
                      data-line-number={index + 1}
                    />
                    <td className="diff-code diff-code-normal">{line}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      );
    }

    const [file] = parseDiff(diff, { nearbySequences: "zip" });
    return (
      <>
        <div className={classes.actions}>
          <Tooltip title="Collapse unchanged text" disableFocusListener>
            <VerticalAlignCenterIcon
              className={classNames({
                [classes.action]: true,
                [classes.activeAction]: condensed,
              })}
              onClick={() => this.toggleCondensed()}
            />
          </Tooltip>
        </div>
        <div className="text-diff">
          <Diff
            key={`${file.oldRevision}-${file.newRevision}`}
            viewType="split"
            diffType={file.type}
            hunks={file.hunks}
            oldSource={oldText}
          />
        </div>
      </>
    );
  }
}

TextDiff.propTypes = {
  classes: PropTypes.object.isRequired,
  oldText: PropTypes.string.isRequired,
  newText: PropTypes.string.isRequired,
};

export default withStyles(styles)(TextDiff);
