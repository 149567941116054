import { useAuth0 } from "@auth0/auth0-react";
import { InputLabel } from "@material-ui/core";
import React, { useState } from "react";
import { Product } from "../../../../model/Product";
import productsApi from "../../../../services/api/products";
import Avatar from "../../../common/Avatar";
import MultiSelectListPicker from "../../../common/MultiSelectListPicker";

interface Props {
  onSelect: (productIds: string[]) => void;
}

const ProductPicker: React.FC<Props> = ({ onSelect }: Props) => {
  const { getAccessTokenSilently } = useAuth0();
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const asyncProductFetch = async (
    pickerFilter: Record<string, unknown>,
    pagination: Record<string, number>,
    abortController: AbortController
  ) => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = {
      ...pickerFilter,
      limit: pagination.pageSize,
      offset: pagination.offset,
      orderByField: "name",
    };
    return productsApi.search(searchParameters, abortController, accessToken);
  };

  const getProductsLabel = () => {
    if (selectedProducts.length > 1) {
      return `${selectedProducts.length} selected`;
    }
    return selectedProducts.map((product: Product) => product.name).join(", ");
  };

  const setProducts = (products: Product[]) => {
    setSelectedProducts(products);
    onSelect(products.map((product) => product.id));
  };

  return (
    <>
      <InputLabel>Products</InputLabel>
      <MultiSelectListPicker
        title="Select product"
        actionText="Select"
        label={getProductsLabel()}
        data-cy={getProductsLabel()}
        onSubmit={(products: Product[]) => setProducts(products)}
        datasource={asyncProductFetch}
        selected={selectedProducts}
        isMulti
        clearable
        toOption={(product: Product) => ({
          label: product.name,
          id: product.id,
          product,
        })}
        fromOption={(option: Record<string, unknown>) => option.product}
        renderIcon={(product: Product, size: string) => (
          <Avatar
            name={(product.name || "Unknown").charAt(0)}
            size={size}
            round
          />
        )}
      />
    </>
  );
};

export default ProductPicker;
