import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";

const styles = (theme) => ({
  box: {
    display: "flex",
    flexWrap: "wrap",
  },
  text: {
    color: theme.palette.swatch.secondary,
    marginRight: theme.spacing(1),
  },
});

const isNotEmpty = (arr) => arr.length > 0;
const startsWith = (needle) => (haystack) => haystack.startsWith(needle);

const containsSingleUserChange = (pathKey, entry) => {
  const results = entry.fieldChanges.filter(
    (item) =>
      isNotEmpty(item.path.filter(startsWith(pathKey))) &&
      item.type === "threewks.model.entitystub.EntityStub"
  );

  return isNotEmpty(results);
};

const isSingleUserChange = (pathKey, entry) =>
  _.has(entry, "fieldChanges") && containsSingleUserChange(pathKey, entry);

const isSingleUserType = (pathKey) => (entry) =>
  entry.type === "threewks.model.entitystub.EntityStub" &&
  startsWith(pathKey, _.first(entry.path));

const userDetails = (user) => {
  switch (user.changeType) {
    case "REMOVAL":
      return {
        ...user.before[0].properties,
      };
    default:
      return {
        ...user.after[0].properties,
      };
  }
};

const useSingleUserChange = (pathKey, entry) => {
  const [hasChanges, setHasChanges] = useState(false);
  const [changes, setChanges] = useState({});

  useEffect(() => {
    if (isSingleUserChange(pathKey, entry)) {
      const rawData = entry.fieldChanges.filter(isSingleUserType(pathKey));
      const updates = rawData.map((update) => ({
        removed: update.changeType === "REMOVAL",
        ...userDetails(update),
      }));

      setHasChanges(true);
      setChanges(updates);
    }
  }, [entry]);

  return [hasChanges, changes];
};

const SingleUserChange = ({ classes, entry, pathKey, label }) => {
  const [hasChanges, changes] = useSingleUserChange(pathKey, entry);

  return (
    <>
      {hasChanges && (
        <div className={classes.box}>
          {changes.map((user) => (
            <Typography key={user.id} variant="body2" className={classes.text}>
              {user.removed ? `Removed ${label}` : `Set ${label}`}{" "}
              <strong>{user.name}</strong>
            </Typography>
          ))}
        </div>
      )}
    </>
  );
};

SingleUserChange.propTypes = {
  classes: PropTypes.object.isRequired,
  entry: PropTypes.object.isRequired,
  pathKey: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default withStyles(styles)(SingleUserChange);
