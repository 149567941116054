import { useAuth0 } from "@auth0/auth0-react";
import { authorizer, useTenant } from "@certane/arcadia-web-components";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router";
import { fetchUser } from "../actions/auth";
import Error403 from "../components/error/Error403";
import Error404 from "../components/error/Error404";
import Layout from "../components/layout/Layout";
import LoadingIndicator from "../components/LoadingIndicator";
import ViewsPage from "../pages/admin/analytics";
import BulkImportPage from "../pages/admin/bulk-import";
import BulkUpdatePage from "../pages/admin/bulk-updates";
import BusinessServiceAreas from "../pages/admin/business-service-areas";
import FundListingPage from "../pages/admin/funds";
import FundPage from "../pages/admin/funds/[fundId]";
import NewFundPage from "../pages/admin/funds/new";
import OrganisationListingPage from "../pages/admin/organisations";
import OrganisationPage from "../pages/admin/organisations/[orgId].tsx";
import NewOrganisationPage from "../pages/admin/organisations/new.tsx";
import ProductListingPage from "../pages/admin/products";
import SlaListingPage from "../pages/admin/slas";
import SubscriptionsListingPage from "../pages/admin/subscriptions";
import ManageTagsPage from "../pages/admin/tags";
import TagPage from "../pages/admin/tags/[tagId]";
import TenantAdminPage from "../pages/admin/tenants";
import UserGroupListingPage from "../pages/admin/user-groups";
import UserListingPage from "../pages/admin/users";
import AuditReportListingPage from "../pages/audits";
import AuditReportPage from "../pages/audits/[auditReportId]";
import CreateAuditReportPage from "../pages/audits/new";
import ControlListingPage from "../pages/controls";
import ControlId from "../pages/controls/[controlId]";
import CreateControlPage from "../pages/controls/new";
import DashboardPage from "../pages/dashboard";
import ContentListingPage from "../pages/metropolis";
import CategoryListingPage from "../pages/metropolis/categories";
import MediaPage from "../pages/metropolis/media/[mediaId]";
import CreateMediaPage from "../pages/metropolis/media/new";
import SnippetPage from "../pages/metropolis/snippet/[snippetId]";
import CreateSnippetPage from "../pages/metropolis/snippet/new";
import QuestionsPage from "../pages/questions";
import RedirectWithState from "../pages/r/RedirectWithState";
import RiskDashboardPage from "../pages/risk";
import RiskDetailsPage from "../pages/risk/[riskId]";
import RiskControlListingPage from "../pages/risk/controls";
import RiskControlPage from "../pages/risk/controls/[riskControlId]";
import RiskControlVerificationListingPage from "../pages/risk/controls/control-verifications";
import CreateRiskControlPage from "../pages/risk/controls/new";
import CreateRiskPage from "../pages/risk/new";
import RiskListingPage from "../pages/risk/risks";
import RiskActionsListingPage from "../pages/risk/risks/actions";
import RiskMeasureUpdatesListingPage from "../pages/risk/risks/measure-updates";
import RiskReassessmentsListingPage from "../pages/risk/risks/reassessments";
import RiskWorkItemPage from "../pages/risk/work-items/[workItemId]";
import WorkItemFriendlyId from "../pages/s/[workItemFriendlyId]";
import WebsiteDifferencesPage from "../pages/sentinel/differences";
import WebsiteDifferenceDetailsPage from "../pages/sentinel/differences/[websiteDifferenceId]";
import DigitalAssetsPage from "../pages/sentinel/digital-assets";
import DigitalAssetDetailsPage from "../pages/sentinel/digital-assets/[digitalAssetId]";
import MentionsPage from "../pages/sentinel/mentions";
import CalendarPage from "../pages/work-item-schedules";
import WorkItemSchedulePage from "../pages/work-item-schedules/[workItemScheduleId]";
import WorkItemsPage from "../pages/work-items";
import WorkItemPage from "../pages/work-items/[workItemId]";
import DraftWorkItemListingPage from "../pages/work-items/drafts";
import CreateWorkItemPage from "../pages/work-items/new/[type]";
import { getAuthenticationError, getLoggedInUser } from "../reducers";
import { isDev, isLocal } from "../util/env";
import PrefetchDataSets from "./PrefetchDataSets";

const SecuredRoutes = () => {
  const { getAccessTokenSilently, user } = useAuth0();
  const { tenant } = useTenant();
  const dispatch = useDispatch();
  const loggedInUser = useSelector(getLoggedInUser);
  const authError = useSelector(getAuthenticationError);

  useEffect(() => {
    (async () => {
      if (!loggedInUser) {
        const accessToken = await getAccessTokenSilently();
        dispatch(fetchUser(accessToken));
      }
    })();
  }, [getAccessTokenSilently]);

  if (authError) {
    return <div>{authError.message}</div>;
  }

  // Wait for loggedInUser to be set
  if (!loggedInUser) {
    return <LoadingIndicator size={60} />;
  }

  const withAuthorization = (component, resourceId, relation = "viewer") => {
    if (authorizer.check(resourceId, relation, user, tenant?.id)) {
      return component;
    }
    return Error403;
  };

  return (
    <PrefetchDataSets>
      <Layout>
        <Switch>
          <Route exact path="/forbidden" component={Error403} />

          <Route
            exact
            path="/admin/bulk-import"
            component={withAuthorization(
              BulkImportPage,
              "tenant-admin:bulk-import"
            )}
          />
          <Route
            exact
            path="/admin/funds"
            component={withAuthorization(FundListingPage, "tenant-admin:fund")}
          />
          <Route
            exact
            path="/admin/funds/new"
            component={withAuthorization(NewFundPage, "tenant-admin:fund")}
          />
          <Route
            exact
            path="/admin/funds/:fundId"
            component={withAuthorization(FundPage, "tenant-admin:fund")}
          />
          <Route
            exact
            path="/admin/products"
            component={withAuthorization(
              ProductListingPage,
              "tenant-admin:product"
            )}
          />
          <Route
            exact
            path="/admin/subscriptions"
            component={withAuthorization(
              SubscriptionsListingPage,
              "tenant-admin:subscription"
            )}
          />
          <Route
            exact
            path="/admin/tags"
            component={withAuthorization(ManageTagsPage, "tenant-admin:tag")}
          />
          <Route
            exact
            path="/admin/tags/:tagId"
            component={withAuthorization(TagPage, "tenant-admin:tag")}
          />

          <Route
            exact
            path="/admin/bulk-updates*"
            component={withAuthorization(BulkUpdatePage, "setting")}
          />
          <Route
            exact
            path="/admin/business-service-areas"
            component={withAuthorization(BusinessServiceAreas, "setting")}
          />
          <Route
            exact
            path="/admin/organisations"
            component={withAuthorization(OrganisationListingPage, "setting")}
          />
          <Route
            exact
            path="/admin/organisations/new"
            component={withAuthorization(NewOrganisationPage, "setting")}
          />
          <Route
            exact
            path="/admin/organisations/:orgId"
            component={withAuthorization(OrganisationPage, "setting")}
          />
          <Route
            exact
            path="/admin/slas"
            component={withAuthorization(SlaListingPage, "setting")}
          />
          <Route
            path="/admin/tenants"
            component={withAuthorization(TenantAdminPage, "setting")}
          />
          <Route
            exact
            path="/admin/user-groups"
            component={withAuthorization(UserGroupListingPage, "setting")}
          />
          <Route
            exact
            path="/admin/users"
            component={withAuthorization(UserListingPage, "setting")}
          />
          {(isDev || isLocal) && (
            <Route
              exact
              path="/admin/views"
              component={withAuthorization(ViewsPage, "setting")}
            />
          )}
          <Redirect
            from="/admin/work-item-schedules/:workItemScheduleId"
            to="/work-item-schedules/:workItemScheduleId"
          />
          <Redirect
            from="/admin/work-item-schedules"
            to="/work-item-schedules"
          />
          <Redirect
            from="/admin/work-items/:workItemId"
            to="/work-items/:workItemId"
          />
          <Redirect from="/admin/work-items" to="/work-items" />
          <Redirect from="/admin" to="/work-items" />

          <Route
            exact
            path="/dashboard"
            component={withAuthorization(DashboardPage, "work-item")}
          />

          <Route
            exact
            path="/questions"
            component={withAuthorization(QuestionsPage, "work-item")}
          />

          <Route
            exact
            path="/work-items"
            component={withAuthorization(WorkItemsPage, "work-item")}
          />
          <Route
            exact
            path="/work-items/drafts"
            component={withAuthorization(DraftWorkItemListingPage, "work-item")}
          />
          <Route
            exact
            path="/work-items/new/:type"
            component={withAuthorization(CreateWorkItemPage, "work-item")}
          />
          <Route
            exact
            path="/work-items/:workItemId"
            component={withAuthorization(WorkItemPage, "work-item")}
          />

          <Route
            exact
            path="/work-item-schedules"
            component={withAuthorization(
              CalendarPage,
              "work-item:compliance-task"
            )}
          />
          <Route
            exact
            path="/work-item-schedules/:workItemScheduleId"
            component={withAuthorization(
              WorkItemSchedulePage,
              "work-item:compliance-task"
            )}
          />

          <Redirect
            from="/client/work-items/:workItemId"
            to="/work-items/:workItemId"
          />
          <Redirect from="/client" to="/work-items" />

          <Route exact path="/r/:target" component={RedirectWithState} />

          <Route
            exact
            path="/s/:workItemFriendlyId"
            component={withAuthorization(WorkItemFriendlyId, "work-item")}
          />

          <Route
            exact
            path="/risk"
            component={withAuthorization(RiskDashboardPage, "risk")}
          />
          <Route
            exact
            path="/risk/risks"
            component={withAuthorization(RiskListingPage, "risk")}
          />
          <Route
            exact
            path="/risk/risks/actions"
            component={withAuthorization(RiskActionsListingPage, "risk")}
          />
          <Route
            exact
            path="/risk/risks/actions/:workItemId"
            component={withAuthorization(RiskWorkItemPage, "risk")}
          />
          <Route
            exact
            path="/risk/risks/reassessments"
            component={withAuthorization(RiskReassessmentsListingPage, "risk")}
          />
          <Route
            exact
            path="/risk/risks/reassessments/:workItemId"
            component={withAuthorization(RiskWorkItemPage, "risk")}
          />
          <Route
            exact
            path="/risk/risks/measure-updates"
            component={withAuthorization(RiskMeasureUpdatesListingPage, "risk")}
          />
          <Route
            exact
            path="/risk/risks/measure-updates/:workItemId"
            component={withAuthorization(RiskWorkItemPage, "risk")}
          />
          <Route
            exact
            path="/risk/controls/control-verifications"
            component={withAuthorization(
              RiskControlVerificationListingPage,
              "risk"
            )}
          />
          <Route
            exact
            path="/risk/controls/control-verifications/:workItemId"
            component={withAuthorization(RiskWorkItemPage, "risk")}
          />
          <Route
            exact
            path="/risk/risks/new"
            component={withAuthorization(CreateRiskPage, "risk")}
          />
          <Route
            exact
            path="/risk/risks/:riskId"
            component={withAuthorization(RiskDetailsPage, "risk")}
          />
          <Route
            exact
            path="/risk/risks/:riskId/actions/:workItemId"
            component={withAuthorization(RiskWorkItemPage, "risk")}
          />
          <Route
            exact
            path="/risk/risks/:riskId/reassessments/:workItemId"
            component={withAuthorization(RiskWorkItemPage, "risk")}
          />
          <Route
            exact
            path="/risk/risks/:riskId/measure-updates/:workItemId"
            component={withAuthorization(RiskWorkItemPage, "risk")}
          />
          <Route
            exact
            path="/risk/controls"
            component={withAuthorization(RiskControlListingPage, "risk")}
          />
          <Route
            exact
            path="/risk/controls/new"
            component={withAuthorization(CreateRiskControlPage, "risk")}
          />
          <Route
            exact
            path="/risk/controls/:riskControlId"
            component={withAuthorization(RiskControlPage, "risk")}
          />
          <Route
            exact
            path="/risk/controls/:riskControlId/verifications/:workItemId"
            component={withAuthorization(RiskWorkItemPage, "risk")}
          />

          <Route
            exact
            path="/controls"
            component={withAuthorization(ControlListingPage, "control")}
          />
          <Route
            exact
            path="/controls/new"
            component={withAuthorization(CreateControlPage, "control")}
          />
          <Route
            exact
            path="/controls/:controlId"
            component={withAuthorization(ControlId, "control")}
          />

          <Route
            exact
            path="/audits"
            component={withAuthorization(AuditReportListingPage, "audit")}
          />
          <Route
            exact
            path="/audits/new"
            component={withAuthorization(CreateAuditReportPage, "audit")}
          />
          <Route
            exact
            path="/audits/:auditReportId"
            component={withAuthorization(AuditReportPage, "audit")}
          />

          <Route
            exact
            path="/sentinel/digital-assets"
            component={withAuthorization(DigitalAssetsPage, "sentinel")}
          />
          <Route
            exact
            path="/sentinel/digital-assets/:digitalAssetId"
            component={withAuthorization(DigitalAssetDetailsPage, "sentinel")}
          />
          <Route
            exact
            path="/sentinel/differences"
            component={withAuthorization(WebsiteDifferencesPage, "sentinel")}
          />
          <Route
            exact
            path="/sentinel/differences/:websiteDifferenceId"
            component={withAuthorization(
              WebsiteDifferenceDetailsPage,
              "sentinel"
            )}
          />
          <Route
            exact
            path="/sentinel/mentions"
            component={withAuthorization(MentionsPage, "sentinel")}
          />
          <Redirect from="/sentinel" to="/sentinel/digital-assets" />

          <Route
            exact
            path="/metropolis"
            component={withAuthorization(ContentListingPage, "metropolis")}
          />
          <Route
            exact
            path="/metropolis/media/new"
            component={withAuthorization(CreateMediaPage, "metropolis")}
          />
          <Route
            exact
            path="/metropolis/media/:mediaId"
            component={withAuthorization(MediaPage, "metropolis")}
          />
          <Route
            exact
            path="/metropolis/snippet/new"
            component={withAuthorization(CreateSnippetPage, "metropolis")}
          />
          <Route
            exact
            path="/metropolis/snippet/:snippetId"
            component={withAuthorization(SnippetPage, "metropolis")}
          />
          <Route
            exact
            path="/metropolis/categories"
            component={withAuthorization(
              CategoryListingPage,
              "metropolis",
              "editor"
            )}
          />

          <Route
            exact
            path="/"
            render={() => {
              if (authorizer.check("work-item", "viewer", user, tenant?.id)) {
                return <Redirect to="/dashboard" />;
              } else if (authorizer.check("risk", "viewer", user, tenant?.id)) {
                return <Redirect to="/risk" />;
              } else if (
                authorizer.check("sentinel", "viewer", user, tenant?.id)
              ) {
                return <Redirect to="/sentinel" />;
              }
              return <Error403 />;
            }}
          />
          <Route path="*" component={Error404} />
        </Switch>
      </Layout>
    </PrefetchDataSets>
  );
};

export default SecuredRoutes;
