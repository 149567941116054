/* eslint-disable react/no-find-dom-node */
import _ from "lodash";
import logger from "./logger";

/**
 * Used for async validation of multiple fields. Handles field specific validation, and complete
 * validation of all fields, if the field parameter is null.
 *
 * @param validators - object keyed by field name and whose value contains functions:
 *  - initiate(values)
 *  - validate(values, response)
 * @returns {Function}
 */
export const getAsyncValidate =
  (validators) => async (values, dispatch, props, field) => {
    const { asyncErrors, getAccessToken } = props;
    if (!getAccessToken) {
      throw new Error("'getAccessToken' prop wasn't passed to form");
    }
    const accessToken = await getAccessToken();
    logger.info("accessToken", accessToken);

    const leafStrings = (obj, stack) => {
      if (obj) {
        if (_.isString(obj)) {
          stack.push(obj);
        } else if (_.isObject(obj)) {
          Object.keys(obj).forEach((key) => leafStrings(obj[key], stack));
        }
      }
      return stack;
    };

    const throwIfError = (result) => {
      // Result is a hierarchical structure and only the leaves have the errors
      const errorStrings = leafStrings(result, []);
      if (errorStrings.length > 0) {
        logger.info("Throwing async errors", result);
        throw result;
      }
    };

    let promise;
    if (field) {
      let validator = validators[field];

      if (!validator && /\[\d+\]/.test(field)) {
        const fieldNoIndexes = field.replace(/\[\d+\]/, "[]");
        validator = validators[fieldNoIndexes];
      }

      promise = validator
        .initiate(values, field, accessToken)
        .then((response) => {
          const result = { ...asyncErrors };
          if (validator.fieldGroup) {
            validator.fieldGroup.forEach((groupField) => {
              _.set(result, groupField, undefined);
              _.set(
                result,
                groupField,
                validator.validate(values, response, groupField)
              );
            });
          } else {
            _.set(result, field, undefined);
            _.set(result, field, validator.validate(values, response, field));
          }
          throwIfError(result);
        });
    } else {
      const validations = [];
      Object.keys(validators).forEach((validatorKey) => {
        const validator = validators[validatorKey];
        if (/\[\]/.test(validatorKey)) {
          const allFields = validator.allFields(values);
          logger.info(
            "Adding all fields for key",
            validatorKey,
            "with fields",
            allFields
          );
          allFields.forEach((f) => {
            validations.push({
              field: f,
              promise: validator.initiate(values, f, accessToken),
              validator,
            });
          });
        } else {
          validations.push({
            field: validatorKey,
            promise: validator.initiate(values, validatorKey, accessToken),
            validator,
          });
        }
      });

      promise = Promise.all(validations.map((v) => v.promise)).then(
        (responses) => {
          const result = {}; // start empty as we're looping through all fields
          for (let i = 0; i < validations.length; i += 1) {
            _.set(
              result,
              validations[i].field,
              validations[i].validator.validate(values, responses[i])
            );
          }
          throwIfError(result);
        }
      );
    }

    return promise;
  };

export function scrollToFirstInvalidInput(errors) {
  const errorFields = getFieldNames(errors);
  logger.info("errors", errors, "errorFields", errorFields);
  // Using breakable for loop
  for (let i = 0; i < errorFields.length; i++) {
    const fieldName = `position-${errorFields[i]}`;
    // Checking if the marker exists in DOM
    const element = document.querySelectorAll(`[name="${fieldName}"]`);
    logger.info("fieldName", fieldName, "elements", element);
    if (element.length > 0) {
      element[0].scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
      break;
    } else {
      logger.error("Couldn't find DOM element where name = ", fieldName);
    }
  }
}

function getFieldNames(obj) {
  const result = [];

  if (!obj) {
    return result;
  }

  Object.keys(obj).forEach((key) => {
    if (_.isString(obj[key])) {
      result.push(key);
    } else if (_.isArray(obj[key])) {
      for (let i = 0; i < obj[key].length; i++) {
        getFieldNames(obj[key][i]).forEach((nestedKey) => {
          result.push(`${key}[${i}].${nestedKey}`);
        });
      }
    } else if (obj[key]) {
      getFieldNames(obj[key]).forEach((nestedKey) => {
        result.push(`${key}.${nestedKey}`);
      });
    }
  });

  return result;
}
