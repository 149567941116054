import { useAuth0 } from "@auth0/auth0-react";
import { authorizer, roles, useTenant } from "@certane/arcadia-web-components";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  addDocument,
  deleteDocument,
  deleteMediaById,
  getMediaById,
  patchMedia,
  updateDocument,
} from "../../../actions/media";
import EntityRelationshipCard from "../../../components/business/EntityRelationshipCard";
import ActionButton from "../../../components/common/ActionButton";
import ActionHeading from "../../../components/common/ActionHeading";
import AlertDialog from "../../../components/common/AlertDialog";
import BreadcrumbLink from "../../../components/common/BreadcrumbLink";
import Container from "../../../components/common/Container";
import HeaderBar from "../../../components/common/HeaderBar";
import InlineInputEditSaveCancel from "../../../components/common/InlineInputEditSaveCancel";
import PageHeading from "../../../components/common/PageHeading";
import PageSubheading from "../../../components/common/PageSubheading";
import Error404 from "../../../components/error/Error404";
import LoadingIndicator from "../../../components/LoadingIndicator";
import MediaCard from "../../../components/metropolis/MediaCard";
import MediaDocumentVersionsCard from "../../../components/metropolis/MediaDocumentVersionsCard";
import MediaLinksCard from "../../../components/metropolis/MediaLinksCard";
import {
  getActiveMedia,
  getLabels,
  getLoggedInUser,
  isLoading,
} from "../../../reducers";
import { deleteIcon, mediaIcon } from "../../../util/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  contents: {
    marginBottom: "75px",
  },
  tabs: {
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
  },
  tabRoot: {
    minWidth: "100px",
    padding: theme.spacing(2),
  },
  card: {
    marginBottom: theme.spacing(2),
  },
}));

const relationshipTypes = [
  "PROMOTER",
  "SUB_PROMOTER",
  "TRUSTEE",
  "RESPONSIBLE_ENTITY",
  "KIWISAVER_MANAGER",
];

const MediaId = ({
  match: { params },
  history,
  loggedInUser,
  loading,
  media,
  fieldLabels,
  localGetMediaById,
  localDeleteMediaById,
  localPatchMedia,
  localAddDocument,
  localUpdateDocument,
  localDeleteDocument,
}) => {
  const { getAccessTokenSilently, user } = useAuth0();
  const { tenant } = useTenant();
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);
  const [deleteOpen, setDeleteOpen] = useState(false);

  useEffect(() => {
    (async () => {
      const { mediaId } = params;
      const accessToken = await getAccessTokenSilently();
      localGetMediaById(mediaId, accessToken);
    })();
  }, []);

  const handleDelete = async () => {
    const accessToken = await getAccessTokenSilently();
    return localDeleteMediaById(media.id, accessToken).then(() => {
      history.push("/metropolis");
    });
  };

  if (!media && loading) {
    return <LoadingIndicator size={60} />;
  }

  if (!media && !loading) {
    return <Error404 />;
  }

  const canDelete = loggedInUser
    ? _.intersection([...roles.ADMIN_ROLES], loggedInUser.roles).length > 0
    : false;
  const readonly = !authorizer.check("metropolis", "editor", user, tenant?.id);

  const handlePatch = async (values, description) => {
    const accessToken = await getAccessTokenSilently();
    return localPatchMedia(media.id, values, description, accessToken);
  };

  const handleAddDocument = async (docVersion) => {
    const accessToken = await getAccessTokenSilently();
    localAddDocument(media.id, docVersion, accessToken);
  };

  const handlePatchDocument = async (versionId, changes) => {
    const accessToken = await getAccessTokenSilently();
    localUpdateDocument(
      media.id,
      versionId,
      changes,
      "Updated document",
      accessToken
    );
  };

  const handleDeleteDocument = async (versionId) => {
    const accessToken = await getAccessTokenSilently();
    localDeleteDocument(media.id, versionId, accessToken);
  };

  const headerActions = [];

  if (canDelete) {
    headerActions.push(
      <ActionButton
        tooltip="Delete media"
        icon={deleteIcon()}
        onClick={() => setDeleteOpen(true)}
      />
    );
  }

  return (
    <div className={classes.root}>
      <AlertDialog
        title="Delete media?"
        body="Deleting this media / file will cause its serving URL to stop working. This cannot be undone. Are you sure you want to delete it?"
        submitButtonText="Delete"
        open={deleteOpen}
        onCancel={() => setDeleteOpen(false)}
        onSubmit={handleDelete}
        data-cy="deleteMedia"
      />
      <HeaderBar>
        <BreadcrumbLink to="/metropolis" label="Content" includeArrow />
        <ActionHeading
          heading={
            <>
              <PageHeading
                icon={mediaIcon()}
                heading={
                  <InlineInputEditSaveCancel
                    value={media.name}
                    onChange={
                      readonly
                        ? null
                        : (name) => handlePatch({ name }, "Edited media name")
                    }
                    validate={(value) => !!value}
                    minWidth="800px"
                    readonly={readonly}
                  />
                }
              />
              <PageSubheading inset subheading={media.slug} />
            </>
          }
          actions={headerActions}
        />
      </HeaderBar>
      <Paper elevation={0} square>
        <Container>
          <Tabs
            value={activeTab}
            onChange={(event, value) => setActiveTab(value)}
            textColor="primary"
            variant="scrollable"
            scrollButtons="off"
            className={classes.tabs}
          >
            <Tab
              classes={{
                root: classes.tabRoot,
              }}
              label="Media details"
              data-cy="mediaDetails"
            />
            <Tab
              classes={{
                root: classes.tabRoot,
              }}
              label="Documents"
              data-cy="documents"
            />
          </Tabs>
        </Container>
      </Paper>
      <Container>
        {activeTab === 0 && (
          <>
            <EntityRelationshipCard
              className={classes.card}
              title={fieldLabels.labels.entityRelationship}
              baseEntity={media}
              fieldLabels={fieldLabels}
              onChange={
                readonly
                  ? null
                  : (values) =>
                      handlePatch(values, "Edited entity relationships")
              }
              customisations={[
                {
                  field: "entityRelationship.financialProducts",
                  state: media.singleProductNamespaced
                    ? "READ_ONLY"
                    : "INCLUDED",
                  helpText: media.singleProductNamespaced
                    ? "Media is fixed to single product"
                    : "",
                },
                {
                  field: "entityRelationship.organisations",
                  state: loggedInUser.externalUser ? "HIDDEN" : "INCLUDED",
                },
                {
                  field: "entityRelationship.businessServiceAreas",
                  state: "HIDDEN",
                },
                {
                  field: "entityRelationship.regions",
                  state: "HIDDEN",
                },
              ]}
              defaultVisibilityScope={{
                INTERNAL: "ORGANISATION",
                EXTERNAL: "ORGANISATION",
              }}
              autoPopulateEntitiesByRelationshipTypes={relationshipTypes}
              filterEntitiesByRelationshipTypes={relationshipTypes}
            />
            <MediaCard
              className={classes.card}
              media={media}
              title="Media details"
              fieldLabels={fieldLabels}
              onChange={
                readonly
                  ? null
                  : (values) => handlePatch(values, "Edited media details")
              }
              data-cy={fieldLabels}
            />
            <MediaLinksCard
              className={classes.card}
              media={media}
              title="Publishing"
              fieldLabels={fieldLabels}
              onChange={
                readonly
                  ? null
                  : (values) => handlePatch(values, "Edited media details")
              }
              data-cy={fieldLabels}
            />
          </>
        )}
        {activeTab === 1 && (
          <MediaDocumentVersionsCard
            className={classes.card}
            media={media}
            fieldLabels={fieldLabels}
            onAddDocument={readonly ? null : handleAddDocument}
            onPatchDocument={readonly ? null : handlePatchDocument}
            onDeleteDocument={readonly ? null : handleDeleteDocument}
            data-cy={fieldLabels}
          />
        )}
      </Container>
    </div>
  );
};

MediaId.propTypes = {
  history: PropTypes.object.isRequired,
  loggedInUser: PropTypes.object,
  localGetMediaById: PropTypes.func.isRequired,
  localDeleteMediaById: PropTypes.func.isRequired,
  localPatchMedia: PropTypes.func.isRequired,
  localAddDocument: PropTypes.func.isRequired,
  localUpdateDocument: PropTypes.func.isRequired,
  localDeleteDocument: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  media: PropTypes.object,
  fieldLabels: PropTypes.object.isRequired,
};

MediaId.defaultProps = {
  loggedInUser: null,
  media: null,
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
  media: getActiveMedia(state),
  loading: isLoading(state),
  fieldLabels: getLabels(state).Media,
});

export default connect(mapStateToProps, {
  localGetMediaById: getMediaById,
  localDeleteMediaById: deleteMediaById,
  localPatchMedia: patchMedia,
  localAddDocument: addDocument,
  localUpdateDocument: updateDocument,
  localDeleteDocument: deleteDocument,
})(MediaId);
