import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import classNames from "classnames";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { SubmissionError } from "redux-form";
import { getRiskById } from "../../actions/risks";
import {
  clearActiveWorkItem,
  createWorkItem,
  loadWorkItems,
} from "../../actions/workItems";
import {
  getActiveRisk,
  getReferenceDataType,
  getWorkItems,
  isLoading,
} from "../../reducers";
import logger from "../../util/logger";
import ExpandableForm from "../forms/ExpandableForm";
import RiskReassessmentForm, {
  RISK_REASSESSMENT_FORM_NAME,
} from "../forms/risk/RiskReassessmentForm";
import WorkItemListItem from "../workitem/WorkItemListItem";
import OpenCloseWorkItemFilter from "../workitem/OpenCloseWorkItemFilter";
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(2),
    border: "none",
    "&:before": {
      height: 0,
    },
  },
  header: {
    border: "none",
    paddingLeft: 0,
  },
}));

const RiskAssessmentHistoryCard = ({
  className,
  loading,
  risk,
  riskReassessments,
  types,
  localClearActiveWorkItem,
  localLoadWorkItems,
  localCreateWorkItem,
  localGetRiskById,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [filteredRiskAssessments, setFilteredRiskAssessments] = useState([]);

  const actionsForRiskSearchParameters = () => ({
    riskId: risk.id,
    types: ["RISK_REASSESSMENT"],
    includeTemplates: false,
    includeChildren: false,
    limit: 100,
    orderByField: "-created",
  });

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      localClearActiveWorkItem();
      localLoadWorkItems(actionsForRiskSearchParameters(), accessToken);
    })();
  }, [getAccessTokenSilently]);

  const handleSubmission = async (values) => {
    const reminderLeadTime = moment(values.dueDate).diff(
      moment(values.reminderDate),
      "days"
    );
    const workItem = {
      ...values,
      reminderDate: undefined,
      reminderLeadTime,
    };
    logger.debug(
      "submitting work item of type RiskReassessment with values",
      workItem
    );

    const accessToken = await getAccessTokenSilently();

    localCreateWorkItem(workItem, accessToken)
      .then(() => {
        setShowCreateForm(false);
        localLoadWorkItems(actionsForRiskSearchParameters(), accessToken);
        localGetRiskById(risk.id, accessToken);
      })
      .catch((error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  const mergedClassName = classNames(classes.root, className);

  const activeRiskReassessment = riskReassessments.find(
    (v) => v.status !== "CLOSED"
  );

  const initialValues = {
    type: "RISK_REASSESSMENT",
    associatedRisks: [{ id: risk.id }],
    category: "RISK_REASSESSMENT_GENERAL",
    title: `Risk reassessment - ${risk.title}`,
    owner: risk.owner,
  };

  return (
    <Card className={mergedClassName} elevation={0}>
      <CardHeader
        title="Risk reassessments"
        data-cy="riskAssessment"
        action={
          !activeRiskReassessment &&
          !loading && (
            <Tooltip title="Add reassessment" disableFocusListener>
              <div>
                <IconButton
                  onClick={() => setShowCreateForm(true)}
                  disabled={showCreateForm}
                  data-cy="addAsssessment"
                >
                  <AddIcon />
                </IconButton>
              </div>
            </Tooltip>
          )
        }
      />
      <ExpandableForm
        data-cy={RiskReassessmentForm}
        title="New risk reassessment"
        submitButtonText="Save"
        maxWidth="md"
        formComponent={RiskReassessmentForm}
        formName={RISK_REASSESSMENT_FORM_NAME}
        open={showCreateForm}
        onCancel={() => setShowCreateForm(false)}
        onSubmit={handleSubmission}
        initialValues={initialValues}
      />
      <OpenCloseWorkItemFilter
        workItems={riskReassessments}
        filteredWorkItemsChange={(filtered) =>
          setFilteredRiskAssessments(filtered)
        }
      />
      {filteredRiskAssessments.length > 0 && (
        <List dense>
          {filteredRiskAssessments.map((assessment) => (
            <WorkItemListItem
              key={assessment.id}
              workItem={assessment}
              types={types}
              linkTo={`/risk/risks/${risk.id}/reassessments/${assessment.id}`}
              data-cy={assessment}
            />
          ))}
        </List>
      )}
      {filteredRiskAssessments.length === 0 && !loading && (
        <CardContent>
          <em data-cy="noRiskAssessment">
            There are no risk reassessments associated with this risk
          </em>
        </CardContent>
      )}
    </Card>
  );
};

RiskAssessmentHistoryCard.propTypes = {
  className: PropTypes.string,

  // redux
  loading: PropTypes.bool.isRequired,
  types: PropTypes.object.isRequired,
  risk: PropTypes.object,
  riskReassessments: PropTypes.array.isRequired,
  localLoadWorkItems: PropTypes.func.isRequired,
  localClearActiveWorkItem: PropTypes.func.isRequired,
  localGetRiskById: PropTypes.func.isRequired,
  localCreateWorkItem: PropTypes.func.isRequired,
};

RiskAssessmentHistoryCard.defaultProps = {
  className: undefined,
  risk: null,
};

const mapStateToProps = (state) => ({
  loading: isLoading(state),
  risk: getActiveRisk(state),
  riskReassessments: getWorkItems(state),
  types: getReferenceDataType(state, "WorkItemType"),
});

export default connect(mapStateToProps, {
  localLoadWorkItems: loadWorkItems,
  localClearActiveWorkItem: clearActiveWorkItem,
  localGetRiskById: getRiskById,
  localCreateWorkItem: createWorkItem,
})(RiskAssessmentHistoryCard);
