import { withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import FormCard from "../../../common/FormCard";
import EditWorkItemComponentForm, {
  EDIT_WORK_ITEM_COMPONENT_FORM_NAME,
} from "../EditWorkItemComponentForm";
import FormChecklistReadonly from "./FormChecklistReadonly";

const styles = () => ({});

const DocumentFormChecklistCard = ({
  className,
  workItem,
  fieldLabels,
  onChange,
  customisations,
}) => (
  <FormCard
    data-cy={fieldLabels.labels.documentChecklist}
    className={className}
    title={fieldLabels.labels.documentChecklist}
    formTitle={`Edit ${fieldLabels.labels.documentChecklist}`}
    readOnlyView={() => (
      <FormChecklistReadonly
        workItem={workItem}
        customisations={customisations}
      />
    )}
    formComponent={EditWorkItemComponentForm}
    formName={EDIT_WORK_ITEM_COMPONENT_FORM_NAME}
    onSave={onChange}
    initialValues={{ documentChecklist: workItem.documentChecklist }}
    formProps={{
      maxWidth: "md",
      workItem,
      componentName: "DOCUMENT_CHECKLIST",
    }}
  />
);

DocumentFormChecklistCard.propTypes = {
  className: PropTypes.string,
  workItem: PropTypes.object.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  customisations: PropTypes.array,
};

DocumentFormChecklistCard.defaultProps = {
  customisations: [],
  className: undefined,
  onChange: null,
};

const mapStateToProps = () => ({});

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(DocumentFormChecklistCard);
