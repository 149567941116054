import { requestJSON } from "./http";

const logComponentError = (error) =>
  requestJSON({ path: "/error/component", method: "POST", data: error });
const logReduxError = (error) =>
  requestJSON({ path: "/error/redux", method: "POST", data: error });
const logUnhandledError = (error) =>
  requestJSON({ path: "/error/unhandled", method: "POST", data: error });

export default {
  logUnhandledError,
  logReduxError,
  logComponentError,
};
