import { withStyles } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React from "react";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import ReactMarkdown from "react-markdown";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  getReferenceDataDescription,
  getReferenceDataType,
} from "../../../../reducers";
import {
  appliesByCategory,
  getResolvedDefinition,
} from "../../../../util/workItemTypeUtils";

const normalise = (max, value) => (value * 100) / max;

const styles = (theme) => ({
  progressIndicator: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  checkbox: {
    cursor: "default",
    paddingLeft: 0,
  },
  checkboxRoot: {
    "&:hover": {
      backgroundColor: "transparent !important",
    },
  },
  notApplicable: {
    textDecoration: "line-through",
  },
  notApplicableMessage: {
    color: theme.palette.grey[500],
  },
  helpText: {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: 12,
    fontWeight: 400,
  },
});

const FormChecklistReadonly = ({
  classes,
  workItem,
  workItemTypes,
  getFormStatusDescription,
  customisations,
}) => {
  const { documentChecklist } = workItem;
  const definition = getResolvedDefinition(
    workItemTypes,
    workItem.type,
    workItem.parent?.type
  );

  const availableTypes = definition.documentFlags
    .filter((flagAssignment) => flagAssignment.inDocumentChecklist)
    .filter((flagAssignment) => appliesByCategory(workItem, flagAssignment));

  const checklistScore = normalise(
    availableTypes.length,
    Object.keys(documentChecklist).length
  );

  const getHelpText = () => {
    if (!customisations.length || !workItem.category) {
      return "";
    }

    const customisationMatch = customisations.find(({ categories }) =>
      categories.includes(workItem.category)
    );
    return customisationMatch && customisationMatch.helpText
      ? customisationMatch.helpText
      : "";
  };

  return (
    <>
      <div className={classes.progressIndicator}>
        <LinearProgress
          variant="determinate"
          color="secondary"
          value={checklistScore}
        />
        <Typography variant="caption">
          {Object.keys(documentChecklist).length}/{availableTypes.length}{" "}
          complete
        </Typography>
      </div>
      {availableTypes.map((flagAssignment) => (
        <Typography key={flagAssignment.documentFlag} variant="subtitle1">
          <Grid container alignItems="center">
            <Grid item>
              <Checkbox
                className={classes.checkbox}
                classes={{ root: classes.checkboxRoot }}
                readOnly
                color="default"
                icon={<NotInterestedIcon />}
                indeterminateIcon={<CheckBoxOutlineBlankIcon />}
                disableRipple
                disableFocusRipple
                value="formType"
                title={getFormStatusDescription(
                  documentChecklist[flagAssignment.documentFlag]
                )}
                checked={
                  documentChecklist[flagAssignment.documentFlag] === "INCLUDED"
                }
                indeterminate={
                  documentChecklist[flagAssignment.documentFlag] === undefined
                }
              />
            </Grid>
            <Grid item>
              <Typography variant="body2">
                {documentChecklist[flagAssignment.documentFlag] ===
                "NOT_APPLICABLE" ? (
                  <>
                    <span className={classes.notApplicable}>
                      {flagAssignment.documentFlagLabel}
                    </span>
                    &nbsp;
                    <span className={classes.notApplicableMessage}>
                      (Not Applicable)
                    </span>
                  </>
                ) : (
                  flagAssignment.documentFlagLabel
                )}
              </Typography>
            </Grid>
          </Grid>
        </Typography>
      ))}
      <Typography variant="subtitle2" className={classes.helpText}>
        <ReactMarkdown source={getHelpText()} />
      </Typography>
    </>
  );
};

FormChecklistReadonly.propTypes = {
  classes: PropTypes.object.isRequired,
  workItem: PropTypes.object.isRequired,
  customisations: PropTypes.array,
  workItemTypes: PropTypes.object.isRequired,
  getFormStatusDescription: PropTypes.func.isRequired,
};

FormChecklistReadonly.defaultProps = {
  customisations: [],
};

const mapStateToProps = (state) => ({
  workItemTypes: getReferenceDataType(state, "WorkItemType"),
  getFormStatusDescription: (id) =>
    getReferenceDataDescription(state, "FormStatus", id, "No response"),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(FormChecklistReadonly);
