import { formEncode, requestJSON } from "./http";

const decorateSearchParameters = (filter) =>
  filter
    ? { __type: "WorkItemSchedule", ...filter }
    : { __type: "WorkItemSchedule" };

// with pagination
const search = (filter, abortController, accessToken) =>
  requestJSON({
    path: "/work-item-schedules/search",
    method: "POST",
    data: decorateSearchParameters(filter),
    abortController,
    accessToken,
  });

const get = (workItemScheduleId, accessToken) =>
  requestJSON({
    path: `/work-item-schedules/${workItemScheduleId}`,
    accessToken,
  });

const patch = (workItemScheduleId, changes, description, accessToken) =>
  requestJSON({
    path: `/work-item-schedules/${workItemScheduleId}`,
    method: "PATCH",
    data: {
      fields: Object.keys(changes),
      changes,
      description,
    },
    accessToken,
  });

const projectPeriods = (params, accessToken) =>
  requestJSON({
    path: `/work-item-schedules?${formEncode(params)}`,
    accessToken,
  });

export default {
  search,
  get,
  patch,
  projectPeriods,
};
