import { normalize } from "normalizr";
import schemas from "../schemas";
import slaApi from "../services/api/slas";
import { asyncAction } from "./actions";

export const SLA_CREATE = "SLA_CREATE";
export const SLA_LIST = "SLA_LIST";
export const SLA_GET_BY_ID = "SLA_GET_BY_ID";
export const SLA_PATCH = "SLA_PATCH";
export const SLA_REMOVE = "SLA_REMOVE";

export const createSla = (sla, accessToken) =>
  asyncAction(SLA_CREATE, slaApi.create(sla, accessToken), {
    responseTransformer: (createdSla) => normalize(createdSla, schemas.sla),
  });

export const listSlas = (accessToken) =>
  asyncAction(SLA_LIST, slaApi.search(accessToken), {
    responseTransformer: (slas) => normalize(slas, schemas.arrayOfSlas),
  });

export const getSlaById = (id, accessToken) =>
  asyncAction(SLA_GET_BY_ID, slaApi.getById(id, accessToken), {
    responseTransformer: (sla) => normalize(sla, schemas.sla),
  });

export const patchSla = (id, changes, description, accessToken) =>
  asyncAction(SLA_PATCH, slaApi.patch(id, changes, description, accessToken), {
    responseTransformer: (sla) => normalize(sla, schemas.sla),
  });

export const deleteSla = (id, accessToken) =>
  asyncAction(SLA_REMOVE, slaApi.remove(id, accessToken), {
    responseTransformer: () => ({ id }),
  });
