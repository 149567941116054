import Badge from "@material-ui/core/Badge";
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  chipRoot: {
    background: theme.palette.grey[200],
    "&:active, &:focus": {
      background: theme.palette.grey[200],
    },
  },
  chipSelected: {
    color: theme.palette.common.white,
    background: theme.palette.secondary.main,
    "&:active, &:hover, &:focus": {
      background: theme.palette.secondary.main,
    },
  },
  chipDisabled: {
    color: theme.palette.grey[400],
    background: theme.palette.grey[200],
    "&:active, &:hover, &:focus": {
      background: theme.palette.grey[200],
    },
  },
  choice: {
    marginLeft: theme.spacing(1) / 2,
    marginRight: theme.spacing(1) / 2,
  },
  badge: {
    backgroundColor: theme.palette.swatch.grey1,
  },
}));

const chipClassName = (classes, option, disabled, selected) =>
  classNames({
    [classes.chipRoot]: true,
    [classes.chipSelected]: selected,
    [classes.chipDisabled]: disabled,
  });

const ChoiceChips = ({
  value,
  options,
  disabled,
  onChange,
  deselectable,
  multiselect,
}) => {
  const classes = useStyles();

  const isSelected = (option) => {
    if (multiselect) {
      return value.indexOf(option.value) !== -1;
    }
    return value === option.value;
  };

  const handleClick = (option) => {
    if (!deselectable && isSelected(option)) {
      return () => undefined;
    }

    const currentValue = value;

    if (deselectable && isSelected(option)) {
      if (multiselect) {
        // remove option value from selection
        return () =>
          onChange(
            currentValue.filter((v) => v !== option.value),
            currentValue
          );
      }
      return () => onChange("", currentValue);
    }

    if (multiselect) {
      // Add option value to selection
      return () => onChange([...currentValue, option.value], currentValue);
    }
    return () => onChange(option.value);
  };

  return (
    <div className={classes.root}>
      {options.map((option) => (
        <Tooltip
          key={option.value.id ? option.value.id : option.value}
          title={(!disabled && option.tooltip) || ""}
          enterDelay={100}
          disableFocusListener
        >
          <Badge
            className={classes.choice}
            badgeContent={option.badgeValue || 0}
            invisible={!option.badgeValue}
            max={99}
            color="secondary"
            classes={{
              colorSecondary: classes.badge,
            }}
          >
            <Chip
              classes={{
                root: chipClassName(
                  classes,
                  option,
                  disabled,
                  isSelected(option)
                ),
              }}
              label={option.label}
              onClick={disabled ? null : handleClick(option)}
              data-cy={option.label}
            />
          </Badge>
        </Tooltip>
      ))}
    </div>
  );
};

ChoiceChips.propTypes = {
  value: PropTypes.any,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  deselectable: PropTypes.bool,
  disabled: PropTypes.bool,
  multiselect: PropTypes.bool,
};

ChoiceChips.defaultProps = {
  value: undefined,
  deselectable: true,
  disabled: false,
  multiselect: false,
};

export default ChoiceChips;
