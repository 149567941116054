import { useAuth0 } from "@auth0/auth0-react";
import { authorizer, useTenant } from "@certane/arcadia-web-components";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { loadMetric as loadQuestionMetric } from "../actions/questions";
import { loadMetric as loadWorkItemMetric } from "../actions/workItems";
import Container from "../components/common/Container";
import HeaderBar from "../components/common/HeaderBar";
import PageHeading from "../components/common/PageHeading";
import MetricCard from "../components/MetricCard";
import WorkItemFloatingActionButton from "../components/workitem/WorkItemFloatingActionButton";
import {
  getLoggedInUser,
  getQuestionsMetrics,
  getReferenceDataType,
  getWorkItemMetrics,
} from "../reducers";
import { helpIcon, workItemDashboardIcon, workItemIcon } from "../util/icons";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(12),
  },
  card: {
    minWidth: 200,
    minHeight: 200,
    maxHeight: 200,
  },
  whiteText: {
    color: "white",
    marginLeft: theme.spacing(2),
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const { getAccessTokenSilently, user } = useAuth0();
  const { tenant } = useTenant();
  const history = useHistory();
  const loggedInUser = useSelector(getLoggedInUser);
  const workItemMetricValues = useSelector(getWorkItemMetrics);
  const questionMetricValues = useSelector(getQuestionsMetrics);
  const customSearchTypes = useSelector((state) =>
    getReferenceDataType(state, "CustomSearchType")
  );

  const dispatch = useDispatch();

  const [workItemSearchTypes] = useState(
    customSearchTypes.ids
      .map((id) => customSearchTypes.values[id])
      .filter((type) => _.startsWith(type.id, "WORK_ITEM"))
      .filter(
        (type) =>
          loggedInUser &&
          _.intersection(type.props.visibleTo, loggedInUser.roles).length > 0
      )
  );
  const [questionSearchTypes] = useState(
    customSearchTypes.ids
      .map((id) => customSearchTypes.values[id])
      .filter((type) => _.startsWith(type.id, "QUESTION_"))
      .filter(
        (type) =>
          loggedInUser &&
          _.intersection(type.props.visibleTo, loggedInUser.roles).length > 0
      )
  );

  useEffect(() => {
    getAccessTokenSilently().then((accessToken) => {
      workItemSearchTypes.forEach((type) =>
        dispatch(loadWorkItemMetric(type.id, null, accessToken))
      );
      questionSearchTypes.forEach((type) =>
        dispatch(
          loadQuestionMetric(type.id, type.props.searchParameters, accessToken)
        )
      );
    });
  }, []);

  const redirectTo = (namedQuery) => {
    history.push("/work-items", { filter: { namedQuery } });
  };

  const getQuestionMetric = (type) => {
    const value = questionMetricValues[type.id];
    return value === null || value === undefined ? (
      <CircularProgress className={classes.whiteText} size={20} />
    ) : (
      `(${value} ${type.description})`
    );
  };

  const WorkItemIcon = workItemIcon("test");
  const HelpIcon = helpIcon();

  const workItemEditor = useMemo(
    () => authorizer.check("work-item", "editor", user, tenant.id),
    [user]
  );

  return (
    <>
      <HeaderBar>
        <PageHeading
          icon={workItemDashboardIcon()}
          heading="Dashboard"
          data-cy="dashboard"
        />
      </HeaderBar>
      {authorizer.check("work-item", "editor", user, tenant?.id) && (
        <WorkItemFloatingActionButton />
      )}
      <Container className={classes.container}>
        <Grid
          container
          spacing={5}
          alignItems="center"
          justifyContent="flex-start"
        >
          <Grid item xs={12}>
            <Button
              size="large"
              variant="contained"
              color="secondary"
              fullWidth
              style={{
                height: "55px",
                boxShadow: "none",
              }}
              onClick={() =>
                history.push("/work-items", { filter: { namedQuery: null } })
              }
              data-cy="browseworkitems"
            >
              <WorkItemIcon fontSize="small" data-cy="browseWorkItems" /> &nbsp;
              Browse Work Items
            </Button>
          </Grid>

          {workItemEditor &&
            questionSearchTypes.map((type) => (
              <Grid item xs={12} style={{ paddingTop: "0" }} key={type.id}>
                <Button
                  size="large"
                  variant="contained"
                  color="secondary"
                  fullWidth
                  style={{
                    height: "55px",
                    boxShadow: "none",
                  }}
                  onClick={() => history.push("/questions")}
                  data-cy="myquestions"
                >
                  <HelpIcon fontSize="small" data-cy="questions" /> &nbsp;
                  Questions {getQuestionMetric(type)}
                </Button>
              </Grid>
            ))}

          {workItemEditor &&
            workItemSearchTypes.map((type) => (
              <Grid item xs={12} md={6} key={type.id}>
                <MetricCard
                  className={classes.card}
                  title={type.description}
                  value={workItemMetricValues[type.id]}
                  onClick={() => redirectTo(type.id)}
                  id={type.id}
                  data-cy={workItemMetricValues[type.id]}
                />
              </Grid>
            ))}
        </Grid>
      </Container>
    </>
  );
};

export default Dashboard;
