import { Tenant } from "@certane/arcadia-web-components";
import _ from "lodash";
import { RefDataItem, ReferenceData } from "../../model/ReferenceData";
import { User } from "../../model/User";

export type FilteredTypes = {
  ids: string[];
  values: Record<string, RefDataItem>;
};

export const creatableWorkItemTypes = ["WORK_ITEMS"];
export const filterableWorkItemTypes = ["AUDIT", "CONTROLS", "WORK_ITEMS"];

export type WorkItemTypeFilterOptions = {
  workItemTypes?: string[];
  additionalFilter?: (workItemType: RefDataItem) => boolean;
};

const initialOptions = {
  additionalFilter: () => true,
};

const getFilteredTypes = (
  types: ReferenceData,
  loggedInUser: User,
  tenant: Tenant,
  options: WorkItemTypeFilterOptions = initialOptions
): ReferenceData => {
  const enabledFlags = tenant
    ? Object.keys(tenant.featureFlags).filter((flagId) => {
        return tenant.featureFlags[flagId];
      })
    : [];

  const enabledWorkItemFeatures = (workItemType: RefDataItem) =>
    _.intersection(workItemType.props.definition.featureFlagIds, enabledFlags)
      .length > 0;

  const visibleToUser = (workItemType: RefDataItem) =>
    loggedInUser &&
    _.intersection(workItemType.props.definition.visibleTo, loggedInUser.roles)
      .length > 0;

  const fTypes: FilteredTypes = { ids: [], values: {} };

  types.ids
    .map((id) => types.values[id])
    .filter(enabledWorkItemFeatures)
    .filter(visibleToUser)
    .filter((workItemType) =>
      options.workItemTypes
        ? options.workItemTypes.includes(workItemType.props.definition.module)
        : true
    )
    .filter((workItemType) =>
      options.additionalFilter ? options.additionalFilter(workItemType) : true
    )
    .forEach((type) => {
      fTypes.ids.push(type.props.definition.id);
      fTypes.values[type.props.definition.id] = type;
    });
  return {
    values: fTypes.values,
    ids: _.orderBy(fTypes.ids, (typeId) => fTypes.values[typeId].description),
  };
};

export default getFilteredTypes;
