import { History } from "history";
import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useAuth0 } from "@auth0/auth0-react";
import FloatingActionButton from "../../../components/common/FloatingActionButton";
import fundApi from "../../../services/api/funds";
import fundsApi from "../../../services/api/funds";
import HeaderBar from "../../../components/common/HeaderBar";
import ActionHeading from "../../../components/common/ActionHeading";
import PageHeading from "../../../components/common/PageHeading";
import { fundIcon } from "../../../util/icons";
import Container from "../../../components/common/Container";
import FundListing from "../../../components/funds/FundListing";
import { getLabels, getReferenceDataDescription } from "../../../reducers";
import { connect } from "react-redux";
import { SearchPagination } from "../../../model/SearchPagination";
import { Fund } from "../../../model/fund";
import { SearchSortBy } from "../../../model/SearchSortBy";
import logger from "../../../util/logger";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
}));

interface Props {
  history: History;
  getRelationshipTypeDescription: (key: string) => string;
  getRegionDescription: (key: string) => string;
  fieldLabels: { labels: Record<string, string> };
}

const FundListingPage: React.FC<Props> = ({
  history,
  getRelationshipTypeDescription,
  getRegionDescription,
  fieldLabels,
}: Props) => {
  const classes = useStyles();

  const { getAccessTokenSilently } = useAuth0();

  const [pagination, setPagination] = useState<SearchPagination<Fund>>({
    offset: 0,
    pageSize: 20,
    resultCount: 0,
  });

  const [sortBy, setSortBy] = useState({
    field: "name",
    direction: "asc",
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [funds, setFunds] = useState<Fund[]>([]);

  const fetchFunds = async (
    pagination: SearchPagination<Fund>,
    sortBy: SearchSortBy
  ) => {
    setLoading(true);
    const accessToken = await getAccessTokenSilently();

    const { results, ...paginationData } = await fundsApi.search(
      {
        orderByField: `${sortBy.direction === "desc" ? "-" : ""}${
          sortBy.field
        }`,
        limit: pagination.pageSize,
        offset: pagination.offset,
      },
      null,
      accessToken
    );

    setLoading(false);
    setFunds(results);
    setPagination(paginationData);
  };

  useEffect(() => {
    fetchFunds(pagination, sortBy);
  }, [getAccessTokenSilently, sortBy]);

  const handlePrevious = () => {
    if (pagination.previousOffset === undefined) return;
    const newPagination: SearchPagination<Fund> = {
      ...pagination,
      offset: pagination.previousOffset,
    };
    fetchFunds(newPagination, sortBy);
  };

  const handleNext = () => {
    if (pagination.nextOffset === undefined) return;
    const newPagination: SearchPagination<Fund> = {
      ...pagination,
      offset: pagination.nextOffset,
    };
    fetchFunds(newPagination, sortBy);
  };

  const updateFund = async (id: string, values: Partial<Fund>) => {
    const accessToken = await getAccessTokenSilently();
    try {
      const updated: Fund = await fundApi.patch(
        id,
        values,
        "Updated fund",
        accessToken
      );
      setFunds(funds.map((f: Fund) => (f.id === id ? updated : f)));
      return updated;
    } catch (e) {
      logger.error(e);
    }
  };

  return (
    <div className={classes.root}>
      <HeaderBar>
        <ActionHeading
          heading={<PageHeading icon={fundIcon()} heading="Funds" />}
          actions={[]}
        />
      </HeaderBar>
      <FloatingActionButton onClick={() => history.push("/admin/funds/new")} />
      <Container>
        <FundListing
          funds={funds}
          loading={loading}
          pagination={pagination}
          onPrevious={handlePrevious}
          onNext={handleNext}
          sortBy={sortBy}
          updateSort={setSortBy}
          onChange={updateFund}
          onSelect={(fund) => history.push(`/admin/funds/${fund.id}`)}
          getRelationshipTypeDescription={getRelationshipTypeDescription}
          getRegionDescription={getRegionDescription}
          fieldLabels={fieldLabels}
        />
      </Container>
    </div>
  );
};

const mapStateToProps = (state: Map<string, unknown>) => ({
  getRelationshipTypeDescription: (relationshipType: string) =>
    getReferenceDataDescription(state, "RelationshipType", relationshipType),
  getRegionDescription: (region: string) =>
    getReferenceDataDescription(state, "Region", region),
  fieldLabels: getLabels(state).Fund,
});

export default connect(mapStateToProps)(FundListingPage);
