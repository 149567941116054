import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  row: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: theme.spacing(0.333),
    marginBottom: theme.spacing(0.33),
    color: "#727272",
  },
  textRight: {
    textAlign: "right",
    paddingRight: theme.spacing(1.5),
  },
});

const TextValueRow = ({ text, value, classes }) => (
  <div className={classes.row}>
    <div>{text}</div>
    <div className={classes.textRight}>{value}</div>
  </div>
);

TextValueRow.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default withStyles(styles)(TextValueRow);
