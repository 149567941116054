import green from "@material-ui/core/colors/green";
import orange from "@material-ui/core/colors/orange";
import red from "@material-ui/core/colors/red";
import yellow from "@material-ui/core/colors/yellow";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import React, { Component } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  riskConsequenceRating: {
    color: theme.palette.grey[500],
  },
  riskConsequenceRatingInsignificant: {
    color: green[500],
  },
  riskConsequenceRatingMinor: {
    color: green[600],
  },
  riskConsequenceRatingModerate: {
    color: yellow[600],
  },
  riskConsequenceRatingMajor: {
    color: orange[500],
  },
  riskConsequenceRatingCritical: {
    color: red[500],
  },
});

class RiskConsequenceRating extends Component {
  ratingClassName = (riskConsequenceRatingId) => {
    const { classes } = this.props;
    return classNames({
      [classes.riskConsequenceRating]: true,
      [classes.riskConsequenceRatingInsignificant]:
        riskConsequenceRatingId === "INSIGNIFICANT",
      [classes.riskConsequenceRatingMinor]: riskConsequenceRatingId === "MINOR",
      [classes.riskConsequenceRatingModerate]:
        riskConsequenceRatingId === "MODERATE",
      [classes.riskConsequenceRatingMajor]: riskConsequenceRatingId === "MAJOR",
      [classes.riskConsequenceRatingCritical]:
        riskConsequenceRatingId === "CRITICAL",
    });
  };

  render() {
    const { riskConsequenceRatingText, riskConsequenceRatingId } = this.props;
    return (
      <Typography
        variant="caption"
        component="span"
        className={this.ratingClassName(riskConsequenceRatingId)}
      >
        {riskConsequenceRatingText}
      </Typography>
    );
  }
}

RiskConsequenceRating.propTypes = {
  classes: PropTypes.object.isRequired,
  riskConsequenceRatingText: PropTypes.string,
  riskConsequenceRatingId: PropTypes.string,
};

RiskConsequenceRating.defaultProps = {
  riskConsequenceRatingText: null,
  riskConsequenceRatingId: null,
};

export default withStyles(styles)(RiskConsequenceRating);
