import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  comment: {
    paddingLeft: "24px",
  },
  paperWidthSm: {
    width: Math.max(theme.breakpoints.values.sm, 600),
  },
  helpText: {
    fontSize: theme.typography.body1.fontSize,
    marginBottom: theme.spacing(2),
  },
});

const EditTimesheetDialog = ({
  classes,
  open,
  onClose,
  onSubmit,
  title,
  actionText,
  hours,
}) => {
  const [currentHours, setCurrentHours] = useState(hours || "0");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const onEnter = () => {
    setCurrentHours(hours);
  };

  const submit = () => {
    onSubmit(currentHours);
    setCurrentHours("0");
  };

  return (
    <Dialog
      classes={{
        paperWidthSm: classes.paperWidthSm,
      }}
      fullScreen={fullScreen}
      open={open}
      onEnter={onEnter}
      onClose={onClose}
      aria-labelledby="Edit timesheet"
    >
      <DialogTitle id="Edit timesheet" data-cy="editTimeSheet">
        {title}
      </DialogTitle>
      <DialogContent>
        <TextField
          placeholder="Enter hours"
          value={currentHours}
          onChange={(e) => setCurrentHours(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">hours</InputAdornment>,
            type: "number",
          }}
          autoFocus
          required
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={submit}>
          {actionText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EditTimesheetDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  actionText: PropTypes.string,
  hours: PropTypes.number,
};

EditTimesheetDialog.defaultProps = {
  title: "Enter time spent",
  actionText: "Update time spent",
  hours: 0,
};

export default withStyles(styles)(EditTimesheetDialog);
