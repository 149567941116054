import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { SubmissionError } from "redux-form";
import {
  createMeasureUpdatesForRisks,
  createReassessmentsForRisks,
} from "../../../actions/risks";
import ActionButton from "../../../components/common/ActionButton";
import ActionHeading from "../../../components/common/ActionHeading";
import Container from "../../../components/common/Container";
import FloatingActionButton from "../../../components/common/FloatingActionButton";
import HeaderBar from "../../../components/common/HeaderBar";
import PageHeading from "../../../components/common/PageHeading";
import FormDialog from "../../../components/forms/FormDialog";
import BulkRiskMeasureUpdatesForm, {
  BULK_RISK_MEASURE_UPDATES_FORM_NAME,
} from "../../../components/forms/risk/BulkRiskMeasureUpdatesForm";
import BulkRiskReassessmentsForm, {
  BULK_RISK_REASSESSMENTS_FORM_NAME,
} from "../../../components/forms/risk/BulkRiskReassessmentsForm";
import RiskFilters from "../../../components/risk/RiskFilters";
import RiskListing from "../../../components/risk/RiskListing";
import { getLoggedInUser, getRisks } from "../../../reducers";
import { authorizer, roles, useTenant } from "@certane/arcadia-web-components";
import {
  clearIcon,
  downloadIcon,
  printIcon,
  riskIcon,
  workItemIcon,
} from "../../../util/icons";
import useLocationStateFilter from "../../../util/locationStateFilter";
import usePersistedState from "../../../util/persistedState";
import { performExport } from "../../../util/asyncRequestHelper";
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  contents: {
    marginBottom: "75px",
  },
}));

const defaultFilter = {
  textSearch: "",
  appetites: [],
  categories: [],
  ownerIds: [],
  riskRegisters: [],
  riskRatings: [],
};

const Index = ({
  history,
  loggedInUser,
  risks,
  localCreateReassessmentsForRisks,
  localCreateMeasureUpdatesForRisks,
}) => {
  const { getAccessTokenSilently, user } = useAuth0();
  const { tenant } = useTenant();
  const classes = useStyles();
  const [createReassessmentsDialogOpen, setCreateReassessmentsDialogOpen] =
    useState(false);
  const [createMeasureUpdateDialogOpen, setCreateMeasureUpdateDialogOpen] =
    useState(false);
  const [downloadingFormat, setDownloadingFormat] = useState(null);
  const [sortBy, setSortBy] = usePersistedState(
    `risk-listing-sort-${loggedInUser.email}`,
    {
      field: "title",
      direction: "asc",
    }
  );
  const [filter, setFilter] = usePersistedState(
    `risk-listing-filter-${loggedInUser.email}`,
    defaultFilter,
    ["textSearch"]
  );
  useLocationStateFilter(setFilter, history);

  const clearFilter = () => setFilter({ ...defaultFilter });

  const updateFilter = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  const getSearchParameters = () => ({
    ...filter,
    orderByField: `${sortBy.direction === "desc" ? "-" : ""}${sortBy.field}`,
    limit: 100,
    offset: 0,
  });

  const onDownload = async (format) => {
    const accessToken = await getAccessTokenSilently();
    setDownloadingFormat(format);
    const searchParameters = getSearchParameters();

    performExport(
      "Risk",
      searchParameters,
      format,
      accessToken,
      () => setDownloadingFormat(null),
      "Risks download",
      "Please wait while we export your risks",
      "This window can be closed"
    );
  };

  const onCreateReassessments = async (values) => {
    const accessToken = await getAccessTokenSilently();
    const reminderLeadTime = moment(values.dueDate).diff(
      moment(values.reminderDate),
      "days"
    );
    localCreateReassessmentsForRisks(
      risks.map((r) => r.id),
      values.dueDate,
      reminderLeadTime,
      accessToken
    )
      .then(() => {
        setCreateReassessmentsDialogOpen(false);
      })
      .catch((error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  const onCreateMeasureUpdates = async (values) => {
    const accessToken = await getAccessTokenSilently();
    const reminderLeadTime = moment(values.dueDate).diff(
      moment(values.reminderDate),
      "days"
    );
    localCreateMeasureUpdatesForRisks(
      risks.map((r) => r.id),
      values.dueDate,
      reminderLeadTime,
      accessToken
    )
      .then(() => {
        setCreateMeasureUpdateDialogOpen(false);
      })
      .catch((error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  const isComplianceOwner = loggedInUser
    ? _.intersection([roles.COMPLIANCE_OWNER], loggedInUser.roles).length > 0
    : false;
  const pdfDownloadEnabled = true;
  const csvDownloadEnabled = false;

  const actions = [
    <ActionButton
      key="clearFilter"
      tooltip="Clear filters"
      icon={clearIcon()}
      onClick={clearFilter}
      data-cy="clear-filters-button"
    />,
  ];
  if (isComplianceOwner) {
    actions.push(
      <ActionButton
        tooltip="Create measure update tasks"
        icon={workItemIcon("RISK_MEASURE_UPDATE")}
        onClick={() => setCreateMeasureUpdateDialogOpen(true)}
        disabled={risks.length === 0}
      />
    );
    actions.push(
      <ActionButton
        tooltip="Create risk reassessment tasks"
        icon={workItemIcon("RISK_REASSESSMENT")}
        onClick={() => setCreateReassessmentsDialogOpen(true)}
        disabled={risks.length === 0}
      />
    );
  }
  if (pdfDownloadEnabled) {
    actions.push(
      <ActionButton
        tooltip="Download"
        icon={printIcon()}
        onClick={() => onDownload("PDF")}
        loading={downloadingFormat === "PDF"}
        disabled={risks.length === 0}
      />
    );
  }
  if (csvDownloadEnabled) {
    actions.push(
      <ActionButton
        tooltip="Download"
        icon={downloadIcon()}
        onClick={() => onDownload("CSV")}
        loading={downloadingFormat === "CSV"}
      />
    );
  }

  return (
    <div className={classes.root}>
      <FormDialog
        title={`Create reassessment tasks for ${risks.length} risks`}
        submitButtonText="Confirm"
        formComponent={BulkRiskReassessmentsForm}
        formName={BULK_RISK_REASSESSMENTS_FORM_NAME}
        open={createReassessmentsDialogOpen}
        onCancel={() => setCreateReassessmentsDialogOpen(false)}
        onSubmit={onCreateReassessments}
      />
      <FormDialog
        title={`Create measure update tasks for ${risks.length} risks`}
        submitButtonText="Confirm"
        formComponent={BulkRiskMeasureUpdatesForm}
        formName={BULK_RISK_MEASURE_UPDATES_FORM_NAME}
        open={createMeasureUpdateDialogOpen}
        onCancel={() => setCreateMeasureUpdateDialogOpen(false)}
        onSubmit={onCreateMeasureUpdates}
      />
      <HeaderBar fluid>
        <ActionHeading
          heading={<PageHeading icon={riskIcon()} heading="Risks" />}
          actions={actions}
        />
      </HeaderBar>
      {authorizer.check("risk", "editor", user, tenant?.id) && (
        <FloatingActionButton onClick={() => history.push("/risk/risks/new")} />
      )}
      <RiskFilters filter={filter} updateFilter={updateFilter} />
      <Container fluid className={classes.contents}>
        <RiskListing
          history={history}
          filter={filter}
          clearFilter={clearFilter}
          sortBy={sortBy}
          updateSort={setSortBy}
        />
      </Container>
    </div>
  );
};

Index.propTypes = {
  loggedInUser: PropTypes.object,
  risks: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  localCreateReassessmentsForRisks: PropTypes.func.isRequired,
  localCreateMeasureUpdatesForRisks: PropTypes.func.isRequired,
};

Index.defaultProps = {
  loggedInUser: null,
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
  risks: getRisks(state),
});

export default connect(mapStateToProps, {
  localCreateReassessmentsForRisks: createReassessmentsForRisks,
  localCreateMeasureUpdatesForRisks: createMeasureUpdatesForRisks,
})(Index);
