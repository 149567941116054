import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { helpIcon } from "../../../../util/icons";
import {
  actionIsEnabled,
  getActionsForWorkItem,
} from "../../../../util/workItemTypeUtils";
import LabelStateSelect from "../../../common/LabelStateSelect";

export function getStatusName(workItem, definition) {
  const statusAssignment = definition.statuses.find(
    (statusAss) => statusAss.status === workItem.status
  );

  return statusAssignment ? statusAssignment.name : workItem.status;
}

export function isClosed(workItem, definition) {
  const statusAssignment = definition.statuses.find(
    (statusAss) => statusAss.status === workItem.status
  );

  return statusAssignment ? statusAssignment.endState : false;
}

const styles = (theme) => ({
  statusNew: {
    color: theme.status.new,
  },
  statusOpen: {
    color: theme.status.open,
  },
  statusExternalReview: {
    color: theme.status.externalReview,
  },
  statusPending: {
    color: theme.status.pending,
  },
  statusOnHold: {
    color: theme.status.onHold,
  },
  statusClosed: {
    color: theme.status.closed,
  },
  disabled: {
    color: theme.palette.text.disabled,
    width: "100%",
  },
});

function statusClassNames(classes, className, status) {
  return classNames({
    [classes.statusNew]: status === "NEW",
    [classes.statusOpen]: status === "OPEN" || status === "STARTED",
    [classes.statusExternalReview]: status === "EXTERNAL_REVIEW",
    [classes.statusPending]:
      status === "PENDING_SUBMITTER" ||
      status === "PENDING_COUNTERPARTY_SIGNATURE",
    [classes.statusOnHold]: status === "ON_HOLD" || status === "MONITORING",
    [classes.statusClosed]: status === "CLOSED",
    [className]: !!className,
  });
}

const WorkItemStatus = ({
  classes,
  loggedInUser,
  className,
  closable,
  readonly,
  workItem,
  definition,
  onStatusChange,
  startWorkItemAction,
}) => {
  const closer = useRef(null);

  const closeAction = getActionsForWorkItem(workItem, definition, loggedInUser)
    .filter((actionAssignment) => actionAssignment.visible)
    .find((actionAssignment) => actionAssignment.action === "CLOSE");

  const closeEnabledCheck = closeAction
    ? actionIsEnabled(workItem, closeAction, loggedInUser)
    : { enabled: false };

  const handleStatusChange = (statusAssignment) => {
    closer.current();

    if (statusAssignment.status !== workItem.status) {
      if (statusAssignment.endState) {
        if (closeAction) {
          startWorkItemAction(closeAction);
        }
      } else {
        onStatusChange(statusAssignment.status);
      }
    }
  };

  const statusLabel = getStatusName(workItem, definition);

  const typeStatuses = definition.statuses;

  const combinedClassName = statusClassNames(
    classes,
    className,
    workItem.status
  );
  const HelpIcon = helpIcon();

  return (
    <>
      {!onStatusChange && (
        <div className={combinedClassName} data-cy={statusLabel}>
          {statusLabel}
        </div>
      )}
      {onStatusChange && (
        <LabelStateSelect
          setCloser={(closeFunc) => {
            closer.current = closeFunc;
          }}
          label={statusLabel}
          readonly={readonly}
          className={combinedClassName}
          data-cy={statusLabel}
        >
          {typeStatuses
            .filter((statusAssignment) => statusAssignment.selectable)
            .filter(
              (statusAssignment) =>
                !statusAssignment.endState || (!!closeAction && closable)
            )
            .map((statusAssignment) => {
              const enabled =
                !statusAssignment.endState || closeEnabledCheck.enabled;

              return (
                <MenuItem
                  data-cy={statusAssignment.status}
                  key={statusAssignment.status}
                  value={statusAssignment.status}
                  disabled={workItem.status === statusAssignment.status}
                  onClick={
                    !enabled ? null : () => handleStatusChange(statusAssignment)
                  }
                >
                  {enabled ? (
                    statusAssignment.name
                  ) : (
                    <>
                      <span className={classes.disabled}>
                        {statusAssignment.name}
                      </span>
                      <Tooltip
                        title={closeEnabledCheck.disabledMessage}
                        disableFocusListener
                      >
                        <HelpIcon color="disabled" />
                      </Tooltip>
                    </>
                  )}
                </MenuItem>
              );
            })}
        </LabelStateSelect>
      )}
    </>
  );
};

WorkItemStatus.propTypes = {
  classes: PropTypes.object.isRequired,
  loggedInUser: PropTypes.object,
  className: PropTypes.string,
  workItem: PropTypes.object.isRequired,
  onStatusChange: PropTypes.func,
  closable: PropTypes.bool,
  readonly: PropTypes.bool.isRequired,
  definition: PropTypes.object.isRequired,
  startWorkItemAction: PropTypes.func,
};

WorkItemStatus.defaultProps = {
  className: undefined,
  loggedInUser: null,
  closable: true,
  onStatusChange: null,
  startWorkItemAction: () => {
    // do nothing.
  },
};

export default withStyles(styles)(WorkItemStatus);
