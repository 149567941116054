import { LOCATION_CHANGE } from "connected-react-router";

const routerLocations = (state = [], action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      if (
        state.length === 0 ||
        state[0].location.key !== action.payload.location.key
      ) {
        return [action.payload, ...state];
      }
      return state;
    default:
      return state;
  }
};

export default routerLocations;
// selectors
export const getLocations = (state) => state;
