import {
  WORK_ITEM_ACTION_START,
  WORK_ITEM_ACTION_STOP,
} from "../actions/workItemActions";

export default (state = null, action) => {
  switch (action.type) {
    case WORK_ITEM_ACTION_START:
      return action.activeAction;
    case WORK_ITEM_ACTION_STOP:
      return null;
    default:
      break;
  }
  return state;
};
