import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import ComponentField from "../../../forms/wrapper/ComponentField";
import WrappedFilePicker from "../../../forms/wrapper/WrappedFilePicker";
import EmailFields from "../../action/EmailFields";

const InitialEmailFields = ({
  fieldLabels,
  customisations,
  change,
  untouch,
  formName,
  initialEmail,
  ...rest
}) => {
  const [recordInitialEmail, setRecordInitialEmail] = useState(!!initialEmail);
  const [uploadNamespace] = useState("workitem-action");

  useEffect(() => {
    if (recordInitialEmail) {
      if (!initialEmail) {
        // initialise it if it's currently null
        change("initialEmail", {
          incoming: true,
          to: [],
        });
      }
    } else {
      change("initialEmail", null);
    }
  }, [recordInitialEmail]);

  return (
    <>
      <FormControlLabel
        margin="normal"
        control={
          <Switch
            checked={recordInitialEmail}
            onChange={() => setRecordInitialEmail(!recordInitialEmail)}
          />
        }
        label="Record initial email"
      />
      {recordInitialEmail && (
        <>
          <EmailFields
            baseName="initialEmail"
            fieldLabels={fieldLabels.nestedTypes.initialEmail}
            formName={formName}
            customisations={customisations}
            change={change}
            untouch={untouch}
            data-cy={fieldLabels}
            {...rest}
          />
          <ComponentField
            name="initialEmail.attachments"
            component={WrappedFilePicker}
            label={fieldLabels.nestedTypes.initialEmail.labels.attachments}
            fullWidth
            uploadNamespace={uploadNamespace}
            helperText="Please upload email attachments"
            customisations={customisations}
            change={change}
            untouch={untouch}
            formName={formName}
            data-cy="emailAttachments"
          />
        </>
      )}
    </>
  );
};

InitialEmailFields.propTypes = {
  customisations: PropTypes.array,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  initialEmail: PropTypes.object,
};

InitialEmailFields.defaultProps = {
  customisations: [],
  initialEmail: undefined,
};

const mapStateToProps = (state, { formName }) => {
  const reduxFormSelector = formValueSelector(formName);
  return {
    initialEmail: reduxFormSelector(state, "initialEmail"),
  };
};

export default connect(mapStateToProps)(InitialEmailFields);
