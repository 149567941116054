import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React, { Component } from "react";
import HomeIcon from "@material-ui/icons/Home";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";

const styles = (theme) => ({
  icon: {
    marginRight: theme.spacing(1),
  },
});

class ContactDetailsReadOnly extends Component {
  render() {
    const { classes, contact, typeOverride } = this.props;

    const typeDecoratedName = () => {
      const { name, type } = contact;
      if (typeOverride) {
        return `${name} (${typeOverride.toLowerCase()})`;
      }
      if (type) {
        return `${name} (${type.toLowerCase()})`;
      }
      return name;
    };

    return (
      <>
        {contact ? (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Typography variant="subtitle1">
                {contact && contact.name ? typeDecoratedName() : "-"}
              </Typography>
              {contact && contact.address && (
                <Grid container direction="row" alignItems="center">
                  <HomeIcon
                    color="action"
                    fontSize="small"
                    className={classes.icon}
                  />
                  <Typography variant="body1">{contact.address}</Typography>
                </Grid>
              )}
              {contact && contact.phone && (
                <Grid container direction="row" alignItems="center">
                  <PhoneIcon
                    color="action"
                    fontSize="small"
                    className={classes.icon}
                  />
                  <Typography variant="body1">{contact.phone}</Typography>
                </Grid>
              )}
              {contact && contact.email && (
                <Grid container direction="row" alignItems="center">
                  <EmailIcon
                    color="action"
                    fontSize="small"
                    className={classes.icon}
                  />
                  <Typography variant="body1">{contact.email}</Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        ) : (
          <p>No contact details</p>
        )}
      </>
    );
  }
}

ContactDetailsReadOnly.propTypes = {
  classes: PropTypes.object.isRequired,
  contact: PropTypes.object,
  typeOverride: PropTypes.string,
};

ContactDetailsReadOnly.defaultProps = {
  typeOverride: undefined,
  contact: undefined,
};

export default withStyles(styles)(ContactDetailsReadOnly);
