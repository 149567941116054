import { useTenant } from "@certane/arcadia-web-components";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import InsertLinkIcon from "@material-ui/icons/InsertLink";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import {
  getLoggedInUser,
  getReferenceDataDescription,
  getReferenceDataType,
} from "../../../reducers";
import { typeParentIcon } from "../../../util/icons";
import { getResolvedDefinition } from "../../../util/workItemTypeUtils";
import { getFirstWorkItemUserByType } from "../../../util/workItemUserUtils";
import DebouncedTextField from "../../common/DebouncedTextField";
import MultiSelectDropdown from "../../common/MultiSelectDropdown";
import WorkItemOwner from "../components/overview/WorkItemOwner";
import WorkItemStatus from "../components/overview/WorkItemStatus";
import getFilteredTypes from "../getFilteredTypes";
import WorkItemBlob from "../WorkItemBlob";
import WorkItemSearchDialog from "../WorkItemSearchDialog";
import WorkItemSubmitter from "../WorkItemSubmitter";

const useStyles = makeStyles((theme) => ({
  searchField: {
    width: "auto",
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  nowrap: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  owner: {
    paddingRight: "18px",
  },
  childParentIcon: {
    fontSize: "inherit",
    marginRight: "5px",
    marginTop: "-3px",
    verticalAlign: "middle",
  },
  sub: {
    fontSize: "0.7rem",
    color: "#888",
  },
}));

const defaultFilter = {
  textSearch: "",
  types: [],
};

// const getFilteredTypes = (types, loggedInUser, featureFlags) => {
//   const enabledFlags = Object.keys(featureFlags).filter((flagId) => {
//     return featureFlags[flagId];
//   });
//
//   const fTypes = { ids: [], values: {} };
//   types.ids
//     .map((id) => types.values[id])
//     .filter(
//       (workItemType) =>
//         _.intersection(
//           workItemType.props.definition.featureFlagIds,
//           enabledFlags
//         ).length > 0
//     )
//     .filter(
//       (workItemType) =>
//         loggedInUser &&
//         _.intersection(
//           workItemType.props.definition.visibleTo,
//           loggedInUser.roles
//         ).length > 0
//     )
//     .forEach((type) => {
//       fTypes.ids.push(type.props.definition.id);
//       fTypes.values[type.props.definition.id] = {
//         ...type,
//       };
//     });
//   return {
//     values: fTypes.values,
//     ids: _.orderBy(fTypes.ids, (typeId) => fTypes.values[typeId].description),
//   };
// };

const WorkItemAssociationSearchDialog = ({
  workItem,
  workItemAssociations,
  open,
  onClose,
  onAdd,
  filteredType,
  localGetReferenceDataDescription,
}) => {
  const classes = useStyles();
  const { tenant } = useTenant();
  const loggedInUser = useSelector(getLoggedInUser);
  const workItemTypes = useSelector((state) =>
    getReferenceDataType(state, "WorkItemType")
  );

  const [filter, setFilter] = useState({
    ...defaultFilter,
    types: filteredType,
  });
  const [filteredTypes] = useState(
    getFilteredTypes(workItemTypes, loggedInUser, tenant)
  );

  useEffect(() => {
    if (!open) {
      setFilter({ ...defaultFilter, types: filteredType });
    }
  }, [open]);

  const updateSearchFilter = (field, value) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };
  const handleAction = (other) => {
    if (other.id === workItem.id) {
      return (
        <Tooltip title="This item" disableFocusListener>
          <div>
            <IconButton disabled>
              <InsertLinkIcon />
            </IconButton>
          </div>
        </Tooltip>
      );
    }
    const existingOthers = _.flatMap(workItemAssociations, (association) => [
      association.workItemA.id,
      association.workItemB.id,
    ]);
    if (existingOthers.some((existing) => existing === other.id)) {
      return (
        <Tooltip title="Already associated" disableFocusListener>
          <div>
            <IconButton disabled>
              <InsertLinkIcon />
            </IconButton>
          </div>
        </Tooltip>
      );
    }
    return (
      <Tooltip title="Add association" disableFocusListener>
        <IconButton
          onClick={() => onAdd(other)}
          data-cy={`${other.friendlyId}-addAssociation`}
        >
          <InsertLinkIcon />
        </IconButton>
      </Tooltip>
    );
  };

  const TypeParentIcon = typeParentIcon();

  return (
    <WorkItemSearchDialog
      title="Associate another work item"
      open={open}
      onClose={onClose}
      action={handleAction}
      filter={filter}
      columns={[
        {
          label: "Type",
          name: "type",
          size: 2,
          sortable: true,
          render: (item) => {
            const { definition } = workItemTypes.values[item.type].props;
            const { name } = definition;
            const category = localGetReferenceDataDescription(
              "WorkItemCategory",
              item.category
            );
            return (
              <>
                <WorkItemBlob type={item.type} description={name} />
                <Typography
                  className={classes.nowrap}
                  title={category}
                  variant="caption"
                  display="block"
                  data-cy={category}
                >
                  {category}
                  {item.hasParent && (
                    <div className={classes.sub}>
                      <TypeParentIcon
                        title="Parent-item"
                        className={classes.childParentIcon}
                      />
                      {
                        workItemTypes.values[item.parent.type].props.definition
                          .name
                      }
                    </div>
                  )}
                </Typography>
              </>
            );
          },
        },
        {
          label: "Submitter",
          name: "createdBy",
          size: 2,
          sortable: true,
          render: (item) => <WorkItemSubmitter workItem={item} />,
        },
        {
          label: "Title",
          name: "title",
          size: 4,
          render: (item) => (
            <>
              <div>
                <Typography className={classes.nowrap} title={item.title}>
                  {item.title}
                </Typography>
              </div>
              <Typography
                className={classes.nowrap}
                title={item.friendlyId}
                variant="caption"
              >
                {item.friendlyId}
              </Typography>
            </>
          ),
        },
        {
          label: "Status",
          name: "status",
          size: 2,
          sortable: true,
          render: (item) => {
            const definition = getResolvedDefinition(
              workItemTypes,
              item.type,
              item.parent?.type
            );
            return (
              <>
                <WorkItemStatus
                  workItem={item}
                  readonly
                  definition={definition}
                />
                <span>
                  {localGetReferenceDataDescription(
                    "WorkItemOutcome",
                    item.outcome,
                    ""
                  )}
                </span>
              </>
            );
          },
        },
        {
          label: "Owner",
          name: "owner",
          size: 2,
          sortable: true,
          render: (item) => (
            <>
              <WorkItemOwner
                className={classes.owner}
                owner={getFirstWorkItemUserByType(item.users, "OWNER")?.user}
                loggedInUser={loggedInUser}
                readonly
              />
            </>
          ),
        },
      ]}
    >
      <Grid container>
        <Grid item xs={8}>
          <div className={classes.searchField}>
            <DebouncedTextField
              value={filter.textSearch}
              onChange={(text) => updateSearchFilter("textSearch", text)}
              placeholder="Search across reference, title and notes"
            />
          </div>
        </Grid>
        <Grid item xs={4}>
          <>
            <InputLabel data-cy="type">Type</InputLabel>
            <div>
              <MultiSelectDropdown
                value={filter.types}
                options={filteredTypes}
                onChange={(value) => updateSearchFilter("types", value)}
                closeOnChange
              />
            </div>
          </>
        </Grid>
      </Grid>
    </WorkItemSearchDialog>
  );
};

WorkItemAssociationSearchDialog.propTypes = {
  workItem: PropTypes.object,
  workItemAssociations: PropTypes.array,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  filteredType: PropTypes.array,

  //redux
  localGetReferenceDataDescription: PropTypes.func.isRequired,
};

WorkItemAssociationSearchDialog.defaultProps = {
  filteredType: [],
};

const mapStateToProps = (state) => ({
  localGetReferenceDataDescription: (type, id, defaultValue) =>
    getReferenceDataDescription(
      state,
      type,
      id,
      defaultValue === undefined ? "-" : defaultValue
    ),
});

export default connect(mapStateToProps)(WorkItemAssociationSearchDialog);
