import _ from "lodash";
import React, { useState, useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import HistoryIcon from "@material-ui/icons/History";
import PauseIcon from "@material-ui/icons/Pause";
import DoneIcon from "@material-ui/icons/Done";
import dates from "../../util/dates";
import WorkItemApi from "../../services/api/workItems";
import TextValueRow from "../common/TextValueRow";

const styles = (theme) => ({
  popover: {
    marginTop: theme.spacing(-2),
  },
  blob: {
    display: "inline-block",
    width: "auto",
    borderRadius: "5px",
    color: "white",
    padding: "5px 5px 3px 5px",
    fontSize: "12px",
    lineHeight: 1,
    verticalAlign: "bottom",
  },
  margin: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  card: {
    minWidth: 50,
    maxWidth: 400,
  },
  icon: {
    marginLeft: theme.spacing(0.75),
    marginBottom: -theme.spacing(0.5),
    cursor: "pointer",
    fontSize: 15,
    color: "#333",
  },
  iconInfo: {
    marginRight: theme.spacing(0.5),
    fontSize: 15,
    color: "#333",
  },
  blobIcon: {
    fontSize: 12,
  },
  flex: { display: "flex", alignItems: "center" },
  pos: {
    marginBottom: 12,
    fontSize: 12,
  },
  ok: {
    backgroundColor: theme.due.ok,
  },
  atRisk: {
    backgroundColor: theme.due.atRisk,
  },
  overdue: {
    backgroundColor: theme.due.overdue,
  },
  listSlaMetrics: {
    fontSize: 14,
    display: "flex",
    flexFlow: "row",
    flexWrap: "wrap",
    width: "100%",
    overflow: "visible",
  },
  slaHr: {
    display: "block",
    fontSize: 12,
    width: "100%",
    color: "#848484",
    borderTop: "1px solid #EFEFEF",
    margin: "10px 0 0 0",
    textTransform: "uppercase",
    padding: "5px 0 0 0",
  },
  circularProgress: {
    margin: theme.spacing(2),
  },
});

const timeInProcessing = (statuses) =>
  _.sum(Object.values(statuses)) -
  _.sum([
    statuses.PENDING_SUBMITTER || 0,
    statuses.EXTERNAL_REVIEW || 0,
    statuses.ON_HOLD || 0,
  ]);

const timeWaitingForThirdParty = (statuses) =>
  _.sum([statuses.PENDING_SUBMITTER || 0, statuses.EXTERNAL_REVIEW || 0]);

const timeToSlaStandard = (slaDays, processingTimeMinutes) => {
  const businessDaysInProcessing = dates.hoursToBusinessDays(
    dates.minutesToHours(processingTimeMinutes)
  );
  return slaDays - businessDaysInProcessing;
};

const getServiceStatusBlob = (
  slaDays,
  processingTimeMinutes,
  status,
  classes
) => {
  const dayDiff = timeToSlaStandard(slaDays, processingTimeMinutes);

  if (status === "CLOSED") {
    return (
      <div className={classNames(classes.blob, classes.ok)}>
        <Tooltip title="Completed">
          <DoneIcon className={classes.blobIcon} fontSize="small" />
        </Tooltip>
      </div>
    );
  }

  if (
    status === "PENDING_SUBMITTER" ||
    status === "ON_HOLD" ||
    status === "EXTERNAL_REVIEW"
  ) {
    return (
      <div className={classNames(classes.blob, classes.atRisk)}>
        <Tooltip title="On hold">
          <PauseIcon className={classes.blobIcon} fontSize="small" />
        </Tooltip>
      </div>
    );
  }

  if (dayDiff === 1) {
    return (
      <div className={classNames(classes.blob, classes.atRisk)}>
        <Tooltip title="Warning - 1 day left">
          <div>{`${dayDiff}d`}</div>
        </Tooltip>
      </div>
    );
  }

  if (dayDiff < 1) {
    return (
      <div className={classNames(classes.blob, classes.overdue)}>
        <Tooltip title="Overdue!">
          <div>{`${dayDiff}d`}</div>
        </Tooltip>
      </div>
    );
  }

  return (
    <div className={classNames(classes.blob, classes.ok)}>
      <Tooltip title="Ok - more than 1 day left">
        <div>{`${dayDiff}d`}</div>
      </Tooltip>
    </div>
  );
};

const WorkItemSlaPopover = ({ classes, workItem, title }) => {
  const [slaData, setSlaData] = useState();
  const [anchorEl, setAnchorEl] = useState();
  const id = anchorEl ? "simple-popover" : undefined;
  const { status } = workItem;

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => {
    setAnchorEl(null);
    setSlaData(null);
  };

  useEffect(() => {
    if (anchorEl) {
      WorkItemApi.getSlaStatistics(workItem.id).then((response) =>
        setSlaData(response)
      );
    }
  }, [anchorEl]);

  return (
    <>
      <Tooltip title="Click for service level overview">
        <HistoryIcon
          fontSize="small"
          onClick={handleClick}
          aria-describedby={id}
          className={classes.icon}
          data-cy={id}
        />
      </Tooltip>

      <Popover
        className={classes.popover}
        id={id}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Card className={classes.card}>
          <CardContent>
            <Typography
              variant="h5"
              component="h2"
              className={classes.margin}
              data-cy={title}
            >
              {title}
            </Typography>
            <Typography
              variant="h6"
              component="h3"
              className={classes.margin}
              data-cy={workItem.title}
            >
              {workItem.title}
            </Typography>
            {slaData ? (
              <>
                <Typography className={classes.pos} color="textSecondary">
                  Typically serviced within{" "}
                  <b>{slaData.settings.maxDaysToProcess} days</b>
                </Typography>
                <Typography variant="body2" component="div">
                  <div className={classes.listSlaMetrics}>
                    <TextValueRow
                      text="Time to first response"
                      value={dates.timeAgoFromSeconds(
                        slaData.statuses.NEW * 60
                      )}
                    />
                    <TextValueRow
                      text="Time to resolution"
                      value={
                        status !== "CLOSED"
                          ? "-"
                          : dates.timeAgoFromSeconds(
                              timeInProcessing(slaData.statuses) * 60
                            )
                      }
                    />
                    <div className={classes.slaHr} />
                    <TextValueRow
                      text={
                        <div className={classes.flex}>
                          <Tooltip title="Service status indicates the performance of the work item against the typical service standard.">
                            <InfoIcon
                              fontSize="small"
                              className={classes.iconInfo}
                            />
                          </Tooltip>
                          <b>SERVICE STATUS</b>
                        </div>
                      }
                      value={getServiceStatusBlob(
                        slaData.settings.maxDaysToProcess,
                        timeInProcessing(slaData.statuses),
                        status,
                        classes
                      )}
                    />
                    <TextValueRow
                      text="Time in processing"
                      value={dates.timeAgoFromSeconds(
                        timeInProcessing(slaData.statuses) * 60
                      )}
                    />

                    <div className={classes.slaHr}>
                      <Typography variant="caption">
                        Excluded from SLA
                      </Typography>
                    </div>
                    <TextValueRow
                      text="Time waiting for 3rd party"
                      value={dates.timeAgoFromSeconds(
                        timeWaitingForThirdParty(slaData.statuses) * 60
                      )}
                    />
                    <TextValueRow
                      text="Time on hold"
                      value={dates.timeAgoFromSeconds(
                        slaData.statuses.ON_HOLD * 60
                      )}
                    />
                  </div>
                </Typography>
              </>
            ) : (
              <CircularProgress
                className={classes.circularProgress}
                size={20}
              />
            )}
          </CardContent>
        </Card>
      </Popover>
    </>
  );
};

WorkItemSlaPopover.propTypes = {
  classes: PropTypes.object.isRequired,
  workItem: PropTypes.object.isRequired,
  title: PropTypes.object.isRequired,
};

WorkItemSlaPopover.defaultProps = {};

export default withStyles(styles)(WorkItemSlaPopover);
