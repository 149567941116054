import {
  NOTIFICATIONS_DISMISS,
  NOTIFICATIONS_DISMISS_ALL,
  NOTIFICATIONS_FETCH,
  NOTIFICATIONS_MARK_AS_READ,
} from "../actions/notifications";
import { publishToastError } from "../services/toasts";

const notifications = (state = { byId: {}, allIds: [] }, action) => {
  switch (action.type) {
    case `${NOTIFICATIONS_FETCH}_SUCCESS`:
      return {
        ...state,
        byId: action.response.entities.notifications,
        allIds: action.response.result,
      };
    case `${NOTIFICATIONS_FETCH}_FAILURE`:
      publishToastError(action.errorMessage);
      break;
    case `${NOTIFICATIONS_MARK_AS_READ}_SUCCESS`:
      return {
        ...state,
        byId: { ...state.byId, ...action.response.entities.notifications },
      };
    case `${NOTIFICATIONS_MARK_AS_READ}_FAILURE`:
      publishToastError(action.errorMessage);
      break;
    case `${NOTIFICATIONS_DISMISS}_SUCCESS`:
      const idToRemove = action.response.id;
      return {
        byId: {
          ...state.byId,
          [idToRemove]: undefined,
        },
        allIds: [...state.allIds.filter((id) => id !== idToRemove)],
      };
    case `${NOTIFICATIONS_DISMISS}_FAILURE`:
      publishToastError(action.errorMessage);
      break;
    case `${NOTIFICATIONS_DISMISS_ALL}_SUCCESS`:
      return {
        ...state,
        byId: {},
        allIds: [],
      };
    case `${NOTIFICATIONS_DISMISS_ALL}_FAILURE`:
      publishToastError(action.errorMessage);
      break;
    default:
      break;
  }

  return state;
};

export default notifications;

// selectors
export const getAll = (state) => state.allIds.map((id) => state.byId[id]);
