import { useAuth0 } from "@auth0/auth0-react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import useTheme from "@material-ui/core/styles/useTheme";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  getLoggedInUser,
  getReferenceDataDescription,
  getReferenceDataType,
} from "../../../reducers";
import usersApi from "../../../services/api/users";
import workItemsApi from "../../../services/api/workItems";
import { deleteIcon, submittersIcon } from "../../../util/icons";
import { getResolvedDefinition } from "../../../util/workItemTypeUtils";
import {
  getFirstWorkItemUserByType,
  getWorkItemUsersByType,
} from "../../../util/workItemUserUtils";
import Avatar from "../../common/Avatar.tsx";
import CardIconTitle from "../../common/CardIconTitle";
import DebouncedTextField from "../../common/DebouncedTextField";
import MultiSelectDropdown from "../../common/MultiSelectDropdown";
import ListPicker from "../../ListPicker";
import WorkItemUserReasonDialog from "./WorkItemUserReasonDialog";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  label: {
    ...theme.typography.body2,
    color: theme.palette.swatch.secondary,
  },
  cardHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1) / 2,
  },
  cardContent: {
    paddingTop: theme.spacing(1) / 2,
    paddingBottom: theme.spacing(1) / 2,
  },
  listIcon: {
    marginRight: 0,
  },
  filter: {
    marginBottom: theme.spacing(1),
  },
});

const WorkItemUsers = ({
  classes,
  loggedInUser,
  workItem,
  userTypes,
  disabledOptions,
  canAddUsers,
  canRemoveUser,
  onAddUsers,
  onRemoveUser,
  withReason,
  readonly,
  title,
  workItemTypes,
  getRelationshipTypeDescription,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const theme = useTheme();

  const [userDialogOpen, setUserDialogOpen] = useState(false);
  const [reasonDialogOpen, setReasonDialogOpen] = useState(false);
  const [userCandidates, setUserCandidates] = useState([]);

  const [workItemUsers, setWorkItemUsers] = useState([]);
  const [workItemUserTypeMap, setWorkItemUserTypeMap] = useState({
    OWNER: null,
    KEY_CONTACT: null,
    AGENT: [],
    SUBMITTER: [],
    ASSIGNEE: [],
  });
  const [productRelationships, setProductRelationships] = useState([]);
  const definition = getResolvedDefinition(
    workItemTypes,
    workItem.type,
    workItem.parent?.type
  );

  useEffect(() => {
    const financialProducts = _.get(
      workItem,
      "entityRelationship.financialProducts",
      []
    );
    setProductRelationships(
      _.flatMap(financialProducts, (product) => product.relationships)
    );
  }, [workItem]);

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      const users = await workItemsApi.listUsers(workItem.id, accessToken);

      const workItemOwner = getFirstWorkItemUserByType(users, "OWNER");
      const workItemKeyContact = getFirstWorkItemUserByType(
        workItemUsers,
        "KEY_CONTACT"
      );
      const workItemAgents = getWorkItemUsersByType(users, "AGENT");
      const workItemSubmitters = getWorkItemUsersByType(users, "SUBMITTER");
      const workItemAssignees = getWorkItemUsersByType(users, "ASSIGNEE");

      setWorkItemUsers(users);
      setWorkItemUserTypeMap({
        OWNER: workItemOwner,
        KEY_CONTACT: workItemKeyContact,
        AGENT: workItemAgents,
        SUBMITTER: workItemSubmitters,
        ASSIGNEE: workItemAssignees,
      });
    })();
  }, [workItem.users]);

  if (!workItem) return null;

  const { submittableBy, collaboratableBy } = definition;

  const selectedWorkItemUsers = userTypes.reduce((selected, userType) => {
    return [...selected, ...workItemUserTypeMap[userType]];
  }, []);

  const handleAddUsers = async (users) => {
    if (withReason) {
      setUserCandidates(users);
      setReasonDialogOpen(true);
    } else {
      await onAddUsers(users);
    }

    setUserDialogOpen(false);
  };

  const handleReasonDialogSubmit = async (reason) => {
    console.log("handleReasonDialogSubmit", reason);
    setReasonDialogOpen(false);
    await onAddUsers(userCandidates, reason);
  };

  const handleRemoveUser = async (workItemUser) => {
    await onRemoveUser(workItemUser);
  };

  const asyncFetch = async (pickerFilter, pagination, abortController) => {
    const explicitExternalOrgs = _.get(
      workItem,
      "entityRelationship.organisations",
      []
    )
      .map((linkedOrg) => linkedOrg.organisation)
      .filter((o) => o.type === "EXTERNAL");
    const implicitExternalOrgs = productRelationships
      .map((relationship) => relationship.organisation)
      .filter((organisation) => organisation.type === "EXTERNAL");
    const externalOrgs = [...explicitExternalOrgs, ...implicitExternalOrgs];
    const organisationCodes = [];
    const externalOrganisationCodes =
      externalOrgs.length === 0
        ? ["UNKNOWN"]
        : _.uniq(externalOrgs.map((org) => org.friendlyId));

    if (pickerFilter.relationships && pickerFilter.relationships.length > 0) {
      const filteredOrgsByRelationship = productRelationships
        .filter((relationship) =>
          pickerFilter.relationships.some(
            (selected) => selected === relationship.relationshipType
          )
        )
        .map((relationship) => relationship.organisation);

      if (filteredOrgsByRelationship.length > 0) {
        filteredOrgsByRelationship
          .map((org) => org.friendlyId)
          .forEach((friendlyId) => organisationCodes.push(friendlyId));
      }

      if (organisationCodes.length === 0) {
        organisationCodes.push("UNKNOWN");
      }
    }

    const roles = _.uniq([...collaboratableBy, ...submittableBy]);

    const searchParameters = {
      ...pickerFilter,
      limit: pagination.pageSize,
      offset: pagination.offset,
      roles,
      externalOrganisationCodes,
      organisationCodes,
      orderByField: "name",
    };
    const accessToken = await getAccessTokenSilently();
    return usersApi.search(searchParameters, abortController, accessToken);
  };

  const getUserLabel = (user) => {
    const organisation =
      user.externalUser && user.organisations.length > 0
        ? user.organisations[0]
        : null;
    const username = user.name || "Unknown";

    return organisation ? `${username} (${organisation.name})` : username;
  };

  const getUserRelationships = (user) => {
    return productRelationships.filter((relationship) =>
      user.organisations.some((org) => org.id === relationship.organisation.id)
    );
  };

  const renderUserRelationships = (user, defaultLabel) => {
    const relationships = getUserRelationships(user);
    return relationships.length === 0
      ? defaultLabel
      : _.uniq(
          relationships.map((relationship) =>
            getRelationshipTypeDescription(
              relationship.relationshipType,
              defaultLabel
            )
          )
        ).join(", ");
  };

  const getRelationshipTypeOptions = () => {
    const options = {
      ids: _.uniq(
        productRelationships.map(
          (relationship) => relationship.relationshipType
        )
      ),
      values: {},
    };
    options.ids.forEach((id) => {
      options.values[id] = {
        id,
        description: getRelationshipTypeDescription(id),
      };
    });

    return options;
  };

  const DeleteIcon = deleteIcon();

  const renderIcon = (user, size, color) => (
    <Avatar
      email={user.email}
      name={user.name}
      size={size}
      color={color}
      round
    />
  );

  const renderLabel = (user) => (
    <ListItemText
      primary={<span>{getUserLabel(user)}</span>}
      secondary={
        <span>
          {renderUserRelationships(
            user,
            user.externalUser ? "External (other)" : "Internal (other)"
          )}
        </span>
      }
    />
  );

  const renderUser = (workItemUser) => {
    const { user, type } = workItemUser;
    return (
      <ListItem key={`${user.id}-${type}`} dense>
        <ListItemIcon className={classes.listIcon}>
          {renderIcon(
            user,
            24,
            type === "SUBMITTER" ? theme.palette.text.secondary : undefined
          )}
        </ListItemIcon>
        {renderLabel(user)}
        <ListItemSecondaryAction>
          {canRemoveUser(loggedInUser, workItemUser) && (
            <IconButton
              aria-label="Delete"
              onClick={() => handleRemoveUser(workItemUser)}
              data-cy="deleteIcon"
            >
              <DeleteIcon />
            </IconButton>
          )}
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  const getSortedWorkItemUsers = () => {
    const loggedInOrganisationStr =
      (loggedInUser &&
        loggedInUser.organisations.map((org) => org.name).join(",")) ||
      "z";
    const loggedInRelationshipStr =
      loggedInUser &&
      renderUserRelationships(
        loggedInUser,
        loggedInUser.externalUser ? "External (other)" : "Internal (other)"
      );

    const orderFieldRelationship = (workItemUser) => {
      const relationshipsStr = renderUserRelationships(
        workItemUser.user,
        workItemUser.user.externalUser ? "External (other)" : "Internal (other)"
      );
      return relationshipsStr === loggedInRelationshipStr
        ? "A"
        : relationshipsStr;
    };

    const orderFieldOrganisation = (workItemUser) => {
      const orgsStr =
        workItemUser.user.organisations.map((org) => org.name).join(",") || "z";
      return orgsStr === loggedInOrganisationStr ? "A" : orgsStr;
    };

    const orderFieldName = (workItemUser) =>
      loggedInUser && workItemUser.user.name === loggedInUser.name
        ? "A"
        : workItemUser.user.name;

    // Order by relationship - organisation - name, and put the logged in workItemUsers' organisation, relationship and name first
    return _.orderBy(
      selectedWorkItemUsers,
      [orderFieldRelationship, orderFieldOrganisation, orderFieldName],
      ["asc", "asc", "asc"]
    );
  };

  const haveProductRelationships = productRelationships.length > 0;

  const defaultDisabledOptions = [
    workItemUserTypeMap["OWNER"]?.user,
    workItemUserTypeMap["KEY_CONTACT"]?.user,
    ...workItemUserTypeMap["AGENT"]
      .filter((workItemUser) => !canRemoveUser(loggedInUser, workItemUser))
      .map((workItemUser) => workItemUser?.user),
    ...workItemUserTypeMap["SUBMITTER"]
      .filter((workItemUser) => !canRemoveUser(loggedInUser, workItemUser))
      .map((workItemUser) => workItemUser?.user),
  ].filter((u) => !!u);

  return (
    <>
      <Card className={classes.root} elevation={0}>
        <CardHeader
          data-cy={title}
          className={classes.cardHeader}
          title={<CardIconTitle title={title} icon={submittersIcon()} />}
          action={
            !readonly && (
              <Tooltip
                title={`Add ${title.toLowerCase()}`}
                disableFocusListener
              >
                <IconButton
                  aria-label={`Add ${title.toLowerCase()}`}
                  aria-haspopup="true"
                  onClick={() => setUserDialogOpen(true)}
                  disabled={!canAddUsers(loggedInUser)}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            )
          }
        />
        <CardContent className={classes.cardContent}>
          <List>
            {getSortedWorkItemUsers(selectedWorkItemUsers).map((workItemUser) =>
              renderUser(workItemUser)
            )}
          </List>
        </CardContent>
      </Card>
      <ListPicker
        title={`Select ${title.toLowerCase()}`}
        actionText="Select"
        open={userDialogOpen}
        onClose={() => setUserDialogOpen(false)}
        onSubmit={handleAddUsers}
        datasource={asyncFetch}
        disabledOptions={disabledOptions || defaultDisabledOptions}
        selected={selectedWorkItemUsers.map(
          (workItemUser) => workItemUser.user
        )}
        isMulti
        toOption={(user) => ({
          label: getUserLabel(user),
          id: user.id,
          email: user.email,
          user,
        })}
        fromOption={(option) => option.user}
        renderIcon={renderIcon}
        renderLabel={renderLabel}
        renderFilter={(onChange, filter) => (
          <Grid
            container
            spacing={1}
            alignItems="flex-end"
            className={classes.filter}
          >
            <Grid item xs={12} md={haveProductRelationships ? 6 : 12}>
              <DebouncedTextField
                value={filter.textSearch}
                onChange={(text) => onChange("textSearch", text)}
                placeholder="Type to filter..."
                margin="none"
                autoFocus
                data-cy="typeToFilter"
              />
            </Grid>
            {haveProductRelationships && (
              <Grid item xs={12} md={6}>
                <div>
                  <FormLabel data-cy="relationship">Relationship</FormLabel>
                  <div>
                    <MultiSelectDropdown
                      value={filter.relationships || []}
                      onChange={(value) => onChange("relationships", value)}
                      options={getRelationshipTypeOptions()}
                      closeOnChange
                    />
                  </div>
                </div>
              </Grid>
            )}
          </Grid>
        )}
        additionalActions={[
          <Button
            key="assignToMe"
            color="primary"
            onClick={() =>
              handleAddUsers([
                loggedInUser,
                ...selectedWorkItemUsers.map(
                  (workItemUser) => workItemUser.user
                ),
              ])
            }
            data-cy="assignToMe"
          >
            Add me
          </Button>,
        ]}
      />

      <WorkItemUserReasonDialog
        open={reasonDialogOpen}
        onSubmit={handleReasonDialogSubmit}
        onClose={() => setReasonDialogOpen(false)}
        onBack={() => {
          setReasonDialogOpen(false);
          setUserDialogOpen(true);
          setUserCandidates([]);
        }}
        users={userCandidates}
      />
    </>
  );
};

WorkItemUsers.propTypes = {
  classes: PropTypes.object.isRequired,
  loggedInUser: PropTypes.object,
  title: PropTypes.string,
  workItemTypes: PropTypes.object.isRequired,
  getRelationshipTypeDescription: PropTypes.func.isRequired,
  workItem: PropTypes.object,
  userTypes: PropTypes.arrayOf(PropTypes.string),
  disabledOptions: PropTypes.arrayOf(PropTypes.string),
  canAddUsers: PropTypes.func,
  onAddUsers: PropTypes.func,
  canRemoveUser: PropTypes.func,
  onRemoveUser: PropTypes.func,
  withReason: PropTypes.bool,
  readonly: PropTypes.bool,
};

WorkItemUsers.defaultProps = {
  loggedInUser: null,
  workItem: null,
  readonly: false,
  title: "Collaborators",
  canAddUsers: () => true,
  canRemoveUser: () => true,
  withReason: false,
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
  workItemTypes: getReferenceDataType(state, "WorkItemType"),
  getRelationshipTypeDescription: (value, defaultLabel) =>
    getReferenceDataDescription(
      state,
      "RelationshipType",
      value,
      defaultLabel || "-"
    ),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(WorkItemUsers);
