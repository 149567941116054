import FormLabel from "@material-ui/core/FormLabel";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, FieldArray, formValueSelector, reduxForm } from "redux-form";
import { format, length, required } from "redux-form-validators";
import Avatar from "../../common/Avatar.tsx";
import { getLabels, getReferenceDataType } from "../../../reducers";
import { scrollToFirstInvalidInput } from "../../../util/formHelpers";
import { getRefDataMenuItems } from "../menuItems";
import WrappedSelect from "../wrapper/WrappedSelect";
import WrappedListPicker from "../wrapper/WrappedListPicker";
import WrappedTextField from "../wrapper/WrappedTextField";
import RelatedOrgsFields from "./RelatedOrgsFields";
import { roles } from "@certane/arcadia-web-components";
import usersApi from "../../../services/api/users";
import fundsApi from "../../../services/api/funds";
import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@material-ui/core";

const ProductForm = ({ isEditing, regions, selectedRegion, fieldLabels }) => {
  const { getAccessTokenSilently } = useAuth0();

  const asyncRelationshipManagerOptionsFetch = async (
    pickerFilter,
    pagination,
    abortController
  ) => {
    const searchParameters = {
      ...pickerFilter,
      limit: pagination.pageSize,
      offset: pagination.offset,
      roles: [...roles.ADMIN_ROLES, ...roles.AGENT_ROLES],
      orderByField: "name",
    };
    const accessToken = await getAccessTokenSilently();
    return usersApi.search(searchParameters, abortController, accessToken);
  };

  const asyncFundOptionsFetch = async (
    pickerFilter,
    pagination,
    abortController
  ) => {
    const searchParameters = {
      ...pickerFilter,
      limit: pagination.pageSize,
      offset: pagination.offset,
      orderByField: "name",
      excludeGroupFilters: true,
    };
    const accessToken = await getAccessTokenSilently();
    return fundsApi.search(searchParameters, abortController, accessToken);
  };

  return (
    <form autoComplete="off">
      <Field
        name="friendlyId"
        component={WrappedTextField}
        label={fieldLabels.labels.friendlyId}
        fullWidth
        // disabled={isEditing}
        required
        validate={[
          required({ message: `${fieldLabels.labels.friendlyId} is required` }),
          length({
            min: 2,
            max: 4,
            message: `${fieldLabels.labels.friendlyId} should be between 2 and 4 characters`,
          }),
          format({
            with: /^[A-Z]+$/,
            message: `${fieldLabels.labels.friendlyId} should consist of only uppercase letters`,
          }),
        ]}
      />
      <Field
        name="name"
        component={WrappedTextField}
        label={fieldLabels.labels.name}
        validate={required({ msg: `${fieldLabels.labels.name} is required` })}
        fullWidth
        required
        data-cy="name"
      />
      <Field
        name="description"
        component={WrappedTextField}
        label={fieldLabels.labels.description}
        fullWidth
        data-cy="description"
      />
      <Field
        name="usi"
        component={WrappedTextField}
        label={fieldLabels.labels.usi}
        fullWidth
        data-cy="usi"
      />
      <Field
        name="contactNumber"
        component={WrappedTextField}
        label={fieldLabels.labels.contactNumber}
        fullWidth
        data-cy="contactNumber"
      />
      <Field
        name="contactEmail"
        component={WrappedTextField}
        label={fieldLabels.labels.contactEmail}
        fullWidth
        data-cy="contactEmail"
      />
      <Field
        name="website"
        component={WrappedTextField}
        label={fieldLabels.labels.website}
        fullWidth
        data-cy="website"
      />
      <Field
        component={WrappedSelect}
        name="region"
        label={fieldLabels.labels.region}
        fullWidth
        disabled={isEditing}
        required
        data-cy="region"
      >
        {getRefDataMenuItems(regions)}
      </Field>

      <Field
        name="relationshipManager"
        label={fieldLabels.labels.relationshipManager}
        component={WrappedListPicker}
        datasource={asyncRelationshipManagerOptionsFetch}
        isMulti={false}
        submitOnChange
        required
        fullWidth
        toOption={(user) => ({
          label: user.name || "Unknown",
          id: user.id,
          email: user.email,
        })}
        fromOption={(option) => ({
          name: option.label,
          id: option.id,
          email: option.email,
        })}
        renderIcon={(user, size) => (
          <Avatar email={user.email} name={user.name} size={size} round />
        )}
        data-cy="relationshipManager"
      />

      <Field
        name="fund"
        label={fieldLabels.labels.fund}
        component={WrappedListPicker}
        datasource={asyncFundOptionsFetch}
        isMulti={false}
        submitOnChange
        fullWidth
        toOption={(fund) => ({
          label: fund.name || "Unknown",
          id: fund.id,
          fund,
        })}
        fromOption={(option) => option.fund}
        renderIcon={(fund, size) => (
          <Avatar name={fund.friendlyId} size={size} round />
        )}
        data-cy="fund"
      />
      {selectedRegion && (
        <>
          <Box mt={2}>
            <FormLabel>{fieldLabels.labels.relationships}</FormLabel>
            <br />
            <FieldArray
              name="relationships"
              component={RelatedOrgsFields}
              region={selectedRegion}
              fieldLabels={fieldLabels.nestedTypes.relationships}
            />
          </Box>
        </>
      )}
    </form>
  );
};

ProductForm.propTypes = {
  regions: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
  selectedRegion: PropTypes.string,
  fieldLabels: PropTypes.object.isRequired,
};

ProductForm.defaultProps = {
  isEditing: false,
  selectedRegion: null,
};

export const PRODUCT_FORM_NAME = "productForm";
const reduxFormSelector = formValueSelector(PRODUCT_FORM_NAME);

const mapStateToProps = (state) => ({
  regions: getReferenceDataType(state, "Region"),
  selectedRegion: reduxFormSelector(state, "region"),
  fieldLabels: getLabels(state).Product,
});

const form = reduxForm({
  form: PRODUCT_FORM_NAME,
  onSubmitFail: (errors) => scrollToFirstInvalidInput(errors),
});

const reduxData = connect(mapStateToProps);
export default compose(form, reduxData)(ProductForm);
