import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Accordion from "@material-ui/core/Accordion";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import BasicFileList from "./BasicFileList";
import { includeTenantParam } from "@certane/arcadia-web-components";

const useStyles = makeStyles(() => ({
  list: {
    width: "100%",
  },
}));

const MetropolisLinkedFileList = ({ workItem, onFileClick }) => {
  const classes = useStyles();
  const { documents, metropolisLinkedDocuments } = workItem;

  if (!metropolisLinkedDocuments) {
    return null;
  }

  const linkedDocuments = documents.filter((doc) => {
    const mediaId = metropolisLinkedDocuments[doc.id];
    return !!mediaId;
  });

  if (!linkedDocuments || linkedDocuments.length === 0) {
    return null;
  }

  return (
    <Accordion elevation={0}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="body1" color="textSecondary">
          Metropolis published files
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <BasicFileList
          className={classes.list}
          documents={linkedDocuments}
          secondaryText={() => null}
          primaryAction={(doc) => (onFileClick ? () => onFileClick(doc) : null)}
          secondaryAction={(doc) => (
            <ListItemSecondaryAction
              onClick={() =>
                window.open(
                  includeTenantParam(
                    `/metropolis/media/${metropolisLinkedDocuments[doc.id]}`
                  ),
                  "_blank"
                )
              }
            >
              <Tooltip title="Open linked media in Metropolis">
                <IconButton edge="end" aria-label="Open in new tab">
                  <OpenInNewIcon color="action" />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          )}
        />
      </AccordionDetails>
    </Accordion>
  );
};

MetropolisLinkedFileList.propTypes = {
  workItem: PropTypes.object.isRequired,
  onFileClick: PropTypes.func,
};

MetropolisLinkedFileList.defaultProps = {
  onFileClick: null,
};

export default MetropolisLinkedFileList;
