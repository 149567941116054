import { withStyles } from "@material-ui/core";
import * as PropTypes from "prop-types";
import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = () => ({
  main: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)",
  },
  spinner: {
    marginBottom: "16px",
  },
  text: {
    textAlign: "center",
  },
});

const LoadingIndicator = ({ classes, text, ...rest }) => (
  <div className={classes.main}>
    <CircularProgress className={classes.spinner} {...rest} />
    <p className={classes.text} data-cy="text">
      {text || "Loading..."}
    </p>
  </div>
);

LoadingIndicator.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string,
};

LoadingIndicator.defaultProps = {
  text: null,
};

export default withStyles(styles)(LoadingIndicator);
