import { useTenant } from "@certane/arcadia-web-components";
import { ListItem } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import Container from "../../../components/common/Container";
import HeaderBar from "../../../components/common/HeaderBar";
import PageHeading from "../../../components/common/PageHeading";
import Scorecard from "../../../components/common/Scorecard";
import { tenantIcon } from "../../../util/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  card: {
    marginTop: theme.spacing(2),
  },
}));

const TenantIcon = tenantIcon();

const TenantAdminPage: React.FC = () => {
  const classes = useStyles();
  const { tenant } = useTenant();

  return (
    <div className={classes.root}>
      <HeaderBar>
        <PageHeading icon={TenantIcon} heading="Tenant" />
      </HeaderBar>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card className={classes.card} elevation={0}>
              <CardContent>
                <Typography variant="h6" component="h2">
                  Tenant information
                </Typography>
              </CardContent>
              <CardContent>
                <Scorecard label={"ID"}>{tenant?.id}</Scorecard>
                <Scorecard label={"Name"}>{tenant?.name}</Scorecard>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card className={classes.card} elevation={0}>
              <CardContent>
                <Typography variant="h6" component="h2">
                  Feature flags
                </Typography>
              </CardContent>
              <List dense>
                {tenant &&
                  Object.keys(tenant.featureFlags).map((id) => (
                    <ListItem key={id}>
                      <ListItemText primary={id} />
                      <ListItemSecondaryAction>
                        <Checkbox
                          edge="end"
                          checked={tenant.featureFlags[id]}
                          disabled
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
              </List>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default TenantAdminPage;
