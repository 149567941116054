import { useAuth0 } from "@auth0/auth0-react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { patchWorkItem } from "../../../../actions/workItems";
import Scorecard from "../../../common/Scorecard";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  cardHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  cardContent: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  chipRoot: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1) / 2,
  },
});

const WorkItemChargeability = ({
  classes,
  fieldLabels,
  workItem,
  readonly,
  localPatchWorkItem,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const updateChargeability = async (chargeable) => {
    const accessToken = await getAccessTokenSilently();
    localPatchWorkItem(
      workItem.id,
      { chargeable },
      "Updated chargeability",
      accessToken
    );
  };

  if (!workItem) return null;

  const booleanToString = (value) => {
    if (value === true) {
      return "true";
    }
    if (value === false) {
      return "false";
    }
    return null;
  };
  const stringToBoolean = (value) => value === "true";

  return (
    <Card className={classes.root} elevation={0}>
      <CardHeader className={classes.cardHeader} title="Chargeability" />
      <CardContent className={classes.cardContent}>
        <Scorecard label={fieldLabels.labels.chargeable}>
          <RadioGroup
            value={booleanToString(workItem.chargeable)}
            onChange={(event) =>
              updateChargeability(stringToBoolean(event.target.value))
            }
            row
          >
            <FormControlLabel
              value="true"
              control={<Radio />}
              label="Yes"
              disabled={readonly}
            />
            <FormControlLabel
              value="false"
              control={<Radio />}
              label="No"
              disabled={readonly}
            />
          </RadioGroup>
        </Scorecard>
      </CardContent>
    </Card>
  );
};

WorkItemChargeability.propTypes = {
  classes: PropTypes.object.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  workItem: PropTypes.object,
  localPatchWorkItem: PropTypes.func.isRequired,
  readonly: PropTypes.bool.isRequired,
};

WorkItemChargeability.defaultProps = {
  workItem: null,
};

const mapStateToProps = () => ({});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    localPatchWorkItem: patchWorkItem,
  })
)(WorkItemChargeability);
