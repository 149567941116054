import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { FormattedDate } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import { SubmissionError } from "redux-form";
import {
  addClaimAssessment,
  editClaimAssessment,
} from "../../../../actions/workItems";
import { getReferenceDataDescription, getWorkItem } from "../../../../reducers";
import formatters from "../../../../util/formatters";
import CollapseExpand from "../../../CollapseExpand";
import GridListing from "../../../common/GridListing";
import MultilineText from "../../../common/MultilineText";
import Scorecard from "../../../common/Scorecard";
import UserEntries from "../../../common/UserEntries";
import ExpandableForm from "../../../forms/ExpandableForm";
import EditWorkItemComponentForm, {
  EDIT_WORK_ITEM_COMPONENT_FORM_NAME,
} from "../EditWorkItemComponentForm";
import { includeTenantParam } from "@certane/arcadia-web-components";

const useStyles = makeStyles({
  content: {
    flex: "1 1 auto",
  },
  action: {
    flex: "0 0 auto",
    alignSelf: "flex-start",
  },
  editButton: {},
  hidden: {
    display: "none",
  },
});

const ClaimAssessmentCard = ({
  workItem,
  localAddClaimAssessment,
  localEditClaimAssessment,
  getAssessmentOutcomeDescription,
  className,
  readonly,
  fieldLabels,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();
  const [formExpanded, setFormExpanded] = useState(false);
  const [assessmentToEdit, setAssessmentToEdit] = useState(null);

  const handleCreate = async (values) => {
    const accessToken = await getAccessTokenSilently();
    localAddClaimAssessment(workItem.id, values, accessToken)
      .then(() => {
        setFormExpanded(false);
      })
      .catch((error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  const handleUpdate = async (values) => {
    const { index, ...claimAssessment } = values;

    const accessToken = await getAccessTokenSilently();
    localEditClaimAssessment(workItem.id, index, claimAssessment, accessToken)
      .then(() => {
        setAssessmentToEdit(null);
      })
      .catch((error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  const mostRecentAssessment =
    workItem && workItem.hardshipAssessments.length > 0
      ? workItem.hardshipAssessments[workItem.hardshipAssessments.length - 1]
      : null;

  const initialValues = {
    assessmentOutcome:
      (mostRecentAssessment && mostRecentAssessment.assessmentOutcome) || null,
    approvedAmount:
      (mostRecentAssessment && mostRecentAssessment.approvedAmount) || null,
    mentions: [],
    complaintIds: [],
  };

  return (
    <Card elevation={0} className={className}>
      <CardHeader
        title={fieldLabels.labels.hardshipAssessments}
        action={
          !readonly && (
            <Tooltip title="Add assessment" disableFocusListener>
              <div>
                <IconButton
                  onClick={() => setFormExpanded(true)}
                  disabled={formExpanded}
                  data-cy="add"
                >
                  <AddIcon />
                </IconButton>
              </div>
            </Tooltip>
          )
        }
      />
      {workItem && (
        <CardContent>
          <ExpandableForm
            title="Add assessment"
            submitButtonText="Save"
            formComponent={EditWorkItemComponentForm}
            formName={EDIT_WORK_ITEM_COMPONENT_FORM_NAME}
            open={formExpanded}
            onCancel={() => setFormExpanded(false)}
            onSubmit={handleCreate}
            initialValues={initialValues}
            workItem={workItem}
            componentName="CLAIM_ASSESSMENT"
            data-cy="addAssessment"
          />
          <UserEntries
            noEntriesText="No assessments"
            entries={workItem.hardshipAssessments.map((entry, index) => ({
              user: entry.user.name,
              date: entry.created,
              mentions: entry.mentions,
              content: (
                <>
                  <Box
                    display={"flex"}
                    className={classNames({
                      [classes.hidden]: assessmentToEdit === index,
                    })}
                  >
                    <div className={classes.content}>
                      <Scorecard
                        label={
                          fieldLabels.nestedTypes.hardshipAssessments.labels
                            .assessmentOutcome
                        }
                      >
                        {getAssessmentOutcomeDescription(
                          entry.assessmentOutcome
                        )}
                      </Scorecard>
                      <Scorecard
                        label={
                          fieldLabels.nestedTypes.hardshipAssessments.labels
                            .approvedAmount
                        }
                      >
                        {entry.approvedAmount !== undefined
                          ? formatters.currency(entry.approvedAmount)
                          : "-"}
                      </Scorecard>
                      <Scorecard
                        label={
                          fieldLabels.nestedTypes.hardshipAssessments.labels
                            .justificationNotes
                        }
                      >
                        <CollapseExpand>
                          <MultilineText
                            text={entry.justificationNotes}
                            defaultText="-"
                          />
                        </CollapseExpand>
                      </Scorecard>
                      <Scorecard
                        label={
                          fieldLabels.nestedTypes.hardshipAssessments.labels
                            .objections
                        }
                      >
                        <Typography
                          variant={"caption"}
                          color={"textSecondary"}
                          gutterBottom
                        >
                          A received objection should be recorded as a complaint
                          work item, you can{" "}
                          <Link
                            component="button"
                            onClick={() => setAssessmentToEdit(index)}
                          >
                            link to complaint
                          </Link>{" "}
                          below.
                        </Typography>

                        {(!entry.objections ||
                          entry.objections.length === 0) && (
                          <Typography variant={"body2"}>
                            No objections
                          </Typography>
                        )}
                        {entry.objections && entry.objections.length > 0 && (
                          <>
                            <GridListing
                              sortedData={entry.objections}
                              loading={false}
                              sortBy={{}}
                              updateSort={() => {
                                // do nothing.
                              }}
                              dense={false}
                              onClick={(complaint) =>
                                window.open(
                                  includeTenantParam(
                                    `/work-items/${complaint.id}`
                                  ),
                                  "_blank"
                                )
                              }
                              columns={[
                                {
                                  label: fieldLabels.labels.friendlyId,
                                  name: "friendlyId",
                                  size: 3,
                                  sortable: false,
                                  render: (complaint) => (
                                    <span>{complaint.friendlyId}</span>
                                  ),
                                },
                                {
                                  label: fieldLabels.labels.title,
                                  name: "title",
                                  size: 3,
                                  sortable: false,
                                  render: (complaint) => (
                                    <span>{complaint.title}</span>
                                  ),
                                },
                                {
                                  label:
                                    fieldLabels.nestedTypes.clientDetails.labels
                                      .name,
                                  name: "clientDetails.name",
                                  size: 3,
                                  sortable: false,
                                  render: (workItem) => (
                                    <span>{workItem.clientDetails.name}</span>
                                  ),
                                },
                                {
                                  label:
                                    fieldLabels.nestedTypes.complaint.labels
                                      .dateReceived,
                                  name: "dateReceived",
                                  size: 3,
                                  sortable: false,
                                  render: (complaint) => (
                                    <span>
                                      {complaint.complaint.dateReceived ? (
                                        <FormattedDate
                                          value={
                                            complaint.complaint.dateReceived
                                          }
                                        />
                                      ) : (
                                        "-"
                                      )}
                                    </span>
                                  ),
                                },
                              ]}
                            />
                          </>
                        )}
                      </Scorecard>
                    </div>
                    <div className={classes.action}>
                      <IconButton
                        className={classes.editButton}
                        onClick={() => setAssessmentToEdit(index)}
                        disabled={assessmentToEdit}
                      >
                        <EditIcon data-cy="edit" />
                      </IconButton>
                    </div>
                  </Box>
                  <ExpandableForm
                    title="Edit objections"
                    submitButtonText="Save"
                    formComponent={EditWorkItemComponentForm}
                    formName={EDIT_WORK_ITEM_COMPONENT_FORM_NAME}
                    open={index === assessmentToEdit}
                    onCancel={() => setAssessmentToEdit(null)}
                    onSubmit={handleUpdate}
                    initialValues={{
                      index: index,
                      ...entry,
                    }}
                    workItem={workItem}
                    componentName="CLAIM_ASSESSMENT"
                  />
                </>
              ),
            }))}
          />
        </CardContent>
      )}
    </Card>
  );
};

ClaimAssessmentCard.propTypes = {
  className: PropTypes.string,
  readonly: PropTypes.bool.isRequired,
  localAddClaimAssessment: PropTypes.func.isRequired,
  localEditClaimAssessment: PropTypes.func.isRequired,
  workItem: PropTypes.object,
  getAssessmentOutcomeDescription: PropTypes.func.isRequired,
  fieldLabels: PropTypes.object.isRequired,
};

ClaimAssessmentCard.defaultProps = {
  className: null,
  workItem: {},
};

const mapStateToProps = (state) => ({
  workItem: getWorkItem(state),
  getAssessmentOutcomeDescription: (id) =>
    getReferenceDataDescription(state, "AssessmentOutcome", id, "-"),
});

export default compose(
  connect(mapStateToProps, {
    localAddClaimAssessment: addClaimAssessment,
    localEditClaimAssessment: editClaimAssessment,
  })
)(ClaimAssessmentCard);
