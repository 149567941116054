import _ from "lodash";
import { combineReducers } from "redux";
import { WORK_ITEM_PATCH } from "../actions/workItems";
import {
  WORK_ITEM_SCHEDULES_CLEAR_ACTIVE,
  WORK_ITEM_SCHEDULES_FETCH_ONE,
  WORK_ITEM_SCHEDULES_LIST,
  WORK_ITEM_SCHEDULES_PATCH,
} from "../actions/workItemSchedules";
import { publishActionToast } from "../services/toasts";

import logger from "../util/logger";

const byId = (state = {}, action) => {
  publishActionToast(action, "WORK_ITEM_SCHEDULES");

  switch (action.type) {
    case `${WORK_ITEM_SCHEDULES_PATCH}_SUCCESS`:
    case `${WORK_ITEM_SCHEDULES_LIST}_SUCCESS`:
    case `${WORK_ITEM_SCHEDULES_FETCH_ONE}_SUCCESS`:
      return {
        ...state,
        ...action.response.entities.workItemSchedules,
      };
    case `${WORK_ITEM_PATCH}_SUCCESS`:
      const updatedWorkItem = _.values(action.response.entities.workItem)[0];
      if (state[updatedWorkItem.workItemScheduleId]) {
        const updatedSchedule = state[updatedWorkItem.workItemScheduleId];
        updatedSchedule.template = updatedWorkItem;
        return {
          ...state,
          [updatedWorkItem.workItemScheduleId]: updatedSchedule,
        };
      }
      return state;

    default:
      break;
  }

  return state;
};

const defaultActive = {
  allIds: [],
  activeId: undefined,
  searchAbortController: null,
  dashboardLastUpdate: 0,
  pagination: {
    offset: 0,
    pageSize: 50,
    resultCount: 0,
  },
};

const active = (state = defaultActive, action) => {
  switch (action.type) {
    case WORK_ITEM_SCHEDULES_CLEAR_ACTIVE:
      return {
        ...defaultActive,
      };
    case `${WORK_ITEM_SCHEDULES_LIST}_INPROGRESS`:
      if (state.searchAbortController) {
        logger.info("aborting previous unfinished request");
        state.searchAbortController.abort();
      }
      return {
        ...state,
        searchAbortController: action.abortController,
      };
    case `${WORK_ITEM_SCHEDULES_LIST}_SUCCESS`:
      if (state.dashboardLastUpdate < action.timestamp) {
        return {
          ...state,
          allIds: action.response.result,
          dashboardLastUpdate: action.timestamp,
          searchAbortController: null,
          pagination: {
            ...action.response.pagination,
            pageSize: defaultActive.pagination.pageSize,
          },
        };
      }
      logger.info("Ignoring out of date response from server");
      break;
    case `${WORK_ITEM_SCHEDULES_LIST}_FAILURE`:
      return {
        ...state,
        searchAbortController: null,
      };
    case `${WORK_ITEM_SCHEDULES_PATCH}_SUCCESS`:
    case `${WORK_ITEM_SCHEDULES_FETCH_ONE}_SUCCESS`:
      return {
        ...state,
        activeId: action.response.result,
      };

    default:
      break;
  }

  return state;
};

export default combineReducers({
  byId,
  active,
});

// selectors
export const getAll = (state) =>
  state.active.allIds.map((id) => state.byId[id]);
export const getOne = (state) =>
  state.active.activeId ? state.byId[state.active.activeId] : null;
export const getSearchPagination = (state) => state.active.pagination;
