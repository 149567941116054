import React from "react";
import PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

const FileViewerDialog = ({
  open,
  content,
  fileSrc,
  fileType,
  onSubmit,
  onCancel,
  submitButtonText,
  cancelButtonText,
}) => (
  <Dialog fullWidth maxWidth="md" open={open}>
    <DialogTitle>Flag document</DialogTitle>
    <DialogContent>
      {content && <DialogContentText>{content}</DialogContentText>}
      {fileType === "image/png" ? (
        <img src={fileSrc} alt="preview document" />
      ) : (
        <iframe
          title="Preview document"
          src={fileSrc}
          width="100%"
          height="400"
        >
          <p>iframes are not supported by your browser.</p>
        </iframe>
      )}
    </DialogContent>
    <DialogActions>
      <Button color="primary" onClick={onCancel}>
        {cancelButtonText}
      </Button>
      <Button color="primary" onClick={onSubmit}>
        {submitButtonText}
      </Button>
    </DialogActions>
  </Dialog>
);

FileViewerDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  fileSrc: PropTypes.string,
  fileType: PropTypes.string,
  content: PropTypes.node,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  submitButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
};

FileViewerDialog.defaultProps = {
  fileSrc: undefined,
  fileType: undefined,
  content: null,
  cancelButtonText: "Cancel",
  submitButtonText: "Submit",
  onSubmit: undefined,
  onCancel: undefined,
};

export default FileViewerDialog;
