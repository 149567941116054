import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";
import React from "react";
import { clearIcon } from "../../util/icons";

const ClearButton = ({ className, onClear }) => {
  const ClearIcon = clearIcon();
  return (
    <span style={{ position: "relative", float: "right" }}>
      <Tooltip title="Clear" disableFocusListener>
        <IconButton className={className} onClick={onClear} data-cy="clearIcon">
          <ClearIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </span>
  );
};

ClearButton.propTypes = {
  className: PropTypes.string.isRequired,
  onClear: PropTypes.func.isRequired,
};

export default ClearButton;
