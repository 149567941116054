import "custom-event-polyfill";

const NOTIFICATION_UPDATE_EVENT = "NOTIFICATION_UPDATE";

const NOTIFICATION_LAST_UPDATED_HEADER = "x-notification-last-update";

export const publishNotificationLastUpdated = (lastUpdated) => {
  window.dispatchEvent(
    new CustomEvent(NOTIFICATION_UPDATE_EVENT, {
      detail: lastUpdated,
    })
  );
};

export const subscribeNotificationUpdate = (listener) => {
  window.addEventListener(NOTIFICATION_UPDATE_EVENT, listener, false);
};

export const unsubscribeNotificationUpdate = (listener) => {
  window.removeEventListener(NOTIFICATION_UPDATE_EVENT, listener, false);
};

export const checkNotificationLastUpdatedHeader = (response) => {
  if (response.headers.has(NOTIFICATION_LAST_UPDATED_HEADER)) {
    publishNotificationLastUpdated(
      response.headers.get(NOTIFICATION_LAST_UPDATED_HEADER)
    );
  }

  return response;
};
