import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { searchBusinessServiceAreas } from "../../actions/businessServiceAreas";
import {
  getBusinessServiceAreaLastEdit,
  getBusinessServiceAreaPagination,
  getBusinessServiceAreas,
  getLoggedInUser,
  isLoading,
} from "../../reducers";
import dates from "../../util/dates";
import GridListing from "../common/GridListing";
import TimeAgo from "../common/TimeAgo";
import Pagination from "../common/Pagination";
import { useAuth0 } from "@auth0/auth0-react";

const styles = (theme) => ({
  row: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.grey[200],
    },
  },
  container: {
    paddingTop: theme.spacing(2),
  },
  label: {
    lineHeight: "1.5rem",
  },
});

const BusinessServiceAreasListing = ({
  onSelect,
  selected,
  pagination,
  lastEdit,
  serviceAreas,
  sortBy,
  filter,
  loading,
  localSearchServiceAreas,
  updateSort,
  clearFilter,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const getOrderBy = () => {
    const prefix = sortBy.direction === "desc" ? "-" : "";
    return `${prefix}${sortBy.field}`;
  };

  const getSearchParameters = () => ({
    textSearch: filter.textSearch,
    orderByField: getOrderBy(),
    limit: pagination.pageSize,
    offset: pagination.offset,
  });

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      const searchParameters = {
        ...getSearchParameters(),
        offset: 0,
      };
      localSearchServiceAreas(searchParameters, accessToken);
    })();
  }, [filter, sortBy, lastEdit]);

  const handlePrevious = () => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      const searchParameters = {
        ...getSearchParameters(),
        offset: pagination.previousOffset,
      };
      localSearchServiceAreas(searchParameters, accessToken);
    })();
  };

  const handleNext = () => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      const searchParameters = {
        ...getSearchParameters(),
        offset: pagination.nextOffset,
      };
      localSearchServiceAreas(searchParameters, accessToken);
    })();
  };

  return (
    <>
      <GridListing
        sortedData={serviceAreas}
        loading={loading}
        sortBy={sortBy}
        dense={false}
        isHighlight1={(serviceArea) =>
          selected && selected.id === serviceArea.id
        }
        updateSort={(field, direction) =>
          updateSort({
            field,
            direction,
          })
        }
        onClick={(serviceArea) => onSelect(serviceArea)}
        clearFilter={clearFilter}
        columns={[
          {
            label: "Name",
            name: "name",
            size: 4,
            sortable: true,
            render: (serviceArea) => <>{serviceArea.name}</>,
          },
          {
            label: "Organisations",
            name: "organisations",
            size: 4,
            render: (serviceArea) => (
              <ul data-cy="serviceArea">
                {serviceArea.organisations.map((organisation) => (
                  <li key={organisation.id} data-cy={organisation.name}>
                    {organisation.name}
                  </li>
                ))}
              </ul>
            ),
          },
          {
            label: "Created",
            name: "created",
            size: 2,
            sortable: true,
            render: (serviceArea) => (
              <TimeAgo
                value={dates.parseTimestamp(serviceArea.created)}
                expandable
              />
            ),
          },
          {
            label: "Updated",
            name: "updated",
            size: 2,
            sortable: true,
            render: (serviceArea) => (
              <TimeAgo
                value={dates.parseTimestamp(serviceArea.updated)}
                expandable
              />
            ),
          },
        ]}
      />
      <Pagination
        pagination={pagination}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
      />
    </>
  );
};

BusinessServiceAreasListing.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.object,
  filter: PropTypes.object.isRequired,
  sortBy: PropTypes.object.isRequired,
  updateSort: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired,

  // redux
  serviceAreas: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  lastEdit: PropTypes.number.isRequired,
  localSearchServiceAreas: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

BusinessServiceAreasListing.defaultProps = {
  selected: null,
};

const mapStateToProps = (state) => ({
  loading: isLoading(state),
  loggedInUser: getLoggedInUser(state),
  serviceAreas: getBusinessServiceAreas(state),
  pagination: getBusinessServiceAreaPagination(state),
  lastEdit: getBusinessServiceAreaLastEdit(state),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    localSearchServiceAreas: searchBusinessServiceAreas,
  })
)(BusinessServiceAreasListing);
