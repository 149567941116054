import { requestJSON } from "./http";

interface Filters {
  statuses: string[];
}

interface Change {
  status: string;
}

type Mention = Record<string, unknown>;

const search = (filter: Filters, accessToken: string): Promise<Mention[]> =>
  requestJSON({
    path: "/sentinel/mention/search",
    method: "POST",
    data: filter || {},
    accessToken,
  });

const patch = (
  mentionId: string,
  changes: Change,
  description: string,
  accessToken: string
): Promise<Mention> =>
  requestJSON({
    path: `/sentinel/mention/${mentionId}`,
    method: "PATCH",
    data: {
      fields: Object.keys(changes),
      changes,
      description,
    },
    accessToken,
  });

export default {
  search,
  patch,
};
