import * as PropTypes from "prop-types";
import _ from "lodash";
import React, { Component } from "react";
import ChoiceChips from "../common/ChoiceChips";
import FiltersBar from "../common/FiltersBar";

const statusMap = {
  OPEN: "Open",
  CLOSED: "Closed",
};

const statusOptions = ["OPEN", "CLOSED"].map((value) => ({
  label: statusMap[value],
  value,
}));

const filterWorkItems = (workItems, status) =>
  workItems.filter((action) => {
    if (status === "OPEN") {
      return action.status !== "CLOSED";
    }
    if (status === "CLOSED") {
      return action.status === "CLOSED";
    }
    return true;
  });

class OpenCloseWorkItemFilter extends Component {
  constructor(props) {
    super(props);
    this.state = { filter: "OPEN" };
  }

  componentDidMount() {
    this.props.filteredWorkItemsChange(
      filterWorkItems(this.props.workItems, this.state.filter)
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { workItems } = this.props;
    const { filter } = this.state;

    if (
      !_.isEqual(workItems, prevProps.workItems) ||
      filter !== prevState.filter
    ) {
      this.props.filteredWorkItemsChange(
        filterWorkItems(this.props.workItems, this.state.filter)
      );
    }
  }

  render() {
    const { filter } = this.state;
    const { workItems } = this.props;

    if (workItems.length === 0) {
      return null;
    }

    return (
      <FiltersBar>
        <ChoiceChips
          value={filter}
          options={statusOptions}
          onChange={(value) => this.setState({ filter: value })}
        />
      </FiltersBar>
    );
  }
}

OpenCloseWorkItemFilter.propTypes = {
  workItems: PropTypes.array.isRequired,
  filteredWorkItemsChange: PropTypes.func.isRequired,
};

export default OpenCloseWorkItemFilter;
