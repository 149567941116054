import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { SubmissionError } from "redux-form";
import Avatar from "@material-ui/core/Avatar";
import { createControl } from "../../actions/controls";
import { getRiskById, patchRisk } from "../../actions/risks";
import { fetchTags } from "../../actions/tags";
import { getLabels, getReferenceDataDescription } from "../../reducers";
import controlsApi from "../../services/api/controls";
import dates from "../../util/dates";
import { associationsIcon, deleteIcon, userIcon } from "../../util/icons";
import AlertDialog from "../common/AlertDialog";
import DateAgo from "../common/DateAgo";
import DebouncedTextField from "../common/DebouncedTextField";
import GridListing from "../common/GridListing";
import CreateControlForm, {
  CREATE_CONTROL_FORM_NAME,
} from "../forms/controls/CreateControlForm";
import ExpandableForm from "../forms/ExpandableForm";
import ListPicker from "../ListPicker";
import { useAuth0 } from "@auth0/auth0-react";
import { includeTenantParam } from "@certane/arcadia-web-components";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  content: {
    overflow: "auto",
  },
  controlTypeChip: {
    backgroundColor: theme.palette.grey[300],
    padding: `${theme.spacing(1) / 4}px ${theme.spacing(1)}px`,
    borderRadius: "16px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: "100%",
    whiteSpace: "nowrap",
    marginRight: theme.spacing(1) / 2,
  },
  nowrap: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  createNewLink: {
    cursor: "pointer",
    color: theme.palette.swatch.link,
  },
  inlineIcon: {
    verticalAlign: "middle",
  },
  registerLabel: {
    fontSize: "smaller",
  },
  listItem: {
    width: "100%",
    overflow: "hidden",
  },
  listIcon: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
}));

const RiskControlsCard = ({
  risk,
  riskFieldLabels,
  controlFieldLabels,
  getRiskControlTypeDescription,
  localGetRiskById,
  localCreateControl,
  localPatchRisk,
  localFetchTags,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();
  const [linkControlDialogOpen, setLinkControlDialogOpen] = useState(false);
  const [createControlFormOpen, setCreateControlFormOpen] = useState(false);
  const [toBeDeleted, setToBeDeleted] = useState(null);

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      localFetchTags(accessToken);
    })();
  }, [getAccessTokenSilently]);

  const handleSubmitEdit = async (associatedControls) => {
    const accessToken = await getAccessTokenSilently();
    localPatchRisk(
      risk.id,
      { associatedControls },
      "Edited controls",
      accessToken
    )
      .then(() => setLinkControlDialogOpen(false))
      .catch((submissionError) => {
        throw new SubmissionError({ _error: submissionError.message });
      });
  };

  const handleSubmitRemove = async () => {
    const accessToken = await getAccessTokenSilently();
    localPatchRisk(
      risk.id,
      {
        associatedControls: risk.associatedControls.filter(
          (c) => c.id !== toBeDeleted.id
        ),
      },
      "Removed control",
      accessToken
    )
      .then(() => setToBeDeleted(null))
      .catch((submissionError) => {
        throw new SubmissionError({ _error: submissionError.message });
      });
  };

  const handleSubmitCreate = async (control) => {
    const accessToken = await getAccessTokenSilently();
    localCreateControl(control, accessToken)
      // Refresh this risk so we get the relationship to the new control on client side
      .then(() =>
        localGetRiskById(risk.id, accessToken).then(() =>
          setCreateControlFormOpen(false)
        )
      )
      .catch((submissionError) => {
        throw new SubmissionError({ _error: submissionError.message });
      });
  };

  const asyncControlOptionsFetch = async (
    pickerFilter,
    pagination,
    abortController
  ) => {
    const searchParameters = {
      ...pickerFilter,
      statuses: ["ACTIVE"],
      limit: pagination.pageSize,
      offset: pagination.offset,
      orderByField: "title",
    };
    const accessToken = await getAccessTokenSilently();
    return controlsApi.search(searchParameters, abortController, accessToken);
  };

  const DeleteIcon = deleteIcon();
  const UserIcon = userIcon();
  const LinkIcon = associationsIcon();

  return (
    <Card className={classes.root} elevation={0}>
      <AlertDialog
        title="Remove control?"
        data-cy="Remove control?"
        body={`Are you sure you want to remove control '${
          toBeDeleted && toBeDeleted.title
        }' from this risk?`}
        submitButtonText="Remove"
        open={!!toBeDeleted}
        onCancel={() => setToBeDeleted(null)}
        onSubmit={handleSubmitRemove}
      />
      <ListPicker
        data-cy="select"
        title="Find a control to link"
        actionText="Select"
        maxWidth="md"
        open={linkControlDialogOpen}
        onClose={() => setLinkControlDialogOpen(false)}
        onSubmit={handleSubmitEdit}
        datasource={asyncControlOptionsFetch}
        selected={risk.associatedControls}
        disabledOptions={risk.associatedControls}
        isMulti
        toOption={(control) => ({
          label: control.title,
          id: control.id,
          control,
        })}
        fromOption={(option) => option.control}
        renderFilter={(onChange, filter) => (
          <Grid container spacing={1} alignItems="flex-end">
            <Grid item xs={12}>
              <DebouncedTextField
                data-cy={filter.textSearch}
                value={filter.textSearch}
                onChange={(text) => onChange("textSearch", text)}
                placeholder="Type to filter..."
                margin="none"
                autoFocus
              />
            </Grid>
          </Grid>
        )}
        renderIcon={(control, size) => (
          <Avatar
            className={classes.listIcon}
            style={{
              width: size,
              height: size,
              fontSize: size < 32 ? "small" : undefined,
            }}
            title={getRiskControlTypeDescription(control.type)}
            color="primary"
          >
            {control.type.charAt(0)}
          </Avatar>
        )}
        renderLabel={(control) => (
          <Grid className={classes.listItem} container direction="column">
            <Grid className={classes.listItem} item>
              <Typography
                className={classes.nowrap}
                title={control.title}
                data-cy={control.title}
              >
                {control.title}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <UserIcon
                      className={classes.inlineIcon}
                      fontSize="small"
                      color="action"
                    />
                  </Grid>
                  <Grid item>{control.owner && control.owner.name}</Grid>
                </Grid>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">{control.description}</Typography>
            </Grid>
          </Grid>
        )}
        additionalActions={[
          <Link
            key="create-new"
            className={classes.createNewLink}
            onClick={() => {
              setLinkControlDialogOpen(false);
              setCreateControlFormOpen(true);
            }}
          >
            Can&apos;t find appropriate control? (Create a new one here)
          </Link>,
        ]}
      />
      <CardHeader
        title={riskFieldLabels.labels.associatedControls}
        data-cy={riskFieldLabels.labels.associatedControls}
        action={
          <Grid container>
            <Grid item>
              <Tooltip title="Add control" disableFocusListener>
                <div>
                  <Button
                    onClick={() => setLinkControlDialogOpen(true)}
                    disabled={createControlFormOpen}
                    variant="contained"
                    color={"primary"}
                    disableElevation
                    startIcon={<LinkIcon />}
                  >
                    Link to control
                  </Button>
                </div>
              </Tooltip>
            </Grid>
          </Grid>
        }
      />
      <ExpandableForm
        data-cy="newControl"
        title="New control"
        submitButtonText="Save"
        maxWidth="md"
        formComponent={CreateControlForm}
        formName={CREATE_CONTROL_FORM_NAME}
        open={createControlFormOpen}
        onCancel={() => setCreateControlFormOpen(false)}
        onSubmit={(values) => handleSubmitCreate(values)}
        initialValues={{
          associatedRisks: [{ id: risk.id }],
          critical: false,
        }}
      />
      <CardContent className={classes.content}>
        <GridListing
          data-cy="table"
          sortedData={risk.associatedControls}
          dense={false}
          sortBy={{
            field: "created",
            direction: "asc",
          }}
          loading={false}
          updateSort={() => {
            // do nothing.
          }}
          onClick={(control) =>
            window.open(includeTenantParam(`/controls/${control.id}`), "_blank")
          }
          noItemsText="There are no controls associated with this risk"
          action={(control) => (
            <Tooltip title="Remove" disableFocusListener>
              <IconButton onClick={() => setToBeDeleted(control)}>
                <DeleteIcon data-cy="deleteIcon" />
              </IconButton>
            </Tooltip>
          )}
          columns={[
            {
              label: controlFieldLabels.labels.type,
              name: "type",
              size: 2,
              sortable: false,
              render: (control) => (
                <span>{getRiskControlTypeDescription(control.type)}</span>
              ),
            },
            {
              label: controlFieldLabels.labels.owner,
              name: "owner",
              size: 2,
              sortable: false,
              render: (control) => (
                <span>{control.owner ? control.owner.name : ""}</span>
              ),
            },
            {
              label: controlFieldLabels.labels.title,
              name: "title",
              size: 2,
              sortable: false,
              render: (control) => <span>{control.title}</span>,
            },
            {
              label: controlFieldLabels.labels.description,
              name: "description",
              size: 4,
              sortable: false,
              render: (control) => (
                <span>{_.truncate(control.description, { length: 80 })}</span>
              ),
            },
            {
              label: controlFieldLabels.labels.lastVerified,
              name: "lastVerified",
              size: 2,
              sortable: true,
              render: (control) => {
                const lastVerified = dates.parseDate(control.lastVerified);
                return (
                  lastVerified && (
                    <>
                      {dates.formatDateLong(lastVerified)} <br />
                      <DateAgo value={lastVerified} expandable={false} />
                    </>
                  )
                );
              },
            },
          ]}
        />
      </CardContent>
    </Card>
  );
};

RiskControlsCard.propTypes = {
  risk: PropTypes.object.isRequired,

  // redux
  riskFieldLabels: PropTypes.object.isRequired,
  controlFieldLabels: PropTypes.object.isRequired,
  getRiskControlTypeDescription: PropTypes.func.isRequired,
  localGetRiskById: PropTypes.func.isRequired,
  localCreateControl: PropTypes.func.isRequired,
  localPatchRisk: PropTypes.func.isRequired,
  localFetchTags: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  riskFieldLabels: getLabels(state).Risk,
  controlFieldLabels: getLabels(state).Control,
  getRiskControlTypeDescription: (id) =>
    getReferenceDataDescription(state, "RiskControlType", id),
});

export default connect(mapStateToProps, {
  localGetRiskById: getRiskById,
  localCreateControl: createControl,
  localPatchRisk: patchRisk,
  localFetchTags: fetchTags,
})(RiskControlsCard);
