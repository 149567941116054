import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { scrollToFirstInvalidInput } from "../../../util/formHelpers";
import FormError from "../../common/FormError";
import EntityRelationshipFields from "./EntityRelationshipFields";

export const EDIT_ENTITY_RELATIONSHIP_FORM_NAME =
  "EDIT_ENTITY_RELATIONSHIP_FORM_NAME";

const EditEntityRelationshipForm = ({
  error,
  change,
  untouch,
  fieldLabels,
  customisations,
  defaultVisibilityScope,
  autoPopulateEntitiesByRelationshipTypes,
  filterEntitiesByRelationshipTypes,
}) => (
  <form autoComplete="off">
    {error && <FormError errorMessage={error} />}
    <EntityRelationshipFields
      formName={EDIT_ENTITY_RELATIONSHIP_FORM_NAME}
      fieldLabels={fieldLabels.nestedTypes.entityRelationship}
      change={change}
      untouch={untouch}
      autoPopulateEntitiesByRelationshipTypes={
        autoPopulateEntitiesByRelationshipTypes
      }
      filterEntitiesByRelationshipTypes={filterEntitiesByRelationshipTypes}
      customisations={customisations}
      defaultVisibilityScope={defaultVisibilityScope}
    />
  </form>
);

EditEntityRelationshipForm.propTypes = {
  error: PropTypes.string,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  customisations: PropTypes.array,
  defaultVisibilityScope: PropTypes.object.isRequired,
  autoPopulateEntitiesByRelationshipTypes: PropTypes.array.isRequired,
  filterEntitiesByRelationshipTypes: PropTypes.array.isRequired,
};

EditEntityRelationshipForm.defaultProps = {
  error: null,
  customisations: [],
};

const mapStateToProps = () => ({});

const form = reduxForm({
  form: EDIT_ENTITY_RELATIONSHIP_FORM_NAME,
  onSubmitFail: (errors) => scrollToFirstInvalidInput(errors),
});
const reduxData = connect(mapStateToProps);
export default compose(form, reduxData)(EditEntityRelationshipForm);
