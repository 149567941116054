import PropTypes from "prop-types";
import React from "react";
import dates from "../../util/dates";
import FormCard from "../common/FormCard";
import Scorecard from "../common/Scorecard";
import TimeAgo from "../common/TimeAgo";
import EditSnippetForm, {
  EDIT_SNIPPET_FORM_NAME,
} from "../forms/metropolis/EditSnippetForm";

const SnippetDetailsCard = ({
  className,
  title,
  snippet,
  onChange,
  fieldLabels,
}) => (
  <FormCard
    className={className}
    title={title}
    formTitle={`Edit ${title}`}
    readOnlyView={() => (
      <>
        <Scorecard label={fieldLabels.labels.title}>{snippet.title}</Scorecard>
        <Scorecard label={fieldLabels.labels.category}>
          {snippet.category ? snippet.category.name : "-"}
        </Scorecard>
        <Scorecard label={fieldLabels.labels.updated}>
          <TimeAgo value={dates.parseTimestamp(snippet.updated)} expandable />
        </Scorecard>
      </>
    )}
    formComponent={EditSnippetForm}
    formName={EDIT_SNIPPET_FORM_NAME}
    onSave={onChange}
    initialValues={snippet}
    formProps={{
      fieldLabels,
    }}
  />
);

SnippetDetailsCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  snippet: PropTypes.object.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  onChange: PropTypes.func,
};

SnippetDetailsCard.defaultProps = {
  className: null,
  onChange: null,
};

export default SnippetDetailsCard;
