import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import FormCard from "../../../common/FormCard";
import EditWorkItemComponentForm, {
  EDIT_WORK_ITEM_COMPONENT_FORM_NAME,
} from "../EditWorkItemComponentForm";
import DependantsDetailsReadOnly from "./DependantsDetailsReadOnly";

class DependantsCard extends Component {
  render() {
    const { className, fieldLabels, workItem, onChange } = this.props;

    const { dependants, memberDetails } = workItem;

    const onSave = onChange
      ? (values) => onChange({ dependants: values.dependants })
      : null;

    return (
      <FormCard
        className={className}
        title={fieldLabels.labels.dependants}
        formTitle={`Edit ${fieldLabels.labels.dependants}`}
        readOnlyView={() => (
          <DependantsDetailsReadOnly
            dependants={dependants}
            fieldLabels={fieldLabels}
          />
        )}
        formComponent={EditWorkItemComponentForm}
        formName={EDIT_WORK_ITEM_COMPONENT_FORM_NAME}
        onSave={onSave}
        initialValues={{ dependants, memberDetails }}
        formProps={{
          maxWidth: "md",
          workItem,
          componentName: "DEPENDANTS",
        }}
      />
    );
  }
}

DependantsCard.propTypes = {
  className: PropTypes.string,
  fieldLabels: PropTypes.object.isRequired,
  workItem: PropTypes.object.isRequired,
  onChange: PropTypes.func,
};

DependantsCard.defaultProps = {
  className: undefined,
  onChange: null,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(DependantsCard);
