import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { getReferenceDataType } from "../../reducers";
import ColorScoreChip from "../ColorScoreChip";

const EffectivenessChip = ({
  effectiveness,
  defaultValue,
  controlEffectiveness,
}) => {
  const effectivenessDef =
    effectiveness && controlEffectiveness.values[effectiveness];

  return (
    <ColorScoreChip
      label={effectivenessDef?.description}
      score={effectivenessDef?.props.score}
      defaultValue={defaultValue}
    />
  );
};

EffectivenessChip.propTypes = {
  effectiveness: PropTypes.string,
  defaultValue: PropTypes.string,
  controlEffectiveness: PropTypes.object.isRequired,
};

EffectivenessChip.defaultProps = {
  effectiveness: null,
  defaultValue: "-",
};

const mapStateToProps = (state) => ({
  controlEffectiveness: getReferenceDataType(state, "ControlEffectiveness"),
});

export default connect(mapStateToProps)(EffectivenessChip);
