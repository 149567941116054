import TextField from "@material-ui/core/TextField";
import { debounce, omit } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";

class DebouncedTextField extends Component {
  constructor(props) {
    super(props);

    this.state = { text: props.value };
    this.fireChange = debounce(this.fireChange, props.delay);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      if (!this.props.value) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ text: "" });
      } else if (this.state.text !== this.props.value) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ text: this.props.value });
      }
    }
  }

  handleChange = (text) => {
    const textValue = text.trim();

    if (
      !textValue ||
      (textValue.length > this.props.minLength &&
        textValue !== this.state.text.trim())
    ) {
      this.fireChange(textValue);
    }

    this.setState({ text });
  };

  fireChange(textValue) {
    this.props.onChange(textValue);
  }

  render() {
    const { text } = this.state;
    const rest = omit(this.props, "value", "onChange", "delay", "minLength");

    return (
      <TextField
        value={text}
        onChange={(event) => this.handleChange(event.target.value)}
        fullWidth
        {...rest}
      />
    );
  }
}

DebouncedTextField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  delay: PropTypes.number,
  minLength: PropTypes.number,
  margin: PropTypes.string,
};

DebouncedTextField.defaultProps = {
  value: "",
  onChange: () => {
    // do nothing.
  },
  delay: 600,
  minLength: 2,
  margin: "normal",
};

export default DebouncedTextField;
