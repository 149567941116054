import { useAuth0 } from "@auth0/auth0-react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";
import { required } from "redux-form-validators";
import tinycolor from "tinycolor2";
import fundsApi from "../../../services/api/funds";
import { addIcon, deleteIcon, warningIcon } from "../../../util/icons";
import Avatar from "../../common/Avatar.tsx";
import DebouncedTextField from "../../common/DebouncedTextField";
import ListPicker from "../../ListPicker";
import WrappedNonInteractive from "../wrapper/WrappedNonInteractive";

const useStyles = makeStyles((theme) => ({
  label: {
    position: "relative",
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
  },
  nowrap: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(0.25),
    marginTop: theme.spacing(0.25),
  },
  chipIcon: {
    marginLeft: 0,
  },
  chipSingle: {
    maxWidth: "100%",
    marginBottom: theme.spacing(0.25),
    marginTop: theme.spacing(0.25),
  },
  chipRoot: {
    maxWidth: "100%",
  },
  relationship: {
    backgroundColor: tinycolor(theme.palette.primary.main).setAlpha(0.1),
    marginLeft: theme.spacing(1),
  },
  warning: {
    backgroundColor: tinycolor(theme.palette.warning.main).setAlpha(0.1),
    marginLeft: theme.spacing(1),
  },
  filter: {
    marginBottom: theme.spacing(1),
  },
  borderBottom: {
    borderBottom: `solid 1px ${theme.palette.grey[500]}`,
    paddingBottom: theme.spacing(1),
    "&:hover": {
      borderBottom: "solid 2px",
      paddingBottom: `${theme.spacing(1) - 1}px`,
    },
  },
  toggleVisibilityButton: {
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    marginLeft: 0,
    marginBottom: theme.spacing(0.25),
    marginTop: theme.spacing(0.25),
  },
}));

const EntityRelationshipFundFields = ({
  meta: { error, submitFailed },
  products,
  funds,
  regions,
  fieldLabels,
  label,
  isRequired,
  className,
  margin,
  fields,
  change,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();
  const [listPickerOpen, setListPickerOpen] = useState(false);

  const asyncFundOptionsFetch = async (
    pickerFilter,
    pagination,
    abortController
  ) => {
    const accessToken = await getAccessTokenSilently();
    const { textSearch, byProducts } = pickerFilter;
    const searchParameters = {
      textSearch,
      regions: byProducts ? regions : undefined,
      limit: pagination.pageSize,
      offset: pagination.offset,
      orderByField: "name",
      excludeGroupFilters: false,
      productIds: byProducts
        ? [...(products || []).map((product) => product.id)]
        : [],
    };
    return fundsApi.search(searchParameters, abortController, accessToken);
  };

  const setFunds = (selected) => {
    const newFunds = selected.map((fund) => {
      const existingLinkedFund = funds.find((f) => f.id === fund.id);
      if (existingLinkedFund) {
        return existingLinkedFund;
      }
      return fund;
    });
    setListPickerOpen(false);
    setTimeout(() => change(fields.name, newFunds), 10);
  };

  const getProducts = (fund) =>
    products.filter((p) => p.fund && p.fund.id === fund.id);

  const remove = (index) => {
    fields.remove(index);
  };

  const getTooltip = (relatedProducts) => {
    if (relatedProducts.length === 0) {
      return "";
    }
    const productNames = relatedProducts.map((p) => p.name).join(", ");
    return `Fund for ${productNames}`;
  };

  const DeleteIcon = deleteIcon();
  const AddIcon = addIcon();
  const WarningIcon = warningIcon();

  return (
    <FormControl
      className={className}
      fullWidth
      margin={margin}
      name={`position-${fields.name}._error`}
    >
      <InputLabel
        className={classes.label}
        shrink
        required={isRequired}
        data-cy={label}
      >
        {label}
      </InputLabel>
      <List className={classes.borderBottom}>
        {fields.map((fieldName, index) => {
          const fund = funds[index];
          const relatedProducts = !fund ? [] : getProducts(fund);
          const showWarning =
            relatedProducts.length === 0 && products.length !== 0;
          return (
            // eslint-disable-next-line react/no-array-index-key
            <ListItem key={index} className={classes.listItem}>
              <Grid container alignItems="center">
                <Grid item>
                  <Field
                    name={`${fieldName}`}
                    data-cy={`${fieldName}`}
                    component={WrappedNonInteractive}
                    validate={required(
                      `${fieldLabels.labels.funds} is required`
                    )}
                    required
                    margin="none"
                    fullWidth={false}
                    render={(value) => (
                      <Chip
                        className={classes.chipSingle}
                        classes={{
                          root: classes.chipRoot,
                          label: classes.nowrap,
                          icon: classes.chipIcon,
                        }}
                        icon={
                          <Avatar
                            name={(value.name || "Unknown").charAt(0)}
                            size={30}
                            round
                          />
                        }
                        title={getTooltip(relatedProducts)}
                        label={value.name}
                        data-cy={value.name}
                      />
                    )}
                  />
                </Grid>
                {showWarning && (
                  <Grid item>
                    <Chip
                      className={classes.warning}
                      icon={<WarningIcon />}
                      label="Not related"
                      size="small"
                      variant="outlined"
                    />
                  </Grid>
                )}
              </Grid>
              <ListItemSecondaryAction>
                <IconButton
                  aria-label="Remove"
                  onClick={() => remove(index)}
                  data-cy="remove"
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Button
            size="small"
            color="primary"
            aria-label="Add"
            onClick={() => setListPickerOpen(true)}
            data-cy="Add"
          >
            <AddIcon /> Add
          </Button>
        </Grid>
      </Grid>
      <ListPicker
        data-cy="selectFunds"
        title="Select funds"
        actionText="Select"
        open={listPickerOpen}
        onClose={() => setListPickerOpen(false)}
        onSubmit={setFunds}
        datasource={asyncFundOptionsFetch}
        selected={funds}
        isMulti
        clearable
        toOption={(fund) => ({ label: fund.name, id: fund.id, fund })}
        fromOption={(option) => option.fund}
        renderIcon={(fund, size) => (
          <Avatar name={(fund.name || "Unknown").charAt(0)} size={size} round />
        )}
        filterInitialValues={{ byProducts: true, sargonOnly: true }}
        renderFilter={(onChange, filter) => (
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
            className={classes.filter}
          >
            <Grid
              item
              style={{ flex: 1, marginRight: "3px", minWidth: "200px" }}
            >
              <DebouncedTextField
                value={filter.textSearch}
                onChange={(text) => onChange("textSearch", text)}
                placeholder="Type to filter..."
                margin="none"
                autoFocus
                fullWidth
                data-cy="typeToFilter"
              />
            </Grid>
            <Grid item>
              <Grid
                container
                alignItems="flex-start"
                justifyContent="space-between"
                direction="column"
              >
                {products.length > 0 && (
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={!!filter.byProducts}
                          onChange={(evt) =>
                            onChange("byProducts", evt.target.checked)
                          }
                          name="byProducts"
                        />
                      }
                      label="Filter by selected products"
                      data-cy="FilterBySelectedProducts"
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      />
      {error && submitFailed && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
};

EntityRelationshipFundFields.propTypes = {
  meta: PropTypes.object.isRequired,
  products: PropTypes.array,
  funds: PropTypes.array,
  regions: PropTypes.array,
  fields: PropTypes.object.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  formName: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  className: PropTypes.string,
  margin: PropTypes.string,
};

EntityRelationshipFundFields.defaultProps = {
  isRequired: false,
  className: null,
  margin: "dense",
  regions: [],
  products: [],
  funds: [],
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(EntityRelationshipFundFields);
