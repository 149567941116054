import { requestJSON } from "./http";

const decorateSearchParameters = (filter) =>
  filter ? { __type: "Snippet", ...filter } : { __type: "Snippet" };

const search = (searchParams, abortController, accessToken) =>
  requestJSON({
    path: "/metropolis/snippet/search",
    method: "POST",
    data: decorateSearchParameters(searchParams),
    abortController,
    accessToken,
  });

const create = (snippet, accessToken) =>
  requestJSON({
    path: "/metropolis/snippet",
    method: "POST",
    data: snippet,
    accessToken,
  });

const load = (snippetId, accessToken) =>
  requestJSON({ path: `/metropolis/snippet/${snippetId}`, accessToken });

const patch = (id, changes, description, accessToken) =>
  requestJSON({
    path: `/metropolis/snippet/${id}`,
    method: "PATCH",
    data: {
      fields: Object.keys(changes),
      changes,
      description,
    },
    accessToken,
  });

const addContent = (id, version, accessToken) =>
  requestJSON({
    path: `/metropolis/snippet/${id}/content`,
    method: "POST",
    data: version,
    accessToken,
  });

// render a snippet (note: productId is optional)
const render = (snippetId, productId, activeVersion, accessToken) => {
  const query =
    activeVersion || activeVersion === 0 ? `?version=${activeVersion}` : "";
  const baseUrl = productId
    ? `/metropolis/product/${productId}/snippet/${snippetId}/render`
    : `/metropolis/snippet/${snippetId}/render`;
  return requestJSON({ path: `${baseUrl}${query}`, accessToken });
};

// check for available slug (note: productId is optional)
const checkSlugAvailability = (productId, slug, id, accessToken) => {
  const baseUrl = "/metropolis/snippet/slug-available";
  const query = id ? `?id=${id}` : "";
  const url = productId
    ? `${baseUrl}/${productId}/${slug}${query}`
    : `${baseUrl}/${slug}${query}`;
  return requestJSON({ path: url, accessToken });
};

const checkReferenceAvailability = (reference, accessToken) =>
  requestJSON({
    path: `/metropolis/snippet/reference-available/${reference}`,
    accessToken,
  });

const remove = (id, accessToken) =>
  requestJSON({
    path: `/metropolis/snippet/${id}`,
    method: "DELETE",
    accessToken,
  });

export default {
  search,
  create,
  patch,
  addContent,
  load,
  render,
  checkSlugAvailability,
  checkReferenceAvailability,
  remove,
};
