import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import DateAgo from "../../common/DateAgo";

const styles = (theme) => ({
  strike: {
    display: "flex",
    alignItems: "center",
    "&:after": {
      content: '""',
      height: "1px",
      width: "100%",
      flexGrow: "1",
      flexShrink: "1",
      flexBasis: "auto",
      backgroundColor: theme.palette.swatch.grey4,
    },
  },
  typography: {
    // marginRight: theme.spacing(2),
    flexShrink: "0",
  },
});

const Date = ({ classes, time }) => (
  <Typography className={classes.strike} variant="subtitle1" color="primary">
    <DateAgo
      textClass={classes.typography}
      value={time}
      expandable
      data-cy="dateAgo"
    />
  </Typography>
);

Date.propTypes = {
  classes: PropTypes.object.isRequired,
  time: PropTypes.string.isRequired,
};

export default withStyles(styles)(Date);
