import { combineReducers } from "redux";
import {
  USERS_CLEAR_ACTIVE,
  USERS_PATCH,
  USERS_SEARCH,
} from "../actions/users";
import { publishActionToast } from "../services/toasts";

const successToasts = {
  [USERS_PATCH]: "Updated user successfully",
};

const byId = (state = {}, action) => {
  publishActionToast(action, "USERS_", successToasts);

  switch (action.type) {
    case `${USERS_SEARCH}_SUCCESS`:
    case `${USERS_PATCH}_SUCCESS`:
      return {
        ...state,
        ...action.response.entities.users,
      };

    default:
      break;
  }

  return state;
};

const defaultActive = {
  dashboard: [],
  dashboardLastUpdate: 0,
  activeUser: null,
  lastEdit: 0,
  error: null,
  pagination: {
    offset: 0,
    pageSize: 50,
    resultCount: 0,
  },
};

const active = (state = defaultActive, action) => {
  switch (action.type) {
    case USERS_CLEAR_ACTIVE:
      return {
        ...defaultActive,
      };

    case `${USERS_SEARCH}_SUCCESS`:
      return {
        ...state,
        dashboard: [...action.response.result],
        dashboardLastUpdate: new Date().getTime(),
        pagination: {
          ...action.response.pagination,
          pageSize: defaultActive.pagination.pageSize,
        },
      };

    case `${USERS_PATCH}_SUCCESS`:
      return {
        ...state,
        activeUser: action.response.result,
        lastEdit: new Date().getTime(),
      };

    default:
      break;
  }

  return state;
};

export default combineReducers({
  active,
  byId,
});

export const getActiveUser = (state) =>
  state.active.activeProduct ? state.byId[state.active.activeUser] : null;
export const getAll = (state) =>
  state.active.dashboard.map((id) => state.byId[id]);
export const getPagination = (state) => state.active.pagination;
export const getLastEdit = (state) => state.active.lastEdit;
