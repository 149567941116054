import { useAuth0 } from "@auth0/auth0-react";
import { roles } from "@certane/arcadia-web-components";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  addContent,
  deleteSnippetById,
  getSnippetById,
  patchSnippet,
} from "../../../actions/snippets";
import EntityRelationshipCard from "../../../components/business/EntityRelationshipCard";
import ActionButton from "../../../components/common/ActionButton";
import ActionHeading from "../../../components/common/ActionHeading";
import AlertDialog from "../../../components/common/AlertDialog";
import BreadcrumbLink from "../../../components/common/BreadcrumbLink";
import Container from "../../../components/common/Container";
import HeaderBar from "../../../components/common/HeaderBar";
import PageHeading from "../../../components/common/PageHeading";
import Error404 from "../../../components/error/Error404";
import LoadingIndicator from "../../../components/LoadingIndicator";
import SnippetDetailsCard from "../../../components/metropolis/SnippetDetailsCard";
import SnippetLinksCard from "../../../components/metropolis/SnippetLinksCard";
import SnippetVersionsCard from "../../../components/metropolis/SnippetVersionsCard";
import {
  getActiveSnippet,
  getLabels,
  getLoggedInUser,
  isLoading,
} from "../../../reducers";
import { deleteIcon, snippetIcon } from "../../../util/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  contents: {
    marginBottom: "75px",
  },
  tabs: {
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
  },
  tabRoot: {
    minWidth: "100px",
    padding: theme.spacing(2),
  },
  card: {
    marginBottom: theme.spacing(2),
  },
}));

const relationshipTypes = [
  "PROMOTER",
  "SUB_PROMOTER",
  "TRUSTEE",
  "RESPONSIBLE_ENTITY",
  "KIWISAVER_MANAGER",
];

const SnippetId = ({
  match: { params },
  history,
  loggedInUser,
  loading,
  snippet,
  fieldLabels,
  localGetSnippetById,
  localDeleteSnippetById,
  localPatchSnippet,
  localAddContent,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);
  const [deleteOpen, setDeleteOpen] = useState(false);

  useEffect(() => {
    const { snippetId } = params;
    (async () => {
      const accessToken = await getAccessTokenSilently();
      localGetSnippetById(snippetId, accessToken);
    })();
  }, []);

  const handleDelete = async () => {
    const accessToken = await getAccessTokenSilently();
    return localDeleteSnippetById(snippet.id, accessToken).then(() => {
      history.push("/metropolis");
    });
  };

  if (!snippet && loading) {
    return <LoadingIndicator size={60} />;
  }

  if (!snippet && !loading) {
    return <Error404 />;
  }

  const canDelete = loggedInUser
    ? _.intersection([...roles.ADMIN_ROLES], loggedInUser.roles).length > 0
    : false;
  const readonly = loggedInUser
    ? _.intersection(
        [
          ...roles.ADMIN_ROLES,
          roles.COMPLIANCE_OWNER,
          roles.COMPLIANCE_OFFICER,
        ],
        loggedInUser.roles
      ).length === 0
    : true;

  const handlePatch = async (values, description) => {
    const accessToken = await getAccessTokenSilently();
    return localPatchSnippet(snippet.id, values, description, accessToken);
  };

  const handleAddContent = async (values) => {
    const accessToken = await getAccessTokenSilently();
    return localAddContent(snippet.id, values, accessToken);
  };

  const headerActions = [];
  if (canDelete) {
    headerActions.push(
      <ActionButton
        tooltip="Delete snippet"
        icon={deleteIcon()}
        onClick={() => setDeleteOpen(true)}
      />
    );
  }

  return (
    <div className={classes.root}>
      <AlertDialog
        title="Delete snippet?"
        body="Deleting this snippet will cause its serving URL to stop working. This cannot be undone. Are you sure you want to delete it?"
        submitButtonText="Delete"
        open={deleteOpen}
        onCancel={() => setDeleteOpen(false)}
        onSubmit={handleDelete}
      />
      <HeaderBar>
        <BreadcrumbLink to="/metropolis" label="Content" includeArrow />
        <ActionHeading
          heading={<PageHeading icon={snippetIcon()} heading={snippet.title} />}
          actions={headerActions}
        />
      </HeaderBar>
      <Paper elevation={0} square>
        <Container>
          <Tabs
            value={activeTab}
            onChange={(event, value) => setActiveTab(value)}
            textColor="primary"
            variant="scrollable"
            scrollButtons="off"
            className={classes.tabs}
          >
            <Tab
              classes={{
                root: classes.tabRoot,
              }}
              label="Snippet details"
            />
            <Tab
              classes={{
                root: classes.tabRoot,
              }}
              label="Content"
            />
          </Tabs>
        </Container>
      </Paper>
      <Container>
        {activeTab === 0 && (
          <>
            <EntityRelationshipCard
              className={classes.card}
              title={fieldLabels.labels.entityRelationship}
              baseEntity={snippet}
              fieldLabels={fieldLabels}
              onChange={
                readonly
                  ? null
                  : (values) =>
                      handlePatch(values, "Edited entity relationships")
              }
              customisations={[
                {
                  field: "entityRelationship.financialProducts",
                  state: snippet.singleProductNamespaced
                    ? "READ_ONLY"
                    : "INCLUDED",
                  helpText: snippet.singleProductNamespaced
                    ? "Snippet is fixed to single product"
                    : "",
                },
                {
                  field: "entityRelationship.organisations",
                  state: loggedInUser.externalUser ? "HIDDEN" : "INCLUDED",
                },
                {
                  field: "entityRelationship.businessServiceAreas",
                  state: "HIDDEN",
                },
                {
                  field: "entityRelationship.regions",
                  state: "HIDDEN",
                },
              ]}
              defaultVisibilityScope={{
                INTERNAL: "ORGANISATION",
                EXTERNAL: "ORGANISATION",
              }}
              autoPopulateEntitiesByRelationshipTypes={relationshipTypes}
              filterEntitiesByRelationshipTypes={relationshipTypes}
            />
            <SnippetDetailsCard
              className={classes.card}
              snippet={snippet}
              title="Snippet details"
              fieldLabels={fieldLabels}
              onChange={
                readonly
                  ? null
                  : (values) => handlePatch(values, "Edited snippet details")
              }
            />
            <SnippetLinksCard
              className={classes.card}
              snippet={snippet}
              title="Snippet links"
              fieldLabels={fieldLabels}
            />
          </>
        )}
        {activeTab === 1 && (
          <SnippetVersionsCard
            className={classes.card}
            fieldLabels={fieldLabels}
            snippet={snippet}
            onAddContent={readonly ? null : handleAddContent}
          />
        )}
      </Container>
    </div>
  );
};

SnippetId.propTypes = {
  history: PropTypes.object.isRequired,
  loggedInUser: PropTypes.object,
  localGetSnippetById: PropTypes.func.isRequired,
  localDeleteSnippetById: PropTypes.func.isRequired,
  localPatchSnippet: PropTypes.func.isRequired,
  localAddContent: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  snippet: PropTypes.object,
  fieldLabels: PropTypes.object.isRequired,
};

SnippetId.defaultProps = {
  loggedInUser: null,
  snippet: null,
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
  snippet: getActiveSnippet(state),
  loading: isLoading(state),
  fieldLabels: getLabels(state).Snippet,
});

export default connect(mapStateToProps, {
  localGetSnippetById: getSnippetById,
  localDeleteSnippetById: deleteSnippetById,
  localPatchSnippet: patchSnippet,
  localAddContent: addContent,
})(SnippetId);
