import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { clearIcon } from "../../util/icons";

const styles = (theme) => ({
  fullWidth: {
    background: theme.palette.common.white,
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },

    "& label, & .select-field": {
      fontSize: "0.8125rem",
    },
  },
  floating: {
    background: theme.palette.common.white,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },

    "& label, & .select-field": {
      fontSize: "0.8125rem",
    },
  },
  gridItem: {
    width: "100%",
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      width: "auto",
      borderLeft: `1px solid ${theme.palette.divider}`,
      "&:first-child": {
        borderLeft: "none",
      },
      "&:last-child": {
        borderLeft: "none",
        marginLeft: "auto",
      },
    },
  },
  overlay: {
    borderRadius: "5px",
    width: "100%",
    background: theme.palette.secondary.light,
  },
  overlayTitle: {
    padding: theme.spacing(2),
  },
});

class FiltersBar extends Component {
  render() {
    const { classes, children, fullWidth, overhead, overlay, dismissOverlay } =
      this.props;

    const ClearIcon = clearIcon();
    const elements = children instanceof Array ? children : [children];

    return (
      <div className={fullWidth ? classes.fullWidth : classes.floating}>
        {overlay && dismissOverlay && (
          <Grid
            container
            alignContent="flex-start"
            className={classes.overlay}
            alignItems="center"
          >
            <Grid item className={classes.gridItem}>
              <Typography
                variant="subtitle1"
                className={classes.overlayTitle}
                data-cy="subtitle1"
              >
                {overlay}
              </Typography>
            </Grid>
            <Grid item className={classes.gridItem}>
              <div>
                <Tooltip title="Dismiss" disableFocusListener>
                  <IconButton
                    onClick={() => dismissOverlay()}
                    data-cy="dismiss"
                  >
                    <ClearIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </Grid>
          </Grid>
        )}
        {!overlay && overhead}
        {!overlay && (
          <Grid container alignContent="flex-start" spacing={2}>
            {elements
              .filter((child) => !!child)
              .map((child, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Grid key={index} item className={classes.gridItem}>
                  {child}
                </Grid>
              ))}
            <Grid item className={classes.gridItem}>
              {this.props.clearSearchFilter && (
                <div>
                  <Tooltip title="Clear filters" disableFocusListener>
                    <IconButton
                      size="small"
                      onClick={() => this.props.clearSearchFilter()}
                      data-cy="clearFilters"
                    >
                      <ClearIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}

FiltersBar.propTypes = {
  classes: PropTypes.object.isRequired,
  fullWidth: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  overlay: PropTypes.string,
  dismissOverlay: PropTypes.func,
  overhead: PropTypes.node,
  clearSearchFilter: PropTypes.func,
};

FiltersBar.defaultProps = {
  fullWidth: true,
  overhead: null,
  overlay: null,
  dismissOverlay: null,
  clearSearchFilter: null,
};

export default withStyles(styles)(FiltersBar);
