import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { getRouterLocations } from "../../../reducers";
import {
  getWorkItemLink,
  workItemSchedulePath,
  workItemSchedulesPath,
  auditReportPath,
  controlPath,
} from "../../../routes/routeUtils";
import BreadcrumbLink from "../../common/BreadcrumbLink";

const WorkItemBreadcrumb = ({ workItem, isTemplate, routerLocations }) => {
  const lastUrl = routerLocations[1]
    ? routerLocations[1].location.pathname.toLowerCase()
    : "";

  const breadcrumbLinks = [];
  const breadcrumbLabels = [];

  if (isTemplate) {
    breadcrumbLinks.push(workItemSchedulesPath());
    breadcrumbLabels.push("Compliance calendar");

    breadcrumbLinks.push(workItemSchedulePath(workItem.workItemSchedule.id));
    breadcrumbLabels.push("Schedule");
  } else if (workItem.type === "AUDIT_FINDING" && lastUrl.includes("audit")) {
    breadcrumbLinks.push("/audits");
    breadcrumbLabels.push("Audits");

    breadcrumbLinks.push(auditReportPath(workItem.auditReport.id));
    breadcrumbLabels.push(workItem.auditReport.title);
  } else if (
    workItem.type === "CONTROL_VERIFICATION" &&
    lastUrl.includes("controls")
  ) {
    breadcrumbLinks.push("/controls");
    breadcrumbLabels.push("Controls");

    breadcrumbLinks.push(controlPath(workItem.associatedControl.id));
    breadcrumbLabels.push(workItem.associatedControl.title);
  } else {
    breadcrumbLinks.push("/work-items");
    breadcrumbLabels.push("Work items");

    if (workItem.hasParent && workItem.parent.title) {
      breadcrumbLinks.push(getWorkItemLink(workItem.parent));
      breadcrumbLabels.push(workItem.parent.title);
    }
  }

  return (
    <BreadcrumbLink
      to={breadcrumbLinks}
      label={breadcrumbLabels}
      includeArrow
    />
  );
};

WorkItemBreadcrumb.propTypes = {
  workItem: PropTypes.object.isRequired,
  routerLocations: PropTypes.array.isRequired,
  isTemplate: PropTypes.bool,
};

WorkItemBreadcrumb.defaultProps = {
  isTemplate: false,
};

const mapStateToProps = (state) => ({
  routerLocations: getRouterLocations(state),
});

export default connect(mapStateToProps)(WorkItemBreadcrumb);
