import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { getLabels } from "../../../reducers";
import {
  getAsyncValidate,
  scrollToFirstInvalidInput,
} from "../../../util/formHelpers";
import CardSection from "../../common/CardSection";
import FormError from "../../common/FormError";
import EntityRelationshipFields from "../business/EntityRelationshipFields";
import MediaDetailFields from "./MediaDetailFields";
import MediaDocumentFields, {
  referenceAsyncValidator,
} from "./MediaDocumentFields";

const useStyles = makeStyles(() => ({}));

const relationshipTypes = [
  "PROMOTER",
  "SUB_PROMOTER",
  "TRUSTEE",
  "RESPONSIBLE_ENTITY",
  "KIWISAVER_MANAGER",
];

export const MEDIA_FORM_NAME = "mediaForm";

const asyncValidators = {
  "documentVersions[].reference": {
    allFields: (values) =>
      (values.documentVersions || []).map(
        (v, i) => `documentVersions[${i}].reference`
      ),
    ...referenceAsyncValidator,
  },
};

const MediaForm = ({
  error,
  fieldLabels,
  change,
  untouch,
  touch,
  disabledFutureDates,
}) => {
  const classes = useStyles();

  return (
    <form autoComplete="off" className={classes.root}>
      {error && <FormError errorMessage={error} />}
      <Card elevation={0}>
        <CardContent>
          <CardSection title={fieldLabels.labels.entityRelationship}>
            <EntityRelationshipFields
              formName={MEDIA_FORM_NAME}
              fieldLabels={fieldLabels.nestedTypes.entityRelationship}
              change={change}
              untouch={untouch}
              customisations={[
                {
                  field: "entityRelationship.businessServiceAreas",
                  state: "HIDDEN",
                },
                {
                  field: "entityRelationship.regions",
                  state: "HIDDEN",
                },
              ]}
              autoPopulateEntitiesByRelationshipTypes={relationshipTypes}
              filterEntitiesByRelationshipTypes={relationshipTypes}
              defaultVisibilityScope={{
                INTERNAL: "ORGANISATION",
                EXTERNAL: "ORGANISATION",
              }}
            />
          </CardSection>
          <CardSection title="Media details" gutterBottom>
            <MediaDetailFields
              fieldLabels={fieldLabels}
              editMode={false}
              change={change}
              touch={touch}
              formName={MEDIA_FORM_NAME}
            />
          </CardSection>
          <CardSection title="Document">
            <MediaDocumentFields
              fieldLabels={fieldLabels}
              namePrefix="documentVersions[0]"
              editMode
              disabledFutureDates={disabledFutureDates}
              change={change}
              formName={MEDIA_FORM_NAME}
            />
          </CardSection>
        </CardContent>
      </Card>
    </form>
  );
};

MediaForm.propTypes = {
  // redux
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  touch: PropTypes.func.isRequired,
  error: PropTypes.string,
  fieldLabels: PropTypes.object.isRequired,
  disabledFutureDates: PropTypes.array,
};

MediaForm.defaultProps = {
  error: null,
};

const mapStateToProps = (state) => ({
  fieldLabels: getLabels(state).Media,
});

const form = reduxForm({
  form: MEDIA_FORM_NAME,
  asyncValidate: getAsyncValidate(asyncValidators),
  asyncBlurFields: ["documentVersions[].reference"],
  onSubmitFail: (errors) => scrollToFirstInvalidInput(errors),
});

const reduxData = connect(mapStateToProps);

export default compose(form, reduxData)(MediaForm);
