import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { useLocation } from "react-router";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Login: React.FC = () => {
  const { loginWithRedirect } = useAuth0();
  const query = useQuery();
  const connection = query.get("connection") || "sargon-pay-okta";
  const returnTo = query.get("next") || query.get("returnTo") || "/dashboard";

  useEffect(() => {
    (async () => {
      await loginWithRedirect({
        connection,
        appState: { returnTo },
      });
    })();
  });

  return <div />;
};

export default Login;
