import * as PropTypes from "prop-types";
import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

const WrappedTriStateCheckbox = ({
  input,
  label,
  meta: { touched, error, invalid },
  margin,
  color,
  checkedValue,
  uncheckedValue,
  ...custom
}) => {
  const values = [uncheckedValue, null, checkedValue];
  const nextValue = values[(values.indexOf(input.value || null) + 1) % 3];
  const isChecked = input.value === checkedValue;
  const isUnChecked = input.value === uncheckedValue;

  return (
    <>
      <FormControlLabel
        name={`position-${input.name}`}
        control={
          <Checkbox
            color={color}
            checked={isChecked}
            indeterminate={!isChecked && !isUnChecked}
            onChange={() => input.onChange(nextValue)}
            {...custom}
          />
        }
        margin={margin}
        label={label}
      />
      {touched && error && (
        <FormHelperText error={touched && invalid}>{error}</FormHelperText>
      )}
    </>
  );
};

WrappedTriStateCheckbox.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.node.isRequired,
  color: PropTypes.string,
  checkedValue: PropTypes.any.isRequired,
  uncheckedValue: PropTypes.any.isRequired,
  margin: PropTypes.string,
};

WrappedTriStateCheckbox.defaultProps = {
  margin: "dense",
  color: "primary",
};

export default WrappedTriStateCheckbox;
