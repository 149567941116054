import { combineReducers } from "redux";
import {
  AUDIT_REPORT_CLEAR_ACTIVE,
  AUDIT_REPORT_CREATE,
  AUDIT_REPORT_GET_BY_ID,
  AUDIT_REPORT_PATCH,
  AUDIT_REPORT_SEARCH,
  AUDIT_SAVE_DOCUMENTS,
} from "../actions/auditReports";
import { publishActionToast } from "../services/toasts";
import logger from "../util/logger";

const successToasts = {
  [AUDIT_REPORT_CREATE]: "Create audit successfully",
  [AUDIT_REPORT_PATCH]: "Updated audit successfully",
};

const byId = (state = {}, action) => {
  publishActionToast(action, "AUDIT_REPORT_", successToasts);

  switch (action.type) {
    case `${AUDIT_REPORT_SEARCH}_SUCCESS`:
    case `${AUDIT_REPORT_GET_BY_ID}_SUCCESS`:
    case `${AUDIT_REPORT_CREATE}_SUCCESS`:
    case `${AUDIT_REPORT_PATCH}_SUCCESS`:
    case `${AUDIT_SAVE_DOCUMENTS}_SUCCESS`:
      return {
        ...state,
        ...action.response.entities.auditReport,
      };

    default:
      break;
  }

  return state;
};

const defaultActive = {
  dashboard: [],
  dashboardLastUpdate: 0,
  activeAuditReport: null,
  error: null,
  searchAbortController: null,
  pagination: {
    offset: 0,
    pageSize: 50,
    resultCount: 0,
  },
};

const active = (state = defaultActive, action) => {
  switch (action.type) {
    case AUDIT_REPORT_CLEAR_ACTIVE:
      return {
        ...defaultActive,
      };

    case `${AUDIT_REPORT_SEARCH}_INPROGRESS`:
      if (state.searchAbortController) {
        logger.info("aborting previous unfinished request");
        state.searchAbortController.abort();
      }
      return {
        ...state,
        searchAbortController: action.abortController,
      };

    case `${AUDIT_REPORT_SEARCH}_FAILURE`:
      return {
        ...state,
        searchAbortController: null,
      };

    case `${AUDIT_REPORT_SEARCH}_SUCCESS`:
      return {
        ...state,
        dashboard: [...action.response.result],
        dashboardLastUpdate: new Date().getTime(),
        searchAbortController: null,
        pagination: {
          ...action.response.pagination,
          pageSize: defaultActive.pagination.pageSize,
        },
      };

    case `${AUDIT_REPORT_GET_BY_ID}_SUCCESS`:
    case `${AUDIT_REPORT_CREATE}_SUCCESS`:
      return {
        ...state,
        activeAuditReport: action.response.result,
      };

    default:
      break;
  }

  return state;
};

export default combineReducers({
  active,
  byId,
});

export const getActiveAuditReport = (state) =>
  state.active.activeAuditReport
    ? state.byId[state.active.activeAuditReport]
    : null;
export const getAuditReports = (state) =>
  state.active.dashboard.map((id) => state.byId[id]);
export const getSearchPagination = (state) => state.active.pagination;
