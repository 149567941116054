import { useAuth0 } from "@auth0/auth0-react";
import FormHelperText from "@material-ui/core/FormHelperText";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { FormattedDate } from "react-intl";
import { connect } from "react-redux";
import { deleteDocument, saveDocuments } from "../../../../actions/workItems";
import { Doc } from "../../../../model/Doc";
import { FieldLabels } from "../../../../model/FieldLabels";
import { ReferenceData } from "../../../../model/ReferenceData";
import { User } from "../../../../model/User";
import { IncidentReportTo, WorkItem } from "../../../../model/WorkItem";
import {
  getLoggedInUser,
  getReferenceDataDescription,
  getReferenceDataType,
} from "../../../../reducers";
import api from "../../../../services/api";
import formatters from "../../../../util/formatters";
import {
  appliesByRoles,
  appliesByTemplate,
  getResolvedDefinition,
} from "../../../../util/workItemTypeUtils";
import AlertDialog from "../../../common/AlertDialog";
import CardSection from "../../../common/CardSection";
import ComponentScorecard from "../../../common/ComponentScorecard";
import FormCard from "../../../common/FormCard";
import FlaggedFileUpload from "../complaint/thirdpartyauthority/FlaggedFileUpload";
import EditWorkItemComponentForm, {
  EDIT_WORK_ITEM_COMPONENT_FORM_NAME,
} from "../EditWorkItemComponentForm";

const onFileClick = (
  source: string,
  document: Doc,
  workItemId: string,
  accessToken: string
) => {
  api.workItems
    .documentDownloadUrl(workItemId, source, document.id, false, accessToken)
    .then((location) => window.open(location, "_blank"));
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  linkText: {
    cursor: "pointer",
    textDecoration: "none",
    color: theme.palette.warning.main,
  },
  reportTosLabel: {
    color: theme.palette.primary.main,
  },
}));

const FLAG_BREACH_REPORT_APPENDIX = "BREACH_REPORT_APPENDIX";

interface Props {
  fieldLabels: FieldLabels;
  workItem: WorkItem;
  customisations: unknown[];
  onChange: (values: Record<string, unknown>) => void;
  scrollToComponent: (component: string) => void;

  // redux
  getReferenceDataDescription: (
    id: string,
    type: string,
    defaultValue: string
  ) => string;
  loggedInUser?: User;
  workItemTypes: ReferenceData[];
  localSaveDocuments: (
    workItemId: string,
    docs: Doc[],
    accessToken: string
  ) => void;
  localDeleteDocument: (
    workItemId: string,
    source: string,
    docId: string,
    accessToken: string
  ) => void;
}

const IncidentBreachReportCard: React.FC<Props> = ({
  fieldLabels,
  workItem,
  customisations = [],
  getReferenceDataDescription,
  onChange,
  loggedInUser,
  workItemTypes,
  localDeleteDocument,
  localSaveDocuments,
}: Props) => {
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();
  const [deleteFile, setDeleteFile] = useState<Doc | null>(null);
  const [uploadNamespace] = useState<string>(
    `${FLAG_BREACH_REPORT_APPENDIX}-workitem-self`
  );

  const definition = getResolvedDefinition(
    workItemTypes,
    workItem.type,
    workItem.parent?.type
  );

  const initialValues = {
    incidentBreachReport: {
      ...workItem.incidentBreachReport,
    },
  };

  const executeFileDelete = async () => {
    const accessToken = await getAccessTokenSilently();
    localDeleteDocument(
      workItem.id,
      "DEFAULT",
      deleteFile?.id as string,
      accessToken
    );
    setDeleteFile(null);
  };

  const canDeleteFiles = () => {
    const deleteRules = definition.fileDeleteRules;
    return (
      appliesByTemplate(workItem, deleteRules) &&
      appliesByRoles(loggedInUser, deleteRules)
    );
  };

  const saveDocuments = async (docs: Doc[]) => {
    const accessToken = await getAccessTokenSilently();
    localSaveDocuments(workItem.id, docs, accessToken);
  };

  const handleFileClick = async (document: Doc) => {
    const accessToken = await getAccessTokenSilently();
    onFileClick("DEFAULT", document, workItem.id, accessToken);
  };

  return (
    <>
      <AlertDialog
        title="Delete file?"
        body={`You are about to permanently delete file ${
          deleteFile && deleteFile.fileName
        }`}
        submitButtonText="Delete"
        open={!!deleteFile}
        onCancel={() => setDeleteFile(null)}
        onSubmit={() => executeFileDelete()}
        data-cy="deleteFile"
      />
      <FormCard
        className={classes.root}
        title="Breach report"
        formTitle="Edit breach report"
        formComponent={EditWorkItemComponentForm}
        formName={EDIT_WORK_ITEM_COMPONENT_FORM_NAME}
        onSave={onChange}
        initialValues={initialValues}
        formProps={{
          maxWidth: "md",
          workItem,
          componentName: "INCIDENT_BREACH_REPORT",
        }}
        readOnlyView={() => (
          <>
            <ComponentScorecard
              fieldLabels={fieldLabels}
              name="incidentBreachReport.reportableDate"
              customisations={customisations}
              entity={workItem}
              render={(value) => <FormattedDate value={value} />}
            />
            <ComponentScorecard
              fieldLabels={fieldLabels}
              name="incidentBreachReport.natureOfBreach"
              customisations={customisations}
              entity={workItem}
              render={(value) =>
                getReferenceDataDescription(value, "ReportableSituation", "-")
              }
            />
            {(workItem?.incidentBreachReport?.natureOfBreach ===
              "SIGNIFICANT" ||
              workItem?.incidentBreachReport?.natureOfBreach ===
                "LIKELY_SIGNIFICANT") && (
              <ComponentScorecard
                fieldLabels={fieldLabels}
                name="incidentBreachReport.reasonBreachIsSignificant"
                customisations={customisations}
                entity={workItem}
                render={(value) => <div>{value || "-"}</div>}
              />
            )}
            <ComponentScorecard
              fieldLabels={fieldLabels}
              name="incidentBreachReport.description"
              customisations={customisations}
              entity={workItem}
              render={(value) => <div>{value || "-"}</div>}
            />
            <ComponentScorecard
              fieldLabels={fieldLabels}
              name="incidentBreachReport.numberOfInstances"
              customisations={customisations}
              entity={workItem}
              render={(value) => <div>{value || "-"}</div>}
            />
            <ComponentScorecard
              fieldLabels={fieldLabels}
              name="incidentBreachReport.howBreachWasIdentified"
              customisations={customisations}
              entity={workItem}
              render={(value) => <div>{value || "-"}</div>}
            />
            <ComponentScorecard
              fieldLabels={fieldLabels}
              name="incidentBreachReport.duration"
              customisations={customisations}
              entity={workItem}
              render={(value) => <div>{value || "-"}</div>}
            />

            <CardSection title="Information about representatives">
              <ComponentScorecard
                fieldLabels={fieldLabels}
                name="incidentBreachReport.representativeInvolved"
                customisations={customisations}
                entity={workItem}
                render={(value) => formatters.displayBoolean(value)}
              />
              {workItem?.incidentBreachReport?.representativeInvolved && (
                <div>
                  <ComponentScorecard
                    fieldLabels={fieldLabels}
                    name="incidentBreachReport.representativeName"
                    customisations={customisations}
                    entity={workItem}
                    render={(value) => <div>{value || "-"}</div>}
                  />
                  <ComponentScorecard
                    fieldLabels={fieldLabels}
                    name="incidentBreachReport.representativeNumber"
                    customisations={customisations}
                    entity={workItem}
                    render={(value) => <div>{value || "-"}</div>}
                  />
                  <ComponentScorecard
                    fieldLabels={fieldLabels}
                    name="incidentBreachReport.representativeAuthorisationRevokedOrSuspended"
                    customisations={customisations}
                    entity={workItem}
                    render={(value) => formatters.displayBoolean(value)}
                  />
                  <ComponentScorecard
                    fieldLabels={fieldLabels}
                    name="incidentBreachReport.representativeMonitoredOrSupervised"
                    customisations={customisations}
                    entity={workItem}
                    render={(value) => formatters.displayBoolean(value)}
                  />
                </div>
              )}
            </CardSection>
            <ComponentScorecard
              fieldLabels={fieldLabels}
              labelClass={classes.reportTosLabel}
              name="incidentBreachReport.reportTos"
              customisations={customisations}
              entity={workItem}
              render={(reportTos) => (
                <List>
                  {reportTos.map(
                    (reportTo: IncidentReportTo, index: number) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <ListItem dense key={index}>
                        <ListItemText
                          primary={reportTo.reportTo}
                          secondary={`Due: ${
                            reportTo.dueDate || "-"
                          }, Submitted: ${
                            reportTo.reportedDate || "-"
                          }, Confirmed: ${reportTo.confirmationDate || "-"}`}
                        />
                      </ListItem>
                    )
                  )}
                </List>
              )}
            />

            <CardSection title="Appendix file">
              <FlaggedFileUpload
                flag={FLAG_BREACH_REPORT_APPENDIX}
                documents={workItem.documents}
                addDocuments={saveDocuments}
                deleteDocument={
                  canDeleteFiles() && !!onChange
                    ? (doc) => setDeleteFile(doc)
                    : null
                }
                onDocumentClick={handleFileClick}
                uploadNamespace={uploadNamespace}
              />
              <FormHelperText>
                You must provide details of any remediation program (including
                preventative measures) that has been or is being developed to
                compensate clients who have suffered a loss. Include relevant
                dates or expected dates for the start and conclusion of the
                remediation program. You should also provide information about
                completion of remediation.
              </FormHelperText>
            </CardSection>
          </>
        )}
      />
    </>
  );
};
const mapStateToProps = (state: Record<string, unknown>) => {
  return {
    getReferenceDataDescription: (
      id: string,
      type: string,
      defaultValue: string
    ) => getReferenceDataDescription(state, type, id, defaultValue || "-"),
    loggedInUser: getLoggedInUser(state),
    workItemTypes: getReferenceDataType(state, "WorkItemType"),
  };
};

export default connect(mapStateToProps, {
  localSaveDocuments: saveDocuments,
  localDeleteDocument: deleteDocument,
})(IncidentBreachReportCard);
