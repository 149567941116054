import { normalize } from "normalizr";
import schemas from "../schemas";
import usersApi from "../services/api/users";
import { asyncAction } from "./actions";

export const USERS_CLEAR_ACTIVE = "USERS_CLEAR_ACTIVE";
export const USERS_SEARCH = "USERS_SEARCH";
export const USERS_PATCH = "PATCH_USERS";

export const searchUsers = (searchParameters, accessToken) => {
  const abortController = window.AbortController ? new AbortController() : null;
  return asyncAction(
    USERS_SEARCH,
    usersApi.search(searchParameters, abortController, accessToken),
    {
      responseTransformer: (response) => ({
        pagination: {
          offset: response.offset,
          previousOffset: response.previousOffset,
          nextOffset: response.nextOffset,
          pageSize: response.pageSize,
          resultCount: response.resultCount,
        },
        ...normalize(response.results, schemas.arrayOfUsers),
      }),
      actionParams: {
        abortController,
        nonBlocking: true,
      },
    }
  );
};

export const patchUser = (userId, changes, description, accessToken) =>
  asyncAction(
    USERS_PATCH,
    usersApi.patch(userId, changes, description, accessToken),
    {
      responseTransformer: (user) => normalize(user, schemas.arrayOfUsers),
    }
  );
