class NetworkError extends Error {
  constructor(message) {
    super(message);
    this.name = "NetworkError";
    if (typeof Error.captureStackTrace === "function") {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
  }
}

export default NetworkError;
