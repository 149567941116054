import { User, UserFlatDto } from "../../../model/User";

const externalUserCanRemoveUser = (
  loggedInUser: User,
  userToRemove: UserFlatDto
): boolean => {
  if (
    loggedInUser?.organisations?.length <= 0 ||
    userToRemove?.organisations?.length <= 0
  ) {
    return false;
  }

  return loggedInUser.organisations?.some((org) => {
    if (userToRemove.organisations?.length === 0) {
      return false;
    }

    const matchedOrgs = userToRemove.organisations?.filter(
      (o) => o.friendlyId === org.friendlyId
    );
    return matchedOrgs?.length > 0;
  });
};

export default externalUserCanRemoveUser;
