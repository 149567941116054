import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import classNames from "classnames";
import { clearActiveWorkItem, loadWorkItems } from "../../actions/workItems";
import {
  getActiveRisk,
  getReferenceDataType,
  getWorkItems,
  isLoading,
} from "../../reducers";
import WorkItemListItem from "../workitem/WorkItemListItem";
import OpenCloseWorkItemFilter from "../workitem/OpenCloseWorkItemFilter";
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  overdue: {
    color: theme.due.overdue,
  },
}));

const MeasureUpdatesCard = ({
  className,
  loading,
  measureUpdates,
  workItemTypes,
  risk,
  localClearActiveWorkItem,
  localLoadWorkItems,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();

  const [filteredMeasureUpdates, setFilteredMeasureUpdates] = useState([]);

  const measureUpdatesForRiskSearchParameters = () => ({
    riskId: risk.id,
    types: ["RISK_MEASURE_UPDATE"],
    includeTemplates: false,
    includeChildren: false,
    limit: 100,
    orderByField: "-created",
  });

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      localClearActiveWorkItem();
      localLoadWorkItems(measureUpdatesForRiskSearchParameters(), accessToken);
    })();
  }, [getAccessTokenSilently]);

  const mergedClassName = classNames(classes.root, className);

  return (
    <Card className={mergedClassName} elevation={0}>
      <CardHeader title="Measure updates" data-cy="measureUpdates" />
      <OpenCloseWorkItemFilter
        workItems={measureUpdates}
        filteredWorkItemsChange={(filtered) =>
          setFilteredMeasureUpdates(filtered)
        }
      />
      {filteredMeasureUpdates.length > 0 && (
        <List dense>
          {filteredMeasureUpdates.map((measureUpdate) => (
            <WorkItemListItem
              key={measureUpdate.id}
              workItem={measureUpdate}
              types={workItemTypes}
              linkTo={`/risk/risks/${risk.id}/measure-updates/${measureUpdate.id}`}
              data-cy={measureUpdate}
            />
          ))}
        </List>
      )}
      {filteredMeasureUpdates.length === 0 && !loading && (
        <CardContent>
          <em data-cy="noMeasure">
            There are no measure updates associated with this risk
          </em>
        </CardContent>
      )}
    </Card>
  );
};

MeasureUpdatesCard.propTypes = {
  loading: PropTypes.bool.isRequired,
  risk: PropTypes.object.isRequired,
  measureUpdates: PropTypes.array.isRequired,
  workItemTypes: PropTypes.object.isRequired,
  localLoadWorkItems: PropTypes.func.isRequired,
  localClearActiveWorkItem: PropTypes.func.isRequired,
  className: PropTypes.string,
};

MeasureUpdatesCard.defaultProps = {
  className: undefined,
};

const mapStateToProps = (state) => ({
  loading: isLoading(state),
  risk: getActiveRisk(state),
  measureUpdates: getWorkItems(state),
  workItemTypes: getReferenceDataType(state, "WorkItemType"),
});

export default connect(mapStateToProps, {
  localLoadWorkItems: loadWorkItems,
  localClearActiveWorkItem: clearActiveWorkItem,
})(MeasureUpdatesCard);
