import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import _ from "lodash";
import PropTypes from "prop-types";
import CollapseExpand from "../../../CollapseExpand";
import RichTextMentionsView from "../../../richtext/RichTextMentionsView";

const styles = (theme) => ({
  box: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  mentions: {
    color: theme.palette.swatch.secondary,
  },
  text: {
    color: theme.palette.swatch.primary,
    whiteSpace: "pre-wrap",
  },
});

const isCorrespondence = (entry) =>
  _.has(entry, "comment") && _.get(entry.comment, "type") === "CORRESPONDENCE";

const useCorrespondence = (entry) => {
  const [hasCorrespondence, setHasCorrespondence] = useState(false);
  const [correspondence, setCorrespondence] = useState(null);

  useEffect(() => {
    if (isCorrespondence(entry)) {
      setHasCorrespondence(true);
      setCorrespondence(entry.comment.draft);
    }
  }, [entry]);

  return [hasCorrespondence, correspondence];
};

const Correspondence = ({ classes, entry }) => {
  const [hasCorrespondence, correspondence] = useCorrespondence(entry);

  return (
    <>
      {hasCorrespondence && (
        <div className={classes.box}>
          <Typography
            component="div"
            variant="body2"
            className={classes.text}
            data-cy="text"
          >
            <CollapseExpand isNewUI>
              <RichTextMentionsView
                value={correspondence}
                data-cy={correspondence}
              />
            </CollapseExpand>
          </Typography>
        </div>
      )}
    </>
  );
};

Correspondence.propTypes = {
  classes: PropTypes.object.isRequired,
  entry: PropTypes.object.isRequired,
};

export default withStyles(styles)(Correspondence);
