import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import _ from "lodash";
import CardContent from "@material-ui/core/CardContent";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { loadRisks } from "../../../../actions/risks";
import { getLabels, getRisks } from "../../../../reducers";
import CardSection from "../../../common/CardSection";
import RiskDetailsFieldsReadOnly from "../../../risk/RiskDetailsFieldsReadOnly";
import { useAuth0 } from "@auth0/auth0-react";
import RiskOverviewFieldsReadOnly from "../../../risk/RiskOverviewFieldsReadOnly";
import { includeTenantParam } from "@certane/arcadia-web-components";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
});

const RiskSummaryCard = ({
  classes,
  className,
  workItem,
  risks,
  fieldLabels,
  localLoadRisks,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      // We have the risk refs, but may not have access to the full risks,
      // in which case we'll just try to load them all and those which
      // we can access will be returned
      if (workItem.associatedRisks.length > 0) {
        const filter = {
          riskIds: workItem.associatedRisks.map((ref) => ref.id),
          orderByField: "friendlyId",
          limit: workItem.associatedRisks.length,
          offset: 0,
        };
        localLoadRisks(filter, accessToken);
      }
    })();
  }, [getAccessTokenSilently, workItem]);

  const getRiskSummary = (riskRef) => {
    const risk = risks.find((r) => r.id === riskRef.id);
    return risk ? (
      <>
        <RiskOverviewFieldsReadOnly risk={risk} fieldLabels={fieldLabels} />
        <RiskDetailsFieldsReadOnly risk={risk} fieldLabels={fieldLabels} />
      </>
    ) : (
      "Risk details are not available"
    );
  };

  const getRiskSummaryAction = (riskRef) => {
    return riskRef.accessible ? (
      <Tooltip
        title={`View Risk ${riskRef.friendlyId} in new window`}
        disableFocusListener
      >
        <IconButton
          onClick={() =>
            window.open(
              includeTenantParam(`/risk/risks/${riskRef.id}`),
              "_blank"
            )
          }
        >
          <Icon>launch</Icon>
        </IconButton>
      </Tooltip>
    ) : null;
  };

  return (
    <Card
      className={classNames({
        [classes.root]: true,
        [className]: !!className,
      })}
      elevation={0}
    >
      <CardHeader title="Risk summary" />
      <CardContent>
        {_.orderBy(workItem.associatedRisks, "friendlyId").map((riskRef) => (
          <CardSection
            key={riskRef.id}
            title={`Risk ${riskRef.friendlyId}`}
            gutterBottom
            action={getRiskSummaryAction(riskRef)}
          >
            {getRiskSummary(riskRef)}
          </CardSection>
        ))}
      </CardContent>
    </Card>
  );
};

RiskSummaryCard.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  workItem: PropTypes.object.isRequired,
  risks: PropTypes.array.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  localLoadRisks: PropTypes.func.isRequired,
};

RiskSummaryCard.defaultProps = {
  className: null,
};

const mapStateToProps = (state) => ({
  risks: getRisks(state),
  fieldLabels: getLabels(state).Risk,
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    localLoadRisks: loadRisks,
  })
)(RiskSummaryCard);
