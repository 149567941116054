import { useAuth0 } from "@auth0/auth0-react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Fade from "@material-ui/core/Fade";
import IconButton from "@material-ui/core/IconButton";
import ListSubheader from "@material-ui/core/ListSubheader";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  addQuestion,
  patchQuestion,
  performActionOnWorkItem,
  removeQuestion,
} from "../../actions/workItems";
import { getLoggedInUser, getReferenceDataType } from "../../reducers";
import api from "../../services/api";
import usersApi from "../../services/api/users";
import workItemsApi from "../../services/api/workItems";
import { roles } from "@certane/arcadia-web-components";
import logger from "../../util/logger";
import QuestionHandler from "../../util/questions";
import { getResolvedDefinition } from "../../util/workItemTypeUtils";
import { getFirstWorkItemUserByType } from "../../util/workItemUserUtils";
import CardSection from "../common/CardSection";
import ExpandableForm from "../forms/ExpandableForm";
import QuestionForm, {
  QUESTION_FORM_NAME,
} from "../workitem/components/questions/QuestionForm";
import QuestionReadOnly from "../workitem/components/questions/QuestionReadOnly";

const onFileClick = (source, document, workItemId, accessToken) => {
  api.workItems
    .documentDownloadUrl(workItemId, source, document.id, false, accessToken)
    .then((location) => window.open(location, "_blank"));
};

const QuestionsCard = ({
  className,
  fieldLabels,
  workItem,
  readonly,
  localAddQuestion,
  localPatchQuestion,
  localRemoveQuestion,
  localPerformActionOnWorkItem,
  customisations,
  workItemTypes,
  loggedInUser,
  scrollToQuestionId,
  onScrolledToQuestion,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const questionElements = useRef({});
  const [questions, setQuestions] = useState([]);
  const [myQuestions, setMyQuestions] = useState([]);
  const [otherQuestions, setOtherQuestions] = useState([]);
  const [expansionData, setExpansionData] = useState({});
  const [formOpen, setFormOpen] = useState(false);
  const [showOtherQuestions, setShowOtherQuestions] = useState(false);
  const [allowEditingQuestions, setAllowEditingQuestions] = useState(false);
  const definition = getResolvedDefinition(
    workItemTypes,
    workItem.type,
    workItem.parent?.type
  );

  const { submittableBy, statuses } = definition;

  const questionHandler = new QuestionHandler(loggedInUser);

  useEffect(() => {
    if (scrollToQuestionId) {
      const isOtherQuestion =
        otherQuestions.filter((q) => q.id === scrollToQuestionId).length > 0;

      // If it's an 'other' question it may be not visible at the moment
      if (isOtherQuestion) {
        setShowOtherQuestions(true);
      }

      setTimeout(() => {
        const element = questionElements.current[scrollToQuestionId];
        if (element) {
          logger.info("found element for question", scrollToQuestionId);

          setTimeout(() => {
            element.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "center",
            });
            setExpansionData({ ...expansionData, [scrollToQuestionId]: true });
          }, 200);

          if (onScrolledToQuestion) {
            onScrolledToQuestion();
          }
        } else {
          logger.info(
            "cannot find element for question id",
            scrollToQuestionId,
            "maybe it has not been fetched yet"
          );
        }
      }, 0);
    }
  }, [workItem, scrollToQuestionId, questions, otherQuestions]);

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      workItemsApi
        .listQuestions(workItem.id, accessToken)
        .then((workItemQuestions) => {
          setQuestions(workItemQuestions);
          setMyQuestions(questionHandler.getMyQuestions(workItemQuestions));
          setOtherQuestions(
            questionHandler.getOtherQuestions(workItemQuestions)
          );
        });
    })();
    const workItemOwner = getFirstWorkItemUserByType(workItem.users, "OWNER");
    const hasEditingRoles = loggedInUser
      ? _.intersection(
          [...roles.ADMIN_ROLES, roles.COMPLIANCE_OWNER],
          loggedInUser.roles
        ).length > 0
      : false;
    const isOwner =
      loggedInUser &&
      workItemOwner.user &&
      loggedInUser.id === workItemOwner.user.id;
    setAllowEditingQuestions(isOwner || hasEditingRoles);
  }, [workItem]);

  const createQuestion = async (values) => {
    const accessToken = await getAccessTokenSilently();
    localAddQuestion(workItem.id, values, accessToken).then(() =>
      setFormOpen(false)
    );
  };

  const asyncSubmitterOptionsFetch = async (
    pickerFilter,
    pagination,
    abortController
  ) => {
    const searchParameters = {
      ...pickerFilter,
      limit: pagination.pageSize,
      offset: pagination.offset,
      roles: submittableBy,
    };
    const accessToken = await getAccessTokenSilently();
    return usersApi.search(searchParameters, abortController, accessToken);
  };

  const handleUpdateQuestion = async (question, values) => {
    const accessToken = await getAccessTokenSilently();
    localPatchQuestion(
      workItem.id,
      question.id,
      values,
      "Updated question",
      accessToken
    );
  };

  const handleRemoveQuestion = async (question) => {
    const accessToken = await getAccessTokenSilently();
    localRemoveQuestion(workItem.id, question.id, accessToken);
  };

  const handleResponse = async (question, values) => {
    const accessToken = await getAccessTokenSilently();
    localPerformActionOnWorkItem(
      workItem.id,
      {
        action: "RESPOND_TO_QUESTION",
        questionId: question.id,
        draft: values.justificationNotes,
        compliant: values.compliant,
        attachments: values.attachments,
      },
      accessToken
    );
  };

  const handleResponseRating = async (question, values) => {
    const accessToken = await getAccessTokenSilently();
    localPerformActionOnWorkItem(
      workItem.id,
      {
        action: "RATE_QUESTION_RESPONSE",
        questionId: question.id,
        ratingNotes: values.ratingNotes,
        ...values,
      },
      accessToken
    );
  };

  const workItemStatusAssignment = statuses.find(
    (statusAssignment) => statusAssignment.status === workItem.status
  );
  const allowResponses =
    workItemStatusAssignment && !workItemStatusAssignment.endState;

  const handleFileClick = async (source, document) => {
    const accessToken = await getAccessTokenSilently();
    onFileClick(source, document, workItem.id, accessToken);
  };

  const renderQuestion = (question) => (
    <Fragment key={question.id}>
      <div
        ref={(ref) => {
          questionElements.current[question.id] = ref;
        }}
      />
      <QuestionReadOnly
        fieldLabels={fieldLabels}
        question={question}
        readonly={readonly || !allowEditingQuestions}
        allowRating={allowEditingQuestions}
        allowResponses={allowResponses}
        onUpdate={(values) => handleUpdateQuestion(question, values)}
        onResponse={(values) => handleResponse(question, values)}
        onResponseRating={(values) => handleResponseRating(question, values)}
        onDelete={() => handleRemoveQuestion(question)}
        onFileClick={(document) =>
          handleFileClick("QUESTION_RESPONSE", document)
        }
        datasource={asyncSubmitterOptionsFetch}
        loggedInUser={loggedInUser}
        expanded={!!expansionData[question.id]}
        setExpanded={(expanded) =>
          setExpansionData({ ...expansionData, [question.id]: expanded })
        }
      />
    </Fragment>
  );

  const numAnsweredMyQuestions = myQuestions.filter((question) =>
    question.responses.some(
      (response) => loggedInUser && response.user.id === loggedInUser.id
    )
  ).length;

  const getShowHideAction = () => (
    <Tooltip
      title={`${
        showOtherQuestions ? "Hide other questions" : "Show other questions"
      }`}
      disableFocusListener
    >
      <Button
        variant="text"
        size="small"
        onClick={() => setShowOtherQuestions(!showOtherQuestions)}
      >
        {showOtherQuestions ? "Hide..." : "Show..."}
      </Button>
    </Tooltip>
  );

  return (
    <Card className={className} elevation={0}>
      <CardHeader
        title={fieldLabels.labels.questions}
        action={
          !readonly &&
          allowEditingQuestions && (
            <Tooltip title="Add question" disableFocusListener>
              <div>
                <IconButton
                  onClick={() => setFormOpen(true)}
                  disabled={formOpen}
                >
                  <AddIcon />
                </IconButton>
              </div>
            </Tooltip>
          )
        }
      />
      <ExpandableForm
        title="New question"
        submitButtonText="Save"
        maxWidth="md"
        formComponent={QuestionForm}
        formName={QUESTION_FORM_NAME}
        open={formOpen}
        onCancel={() => setFormOpen(false)}
        onSubmit={createQuestion}
        customisations={customisations}
        workItemDefinition={definition}
      />
      <CardContent>
        <>
          {questions.length === 0 && (
            <ListSubheader disableSticky>No questions</ListSubheader>
          )}
          {myQuestions.length > 0 && (
            <CardSection
              title={`My questions (${numAnsweredMyQuestions}/${myQuestions.length} answered)`}
              gutterBottom
            >
              {myQuestions.map((question) => renderQuestion(question))}
            </CardSection>
          )}
          {otherQuestions.length > 0 && (
            <CardSection title="Other questions" action={getShowHideAction()}>
              {showOtherQuestions && (
                <Fade in timeout={1000}>
                  <>
                    {otherQuestions.map((question) => renderQuestion(question))}
                  </>
                </Fade>
              )}
            </CardSection>
          )}
        </>
      </CardContent>
    </Card>
  );
};

QuestionsCard.propTypes = {
  className: PropTypes.string,
  fieldLabels: PropTypes.object.isRequired,
  workItem: PropTypes.object.isRequired,
  localAddQuestion: PropTypes.func.isRequired,
  localPatchQuestion: PropTypes.func.isRequired,
  localRemoveQuestion: PropTypes.func.isRequired,
  localPerformActionOnWorkItem: PropTypes.func.isRequired,
  readonly: PropTypes.bool.isRequired,
  customisations: PropTypes.array,
  workItemTypes: PropTypes.object.isRequired,
  loggedInUser: PropTypes.object,
  scrollToQuestionId: PropTypes.string,
  onScrolledToQuestion: PropTypes.func,
};

QuestionsCard.defaultProps = {
  className: undefined,
  customisations: [],
  loggedInUser: null,
  scrollToQuestionId: undefined,
  onScrolledToQuestion: undefined,
};

const mapStateToProps = (state) => ({
  workItemTypes: getReferenceDataType(state, "WorkItemType"),
  loggedInUser: getLoggedInUser(state),
});

export default connect(mapStateToProps, {
  localAddQuestion: addQuestion,
  localPatchQuestion: patchQuestion,
  localRemoveQuestion: removeQuestion,
  localPerformActionOnWorkItem: performActionOnWorkItem,
})(QuestionsCard);
