import moment from "moment";
import _ from "lodash";
import dates from "./dates";

function sortByDateDesc(array, field) {
  return array.sort((a, b) =>
    moment(a[field]).isBefore(moment(b[field])) ? 1 : -1
  );
}

export function sortWorkItemFields(workItem) {
  return {
    ...workItem,
    documents: workItem.documents
      ? sortByDateDesc(workItem.documents, "uploaded")
      : [],
    endorsements: workItem.endorsements
      ? sortByDateDesc(workItem.endorsements, "createdDate")
      : [],
  };
}

export function sortWorkItems(
  workItems,
  orderBy,
  types,
  categories,
  statuses,
  priorities
) {
  let valueFunc = null;
  switch (orderBy.field) {
    case "status":
      valueFunc = (workItem) =>
        statuses.values[workItem[orderBy.field]].ordinal;
      break;
    case "priority":
      valueFunc = (workItem) =>
        priorities.values[workItem[orderBy.field]].ordinal;
      break;
    case "type":
      valueFunc = (workItem) =>
        types.values[workItem[orderBy.field]].description;
      break;
    case "category":
      valueFunc = (workItem) =>
        categories.values[workItem[orderBy.field]].description;
      break;
    case "updated":
    case "created":
      valueFunc = (workItem) => dates.parseTimestamp(workItem[orderBy.field]);
      break;
    case "dueDate":
      valueFunc = (workItem) => dates.parseDate(workItem[orderBy.field]);
      break;
    case "owner":
      valueFunc = (workItem) =>
        workItem[orderBy.field] ? workItem[orderBy.field].name : "";
      break;
    default:
      valueFunc = (workItem) =>
        workItem[orderBy.field] && workItem[orderBy.field].toLowerCase
          ? workItem[orderBy.field].toLowerCase()
          : workItem[orderBy.field];
      break;
  }

  return _.orderBy(workItems, valueFunc, orderBy.direction);
}
