import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router";
import Error404 from "../../components/error/Error404";
import { workItemsBase } from "../../routes/routeUtils";
import workItems from "../../services/api/workItems";

const WorkItemFriendlyId = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { workItemFriendlyId } = useParams();
  const [redirectTo, setRedirectTo] = useState(null);
  const [error, setError] = useState(null);

  const linkBase = workItemsBase();

  useEffect(() => {
    if (!workItemFriendlyId) {
      return;
    }

    (async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const workItem = await workItems.getByFriendlyId(
          workItemFriendlyId,
          accessToken
        );
        setRedirectTo(`${linkBase}/${workItem.id}`);
      } catch (e) {
        console.error(JSON.stringify(e));
        setError(e);
      }
    })();
  }, [workItemFriendlyId]);

  if (error && error.status === 404) {
    return <Error404 />;
  }

  return redirectTo ? <Redirect to={redirectTo} /> : <div>Loading...</div>;
};

WorkItemFriendlyId.propTypes = {};

export default WorkItemFriendlyId;
