import { requestJSON } from "./http";

const create = (subscription, accessToken) =>
  requestJSON({
    path: "/subscriptions/work-item",
    method: "POST",
    data: subscription,
    accessToken,
  });

const search = (accessToken) =>
  requestJSON({ path: "/subscriptions/work-item", accessToken });

const remove = (id, accessToken) =>
  requestJSON({
    path: `/subscriptions/work-item/${id}`,
    method: "DELETE",
    accessToken,
  });

export default {
  create,
  search,
  remove,
};
