import { requestJSON, requestText } from "./http";

const getDocumentZip = (documentZipId, accessToken) =>
  requestJSON({ path: `/document-zip/${documentZipId}`, accessToken });

const getDownloadUrl = (documentZipId, asAttachment, accessToken) =>
  requestText({
    path: `/document-zip/${documentZipId}/download${
      asAttachment ? "?asAttachment=true" : ""
    }`,
    accessToken,
  });

export default {
  getDocumentZip,
  getDownloadUrl,
};
