import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { scrollToFirstInvalidInput } from "../../../util/formHelpers";
import FormError from "../../common/FormError";
import WorkItemComponentFields from "../components/WorkItemComponentFields";

const styles = {
  intro: {
    margin: "2rem 0",
  },
};

export const CHILD_WORK_ITEM_FORM_NAME = "CHILD_WORK_ITEM_FORM_NAME";

const CreateChildWorkItemForm = ({
  childTypeDefinition,
  error,
  change,
  untouch,
  parentType,
  template,
}) => (
  <form autoComplete="off">
    {error && <FormError errorMessage={error} />}
    <WorkItemComponentFields
      parentType={parentType}
      workItemDefinition={childTypeDefinition}
      change={change}
      untouch={untouch}
      formName={CHILD_WORK_ITEM_FORM_NAME}
      formType="CREATE"
      template={template}
    />
  </form>
);

CreateChildWorkItemForm.propTypes = {
  childTypeDefinition: PropTypes.object.isRequired,
  error: PropTypes.string,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  parentType: PropTypes.string.isRequired,
  template: PropTypes.bool.isRequired,
};

CreateChildWorkItemForm.defaultProps = {
  error: null,
};

const mapStateToProps = () => ({});

const form = reduxForm({
  form: CHILD_WORK_ITEM_FORM_NAME,
  onSubmitFail: (errors) => scrollToFirstInvalidInput(errors),
});
const reduxData = connect(mapStateToProps);
export default compose(
  form,
  reduxData
)(withStyles(styles)(CreateChildWorkItemForm));
