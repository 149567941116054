import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Fade from "@material-ui/core/Fade";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { SubmissionError } from "redux-form";
import AlertDialog from "../../common/AlertDialog";

const useStyles = makeStyles(() => ({
  root: {
    overflow: "visible",
  },
}));

const FormCard = ({
  onSave,
  onDelete,
  icon,
  additionalActions,
  className,
  formComponent: FormComponent,
  readOnlyView: ReadOnlyComponent,
  title,
  formTitle,
  deleteTitle,
  deleteBody,
  initialValues,
  formProps,
}) => {
  const classes = useStyles();
  const [formOpen, setFormOpen] = useState(false);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);

  const handleSubmitForm = (changes) => {
    onSave(changes)
      .then(() => {
        setFormOpen(false);
      })
      .catch((error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  const handleSubmitDeleteDialog = () => {
    onDelete().then(() => {
      setRemoveDialogOpen(false);
    });
  };

  const getAction = () => {
    const readOnly = !onSave;
    const deletable = !!onDelete;

    if (!formOpen && !readOnly) {
      return (
        <>
          {additionalActions}
          <IconButton
            onClick={() => setFormOpen(true)}
            title="Edit"
            data-cy="editIcon"
          >
            {icon}
          </IconButton>
          {deletable && (
            <IconButton
              onClick={() => setRemoveDialogOpen(true)}
              title="Delete"
              data-cy="deleteIcon"
            >
              <Icon>delete</Icon>
            </IconButton>
          )}
        </>
      );
    }
    if (!readOnly) {
      return (
        <>
          {additionalActions}
          <IconButton
            onClick={() => setFormOpen(false)}
            title="Cancel"
            data-cy="cancelIcon"
          >
            <CloseIcon />
          </IconButton>
        </>
      );
    }
    return null;
  };

  const mergedClassName = classNames(classes.root, className);

  return (
    <>
      <Card className={mergedClassName} elevation={0}>
        <CardHeader title={formOpen ? formTitle : title} action={getAction()} />
        <AlertDialog
          title={deleteTitle}
          body={deleteBody}
          submitButtonText="Delete"
          open={removeDialogOpen}
          onCancel={() => setRemoveDialogOpen(false)}
          onSubmit={() => handleSubmitDeleteDialog()}
        />
        {formOpen && (
          <Fade in timeout={1000}>
            <CardContent>
              <FormComponent
                initialValues={initialValues}
                onSubmit={handleSubmitForm}
                onCancel={() => setFormOpen(false)}
                {...formProps}
              />
            </CardContent>
          </Fade>
        )}
        {!formOpen && (
          <CardContent>
            <ReadOnlyComponent />
          </CardContent>
        )}
      </Card>
    </>
  );
};

FormCard.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  title: PropTypes.string.isRequired,
  formTitle: PropTypes.string.isRequired,
  deleteTitle: PropTypes.string,
  deleteBody: PropTypes.string,
  readOnlyView: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
    .isRequired,
  formComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
    .isRequired,
  initialValues: PropTypes.object,
  formProps: PropTypes.object,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  additionalActions: PropTypes.object,
};

FormCard.defaultProps = {
  className: undefined,
  icon: <EditIcon />,
  deleteTitle: "",
  deleteBody: "",
  onSave: null,
  onDelete: null,
  initialValues: {},
  formProps: {},
  additionalActions: null,
};

export default FormCard;
