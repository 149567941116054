import { useAuth0 } from "@auth0/auth0-react";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import { email, required } from "redux-form-validators";
import { getReferenceDataType } from "../../../reducers";
import organisationsApi from "../../../services/api/organisations";
import { scrollToFirstInvalidInput } from "../../../util/formHelpers";
import { organisationIcon } from "../../../util/icons";
import Avatar from "../../common/Avatar.tsx";
import WrappedCheckbox from "../wrapper/WrappedCheckbox";
import WrappedChipListPicker from "../wrapper/WrappedChipListPicker";
import WrappedSelectChips from "../wrapper/WrappedSelectChips";
import WrappedTextField from "../wrapper/WrappedTextField";

const UserForm = ({ roles }) => {
  const { getAccessTokenSilently } = useAuth0();

  const asyncOrganisationOptionsFetch = async (
    pickerFilter,
    pagination,
    abortController
  ) => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = {
      ...pickerFilter,
      limit: pagination.pageSize,
      offset: pagination.offset,
      orderByField: "name",
    };
    return organisationsApi.search(
      searchParameters,
      abortController,
      accessToken
    );
  };

  return (
    <form autoComplete="off">
      <Field
        name="name"
        component={WrappedTextField}
        label="Name"
        validate={required({ msg: "Name is required" })}
        fullWidth
        required
        data-cy="name"
      />
      <Field
        name="email"
        component={WrappedTextField}
        label="Email"
        validate={[
          required({ msg: "Email is required" }),
          email({ msg: "Email is invalid" }),
        ]}
        fullWidth
        required
        data-cy="email"
      />
      <Field
        name="roles"
        component={WrappedSelectChips}
        options={roles.ids}
        isMulti
        label="Roles"
        fullWidth
        toOption={(role) => ({
          label: roles.values[role].description,
          value: role,
        })}
        fromOption={(option) => option.value}
        data-cy="roles"
      />
      <Field
        data-cy="organisations"
        name="organisations"
        component={WrappedChipListPicker}
        datasource={asyncOrganisationOptionsFetch}
        label="Organisations"
        fullWidth
        clearable
        toOption={(org) => ({
          label: org.name,
          id: org.id,
          org,
        })}
        fromOption={(option) => option.org}
        addIcon={organisationIcon()}
        renderIcon={(org, size) => <Avatar name={org.name} size={size} round />}
        disabled
      />
      <Field
        name="enabled"
        component={WrappedCheckbox}
        label="Enabled"
        data-cy="enabled"
      />
    </form>
  );
};

UserForm.propTypes = {
  roles: PropTypes.object.isRequired,
  selectedRoles: PropTypes.array,
};

UserForm.defaultProps = {
  selectedRoles: [],
};

export const USER_FORM_NAME = "USER_FORM_NAME";

const mapStateToProps = (state) => ({
  roles: getReferenceDataType(state, "Role"),
});

const form = reduxForm({
  form: USER_FORM_NAME,
  onSubmitFail: (errors) => scrollToFirstInvalidInput(errors),
});

const reduxData = connect(mapStateToProps);
export default compose(form, reduxData)(UserForm);
