import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import _ from "lodash";
import PropTypes from "prop-types";
import CollapseExpand from "../../../CollapseExpand";
import RichTextMentionsView from "../../../richtext/RichTextMentionsView";

const styles = (theme) => ({
  box: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  mentions: {
    color: theme.palette.swatch.secondary,
  },
  text: {
    color: theme.palette.swatch.primary,
    whiteSpace: "pre-wrap",
  },
});

const isGenericComment = (entry) =>
  _.has(entry, "comment") && _.get(entry.comment, "type") === "GENERIC";

const useComment = (entry) => {
  const [hasComment, setHasComment] = useState(false);
  const [comment, setComment] = useState(null);

  useEffect(() => {
    if (isGenericComment(entry)) {
      setHasComment(true);
      setComment(entry.comment.draft);
    }
  }, [entry]);

  return [hasComment, comment];
};

const GenericComment = ({ classes, entry }) => {
  const [hasComment, comment] = useComment(entry);

  return (
    <>
      {hasComment && (
        <div className={classes.box}>
          <Typography
            component="div"
            variant="body2"
            className={classes.text}
            data-cy="text"
          >
            <CollapseExpand isNewUI>
              <RichTextMentionsView value={comment} data-cy={comment} />
            </CollapseExpand>
          </Typography>
        </div>
      )}
    </>
  );
};

GenericComment.propTypes = {
  classes: PropTypes.object.isRequired,
  entry: PropTypes.object.isRequired,
};

export default withStyles(styles)(GenericComment);
