import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { getReferenceDataDescription } from "../../../../reducers";
import FormCard from "../../../common/FormCard";
import EditWorkItemComponentForm, {
  EDIT_WORK_ITEM_COMPONENT_FORM_NAME,
} from "../EditWorkItemComponentForm";
import ComponentScorecard from "../../../common/ComponentScorecard";

const ClientCard = ({
  className,
  workItem,
  customisations,
  fieldLabels,
  onChange,
  getRefDataDescription,
}) => {
  const showGenderAndAge = workItem.clientDetails?.type === "INDIVIDUAL_COUPLE";
  return (
    <>
      <FormCard
        className={className}
        title={fieldLabels.labels.clientDetails}
        formTitle={`Edit ${fieldLabels.labels.clientDetails}`}
        readOnlyView={() => (
          <>
            <ComponentScorecard
              fieldLabels={fieldLabels}
              name="clientDetails.name"
              customisations={customisations}
              entity={workItem}
              render={(value) => value}
              defaultDisplay="-"
            />
            <ComponentScorecard
              fieldLabels={fieldLabels}
              name="clientDetails.identificationNumber"
              customisations={customisations}
              entity={workItem}
              render={(value) => value}
              defaultDisplay="-"
            />
            <ComponentScorecard
              fieldLabels={fieldLabels}
              name="clientDetails.type"
              customisations={customisations}
              entity={workItem}
              render={(value) => getRefDataDescription(value, "ClientType")}
              defaultDisplay="-"
            />
            {showGenderAndAge && (
              <ComponentScorecard
                fieldLabels={fieldLabels}
                name="clientDetails.gender"
                customisations={customisations}
                entity={workItem}
                render={(value) => getRefDataDescription(value, "ClientGender")}
                defaultDisplay="-"
              />
            )}
            {showGenderAndAge && (
              <ComponentScorecard
                fieldLabels={fieldLabels}
                name="clientDetails.age"
                customisations={customisations}
                entity={workItem}
                render={(value) => getRefDataDescription(value, "ClientAge")}
                defaultDisplay="-"
              />
            )}
            <ComponentScorecard
              fieldLabels={fieldLabels}
              name="clientDetails.postcode"
              customisations={customisations}
              entity={workItem}
              render={(value) => value}
              defaultDisplay="-"
            />
          </>
        )}
        formComponent={EditWorkItemComponentForm}
        formName={EDIT_WORK_ITEM_COMPONENT_FORM_NAME}
        onSave={onChange}
        initialValues={{
          clientDetails: workItem.clientDetails,
        }}
        formProps={{
          maxWidth: "md",
          workItem,
          componentName: "CLIENT",
        }}
      />
    </>
  );
};

ClientCard.propTypes = {
  className: PropTypes.string,
  fieldLabels: PropTypes.object.isRequired,
  workItem: PropTypes.object.isRequired,
  customisations: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  getRefDataDescription: PropTypes.func.isRequired,
};

ClientCard.defaultProps = {
  className: undefined,
  onChange: null,
};

const mapStateToProps = (state) => ({
  getRefDataDescription: (id, type) =>
    getReferenceDataDescription(state, type, id, "-"),
});

export default connect(mapStateToProps)(ClientCard);
