import InputAdornment from "@material-ui/core/InputAdornment";
import PropTypes from "prop-types";
import React from "react";
import { numericality, required } from "redux-form-validators";
import ComponentField from "../../../forms/wrapper/ComponentField";
import WrappedDatePicker from "../../../forms/wrapper/WrappedDatePicker";
import WrappedTextField from "../../../forms/wrapper/WrappedTextField";

const MemberDetailsFields = ({
  fieldLabels,
  customisations,
  change,
  untouch,
  formName,
}) => (
  <>
    <ComponentField
      name="memberDetails.memberName"
      component={WrappedTextField}
      label={fieldLabels.nestedTypes.memberDetails.labels.memberName}
      fullWidth
      required
      validate={required({
        msg: `${fieldLabels.nestedTypes.memberDetails.labels.memberName} is required`,
      })}
      customisations={customisations}
      change={change}
      untouch={untouch}
      formName={formName}
    />
    <ComponentField
      name="memberDetails.memberNumber"
      component={WrappedTextField}
      label={fieldLabels.nestedTypes.memberDetails.labels.memberNumber}
      fullWidth
      customisations={customisations}
      change={change}
      untouch={untouch}
      formName={formName}
    />
    <ComponentField
      name="memberDetails.memberSince"
      component={WrappedDatePicker}
      label={fieldLabels.nestedTypes.memberDetails.labels.memberSince}
      fullWidth
      customisations={customisations}
      change={change}
      untouch={untouch}
      formName={formName}
    />
    <ComponentField
      component={WrappedTextField}
      name="memberDetails.fundBalance"
      label={fieldLabels.nestedTypes.memberDetails.labels.fundBalance}
      validate={numericality({
        ">=": 0,
        allowBlank: true,
      })}
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
      fullWidth
      helperText="Amount available in fund"
      customisations={customisations}
      change={change}
      untouch={untouch}
      formName={formName}
    />
  </>
);

MemberDetailsFields.propTypes = {
  fieldLabels: PropTypes.object.isRequired,
  customisations: PropTypes.array,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
};

MemberDetailsFields.defaultProps = {
  customisations: [],
};

export default MemberDetailsFields;
