import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import {
  getAsyncValidate,
  scrollToFirstInvalidInput,
} from "../../../util/formHelpers";
import FormError from "../../common/FormError";
import MediaDocumentFields, {
  referenceAsyncValidator,
} from "./MediaDocumentFields";

export const ADD_DOCUMENT_FORM_NAME = "ADD_DOCUMENT_FORM_NAME";

const asyncValidators = {
  reference: {
    ...referenceAsyncValidator,
  },
};

const AddDocumentForm = ({
  error,
  fieldLabels,
  change,
  disabledFutureDates,
}) => (
  <form autoComplete="off">
    {error && <FormError errorMessage={error} />}
    <MediaDocumentFields
      fieldLabels={fieldLabels}
      namePrefix=""
      editMode
      disabledFutureDates={disabledFutureDates}
      change={change}
      formName={ADD_DOCUMENT_FORM_NAME}
    />
  </form>
);

AddDocumentForm.propTypes = {
  error: PropTypes.string,
  fieldLabels: PropTypes.object.isRequired,
  disabledFutureDates: PropTypes.array.isRequired,
  change: PropTypes.func.isRequired,
};

AddDocumentForm.defaultProps = {
  error: null,
};

const mapStateToProps = () => ({});

const form = reduxForm({
  form: ADD_DOCUMENT_FORM_NAME,
  asyncValidate: getAsyncValidate(asyncValidators),
  asyncBlurFields: ["reference"],
  onSubmitFail: (errors) => scrollToFirstInvalidInput(errors),
});
const reduxData = connect(mapStateToProps);
export default compose(form, reduxData)(AddDocumentForm);
