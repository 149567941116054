import InputAdornment from "@material-ui/core/InputAdornment";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import { numericality, required } from "redux-form-validators";
import {
  getLabels,
  getLoggedInUser,
  getReferenceDataType,
} from "../../../reducers";
import usersApi from "../../../services/api/users";
import { scrollToFirstInvalidInput } from "../../../util/formHelpers";
import FormError from "../../common/FormError";
import WrappedDatePicker from "../wrapper/WrappedDatePicker";
import WrappedTextField from "../wrapper/WrappedTextField";
import WrappedOwnerPicker from "../wrapper/WrappedOwnerPicker";
import { useAuth0 } from "@auth0/auth0-react";

const styles = {};

const ActionPlanItemForm = ({
  error,
  ownerRoles,
  fieldLabels,
  loggedInUser,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const asyncOwnerOptionsFetch = async (
    pickerFilter,
    pagination,
    abortController
  ) => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = {
      ...pickerFilter,
      limit: pagination.pageSize,
      offset: pagination.offset,
      roles: ownerRoles,
      orderByField: "name",
    };
    return usersApi.search(searchParameters, abortController, accessToken);
  };

  return (
    <form autoComplete="off">
      {error && <FormError errorMessage={error} />}
      <Field
        component={WrappedTextField}
        name="title"
        label={fieldLabels.labels.title}
        data-cy={fieldLabels.labels.title}
        validate={required({ msg: `${fieldLabels.labels.title} is required` })}
        fullWidth
        required
      />
      <Field
        name="owner"
        component={WrappedOwnerPicker}
        datasource={asyncOwnerOptionsFetch}
        required
        validate={required({ msg: `${fieldLabels.labels.owner} is required` })}
        label={fieldLabels.labels.owner}
        data-cy={fieldLabels.labels.owner}
        fullWidth
        loggedInUser={loggedInUser}
      />
      <Field
        name="dueDate"
        component={WrappedDatePicker}
        label={fieldLabels.labels.dueDate}
        data-cy={fieldLabels.labels.dueDate}
        validate={required({
          msg: `${fieldLabels.labels.dueDate} is required`,
        })}
        required
        fullWidth
      />
      <Field
        name="reminderLeadTime"
        component={WrappedTextField}
        label={fieldLabels.labels.reminderLeadTime}
        data-cy={fieldLabels.labels.reminderLeadTime}
        helperText="Number of days before due date to send reminder"
        fullWidth
        InputProps={{
          endAdornment: <InputAdornment position="end">days</InputAdornment>,
        }}
        validate={numericality({
          ">": 0,
          int: true,
          allowBlank: true,
        })}
      />
      <Field
        component={WrappedTextField}
        name="notes"
        label={fieldLabels.labels.notes}
        data-cy={fieldLabels.labels.notes}
        fullWidth
        multiline
        rowsMax={10}
        rows={3}
      />
    </form>
  );
};

ActionPlanItemForm.propTypes = {
  fieldLabels: PropTypes.object.isRequired,
  loggedInUser: PropTypes.object.isRequired,
  ownerRoles: PropTypes.array.isRequired,
  error: PropTypes.string,
};

ActionPlanItemForm.defaultProps = {
  error: null,
};

export const ACTION_PLAN_ITEM_FORM_NAME = "ActionPlanItemForm";

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
  ownerRoles: getReferenceDataType(state, "WorkItemType").values.RISK_ACTION
    .props.definition.ownableBy,
  fieldLabels: getLabels(state).RISK_ACTION,
});

const form = reduxForm({
  form: ACTION_PLAN_ITEM_FORM_NAME,
  onSubmitFail: (errors) => scrollToFirstInvalidInput(errors),
});
const reduxData = connect(mapStateToProps);
export default compose(form, reduxData)(withStyles(styles)(ActionPlanItemForm));
