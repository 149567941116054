import CardHeader from "@material-ui/core/CardHeader";
import Accordion from "@material-ui/core/Accordion";
import AccordionActions from "@material-ui/core/AccordionActions";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { submit } from "redux-form";
import Button from "@material-ui/core/Button";
import classnames from "classnames";
import { isUploading } from "../../reducers";

const styles = (theme) => ({
  root: {
    width: "100%",
    border: "none",
    "&:before": {
      height: 0,
    },
    background: "transparent",
  },
  paper: {
    padding: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  altPaper: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  header: {
    padding: "0px",
  },
  button: {
    marginRight: theme.spacing(1),
  },
});

const paperClasses = (classes, isAltPaper) =>
  classnames({
    [classes.paper]: true,
    [classes.altPaper]: isAltPaper,
  });

class ExpandableForm extends Component {
  handleDelete = () => {
    const { onDelete, initialValues } = this.props;
    return onDelete && onDelete(initialValues);
  };

  render() {
    const {
      classes,
      title,
      open,
      isAltPaper,
      formRef,
      cancelButtonText,
      submitButtonText,
      deleteButtonText,
      handleSubmit,
      onCancel,
      onSubmit,
      onDelete,
      uploading,
      disableSubmit,
      formComponent: FormComponent,
      ...rest
    } = this.props;

    const actions = (
      <>
        <Button variant="text" onClick={onCancel} className={classes.button}>
          {cancelButtonText}
        </Button>
        {onDelete && (
          <Button
            variant="text"
            secondary
            onClick={this.handleDelete}
            className={classes.button}
          >
            {deleteButtonText}
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={uploading || disableSubmit}
        >
          {submitButtonText}
        </Button>
      </>
    );

    const closeAction = (
      <Tooltip title={cancelButtonText} disableFocusListener>
        <IconButton aria-label={cancelButtonText} onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </Tooltip>
    );

    return (
      <Accordion expanded={open} elevation={0} className={classes.root}>
        <div ref={formRef} />
        <Paper elevation={2} className={paperClasses(classes, isAltPaper)}>
          <Grid container>
            <Grid item xs={12}>
              <CardHeader
                className={classes.header}
                title={title}
                action={closeAction}
              />
            </Grid>
            <Grid item xs={12}>
              {open && <FormComponent onSubmit={onSubmit} {...rest} />}
            </Grid>
          </Grid>
          <AccordionActions>{actions}</AccordionActions>
        </Paper>
      </Accordion>
    );
  }
}

ExpandableForm.propTypes = {
  title: PropTypes.string.isRequired,
  formComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
    .isRequired,
  formName: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  enableFileUploads: PropTypes.bool,
  onDelete: PropTypes.func,
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  isEditing: PropTypes.bool,
  isAltPaper: PropTypes.bool,
  formRef: PropTypes.object,
  cancelButtonText: PropTypes.string,
  submitButtonText: PropTypes.string,
  deleteButtonText: PropTypes.string,
  initialValues: PropTypes.object,
  uploading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  disableSubmit: PropTypes.bool,
};

ExpandableForm.defaultProps = {
  open: false,
  isEditing: false,
  isAltPaper: false,
  formRef: null,
  cancelButtonText: "Cancel",
  submitButtonText: "Submit",
  deleteButtonText: "Delete",
  enableFileUploads: false,
  onDelete: null,
  initialValues: null,
  disableSubmit: false,
};

const mapStateToProps = (state) => ({
  uploading: isUploading(state),
});

export const mapDispatchToProps = (dispatch, ownProps) => ({
  handleSubmit: () => dispatch(submit(ownProps.formName)),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(ExpandableForm);
