import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";

const styles = () => ({
  progressbar: {
    position: "fixed",
    top: 0,
    width: "100%",
    height: 4,
    zIndex: 1500,
  },
  blocker: {
    position: "fixed",
    top: 0,
    width: "100%",
    height: "100%",
    zIndex: 1600,
  },
});

const BlockingLoadingIndicator = ({ loading, block, classes }) =>
  loading ? (
    <>
      <LinearProgress
        className={classes.progressbar}
        color="primary"
        data-cy="progressBar"
      />
      {block && <div className={classes.blocker} />}
    </>
  ) : null;

BlockingLoadingIndicator.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  block: PropTypes.bool,
};

BlockingLoadingIndicator.defaultProps = {
  block: true,
};

export default withStyles(styles)(BlockingLoadingIndicator);
