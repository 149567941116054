import { useAuth0 } from "@auth0/auth0-react";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchLabels } from "../actions/labels";
import { fetchNotifications } from "../actions/notifications";
import { fetchReferenceData } from "../actions/referenceData";
import { isLabelsDataLoaded, isReferenceDataLoaded } from "../reducers";

const PrefetchDataSets = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const referenceDataLoaded = useSelector(isReferenceDataLoaded);
  const labelsLoaded = useSelector(isLabelsDataLoaded);

  useEffect(() => {
    async function fetchData() {
      const token = await getAccessTokenSilently();

      dispatch(fetchNotifications(token));

      if (!referenceDataLoaded) {
        dispatch(fetchReferenceData(token));
      }

      if (!labelsLoaded) {
        dispatch(fetchLabels(token));
      }
    }

    fetchData();
  }, []);

  if (referenceDataLoaded && labelsLoaded) {
    return children;
  }

  return null;
};

PrefetchDataSets.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default PrefetchDataSets;
