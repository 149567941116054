import { JSONAPI } from "@certane/arcadia-web-components";
import { RiskDto } from "../../../model/Risk";
import { requestJSON } from "../http";

const decorateSearchParameters = (filter: SearchParams) =>
  filter ? { __type: "Risk", ...filter } : { __type: "Risk" };

interface SearchParams {
  limit?: number;
  offset?: number;
  orderByField?: string;
  textSearch?: string;
  ownerIds?: string[];
}

export interface RiskBulkUpdateRequest {
  riskSearchParameters: SearchParams;
  userId: string;
  dryRun: boolean;
}

const updateRiskUsers = (
  request: RiskBulkUpdateRequest,
  accessToken: string,
  abortController?: AbortController
): Promise<JSONAPI<RiskDto[]>> => {
  const riskSearchParameters = decorateSearchParameters(
    request.riskSearchParameters
  );
  return requestJSON({
    path: "/bulk-updates/risks/users",
    method: "POST",
    data: { ...request, riskSearchParameters },
    abortController,
    accessToken,
  });
};

export default {
  updateRiskUsers,
};
