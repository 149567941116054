import { requestJSON } from "./http";
import { Tag, TagUsage } from "../../model/Tag";

const list = (accessToken: string): Promise<Tag[]> =>
  requestJSON({ path: "/tags", accessToken });

const load = (id: string, accessToken: string): Promise<Tag> =>
  requestJSON({
    path: `/tags/${id}`,
    accessToken,
  });

const patch = (
  id: string,
  changes: Record<string, unknown>,
  description: string,
  accessToken: string
): Promise<Tag> =>
  requestJSON({
    path: `/tags/${id}`,
    method: "PATCH",
    data: {
      fields: Object.keys(changes),
      changes,
      description,
    },
    accessToken,
  });

const usages = (id: string, accessToken: string): Promise<TagUsage[]> =>
  requestJSON({
    path: `/tags/${id}/usage`,
    accessToken,
  });

const deleteTag = (id: string, accessToken: string): Promise<void> =>
  requestJSON({ path: `/tags/${id}`, method: "DELETE", accessToken });

export default {
  list,
  load,
  patch,
  usages,
  deleteTag,
};
