import { withStyles } from "@material-ui/core";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Editor,
  EditorState,
  CompositeDecorator,
  convertFromRaw,
} from "draft-js";
import InlineMention from "./InlineMention";

const styles = (theme) => ({
  actions: {
    position: "absolute",
    right: theme.spacing(1) / 4,
    top: 0,
  },
});

const findMentions = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "MENTION"
    );
  }, callback);
};

const RichTextMentionsView = ({ classes, value, children }) => {
  const decorator = new CompositeDecorator([
    {
      strategy: findMentions,
      component: InlineMention,
    },
  ]);
  const [editorState, setEditorState] = useState(
    EditorState.createEmpty(decorator)
  );

  useEffect(() => {
    setEditorState(
      value
        ? EditorState.createWithContent(convertFromRaw(value), decorator)
        : EditorState.createEmpty(decorator)
    );
  }, [value]);

  return (
    <div>
      <div className={classes.actions}>{children}</div>

      <Editor editorState={editorState} readOnly />
    </div>
  );
};

RichTextMentionsView.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

RichTextMentionsView.defaultProps = {
  value: null,
  children: null,
};

export default withStyles(styles)(RichTextMentionsView);
