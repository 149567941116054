import { useAuth0 } from "@auth0/auth0-react";
import { authorizer, useTenant } from "@certane/arcadia-web-components";
import React from "react";
import { connect } from "react-redux";
import {
  removeWorkItemUser,
  setWorkItemUsers,
} from "../../../actions/workItems";
import { User, WorkItemUser } from "../../../model/User";
import { WorkItem } from "../../../model/WorkItem";
import { getLoggedInUser } from "../../../reducers";
import externalUserCanRemoveUser from "./externalUserCanRemoveUser";
import WorkItemUsers from "./WorkItemUsers";

interface Props {
  workItem: WorkItem;
  readonly: boolean;
  // redux
  loggedInUser: User;
  localSetWorkItemUsers: (
    param: {
      workItemId: string;
      userIds: Record<string, string[]>;
      reason: string;
    },
    accessToken: string
  ) => Promise<unknown>;
  localRemoveWorkItemUser: (
    workItemId: string,
    type: string,
    userId: string,
    accessToken: string
  ) => Promise<unknown>;
}

const AssigneeCard: React.FC<Props> = ({
  workItem,
  readonly,
  localSetWorkItemUsers,
  localRemoveWorkItemUser,
}: Props) => {
  const { getAccessTokenSilently, user } = useAuth0();
  const { tenant } = useTenant();

  const canAddUsers = () => true;
  const canRemoveUser = (
    loggedInUser: User,
    selectedWorkItemUser: WorkItemUser
  ) => {
    if (!authorizer.check("work-item", "editor", user, tenant?.id))
      return false;
    if (loggedInUser.externalUser) {
      return externalUserCanRemoveUser(loggedInUser, selectedWorkItemUser.user);
    } else {
      return true;
    }
  };

  const handleAddUsers = async (users: User[], reason: string) => {
    console.log("handleAddUsers", users, reason);
    const userIds: Record<string, string[]> = {};
    userIds.ASSIGNEE = users.map((user) => user.id);

    const accessToken = await getAccessTokenSilently();
    return await localSetWorkItemUsers(
      { workItemId: workItem.id, userIds, reason },
      accessToken
    );
  };

  const handleRemoveUser = async (workItemUser: WorkItemUser) => {
    const accessToken = await getAccessTokenSilently();
    return await localRemoveWorkItemUser(
      workItem.id,
      workItemUser.type,
      workItemUser.user.id,
      accessToken
    );
  };

  return (
    <WorkItemUsers
      workItem={workItem}
      userTypes={["ASSIGNEE"]}
      title={"Assignees"}
      readonly={readonly}
      canAddUsers={canAddUsers}
      onAddUsers={handleAddUsers}
      canRemoveUser={canRemoveUser}
      onRemoveUser={handleRemoveUser}
      disabledOptions={[]}
      withReason
    />
  );
};

const mapStateToProps = (state: Record<string, unknown>) => ({
  loggedInUser: getLoggedInUser(state),
});

export default connect(mapStateToProps, {
  localSetWorkItemUsers: setWorkItemUsers,
  localRemoveWorkItemUser: removeWorkItemUser,
})(AssigneeCard);
