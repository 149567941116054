import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles((theme) => ({
  warning: {
    margin: `${theme.spacing(1)}px 0`,
    display: "block",
    color: theme.palette.warning.main,
  },
}));

const NoEndorsementsWarning = ({ workItem }) => {
  const classes = useStyles();

  if (workItem.endorsements.length) {
    return null;
  }

  return (
    <Typography className={classes.warning} variant="body2" display="block">
      Warning: No endorsements added.
    </Typography>
  );
};

NoEndorsementsWarning.propTypes = {
  workItem: PropTypes.any.isRequired,
};

export default NoEndorsementsWarning;
