import { withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { setWorkItemStatus } from "../../../../actions/workItems";
import { getLabels, getReferenceDataDescription } from "../../../../reducers";
import AlertDialog from "../../../common/AlertDialog";
import FormCard from "../../../common/FormCard";
import Scorecard from "../../../common/Scorecard";
import EditWorkItemComponentForm, {
  EDIT_WORK_ITEM_COMPONENT_FORM_NAME,
} from "../EditWorkItemComponentForm";
import { useAuth0 } from "@auth0/auth0-react";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
});

const RiskAssessment = ({
  classes,
  workItem,
  onChange,
  fieldLabels,
  localSetWorkItemStatus,
  getRiskLikelihoodDescription,
  getRiskConsequenceDescription,
  getRiskRatingDescription,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const [closeFailureError, setCloseFailureError] = useState(null);
  const [submitPromise, setSubmitPromise] = useState(null);

  const confirmSubmission = async () => {
    const accessToken = await getAccessTokenSilently();

    // update the work item, and then close it
    try {
      await onChange(submitPromise.values);
      const response = await localSetWorkItemStatus(
        workItem.id,
        "CLOSED",
        "COMPLETE",
        null,
        null,
        accessToken
      );
      submitPromise.resolve(response);
      setSubmitPromise(null);
    } catch (error) {
      submitPromise.resolve(null);
      setSubmitPromise(null);
      setCloseFailureError(null);
    }
  };

  const cancelSubmission = () => {
    if (submitPromise) {
      submitPromise.resolve(null);
      setSubmitPromise(null);
    }
  };

  const submitReassessment = (values) =>
    new Promise((resolve, reject) => {
      setSubmitPromise({
        resolve,
        reject,
        values,
      });
    });

  return (
    <>
      <AlertDialog
        title="Submit assessment"
        body="Submitting the assessment will update the risk and close this reassessment task."
        submitButtonText="Submit"
        open={!!submitPromise}
        onCancel={() => cancelSubmission()}
        onSubmit={() => confirmSubmission()}
        data-cy="submitAssessment"
      />
      <AlertDialog
        title="Close error"
        body="A problem occurred closing the reassessment work item. Please close manually."
        submitButtonText="Ok"
        open={!!closeFailureError}
        onSubmit={() => setCloseFailureError(null)}
        data-cy="closeError"
      />
      <FormCard
        className={classes.root}
        title={fieldLabels.labels.riskAssessmentDetails}
        data-cy="RiskAssessmentDetails"
        formTitle={`Edit ${fieldLabels.labels.riskAssessmentDetails.toLowerCase()}`}
        readOnlyView={() => (
          <>
            <Scorecard
              label={
                fieldLabels.nestedTypes.riskAssessmentDetails.labels.likelihood
              }
              data-cy={
                fieldLabels.nestedTypes.riskAssessmentDetails.labels.likelihood
              }
            >
              {getRiskLikelihoodDescription(
                workItem.riskAssessmentDetails.likelihood
              )}
            </Scorecard>
            <Scorecard
              label={
                fieldLabels.nestedTypes.riskAssessmentDetails.labels.consequence
              }
              data-cy={
                fieldLabels.nestedTypes.riskAssessmentDetails.labels.consequence
              }
            >
              {getRiskConsequenceDescription(
                workItem.riskAssessmentDetails.consequence
              )}
            </Scorecard>
            <Scorecard
              label={
                fieldLabels.nestedTypes.riskAssessmentDetails.labels.riskRating
              }
              data-cy={
                fieldLabels.nestedTypes.riskAssessmentDetails.labels.riskRating
              }
            >
              {getRiskRatingDescription(
                workItem.riskAssessmentDetails.riskRating
              )}
            </Scorecard>
            <Scorecard
              label={
                fieldLabels.nestedTypes.riskAssessmentDetails.labels.comment
              }
              data-cy={
                fieldLabels.nestedTypes.riskAssessmentDetails.labels.comment
              }
            >
              {workItem.riskAssessmentDetails.comment}
            </Scorecard>
          </>
        )}
        formComponent={EditWorkItemComponentForm}
        formName={EDIT_WORK_ITEM_COMPONENT_FORM_NAME}
        onSave={!onChange ? null : submitReassessment}
        initialValues={{ ...workItem }}
        formProps={{
          maxWidth: "md",
          workItem,
          componentName: "RISK_ASSESSMENT",
        }}
      />
    </>
  );
};

RiskAssessment.propTypes = {
  classes: PropTypes.object.isRequired,
  workItem: PropTypes.object.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  localSetWorkItemStatus: PropTypes.func.isRequired,
  getRiskLikelihoodDescription: PropTypes.func.isRequired,
  getRiskConsequenceDescription: PropTypes.func.isRequired,
  getRiskRatingDescription: PropTypes.func.isRequired,
};

RiskAssessment.defaultProps = {
  onChange: null,
};

const mapStateToProps = (state) => ({
  fieldLabels: getLabels(state).RISK_REASSESSMENT,
  getRiskLikelihoodDescription: (id) =>
    getReferenceDataDescription(state, "RiskLikelihood", id, "-"),
  getRiskConsequenceDescription: (id) =>
    getReferenceDataDescription(state, "RiskConsequenceRating", id, "-"),
  getRiskRatingDescription: (id) =>
    getReferenceDataDescription(state, "RiskRating", id, "-"),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    localSetWorkItemStatus: setWorkItemStatus,
  })
)(RiskAssessment);
