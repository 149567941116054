import { useAuth0 } from "@auth0/auth0-react";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import NotificationsIcon from "@material-ui/icons/Notifications";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import {
  dismiss,
  dismissAll,
  fetchNotifications,
  markAsRead,
} from "../../../../actions/notifications";
import { getNotifications } from "../../../../reducers";
import { getWorkItemLink } from "../../../../routes/routeUtils";
import {
  subscribeNotificationUpdate,
  unsubscribeNotificationUpdate,
} from "../../../../services/notificationAdapter";
import logger from "../../../../util/logger";
import NotificationCard from "./NotificationCard";

const styles = () => ({
  button: {},
  popoverPaper: {
    width: 400,
  },
});

const getLink = (entity) => {
  switch (entity.className) {
    case "Risk":
      return `/risk/risks/${entity.id}`;
    case "WorkItem":
      return getWorkItemLink(entity);
    default:
      logger.error(`No link for entity ${entity.id}`);
  }
  return null;
};

const NotificationButton = ({
  history,
  classes,
  notifications,
  localFetchNotifications,
  localMarkAsRead,
  localDismiss,
  localDismissAll,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [anchorEl, setAnchorEl] = useState(null);
  const notificationLastUpdated = useRef(null);

  const handleNotificationUpdate = _.memoize((event) => {
    const lastUpdated = event.detail;
    if (
      notificationLastUpdated.current &&
      notificationLastUpdated.current < lastUpdated
    ) {
      (async () => {
        const accessToken = await getAccessTokenSilently();
        localFetchNotifications(accessToken);
      })();
    }
    notificationLastUpdated.current = lastUpdated;
  });

  useEffect(() => {
    subscribeNotificationUpdate(handleNotificationUpdate);
    return () => {
      unsubscribeNotificationUpdate(handleNotificationUpdate);
    };
  }, []);

  const handleBellClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = async (notification) => {
    const accessToken = await getAccessTokenSilently();
    // markAsRead, then redirect / close
    localMarkAsRead(notification.id, accessToken).finally(() => {
      if (notification.entity) {
        const link = getLink(notification.entity);
        logger.debug(`redirecting to ${link}`);
        history.push(link);
      }

      handleClose();
    });
  };

  const handleDismiss = async (notification) => {
    const accessToken = await getAccessTokenSilently();
    localDismiss(notification.id, accessToken);
  };

  const handleDismissAll = async () => {
    const accessToken = await getAccessTokenSilently();
    localDismissAll(accessToken);
  };

  return (
    <>
      <Tooltip title="Notifications" disableFocusListener>
        <IconButton
          className={classes.button}
          aria-label="Notifications"
          color="inherit"
          onClick={handleBellClick}
          data-cy="notification"
        >
          <Badge
            badgeContent={
              notifications.filter((notification) => !notification.read).length
            }
          >
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        classes={{ paper: classes.popoverPaper }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <NotificationCard
          notifications={notifications}
          onNotificationClick={handleNotificationClick}
          onDismissClick={handleDismiss}
          onDismissAllClick={handleDismissAll}
        />
      </Popover>
    </>
  );
};

NotificationButton.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  notifications: PropTypes.array,
  localFetchNotifications: PropTypes.func.isRequired,
  localMarkAsRead: PropTypes.func.isRequired,
  localDismiss: PropTypes.func.isRequired,
  localDismissAll: PropTypes.func.isRequired,
};

NotificationButton.defaultProps = {
  notifications: [],
};

const mapStateToProps = (state) => ({
  notifications: getNotifications(state),
});

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, {
    localFetchNotifications: fetchNotifications,
    localMarkAsRead: markAsRead,
    localDismissAll: dismissAll,
    localDismiss: dismiss,
  })
)(NotificationButton);
