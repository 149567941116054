import InputAdornment from "@material-ui/core/InputAdornment";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { length, numericality, required } from "redux-form-validators";
import { yesNoOptions } from "../../../../util/radioOptions";
import ComponentField from "../../../forms/wrapper/ComponentField";
import WrappedRadioGroup from "../../../forms/wrapper/WrappedRadioGroup";
import WrappedTextField from "../../../forms/wrapper/WrappedTextField";
import WrappedDatePicker from "../../../forms/wrapper/WrappedDatePicker";
import WrappedSelectChips from "../../../forms/wrapper/WrappedSelectChips";
import { getReferenceDataType } from "../../../../reducers";

const approveRejectOptions = [
  {
    label: "Approved",
    value: true,
  },
  {
    label: "Rejected",
    value: false,
  },
];

const inOutOptions = [
  {
    label: "Opt-in",
    value: true,
  },
  {
    label: "Opt-out",
    value: false,
  },
];

const InsuranceFields = ({
  fieldLabels,
  customisations,
  change,
  untouch,
  formName,
  coverType,
  insurance,
}) => {
  const { hasInsurance } = insurance || {};
  const { claimApproved } = insurance || {};
  const { coverTypes } = insurance || {};

  return (
    <>
      <ComponentField
        component={WrappedRadioGroup}
        name="insurance.hasInsurance"
        label={fieldLabels.nestedTypes.insurance.labels.hasInsurance}
        options={yesNoOptions}
        row
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      />
      {hasInsurance && (
        <>
          <ComponentField
            component={WrappedTextField}
            name="insurance.coverAmount"
            label={fieldLabels.nestedTypes.insurance.labels.coverAmount}
            validate={numericality({
              ">=": 0,
              allowBlank: true,
            })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            fullWidth
            customisations={customisations}
            change={change}
            untouch={untouch}
            formName={formName}
          />
          <ComponentField
            component={WrappedRadioGroup}
            name="insurance.claimApproved"
            label={fieldLabels.nestedTypes.insurance.labels.claimApproved}
            options={approveRejectOptions}
            row
            customisations={customisations}
            change={change}
            untouch={untouch}
            formName={formName}
          />
          <ComponentField
            component={WrappedTextField}
            name="insurance.claimNotes"
            label={fieldLabels.nestedTypes.insurance.labels.claimNotes}
            fullWidth
            multiline
            rowsMax={10}
            rows={2}
            helperText="Please add any notes about the insurance claim here."
            customisations={customisations}
            change={change}
            untouch={untouch}
            formName={formName}
          />
          {claimApproved === false && (
            <>
              <ComponentField
                component={WrappedDatePicker}
                name="insurance.dateJoinedFund"
                label={fieldLabels.nestedTypes.insurance.labels.dateJoinedFund}
                fullWidth
                data-cy={
                  fieldLabels.nestedTypes.insurance.labels.dateJoinedFund
                }
                required
                validate={required({
                  msg: `${fieldLabels.nestedTypes.insurance.labels.dateJoinedFund} is required`,
                })}
                customisations={customisations}
                change={change}
                untouch={untouch}
                formName={formName}
              />
              <ComponentField
                component={WrappedDatePicker}
                name="insurance.dateCoverApplied"
                label={
                  fieldLabels.nestedTypes.insurance.labels.dateCoverApplied
                }
                fullWidth
                data-cy={
                  fieldLabels.nestedTypes.insurance.labels.dateCoverApplied
                }
                required
                validate={required({
                  msg: `${fieldLabels.nestedTypes.insurance.labels.dateCoverApplied} is required`,
                })}
                customisations={customisations}
                change={change}
                untouch={untouch}
                formName={formName}
              />
              <ComponentField
                name="insurance.coverTypes"
                component={WrappedSelectChips}
                options={coverType.ids}
                label={fieldLabels.nestedTypes.insurance.labels.coverTypes}
                fullWidth
                toOption={(id) => ({
                  label: coverType.values[id].description,
                  value: id,
                })}
                fromOption={(option) => option.value}
                required
                validate={length({
                  min: 1,
                  msg: `${fieldLabels.nestedTypes.insurance.labels.coverTypes} is required`,
                })}
                customisations={customisations}
                change={change}
                untouch={untouch}
                formName={formName}
              />
              {coverTypes?.toString().includes(coverType.ids[0]) && (
                <>
                  <ComponentField
                    component={WrappedRadioGroup}
                    name="insurance.optInOrOut"
                    label={fieldLabels.nestedTypes.insurance.labels.optInOrOut}
                    options={inOutOptions}
                    row
                    required
                    validate={required({
                      msg: `${fieldLabels.nestedTypes.insurance.labels.optInOrOut} is required`,
                    })}
                    customisations={customisations}
                    change={change}
                    untouch={untouch}
                    formName={formName}
                  />
                </>
              )}
              <ComponentField
                component={WrappedDatePicker}
                name="insurance.dateClaimFirstSubmitted"
                label={
                  fieldLabels.nestedTypes.insurance.labels
                    .dateClaimFirstSubmitted
                }
                fullWidth
                data-cy={
                  fieldLabels.nestedTypes.insurance.labels
                    .dateClaimFirstSubmitted
                }
                required
                validate={required({
                  msg: `${fieldLabels.nestedTypes.insurance.labels.dateClaimFirstSubmitted} is required`,
                })}
                customisations={customisations}
                change={change}
                untouch={untouch}
                formName={formName}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

InsuranceFields.propTypes = {
  fieldLabels: PropTypes.object.isRequired,
  customisations: PropTypes.array,
  formName: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  coverType: PropTypes.object,
  insurance: PropTypes.object,
};

InsuranceFields.defaultProps = {
  customisations: [],
  insurance: {},
};

const mapStateToProps = (state, ownProps) => {
  const reduxFormSelector = formValueSelector(ownProps.formName);
  return {
    coverType: getReferenceDataType(state, "CoverType"),
    insurance: reduxFormSelector(state, "insurance"),
  };
};

export default connect(mapStateToProps)(InsuranceFields);
