import { List } from "@material-ui/core";
import ListSubheader from "@material-ui/core/ListSubheader";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getReferenceDataDescription } from "../../../../reducers";
import formatters from "../../../../util/formatters";
import ContactDetailsReadOnly from "../contacts/ContactDetailsReadOnly";
import Scorecard from "../../../common/Scorecard";

const styles = () => ({});

class DependantsDetailsReadOnly extends Component {
  render() {
    const { fieldLabels, dependants, dependantTypeDescription } = this.props;

    const typeAndRelationship = (dependant) =>
      dependant &&
      [dependantTypeDescription(dependant.type), dependant.relationship]
        .filter(Boolean)
        .join(": ");

    return (
      <>
        {dependants && dependants.length > 0 ? (
          <List dense>
            {dependants.map((dependant, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <ListItem key={index} divider>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Grid item xs={12} md={6}>
                    <ContactDetailsReadOnly
                      contact={dependant.contact}
                      typeOverride={typeAndRelationship(dependant)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                    >
                      {dependant &&
                        dependant.benefitAmountType &&
                        dependant.benefitAmountType === "FIXED" && (
                          <Scorecard
                            label={
                              fieldLabels.nestedTypes.dependants.labels
                                .benefitAmount
                            }
                          >
                            {dependant.benefitAmount
                              ? formatters.currency(dependant.benefitAmount)
                              : "-"}
                          </Scorecard>
                        )}
                      {dependant &&
                        dependant.benefitAmountType &&
                        dependant.benefitAmountType === "PERCENTAGE" && (
                          <Scorecard
                            label={
                              fieldLabels.nestedTypes.dependants.labels
                                .benefitAmount
                            }
                          >
                            {dependant.benefitAmount
                              ? `${dependant.benefitAmount}%`
                              : "-"}
                          </Scorecard>
                        )}
                    </Grid>
                    {dependant &&
                      dependant.benefitAmount &&
                      (dependant.notes ? (
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-start"
                        >
                          <Scorecard
                            label={
                              fieldLabels.nestedTypes.dependants.labels.notes
                            }
                          >
                            {dependant.notes}
                          </Scorecard>
                        </Grid>
                      ) : (
                        <p>No special conditions</p>
                      ))}
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
        ) : (
          <ListSubheader disableSticky>No dependants</ListSubheader>
        )}
      </>
    );
  }
}

DependantsDetailsReadOnly.propTypes = {
  fieldLabels: PropTypes.object.isRequired,
  dependants: PropTypes.array,
  dependantTypeDescription: PropTypes.func.isRequired,
};

DependantsDetailsReadOnly.defaultProps = {
  dependants: [],
};

const mapStateToProps = (state) => ({
  dependantTypeDescription: (id) =>
    getReferenceDataDescription(state, "DependantType", id, null),
});

export default connect(mapStateToProps)(
  withStyles(styles)(DependantsDetailsReadOnly)
);
