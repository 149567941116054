import { combineReducers } from "redux";
import { MENTION_LIST, MENTION_PATCH } from "../actions/mentions";
import { publishActionToast } from "../services/toasts";

const successToasts = {
  MENTION_SET_STATUS: "Successfully updated status",
};

const byId = (state = {}, action) => {
  publishActionToast(action, "MENTION_", successToasts);

  switch (action.type) {
    case `${MENTION_PATCH}_SUCCESS`:
    case `${MENTION_LIST}_SUCCESS`:
      return {
        ...state,
        ...action.response.entities.mention,
      };

    default:
      break;
  }

  return state;
};

const defaultActive = {
  activeList: [],
};

const active = (state = defaultActive, action) => {
  switch (action.type) {
    case `${MENTION_LIST}_SUCCESS`:
      return {
        ...state,
        activeList: [...action.response.result],
      };

    default:
      break;
  }

  return state;
};

export default combineReducers({
  active,
  byId,
});

export const getMentions = (state) =>
  state.active.activeList.map((id) => state.byId[id]);
