import { useAuth0 } from "@auth0/auth0-react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { loadRiskControl } from "../../../../actions/riskControls";
import { getActiveRiskControl } from "../../../../reducers";
import RiskControlDetailsFieldsReadOnly from "../../../risk/RiskControlDetailsFieldsReadOnly";
import { includeTenantParam } from "@certane/arcadia-web-components";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
});

const RiskControlSummaryCard = ({
  classes,
  className,
  riskControl,
  workItem,
  localLoadRiskControl,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    if (
      workItem.associatedRiskControl.accessible &&
      (!riskControl || riskControl.id !== workItem.associatedRiskControl.id)
    ) {
      (async () => {
        const accessToken = await getAccessTokenSilently();
        localLoadRiskControl(workItem.associatedRiskControl.id, accessToken);
      })();
    }
  }, [workItem]);

  const getRiskControlSummary = () => {
    if (riskControl && riskControl.id === workItem.associatedRiskControl.id) {
      return <RiskControlDetailsFieldsReadOnly riskControl={riskControl} />;
    }
    return `Risk control '${workItem.associatedRiskControl.name}' details are not available`;
  };

  const getRiskControlSummaryAction = () =>
    workItem.associatedRiskControl.accessible ? (
      <Tooltip
        title={`View ${workItem.associatedRiskControl.name} in new window`}
        disableFocusListener
      >
        <IconButton
          onClick={() =>
            window.open(
              includeTenantParam(
                `/risk/controls/${workItem.associatedRiskControl.id}`
              ),
              "_blank"
            )
          }
        >
          <Icon>launch</Icon>
        </IconButton>
      </Tooltip>
    ) : null;

  return (
    <Card
      className={classNames({
        [classes.root]: true,
        [className]: !!className,
      })}
      elevation={0}
    >
      <CardHeader
        title="Risk control summary"
        action={getRiskControlSummaryAction()}
      />
      <CardContent>
        {!workItem.associatedRiskControl && "No risk control linked"}
        {workItem.associatedRiskControl && getRiskControlSummary()}
      </CardContent>
    </Card>
  );
};

RiskControlSummaryCard.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  workItem: PropTypes.object.isRequired,
  riskControl: PropTypes.object,
  localLoadRiskControl: PropTypes.func.isRequired,
};

RiskControlSummaryCard.defaultProps = {
  className: null,
  riskControl: null,
};

const mapStateToProps = (state) => ({
  riskControl: getActiveRiskControl(state),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    localLoadRiskControl: loadRiskControl,
  })
)(RiskControlSummaryCard);
