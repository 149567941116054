import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  deleteDigitalAsset,
  DIGITAL_ASSETS_SEARCH,
  patchDigitalAsset,
  searchDigitalAssets,
} from "../../actions/digitalAssets";
import {
  getDigitalAssets,
  getDigitalAssetsPagination,
  isLoadingAction,
} from "../../reducers";
import { deleteIcon, toggleOffIcon, toggleOnIcon } from "../../util/icons";
import AlertDialog from "../common/AlertDialog";
import Pagination from "../common/Pagination";
import GridListing from "../common/GridListing";
import { useAuth0 } from "@auth0/auth0-react";

const styles = () => ({});

const DigitalAssetListing = ({
  history,
  loading,
  digitalAssets,
  pagination,
  localSearchDigitalAssets,
  localDeleteDigitalAsset,
  localPatchDigitalAsset,
  filter,
  sortBy,
  updateSort,
  clearFilter,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [toBeDeleted, setToBeDeleted] = useState(null);

  const refreshData = (offset, accessToken) => {
    const searchParameters = {
      ...filter,
      limit: pagination.pageSize,
      orderByField:
        sortBy.direction === "asc" ? sortBy.field : `-${sortBy.field}`,
      offset,
    };

    return localSearchDigitalAssets(searchParameters, accessToken);
  };

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      refreshData(pagination.offset, accessToken);
    })();
  }, [getAccessTokenSilently, filter, sortBy]);

  const handlePrevious = async () => {
    const accessToken = await getAccessTokenSilently();
    refreshData(pagination.previousOffset, accessToken);
  };

  const handleNext = async () => {
    const accessToken = await getAccessTokenSilently();
    refreshData(pagination.nextOffset, accessToken);
  };

  const handleDeleteAsset = async () => {
    const accessToken = await getAccessTokenSilently();
    localDeleteDigitalAsset(toBeDeleted.id, accessToken).then(() =>
      setToBeDeleted(null)
    );
  };

  const handleUpdate = async (digitalAssetId, changes) => {
    const accessToken = await getAccessTokenSilently();
    localPatchDigitalAsset(digitalAssetId, changes, "Updated", accessToken);
  };

  const DeleteIcon = deleteIcon();
  const ActiveIcon = toggleOnIcon();
  const InactiveIcon = toggleOffIcon();

  return (
    <>
      <AlertDialog
        title="Delete digital asset?"
        body="Are you sure you want to delete this digital asset?"
        submitButtonText="Delete"
        open={!!toBeDeleted}
        onCancel={() => setToBeDeleted(null)}
        onSubmit={handleDeleteAsset}
      />
      <GridListing
        sortedData={digitalAssets}
        loading={loading && digitalAssets.length === 0}
        sortBy={sortBy}
        dense={false}
        updateSort={(field, direction) =>
          updateSort({
            field,
            direction,
          })
        }
        clearFilter={clearFilter}
        onClick={(digitalAsset) =>
          history.push(`/sentinel/digital-assets/${digitalAsset.id}`)
        }
        action={(asset) => (
          <Tooltip title="Delete" disableFocusListener>
            <IconButton
              onClick={() => setToBeDeleted(asset)}
              data-cy="deleteIcon"
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
        columns={[
          {
            label: "Active",
            name: "active",
            size: 1,
            sortable: false,
            render: (digitalAsset) => (
              <Tooltip
                title={digitalAsset.active ? "Active" : "Inactive"}
                disableFocusListener
              >
                <IconButton
                  size="small"
                  onClick={(evt) => {
                    evt.stopPropagation();
                    evt.preventDefault();
                    return handleUpdate(digitalAsset.id, {
                      active: !digitalAsset.active,
                    });
                  }}
                >
                  {digitalAsset.active && <ActiveIcon color="primary" />}
                  {!digitalAsset.active && <InactiveIcon />}
                </IconButton>
              </Tooltip>
            ),
          },
          {
            label: "Organisation",
            name: "orgFriendlyId",
            size: 2,
            sortable: true,
            render: (digitalAsset) => (
              <span>
                {digitalAsset.organisation
                  ? digitalAsset.organisation.name
                  : digitalAsset.orgFriendlyId}
              </span>
            ),
          },
          {
            label: "Url",
            name: "url",
            size: 8,
            sortable: false,
            render: (digitalAsset) => <span>{digitalAsset.url}</span>,
          },
        ]}
      />
      <Pagination
        pagination={pagination}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
      />
    </>
  );
};

DigitalAssetListing.propTypes = {
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,

  filter: PropTypes.object.isRequired,
  sortBy: PropTypes.object.isRequired,
  updateSort: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired,

  // redux
  digitalAssets: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  localSearchDigitalAssets: PropTypes.func.isRequired,
  localDeleteDigitalAsset: PropTypes.func.isRequired,
  localPatchDigitalAsset: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: isLoadingAction(state, DIGITAL_ASSETS_SEARCH),
  digitalAssets: getDigitalAssets(state),
  pagination: getDigitalAssetsPagination(state),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    localSearchDigitalAssets: searchDigitalAssets,
    localDeleteDigitalAsset: deleteDigitalAsset,
    localPatchDigitalAsset: patchDigitalAsset,
  })
)(DigitalAssetListing);
