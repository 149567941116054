import { useAuth0 } from "@auth0/auth0-react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/Delete";
import classNames from "classnames";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { connect } from "react-redux";
import { compose } from "redux";
import { clearFiles, removeFile, uploadFiles } from "../../actions/files";
import { getFileUploadsInProgress, isUploading } from "../../reducers";
import { publishToastWarning } from "../../services/toasts";
import logger from "../../util/logger";
import { ACCEPTED_FILES } from "../../util/referenceData";
import FileIcon from "../workitem/FileIcon";

const styles = (theme) => ({
  dropzone: {
    position: "relative",
    width: "100%",
    textAlign: "center",
    background: theme.palette.swatch.grey6,
    padding: theme.spacing(2),
    height: "none",
    minHeight: "65px",
    border: "dashed 2px #ccc",
    borderRadius: 5,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    "&:hover": {
      cursor: "pointer",
    },
  },
  dropzoneAccept: {
    border: `solid 2px ${theme.palette.primary.main}`,
    background: theme.palette.swatch.grey4,
  },
  dropzoneReject: {
    border: `solid 2px ${theme.palette.warning.main}`,
    background: theme.palette.swatch.grey4,
  },
  hiddenDropzone: {
    display: "none",
  },
  uploadText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
  },
  uploadIcon: {
    marginRight: theme.spacing(1),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  unsavedIndicator: {
    color: theme.palette.text.hint,
    fontWeight: "normal",
    marginLeft: theme.spacing(1),
  },
  saveButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(3),
    },
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
    padding: theme.spacing(0.5),
  },
  actions: {
    textAlign: "right",
    marginBottom: theme.spacing(2),
  },
  formGrid: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    alignItems: "center",
  },
  formLabel: {
    color: "inherit",
  },
  formButton: {
    [theme.breakpoints.up("sm")]: {
      textAlign: "right",
    },
  },
});

const FileUpload = ({
  className,
  classes,
  uploading,
  multiple,
  showDropzone,
  showFiles,
  filesInProgress,
  uploadNamespace,
  localClearFiles,
  localUploadFiles,
  localRemoveFile,
  onChange,
  onSubmit,
  onClose,
  setFileOpener,
  acceptedFileTypes,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [files, setFiles] = useState([]);
  const {
    open,
    acceptedFiles,
    rejectedFiles,
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: acceptedFileTypes, multiple });

  useEffect(() => {
    if (!_.isEqual(files, filesInProgress)) {
      setFiles(filesInProgress);
    }
  }, [filesInProgress]);

  useEffect(() => {
    if (onChange) {
      const value = multiple ? files : files[0];
      onChange(value === undefined ? null : value);
    }
  }, [files]);

  useEffect(() => {
    const haveExistingDocuments = files && files.length > 0;

    if (rejectedFiles && rejectedFiles.length > 0) {
      logger.debug("rejectedFiles", rejectedFiles);
      publishToastWarning(
        `Sorry ${rejectedFiles
          .map((r) => r.name)
          .join(", ")} cannot be uploaded`
      );
    }

    if (acceptedFiles && acceptedFiles.length > 0) {
      if (!multiple && haveExistingDocuments) {
        files.forEach((doc) => localRemoveFile(uploadNamespace, doc.fileName));
      }
      getAccessTokenSilently().then((accessToken) =>
        localUploadFiles(acceptedFiles, uploadNamespace, accessToken)
      );
    }
  }, [acceptedFiles, rejectedFiles]);

  useEffect(() => {
    if (setFileOpener) {
      setFileOpener(open);
    }
  }, [setFileOpener]);

  const closeDropZone = (event) => {
    event.stopPropagation();
    event.preventDefault();
    localClearFiles(uploadNamespace);
    onClose();
  };

  const saveNewFiles = (event) => {
    event.stopPropagation();
    event.preventDefault();
    onSubmit(files)
      .then(() => localClearFiles(uploadNamespace))
      .then(() => onClose())
      .catch(() => logger.info("Cancelling upload"));
  };

  const handleRemoveFile = (event, fileName) => {
    event.stopPropagation();
    event.preventDefault();
    localRemoveFile(uploadNamespace, fileName);
  };

  const renderFileList = (documents) => (
    <List className={classes.list} disablePadding>
      {documents.map((document) => {
        if (!document) {
          return null;
        }
        return (
          <ListItem key={document.fileName} className={classes.file}>
            <ListItemIcon>
              <FileIcon document={document} data-cy="file" />
            </ListItemIcon>
            <ListItemText
              inset
              primary={
                <>
                  <span>{document.fileName}</span>
                  <span
                    className={classes.unsavedIndicator}
                    data-cy="fileUpload"
                  >
                    {document.gcsObjectName ? "(unsaved)" : ""}
                  </span>
                </>
              }
              style={{ maxWidth: "79%" }}
            />
            <ListItemSecondaryAction style={{ width: "140px" }}>
              <Grid
                container
                spacing={0}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={8}>
                  {!document.gcsObjectName ? (
                    <LinearProgress
                      variant="determinate"
                      value={document.percentCompleted}
                    />
                  ) : null}
                </Grid>
                <Grid item xs={4}>
                  <IconButton
                    onClick={(evt) => handleRemoveFile(evt, document.fileName)}
                  >
                    <DeleteIcon size={16} />
                  </IconButton>
                </Grid>
              </Grid>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );

  const haveNewDocuments = files && files.length > 0;

  return (
    <div>
      <div
        className={classNames({
          [classes.dropzone]: showDropzone,
          [classes.hiddenDropzone]: !showDropzone,
          [classes.dropzoneAccept]: isDragAccept,
          [classes.dropzoneReject]: isDragReject,
          [className]: !!className,
        })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {onClose && (
          <IconButton
            className={classes.closeButton}
            color="inherit"
            onClick={closeDropZone}
            data-cy="fileIcon"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
        <div className={classes.uploadText}>
          <CloudUploadIcon className={classes.uploadIcon} color="disabled" />
          <Typography variant="subtitle1" display="inline" data-cy="dragDrop">
            Drag and drop here or{" "}
            <span className={classes.link} data-cy="chooseFile">
              choose file
            </span>{" "}
          </Typography>
        </div>
        {haveNewDocuments && showFiles && (
          <div style={{ width: "100%" }}>
            {renderFileList(files)}
            {onSubmit && (
              <div className={classes.actions}>
                <Button
                  className={classes.saveButton}
                  variant="contained"
                  color="primary"
                  size="medium"
                  disabled={uploading}
                  onClick={(evt) => saveNewFiles(evt)}
                  data-cy="saveNewFiles"
                >
                  Save new files
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

FileUpload.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  uploadNamespace: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  multiple: PropTypes.bool,
  showDropzone: PropTypes.bool,
  showFiles: PropTypes.bool,
  setFileOpener: PropTypes.func,
  acceptedFileTypes: PropTypes.string,

  // redux state
  filesInProgress: PropTypes.array.isRequired,
  uploading: PropTypes.bool.isRequired,

  // redux actions
  localUploadFiles: PropTypes.func.isRequired,
  localRemoveFile: PropTypes.func.isRequired,
  localClearFiles: PropTypes.func.isRequired,
};

FileUpload.defaultProps = {
  className: null,
  onSubmit: null,
  onChange: null,
  onClose: null,
  multiple: true,
  showDropzone: true,
  showFiles: true,
  setFileOpener: null,
  acceptedFileTypes: ACCEPTED_FILES,
};

const mapStateToProps = (state, { uploadNamespace }) => ({
  filesInProgress: getFileUploadsInProgress(state)[uploadNamespace] || [],
  uploading: isUploading(state),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    localUploadFiles: uploadFiles,
    localRemoveFile: removeFile,
    localClearFiles: clearFiles,
  })
)(FileUpload);
