import { normalize } from "normalizr";
import schemas from "../schemas";
import mediaApi from "../services/api/media";
import { asyncAction } from "./actions";

export const MEDIA_CLEAR_ACTIVE = "MEDIA_CLEAR_ACTIVE";
export const MEDIA_CREATE = "MEDIA_CREATE";
export const MEDIA_ADD_DOCUMENT = "MEDIA_ADD_DOCUMENT";
export const MEDIA_LIST = "MEDIA_LIST";
export const MEDIA_GET_BY_ID = "MEDIA_GET_BY_ID";
export const MEDIA_DELETE_BY_ID = "MEDIA_DELETE_BY_ID";
export const MEDIA_UPDATE = "MEDIA_UPDATE";

export function clearActiveMedia() {
  return {
    type: MEDIA_CLEAR_ACTIVE,
  };
}

export const createMedia = (media, accessToken) =>
  asyncAction(MEDIA_CREATE, mediaApi.create(media, accessToken), {
    responseTransformer: (createdMedia) =>
      normalize(createdMedia, schemas.media),
  });

export const addDocument = (mediaId, version, accessToken) =>
  asyncAction(
    MEDIA_ADD_DOCUMENT,
    mediaApi.addDocument(mediaId, version, accessToken),
    {
      responseTransformer: (media) => normalize(media, schemas.media),
    }
  );

export const updateDocument = (
  mediaId,
  versionId,
  changes,
  description,
  accessToken
) =>
  asyncAction(
    MEDIA_UPDATE,
    mediaApi.patchDocument(
      mediaId,
      versionId,
      changes,
      description,
      accessToken
    ),
    {
      responseTransformer: (media) => normalize(media, schemas.media),
    }
  );

export const deleteDocument = (mediaId, versionId, accessToken) =>
  asyncAction(
    MEDIA_UPDATE,
    mediaApi.deleteDocument(mediaId, versionId, accessToken),
    {
      responseTransformer: (media) => normalize(media, schemas.media),
    }
  );

export const searchMedia = (searchParameters, accessToken) => {
  const abortController = window.AbortController ? new AbortController() : null;
  return asyncAction(
    MEDIA_LIST,
    mediaApi.search(searchParameters, abortController, accessToken),
    {
      responseTransformer: (response) => ({
        pagination: {
          offset: response.offset,
          previousOffset: response.previousOffset,
          nextOffset: response.nextOffset,
          pageSize: response.pageSize,
          resultCount: response.resultCount,
        },
        ...normalize(response.results, schemas.arrayOfMedia),
      }),
      actionParams: {
        abortController,
        nonBlocking: true,
      },
    }
  );
};

export const getMediaById = (mediaId, accessToken) =>
  asyncAction(MEDIA_GET_BY_ID, mediaApi.load(mediaId, accessToken), {
    responseTransformer: (media) => normalize(media, schemas.media),
  });

export const deleteMediaById = (mediaId, accessToken) =>
  asyncAction(MEDIA_DELETE_BY_ID, mediaApi.deleteMedia(mediaId, accessToken), {
    responseTransformer: () => ({ id: mediaId }),
  });

export const patchMedia = (mediaId, changes, description, accessToken) =>
  asyncAction(
    MEDIA_UPDATE,
    mediaApi.patch(mediaId, changes, description, accessToken),
    {
      responseTransformer: (updatedMedia) =>
        normalize(updatedMedia, schemas.media),
    }
  );
