import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";

const styles = (theme) => ({
  banner: {
    width: "100%",
    height: 50,
    zIndex: 1200,
    background: theme.palette.secondary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 600,
  },
});

const FullWidthNotification = ({ classes, text }) => (
  <div className={classes.banner}>{text}</div>
);

FullWidthNotification.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
};

export default withStyles(styles)(FullWidthNotification);
