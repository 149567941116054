import { formEncode, requestJSON, requestText } from "./http";

const decorateSearchParameters = (filter) =>
  filter ? { __type: "WorkItem", ...filter } : { __type: "WorkItem" };

// with pagination
const search = (filter, abortController, accessToken) =>
  filter.customSearch
    ? requestJSON({
        path: `/work-items/custom-search/${filter.customSearch}`,
        method: "POST",
        data: decorateSearchParameters(filter),
        abortController,
        accessToken,
      })
    : requestJSON({
        path: "/work-items/search",
        method: "POST",
        data: decorateSearchParameters(filter),
        abortController,
        accessToken,
      });

// no pagination
const fullSearch = (filter, abortController, accessToken) =>
  requestJSON({
    path: "/work-items/full-search",
    method: "POST",
    data: decorateSearchParameters(filter),
    abortController,
    accessToken,
  });

const count = (filter, accessToken) =>
  requestJSON({
    path: "/work-items/count",
    method: "POST",
    data: decorateSearchParameters(filter),
    accessToken,
  });

const getMetric = (metric, accessToken) =>
  requestJSON({
    path: `/work-items/custom-search/${metric}/count`,
    accessToken,
  });

const listChildren = (workItemId, accessToken) =>
  requestJSON({ path: `/work-items/${workItemId}/children`, accessToken });

const listQuestions = (workItemId, accessToken) =>
  requestJSON({ path: `/work-items/${workItemId}/questions`, accessToken });

const addQuestion = (workItemId, question, accessToken) =>
  requestJSON({
    path: `/work-items/${workItemId}/questions`,
    method: "POST",
    data: question,
    accessToken,
  });

const removeQuestion = (workItemId, questionId, accessToken) =>
  requestJSON({
    path: `/work-items/${workItemId}/questions/${questionId}`,
    method: "DELETE",
    accessToken,
  });

const patchQuestion = (
  workItemId,
  questionId,
  changes,
  description,
  accessToken
) =>
  requestJSON({
    path: `/work-items/${workItemId}/questions/${questionId}`,
    method: "PATCH",
    data: {
      fields: Object.keys(changes),
      changes,
      description,
    },
    accessToken,
  });

const get = (workItemId, accessToken) =>
  requestJSON({ path: `/work-items/${workItemId}`, accessToken });

const getMeta = (workItemId, accessToken) =>
  requestJSON({ path: `/work-items/${workItemId}/meta`, accessToken });

const getByFriendlyId = (friendlyId, accessToken) =>
  requestJSON({ path: `/work-items/friendly-id/${friendlyId}`, accessToken });

const available = (workItemId, accessToken) =>
  requestJSON({ path: `/work-items/${workItemId}/available`, accessToken });

const getParent = (workItemId, accessToken) =>
  requestJSON({ path: `/work-items/${workItemId}/parent`, accessToken });

const getSlaStatistics = (workItemId, accessToken) =>
  requestJSON({
    path: `/work-items/${workItemId}/sla-statistics`,
    accessToken,
  });

const create = (workItem, accessToken) =>
  requestJSON({
    path: "/work-items",
    method: "POST",
    data: workItem,
    accessToken,
  });

const addChild = (parentWorkItemId, childWorkItem, accessToken) =>
  requestJSON({
    path: `/work-items/${parentWorkItemId}/children`,
    method: "POST",
    data: childWorkItem,
    accessToken,
  });

const patch = (workItemId, changes, description, accessToken) =>
  requestJSON({
    path: `/work-items/${workItemId}`,
    method: "PATCH",
    data: {
      fields: Object.keys(changes),
      changes,
      description,
    },
    accessToken,
  });

const remove = (workItemId, accessToken) =>
  requestJSON({
    path: `/work-items/${workItemId}`,
    method: "DELETE",
    accessToken,
  });

const updateDocuments = (workItemId, documents, accessToken) =>
  requestJSON({
    path: `/work-items/${workItemId}/documents`,
    method: "PUT",
    data: documents,
    accessToken,
  });

const deleteDocument = (workItemId, source, documentId, accessToken) =>
  requestJSON({
    path: `/work-items/${workItemId}/documents/${source}/${documentId}`,
    method: "DELETE",
    accessToken,
  });

const setDocumentFlags = (workItemId, document, flags, accessToken) =>
  requestJSON({
    path: `/work-items/${workItemId}/documents/${
      document.id
    }/flags?flags=${flags.join(",")}`,
    method: "POST",
    accessToken,
  });

const setStatus = (
  workItemId,
  status,
  outcome,
  completionDate,
  accessToken
) => {
  const params = {};
  params.status = status;
  if (outcome) {
    params.outcome = outcome;
  }
  if (completionDate) {
    params.completionDate = completionDate;
  }

  return requestJSON({
    path: `/work-items/${workItemId}/status?${formEncode(params)}`,
    method: "POST",
    accessToken,
  });
};

const listUsers = (workItemId, accessToken) =>
  requestJSON({
    path: `/work-items/${workItemId}/users`,
    accessToken,
  });

const setUsers = (workItemId, userIds, reason, accessToken) =>
  requestJSON({
    path: `/work-items/${workItemId}/users`,
    method: "POST",
    data: { userIds, comment: reason },
    accessToken,
  });

const removeUser = (workItemId, type, userId, accessToken) =>
  requestJSON({
    path: `/work-items/${workItemId}/users/${type}/${userId}`,
    method: "DELETE",
    accessToken,
  });

const addClaimAssessment = (workItemId, claimAssessment, accessToken) =>
  requestJSON({
    path: `/work-items/${workItemId}/claim-assessments`,
    method: "POST",
    data: claimAssessment,
    accessToken,
  });

const editClaimAssessment = (workItemId, index, changes, accessToken) =>
  requestJSON({
    path: `/work-items/${workItemId}/claim-assessments/${index}`,
    method: "PATCH",
    data: {
      fields: Object.keys(changes),
      changes,
      description: "Edited claim assessment",
    },
    accessToken,
  });

const removeEndorsement = (workItemId, endorsementId, accessToken) =>
  requestJSON({
    path: `/work-items/${workItemId}/endorsements/${endorsementId}`,
    method: "DELETE",
    accessToken,
  });

const setSimpleReminder = (workItemId, simpleReminder, accessToken) =>
  requestJSON({
    path: `/work-items/${workItemId}/simple-reminder`,
    method: "POST",
    data: simpleReminder,
    accessToken,
  });

const updateTimesheetEntry = (workItemId, hours, accessToken) =>
  requestJSON({
    path: `/work-items/${workItemId}/timesheet-entries?hours=${hours}`,
    method: "PUT",
    accessToken,
  });

const removeTimesheetEntry = (workItemId, accessToken) =>
  requestJSON({
    path: `/work-items/${workItemId}/timesheet-entries`,
    method: "DELETE",
    accessToken,
  });

const performAction = (workItemId, params, accessToken) =>
  requestJSON({
    path: `/work-items/${workItemId}/`,
    method: "POST",
    data: params,
    accessToken,
  });

const documentDownloadUrl = (
  workItemId,
  source,
  documentId,
  asAttachment,
  accessToken
) =>
  requestText({
    path: `/work-items/${workItemId}/documents/${source}/${documentId}${
      asAttachment ? "?asAttachment=true" : ""
    }`,
    accessToken,
  });

const bulkImport = (inputs, accessToken) =>
  requestJSON({
    path: "/work-items/bulk-import",
    method: "POST",
    data: inputs,
    accessToken,
  });

const fetchCombinedDocuments = (workItemId, accessToken) =>
  requestJSON({ path: `/work-items/${workItemId}/documents`, accessToken });

const downloadAllDocuments = (workItemId, accessToken) =>
  requestJSON({
    path: `/work-items/${workItemId}/documents/download-all`,
    method: "POST",
    accessToken,
  });

export default {
  search,
  count,
  fullSearch,
  getMetric,
  listChildren,
  listQuestions,
  addQuestion,
  removeQuestion,
  patchQuestion,
  get,
  getMeta,
  getByFriendlyId,
  available,
  getParent,
  getSlaStatistics,
  create,
  addChild,
  patch,
  remove,
  updateDocuments,
  deleteDocument,
  setDocumentFlags,
  listUsers,
  setUsers,
  removeUser,
  setStatus,
  addClaimAssessment,
  editClaimAssessment,
  removeEndorsement,
  setSimpleReminder,
  updateTimesheetEntry,
  removeTimesheetEntry,
  performAction,
  documentDownloadUrl,
  bulkImport,
  fetchCombinedDocuments,
  downloadAllDocuments,
};
