import { Tooltip } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { getReferenceDataType } from "../../reducers";
import ColorScoreChip from "../ColorScoreChip";

const OutcomeChip = ({ outcome, defaultValue, workItemOutcomes }) => {
  if (!outcome) {
    return null;
  }

  const outcomeDef = outcome && workItemOutcomes.values[outcome];

  return (
    <Tooltip title={outcomeDef?.description}>
      <div>
        <ColorScoreChip
          label={outcomeDef?.description}
          score={outcomeDef?.props.score}
          defaultValue={defaultValue}
        />
      </div>
    </Tooltip>
  );
};

OutcomeChip.propTypes = {
  outcome: PropTypes.string,
  defaultValue: PropTypes.string,
  workItemOutcomes: PropTypes.object.isRequired,
};

OutcomeChip.defaultProps = {
  outcome: null,
  defaultValue: "-",
};

const mapStateToProps = (state) => ({
  workItemOutcomes: getReferenceDataType(state, "WorkItemOutcome"),
});

export default connect(mapStateToProps)(OutcomeChip);
