import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { scrollToFirstInvalidInput } from "../../../util/formHelpers";
import FormError from "../../common/FormError";
import MediaDetailFields from "./MediaDetailFields";

export const EDIT_MEDIA_FORM_NAME = "EDIT_MEDIA_FORM_NAME";

const EditMediaForm = ({ error, change, touch, fieldLabels }) => (
  <form autoComplete="off">
    {error && <FormError errorMessage={error} />}
    <MediaDetailFields
      fieldLabels={fieldLabels}
      editMode
      change={change}
      touch={touch}
      formName={EDIT_MEDIA_FORM_NAME}
      data-cy={fieldLabels}
    />
  </form>
);

EditMediaForm.propTypes = {
  error: PropTypes.string,
  change: PropTypes.func.isRequired,
  touch: PropTypes.func.isRequired,
  fieldLabels: PropTypes.object.isRequired,
};

EditMediaForm.defaultProps = {
  error: null,
};

const mapStateToProps = () => ({});

const form = reduxForm({
  form: EDIT_MEDIA_FORM_NAME,
  onSubmitFail: (errors) => scrollToFirstInvalidInput(errors),
});
const reduxData = connect(mapStateToProps);
export default compose(form, reduxData)(EditMediaForm);
