import * as PropTypes from "prop-types";
import React from "react";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import dates from "../../../util/dates";

const WrappedDateTimePicker = ({
  input,
  storeDatesAsString,
  helperText,
  margin,
  meta: { touched, error, invalid, active },
  ...custom
}) => {
  const rest = { ...custom };

  if (touched && error) {
    rest.error = true;
    rest.helperText = error;
  } else if ((!touched || !invalid) && helperText) {
    rest.helperText = helperText;
  }

  return (
    <KeyboardDateTimePicker
      name={`position-${input.name}`}
      value={
        storeDatesAsString
          ? dates.parseTimestamp(input.value) || null
          : input.value
      }
      format="DD/MM/YYYY hh:mm"
      placeholder={active ? "dd/mm/yyyy hh:mm" : null}
      autoOk
      clearable
      onChange={(date) =>
        input.onChange(
          storeDatesAsString ? dates.serializeTimestamp(date) : date
        )
      }
      onFocus={input.onFocus}
      margin={margin}
      {...rest}
    />
  );
};

WrappedDateTimePicker.propTypes = {
  input: PropTypes.object.isRequired,
  helperText: PropTypes.any,
  storeDatesAsString: PropTypes.bool,
  meta: PropTypes.object.isRequired,
  margin: PropTypes.string,
};

WrappedDateTimePicker.defaultProps = {
  helperText: "",
  storeDatesAsString: true,
  margin: "dense",
};

export default WrappedDateTimePicker;
