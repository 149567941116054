import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles((theme) => ({
  warning: {
    margin: `${theme.spacing(3)}px 0`,
    display: "block",
    color: theme.palette.warning.main,
  },
}));

const RequiredEndorsementsWarning = ({
  workItem,
  warnForRequiredEndorsements,
  getEndorsementDescription,
}) => {
  const classes = useStyles();

  const workItemEndorsements = workItem.endorsements.map(({ type }) => type);
  const warnings = warnForRequiredEndorsements
    .filter(
      (endorsement) =>
        !workItemEndorsements.find(
          (workItemEndorsement) => workItemEndorsement === endorsement
        )
    )
    .map(getEndorsementDescription);

  if (!warnings.length) {
    return null;
  }

  return (
    <Typography className={classes.warning} variant="body2" display="block">
      Warning: {warnings.join(", ")}{" "}
      {`${warnings.length > 1 ? "endorsements" : "endorsement"}`} missing.
    </Typography>
  );
};

RequiredEndorsementsWarning.propTypes = {
  workItem: PropTypes.any.isRequired,
  getEndorsementDescription: PropTypes.func.isRequired,
  warnForRequiredEndorsements: PropTypes.array.isRequired,
};

export default RequiredEndorsementsWarning;
