import { requestJSON } from "./http";

const findByWorkItemId = (workItemId, accessToken) =>
  requestJSON({
    path: `/work-item-associations?workItemId=${workItemId}`,
    accessToken,
  });

const create = (workItemAssociation, accessToken) =>
  requestJSON({
    path: "/work-item-associations",
    method: "POST",
    data: workItemAssociation,
    accessToken,
  });

const remove = (id, accessToken) =>
  requestJSON({
    path: `/work-item-associations/${id}`,
    method: "DELETE",
    accessToken,
  });

export default {
  findByWorkItemId,
  create,
  remove,
};
