import PropTypes from "prop-types";
import React from "react";
import FormCard from "../common/FormCard";
import RiskDetailsFieldsReadOnly from "./RiskDetailsFieldsReadOnly";
import EditRiskDetailsForm, {
  EDIT_RISK_DETAILS_FORM_NAME,
} from "../forms/risk/EditRiskDetailsForm";

const RiskDetailsCard = ({ className, risk, onChange, fieldLabels }) => {
  const initialValues = {
    ...risk,
  };

  return (
    <>
      <FormCard
        className={className}
        data-cy="editRiskForm"
        title="Risk details"
        formTitle="Edit risk"
        readOnlyView={() => (
          <RiskDetailsFieldsReadOnly risk={risk} fieldLabels={fieldLabels} />
        )}
        formComponent={EditRiskDetailsForm}
        formName={EDIT_RISK_DETAILS_FORM_NAME}
        onSave={onChange}
        initialValues={initialValues}
        formProps={{
          maxWidth: "md",
          onCreateForm: false,
          fieldLabels,
        }}
      />
    </>
  );
};

RiskDetailsCard.propTypes = {
  className: PropTypes.string,
  fieldLabels: PropTypes.object.isRequired,
  risk: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

RiskDetailsCard.defaultProps = {
  className: null,
};

export default RiskDetailsCard;
