import { useAuth0 } from "@auth0/auth0-react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  clearActiveWorkItem,
  createWorkItem,
} from "../../../actions/workItems";
import BreadcrumbLink from "../../../components/common/BreadcrumbLink";
import Container from "../../../components/common/Container";
import HeaderBar from "../../../components/common/HeaderBar";
import PageHeading from "../../../components/common/PageHeading";
import { useDraftForm } from "../../../components/draft/useDraftForm";
import { getEntityRelationshipInitialValues } from "../../../components/forms/business/EntityRelationshipFields";
import FormWrapper from "../../../components/forms/FormWrapper";
import CreateWorkItemForm, {
  CREATE_WORK_ITEM_FORM_NAME,
} from "../../../components/workitem/CreateWorkItemForm";
import CreateWorkItemSuccess from "../../../components/workitem/CreateWorkItemSuccess";
import {
  getLoggedInUser,
  getReferenceDataType,
  getRouterLocations,
  getWorkItem,
  isLoading,
} from "../../../reducers";
import {
  dashboardPath,
  getWorkItemLink,
  workItemSchedulePath,
  workItemSchedulesPath,
} from "../../../routes/routeUtils";
import logger from "../../../util/logger";
import {
  appliesByCategory,
  appliesByParentType,
  appliesByParties,
} from "../../../util/workItemTypeUtils";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginBottom: "4rem",
  },
  container: {
    marginTop: theme.spacing(2),
  },
});

const autoPopulateRelationshipTypes = [
  "PROMOTER",
  "TRUSTEE",
  "RESPONSIBLE_ENTITY",
  "KIWISAVER_MANAGER",
];

const Type = ({
  classes,
  loggedInUser,
  types,
  workItem,
  match: {
    params: { type },
  },
  routerLocations,
  history,
  onClearActiveWorkItem,
  onCreateWorkItem,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [draftValues, setDraftValues] = useState(null);
  const [initialValues, setInitialValues] = useState(null);
  const [hasDraft, activeDraft, saveDraft, removeDraft] =
    useDraftForm("WorkItem");

  const typeDisplay = type ? types.values[type].description : "Work item";
  const lastRoute = routerLocations[1];

  const breadcrumbs = [];

  if (lastRoute) {
    if (lastRoute.location.pathname === workItemSchedulesPath()) {
      breadcrumbs.push({
        link: lastRoute.location.pathname,
        label: "Compliance calendar",
      });
    } else if (
      lastRoute.location.pathname === "/work-items" ||
      lastRoute.location.pathname.startsWith("/work-items/view")
    ) {
      breadcrumbs.push({
        link: lastRoute.location.pathname,
        label: "Work items",
      });
    }
  }

  if (breadcrumbs.length === 0) {
    const formValues = draftValues || initialValues;
    const auditReport = formValues?.auditReport;
    const associatedControl = formValues?.associatedControl;
    if (auditReport) {
      breadcrumbs.push({ link: "/audits", label: "Audits" });
      breadcrumbs.push({
        link: `/audits/${auditReport.id}`,
        label: auditReport?.title || "Audit",
      });
    } else if (associatedControl) {
      breadcrumbs.push({ link: "/controls", label: "Controls" });
      breadcrumbs.push({
        link: `/controls/${associatedControl.id}`,
        label: associatedControl?.title || "Control",
      });
    } else {
      breadcrumbs.push({ link: dashboardPath(), label: "Dashboard" });
    }
  }

  useEffect(() => {
    onClearActiveWorkItem();
  }, []);

  useEffect(() => {
    if (activeDraft) {
      const valuesFromDraft = JSON.parse(activeDraft.draftData);
      valuesFromDraft.documents = activeDraft.documents;

      logger.debug("setting values from draft", valuesFromDraft);
      setDraftValues(valuesFromDraft);
    }
  }, [activeDraft]);

  const calcInitValues = (accessToken) => {
    if (history.location.state && history.location.state.initValues) {
      logger.debug("state initValues: ", history.location.state.initValues);
    }

    const initValues = {
      type,
      ...history.location?.state?.initValues,
    };

    const { definition } = types.values[type].props;

    const components = definition.components
      .filter((component) => appliesByCategory({ category: null }, component))
      .filter((component) =>
        appliesByParentType({ hasParent: false, parent: null }, component)
      )
      .filter((component) =>
        appliesByParties({ users: [] }, loggedInUser, component)
      )
      .filter((component) => component.atCreation);

    // Set the default values as provided by the component
    components.forEach((component) =>
      component.fieldCustomisations
        .filter(
          (customisation) =>
            customisation.categories.length === 0 ||
            appliesByCategory({ category: null }, customisation)
        )
        .filter((customisation) => customisation.defaultValue !== undefined)
        .forEach((customisation) => {
          initValues[customisation.field] = customisation.defaultValue;
        })
    );

    if (
      components.filter((comp) => comp.component === "ENTITY_RELATIONSHIP")
        .length > 0
    ) {
      if (initValues.entityRelationship) {
        logger.debug(
          "setting initial values to (from locationState)",
          initValues
        );
        setInitialValues(initValues);
      } else {
        getEntityRelationshipInitialValues(
          loggedInUser,
          autoPopulateRelationshipTypes,
          accessToken
        ).then((entityRelationship) => {
          initValues.entityRelationship = entityRelationship;

          logger.debug(
            "async setting initial values to (entityRelationship) ",
            initValues
          );
          setInitialValues(initValues);
        });
      }
    } else {
      logger.debug(
        "setting initial values to (no entityRelationship)",
        initValues
      );
      setInitialValues(initValues);
    }
  };

  useEffect(() => {
    if (!hasDraft) {
      getAccessTokenSilently().then(calcInitValues);
    }
  }, [type]);

  const handleSubmission = async (values) => {
    const accessToken = await getAccessTokenSilently();
    logger.debug(`submitting ${type} with values`, values);
    return onCreateWorkItem(values, accessToken).then(() => {
      if (activeDraft) {
        logger.debug(
          `deleting draft ${activeDraft.id} now that work item is created`
        );
        removeDraft();
      }
    });
  };

  const getLinkToNewWorkItem = () =>
    workItem.template
      ? workItemSchedulePath(workItem.workItemSchedule.id)
      : getWorkItemLink(workItem);

  return (
    <div className={classes.root}>
      <HeaderBar narrow>
        <BreadcrumbLink
          to={breadcrumbs.map((bc) => bc.link)}
          label={breadcrumbs.map((bc) => bc.label)}
          includeArrow
        />
        <PageHeading heading={`${hasDraft ? "Draft" : "New"} ${typeDisplay}`} />
      </HeaderBar>
      <Container narrow className={classes.container} data-cy="workItem">
        {workItem && (
          <CreateWorkItemSuccess
            data-cy="createWorkItems"
            createdWorkItem={workItem}
            workItemTypes={types}
            originLink={breadcrumbs[breadcrumbs.length - 1].link}
            originLinkLabel={`Return to ${
              breadcrumbs[breadcrumbs.length - 1].label
            }`}
            itemLink={getLinkToNewWorkItem()}
            template={workItem.template}
          />
        )}
        {!workItem && type && (draftValues || initialValues) && (
          <FormWrapper
            data-cy="create"
            submitButtonText="Create"
            type={type}
            formComponent={CreateWorkItemForm}
            formName={CREATE_WORK_ITEM_FORM_NAME}
            onSubmit={handleSubmission}
            onCancel={() =>
              history.push(breadcrumbs[breadcrumbs.length - 1].link)
            }
            onSaveDraft={saveDraft}
            initialValues={draftValues || initialValues}
            autoSaveDraft
          />
        )}
      </Container>
    </div>
  );
};

Type.propTypes = {
  history: PropTypes.object.isRequired,
  loggedInUser: PropTypes.object,
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  types: PropTypes.object.isRequired,
  workItem: PropTypes.object,
  onCreateWorkItem: PropTypes.func.isRequired,
  onClearActiveWorkItem: PropTypes.func.isRequired,
  routerLocations: PropTypes.array.isRequired,
};

Type.defaultProps = {
  loggedInUser: null,
  workItem: null,
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
  types: getReferenceDataType(state, "WorkItemType"),
  workItem: getWorkItem(state),
  routerLocations: getRouterLocations(state),
  loading: isLoading(state),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    onCreateWorkItem: createWorkItem,
    onClearActiveWorkItem: clearActiveWorkItem,
  })
)(Type);
