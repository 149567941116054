import React from "react";
import ErrorIcon from "@material-ui/icons/Error";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  error: {
    width: "100%",
    color: theme.palette.error.main,
    border: "1px solid",
    borderRadius: "3px",
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
  },
  text: {
    marginLeft: "5px",
    fontWeight: "bold",
  },
});

const FormError = ({ classes, errorMessage }) => (
  <div className={classes.error} data-cy="error">
    <ErrorIcon />
    <span className={classes.text}>{errorMessage}</span>
  </div>
);

FormError.propTypes = {
  classes: PropTypes.object.isRequired,
  errorMessage: PropTypes.string.isRequired,
};

export default withStyles(styles)(FormError);
