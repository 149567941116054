import { requestJSON } from "./http";
import { UserGroup } from "../../model/UserGroup";

const decorateSearchParameters = (filter: Filters) =>
  filter ? { __type: "UserGroup", ...filter } : { __type: "UserGroup" };

interface Filters {
  excludeGroupFilters: boolean;
  limit: number;
  offset: number;
  orderByField: string;
  types: string[];
}

interface SearchResults {
  results: UserGroup[];
}

const create = (
  userGroup: UserGroup,
  accessToken: string
): Promise<UserGroup> =>
  requestJSON({
    path: "/user-groups",
    method: "POST",
    data: userGroup,
    accessToken,
  });

// with pagination
const search = (
  filter: Filters,
  abortController: AbortController,
  accessToken: string
): Promise<SearchResults> =>
  requestJSON({
    path: "/user-groups/search",
    method: "POST",
    data: decorateSearchParameters(filter),
    abortController,
    accessToken,
  });

const getById = (id: string, accessToken: string): Promise<UserGroup> =>
  requestJSON({
    path: `/user-groups/${id}`,
    accessToken,
  });

const patch = (
  id: string,
  changes: Record<string, unknown>,
  description: string,
  accessToken: string
): Promise<UserGroup> =>
  requestJSON({
    path: `/user-groups/${id}`,
    method: "PATCH",
    data: {
      fields: Object.keys(changes),
      changes,
      description,
    },
    accessToken,
  });

export default {
  create,
  search,
  getById,
  patch,
};
