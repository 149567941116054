import { useTenant } from "@certane/arcadia-web-components";
import MenuItem from "@material-ui/core/MenuItem";
import _ from "lodash";
import React, { ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RefDataItem } from "../../model/ReferenceData";
import { getLoggedInUser, getReferenceDataType } from "../../reducers";
import FloatingActionButton from "../common/FloatingActionButton";
import getFilteredTypes, { creatableWorkItemTypes } from "./getFilteredTypes";

const WorkItemFloatingActionButton: React.FC = () => {
  const history = useHistory();
  const { tenant } = useTenant();
  const loggedInUser = useSelector(getLoggedInUser);
  const workItemTypes = useSelector((state) =>
    getReferenceDataType(state, "WorkItemType")
  );
  const [menuItems, setMenuItems] = useState<ReactElement[]>([]);

  const additionalFilter = (workItemType: RefDataItem): boolean => {
    const creatableByUser =
      loggedInUser &&
      _.intersection(
        workItemType.props.definition.creatableBy,
        loggedInUser.roles
      ).length > 0;
    const notSubType = !workItemType.props.definition.subType;
    return creatableByUser && notSubType;
  };

  useEffect(() => {
    if (!workItemTypes || !loggedInUser || !tenant) return;
    const filteredTypes = getFilteredTypes(
      workItemTypes,
      loggedInUser,
      tenant,
      { workItemTypes: creatableWorkItemTypes, additionalFilter }
    );

    const items = filteredTypes?.ids.map((id) => {
      const type = filteredTypes.values[id];
      return (
        <MenuItem key={id} onClick={() => newWorkItem(type)}>
          {`New ${type.description.toLowerCase()}`}
        </MenuItem>
      );
    });

    setMenuItems(items);
  }, [workItemTypes, loggedInUser]);

  const newWorkItem = (workItemType: RefDataItem) => {
    history.push(`/work-items/new/${workItemType.id}`);
  };

  return <FloatingActionButton>{menuItems}</FloatingActionButton>;
};

export default WorkItemFloatingActionButton;
