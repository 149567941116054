import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as _ from "lodash";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import Accordion from "@material-ui/core/Accordion";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import BasicFileList from "./BasicFileList";
import { getReferenceDataDescription } from "../../../reducers";

const useStyles = makeStyles((theme) => ({
  expansionPanelSummary: {
    color: theme.palette.grey[600],
  },
  list: {
    width: "100%",
  },
}));

const FlaggedFileList = ({
  documents,
  onFileClick,
  getDocumentFlagDescription,
}) => {
  const classes = useStyles();

  const groupByFlag = (docs) => {
    const byFlag = {};
    _.forEach(docs, (document) => {
      _.forEach(document.flags, (flag) => {
        if (!byFlag[flag]) {
          byFlag[flag] = [];
        }
        byFlag[flag].push(document);
      });
    });
    return byFlag;
  };

  const groupedByFlag = groupByFlag(documents);
  const flags = _.sortBy(_.keys(groupedByFlag));

  if (_.isEmpty(flags)) {
    return null;
  }

  return (
    <Accordion elevation={0}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} data-cy="expand">
        <Typography
          variant="body1"
          className={classes.expansionPanelSummary}
          data-cy="flaggedFiles"
        >
          Flagged files
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List dense className={classes.list}>
          {flags.map((flag) => (
            <Fragment key={flag}>
              <ListSubheader
                disableSticky
                data-cy={getDocumentFlagDescription(flag)}
              >
                {getDocumentFlagDescription(flag)}
              </ListSubheader>
              <BasicFileList
                className={classes.list}
                documents={groupedByFlag[flag]}
                primaryText={(file) =>
                  file.namespace
                    ? `${file.namespace} / ${file.fileName}`
                    : file.fileName
                }
                secondaryText={() => null}
                primaryAction={(file) =>
                  onFileClick ? () => onFileClick(file) : null
                }
              />
            </Fragment>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

FlaggedFileList.propTypes = {
  documents: PropTypes.array.isRequired,
  onFileClick: PropTypes.func.isRequired,
  // redux
  getDocumentFlagDescription: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  getDocumentFlagDescription: (id) =>
    getReferenceDataDescription(state, "DocumentFlag", id, "-"),
});

export default connect(mapStateToProps)(FlaggedFileList);
