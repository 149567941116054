import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import Remove from "@material-ui/icons/Remove";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field } from "redux-form";
import { required } from "redux-form-validators";
import { getReferenceDataType } from "../../../../reducers";
import { getRefDataMenuItems } from "../../../forms/menuItems";
import WrappedDatePicker from "../../../forms/wrapper/WrappedDatePicker";
import WrappedSelect from "../../../forms/wrapper/WrappedSelect";

const styles = () => ({
  root: {
    width: "100%",
  },
  item: {
    paddingLeft: "0",
    paddingTop: "0",
    paddingBottom: "0",
  },
});

class IncidentAssessmentReportTos extends Component {
  add = () => {
    this.props.fields.push({});
  };

  remove = (index) => {
    this.props.fields.remove(index);
  };

  render() {
    const { fields, classes, reportTos } = this.props;
    return (
      <>
        {fields.map((reportTo, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <List dense key={index} className={classes.root}>
            <ListItem dense className={classes.item}>
              <ListItemText>
                <Grid container spacing={1}>
                  <Grid item xs={3}>
                    <Field
                      name={`${reportTo}.reportTo`}
                      component={WrappedSelect}
                      label="Report to"
                      validate={required({ msg: "Value is required" })}
                      fullWidth
                      margin="none"
                      required
                    >
                      {getRefDataMenuItems(reportTos)}
                    </Field>
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      name={`${reportTo}.dueDate`}
                      component={WrappedDatePicker}
                      label="Due date"
                      validate={required({ msg: "Value is required" })}
                      fullWidth
                      margin="none"
                      required
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      name={`${reportTo}.reportedDate`}
                      component={WrappedDatePicker}
                      label="Submitted date"
                      margin="none"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      name={`${reportTo}.confirmationDate`}
                      component={WrappedDatePicker}
                      label="Confirmation date"
                      margin="none"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </ListItemText>
              <ListItemSecondaryAction>
                <IconButton
                  color="primary"
                  aria-label="Remove"
                  onClick={() => this.remove(index)}
                >
                  <Remove />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        ))}
        <div>
          <Button
            size="small"
            color="primary"
            aria-label="Add"
            onClick={this.add}
          >
            Add reportable to
          </Button>
        </div>
      </>
    );
  }
}

IncidentAssessmentReportTos.propTypes = {
  fields: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  reportTos: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  reportTos: getReferenceDataType(state, "Regulator"),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(IncidentAssessmentReportTos);
