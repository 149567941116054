import { useAuth0 } from "@auth0/auth0-react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  createAssociation,
  deleteAssociation,
  getAssociationsByWorkItemId,
} from "../../../actions/workItemAssociations";
import {
  getLabels,
  getReferenceDataDescription,
  getReferenceDataType,
  getWorkItemAssociations,
} from "../../../reducers";
import { getWorkItemLink } from "../../../routes/routeUtils";
import dates from "../../../util/dates";
import { typeParentIcon } from "../../../util/icons";
import { getResolvedDefinition } from "../../../util/workItemTypeUtils";
import GridListing from "../../common/GridListing";
import WorkItemStatus from "../components/overview/WorkItemStatus";
import WorkItemBlob from "../WorkItemBlob";
import WorkItemAssociationReasonDialog from "./WorkItemAssociationReasonDialog";
import WorkItemAssociationSearchDialog from "./WorkItemAssociationSearchDialog";
import { includeTenantParam } from "@certane/arcadia-web-components";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  cardHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  cardContent: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  childParentIcon: {
    fontSize: "inherit",
    marginRight: "5px",
    marginTop: "-3px",
    verticalAlign: "middle",
  },
});

const WorkItemAssociations = ({
  classes,
  workItem,
  postUpdate,
  workItemLabels,
  associationLabels,
  workItemTypes,
  workItemAssociations,
  readonly,
  associationTypes,
  localGetReferenceDataDescription,
  localGetAssociationsByWorkItemId,
  localCreateAssociation,
  localDeleteAssociation,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [searchDialogOpen, setSearchDialogOpen] = useState(false);
  const [linkCandidate, setLinkCandidate] = useState(null);

  const loadAssociations = async () => {
    if (workItem) {
      const accessToken = await getAccessTokenSilently();
      localGetAssociationsByWorkItemId(workItem.id, accessToken);
    }
  };

  useEffect(() => {
    loadAssociations();
  }, [workItem]);

  const addAssociation = async (otherWorkItem, reason) => {
    const accessToken = await getAccessTokenSilently();
    localCreateAssociation(
      {
        workItemA: {
          id: workItem.id,
        },
        workItemB: {
          id: otherWorkItem.id,
        },
        associationType: "RELATES_TO",
        reason,
      },
      accessToken
    ).then(() => {
      setSearchDialogOpen(false);
      setLinkCandidate(null);
      postUpdate();
    });
  };

  const removeAssociation = async (associationId) => {
    const accessToken = await getAccessTokenSilently();
    localDeleteAssociation(associationId, accessToken).then(() => postUpdate());
  };

  const getAssociationTypeDescription = (association) => {
    if (association.workItemA.id === workItem.id) {
      return associationTypes.values[association.associationType].description;
    }
    return associationTypes.values[association.associationType].props
      .inverseDescription;
  };

  const openWorkItem = (otherWorkItemRef) => {
    window.open(
      includeTenantParam(getWorkItemLink(otherWorkItemRef)),
      "_blank"
    );
  };

  const getOtherWorkItemRef = (association) => {
    return association.workItemA.id === workItem.id
      ? association.workItemB
      : association.workItemA;
  };

  const TypeParentIcon = typeParentIcon();

  if (!workItem) return null;

  return (
    <Card className={classes.root} elevation={0}>
      <WorkItemAssociationSearchDialog
        workItem={workItem}
        workItemAssociations={workItemAssociations}
        open={searchDialogOpen}
        onClose={() => setSearchDialogOpen(false)}
        onAdd={(candidate) => {
          setSearchDialogOpen(false);
          setLinkCandidate(candidate);
        }}
      />
      <WorkItemAssociationReasonDialog
        workItem={linkCandidate}
        open={!!linkCandidate}
        onCancel={() => setLinkCandidate(null)}
        onBack={() => {
          setSearchDialogOpen(true);
          setLinkCandidate(null);
        }}
        onSubmit={(reason) => addAssociation(linkCandidate, reason)}
      />
      <CardHeader
        className={classes.cardHeader}
        title={"Associations"}
        action={
          !readonly && (
            <IconButton
              title="Add association"
              aria-label="Add association"
              aria-haspopup="true"
              onClick={() => setSearchDialogOpen(true)}
              data-cy="associations"
            >
              <AddIcon />
            </IconButton>
          )
        }
      />
      <CardContent className={classes.cardContent}>
        <GridListing
          sortedData={workItemAssociations}
          loading={false}
          sortBy={{}}
          updateSort={() => {
            // do nothing.
          }}
          noItemsText="Get started by adding an association"
          dense={false}
          onClick={(association) =>
            openWorkItem(getOtherWorkItemRef(association))
          }
          action={(association) => {
            return !readonly ? (
              <IconButton
                aria-label="Remove"
                onClick={() => removeAssociation(association.id)}
                title="Remove"
                size={"small"}
                disabled={!association.deletableByUser}
              >
                <DeleteIcon />
              </IconButton>
            ) : null;
          }}
          columns={[
            {
              label: workItemLabels.labels.type,
              name: "type",
              size: 2,
              sortable: false,
              render: (association) => {
                const workItem = getOtherWorkItemRef(association);
                const { definition } =
                  workItemTypes.values[workItem.type].props;
                const { name } = definition;
                const category = localGetReferenceDataDescription(
                  "WorkItemCategory",
                  workItem.category
                );
                return (
                  <div className={classes.box}>
                    <Typography variant="subtitle1">
                      {getAssociationTypeDescription(association)}
                    </Typography>
                    <WorkItemBlob type={workItem.type} description={name} />
                    <Typography
                      className={classes.nowrap}
                      title={category}
                      variant="caption"
                      display="block"
                      data-cy={category}
                    >
                      {category}
                      {workItem.hasParent && (
                        <div className={classes.sub}>
                          <TypeParentIcon
                            title="Parent-item"
                            className={classes.childParentIcon}
                          />
                          {
                            workItemTypes.values[workItem.parent.type].props
                              .definition.name
                          }
                        </div>
                      )}
                    </Typography>
                  </div>
                );
              },
            },
            {
              label: workItemLabels.labels.title,
              name: "title",
              size: 3,
              render: (association) => {
                const workItem = getOtherWorkItemRef(association);
                return (
                  <>
                    <Typography
                      className={classes.nowrap}
                      title={workItem.title}
                    >
                      {workItem.title}
                    </Typography>
                    <Typography
                      className={classes.nowrap}
                      title={workItem.friendlyId}
                      variant="caption"
                    >
                      {workItem.friendlyId}
                    </Typography>
                  </>
                );
              },
            },
            {
              label: associationLabels.labels.reason,
              name: "reason",
              size: 3,
              sortable: false,
              render: (association) => (
                <Typography title={association.reason} variant="caption">
                  {association.reason}
                </Typography>
              ),
            },
            {
              label: workItemLabels.labels.status,
              name: "status",
              size: 2,
              sortable: false,
              render: (association) => {
                const workItem = getOtherWorkItemRef(association);
                const definition = getResolvedDefinition(
                  workItemTypes,
                  workItem.type,
                  workItem.parent?.type
                );
                return (
                  <>
                    <WorkItemStatus
                      workItem={workItem}
                      readonly
                      definition={definition}
                    />
                    <span>
                      {localGetReferenceDataDescription(
                        "WorkItemOutcome",
                        workItem.outcome,
                        ""
                      )}
                    </span>
                  </>
                );
              },
            },
            {
              label: workItemLabels.labels.dueDate,
              name: "dueDate",
              size: 2,
              sortable: false,
              render: (association) => {
                const dueDate = dates.parseDate(
                  getOtherWorkItemRef(association).dueDate
                );

                return <div>{dates.formatDateLong(dueDate)}</div>;
              },
            },
          ]}
        />
      </CardContent>
    </Card>
  );
};

WorkItemAssociations.propTypes = {
  classes: PropTypes.object.isRequired,
  workItem: PropTypes.object,
  readonly: PropTypes.bool,
  postUpdate: PropTypes.func.isRequired,

  // redux
  workItemLabels: PropTypes.object.isRequired,
  associationLabels: PropTypes.object.isRequired,
  workItemTypes: PropTypes.object.isRequired,
  associationTypes: PropTypes.object.isRequired,
  workItemAssociations: PropTypes.array.isRequired,
  localGetReferenceDataDescription: PropTypes.func.isRequired,
  localGetAssociationsByWorkItemId: PropTypes.func.isRequired,
  localCreateAssociation: PropTypes.func.isRequired,
  localDeleteAssociation: PropTypes.func.isRequired,
};

WorkItemAssociations.defaultProps = {
  workItem: null,
  readonly: false,
};

const mapStateToProps = (state) => ({
  workItemLabels: getLabels(state).WorkItem,
  associationLabels: getLabels(state).WorkItemAssociation,
  workItemTypes: getReferenceDataType(state, "WorkItemType"),
  associationTypes: getReferenceDataType(state, "AssociationType"),
  workItemAssociations: getWorkItemAssociations(state),
  localGetReferenceDataDescription: (type, id, defaultValue) =>
    getReferenceDataDescription(state, type, id, defaultValue),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    localGetAssociationsByWorkItemId: getAssociationsByWorkItemId,
    localCreateAssociation: createAssociation,
    localDeleteAssociation: deleteAssociation,
  })
)(WorkItemAssociations);
