import { useAuth0 } from "@auth0/auth0-react";
import { Typography } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useReducer, useState } from "react";
import { Link } from "react-router-dom";
import uuid from "uuid/v4";
import { includeTenantParam } from "@certane/arcadia-web-components";
import api from "../../../../services/api";
import dates from "../../../../util/dates";
import FileIcon from "../../FileIcon";
import mediaApi from "../../../../services/api/media";

const useStyles = makeStyles((theme) => ({
  toggleText: {
    color: theme.palette.text.secondary,
  },
  chip: {
    backgroundColor: theme.palette.swatch.grey5,
  },
}));

const isNotEmpty = (arr) => arr.length > 0;
const startsWithMetropolis = (str) =>
  str.startsWith("metropolisLinkedDocuments");

const containsMetropolisChange = (entry) => {
  const results = entry.fieldChanges.filter((item) =>
    isNotEmpty(item.path.filter(startsWithMetropolis))
  );

  return isNotEmpty(results);
};

const containsMetropolisLinkedDocuments = (entry) =>
  _.has(entry, "fieldChanges") && containsMetropolisChange(entry);

const buildUIFriendlyChange = (item, documents) => {
  const data = item.changeType === "REMOVAL" ? item.before[0] : item.after[0];
  const mediaId = data.value;
  const documentId = item.path[0]
    .replace("metropolisLinkedDocuments[", "")
    .replace("]", "");
  const document = documents.find((doc) => doc.id === documentId);

  return {
    id: uuid(),
    removed: item.changeType === "REMOVAL",
    document,
    mediaId,
  };
};

const useMetropolisLinks = (entry, documents) => {
  const [hasMetropolisDocuments, setHasMetropolisDocuments] = useState(false);
  const [metropolisDocuments, setMetropolisDocuments] = useState([]);

  useEffect(() => {
    if (containsMetropolisLinkedDocuments(entry)) {
      const rawChanges = entry.fieldChanges.filter((change) =>
        startsWithMetropolis(change.path[0])
      );
      setHasMetropolisDocuments(true);
      setMetropolisDocuments(
        rawChanges.map((change) => buildUIFriendlyChange(change, documents))
      );
    }
  }, [entry]);

  return [hasMetropolisDocuments, metropolisDocuments];
};

const onFileClick = (source, document, workItemId, accessToken) => {
  api.workItems
    .documentDownloadUrl(workItemId, source, document.id, false, accessToken)
    .then((location) => window.open(location, "_blank"));
};

const MetropolisLinks = ({ entry, workItem }) => {
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();
  const [hasMetropolisLinks, metropolisLinks] = useMetropolisLinks(
    entry,
    workItem.documents
  );
  const [media, setMedia] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {}
  );

  useEffect(() => {
    if (hasMetropolisLinks) {
      (async () => {
        const accessToken = await getAccessTokenSilently();
        metropolisLinks.forEach((metropolisLink) =>
          mediaApi
            .load(metropolisLink.mediaId, accessToken)
            .then((mediaItem) => setMedia({ [mediaItem.id]: mediaItem }))
        );
      })();
    }
  }, [hasMetropolisLinks]);

  const getTitle = (doc) => {
    if (doc.removed) {
      return `Uploaded by ${doc.createdByName} on ${dates.formatTimestamp(
        doc.uploaded
      )}\nRemoved by ${doc.deletedByName} on ${dates.formatTimestamp(
        doc.deleted
      )}`;
    }
    return `Uploaded by ${doc.createdByName} on ${dates.formatTimestamp(
      doc.uploaded
    )}`;
  };

  const handleFileClick = async (source, document) => {
    const accessToken = await getAccessTokenSilently();
    onFileClick(source, document, workItem.id, accessToken);
  };

  const getMediaName = (mediaId) => (media[mediaId] ? media[mediaId].name : "");

  return (
    <>
      {hasMetropolisLinks && (
        <div>
          {metropolisLinks.map((metropolisLink) => (
            <Grid
              key={metropolisLink.id}
              container
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <Chip
                  className={classes.chip}
                  clickable={!metropolisLink.document.removed}
                  title={getTitle(metropolisLink.document)}
                  onClick={
                    metropolisLink.document.removed
                      ? () => {
                          // do nothing.
                        }
                      : () =>
                          handleFileClick("DEFAULT", metropolisLink.document)
                  }
                  avatar={
                    <Avatar className={classes.avatar}>
                      <FileIcon document={metropolisLink.document} />
                    </Avatar>
                  }
                  label={metropolisLink.document.fileName}
                />
              </Grid>
              <Grid item>
                <Typography variant="body2" className={classes.toggleText}>
                  was published to{" "}
                  <Link
                    to={includeTenantParam(
                      `/metropolis/media/${metropolisLink.mediaId}`
                    )}
                    target="_blank"
                  >
                    Metropolis media {getMediaName(metropolisLink.mediaId)}
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          ))}
        </div>
      )}
    </>
  );
};

MetropolisLinks.propTypes = {
  entry: PropTypes.object.isRequired,
  workItem: PropTypes.object.isRequired,
};

export default MetropolisLinks;
