import React from "react";
import BreadcrumbLink from "../../../components/common/BreadcrumbLink";
import Container from "../../../components/common/Container";
import HeaderBar from "../../../components/common/HeaderBar";
import PageHeading from "../../../components/common/PageHeading";
import { organisationIcon } from "../../../util/icons";
import makeStyles from "@material-ui/core/styles/makeStyles";
import OrganisationForm from "../../../components/forms/organisation/OrganisationForm";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
}));

const NewOrgPage: React.FC = ({}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <HeaderBar>
        <BreadcrumbLink
          to="/admin/organisations"
          label="Organisations"
          includeArrow
        />
        <PageHeading
          icon={organisationIcon()}
          heading={"Create organisation"}
        />
      </HeaderBar>
      <Container>
        <OrganisationForm />
      </Container>
    </div>
  );
};

export default NewOrgPage;
