import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { required } from "redux-form-validators";
import { getReferenceDataType } from "../../../../reducers";
import { getRefDataMenuItems } from "../../../forms/menuItems";
import ComponentField from "../../../forms/wrapper/ComponentField";
import WrappedSelect from "../../../forms/wrapper/WrappedSelect";
import WrappedTextField from "../../../forms/wrapper/WrappedTextField";

const ClientDetailsFields = ({
  fieldLabels,
  selectedType,
  clientAgeOptions,
  clientGenderOptions,
  clientTypeOptions,
  change,
  untouch,
  customisations,
  formName,
  fieldPrefix,
}) => {
  const [showGenderAge, setShowGenderAge] = useState(
    selectedType === "INDIVIDUAL_COUPLE"
  );
  const clientLabels = fieldLabels.nestedTypes.clientDetails;

  useEffect(() => {
    setShowGenderAge(selectedType === "INDIVIDUAL_COUPLE");
  }, [selectedType]);

  useEffect(() => {
    if (!showGenderAge) {
      change("clientDetails.age", null);
      change("clientDetails.gender", null);
    }
  }, [showGenderAge]);

  return (
    <>
      <ComponentField
        fieldPrefix={fieldPrefix}
        name="clientDetails.name"
        component={WrappedTextField}
        label={clientLabels.labels.name}
        fullWidth
        required
        validate={required({
          msg: `${clientLabels.labels.name} is required`,
        })}
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      />
      <ComponentField
        fieldPrefix={fieldPrefix}
        name="clientDetails.identificationNumber"
        component={WrappedTextField}
        label={clientLabels.labels.identificationNumber}
        fullWidth
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      />
      <ComponentField
        fieldPrefix={fieldPrefix}
        component={WrappedSelect}
        name="clientDetails.type"
        label={clientLabels.labels.type}
        validate={required({
          msg: `${clientLabels.labels.type} is required`,
        })}
        required
        fullWidth
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      >
        {getRefDataMenuItems(clientTypeOptions)}
      </ComponentField>
      {showGenderAge && (
        <ComponentField
          fieldPrefix={fieldPrefix}
          component={WrappedSelect}
          name="clientDetails.gender"
          label={clientLabels.labels.gender}
          validate={required({
            msg: `${clientLabels.labels.gender} is required`,
          })}
          required
          fullWidth
          customisations={customisations}
          change={change}
          untouch={untouch}
          formName={formName}
        >
          {getRefDataMenuItems(clientGenderOptions)}
        </ComponentField>
      )}
      {showGenderAge && (
        <ComponentField
          fieldPrefix={fieldPrefix}
          component={WrappedSelect}
          name="clientDetails.age"
          label={clientLabels.labels.age}
          validate={required({
            msg: `${clientLabels.labels.age} is required`,
          })}
          required
          fullWidth
          customisations={customisations}
          change={change}
          untouch={untouch}
          formName={formName}
        >
          {getRefDataMenuItems(clientAgeOptions)}
        </ComponentField>
      )}
      <ComponentField
        fieldPrefix={fieldPrefix}
        name="clientDetails.postcode"
        component={WrappedTextField}
        label={clientLabels.labels.postcode}
        fullWidth
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      />
    </>
  );
};

ClientDetailsFields.propTypes = {
  fieldLabels: PropTypes.object.isRequired,
  selectedType: PropTypes.string,
  clientTypeOptions: PropTypes.object.isRequired,
  clientGenderOptions: PropTypes.object.isRequired,
  clientAgeOptions: PropTypes.object.isRequired,
  formName: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  customisations: PropTypes.array,
  fieldPrefix: PropTypes.string,
};

ClientDetailsFields.defaultProps = {
  selectedType: null,
  customisations: [],
  fieldPrefix: "",
};

const mapStateToProps = (state, ownProps) => {
  const reduxFormSelector = formValueSelector(ownProps.formName);
  return {
    selectedType: reduxFormSelector(state, "clientDetails.type"),
    clientAgeOptions: getReferenceDataType(state, "ClientAge"),
    clientGenderOptions: getReferenceDataType(state, "ClientGender"),
    clientTypeOptions: getReferenceDataType(state, "ClientType"),
  };
};

export default connect(mapStateToProps)(ClientDetailsFields);
