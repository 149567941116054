import { withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import dates from "../../../../util/dates";
import formatters from "../../../../util/formatters";
import ComponentScorecard from "../../../common/ComponentScorecard";
import DateAgo from "../../../common/DateAgo";
import FormCard from "../../../common/FormCard";
import EditWorkItemComponentForm, {
  EDIT_WORK_ITEM_COMPONENT_FORM_NAME,
} from "../EditWorkItemComponentForm";

const styles = () => ({});

const HardshipCard = ({
  className,
  workItem,
  fieldLabels,
  customisations,
  onChange,
}) => {
  const { hardship } = workItem;

  return (
    <FormCard
      className={className}
      title={fieldLabels.labels.hardship}
      formTitle={`Edit ${fieldLabels.labels.hardship}`}
      readOnlyView={() => (
        <>
          <ComponentScorecard
            fieldLabels={fieldLabels}
            name="hardship.fundsRequested"
            customisations={customisations}
            entity={workItem}
            render={(value) => formatters.currency(value)}
          />
          <ComponentScorecard
            fieldLabels={fieldLabels}
            name="hardship.reason"
            customisations={customisations}
            entity={workItem}
          />
          <ComponentScorecard
            fieldLabels={fieldLabels}
            name="hardship.hasPreviouslyBeenPaid"
            customisations={customisations}
            entity={workItem}
            render={(value) => formatters.displayBoolean(value, "Yes", "No")}
            defaultDisplay="N/A"
          />
          {hardship && hardship.hasPreviouslyBeenPaid && (
            <ComponentScorecard
              fieldLabels={fieldLabels}
              name="hardship.dateOfLastClaim"
              customisations={customisations}
              entity={workItem}
              render={(value) => (
                <DateAgo value={dates.parseDate(value)} startFull expandable />
              )}
            />
          )}
          {hardship && hardship.hasPreviouslyBeenPaid && (
            <ComponentScorecard
              fieldLabels={fieldLabels}
              name="hardship.previousClaimAmount"
              customisations={customisations}
              entity={workItem}
              render={(value) => formatters.currency(value)}
            />
          )}
          <ComponentScorecard
            fieldLabels={fieldLabels}
            name="hardship.recommendedAmount"
            customisations={customisations}
            entity={workItem}
            render={(value) => formatters.currency(value)}
          />
        </>
      )}
      formComponent={EditWorkItemComponentForm}
      formName={EDIT_WORK_ITEM_COMPONENT_FORM_NAME}
      onSave={onChange}
      initialValues={{ hardship: workItem.hardship }}
      formProps={{
        maxWidth: "md",
        workItem,
        componentName: "HARDSHIP_CLAIM",
      }}
    />
  );
};

HardshipCard.propTypes = {
  className: PropTypes.string,
  workItem: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  fieldLabels: PropTypes.object.isRequired,
  customisations: PropTypes.array,
};

HardshipCard.defaultProps = {
  className: undefined,
  customisations: [],
  onChange: null,
};

const mapStateToProps = () => ({});

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(HardshipCard);
