import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useState } from "react";
import logger from "../../util/logger";
import FormDialog from "../forms/FormDialog";
import ConfirmFileNamesForm, {
  CONFIRM_FILE_NAMES_FORM_NAME,
} from "./ConfirmFileNamesForm";
import FileUpload from "./FileUpload";

const fileExtension = (fileName) => /(?:\.([^.]+))?$/.exec(fileName)[1];

const getRootFileName = (fileName) => {
  const extension = fileExtension(fileName);
  const name = fileName
    // Matches the extension
    .replace(`.${extension}`, "")
    // Matches the first part of 'copy of xxx' (Old windows file duplication
    .replace(/^(copy\sof\s)+/i, "")
    // Matches the last part of ' - Copy' (Windows file duplication)
    .replace(/(\s-\scopy)+$/i, "")
    // Matches the last string of 'copy xxx' (Mac file duplication)
    .replace(/(\bcopy(\s[0-9]+)?\b)(?!.*\bcopy(\s[0-9]+)?\b)/gi, "")
    // Matches the last string of '(xx)' (Browser download duplication)
    .replace(/\([^)]*\)(?!.*\([^)]*\))$/gi, "")
    .trim();

  return `${name}.${extension}`;
};

const styles = () => ({});

const VersionedFileUpload = ({ existingDocuments, onSubmit, ...rest }) => {
  const [uploadingDocuments, setUploadingDocuments] = useState(null);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  /**
   * Checks if newly uploaded files have similar names to existing files. If so the user is prompted whether they
   * want to upload the new files under the existing file names. Otherwise the upload proceeds with no prompts.
   * @param documents
   * @returns {Promise<Array<Document>>}
   */
  const checkUploadAsNewVersions = (newDocuments) => {
    // prompt for new files with similar names to existing files - whether to upload as new versions of existing files (ie same name)
    const fileNameOptions = newDocuments
      .map((file) => {
        const existingFileExactMatch = existingDocuments.some(
          (doc) => doc.fileName === file.fileName
        );
        if (!existingFileExactMatch) {
          const rootFileName = getRootFileName(file.fileName);
          const existingFileRootMatch = existingDocuments.some(
            (doc) => getRootFileName(doc.fileName) === rootFileName
          );
          if (existingFileRootMatch) {
            return {
              fileName: file.fileName,
              rootFileName,
              useRootName: false,
            };
          }
        }
        return null;
      })
      .filter((mapping) => !!mapping);
    logger.debug("discovered fileNameOptions", fileNameOptions);

    return new Promise((resolve, reject) => {
      if (fileNameOptions.length === 0) {
        resolve(newDocuments);
      } else {
        setUploadingDocuments({
          documents: newDocuments,
          fileNameOptions,
          resolve,
          reject,
        });
        setConfirmationDialogOpen(true);
      }
    });
  };

  /**
   * User has confirmed the upload and may have selected to upload files under their root name
   * @param fileNameOptions
   */
  const handleSubmitAfterConfirmation = (fileNameOptions) => {
    const fileNameMapping = {};
    fileNameOptions.forEach((fileNameOption) => {
      fileNameMapping[fileNameOption.fileName] = fileNameOption.useRootName
        ? fileNameOption.rootFileName
        : fileNameOption.fileName;
    });

    const newDocuments = uploadingDocuments.documents.map((document) => ({
      ...document,
      fileName: fileNameMapping[document.fileName] || document.fileName,
    }));

    logger.debug("sending documents:", newDocuments);

    setConfirmationDialogOpen(false);
    setUploadingDocuments(null);
    uploadingDocuments.resolve(newDocuments);
  };

  /**
   * User has cancelled the file upload
   */
  const handleCancelSubmitAfterConfirmation = () => {
    setConfirmationDialogOpen(false);
    setUploadingDocuments(null);
    uploadingDocuments.reject();
  };

  const handleSubmit = (newDocuments) =>
    checkUploadAsNewVersions(newDocuments).then((docs) => onSubmit(docs));

  const confirmFileNamesInitialValues = uploadingDocuments
    ? { fileNameOptions: uploadingDocuments.fileNameOptions }
    : {};

  return (
    <>
      <FormDialog
        title="Confirmation required"
        submitButtonText="Upload"
        formComponent={ConfirmFileNamesForm}
        formName={CONFIRM_FILE_NAMES_FORM_NAME}
        open={confirmationDialogOpen}
        initialValues={confirmFileNamesInitialValues}
        onCancel={() => handleCancelSubmitAfterConfirmation()}
        onSubmit={(values) =>
          handleSubmitAfterConfirmation(values.fileNameOptions)
        }
      />
      <FileUpload onSubmit={handleSubmit} {...rest} />
    </>
  );
};

VersionedFileUpload.propTypes = {
  existingDocuments: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default withStyles(styles)(VersionedFileUpload);
