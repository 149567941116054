import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import { getReferenceDataOptions } from "../../../../reducers";
import ComponentField from "../../../forms/wrapper/ComponentField";
import WrappedRadioGroup from "../../../forms/wrapper/WrappedRadioGroup";

const IncidentActionFields = ({
  fieldLabels,
  incidentActionTypeOptions,
  change,
  untouch,
  formName,
  fieldPrefix,
  customisations,
}) => (
  <>
    <ComponentField
      fieldPrefix={fieldPrefix}
      name="incidentAction.actionType"
      component={WrappedRadioGroup}
      label={fieldLabels.nestedTypes.incidentAction.labels.actionType}
      validate={required({
        msg: `${fieldLabels.nestedTypes.incidentAction.labels.actionType} is required`,
      })}
      options={incidentActionTypeOptions}
      row
      required
      customisations={customisations}
      change={change}
      untouch={untouch}
      formName={formName}
    />
  </>
);

IncidentActionFields.propTypes = {
  customisations: PropTypes.array,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  fieldPrefix: PropTypes.string,

  // redux
  incidentActionTypeOptions: PropTypes.object.isRequired,
};

IncidentActionFields.defaultProps = {
  customisations: [],
  fieldPrefix: "",
};

const mapStateToProps = (state) => ({
  incidentActionTypeOptions: getReferenceDataOptions(
    state,
    "IncidentActionType"
  ),
});

export default connect(mapStateToProps)(IncidentActionFields);
