import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import Remove from "@material-ui/icons/Remove";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field } from "redux-form";
import { numericality, required } from "redux-form-validators";
import { getReferenceDataType } from "../../../reducers";
import { getRefDataMenuItems } from "../menuItems";
import WrappedSelect from "../wrapper/WrappedSelect";
import WrappedTextField from "../wrapper/WrappedTextField";

const styles = () => ({
  root: {
    width: "100%",
  },
  item: {
    paddingLeft: "0",
    paddingTop: "0",
    paddingBottom: "0",
  },
});

class CapitalImpactFields extends Component {
  add = () => {
    this.props.fields.push({});
  };

  remove = (index) => {
    this.props.fields.remove(index);
  };

  render() {
    const { fields, classes, capitalImpactTypes } = this.props;

    return (
      <>
        {fields.map((capitalImpact, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <List key={index} className={classes.root}>
            <ListItem dense className={classes.item}>
              <ListItemText>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Field
                      name={`${capitalImpact}.type`}
                      component={WrappedSelect}
                      label="Type"
                      validate={required({ msg: "Value is required" })}
                      fullWidth
                      required
                    >
                      {getRefDataMenuItems(capitalImpactTypes)}
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={WrappedTextField}
                      label="Amount"
                      name={`${capitalImpact}.amount`}
                      validate={numericality({
                        ">=": 0,
                        allowBlank: true,
                      })}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </ListItemText>
              <ListItemSecondaryAction>
                <IconButton
                  color="primary"
                  aria-label="Remove"
                  onClick={() => this.remove(index)}
                >
                  <Remove />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        ))}
        <Button
          size="small"
          color="primary"
          aria-label="Add"
          onClick={this.add}
        >
          Add capital impact
        </Button>
      </>
    );
  }
}

CapitalImpactFields.propTypes = {
  fields: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  capitalImpactTypes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  capitalImpactTypes: getReferenceDataType(state, "CapitalImpactType"),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(CapitalImpactFields);
