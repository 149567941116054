import FormLabel from "@material-ui/core/FormLabel";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, FieldArray, reduxForm } from "redux-form";
import { length } from "redux-form-validators";
import { fetchTags } from "../../../actions/tags";
import { getTags } from "../../../reducers";
import { scrollToFirstInvalidInput } from "../../../util/formHelpers";
import FormError from "../../common/FormError";
import WrappedTags from "../wrapper/WrappedTags";
import OperationalToleranceFields from "./OperationalToleranceFields";
import { useAuth0 } from "@auth0/auth0-react";

const styles = (theme) => ({
  formLabel: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

const fieldLabels = {
  items: "Measures",
  operationalTolerances: "Operational tolerances",
};

const RiskMeasureForm = ({ error, tags, classes, fetchTags }) => {
  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      fetchTags(accessToken);
    })();
  }, [getAccessTokenSilently]);

  return (
    <form autoComplete="off">
      {error && <FormError errorMessage={error} />}
      <Field
        name="items"
        component={WrappedTags}
        label={fieldLabels.items}
        validate={length({
          min: 1,
          msg: `${fieldLabels.items} is required`,
        })}
        tags={tags}
        tagType="RISK_MEASURE"
        placeholder="Enter risk measures(s)..."
        fullWidth
        required
        isMulti
        data-cy={fieldLabels.items}
      />
      <div className={classes.formLabel}>
        <FormLabel data-cy={fieldLabels.operationalTolerances}>
          {fieldLabels.operationalTolerances}
        </FormLabel>
      </div>
      <FieldArray
        name="operationalTolerances"
        component={OperationalToleranceFields}
        data-cy={OperationalToleranceFields}
      />
    </form>
  );
};

RiskMeasureForm.propTypes = {
  error: PropTypes.string,
  classes: PropTypes.object.isRequired,
  tags: PropTypes.array.isRequired,
  fetchTags: PropTypes.func.isRequired,
};

RiskMeasureForm.defaultProps = {
  error: null,
};

const mapStateToProps = (state) => ({
  tags: getTags(state),
});

export const RISK_MEASURE_FORM_NAME = "riskMeasureForm";

const form = reduxForm({
  form: RISK_MEASURE_FORM_NAME,
  onSubmitFail: (errors) => scrollToFirstInvalidInput(errors),
});

const reduxData = connect(mapStateToProps, {
  fetchTags,
});

export default compose(form, reduxData)(withStyles(styles)(RiskMeasureForm));
