import Grid from "@material-ui/core/Grid";
import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
  icon: {
    verticalAlign: "middle",
    marginBottom: "5px",
  },
});

const CardIconTitle = ({ classes, title, icon: CustomIcon }) => (
  <Grid container direction="row" alignItems="center">
    <Grid item>
      <CustomIcon
        classes={{
          root: classes.icon,
        }}
      />
    </Grid>
    <Grid item>&nbsp;</Grid>
    <Grid item xs>
      {title}
    </Grid>
  </Grid>
);

CardIconTitle.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
};

export default compose(withStyles(styles))(CardIconTitle);
