import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import BreadcrumbLink from "../../../components/common/BreadcrumbLink";
import Container from "../../../components/common/Container";
import HeaderBar from "../../../components/common/HeaderBar";
import PageHeading from "../../../components/common/PageHeading";
import { organisationIcon } from "../../../util/icons";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingIndicator from "../../../components/LoadingIndicator";
import organisationApi from "../../../services/api/organisations";
import Error404 from "../../../components/error/Error404";
import OrganisationForm from "../../../components/forms/organisation/OrganisationForm";
import { Organisation } from "../../../model/Organisation";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
}));

interface Params {
  orgId: string;
}

const OrgPage: React.FC = ({}) => {
  const classes = useStyles();
  const { orgId } = useParams<Params>();
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [organisation, setOrganisation] = useState<Organisation | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        setLoading(true);
        const org = await organisationApi.getById(orgId, accessToken);
        setOrganisation(org);
      } finally {
        setLoading(false);
      }
    })();
  }, [getAccessTokenSilently, orgId]);

  if (!organisation && loading) {
    return <LoadingIndicator size={60} />;
  }

  if (!organisation && !loading) {
    return <Error404 />;
  }

  return (
    <div className={classes.root}>
      <HeaderBar>
        <BreadcrumbLink
          to="/admin/organisations"
          label="Organisations"
          includeArrow
        />
        <PageHeading icon={organisationIcon()} heading={organisation?.name} />
      </HeaderBar>
      <Container>
        <OrganisationForm organisation={organisation} />
      </Container>
    </div>
  );
};

export default OrgPage;
