import PropTypes from "prop-types";
import React, { Component } from "react";
import { Field } from "redux-form";
import { required } from "redux-form-validators";
import WrappedTextField from "../../../forms/wrapper/WrappedTextField";

class ContactDetailsFields extends Component {
  render() {
    const { contactLabels, name, withType, withTypeHelp } = this.props;

    return (
      <>
        <Field
          name={`${name}.name`}
          component={WrappedTextField}
          label={contactLabels.name}
          fullWidth
          required
          validate={required({ msg: `${contactLabels.name} is required` })}
        />
        {withType && (
          <Field
            name={`${name}.type`}
            component={WrappedTextField}
            label={contactLabels.type}
            fullWidth
            helperText={withTypeHelp}
          />
        )}
        <Field
          name={`${name}.address`}
          component={WrappedTextField}
          label={contactLabels.address}
          fullWidth
          multiline
          rowsMax={6}
          rows={1}
        />
        <Field
          name={`${name}.phone`}
          component={WrappedTextField}
          label={contactLabels.phone}
          fullWidth
        />
        <Field
          name={`${name}.email`}
          component={WrappedTextField}
          label={contactLabels.email}
          fullWidth
        />
      </>
    );
  }
}

ContactDetailsFields.propTypes = {
  contactLabels: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  withType: PropTypes.bool,
  withTypeHelp: PropTypes.string,
};

ContactDetailsFields.defaultProps = {
  withType: false,
  withTypeHelp:
    "Provide an optional type descriptor eg. 'primary', 'lawyer', 'spouse', etc.",
};

export default ContactDetailsFields;
