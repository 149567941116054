import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import PropTypes from "prop-types";
import classNames from "classnames";

const styles = (theme) => ({
  text: {
    display: "inline-block",
    color: theme.palette.swatch.secondary,
  },
  statusNew: {
    color: theme.palette.statusColors.NEW,
  },
  statusOpen: {
    color: theme.palette.statusColors.OPEN,
  },
  statusExternalReview: {
    color: theme.palette.statusColors.EXTERNAL_REVIEW,
  },
  statusPendingSubmitter: {
    color: theme.palette.statusColors.PENDING_SUBMITTER,
  },
  statusPendingCounterpartySignature: {
    color: theme.palette.statusColors.PENDING_COUNTERPARTY_SIGNATURE,
  },
  statusOnHold: {
    color: theme.palette.statusColors.ON_HOLD,
  },
  statusMonitoring: {
    color: theme.palette.statusColors.MONITORING,
  },
  statusClosed: {
    color: theme.palette.statusColors.CLOSED,
  },
  statusUnknown: {
    color: theme.palette.statusColors.UNKNOWN,
  },
  icon: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(-4) - 2,
  },
  iconStatusNew: {
    "& path:nth-child(1)": {
      fill: theme.palette.statusColors.NEW,
    },
  },
  iconStatusOpen: {
    "& path:nth-child(1)": {
      fill: theme.palette.statusColors.OPEN,
    },
  },
  iconStatusExternalReview: {
    "& path:nth-child(1)": {
      fill: theme.palette.statusColors.EXTERNAL_REVIEW,
    },
  },
  iconStatusPendingSubmitter: {
    "& path:nth-child(1)": {
      fill: theme.palette.statusColors.PENDING_SUBMITTER,
    },
  },
  iconStatusPendingCounterpartySignature: {
    "& path:nth-child(1)": {
      fill: theme.palette.statusColors.PENDING_COUNTERPARTY_SIGNATURE,
    },
  },
  iconStatusOnHold: {
    "& path:nth-child(1)": {
      fill: theme.palette.statusColors.ON_HOLD,
    },
  },
  iconStatusMonitoring: {
    "& path:nth-child(1)": {
      fill: theme.palette.statusColors.MONITORING,
    },
  },
  iconStatusClosed: {
    "& path:nth-child(1)": {
      fill: theme.palette.statusColors.CLOSED,
    },
  },
  iconStatusUnknown: {
    "& path:nth-child(1)": {
      fill: theme.palette.statusColors.UNKNOWN,
    },
  },
});

const statusClass = (classes, status) =>
  classNames({
    [classes.text]: true,
    [classes.statusNew]: status === "NEW",
    [classes.statusOpen]: status === "OPEN",
    [classes.statusExternalReview]: status === "EXTERNAL_REVIEW",
    [classes.statusPendingSubmitter]: status === "PENDING_SUBMITTER",
    [classes.statusPendingCounterpartySignature]:
      status === "PENDING_COUNTERPARTY_SIGNATURE",
    [classes.statusOnHold]: status === "ON_HOLD",
    [classes.statusMonitoring]: status === "MONITORING",
    [classes.statusClosed]: status === "CLOSED",
    [classes.statusUnknown]: status === "UNKNOWN",
  });

const useStatusChange = (entry) => {
  const [hasStatusChange, setHasStatusChange] = useState(false);
  const [status, setStatus] = useState({
    displayValue: "",
    value: "",
  });

  useEffect(() => {
    const pathsWithStatus = entry.fieldChanges.filter((change) =>
      _.includes(change.path, "status")
    );

    if (pathsWithStatus.length > 0) {
      const first = _.head(pathsWithStatus);

      setHasStatusChange(true);
      setStatus(_.head(first.after));
    }
  }, [entry]);

  return [hasStatusChange, status.displayValue, status.value];
};

const StatusChange = ({ classes, entry }) => {
  const [hasStatusChange, displayValue, statusEnum] = useStatusChange(entry);

  return (
    <>
      {hasStatusChange && (
        <Typography variant="body2" className={classes.text}>
          Set status{" "}
          <strong className={statusClass(classes, statusEnum)}>
            {displayValue}
          </strong>
        </Typography>
      )}
    </>
  );
};

StatusChange.propTypes = {
  classes: PropTypes.object.isRequired,
  entry: PropTypes.object.isRequired,
};

export default withStyles(styles)(StatusChange);
