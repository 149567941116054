import { useTenant } from "@certane/arcadia-web-components";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React from "react";
import { FormattedDate } from "react-intl";
import { connect } from "react-redux";
import { getReferenceDataDescription } from "../../../../reducers";
import formatters from "../../../../util/formatters";
import ComponentScorecard from "../../../common/ComponentScorecard";
import FormCard from "../../../common/FormCard";
import MultilineText from "../../../common/MultilineText";
import Scorecard from "../../../common/Scorecard";
import SimpleChips from "../../../common/SimpleChips";
import WarningText from "../../../WarningText";
import EditWorkItemComponentForm, {
  EDIT_WORK_ITEM_COMPONENT_FORM_NAME,
} from "../EditWorkItemComponentForm";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  report: {
    whiteSpace: "pre-wrap",
  },
  listItem: {
    paddingLeft: "0",
    paddingTop: "0",
    paddingBottom: "0",
  },
  linkText: {
    cursor: "pointer",
    textDecoration: "none",
    color: theme.palette.swatch.link,
  },
}));

const AssessmentCard = ({
  fieldLabels,
  onChange,
  workItem,
  customisations,
  scrollToComponent,
  getReferenceDataDescription,
}) => {
  const classes = useStyles();
  const { tenant } = useTenant();

  const getDescriptions = (ids, type) =>
    (ids || []).map((id) => getReferenceDataDescription(id, type));

  const initialValues = {
    incidentAssessment: workItem.incidentAssessment,
    associatedRisks: workItem.associatedRisks,
  };

  const haveAssociatedRisks =
    workItem.associatedRisks && workItem.associatedRisks.length > 0;

  return (
    <FormCard
      className={classes.root}
      title="Assessment"
      formTitle="Edit assessment"
      readOnlyView={() => (
        <>
          {!workItem.incidentAssessment && !haveAssociatedRisks && (
            <p>No assessment entered.</p>
          )}
          {(workItem.incidentAssessment || haveAssociatedRisks) && (
            <>
              <ComponentScorecard
                fieldLabels={fieldLabels}
                name="incidentAssessment.riskConsequenceRating"
                customisations={customisations}
                entity={workItem}
                render={(value) =>
                  getReferenceDataDescription(
                    value,
                    "RiskConsequenceRating",
                    "Not yet rated"
                  )
                }
              />
              {workItem.incidentAssessment &&
                workItem.incidentAssessment.groupCompany && (
                  <Scorecard
                    label={
                      fieldLabels.nestedTypes.incidentAssessment.labels
                        .groupCompany
                    }
                  >
                    {getReferenceDataDescription(
                      workItem.incidentAssessment &&
                        workItem.incidentAssessment.groupCompany,
                      "GroupCompany"
                    )}
                  </Scorecard>
                )}
              {workItem.incidentAssessment &&
                workItem.incidentAssessment
                  .relevantOutsourcedServiceProvidersString && (
                  <Scorecard
                    label={
                      fieldLabels.nestedTypes.incidentAssessment.labels
                        .relevantOutsourcedServiceProvidersString
                    }
                  >
                    {
                      workItem.incidentAssessment
                        .relevantOutsourcedServiceProvidersString
                    }
                  </Scorecard>
                )}
              {tenant.featureFlags.risk && (
                <ComponentScorecard
                  fieldLabels={fieldLabels}
                  name="associatedRisks"
                  customisations={customisations}
                  entity={workItem}
                  render={(associatedRisks) =>
                    associatedRisks.length > 0 ? (
                      <ul>
                        {associatedRisks.map((riskRef) => {
                          const riskLink = riskRef.accessible ? (
                            <Link to={`/risk/risks/${riskRef.id}`}>
                              {`Risk ${riskRef.title}`}
                            </Link>
                          ) : (
                            `Risk ${riskRef.title}`
                          );
                          return <li key={riskRef.id}>{riskLink}</li>;
                        })}
                      </ul>
                    ) : (
                      "-"
                    )
                  }
                />
              )}
              <ComponentScorecard
                fieldLabels={fieldLabels}
                name="incidentAssessment.obligationsImpacted"
                customisations={customisations}
                entity={workItem}
                render={(obligationsImpacted) => (
                  <SimpleChips
                    values={obligationsImpacted.map((tag) => tag.text)}
                    blankLabel="-"
                  />
                )}
              />
              <ComponentScorecard
                fieldLabels={fieldLabels}
                name="incidentAssessment.breach"
                customisations={customisations}
                entity={workItem}
                render={(breach) => formatters.displayBoolean(breach)}
              />
              <ComponentScorecard
                fieldLabels={fieldLabels}
                name="incidentAssessment.systemic"
                customisations={customisations}
                entity={workItem}
                render={(systemic) => formatters.displayBoolean(systemic)}
              />
              <ComponentScorecard
                fieldLabels={fieldLabels}
                name="incidentAssessment.reportableSituation"
                customisations={customisations}
                entity={workItem}
                render={(reportableSituation) =>
                  getReferenceDataDescription(
                    reportableSituation,
                    "ReportableSituation",
                    "Non reportable"
                  )
                }
              />
              {!!workItem.incidentAssessment.reportableSituation && (
                <WarningText
                  text={
                    <Typography gutterBottom variant="caption">
                      You must complete a{" "}
                      <Link
                        className={classes.linkText}
                        onClick={() =>
                          scrollToComponent("INCIDENT_BREACH_REPORT")
                        }
                      >
                        breach report
                      </Link>{" "}
                      for this incident.
                    </Typography>
                  }
                />
              )}

              <ComponentScorecard
                fieldLabels={fieldLabels}
                name="incidentAssessment.reportable"
                customisations={customisations}
                entity={workItem}
                render={(reportable) => formatters.displayBoolean(reportable)}
              />

              {workItem.incidentAssessment?.reportableSituation &&
                (workItem.category === "INCIDENT_ADVISER_ADVISER_SERVICE_FEE" ||
                  workItem.category === "INCIDENT_OTHER_ACL_ACTIVITY" ||
                  workItem.category ===
                    "INCIDENT_OTHER_AFS_LICENSEE_ACTIVITY") && (
                  <Scorecard
                    label={
                      fieldLabels.nestedTypes.incidentAssessment.labels
                        .reportProvided
                    }
                  >
                    <>
                      {formatters.displayBoolean(
                        typeof workItem.incidentAssessment?.reportProvided !==
                          "undefined" &&
                          workItem.incidentAssessment?.reportProvided
                      )}
                    </>
                  </Scorecard>
                )}
              {workItem.incidentAssessment?.reportProvided && (
                <>
                  <Scorecard
                    label={
                      fieldLabels.nestedTypes.incidentAssessment.labels
                        .dateReportProvided
                    }
                  >
                    <>
                      {workItem.incidentAssessment?.dateReportProvided ? (
                        <FormattedDate
                          value={workItem.incidentAssessment.dateReportProvided}
                        />
                      ) : (
                        "-"
                      )}
                    </>
                  </Scorecard>
                  <WarningText text="Ensure evidence of report provided is uploaded." />
                </>
              )}

              <ComponentScorecard
                fieldLabels={fieldLabels}
                name="incidentAssessment.report"
                customisations={customisations}
                entity={workItem}
                render={(report) => (
                  <MultilineText text={report} defaultText="-" />
                )}
              />
              {(workItem.incidentAssessment?.reportableSituation ===
                "SIGNIFICANT" ||
                workItem.incidentAssessment?.reportableSituation ===
                  "LIKELY_SIGNIFICANT") && (
                <ComponentScorecard
                  fieldLabels={fieldLabels}
                  name="incidentAssessment.reasonBreachIsSignificant"
                  customisations={customisations}
                  entity={workItem}
                  render={(reasonBreachIsSignificant) => (
                    <MultilineText
                      text={reasonBreachIsSignificant}
                      defaultText="-"
                    />
                  )}
                />
              )}
              {workItem.incidentAssessment?.reportableSituation === "OTHER" && (
                <ComponentScorecard
                  fieldLabels={fieldLabels}
                  name="incidentAssessment.significanceDetermination"
                  customisations={customisations}
                  entity={workItem}
                  render={(significanceDetermination) => (
                    <MultilineText
                      text={significanceDetermination}
                      defaultText="-"
                    />
                  )}
                />
              )}
              {workItem.incidentAssessment.reportableSituation ===
                "OVER_30_DAYS" && (
                <ComponentScorecard
                  fieldLabels={fieldLabels}
                  name="incidentAssessment.investigationOutcome"
                  customisations={customisations}
                  entity={workItem}
                  render={(investigationOutcome) => (
                    <span>{investigationOutcome}</span>
                  )}
                />
              )}
              <ComponentScorecard
                fieldLabels={fieldLabels}
                name="incidentAssessment.rectificationDate"
                customisations={customisations}
                entity={workItem}
                render={(rectificationDate) => (
                  <FormattedDate value={rectificationDate} />
                )}
              />
              <ComponentScorecard
                fieldLabels={fieldLabels}
                name="incidentAssessment.learnings"
                customisations={customisations}
                entity={workItem}
                render={(learnings) => (
                  <MultilineText text={learnings} defaultText="-" />
                )}
              />
              <ComponentScorecard
                fieldLabels={fieldLabels}
                name="incidentAssessment.complaintOutcomes"
                customisations={customisations}
                entity={workItem}
                render={(complaintOutcomes) => (
                  <SimpleChips
                    values={getDescriptions(
                      complaintOutcomes,
                      "ComplaintOutcome"
                    )}
                    blankLabel="-"
                  />
                )}
              />
            </>
          )}
        </>
      )}
      formComponent={EditWorkItemComponentForm}
      formName={EDIT_WORK_ITEM_COMPONENT_FORM_NAME}
      onSave={onChange}
      initialValues={initialValues}
      formProps={{
        maxWidth: "md",
        workItem,
        componentName: "INCIDENT_ASSESSMENT",
        scrollToComponent,
      }}
    />
  );
};

AssessmentCard.propTypes = {
  fieldLabels: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  workItem: PropTypes.object.isRequired,
  customisations: PropTypes.array.isRequired,
  scrollToComponent: PropTypes.func.isRequired,

  // redux
  getReferenceDataDescription: PropTypes.func.isRequired,
};

AssessmentCard.defaultProps = {
  onChange: null,
};

const mapStateToProps = (state) => ({
  getReferenceDataDescription: (id, type, defaultValue) =>
    getReferenceDataDescription(state, type, id, defaultValue || "-"),
});

export default connect(mapStateToProps)(AssessmentCard);
