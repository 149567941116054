import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import { getReferenceDataType } from "../../../../reducers";
import ComponentField from "../../../forms/wrapper/ComponentField";
import { getRefDataMenuItems } from "../../../forms/menuItems";
import WrappedSelect from "../../../forms/wrapper/WrappedSelect";
import WrappedTextField from "../../../forms/wrapper/WrappedTextField";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  update: {
    border: `solid 1px ${theme.palette.grey[300]}`,
  },
  formLabel: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const RiskMeasureUpdateDetailsFields = ({
  fields,
  customisations,
  fieldLabels,
  change,
  untouch,
  formName,
  operationalToleranceStatuses,
}) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.formLabel}>
        <FormLabel data-cy="operationalTolerance">
          Operational Tolerances
        </FormLabel>
      </div>
      {fields.map((riskMeasureUpdateDetail, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <List key={index} className={classes.root}>
          <ListItem className={classes.update}>
            <ListItemText>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <ComponentField
                    component={WrappedTextField}
                    name={`${riskMeasureUpdateDetail}.description`}
                    label="Description"
                    fullWidth
                    disabled
                    customisations={customisations}
                    change={change}
                    untouch={untouch}
                    formName={formName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ComponentField
                    name={`${riskMeasureUpdateDetail}.status`}
                    component={WrappedSelect}
                    label={
                      fieldLabels.nestedTypes.riskMeasureUpdateDetails.labels
                        .status
                    }
                    validate={required({
                      msg: `${fieldLabels.nestedTypes.riskMeasureUpdateDetails.labels.status} is required`,
                    })}
                    required
                    fullWidth
                    customisations={customisations}
                    change={change}
                    untouch={untouch}
                    formName={formName}
                  >
                    {getRefDataMenuItems(operationalToleranceStatuses)}
                  </ComponentField>
                </Grid>
                <Grid item xs={12}>
                  <ComponentField
                    name={`${riskMeasureUpdateDetail}.comment`}
                    component={WrappedTextField}
                    label={
                      fieldLabels.nestedTypes.riskMeasureUpdateDetails.labels
                        .comment
                    }
                    fullWidth
                    multiline
                    validate={required({
                      msg: `${fieldLabels.nestedTypes.riskMeasureUpdateDetails.labels.comment} is required`,
                    })}
                    required
                    rowsMax={10}
                    rows={2}
                    customisations={customisations}
                    change={change}
                    untouch={untouch}
                    formName={formName}
                  />
                </Grid>
              </Grid>
            </ListItemText>
          </ListItem>
        </List>
      ))}
    </>
  );
};

RiskMeasureUpdateDetailsFields.propTypes = {
  fieldLabels: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  customisations: PropTypes.array,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,

  // redux
  operationalToleranceStatuses: PropTypes.object.isRequired,
};

RiskMeasureUpdateDetailsFields.defaultProps = {
  customisations: [],
};

const mapStateToProps = (state) => ({
  operationalToleranceStatuses: getReferenceDataType(
    state,
    "OperationalToleranceStatus"
  ),
});

export default connect(mapStateToProps)(RiskMeasureUpdateDetailsFields);
