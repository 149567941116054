import { useAuth0 } from "@auth0/auth0-react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import workItemsApi from "../../../services/api/workItems";

const styles = (theme) => ({
  warning: {
    margin: `${theme.spacing(3)}px 0`,
    display: "block",
    color: theme.palette.warning.main,
  },
});

const QuestionWarning = ({ workItem, classes }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [responders, setResponders] = useState();
  const [responses, setResponsess] = useState();

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      workItemsApi.listQuestions(workItem.id, accessToken).then((questions) => {
        setResponsess(
          _.flatMap(questions, (question) => [...question.responses]).length
        );
        setResponders(
          _.flatMap(questions, (question) => question.responders).length
        );
      });
    })();
  }, [workItem]);

  if (responders === responses) {
    return null;
  }

  return (
    <Typography className={classes.warning} variant="body2" display="block">
      {`Warning: Some questions were not fully responded to.
      Received ${responses} out of ${responders} expected responses.`}
    </Typography>
  );
};

QuestionWarning.propTypes = {
  workItem: PropTypes.any.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(QuestionWarning);
