import _ from "lodash";
import { withStyles } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import React from "react";
import * as PropTypes from "prop-types";

const styles = () => ({
  label: {
    position: "relative",
  },
  groupVertical: {
    marginTop: "-4px",
    marginBottom: "-8px",
  },
  optionVertical: {
    marginTop: "-8px",
    "&:first-child": {
      marginTop: 0,
    },
  },
});

const NULL_VALUE = "";

const booleanToString = (value) => {
  if (value === true || value === "true") {
    return "true";
  }
  if (value === false || value === "false") {
    return "false";
  }
  return NULL_VALUE;
};

const stringToBoolean = (value) => value === true || value === "true";

const WrappedRadioGroup = (props) => {
  const {
    classes,
    input: { value, onChange, name },
    meta: { touched, error, invalid },
    required,
    disabled,
    margin,
    fullWidth,
    options,
    label,
    row,
    helperText,
    nullLabel,
    ...custom
  } = props;

  const asBoolean = _.isBoolean(options[0].value);

  const handleOnChange = (checked, optionValue) => {
    if (checked) {
      if (optionValue === NULL_VALUE) {
        onChange(null);
      } else {
        onChange(asBoolean ? stringToBoolean(optionValue) : optionValue);
      }
    }
  };

  return (
    <FormControl
      name={`position-${name}`}
      component="fieldset"
      margin={margin}
      disabled={disabled}
      error={touched && invalid}
      required={required}
      fullWidth={fullWidth}
    >
      {label && (
        <InputLabel shrink className={classes.label} data-cy={label}>
          {label}
        </InputLabel>
      )}
      <RadioGroup
        classes={{
          root: row ? undefined : classes.groupVertical,
        }}
        aria-label={label}
        name={label}
        value={asBoolean ? booleanToString(value) : value}
        onChange={(event) =>
          handleOnChange(event.target.checked, event.target.value)
        }
        margin={margin}
        row={row}
        {...custom}
        data-cy={label}
      >
        {options.map((option) => (
          <FormControlLabel
            className={row ? undefined : classes.optionVertical}
            value={asBoolean ? booleanToString(option.value) : option.value}
            key={option.value}
            control={<Radio />}
            label={option.label}
            data-cy={option.label}
          />
        ))}
        {!required && (
          <FormControlLabel
            className={row ? undefined : classes.optionVertical}
            value={NULL_VALUE}
            control={<Radio />}
            label={nullLabel}
          />
        )}
      </RadioGroup>
      {touched && error && <FormHelperText>{error}</FormHelperText>}
      {helperText && !(touched && error) && (
        <FormHelperText data-cy={helperText}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

WrappedRadioGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  required: PropTypes.bool,
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  margin: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  row: PropTypes.bool,
  helperText: PropTypes.any,
  nullLabel: PropTypes.string,
};

WrappedRadioGroup.defaultProps = {
  required: false,
  disabled: false,
  label: null,
  margin: "dense",
  fullWidth: false,
  row: false,
  helperText: "",
  nullLabel: "N/A",
};

export default withStyles(styles)(WrappedRadioGroup);
