import { useAuth0 } from "@auth0/auth0-react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getReferenceDataDescription } from "../../reducers";
import productsApi from "../../services/api/products";
import ClearButton from "../common/ClearButton";
import DebouncedTextField from "../common/DebouncedTextField";
import GridListing from "../common/GridListing";
import Pagination from "../common/Pagination";

const useStyles = makeStyles((theme) => ({
  dialogContentRoot: {
    paddingBottom: 0,
  },
  content: {
    position: "relative",
    minHeight: "340px",
    maxHeight: "340px",
    overflow: "auto",
  },
  organisationList: {
    marginBlockStart: 0,
    marginBlockEnd: 0,
  },
  clearButton: {
    padding: theme.spacing(1) / 2,
    position: "absolute",
    right: 0,
    top: 0,
  },
}));

const defaultPagination = {
  offset: 0,
  pageSize: 10,
  resultCount: 0,
};

const EntityExplorer = ({
  open,
  onClose,
  title,
  getRelationshipTypeDescription,
}) => {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const [textSearch, setTextSearch] = useState("");
  const [products, setProducts] = useState([]);
  const [pagination, setPagination] = useState(defaultPagination);
  const [searching, setSearching] = useState(false);
  const [abortController, setAbortController] = useState(null);
  const [sortBy, setSortBy] = useState({ field: "name", direction: "asc" });

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const loadResults = async (offset) => {
    if (abortController) {
      abortController.abort();
    }

    const newAbortController = window.AbortController
      ? new AbortController()
      : null;
    setSearching(true);
    setAbortController(newAbortController);

    const searchParameters = {
      limit: pagination.pageSize,
      offset,
      textSearch,
      orderByField:
        sortBy.direction === "asc" ? sortBy.field : `-${sortBy.field}`,
      excludeInactive: true,
    };

    const accessToken = await getAccessTokenSilently();
    productsApi
      .search(searchParameters, newAbortController, accessToken)
      .then((response) => {
        setProducts(response.results);
        setPagination({
          offset: response.offset,
          previousOffset: response.previousOffset,
          nextOffset: response.nextOffset,
          pageSize: response.pageSize,
          resultCount: response.resultCount,
        });
      })
      .finally(() => {
        setSearching(false);
        setAbortController(null);
      });
  };

  useEffect(() => {
    if (open) {
      loadResults(0);
    }
  }, [textSearch, sortBy]);

  const initialise = () => {
    setTextSearch("");
    setProducts([]);
    setPagination(defaultPagination);
    // prevent double fetching if only rendered when open
    if (!searching) {
      loadResults(0);
    }
  };

  const handlePreviousPage = () => {
    loadResults(pagination.previousOffset);
  };

  const handleNextPage = () => {
    loadResults(pagination.nextOffset);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      onEnter={initialise}
      fullWidth
      maxWidth="md"
      aria-labelledby="EntityExplorer"
      data-cy="entityExplorer"
    >
      <DialogTitle id="EntityExplorer" data-cy={title}>
        {title}
      </DialogTitle>
      <DialogContent
        classes={{
          root: classes.dialogContentRoot,
        }}
      >
        {textSearch && (
          <ClearButton
            className={classes.clearButton}
            onClear={() => setTextSearch("")}
          />
        )}
        <DebouncedTextField
          value={textSearch}
          onChange={setTextSearch}
          placeholder="Filter products and organisations..."
          margin="normal"
          autoFocus
        />
        <div className={classes.content}>
          <GridListing
            sortedData={products}
            loading={searching}
            sortBy={sortBy}
            updateSort={(field, direction) => setSortBy({ field, direction })}
            dense
            columns={[
              {
                label: "Product",
                name: "name",
                size: 4,
                sortable: true,
                render: (product) => <>{product.name}</>,
              },
              {
                label: "Related Organisations",
                name: "organisations",
                size: 8,
                render: (product) => (
                  <ul
                    className={classes.organisationList}
                    data-cy="organisationList"
                  >
                    {product.relationships.map((relationship) => (
                      <li
                        key={`${relationship.organisation.id}-${relationship.relationshipType}`}
                        data-cy={relationship.organisation.name}
                      >
                        {relationship.organisation.name} -{" "}
                        {getRelationshipTypeDescription(
                          relationship.relationshipType
                        )}
                      </li>
                    ))}
                  </ul>
                ),
              },
            ]}
          />
        </div>
        <Pagination
          pagination={pagination}
          handlePrevious={handlePreviousPage}
          handleNext={handleNextPage}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EntityExplorer.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,

  // redux
  getRelationshipTypeDescription: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  getRelationshipTypeDescription: (relationshipType) =>
    getReferenceDataDescription(state, "RelationshipType", relationshipType),
});

export default connect(mapStateToProps)(EntityExplorer);
