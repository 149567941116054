import { withStyles } from "@material-ui/core";
import * as PropTypes from "prop-types";
import React from "react";

const styles = (theme) => ({
  inlineMention: {
    color: theme.palette.swatch.link,
  },
});

const InlineMention = ({ classes, children }) => (
  <span className={classes.inlineMention}>@{children}</span>
);

InlineMention.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
};

export default withStyles(styles)(InlineMention);
