import { withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { getReferenceDataDescription } from "../../reducers";
import formatters from "../../util/formatters";
import SimpleChips from "../common/SimpleChips";
import Scorecard from "../common/Scorecard";

const styles = (theme) => ({
  icon: {
    marginRight: theme.spacing(1),
  },
});

const RiskDetailsFieldsReadOnly = ({
  fieldLabels,
  risk,
  getCapitalImpactDescription,
  getRiskAppetiteDescription,
}) => {
  return (
    <>
      <Scorecard
        label={fieldLabels.labels.sourcesOfRisk}
        data-cy={fieldLabels.labels.sourcesOfRisk}
      >
        <SimpleChips
          values={risk.sourcesOfRisk.map((tag) => tag.text)}
          blankLabel="-"
        />
      </Scorecard>
      <Scorecard
        label={fieldLabels.labels.consequencesOfRisk}
        data-cy={fieldLabels.labels.consequencesOfRisk}
      >
        <SimpleChips
          values={risk.consequencesOfRisk.map((tag) => tag.text)}
          blankLabel="-"
        />
      </Scorecard>
      <Scorecard
        label={fieldLabels.labels.riskRegisters}
        data-cy={fieldLabels.labels.riskRegisters}
      >
        <SimpleChips
          values={risk.riskRegisters.map((tag) => tag.text)}
          blankLabel="-"
        />
      </Scorecard>
      <Scorecard
        label={fieldLabels.labels.capitalImpacts}
        data-cy={fieldLabels.labels.capitalImpacts}
      >
        {risk.capitalImpacts && risk.capitalImpacts.length > 0 ? (
          <ul>
            {risk.capitalImpacts.map((capitalImpact, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={index} data-cy="list">
                {getCapitalImpactDescription(capitalImpact.type)} (
                {capitalImpact.amount
                  ? formatters.currency(capitalImpact.amount)
                  : "-"}
                )
              </li>
            ))}
          </ul>
        ) : (
          <span>-</span>
        )}
      </Scorecard>
      <Scorecard
        label={fieldLabels.labels.appetite}
        data-cy={fieldLabels.labels.appetite}
      >
        {getRiskAppetiteDescription(risk.appetite)}
      </Scorecard>
    </>
  );
};

RiskDetailsFieldsReadOnly.propTypes = {
  fieldLabels: PropTypes.object.isRequired,
  risk: PropTypes.object.isRequired,
  getCapitalImpactDescription: PropTypes.func.isRequired,
  getRiskAppetiteDescription: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  getCapitalImpactDescription: (id) =>
    getReferenceDataDescription(state, "CapitalImpactType", id, "-"),
  getRiskAppetiteDescription: (id) =>
    getReferenceDataDescription(state, "RiskAppetite", id, "-"),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(RiskDetailsFieldsReadOnly);
