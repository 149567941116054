import { withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { getReferenceDataDescription } from "../../../../reducers";
import ComponentScorecard from "../../../common/ComponentScorecard";
import FormCard from "../../../common/FormCard";
import MultilineText from "../../../common/MultilineText";
import EditWorkItemComponentForm, {
  EDIT_WORK_ITEM_COMPONENT_FORM_NAME,
} from "../EditWorkItemComponentForm";

const styles = () => ({});

const AuditFindingCard = ({
  className,
  workItem,
  onChange,
  fieldLabels,
  customisations,
  getRatingDescription,
}) => {
  return (
    <FormCard
      className={className}
      title={fieldLabels.labels.auditFinding}
      formTitle={`Edit ${fieldLabels.labels.auditFinding}`}
      readOnlyView={() => (
        <>
          <ComponentScorecard
            fieldLabels={fieldLabels}
            name="auditFinding.observation"
            customisations={customisations}
            entity={workItem}
            render={(value) => <MultilineText text={value} defaultText="-" />}
          />
          <ComponentScorecard
            fieldLabels={fieldLabels}
            name="auditFinding.managementComment"
            customisations={customisations}
            entity={workItem}
            render={(value) => <MultilineText text={value} defaultText="-" />}
          />
          <ComponentScorecard
            fieldLabels={fieldLabels}
            name="auditFinding.rating"
            customisations={customisations}
            entity={workItem}
            render={(value) => getRatingDescription(value)}
          />
          <ComponentScorecard
            fieldLabels={fieldLabels}
            name="auditFinding.recommendation"
            customisations={customisations}
            entity={workItem}
            render={(value) => <MultilineText text={value} defaultText="-" />}
          />
          <ComponentScorecard
            fieldLabels={fieldLabels}
            name="associatedRisks"
            customisations={customisations}
            entity={workItem}
            render={(risks) => {
              if (risks && risks.length > 0) {
                return (
                  <ul>
                    {risks.map((riskRef) => {
                      const riskLink = riskRef.accessible ? (
                        <Link to={`/risk/risks/${riskRef.id}`}>
                          {`Risk ${riskRef.title}`}
                        </Link>
                      ) : (
                        `Risk ${riskRef.title}`
                      );
                      return <li key={riskRef.id}>{riskLink}</li>;
                    })}
                  </ul>
                );
              }
              return "-";
            }}
          />
        </>
      )}
      formComponent={EditWorkItemComponentForm}
      formName={EDIT_WORK_ITEM_COMPONENT_FORM_NAME}
      onSave={onChange}
      initialValues={{
        auditFinding: workItem.auditFinding,
        associatedRisks: workItem.associatedRisks,
      }}
      formProps={{
        maxWidth: "md",
        workItem,
        componentName: "AUDIT_FINDING",
      }}
    />
  );
};

AuditFindingCard.propTypes = {
  className: PropTypes.string,
  workItem: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  fieldLabels: PropTypes.object.isRequired,
  customisations: PropTypes.array,
  getRatingDescription: PropTypes.func.isRequired,
};

AuditFindingCard.defaultProps = {
  onChange: null,
  className: undefined,
  customisations: [],
};

const mapStateToProps = (state) => ({
  getRatingDescription: (rating) =>
    getReferenceDataDescription(state, "AuditFindingRating", rating, "-"),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(AuditFindingCard);
