import api from "../services/api";
import { publishToastWarning } from "../services/toasts";
import { uploadToGcs } from "../util/GCSFileUploader";

export const FILE_UPLOAD_STARTED = "file-upload-started";
export const FILE_UPLOAD_GOT_UPLOAD_URL = "file-upload-got-upload-url";
export const FILE_UPLOAD_PROGRESS = "file-upload-progress";
export const FILE_UPLOAD_COMPLETED = "file-upload-completed";
export const FILE_UPLOAD_ALL_COMPLETED = "file-upload-all-completed";
export const FILE_UPLOAD_REMOVE = "file-upload-remove";
export const FILE_UPLOAD_CLEAR = "file-upload-clear";

const EMPTY_SIZE = 0;
const MAX_SIZE_MB = 50;
const MAX_SIZE = MAX_SIZE_MB * 1024 * 1024;

const fetchUploadUrl = (dispatch, uploadNamespace, accessToken) => (file) => {
  const requestDto = {
    contentType: file.type,
    originalFilename: file.name,
  };

  return api.file.getUploadUrl(requestDto, accessToken).then((response) => {
    dispatch({
      type: FILE_UPLOAD_GOT_UPLOAD_URL,
      document: { ...response.document },
      uploadNamespace,
    });
    return response.uploadUrl;
  });
};

export const uploadFiles =
  (files, uploadNamespace, accessToken) => (dispatch) => {
    const filesToUpload = !files
      ? null
      : Array.from(files).filter((file) => {
          const tooBig = file.size > MAX_SIZE;
          const emptyFile = file.size <= EMPTY_SIZE;

          if (emptyFile) {
            publishToastWarning(
              `Sorry ${file.name} appears to be empty (${EMPTY_SIZE}MB)`
            );
          }

          if (tooBig) {
            publishToastWarning(
              `Sorry ${file.name} exceeds maximum size of ${MAX_SIZE_MB}MB`
            );
          }

          return !tooBig && !emptyFile;
        });

    if (filesToUpload) {
      uploadToGcs(
        filesToUpload,
        fetchUploadUrl(dispatch, uploadNamespace, accessToken),
        {
          onFileUploadStarted: ({ file, cancel }) => {
            const document = {
              fileName: file.name,
              percentCompleted: 0,
              cancel,
            };
            dispatch({
              type: FILE_UPLOAD_STARTED,
              document,
              uploadNamespace,
            });
          },
          onUploadProgress: ({ file, percentCompleted }) => {
            const document = {
              fileName: file.name,
              percentCompleted,
            };

            dispatch({
              type: FILE_UPLOAD_PROGRESS,
              document,
              uploadNamespace,
            });
          },
          onFileUploadCompleted: ({ file, gcsName }) => {
            const document = {
              fileName: file.name,
              percentCompleted: 100,
              gcsObjectName: gcsName,
              cancel: undefined,
            };
            dispatch({
              type: FILE_UPLOAD_COMPLETED,
              document,
              uploadNamespace,
            });
          },
          onAllFilesUploaded: () => {
            dispatch({ type: FILE_UPLOAD_ALL_COMPLETED });
          },
        }
      );
    }
  };

export function removeFile(uploadNamespace, fileName) {
  return {
    type: FILE_UPLOAD_REMOVE,
    document: { fileName },
    uploadNamespace,
  };
}

export function clearFiles(uploadNamespace) {
  return {
    type: FILE_UPLOAD_CLEAR,
    uploadNamespace,
  };
}
