import { requestJSON, requestText } from "./http";

const decorateSearchParameters = (filter) =>
  filter
    ? { __type: "WebsiteDifference", ...filter }
    : { __type: "WebsiteDifference" };

const search = (searchParameters, accessToken) =>
  requestJSON({
    path: "/sentinel/difference/search",
    method: "POST",
    data: decorateSearchParameters(searchParameters),
    accessToken,
  });

const getById = (websiteDifferenceId, accessToken) =>
  requestJSON({
    path: `/sentinel/difference/${websiteDifferenceId}`,
    accessToken,
  });

const patch = (websiteDifferenceId, changes, description, accessToken) =>
  requestJSON({
    path: `/sentinel/difference/${websiteDifferenceId}`,
    method: "PATCH",
    data: {
      fields: Object.keys(changes),
      changes,
      description,
    },
    accessToken,
  });

const getChangeUrl = (websiteDifferenceId, accessToken) =>
  requestText({
    path: `/sentinel/difference/${websiteDifferenceId}/change`,
    accessToken,
  });

export default {
  search,
  getById,
  patch,
  getChangeUrl,
};
