import { isString } from "lodash";
import LoadingGroup from "./LoadingGroup";

const loading = (state = { stats: new LoadingGroup() }, action) => {
  if (isString(action.type)) {
    const { nonBlocking = false, loadingIgnore = false } = action;

    if (!loadingIgnore) {
      const actionTypePrefix = action.type.slice(
        0,
        action.type.lastIndexOf("_")
      );
      if (action.type.endsWith("_INPROGRESS")) {
        return {
          ...state,
          stats: state.stats.startFetch(nonBlocking, actionTypePrefix),
        };
      }
      if (
        action.type.endsWith("_SUCCESS") ||
        action.type.endsWith("_FAILURE") ||
        action.type.endsWith("_CANCELLED")
      ) {
        return {
          ...state,
          stats: state.stats.completeFetch(nonBlocking, actionTypePrefix),
        };
      }
    }
  }
  return state;
};

export default loading;

export const isLoading = (state) => state.stats.isLoading;
export const isLoadingAction = (state, actionType) =>
  state.stats.isLoadingAction(actionType);
export const isBlockerEnabled = (state) => state.stats.isBlocking;
