import { useAuth0 } from "@auth0/auth0-react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { searchAuditReports } from "../../actions/auditReports";
import {
  getAuditReports,
  getAuditReportSearchPagination,
  getLabels,
  getReferenceDataType,
  isLoading,
} from "../../reducers";
import dates from "../../util/dates";
import GridListing from "../common/GridListing";
import SimpleChip from "../common/SimpleChip";
import TimeAgo from "../common/TimeAgo";
import Pagination from "../common/Pagination";

const styles = () => ({
  primaryText: {
    display: "block",
    fontSize: "0.875rem",
    fontWeight: "600",
  },
  nowrap: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  textWithIcon: {
    display: "flex",
  },
  iconSm: {
    fontSize: 16,
  },
});

function displayEnum(defs, value) {
  return value ? defs.values[value].description : "";
}

const AuditReportListing = ({
  classes,
  history,
  filter,
  sortBy,
  auditReports,
  loading,
  types,
  priorities,
  pagination,
  labels,
  clearFilter,
  localSearchAuditReports,
  updateSort,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const getSearchParameters = () => ({
    ...filter,
    orderByField:
      sortBy.direction === "asc" ? sortBy.field : `-${sortBy.field}`,
    limit: pagination.pageSize,
    offset: pagination.offset,
  });

  useEffect(() => {
    const searchParameters = {
      ...getSearchParameters(),
      offset: 0,
    };
    getAccessTokenSilently().then((accessToken) =>
      localSearchAuditReports(searchParameters, accessToken)
    );
  }, [filter, sortBy]);

  const handlePrevious = () => {
    const searchParameters = {
      ...getSearchParameters(),
      offset: pagination.previousOffset,
    };
    getAccessTokenSilently().then((accessToken) =>
      localSearchAuditReports(searchParameters, accessToken)
    );
  };

  const handleNext = () => {
    const searchParameters = {
      ...getSearchParameters(),
      offset: pagination.nextOffset,
    };
    getAccessTokenSilently().then((accessToken) =>
      localSearchAuditReports(searchParameters, accessToken)
    );
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  const fieldLabels = labels.AuditReport;

  return (
    <>
      <GridListing
        sortedData={auditReports}
        loading={loading}
        sortBy={sortBy}
        updateSort={(field, direction) => updateSort({ field, direction })}
        clearFilter={clearFilter}
        onClick={(auditReport) => history.push(`/audits/${auditReport.id}`)}
        columns={[
          {
            label: fieldLabels.labels.title,
            name: "title",
            size: 2,
            sortable: true,
            render: (auditReport) => (
              <span className={classes.primaryText}>{auditReport.title}</span>
            ),
          },
          {
            label: fieldLabels.labels.auditor,
            name: "auditor",
            size: 2,
            sortable: false,
            render: (auditReport) =>
              auditReport.auditor ? (
                <SimpleChip
                  key={auditReport.auditor.id}
                  label={auditReport.auditor.text}
                />
              ) : (
                <span />
              ),
          },
          {
            label: fieldLabels.labels.priority,
            name: "priority",
            size: 1,
            sortable: false,
            render: (auditReport) => (
              <span>{displayEnum(priorities, auditReport.priority)}</span>
            ),
          },
          {
            label: fieldLabels.labels.type,
            name: "type",
            size: 1,
            sortable: true,
            render: (auditReport) => (
              <span>{displayEnum(types, auditReport.type)}</span>
            ),
          },
          {
            label: fieldLabels.labels.period,
            name: "period",
            size: 2,
            sortable: false,
            render: (auditReport) => (
              <span>
                {dates.formatDateLong(auditReport.period.from)} to{" "}
                {dates.formatDateLong(auditReport.period.to)}
              </span>
            ),
          },
          {
            label: fieldLabels.labels.sargonServiceProviders,
            name: "sargonServiceProviders",
            size: 4,
            sortable: false,
            render: (auditReport) =>
              auditReport.sargonServiceProviders.map((sp) => (
                <SimpleChip key={sp.id} label={sp.name} />
              )),
          },
          {
            label: "Created",
            name: "created",
            size: 1,
            sortable: true,
            render: (workItem) => (
              <TimeAgo
                value={dates.parseTimestamp(workItem.created)}
                expandable
              />
            ),
          },
          {
            label: "Updated",
            name: "updated",
            size: 1,
            sortable: true,
            render: (workItem) => (
              <TimeAgo
                value={dates.parseTimestamp(workItem.updated)}
                expandable
              />
            ),
          },
        ]}
      />
      <Pagination
        pagination={pagination}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
      />
    </>
  );
};

AuditReportListing.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  auditReports: PropTypes.array.isRequired,
  priorities: PropTypes.object.isRequired,
  types: PropTypes.object.isRequired,
  clearFilter: PropTypes.func.isRequired,
  localSearchAuditReports: PropTypes.func.isRequired,
  updateSort: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  sortBy: PropTypes.object.isRequired,
  pagination: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  loading: isLoading(state),
  auditReports: getAuditReports(state),
  priorities: getReferenceDataType(state, "Priority"),
  types: getReferenceDataType(state, "AuditType"),
  pagination: getAuditReportSearchPagination(state),
  labels: getLabels(state),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    localSearchAuditReports: searchAuditReports,
  })
)(AuditReportListing);
