import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { patchRiskControl } from "../../actions/riskControls";
import { getActiveRiskControl, isLoading } from "../../reducers";
import FormCard from "../common/FormCard";
import EditRiskControlForm, {
  EDIT_RISK_CONTROL_FORM_NAME,
} from "../forms/risk/EditRiskControlForm";
import LoadingIndicator from "../LoadingIndicator";
import RiskControlDetailsFieldsReadOnly from "./RiskControlDetailsFieldsReadOnly";
import { useAuth0 } from "@auth0/auth0-react";

const styles = () => ({});

const RiskControlDetailsCard = ({ riskControl, loading, patchRiskControl }) => {
  const { getAccessTokenSilently } = useAuth0();

  const handleSubmitEdit = async (values) => {
    const accessToken = await getAccessTokenSilently();
    return patchRiskControl(
      riskControl.id,
      values,
      "Edited risk control",
      accessToken
    );
  };

  if (loading) {
    return <LoadingIndicator size={60} />;
  }

  return (
    <FormCard
      data-cy="riskControlDetails"
      title="Risk control details"
      formTitle="Edit risk control"
      readOnlyView={() => (
        <RiskControlDetailsFieldsReadOnly
          riskControl={riskControl}
          data-cy={riskControl}
        />
      )}
      formComponent={EditRiskControlForm}
      formName={EDIT_RISK_CONTROL_FORM_NAME}
      onSave={(values) => handleSubmitEdit(values)}
      initialValues={riskControl}
      formProps={{
        maxWidth: "md",
        onCreateForm: false,
      }}
    />
  );
};

RiskControlDetailsCard.propTypes = {
  riskControl: PropTypes.object,
  patchRiskControl: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

RiskControlDetailsCard.defaultProps = {
  riskControl: null,
};

const mapStateToProps = (state) => ({
  riskControl: getActiveRiskControl(state),
  loading: isLoading(state),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    patchRiskControl,
  })
)(RiskControlDetailsCard);
