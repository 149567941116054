import { roles } from "@certane/arcadia-web-components";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { connect } from "react-redux";
import consequenceRatingImage from "../../images/consequence-rating.png";
import likelihoodRatingImage from "../../images/likelihood-rating.png";
import riskRatingImage from "../../images/risk-rating.png";
import {
  getReferenceDataDescription,
  getReferenceDataType,
} from "../../reducers";
import { overviewIcon } from "../../util/icons";
import CardIconTitle from "../common/CardIconTitle";
import HelpPopover from "../common/HelpPopover";
import InlineScorecard from "../common/InlineScorecard";
import LabelStateSelect from "../common/LabelStateSelect";
import WorkItemOwner from "../workitem/components/overview/WorkItemOwner";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  cardContent: {
    paddingTop: "0 !important",
  },
  label: {
    ...theme.typography.body2,
    color: theme.palette.swatch.secondary,
  },
  switchLabel: {
    marginLeft: `-${theme.spacing(1)}px`,
  },
  selectable: {
    ...theme.typography.body2,
    fontWeight: 400,
    color: theme.palette.swatch.primary,
  },
  helpPopup: {
    width: "100%",
    height: "100%",
  },
}));

const RiskOverviewCard = ({
  loggedInUser,
  risk,
  onChange,
  fieldLabels,
  riskLikelihoods,
  consequenceRatings,
  riskCategories,
  getRiskRatingDescription,
}) => {
  const classes = useStyles();
  const categoryCloser = useRef(null);
  const likelihoodCloser = useRef(null);
  const consequenceCloser = useRef(null);

  const handleCategoryChange = (category) => {
    onChange({ category }).then(() => categoryCloser.current());
  };

  const handleLikelihoodChange = (likelihood) => {
    onChange({ likelihood }).then(() => likelihoodCloser.current());
  };

  const handleConsequenceChange = (consequence) => {
    onChange({ consequence }).then(() => consequenceCloser.current());
  };

  const handleOwnerChange = (owner) => {
    onChange({ owner });
  };

  return (
    <Card className={classes.root} elevation={0}>
      <CardHeader
        title={<CardIconTitle title="Overview" icon={overviewIcon()} />}
        data-cy="overview"
      />
      <CardContent className={classes.cardContent}>
        <InlineScorecard
          label={fieldLabels.labels.category}
          labelVariant="body2"
          labelClass={classes.label}
        >
          <LabelStateSelect
            setCloser={(closer) => {
              categoryCloser.current = closer;
            }}
            label={riskCategories.values[risk.category].description}
            readonly={!onChange}
            className={classes.selectable}
            data-cy="category"
          >
            {riskCategories.ids
              .map((id) => riskCategories.values[id])
              .map((category) => (
                <MenuItem
                  key={category.id}
                  value={category.id}
                  disabled={risk.category === category.id}
                  onClick={() => handleCategoryChange(category.id)}
                  data-cy={category.description}
                >
                  {category.description}
                </MenuItem>
              ))}
          </LabelStateSelect>
        </InlineScorecard>
        <InlineScorecard
          label={
            <>
              {fieldLabels.labels.likelihood}
              <HelpPopover smallIcon>
                <img
                  className={classes.helpPopup}
                  src={likelihoodRatingImage}
                  alt="Likelihood rating matrix"
                />
              </HelpPopover>
            </>
          }
          labelVariant="body2"
          labelClass={classes.label}
        >
          <LabelStateSelect
            setCloser={(closer) => {
              likelihoodCloser.current = closer;
            }}
            label={riskLikelihoods.values[risk.likelihood].description}
            readonly={!onChange}
            className={classes.selectable}
            data-cy="category"
          >
            {riskLikelihoods.ids
              .map((id) => riskLikelihoods.values[id])
              .map((likelihood) => (
                <MenuItem
                  key={likelihood.id}
                  value={likelihood.id}
                  disabled={risk.likelihood === likelihood.id}
                  onClick={() => handleLikelihoodChange(likelihood.id)}
                  data-cy={likelihood.description}
                >
                  {likelihood.description}
                </MenuItem>
              ))}
          </LabelStateSelect>
        </InlineScorecard>
        <InlineScorecard
          label={
            <>
              {fieldLabels.labels.consequence}
              <HelpPopover notAnchored smallIcon>
                <img
                  className={classes.helpPopup}
                  src={consequenceRatingImage}
                  alt="Consequence rating matrix"
                />
              </HelpPopover>
            </>
          }
          labelVariant="body2"
          labelClass={classes.label}
        >
          <LabelStateSelect
            setCloser={(closer) => {
              consequenceCloser.current = closer;
            }}
            label={consequenceRatings.values[risk.consequence].description}
            readonly={!onChange}
            className={classes.selectable}
            data-cy="category"
          >
            {consequenceRatings.ids
              .map((id) => consequenceRatings.values[id])
              .map((consequence) => (
                <MenuItem
                  key={consequence.id}
                  value={consequence.id}
                  disabled={risk.consequence === consequence.id}
                  onClick={() => handleConsequenceChange(consequence.id)}
                  data-cy={consequence.description}
                >
                  {consequence.description}
                </MenuItem>
              ))}
          </LabelStateSelect>
        </InlineScorecard>
        <InlineScorecard
          label={
            <>
              {fieldLabels.labels.riskRating}
              <HelpPopover notAnchored smallIcon>
                <img
                  className={classes.helpPopup}
                  src={riskRatingImage}
                  alt="Risk rating matrix"
                />
              </HelpPopover>
            </>
          }
          labelVariant="body2"
          labelClass={classes.label}
        >
          {getRiskRatingDescription(risk.riskRating)}
        </InlineScorecard>
        <InlineScorecard
          label={fieldLabels.labels.owner}
          labelVariant="body2"
          labelClass={classes.label}
        >
          <WorkItemOwner
            className={classes.selectable}
            owner={risk.owner}
            userRoles={[
              roles.RISK_ADMIN,
              roles.COMPLIANCE_OWNER,
              roles.COMPLIANCE_OFFICER,
            ]}
            loggedInUser={loggedInUser}
            onOwnerChange={loggedInUser.externalUser ? null : handleOwnerChange}
            readonly={!onChange}
            includeAvatar
            data-cy="owner"
          />
        </InlineScorecard>
      </CardContent>
    </Card>
  );
};

RiskOverviewCard.propTypes = {
  loggedInUser: PropTypes.object.isRequired,
  risk: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  fieldLabels: PropTypes.object.isRequired,

  riskLikelihoods: PropTypes.object.isRequired,
  consequenceRatings: PropTypes.object.isRequired,
  riskCategories: PropTypes.object.isRequired,
  getRiskRatingDescription: PropTypes.func.isRequired,
};

RiskOverviewCard.defaultProps = {
  onChange: null,
};

const mapStateToProps = (state) => ({
  riskLikelihoods: getReferenceDataType(state, "RiskLikelihood"),
  consequenceRatings: getReferenceDataType(state, "RiskConsequenceRating"),
  riskCategories: getReferenceDataType(state, "RiskCategory"),
  getRiskRatingDescription: (id) =>
    getReferenceDataDescription(state, "RiskRating", id, "-"),
});

export default connect(mapStateToProps)(RiskOverviewCard);
