import { requestJSON } from "./http";

const decorateSearchParameters = (filter) =>
  filter ? { __type: "Draft", ...filter } : { __type: "Draft" };

const create = (values, accessToken) =>
  requestJSON({ path: "/drafts", method: "POST", data: values, accessToken });

const patch = (draftId, changes, description, accessToken) =>
  requestJSON({
    path: `/drafts/${draftId}`,
    method: "PATCH",
    data: {
      fields: Object.keys(changes),
      changes,
      description,
    },
    accessToken,
  });

const remove = (draftId, accessToken) =>
  requestJSON({ path: `/drafts/${draftId}`, method: "DELETE", accessToken });

const search = (filter, accessToken) =>
  requestJSON({
    path: "/drafts/search",
    method: "POST",
    data: decorateSearchParameters(filter),
    accessToken,
  });

const count = (filter, accessToken) =>
  requestJSON({
    path: "/drafts/count",
    method: "POST",
    data: decorateSearchParameters(filter),
    accessToken,
  });

const getById = (id, accessToken) =>
  requestJSON({ path: `/drafts/${id}`, accessToken });

export default {
  create,
  search,
  count,
  getById,
  patch,
  remove,
};
