import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import MaterialAvatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Fade from "@material-ui/core/Fade";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import useTheme from "@material-ui/core/styles/useTheme";
import { connect } from "react-redux";
import { getLabels, getReferenceDataOptions } from "../../../../reducers";
import { deleteIcon } from "../../../../util/icons";
import AlertDialog from "../../../common/AlertDialog";
import CollapseExpand from "../../../CollapseExpand";
import Avatar from "../../../common/Avatar.tsx";
import Scorecard from "../../../common/Scorecard";
import GhostChip from "../../../composer/GhostChip";
import FormWrapper from "../../../forms/FormWrapper";
import QuestionResponseForm, {
  QUESTION_RESPONSE_FORM_NAME,
} from "./QuestionResponseForm";
import ListPicker from "../../../ListPicker";
import logger from "../../../../util/logger";
import RichTextMentionsView from "../../../richtext/RichTextMentionsView";
import FileIcon from "../../FileIcon";
import QuestionHandler from "../../../../util/questions";
import RatingPicker from "./RatingPicker";
import RatingNotesForm, { RATING_NOTES_FORM_NAME } from "./RatingNotesForm";

const useStyles = makeStyles((theme) => ({
  nowrap: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  chip: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1) / 2,
  },
  chipRoot: {
    maxWidth: "100%",
  },
  chipIcon: {
    marginLeft: 0,
  },
  documentAvatar: {
    backgroundColor: "transparent",
  },
  responseBody: {
    margin: theme.spacing(2),
    paddingLeft: theme.spacing(1),
  },
  questionActions: {
    display: "flex",
    justifyContent: "flex-end",
  },
  divider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  editDetails: {
    marginBottom: theme.spacing(2),
  },
  label: {
    position: "relative",
  },
  groupVertical: {
    marginTop: "-4px",
    marginBottom: "-8px",
  },
  optionVertical: {
    marginTop: "-8px",
    "&:first-child": {
      marginTop: 0,
    },
  },
}));

const questionHandler = new QuestionHandler();

const QuestionExpanded = ({
  readonly,
  allowResponses,
  allowRating,
  question,
  onResponse,
  onResponseRating,
  onUpdate,
  onDelete,
  onFileClick,
  setExpanded,
  datasource,
  loggedInUser,
  fieldLabels,
  justificationNotesInputOptions,
  attachmentsInputOptions,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [activeResponseUserId, setActiveResponseUserId] = useState(null);
  const [activeNotesResponseUserId, setActiveNotesResponseUserId] =
    useState(null);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [pickerOpen, setPickerOpen] = useState(false);
  const [responsesByUserId, setResponsesByUserId] = useState({});
  const [deleteResponder, setDeleteResponder] = useState(null);

  useEffect(() => {
    const responses = {};
    question.responses.forEach((response) => {
      responses[response.user.id] = response;
    });
    setResponsesByUserId(responses);
  }, [question]);

  const DeleteIcon = deleteIcon();

  const handleSubmitResponse = (values) => {
    logger.info("saving response", values, "for question", question.id);
    return onResponse(values).then(() => {
      setActiveResponseUserId(null);
      setTimeout(() => setExpanded(false), 600);
    });
  };

  const handleSubmitRatingNotes = (responderId, responseValues) => {
    const ratingNotes = responseValues.ratingNotes
      ? responseValues.ratingNotes
      : "";
    const values = {
      responderId,
      ratingNotes,
    };
    logger.info("saving rating notes", values, "for question", question.id);
    return onResponseRating(values).then(() => {
      setActiveNotesResponseUserId(null);
      setTimeout(() => setExpanded(false), 600);
    });
  };

  const handleRemoveResponder = (user) => {
    onUpdate({
      responders: question.responders.filter((u) => u.id !== user.id),
    }).then(() => {
      setActiveResponseUserId(null);
      setDeleteResponder(null);
    });
  };

  const updateResponders = (users) => {
    onUpdate({ responders: [...users] })
      .then(() => setPickerOpen(false))
      .then(() => setActiveResponseUserId(null));
  };

  const handleRatingChange = (responderId, responseRating) => {
    const values = {
      responderId,
      responseRating,
    };
    logger.info("saving response rating", values, "for question", question.id);
    return onResponseRating(values);
  };

  return (
    <>
      <AlertDialog
        title="Remove question"
        body="Are you sure you want to remove this question?"
        submitButtonText="Remove"
        open={removeDialogOpen}
        onCancel={() => setRemoveDialogOpen(false)}
        onSubmit={() => onDelete()}
      />
      <AlertDialog
        title="Remove responder"
        body="Are you sure you want to remove this responder?"
        submitButtonText="Remove"
        open={!!deleteResponder}
        onCancel={() => setDeleteResponder(null)}
        onSubmit={() => handleRemoveResponder(deleteResponder)}
      />
      <ListPicker
        title="Select responder"
        actionText="Select"
        open={pickerOpen}
        onClose={() => setPickerOpen(false)}
        onSubmit={(users) => updateResponders(users)}
        datasource={datasource}
        selected={question.responders}
        disabledOptions={question.responders}
        isMulti
        toOption={(user) => ({
          label: user.name || "Unknown",
          id: user.id,
          email: user.email,
        })}
        fromOption={(option) => ({
          name: option.label,
          id: option.id,
          email: option.email,
        })}
        renderIcon={(user, size) => (
          <Avatar email={user.email} name={user.name} size={size} round />
        )}
      />

      {!readonly && (
        <div className={classes.editDetails}>
          <FormControl component="fieldset" margin="dense" fullWidth>
            <InputLabel shrink className={classes.label}>
              {fieldLabels.labels.justificationNotesInput}
            </InputLabel>
            <RadioGroup
              classes={{
                root: classes.groupVertical,
              }}
              value={question.justificationNotesInput}
              onChange={(event) =>
                onUpdate({ justificationNotesInput: event.target.value })
              }
              margin="dense"
            >
              {justificationNotesInputOptions.map((option) => (
                <FormControlLabel
                  className={classes.optionVertical}
                  value={option.value}
                  key={option.value}
                  control={<Radio />}
                  label={option.label}
                />
              ))}
            </RadioGroup>
          </FormControl>

          <FormControl component="fieldset" margin="dense" fullWidth>
            <InputLabel shrink className={classes.label}>
              {fieldLabels.labels.attachmentsInput}
            </InputLabel>
            <RadioGroup
              classes={{
                root: classes.groupVertical,
              }}
              value={question.attachmentsInput}
              onChange={(event) =>
                onUpdate({ attachmentsInput: event.target.value })
              }
              margin="dense"
            >
              {attachmentsInputOptions.map((option) => (
                <FormControlLabel
                  className={classes.optionVertical}
                  value={option.value}
                  key={option.value}
                  control={<Radio />}
                  label={option.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </div>
      )}

      <InputLabel shrink className={classes.label}>
        {fieldLabels.labels.responses}
      </InputLabel>
      {questionHandler.sortQuestionResponses(question).map((user, index) => {
        const formOpen = activeResponseUserId === user.id;
        const response = responsesByUserId[user.id];
        const isCurrentUser = loggedInUser && loggedInUser.id === user.id;
        const ratingFormOpen = activeNotesResponseUserId === user.id;

        return (
          <div key={user.id}>
            {index > 0 && <Divider className={classes.divider} />}

            <Chip
              className={classes.chip}
              classes={{
                root: classes.chipRoot,
                label: classes.nowrap,
                icon: classes.chipIcon,
              }}
              icon={
                <Avatar
                  color={questionHandler.getResponseColor(
                    theme,
                    user,
                    question.responses
                  )}
                  email={user.email}
                  name={user.name}
                  size={30}
                  round
                />
              }
              label={user.name}
              onDelete={
                readonly
                  ? null
                  : () => {
                      setDeleteResponder(user);
                    }
              }
            />
            <div className={classes.responseBody}>
              {formOpen && (
                <Fade in timeout={1000}>
                  <FormWrapper
                    submitButtonText="Save"
                    formComponent={QuestionResponseForm}
                    formName={QUESTION_RESPONSE_FORM_NAME}
                    onSubmit={handleSubmitResponse}
                    onCancel={() => setActiveResponseUserId(null)}
                    initialValues={responsesByUserId[user.id]}
                    justificationNotesInput={question.justificationNotesInput}
                    attachmentsInput={question.attachmentsInput}
                  />
                </Fade>
              )}
              {!formOpen && response && (
                <>
                  <Scorecard
                    label={fieldLabels.nestedTypes.responses.labels.compliant}
                  >
                    {response.compliant ? "Yes" : "No"}
                  </Scorecard>
                  <Scorecard
                    label={
                      fieldLabels.nestedTypes.responses.labels
                        .justificationNotes
                    }
                  >
                    <CollapseExpand>
                      <RichTextMentionsView
                        value={response.justificationNotes}
                      />
                    </CollapseExpand>
                    {response.attachments.map((document) => (
                      <Chip
                        key={document.gcsObjectName}
                        className={classes.chip}
                        classes={{
                          root: classes.chipRoot,
                          label: classes.nowrap,
                        }}
                        avatar={
                          <MaterialAvatar className={classes.documentAvatar}>
                            <FileIcon document={document} />
                          </MaterialAvatar>
                        }
                        label={document.fileName}
                        onClick={() => onFileClick(document)}
                      />
                    ))}
                  </Scorecard>
                  {isCurrentUser && allowResponses && (
                    <Button
                      variant="text"
                      size="small"
                      color="primary"
                      onClick={() => setActiveResponseUserId(user.id)}
                    >
                      Edit response
                    </Button>
                  )}
                  {allowRating && (
                    <>
                      <Scorecard
                        label={
                          fieldLabels.nestedTypes.responses.labels
                            .responseRating
                        }
                      >
                        <RatingPicker
                          readonly={readonly}
                          response={response}
                          onChange={(rating) =>
                            handleRatingChange(user.id, rating)
                          }
                        />
                      </Scorecard>
                      {!ratingFormOpen && response.ratingNotes && (
                        <Scorecard
                          label={
                            fieldLabels.nestedTypes.responses.labels.ratingNotes
                          }
                        >
                          <CollapseExpand>
                            <RichTextMentionsView
                              value={response.ratingNotes}
                            />
                          </CollapseExpand>
                        </Scorecard>
                      )}
                      {ratingFormOpen && (
                        <Fade in timeout={1000}>
                          <FormWrapper
                            submitButtonText="Save"
                            formComponent={RatingNotesForm}
                            formName={RATING_NOTES_FORM_NAME}
                            onSubmit={(values) =>
                              handleSubmitRatingNotes(user.id, values)
                            }
                            onCancel={() => setActiveNotesResponseUserId(null)}
                            initialValues={responsesByUserId[user.id]}
                          />
                        </Fade>
                      )}
                      {!ratingFormOpen && response.ratingNotes && (
                        <Button
                          variant="text"
                          size="small"
                          color="primary"
                          onClick={() => setActiveNotesResponseUserId(user.id)}
                        >
                          Edit rating notes
                        </Button>
                      )}
                      {!ratingFormOpen && !response.ratingNotes && (
                        <Button
                          variant="text"
                          size="small"
                          color="primary"
                          onClick={() => setActiveNotesResponseUserId(user.id)}
                        >
                          Add rating notes
                        </Button>
                      )}
                    </>
                  )}
                </>
              )}
              {!formOpen && !response && isCurrentUser && allowResponses && (
                <Button
                  variant="text"
                  size="small"
                  color="primary"
                  onClick={() => setActiveResponseUserId(user.id)}
                >
                  Add response
                </Button>
              )}
              {!formOpen && !response && !isCurrentUser && (
                <span>No response yet</span>
              )}
            </div>
          </div>
        );
      })}
      {!readonly && (
        <GhostChip label="Add responder" onClick={() => setPickerOpen(true)} />
      )}
      {!readonly && (
        <div className={classes.questionActions}>
          <Button
            variant="text"
            size="small"
            color="default"
            onClick={() => setRemoveDialogOpen(true)}
          >
            <DeleteIcon />
            &nbsp;Delete
          </Button>
        </div>
      )}
    </>
  );
};

QuestionExpanded.propTypes = {
  readonly: PropTypes.bool.isRequired,
  question: PropTypes.object.isRequired,
  allowResponses: PropTypes.bool.isRequired,
  allowRating: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onResponse: PropTypes.func.isRequired,
  onResponseRating: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onFileClick: PropTypes.func.isRequired,
  setExpanded: PropTypes.func.isRequired,
  datasource: PropTypes.oneOfType([PropTypes.func, PropTypes.array]).isRequired,
  loggedInUser: PropTypes.object,

  // redux
  fieldLabels: PropTypes.object.isRequired,
  justificationNotesInputOptions: PropTypes.array.isRequired,
  attachmentsInputOptions: PropTypes.array.isRequired,
};

QuestionExpanded.defaultProps = {
  loggedInUser: null,
};

const mapStateToProps = (state) => ({
  fieldLabels: getLabels(state).Question,
  justificationNotesInputOptions: getReferenceDataOptions(
    state,
    "JustificationNotesInput"
  ),
  attachmentsInputOptions: getReferenceDataOptions(state, "AttachmentsInput"),
});

export default connect(mapStateToProps)(QuestionExpanded);
