import React from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CheckCircle from "@material-ui/icons/CheckCircle";
import PropTypes from "prop-types";

const styles = (theme) => ({
  successPanel: {
    width: "600px",
    textAlign: "center",
  },
  refNumber: {
    marginBottom: theme.spacing(4),
  },
  button: {
    marginLeft: "10px",
    "&:first-child": {
      marginLeft: 0,
    },
  },
  successIcon: {
    fontSize: "80px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
});

const CreateRiskSuccess = (props) => {
  const { classes, originLink, originLinkLabel, itemLink, friendlyId } = props;

  return (
    <Grid container alignItems="center" direction="row" justifyContent="center">
      <Paper style={{ padding: 24 }} elevation={0}>
        <div className={classes.successPanel}>
          <Typography variant="h4" data-cy="RiskCreatedSuccessfully">
            The risk has been created.
          </Typography>
          <CheckCircle className={classes.successIcon} data-cy="checkCircle" />
          <Typography
            className={classes.refNumber}
            variant="body1"
            data-cy="referenceNumber"
          >
            Reference Number: {friendlyId}
          </Typography>
          <div>
            <Button
              className={classes.button}
              component={Link}
              to={itemLink}
              variant="contained"
              color="primary"
              size="large"
              data-cy="viewRisk"
            >
              View Risk
            </Button>
            <Button
              className={classes.button}
              component={Link}
              to={originLink}
              variant="contained"
              color="default"
              size="large"
              data-cy={originLinkLabel}
            >
              {originLinkLabel}
            </Button>
          </div>
        </div>
      </Paper>
    </Grid>
  );
};

CreateRiskSuccess.propTypes = {
  classes: PropTypes.object.isRequired,
  originLink: PropTypes.string.isRequired,
  originLinkLabel: PropTypes.string.isRequired,
  itemLink: PropTypes.string.isRequired,
  friendlyId: PropTypes.number.isRequired,
};

export default withStyles(styles)(CreateRiskSuccess);
