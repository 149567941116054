import { useAuth0 } from "@auth0/auth0-react";
import { Card } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { clearActiveMedia, createMedia } from "../../../actions/media";
import BreadcrumbLink from "../../../components/common/BreadcrumbLink";
import Container from "../../../components/common/Container";
import HeaderBar from "../../../components/common/HeaderBar";
import PageHeading from "../../../components/common/PageHeading";
import { getEntityRelationshipInitialValues } from "../../../components/forms/business/EntityRelationshipFields";
import FormWrapper from "../../../components/forms/FormWrapper";
import MediaForm, {
  MEDIA_FORM_NAME,
} from "../../../components/forms/metropolis/MediaForm";
import CreateMediaSuccess from "../../../components/metropolis/CreateMediaSuccess";
import { getActiveMedia, getLoggedInUser } from "../../../reducers";
import logger from "../../../util/logger";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  contents: {
    marginTop: theme.spacing(2),
    marginBottom: "75px",
  },
}));

const New = ({
  loggedInUser,
  localCreateMedia,
  localClearActiveMedia,
  createdMedia,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    localClearActiveMedia();

    getEntityRelationshipInitialValues(loggedInUser).then(
      (entityRelationship) => {
        logger.debug(
          "async setting initial values to (entityRelationship) ",
          entityRelationship
        );
        setInitialValues({
          entityRelationship,
          publishedToApi: false,
        });
      }
    );
  }, []);

  const handleSubmission = async (values) => {
    logger.debug("submitting media with values", values);
    const accessToken = await getAccessTokenSilently();
    return localCreateMedia(values, accessToken);
  };

  return (
    <div className={classes.root}>
      <HeaderBar narrow>
        <BreadcrumbLink to="/metropolis" label="Content" includeArrow />
        <PageHeading heading="Create new media" data-cy="createNewMedia" />
      </HeaderBar>
      <Container narrow className={classes.contents}>
        {createdMedia && (
          <CreateMediaSuccess
            originLink="/metropolis"
            originLinkLabel="Return to content"
            itemLink={`/metropolis/media/${createdMedia.id}`}
            publicUrl={createdMedia.publicUrl}
          />
        )}
        {!createdMedia && (
          <Card>
            <CardContent>
              {initialValues && (
                <FormWrapper
                  submitButtonText="Create"
                  formComponent={MediaForm}
                  formName={MEDIA_FORM_NAME}
                  onSubmit={handleSubmission}
                  initialValues={initialValues}
                  data-cy={MediaForm}
                  getAccessToken={getAccessTokenSilently}
                />
              )}
            </CardContent>
          </Card>
        )}
      </Container>
    </div>
  );
};

New.propTypes = {
  loggedInUser: PropTypes.object,
  createdMedia: PropTypes.object,
  localCreateMedia: PropTypes.func.isRequired,
  localClearActiveMedia: PropTypes.func.isRequired,
};

New.defaultProps = {
  loggedInUser: null,
  createdMedia: null,
};

const mapStateToProps = (state) => ({
  createdMedia: getActiveMedia(state),
  loggedInUser: getLoggedInUser(state),
});

export default connect(mapStateToProps, {
  localCreateMedia: createMedia,
  localClearActiveMedia: clearActiveMedia,
})(New);
