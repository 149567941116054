import { withStyles } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import * as PropTypes from "prop-types";
import React, { useState } from "react";

const styles = (theme) => ({
  label: {
    position: "relative",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    marginTop: theme.spacing(1) - theme.spacing(1) / 4,
    marginBottom: theme.spacing(1) - theme.spacing(1) / 4,
  },
  borderBottom: {
    borderBottom: `solid 1px ${theme.palette.grey[500]}`,
    paddingBottom: "1px",
    "&:hover": {
      borderBottom: "solid 2px",
      paddingBottom: 0,
    },
  },
  addButton: {
    paddingTop: theme.spacing(1) / 4,
    marginRight: 0,
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1) / 4,
    marginTop: theme.spacing(1) / 4,
  },
});

const WrappedCustomSelect = ({
  classes,
  className,
  input,
  label,
  options,
  margin,
  helperText,
  disabled,
  required,
  renderListItem,
  renderValue,
  addIcon: AddIcon,
  meta: { touched, error, invalid },
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const onOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  const onSelect = (value) => {
    input.onChange(value);
    setAnchorEl(null);
  };

  return (
    <FormControl
      className={className}
      fullWidth
      margin={margin}
      name={`position-${input.name}`}
    >
      <InputLabel
        className={classes.label}
        shrink
        required={required}
        data-cy={label}
      >
        {label}
      </InputLabel>
      <div className={classes.borderBottom}>
        <div
          className={classes.container}
          onClick={disabled ? null : (e) => onOpenMenu(e)}
          onKeyPress={disabled ? null : (e) => onOpenMenu(e)}
        >
          {renderValue(input.value)}
          <div className={classes.addButton}>
            <AddIcon />
          </div>
        </div>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => onCloseMenu()}
        >
          {options.map((option, index) => (
            <MenuItem
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              value={option}
              onClick={() => onSelect(option)}
              selected={input.value === option}
            >
              {renderListItem(option)}
            </MenuItem>
          ))}
        </Menu>
      </div>
      {touched && error && (
        <FormHelperText error={touched && invalid}>{error}</FormHelperText>
      )}
      {!(touched && error) && helperText && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

WrappedCustomSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  input: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  renderValue: PropTypes.func.isRequired,
  renderListItem: PropTypes.func.isRequired,
  margin: PropTypes.string,
  helperText: PropTypes.any,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  addIcon: PropTypes.object,
};

WrappedCustomSelect.defaultProps = {
  className: null,
  margin: "dense",
  helperText: "",
  disabled: false,
  required: false,
  addIcon: ArrowDropDown,
};

export default withStyles(styles)(WrappedCustomSelect);
