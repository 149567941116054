import { requestJSON } from "./http";
import { BusinessServiceArea } from "../../model/BusinessServiceArea";

const decorateSearchParameters = (filter: SearchParams) =>
  filter
    ? { __type: "BusinessServiceArea", ...filter }
    : { __type: "BusinessServiceArea" };

interface SearchParams {
  limit?: number;
  offset?: number;
  orderByField?: string;
  textSearch?: string;
}

interface SearchResults {
  results: BusinessServiceArea[];
}

const create = (
  bsa: BusinessServiceArea,
  accessToken: string
): Promise<BusinessServiceArea> =>
  requestJSON({
    path: "/business-service-areas",
    method: "POST",
    data: bsa,
    accessToken,
  });

// with pagination
const search = (
  searchParams: SearchParams,
  abortController: AbortController,
  accessToken: string
): Promise<SearchResults> =>
  requestJSON({
    path: "/business-service-areas/search",
    method: "POST",
    data: decorateSearchParameters(searchParams),
    abortController,
    accessToken,
  });

const getById = (
  id: string,
  accessToken: string
): Promise<BusinessServiceArea> =>
  requestJSON({
    path: `/business-service-areas/${id}`,
    accessToken,
  });

const patch = (
  id: string,
  changes: Record<string, unknown>,
  description: string,
  accessToken: string
): Promise<BusinessServiceArea> =>
  requestJSON({
    path: `/business-service-areas/${id}`,
    method: "PATCH",
    data: {
      fields: Object.keys(changes),
      changes,
      description,
    },
    accessToken,
  });

export default {
  create,
  search,
  getById,
  patch,
};
