import { useAuth0 } from "@auth0/auth0-react";
import { authorizer, useTenant } from "@certane/arcadia-web-components";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import ActionButton from "../../components/common/ActionButton";
import ActionHeading from "../../components/common/ActionHeading";
import Container from "../../components/common/Container";
import FloatingActionButton from "../../components/common/FloatingActionButton";
import HeaderBar from "../../components/common/HeaderBar";
import PageHeading from "../../components/common/PageHeading";
import ControlFilters from "../../components/controls/ControlFilters";
import ControlListing from "../../components/controls/ControlListing";
import { getControls, getLoggedInUser } from "../../reducers";
import { clearIcon, downloadIcon, riskControlIcon } from "../../util/icons";
import useLocationStateFilter from "../../util/locationStateFilter";
import usePersistedState from "../../util/persistedState";
import { performExport } from "../../util/asyncRequestHelper";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  contents: {
    marginBottom: "75px",
  },
}));

const defaultFilter = {
  textSearch: null,
  ownerIds: [],
  statuses: [],
};

const Index = ({ history, loggedInUser, controls }) => {
  const { getAccessTokenSilently, user } = useAuth0();
  const { tenant } = useTenant();
  const classes = useStyles();
  const [downloadingFormat, setDownloadingFormat] = useState(null);
  const [sortBy, setSortBy] = usePersistedState(
    `control-listing-sort-${loggedInUser.email}`,
    {
      field: "title",
      direction: "asc",
    }
  );
  const [filter, setFilter] = usePersistedState(
    `control-listing-filter-${loggedInUser.email}`,
    defaultFilter,
    ["textSearch"]
  );
  useLocationStateFilter(setFilter, history);

  const clearFilter = () => setFilter({ ...defaultFilter });
  const updateFilter = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  const getSearchParameters = () => ({
    ...filter,
    orderByField: `${sortBy.direction === "desc" ? "-" : ""}${sortBy.field}`,
    limit: 100,
    offset: 0,
  });

  const onDownload = async (format) => {
    const accessToken = await getAccessTokenSilently();
    setDownloadingFormat(format);
    const searchParameters = getSearchParameters();
    performExport(
      "Control",
      searchParameters,
      format,
      accessToken,
      () => setDownloadingFormat(null),
      "Controls download",
      "Please wait while we export your controls",
      "This window can be closed"
    );
  };

  const actions = [
    <ActionButton
      key="clearFilter"
      tooltip="Clear filters"
      icon={clearIcon()}
      onClick={clearFilter}
      data-cy="clear-filters-button"
    />,
  ];
  const csvDownloadEnabled = true;

  if (csvDownloadEnabled) {
    actions.push(
      <ActionButton
        tooltip="Download"
        icon={downloadIcon()}
        onClick={() => onDownload("CSV")}
        loading={downloadingFormat === "CSV"}
        disabled={controls.length === 0}
      />
    );
  }

  return (
    <div>
      <HeaderBar fluid>
        <ActionHeading
          heading={<PageHeading icon={riskControlIcon()} heading="Controls" />}
          actions={actions}
        />
      </HeaderBar>
      {authorizer.check("control", "editor", user, tenant?.id) && (
        <FloatingActionButton onClick={() => history.push("/controls/new")} />
      )}
      <ControlFilters filter={filter} updateFilter={updateFilter} />
      <Container fluid className={classes.contents}>
        <ControlListing
          history={history}
          filter={filter}
          clearFilter={clearFilter}
          sortBy={sortBy}
          updateSort={setSortBy}
        />
      </Container>
    </div>
  );
};

Index.propTypes = {
  history: PropTypes.object.isRequired,
  loggedInUser: PropTypes.object,
  controls: PropTypes.array.isRequired,
};

Index.defaultProps = {
  loggedInUser: null,
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
  controls: getControls(state),
});

export default connect(mapStateToProps)(Index);
