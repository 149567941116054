import _ from "lodash";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { getRefDataMenuItems } from "../forms/menuItems";

const styles = () => ({});

const MultiSelectDropdown = ({ onChange, options, value, closeOnChange }) => {
  const [open, setOpen] = useState(false);
  const [stateValue, setStateValue] = useState(value);

  const getLabel = () => {
    if (value.length > 1) {
      return `${value.length} selected`;
    }
    return value.map((typeId) => options.values[typeId].description).join(", ");
  };

  const setValue = (event) => {
    const { value: newValue } = event.target;
    setStateValue(newValue);
    if (
      closeOnChange ||
      newValue.length === 0 ||
      newValue.length === options.ids.length
    ) {
      setOpen(false);
      onChange(newValue);
    }
  };

  const setClose = () => {
    setOpen(false);
    if (!_.isEqual(value, stateValue)) {
      onChange(stateValue);
    }
  };

  return (
    <Select
      multiple
      value={stateValue}
      onChange={setValue}
      input={<Input />}
      fullWidth
      renderValue={getLabel}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={setClose}
    >
      {getRefDataMenuItems(options)}
    </Select>
  );
};

MultiSelectDropdown.propTypes = {
  value: PropTypes.array.isRequired,
  options: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  closeOnChange: PropTypes.bool.isRequired,
};

export default withStyles(styles)(MultiSelectDropdown);
