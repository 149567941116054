import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";
import { required } from "redux-form-validators";
import { getLoggedInUser, getReferenceDataType } from "../../../reducers";
import usersApi from "../../../services/api/users";
import { roles } from "@certane/arcadia-web-components";
import HelpPopover from "../../common/HelpPopover";
import { getRefDataMenuItems } from "../menuItems";
import WrappedSelect from "../wrapper/WrappedSelect";
import WrappedSwitch from "../wrapper/WrappedSwitch";
import WrappedTextField from "../wrapper/WrappedTextField";
import WrappedOwnerPicker from "../wrapper/WrappedOwnerPicker";
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles((theme) => ({
  helpPopover: {
    padding: theme.spacing(2),
    maxWidth: theme.breakpoints.values.md,
  },
}));

const RiskControlDetailFields = ({
  riskControlTypes,
  operationalFrequencyTypes,
  fieldLabels,
  loggedInUser,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();
  const asyncOwnerOptionsFetch = async (
    pickerFilter,
    pagination,
    abortController
  ) => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = {
      ...pickerFilter,
      limit: pagination.pageSize,
      offset: pagination.offset,
      roles: roles.AGENT_ROLES,
      orderByField: "name",
    };
    return usersApi.search(searchParameters, abortController, accessToken);
  };

  return (
    <>
      <Field
        name="type"
        component={WrappedSelect}
        label={fieldLabels.labels.type}
        validate={required({ msg: `${fieldLabels.labels.type} is required` })}
        fullWidth
        required
        helperText={
          <>
            <span>Get help with </span>
            <HelpPopover linkText={fieldLabels.labels.type.toLowerCase()}>
              <div className={classes.helpPopover}>
                <Typography variant="h5" gutterBottom>
                  {fieldLabels.labels.type}
                </Typography>
                <Typography variant="body1" component="div">
                  A control is an activity, device, documentation that is
                  designed to prevent, detect or reduce the likelihood of risk
                  materializing or to reduce the impact of the risk, if it
                  materializes. There are three types of controls:
                  <ul>
                    <li>
                      Preventative controls are controls that are designed to
                      avert a negative event from occurring. Preventative
                      controls are proactive by design and help prevent loss.
                    </li>
                    <li>
                      Detective controls are designed to identify positive or
                      negative events once its occurred. Detective controls
                      provide evidence that a loss has occurred but do not
                      prevent a loss from occurring.
                    </li>
                    <li>
                      Corrective controls are functional after detective
                      controls have discovered a problem.
                    </li>
                  </ul>
                </Typography>
              </div>
            </HelpPopover>
          </>
        }
      >
        {getRefDataMenuItems(riskControlTypes)}
      </Field>
      <Field
        name="critical"
        label={fieldLabels.labels.critical}
        component={WrappedSwitch}
        validate={required({
          msg: `${fieldLabels.labels.critical} is required`,
        })}
        required
      />
      <Field
        name="owner"
        component={WrappedOwnerPicker}
        datasource={asyncOwnerOptionsFetch}
        required
        validate={required({ msg: `${fieldLabels.labels.owner} is required` })}
        label={fieldLabels.labels.owner}
        fullWidth
        loggedInUser={loggedInUser}
      />
      <Field
        name="name"
        component={WrappedTextField}
        label={fieldLabels.labels.name}
        validate={required({ msg: `${fieldLabels.labels.type} is required` })}
        fullWidth
        required
      />
      <Field
        name="description"
        component={WrappedTextField}
        label={fieldLabels.labels.description}
        multiline
        validate={required({
          msg: `${fieldLabels.labels.description} is required`,
        })}
        rowsMax={4}
        rows={2}
        fullWidth
        required
        helperText={
          <>
            <span>Get help with </span>
            <HelpPopover
              linkText={fieldLabels.labels.description.toLowerCase()}
            >
              <div className={classes.helpPopover}>
                <Typography variant="h5" gutterBottom>
                  {fieldLabels.labels.description}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  This is a detailed statement what the control is and how it
                  works.
                </Typography>
                <Typography variant="body1" component="div">
                  Things to consider:
                  <ul>
                    <li>How and how often the control operates?</li>
                    <li>How does the control mitigate its risks associated?</li>
                    <li>
                      Will the control effectively address sources or
                      consequences of risks associated?
                    </li>
                  </ul>
                </Typography>
              </div>
            </HelpPopover>
          </>
        }
      />
      <Field
        name="operationalFrequency"
        component={WrappedSelect}
        label={fieldLabels.labels.operationalFrequency}
        fullWidth
        helperText={
          <>
            <span>Get help with </span>
            <HelpPopover
              linkText={fieldLabels.labels.operationalFrequency.toLowerCase()}
            >
              <div className={classes.helpPopover}>
                <Typography variant="h5" gutterBottom>
                  {fieldLabels.labels.operationalFrequency}
                </Typography>
                <Typography variant="body1">
                  How often the control is performed eg. Daily, Weekly, Monthly,
                  Quarterly, Half-yearly, Yearly etc.
                </Typography>
                <Typography variant="body1">
                  If the control is performed ad-hoc, an estimate or approximate
                  frequency should be used.
                </Typography>
              </div>
            </HelpPopover>
          </>
        }
      >
        {getRefDataMenuItems(operationalFrequencyTypes)}
      </Field>
    </>
  );
};

RiskControlDetailFields.propTypes = {
  loggedInUser: PropTypes.object.isRequired,
  fieldLabels: PropTypes.object.isRequired,

  // redux
  riskControlTypes: PropTypes.object.isRequired,
  operationalFrequencyTypes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
  riskControlTypes: getReferenceDataType(state, "RiskControlType"),
  operationalFrequencyTypes: getReferenceDataType(
    state,
    "OperationalFrequencyType"
  ),
});

export default connect(mapStateToProps, {})(RiskControlDetailFields);
