import moment from "moment";

const LOCAL_DATE_FORMAT = "YYYY-MM-DD";
const LONG_DATE_DISPLAY_FORMAT = "ddd DD MMM YYYY";

const TIMESTAMP_DISPLAY_FORMAT = "DD/MM/YYYY hh:mma";
const TIMESTAMP_FORMAT = "YYYY-MM-DDTHH:mm:ss.sssZ";

const parseDate = (str) =>
  (str && moment(str, LOCAL_DATE_FORMAT).toDate()) || null;
const monthNumToShortName = (num) =>
  (num && moment(num, "MM").format("MMM")) || null;
const monthNumToLongName = (num) =>
  (num && moment(num, "MM").format("MMMM")) || null;
const shortYearMonthDate = (date) =>
  `${monthNumToShortName(date.split("-")[1])} ${date.split("-")[0]}`;
const shortDayMonthDate = (date) =>
  `${date.split("-")[2]} ${monthNumToShortName(date.split("-")[1])}`;
const serializeDate = (date) =>
  (date && moment(date).format(LOCAL_DATE_FORMAT)) || date;
const formatDateLong = (date) =>
  (date && moment(date).format(LONG_DATE_DISPLAY_FORMAT)) || date;
const formatDate = (date, format) =>
  (date && moment(date).format(format)) || date;
const dateIsBeforeToday = (date) =>
  (date && moment(date).isBefore(moment().startOf("day"))) || false;
const dateIsBeforeThisMonth = (date) =>
  (date && moment(date).isBefore(moment().startOf("month"))) || false;
const dateIsAfterEndOfNextYear = (date) =>
  (date && moment(date).isAfter(moment().add(1, "y"), "year")) || false;
const compareDates = (date1, date2) => moment(date1).isAfter(moment(date2));
const areSame = (date1, date2) => moment(date1).isSame(moment(date2));
const daysFromNow = (days) => moment().add(days, "days");
const timeAgoFromSeconds = (seconds) =>
  !seconds ? "-" : moment().seconds(seconds).fromNow(true);
const toFirstDayOfMonth = (date) =>
  (date && moment(date).startOf("month")).toDate() || false;
const startOfLastYear = () =>
  moment().subtract(1, "year").startOf("year").toDate();
const endOfNextYear = () => moment().add(1, "year").endOf("year").toDate();
const hourDifference = (date) =>
  Math.floor(moment.duration(moment(date).diff(moment(new Date()))).asHours()) +
  1;
const dayDifference = (date) =>
  Math.floor(moment.duration(moment(date).diff(moment(new Date()))).asDays()) +
  1;

const parseTimestamp = (str) => (str && new Date(Date.parse(str))) || str;
const serializeTimestamp = (date) =>
  (date && moment(date).format(TIMESTAMP_FORMAT)) || date;
const formatTimestamp = (date) =>
  (date && moment(date).format(TIMESTAMP_DISPLAY_FORMAT)) || date;
const minutesToHours = (minutes) => Math.floor(minutes / 60);
const hoursToBusinessDays = (hours) => Math.ceil(hours / 8);

export default {
  parseDate,
  shortYearMonthDate,
  shortDayMonthDate,
  monthNumToShortName,
  monthNumToLongName,
  daysFromNow,
  toFirstDayOfMonth,
  serializeDate,
  formatDateLong,
  formatDate,
  compareDates,
  areSame,
  hourDifference,
  dayDifference,
  dateIsBeforeToday,
  dateIsBeforeThisMonth,
  dateIsAfterEndOfNextYear,
  parseTimestamp,
  formatTimestamp,
  serializeTimestamp,
  startOfLastYear,
  endOfNextYear,
  minutesToHours,
  hoursToBusinessDays,
  timeAgoFromSeconds,
};
