import PropTypes from "prop-types";
import React from "react";
import { FormattedDate } from "react-intl";
import Moment from "react-moment";
import Time from "./Time";

class TimeAgo extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showFull: props.startFull };
  }

  toggleShowFull = () => {
    this.setState({ showFull: !this.state.showFull });
  };

  render() {
    const { value, expandable } = this.props;
    const { showFull } = this.state;
    return (
      <span className="time-ago">
        {expandable && !showFull && (
          <Moment
            date={value}
            fromNow
            onClick={this.toggleShowFull}
            style={{ cursor: "pointer" }}
          />
        )}
        {expandable && showFull && (
          <span
            role="button"
            onClick={this.toggleShowFull}
            onKeyPress={this.toggleShowFull}
            tabIndex={0}
            style={{ cursor: "pointer" }}
          >
            <FormattedDate value={value} /> <Time value={value} />
          </span>
        )}
        {!expandable && <Moment date={value} fromNow />}
      </span>
    );
  }
}

TimeAgo.propTypes = {
  value: PropTypes.instanceOf(Date).isRequired,
  expandable: PropTypes.bool,
  startFull: PropTypes.bool,
};

TimeAgo.defaultProps = {
  expandable: false,
  startFull: false,
};

export default TimeAgo;
