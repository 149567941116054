import PropTypes from "prop-types";
import React from "react";
import FormCard from "../../../common/FormCard";
import EditWorkItemComponentForm, {
  EDIT_WORK_ITEM_COMPONENT_FORM_NAME,
} from "../EditWorkItemComponentForm";
import IncidentSummaryFieldsReadOnly from "./IncidentSummaryFieldsReadOnly";

const IncidentCard = ({
  className,
  workItem,
  onChange,
  customisations,
  fieldLabels,
}) => {
  return (
    <FormCard
      className={className}
      title="Incident details"
      formTitle="Edit incident details"
      readOnlyView={() => (
        <IncidentSummaryFieldsReadOnly
          workItem={workItem}
          customisations={customisations}
          fieldLabels={fieldLabels}
        />
      )}
      formComponent={EditWorkItemComponentForm}
      formName={EDIT_WORK_ITEM_COMPONENT_FORM_NAME}
      onSave={onChange}
      initialValues={{ incident: workItem.incident }}
      formProps={{
        maxWidth: "md",
        workItem,
        componentName: "INCIDENT",
      }}
    />
  );
};

IncidentCard.propTypes = {
  className: PropTypes.string,
  workItem: PropTypes.object.isRequired,
  customisations: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  fieldLabels: PropTypes.object.isRequired,
};

IncidentCard.defaultProps = {
  className: undefined,
  onChange: null,
};

export default IncidentCard;
