import Fab from "@material-ui/core/Fab";
import Menu from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import PropTypes from "prop-types";
import React from "react";

const styles = (theme) => ({
  addButton: {
    position: "fixed",
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    zIndex: theme.zIndex.appBar,
  },
});

class FloatingActionButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  handleButtonClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleMenuItemClick = (clickHandler) => () => {
    this.setState({ anchorEl: null });
    clickHandler();
  };

  render() {
    const { anchorEl } = this.state;
    const { classes, children, onClick } = this.props;

    const showMenu = !onClick;

    return (
      <div>
        <Fab
          className={classes.addButton}
          aria-label="Add work item"
          aria-owns={anchorEl ? "simple-menu" : null}
          aria-haspopup="true"
          variant="circular"
          color="primary"
          onClick={onClick || this.handleButtonClick}
          data-cy="addWorkItem"
        >
          <AddIcon />
        </Fab>
        {showMenu && (
          <Menu
            data-cy="simpleMenu"
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleMenuClose}
          >
            {React.Children.map(children, (child) =>
              React.cloneElement(child, {
                onClick: this.handleMenuItemClick(child.props.onClick),
              })
            )}
          </Menu>
        )}
      </div>
    );
  }
}

FloatingActionButton.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.array,
  onClick: PropTypes.func,
};

FloatingActionButton.defaultProps = {
  children: null,
  onClick: null,
};

export default withStyles(styles)(FloatingActionButton);
