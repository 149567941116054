import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import ActionHeading from "../../../components/common/ActionHeading";
import Container from "../../../components/common/Container";
import FloatingActionButton from "../../../components/common/FloatingActionButton";
import HeaderBar from "../../../components/common/HeaderBar";
import PageHeading from "../../../components/common/PageHeading";
import { getLoggedInUser } from "../../../reducers";
import { roles } from "@certane/arcadia-web-components";
import { categoryIcon } from "../../../util/icons";
import usePersistedState from "../../../util/persistedState";
import CategoryListing from "../../../components/metropolis/categories/CategoryListing";
import categoriesApi from "../../../services/api/contentCategories";
import groupsApi from "../../../services/api/contentCategoryGroups";
import { publishToastSuccess } from "../../../services/toasts";
import { useAuth0 } from "@auth0/auth0-react";
import AlertDialog from "../../../components/common/AlertDialog";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  contents: {
    marginBottom: "75px",
  },
}));

const Index = ({ loggedInUser }) => {
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();
  const [categories, setCategories] = useState([]);
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categoryToBeDeleted, setCategoryToBeDeleted] = useState(null);
  const [sortBy, setSortBy] = usePersistedState(
    `category-listing-sort-${loggedInUser.email}`,
    {
      field: "name",
      direction: "asc",
    }
  );

  const fetch = async () => {
    setLoading(true);
    const accessToken = await getAccessTokenSilently();
    const categoriesPromise = categoriesApi.list(accessToken);
    const groupsPromise = groupsApi.list(accessToken);
    setCategories(await categoriesPromise);
    setGroups(await groupsPromise);
    setLoading(false);
  };

  const patchCategory = async (category, changes) => {
    const accessToken = await getAccessTokenSilently();
    const updated = await categoriesApi.update(
      category.id,
      changes,
      "updated",
      accessToken
    );
    setCategories(
      categories.map((c) => {
        if (c.id === updated.id) {
          return updated;
        }
        return c;
      })
    );
    publishToastSuccess("Successfully saved");
  };

  const patchGroup = async (group, changes) => {
    const accessToken = await getAccessTokenSilently();
    const updated = await groupsApi.update(
      group.id,
      changes,
      "updated",
      accessToken
    );
    setGroups(
      groups.map((g) => {
        if (g.id === updated.id) {
          return updated;
        }
        return g;
      })
    );
    publishToastSuccess("Successfully saved");
  };

  const createNewCategory = async () => {
    const accessToken = await getAccessTokenSilently();
    const created = await categoriesApi.create(
      {
        name: "<New category>",
        sortOrder: 100,
      },
      accessToken
    );
    setCategories([created, ...categories]);
    publishToastSuccess("Successfully created");
  };

  const createNewGroup = async () => {
    const accessToken = await getAccessTokenSilently();
    const created = await groupsApi.create(
      {
        name: "<New group>",
        categories: [],
      },
      accessToken
    );
    setGroups([created, ...groups]);
    publishToastSuccess("Successfully created");
  };

  const removeCategory = async () => {
    const accessToken = await getAccessTokenSilently();
    await categoriesApi.remove(categoryToBeDeleted.id, accessToken);
    setCategories(categories.filter((c) => c.id !== categoryToBeDeleted.id));
    publishToastSuccess("Successfully deleted");
    setCategoryToBeDeleted(null);
  };

  const removeGroup = async (group) => {
    const accessToken = await getAccessTokenSilently();
    await groupsApi.remove(group.id, accessToken);
    setGroups(groups.filter((g) => g.id !== group.id));
    publishToastSuccess("Successfully deleted");
  };

  useEffect(() => {
    fetch();
  }, []);

  const canEdit = loggedInUser
    ? _.intersection(
        [
          ...roles.ADMIN_ROLES,
          roles.COMPLIANCE_OWNER,
          roles.COMPLIANCE_OFFICER,
        ],
        loggedInUser.roles
      ).length > 0
    : false;

  const canDelete = loggedInUser
    ? _.intersection(roles.ADMIN_ROLES, loggedInUser.roles).length > 0
    : false;

  return (
    <div className={classes.root}>
      <AlertDialog
        title="Delete category?"
        body={`Are you sure you want to delete category '${categoryToBeDeleted?.name}'?`}
        submitButtonText="Delete"
        open={!!categoryToBeDeleted}
        onCancel={() => setCategoryToBeDeleted(null)}
        onSubmit={removeCategory}
        data-cy="deleteCategory"
      />
      <HeaderBar>
        <ActionHeading
          heading={
            <PageHeading
              icon={categoryIcon()}
              heading="Content Categories"
              data-cy="content"
            />
          }
          actions={[]}
        />
      </HeaderBar>
      {canEdit && <FloatingActionButton onClick={createNewCategory} />}
      <Container className={classes.contents}>
        <CategoryListing
          categories={categories}
          groups={groups}
          loading={loading}
          sortBy={sortBy}
          updateSort={setSortBy}
          onUpdateCategory={canEdit && patchCategory}
          onDeleteCategory={canDelete && setCategoryToBeDeleted}
          onCreateGroup={canEdit && createNewGroup}
          onUpdateGroup={canEdit && patchGroup}
          onDeleteGroup={canDelete && removeGroup}
          data-cy="category-listing"
        />
      </Container>
    </div>
  );
};

Index.propTypes = {
  loggedInUser: PropTypes.object,
};

Index.defaultProps = {
  loggedInUser: null,
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
});

export default compose(connect(mapStateToProps))(Index);
