import Input from "@material-ui/core/Input";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { addIcon } from "../../util/icons";
import MultilineText from "./MultilineText";

const InlineInput = ({
  multiline,
  value,
  validate,
  onChange,
  startInEditMode,
  ...rest
}) => {
  const [editMode, setEditMode] = useState(startInEditMode);
  const [localValue, setLocalValue] = useState(value);
  const [invalid, setInvalid] = useState(false);

  const AddIcon = addIcon();

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const onBlur = () => {
    if (localValue !== value) {
      if (!validate || validate(localValue)) {
        onChange(localValue)
          .then(() => setEditMode(false))
          .catch(() => {
            setEditMode(false);
            setLocalValue(value);
          });
      } else {
        setInvalid(true);
      }
    } else {
      setEditMode(false);
    }
  };

  const toEditMode = (e) => {
    setEditMode(true);
    setInvalid(false);
    e.stopPropagation();
  };

  const getButtonBody = () => {
    if (value !== null && value !== undefined && value !== "") {
      return multiline ? <MultilineText text={value} /> : value;
    }
    return <AddIcon fontSize="small" />;
  };

  return (
    <div onBlur={() => onBlur()}>
      {!editMode && (
        <div
          role="button"
          onKeyPress={toEditMode}
          tabIndex={0}
          onClick={toEditMode}
          style={{ cursor: "pointer" }}
        >
          {getButtonBody()}
        </div>
      )}
      {editMode && (
        <Input
          autoFocus
          value={localValue}
          onChange={(event) => setLocalValue(event.target.value)}
          error={invalid}
          {...rest}
        />
      )}
    </div>
  );
};

InlineInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  multiline: PropTypes.bool,
  startInEditMode: PropTypes.bool,
  validate: PropTypes.func,
};

InlineInput.defaultProps = {
  value: "",
  multiline: false,
  startInEditMode: false,
  validate: null,
};

export default InlineInput;
