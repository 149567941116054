import { useAuth0 } from "@auth0/auth0-react";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { length, required } from "redux-form-validators";
import { getReferenceDataOptions } from "../../../../reducers";
import organisationsApi from "../../../../services/api/organisations";
import { organisationIcon } from "../../../../util/icons";
import Avatar from "../../../common/Avatar.tsx";
import ComponentField from "../../../forms/wrapper/ComponentField";
import WrappedChipListPicker from "../../../forms/wrapper/WrappedChipListPicker";
import WrappedRadioGroup from "../../../forms/wrapper/WrappedRadioGroup";
import WrappedTextField from "../../../forms/wrapper/WrappedTextField";

const OriginFields = ({
  customisations,
  change,
  untouch,
  formName,
  fieldLabels,
  name,
  originTypeOptions,
  entityRelationship,
  selectedType,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [previousOrganisations, setPreviousOrganisations] = useState(
    entityRelationship.organisations || []
  );
  const [previousProducts, setPreviousProducts] = useState(
    entityRelationship.financialProducts || []
  );

  const asyncExternalOrganisationOptionsFetch = async (
    pickerFilter,
    pagination,
    abortController
  ) => {
    const accessToken = await getAccessTokenSilently();

    const organisations = entityRelationship.organisations || [];
    const products = entityRelationship.financialProducts || [];

    const searchParameters = {
      ...pickerFilter,
      limit: pagination.pageSize,
      offset: pagination.offset,
      organisationIds:
        products.length === 0
          ? organisations.map((o) => o.organisation.id)
          : [],
      productIds: products.map((p) => p.id),
      orderByField: "name",
      excludeGroupFilters: true,
    };
    return organisationsApi.search(
      searchParameters,
      abortController,
      accessToken
    );
  };

  useEffect(() => {
    const organisations = entityRelationship.organisations || [];
    const products = entityRelationship.financialProducts || [];
    if (
      !_.isEqual(organisations, previousOrganisations) ||
      !_.isEqual(products, previousProducts)
    ) {
      change(`${name}.relatedEntities`, []);
      setPreviousOrganisations(organisations);
      setPreviousProducts(products);
    }
  }, [entityRelationship]);

  useEffect(() => {
    if (selectedType !== "ENTITY") {
      change(`${name}.relatedEntities`, []);
    }
    if (selectedType !== "OTHER") {
      change(`${name}.other`, null);
    }
  }, [selectedType]);

  return (
    <>
      <ComponentField
        fullWidth
        component={WrappedRadioGroup}
        name={`${name}.type`}
        label={fieldLabels.labels.type}
        options={originTypeOptions}
        row
        nullLabel="Not yet known"
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      />
      {selectedType && selectedType === "ENTITY" && (
        <ComponentField
          name={`${name}.relatedEntities`}
          component={WrappedChipListPicker}
          datasource={asyncExternalOrganisationOptionsFetch}
          label={fieldLabels.labels.relatedEntities}
          validate={length({
            min: 1,
            msg: `${fieldLabels.labels.relatedEntities} is required`,
          })}
          required
          fullWidth
          clearable
          toOption={(org) => ({ label: org.name, id: org.id, org })}
          fromOption={(option) => option.org}
          addIcon={organisationIcon()}
          renderIcon={(org, size) => (
            <Avatar
              name={(org.name || "Unknown").charAt(0)}
              size={size}
              round
            />
          )}
          data-cy={fieldLabels.labels.relatedEntities}
          customisations={customisations}
          change={change}
          untouch={untouch}
          formName={formName}
        />
      )}
      {selectedType && selectedType === "OTHER" && (
        <ComponentField
          component={WrappedTextField}
          name={`${name}.other`}
          label={fieldLabels.labels.other}
          validate={required({
            msg: `${fieldLabels.labels.other} is required`,
          })}
          required
          fullWidth
          data-cy={fieldLabels.labels.other}
          customisations={customisations}
          change={change}
          untouch={untouch}
          formName={formName}
        />
      )}
    </>
  );
};

OriginFields.propTypes = {
  name: PropTypes.string.isRequired,
  customisations: PropTypes.array,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  entityRelationship: PropTypes.object.isRequired,
  originTypeOptions: PropTypes.array.isRequired,
  selectedType: PropTypes.string,
};

OriginFields.defaultProps = {
  customisations: [],
  selectedType: null,
};

const mapStateToProps = (state, { formName, name }) => {
  const reduxFormSelector = formValueSelector(formName);

  return {
    originTypeOptions: getReferenceDataOptions(state, "IncidentOriginType"),
    selectedType: reduxFormSelector(state, `${name}.type`),
  };
};

export default connect(mapStateToProps)(OriginFields);
