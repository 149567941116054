import { withStyles } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import tinycolor from "tinycolor2";
import { expandIcon } from "../../../../util/icons";
import QuestionSummary from "./QuestionSummary";
import QuestionExpanded from "./QuestionExpanded";
import { QUESTION_STATUS } from "../../../../util/questions";

const styles = (theme) => ({
  container: {
    minWidth: "100%",
    maxWidth: "100%",
    margin: `${theme.spacing(1)}px 0`,
    padding: ` 0 ${theme.spacing(1.5)}px`,
  },
  needsResponse: {
    backgroundColor: tinycolor(theme.palette.primary.main).setAlpha(0.1),
  },
  summaryContent: {
    minWidth: "100%",
    maxWidth: "100%",
  },
  details: {
    display: "block",
  },
});

const QuestionReadOnly = ({
  classes,
  question,
  readonly,
  allowResponses,
  allowRating,
  onUpdate,
  onResponse,
  onResponseRating,
  onDelete,
  onFileClick,
  datasource,
  loggedInUser,
  expanded,
  setExpanded,
}) => {
  const [questionStatus, setQuestionStatus] = useState({
    ...QUESTION_STATUS,
    notResponder: true,
  });

  useEffect(() => {
    const currentUserIsResponder = question.responders.some(
      (user) => loggedInUser && loggedInUser.id === user.id
    );
    const currentUserHasResponded = question.responses
      .map((response) => response.user)
      .some((user) => loggedInUser && loggedInUser.id === user.id);

    if (currentUserIsResponder && !currentUserHasResponded) {
      setQuestionStatus({ ...QUESTION_STATUS, needsResponse: true });
    }

    if (currentUserIsResponder && currentUserHasResponded) {
      setQuestionStatus({ ...QUESTION_STATUS, hasResponse: true });
    }
  }, [question]);

  const ExpandIcon = expandIcon();

  return (
    <Accordion
      className={classes.container}
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary
        classes={{ content: classes.summaryContent }}
        expandIcon={<ExpandIcon />}
      >
        <QuestionSummary
          question={question}
          expanded={expanded}
          readonly={readonly}
          onUpdate={onUpdate}
          questionStatus={questionStatus}
        />
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.details }}>
        <QuestionExpanded
          readonly={readonly}
          allowResponses={allowResponses}
          question={question}
          onUpdate={onUpdate}
          datasource={datasource}
          onResponse={onResponse}
          onResponseRating={onResponseRating}
          onDelete={onDelete}
          onFileClick={onFileClick}
          setExpanded={setExpanded}
          loggedInUser={loggedInUser}
          allowRating={allowRating}
        />
      </AccordionDetails>
    </Accordion>
  );
};

QuestionReadOnly.propTypes = {
  classes: PropTypes.object.isRequired,
  question: PropTypes.object.isRequired,
  readonly: PropTypes.bool.isRequired,
  allowResponses: PropTypes.bool.isRequired,
  allowRating: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onResponse: PropTypes.func.isRequired,
  onResponseRating: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onFileClick: PropTypes.func.isRequired,
  datasource: PropTypes.oneOfType([PropTypes.func, PropTypes.array]).isRequired,
  loggedInUser: PropTypes.object,
  expanded: PropTypes.bool.isRequired,
  setExpanded: PropTypes.func.isRequired,
};

QuestionReadOnly.defaultProps = {
  loggedInUser: null,
};

export default withStyles(styles)(QuestionReadOnly);
