import moment from "moment";
import * as numeral from "numeral";
import { isNumber } from "lodash";

const date = (str, defaultVal) =>
  (str && moment(str).format("DD/MM/YYYY")) || defaultVal;
const currency = (num) => isNumber(num) && numeral(num).format("$0,0.00");
const percent = (num) => isNumber(num) && numeral(num).format("0%");
const enumSlugify = (str) => str && str.replace(/_/g, "-").toLowerCase();

const displayBoolean = (
  value,
  trueLabel = "Yes",
  falseLabel = "No",
  nullLabel = "N/A"
) => {
  if (value === null || value === undefined) {
    return nullLabel;
  }
  return value ? trueLabel : falseLabel;
};

export default {
  date,
  currency,
  percent,
  enumSlugify,
  displayBoolean,
};
