import { normalize } from "normalizr";
import schemas from "../schemas";
import subscriptionsApi from "../services/api/subscriptions";
import { asyncAction } from "./actions";

export const SUBSCRIPTION_CREATE = "SUBSCRIPTION_CREATE";
export const SUBSCRIPTION_LIST = "SUBSCRIPTION_LIST";
export const SUBSCRIPTION_REMOVE = "SUBSCRIPTION_REMOVE";

export const createSubscription = (subscription, accessToken) =>
  asyncAction(
    SUBSCRIPTION_CREATE,
    subscriptionsApi.create(subscription, accessToken),
    {
      responseTransformer: (createdSubscription) =>
        normalize(createdSubscription, schemas.subscription),
    }
  );

export const listSubscriptions = (accessToken) =>
  asyncAction(SUBSCRIPTION_LIST, subscriptionsApi.search(accessToken), {
    responseTransformer: (subscriptions) =>
      normalize(subscriptions, schemas.arrayOfSubscriptions),
  });

export const deleteSubscription = (id, accessToken) =>
  asyncAction(SUBSCRIPTION_REMOVE, subscriptionsApi.remove(id, accessToken), {
    responseTransformer: () => ({ id }),
  });
