import { useAuth0 } from "@auth0/auth0-react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AddAlarmIcon from "@material-ui/icons/AddAlarm";
import classNames from "classnames";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { startWorkItemAction } from "../../../../actions/workItemActions";
import {
  patchWorkItem,
  setSimpleReminder,
  setWorkItemStatus,
  setWorkItemUsers,
} from "../../../../actions/workItems";
import {
  getActiveWorkItemAction,
  getLoggedInUser,
  getReferenceDataType,
} from "../../../../reducers";
import dates from "../../../../util/dates";
import { overviewIcon } from "../../../../util/icons";
import {
  appliesByCategory,
  appliesByCondition,
  appliesByRegion,
  appliesByRoles,
  appliesByTemplate,
  getResolvedDefinition,
} from "../../../../util/workItemTypeUtils";
import {
  getFirstWorkItemUserByType,
  getWorkItemUsersByType,
} from "../../../../util/workItemUserUtils";
import CardIconTitle from "../../../common/CardIconTitle";
import DueAgo from "../../../common/DueAgo";
import InlineInputEditSaveCancel from "../../../common/InlineInputEditSaveCancel";
import InlineScorecard from "../../../common/InlineScorecard";
import MultilineText from "../../../common/MultilineText";
import TimeAgo from "../../../common/TimeAgo";
import DateButtonPicker from "../../../DateButtonPicker";
import FormDialog from "../../../forms/FormDialog";
import EndorsementsSummary from "../../EndorsementsSummary";
import OutcomeChip from "../../OutcomeChip";
import WorkItemBlob from "../../WorkItemBlob";
import WorkItemNotes from "../../WorkItemNotes";
import WorkItemSubmitter from "../../WorkItemSubmitter";
import SetReminderForm, { SET_REMINDER_FORM_NAME } from "./SetReminderForm";
import WorkItemCategory from "./WorkItemCategory";
import WorkItemOwner from "./WorkItemOwner";
import WorkItemPriority from "./WorkItemPriority";
import WorkItemStatus from "./WorkItemStatus";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  content: {
    paddingBottom: "0 !important",
  },
  cardContent: {
    paddingTop: "0 !important",
  },
  label: {
    ...theme.typography.body2,
    color: theme.palette.swatch.secondary,
  },
  selectable: {
    ...theme.typography.body2,
    fontWeight: 400,
    color: theme.palette.swatch.primary,
  },
  due: {
    color: theme.due.ok,
  },
  overdue: {
    color: theme.due.overdue,
  },
  reminderButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  reminderButtonIcon: {
    marginLeft: theme.spacing(1),
  },
  clientCompany: {
    ...theme.typography.h5,
    marginBottom: theme.spacing(2),
  },
  clientLink: {
    textDecoration: "none",
  },
  createdBy: {
    paddingBottom: 23,
    paddingTop: 3,
    color: theme.palette.swatch.secondary,
  },
});

const WorkItemDetailCard = ({
  classes,
  className,
  workItem,
  activeAction,
  readonly,
  fieldLabels,
  template,
  loggedInUser,
  types,
  priorities,
  closeable,
  localSetSimpleReminder,
  localSetWorkItemStatus,
  localPatchWorkItem,
  localSetWorkItemUsers,
  localStartWorkItemAction,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [reminderDialogOpen, setReminderDialogOpen] = useState(false);
  const definition = getResolvedDefinition(
    types,
    workItem.type,
    workItem.parent?.type
  );

  const handleSetReminder = async (simpleReminder) => {
    const accessToken = await getAccessTokenSilently();
    localSetSimpleReminder(workItem.id, simpleReminder, accessToken).then(
      () => {
        setReminderDialogOpen(false);
      }
    );
  };

  const handleUpdate = async (values, desc) => {
    const accessToken = await getAccessTokenSilently();
    localPatchWorkItem(workItem.id, values, desc, accessToken);
  };

  const handleSetUsers = async (userIds) => {
    const accessToken = await getAccessTokenSilently();
    localSetWorkItemUsers({ workItemId: workItem.id, userIds }, accessToken);
  };

  const handleSetStatus = async (
    status,
    outcome,
    completionDate,
    onSuccess
  ) => {
    const accessToken = await getAccessTokenSilently();
    localSetWorkItemStatus(
      workItem.id,
      status,
      outcome,
      completionDate,
      onSuccess,
      accessToken
    );
  };

  const getDueDateOffsetLabel = () => {
    if (
      workItem.parentDueDateOffset !== undefined ||
      workItem.parentDueDateOffset !== null
    ) {
      if (workItem.parentDueDateOffset === 0) {
        return "Due: same day";
      }
      if (workItem.parentDueDateOffset === 1) {
        return "Due: 1 day prior";
      }
      if (workItem.parentDueDateOffset === -1) {
        return "Due: 1 day after";
      }
      if (workItem.parentDueDateOffset < -1) {
        return `Due: ${workItem.parentDueDateOffset * -1} days after`;
      }
      return `Due: ${workItem.parentDueDateOffset} days prior`;
    }
    return null;
  };

  const mergedClassName = classNames(classes.root, className);

  const {
    ownableBy,
    keyContactRules,
    dueDateRules,
    categories,
    components,
    endorsementTypes,
    name: typeDisplay,
  } = definition;
  const overviewComponent = components.find(
    (componentAssignment) => componentAssignment.component === "OVERVIEW"
  );
  const customisations = overviewComponent.fieldCustomisations
    .filter(
      (customisation) =>
        customisation.categories.length === 0 ||
        appliesByCategory(workItem, customisation)
    )
    .filter((customisation) => appliesByRoles(loggedInUser, customisation))
    .filter((customisation) => appliesByRegion(workItem, customisation))
    .filter((customisation) =>
      appliesByCondition(workItem, customisation.condition)
    );

  const keyContactableBy = appliesByTemplate(workItem, keyContactRules)
    ? keyContactRules.roles
    : [];
  const showKeyContact = keyContactableBy.length > 0;

  const dueDateReadonly = dueDateRules.length
    ? dueDateRules.some((dueDateRule) => dueDateRule.disableEditing === true)
    : false;

  const filteredCategories = categories.filter((category) =>
    appliesByRegion(workItem, category)
  );

  const owner = getFirstWorkItemUserByType(workItem.users, "OWNER")?.user;

  const dueDate = dates.parseDate(workItem.dueDate);
  const overdue = dates.dateIsBeforeToday(dueDate);
  const originalDueDate = dates.parseDate(workItem.dueDateHistory[0].dueDate);
  const showOriginalDueDate = !dates.areSame(dueDate, originalDueDate);

  const completionDate = dates.parseDate(workItem.completionDate);
  const showCompletionDate =
    workItem.incidentAction && workItem.incidentAction.retrospective;

  const includeCategory = categories.length > 1;

  const includeField = (field) => {
    const excluded = !!customisations
      .filter((c) => c.field === field)
      .find((c) => c.state === "EXCLUDED");
    const hidden = !!customisations
      .filter((c) => c.field === field)
      .find((c) => c.state === "HIDDEN");
    return !(excluded || hidden);
  };

  const isImmutable = (field) =>
    !!customisations
      .filter((c) => c.field === field)
      .find((c) => c.state === "IMMUTABLE");

  return (
    <>
      <FormDialog
        title="Set reminder"
        submitButtonText="Save reminder"
        formComponent={SetReminderForm}
        formName={SET_REMINDER_FORM_NAME}
        open={reminderDialogOpen}
        onCancel={() => setReminderDialogOpen(false)}
        onSubmit={handleSetReminder}
      />
      <Card className={mergedClassName} elevation={0}>
        <CardHeader
          title={<CardIconTitle title="Overview" icon={overviewIcon()} />}
          data-cy="overview"
        />
        <CardContent className={classes.cardContent}>
          <Grid container>
            <Grid item xs={12}>
              <InlineScorecard
                label={fieldLabels.labels.type}
                labelVariant="body2"
                labelClass={classes.label}
              >
                <WorkItemBlob
                  type={workItem.type}
                  description={types.values[workItem.type].description}
                  data-cy={workItem.type}
                />
              </InlineScorecard>
              {includeCategory && (
                <InlineScorecard
                  label={fieldLabels.labels.category}
                  labelVariant="body2"
                  labelClass={classes.label}
                >
                  <WorkItemCategory
                    className={classes.selectable}
                    category={workItem.category}
                    categories={filteredCategories}
                    onCategoryChange={
                      loggedInUser.externalUser
                        ? null
                        : (category) =>
                            handleUpdate({ category }, `Edited ${typeDisplay}`)
                    }
                    readonly={readonly || isImmutable("category")}
                    data-cy="category"
                  />
                </InlineScorecard>
              )}
              <InlineScorecard
                label="Owner"
                labelVariant="body2"
                labelClass={classes.label}
                data-cy="owner"
              >
                <WorkItemOwner
                  className={classes.selectable}
                  workItem={workItem}
                  owner={owner}
                  disabledOptions={getWorkItemUsersByType(
                    workItem.users,
                    "KEY_CONTACT"
                  ).map((workItemUser) => workItemUser.user)}
                  userRoles={ownableBy}
                  loggedInUser={loggedInUser}
                  onOwnerChange={
                    loggedInUser.externalUser
                      ? null
                      : (owner) =>
                          handleSetUsers({
                            OWNER: owner ? [owner.id] : [],
                          })
                  }
                  readonly={readonly || !!activeAction}
                  includeAvatar
                  data-cy="owner"
                />
              </InlineScorecard>
              {showKeyContact && (
                <InlineScorecard
                  label="Key contact"
                  labelVariant="body2"
                  labelClass={classes.label}
                >
                  <WorkItemOwner
                    className={classes.selectable}
                    workItem={workItem}
                    owner={
                      getFirstWorkItemUserByType(workItem.users, "KEY_CONTACT")
                        ?.user
                    }
                    disabledOptions={getWorkItemUsersByType(
                      workItem.users,
                      "OWNER"
                    ).map((workItemUser) => workItemUser.user)}
                    userRoles={keyContactableBy}
                    loggedInUser={loggedInUser}
                    onOwnerChange={
                      loggedInUser.externalUser
                        ? null
                        : (keyContact) =>
                            handleSetUsers({
                              KEY_CONTACT: keyContact ? [keyContact.id] : [],
                            })
                    }
                    readonly={readonly || !!activeAction}
                    title="Select key contact"
                    noValueText="No key contact"
                    includeAvatar
                    data-cy="owner"
                  />
                </InlineScorecard>
              )}
              {!template || !workItem.hasParent ? (
                <InlineScorecard
                  label="Due"
                  labelVariant="body2"
                  labelClass={classes.label}
                  data-cy="due"
                >
                  {dates.formatDateLong(dueDate)}
                  <DateButtonPicker
                    labelComponent={
                      <DueAgo value={dueDate} stayFull={readonly || template} />
                    }
                    className={classNames({
                      [classes.due]: true,
                      [classes.overdue]: !template && !readonly && overdue,
                    })}
                    dateValue={dueDate}
                    readonly={readonly || dueDateReadonly || !!activeAction}
                    onDateChange={
                      loggedInUser.externalUser
                        ? null
                        : (date) =>
                            handleUpdate(
                              { dueDate: dates.serializeDate(date) },
                              `Edited ${typeDisplay}`
                            )
                    }
                    data-cy="due"
                  />
                  {showOriginalDueDate && (
                    <Typography variant="caption" display="block">
                      Originally {dates.formatDateLong(originalDueDate)}
                    </Typography>
                  )}
                </InlineScorecard>
              ) : (
                <InlineScorecard
                  label={getDueDateOffsetLabel(workItem)}
                  labelVariant="body2"
                  labelClass={classes.label}
                >
                  <InlineInputEditSaveCancel
                    value={workItem.parentDueDateOffset}
                    onChange={(parentDueDateOffset) =>
                      handleUpdate(
                        { parentDueDateOffset },
                        `Edited ${typeDisplay}`
                      )
                    }
                    fullWidth
                    inputProps={{ min: 0 }}
                    validate={(value) =>
                      value && _.isNumber(Number(value)) && value >= 0
                    }
                    readonly={
                      readonly || !!activeAction || loggedInUser.externalUser
                    }
                  />
                </InlineScorecard>
              )}
              {showCompletionDate && (
                <InlineScorecard
                  label={`Completed: ${
                    completionDate ? dates.formatDateLong(completionDate) : "-"
                  }`}
                  labelVariant="body2"
                  labelClass={classes.label}
                  data-cy="completionDate"
                >
                  <DateButtonPicker
                    labelComponent={<DueAgo value={completionDate} stayFull />}
                    className={classNames({
                      [classes.due]: true,
                    })}
                    dateValue={completionDate}
                    readonly={
                      readonly || !!activeAction || loggedInUser.externalUser
                    }
                    onDateChange={(date) =>
                      handleUpdate(
                        { completionDate: dates.serializeDate(date) },
                        `Edited ${typeDisplay}`
                      )
                    }
                    data-cy={completionDate}
                  />
                </InlineScorecard>
              )}
              {!template && (
                <>
                  <InlineScorecard
                    label={fieldLabels.labels.status}
                    labelVariant="body2"
                    labelClass={classes.label}
                    data-cy="status"
                  >
                    <WorkItemStatus
                      workItem={workItem}
                      onStatusChange={
                        loggedInUser.externalUser ? null : handleSetStatus
                      }
                      readonly={!!activeAction}
                      loggedInUser={loggedInUser}
                      definition={definition}
                      closable={closeable}
                      startWorkItemAction={localStartWorkItemAction}
                      data-cy={types}
                    />
                    {workItem.outcome && (
                      <OutcomeChip outcome={workItem.outcome} defaultValue="" />
                    )}
                  </InlineScorecard>
                  {workItem.status === "MONITORING" && (
                    <Button
                      variant="contained"
                      size="small"
                      className={classes.reminderButton}
                      onClick={() => setReminderDialogOpen(true)}
                      disabled={!!activeAction}
                    >
                      Set reminder
                      <AddAlarmIcon className={classes.reminderButtonIcon} />
                    </Button>
                  )}
                </>
              )}
              {workItem.priority && (
                <InlineScorecard
                  label={fieldLabels.labels.priority}
                  labelVariant="body2"
                  labelClass={classes.label}
                  data-cy="priority"
                >
                  <WorkItemPriority
                    workItem={workItem}
                    onPriorityChange={
                      loggedInUser.externalUser
                        ? null
                        : (priority) =>
                            handleUpdate({ priority }, `Edited ${typeDisplay}`)
                    }
                    loggedInUser={loggedInUser}
                    definition={definition}
                    startWorkItemAction={localStartWorkItemAction}
                    readonly={
                      readonly || !!activeAction || isImmutable("priority")
                    }
                    priorities={priorities}
                    data-cy="priority"
                  />
                </InlineScorecard>
              )}
              {(includeField("priorityReason") || workItem.priorityReason) && (
                <InlineScorecard
                  label={fieldLabels.labels.priorityReason}
                  labelVariant="body2"
                  labelClass={classes.label}
                  data-cy="priority"
                >
                  <MultilineText
                    text={workItem.priorityReason}
                    defaultText="-"
                  />
                </InlineScorecard>
              )}
              <InlineScorecard
                label="Submitted"
                labelVariant="body2"
                labelClass={classes.label}
                data-cy="submitted"
              >
                <>
                  <WorkItemSubmitter workItem={workItem} data-cy="submitted" />
                  <Typography
                    display="block"
                    variant="caption"
                    style={{ color: "#778088" }}
                    data-cy="submitted"
                  >
                    <TimeAgo
                      value={dates.parseTimestamp(workItem.created)}
                      expandable
                    />
                  </Typography>
                </>
              </InlineScorecard>

              <InlineScorecard
                label="Endorsements"
                labelVariant="body2"
                labelClass={classes.label}
                data-cy="endorsement"
              >
                <EndorsementsSummary
                  workItem={workItem}
                  readonly={readonly}
                  loggedInUser={loggedInUser}
                  data-cy="endorsement"
                />
              </InlineScorecard>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {includeField("notes") && (
        <WorkItemNotes
          workItem={workItem}
          readonly={readonly || isImmutable("notes")}
          fieldLabels={fieldLabels}
          onNotesChange={(notes) =>
            handleUpdate({ notes }, `Edited ${typeDisplay}`)
          }
          contentName={fieldLabels.labels.notes.toLowerCase()}
        />
      )}
    </>
  );
};

WorkItemDetailCard.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  workItem: PropTypes.object.isRequired,
  activeAction: PropTypes.object,
  readonly: PropTypes.bool.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  template: PropTypes.bool.isRequired,
  closeable: PropTypes.bool,
  loggedInUser: PropTypes.object,
  types: PropTypes.object.isRequired,
  priorities: PropTypes.object.isRequired,
  localSetSimpleReminder: PropTypes.func.isRequired,
  localSetWorkItemStatus: PropTypes.func.isRequired,
  localSetWorkItemUsers: PropTypes.func.isRequired,
  localPatchWorkItem: PropTypes.func.isRequired,
  localStartWorkItemAction: PropTypes.func.isRequired,
};

WorkItemDetailCard.defaultProps = {
  className: undefined,
  loggedInUser: {},
  closeable: true,
  activeAction: null,
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
  activeAction: getActiveWorkItemAction(state),
  types: getReferenceDataType(state, "WorkItemType"),
  priorities: getReferenceDataType(state, "Priority"),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    localSetWorkItemStatus: setWorkItemStatus, // triggers notifications to submitter
    localSetSimpleReminder: setSimpleReminder, // triggers notifications, dueDate change
    localSetWorkItemUsers: setWorkItemUsers,
    localPatchWorkItem: patchWorkItem,
    localStartWorkItemAction: startWorkItemAction,
  })
)(WorkItemDetailCard);
