const getEnv = (window: Window): string => {
  if (window.location.hostname.startsWith("dev.arcadia.diversa.com.au")) {
    return "dev";
  } else if (
    window.location.hostname.startsWith("uat.arcadia.diversa.com.au")
  ) {
    return "uat";
  } else if (window.location.hostname.startsWith("arcadia.diversa.com.au")) {
    return "prod";
  }
  return "local";
};

const env = getEnv(window);

export const isLocal = env === "local";
export const isDev = env === "dev";
export const isUat = env === "uat";
export const isProd = env === "prod";
export default env;
