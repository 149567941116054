import { combineReducers } from "redux";
import {
  SNIPPET_CLEAR_ACTIVE,
  SNIPPET_CREATE,
  SNIPPET_GET_BY_ID,
  SNIPPET_SEARCH,
  SNIPPET_PATCH,
  SNIPPET_REMOVE,
  SNIPPET_SET_ACTIVE_VERSION,
  SNIPPET_ADD_CONTENT,
} from "../actions/snippets";
import { publishActionToast } from "../services/toasts";

const successToasts = {
  [SNIPPET_CREATE]: "Create snippet successfully",
  [SNIPPET_PATCH]: "Updated snippet successfully",
};

const byId = (state = {}, action) => {
  publishActionToast(action, "SNIPPET_", successToasts);

  switch (action.type) {
    case `${SNIPPET_SEARCH}_SUCCESS`:
    case `${SNIPPET_GET_BY_ID}_SUCCESS`:
    case `${SNIPPET_PATCH}_SUCCESS`:
    case `${SNIPPET_CREATE}_SUCCESS`:
    case `${SNIPPET_ADD_CONTENT}_SUCCESS`:
      return {
        ...state,
        ...action.response.entities.snippet,
      };

    // Remove a snippet
    case `${SNIPPET_REMOVE}_SUCCESS`:
      const idToRemove = action.response.id;
      return {
        ...state,
        [idToRemove]: undefined,
      };

    default:
      break;
  }

  return state;
};

const defaultActive = {
  activeList: [],
  activeSnippet: null,
  activeVersion: null,
  lastEdit: 0,
  pagination: {
    offset: 0,
    pageSize: 50,
    resultCount: 0,
  },
};

const active = (state = defaultActive, action) => {
  switch (action.type) {
    case SNIPPET_CLEAR_ACTIVE:
      return {
        ...defaultActive,
      };

    case SNIPPET_SET_ACTIVE_VERSION:
      return {
        ...state,
        activeVersion: action.payload,
        lastEdit: new Date().getTime(),
      };

    case `${SNIPPET_SEARCH}_SUCCESS`:
      return {
        ...state,
        activeList: [...action.response.result],
        activeVersion: null,
        pagination: {
          ...action.response.pagination,
          pageSize: defaultActive.pagination.pageSize,
        },
      };

    case `${SNIPPET_GET_BY_ID}_SUCCESS`:
    case `${SNIPPET_CREATE}_SUCCESS`:
    case `${SNIPPET_ADD_CONTENT}_SUCCESS`:
      return {
        ...state,
        activeList:
          state.activeList.indexOf(action.response.result) === -1
            ? [...state.activeList, action.response.result]
            : state.activeList,
        activeSnippet: action.response.result,
        activeVersion: null,
        lastEdit: new Date().getTime(),
      };

    // Remove a risk control
    case `${SNIPPET_REMOVE}_SUCCESS`:
      const idToRemove = action.response.id;
      return {
        ...state,
        activeList: [...state.activeList.filter((id) => id !== idToRemove)],
        activeVersion:
          state.activeSnippet === idToRemove ? null : state.activeVersion,
        activeSnippet:
          state.activeSnippet === idToRemove ? null : state.activeSnippet,
      };

    default:
      break;
  }

  return state;
};

export default combineReducers({
  active,
  byId,
});

export const getActiveSnippet = (state) =>
  state.active.activeSnippet ? state.byId[state.active.activeSnippet] : null;
export const getActiveVersion = (state) => state.active.activeVersion;
export const getSnippets = (state) =>
  state.active.activeList.map((id) => state.byId[id]);
export const getPagination = (state) => state.active.pagination;
export const getLastEdit = (state) => state.active.lastEdit;
