import { useAuth0 } from "@auth0/auth0-react";
import { authorizer, roles, useTenant } from "@certane/arcadia-web-components";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  deleteControl,
  loadControl,
  patchControl,
} from "../../actions/controls";
import EntityRelationshipCard from "../../components/business/EntityRelationshipCard";
import ActionButton from "../../components/common/ActionButton";
import ActionHeading from "../../components/common/ActionHeading";
import AlertDialog from "../../components/common/AlertDialog";
import BreadcrumbLink from "../../components/common/BreadcrumbLink";
import Container from "../../components/common/Container";
import HeaderBar from "../../components/common/HeaderBar";
import InlineInputEditSaveCancel from "../../components/common/InlineInputEditSaveCancel";
import PageHeading from "../../components/common/PageHeading";
import ControlDetailsCard from "../../components/controls/ControlDetailsCard";
import ControlLifecycle from "../../components/controls/ControlLifecycle";
import ControlObligationLinksCard from "../../components/controls/ControlObligationLinksCard";
import ControlRiskLinksCard from "../../components/controls/ControlRiskLinksCard";
import ControlSummaryCard from "../../components/controls/ControlSummaryCard";
import ControlVerificationsCard from "../../components/controls/ControlVerificationsCard";
import Error404 from "../../components/error/Error404";
import Error410 from "../../components/error/Error410";
import LoadingIndicator from "../../components/LoadingIndicator";
import AuditHistory from "../../components/workitem/AuditHistory";
import ControlVerificationComponentCard from "../../components/workitem/components/controlverification/ControlVerificationComponentCard";
import {
  getActiveControl,
  getControlLoadError,
  getLabels,
  getLoggedInUser,
  getReferenceDataType,
  isLoading,
} from "../../reducers";
import { getWorkItemLink } from "../../routes/routeUtils";
import workItemsApi from "../../services/api/workItems";
import { deleteIcon, riskControlIcon } from "../../util/icons";

const tabs = {
  details: {
    value: "details",
    label: "Details",
  },
  associations: {
    value: "associations",
    label: "Associations",
  },
  verifications: {
    value: "verifications",
    label: "Verifications",
  },
  auditHistory: {
    value: "auditHistory",
    label: "Audit history",
  },
};

const relationshipTypes = [
  "PROMOTER",
  "SUB_PROMOTER",
  "TRUSTEE",
  "RESPONSIBLE_ENTITY",
  "KIWISAVER_MANAGER",
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    marginTop: theme.spacing(2),
  },
  tabs: {
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
  },
  tabRoot: {
    minWidth: "100px",
    padding: theme.spacing(2),
  },
  gridItem: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "auto",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      "&:first-child": {
        width: "80%",
        paddingLeft: 0,
      },
      "&:last-child": {
        marginLeft: "auto",
      },
    },
  },
  actionButton: {
    display: "inline-block",
  },
  card: {
    marginBottom: theme.spacing(2),
  },
}));

const orderedTabs = [
  tabs.details,
  tabs.associations,
  tabs.verifications,
  tabs.auditHistory,
];

const ControlId = ({
  match: {
    params: { controlId },
  },
  history,
  loggedInUser,
  loading,
  control,
  loadError,
  fieldLabels,
  fieldLabelsWorkItem,
  localLoadControl,
  localDeleteControl,
  localPatchControl,
  workItemTypes,
}) => {
  const { getAccessTokenSilently, user } = useAuth0();
  const { tenant } = useTenant();
  const classes = useStyles();
  const [fetchingVerifications, setFetchingVerifications] = useState(false);
  const [controlVerifications, setControlVerifications] = useState([]);
  const [activeTab, setActiveTab] = useState(tabs.details.value);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [activeControlVerification, setActiveControlVerification] =
    useState(null);

  useEffect(() => {
    (async () => {
      setFetchingVerifications(true);
      const accessToken = await getAccessTokenSilently();

      // load control
      localLoadControl(controlId, accessToken);

      // load verifications
      const searchParameters = {
        controlId: controlId,
        types: ["CONTROL_VERIFICATION"],
        limit: 100,
        orderByField: "-created",
      };
      const response = await workItemsApi.search(
        searchParameters,
        null,
        accessToken
      );
      const verifications = response.results;
      setControlVerifications(verifications);
      const active = verifications.find((v) => v.status !== "CLOSED");
      if (active) {
        const activeFull = await workItemsApi.get(active.id, accessToken);
        setActiveControlVerification(activeFull);
      }
      setFetchingVerifications(false);
    })();
  }, [getAccessTokenSilently, controlId]);

  const renderDeleteConfirmation = () => (
    <div>
      {controlVerifications?.length > 0 &&
        workItemList(
          "The following open verifications will also be deleted:",
          controlVerifications.filter(
            (verification) => verification.status !== "CLOSED"
          )
        )}
    </div>
  );

  const workItemList = (text, workItems) => (
    <div>
      <Typography variant="subtitle1" data-cy={text}>
        {text}
      </Typography>
      <List dense>
        {workItems.map((workItem) => (
          <ListItem
            key={workItem.id}
            component={Link}
            to={getWorkItemLink(workItem)}
            button
            divider
            draggable={false}
          >
            <ListItemText data-cy={workItem.title}>
              <span className={classes.workItemType} data-cy={workItem.title}>
                {workItem.type_display}
              </span>
              - {workItem.title}
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </div>
  );

  const readOnly = !authorizer.check("control", "editor", user, tenant?.id);

  const onChange = readOnly
    ? null
    : async (values) => {
        const accessToken = await getAccessTokenSilently();
        return localPatchControl(control.id, values, "Updated", accessToken);
      };

  const onChangeWorkItem =
    readOnly || !activeControlVerification
      ? null
      : async (values) => {
          const accessToken = await getAccessTokenSilently();
          const active = await workItemsApi.patch(
            activeControlVerification.id,
            values,
            "Updated",
            accessToken
          );
          setActiveControlVerification(active);
        };

  const handleSubmitRemoveDialog = async () => {
    const accessToken = await getAccessTokenSilently();
    localDeleteControl(controlId, accessToken).then(() => {
      history.push("/controls");
      setRemoveDialogOpen(false);
    });
  };

  const handleTabChange = (event, value) => {
    setActiveTab(value);
  };

  const isActiveTab = (tab) => activeTab === tab;

  const isRiskAdmin = loggedInUser
    ? _.intersection(
        [...roles.ADMIN_ROLES, roles.RISK_ADMIN],
        loggedInUser.roles
      ).length > 0
    : false;
  const actions = [];
  if (isRiskAdmin) {
    actions.push(
      <ActionButton
        tooltip="Delete"
        icon={deleteIcon()}
        onClick={() => setRemoveDialogOpen(true)}
        data-cy="delete"
      />
    );
  }

  if (!control && loading) {
    return <LoadingIndicator size={60} />;
  }

  if (!control && !loading) {
    if (loadError && loadError.status === 410) {
      return <Error410 message={loadError.message} />;
    }
    return <Error404 />;
  }

  return (
    <div className={classes.root}>
      <AlertDialog
        title="Are you sure you want to delete this control?"
        body={renderDeleteConfirmation}
        submitButtonText="Remove"
        open={removeDialogOpen}
        onCancel={() => setRemoveDialogOpen(false)}
        onSubmit={(values) => handleSubmitRemoveDialog(values)}
        data-cy="removeControls"
      />
      <HeaderBar>
        <BreadcrumbLink to="/controls" label="Controls" includeArrow />
        <ActionHeading
          heading={
            <PageHeading
              icon={riskControlIcon()}
              heading={
                <InlineInputEditSaveCancel
                  value={control.title}
                  onChange={(title) => onChange({ title })}
                  fullWidth
                  validate={(value) => !!value}
                  readonly={!onChange}
                />
              }
            />
          }
          actions={actions}
        />
      </HeaderBar>
      <Paper elevation={0} square>
        <Container>
          <Grid container spacing={2}>
            {/* TAB Headings */}
            <Grid item sm={12} md={8}>
              <Tabs
                value={activeTab}
                textColor="primary"
                onChange={handleTabChange}
                className={classes.tabs}
                data-cy={activeTab}
              >
                {orderedTabs.map((tab) => (
                  <Tab
                    key={tab.value}
                    value={tab.value}
                    label={tab.label}
                    classes={{
                      root: classes.tabRoot,
                    }}
                    data-cy={tab.label}
                  />
                ))}
              </Tabs>
            </Grid>
            <Grid item sm={12} md={4}>
              <ControlLifecycle
                loggedInUser={loggedInUser}
                control={control}
                onChange={onChange}
              />
            </Grid>
          </Grid>
        </Container>
      </Paper>
      <Container className={classes.container}>
        <Grid container spacing={2}>
          {/* TAB CONTENT */}
          <Grid item sm={12} md={8}>
            {isActiveTab(tabs.details.value) && (
              <>
                <ControlDetailsCard
                  className={classes.card}
                  control={control}
                  onChange={onChange}
                  fieldLabels={fieldLabels}
                />
                <EntityRelationshipCard
                  className={classes.card}
                  title={fieldLabels.labels.entityRelationship}
                  baseEntity={control}
                  fieldLabels={fieldLabels}
                  onChange={onChange}
                  customisations={[
                    {
                      field: "entityRelationship.fundLevel",
                      state: "EXCLUDED",
                    },
                  ]}
                  defaultVisibilityScope={{
                    INTERNAL: "ORGANISATION",
                    EXTERNAL: "ORGANISATION",
                  }}
                  autoPopulateEntitiesByRelationshipTypes={relationshipTypes}
                  filterEntitiesByRelationshipTypes={relationshipTypes}
                />
              </>
            )}
            {isActiveTab(tabs.associations.value) && (
              <>
                <ControlObligationLinksCard
                  control={control}
                  fieldLabels={fieldLabels}
                />
                {tenant.featureFlags.risk && (
                  <ControlRiskLinksCard control={control} />
                )}
              </>
            )}
            {isActiveTab(tabs.verifications.value) && (
              <>
                {activeControlVerification && (
                  <ControlVerificationComponentCard
                    workItem={activeControlVerification}
                    onChange={onChangeWorkItem}
                    fieldLabels={fieldLabelsWorkItem}
                    customisations={
                      workItemTypes.values[activeControlVerification.type].props
                        .definition.fieldCustomisations
                    }
                    data-cy={fieldLabels}
                  />
                )}
                <ControlVerificationsCard
                  control={control}
                  controlVerifications={controlVerifications}
                  history={history}
                  loading={fetchingVerifications}
                />
              </>
            )}
            {isActiveTab(tabs.auditHistory.value) && (
              <AuditHistory auditHistory={control.auditHistory} />
            )}
          </Grid>
          <Grid item sm={12} md={4}>
            <ControlSummaryCard
              loggedInUser={loggedInUser}
              control={control}
              onChange={onChange}
              fieldLabels={fieldLabels}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

ControlId.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  loggedInUser: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  localLoadControl: PropTypes.func.isRequired,
  localDeleteControl: PropTypes.func.isRequired,
  localPatchControl: PropTypes.func.isRequired,
  control: PropTypes.object,
  loadError: PropTypes.object,
  fieldLabels: PropTypes.object.isRequired,
  fieldLabelsWorkItem: PropTypes.object.isRequired,
  workItemTypes: PropTypes.object.isRequired,
};

ControlId.defaultProps = {
  control: null,
  loadError: null,
  loggedInUser: null,
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
  loading: isLoading(state),
  control: getActiveControl(state),
  loadError: getControlLoadError(state),
  fieldLabels: getLabels(state).Control,
  fieldLabelsWorkItem: getLabels(state).CONTROL_VERIFICATION,
  workItemTypes: getReferenceDataType(state, "WorkItemType"),
});

export default connect(mapStateToProps, {
  localLoadControl: loadControl,
  localDeleteControl: deleteControl,
  localPatchControl: patchControl,
})(ControlId);
