import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import _ from "lodash";
import PropTypes from "prop-types";
import CollapseExpand from "../../../CollapseExpand";
import RichTextMentionsView from "../../../richtext/RichTextMentionsView";

const styles = (theme) => ({
  box: {
    marginTop: theme.spacing(1),
  },
  text: {
    color: theme.palette.swatch.primary,
    whiteSpace: "pre-wrap",
  },
  response: {
    color: theme.palette.swatch.grey1,
    whiteSpace: "pre-wrap",
  },
});

const isQuestionResponse = (entry) =>
  _.has(entry, "comment") &&
  _.get(entry.comment, "type") === "QUESTION_RESPONSE";

const useQuestionResponse = (entry) => {
  const [hasQuestionResponse, setHasQuestionResponse] = useState(false);
  const [questionResponse, setQuestionResponse] = useState(null);
  const [responseTo, setResponseTo] = useState("");

  useEffect(() => {
    if (isQuestionResponse(entry)) {
      setHasQuestionResponse(true);
      setQuestionResponse(entry.comment.draft);
      setResponseTo(entry.comment.responseTo);
    }
  }, [entry]);

  return [hasQuestionResponse, questionResponse, responseTo];
};

const QuestionResponse = ({ classes, entry }) => {
  const [hasQuestionResponse, questionResponse, responseTo] =
    useQuestionResponse(entry);

  return (
    <>
      {hasQuestionResponse && (
        <div className={classes.box}>
          {responseTo && (
            <Typography
              component="div"
              variant="body2"
              className={classes.text}
            >
              Responded to:{" "}
              <span style={{ fontStyle: "italic" }}>{responseTo}</span>
            </Typography>
          )}
          <Typography component="div" variant="body1" className={classes.text}>
            <CollapseExpand isNewUI>
              <RichTextMentionsView value={questionResponse} />
            </CollapseExpand>
          </Typography>
        </div>
      )}
    </>
  );
};

QuestionResponse.propTypes = {
  classes: PropTypes.object.isRequired,
  entry: PropTypes.object.isRequired,
};

export default withStyles(styles)(QuestionResponse);
