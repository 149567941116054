import { combineReducers } from "redux";
import {
  CONTROL_CLEAR_ACTIVE,
  CONTROL_CREATE,
  CONTROL_LIST,
  CONTROL_LOAD,
  CONTROL_LOAD_METRIC,
  CONTROL_PATCH,
  CONTROL_REMOVE,
} from "../actions/controls";
import { publishActionToast } from "../services/toasts";
import logger from "../util/logger";

const successToasts = {
  [CONTROL_CREATE]: "Created control successfully",
  [CONTROL_REMOVE]: "Removed control successfully",
  [CONTROL_PATCH]: "Updated control successfully",
};

const byId = (state = { lite: {}, full: {} }, action) => {
  publishActionToast(action, "CONTROL", successToasts);

  switch (action.type) {
    case `${CONTROL_LIST}_SUCCESS`:
      return {
        ...state,
        lite: {
          ...state.lite,
          ...action.response.entities.control,
        },
      };

    case `${CONTROL_CREATE}_SUCCESS`:
    case `${CONTROL_LOAD}_SUCCESS`:
    case `${CONTROL_PATCH}_SUCCESS`:
      return {
        ...state,
        lite: {
          ...state.lite,
          ...action.response.entities.control,
        },
        full: {
          ...state.full,
          ...action.response.entities.control,
        },
      };

    // Remove a control
    case `${CONTROL_REMOVE}_SUCCESS`:
      const idToRemove = action.response.id;
      return {
        ...state,
        lite: {
          ...state.lite,
          [idToRemove]: undefined,
        },
        full: {
          ...state.full,
          [idToRemove]: undefined,
        },
      };
    default:
      break;
  }

  return state;
};

const defaultActive = {
  dashboard: [],
  dashboardLastUpdate: 0,
  activeControl: null,
  exportLink: null,
  error: null,
  searchAbortController: null,
  pagination: {
    offset: 0,
    pageSize: 50,
    resultCount: 0,
  },
};

const active = (state = defaultActive, action) => {
  switch (action.type) {
    case CONTROL_CLEAR_ACTIVE:
      return {
        ...defaultActive,
      };

    case `${CONTROL_LIST}_INPROGRESS`:
      if (state.searchAbortController) {
        logger.info("aborting previous unfinished request");
        state.searchAbortController.abort();
      }
      return {
        ...state,
        searchAbortController: action.abortController,
      };

    case `${CONTROL_LIST}_FAILURE`:
      return {
        ...state,
        searchAbortController: null,
      };

    case `${CONTROL_LIST}_SUCCESS`:
      return {
        ...state,
        dashboard: [...action.response.result],
        dashboardLastUpdate: new Date().getTime(),
        pagination: {
          ...action.response.pagination,
          pageSize: defaultActive.pagination.pageSize,
        },
      };

    case `${CONTROL_CREATE}_SUCCESS`:
    case `${CONTROL_LOAD}_SUCCESS`:
      return {
        ...state,
        activeControl: action.response.result,
      };

    // failed control
    case `${CONTROL_LOAD}_FAILURE`:
      return {
        ...state,
        activeControl: null,
        error: {
          status: action.error.status,
          message: action.errorMessage,
        },
      };

    // Remove a control
    case `${CONTROL_REMOVE}_SUCCESS`:
      const idToRemove = action.response.id;
      return {
        ...state,
        dashboard: [...state.dashboard.filter((id) => id !== idToRemove)],
        activeControl:
          state.activeControl === idToRemove ? null : state.activeControl,
      };

    default:
      break;
  }

  return state;
};

const metrics = (state = {}, action) => {
  switch (action.type) {
    case `${CONTROL_LOAD_METRIC}_SUCCESS`:
      return {
        ...state,
        [action.response.metric]: action.response.count,
      };
    default:
      break;
  }

  return state;
};

export default combineReducers({
  active,
  byId,
  metrics,
});

export const getActiveControl = (state) =>
  state.active.activeControl
    ? state.byId.full[state.active.activeControl]
    : null;
export const getControls = (state) =>
  state.active.dashboard.map((id) => state.byId.lite[id]);
export const getSearchPagination = (state) => state.active.pagination;
export const getError = (state) => state.active.error;
export const getMetrics = (state) => state.metrics;
