import { useAuth0 } from "@auth0/auth0-react";
import { roles } from "@certane/arcadia-web-components";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { fetchTags } from "../../../../actions/tags";
import {
  getLoggedInUser,
  getReferenceDataDescription,
  getReferenceDataType,
  getTags,
} from "../../../../reducers";
import risksApi from "../../../../services/api/risks";
import { associationsIcon } from "../../../../util/icons";
import Avatar from "../../../common/Avatar.tsx";
import DebouncedTextField from "../../../common/DebouncedTextField";
import MultiSelectDropdown from "../../../common/MultiSelectDropdown";
import { getRefDataMenuItems, tagsToOptions } from "../../../forms/menuItems";
import ComponentField from "../../../forms/wrapper/ComponentField";
import WrappedChipListPicker from "../../../forms/wrapper/WrappedChipListPicker";
import WrappedSelect from "../../../forms/wrapper/WrappedSelect";
import WrappedTextField from "../../../forms/wrapper/WrappedTextField";

const styles = (theme) => ({
  riskRatingChip: {
    backgroundColor: theme.palette.grey[300],
    padding: `${theme.spacing(1) / 4}px ${theme.spacing(1)}px`,
    borderRadius: "16px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: "100%",
    whiteSpace: "nowrap",
    marginRight: theme.spacing(1) / 2,
  },
  nowrap: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

const AuditFindingFields = ({
  classes,
  loggedInUser,
  fieldLabels,
  customisations,
  change,
  untouch,
  formName,
  tags,
  auditFindingRatings,
  disableRiskSelection,
  localFetchTags,
  getRiskRatingDescription,
  onCreateForm,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    getAccessTokenSilently().then((accessToken) => localFetchTags(accessToken));
  }, []);

  const asyncRiskOptionsFetch = async (
    pickerFilter,
    pagination,
    abortController
  ) => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = {
      ...pickerFilter,
      limit: pagination.pageSize,
      offset: pagination.offset,
      orderByField: "title",
    };
    return risksApi.search(searchParameters, abortController, accessToken);
  };

  const fullEditAccess =
    loggedInUser &&
    _.intersection([roles.COMPLIANCE_OWNER, roles.ADMIN], loggedInUser.roles)
      .length > 0;
  const riskRegisterOptions = tagsToOptions(
    tags.filter((tag) => tag.type === "RISK_REGISTER")
  );

  return (
    <>
      <ComponentField
        component={WrappedTextField}
        name="auditFinding.observation"
        label={fieldLabels.nestedTypes.auditFinding.labels.observation}
        fullWidth
        multiline
        rowsMax={10}
        rows={2}
        disabled={!(onCreateForm || fullEditAccess)}
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      />
      <ComponentField
        component={WrappedTextField}
        name="auditFinding.managementComment"
        label={fieldLabels.nestedTypes.auditFinding.labels.managementComment}
        fullWidth
        multiline
        rowsMax={10}
        rows={2}
        disabled={!(onCreateForm || fullEditAccess)}
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      />
      <ComponentField
        component={WrappedSelect}
        name="auditFinding.rating"
        label={fieldLabels.nestedTypes.auditFinding.labels.rating}
        fullWidth
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      >
        {getRefDataMenuItems(auditFindingRatings)}
      </ComponentField>
      <ComponentField
        component={WrappedTextField}
        name="auditFinding.recommendation"
        label={fieldLabels.nestedTypes.auditFinding.labels.recommendation}
        fullWidth
        multiline
        rowsMax={10}
        rows={2}
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      />

      <ComponentField
        name="associatedRisks"
        component={WrappedChipListPicker}
        datasource={asyncRiskOptionsFetch}
        label={fieldLabels.labels.associatedRisks}
        fullWidth
        clearable
        toOption={(risk) => ({ label: risk.title, id: risk.id, risk })}
        fromOption={(option) => option.risk}
        addIcon={associationsIcon()}
        disabled={disableRiskSelection}
        renderIcon={(risk, size) => (
          <Avatar
            name={(risk.title || "Unknown").charAt(0)}
            size={size}
            round
          />
        )}
        renderLabel={(risk) => (
          <ListItemText
            primary={
              <Typography className={classes.nowrap} title={risk.title}>
                {risk.title}
              </Typography>
            }
            secondary={
              <span>
                <span className={classes.riskRatingChip}>
                  {getRiskRatingDescription(risk.riskRating)}
                </span>
                {risk.owner && risk.owner.name}
              </span>
            }
          />
        )}
        renderFilter={(onChange, filter) => (
          <Grid container spacing={1} alignItems="flex-end">
            <Grid item xs={12} md={6}>
              <DebouncedTextField
                value={filter.textSearch}
                onChange={(text) => onChange("textSearch", text)}
                placeholder="Type to filter..."
                margin="none"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <div>
                <FormLabel>Risk register</FormLabel>
                <div>
                  <MultiSelectDropdown
                    value={filter.riskRegisters || []}
                    options={riskRegisterOptions}
                    onChange={(value) => onChange("riskRegisters", value)}
                    closeOnChange
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        )}
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      />
    </>
  );
};

AuditFindingFields.propTypes = {
  classes: PropTypes.object.isRequired,
  loggedInUser: PropTypes.object,
  fieldLabels: PropTypes.object.isRequired,
  customisations: PropTypes.array,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
  auditFindingRatings: PropTypes.object.isRequired,
  localFetchTags: PropTypes.func.isRequired,
  getRiskRatingDescription: PropTypes.func.isRequired,
  disableRiskSelection: PropTypes.bool,
  onCreateForm: PropTypes.bool.isRequired,
};

AuditFindingFields.defaultProps = {
  loggedInUser: null,
  customisations: [],
  disableRiskSelection: false,
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
  tags: getTags(state),
  auditFindingRatings: getReferenceDataType(state, "AuditFindingRating"),
  getRiskRatingDescription: (rating) =>
    getReferenceDataDescription(state, "RiskRating", rating, "-"),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    localFetchTags: fetchTags,
  })
)(AuditFindingFields);
