import {
  FILE_UPLOAD_ALL_COMPLETED,
  FILE_UPLOAD_CLEAR,
  FILE_UPLOAD_COMPLETED,
  FILE_UPLOAD_GOT_UPLOAD_URL,
  FILE_UPLOAD_PROGRESS,
  FILE_UPLOAD_REMOVE,
  FILE_UPLOAD_STARTED,
} from "../actions/files";
import logger from "../util/logger";

function getKey(action) {
  return [action.uploadNamespace, action.document.fileName].join("__");
}

function getKeyPrefix(action) {
  return action.uploadNamespace;
}

function splitKey(key) {
  const parts = key.split("__");
  return {
    prefix: parts[0],
    fileName: parts[1],
  };
}

// state stores files in a map, using a key hierarchy: <prefix>__<filename>

const files = (state = { uploading: false, filesInProgress: {} }, action) => {
  switch (action.type) {
    case FILE_UPLOAD_STARTED:
      logger.debug("action.uploadNamespace", action.uploadNamespace);
      const startedFile = action.document;
      const startedKey = getKey(action);

      return {
        uploading: true,
        filesInProgress: {
          ...state.filesInProgress,
          [startedKey]: {
            ...state.filesInProgress[startedKey],
            ...startedFile,
          },
        },
      };

    case FILE_UPLOAD_GOT_UPLOAD_URL:
      const initFile = action.document;
      const initKey = getKey(action);

      return {
        uploading: true,
        filesInProgress: {
          ...state.filesInProgress,
          [initKey]: {
            ...state.filesInProgress[initKey],
            ...initFile,
          },
        },
      };

    case FILE_UPLOAD_PROGRESS:
      const updatedFile = action.document;
      const updatedKey = getKey(action);
      const updatedKnownFile = state.filesInProgress[updatedKey];

      if (
        updatedKnownFile &&
        updatedKnownFile.percentCompleted < updatedFile.percentCompleted
      ) {
        return {
          uploading: true,
          filesInProgress: {
            ...state.filesInProgress,
            [updatedKey]: {
              ...updatedKnownFile,
              percentCompleted: updatedFile.percentCompleted,
            },
          },
        };
      }
      break;

    case FILE_UPLOAD_COMPLETED:
      const completedFile = action.document;
      const completedKey = getKey(action);
      const completedKnownFile = state.filesInProgress[completedKey];

      if (completedKnownFile) {
        return {
          ...state,
          filesInProgress: {
            ...state.filesInProgress,
            [completedKey]: {
              ...completedKnownFile,
              ...completedFile,
            },
          },
        };
      }
      break;

    case FILE_UPLOAD_ALL_COMPLETED:
      const stillUploading = Object.values(state.filesInProgress).some(
        (file) => !file.gcsObjectName
      );

      return {
        ...state,
        uploading: stillUploading,
      };

    case FILE_UPLOAD_REMOVE:
      const keyToRemove = getKey(action);
      const fileToRemove = state.filesInProgress[keyToRemove];

      if (fileToRemove) {
        if (fileToRemove.cancel) {
          fileToRemove.cancel();
        }

        const filesInProgress = {
          ...state.filesInProgress,
        };

        delete filesInProgress[keyToRemove];

        const stillUploadingAfterRemoval = Object.values(filesInProgress).some(
          (file) => !file.gcsObjectName
        );

        return {
          uploading: stillUploadingAfterRemoval,
          filesInProgress,
        };
      }
      break;

    case FILE_UPLOAD_CLEAR:
      const keyPrefix = getKeyPrefix(action);
      const filesInProgressAfterClear = {
        ...state.filesInProgress,
      };

      Object.keys(state.filesInProgress).forEach((key) => {
        if (key.startsWith(keyPrefix)) {
          delete filesInProgressAfterClear[key];
        }
      });

      const stillUploadingAfterClear = Object.values(
        filesInProgressAfterClear
      ).some((file) => !file.gcsObjectName);

      return {
        uploading: stillUploadingAfterClear,
        filesInProgress: filesInProgressAfterClear,
      };

    default:
      return state;
  }

  return state;
};

export default files;

export const getFilesInProgress = (state) => {
  const result = {};

  Object.keys(state.filesInProgress).forEach((key) => {
    const { prefix } = splitKey(key);
    result[prefix] = result[prefix] || [];
    result[prefix].push(state.filesInProgress[key]);
  });

  return result;
};

export const isUploading = (state) => state.uploading;
