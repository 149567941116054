import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import { required } from "redux-form-validators";
import { scrollToFirstInvalidInput } from "../../../util/formHelpers";
import FormError from "../../common/FormError";
import WrappedDatePicker from "../wrapper/WrappedDatePicker";

const BulkRiskMeasureUpdatesForm = ({ error }) => (
  <form autoComplete="off">
    {error && <FormError errorMessage={error} />}
    <Field
      name="dueDate"
      component={WrappedDatePicker}
      label="Due"
      validate={required({ msg: "Due date is required" })}
      required
      fullWidth
      minDate={Date.now()}
    />
    <Field
      name="reminderDate"
      component={WrappedDatePicker}
      label="Reminder"
      validate={required({ msg: "Reminder date is required" })}
      required
      fullWidth
      minDate={Date.now()}
    />
  </form>
);

BulkRiskMeasureUpdatesForm.propTypes = {
  error: PropTypes.string,
};

BulkRiskMeasureUpdatesForm.defaultProps = {
  error: null,
};

const mapStateToProps = () => ({});

export const BULK_RISK_MEASURE_UPDATES_FORM_NAME = "BulkRiskMeasureUpdatesForm";

const form = reduxForm({
  form: BULK_RISK_MEASURE_UPDATES_FORM_NAME,
  onSubmitFail: (errors) => scrollToFirstInvalidInput(errors),
});

const reduxData = connect(mapStateToProps);

export default compose(form, reduxData)(BulkRiskMeasureUpdatesForm);
