import { requestJSON } from "./http";

const createViewFileTest = (upload, accessToken) =>
  requestJSON({
    path: "/analytics/views",
    method: "POST",
    data: upload,
    accessToken,
  });

const getViewFileTest = (id, accessToken) =>
  requestJSON({
    path: `/analytics/views/${id}`,
    method: "GET",
    accessToken,
  });

export default {
  createViewFileTest,
  getViewFileTest,
};
