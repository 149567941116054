import { useAuth0 } from "@auth0/auth0-react";
import Collapse from "@material-ui/core/Collapse";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import Link from "@material-ui/core/Link";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { length, numericality, required } from "redux-form-validators";
import complaintIssueDefinitionsPdf from "../../../../images/complaint-issue-definitions.pdf";
import productServicePdf from "../../../../images/product-or-service-quick-reference.pdf";
import {
  getFilteredReferenceDataType,
  getReferenceDataType,
} from "../../../../reducers";
import organisationsApi from "../../../../services/api/organisations";
import { organisationIcon } from "../../../../util/icons";
import { yesNoOptions } from "../../../../util/radioOptions";
import Avatar from "../../../common/Avatar";
import CardSection from "../../../common/CardSection";
import { getRefDataMenuItems } from "../../../forms/menuItems";
import ComponentField from "../../../forms/wrapper/ComponentField";
import WrappedChipListPicker from "../../../forms/wrapper/WrappedChipListPicker";
import WrappedDatePicker from "../../../forms/wrapper/WrappedDatePicker";
import WrappedRadioGroup from "../../../forms/wrapper/WrappedRadioGroup";
import WrappedSelect from "../../../forms/wrapper/WrappedSelect";
import WrappedSelectChips from "../../../forms/wrapper/WrappedSelectChips";
import WrappedTextField from "../../../forms/wrapper/WrappedTextField";
import WarningText from "../../../WarningText";
import ContactDetailsFields from "../contacts/ContactDetailsFields";
import EDRDetailsFields from "./EDRDetailsFields";
import ThirdPartyAuthorityFields from "./ThirdPartyAuthorityFields";
import OriginFields from "../incident/OriginFields";

const useStyles = makeStyles((theme) => ({
  label: {
    position: "relative",
  },
  linkText: {
    cursor: "pointer",
    textDecoration: "none",
    color: theme.palette.swatch.link,
  },
}));

const THIRD_PARTY = "ThirdParty";
const CLIENT = "Client";

const ComplaintDetailsFields = ({
  fieldLabels,
  gutterTop,
  onCreateForm,
  thirdPartyAuthorityValues,
  activeAdviser,
  activeIdrDetails,
  activeEdrDetails,
  activeExemption,
  activeSystemicIssue,
  activeRemediationType,
  communicationFormats,
  remediationTypes,
  complaintProductServices,
  complaintIssues,
  exemptionTypes,
  incidentCauseOptions,
  change,
  untouch,
  customisations,
  formName,
  fieldPrefix,
  entityRelationship,
}) => {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const [previousOrganisations, setPreviousOrganisations] = useState(
    entityRelationship.organisations || []
  );
  const [previousProducts, setPreviousProducts] = useState(
    entityRelationship.financialProducts || []
  );
  const [remediationTypeIsMonetary, setRemediationTypeIsMonetary] = useState(
    !!(
      activeRemediationType &&
      remediationTypes.values[activeRemediationType].props.monetary
    )
  );
  const [
    remediationTypeRequiresExplanation,
    setRemediationTypeRequiresExplanation,
  ] = useState(
    !!(
      activeRemediationType &&
      remediationTypes.values[activeRemediationType].props.requiresExplanation
    )
  );
  const [initRemediationTypeIsMonetary] = useState(
    !!(
      activeRemediationType &&
      remediationTypes.values[activeRemediationType].props.monetary
    )
  );
  const [complaintLodgedBy, setComplaintLodgedBy] = useState(null);

  useEffect(() => {
    // Don't preselect in create form.
    if (onCreateForm && !complaintLodgedBy) {
      return;
    }
    if (thirdPartyAuthorityValues) {
      setComplaintLodgedBy(THIRD_PARTY);
    } else {
      setComplaintLodgedBy(CLIENT);
    }
  }, [thirdPartyAuthorityValues]);

  useEffect(() => {
    if (complaintLodgedBy === CLIENT) {
      change("complaint.thirdPartyAuthority", null);
    }
  }, [complaintLodgedBy]);

  const asyncExternalOrganisationOptionsFetch = async (
    pickerFilter,
    pagination,
    abortController
  ) => {
    const accessToken = await getAccessTokenSilently();

    const organisations = entityRelationship.organisations || [];
    const products = entityRelationship.financialProducts || [];

    const searchParameters = {
      ...pickerFilter,
      limit: pagination.pageSize,
      offset: pagination.offset,
      organisationIds:
        products.length === 0
          ? organisations.map((o) => o.organisation.id)
          : [],
      productIds: products.map((p) => p.id),
      orderByField: "name",
      excludeGroupFilters: true,
    };
    return organisationsApi.search(
      searchParameters,
      abortController,
      accessToken
    );
  };

  useEffect(() => {
    const organisations = entityRelationship.organisations || [];
    const products = entityRelationship.financialProducts || [];
    if (
      !_.isEqual(organisations, previousOrganisations) ||
      !_.isEqual(products, previousProducts)
    ) {
      change("complaint.complaintReceivedBy", []);
      setPreviousOrganisations(organisations);
      setPreviousProducts(products);
    }
  }, [entityRelationship]);

  const toggleEdrDetails = (event) => {
    if (event.target.value === "true") {
      change("complaint.edrDetails", {});
    } else {
      change("complaint.edrDetails", null);
    }
  };

  const activeAdviserDetails = (event) => {
    if (event.target.value === "true") {
      change("complaint.adviser", {});
    } else {
      change("complaint.adviser", null);
    }
  };

  const toggleExemption = (event) => {
    if (event.target.value === "true") {
      change("complaint.exemption", {});
    } else {
      change("complaint.exemption", null);
    }
  };

  const complaintLabels = fieldLabels.nestedTypes.complaint;

  useEffect(() => {
    if (activeRemediationType) {
      const typeDef = remediationTypes.values[activeRemediationType];
      setRemediationTypeIsMonetary(!!typeDef.props.monetary);
      setRemediationTypeRequiresExplanation(
        !!typeDef.props.requiresExplanation
      );
    } else {
      setRemediationTypeIsMonetary(false);
      setRemediationTypeRequiresExplanation(false);
    }
  }, [activeRemediationType]);

  useEffect(() => {
    if (
      !remediationTypeIsMonetary &&
      remediationTypeIsMonetary !== initRemediationTypeIsMonetary
    ) {
      change("complaint.compensationAmount", null);
      change("complaint.compensationPaidBy", null);
    }
  }, [remediationTypeIsMonetary]);

  useEffect(() => {
    if (!remediationTypeRequiresExplanation) {
      change("complaint.remediationDetails", null);
    }
  }, [remediationTypeRequiresExplanation]);

  return (
    <>
      <CardSection
        title={fieldLabels.labels.complaint}
        gutterTop={gutterTop}
        data-cy="complaintdetails"
      >
        <ComponentField
          fieldPrefix={fieldPrefix}
          name="complaint.dateReceived"
          component={WrappedDatePicker}
          label={complaintLabels.labels.dateReceived}
          fullWidth
          disableFuture
          customisations={customisations}
          change={change}
          untouch={untouch}
          formName={formName}
          data-cy="dateReceived"
        />
        <ComponentField
          name="complaint.complaintReceivedBy"
          component={WrappedChipListPicker}
          datasource={asyncExternalOrganisationOptionsFetch}
          fieldPrefix={fieldPrefix}
          label={complaintLabels.labels.complaintReceivedBy}
          fullWidth
          clearable
          toOption={(org) => ({ label: org.name, id: org.id, org })}
          fromOption={(option) => option.org}
          addIcon={organisationIcon()}
          renderIcon={(org, size) => (
            <Avatar
              name={(org.name || "Unknown").charAt(0)}
              size={size}
              round
            />
          )}
          customisations={customisations}
          change={change}
          untouch={untouch}
          formName={formName}
          data-cy="receiver"
        />
        <ComponentField
          name="complaint.complaintFormat"
          component={WrappedSelect}
          fieldPrefix={fieldPrefix}
          label={complaintLabels.labels.complaintFormat}
          fullWidth
          customisations={customisations}
          change={change}
          untouch={untouch}
          formName={formName}
          data-cy="channel"
        >
          {getRefDataMenuItems(communicationFormats)}
        </ComponentField>
        <FormControl component="fieldset" fullWidth margin="dense">
          <InputLabel
            className={classes.label}
            shrink
            data-cy={"complaintLodgedBy"}
          >
            Complaint lodged by
          </InputLabel>
          <RadioGroup
            aria-label="complaintLodgedBy"
            name="complaintLodgedBy"
            value={complaintLodgedBy}
            onChange={(event) => {
              setComplaintLodgedBy(event.target.value);
            }}
            row
          >
            <FormControlLabel
              value={CLIENT}
              control={<Radio />}
              label="Complainant"
            />
            <FormControlLabel
              value={THIRD_PARTY}
              control={<Radio />}
              label="Third party"
            />
          </RadioGroup>
        </FormControl>
        <ComponentField
          name="complaint.complaintProductService"
          component={WrappedSelectChips}
          options={complaintProductServices.ids}
          label={complaintLabels.labels.complaintProductService}
          validate={length({
            min: 1,
            max: 3,
            msg: `${complaintLabels.labels.complaintProductService} select from 1 to 3 options`,
          })}
          required
          fullWidth
          toOption={(id) => ({
            label: complaintProductServices.values[id].description,
            value: id,
          })}
          helperText={
            <span>
              Starting typing to search for product or service, a complete list
              is{" "}
              <Link
                className={classes.linkText}
                href={productServicePdf}
                target="_blank"
              >
                here
              </Link>
            </span>
          }
          fromOption={(option) => option.value}
          customisations={customisations}
          change={change}
          untouch={untouch}
          formName={formName}
        />
        <ComponentField
          name="complaint.complaintIssue"
          component={WrappedSelectChips}
          options={complaintIssues.ids}
          label={complaintLabels.labels.complaintIssue}
          validate={length({
            min: 1,
            max: 3,
            msg: `${complaintLabels.labels.complaintIssue} select from 1 to 3 options`,
          })}
          required
          fullWidth
          toOption={(id) => ({
            label: complaintIssues.values[id].description,
            value: id,
          })}
          fromOption={(option) => option.value}
          helperText={
            <span>
              Starting typing to search for the complaint issue, a complete list
              is{" "}
              <Link
                className={classes.linkText}
                href={complaintIssueDefinitionsPdf}
                target="_blank"
              >
                here
              </Link>
            </span>
          }
          customisations={customisations}
          change={change}
          untouch={untouch}
          formName={formName}
        />
        <ComponentField
          fieldPrefix={fieldPrefix}
          component={WrappedTextField}
          name="complaint.complaintDetails"
          label={complaintLabels.labels.complaintDetails}
          validate={required({
            msg: `${complaintLabels.labels.complaintDetails} are required`,
          })}
          fullWidth
          required
          multiline
          rowsMax={10}
          rows={2}
          helperText="Describe the nature of the complaint here"
          customisations={customisations}
          change={change}
          untouch={untouch}
          formName={formName}
        />
        <ComponentField
          name="complaint.complaintCause"
          component={WrappedSelect}
          fieldPrefix={fieldPrefix}
          label={complaintLabels.labels.complaintCause}
          fullWidth
          customisations={customisations}
          change={change}
          untouch={untouch}
          formName={formName}
          data-cy="complaintCause"
        >
          {getRefDataMenuItems(incidentCauseOptions)}
        </ComponentField>
        <ComponentField
          name="complaint.aboutRepresentative"
          component={WrappedRadioGroup}
          label={complaintLabels.labels.aboutRepresentative}
          options={yesNoOptions}
          row
          fullWidth
          nullLabel="Unknown"
          customisations={customisations}
          change={change}
          untouch={untouch}
          formName={formName}
        />
        <ComponentField
          name="complaint.systemicIssue"
          component={WrappedRadioGroup}
          label={complaintLabels.labels.systemicIssue}
          options={yesNoOptions}
          nullLabel={"Unknown"}
          row
          fullWidth
          customisations={customisations}
          change={change}
          untouch={untouch}
          formName={formName}
        />
        {!!activeSystemicIssue && (
          <WarningText text="Ensure that an incident is created and/or linked to this systemic complaint." />
        )}
      </CardSection>
      <Collapse in={complaintLodgedBy === THIRD_PARTY}>
        <CardSection
          title={fieldLabels.nestedTypes.complaint.labels.thirdPartyAuthority}
          gutterTop
        >
          {complaintLodgedBy === THIRD_PARTY && (
            <ThirdPartyAuthorityFields
              name="complaint.thirdPartyAuthority"
              fieldLabels={complaintLabels.nestedTypes.thirdPartyAuthority}
              customisations={customisations}
              change={change}
              untouch={untouch}
              formName={formName}
            />
          )}
        </CardSection>
      </Collapse>
      <CardSection
        title={fieldLabels.nestedTypes.complaint.labels.origin}
        gutterTop
      >
        <OriginFields
          customisations={customisations}
          change={change}
          untouch={untouch}
          formName={formName}
          name="complaint.origin"
          fieldLabels={fieldLabels.nestedTypes.complaint.nestedTypes.origin}
          entityRelationship={entityRelationship}
        />
      </CardSection>
      <CardSection title="Remediation" gutterTop>
        <ComponentField
          name="complaint.remediationType"
          component={WrappedSelect}
          fieldPrefix={fieldPrefix}
          label={complaintLabels.labels.remediationType}
          fullWidth
          customisations={customisations}
          change={change}
          untouch={untouch}
          formName={formName}
          data-cy="remediationType"
        >
          {getRefDataMenuItems(remediationTypes)}
        </ComponentField>
        {remediationTypeIsMonetary && (
          <ComponentField
            fieldPrefix={fieldPrefix}
            component={WrappedTextField}
            name="complaint.compensationAmount"
            label={complaintLabels.labels.compensationAmount}
            validate={numericality({
              ">=": 0,
              allowBlank: true,
            })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            fullWidth
            customisations={customisations}
            change={change}
            untouch={untouch}
            formName={formName}
          />
        )}
        {remediationTypeIsMonetary && (
          <ComponentField
            fieldPrefix={fieldPrefix}
            name="complaint.compensationPaidBy"
            component={WrappedTextField}
            label={complaintLabels.labels.compensationPaidBy}
            fullWidth
            customisations={customisations}
            change={change}
            untouch={untouch}
            formName={formName}
          />
        )}
        {remediationTypeRequiresExplanation && (
          <ComponentField
            fieldPrefix={fieldPrefix}
            name="complaint.remediationDetails"
            component={WrappedTextField}
            label={complaintLabels.labels.remediationDetails}
            fullWidth
            customisations={customisations}
            required
            validate={required({
              msg: `${complaintLabels.labels.remediationDetails} is required`,
            })}
            change={change}
            untouch={untouch}
            formName={formName}
          />
        )}
      </CardSection>
      <CardSection title="Adviser" gutterTop>
        <FormControl component="fieldset" margin="dense" required>
          <InputLabel shrink className={classes.label}>
            Has an adviser?
          </InputLabel>
          <RadioGroup
            value={activeAdviser ? "true" : "false"}
            onChange={activeAdviserDetails}
            row
          >
            <FormControlLabel value="true" control={<Radio />} label="Yes" />
            <FormControlLabel value="false" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
        {activeAdviser && (
          <ContactDetailsFields
            name="complaint.adviser"
            contactLabels={complaintLabels.nestedTypes.adviser.labels}
            withType
            withTypeHelp=""
          />
        )}
      </CardSection>
      <CardSection title={complaintLabels.labels.idrDetails} gutterTop>
        <ComponentField
          fullWidth
          component={WrappedRadioGroup}
          name="complaint.idrDetails.acknowledged"
          label={complaintLabels.nestedTypes.idrDetails.labels.acknowledged}
          options={yesNoOptions}
          row
          validate={required({
            msg: `${complaintLabels.nestedTypes.idrDetails.labels.acknowledged} is required`,
          })}
          required
          customisations={customisations}
          change={change}
          untouch={untouch}
          formName={formName}
        />
        {activeIdrDetails && activeIdrDetails.acknowledged && (
          <>
            <ComponentField
              fieldPrefix={fieldPrefix}
              name="complaint.idrDetails.acknowledgementDate"
              component={WrappedDatePicker}
              label={
                complaintLabels.nestedTypes.idrDetails.labels
                  .acknowledgementDate
              }
              fullWidth
              disableFuture
              customisations={customisations}
              change={change}
              untouch={untouch}
              formName={formName}
            />
            <ComponentField
              name="complaint.idrDetails.acknowledgementFormat"
              component={WrappedSelect}
              fieldPrefix={fieldPrefix}
              label={
                complaintLabels.nestedTypes.idrDetails.labels
                  .acknowledgementFormat
              }
              fullWidth
              customisations={customisations}
              change={change}
              untouch={untouch}
              formName={formName}
              data-cy="acknowledgementFormat"
            >
              {getRefDataMenuItems(communicationFormats)}
            </ComponentField>
          </>
        )}
        <ComponentField
          fieldPrefix={fieldPrefix}
          name="complaint.idrDetails.dateFinalIDRResponseIssued"
          component={WrappedDatePicker}
          label={
            complaintLabels.nestedTypes.idrDetails.labels
              .dateFinalIDRResponseIssued
          }
          fullWidth
          disableFuture
          customisations={customisations}
          change={change}
          untouch={untouch}
          formName={formName}
        />
      </CardSection>
      <CardSection title={complaintLabels.labels.edrDetails} gutterTop>
        <FormControl component="fieldset" margin="dense" required>
          <InputLabel shrink className={classes.label}>
            Is there an EDR in progress?
          </InputLabel>
          <RadioGroup
            value={activeEdrDetails ? "true" : "false"}
            onChange={toggleEdrDetails}
            row
          >
            <FormControlLabel value="true" control={<Radio />} label="Yes" />
            <FormControlLabel value="false" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
        {activeEdrDetails && (
          <EDRDetailsFields
            name="complaint.edrDetails"
            fieldLabels={complaintLabels.nestedTypes.edrDetails}
          />
        )}
      </CardSection>
      <CardSection title={complaintLabels.labels.exemption} gutterTop>
        <FormControl component="fieldset" margin="dense">
          <InputLabel shrink className={classes.label}>
            Has exemption occurred?
          </InputLabel>
          <RadioGroup
            value={activeExemption ? "true" : "false"}
            onChange={toggleExemption}
            row
          >
            <FormControlLabel value="true" control={<Radio />} label="Yes" />
            <FormControlLabel value="false" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
        {activeExemption && (
          <>
            <ComponentField
              name="complaint.exemption.exemptionType"
              component={WrappedSelect}
              fieldPrefix={fieldPrefix}
              label={complaintLabels.nestedTypes.exemption.labels.exemptionType}
              fullWidth
              customisations={customisations}
              change={change}
              untouch={untouch}
              formName={formName}
              data-cy="exemptionType"
            >
              {getRefDataMenuItems(exemptionTypes)}
            </ComponentField>
            <ComponentField
              fieldPrefix={fieldPrefix}
              name="complaint.exemption.exemptionDescription"
              component={WrappedTextField}
              label={
                complaintLabels.nestedTypes.exemption.labels
                  .exemptionDescription
              }
              fullWidth
              customisations={customisations}
              change={change}
              untouch={untouch}
              formName={formName}
              data-cy="exemptionDescription"
            />
            <ComponentField
              fieldPrefix={fieldPrefix}
              name="complaint.exemption.delayedIDRDate"
              component={WrappedDatePicker}
              label={
                complaintLabels.nestedTypes.exemption.labels.delayedIDRDate
              }
              fullWidth
              disableFuture
              customisations={customisations}
              change={change}
              untouch={untouch}
              formName={formName}
            />
          </>
        )}
      </CardSection>
    </>
  );
};

ComplaintDetailsFields.propTypes = {
  fieldLabels: PropTypes.object.isRequired,
  onCreateForm: PropTypes.bool.isRequired,
  gutterTop: PropTypes.bool.isRequired,
  thirdPartyAuthorityValues: PropTypes.object,
  activeAdviser: PropTypes.object,
  activeIdrDetails: PropTypes.object,
  activeEdrDetails: PropTypes.object,
  activeExemption: PropTypes.object,
  activeSystemicIssue: PropTypes.bool,
  activeRemediationType: PropTypes.string,
  communicationFormats: PropTypes.object.isRequired,
  remediationTypes: PropTypes.object.isRequired,
  complaintProductServices: PropTypes.object.isRequired,
  complaintIssues: PropTypes.object.isRequired,
  exemptionTypes: PropTypes.object.isRequired,
  incidentCauseOptions: PropTypes.object.isRequired,
  formName: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  customisations: PropTypes.array,
  fieldPrefix: PropTypes.string,
  entityRelationship: PropTypes.object.isRequired,
};

ComplaintDetailsFields.defaultProps = {
  activeAdviser: null,
  activeIdrDetails: null,
  activeEdrDetails: null,
  activeExemption: null,
  activeSystemicIssue: null,
  activeRemediationType: null,
  customisations: [],
  fieldPrefix: "",
};

const mapStateToProps = (state, ownProps) => {
  const reduxFormSelector = formValueSelector(ownProps.formName);
  return {
    thirdPartyAuthorityValues: reduxFormSelector(
      state,
      "complaint.thirdPartyAuthority"
    ),
    activeAdviser: reduxFormSelector(state, "complaint.adviser"),
    activeIdrDetails: reduxFormSelector(state, "complaint.idrDetails"),
    activeEdrDetails: reduxFormSelector(state, "complaint.edrDetails"),
    activeExemption: reduxFormSelector(state, "complaint.exemption"),
    activeSystemicIssue: reduxFormSelector(state, "complaint.systemicIssue"),
    activeRemediationType: reduxFormSelector(
      state,
      "complaint.remediationType"
    ),
    communicationFormats: getReferenceDataType(state, "CommunicationFormat"),
    remediationTypes: getReferenceDataType(state, "RemediationType"),
    complaintIssues: getReferenceDataType(state, "ComplaintIssue"),
    complaintProductServices: getReferenceDataType(
      state,
      "ComplaintProductService"
    ),
    exemptionTypes: getReferenceDataType(state, "ExemptionType"),
    incidentCauseOptions: getFilteredReferenceDataType(
      state,
      "IncidentCause",
      (t) => t.selectable
    ),
  };
};

export default connect(mapStateToProps)(ComplaintDetailsFields);
