import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import LinkIcon from "@material-ui/icons/Link";
import UnlinkIcon from "@material-ui/icons/LinkOff";
import makeStyles from "@material-ui/core/styles/makeStyles";
import BasicFileList from "../../common/file/BasicFileList";
import LinkFileToMetropolisForm, {
  LINK_TO_METROPOLIS_FORM_NAME,
} from "./LinkFileToMetropolisForm";
import FormDialog from "../../forms/FormDialog";
import mediaApi from "../../../services/api/media";
import { useAuth0 } from "@auth0/auth0-react";

const PUBLISH_VERSION = "PUBLISH_VERSION";

const useStyles = makeStyles((theme) => ({
  warning: {
    margin: `${theme.spacing(3)}px 0`,
    display: "block",
    color: theme.palette.warning.main,
  },
}));

const ActionFormFlaggedFileList = ({
  workItem,
  requiredFlags,
  availableFlags,
  metropolisIntegration,
  metropolisLinkedDocuments,
  changeMetropolisLinkedDocuments,
  addBeforeSubmitAction,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { documents } = workItem;
  const classes = useStyles();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedDocumentToLink, setSelectedDocumentToLink] = useState(null);
  const [metropolisLinkedFiles, setMetropolisLinkedFiles] = useState({});

  const groupByFlagId = (docs, flags) => {
    const byFlag = {};
    _.forEach(flags, (flag) => {
      byFlag[flag.id] = _.filter(docs, (document) =>
        _.includes(document.flags, flag.id)
      );
    });
    return byFlag;
  };

  const byFlagId = groupByFlagId(documents, availableFlags);

  const handleSubmit = async (formValues) => {
    const accessToken = await getAccessTokenSilently();
    const workItemDocument = formValues.selectedDocument;
    const mediaId = formValues.media.id;

    // update local state
    setMetropolisLinkedFiles({
      ...metropolisLinkedFiles,
      [workItemDocument.id]: formValues,
    });

    // update action form
    changeMetropolisLinkedDocuments({
      ...metropolisLinkedDocuments,
      [workItemDocument.id]: mediaId,
    });

    // add new media version before action form submit
    const mediaDocumentVersion = {
      ...formValues,
      document: workItemDocument,
      reference: workItem.friendlyId,
    };
    addBeforeSubmitAction(() =>
      mediaApi.addDocumentLinkFromArcadia(
        mediaId,
        mediaDocumentVersion,
        accessToken
      )
    );

    setSelectedDocumentToLink(null);
    setDialogOpen(false);
  };

  const unlink = (doc) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [doc.id]: deleted, ...newState } = metropolisLinkedDocuments;
    setMetropolisLinkedFiles(newState);

    // update action form
    changeMetropolisLinkedDocuments(newState);
  };

  const isDocumentLinked = (documentId) => metropolisLinkedFiles[documentId];

  const createFilePrimaryText = (doc) => {
    if (!isDocumentLinked(doc.id)) {
      return doc.fileName;
    }
    const file = metropolisLinkedFiles[doc.id];
    return (
      <>
        <span>{doc.fileName}</span>
        <Typography variant="subtitle2" component="span" data-cy="linked to ">
          {" "}
          - linked to {file.media.name}
        </Typography>
      </>
    );
  };

  const createFileSecondaryAction = (doc) => {
    const docReadyToPublish = doc.flags.find(
      (flag) => flag === PUBLISH_VERSION
    );
    if (!metropolisIntegration || !docReadyToPublish) return null;

    if (!isDocumentLinked(doc.id)) {
      return (
        // link media
        <Tooltip title="Link to Metropolis media">
          <ListItemSecondaryAction
            onClick={() => {
              setSelectedDocumentToLink(doc);
              setDialogOpen(true);
            }}
          >
            <IconButton edge="end" aria-label="link" data-cy="link">
              <LinkIcon color="action" />
            </IconButton>
          </ListItemSecondaryAction>
        </Tooltip>
      );
    }

    return (
      // unlink media
      <Tooltip title="Remove Metropolis link">
        <ListItemSecondaryAction onClick={() => unlink(doc)}>
          <IconButton edge="end" aria-label="link">
            <UnlinkIcon color="action" />
          </IconButton>
        </ListItemSecondaryAction>
      </Tooltip>
    );
  };

  const missingRequiredFlags = requiredFlags.filter(
    (flag) => !byFlagId[flag.id] || byFlagId[flag.id].length === 0
  );

  return (
    <div>
      {availableFlags.map((flag) => {
        const files = byFlagId[flag.id];

        if (files.length > 0) {
          return (
            <Fragment key={flag.id}>
              <Typography variant="body1" gutterBottom>
                {flag.description} Files
              </Typography>
              <BasicFileList
                documents={files}
                primaryText={createFilePrimaryText}
                secondaryAction={
                  flag.id === PUBLISH_VERSION
                    ? createFileSecondaryAction
                    : undefined
                }
              />

              <FormDialog
                title="Link media"
                submitButtonText="Link"
                formComponent={LinkFileToMetropolisForm}
                formName={LINK_TO_METROPOLIS_FORM_NAME}
                open={dialogOpen}
                onCancel={() => setDialogOpen(false)}
                onSubmit={handleSubmit}
                productIds={
                  workItem.entityRelationship
                    ? workItem.entityRelationship.financialProducts.map(
                        (product) => product.id
                      )
                    : []
                }
                selectedDocument={selectedDocumentToLink}
                data-cy="linkMedia"
              />

              {flag.id === PUBLISH_VERSION &&
                Object.keys(metropolisLinkedFiles).length > 0 && (
                  <strong>
                    Selected Metropolis media will be updated with the new
                    version on closing this item.
                  </strong>
                )}
            </Fragment>
          );
        }
        return null;
      })}
      {missingRequiredFlags.length > 0 && (
        <div className={classes.warning}>
          <Typography variant="body2" display="block">
            Warning: No files were marked as the following. Please correct this
            unless it was intentional:
          </Typography>
          <Typography variant="caption" display="block">
            <ul>
              {missingRequiredFlags.map((flag) => (
                <li key={flag.id}>{flag.description}</li>
              ))}
            </ul>
          </Typography>
        </div>
      )}
    </div>
  );
};

ActionFormFlaggedFileList.propTypes = {
  requiredFlags: PropTypes.array.isRequired,
  availableFlags: PropTypes.array.isRequired,
  workItem: PropTypes.object.isRequired,
  metropolisIntegration: PropTypes.bool.isRequired,
  metropolisLinkedDocuments: PropTypes.object.isRequired,
  changeMetropolisLinkedDocuments: PropTypes.func.isRequired,
  addBeforeSubmitAction: PropTypes.func.isRequired,
};

export default ActionFormFlaggedFileList;
