import { useAuth0 } from "@auth0/auth0-react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import ActionButton from "../../../components/common/ActionButton";
import ActionHeading from "../../../components/common/ActionHeading";
import Container from "../../../components/common/Container";
import FloatingActionButton from "../../../components/common/FloatingActionButton";
import HeaderBar from "../../../components/common/HeaderBar";
import PageHeading from "../../../components/common/PageHeading";
import RiskControlFilters from "../../../components/risk/RiskControlFilters";
import RiskControlListing from "../../../components/risk/RiskControlListing";
import { getLoggedInUser, getRiskControls } from "../../../reducers";
import {
  clearIcon,
  downloadIcon,
  printIcon,
  riskControlIcon,
} from "../../../util/icons";
import useLocationStateFilter from "../../../util/locationStateFilter";
import usePersistedState from "../../../util/persistedState";
import { performExport } from "../../../util/asyncRequestHelper";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  contents: {
    marginBottom: "75px",
  },
}));

const defaultFilter = {
  textSearch: null,
  ownerIds: [],
  riskRegisters: [],
};

const Index = ({ history, loggedInUser, riskControls }) => {
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();
  const [downloadingFormat, setDownloadingFormat] = useState(null);
  const [sortBy, setSortBy] = usePersistedState(
    `riskcontrol-listing-sort-${loggedInUser.email}`,
    {
      field: "name",
      direction: "asc",
    }
  );
  const [filter, setFilter] = usePersistedState(
    `riskcontrol-listing-filter-${loggedInUser.email}`,
    defaultFilter,
    ["textSearch"]
  );
  useLocationStateFilter(setFilter, history);

  const clearFilter = () => setFilter({ ...defaultFilter });
  const updateFilter = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  const getSearchParameters = () => ({
    ...filter,
    orderByField: `${sortBy.direction === "desc" ? "-" : ""}${sortBy.field}`,
    limit: 100,
    offset: 0,
  });

  const onDownload = async (format) => {
    const accessToken = await getAccessTokenSilently();
    setDownloadingFormat(format);
    const searchParameters = getSearchParameters();
    performExport(
      "RiskControl",
      searchParameters,
      format,
      accessToken,
      () => setDownloadingFormat(null),
      "Risk controls download",
      "Please wait while we export your risk controls",
      "This window can be closed"
    );
  };

  const actions = [
    <ActionButton
      key="clearFilter"
      tooltip="Clear filters"
      icon={clearIcon()}
      onClick={clearFilter}
      data-cy="clear-filters-button"
    />,
  ];
  const pdfDownloadEnabled = true;
  const csvDownloadEnabled = false;

  if (pdfDownloadEnabled) {
    actions.push(
      <ActionButton
        tooltip="Download"
        icon={printIcon()}
        onClick={() => onDownload("PDF")}
        loading={downloadingFormat === "PDF"}
        disabled={riskControls.length === 0}
      />
    );
  }
  if (csvDownloadEnabled) {
    actions.push(
      <ActionButton
        tooltip="Download"
        icon={downloadIcon()}
        onClick={() => onDownload("CSV")}
        loading={downloadingFormat === "CSV"}
        disabled={riskControls.length === 0}
      />
    );
  }
  return (
    <div>
      <HeaderBar fluid>
        <ActionHeading
          heading={
            <PageHeading icon={riskControlIcon()} heading="Risk controls" />
          }
          actions={actions}
        />
      </HeaderBar>
      <FloatingActionButton
        onClick={() => history.push("/risk/controls/new")}
      />
      <RiskControlFilters filter={filter} updateFilter={updateFilter} />
      <Container fluid className={classes.contents}>
        <RiskControlListing
          history={history}
          filter={filter}
          clearFilter={clearFilter}
          sortBy={sortBy}
          updateSort={setSortBy}
        />
      </Container>
    </div>
  );
};

Index.propTypes = {
  history: PropTypes.object.isRequired,
  loggedInUser: PropTypes.object,
  riskControls: PropTypes.array.isRequired,
};

Index.defaultProps = {
  loggedInUser: null,
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
  riskControls: getRiskControls(state),
});

export default connect(mapStateToProps)(Index);
