import { normalize } from "normalizr";
import schemas from "../schemas";
import riskControlsApi from "../services/api/riskControls";
import { asyncAction } from "./actions";

export const RISK_CONTROL_LOAD = "RISK-CONTROL-LOAD";
export const RISK_CONTROL_REMOVE = "RISK-CONTROL-REMOVE";
export const RISK_CONTROL_LIST = "RISK-CONTROL-LIST";
export const RISK_CONTROL_CREATE = "RISK-CONTROL-CREATE";
export const RISK_CONTROL_CLEAR_ACTIVE = "RISK-CONTROL-CLEAR-ACTIVE";
export const RISK_CONTROL_PATCH = "RISK-CONTROL-PATCH";
export const RISK_CONTROL_LOAD_METRIC = "RISK-CONTROL-LOAD-METRIC";
export const RISK_CONTROL_LOAD_ASSOCIATED_RISKS =
  "RISK-CONTROL-LOAD-ASSOCIATED_RISKS";
export const RISK_CONTROL_ADD_ASSOCIATED_RISKS =
  "RISK-CONTROL-ADD-ASSOCIATED_RISKS";

export const listRiskControls = (filter, accessToken) => {
  const abortController = window.AbortController ? new AbortController() : null;
  return asyncAction(
    RISK_CONTROL_LIST,
    riskControlsApi.search(filter, abortController, accessToken),
    {
      responseTransformer: (response) => ({
        pagination: {
          offset: response.offset,
          previousOffset: response.previousOffset,
          nextOffset: response.nextOffset,
          pageSize: response.pageSize,
          resultCount: response.resultCount,
        },
        ...normalize(response.results, schemas.arrayOfRiskControls),
      }),
      actionParams: {
        abortController,
        nonBlocking: true,
      },
    }
  );
};

export const createRiskControl = (riskControl, accessToken) =>
  asyncAction(
    RISK_CONTROL_CREATE,
    riskControlsApi.create(riskControl, accessToken),
    {
      responseTransformer: (response) =>
        normalize(response, schemas.riskControl),
    }
  );

export const loadRiskControl = (riskControlId, accessToken) =>
  asyncAction(
    RISK_CONTROL_LOAD,
    riskControlsApi.load(riskControlId, accessToken),
    {
      responseTransformer: (response) =>
        normalize(response, schemas.riskControl),
    }
  );

export function clearActiveRiskControl() {
  return {
    type: RISK_CONTROL_CLEAR_ACTIVE,
  };
}

export const deleteRiskControl = (riskControlId, accessToken) =>
  asyncAction(
    RISK_CONTROL_REMOVE,
    riskControlsApi.remove(riskControlId, accessToken),
    {
      responseTransformer: () => ({ id: riskControlId }),
    }
  );

export const patchRiskControl = (
  riskControlId,
  changes,
  description,
  accessToken
) =>
  asyncAction(
    RISK_CONTROL_PATCH,
    riskControlsApi.patch(riskControlId, changes, description, accessToken),
    {
      responseTransformer: (response) =>
        normalize(response, schemas.riskControl),
    }
  );

export const loadRiskControlMetric = (metric, filter, accessToken) =>
  asyncAction(
    RISK_CONTROL_LOAD_METRIC,
    riskControlsApi.count(filter, accessToken),
    {
      responseTransformer: (results) => ({
        count: results.count,
        metric,
      }),
    }
  );

export const loadAssociatedRisks = (riskControlId, accessToken) =>
  asyncAction(
    RISK_CONTROL_LOAD_ASSOCIATED_RISKS,
    riskControlsApi.getAssociatedRisks(riskControlId, accessToken)
  );

export const addAssociatedRisks = (riskControlId, riskIds, accessToken) =>
  asyncAction(
    RISK_CONTROL_ADD_ASSOCIATED_RISKS,
    riskControlsApi.addAssociatedRisks(riskControlId, riskIds, accessToken)
  );
