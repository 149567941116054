import { withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Menu from "@material-ui/core/Menu";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PropTypes from "prop-types";
import React, { Component } from "react";

const styles = (theme) => ({
  listItemSecondaryAction: {
    visibility: "hidden",
  },
  listItemContainer: {
    borderTop: `solid 1px ${theme.palette.divider}`,
    "&:hover $listItemSecondaryAction": {
      visibility: "inherit",
    },
    "&:hover, &:focus-within": {
      cursor: "pointer",
    },
  },
  listItemGutters: {
    borderLeft: "solid 4px transparent",
    "&:hover": {
      borderLeft: `solid 4px ${theme.palette.divider}`,
    },
  },
  checkbox: {
    padding: 0,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
});

class ExpandableListItem extends Component {
  constructor(props) {
    super(props);
    this.handleExpand = this.handleExpand.bind(this);
    this.onOpenMenu = this.onOpenMenu.bind(this);
    this.onCloseMenu = this.onCloseMenu.bind(this);
    this.selectOption = this.selectOption.bind(this);
    this.state = {
      open: false,
      anchorEl: null,
    };
  }

  onOpenMenu(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  onCloseMenu() {
    this.setState({ anchorEl: null });
  }

  handleExpand() {
    const isOpen = !this.state.open;
    this.setState({ open: isOpen });
    if (this.props.onExpand && isOpen) {
      this.props.onExpand();
    } else if (this.props.onCollapse && !isOpen) {
      this.props.onCollapse();
    }
  }

  selectOption(options, optionId) {
    options.forEach((option) => {
      if (option.id === optionId && option.handleSelect) {
        option.handleSelect();
      }
    });

    this.setState({ anchorEl: null });
  }

  render() {
    const {
      classes,
      icon,
      primary,
      secondary,
      onClick,
      children,
      renderMenuItems,
      expanderLabelOpen,
      expanderLabelClosed,
    } = this.props;

    const { open, anchorEl } = this.state;

    const useExpanderLabels = expanderLabelClosed && expanderLabelOpen;

    const menuItems = renderMenuItems
      ? renderMenuItems(this.onCloseMenu)
      : null;

    return (
      <>
        {open && <Divider />}
        <ListItem
          onClick={onClick}
          classes={{
            container: classes.listItemContainer,
            gutters: classes.listItemGutters,
          }}
        >
          {icon && <span className={classes.icon}>{icon}</span>}
          <ListItemText
            primary={primary}
            secondary={secondary}
            secondaryTypographyProps={{ component: "div" }}
          />
          <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
            {children && useExpanderLabels && (
              <Button onClick={this.handleExpand} color="primary">
                {this.state.open ? expanderLabelOpen : expanderLabelClosed}
              </Button>
            )}
            {children && !useExpanderLabels && (
              <IconButton
                aria-label="Comments"
                onClick={this.handleExpand}
                data-cy="comments"
              >
                {this.state.open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            )}

            {menuItems && (
              <>
                <IconButton
                  aria-label="More"
                  aria-owns={anchorEl ? "simple-menu" : null}
                  aria-haspopup="true"
                  disableRipple
                  onClick={this.onOpenMenu}
                  data-cy="more"
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  data-cy={menuItems}
                  id="simple-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={this.onCloseMenu}
                  anchorOrigin={{
                    horizontal: "right",
                    vertical: "top",
                  }}
                  transformOrigin={{
                    horizontal: "right",
                    vertical: "top",
                  }}
                >
                  {menuItems}
                </Menu>
              </>
            )}
          </ListItemSecondaryAction>
        </ListItem>
        {children && (
          <Collapse in={this.state.open} timeout="auto" unmountOnExit>
            {children}
          </Collapse>
        )}
        {open && <Divider />}
      </>
    );
  }
}

ExpandableListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.element,
  primary: PropTypes.node,
  secondary: PropTypes.node,
  onClick: PropTypes.func,
  onExpand: PropTypes.func,
  onCollapse: PropTypes.func,
  children: PropTypes.element,
  renderMenuItems: PropTypes.func, // render function: (onCloseMenu func) => Menu's children
  expanderLabelOpen: PropTypes.string,
  expanderLabelClosed: PropTypes.string,
};

ExpandableListItem.defaultProps = {
  icon: null,
  primary: null,
  secondary: null,
  onClick: null,
  onExpand: null,
  onCollapse: null,
  children: null,
  renderMenuItems: null,
  expanderLabelOpen: null,
  expanderLabelClosed: null,
};

export default withStyles(styles)(ExpandableListItem);
