import { useAuth0 } from "@auth0/auth0-react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import Container from "../../../components/common/Container";
import HeaderBar from "../../../components/common/HeaderBar";
import PageHeading from "../../../components/common/PageHeading";
import FormWrapper from "../../../components/forms/FormWrapper";
import analyticsApi from "../../../services/api/analytics";
import { uploadIcon } from "../../../util/icons";
import UploadViewsForm, {
  UPLOAD_VIEWS_FORM_NAME,
} from "../../../components/forms/analytics/UploadViewsForm";
import BlockingLoadingIndicator from "../../../components/common/BlockingLoadingIndicator";
import { asyncPoll } from "../../../util/asyncRequestHelper";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  contents: {
    marginBottom: "75px",
  },
  card: {
    marginTop: theme.spacing(2),
  },
});

const Index = ({ classes }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    const accessToken = await getAccessTokenSilently();
    setLoading(true);

    return asyncPoll(
      () => setLoading(false),
      analyticsApi.createViewFileTest(values, accessToken),
      (viewFileTest) =>
        analyticsApi.getViewFileTest(viewFileTest.id, accessToken),
      (viewFileTest) => {
        if (
          viewFileTest.success !== undefined &&
          viewFileTest.success !== null
        ) {
          setResult(viewFileTest);
          return true;
        }
        return false;
      },
      10000
    );
  };

  return (
    <div className={classes.root}>
      <HeaderBar>
        <PageHeading icon={uploadIcon()} heading="Analytics Admin" />
      </HeaderBar>
      <BlockingLoadingIndicator loading={loading} />
      <Container className={classes.contents}>
        <Card className={classes.card}>
          <CardHeader title="View testing tool" />
          <CardContent>
            {!result && (
              <FormWrapper
                submitButtonText="Upload"
                formComponent={UploadViewsForm}
                formName={UPLOAD_VIEWS_FORM_NAME}
                onSubmit={onSubmit}
                isAltPaper
              />
            )}
            {result && (
              <>
                {result.success && (
                  <Typography variant="h6" gutterBottom>
                    Submitted file successfully
                  </Typography>
                )}
                {!result.success && (
                  <>
                    <Typography variant="h6" gutterBottom>
                      Validation error
                    </Typography>
                    <Typography variant="h4" gutterBottom>
                      {result.error || "-"}
                    </Typography>
                  </>
                )}
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => setResult(null)}
                >
                  Try again
                </Button>
              </>
            )}
          </CardContent>
        </Card>
      </Container>
    </div>
  );
};

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = () => ({});

export default compose(withStyles(styles), connect(mapStateToProps))(Index);
