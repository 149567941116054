import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { getReferenceDataType } from "../../reducers";
import ChoiceChips from "../common/ChoiceChips";
import FiltersBar from "../common/FiltersBar";
import RefMultiSelect from "../common/RefMultiSelect";

const useStyles = makeStyles((theme) => ({
  selectWidgets: {
    marginTop: theme.spacing(1),
  },
  clearButton: {
    padding: theme.spacing(0.5),
    position: "absolute",
    right: 0,
    top: `-${theme.spacing(2)}px`,
  },
  label: {
    lineHeight: "1.5rem",
  },
}));

const WebsiteDifferenceFilters = ({
  filter,
  updateFilter,
  websiteDifferenceStates,
}) => {
  const classes = useStyles();

  const differenceOptions = [
    { label: "Show only detected differences", value: true },
  ];

  return (
    <FiltersBar>
      <>
        <InputLabel className={classes.label}>Status</InputLabel>
        <div>
          <RefMultiSelect
            title="Select status"
            value={filter.statuses}
            options={websiteDifferenceStates}
            onChange={(value) => updateFilter("statuses", value)}
          />
        </div>
      </>
      <>
        <InputLabel className={classes.label}>Difference</InputLabel>
        <ChoiceChips
          value={filter.different}
          options={differenceOptions}
          onChange={(value) => updateFilter("different", value)}
        />
      </>
    </FiltersBar>
  );
};

WebsiteDifferenceFilters.propTypes = {
  filter: PropTypes.object.isRequired,
  updateFilter: PropTypes.func.isRequired,
  websiteDifferenceStates: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  websiteDifferenceStates: getReferenceDataType(
    state,
    "WebsiteDifferenceStatus"
  ),
});

export default connect(mapStateToProps)(WebsiteDifferenceFilters);
