import { combineReducers } from "redux";
import {
  MEDIA_CREATE,
  MEDIA_GET_BY_ID,
  MEDIA_LIST,
  MEDIA_UPDATE,
  MEDIA_DELETE_BY_ID,
  MEDIA_CLEAR_ACTIVE,
  MEDIA_ADD_DOCUMENT,
} from "../actions/media";
import { publishActionToast } from "../services/toasts";

const successToasts = {
  [MEDIA_CREATE]: "Created media successfully",
  [MEDIA_UPDATE]: "Updated media successfully",
  [MEDIA_DELETE_BY_ID]: "Media deleted successfully",
};

const byId = (state = {}, action) => {
  publishActionToast(action, "PRODUCT_", successToasts);

  switch (action.type) {
    case `${MEDIA_LIST}_SUCCESS`:
    case `${MEDIA_GET_BY_ID}_SUCCESS`:
    case `${MEDIA_CREATE}_SUCCESS`:
    case `${MEDIA_UPDATE}_SUCCESS`:
    case `${MEDIA_ADD_DOCUMENT}_SUCCESS`:
      return {
        ...state,
        ...action.response.entities.media,
      };

    default:
      break;
  }

  return state;
};
const defaultActive = {
  dashboard: [],
  dashboardLastUpdate: 0,
  activeMedia: null,
  lastEdit: 0,
  error: null,
  pagination: {
    offset: 0,
    pageSize: 50,
    resultCount: 0,
  },
};

const active = (state = defaultActive, action) => {
  switch (action.type) {
    case `${MEDIA_LIST}_SUCCESS`:
      return {
        ...state,
        dashboard: [...action.response.result],
        dashboardLastUpdate: new Date().getTime(),
        pagination: {
          ...action.response.pagination,
          pageSize: defaultActive.pagination.pageSize,
        },
      };

    case `${MEDIA_GET_BY_ID}_SUCCESS`:
      return {
        ...state,
        activeMedia: action.response.result,
      };

    case `${MEDIA_CREATE}_SUCCESS`:
    case `${MEDIA_UPDATE}_SUCCESS`:
    case `${MEDIA_ADD_DOCUMENT}_SUCCESS`:
      return {
        ...state,
        activeMedia: action.response.result,
        lastEdit: new Date().getTime(),
      };

    case MEDIA_CLEAR_ACTIVE:
      return {
        ...defaultActive,
      };

    default:
      break;
  }

  return state;
};

export default combineReducers({
  active,
  byId,
});

export const getActiveMedia = (state) =>
  state.active.activeMedia ? state.byId[state.active.activeMedia] : null;
export const getAll = (state) =>
  state.active.dashboard.map((id) => state.byId[id]);
export const getPagination = (state) => state.active.pagination;
export const getLastEdit = (state) => state.active.lastEdit;
