import { withStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";
import classNames from "classnames";
import * as PropTypes from "prop-types";
import React from "react";
import ArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { DatePicker } from "@material-ui/pickers";
import dates from "../../../util/dates";

const styles = (theme) => ({
  highlight: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  firstHighlight: {
    extend: "highlight",
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  },
  endHighlight: {
    extend: "highlight",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: "0 2px",
    color: "inherit",
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled,
  },
  highlightNonCurrentMonthDay: {
    color: "#676767",
  },
});

const WrappedWeekPicker = ({
  classes,
  input,
  storeDatesAsString,
  helperText,
  margin,
  meta: { touched, error, invalid },
  ...custom
}) => {
  const rest = { ...custom };

  const toStartOfWeek = (date) => (date ? moment(date).startOf("week") : date);

  const formatWeekSelectLabel = (date, invalidLabel) => {
    const dateClone = date && moment(date).startOf("week");

    return dateClone
      ? `Week of ${dateClone.format("MMM Do YYYY")}`
      : invalidLabel;
  };

  const renderWrappedWeekDay = (date, selectedDate, dayInCurrentMonth) => {
    const dateClone = moment(date);
    const selectedDateClone = moment(selectedDate);

    const start = moment(selectedDateClone).startOf("week");
    const end = moment(selectedDateClone).endOf("week");

    const dayIsBetween = dateClone.isBetween(start, end, "day", "[]");
    const isFirstDay = dateClone.isSame(start, "day");
    const isLastDay = dateClone.isSame(end, "day");

    const wrapperClassName = classNames({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay,
    });

    const dayClassName = classNames({
      [classes.day]: true,
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
    });

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span> {dateClone.format("D")} </span>
        </IconButton>
      </div>
    );
  };

  if (touched && error) {
    rest.error = true;
    rest.helperText = error;
  } else if ((!touched || !invalid) && helperText) {
    rest.helperText = helperText;
  }

  return (
    <DatePicker
      name={`position-${input.name}`}
      value={
        storeDatesAsString
          ? toStartOfWeek(dates.parseDate(input.value)) || input.value
          : toStartOfWeek(input.value)
      }
      leftArrowIcon={<ArrowLeftIcon />}
      rightArrowIcon={<ArrowRightIcon />}
      autoOk
      clearable
      onChange={(date) =>
        input.onChange(
          storeDatesAsString
            ? dates.serializeDate(toStartOfWeek(date))
            : toStartOfWeek(date)
        )
      }
      onFocus={input.onFocus}
      margin={margin}
      labelFunc={formatWeekSelectLabel}
      renderDay={renderWrappedWeekDay}
      {...rest}
    />
  );
};

WrappedWeekPicker.propTypes = {
  classes: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  helperText: PropTypes.any,
  storeDatesAsString: PropTypes.bool,
  meta: PropTypes.object.isRequired,
  margin: PropTypes.string,
};

WrappedWeekPicker.defaultProps = {
  helperText: "",
  storeDatesAsString: true,
  margin: "dense",
};

export default withStyles(styles)(WrappedWeekPicker);
