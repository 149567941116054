import _ from "lodash";
import TextField from "@material-ui/core/TextField";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import ListPicker from "../../ListPicker";

const WrappedListPicker = ({
  input: { onChange, onBlur, value, name },
  label,
  datasource,
  isMulti,
  selectAll,
  clearable,
  renderIcon,
  submitOnChange,
  margin,
  helperText,
  disabled,
  toOption,
  fromOption,
  meta: { touched, error, invalid },
  listPickerProps,
  ...custom
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (value === "") {
      // ignore
    } else if (value === null || value === undefined) {
      onBlur(undefined);
    } else {
      onBlur(value);
    }
  }, [value]);

  const onSelect = (values) => {
    setDialogOpen(false);
    onChange(values);
  };

  const getSelection = (values) => {
    if (_.isArray(values)) {
      return values;
    }
    if (values === null || values === undefined || values === "") {
      return null;
    }
    return values;
  };

  const getLabel = (values) => {
    if (_.isArray(values)) {
      return values
        .map((v) => toOption(v))
        .map((option) => option.label)
        .join(", ");
    }
    if (values === null || values === undefined || values === "") {
      return "";
    }
    return toOption(values).label;
  };

  return (
    <>
      <TextField
        InputLabelProps={{ name: `position-${name}` }}
        label={label}
        data-cy={label}
        error={touched && invalid}
        helperText={(touched && error) || helperText}
        readOnly
        onClick={disabled ? null : () => setDialogOpen(true)}
        value={getLabel(value)}
        margin={margin}
        disabled={disabled}
        inputProps={{
          onKeyPress: () => setDialogOpen(true),
        }}
        {...custom}
      />
      <ListPicker
        data-cy={label}
        title={label}
        actionText="Select"
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onSubmit={(values) => onSelect(values)}
        datasource={datasource}
        selected={getSelection(value)}
        isMulti={isMulti}
        selectAll={selectAll}
        clearable={clearable}
        renderIcon={renderIcon}
        submitOnChange={submitOnChange}
        toOption={toOption}
        fromOption={fromOption}
        {...listPickerProps}
      />
    </>
  );
};

WrappedListPicker.propTypes = {
  input: PropTypes.object.isRequired,
  datasource: PropTypes.oneOfType([PropTypes.func, PropTypes.array]).isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  isMulti: PropTypes.bool,
  renderIcon: PropTypes.func.isRequired,
  submitOnChange: PropTypes.bool,
  margin: PropTypes.string,
  helperText: PropTypes.any,
  disabled: PropTypes.bool,
  selectAll: PropTypes.bool,
  clearable: PropTypes.bool,
  toOption: PropTypes.func.isRequired,
  fromOption: PropTypes.func.isRequired,
  listPickerProps: PropTypes.object,
};

WrappedListPicker.defaultProps = {
  submitOnChange: false,
  margin: "dense",
  helperText: "",
  disabled: false,
  isMulti: true,
  selectAll: false,
  clearable: false,
  listPickerProps: {},
};

export default WrappedListPicker;
