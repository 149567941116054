import { compose } from "redux";
import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { getReferenceDataType } from "../../../../reducers";
import { getResolvedDefinition } from "../../../../util/workItemTypeUtils";

const styles = (theme) => ({
  box: {
    marginTop: theme.spacing(1),
  },
  text: {
    color: theme.palette.swatch.secondary,
  },
});

const isCreateEntry = (entry) => entry.type === "CREATE";

const useCreate = (entry, workItem, types) => {
  const [isCreate, setIsCreate] = useState(false);
  const [content, setContent] = useState("");
  const definition = getResolvedDefinition(
    types,
    workItem.type,
    workItem.parent?.type
  );

  useEffect(() => {
    if (isCreateEntry(entry)) {
      const { name: typeDisplay } = definition;

      setIsCreate(true);
      setContent(`Submitted ${typeDisplay}`);
    }
  }, [entry, workItem]);

  return [isCreate, content];
};

const Create = ({ classes, entry, types, workItem }) => {
  const [isCreate, content] = useCreate(entry, workItem, types);

  return (
    <>
      {isCreate && (
        <Typography variant="body2" className={classes.text}>
          {content}
        </Typography>
      )}
    </>
  );
};

Create.propTypes = {
  classes: PropTypes.object.isRequired,
  entry: PropTypes.object.isRequired,
  workItem: PropTypes.object.isRequired,
  types: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  types: getReferenceDataType(state, "WorkItemType"),
});

export default compose(withStyles(styles), connect(mapStateToProps))(Create);
