import { withStyles } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import * as PropTypes from "prop-types";
import React from "react";

const styles = () => ({
  label: {
    position: "relative",
  },
  root: {
    maxWidth: "100%",
  },
});

const WrappedErrorDisplay = ({
  classes,
  displayAlways,
  renderError,
  input,
  meta: { touched, error },
  margin,
  fullWidth,
}) => (
  <FormControl
    className={classes.root}
    fullWidth={fullWidth}
    margin={margin}
    name={`position-${input.name}`}
  >
    {(touched || displayAlways) && error && renderError(error)}
  </FormControl>
);

WrappedErrorDisplay.propTypes = {
  classes: PropTypes.object.isRequired,
  displayAlways: PropTypes.bool,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  margin: PropTypes.string,
  renderError: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
};

WrappedErrorDisplay.defaultProps = {
  margin: "dense",
  displayAlways: false,
  fullWidth: true,
};

export default withStyles(styles)(WrappedErrorDisplay);
