import React from "react";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import dates from "../../util/dates";
import MultilineText from "../common/MultilineText";
import TimeAgo from "../common/TimeAgo";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

const AuditHistory = (props) => {
  const auditHistory = _.orderBy(props.auditHistory, ["created"], ["desc"]);

  return (
    <Card elevation={0}>
      <CardHeader title="Audit history" data-cy="auditHistory" />
      <List dense data-cy="tabContent">
        {auditHistory.map((entry) => (
          <ListItem
            key={`${entry.created}_${entry.type}_${entry.description}`}
            divider
            data-cy="tabContent"
          >
            <ListItemText
              secondary={
                <MultilineText
                  text={entry.description}
                  data-cy={entry.description}
                />
              }
              primary={
                <>
                  <strong data-cy={entry.user}>{entry.user} </strong>
                  <TimeAgo
                    value={dates.parseTimestamp(entry.created)}
                    expandable
                    data-cy="timeCreated"
                  />
                </>
              }
            />
          </ListItem>
        ))}
      </List>
    </Card>
  );
};

AuditHistory.propTypes = {
  auditHistory: PropTypes.array.isRequired,
};

export default withStyles(styles)(AuditHistory);
