import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { SubmissionError } from "redux-form";
import { createDigitalAsset } from "../../../actions/digitalAssets";
import ActionButton from "../../../components/common/ActionButton";
import ActionHeading from "../../../components/common/ActionHeading";
import Container from "../../../components/common/Container";
import FloatingActionButton from "../../../components/common/FloatingActionButton";
import HeaderBar from "../../../components/common/HeaderBar";
import PageHeading from "../../../components/common/PageHeading";
import ExpandableForm from "../../../components/forms/ExpandableForm";
import DigitalAssetForm, {
  DIGITAL_ASSET_FORM_NAME,
} from "../../../components/forms/sentinel/DigitalAssetForm";
import DigitalAssetListing from "../../../components/sentinel/DigitalAssetListing";
import DigitalAssetFilter from "../../../components/sentinel/DigitalAssetFilter";
import { getLoggedInUser } from "../../../reducers";

import { clearIcon, digitalAssetIcon } from "../../../util/icons";
import usePersistedState from "../../../util/persistedState";
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  contents: {
    marginBottom: "75px",
  },
}));

const defaultFilter = {
  organisations: [],
};

const Index = ({ history, loggedInUser, localCreateDigitalAsset }) => {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const [sortBy, setSortBy] = usePersistedState(
    `digital-assets-listing-sort-${loggedInUser.email}`,
    {
      field: "orgFriendlyId",
      direction: "asc",
    }
  );
  const [filter, setFilter] = usePersistedState(
    `digital-assets-filter-${loggedInUser.email}`,
    defaultFilter,
    ["textSearch"]
  );

  const [dialogOpen, setDialogOpen] = useState(false);
  const formRef = useRef();

  const clearFilter = () => setFilter(defaultFilter);

  const updateFilter = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  const handleSubmitDigitalAssetDialog = async (values) => {
    const accessToken = await getAccessTokenSilently();
    localCreateDigitalAsset(values, accessToken)
      .then(() => {
        setDialogOpen(false);
      })
      .catch((error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  const openForm = () => {
    setDialogOpen(true);
    window.scrollTo(0, formRef.current.offsetTop);
  };

  return (
    <div className={classes.root}>
      <HeaderBar>
        <ActionHeading
          heading={
            <PageHeading icon={digitalAssetIcon()} heading="Digital Assets" />
          }
          actions={[
            <ActionButton
              key="clearFilter"
              tooltip="Clear filters"
              icon={clearIcon()}
              onClick={clearFilter}
              data-cy="clear-filters-button"
            />,
          ]}
        />
      </HeaderBar>

      <Container className={classes.contents}>
        <ExpandableForm
          title="Create new digital asset"
          submitButtonText="Create"
          formComponent={DigitalAssetForm}
          formName={DIGITAL_ASSET_FORM_NAME}
          open={dialogOpen}
          formRef={formRef}
          onCancel={() => setDialogOpen(false)}
          onSubmit={handleSubmitDigitalAssetDialog}
          isAltPaper
        />
        {!dialogOpen && <FloatingActionButton onClick={openForm} />}
        <DigitalAssetFilter
          filter={filter}
          updateFilter={updateFilter}
          fullWidth={false}
        />
        <DigitalAssetListing
          filter={filter}
          clearFilter={clearFilter}
          sortBy={sortBy}
          updateSort={setSortBy}
          history={history}
        />
      </Container>
    </div>
  );
};

Index.propTypes = {
  history: PropTypes.object.isRequired,
  loggedInUser: PropTypes.object,
  localCreateDigitalAsset: PropTypes.func.isRequired,
};

Index.defaultProps = {
  loggedInUser: null,
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
});

export default connect(mapStateToProps, {
  localCreateDigitalAsset: createDigitalAsset,
})(Index);
