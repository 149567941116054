import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { getLoggedInUser } from "../../../../reducers";
import { yesNoOptions } from "../../../../util/radioOptions";
import ComponentField from "../../../forms/wrapper/ComponentField";
import WrappedRadioGroup from "../../../forms/wrapper/WrappedRadioGroup";

const ChargeabilityFields = ({
  fieldLabels,
  loggedInUser,
  customisations,
  change,
  untouch,
  formName,
}) => {
  return (
    <>
      {!loggedInUser.externalUser && (
        <ComponentField
          name="chargeable"
          component={WrappedRadioGroup}
          label={fieldLabels.labels.chargeable}
          options={yesNoOptions}
          row
          customisations={customisations}
          change={change}
          untouch={untouch}
          formName={formName}
        />
      )}
    </>
  );
};

ChargeabilityFields.propTypes = {
  loggedInUser: PropTypes.object,
  customisations: PropTypes.array,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  fieldLabels: PropTypes.object.isRequired,
};

ChargeabilityFields.defaultProps = {
  loggedInUser: null,
  customisations: [],
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
});

export default connect(mapStateToProps)(ChargeabilityFields);
