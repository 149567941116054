import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardDatePicker } from "@material-ui/pickers";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import dates from "../../util/dates";
import { closeIcon, doneIcon, editIcon } from "../../util/icons";

const useStyles = makeStyles((theme) => ({
  button: {
    padding: "3px",
  },
  gridItem: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "auto",
      "&:last-child": {
        marginLeft: "auto",
      },
    },
  },
}));

const InlineDatePicker = ({
  startInEditMode,
  value,
  onCancel,
  onChange,
  validate,
  readonly,
  storeDatesAsString,
  readOnlyView,
  ...rest
}) => {
  const classes = useStyles();
  const [editMode, setEditMode] = useState(startInEditMode);
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    if (value !== currentValue) {
      setCurrentValue(value);
    }
  }, [value]);

  const handleCancel = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setEditMode(false);
    setCurrentValue(value);
    if (onCancel) {
      onCancel();
    }
  };

  const onSave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (currentValue !== value) {
      onChange(currentValue)
        .catch(() => {
          setCurrentValue(value);
        })
        .finally(() => {
          setEditMode(false);
        });
    } else {
      setCurrentValue(value);
      setEditMode(false);
    }
  };

  const toEditMode = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setEditMode(true);
  };

  const EditIcon = editIcon();
  const DoneIcon = doneIcon();
  const CloseIcon = closeIcon();

  const valid = !validate || validate(currentValue);

  return (
    <div>
      {!editMode && (
        <Grid container alignContent="flex-start" alignItems="center">
          <Grid item className={classes.gridItem}>
            {readOnlyView}
          </Grid>
          <Grid item className={classes.gridItem}>
            {!readonly && (
              <IconButton
                classes={{
                  root: classes.button,
                }}
                onClick={toEditMode}
                title="Edit"
                data-cy="edit"
              >
                <EditIcon fontSize="small" />
              </IconButton>
            )}
          </Grid>
        </Grid>
      )}
      {editMode && (
        <KeyboardDatePicker
          error={!valid}
          value={
            storeDatesAsString
              ? dates.parseDate(currentValue) || null
              : currentValue
          }
          format="DD/MM/YYYY"
          placeholder="dd/mm/yyyy"
          data-cy="dd/mm/yyyy"
          autoOk
          clearable
          onChange={(date) =>
            setCurrentValue(
              storeDatesAsString ? dates.serializeDate(date) : date
            )
          }
          margin="none"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  classes={{
                    root: classes.button,
                  }}
                  onClick={handleCancel}
                  title="Cancel"
                  data-cy="cancel"
                >
                  <CloseIcon fontSize="small" data-cy="close" />
                </IconButton>
                <IconButton
                  classes={{
                    root: classes.button,
                  }}
                  onClick={onSave}
                  title="Save"
                  disabled={!valid}
                  data-cy="save"
                >
                  <DoneIcon fontSize="small" data-cy="done" />
                </IconButton>
              </InputAdornment>
            ),
          }}
          InputAdornmentProps={{
            position: "start",
          }}
          KeyboardButtonProps={{
            fontSize: "small",
            className: classes.button,
          }}
          {...rest}
        />
      )}
    </div>
  );
};

InlineDatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  startInEditMode: PropTypes.bool,
  validate: PropTypes.func,
  readonly: PropTypes.bool,
  value: PropTypes.any,
  storeDatesAsString: PropTypes.bool.isRequired,
  readOnlyView: PropTypes.any.isRequired,
};

InlineDatePicker.defaultProps = {
  startInEditMode: false,
  onCancel: null,
  validate: null,
  readonly: false,
  value: undefined,
};

export default InlineDatePicker;
