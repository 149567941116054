import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { roles } from "@certane/arcadia-web-components";
import { deleteIcon, favoriteIcon, favoriteOptionIcon } from "../../util/icons";
import AlertDialog from "../common/AlertDialog";

const useStyles = makeStyles((theme) => ({
  main: {
    minWidth: "300px",
  },
  titleCard: {
    width: "100%",
    background: theme.palette.secondary.dark,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    margin: theme.spacing(2),
    color: theme.palette.common.white,
  },
}));

const useItemStyles = makeStyles(() => ({
  active: {
    fontWeight: 700,
  },
  listItem: {
    "&:hover div button": {
      visibility: "visible",
    },
  },
  searchButton: {
    padding: "5px",
    marginLeft: "10px",
    width: "30px",
    height: "30px",
  },
  hidden: {
    visibility: "hidden",
    transition: "0.1s ease",
  },
}));

const SavedSearchItem = ({
  savedSearch,
  isActive,
  isFavourite,
  onSelect,
  setSavedSearchToDelete,
  deleteFavourite,
  addFavourite,
  readonly,
}) => {
  const classes = useItemStyles();

  const onDeleteSavedSearch = (savedSearch, event) => {
    event.stopPropagation();
    event.preventDefault();
    setSavedSearchToDelete(savedSearch);
  };

  const togglePin = async (id, event) => {
    event.stopPropagation();
    event.preventDefault();
    if (isFavourite) {
      deleteFavourite(id);
    } else {
      addFavourite(id);
    }
  };

  const FavoriteIcon = favoriteIcon();
  const FavoriteOptionIcon = favoriteOptionIcon();
  const DeleteIcon = deleteIcon();

  return (
    <ListItem
      button
      onClick={() => onSelect(savedSearch)}
      className={classes.listItem}
    >
      <ListItemText
        primary={
          <span
            className={isActive ? classes.active : undefined}
            data-cy={savedSearch.name}
          >
            {savedSearch.name}
          </span>
        }
      />
      <ListItemIcon>
        <Grid container className={classes.hidden} justifyContent="flex-end">
          {!readonly && !isActive && (
            <Grid item>
              <IconButton
                classes={{
                  root: classes.searchButton,
                }}
                title="Delete"
                onClick={(event) => onDeleteSavedSearch(savedSearch, event)}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          )}
          <Grid item>
            <IconButton
              classes={{
                root: classes.searchButton,
              }}
              title={isFavourite ? "Remove favourite" : "Add favourite"}
              onClick={(event) => togglePin(savedSearch.id, event)}
              data-cy="favourite"
            >
              {isFavourite ? <FavoriteIcon /> : <FavoriteOptionIcon />}
            </IconButton>
          </Grid>
        </Grid>
      </ListItemIcon>
    </ListItem>
  );
};

SavedSearchItem.propTypes = {
  savedSearch: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  isFavourite: PropTypes.bool.isRequired,
  setSavedSearchToDelete: PropTypes.func.isRequired,
  deleteFavourite: PropTypes.func.isRequired,
  addFavourite: PropTypes.func.isRequired,
  readonly: PropTypes.bool.isRequired,
};

const SavedSearchDrawer = ({
  loggedInUser,
  anchor,
  open,
  onClose,
  onSelect,
  onDelete,
  savedSearches,
  activeSearch,
  searchFavourites,
  addFavourite,
  deleteFavourite,
}) => {
  const classes = useStyles();
  const [savedSearchToDelete, setSavedSearchToDelete] = useState(null);

  const isAdmin = loggedInUser
    ? _.intersection(roles.ADMIN_ROLES, loggedInUser.roles).length > 0
    : false;

  const onDeleteSubmit = async () => {
    await onDelete(savedSearchToDelete.id);
    setSavedSearchToDelete(null);
    onClose();
  };

  const isActive = (savedSearch) =>
    !!activeSearch && activeSearch.id === savedSearch.id;

  const isFavourite = (search) => searchFavourites.indexOf(search.id) !== -1;

  const isReadOnly = (search) => {
    if (search.global) {
      return !isAdmin;
    }
    return search.owner.id !== loggedInUser.id;
  };

  const sortedSearches = _.orderBy(savedSearches, "name");
  const favouriteSearches = sortedSearches.filter(isFavourite);
  const globalSearches = sortedSearches.filter((s) => s.global);
  const personalSearches = sortedSearches.filter(
    (s) => !s.global && s.owner.id === loggedInUser.id
  );

  return (
    <Drawer anchor={anchor} open={open} onClose={onClose}>
      <AlertDialog
        title="Remove view?"
        body={`Are you sure you want to remove '${
          savedSearchToDelete && savedSearchToDelete.name
        }'?`}
        submitButtonText="Remove"
        open={!!savedSearchToDelete}
        onCancel={() => setSavedSearchToDelete(null)}
        onSubmit={onDeleteSubmit}
      />
      <div className={classes.titleCard}>
        <Typography className={classes.title} variant="h5" data-cy="view">
          Views
        </Typography>
      </div>
      <div className={classes.main}>
        <List dense>
          <ListSubheader disableSticky>Favourite views</ListSubheader>
          {favouriteSearches.length === 0 && (
            <ListItem data-cy="favourite">
              <Typography variant="caption">
                Pin a view you like to see in this list
              </Typography>
            </ListItem>
          )}
          {favouriteSearches.map((savedSearch) => (
            <SavedSearchItem
              key={savedSearch.id}
              savedSearch={savedSearch}
              isActive={isActive(savedSearch)}
              isFavourite={isFavourite(savedSearch)}
              onSelect={onSelect}
              setSavedSearchToDelete={setSavedSearchToDelete}
              deleteFavourite={deleteFavourite}
              addFavourite={addFavourite}
              readonly={isReadOnly(savedSearch)}
            />
          ))}
          <Divider />
          <ListSubheader disableSticky>System views</ListSubheader>
          {globalSearches.length === 0 && (
            <ListItem data-cy="systemViews">
              <Typography variant="caption" data-cy="Noviews">
                No system views available
              </Typography>
            </ListItem>
          )}
          {globalSearches.map((savedSearch) => (
            <SavedSearchItem
              key={savedSearch.id}
              savedSearch={savedSearch}
              isActive={isActive(savedSearch)}
              isFavourite={isFavourite(savedSearch)}
              onSelect={onSelect}
              setSavedSearchToDelete={setSavedSearchToDelete}
              deleteFavourite={deleteFavourite}
              addFavourite={addFavourite}
              readonly={isReadOnly(savedSearch)}
            />
          ))}
          <ListSubheader disableSticky>My views</ListSubheader>
          {personalSearches.length === 0 && (
            <ListItem data-cy="myViews">
              <Typography variant="caption">
                You haven&apos;t created any views yet.
              </Typography>
            </ListItem>
          )}
          {personalSearches.map((savedSearch) => (
            <SavedSearchItem
              key={savedSearch.id}
              savedSearch={savedSearch}
              isActive={isActive(savedSearch)}
              isFavourite={isFavourite(savedSearch)}
              onSelect={onSelect}
              setSavedSearchToDelete={setSavedSearchToDelete}
              deleteFavourite={deleteFavourite}
              addFavourite={addFavourite}
              readonly={isReadOnly(savedSearch)}
            />
          ))}
        </List>
      </div>
    </Drawer>
  );
};

SavedSearchDrawer.propTypes = {
  loggedInUser: PropTypes.object,
  anchor: PropTypes.oneOf(["right", "top", "bottom", "left"]).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,

  savedSearches: PropTypes.array.isRequired,
  activeSearch: PropTypes.object,

  searchFavourites: PropTypes.array.isRequired,
  addFavourite: PropTypes.func.isRequired,
  deleteFavourite: PropTypes.func.isRequired,
};

SavedSearchDrawer.defaultProps = {
  loggedInUser: null,
  activeSearch: null,
};

export default SavedSearchDrawer;
