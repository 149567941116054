import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Tooltip } from "@material-ui/core";
import _ from "lodash";
import { Star } from "@material-ui/icons";
import PropTypes from "prop-types";
import tinycolor from "tinycolor2";
import { privateIcon } from "../../../../util/icons";
import CollapseExpand from "../../../CollapseExpand";
import RichTextMentionsView from "../../../richtext/RichTextMentionsView";

const styles = (theme) => ({
  box: {
    position: "relative",
    backgroundColor: tinycolor
      .mix("white", theme.palette.swatch.accent4, 10)
      .toHexString(),
    borderColor: tinycolor(theme.palette.swatch.accent4).setAlpha(0.2),
    border: "1px solid",
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(
      1
    )}px ${theme.spacing(2)}px`,
    borderRadius: 2,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  mentions: {
    color: theme.palette.swatch.secondary,
  },
  iconWrapper: {
    color: theme.palette.swatch.accent1,
  },
  icon: {
    marginRight: theme.spacing(1),
    width: theme.spacing(2),
    verticalAlign: "middle",
  },
  header: {
    color: theme.palette.swatch.secondary,
    textTransform: "uppercase",
    fontSize: 12,
  },
  text: {
    marginTop: 10,
    color: theme.palette.swatch.primary,
    whiteSpace: "pre-wrap",
  },
  important: {
    color: theme.palette.swatch.accent1,
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
  noteIdentifier: {
    color: theme.palette.swatch.accent1,
    display: "flex",
    alignItems: "center",
  },
});

const isPrivateComment = (entry) =>
  _.has(entry, "comment") && _.get(entry.comment, "type") === "PRIVATE";

const usePrivateNote = (entry) => {
  const [isPrivate, setIsPrivate] = useState(false);
  const [isImportant, setIsImportant] = useState(false);
  const [note, setNote] = useState(null);
  const [organisations, setOrganisations] = useState([]);

  useEffect(() => {
    if (isPrivateComment(entry)) {
      setIsPrivate(true);
      setNote(entry.comment.draft);
      setIsImportant(entry.comment.important);
      setOrganisations(entry.organisations.map((org) => org.name));
    }
  }, [entry]);

  return [isPrivate, isImportant, note, organisations];
};

const PrivateNote = ({ classes, entry }) => {
  const [hasPrivateNote, isImportant, privateNote, organisations] =
    usePrivateNote(entry);

  const PrivateIcon = privateIcon();

  return (
    <>
      {hasPrivateNote && (
        <div className={classes.box}>
          <div className={classes.noteIdentifier}>
            <Tooltip title={organisations.join(<br />)}>
              <PrivateIcon className={classes.icon} color="inherit" />
            </Tooltip>
            <Typography
              variant="body2"
              className={classes.header}
              data-cy="privateNote"
            >
              Private Note
            </Typography>
          </div>
          <Typography
            component="div"
            variant="body2"
            className={classes.text}
            data-cy="text"
          >
            {privateNote && (
              <CollapseExpand isInternal isNewUI>
                <RichTextMentionsView
                  value={privateNote}
                  data-cy={privateNote}
                />
              </CollapseExpand>
            )}
          </Typography>
          {isImportant && (
            <Tooltip title="Marked as important" className={classes.important}>
              <Star color="inherit" />
            </Tooltip>
          )}
        </div>
      )}
    </>
  );
};

PrivateNote.propTypes = {
  classes: PropTypes.object.isRequired,
  entry: PropTypes.object.isRequired,
};

export default withStyles(styles)(PrivateNote);
