import { useAuth0 } from "@auth0/auth0-react";
import { roles } from "@certane/arcadia-web-components";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  deleteRiskControl,
  loadAssociatedRisks,
  loadRiskControl,
} from "../../../actions/riskControls";
import ActionButton from "../../../components/common/ActionButton";
import ActionHeading from "../../../components/common/ActionHeading";
import AlertDialog from "../../../components/common/AlertDialog";
import BreadcrumbLink from "../../../components/common/BreadcrumbLink";
import Container from "../../../components/common/Container";
import HeaderBar from "../../../components/common/HeaderBar";
import PageHeading from "../../../components/common/PageHeading";
import Error404 from "../../../components/error/Error404";
import Error410 from "../../../components/error/Error410";
import LoadingIndicator from "../../../components/LoadingIndicator";
import AssociatedRisksCard from "../../../components/risk/AssociatedRisksCard";
import RiskControlDetailsCard from "../../../components/risk/RiskControlDetailsCard";
import RiskControlVerificationCards from "../../../components/risk/RiskControlVerificationCards";
import AuditHistory from "../../../components/workitem/AuditHistory";
import {
  getActiveRiskControl,
  getLoggedInUser,
  getRiskControlAssociatedRisks,
  getRiskControlLoadError,
  isLoading,
} from "../../../reducers";
import { performExport } from "../../../util/asyncRequestHelper";
import { deleteIcon, printIcon, riskControlIcon } from "../../../util/icons";

const tabs = {
  riskControl: {
    value: "riskControl",
    label: "Risk control",
  },
  verificationHistory: {
    value: "verifications",
    label: "Control verification",
  },
  associatedRisks: {
    value: "associatedRisks",
    label: "Associated risks",
  },
  auditHistory: {
    value: "auditHistory",
    label: "Audit history",
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    marginTop: theme.spacing(2),
  },
  tabs: {
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
  },
  tabRoot: {
    minWidth: "100px",
    padding: theme.spacing(2),
  },
  gridItem: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "auto",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      "&:first-child": {
        width: "80%",
        paddingLeft: 0,
      },
      "&:last-child": {
        marginLeft: "auto",
      },
    },
  },
  actionButton: {
    display: "inline-block",
  },
}));

const orderedTabs = [
  tabs.riskControl,
  tabs.verificationHistory,
  tabs.associatedRisks,
  tabs.auditHistory,
];

const RiskControlId = ({
  match: {
    params: { riskControlId },
  },
  history,
  loggedInUser,
  loading,
  riskControl,
  loadError,
  associatedRisks,
  localLoadRiskControl,
  localLoadAssociatedRisks,
  localDeleteRiskControl,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(tabs.riskControl.value);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [downloadingFormat, setDownloadingFormat] = useState(null);

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      localLoadRiskControl(riskControlId, accessToken);
      localLoadAssociatedRisks(riskControlId, accessToken);
    })();
  }, [getAccessTokenSilently, riskControlId]);

  const handleSubmitRemoveDialog = async () => {
    const accessToken = await getAccessTokenSilently();
    localDeleteRiskControl(riskControlId, accessToken).then(() => {
      history.push("/risk/controls");
      setRemoveDialogOpen(false);
    });
  };

  const handleTabChange = (event, value) => {
    setActiveTab(value);
  };

  const onDownload = async (format) => {
    const accessToken = await getAccessTokenSilently();
    setDownloadingFormat(format);
    const searchParameters = {
      riskControlIds: [riskControlId],
      limit: 1,
      offset: 0,
    };
    performExport(
      "RiskControl",
      searchParameters,
      format,
      accessToken,
      () => setDownloadingFormat(null),
      "Risk control download",
      "Please wait while we export your risk control",
      "This window can be closed"
    );
  };

  const isActiveTab = (tab) => activeTab === tab;

  const isRiskAdmin = loggedInUser
    ? _.intersection(
        [...roles.ADMIN_ROLES, roles.RISK_ADMIN],
        loggedInUser.roles
      ).length > 0
    : false;
  const actions = [
    <ActionButton
      key="download"
      tooltip="Download"
      icon={printIcon()}
      onClick={() => onDownload("PDF")}
      loading={downloadingFormat === "PDF"}
    />,
  ];
  if (isRiskAdmin) {
    actions.push(
      <ActionButton
        tooltip="Delete"
        icon={deleteIcon()}
        onClick={() => setRemoveDialogOpen(true)}
        data-cy="delete"
      />
    );
  }

  if (!riskControl && loading) {
    return <LoadingIndicator size={60} />;
  }

  if (!riskControl && !loading) {
    if (loadError && loadError.status === 410) {
      return <Error410 message={loadError.message} />;
    }
    return <Error404 />;
  }

  return (
    <div className={classes.root}>
      <AlertDialog
        title="Remove risk control"
        body="Are you sure you want to remove this risk control?"
        submitButtonText="Remove"
        open={removeDialogOpen}
        onCancel={() => setRemoveDialogOpen(false)}
        onSubmit={(values) => handleSubmitRemoveDialog(values)}
      />
      <HeaderBar>
        <BreadcrumbLink
          to="/risk/controls"
          label="Risk controls"
          includeArrow
        />
        <ActionHeading
          heading={
            <PageHeading icon={riskControlIcon()} heading={riskControl.name} />
          }
          actions={actions}
        />
      </HeaderBar>
      <Paper elevation={0} square>
        <Container>
          <Tabs
            value={activeTab}
            textColor="primary"
            onChange={handleTabChange}
            className={classes.tabs}
            data-cy={activeTab}
          >
            {orderedTabs.map((tab) => (
              <Tab
                key={tab.value}
                value={tab.value}
                label={tab.label}
                classes={{
                  root: classes.tabRoot,
                }}
                data-cy={tab.label}
              />
            ))}
          </Tabs>
        </Container>
      </Paper>
      <Container className={classes.container}>
        {isActiveTab(tabs.riskControl.value) && <RiskControlDetailsCard />}
        {isActiveTab(tabs.verificationHistory.value) && (
          <RiskControlVerificationCards />
        )}
        {isActiveTab(tabs.associatedRisks.value) && (
          <AssociatedRisksCard
            riskControl={riskControl}
            associatedRiskRefs={associatedRisks}
          />
        )}
        {isActiveTab(tabs.auditHistory.value) && (
          <AuditHistory auditHistory={riskControl.auditHistory} />
        )}
      </Container>
    </div>
  );
};

RiskControlId.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  loggedInUser: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  localLoadRiskControl: PropTypes.func.isRequired,
  localLoadAssociatedRisks: PropTypes.func.isRequired,
  associatedRisks: PropTypes.array.isRequired,
  localDeleteRiskControl: PropTypes.func.isRequired,
  riskControl: PropTypes.object,
  loadError: PropTypes.object,
};

RiskControlId.defaultProps = {
  riskControl: null,
  loadError: null,
  loggedInUser: null,
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
  loading: isLoading(state),
  riskControl: getActiveRiskControl(state),
  loadError: getRiskControlLoadError(state),
  associatedRisks: getRiskControlAssociatedRisks(state),
});

export default connect(mapStateToProps, {
  localLoadRiskControl: loadRiskControl,
  localLoadAssociatedRisks: loadAssociatedRisks,
  localDeleteRiskControl: deleteRiskControl,
})(RiskControlId);
