import { useAuth0 } from "@auth0/auth0-react";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Chip from "@material-ui/core/Chip";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import { withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import AlarmOffIcon from "@material-ui/icons/AlarmOff";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import PhotoIcon from "@material-ui/icons/Photo";
import VisibilityIcon from "@material-ui/icons/Visibility";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  clearActiveWebsiteDifference,
  getWebsiteDifferenceById,
  patchWebsiteDifference,
  searchWebsiteDifferences,
} from "../../../actions/websiteDifferences";
import {
  clearActiveWebsiteSnapshot,
  getPreviousWebsiteSnapshotById,
  getWebsiteSnapshotById,
} from "../../../actions/websiteSnapshots";
import BreadcrumbLink from "../../../components/common/BreadcrumbLink";
import Container from "../../../components/common/Container";
import HeaderBar from "../../../components/common/HeaderBar";
import PageHeading from "../../../components/common/PageHeading";
import PageSubheading from "../../../components/common/PageSubheading";
import Scorecard from "../../../components/common/Scorecard";
import TimeAgo from "../../../components/common/TimeAgo";
import { getRefDataMenuItems } from "../../../components/forms/menuItems";
import TextDiff from "../../../components/sentinel/TextDiff";
import {
  getActivePreviousWebsiteSnapshot,
  getActiveWebsiteDifference,
  getActiveWebsiteSnapshot,
  getReferenceDataType,
  getWebsiteDifferences,
} from "../../../reducers";
import api from "../../../services/api";
import dates from "../../../util/dates";
import { websiteDifferenceIcon } from "../../../util/icons";
import useLocationStateFilter from "../../../util/locationStateFilter";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  card: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  chip: {
    marginLeft: theme.spacing(1),
  },
  container: {
    marginTop: theme.spacing(2),
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  media: {
    height: "500px",
  },
  gridItem: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "auto",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      "&:first-child": {
        paddingLeft: 0,
      },
      "&:last-child": {
        marginLeft: "auto",
      },
    },
  },
  tabs: {
    marginBottom: theme.spacing(2),
  },
  text: {
    overflow: "scroll",
    border: `solid 1px ${theme.palette.grey[300]}`,
    padding: "5px",
    fontSize: "12px",
  },
  differencesPanel: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  imageMessage: {
    textAlign: "center",
    fontStyle: "italic",
  },
});

const SNAPSHOT_TAB = "snapshots";
const EXTRACTED_TEXT_TAB = "extractedText";
const PAGE_TEXT_TAB = "pageText";
const PAGE_CONTENT_TAB = "pageContent";

const WebsiteDifferenceId = ({
  classes,
  match: {
    params: { websiteDifferenceId },
  },
  history,
  websiteDifference,
  websiteDifferences,
  snapshot,
  previousSnapshot,
  websiteDifferenceStates,
  localClearActiveWebsiteDifference,
  localClearActiveWebsiteSnapshot,
  localSearchWebsiteDifferences,
  localGetWebsiteDifferenceById,
  localGetWebsiteSnapshotById,
  localGetPreviousWebsiteSnapshotById,
  localPatchWebsiteDifference,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [activeTab, setActiveTab] = useState(SNAPSHOT_TAB);
  const [previousSnapshotText, setPreviousSnapshotText] = useState("");
  const [previousSnapshotContent, setPreviousSnapshotContent] = useState("");
  const [snapshotText, setSnapshotText] = useState("");
  const [snapshotContent, setSnapshotContent] = useState("");
  const [previousSnapshotImageUrl, setPreviousSnapshotImageUrl] =
    useState(null);
  const [currentSnapshotImageUrl, setCurrentSnapshotImageUrl] = useState(null);
  const [changeImageUrl, setChangeImageUrl] = useState(null);
  const [transition, setTransition] = useState(null);
  const [enableTransition] = useState(true);
  const [filter, setFilter] = useState({});
  useLocationStateFilter(setFilter, history);

  const goToWebsiteDifference = async (nextDifferenceId) => {
    setTransition(false);

    const accessToken = await getAccessTokenSilently();
    localGetWebsiteDifferenceById(nextDifferenceId, accessToken).then(() => {
      window.scrollTo(0, 0);
      setTransition(true);
    });
  };

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      localClearActiveWebsiteDifference(accessToken);
      localClearActiveWebsiteSnapshot(accessToken);
    })();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    goToWebsiteDifference(websiteDifferenceId);
  }, [getAccessTokenSilently, websiteDifferenceId]);

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      if (websiteDifference) {
        localGetWebsiteSnapshotById(websiteDifference.snapshotId, accessToken);
        localGetPreviousWebsiteSnapshotById(
          websiteDifference.previousSnapshotId,
          accessToken
        );
        const url = await api.websiteDifferences.getChangeUrl(
          websiteDifference.id,
          accessToken
        );
        setChangeImageUrl(url);
      }
    })();
  }, [getAccessTokenSilently, websiteDifference]);

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      if (previousSnapshot) {
        api.websiteSnapshots
          .getText(previousSnapshot.id, accessToken)
          .then((t) => setPreviousSnapshotText(t));
        api.websiteSnapshots
          .getContent(previousSnapshot.id, accessToken)
          .then((c) => setPreviousSnapshotContent(c));
        api.websiteSnapshots
          .getScreenshotUrl(previousSnapshot.id, accessToken)
          .then((url) => setPreviousSnapshotImageUrl(url));
      }
    })();
  }, [getAccessTokenSilently, previousSnapshot]);

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      if (snapshot) {
        api.websiteSnapshots
          .getText(snapshot.id, accessToken)
          .then((t) => setSnapshotText(t));
        api.websiteSnapshots
          .getContent(snapshot.id, accessToken)
          .then((c) => setSnapshotContent(c));
        api.websiteSnapshots
          .getScreenshotUrl(snapshot.id, accessToken)
          .then((url) => setCurrentSnapshotImageUrl(url));
      }
    })();
  }, [getAccessTokenSilently, snapshot]);

  const getTabContent = () => {
    switch (activeTab) {
      case SNAPSHOT_TAB:
        return (
          <div className={classes.differencesPanel}>
            <GridList cols={3} cellHeight="auto">
              <GridListTile>
                {previousSnapshotImageUrl && (
                  <a
                    href={previousSnapshotImageUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt="previous snapshot"
                      src={previousSnapshotImageUrl}
                      width="100%"
                      height="100%"
                    />
                  </a>
                )}
              </GridListTile>
              <GridListTile>
                {websiteDifference.imageDifferent && changeImageUrl && (
                  <a href={changeImageUrl} target="_blank" rel="noreferrer">
                    <img
                      alt="difference"
                      src={changeImageUrl}
                      width="100%"
                      height="100%"
                    />
                  </a>
                )}
                {!websiteDifference.imageDifferent && (
                  <p className={classes.imageMessage}>Images are identical.</p>
                )}
              </GridListTile>
              <GridListTile>
                {currentSnapshotImageUrl && (
                  <a
                    href={currentSnapshotImageUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt="snapshot"
                      src={currentSnapshotImageUrl}
                      width="100%"
                      height="100%"
                    />
                  </a>
                )}
              </GridListTile>
            </GridList>
          </div>
        );

      case EXTRACTED_TEXT_TAB:
        return (
          <TextDiff
            oldText={previousSnapshot.extractedText}
            newText={snapshot.extractedText}
          />
        );

      case PAGE_TEXT_TAB:
        return (
          <TextDiff oldText={previousSnapshotText} newText={snapshotText} />
        );

      case PAGE_CONTENT_TAB:
        return (
          <TextDiff
            oldText={previousSnapshotContent}
            newText={snapshotContent}
          />
        );

      default:
        throw new Error(`Unknown tab ${activeTab}`);
    }
  };

  const updateStatus = async (status) => {
    let nextId = null;

    const accessToken = await getAccessTokenSilently();
    localSearchWebsiteDifferences(filter, accessToken)
      .then(() => {
        // get the id of the next website difference in the list
        const index = _.findIndex(websiteDifferences, {
          id: websiteDifferenceId,
        });
        nextId = _.get(websiteDifferences, `[${index + 1}].id`, null);
      })
      .then(() => {
        // update the status of the current difference
        localPatchWebsiteDifference(
          websiteDifferenceId,
          { status },
          "Updated status",
          accessToken
        );
      })
      .then(() => {
        // navigate to the next item in the list, otherwise return to the listing page
        history.push(
          nextId ? `/sentinel/differences/${nextId}` : "/sentinel/differences"
        );
      });
  };

  const differenceTimestamp = websiteDifference
    ? dates.formatTimestamp(dates.parseTimestamp(websiteDifference.created))
    : "";

  const updateWebsiteDifferenceStatus = async (status) => {
    const accessToken = await getAccessTokenSilently();
    return localPatchWebsiteDifference(
      websiteDifference.id,
      { status },
      "Updated status",
      accessToken
    );
  };

  const downloadFile = async (s) => {
    const accessToken = await getAccessTokenSilently();
    const url = await api.websiteSnapshots.getScreenshotUrl(s.id, accessToken);
    window.open(url, "_blank");
  };

  return (
    <Slide
      direction="left"
      in={transition}
      exit={false}
      timeout={enableTransition ? 500 : 0}
    >
      <div className={classes.root}>
        <HeaderBar>
          <BreadcrumbLink
            to="/sentinel/differences"
            label="Website Differences"
            includeArrow
          />
          <PageHeading
            icon={websiteDifferenceIcon()}
            heading={websiteDifference ? websiteDifference.url : ""}
          />
          <PageSubheading subheading={`@ ${differenceTimestamp}`} />
        </HeaderBar>
        <Container className={classes.container}>
          {websiteDifference && (
            <Card className={classes.card} elevation={0}>
              <CardHeader
                title="Details"
                action={
                  <>
                    {websiteDifference.imageDifferent && (
                      <Chip
                        avatar={
                          <Avatar>
                            <PhotoIcon />
                          </Avatar>
                        }
                        label="Image different"
                        className={classes.chip}
                      />
                    )}
                    {websiteDifference.textDifferent && (
                      <Chip
                        avatar={
                          <Avatar>
                            <FormatAlignLeftIcon />
                          </Avatar>
                        }
                        label="Text different"
                        className={classes.chip}
                      />
                    )}
                  </>
                }
              />
              <CardContent>
                <Scorecard label="Organisation">
                  {websiteDifference.organisation
                    ? websiteDifference.organisation.name
                    : "-"}
                </Scorecard>
                <Scorecard label="Url">{websiteDifference.url}</Scorecard>
                <Scorecard label="Image different">
                  {websiteDifference.imageDifferent ? "Yes" : "No"}
                </Scorecard>
                <Scorecard label="Text different">
                  {websiteDifference.textDifferent ? "Yes" : "No"}
                </Scorecard>
                <Scorecard label="Status">
                  <TextField
                    select
                    label=""
                    value={websiteDifference.status}
                    onChange={(event) =>
                      updateWebsiteDifferenceStatus(event.target.value)
                    }
                    disabled={!websiteDifference.different}
                  >
                    {getRefDataMenuItems(websiteDifferenceStates)}
                  </TextField>
                </Scorecard>
                <Scorecard label="Snapshot">
                  {snapshot ? (
                    <TimeAgo
                      value={dates.parseTimestamp(snapshot.created)}
                      expandable
                    />
                  ) : (
                    "-"
                  )}
                  <IconButton onClick={() => downloadFile(snapshot)}>
                    <Icon>open_in_new</Icon>
                  </IconButton>
                </Scorecard>
                <Scorecard label="Previous snapshot">
                  {previousSnapshot ? (
                    <TimeAgo
                      value={dates.parseTimestamp(previousSnapshot.created)}
                      expandable
                    />
                  ) : (
                    "-"
                  )}
                  <IconButton onClick={() => downloadFile(previousSnapshot)}>
                    <Icon>open_in_new</Icon>
                  </IconButton>
                </Scorecard>
              </CardContent>
            </Card>
          )}
          {websiteDifference &&
            previousSnapshot &&
            snapshot &&
            websiteDifference.different && (
              <Card className={classes.card} elevation={0}>
                <CardHeader
                  title="Difference"
                  action={
                    <>
                      <Tooltip
                        title="Mark as 'Under investigation'"
                        disableFocusListener
                      >
                        <IconButton
                          aria-label="Under investigation"
                          aria-haspopup="true"
                          onClick={() => updateStatus("UNDER_INVESTIGATION")}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        title="Mark as 'False alarm'"
                        disableFocusListener
                      >
                        <IconButton
                          aria-label="False positive"
                          aria-haspopup="true"
                          onClick={() => updateStatus("FALSE_ALARM")}
                        >
                          <AlarmOffIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  }
                />
                <div>
                  <Tabs
                    value={activeTab}
                    onChange={(event, t) => setActiveTab(t)}
                    className={classes.tabs}
                    textColor="primary"
                  >
                    <Tab label="Snapshots" value={SNAPSHOT_TAB} />
                    <Tab
                      label="Page text"
                      value={PAGE_TEXT_TAB}
                      disabled={!previousSnapshot.captureResult.textFilename}
                    />
                    <Tab
                      label="Visually recognised text"
                      value={EXTRACTED_TEXT_TAB}
                    />
                    <Tab
                      label="Page content"
                      value={PAGE_CONTENT_TAB}
                      disabled={!previousSnapshot.captureResult.contentFilename}
                    />
                  </Tabs>

                  {getTabContent()}
                </div>
              </Card>
            )}
        </Container>
      </div>
    </Slide>
  );
};

WebsiteDifferenceId.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,

  // redux
  websiteDifference: PropTypes.object,
  snapshot: PropTypes.object,
  previousSnapshot: PropTypes.object,
  websiteDifferenceStates: PropTypes.object.isRequired,
  websiteDifferences: PropTypes.array.isRequired,
  localClearActiveWebsiteSnapshot: PropTypes.func.isRequired,
  localClearActiveWebsiteDifference: PropTypes.func.isRequired,
  localGetWebsiteDifferenceById: PropTypes.func.isRequired,
  localGetWebsiteSnapshotById: PropTypes.func.isRequired,
  localGetPreviousWebsiteSnapshotById: PropTypes.func.isRequired,
  localPatchWebsiteDifference: PropTypes.func.isRequired,
  localSearchWebsiteDifferences: PropTypes.func.isRequired,
};

WebsiteDifferenceId.defaultProps = {
  websiteDifference: null,
  snapshot: null,
  previousSnapshot: null,
};

const mapStateToProps = (state) => ({
  websiteDifference: getActiveWebsiteDifference(state),
  snapshot: getActiveWebsiteSnapshot(state),
  previousSnapshot: getActivePreviousWebsiteSnapshot(state),
  websiteDifferences: getWebsiteDifferences(state),
  websiteDifferenceStates: getReferenceDataType(
    state,
    "WebsiteDifferenceStatus"
  ),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    localClearActiveWebsiteSnapshot: clearActiveWebsiteSnapshot,
    localClearActiveWebsiteDifference: clearActiveWebsiteDifference,
    localGetWebsiteDifferenceById: getWebsiteDifferenceById,
    localGetWebsiteSnapshotById: getWebsiteSnapshotById,
    localGetPreviousWebsiteSnapshotById: getPreviousWebsiteSnapshotById,
    localPatchWebsiteDifference: patchWebsiteDifference,
    localSearchWebsiteDifferences: searchWebsiteDifferences,
  })
)(WebsiteDifferenceId);
