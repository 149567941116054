import { useAuth0 } from "@auth0/auth0-react";
import { InputLabel } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import React, { useState } from "react";
import usersApi, { Filters } from "../../../services/api/users";
import { roles } from "@certane/arcadia-web-components";
import Avatar from "../../common/Avatar";
import ListPicker from "../../ListPicker";

interface Props {
  className?: string;
  label: string;
  onSelect: (value: unknown) => void;
  disabled: boolean;
}

interface SelectedUser {
  id: string;
  name: string;
  email: string;
}

const OwnerPicker: React.FC<Props> = ({
  className,
  label,
  onSelect,
  disabled,
}: Props) => {
  const { getAccessTokenSilently } = useAuth0();
  const [newOwner, setNewOwner] = useState<SelectedUser | null>();
  const [newOwnerPickerOpen, setNewOwnerPickerOpen] = useState(false);

  const asyncFetch = async (
    pickerFilter: Filters,
    pagination: Record<string, number>,
    abortController: AbortController
  ) => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = {
      ...pickerFilter,
      limit: pagination.pageSize,
      offset: pagination.offset,
      roles: roles.AGENT_ROLES,
      orderByField: "name",
    };
    return usersApi.search(searchParameters, abortController, accessToken);
  };

  const dialogTitle = `Select ${label.toLowerCase()}`;

  return (
    <>
      <InputLabel required>{label}</InputLabel>
      <Chip
        className={className}
        label={newOwner ? newOwner.name : "Select"}
        size="small"
        variant="outlined"
        onClick={() => setNewOwnerPickerOpen(true)}
        disabled={disabled}
      />

      <ListPicker
        title={dialogTitle}
        actionText="Select"
        open={newOwnerPickerOpen}
        onClose={() => setNewOwnerPickerOpen(false)}
        onSubmit={(user: SelectedUser) => {
          onSelect(user?.id);
          setNewOwner(user);
          setNewOwnerPickerOpen(false);
        }}
        datasource={asyncFetch}
        selected={newOwner}
        clearable
        toOption={(user) => ({
          label: user.name || "Unknown",
          id: user.id,
          email: user.email,
        })}
        fromOption={(option) => ({
          name: option.label,
          id: option.id,
          email: option.email,
        })}
        renderIcon={(user, size) => (
          <Avatar
            data-cy={user.name}
            email={user.email}
            name={user.name}
            size={size}
            round
          />
        )}
      />
    </>
  );
};

export default OwnerPicker;
