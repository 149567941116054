import { withStyles } from "@material-ui/core/styles";
import React from "react";
import PropTypes from "prop-types";
import SimpleChip from "./SimpleChip";

// A wrapper around our SimpleChip for a list of chips

const styles = () => ({
  root: {
    paddingTop: "6px",
  },
});

const SimpleChips = ({ classes, values, blankLabel }) => (
  <div className={classes.root}>
    {values && values.length > 0
      ? values.map((value) => <SimpleChip key={value} label={value} />)
      : blankLabel}
  </div>
);

SimpleChips.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string),
  blankLabel: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

SimpleChips.defaultProps = {
  values: [],
};

export default withStyles(styles)(SimpleChips);
