import { useAuth0 } from "@auth0/auth0-react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  getLoggedInUser,
  getReferenceDataDescription,
  getReferenceDataType,
} from "../../reducers";
import workItemsApi from "../../services/api/workItems";
import { createWorkItemSearchParameters } from "../../util/searchParameterUtils";
import GridListing from "../common/GridListing";
import Pagination from "../common/Pagination";

const styles = (theme) => ({
  resultsAria: {
    minHeight: "350px",
    maxHeight: "350px",
    position: "relative",
    overflowY: "scroll",
  },
  loading: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  dialogContentRoot: {
    paddingBottom: 0,
  },
});

const defaultPagination = {
  offset: 0,
  pageSize: 10,
  resultCount: 0,
};

const WorkItemSearchDialog = ({
  children,
  columns,
  open,
  onClose,
  title,
  classes,
  action,
  filter,
  statuses,
  workItemTypes,
  loggedInUser,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [sortBy, setSortBy] = useState({ field: "created", direction: "desc" });
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [searching, setSearching] = useState(false);
  const [abortController, setAbortController] = useState(false);
  const [pagination, setPagination] = useState(defaultPagination);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const getSearchParams = (offset) =>
    createWorkItemSearchParameters(
      filter,
      statuses,
      workItemTypes,
      loggedInUser,
      {
        ...pagination,
        offset,
      },
      `${sortBy.direction === "desc" ? "-" : ""}${sortBy.field}`
    );

  const loadResults = async (searchParameters) => {
    if (abortController) {
      abortController.abort();
    }

    const newAbortController = window.AbortController
      ? new AbortController()
      : null;
    setSearching(true);
    setAbortController(newAbortController);

    const accessToken = await getAccessTokenSilently();
    workItemsApi
      .search(searchParameters, newAbortController, accessToken)
      .then((response) => {
        setFilteredOptions(response.results);
        setPagination({
          offset: response.offset,
          previousOffset: response.previousOffset,
          nextOffset: response.nextOffset,
          pageSize: response.pageSize,
          resultCount: response.resultCount,
        });
      })
      .finally(() => {
        setSearching(false);
        setAbortController(null);
      });
  };

  const handlePreviousPage = () => {
    loadResults(getSearchParams(pagination.previousOffset));
  };

  const handleNextPage = () => {
    loadResults(getSearchParams(pagination.nextOffset));
  };

  const initialise = () => {
    setSearching(false);
    setFilteredOptions([]);
    loadResults(getSearchParams(0));
  };

  useEffect(() => {
    if (open) {
      loadResults(getSearchParams(0));
    }
  }, [filter, sortBy]);

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
      onEnter={initialise}
      aria-labelledby="WorkItemSearchDialog"
      data-cy="workitem-search-dialog"
    >
      <DialogTitle id="WorkItemSearchDialog" data-cy={title}>
        {title}
      </DialogTitle>
      <DialogContent
        classes={{
          root: classes.dialogContentRoot,
        }}
      >
        {children}
        <div className={classes.resultsAria}>
          {searching && (
            <div className={classes.loading}>
              <CircularProgress size={24} />
            </div>
          )}
          {!searching && filteredOptions.length === 0 && "No matches"}
          {!searching && filteredOptions.length > 0 && (
            <GridListing
              sortedData={filteredOptions}
              loading={searching}
              sortBy={sortBy}
              updateSort={(field, direction) => setSortBy({ field, direction })}
              action={action}
              isHighlight1={(workItem) => workItem.hasChildren}
              isHighlight2={(workItem) => workItem.hasParent}
              columns={columns}
            />
          )}
        </div>
        <Pagination
          pagination={pagination}
          handlePrevious={handlePreviousPage}
          handleNext={handleNextPage}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

WorkItemSearchDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  action: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,

  // redux state
  loggedInUser: PropTypes.object,
  statuses: PropTypes.object.isRequired,
  workItemTypes: PropTypes.object.isRequired,
};

WorkItemSearchDialog.defaultProps = {
  loggedInUser: null,
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
  workItemTypes: getReferenceDataType(state, "WorkItemType"),
  statuses: getReferenceDataType(state, "WorkItemStatus"),
  localGetReferenceDataDescription: (type, id, defaultValue) =>
    getReferenceDataDescription(
      state,
      type,
      id,
      defaultValue === undefined ? "-" : defaultValue
    ),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(WorkItemSearchDialog);
