import _ from "lodash";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as PropTypes from "prop-types";
import React from "react";

const WrappedSelectChips = ({
  input: { value, onChange, name },
  label,
  options,
  meta: { touched, error, invalid },
  margin,
  isMulti,
  toOption,
  fromOption,
  helperText,
  freeTextConverter,
  ...custom
}) => {
  const convertFromOption = (selected) => {
    if (_.isString(selected)) {
      if (freeTextConverter) {
        return freeTextConverter(selected);
      }
    }
    return fromOption(selected);
  };

  const handleOnChange = (selection) => {
    if (isMulti) {
      const selected = selection || [];
      onChange(selected.map((s) => convertFromOption(s)));
    } else {
      onChange(selection ? convertFromOption(selection) : null);
    }
  };

  const handleOnInputChange = (selection, reason) => {
    if (reason === "input") {
      handleOnChange(selection);
    }
  };

  const getValue = () => {
    if (isMulti) {
      const selection = value || [];
      return selection.map((s) => toOption(s));
    }
    return value ? toOption(value) : null;
  };

  const isSelected = (option) => {
    if (isMulti) {
      return value.filter((v) => toOption(v).value === option.value).length > 0;
    }
    return value && toOption(value).value === option.value;
  };

  const supportFreeText = !!freeTextConverter;

  return (
    <div style={{ marginBottom: "5px" }}>
      <Autocomplete
        name={`position-${name}`}
        multiple={isMulti}
        margin={margin}
        autoComplete
        autoHighlight
        blurOnSelect
        filterSelectedOptions
        getOptionSelected={isSelected}
        freeSolo={supportFreeText}
        options={options.map((o) => toOption(o))}
        getOptionLabel={(option) =>
          _.isString(option) ? option : option.label
        }
        value={getValue()}
        onChange={(event, selection) => handleOnChange(selection)}
        onInputChange={
          supportFreeText && !isMulti
            ? (event, selection, reason) =>
                handleOnInputChange(selection, reason)
            : null
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={label}
            margin={margin}
            error={touched && invalid}
            helperText={(touched && error) || helperText}
            {...custom}
          />
        )}
      />
    </div>
  );
};

WrappedSelectChips.propTypes = {
  input: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  margin: PropTypes.string,
  isMulti: PropTypes.bool,
  toOption: PropTypes.func,
  fromOption: PropTypes.func,
  helperText: PropTypes.any,
  freeTextConverter: PropTypes.func,
};

WrappedSelectChips.defaultProps = {
  margin: "dense",
  isMulti: true,
  toOption: (value) => value,
  fromOption: (option) => option,
  helperText: "",
  freeTextConverter: null,
};

export default WrappedSelectChips;
