import {
  Box,
  Card,
  CircularProgress,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { CheckboxWithLabel, Select } from "formik-material-ui";
import React from "react";
import * as Yup from "yup";
import OwnerPicker from "../OwnerPicker";

export interface RiskBulkUpdateFormValues {
  oldOwnerId: string;
  newOwnerId: string;
  limit: number;
  dryRun: boolean;
}

interface Props {
  onSubmit: (values: RiskBulkUpdateFormValues) => void;
}

const useStyles = makeStyles((theme) => ({
  card: {
    height: "100%",
  },
  fieldSet: {
    marginBottom: theme.spacing(2),
  },
  field: {
    marginTop: theme.spacing(1),
  },
}));

const initialValues: RiskBulkUpdateFormValues = {
  oldOwnerId: "",
  newOwnerId: "",
  limit: 50,
  dryRun: true,
};

const riskUserBulkUpdateSchema = Yup.object().shape({
  oldOwnerId: Yup.string().required("Current owner is required"),
  newOwnerId: Yup.string().required("New owner is required"),
});

const RiskBulkUpdateForm: React.FC<Props> = ({ onSubmit }: Props) => {
  const classes = useStyles();
  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={riskUserBulkUpdateSchema}
      onSubmit={async (values: RiskBulkUpdateFormValues, { setSubmitting }) => {
        try {
          await onSubmit(values);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, setFieldValue }) => {
        return (
          <Form>
            <Grid spacing={2} container>
              <Grid item xs={5}>
                <Card className={classes.card} variant={"outlined"}>
                  <CardHeader title={"Filters"} />
                  <CardContent>
                    <Box className={classes.fieldSet}>
                      <OwnerPicker
                        className={classes.field}
                        label={"Owner"}
                        onSelect={(value) => setFieldValue("oldOwnerId", value)}
                        disabled={isSubmitting}
                      />
                      <FormHelperText error>
                        <ErrorMessage name={"oldOwnerId"} />
                      </FormHelperText>
                    </Box>
                    <Box className={classes.fieldSet}>
                      <InputLabel>Limit</InputLabel>
                      <Field
                        component={Select}
                        name="limit"
                        margin="dense"
                        disabled={isSubmitting}
                        fullWidth
                      >
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Field>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={1}>
                <Box
                  display={"flex"}
                  flexDirection="column"
                  alignItems={"center"}
                  justifyContent={"center"}
                  height={"100%"}
                >
                  <ArrowRightIcon fontSize={"large"} />
                </Box>
              </Grid>
              <Grid item xs={5}>
                <Card className={classes.card} variant={"outlined"}>
                  <CardHeader title={"Updates"} />
                  <CardContent>
                    <Box className={classes.fieldSet}>
                      <OwnerPicker
                        className={classes.field}
                        label={"New owner"}
                        onSelect={(value) => setFieldValue("newOwnerId", value)}
                        disabled={isSubmitting}
                      />
                      <FormHelperText error>
                        <ErrorMessage name={"newOwnerId"} />
                      </FormHelperText>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            <Box className={classes.fieldSet}>
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="dryRun"
                Label={{ label: "Dry run" }}
                margin="dense"
                disabled={isSubmitting}
              />
            </Box>

            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting}
            >
              Update{" "}
              {isSubmitting && (
                <CircularProgress color={"secondary"} size={16} />
              )}
            </Button>
            {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
          </Form>
        );
      }}
    </Formik>
  );
};

export default RiskBulkUpdateForm;
