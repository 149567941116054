import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { fetchTags } from "../../../actions/tags";
import { getLabels } from "../../../reducers";
import { scrollToFirstInvalidInput } from "../../../util/formHelpers";
import FormError from "../../common/FormError";
import ControlDescriptionField from "./ControlDescriptionField";
import ControlObjectiveField from "./ControlObjectiveField";
import OperationalFrequencyField from "./OperationalFrequencyField";

export const EDIT_CONTROL_DETAILS_FORM_NAME = "editControlDetailsForm";

const EditControlDetailsForm = ({ error, fieldLabels }) => {
  return (
    <form autoComplete="off">
      {error && <FormError errorMessage={error} />}

      <ControlObjectiveField fieldLabels={fieldLabels} />
      <ControlDescriptionField fieldLabels={fieldLabels} />
      <OperationalFrequencyField fieldLabels={fieldLabels} />
    </form>
  );
};

EditControlDetailsForm.propTypes = {
  error: PropTypes.string,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  fieldLabels: PropTypes.object.isRequired,
};

EditControlDetailsForm.defaultProps = {
  error: null,
};

const mapStateToProps = (state) => ({
  fieldLabels: getLabels(state).Control,
});

const form = reduxForm({
  form: EDIT_CONTROL_DETAILS_FORM_NAME,
  onSubmitFail: (errors) => scrollToFirstInvalidInput(errors),
});
const reduxData = connect(mapStateToProps, {
  localFetchTags: fetchTags,
});

export default compose(form, reduxData)(EditControlDetailsForm);
