import { ArrowRight } from "@material-ui/icons";
import { Chip } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const styles = () => ({
  ghostChip: {
    flexGrow: "0",
    flexShrink: "0",
    flexBasis: "auto",
    border: "1px dashed rgba(0, 0, 0, 0.2)",
  },
});

const GhostChip = ({ classes, className, label, onClick }) => (
  <Chip
    {...{ label, onClick }}
    className={classNames({
      [classes.ghostChip]: true,
      [className]: !!className,
    })}
    variant="outlined"
    onDelete={onClick}
    deleteIcon={<ArrowRight />}
    clickable
  />
);

GhostChip.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

GhostChip.defaultProps = {
  onClick: () => {
    // do nothing.
  },
  className: null,
};

export default withStyles(styles)(GhostChip);
