import { FormControlLabel } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Switch from "@material-ui/core/Switch";
import PropTypes from "prop-types";
import React from "react";
import UrlCopyField from "./UrlCopyField";

const MediaLinksCard = ({ className, title, media, fieldLabels, onChange }) => (
  <Card className={className} elevation={0}>
    <CardHeader title={title} data-cy={title} />
    <CardContent>
      <UrlCopyField
        label={fieldLabels.labels.publicUrl}
        url={media.publicUrl ? media.publicUrl : ""}
        data-cy={fieldLabels.labels.publicUrl}
      />
      <UrlCopyField
        label={fieldLabels.labels.apiEndpoint}
        url={media.apiEndpoint}
        data-cy={fieldLabels.labels.apiEndpoint}
      />
      {media.legacyPublicUrl && (
        <UrlCopyField
          label={fieldLabels.labels.legacyPublicUrl}
          url={media.legacyPublicUrl}
        />
      )}
      <FormControlLabel
        control={
          <Switch
            checked={media.publishedToApi}
            disabled={!onChange}
            onChange={(evt) => onChange({ publishedToApi: evt.target.checked })}
            data-cy="publishedToApi"
          />
        }
        label={fieldLabels.labels.publishedToApi}
      />
    </CardContent>
  </Card>
);

MediaLinksCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  media: PropTypes.object.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  onChange: PropTypes.func,
};

MediaLinksCard.defaultProps = {
  className: null,
  onChange: null,
};

export default MediaLinksCard;
