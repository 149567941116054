import { useAuth0 } from "@auth0/auth0-react";
import InputAdornment from "@material-ui/core/InputAdornment";
import { mdiLinkVariant } from "@mdi/js";
import Icon from "@mdi/react";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { numericality, required } from "redux-form-validators";
import { getReferenceDataType } from "../../../../reducers";
import usersApi from "../../../../services/api/users";
import workItemsApi from "../../../../services/api/workItems";
import Avatar from "../../../common/Avatar.tsx";
import { getRefDataMenuItems } from "../../../forms/menuItems";
import ComponentField from "../../../forms/wrapper/ComponentField";
import WrappedChipListPicker from "../../../forms/wrapper/WrappedChipListPicker";
import WrappedListPicker from "../../../forms/wrapper/WrappedListPicker";
import WrappedSelect from "../../../forms/wrapper/WrappedSelect";
import WrappedTextField from "../../../forms/wrapper/WrappedTextField";

const linkIcon = () => <Icon size={1} path={mdiLinkVariant} />;

const ClaimAssessmentFields = ({
  assessmentOutcomes,
  assessmentOutcome,
  index,
  workItemDefinition,
  customisations,
  fieldLabels,
  change,
  untouch,
  formName,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (assessmentOutcome === "DENIED") {
      change("approvedAmount", 0);
    }
  }, [assessmentOutcome]);

  const { collaboratableBy } = workItemDefinition;

  const asyncUserOptionsFetch = async (
    pickerFilter,
    pagination,
    abortController
  ) => {
    const searchParameters = {
      ...pickerFilter,
      limit: pagination.pageSize,
      offset: pagination.offset,
      roles: collaboratableBy,
      orderByField: "name",
    };
    const accessToken = await getAccessTokenSilently();
    return usersApi.search(searchParameters, abortController, accessToken);
  };

  const asyncComplaintOptionsFetch = async (
    pickerFilter,
    pagination,
    abortController
  ) => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = {
      ...pickerFilter,
      types: ["COMPLAINT"],
      limit: pagination.pageSize,
      offset: pagination.offset,
      orderByField: "title",
    };
    return workItemsApi.search(searchParameters, abortController, accessToken);
  };

  const editMode = index >= 0;

  return (
    <>
      <ComponentField
        name="assessmentOutcome"
        component={WrappedSelect}
        required
        validate={required({
          msg: `${fieldLabels.nestedTypes.hardshipAssessments.labels.assessmentOutcome} is required`,
        })}
        label={
          fieldLabels.nestedTypes.hardshipAssessments.labels.assessmentOutcome
        }
        fullWidth
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
        disabled={editMode}
      >
        {getRefDataMenuItems(assessmentOutcomes)}
      </ComponentField>
      <ComponentField
        component={WrappedTextField}
        label={
          fieldLabels.nestedTypes.hardshipAssessments.labels.approvedAmount
        }
        name="approvedAmount"
        validate={numericality({
          ">=": 0,
          allowBlank: true,
        })}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        fullWidth
        disabled={editMode || assessmentOutcome === "DENIED"}
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
      />
      <ComponentField
        name="justificationNotes"
        component={WrappedTextField}
        placeholder={`Enter ${fieldLabels.nestedTypes.hardshipAssessments.labels.justificationNotes} here`}
        label={
          fieldLabels.nestedTypes.hardshipAssessments.labels.justificationNotes
        }
        fullWidth
        multiline
        rowsMax={10}
        rows={2}
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
        disabled={editMode}
      />
      <ComponentField
        name="mentions"
        component={WrappedListPicker}
        helperText="People you notify will automatically become Collaborators"
        datasource={asyncUserOptionsFetch}
        label={fieldLabels.nestedTypes.hardshipAssessments.labels.mentions}
        fullWidth
        renderIcon={(user, size) => (
          <Avatar email={user.email} name={user.name} size={size} round />
        )}
        toOption={(user) => ({
          label: user.name || "Unknown",
          id: user.id,
          email: user.email,
        })}
        fromOption={(option) => ({
          name: option.label,
          id: option.id,
          email: option.email,
        })}
        customisations={customisations}
        change={change}
        untouch={untouch}
        formName={formName}
        disabled={editMode}
      />
      {editMode && (
        <ComponentField
          name="objections"
          component={WrappedChipListPicker}
          datasource={asyncComplaintOptionsFetch}
          label={fieldLabels.nestedTypes.hardshipAssessments.labels.objections}
          data-cy="objections"
          description={
            <>
              A received objection should be recorded as a &quot;complaint&quot;
              work item. You can link existing complaints below.
            </>
          }
          fullWidth
          clearable
          toOption={(workItem) => ({
            label: workItem.title,
            id: workItem.id,
            workItem,
          })}
          fromOption={(option) => option.workItem}
          addIcon={linkIcon}
          renderIcon={(workItem, size) => (
            <Avatar
              name={(workItem.title || "Unknown").charAt(0)}
              size={size}
              round
            />
          )}
          customisations={customisations}
          change={change}
          untouch={untouch}
          formName={formName}
        />
      )}
    </>
  );
};

ClaimAssessmentFields.propTypes = {
  fieldLabels: PropTypes.object.isRequired,
  workItemDefinition: PropTypes.object.isRequired,
  customisations: PropTypes.array,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,

  // redux
  assessmentOutcomes: PropTypes.object.isRequired,
  assessmentOutcome: PropTypes.string,
  index: PropTypes.number,
};

ClaimAssessmentFields.defaultProps = {
  assessmentOutcome: null,
  customisations: [],
};

const mapStateToProps = (state, { formName }) => {
  const reduxFormSelector = formValueSelector(formName);
  return {
    assessmentOutcomes: getReferenceDataType(state, "AssessmentOutcome"),
    assessmentOutcome: reduxFormSelector(state, "assessmentOutcome"),
    index: reduxFormSelector(state, "index"),
  };
};

export default connect(mapStateToProps)(ClaimAssessmentFields);
