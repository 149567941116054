import { useAuth0 } from "@auth0/auth0-react";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import workItemsApi from "../../services/api/workItems";
import logger from "../../util/logger";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    padding: theme.spacing(2),
  },
}));

const events = [
  "click",
  "mousemove",
  "mousedown",
  "mousewheel",
  "keypress",
  "keydown",
  "touchmove",
  "touchstart",
  "touchend",
];

const VERSION_CHECK_INTERVAL = 30000;
const ACTIVITY_TIMEOUT = 60000;
const ACTIVITY_THROTTLE = 2500;

const WorkItemUpdatedCheck = ({ workItem }) => {
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const active = useRef(true);
  const workItemRef = useRef(workItem); // refer to work item from reference to avoid using stale value in functions
  const activityTimeoutHandle = useRef(null);
  const versionCheckHandle = useRef(null);

  useEffect(() => {
    workItemRef.current = workItem;
  }, [workItem]);

  const startActivityTimeout = () => {
    logger.debug("starting activity timeout timeout");
    return window.setTimeout(() => {
      logger.debug("setting active to false");
      active.current = false;
    }, ACTIVITY_TIMEOUT);
  };

  const checkForNewVersion = () => {
    logger.debug("Setting up interval for version checks");
    return window.setInterval(() => {
      // only check if the user is currently active
      logger.debug("interval fired, active is", active.current);
      if (workItemRef.current && active.current) {
        getAccessTokenSilently().then((accessToken) => {
          workItemsApi
            .getMeta(workItemRef.current.id, accessToken)
            .then((latestWorkItem) => {
              logger.debug("got new work item");
              if (
                workItemRef.current &&
                moment(latestWorkItem.updated).isAfter(
                  moment(workItemRef.current.updated)
                )
              ) {
                logger.debug(
                  `Newer version exists. Current updated ${workItem.updated}, new updated ${latestWorkItem.updated}`
                );
                showModal();
              }
            });
        });
      }
    }, VERSION_CHECK_INTERVAL);
  };

  /**
   * Reset the inactivity timeout each time an event listener fires.
   * Note: throttled to prevent too many calls.
   */
  const resetActivityTimeout = _.memoize(
    _.throttle(
      () => {
        logger.debug("throttled activity timeout");
        window.clearTimeout(activityTimeoutHandle.current);
        active.current = true;
        activityTimeoutHandle.current = startActivityTimeout();
      },
      ACTIVITY_THROTTLE,
      { leading: true, trailing: false }
    )
  );

  const addListeners = () => {
    events.forEach((event) => {
      window.addEventListener(event, resetActivityTimeout);
    });
  };

  const removeListeners = () => {
    events.forEach((event) => {
      window.removeEventListener(event, resetActivityTimeout);
    });
  };

  /**
   * Stop all listeners, timers etc.
   */
  const stopAll = () => {
    logger.debug("stop all");
    removeListeners();
    window.clearTimeout(activityTimeoutHandle.current);
    window.clearInterval(versionCheckHandle.current);
  };

  useEffect(() => {
    // Start all listeners, timers etc.
    addListeners();
    activityTimeoutHandle.current = startActivityTimeout();
    versionCheckHandle.current = checkForNewVersion();

    return () => {
      stopAll();
    };
  }, []);

  const showModal = () => {
    stopAll();
    setOpen(true);
  };

  const handleReload = () => {
    window.location.reload(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };
  logger.warn("active =", active);
  return (
    <Modal open={open}>
      <Paper elevation={2} className={classes.root}>
        <span data-cy="This work item has been updated.">
          This work item has been updated.
        </span>
        <Button color="primary" onClick={handleReload} data-cy="reload">
          Reload
        </Button>
        <Button color="secondary" onClick={handleCancel} data-cy="ignore">
          Ignore
        </Button>
      </Paper>
    </Modal>
  );
};

WorkItemUpdatedCheck.propTypes = {
  workItem: PropTypes.object,
};

WorkItemUpdatedCheck.defaultProps = {
  workItem: undefined,
};

export default WorkItemUpdatedCheck;
