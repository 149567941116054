import { reduxForm } from "redux-form";
import React from "react";
import { scrollToFirstInvalidInput } from "../../../util/formHelpers";
import RiskDetailFields from "./partial/RiskDetailFields";
import PropTypes from "prop-types";

export const EDIT_RISK_DETAILS_FORM_NAME = "EDIT_RISK_DETAILS_FORM_NAME";

const EditRiskDetailsForm = ({ fieldLabels }) => {
  return <RiskDetailFields fieldLabels={fieldLabels} />;
};

EditRiskDetailsForm.propTypes = {
  fieldLabels: PropTypes.object.isRequired,
};

const form = reduxForm({
  form: EDIT_RISK_DETAILS_FORM_NAME,
  onSubmitFail: (errors) => scrollToFirstInvalidInput(errors),
});

export default form(EditRiskDetailsForm);
