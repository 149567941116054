import { requestJSON } from "./http";

const decorateSearchParameters = (filter) =>
  filter ? { __type: "Product", ...filter } : { __type: "Product" };

const create = (product, accessToken) =>
  requestJSON({
    path: "/products",
    method: "POST",
    data: product,
    accessToken,
  });

// with pagination
const search = (searchParams, abortController, accessToken) =>
  requestJSON({
    path: "/products/search",
    method: "POST",
    data: decorateSearchParameters(searchParams),
    abortController,
    accessToken,
  });

const getById = (id, accessToken) =>
  requestJSON({
    path: `/products/${id}`,
    accessToken,
  });

const patch = (id, changes, description, accessToken) =>
  requestJSON({
    path: `/products/${id}`,
    method: "PATCH",
    data: {
      fields: Object.keys(changes),
      changes,
      description,
    },
    accessToken,
  });

const checkFriendlyIdAvailability = (id, friendlyId, accessToken) =>
  id
    ? requestJSON({
        path: `/products/${id}/friendly-available/${friendlyId}`,
        accessToken,
      })
    : requestJSON({
        path: `/products/friendly-available/${friendlyId}`,
        accessToken,
      });

export default {
  create,
  search,
  getById,
  patch,
  checkFriendlyIdAvailability,
};
