import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import { required } from "redux-form-validators";
import { getLoggedInUser, getReferenceDataType } from "../../../reducers";
import usersApi from "../../../services/api/users";
import { scrollToFirstInvalidInput } from "../../../util/formHelpers";
import FormError from "../../common/FormError";
import WrappedDatePicker from "../wrapper/WrappedDatePicker";
import WrappedTextField from "../wrapper/WrappedTextField";
import WrappedOwnerPicker from "../wrapper/WrappedOwnerPicker";
import { useAuth0 } from "@auth0/auth0-react";

const RiskReassessmentForm = ({ error, ownerRoles, loggedInUser }) => {
  const { getAccessTokenSilently } = useAuth0();

  const asyncOwnerOptionsFetch = async (
    pickerFilter,
    pagination,
    abortController
  ) => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = {
      ...pickerFilter,
      limit: pagination.pageSize,
      offset: pagination.offset,
      roles: ownerRoles,
      orderByField: "name",
    };
    return usersApi.search(searchParameters, abortController, accessToken);
  };

  return (
    <form autoComplete="off">
      {error && <FormError errorMessage={error} />}
      <Field
        component={WrappedTextField}
        name="title"
        label="Title"
        validate={required({ msg: "Title is required" })}
        fullWidth
        required
      />
      <Field
        name="owner"
        component={WrappedOwnerPicker}
        datasource={asyncOwnerOptionsFetch}
        required
        validate={required({ msg: "Owner is required" })}
        label="Owner"
        fullWidth
        loggedInUser={loggedInUser}
      />
      <Field
        name="dueDate"
        component={WrappedDatePicker}
        label="Due"
        validate={required({ msg: "Due date is required" })}
        required
        fullWidth
        minDate={Date.now()}
        data-cy="dueDate"
      />
      <Field
        name="reminderDate"
        component={WrappedDatePicker}
        label="Reminder"
        validate={required({ msg: "Reminder date is required" })}
        required
        fullWidth
        minDate={Date.now()}
        data-cy="reminderDate"
      />
    </form>
  );
};

RiskReassessmentForm.propTypes = {
  loggedInUser: PropTypes.object.isRequired,
  ownerRoles: PropTypes.array.isRequired,
  error: PropTypes.string,
};

RiskReassessmentForm.defaultProps = {
  error: null,
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
  ownerRoles: getReferenceDataType(state, "WorkItemType").values
    .RISK_REASSESSMENT.props.definition.ownableBy,
});

export const RISK_REASSESSMENT_FORM_NAME = "RiskReassessmentForm";

const form = reduxForm({
  form: RISK_REASSESSMENT_FORM_NAME,
  onSubmitFail: (errors) => scrollToFirstInvalidInput(errors),
});

const reduxData = connect(mapStateToProps);

export default compose(form, reduxData)(RiskReassessmentForm);
