import { withStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import * as PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  CompositeDecorator,
  convertFromRaw,
  convertToRaw,
  Editor,
  EditorState,
  getDefaultKeyBinding,
  Modifier,
  RichUtils,
} from "draft-js";
import AlternateEmail from "@material-ui/icons/AlternateEmail";
import FormatBold from "@material-ui/icons/FormatBold";
import FormatItalic from "@material-ui/icons/FormatItalic";
import FormatUnderlined from "@material-ui/icons/FormatUnderlined";
import Code from "@material-ui/icons/Code";
import logger from "../../util/logger";
import Avatar from "../common/Avatar.tsx";
import InlineMention from "./InlineMention";

const styles = (theme) => ({
  actions: {
    position: "absolute",
    right: theme.spacing(1) / 4,
    top: 0,
  },
  actionButton: {
    padding: theme.spacing(1) / 4,
    margin: 0,
  },
  suggestionContainer: {
    position: "relative",
  },
  suggestionPopup: {
    position: "absolute",
    top: 0,
    zIndex: 1500,
    width: "100%",
    overflow: "hidden",
  },
  suggestionsList: {
    position: "relative",
    maxHeight: 185,
    width: "100%",
    overflowY: "auto",
    overflowX: "hidden",
  },
  editorLarge: {
    height: "200px",
    overflowY: "auto",
    outline: "none",
  },
  editorSmall: {
    height: "60px",
    overflowY: "auto",
    outline: "none",
  },
  filterSummary: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: theme.palette.swatch.grey6,
  },
  filterText: {
    fontStyle: "italic",
    margin: theme.spacing(1) / 2,
  },
  filterSearching: {
    flexGrow: 1,
    margin: theme.spacing(1) / 2,
  },
  blinkingCursor: {
    animation: "1s $blink step-end infinite",
  },
  "@keyframes blink": {
    "from, to": {
      color: "transparent",
    },
    "50%": {
      color: "black",
    },
  },
});

const useOutsideAlerter = (ref, onClickOutside) => {
  /**
   * Alert if clicked on outside of element
   */
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      onClickOutside();
    }
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
};

const CMD_ESC = "command-esc";
const CMD_UP = "command-up";
const CMD_DOWN = "command-down";
const CMD_ENTER = "command-enter";

const customKeyBindingFn = (e) => {
  switch (e.keyCode) {
    case 27:
      return CMD_ESC;
    case 38:
      return CMD_UP;
    case 40:
      return CMD_DOWN;
    case 13:
      return CMD_ENTER;
    default:
      break;
  }
  return getDefaultKeyBinding(e);
};

const SuggestionPopup = ({ classes, heading, children, onClose }) => {
  const selectionRef = useRef(null);
  useOutsideAlerter(selectionRef, onClose);
  return (
    <div className={classes.suggestionContainer} ref={selectionRef}>
      <Paper elevation={2} square className={classes.suggestionPopup}>
        {heading}
        <div className={classes.suggestionsList}>{children}</div>
      </Paper>
    </div>
  );
};

SuggestionPopup.propTypes = {
  classes: PropTypes.object.isRequired,
  heading: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
};

const StyledSuggestionPopup = withStyles(styles)(SuggestionPopup);

const findMentions = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "MENTION"
    );
  }, callback);
};

const defaultPagination = {
  offset: 0,
  pageSize: 10,
  resultCount: 0,
};

const RichTextMentionsField = ({
  classes,
  mentionSuggestions,
  value,
  onChange,
  children,
  small,
  autoFocus,
  placeholder,
  stylingSupported,
}) => {
  const decorator = new CompositeDecorator([
    {
      strategy: findMentions,
      component: InlineMention,
    },
  ]);
  const editor = useRef(null);
  const [pickerOpen, setPickerOpen] = useState(false);
  const [pickerSelection, setPickerSelection] = useState(null);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [filterText, setFilterText] = useState(null);
  const [editorState, setEditorState] = useState(
    value
      ? EditorState.createWithContent(convertFromRaw(value), decorator)
      : EditorState.createEmpty(decorator)
  );
  const [abortController, setAbortController] = useState(false);
  const [pagination, setPagination] = useState(defaultPagination);
  const [searching, setSearching] = useState(false);
  const showMentions = pickerOpen && !_.isArray(mentionSuggestions);

  const debouncedFireAsyncFetch = useCallback(
    _.debounce(
      (text, page, abort) => {
        if (abort) {
          abort.abort();
        }
        logger.debug("firing search", text);
        const newAbortController = window.AbortController
          ? new AbortController()
          : null;
        setAbortController(newAbortController);
        setSearching(true);

        mentionSuggestions(text, page, newAbortController)
          .then((response) => {
            setFilteredSuggestions(response.results);
            setPagination({
              offset: response.offset,
              previousOffset: response.previousOffset,
              nextOffset: response.nextOffset,
              pageSize: response.pageSize,
              resultCount: response.resultCount,
            });
          })
          .finally(() => {
            setAbortController(null);
            setSearching(false);
          });
      },
      400,
      {}
    ),
    []
  );

  const focus = () => editor.current && editor.current.focus();

  const shouldInitiateMention = () => {
    const currentContent = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const anchorKey = selectionState.getAnchorKey();
    const currentContentBlock = currentContent.getBlockForKey(anchorKey);
    const end = selectionState.getEndOffset();

    if (end === 0) {
      return false;
    }

    const line = currentContentBlock.getText();
    const lastChar = line.slice(end - 1, end);

    if (lastChar === "@") {
      const prevChar = end === 1 ? null : line.slice(end - 2, end - 1);
      return prevChar === null || /^\s+$/.test(prevChar);
    }
    return false;
  };

  const currentSuggestionFilter = () => {
    const currentContent = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const anchorKey = selectionState.getAnchorKey();
    const currentContentBlock = currentContent.getBlockForKey(anchorKey);
    const end = selectionState.getEndOffset();

    if (end === 0) {
      return false;
    }

    const slicedLine = currentContentBlock.getText().slice(0, end);
    const start = slicedLine.lastIndexOf(" ");
    const lastWord = slicedLine.slice(start === -1 ? 0 : start + 1);
    return lastWord[0] === "@" ? lastWord : null;
  };

  useEffect(() => {
    if (autoFocus) {
      focus();
    }
  }, [autoFocus]);

  useEffect(() => {
    const currentContent = editorState.getCurrentContent();
    let rawNewState = convertToRaw(currentContent);
    if (rawNewState.blocks.length === 1 && !rawNewState.blocks[0].text) {
      rawNewState = null;
    }
    const stateChanged = !_.isEqual(value, rawNewState);

    if (stateChanged) {
      onChange(rawNewState);

      if (shouldInitiateMention()) {
        setFilterText(null);
        if (!pickerOpen) {
          setPickerSelection(0);
          setPickerOpen(true);
          focus();
        }
      } else if (pickerOpen) {
        const currentFilterText = currentSuggestionFilter();
        if (currentFilterText) {
          setFilterText(currentFilterText.slice(1));
        } else {
          setFilterText(null);
          setPickerOpen(false);
        }
        focus();
      }
    } else if (pickerOpen && !currentSuggestionFilter()) {
      setPickerOpen(false);
    }
  }, [editorState]);

  const doesSuggestionNameContainText = (suggestion, text) =>
    suggestion.name &&
    suggestion.name.toLowerCase().search(_.escapeRegExp(text.toLowerCase())) !==
      -1;

  useEffect(() => {
    const isAsyncFetch = !_.isArray(mentionSuggestions);

    if (isAsyncFetch) {
      debouncedFireAsyncFetch(filterText, pagination, abortController);
    } else {
      setFilteredSuggestions(
        mentionSuggestions
          .filter(
            (suggestion) =>
              !filterText ||
              doesSuggestionNameContainText(suggestion, filterText)
          )
          .slice(0, 10)
      );
    }
  }, [filterText]);

  const toggleStyle = (style) => {
    const selection = editorState.getSelection();
    const newState = RichUtils.toggleInlineStyle(editorState, style);

    setTimeout(() => {
      focus();
      setEditorState(EditorState.forceSelection(newState, selection));
    }, 100);
  };

  const onAddMention = (mention) => {
    if (mention) {
      logger.info("adding user", mention);
      const name = mention.name || "Unknown";
      let eState = editorState;
      let selection = eState.getSelection();

      // create the mention entity
      const entityKey = eState
        .getCurrentContent()
        .createEntity("MENTION", "SEGMENTED", { mention })
        .getLastCreatedEntityKey();

      // select the '@' symbol and any suggestion filter the user may have entered
      const currentFilterText = currentSuggestionFilter();
      if (currentFilterText) {
        selection = selection.merge({
          anchorOffset: selection.getEndOffset() - currentFilterText.length,
          focusOffset: selection.getEndOffset(),
        });
        logger.debug("selecting the @ bit and filter", currentFilterText);
      }

      // insert the mention's name (replacing any @ symbol and filter text)
      let modifier = selection.isCollapsed()
        ? Modifier.insertText(
            eState.getCurrentContent(),
            selection,
            name,
            null,
            entityKey
          )
        : Modifier.replaceText(
            eState.getCurrentContent(),
            selection,
            name,
            null,
            entityKey
          );
      selection = selection.merge({
        anchorOffset: selection.getStartOffset() + name.length,
        focusOffset: selection.getStartOffset() + name.length,
      });
      eState = EditorState.forceSelection(
        EditorState.push(eState, modifier, "insert-characters"),
        selection
      );

      // add a plain text (non-entity associated) space after the mention entity
      modifier = Modifier.insertText(
        eState.getCurrentContent(),
        selection,
        " "
      );
      selection = selection.merge({
        anchorOffset: selection.getStartOffset() + 1,
        focusOffset: selection.getStartOffset() + 1,
      });
      const finalState = EditorState.forceSelection(
        EditorState.push(eState, modifier, "insert-characters"),
        selection
      );

      setTimeout(() => {
        setEditorState(finalState);
        focus();
        setPickerOpen(false);
      }, 100);
    } else {
      setPickerOpen(false);
      focus();
    }
  };

  const startMention = () => {
    const currentContent = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const text = Modifier.insertText(currentContent, selection, "@");
    const newState = EditorState.push(editorState, text, "insert-characters");

    setEditorState(
      EditorState.forceSelection(
        newState,
        selection.merge({
          anchorOffset: selection.getStartOffset() + 1,
          focusOffset: selection.getStartOffset() + 1,
        })
      )
    );
  };

  const handleKeyCommand = (command, eState) => {
    if (pickerOpen) {
      switch (command) {
        case CMD_ESC:
          setPickerOpen(false);
          return "handled";
        case CMD_UP:
          if (pickerSelection === null) {
            setPickerSelection(0);
          }
          if (pickerSelection > 0) {
            setPickerSelection(pickerSelection - 1);
          }
          return "handled";
        case CMD_DOWN:
          if (pickerSelection === null) {
            setPickerSelection(0);
          } else {
            setPickerSelection(
              Math.min(pickerSelection + 1, filteredSuggestions.length - 1)
            );
          }
          return "handled";
        case CMD_ENTER:
          if (pickerSelection || pickerSelection === 0) {
            onAddMention(filteredSuggestions[pickerSelection]);
          }
          return "handled";
        default:
          break;
      }
    }

    if (stylingSupported) {
      const newState = RichUtils.handleKeyCommand(eState, command);
      if (newState) {
        setEditorState(newState);
        return "handled";
      }
    }
    return "not-handled";
  };

  const filterSummary = () => {
    const numResults = filteredSuggestions.length;
    return (
      <div
        className={classes.filterSummary}
        onClick={focus}
        onKeyPress={focus}
        role="button"
        tabIndex="0"
        data-cy="filter"
      >
        <Typography variant="body2" className={classes.filterText}>
          {`${filterText || ""}`}
          <span className={classes.blinkingCursor}>|</span>
        </Typography>
        {searching && (
          <LinearProgress className={classes.filterSearching} color="primary" />
        )}
        {!searching && numResults === 0 && (
          <span className={classes.filterText}>(No matches)</span>
        )}
        {!searching &&
          numResults === pagination.resultCount &&
          numResults > 0 && (
            <span className={classes.filterText}>
              {`(${numResults} matches)`}
            </span>
          )}
        {!searching && numResults < pagination.resultCount && (
          <span className={classes.filterText}>
            {`(Showing ${numResults} of ${pagination.resultCount})`}
          </span>
        )}
      </div>
    );
  };

  return (
    <div>
      <div className={classes.actions} data-cy="actionsIcon">
        {mentionSuggestions.length > 0 && (
          <IconButton
            className={classes.actionButton}
            color="inherit"
            onClick={() => startMention()}
            data-cy="iconButton"
          >
            <AlternateEmail />
          </IconButton>
        )}
        {stylingSupported && (
          <>
            <IconButton
              className={classes.actionButton}
              color="inherit"
              onClick={() => toggleStyle("BOLD")}
            >
              <FormatBold />
            </IconButton>
            <IconButton
              className={classes.actionButton}
              color="inherit"
              onClick={() => toggleStyle("ITALIC")}
            >
              <FormatItalic />
            </IconButton>
            <IconButton
              className={classes.actionButton}
              color="inherit"
              onClick={() => toggleStyle("UNDERLINE")}
            >
              <FormatUnderlined />
            </IconButton>
            <IconButton
              className={classes.actionButton}
              color="inherit"
              onClick={() => toggleStyle("CODE")}
            >
              <Code />
            </IconButton>
          </>
        )}
        {children}
      </div>
      {showMentions && (
        <StyledSuggestionPopup
          onClose={() => setPickerOpen(false)}
          heading={filterSummary()}
        >
          {filteredSuggestions.map((suggestion, index) => (
            <MenuItem
              key={suggestion.id}
              onClick={() => onAddMention(suggestion)}
              selected={index === pickerSelection}
              data-cy={suggestion.name}
            >
              <Avatar
                email={suggestion.email}
                name={suggestion.name}
                size={24}
                round
                data-cy="avatar"
              />
              {suggestion.name}
            </MenuItem>
          ))}
        </StyledSuggestionPopup>
      )}
      <div
        className={small ? classes.editorSmall : classes.editorLarge}
        onClick={() => focus()}
        role="button"
        onKeyPress={() => focus()}
        tabIndex={0}
        data-cy="editor"
      >
        <Editor
          editorState={editorState}
          onChange={setEditorState}
          spellCheck
          stripPastedStyles
          handleKeyCommand={handleKeyCommand}
          keyBindingFn={pickerOpen ? customKeyBindingFn : getDefaultKeyBinding}
          placeholder={placeholder}
          ref={editor}
        />
      </div>
    </div>
  );
};

RichTextMentionsField.propTypes = {
  classes: PropTypes.object.isRequired,
  mentionSuggestions: PropTypes.oneOfType([PropTypes.func, PropTypes.array])
    .isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  small: PropTypes.bool,
  autoFocus: PropTypes.bool,
  placeholder: PropTypes.string,
  stylingSupported: PropTypes.bool,
};

RichTextMentionsField.defaultProps = {
  value: null,
  children: null,
  small: false,
  autoFocus: false,
  placeholder: null,
  stylingSupported: false,
};

export default withStyles(styles)(RichTextMentionsField);
