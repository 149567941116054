import { useAuth0 } from "@auth0/auth0-react";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { searchProducts } from "../../actions/products";
import {
  getLabels,
  getLoggedInUser,
  getProductLastEdit,
  getProductPagination,
  getProducts,
  getReferenceDataDescription,
  isLoading,
} from "../../reducers";
import { roles } from "@certane/arcadia-web-components";
import dates from "../../util/dates";
import { toggleOffIcon, toggleOnIcon } from "../../util/icons";
import GridListing from "../common/GridListing";
import TimeAgo from "../common/TimeAgo";
import Pagination from "../common/Pagination";

const styles = () => ({
  organisationList: {
    marginBlockStart: 0,
    marginBlockEnd: 0,
  },
});

const ProductListing = ({
  classes,
  loggedInUser,
  onSelect,
  selected,
  pagination,
  lastEdit,
  fieldLabels,
  products,
  sortBy,
  filter,
  loading,
  localSearchProducts,
  updateSort,
  clearFilter,
  onChange,
  getRelationshipTypeDescription,
  getRegionDescription,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const isAdmin = loggedInUser
    ? _.intersection(roles.ADMIN_ROLES, loggedInUser.roles).length > 0
    : false;

  const getOrderBy = () => {
    const prefix = sortBy.direction === "desc" ? "-" : "";
    return `${prefix}${sortBy.field}`;
  };

  const getSearchParameters = () => ({
    ...filter,
    orderByField: getOrderBy(),
    limit: pagination.pageSize,
    offset: pagination.offset,
    excludeGroupFilters: isAdmin,
  });

  useEffect(() => {
    const searchParameters = {
      ...getSearchParameters(),
      offset: 0,
    };
    getAccessTokenSilently().then((accessToken) =>
      localSearchProducts(searchParameters, accessToken)
    );
  }, [filter, sortBy, lastEdit]);

  const handlePrevious = () => {
    const searchParameters = {
      ...getSearchParameters(),
      offset: pagination.previousOffset,
    };
    getAccessTokenSilently().then((accessToken) =>
      localSearchProducts(searchParameters, accessToken)
    );
  };

  const handleNext = () => {
    const searchParameters = {
      ...getSearchParameters(),
      offset: pagination.nextOffset,
    };
    getAccessTokenSilently().then((accessToken) =>
      localSearchProducts(searchParameters, accessToken)
    );
  };

  const ActiveIcon = toggleOnIcon();
  const InactiveIcon = toggleOffIcon();

  return (
    <>
      <GridListing
        sortedData={products}
        loading={loading}
        sortBy={sortBy}
        dense={false}
        isHighlight1={(product) => selected && selected.id === product.id}
        updateSort={(field, direction) => updateSort({ field, direction })}
        onClick={onSelect}
        clearFilter={clearFilter}
        columns={[
          {
            label: fieldLabels.labels.active,
            name: "active",
            size: 1,
            sortable: false,
            render: (product) => (
              <Tooltip
                title={product.active ? "Active" : "Inactive"}
                disableFocusListener
              >
                <IconButton
                  size="small"
                  onClick={(evt) => {
                    evt.stopPropagation();
                    evt.preventDefault();
                    return onChange(product.id, {
                      active: !product.active,
                    });
                  }}
                  disabled={!onChange}
                >
                  {product.active && <ActiveIcon color="primary" />}
                  {!product.active && <InactiveIcon />}
                </IconButton>
              </Tooltip>
            ),
          },
          {
            label: fieldLabels.labels.friendlyId,
            name: "friendlyId",
            size: 2,
            sortable: true,
            render: (product) => <>{product.friendlyId}</>,
          },
          {
            label: fieldLabels.labels.name,
            name: "name",
            size: 3,
            sortable: true,
            render: (product) => (
              <>
                <Typography variant="body1" display="block" gutterBottom>
                  {product.name}
                </Typography>
                {product.fund && (
                  <Typography variant="caption" display="block">
                    {product.fund.name}
                  </Typography>
                )}
              </>
            ),
          },
          {
            label: fieldLabels.labels.region,
            name: "region",
            size: 2,
            sortable: true,
            render: (product) => <>{getRegionDescription(product.region)}</>,
          },
          {
            label: fieldLabels.labels.relationships,
            name: "relationships",
            size: 5,
            render: (product) => (
              <ul className={classes.organisationList} data-cy="organisations">
                {product.relationships.map((relationship) => (
                  <li
                    key={`${relationship.relationshipType}-${relationship.organisation.id}`}
                    data-cy={relationship.organisation.name}
                  >
                    {relationship.organisation.name} -{" "}
                    {getRelationshipTypeDescription(
                      relationship.relationshipType
                    )}
                  </li>
                ))}
              </ul>
            ),
          },
          {
            label: fieldLabels.labels.relationshipManager,
            name: "relationshipManager",
            size: 3,
            render: (product) => (
              <span>
                {product.relationshipManager
                  ? product.relationshipManager.name
                  : "-"}
              </span>
            ),
          },
          {
            label: fieldLabels.labels.created,
            name: "created",
            size: 3,
            sortable: true,
            render: (product) => (
              <TimeAgo
                value={dates.parseTimestamp(product.created)}
                expandable
              />
            ),
          },
          {
            label: fieldLabels.labels.updated,
            name: "updated",
            size: 3,
            sortable: true,
            render: (product) => (
              <TimeAgo
                value={dates.parseTimestamp(product.updated)}
                expandable
              />
            ),
          },
        ]}
      />
      <Pagination
        pagination={pagination}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
      />
    </>
  );
};

ProductListing.propTypes = {
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  selected: PropTypes.object,
  filter: PropTypes.object.isRequired,
  sortBy: PropTypes.object.isRequired,
  updateSort: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired,
  onChange: PropTypes.func,

  // redux
  loggedInUser: PropTypes.object,
  products: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  lastEdit: PropTypes.number.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  localSearchProducts: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  getRelationshipTypeDescription: PropTypes.func.isRequired,
  getRegionDescription: PropTypes.func.isRequired,
};

ProductListing.defaultProps = {
  onSelect: null,
  selected: null,
  loggedInUser: null,
  onChange: null,
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
  loading: isLoading(state),
  products: getProducts(state),
  pagination: getProductPagination(state),
  lastEdit: getProductLastEdit(state),
  getRelationshipTypeDescription: (relationshipType) =>
    getReferenceDataDescription(state, "RelationshipType", relationshipType),
  getRegionDescription: (region) =>
    getReferenceDataDescription(state, "Region", region),
  fieldLabels: getLabels(state).Product,
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    localSearchProducts: searchProducts,
  })
)(ProductListing);
