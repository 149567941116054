import { combineReducers } from "redux";
import {
  WEBSITE_DIFFERENCE_CLEAR_ACTIVE,
  WEBSITE_DIFFERENCE_CREATE,
  WEBSITE_DIFFERENCE_GET_BY_ID,
  WEBSITE_DIFFERENCE_PATCH,
  WEBSITE_DIFFERENCE_SEARCH,
} from "../actions/websiteDifferences";
import { publishActionToast } from "../services/toasts";

const successToasts = {
  [WEBSITE_DIFFERENCE_CREATE]: "Successfully initiated difference check",
  [WEBSITE_DIFFERENCE_PATCH]: "Successfully updated",
};

const byId = (state = {}, action) => {
  publishActionToast(action, "WEBSITE_DIFFERENCE_", successToasts);

  switch (action.type) {
    case `${WEBSITE_DIFFERENCE_GET_BY_ID}_SUCCESS`:
    case `${WEBSITE_DIFFERENCE_CREATE}_SUCCESS`:
    case `${WEBSITE_DIFFERENCE_SEARCH}_SUCCESS`:
    case `${WEBSITE_DIFFERENCE_PATCH}_SUCCESS`:
      return {
        ...state,
        ...action.response.entities.websiteDifference,
      };

    default:
      break;
  }

  return state;
};

const defaultActive = {
  activeList: [],
  activeDifference: null,
  pagination: {
    offset: 0,
    pageSize: 50,
    resultCount: 0,
  },
};

const active = (state = defaultActive, action) => {
  switch (action.type) {
    case WEBSITE_DIFFERENCE_CLEAR_ACTIVE:
      return {
        ...defaultActive,
      };

    case `${WEBSITE_DIFFERENCE_SEARCH}_SUCCESS`:
      return {
        ...state,
        activeList: [...action.response.result],
        pagination: {
          ...action.response.pagination,
          pageSize: defaultActive.pagination.pageSize,
        },
      };

    case `${WEBSITE_DIFFERENCE_GET_BY_ID}_INPROGRESS`:
      return {
        ...state,
        activeDifference: null,
      };

    case `${WEBSITE_DIFFERENCE_GET_BY_ID}_SUCCESS`:
    case `${WEBSITE_DIFFERENCE_CREATE}_SUCCESS`:
      return {
        ...state,
        activeList:
          state.activeList.indexOf(action.response.result) === -1
            ? [...state.activeList, action.response.result]
            : state.activeList,
        activeDifference: action.response.result,
      };

    default:
      break;
  }

  return state;
};

export default combineReducers({
  active,
  byId,
});

export const getWebsiteDifferences = (state) =>
  state.active.activeList.map((id) => state.byId[id]);
export const getActiveWebsiteDifference = (state) =>
  state.active.activeDifference
    ? state.byId[state.active.activeDifference]
    : null;
export const getPagination = (state) => state.active.pagination;
