import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";
import PropTypes from "prop-types";
import WorkItemComponentFields from "../components/WorkItemComponentFields";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  item: {
    paddingLeft: "0",
    paddingTop: "0",
    paddingBottom: "0",
  },
  child: {
    border: `solid 1px ${theme.palette.grey[300]}`,
  },
  childForm: {
    marginRight: "20px",
  },
}));

const WorkItemChildList = ({
  fields,
  addLabel,
  childDefinition,
  change,
  untouch,
  formName,
  parentType,
  template,
}) => {
  const classes = useStyles();

  const add = () => {
    fields.push({ type: childDefinition.id });
  };

  const remove = (index) => {
    fields.remove(index);
  };

  return (
    <>
      {fields.map((child, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <List key={index} className={classes.root}>
          <ListItem className={classes.child}>
            <ListItemText className={classes.childForm}>
              <WorkItemComponentFields
                prefix={child}
                parentType={parentType}
                workItemDefinition={childDefinition}
                change={change}
                untouch={untouch}
                formName={formName}
                formType="CREATE"
                template={template}
              />
            </ListItemText>
            <ListItemSecondaryAction>
              <IconButton
                aria-label="Remove"
                onClick={() => remove(index)}
                title="Remove"
                data-cy="remove"
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      ))}
      <div>
        <Button
          size="small"
          color="primary"
          aria-label="Add"
          onClick={add}
          data-cy="add"
        >
          {addLabel}
        </Button>
      </div>
    </>
  );
};

WorkItemChildList.propTypes = {
  addLabel: PropTypes.string.isRequired,
  fields: PropTypes.object.isRequired,
  childDefinition: PropTypes.object.isRequired,
  formName: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  parentType: PropTypes.string.isRequired,
  template: PropTypes.bool.isRequired,
};

export default WorkItemChildList;
