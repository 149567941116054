import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import {
  getReferenceDataDescription,
  getReferenceDataType,
} from "../../../../reducers";
import formatters from "../../../../util/formatters";
import CardSection from "../../../common/CardSection";
import FormCard from "../../../common/FormCard";
import Scorecard from "../../../common/Scorecard";
import SimpleChips from "../../../common/SimpleChips";
import ContactDetailsReadOnly from "../contacts/ContactDetailsReadOnly";
import EditWorkItemComponentForm, {
  EDIT_WORK_ITEM_COMPONENT_FORM_NAME,
} from "../EditWorkItemComponentForm";
import ThirdPartyAuthorityCard from "./thirdpartyauthority/ThirdPartyAuthorityCard";
import ComponentScorecard from "../../../common/ComponentScorecard";
import WarningText from "../../../WarningText";
import OriginFieldsReadOnly from "../incident/OriginFieldsReadOnly";

const ComplaintCard = ({
  className,
  workItem,
  customisations,
  fieldLabels,
  onChange,
  remediationTypes,
  getRefDataDescription,
}) => {
  const { complaint } = workItem;

  const includeThirdPartyAuthority = !!workItem.complaint?.thirdPartyAuthority;
  const lodgedBy = includeThirdPartyAuthority ? "Third party" : "Complainant";

  const remediationTypeIsMonetary =
    complaint?.remediationType &&
    remediationTypes.values[complaint.remediationType].props.monetary;
  const remediationTypeRequiresExplanation =
    complaint?.remediationType &&
    remediationTypes.values[complaint.remediationType].props
      .requiresExplanation;

  return (
    <>
      <FormCard
        className={className}
        title={fieldLabels.labels.complaint}
        formTitle={`Edit ${fieldLabels.labels.complaint}`}
        readOnlyView={() => (
          <>
            <CardSection
              title={fieldLabels.labels.complaint}
              gutterBottom
              data-cy="complaintDetails"
            >
              <ComponentScorecard
                fieldLabels={fieldLabels}
                name="complaint.dateReceived"
                customisations={customisations}
                entity={workItem}
                render={(value) => formatters.date(value)}
                defaultDisplay="-"
              />
              <ComponentScorecard
                fieldLabels={fieldLabels}
                name="complaint.complaintReceivedBy"
                customisations={customisations}
                entity={workItem}
                render={(value) => (
                  <SimpleChips
                    values={value.map((org) => org.name)}
                    blankLabel="-"
                  />
                )}
                defaultDisplay="-"
              />
              <ComponentScorecard
                fieldLabels={fieldLabels}
                name="complaint.complaintFormat"
                customisations={customisations}
                entity={workItem}
                render={(value) =>
                  getRefDataDescription(value, "CommunicationFormat")
                }
                defaultDisplay="-"
              />
              <Scorecard label="Complaint lodged by">{lodgedBy}</Scorecard>
              <ComponentScorecard
                fieldLabels={fieldLabels}
                name="complaint.complaintProductService"
                customisations={customisations}
                entity={workItem}
                render={(value) => (
                  <SimpleChips
                    blankLabel="-"
                    values={value.map((type) =>
                      getRefDataDescription(type, "ComplaintProductService")
                    )}
                  />
                )}
                defaultDisplay="-"
              />
              <ComponentScorecard
                fieldLabels={fieldLabels}
                name="complaint.complaintIssue"
                customisations={customisations}
                entity={workItem}
                render={(value) => (
                  <SimpleChips
                    blankLabel="-"
                    values={value.map((type) =>
                      getRefDataDescription(type, "ComplaintIssue")
                    )}
                  />
                )}
                defaultDisplay="-"
              />
              <ComponentScorecard
                fieldLabels={fieldLabels}
                name="complaint.complaintDetails"
                customisations={customisations}
                entity={workItem}
                render={(value) => value}
                defaultDisplay="-"
              />
              <ComponentScorecard
                fieldLabels={fieldLabels}
                name="complaint.complaintCause"
                customisations={customisations}
                entity={workItem}
                render={(value) =>
                  getRefDataDescription(value, "IncidentCause")
                }
                defaultDisplay="-"
              />
              <ComponentScorecard
                fieldLabels={fieldLabels}
                name="complaint.aboutRepresentative"
                customisations={customisations}
                entity={workItem}
                render={(value) => formatters.displayBoolean(value)}
                defaultDisplay="Unknown"
              />
              <ComponentScorecard
                fieldLabels={fieldLabels}
                name="complaint.systemicIssue"
                customisations={customisations}
                entity={workItem}
                render={(value) => (
                  <>
                    {formatters.displayBoolean(value)}
                    {!!value && (
                      <WarningText text="Ensure that an incident is created and/or linked to this systemic complaint." />
                    )}
                  </>
                )}
                defaultDisplay="-"
              />
            </CardSection>
            {includeThirdPartyAuthority && (
              <CardSection
                title={
                  fieldLabels.nestedTypes.complaint.labels.thirdPartyAuthority
                }
                gutterBottom
                data-cy="thirdPartyAuthority"
              >
                <ContactDetailsReadOnly
                  contact={complaint.thirdPartyAuthority}
                />
                <ComponentScorecard
                  fieldLabels={fieldLabels}
                  name="complaint.thirdPartyAuthority.onFile"
                  customisations={customisations}
                  entity={workItem}
                  render={(value) => formatters.displayBoolean(value)}
                  defaultDisplay="-"
                />
                <ThirdPartyAuthorityCard
                  workItem={workItem}
                  fieldLabels={fieldLabels}
                />
              </CardSection>
            )}
            <CardSection
              title={fieldLabels.nestedTypes.complaint.labels.origin}
              gutterBottom
              data-cy={fieldLabels.nestedTypes.complaint.labels.origin}
            >
              <OriginFieldsReadOnly
                workItem={workItem}
                customisations={customisations}
                fieldLabels={fieldLabels}
                name="complaint.origin"
              />
            </CardSection>
            <CardSection
              title="Remediation"
              gutterBottom
              data-cy="compensation"
            >
              <ComponentScorecard
                fieldLabels={fieldLabels}
                name="complaint.remediationType"
                customisations={customisations}
                entity={workItem}
                render={(value) =>
                  getRefDataDescription(value, "RemediationType")
                }
                defaultDisplay="-"
              />
              {(remediationTypeIsMonetary ||
                (complaint && complaint.compensationAmount)) && (
                <>
                  <ComponentScorecard
                    fieldLabels={fieldLabels}
                    name="complaint.compensationAmount"
                    customisations={customisations}
                    entity={workItem}
                    render={(value) => formatters.currency(value)}
                    defaultDisplay="-"
                  />
                  <ComponentScorecard
                    fieldLabels={fieldLabels}
                    name="complaint.compensationPaidBy"
                    customisations={customisations}
                    entity={workItem}
                    render={(value) => value}
                    defaultDisplay="-"
                  />
                </>
              )}
              {remediationTypeRequiresExplanation && (
                <ComponentScorecard
                  fieldLabels={fieldLabels}
                  name="complaint.remediationDetails"
                  customisations={customisations}
                  entity={workItem}
                  render={(value) => value}
                  defaultDisplay="-"
                />
              )}
            </CardSection>
            <CardSection title="Advisor" gutterBottom data-cy="advisor">
              {complaint && complaint.adviser ? (
                <ContactDetailsReadOnly contact={complaint.adviser} />
              ) : (
                "-"
              )}
            </CardSection>

            <CardSection
              title={fieldLabels.nestedTypes.complaint.labels.idrDetails}
              gutterBottom
              data-cy="idrDetails"
            >
              <ComponentScorecard
                fieldLabels={fieldLabels}
                name="complaint.idrDetails.acknowledged"
                customisations={customisations}
                entity={workItem}
                render={(value) => formatters.displayBoolean(value)}
                defaultDisplay="-"
              />
              {complaint &&
                complaint.idrDetails &&
                complaint.idrDetails.acknowledged && (
                  <>
                    <ComponentScorecard
                      fieldLabels={fieldLabels}
                      name="complaint.idrDetails.acknowledgementDate"
                      customisations={customisations}
                      entity={workItem}
                      render={(value) => formatters.date(value)}
                      defaultDisplay="-"
                    />
                    <ComponentScorecard
                      fieldLabels={fieldLabels}
                      name="complaint.idrDetails.acknowledgementFormat"
                      customisations={customisations}
                      entity={workItem}
                      render={(value) =>
                        getRefDataDescription(value, "CommunicationFormat")
                      }
                      defaultDisplay="-"
                    />
                  </>
                )}
              <ComponentScorecard
                fieldLabels={fieldLabels}
                name="complaint.idrDetails.dateFinalIDRResponseIssued"
                customisations={customisations}
                entity={workItem}
                render={(value) => formatters.date(value)}
                defaultDisplay="-"
              />
            </CardSection>
            <CardSection
              title={fieldLabels.nestedTypes.complaint.labels.edrDetails}
              gutterBottom
              data-cy="externalDisputeResolution"
            >
              <Scorecard label={"Is there an EDR in progress?"}>
                {complaint?.edrDetails ? "Yes" : "No"}
              </Scorecard>
              {complaint && complaint.edrDetails && (
                <>
                  <ComponentScorecard
                    fieldLabels={fieldLabels}
                    name="complaint.edrDetails.referenceNumber"
                    customisations={customisations}
                    entity={workItem}
                    render={(value) => value}
                    defaultDisplay="-"
                  />
                  <ComponentScorecard
                    fieldLabels={fieldLabels}
                    name="complaint.edrDetails.originatedAsEDR"
                    customisations={customisations}
                    entity={workItem}
                    render={(value) => value}
                    defaultDisplay="-"
                  />
                  <ComponentScorecard
                    fieldLabels={fieldLabels}
                    name="complaint.edrDetails.dateRaised"
                    customisations={customisations}
                    entity={workItem}
                    render={(value) => formatters.date(value)}
                    defaultDisplay="-"
                  />
                  <ComponentScorecard
                    fieldLabels={fieldLabels}
                    name="complaint.edrDetails.dueDate"
                    customisations={customisations}
                    entity={workItem}
                    render={(value) => formatters.date(value)}
                    defaultDisplay="-"
                  />
                </>
              )}
            </CardSection>
            <CardSection
              title={fieldLabels.nestedTypes.complaint.labels.exemption}
              gutterBottom
              data-cy="exemption"
            >
              <Scorecard label={"Has exemption occurred?"}>
                {complaint?.exemption ? "Yes" : "No"}
              </Scorecard>
              {complaint && complaint.exemption && (
                <>
                  <ComponentScorecard
                    fieldLabels={fieldLabels}
                    name="complaint.exemption.exemptionType"
                    customisations={customisations}
                    entity={workItem}
                    render={(value) =>
                      getRefDataDescription(value, "ExemptionType")
                    }
                    defaultDisplay="-"
                  />
                  <ComponentScorecard
                    fieldLabels={fieldLabels}
                    name="complaint.exemption.exemptionDescription"
                    customisations={customisations}
                    entity={workItem}
                    render={(value) => value}
                    defaultDisplay="-"
                  />
                  <ComponentScorecard
                    fieldLabels={fieldLabels}
                    name="complaint.exemption.delayedIDRDate"
                    customisations={customisations}
                    entity={workItem}
                    render={(value) => formatters.date(value)}
                    defaultDisplay="-"
                  />
                </>
              )}
            </CardSection>
          </>
        )}
        formComponent={EditWorkItemComponentForm}
        formName={EDIT_WORK_ITEM_COMPONENT_FORM_NAME}
        onSave={onChange}
        initialValues={{
          complaint: workItem.complaint,
          memberDetails: workItem.memberDetails,
        }}
        formProps={{
          maxWidth: "md",
          workItem,
          componentName: "COMPLAINT",
        }}
      />
    </>
  );
};

ComplaintCard.propTypes = {
  className: PropTypes.string,
  fieldLabels: PropTypes.object.isRequired,
  workItem: PropTypes.object.isRequired,
  customisations: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  remediationTypes: PropTypes.object.isRequired,
  getRefDataDescription: PropTypes.func.isRequired,
};

ComplaintCard.defaultProps = {
  className: undefined,
  onChange: null,
};

const mapStateToProps = (state) => ({
  remediationTypes: getReferenceDataType(state, "RemediationType"),
  getRefDataDescription: (id, type) =>
    getReferenceDataDescription(state, type, id, "-"),
});

export default connect(mapStateToProps)(ComplaintCard);
