import { useAuth0 } from "@auth0/auth0-react";
import { JSONAPI } from "@certane/arcadia-web-components";
import { Box } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import React, { useState } from "react";
import { RiskDto } from "../../../../model/Risk";
import riskBulkUpdateApi, {
  RiskBulkUpdateRequest,
} from "../../../../services/api/bulk-updates/riskBulkUpdates";
import {
  publishToastError,
  publishToastSuccess,
} from "../../../../services/toasts";
import RiskUserBulkUpdateForm, {
  RiskBulkUpdateFormValues,
} from "./RiskBulkUpdateForm";
import RiskBulkUpdateResults from "./RiskBulkUpdateResults";

const RiskBulkUpdate: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] =
    useState<RiskBulkUpdateFormValues | null>();
  const [response, setResponse] = useState<JSONAPI<RiskDto[]>>();

  const handleSubmit = async (values: RiskBulkUpdateFormValues) => {
    try {
      setLoading(true);

      const accessToken = await getAccessTokenSilently();

      const request: RiskBulkUpdateRequest = {
        riskSearchParameters: {
          ownerIds: [values.oldOwnerId],
          limit: values.limit,
        },
        userId: values.newOwnerId,
        dryRun: values.dryRun,
      };

      const response = await riskBulkUpdateApi.updateRiskUsers(
        request,
        accessToken
      );
      console.log("response", response);
      setFormValues(values);
      setResponse(response);

      if (!values.dryRun && response.data) {
        publishToastSuccess(
          `${response.data.length} risks updated successfully`
        );
      }
    } catch (e) {
      publishToastError(e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <RiskUserBulkUpdateForm onSubmit={handleSubmit} />
      {!loading && response && response.data && (
        <Box mt={2}>
          {formValues?.dryRun && (
            <Alert severity={"info"}>
              <strong>{response.data.length}</strong> of{" "}
              <strong>{response.meta.resultCount}</strong> work items will be
              updated.
            </Alert>
          )}
          <RiskBulkUpdateResults risks={response.data} />
        </Box>
      )}
    </>
  );
};

export default RiskBulkUpdate;
