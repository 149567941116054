import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { getReferenceDataType } from "../../../../reducers";
import ComponentField from "../../../forms/wrapper/ComponentField";
import WrappedSelectChips from "../../../forms/wrapper/WrappedSelectChips";

const CorrespondenceDetailsFields = ({
  fieldLabels,
  regulators,
  customisations,
  change,
  untouch,
  formName,
}) => (
  <ComponentField
    name="correspondence.regulators"
    component={WrappedSelectChips}
    options={regulators.ids.filter((id) => !!regulators.values[id].selectable)}
    label={fieldLabels.nestedTypes.correspondence.labels.regulators}
    fullWidth
    toOption={(id) => ({
      label: regulators.values[id].description,
      value: id,
    })}
    fromOption={(option) => option.value}
    customisations={customisations}
    change={change}
    untouch={untouch}
    formName={formName}
  />
);

CorrespondenceDetailsFields.propTypes = {
  customisations: PropTypes.array,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  regulators: PropTypes.object.isRequired,
};

CorrespondenceDetailsFields.defaultProps = {
  customisations: [],
};

const mapStateToProps = (state) => ({
  regulators: getReferenceDataType(state, "Regulator"),
});

export default connect(mapStateToProps)(CorrespondenceDetailsFields);
