import { Step, StepButton, Stepper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getReferenceDataType } from "../../reducers";
import { roles } from "@certane/arcadia-web-components";
import logger from "../../util/logger";

const useStyles = makeStyles(() => ({
  root: {
    minWidth: "100%",
    padding: 0,
  },
}));

const getActiveStep = (control, lifecycleStatuses) => {
  let activeStep = 0;
  lifecycleStatuses.ids.forEach((id, index) => {
    if (control.status === id) {
      activeStep = index;
    }
  });
  return activeStep;
};

const ControlLifecycle = ({
  loggedInUser,
  control,
  onChange,
  lifecycleStatuses,
}) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(
    getActiveStep(control, lifecycleStatuses)
  );
  const [canArchive] = useState(
    _.intersection(
      [...roles.ADMIN_ROLES, roles.RISK_ADMIN, roles.COMPLIANCE_OWNER],
      loggedInUser.roles
    ).length > 0 ||
      (control.owner && loggedInUser.id === control.owner.id)
  );

  useEffect(() => {
    setActiveStep(getActiveStep(control, lifecycleStatuses));
  }, [control]);

  const handleStatusChange = (status) => {
    return onChange({ status });
  };

  const steps = lifecycleStatuses.ids.map((id) => lifecycleStatuses.values[id]);
  logger.debug("steps", steps);
  logger.debug("control.status", control.status);
  logger.debug("activeStep", activeStep);
  return (
    <Stepper className={classes.root} alternativeLabel nonLinear data-cy="step">
      {steps.map((step, index) => {
        return (
          <Step
            key={step.id}
            completed={index < activeStep}
            active={index === activeStep}
          >
            <StepButton
              onClick={() => handleStatusChange(step.id)}
              disabled={step.id === "ARCHIVED" && !canArchive}
            >
              {step.description}
            </StepButton>
          </Step>
        );
      })}
    </Stepper>
  );
};

ControlLifecycle.propTypes = {
  loggedInUser: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  lifecycleStatuses: PropTypes.object.isRequired,
};

ControlLifecycle.defaultProps = {
  onChange: null,
};

const mapStateToProps = (state) => ({
  lifecycleStatuses: getReferenceDataType(state, "LifecycleStatus"),
});

export default connect(mapStateToProps)(ControlLifecycle);
