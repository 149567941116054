import { useAuth0 } from "@auth0/auth0-react";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import organisationsApi from "../../services/api/organisations";
import Avatar from "../common/Avatar.tsx";
import ChoiceChips from "../common/ChoiceChips";
import ClearButton from "../common/ClearButton";
import FiltersBar from "../common/FiltersBar";
import MultiSelectListPicker from "../common/MultiSelectListPicker";

const useStyles = makeStyles((theme) => ({
  selectWidgets: {
    marginTop: theme.spacing(1),
  },
  clearButton: {
    padding: theme.spacing(0.5),
    position: "absolute",
    right: 0,
    top: `-${theme.spacing(2)}px`,
  },
  label: {
    lineHeight: "1.5rem",
  },
}));

const equalSets = (set1, set2) => _.isEqual(_.orderBy(set1), _.orderBy(set2));

const DigitalAssetFilter = ({ filter, updateFilter }) => {
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();
  const [selectedOrganisations, setSelectedOrganisations] = useState([]);

  useEffect(() => {
    if (filter.organisations.length === 0) {
      setSelectedOrganisations([]);
    } else if (
      !equalSets(
        selectedOrganisations.map((o) => o.friendlyId),
        filter.organisations
      )
    ) {
      getAccessTokenSilently().then((accessToken) =>
        organisationsApi
          .search(
            {
              limit: filter.organisations.length,
              offset: 0,
              friendlyIds: filter.organisations,
            },
            null,
            accessToken
          )
          .then((response) => setSelectedOrganisations(response.results))
      );
    }
  }, [filter]);

  const asyncOrganisationFetch = async (
    pickerFilter,
    pagination,
    abortController
  ) => {
    const accessToken = await getAccessTokenSilently();
    const searchParameters = {
      ...pickerFilter,
      limit: pagination.pageSize,
      offset: pagination.offset,
      orderByField: "name",
    };
    return organisationsApi.search(
      searchParameters,
      abortController,
      accessToken
    );
  };

  const getOrganisationsLabel = () => {
    if (selectedOrganisations.length > 1) {
      return `${selectedOrganisations.length} selected`;
    }
    return selectedOrganisations.map((org) => org.name).join(", ");
  };

  const setOrganisations = (organisations) => {
    setSelectedOrganisations(organisations);
    updateFilter(
      "organisations",
      organisations.map((org) => org.friendlyId)
    );
  };

  const activeOptions = [{ label: "Active only", value: true }];

  return (
    <FiltersBar fullWidth={false}>
      <>
        <InputLabel className={classes.label}>Organisations</InputLabel>
        {selectedOrganisations.length > 0 && (
          <ClearButton
            className={classes.clearButton}
            onClear={() => setOrganisations([])}
            data-cy="clear"
          />
        )}
        <div className={classes.selectWidgets}>
          <MultiSelectListPicker
            title="Select organisations"
            actionText="Select"
            label={getOrganisationsLabel()}
            onSubmit={setOrganisations}
            datasource={asyncOrganisationFetch}
            selected={selectedOrganisations}
            isMulti
            clearable
            toOption={(org) => ({ label: org.name, id: org.id, org })}
            fromOption={(option) => option.org}
            renderIcon={(org, size) => (
              <Avatar
                name={(org.name || "Unknown").charAt(0)}
                size={size}
                round
              />
            )}
          />
        </div>
      </>
      <>
        <InputLabel className={classes.label}>Active</InputLabel>
        <ChoiceChips
          value={filter.active}
          options={activeOptions}
          onChange={(value) => updateFilter("active", value)}
        />
      </>
    </FiltersBar>
  );
};

DigitalAssetFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  updateFilter: PropTypes.func.isRequired,
};

export default DigitalAssetFilter;
