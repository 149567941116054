import PropTypes from "prop-types";
import React, { Component } from "react";
import { Field } from "redux-form";
import { required } from "redux-form-validators";
import { yesNoOptions } from "../../../../util/radioOptions";
import WrappedDatePicker from "../../../forms/wrapper/WrappedDatePicker";
import WrappedRadioGroup from "../../../forms/wrapper/WrappedRadioGroup";
import WrappedTextField from "../../../forms/wrapper/WrappedTextField";

class EDRDetailsFields extends Component {
  render() {
    const { fieldLabels, name } = this.props;

    return (
      <>
        <Field
          name={`${name}.referenceNumber`}
          component={WrappedTextField}
          label={fieldLabels.labels.referenceNumber}
          fullWidth
          required
          validate={required({
            msg: `${fieldLabels.labels.referenceNumber} is required`,
          })}
        />
        <Field
          component={WrappedRadioGroup}
          name={`${name}.originatedAsEDR`}
          label={fieldLabels.labels.originatedAsEDR}
          options={yesNoOptions}
          row
        />
        <Field
          name={`${name}.dateRaised`}
          component={WrappedDatePicker}
          label={fieldLabels.labels.dateRaised}
          validate={required({
            msg: `${fieldLabels.labels.dateRaised} is required`,
          })}
          required
          fullWidth
        />
        <Field
          name={`${name}.dueDate`}
          component={WrappedDatePicker}
          label={fieldLabels.labels.dueDate}
          fullWidth
        />
      </>
    );
  }
}

EDRDetailsFields.propTypes = {
  fieldLabels: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};

EDRDetailsFields.defaultProps = {};

export default EDRDetailsFields;
