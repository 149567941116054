import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {},
  fixed: {
    margin: "0 auto",
    [theme.breakpoints.up("xs")]: {
      width: "auto",
      maxWidth: "100%",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.up("sm")]: {
      width: "auto",
      maxWidth: 568,
      padding: 0,
    },
    [theme.breakpoints.up("md")]: {
      width: "auto",
      maxWidth: 912,
      padding: 0,
    },
    [theme.breakpoints.up("lg")]: {
      width: "auto",
      maxWidth: 1200,
      padding: 0,
    },
    [theme.breakpoints.up("xl")]: {
      width: "auto",
      maxWidth: 1400,
      padding: 0,
    },
  },
  narrow: {
    margin: "0 auto",
    [theme.breakpoints.up("xs")]: {
      width: "auto",
      maxWidth: "100%",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.up("sm")]: {
      width: "auto",
      maxWidth: 568,
      padding: 0,
    },
    [theme.breakpoints.up("md")]: {
      width: "auto",
      maxWidth: 650,
      padding: 0,
    },
  },
  fluid: {
    width: "100%",
    marginRight: "auto",
    marginLeft: "auto",
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
  },
});

function Container(props) {
  const { children, classes, className: classNameProp, fluid, narrow } = props;

  const className = classNames(
    {
      [classes.root]: true,
      [classes.fixed]: !fluid && !narrow,
      [classes.fluid]: fluid,
      [classes.narrow]: narrow,
    },
    classNameProp
  );

  return <div className={className}>{children}</div>;
}

Container.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  fluid: PropTypes.bool,
  narrow: PropTypes.bool,
};

Container.defaultProps = {
  children: null,
  classes: {},
  className: null,
  fluid: false,
  narrow: false,
};

export default withStyles(styles)(Container);
