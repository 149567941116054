import { useAuth0 } from "@auth0/auth0-react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { SubmissionError } from "redux-form";
import AlertDialog from "../../../components/common/AlertDialog";
import Container from "../../../components/common/Container";
import HeaderBar from "../../../components/common/HeaderBar";
import PageHeading from "../../../components/common/PageHeading";
import BulkImportForm, {
  BULK_IMPORT_FORM_NAME,
} from "../../../components/forms/bulkImport/BulkImportForm";
import FormWrapper from "../../../components/forms/FormWrapper";
import workItemsApi from "../../../services/api/workItems";
import { uploadIcon } from "../../../util/icons";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  contents: {
    marginBottom: "75px",
  },
  card: {
    marginTop: theme.spacing(2),
  },
});

const Index = ({ classes }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [result, setResult] = useState(null);
  const [stagedValues, setStagedValues] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = async (values) => {
    const accessToken = await getAccessTokenSilently();
    const { persist } = values;
    const payload = {
      ...values,
      persist: false,
    };

    return workItemsApi
      .bulkImport(payload, accessToken)
      .then((validation) => {
        if (persist && validation.valid) {
          setStagedValues(values);
        } else {
          setResult(validation);
        }
      })
      .catch((error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  const onConfirmSave = async () => {
    setSubmitting(true);
    const accessToken = await getAccessTokenSilently();
    workItemsApi
      .bulkImport(stagedValues, accessToken)
      .then((validation) => {
        setResult(validation);
      })
      .finally(() => {
        setStagedValues(null);
        setSubmitting(false);
      });
  };

  return (
    <div className={classes.root}>
      <AlertDialog
        title="Save the data?"
        body="No validation issues found, do you wish to continue and save the data?"
        submitButtonText="Confirm"
        open={!!stagedValues}
        onCancel={() => setStagedValues(null)}
        onSubmit={onConfirmSave}
        submitEnabled={!submitting}
      />
      <HeaderBar>
        <PageHeading icon={uploadIcon()} heading="Bulk import" />
      </HeaderBar>
      <Container className={classes.contents}>
        <Card className={classes.card}>
          <CardHeader title="Bulk import tool" />
          <CardContent>
            {!result && (
              <FormWrapper
                submitButtonText="Upload"
                formComponent={BulkImportForm}
                formName={BULK_IMPORT_FORM_NAME}
                onSubmit={onSubmit}
                isAltPaper
                initialValues={{ persist: false }}
              />
            )}
            {result && (
              <>
                {result.valid && (
                  <Typography variant="h6" gutterBottom>
                    Submitted files successfully
                  </Typography>
                )}
                {!result.valid && (
                  <>
                    <Typography variant="h6" gutterBottom>
                      Validation errors
                    </Typography>
                    <dl>
                      {Object.keys(result.errors).map((key) => (
                        <Fragment key={key}>
                          <dt>{key}</dt>
                          <dd>{result.errors[key]}</dd>
                        </Fragment>
                      ))}
                    </dl>
                  </>
                )}
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => setResult(null)}
                >
                  Upload more files
                </Button>
              </>
            )}
          </CardContent>
        </Card>
      </Container>
    </div>
  );
};

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = () => ({});

export default compose(withStyles(styles), connect(mapStateToProps))(Index);
