import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import withStyles from "@material-ui/core/styles/withStyles";
import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import ContactDetailsFields from "./ContactDetailsFields";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  contact: {
    border: `solid 1px ${theme.palette.grey[300]}`,
  },
  contactForm: {
    marginRight: "20px",
  },
});

class ContactsListField extends Component {
  add = () => {
    this.props.fields.push({});
  };

  remove = (index) => {
    this.props.fields.remove(index);
  };

  render() {
    const { fields, fieldLabels, classes } = this.props;

    return (
      <>
        {fields.map((contact, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <List key={index} className={classes.root}>
            <ListItem className={classes.contact}>
              <ListItemText className={classes.contactForm}>
                <ContactDetailsFields
                  name={`${contact}`}
                  withType
                  contactLabels={fieldLabels.nestedTypes.contacts.labels}
                />
              </ListItemText>
              <ListItemSecondaryAction>
                <IconButton
                  aria-label="Remove"
                  onClick={() => this.remove(index)}
                  title="Remove"
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        ))}
        <Button
          size="small"
          color="primary"
          aria-label="Add"
          onClick={this.add}
        >
          Add contact
        </Button>
      </>
    );
  }
}

ContactsListField.propTypes = {
  classes: PropTypes.object.isRequired,
  fieldLabels: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
};

ContactsListField.defaultProps = {};

const mapStateToProps = () => ({});

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(ContactsListField);
