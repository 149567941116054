import Grid from "@material-ui/core/Grid";
import React from "react";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import { required } from "redux-form-validators";
import WrappedDatePicker from "../../../forms/wrapper/WrappedDatePicker";
import WrappedTextField from "../../../forms/wrapper/WrappedTextField";

const SetReminderForm = () => (
  <form autoComplete="off">
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Field
          name="date"
          component={WrappedDatePicker}
          label="Reminder date"
          fullWidth
          validate={required({ msg: "Value is required" })}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="note"
          component={WrappedTextField}
          placeholder="Enter notes to remind you of the action to take"
          label="Note"
          required
          validate={required({ msg: "Value is required" })}
          fullWidth
          multiline
          rowsMax={10}
          rows={2}
        />
      </Grid>
    </Grid>
  </form>
);

export const SET_REMINDER_FORM_NAME = "setReminderForm";

const form = reduxForm({ form: SET_REMINDER_FORM_NAME });

export default compose(form)(SetReminderForm);
