import { requestJSON } from "./http";

const decorateSearchParameters = (filter) =>
  filter ? { __type: "RiskControl", ...filter } : { __type: "RiskControl" };

const search = (filter, abortController, accessToken) =>
  requestJSON({
    path: "/risk/controls/search",
    method: "POST",
    data: decorateSearchParameters(filter),
    abortController,
    accessToken,
  });

const count = (filter, accessToken) =>
  requestJSON({
    path: "/risk/controls/count",
    method: "POST",
    data: decorateSearchParameters(filter),
    accessToken,
  });

const create = (riskControl, accessToken) =>
  requestJSON({
    path: "/risk/controls",
    method: "POST",
    data: riskControl,
    accessToken,
  });

const load = (riskControlId, accessToken) =>
  requestJSON({
    path: `/risk/controls/${riskControlId}`,
    accessToken,
  });

const patch = (riskControlId, changes, description, accessToken) =>
  requestJSON({
    path: `/risk/controls/${riskControlId}`,
    method: "PATCH",
    data: {
      fields: Object.keys(changes),
      changes,
      description,
    },
    accessToken,
  });

const remove = (riskControlId, accessToken) =>
  requestJSON({
    path: `/risk/controls/${riskControlId}`,
    method: "DELETE",
    accessToken,
  });

const getAssociatedRisks = (riskControlId, accessToken) =>
  requestJSON({
    path: `/risk/controls/${riskControlId}/associatedRisks`,
    method: "GET",
    accessToken,
  });

const addAssociatedRisks = (riskControlId, riskIds, accessToken) =>
  requestJSON({
    path: `/risk/controls/${riskControlId}/associatedRisks`,
    method: "POST",
    data: riskIds,
    accessToken,
  });

export default {
  search,
  count,
  create,
  load,
  patch,
  remove,
  getAssociatedRisks,
  addAssociatedRisks,
};
