import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import _ from "lodash";
import PropTypes from "prop-types";
import CollapseExpand from "../../../CollapseExpand";
import RichTextMentionsView from "../../../richtext/RichTextMentionsView";

const styles = (theme) => ({
  box: {
    marginTop: theme.spacing(1),
  },
  mentions: {
    color: theme.palette.swatch.secondary,
  },
  text: {
    color: theme.palette.swatch.primary,
    whiteSpace: "pre-wrap",
  },
});

const isInitialComment = (entry) =>
  _.has(entry, "comment") && _.get(entry.comment, "type") === "INITIAL";

const useInitialComment = (entry) => {
  const [hasInitialComment, setHasInitialComment] = useState(false);
  const [initialComment, setInitialComment] = useState(null);

  useEffect(() => {
    if (isInitialComment(entry)) {
      setHasInitialComment(true);
      setInitialComment(entry.comment.draft);
    }
  }, [entry]);

  return [hasInitialComment, initialComment];
};

const InitialComment = ({ classes, entry }) => {
  const [hasInitialComment, initialComment] = useInitialComment(entry);

  return (
    <>
      {hasInitialComment && (
        <div className={classes.box}>
          <Typography component="div" variant="body1" className={classes.text}>
            <CollapseExpand isNewUI>
              <RichTextMentionsView value={initialComment} />
            </CollapseExpand>
          </Typography>
        </div>
      )}
    </>
  );
};

InitialComment.propTypes = {
  classes: PropTypes.object.isRequired,
  entry: PropTypes.object.isRequired,
};

export default withStyles(styles)(InitialComment);
