import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";
import { getReferenceDataType } from "../../../reducers";
import HelpPopover from "../../common/HelpPopover";
import { getRefDataMenuItems } from "../menuItems";
import WrappedSelect from "../wrapper/WrappedSelect";

const useStyles = makeStyles((theme) => ({
  helpPopover: {
    padding: theme.spacing(2),
    maxWidth: theme.breakpoints.values.md,
  },
}));

const OperationalFrequencyField = ({
  fieldLabels,
  operationalFrequencyTypes,
}) => {
  const classes = useStyles();
  return (
    <Field
      name="operationalFrequency"
      component={WrappedSelect}
      label={fieldLabels.labels.operationalFrequency}
      fullWidth
      helperText={
        <>
          <span>Get help with </span>
          <HelpPopover
            linkText={fieldLabels.labels.operationalFrequency.toLowerCase()}
          >
            <div className={classes.helpPopover}>
              <Typography variant="h5" gutterBottom>
                {fieldLabels.labels.operationalFrequency}
              </Typography>
              <Typography variant="body1">
                How often the control is performed eg. Daily, Weekly, Monthly,
                Quarterly, Half-yearly, Yearly etc.
              </Typography>
              <Typography variant="body1">
                If the control is performed ad-hoc, an estimate or approximate
                frequency should be used.
              </Typography>
            </div>
          </HelpPopover>
        </>
      }
    >
      {getRefDataMenuItems(operationalFrequencyTypes)}
    </Field>
  );
};

OperationalFrequencyField.propTypes = {
  fieldLabels: PropTypes.object.isRequired,
  operationalFrequencyTypes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  operationalFrequencyTypes: getReferenceDataType(
    state,
    "OperationalFrequencyType"
  ),
});

export default connect(mapStateToProps)(OperationalFrequencyField);
