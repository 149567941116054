import { useAuth0 } from "@auth0/auth0-react";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { createProduct, patchProduct } from "../../../actions/products";
import ActionButton from "../../../components/common/ActionButton";
import ActionHeading from "../../../components/common/ActionHeading";
import Container from "../../../components/common/Container";
import FloatingActionButton from "../../../components/common/FloatingActionButton";
import HeaderBar from "../../../components/common/HeaderBar";
import PageHeading from "../../../components/common/PageHeading";
import ExpandableForm from "../../../components/forms/ExpandableForm";
import ProductForm, {
  PRODUCT_FORM_NAME,
} from "../../../components/forms/products/ProductForm";
import ProductFilters from "../../../components/products/ProductFilters";
import ProductListing from "../../../components/products/ProductListing";
import { getLoggedInUser, getProductPagination } from "../../../reducers";
import { roles } from "@certane/arcadia-web-components";
import { clearIcon, downloadIcon, productIcon } from "../../../util/icons";
import usePersistedState from "../../../util/persistedState";
import { performExport } from "../../../util/asyncRequestHelper";

const styles = () => ({
  root: {
    width: "100%",
  },
  contents: {
    marginBottom: "75px",
  },
  title: {
    marginRight: "auto",
  },
});

const defaultFilter = {
  textSearch: "",
  relationshipManagerIds: [],
};

const Index = ({
  classes,
  loggedInUser,
  history,
  pagination,
  localCreateProduct,
  localPatchProduct,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [sortBy, setSortBy] = usePersistedState(
    `products-listing-sort-${loggedInUser.email}`,
    {
      field: "name",
      direction: "asc",
    }
  );
  const [filter, setFilter] = usePersistedState(
    `products-listing-filter-${loggedInUser.email}`,
    defaultFilter,
    ["textSearch"]
  );
  const isAdmin = loggedInUser
    ? _.intersection(roles.ADMIN_ROLES, loggedInUser.roles).length > 0
    : false;
  const [formOpen, setFormOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const formRef = useRef();

  const clearFilter = () => setFilter(defaultFilter);

  const updateFilter = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  const openForm = () => {
    setFormOpen(true);
    window.scrollTo(0, formRef.current.offsetTop);
  };

  useEffect(() => {
    if (selectedProduct) {
      if (!formOpen) {
        openForm();
      } else {
        // need to close the redux form fully before changing between edit and create
        setFormOpen(false);
        setTimeout(() => {
          openForm();
        }, 0);
      }
    }
  }, [selectedProduct]);

  const onSave = async (product) => {
    const accessToken = await getAccessTokenSilently();
    const promise = selectedProduct
      ? localPatchProduct(
          selectedProduct.id,
          product,
          "Updated product",
          accessToken
        )
      : localCreateProduct(product, accessToken);

    promise.then(() => {
      setFormOpen(false);
      setSelectedProduct(null);
    });
  };

  const inlineSave = async (id, product) => {
    const accessToken = await getAccessTokenSilently();
    localPatchProduct(id, product, "Updated product", accessToken);
  };

  const createNew = () => {
    setSelectedProduct(null);
    if (formOpen) {
      // need to close the redux form fully before changing between edit and create
      setFormOpen(false);
      setTimeout(() => {
        openForm();
      }, 0);
    } else {
      openForm();
    }
  };

  const cancelForm = () => {
    setSelectedProduct(null);
    setFormOpen(false);
  };

  const getSearchParameters = () => ({
    ...filter,
    orderByField: `${sortBy.direction === "desc" ? "-" : ""}${sortBy.field}`,
    limit: pagination.pageSize,
    offset: pagination.offset,
    excludeGroupFilters: isAdmin,
  });

  const onDownload = async () => {
    const accessToken = await getAccessTokenSilently();
    performExport(
      "Product",
      getSearchParameters(),
      "CSV",
      accessToken,
      null,
      "Products download",
      "Please wait while we export your products",
      "This window can be closed"
    );
  };

  return (
    <div className={classes.root}>
      <HeaderBar>
        <ActionHeading
          heading={<PageHeading icon={productIcon()} heading="Products" />}
          actions={[
            <ActionButton
              key="clearFilter"
              tooltip="Clear filters"
              icon={clearIcon()}
              onClick={clearFilter}
              data-cy="clear-filters-button"
            />,
            <ActionButton
              key="onDownload"
              tooltip="Download"
              icon={downloadIcon()}
              onClick={onDownload}
            />,
          ]}
        />
      </HeaderBar>
      <Container className={classes.contents}>
        {!formOpen && isAdmin && <FloatingActionButton onClick={createNew} />}
        <ExpandableForm
          title={selectedProduct ? "Edit product" : "Create product"}
          submitButtonText={selectedProduct ? "Save" : "Create"}
          formComponent={ProductForm}
          formName={PRODUCT_FORM_NAME}
          open={formOpen}
          formRef={formRef}
          onCancel={cancelForm}
          onSubmit={onSave}
          isAltPaper
          initialValues={selectedProduct ? { ...selectedProduct } : {}}
          isEditing={!!selectedProduct}
        />
        <ProductFilters
          filter={filter}
          updateFilter={updateFilter}
          fullWidth={false}
        />
        {isAdmin && (
          <ProductListing
            history={history}
            filter={filter}
            clearFilter={clearFilter}
            sortBy={sortBy}
            updateSort={setSortBy}
            onChange={inlineSave}
            onSelect={(product) => setSelectedProduct(product)}
            selected={selectedProduct}
          />
        )}
        {!isAdmin && (
          <ProductListing
            history={history}
            filter={filter}
            clearFilter={clearFilter}
            sortBy={sortBy}
            updateSort={setSortBy}
          />
        )}
      </Container>
    </div>
  );
};

Index.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,

  // redux
  loggedInUser: PropTypes.object,
  pagination: PropTypes.object.isRequired,
  localCreateProduct: PropTypes.func.isRequired,
  localPatchProduct: PropTypes.func.isRequired,
};

Index.defaultProps = {
  loggedInUser: null,
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
  pagination: getProductPagination(state),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    localCreateProduct: createProduct,
    localPatchProduct: patchProduct,
  })
)(Index);
