import { requestJSON } from "./http";
import {
  CategoryGroup,
  CategoryGroupCreation,
} from "../../model/CategoryGroup";

const list = (accessToken: string): Promise<CategoryGroup[]> =>
  requestJSON({
    path: "/metropolis/category-groups",
    accessToken,
  });

const create = (
  group: CategoryGroupCreation,
  accessToken: string
): Promise<CategoryGroup> =>
  requestJSON({
    method: "POST",
    path: "/metropolis/category-groups",
    accessToken,
    data: group,
  });

const update = (
  id: string,
  changes: CategoryGroup,
  description: string,
  accessToken: string
): Promise<CategoryGroup> =>
  requestJSON({
    method: "PATCH",
    path: `/metropolis/category-groups/${id}`,
    accessToken,
    data: {
      fields: Object.keys(changes),
      changes,
      description,
    },
  });

const remove = (id: string, accessToken: string): Promise<void> =>
  requestJSON({
    method: "DELETE",
    path: `/metropolis/category-groups/${id}`,
    accessToken,
  });

export default {
  list,
  create,
  update,
  remove,
};
