import { Grid } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector } from "redux-form";
import { required } from "redux-form-validators";
import mediaApi from "../../../services/api/media";
import { infoIcon } from "../../../util/icons";
import WrappedDatePicker from "../wrapper/WrappedDatePicker";
import WrappedFilePicker from "../wrapper/WrappedFilePicker";
import WrappedTextField from "../wrapper/WrappedTextField";

const useStyles = makeStyles(() => ({
  label: {
    position: "relative",
  },
  groupVertical: {
    marginTop: "-4px",
    marginBottom: "-4px",
  },
  optionVertical: {
    marginTop: "-4px",
    "&:first-child": {
      marginTop: 0,
    },
  },
}));

const uploadNamespace = "media";

const validateReference = (reference) => {
  if (reference && !/^[A-Z0-9]+(-[A-Z0-9]+)*$/.test(reference)) {
    return "Must only include upper case alphanumeric characters separated by hyphens";
  }
  return null;
};

export const referenceAsyncValidator = {
  initiate: (values, field, accessToken) => {
    const reference = _.get(values, field);

    return reference
      ? mediaApi.checkReferenceAvailability(reference, accessToken)
      : Promise.resolve({ available: true });
  },
  validate: (values, response) =>
    response.available ? undefined : "No item found for this friendly id",
};

const timingOptions = {
  NOW: {
    id: "NOW",
    label: "Now (Current version)",
    tooltip: "This document will go live as soon as submitted",
  },
  HISTORICAL: {
    id: "HISTORICAL",
    label: "Historical (Past version)",
    tooltip: "This document won't go live but will be stored as a past version",
  },
  FUTURE: {
    id: "FUTURE",
    label: "Future date (Future version)",
    tooltip: "This document will go live midnight of go live date",
  },
};

const MediaDocumentFields = ({
  fieldLabels,
  namePrefix,
  editMode,
  disabledFutureDates,
  change,
  selectedScheduledActivationDate,
  selectedReviewDate,
}) => {
  const classes = useStyles();
  const [timing, setTiming] = useState(timingOptions.NOW.id);
  const [includeReviewDate, setIncludeReviewDate] = useState(
    !!selectedReviewDate
  );
  const fieldPrefix = namePrefix ? `${namePrefix}.` : "";

  const showReview = timing !== timingOptions.HISTORICAL.id;

  const InfoIcon = infoIcon();

  useEffect(() => {
    change(`${fieldPrefix}historical`, timing === timingOptions.HISTORICAL.id);
    change(`${fieldPrefix}scheduledActivationDate`, "");
    change(`${fieldPrefix}reviewBy`, "");
  }, [timing]);

  useEffect(() => {
    if (!includeReviewDate && !!selectedReviewDate) {
      change(`${fieldPrefix}reviewBy`, "");
    }
  }, [includeReviewDate]);

  return (
    <>
      <Field
        name={`${fieldPrefix}reference`}
        component={WrappedTextField}
        placeholder="Please enter the friendly id here"
        label={fieldLabels.nestedTypes.documentVersions.labels.reference}
        validate={validateReference}
        fullWidth
      />

      <Field
        name={`${fieldPrefix}effectiveFrom`}
        component={WrappedDatePicker}
        label={fieldLabels.nestedTypes.documentVersions.labels.effectiveFrom}
        fullWidth
      />

      {editMode && (
        <>
          <FormControl component="fieldset" margin="dense" fullWidth>
            <InputLabel shrink className={classes.label}>
              Publish
            </InputLabel>
            <RadioGroup
              className={classes.groupVertical}
              aria-label="timing"
              name="timing"
              value={timing}
              onChange={(event) => setTiming(event.target.value)}
            >
              {Object.values(timingOptions).map((timingOption) => (
                <FormControlLabel
                  className={classes.optionVertical}
                  key={timingOption.id}
                  value={timingOption.id}
                  control={<Radio />}
                  label={
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>{timingOption.label}</Grid>
                      <Grid item>
                        <Tooltip title={timingOption.tooltip}>
                          <InfoIcon fontSize="small" color="action" />
                        </Tooltip>
                      </Grid>
                    </Grid>
                  }
                />
              ))}
            </RadioGroup>
          </FormControl>
          {timing === timingOptions.FUTURE.id && (
            <Field
              name={`${fieldPrefix}scheduledActivationDate`}
              component={WrappedDatePicker}
              label={
                fieldLabels.nestedTypes.documentVersions.labels
                  .scheduledActivationDate
              }
              validate={required({
                msg: `${fieldLabels.nestedTypes.documentVersions.labels.scheduledActivationDate} is required`,
              })}
              required
              fullWidth
              shouldDisableDate={(date) =>
                disabledFutureDates.some((d) =>
                  moment(d).isSame(moment(date), "day")
                )
              }
              minDate={moment().add(1, "day").startOf("day")}
            />
          )}
        </>
      )}

      {showReview && (
        <>
          <FormControl margin="dense" fullWidth>
            <FormControlLabel
              control={
                <Switch
                  checked={includeReviewDate}
                  onChange={() => setIncludeReviewDate(!includeReviewDate)}
                  name="includeReviewDate"
                />
              }
              label="Requires regular review?"
            />
          </FormControl>
          {includeReviewDate && (
            <Field
              name={`${fieldPrefix}reviewBy`}
              component={WrappedDatePicker}
              label={fieldLabels.nestedTypes.documentVersions.labels.reviewBy}
              fullWidth
              validate={required({
                msg: `${fieldLabels.nestedTypes.documentVersions.labels.reviewBy} is required`,
              })}
              required
              disabled={
                timing === timingOptions.FUTURE.id &&
                !selectedScheduledActivationDate
              }
              minDate={moment(selectedScheduledActivationDate || new Date())
                .add(1, "day")
                .startOf("day")}
            />
          )}
        </>
      )}

      <Field
        name={`${fieldPrefix}document`}
        component={WrappedFilePicker}
        validate={required({
          msg: `${fieldLabels.nestedTypes.documentVersions.labels.document} is required`,
        })}
        label={fieldLabels.nestedTypes.documentVersions.labels.document}
        required
        fullWidth
        uploadNamespace={uploadNamespace}
        multiple={false}
      />
    </>
  );
};

MediaDocumentFields.propTypes = {
  fieldLabels: PropTypes.object.isRequired,
  namePrefix: PropTypes.string.isRequired,
  editMode: PropTypes.bool.isRequired,
  disabledFutureDates: PropTypes.array,
  change: PropTypes.func.isRequired,
  selectedScheduledActivationDate: PropTypes.string,
  selectedReviewDate: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  formName: PropTypes.string.isRequired,
};

MediaDocumentFields.defaultProps = {
  disabledFutureDates: [],
  selectedScheduledActivationDate: null,
  selectedReviewDate: null,
};

const mapStateToProps = (state, { formName, namePrefix }) => {
  const fieldPrefix = namePrefix ? `${namePrefix}.` : "";
  const reduxFormSelector = formValueSelector(formName);
  return {
    selectedScheduledActivationDate: reduxFormSelector(
      state,
      `${fieldPrefix}scheduledActivationDate`
    ),
    selectedReviewDate: reduxFormSelector(state, `${fieldPrefix}reviewBy`),
  };
};

export default connect(mapStateToProps)(MediaDocumentFields);
